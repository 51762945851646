import { useState, useMemo } from 'react';
import { addMessage } from 'redux/messageSlice';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'redux/hooks';
import {
  MessageType,
  MessageActionType,
} from '@revenue-solutions-inc/revxcoreui';
import { Error } from 'types/graphqlErrors';
import extractMeaningfulMessage from 'utils/errorMessage';
import {
  TimeZoneByUserResponse,
  useTimeZoneByUserQuery,
} from 'generated/graphql';

interface TenantDate {
  timezone: string;
  ianaTimeZone: string;
  tenantDate: string;
}
interface TimeZoneByUser {
  TimeZoneByUser: TimeZoneByUserResponse[];
}

const defaultTenantDate = {
  timezone: '',
  ianaTimeZone: '',
  tenantDate: '',
};

const getUserDate = (ianaTimeZone: string) => {
  const defaultIanaTimeZone = 'UTC';
  let currentDate = new Date();

  let newIanaTimeZone = ianaTimeZone;
  if (ianaTimeZone === '') newIanaTimeZone = defaultIanaTimeZone;

  if (newIanaTimeZone === defaultIanaTimeZone) {
    currentDate = new Date(
      Date.UTC(
        currentDate.getUTCFullYear(),
        currentDate.getUTCMonth(),
        currentDate.getUTCDate(),
        currentDate.getUTCHours(),
        currentDate.getUTCMinutes(),
        currentDate.getUTCSeconds()
      )
    );
  }

  const newTenantDate = currentDate.toLocaleString('en-US', {
    timeZone: newIanaTimeZone,
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  });
  return {
    ianaTimeZone: newIanaTimeZone,
    tenantDate: newTenantDate,
  };
};

const useTenantZone = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const timeZoneByUserKey = 'timeZoneByUser';
  const [firstRender, setFirstRender] = useState<boolean>(false);
  const [tenantDate, setTenantDate] = useState<TenantDate>(defaultTenantDate);

  const { isFetching } = useTimeZoneByUserQuery<TimeZoneByUser>(
    {},
    {
      enabled: !sessionStorage.getItem(timeZoneByUserKey),
      onSuccess: (data: TimeZoneByUser) => {
        if (data?.TimeZoneByUser) {
          const timeZone = data.TimeZoneByUser[0]?.timeZone ?? '';
          const { ianaTimeZone, tenantDate: newTenantDate } = getUserDate(
            data.TimeZoneByUser[0]?.ianaTimeZone ?? ''
          );
          const currentTenantDate = {
            timezone: timeZone,
            ianaTimeZone: ianaTimeZone,
            tenantDate: newTenantDate,
          };

          sessionStorage.setItem(
            timeZoneByUserKey,
            JSON.stringify(currentTenantDate)
          );
          setTenantDate(currentTenantDate);
          setFirstRender(true);
        }
      },
      onError: (error: Error[] | unknown) => {
        const message = extractMeaningfulMessage(
          error,
          t('pages.manageReusableContent.networkError')
        );
        dispatch(
          addMessage({
            actionType: MessageActionType.None,
            type: MessageType.Error,
            message: message,
          })
        );
      },
    }
  );

  const response = useMemo(() => {
    if (firstRender) {
      return {
        isFetching: isFetching,
        tenantDate: tenantDate,
      };
    } else if (sessionStorage.getItem(timeZoneByUserKey)) {
      const timeZoneByUserCached = JSON.parse(
        sessionStorage?.getItem(timeZoneByUserKey) ?? ''
      );
      const { ianaTimeZone, tenantDate: newTenantDate } = getUserDate(
        timeZoneByUserCached?.ianaTimeZone ?? ''
      );

      const tenantDateReloaded = {
        timezone: timeZoneByUserCached?.timezone ?? '',
        ianaTimeZone,
        tenantDate: newTenantDate,
      };

      sessionStorage.setItem(
        timeZoneByUserKey,
        JSON.stringify(tenantDateReloaded)
      );

      return {
        isFetching: false,
        tenantDate: tenantDateReloaded,
      };
    } else {
      return {
        isFetching: false,
        tenantDate: defaultTenantDate,
      };
    }
  }, [firstRender, isFetching, tenantDate]);

  return response;
};

export default useTenantZone;
