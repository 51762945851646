import { Modal } from '@mui/material';
import { Box } from '@mui/system';
import { WorkflowContextType } from 'types/WorkflowContext';
import AddWorkflow from 'components/AddWorkflow';

type WorkflowModalProps = {
  open: boolean;
  onClose?: () => void;
  contextId: string;
  contextType: WorkflowContextType;
  selectedGroupName?: string;
};

const WorkflowModal = ({
  open,
  onClose,
  contextId,
  contextType,
  selectedGroupName,
}: WorkflowModalProps) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="create-new-workflow"
      aria-describedby="create-new-workflow"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          minWidth: 320,
          maxHeight: 300,
          bgcolor: 'background.paper',
          p: 4,
        }}
      >
        <AddWorkflow
          selectedGroupName={selectedGroupName}
          contextId={contextId}
          contextType={contextType}
          onCloseCallback={onClose}
        />
      </Box>
    </Modal>
  );
};

export default WorkflowModal;
