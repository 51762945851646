import CircleIcon from '@mui/icons-material/Circle';
import SquareIcon from '@mui/icons-material/Square';
import { Stack } from '@mui/system';

interface PriorityIconProps {
  value: string;
}

const PriorityIcon = ({ value }: PriorityIconProps) => {
  //conditional color return of priority icon badge
  if (value?.toLowerCase() === 'high') {
    return (
      <Stack
        data-testid="highPriority-response"
        direction="row"
        spacing={3}
        alignItems="center"
      >
        <SquareIcon
          data-testid="high-icon"
          sx={{
            color: (theme) => `${theme.palette.error.main}`,
            fontSize: 'medium',
          }}
        />
        {value.toLowerCase()}
      </Stack>
    );
  }
  if (value?.toLowerCase() === 'medium') {
    return (
      <Stack
        data-testid="mediumPriority-response"
        direction="row"
        spacing={3}
        alignItems="center"
      >
        <SquareIcon
          data-testid="medium-icon"
          sx={{
            color: (theme) => `${theme.palette.warning.main}`,
            transform: 'rotate(40deg)',
            fontSize: 'medium',
          }}
        />
        {value.toLowerCase()}
      </Stack>
    );
  }
  if (value?.toLowerCase() === 'low') {
    return (
      <Stack
        data-testid="lowPriority-response"
        direction="row"
        spacing={3}
        alignItems="center"
      >
        <CircleIcon
          data-testid="low-icon"
          sx={{
            color: (theme) => `${theme.palette.success.main}`,
            fontSize: 'medium',
          }}
        />
        {value.toLowerCase()}
      </Stack>
    );
  }
  return <>{value?.toLowerCase()}</>;
};

export default PriorityIcon;
