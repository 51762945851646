import { useEffect, useState } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import {
  DestinationObject,
  SinkObject,
  DomainSink,
} from '@revenue-solutions-inc/revxcoreui/material/logix/types/entities';
import DestinationOperations from './DestinationOperations';

interface CatTypes {
  cat: string;
  id: number;
}
interface DestinationSynkObjectsProps {
  indexDomain: string;
  isExpandAll: number;
  sinkObjects: DestinationObject;
  setCatInfo: (catino: CatTypes) => void;
  catInfo: CatTypes;
  setIsExpandAll: () => void;
}

function DestinationSynkObjects({
  isExpandAll,
  sinkObjects,
  indexDomain,
  setCatInfo,
  catInfo,
  setIsExpandAll,
}: DestinationSynkObjectsProps): JSX.Element {
  const [openSynkObjects, setOpenSynkObjects] = useState<boolean[]>([]);

  const [selectedSynkObject, setSelectedSynkObject] =
    useState<DomainSink | null>(null);
  const theme = useTheme();

  useEffect(() => {
    const selectedSinkObject = sinkObjects.Domain.DomainSinks.filter(
      (object) => {
        return object.DomainName === indexDomain;
      }
    );
    setSelectedSynkObject(selectedSinkObject[0]);
  }, [indexDomain, sinkObjects]);

  useEffect(() => {
    const auxOpen = [...openSynkObjects];
    selectedSynkObject?.SinkObjects?.forEach(() => {
      auxOpen.push(false);
    });
    setOpenSynkObjects(auxOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSynkObject]);

  useEffect(() => {
    if (indexDomain && isExpandAll !== 2) {
      const auxOpen = [...openSynkObjects];
      auxOpen.forEach((t, i) => {
        auxOpen[i] = isExpandAll === 0 ? false : true;
      });
      setOpenSynkObjects(auxOpen);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpandAll, indexDomain]);

  const handleClickShowHide = (index: number) => {
    const auxOpen = [...openSynkObjects];
    auxOpen.forEach((t, i) => {
      if (i === index) {
        auxOpen[i] = !t;
      }
    });
    setOpenSynkObjects(auxOpen);
  };

  return indexDomain && selectedSynkObject?.SinkObjects ? (
    <Box>
      {selectedSynkObject?.SinkObjects?.map(
        (synkObject: SinkObject, index: number) => (
          <Box key={index}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexGrow: 1,
                }}
                onClick={() => {
                  setCatInfo({
                    cat: 'SinkObjects',
                    id: synkObject.SinkObjectId,
                  });
                  handleClickShowHide(index);
                  setIsExpandAll();
                }}
              >
                {openSynkObjects[index] ? (
                  <KeyboardArrowDownIcon />
                ) : (
                  <KeyboardArrowRightIcon />
                )}
                <Typography
                  variant="h6"
                  display="block"
                  gutterBottom
                  m={0}
                  sx={{
                    fontSize: '0.875rem',
                    cursor: 'pointer',
                    color: '#c9594d',
                    fontWeight:
                      catInfo.cat === 'SinkObjects' &&
                      catInfo.id == synkObject.SinkObjectId
                        ? 600
                        : 400,
                  }}
                >
                  {synkObject.SinkObjectName}{' '}
                  <span style={{ color: '#777' }}>
                    &nbsp; ({synkObject.Operations.length}){' '}
                  </span>
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: openSynkObjects[index] ? 'block' : 'none',
                borderLeft: `1px dashed ${theme.palette.grey2.main}`,
                marginLeft: '11px',
                paddingLeft: '5px',
              }}
            >
              <DestinationOperations
                operations={synkObject.Operations}
                sinkObjectId={synkObject.SinkObjectId}
                domainName={sinkObjects?.Domain.DomainSinks[0]?.DomainName}
                isExpandAll={isExpandAll}
                setCatInfo={setCatInfo}
                catInfo={catInfo}
                setIsExpandAll={setIsExpandAll}
              />
            </Box>
          </Box>
        )
      )}
    </Box>
  ) : (
    <></>
  );
}

export default DestinationSynkObjects;
