import { CardContent, Grid, Typography } from '@mui/material';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { AreaValue } from 'types/property';

import EditPropertyCardLayout from '../EditPropertyCardLayout';

interface Props {
  setActive: React.Dispatch<React.SetStateAction<string>>;
}
type TaxableDataType = {
  authority: string;
  roll: string;
  certified: string;
};

// TODO: Remove once the services are in place.
const taxableData: TaxableDataType[] = [
  { authority: 'City', roll: '', certified: '' },
  { authority: 'Improvement Value', roll: '320,600', certified: '320,600' },
  { authority: 'Land Value', roll: '100,000', certified: '100,000' },
  { authority: 'Assessed Value', roll: '420,600', certified: '420,600' },
  { authority: 'Credit', roll: 'HS ($2.08)', certified: '' },
  { authority: 'Exemption', roll: 'DV 10,000', certified: 'DV 10,000' },
  { authority: 'Taxable Value', roll: '410,600', certified: '410,600' },
  { authority: 'Tax Due', roll: '$579.95', certified: '$582.03' },
  { authority: 'County', roll: '', certified: '' },
  { authority: 'Improvement Value', roll: '320,600', certified: '320,600' },
  { authority: 'Land Value', roll: '100,000', certified: '100,000' },
  { authority: 'Assessed Value', roll: '420,600', certified: '420,600' },
  { authority: 'Credit', roll: 'HS ($2.08)', certified: '' },
  { authority: 'Taxable Value', roll: '420,600', certified: '420,600' },
  { authority: 'Tax Due', roll: '$2,067.88', certified: '$2,069.06' },
  { authority: 'State', roll: '', certified: '' },
  { authority: 'Improvement Value', roll: '320,600', certified: '320,600' },
  { authority: 'Land Value', roll: '100,000', certified: '100,000' },
  { authority: 'Assessed Value', roll: '420,600', certified: '420,600' },
  { authority: 'Credit', roll: 'HS ($2.08)', certified: '' },
  { authority: 'Exemption Value', roll: 'DV 20,000', certified: 'DV 20,000' },
  { authority: 'Exemption Value', roll: 'O65 15,000', certified: 'O65 15,000' },
  { authority: 'Taxable Value', roll: '385,600', certified: '385,600' },
  { authority: 'Tax Due', roll: '$202.98', certified: '$205.06' },
];

function EditTaxableValues({ setActive }: Props): JSX.Element {
  const { t } = useTranslation();

  // TODO: Make sure year is not hard coded once services are in place.
  const COLUMNS: ColumnDef<TaxableDataType>[] = [
    {
      id: 'authority',
      accessorKey: 'authority',
      header: () => (
        <HeaderColumnNext localization={t('pages.propertyView.authority')} />
      ),
      cell: ({ row }) => {
        const value = row.getValue('authority') as string;
        return (
          <Typography
            variant="body2"
            sx={{
              fontWeight: Object.values(AreaValue).includes(
                value.toLowerCase() as AreaValue
              )
                ? '900'
                : '',
            }}
          >
            {value}
          </Typography>
        );
      },
    },
    {
      id: 'roll',
      accessorKey: 'roll',
      header: () => (
        <HeaderColumnNext localization={t('pages.propertyView.roll')} />
      ),
      cell: ({ getValue }) => {
        return (
          <p
            style={{
              color: getValue() === 'HS ($2.08)' ? 'red' : '',
            }}
          >
            {getValue() as string}
          </p>
        );
      },
    },

    {
      id: 'certified',
      accessorKey: 'certified',
      header: () => (
        <HeaderColumnNext localization={t('pages.propertyView.certified')} />
      ),
    },
  ];

  return (
    <EditPropertyCardLayout
      setActive={setActive}
      title={t('pages.propertyView.taxableValuesDetail')}
    >
      <CardContent>
        <Grid container spacing={1}>
          <Grid item lg={4}>
            <DefaultDataTableNext
              columns={COLUMNS as ColumnDef<Record<string, unknown>>[]}
              data={taxableData}
              enableGlobalFilter={false}
            />
          </Grid>
        </Grid>
      </CardContent>
    </EditPropertyCardLayout>
  );
}

export default EditTaxableValues;
