import { gql } from 'graphql-request';

export const updateStatus = gql`
  mutation ($batchStatus: BatchStatusInput!) {
    UpdateBatchStatus(batchStatus: $batchStatus) {
      id
      depositId
      depositLabel
      batchStatus
      batchType
      paidDate
      defaultTransactionEffectiveDate
      createdDate
      dateClosed
      branchLocationId
      label
      source
      adjustmentAmount
      recordCount
    }
  }
`;

export const graphQuery = gql`
  query ($openDateBegin: String!) {
    FindBatch(openDateBegin: $openDateBegin) {
      id
      depositId
      depositLabel
      batchStatus
      batchType
      paidDate
      defaultTransactionEffectiveDate
      createdDate
      dateClosed
      branchLocationId
      label
      source
      adjustmentAmount
      recordCount
      externalId
    }
  }
`;
