import { ScheduledTasks } from 'types/channels';

export const getScheduledTasks: ScheduledTasks = {
  ScheduleTask: {
    TaskId: '',
    ScheduleTaskName: '',
    DisplayName: '',
    Description: '',
    Status: '',
    Version: 0,
    VersionName: '',
    Stages: [
      {
        StageName: '',
        StageDisplayName: '',
        StageDescription: '',
        ExecutionOrder: 1,
        DomainName: '',
        SinkObjectName: '',
        SinkObjectId: '',
        OperationName: '',
        OperationId: '',
        OperationType: '',
        ContinueOnStageFailure: false,
      },
    ],
    PipelineSchedules: [
      {
        Id: '',
        Name: '',
        StartTime: new Date(),
        EndTime: null,
        Interval: 1,
        Frequency: 'Hour',
        PipelineReferenceName: '',
        IsDelete: false,
        TaskParameters: null,
        RecurrenceSchedule: {
          Minutes: [],
          Hours: [],
          WeekDays: [],
          MonthDays: [],
        },
      },
    ],
    Module: '',
    ModuleId: 1,
    BusinessPolicy: '',
    NextRunOccurrence: '',
    NextRunTriggeredBy: '',
  },
};
