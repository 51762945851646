import { Fragment, useEffect, useRef, useState } from 'react';

import { Grid, SelectChangeEvent } from '@mui/material';
import { setHeader } from 'redux/contentSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useTranslation } from 'react-i18next';

import {
  AActionsInput,
  ActionFieldMapsInput,
  BusinessSectionInput,
  Field4Input,
  LogiXgroupInput,
  useCreateMultipleFormLayoutMutation,
  useCreateFormTemplateMutation,
  useGetLookupTypesConfigurationQuery,
  useValidateFormMutation,
  useGetConfigurationRecordsQuery,
  CreateLayoutConfigurationInput,
} from 'generated/graphql';
import { addMessage } from 'redux/messageSlice';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from 'components/Loading';
import {
  Field,
  FieldType,
  FormErrors,
  LogixGroupType,
  MessageType,
  ProgressLoader,
  RootAttributes,
  Parameters,
} from '@revenue-solutions-inc/revxcoreui';
import { Error } from 'types/graphqlErrors';
import { parseLogixGroupInput } from 'common/adapters/formInputAdapter';
import {
  ConfigurationDomains,
  ConfigurationModules,
} from 'common/platformConfigUtils/platformConfigUtils';
import Select, {
  SelectType,
} from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import { generateDefaultLayout } from '@revenue-solutions-inc/revxcoreui/material/layoutBuilder/builder/utils/layoutUtils';
import { LayoutBuilderHandle } from '@revenue-solutions-inc/revxcoreui/material/layoutBuilder/builder/AppPanel';
import LayoutBuilderWrapper from 'pages/admin/LayoutBuilderWrapper/LayoutBuilderWrapper';
import { JsonPath } from 'utils/filterJsonData';
import { parseType } from 'utils/parseType';
import {
  MasterLayout,
  MasterLayoutType,
  LayoutType,
} from '@revenue-solutions-inc/revxcoreui/material/layoutBuilder/types/layouts';
import FormDetails from '../FormDetails/FormDetails';
import FormLogix from '../FormLogix';
import FormDataMapper from '../FormDataMapper';

interface ActionProps {
  name: string;
  category: string;
  repeatable: boolean;
  statusToBeChanged: string;
  domain: string;
  operationId: string;
  operationType: string;
}

interface WorkflowProps {
  code: string;
  id: number;
}

function CreateForm(): JSX.Element {
  const { t } = useTranslation();
  const module = useAppSelector((state) => state.user.module);
  const dispatch = useAppDispatch();
  const { formCategory } = useParams();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [completed, setCompleted] = useState<{
    [k: number]: boolean;
  }>({});
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [formErrors, setFormErrors] = useState<FormErrors[] | []>([]);
  const [isPipelineDetailsValid, setPipelineDetailsValid] =
    useState<boolean>(false);
  const [LogixGroup, setLogixGroup] = useState<LogiXgroupInput | null>(null);

  const { mutate, isLoading: loadingCreateFormMutation } =
    useCreateFormTemplateMutation({});
  const { mutate: mutateValidateLogix } = useValidateFormMutation({});
  const [stepPassedValidation, setStepPassedValidation] =
    useState<boolean>(true);
  const [contextOptions, setLayoutContexts] = useState<SelectType[]>([]);
  const [selectedContext, setSelectedContext] = useState<string | number>();
  const layoutRef = useRef<LayoutBuilderHandle>(null);
  const [workFlowAttributes, setWorkFlowAttributes] = useState<
    RootAttributes[] | []
  >([]);
  const [forWF, setForWF] = useState<string>('');
  const [type, setType] = useState<string>('');
  const [subType, setSubType] = useState<string>('');
  const [typeObj, setTypeObj] = useState<WorkflowProps | null>(null);
  const [subTypeObj, setSubTypeObj] = useState<WorkflowProps | null>(null);
  const [actionsTypes, setActionsTypes] = useState<ActionProps[] | []>([]);
  const refBtnDM = useRef<HTMLButtonElement>(null);
  const [isSubmiting, setIsSubmiting] = useState<boolean>(false);
  const [mLayout, setMlayout] = useState<MasterLayout | undefined>(undefined);
  const [multipleLayouts, setMultipleLayouts] = useState<
    CreateLayoutConfigurationInput[]
  >([]);
  const { isFetching: fetchingLookupModules } =
    useGetLookupTypesConfigurationQuery(
      {
        configurationDomain: ConfigurationDomains.ReferenceSchema,
        configurationModule: ConfigurationModules.Platform,
        configurationType: 'LayoutContext',
      },
      {
        enabled: true,
        onSuccess: (data) => {
          const options: SelectType[] = [];
          data.GetLookupTypesConfiguration.forEach((typeC) => {
            options.push({
              key: typeC.platformConfigurationId ?? '',
              desc: typeC.configurationDescription ?? '',
            });
          });

          setLayoutContexts(options);
        },
        onError: () => {
          dispatch(
            addMessage({
              type: MessageType.Error,
              message: t('components.message.lookupError'),
            })
          );
        },
      }
    );
  const { mutate: saveNewLayout, isLoading: isLoadingSaveNewLayout } =
    useCreateMultipleFormLayoutMutation();

  const handleReset = () => {
    setCurrentStep(0);
    setCompleted({});
  };

  const handleMappedLogixGroupSchema = (logixUpdated: LogixGroupType) => {
    setLogixGroup(logixUpdated as LogiXgroupInput);
    return logixUpdated;
  };

  const handleFormToDataBase = async () => {
    const refLayout = layoutRef.current?.getLayout();
    setIsSubmiting(true);

    mutate(
      {
        version: '1',
        formCategory: formCategory as string,
        formTemplate: LogixGroup as LogiXgroupInput,
      },
      {
        onSuccess: (createFormTemplateRes) => {
          dispatch(
            addMessage({
              type: MessageType.Success,
              message: t('pages.manageFormsProcessing.success'),
            })
          );
          const currentContext = contextOptions.find(
            (c) => c.key === selectedContext
          );
          if (currentContext !== undefined) {
            const layouts = [...multipleLayouts];
            if (
              !layouts.some((item) => item.context === currentContext?.desc)
            ) {
              layouts.push({
                context: currentContext?.desc || 'Default',
                layout: JSON.stringify(refLayout),
                layoutType: 'DummyType',
              });
            }
            saveNewLayout(
              {
                layoutConfigurationInput: layouts.map((layout) => {
                  const newLayout = {
                    ...layout,
                    configurationId:
                      createFormTemplateRes.createFormTemplate.Id,
                    configurationVersion:
                      createFormTemplateRes.createFormTemplate.Version,
                  };
                  return newLayout;
                }),
              },
              {
                onSuccess: () => {
                  navigate(`/${module}/manageforms`);
                  setIsSubmiting(false);
                },
                onError: (errors) => {
                  const formErr = (errors as Error[])[0].extensions.response
                    .body.errors;
                  if (formErr && formErr?.length > 0) {
                    dispatch(
                      addMessage({
                        message: (errors as Error[])[0].extensions.response.body
                          .detail as string,
                        type: MessageType.Error,
                      })
                    );
                    setCurrentStep(2);
                    setIsSubmiting(false);
                  } else {
                    dispatch(
                      addMessage({
                        message: t('components.message.networkerror'),
                        type: MessageType.Error,
                      })
                    );
                    setIsSubmiting(false);
                  }
                },
              }
            );
          } else {
            navigate(`/${module}/manageforms`);
            setIsSubmiting(false);
          }
        },
        onError: (errors) => {
          const formErr = (errors as Error[])[0].extensions.response.body
            .errors;
          if (formErr && formErr?.length > 0) {
            dispatch(
              addMessage({
                type: MessageType.Error,
                message: (errors as Error[])[0].extensions.response.body
                  .detail as string,
              })
            );
            setCurrentStep(2);
            setIsSubmiting(false);
          } else {
            dispatch(
              addMessage({
                type: MessageType.Error,
                message: t('components.message.networkerror'),
              })
            );
            setIsSubmiting(false);
          }
        },
      }
    );
  };

  useEffect(() => {
    dispatch(
      setHeader({
        pageTitle: t('pages.forms.newForm'),
        previousPage: t('pages.forms.formDesign'),
        route: 'manageForms',
      })
    );
  }, [dispatch, t]);

  useEffect(() => {
    if (currentStep === 2) {
      mutateValidateLogix(
        {
          formTemplate: LogixGroup as LogiXgroupInput,
        },
        {
          onSuccess: () => {
            const newCompleted = completed;
            setCompleted(newCompleted);
          },
          onError: (errors) => {
            const formErr = (errors as Error[])[0].extensions.response.body
              .errors;
            if (formErr && formErr?.length > 0) {
              setFormErrors(formErr as FormErrors[]);
              dispatch(
                addMessage({
                  type: MessageType.Error,
                  message: (errors as Error[])[0].extensions.response.body
                    .detail as string,
                })
              );
              setCurrentStep(1);
            } else {
              dispatch(
                addMessage({
                  type: MessageType.Error,
                  message: t('components.message.networkerror'),
                })
              );
            }
          },
        }
      );
    } else {
      const newCompleted = completed;
      if (isPipelineDetailsValid) newCompleted[currentStep] = true;

      switch (currentStep) {
        case 0:
          newCompleted[currentStep] = isPipelineDetailsValid;
          break;
      }
      setCompleted(newCompleted);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completed, currentStep, isPipelineDetailsValid]);

  const validateSteps = () => {
    let isValid = true;

    switch (currentStep) {
      case 0:
        isValid = isPipelineDetailsValid;
        break;
    }
    setStepPassedValidation(isValid);
  };

  useEffect(() => {
    validateSteps();
  });

  const handleGetLogixUpdate = (logixUpdated: LogixGroupType) => {
    setLogixGroup(logixUpdated as LogiXgroupInput);
  };

  useEffect(() => {
    if (currentStep === 1 && workFlowAttributes.length > 0) {
      const copyLogix = { ...LogixGroup };
      const newFields: Field[] = workFlowAttributes.map((wAttr, idx) => ({
        FieldValues: [],
        BaseField: {
          FieldProcessingOrder: idx,
          IsPII: false,
          Show: false,
          Enable: false,
          IsFixed: false,
          IsMultiselect: false,
          FieldFormat: '',
          FieldName: wAttr.name,
          FieldType: FieldType.CALCULATED,
          FieldValueType: parseType(wAttr.dataType),
          Ignore: false,
          Rules: [],
          FieldDisplayName: wAttr.displayName,
          PossibleValues: [],
          GlobalRuleNames: [],
          LastExecutionRuleChain: [],
          CustomAttributes: [],
          FieldID: idx + 1,
          CustomFieldGroups: [],
          DataMaps: [],
          LineNumber: '',
          RequiredField: wAttr.required,
        },
      }));
      const newBS: BusinessSectionInput[] = [
        {
          SectionName: 'Workflow Section',
          SectionDisplayText: 'Workflow Section',
          Fields: newFields as Field4Input[],
          RepeatingGroups: [],
          SubSections: [],
          SectionProcessingOrder: 0,
          CustomAttributes: [],
        },
      ];

      if (copyLogix.Contexts && copyLogix.Contexts.length > 0) {
        const copySect = [...(copyLogix.Contexts[0].BusinessSections || [])];
        if (copySect.length > 0) {
          copySect.splice(0, 1, newBS[0]);
          copyLogix.Contexts[0].BusinessSections = copySect;
        } else {
          copyLogix.Contexts[0].BusinessSections = newBS;
        }

        //Create the action related to the workflow section
        const parameters: Parameters[] = [];
        //Find the action UpdateWorkflow to created it if not,
        //we dont do anything
        const actionUpdateWorkflow = actionsTypes.find(
          (actionType) => actionType.name === 'UpdateWorkflow'
        );
        if (actionUpdateWorkflow && actionUpdateWorkflow !== undefined) {
          parameters.push({
            Name: 'Action Type',
            Value: actionUpdateWorkflow.name,
          });
          parameters.push({
            Name: 'Type',
            Value: typeObj?.code ?? '',
          });
          parameters.push({
            Name: 'TypeId',
            Value: typeObj?.id.toString() ?? '',
          });
          parameters.push({
            Name: 'SubType',
            Value: subTypeObj?.code ?? '',
          });
          parameters.push({
            Name: 'SubTypeId',
            Value: subTypeObj?.id.toString() ?? '',
          });
          const actionFieldMaps = newFields.map((field) => ({
            FieldId: field.BaseField.FieldID,
            Attribute: {
              FieldValues: [],
              BaseField: field.BaseField,
            },
          }));
          const newAction: AActionsInput = {
            Name: `${actionUpdateWorkflow.name}_${typeObj?.code.replace(
              /\s/g,
              ''
            )}_${subTypeObj?.code.replace(/\s/g, '')}_1`,
            Description: '',
            Type: actionUpdateWorkflow.name,
            Category: actionUpdateWorkflow.category,
            Repeatable: actionUpdateWorkflow.repeatable,
            StatusToBeChanged: actionUpdateWorkflow.statusToBeChanged,
            ShortLabel: '',
            Parameters: parameters,
            DataMap: null,
            ActionFieldMaps: actionFieldMaps as ActionFieldMapsInput[],
          };
          if (
            copyLogix.Contexts[0].Actions &&
            copyLogix.Contexts[0].Actions?.length > 0
          ) {
            const copyActions = [...copyLogix.Contexts[0].Actions];
            copyActions.splice(0, 1, newAction);
            copyLogix.Contexts[0].Actions = copyActions;
          } else {
            copyLogix.Contexts[0].Actions?.push(newAction);
          }
        }

        setLogixGroup(copyLogix);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  const { data: actionTypesQuery } = useGetConfigurationRecordsQuery({
    configurationType: 'Form Action Type',
    configurationModule: '',
  });

  useEffect(() => {
    if (actionTypesQuery && actionTypesQuery.getConfigurations) {
      const acTypes: ActionProps[] = [];
      actionTypesQuery.getConfigurations.forEach((acType) => {
        const logixFields = JsonPath({
          keyName: 'groupName',
          schema: acType,
          value: 'Configuration',
          searchWith: '',
        });
        const objAct = {
          actionCategory: '',
          actionStatus: '',
          actionDomain: '',
          actionRepetable: false,
          actionOperationType: '',
          actionOperationId: '',
        };
        if (logixFields.length > 0) {
          objAct.actionOperationType =
            logixFields[0].value.attribute.find(
              (it: { attributeName: string }) =>
                it.attributeName === 'OperationType'
            )?.attributeValue ?? '';
          objAct.actionCategory =
            logixFields[0].value.attribute.find(
              (it: { attributeName: string }) => it.attributeName === 'Category'
            )?.attributeValue ?? '';
          objAct.actionDomain =
            logixFields[0].value.attribute.find(
              (it: { attributeName: string }) => it.attributeName === 'Domain'
            )?.attributeValue ?? '';
          objAct.actionStatus =
            logixFields[0].value.attribute.find(
              (it: { attributeName: string }) =>
                it.attributeName === 'StatusToBeChanged'
            )?.attributeValue ?? false;
          objAct.actionRepetable =
            logixFields[0].value.attribute.find(
              (it: { attributeName: string }) =>
                it.attributeName === 'Repeatable'
            )?.attributeValue ?? false;

          objAct.actionOperationId =
            logixFields[0].value.attribute.find(
              (it: { attributeName: string }) =>
                it.attributeName === 'OperationId'
            )?.attributeValue ?? '';
        }
        const action: ActionProps = {
          name: acType.configurationName,
          category: objAct.actionCategory,
          repeatable: Boolean(objAct.actionRepetable),
          statusToBeChanged: objAct.actionStatus,
          domain: objAct.actionDomain,
          operationId: objAct.actionOperationId,
          operationType: objAct.actionOperationType,
        };
        acTypes.push(action);
      });
      setActionsTypes(acTypes);
    }
  }, [actionTypesQuery]);

  return !(
    loadingCreateFormMutation ||
    isLoadingSaveNewLayout ||
    fetchingLookupModules ||
    isSubmiting
  ) ? (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ProgressLoader
          completed={completed}
          handleStepTransition={(cStep: number, previousStep: number) => {
            if (cStep === 1 && previousStep === 2) {
              refBtnDM.current?.click();
            } else if (cStep === 3 && previousStep === 2) {
              refBtnDM.current?.click();
            } else if (cStep === 2 && previousStep === 3) {
              setMlayout(layoutRef.current?.getLayout());
            }
          }}
          handleReset={handleReset}
          setCurrentStep={setCurrentStep}
          steps={[
            t('pages.forms.formDetails'),
            t('pages.forms.formStructure'),
            t('pages.manageChannel.dataMapper'),
            t('pages.manageForms.layoutBuilder'),
          ]}
          nonLinear={false}
          currentStep={currentStep}
          handleCurrentStep={(activeStep: number) => {
            setCurrentStep(activeStep);
          }}
          centerAlign
          handleSave={handleFormToDataBase}
          isCompleteStepBtnDisabled={!stepPassedValidation}
          isSaveAndCreateDisabled={false}
        >
          {currentStep === 0 && (
            <FormDetails
              LogixGroup={LogixGroup}
              setLogixGroup={setLogixGroup}
              setPipelineDetailsValid={setPipelineDetailsValid}
              setWorkFlowAttributes={setWorkFlowAttributes}
              forWF={forWF}
              setForWF={setForWF}
              type={type}
              setType={setType}
              subType={subType}
              setSubType={setSubType}
              typeObj={typeObj}
              setTypeObj={setTypeObj}
              subTypeObj={subTypeObj}
              setSubTypeObj={setSubTypeObj}
              formCategory={formCategory as string}
            />
          )}
          {currentStep === 1 && (
            <FormLogix
              LogixGroup={LogixGroup}
              formErrors={formErrors}
              handleGetLogixUpdate={handleGetLogixUpdate}
            />
          )}
          {currentStep === 2 && (
            <FormDataMapper
              LogixGroup={LogixGroup}
              handleMappedLogixGroupSchema={handleMappedLogixGroupSchema}
              actions={actionsTypes}
              refBtnDM={refBtnDM}
            />
          )}

          {currentStep === 3 && (
            <Fragment>
              <Select
                label="Context"
                id={'context-create-form'}
                value={selectedContext ?? ''}
                options={contextOptions}
                onChange={function (
                  event: SelectChangeEvent<string | number>
                ): void {
                  const masterLayout: MasterLayout =
                    layoutRef.current?.getLayout() as MasterLayout;
                  let tempLayout = [...multipleLayouts];
                  if (selectedContext !== undefined) {
                    const prevContext = contextOptions.find(
                      (c) => c.key === selectedContext
                    );
                    // set Layout locally in state of array when context is changed
                    // and later after click on save button then just save all latouts present in array
                    if (
                      multipleLayouts.some(
                        (item) => item.context === prevContext?.desc
                      )
                    ) {
                      tempLayout = multipleLayouts.map((item) => {
                        if (item.context === prevContext?.desc) {
                          item.layout = JSON.stringify(masterLayout);
                        }
                        return item;
                      });
                    } else if (
                      masterLayout.sections.some((section) => {
                        return section.layouts.length > 0 || section?.helptext;
                      }) ||
                      ((masterLayout.type === 'TABS' ||
                        masterLayout.type === 'WIZARD') &&
                        masterLayout.sections.length !== 2) ||
                      masterLayout.sections.some((section, index) => {
                        if (masterLayout.type === 'TABS') {
                          return section.name !== `Tab ${index + 1}`;
                        } else if (masterLayout.type === 'WIZARD') {
                          return section.name !== `Step ${index + 1}`;
                        }
                      })
                    ) {
                      tempLayout.push({
                        context: prevContext?.desc || 'Default',
                        layout: JSON.stringify(masterLayout),
                        layoutType: 'DummyType',
                        // configurationId and configurationVersion will not be vailable here.
                      });
                    }
                    setMultipleLayouts([...tempLayout]);
                  }
                  setSelectedContext(event.target.value);
                  // update mLayout state
                  const currentContext = contextOptions.find(
                    (c) => c.key === event.target.value
                  );
                  if (
                    tempLayout.some(
                      (item) => item.context === currentContext?.desc
                    )
                  ) {
                    setMlayout(
                      JSON.parse(
                        tempLayout[
                          tempLayout.findIndex(
                            (item) => item.context === currentContext?.desc
                          )
                        ].layout as string
                      )
                    );
                  } else {
                    setMlayout(undefined);
                  }
                }}
              />
              <LayoutBuilderWrapper
                ref={layoutRef}
                fields={parseLogixGroupInput(LogixGroup as LogiXgroupInput)}
                hideSaveButton={true}
                saveConfiguration={() => {
                  // eslint-disable-next-line no-console
                  console.log(layoutRef.current?.getLayout());
                }}
                masterLayout={mLayout}
                viewLayout={(view) => {
                  let tempLayout = [...multipleLayouts];
                  const currentContext = contextOptions.find(
                    (c) => c.key === selectedContext
                  );
                  const masterLayout: MasterLayout =
                    layoutRef.current?.getLayout() as MasterLayout;

                  //This is only for suspense context
                  if (currentContext?.desc === 'Suspense') {
                    // first check that suspense present in the multipleLayouts
                    // if yes then we must replace, do not add new layout in array
                    if (
                      tempLayout.some((item) => item.context === 'Suspense') &&
                      !view
                    ) {
                      // if masterLayout has atleast one layout then only add it in a multipleLayout
                      if (
                        masterLayout.sections.some((section) => {
                          return (
                            section.layouts.length > 0 || section?.helptext
                          );
                        }) ||
                        ((masterLayout.type === 'TABS' ||
                          masterLayout.type === 'WIZARD') &&
                          masterLayout.sections.length !== 2) ||
                        masterLayout.sections.some((section, index) => {
                          if (masterLayout.type === 'TABS') {
                            return section.name !== `Tab ${index + 1}`;
                          } else if (masterLayout.type === 'WIZARD') {
                            return section.name !== `Step ${index + 1}`;
                          }
                        })
                      ) {
                        // UPDATE
                        tempLayout = multipleLayouts.map((item) => {
                          if (item.context === 'Suspense') {
                            item.layout = JSON.stringify(masterLayout);
                          }
                          return item;
                        });
                        // ADD in final array
                        setMultipleLayouts([...tempLayout]);
                        // set Mlayout
                        setMlayout(masterLayout);
                      } else {
                        // REMOVE
                        tempLayout.splice(
                          tempLayout.findIndex(
                            (item) => item.context === 'Suspense'
                          ),
                          1
                        );
                        // REMOVE in final array
                        setMultipleLayouts([...tempLayout]);
                        // set Mlayout to default suspense renderer
                        setMlayout(
                          generateDefaultLayout(
                            parseLogixGroupInput(LogixGroup as LogiXgroupInput),
                            LayoutType.OneColumn,
                            MasterLayoutType.Suspense
                          )
                        );
                      }
                    } else if (
                      !view &&
                      !tempLayout.some((item) => item.context === 'Suspense')
                    ) {
                      // if no suspense present in multipleLayouts, add new layout
                      // add only if we have one layout in masterLayout
                      if (
                        masterLayout.sections.some((section) => {
                          return (
                            section.layouts.length > 0 || section?.helptext
                          );
                        }) ||
                        ((masterLayout.type === 'TABS' ||
                          masterLayout.type === 'WIZARD') &&
                          masterLayout.sections.length !== 2) ||
                        masterLayout.sections.some((section, index) => {
                          if (masterLayout.type === 'TABS') {
                            return section.name !== `Tab ${index + 1}`;
                          } else if (masterLayout.type === 'WIZARD') {
                            return section.name !== `Step ${index + 1}`;
                          }
                        })
                      ) {
                        // ADD
                        tempLayout.push({
                          context: currentContext?.desc || 'Default',
                          layout: JSON.stringify(masterLayout),
                          layoutType: 'DummyType',
                        });
                        // ADD in final array
                        setMultipleLayouts([...tempLayout]);
                        // set Mlayout
                        setMlayout(masterLayout);
                      } else {
                        // NO container present in masterLayout and click on view layout button
                        // add default suspense renderer
                        setMlayout(
                          generateDefaultLayout(
                            parseLogixGroupInput(LogixGroup as LogiXgroupInput),
                            LayoutType.OneColumn,
                            MasterLayoutType.Suspense
                          )
                        );
                      }
                    } else if (
                      view &&
                      !tempLayout.some((item) => item.context === 'Suspense')
                    ) {
                      setMlayout(undefined);
                    }
                  }
                }}
              />
            </Fragment>
          )}
        </ProgressLoader>
      </Grid>
    </Grid>
  ) : (
    <Loading />
  );
}

export default CreateForm;
