import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { GROUP_NAMES, SectionProps } from '../WorkflowDetailsTab';
import CustomAttributes from '../../CustomAttributes';

type CustomAttributesSectionProps = SectionProps;

const CustomAttributesSection = ({
  editDisabled,
  group,
  isEditMode,
  groupIndex,
  triggerValidation,
}: CustomAttributesSectionProps) => {
  const { t } = useTranslation();

  return (
    <Grid spacing={3} container>
      <Grid item xs={12}>
        <Typography variant="h1">
          {`${
            group.groupDescription ??
            group.groupName ??
            t('pages.workflowEditor.noGroupName')
          }`}
        </Typography>
      </Grid>
      <CustomAttributes
        triggerValidation={triggerValidation}
        editDisabled={editDisabled}
        grpIndex={groupIndex}
        isEditMode={isEditMode}
        isCustomTables={group.groupName === GROUP_NAMES.Tables}
      />
    </Grid>
  );
};

export default CustomAttributesSection;
