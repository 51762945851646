import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

function NoResults(): JSX.Element {
  const { t } = useTranslation();

  return (
    <Typography variant="h4">{t('components.message.noResults')}</Typography>
  );
}

export default NoResults;
