import { useEffect, useState } from 'react';

import { Grid, SelectChangeEvent, Typography } from '@mui/material';
import Checkbox from '@revenue-solutions-inc/revxcoreui/material/controls/Checkbox';
import DatePicker from '@revenue-solutions-inc/revxcoreui/material/controls/DatePicker';
import Input from '@revenue-solutions-inc/revxcoreui/material/controls/Input';
import Select from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import { SelectType } from '@revenue-solutions-inc/revxcoreui/material/controls/Select/Select';
import TextArea from '@revenue-solutions-inc/revxcoreui/material/controls/TextArea';
import { useTranslation } from 'react-i18next';
import { useGetDepositTypesQuery } from 'generated/graphql';
import { CreateDepositFlexible } from 'pages/CreateDeposit/types/CreateDepositFlexible';
interface Props {
  deposit: CreateDepositFlexible;
  setDeposit: (arg1: CreateDepositFlexible) => void;
}

//TODO: replace these hardcoded bank names with a Lookup - Jira ticket 3148
const selectBankNames: SelectType[] = [
  { key: 'General', desc: 'General' },
  { key: 'Reserved', desc: 'Reserved' },
];

function DepositDetailsStep({ deposit, setDeposit }: Props): JSX.Element {
  const { t } = useTranslation();
  const [depositTypesOptions, setDepositTypesOptions] = useState<SelectType[]>(
    []
  );
  const noteExistsInService = false;
  const depositHasBankAccount = false;
  const [addNoteChk, setAddNoteChk] = useState<boolean>(false);
  const { isLoading, data: depositTypes } = useGetDepositTypesQuery();

  useEffect(() => {
    const typesOptions: SelectType[] = [];
    if (!isLoading && depositTypes?.GetDepositTypes) {
      depositTypes.GetDepositTypes?.map(({ active, code, description }) => {
        //check if the depositType is currently active
        if (active) {
          typesOptions.push({
            key: code,
            desc: description,
          });
        }
      });
    }
    setDepositTypesOptions(typesOptions);
  }, [isLoading, depositTypes?.GetDepositTypes]);

  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <Typography variant="h2">
            {t('pages.createDeposit.enterDepositDetails')}
          </Typography>
          <Grid item xs={6} mt={2}>
            <div data-testid="depositDate">
              <DatePicker
                id="depositDate"
                label={t('pages.createDeposit.depositDate')}
                required
                value={deposit?.depositDate}
                handleChange={(date: Date | null) => {
                  setDeposit({
                    ...deposit,
                    depositDate: date,
                  });
                }}
                onError={(reason) => {
                  if (reason) {
                    setDeposit({
                      ...deposit,
                      depositDate: null,
                    });
                  }
                }}
              />
            </div>
          </Grid>
          <Grid item xs={6} mt={3}>
            <div data-testid="depositVoucher">
              <Input
                id="depositVoucher"
                required={false}
                label={t('pages.createDeposit.voucherNumber')}
                value={deposit.voucherNumber ?? ''}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setDeposit({
                    ...deposit,
                    voucherNumber: event.target.value,
                  });
                }}
                sx={{ maxWidth: '13.8rem', width: '100%' }}
              />
            </div>
          </Grid>
          <Grid item xs={6} mt={2}>
            <div data-testid="depositType">
              <Select
                id="depositType"
                value={deposit.depositType ?? ''}
                options={depositTypesOptions}
                label={t('pages.createDeposit.depositTypes')}
                required
                onChange={(event: SelectChangeEvent<string | number>) => {
                  setDeposit({
                    ...deposit,
                    depositType: event.target.value as string,
                  });
                }}
                sx={{ maxWidth: '13.8rem', width: '100%' }}
              />
            </div>
          </Grid>
          {/* TODO: replace these hardcoded bank names with a Lookup - Jira ticket 3148 */}
          {/*Please complete the TODOS below related to depositAccount when exist */}
          {depositHasBankAccount && (
            <>
              <Grid item xs={6} mt={2}>
                <div data-testid="depositAccount">
                  <Select
                    id="depositAccount"
                    value={''} //todo Update this line when deposit has depossitAccount
                    options={selectBankNames}
                    label={t('pages.createDeposit.bankName')}
                    required
                    onChange={
                      (/* event: SelectChangeEvent<string | number> */) => {
                        //todo Descomment this code when deposit has depositAccount
                        {
                          /* setDeposit({
                          ...deposit,
                          depositAccount: event.target.value as string,
                        }); */
                        }
                      }
                    }
                    sx={{ maxWidth: '13.8rem', width: '100%' }}
                  />
                </div>
              </Grid>
            </>
          )}

          {/* todo when the parameter note exists in the service remove noteExistsInService validation*/}
          {noteExistsInService && (
            <>
              <Grid item xs={4} mt={2}>
                <Checkbox
                  id={'addNoteChk'}
                  checked={addNoteChk}
                  label={t('pages.createDeposit.addNote')}
                  onChange={(event) => {
                    setAddNoteChk(event.target.checked);
                    if (!addNoteChk) {
                      //todo when the paremeter note exists in the service,
                      // remove this commented code and update deposit object to have this notes property
                      {
                        /* setDeposit({
                        ...deposit,
                        notes: '',
                      }); */
                      }
                    }
                  }}
                />
                {addNoteChk && (
                  <TextArea
                    ariaLabel="add notes"
                    value={''} //todo Update with deposit.notes when parameter notes exits
                    multiline
                    onChange={() =>
                      /* event: React.ChangeEvent<HTMLTextAreaElement> */
                      {
                        //todo when the paremeter note exists in the service,
                        // remove this commented code and update deposit object to have this notes property
                        {
                          /* setDeposit({
                        ...deposit,
                        notes: event.target.value,
                      }); */
                        }
                      }
                    }
                    sx={{ maxWidth: '13.8rem' }}
                  />
                )}
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default DepositDetailsStep;
