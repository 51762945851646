import { useState } from 'react';
import { formatObject } from 'utils/object-utils';
import NoData from 'components/NoData';
import { useGetAssignedWorkflowsQuery, WorkflowItem } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import Error from 'components/Error';
import { useFormatWorkflowDataForTable } from 'hooks/useFormatWorkflowDataForTable';
import { WorkHistoryTable } from 'components/Workflow/WorkHistoryTable/WorkHistoryTable';
import {
  RowDataDrawer,
  WorkflowDataDetailsType,
} from 'components/RowDataDisplay/RowDataDrawer';
import UpdateWorkflowButton from './UpdateWorkflowButton';

export const AssignedWorkflowsTable = (): JSX.Element => {
  const { t } = useTranslation();
  const {
    data: fetchedData,
    isLoading,
    isError,
  } = useGetAssignedWorkflowsQuery<{
    GetAssignedWorkflows: WorkflowItem[];
  }>();

  const { formatDataForTable } = useFormatWorkflowDataForTable();
  const tableData = formatDataForTable(fetchedData?.GetAssignedWorkflows ?? []);
  const [selectedWorkflow, setSelectedWorkflow] = useState<
    WorkflowDataDetailsType | undefined
  >();

  if (isError) {
    return <Error />;
  }

  if (isLoading) {
    return <div data-testid="isLoading-message"></div>;
  }

  if (!tableData || tableData?.length === 0) {
    return <NoData />;
  }

  return (
    <>
      <WorkHistoryTable
        tableData={tableData}
        tableName={t('pages.workflow.assignedTable.assignedTitle')}
        cursorHover
        onRowClick={({ original }) =>
          setSelectedWorkflow(formatObject(original))
        }
      />
      <RowDataDrawer
        title={`Work ${selectedWorkflow?.displayId ?? ''}`}
        open={!!selectedWorkflow}
        data={selectedWorkflow}
        onClose={() => setSelectedWorkflow(undefined)}
        footer={
          <UpdateWorkflowButton
            workflowKey={`${selectedWorkflow?.workflow_Key}`}
            workflowVariantId={`${selectedWorkflow?.workflow_Variant_Id}`}
          />
        }
      />
    </>
  );
};
