import { Grid, Typography } from '@mui/material';
import { Card, CardContent } from '@revenue-solutions-inc/revxcoreui';
import { Box } from '@mui/system';
import DataDisplay from '@revenue-solutions-inc/revxcoreui/material/controls/DataDisplay';
import CurrencyCell from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/TableCells/CurrencyCell';
import DateCell from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/TableCells/DateCell';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import { ColumnDef } from '@tanstack/react-table';
import format from 'date-fns/format';
import { useTranslation } from 'react-i18next';
import { dateFormat } from 'utils/date-util';
import { formatCurrency } from 'common/helpers';
import { AvailableBatchResponse } from 'generated/graphql';
import { CreateDepositFlexible } from 'pages/CreateDeposit/types/CreateDepositFlexible';

interface Props {
  selectedBatches: AvailableBatchResponse[];
  deposit: CreateDepositFlexible;
}

function DepositReview({ selectedBatches, deposit }: Props): JSX.Element {
  const { t } = useTranslation();
  const depositHasBankAccount = false;

  const Columns: ColumnDef<AvailableBatchResponse>[] = [
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.manageBatches.identifier')} />
      ),
      accessorKey: 'identifier',
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.manageBatches.status')} />
      ),
      accessorKey: 'status',
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.manageBatches.paidDate')} />
      ),
      accessorKey: 'settlementDate',
      cell: ({ getValue }) => {
        return <DateCell dateString={getValue() as string} />;
      },
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.manageBatches.amount')} />
      ),
      accessorKey: 'paymentAmount',
      cell: ({ getValue }) => {
        return (
          <Box sx={{ mr: '55%' }}>
            <CurrencyCell
              invalidValue={t('pages.tableCell.invalidValue')}
              amountString={getValue() as string}
            />
          </Box>
        );
      },
    },
  ];

  const getBatchesPaymentAmount = () => {
    let totalPaymentAmount = 0;
    selectedBatches?.map(
      (batch) => (totalPaymentAmount += batch.paymentAmount)
    );
    return totalPaymentAmount;
  };

  return (
    <>
      <Card sx={{ width: '80%' }}>
        <CardContent>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} mb={2}>
                <Typography
                  variant="h2"
                  align="center"
                  sx={{ textTransform: 'uppercase' }}
                >
                  {t('pages.createDeposit.deposit')}
                </Typography>
              </Grid>
              <Grid item xs={10}>
                {deposit?.voucherNumber && (
                  <DataDisplay
                    id="depositVoucherNumber"
                    label={t('pages.createDeposit.depositVoucherNumber')}
                    data={deposit?.voucherNumber}
                  />
                )}
              </Grid>
              {depositHasBankAccount && (
                <Grid item xs={2}>
                  <DataDisplay
                    id="depositBankName"
                    label={t('pages.createDeposit.bankName')}
                    // todo update data with deposit.depositAccount when deposit has bankAccount
                    data={''}
                  />
                </Grid>
              )}
              <Grid item xs={5} my={2}>
                <DataDisplay
                  id="depositDate"
                  label={t('pages.createDeposit.depositDate')}
                  data={
                    deposit.depositDate
                      ? format(new Date(deposit.depositDate), dateFormat)
                      : '-'
                  }
                />
              </Grid>
              <Grid item xs={5} my={2} pl={5}>
                <DataDisplay
                  id="depositSubtotal"
                  label={t('pages.createDeposit.depositPaymentAmount')}
                  dataFormat="currency"
                  data={formatCurrency(`${getBatchesPaymentAmount()}`)}
                />
              </Grid>
              <Grid item xs={2} my={2}>
                <DataDisplay
                  id="batchCount"
                  label={t('pages.createDeposit.batchCount')}
                  data={selectedBatches.length.toString()}
                />
              </Grid>
              <Grid item xs={12} mb={1}>
                <Typography
                  variant="h2"
                  align="center"
                  mb={2}
                  sx={{ textTransform: 'uppercase' }}
                >
                  {t('pages.createDeposit.batches')}
                </Typography>
                <DefaultDataTableNext
                  columns={
                    Columns as ColumnDef<Record<string, unknown>, unknown>[]
                  }
                  data={selectedBatches}
                  enableGlobalFilter={false}
                />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

export default DepositReview;
