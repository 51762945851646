/* eslint-disable jsx-a11y/control-has-associated-label */
import { useEffect, useReducer, ChangeEvent, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  Stack,
  Box,
  Theme,
  Typography,
  IconButton,
  List,
  LinearProgress,
  Tooltip,
} from '@mui/material';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { useTranslation } from 'react-i18next';
import { TabPanel, Checkbox } from '@revenue-solutions-inc/revxcoreui';
import useUploadAttachment, {
  UploadAttachmentCommand,
} from 'hooks/useUploadAttachment';
import { useGetWorkflowAttachmentsQuery } from 'generated/graphql';
import { WorkflowAttachment } from 'types/WorkflowAttachment';
import DatasourceSelect from 'components/DatasourceSelect';
import AttachmentSearch from './AttachmentSearch';
import {
  initialState,
  actions,
  attachmentsPanelReducer,
} from './attachmentsPanelReducer';
import AttachmentRowItem from './AttachmentRowItem';
import type { PanelProps } from '../types';

type AttachmentsPanelProps = PanelProps & {
  isEdit?: boolean;
};

export const AttachmentsPanel = ({
  selectedIndex,
  index,
  isEdit,
}: AttachmentsPanelProps) => {
  const { t } = useTranslation();
  const { key } = useParams() as { variantId: string; key: string };
  const [state, localDispatch] = useReducer(
    attachmentsPanelReducer,
    initialState
  );
  const fileTypesDataSource = 'AttachmentType';

  const attachments = useMemo(() => {
    return state.filterValue === ''
      ? state.attachments
      : state.attachments.filter((file) => {
          const propertiesArray = Object.values(file);
          return propertiesArray.some((property) =>
            property
              ?.toString()
              .toLowerCase()
              .includes(state.filterValue.toLowerCase())
          );
        });
  }, [state.attachments, state.filterValue]);

  const { data } = useGetWorkflowAttachmentsQuery({ workflowId: key });

  useEffect(() => {
    if (data?.GetWorkflowAttachments?.length) {
      localDispatch({
        type: actions.SET_EXSITING_ATTACHMENTS,
        payload: {
          attachments: data.GetWorkflowAttachments,
        },
      });
    }
  }, [data]);

  const onUploadAttachmentSuccess = (attachment: WorkflowAttachment) => {
    localDispatch({
      type: actions.ON_ADD_SUCCESS,
      payload: {
        attachment,
      },
    });
  };

  const { mutate: upload, isLoading } = useUploadAttachment({
    onSuccess: onUploadAttachmentSuccess,
  });

  const toggleIsConfidential = (e: ChangeEvent<HTMLInputElement>) => {
    localDispatch({
      type: actions.SET_IS_CONFIDENTIAL,
      payload: {
        isConfidential: e.target.checked,
      },
    });
  };

  const getFile = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    localDispatch({
      type: actions.SET_SELECTED_FILE,
      payload: {
        selectedFile: file ?? null,
      },
    });
  };

  const saveAttachment = () => {
    if (state.selectedFile) {
      const payload: UploadAttachmentCommand = {
        file: state.selectedFile,
        contentType: state.selectedFile.type,
        description: state.selectedFile.name,
        isConfidential: state.isConfidential,
        attachmentType: state.attachmentType,
        workflowId: +key,
      };
      upload(payload);
    }
  };
  return (
    <div className="crm-tab__panel">
      <TabPanel selectedIndex={selectedIndex} index={index}>
        <Box
          sx={(theme: Theme) => ({
            backgroundColor: 'background.paper',
            p: theme.spacing(2),
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            justifyContent: 'flex-end',
            width: '100%',
            mb: theme.spacing(1),
          })}
        >
          {!state.selectedFile && (
            <AttachmentSearch
              onSearchChange={(val) => {
                localDispatch({
                  type: actions.SET_ATTACHMENT_FILTER,
                  payload: {
                    filterValue: val,
                  },
                });
              }}
            />
          )}

          {state.selectedFile && (
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={1}
            >
              {isLoading ? (
                <Box sx={{ height: 15, width: 300 }}>
                  <LinearProgress
                    color="primary"
                    sx={{ height: 10, width: 300 }}
                  />
                </Box>
              ) : (
                <>
                  <IconButton
                    id="delete-selected-attachment"
                    aria-label="delete selected attachment"
                    color="default"
                    size="small"
                    onClick={() => {
                      localDispatch({
                        type: actions.CLEAR_SELECTED_FILE,
                      });
                    }}
                  >
                    <CancelRoundedIcon fontSize="small" />
                  </IconButton>
                  <Typography>{state.selectedFile.name}</Typography>
                  <DatasourceSelect
                    disabled={false}
                    datasource={fileTypesDataSource}
                    label={t('pages.workflow.attachmentType')}
                    fetchLayoutInfo={(e) => {
                      localDispatch({
                        type: actions.SET_ATTACHMENT_TYPE,
                        payload: {
                          attachmentType: e.target.value,
                        },
                      });
                    }}
                    value={state.attachmentType}
                  />
                  <Checkbox
                    onChange={toggleIsConfidential}
                    checked={state.isConfidential}
                    label={t('pages.workflow.isConfidential')}
                  />
                  <Button
                    id="save-attachment-btn"
                    onClick={saveAttachment}
                    variant="outlined"
                  >
                    {t('pages.workflow.upload')}
                  </Button>
                </>
              )}
            </Stack>
          )}
          {!state.selectedFile && (
            <Tooltip
              title={isEdit ? '' : t('pages.workflow.disabledAttachments')}
            >
              <span>
                <Button
                  id="crm-add-attachments-btn"
                  component="label"
                  variant="contained"
                  disabled={!isEdit}
                >
                  {t('pages.workflow.addAttachment')}
                  <input
                    hidden
                    accept="image/*, application/pdf"
                    type="file"
                    onChange={getFile}
                  />
                </Button>
              </span>
            </Tooltip>
          )}
        </Box>
        <List
          sx={{
            maxHeight: 300,
            overflowY: 'auto',
          }}
        >
          {attachments.map((att) =>
            Boolean(att) ? (
              <AttachmentRowItem
                key={att.workflowAttachmentId}
                attachment={att}
              />
            ) : null
          )}
        </List>
      </TabPanel>
    </div>
  );
};
