import { Stack, Typography } from '@mui/material';
import { Card } from '@revenue-solutions-inc/revxcoreui';
import Checkbox from '@revenue-solutions-inc/revxcoreui/material/controls/Checkbox';
import {
  Keys,
  handleIsChecked,
  handleElementsSelected,
} from 'utils/checkboxes';

export interface SideScrollType {
  key: string | number | undefined;
  name: string;
}

interface SideScrollProps<T> {
  title: string;
  options: SideScrollType[];
  id: string;
  scrollBoxValues: string[];
  onChangeState: (value: T) => void;
  onChangeValue?: T;
  width?: number | string;
}

function SideScrollBox<T>({
  options,
  scrollBoxValues,
  title,
  onChangeState,
  onChangeValue,
  id,
  width = '20em',
}: SideScrollProps<T>): JSX.Element {
  return (
    <>
      <Typography>{title}</Typography>
      <Card
        sx={{
          padding: '.7em',
          height: '90%',
          minHeight: '25em',
          overflow: 'auto',
          width: width,
        }}
      >
        <Stack>
          {options.map((option: SideScrollType, index: number) => {
            return (
              <Checkbox
                {...{
                  onKeyDown: (event) => {
                    if (event.key === Keys.ENTER) {
                      const isChecked = handleIsChecked(
                        scrollBoxValues,
                        `${option.key}`
                      );
                      const itemsSelected = handleElementsSelected(
                        scrollBoxValues,
                        isChecked,
                        `${option.key}`
                      );
                      onChangeState([...itemsSelected] as never);
                    }
                  },
                }}
                key={option.key}
                id={id}
                checked={true}
                label={option.name}
                onChange={() => {
                  const newList = scrollBoxValues;
                  newList.splice(index, 1);
                  if (onChangeValue) {
                    onChangeState({
                      ...onChangeValue,
                      scrollBoxValues: [...newList],
                    });
                  } else {
                    onChangeState([...newList] as never);
                  }
                }}
              />
            );
          })}
        </Stack>
      </Card>
    </>
  );
}
export default SideScrollBox;
