import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { setHeader } from 'redux/contentSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { format } from 'date-fns';

import { Grid, Box, SelectChangeEvent, Typography } from '@mui/material';
import {
  Input,
  DatePicker,
  Button,
  Select,
  MessageType,
  MessageActionType,
} from '@revenue-solutions-inc/revxcoreui';
import {
  useAutogenerateBatchIdMutation,
  useGetAllFormLayoutsQuery,
  useGetFormBatchQuery,
  useGetLastFormsVersionQuery,
} from 'generated/graphql';
import { useNavigate } from 'react-router-dom';
import Dialog from '@revenue-solutions-inc/revxcoreui/material/controls/Dialog';
import { Error } from 'types/graphqlErrors';
import Loading from 'components/Loading';
import { getTime } from 'utils/getTime';
import { dateFormat, isDateInvalid, toDate } from 'utils/date-util';
import { SelectType } from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import { addMessage } from 'redux/messageSlice';
import extractMeaningfulMessage from 'utils/errorMessage';

interface ListProps {
  FormName: string;
  Version: string;
  Id: string;
}

interface ErrorTypeProps {
  error: boolean;
  message: string;
}

interface ErrorsProps {
  name: ErrorTypeProps;
}

function FormEntry(): JSX.Element {
  const module = useAppSelector((state) => state.user.module);
  const [layoutOptions, setLayoutOptions] = useState<SelectType[] | []>([]);
  const [formNameSelect, setFormNameSelect] = useState<string>('');
  const [selectedFormId, setSelectedFormId] = useState<string>('');
  const [version, setVersion] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [dataGet, setDataGet] = useState<ListProps[] | []>([]);
  const [batchId, setBatchId] = useState<string>('');
  const [errorDate, setErrorDate] = useState<boolean>(false);
  const [formContext, setFormContext] = useState<string>('');
  const [numberEntries] = useState<number>(1);
  const [errors, setErrors] = useState<ErrorsProps>({
    name: {
      error: false,
      message: '',
    },
  });
  const navigate = useNavigate();

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    if (date) {
      const currentTime = getTime(new Date());
      const selectDateTime = getTime(date);

      if (selectDateTime > currentTime) {
        setErrorDate(true);
      } else {
        setErrorDate(false);
      }
    } else {
      setSelectedDate(null);
      setErrorDate(false);
    }
  };
  const { data: getLatestFormVersionQuery, isLoading: isFetchingForms } =
    useGetLastFormsVersionQuery({
      formCategory: 'Taxpayer',
    });
  const [batchNameUnique, setBatchNameUnique] = useState<boolean>(true);
  const { t } = useTranslation();

  const {
    data: getBatchData,
    refetch,
    isLoading: isBatchLoading,
    fetchStatus: batchFetchStatus,
  } = useGetFormBatchQuery(
    {
      getFormBatchId: batchId,
    },
    {
      enabled: false,
      networkMode: 'always',
      onError: (fb_errors: Error[]) => {
        if (fb_errors[0].message === t('components.pagenotfound.message')) {
          setBatchNameUnique(true);
        } else {
          setBatchNameUnique(false); //If any other error occurs we are not able to positively verify that the batch is unique or open we must not allow the user to continue
          setErrors((prev) => ({
            ...prev,
            name: {
              error: true,
              message: t('pages.forms.errorValidatingBatch'),
            },
          }));
        }
      },
      onSuccess(data) {
        // Should never have a batch without a formId so if it does happen it should be made obvious / Fail ungracefully.
        setSelectedFormId(data.getFormBatch.FormId || 'NoFormIdOnBatch');
        setFormNameSelect(
          data.getFormBatch.FormName || 'No FormName Associated with Batch'
        );
      },
    }
  );

  const {
    isLoading: isLoadingAutogenerateBatch,
    mutateAsync: autogenerateBatchID,
  } = useAutogenerateBatchIdMutation<Error[]>();

  const dispatch = useAppDispatch();
  const checkName = () => {
    setErrors((prev) => ({ ...prev, name: { error: false, message: '' } }));
    const isNotSpace = !batchId.trim().includes(' ');
    const characterAllowed = new RegExp(/^([a-zA-Z0-9 _-]*)$/).test(
      batchId.trim()
    );

    if (!isNotSpace) {
      setErrors((prev) => ({
        ...prev,
        name: {
          error: true,
          message: t('pages.forms.noSpacesBatchId'),
        },
      }));
    } else if (!characterAllowed) {
      setBatchNameUnique(true);
      setSelectedDate(null);
      setErrors((prev) => ({
        ...prev,
        name: {
          error: true,
          message: t('pages.forms.characterNotAllowed'),
        },
      }));
    }

    if (isNotSpace && characterAllowed && batchId !== '') {
      refetch().then((getBatchDataQuery) => {
        if (getBatchDataQuery) {
          setSelectedDate(null);
          if (getBatchDataQuery.data?.getFormBatch.Status === 'close') {
            setErrors((prev) => ({
              ...prev,
              name: {
                error: true,
                message: t('pages.forms.batchInUseClosed'),
              },
            }));
          }
          if (getBatchDataQuery.data?.getFormBatch.ReceivedDate !== undefined) {
            if (
              !isDateInvalid(getBatchDataQuery.data?.getFormBatch.ReceivedDate)
            ) {
              setSelectedDate(
                toDate(
                  getBatchDataQuery.data.getFormBatch.ReceivedDate as string
                )
              );
            }
          }
          if (getBatchDataQuery.isError) {
            setErrorDate(false);
          }
        }
      });
    }
  };
  const { isFetching } = useGetAllFormLayoutsQuery(
    {
      configurationId: selectedFormId,
    },
    {
      enabled: selectedFormId !== '',
      onSuccess: (data) => {
        const allLayouts =
          (data.getAllFormLayouts.map((layout) => {
            if (layout.layoutConfigurationId) {
              return {
                key: layout.layoutConfigurationId,
                desc: `${layout.context}`,
              };
            }
          }) as SelectType[]) || [];

        setLayoutOptions(allLayouts);
        const formContextByDefault = allLayouts.find(
          (layoutOpt) => layoutOpt.desc === 'Data Entry'
        );
        if (formContextByDefault !== undefined) {
          setFormContext(formContextByDefault.key);
        } else {
          setFormContext('');
        }
      },
    }
  );

  const conditionalMessage = () => {
    if (!batchId && !selectedDate) {
      return t('pages.forms.emptyFields');
    } else if (!batchId) {
      return t('pages.forms.batchId');
    } else {
      return t('pages.forms.receivedDate');
    }
  };

  useEffect(() => {
    if (getLatestFormVersionQuery?.getLastFormsVersion) {
      const listTemp = getLatestFormVersionQuery?.getLastFormsVersion.map(
        (item) => ({
          FormName: item.Forms?.length && item.Forms[0].FormName,
          Version: item.Forms?.length && item.Forms[0].Version,
          Id: item.Id,
        })
      );
      setDataGet(listTemp as ListProps[]);
    }
  }, [getLatestFormVersionQuery]);

  useEffect(() => {
    if (getBatchData !== null && getBatchData !== undefined) {
      setBatchNameUnique(false);
    }
  }, [getBatchData]);

  useEffect(() => {
    dispatch(
      setHeader({
        pageTitle: t('pages.forms.title'),
        previousPage: t('pages.forms.previousPage'),
      })
    );
  }, [dispatch, t]);

  const dispatchErrorMessage = (error: Error[]) => {
    dispatch(
      addMessage({
        message:
          t('pages.forms.autogenerateBatchIdError') +
          extractMeaningfulMessage(error, t('components.message.networkerror')),
        type: MessageType.Error,
        actionType: MessageActionType.None,
      })
    );
  };

  const goToForm = async () => {
    const query = new URLSearchParams();
    let tempBatchId: string | undefined;

    if (batchId.trim()) {
      tempBatchId = batchId.trim();
    } else {
      const data = await autogenerateBatchID({}).catch((e) => {
        dispatchErrorMessage(e);
        setOpen(false);
      });
      tempBatchId = data?.autogenerateBatchId;
    }

    // to navigate to the next page there must be an introduced batchId or one autogenerated
    if (!tempBatchId) return;

    query.append('batchId', tempBatchId);

    if (!isDateInvalid(selectedDate)) {
      const date = format(selectedDate as Date, dateFormat);
      query.append('received', date);
    }

    if (formContext) {
      query.append('context', formContext);
    }

    if (getBatchData?.getFormBatch.NextSequenceNumber) {
      query.append(
        'seqNbr',
        getBatchData?.getFormBatch.NextSequenceNumber.toString() || '1'
      );
    }

    let batchVersion = '0';
    if (getBatchData?.getFormBatch.FormVersion) {
      batchVersion = getBatchData?.getFormBatch.FormVersion;
    }

    navigate(
      `/${module}/forms/${selectedFormId}/${
        version || batchVersion
      }/fill?${query.toString()}`
    );
  };

  const handleBatchChange = (event: SelectChangeEvent<string>) => {
    setBatchId(event.target.value);
  };
  const clickButton = () => {
    if (
      !batchId ||
      (!selectedDate && batchNameUnique) ||
      (isDateInvalid(selectedDate) && batchNameUnique)
    ) {
      setOpen(true);
    } else {
      goToForm();
    }
  };
  return (
    <>
      {(isFetchingForms || isFetching) && <Loading />}
      <Grid container>
        <Grid item xs={12}>
          <Input
            id="batchId"
            label={t('pages.forms.batchField')}
            placeholder="Batch Id"
            value={batchId}
            onChange={handleBatchChange}
            onBlur={checkName}
            error={errors.name.error}
            helperText={errors.name.message}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            id="numberEntries"
            disabled
            label={t('pages.forms.numberEntries')}
            value={numberEntries}
          />
        </Grid>
        <Grid item xs={12}>
          <DatePicker
            id="datepicker"
            label={t('pages.forms.date')}
            value={selectedDate}
            handleChange={handleDateChange}
            isCalendarRequired={true}
            disableFuture={true}
            disabled={!batchNameUnique}
            size="medium"
            sx={[
              { height: '40px', marginBottom: '15px', paddingBottom: '25px' },
            ]}
          />
        </Grid>
        <Grid item xs={12} sx={{ marginTop: '20px', paddingTop: '10px' }}>
          <Typography variant="h2" sx={{ fontSize: '14px' }}>
            {t('pages.forms.form')}
          </Typography>
        </Grid>

        {
          //Form Selector / Label
          //Show the selector if the batch doesn't exist, or if the batchId is empty
          //Show the form label if the batch exists
        }
        {!getBatchData ? (
          <Grid
            item
            xs={5}
            my={2}
            sx={{
              maxHeight: '300px',
              overflowY: 'auto',
              backgroundColor: 'white.main',
              borderRadius: '5px',
              border: '1px solid',
              borderColor: 'grey.500',
              padding: '5px',
              paddingLeft: '10px',
              paddingRight: '10px',
            }}
          >
            {dataGet.map((item, index) => (
              <Box
                key={index}
                sx={{
                  cursor: 'pointer',
                  backgroundColor: `${
                    formNameSelect === item.FormName
                      ? 'primary.main'
                      : 'white.main'
                  }`,
                }}
              >
                <Typography
                  p={0.5}
                  variant="body1"
                  sx={{
                    color: `${
                      formNameSelect === item.FormName
                        ? 'white.main'
                        : 'inherit'
                    }`,
                  }}
                  onClick={() => {
                    setSelectedFormId(item.Id);
                    setFormNameSelect(item.FormName);
                    setVersion(item.Version);
                  }}
                >
                  {item.FormName}
                </Typography>
              </Box>
            ))}
          </Grid>
        ) : (
          <Typography variant="h3" mb={1}>
            {' ' + getBatchData?.getFormBatch.FormName}
          </Typography>
        )}

        {/*
          The Layout Context Selector we are going to show
          if we are in dev or qa env, if not example for other env by default it select DataEntry 
          layout if its comming with the form, if not we generate a default layout
        */}
        {(process.env.REACT_APP_ENVIRONMENT === 'Development Environment' ||
          process.env.REACT_APP_ENVIRONMENT === 'QA Environment') && (
          <Grid item container xs={12}>
            <Grid item xs={2}>
              <Select
                options={layoutOptions}
                label={t('pages.manageForms.layoutContext')}
                id="layout-context-select"
                onChange={(event: SelectChangeEvent<string | number>) => {
                  setFormContext(event.target.value.toString());
                }}
                value={formContext}
                disabled={!selectedFormId}
              />
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <Button
            disabled={
              (isBatchLoading && batchFetchStatus !== 'idle') ||
              !(formNameSelect && errors.name.error === false) ||
              errorDate
            }
            onClick={clickButton}
            id="startButton"
          >
            {batchNameUnique
              ? t('pages.forms.buttons.next')
              : t('pages.forms.resumeBatch')}
          </Button>
        </Grid>
      </Grid>
      <Dialog
        id="QuestionFormDialog"
        open={open}
        children={conditionalMessage()}
        title={t('pages.forms.caution')}
        type="danger"
        maxWidth="xs"
        dangerModalDangerButtonText={t('pages.forms.continue')}
        handleClose={() => {
          setOpen(false);
        }}
        handleCancelClick={() => {
          setOpen(false);
        }}
        handleDangerClick={() => {
          goToForm();
        }}
        loading={isLoadingAutogenerateBatch}
      />
    </>
  );
}

export default FormEntry;
