import { useEffect, useState } from 'react';

import { Grid, Typography } from '@mui/material';
import { getDate } from 'common/helpers';
import { getIdFormats } from 'components/entityManagement/common/formatValidations';
import { Account, useGetLookupConfigurationQuery } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { formatIdentifier } from 'utils/formatIdentifier';
import { ConfigurationDomains } from 'common/platformConfigUtils/platformConfigUtils';

interface Props {
  accountData: Account;
}

interface Details {
  label: string;
  value: string;
}

function AccountDetail({ accountData }: Props): JSX.Element {
  const MAIN_CONFIG_DOMAIN = ConfigurationDomains.ReferenceSchema;
  const { t } = useTranslation();
  const [details, setDetails] = useState<Details[] | null>(null);

  const { data: configuredIdTypes } = useGetLookupConfigurationQuery({
    configurationDomain: MAIN_CONFIG_DOMAIN,
    configurationType: 'IdType',
  });

  useEffect(() => {
    const detailsData: Details[] = [];
    detailsData.push({
      label: 'primaryId',
      value: formatIdentifier(
        accountData.primaryIdentifier ?? '-',
        getIdFormats(configuredIdTypes),
        accountData.primaryIdentifierType
      ),
    });
    detailsData.push({
      label: 'primaryIdType',
      value: accountData.primaryIdentifierType ?? '-',
    });
    if (accountData.group) {
      // Primary address
      const primaryAddress =
        accountData.group
          .find((group) => group.groupName?.toLowerCase() === 'addresses')
          ?.attribute?.find(
            (address) => address.attributeName === 'addressLine1'
          )?.attributeValue ?? '-';
      detailsData.push({
        label: 'primaryAddress',
        value: primaryAddress ? primaryAddress : '-',
      });
      // Primary email
      const primaryEmail =
        accountData.group
          .find((group) => group.groupName?.toLowerCase() === 'emailaddresses')
          ?.attribute?.find((email) => email.attributeName === 'value')
          ?.attributeValue ?? '-';
      detailsData.push({
        label: 'primaryEmail',
        value: primaryEmail ? primaryEmail : '-',
      });
      // Primary phone type
      const primaryPhoneType =
        accountData.group
          .find((group) => group.groupName?.toLowerCase() === 'phonenumbers')
          ?.attribute?.find(
            (phone) => phone.attributeName.toLowerCase() === 'phonenumbertype'
          )?.attributeValue ?? '-';
      detailsData.push({
        label: 'primaryPhoneType',
        value: primaryPhoneType ? primaryPhoneType : '-',
      });
      // Primary phone
      const primaryPhone =
        accountData.group
          .find((group) => group.groupName?.toLowerCase() === 'phonenumbers')
          ?.attribute?.find((phone) => phone.attributeName === 'value')
          ?.attributeValue ?? '-';
      detailsData.push({
        label: 'primaryPhone',
        value: primaryPhone ? primaryPhone : '-',
      });
    }
    if (accountData.commenceDate) {
      detailsData.push({
        label: 'commenceDate',
        value: getDate(accountData.commenceDate ?? ''),
      });
    }
    setDetails(detailsData);
  }, [accountData, configuredIdTypes]);

  return (
    <>
      {details && (
        <Grid container spacing={1}>
          {details?.map((detail) => {
            return (
              <Grid
                key={detail.label + '-grid'}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginBottom: '10px',
                }}
                item
                xs={12}
              >
                <Typography
                  variant="h5"
                  id={detail.label + '-dtl-label'}
                  component="label"
                >
                  {t(`pages.accountSummary.detail.${detail.label}`)}
                </Typography>
                <Typography variant="h3" id={detail.label + '-' + detail.value}>
                  {detail.value}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
      )}
    </>
  );
}

export default AccountDetail;
