import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams, useLocation } from 'react-router-dom';
import { setHeader } from 'redux/contentSlice';
import { useAppDispatch } from 'redux/hooks';
import {
  useGetViewFinancialTransactionByIdQuery,
  ViewFinancialTransactionResponse,
} from 'generated/graphql';
import { addMessage } from 'redux/messageSlice';
import {
  MessageActionType,
  MessageType,
} from '@revenue-solutions-inc/revxcoreui';
import Loading from 'components/Loading';
import extractMeaningfulMessage from 'utils/errorMessage';
import FinancialTransactionDetails from 'components/entityManagement/transactions/FinancialTransactionDetails';

function FinancialTransaction(): JSX.Element {
  const dispatch = useAppDispatch();
  const { id } = useParams() as { id: string };
  const location = useLocation();
  const { t } = useTranslation();
  const [financialTransaction, setFinancialTransaction] =
    useState<ViewFinancialTransactionResponse>();

  useEffect(() => {
    dispatch(setHeader({ pageTitle: t('pages.FinancialTransaction.title') }));
  }, [dispatch, t]);

  const { isLoading, isError } = useGetViewFinancialTransactionByIdQuery(
    {
      financialTransactionId: id,
    },
    {
      onSuccess: (response) => {
        setFinancialTransaction(response.GetViewFinancialTransactionById);
      },
      onError: (error) => {
        const message = extractMeaningfulMessage(
          error,
          t('pages.manageReusableContent.networkError')
        );
        dispatch(
          addMessage({
            message: message,
            type: MessageType.Error,
          })
        );
      },
    }
  );

  useEffect(() => {
    if (
      location.state &&
      location.state.prevPageName &&
      location.state.prevPath
    ) {
      dispatch(
        setHeader({
          pageTitle: t('pages.FinancialTransaction.title'),
          previousPage: location.state.prevPageName,
          route: location.state.prevPath.substring(1),
        })
      );
    }
  });

  useEffect(() => {
    if (isError) {
      dispatch(
        addMessage({
          message: t('pages.FinancialTransaction.error'),
          type: MessageType.Error,
          actionType: MessageActionType.None,
        })
      );
    }
  }, [dispatch, isError, t]);

  return (
    <>
      {isLoading && !isError && <Loading />}
      {!isLoading && (
        <FinancialTransactionDetails
          transaction={financialTransaction ?? undefined}
        />
      )}
    </>
  );
}

export default FinancialTransaction;
