import { useMemo, useState, useEffect, useCallback, ChangeEvent } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import {
  Grid,
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from '@mui/material';

import VisibilityIcon from '@mui/icons-material/Visibility';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useParams, useNavigate } from 'react-router-dom';
import { useHasEdit, useHasAccess } from 'hooks/useHasAccess';
import { useTranslation } from 'react-i18next';

import Loading from 'components/Loading';
import { setHeader } from 'redux/contentSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import {
  MessageActionType,
  MessageType,
} from '@revenue-solutions-inc/revxcoreui';
import { createPbimoduleColumnTables } from 'utils/createPbimoduleColumnTables';

import {
  useGetReportTemplatesQuery,
  useUpdateReportMutation,
  useCreateReportTemplateMutation,
  useActivateReportTemplateMutation,
  useGetReportQuery,
  GetReportTemplatesQuery,
  useDeleteTempMutation,
  useDeactivateReportTemplateMutation,
  useUpdateReportTemplateMutation,
  useCloneReportTemplateMutation,
} from 'generated/graphql';

import useDownloadFile from 'hooks/useDownloadFile';
import useGetAccessToken from 'hooks/useGetAccessToken';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import Select from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import Input from '@revenue-solutions-inc/revxcoreui/material/controls/Input';
import Dialog from '@revenue-solutions-inc/revxcoreui/material/controls/Dialog';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import Dropzone from '@revenue-solutions-inc/revxcoreui/material/controls/Dropzone';

type RowType = {
  original: {
    templateId: number;
    reportId: number;
    templateName: string;
    templateDescription: string;
    createdBy: string;
    createdDate: string;
    status: string;
    version: number;
    updatedBy: string;
    updatedDate: string;
    pbiProfileId?: string;
    pbiWorkspaceId?: string;
    pbiReportId?: string;
  };
};

type ReportFormType = {
  name: string;
  description: string;
  nameError: boolean;
  descriptionError: boolean;
  isPresent: boolean;
  templateName: string;
  templateDescription: string;
  templateNameError: boolean;
  templateDescriptionError: boolean;
};

type DataSet = {
  templateId?: number;
  reportId?: number | null;
  templateName?: string | null;
  templateDescription?: string | null;
  createdBy?: string | null;
  createdDate?: string | null;
  status?: string | null;
  version?: number | null;
  updatedBy?: string | null;
  updatedDate?: string | null;
  pbiProfileId?: string | null;
  pbiWorkspaceId?: string | null;
  pbiReportId?: string | null;
};

interface Registry {
  templateName: string;
  templateDescription: string;
}

function ReportsDetails(): JSX.Element {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const accessToken = useGetAccessToken();
  const { reportId } = useParams() as { reportId: string };
  const [isLoadingTemplates, setIsLoadingTemplates] = useState(false);
  const { mutate: updateReport } = useUpdateReportMutation();
  const { mutate: createReportTemplate } = useCreateReportTemplateMutation();
  const { mutate: activateReportTemplate } =
    useActivateReportTemplateMutation();
  const { mutate: deactivateReportTemplate } =
    useDeactivateReportTemplateMutation();
  const { mutate: deleteTemplate } = useDeleteTempMutation();
  const { mutate: updateTemplate } = useUpdateReportTemplateMutation();
  const { mutate: cloneTemp } = useCloneReportTemplateMutation();
  const dispatch = useAppDispatch();
  const module = useAppSelector((state) => state.user.module);
  /* Query getReport for the initial inputs values update report form */
  const { data: getReport, refetch: refetReport } = useGetReportQuery({
    report: { reportId: Number(reportId) },
  });
  const [moduleUpdateReport, setModuleUpdateReport] = useState<
    number | null | undefined
  >(-1);

  useMemo(() => {
    if (getReport !== undefined) {
      setModuleUpdateReport(getReport.getReport.moduleId);
    }
  }, [getReport]);

  const [donwloadFileState, setDownloadFileState] = useState({
    download: false,
    id: 0,
    name: '',
  });

  const { isFetching } = useDownloadFile(
    donwloadFileState.download,
    accessToken,
    `${process.env.REACT_APP_REPORTS_API}/template/file/${donwloadFileState.id}`,
    donwloadFileState.name.replace(/ /g, ''),
    { templateId: donwloadFileState.id },
    'application/zip'
  );

  useEffect(() => {
    if (isFetching) setDownloadFileState({ download: false, id: 0, name: '' });
  }, [isFetching]);

  /* Query getReportTemplates for the data templates table */
  const {
    data: getReportTemplate,
    refetch: refetchReport,
    isError,
  } = useGetReportTemplatesQuery({
    report: { reportId: Number(reportId) },
  });

  /* State to set the selected row table */
  const [rowData, setRowData] = useState<RowType | null>(null);
  /* State to set the table templates data */
  const [data, setData] = useState<GetReportTemplatesQuery | undefined>(
    getReportTemplate
  );
  /* State to set the right lateral details template card */
  const [card, setCard] = useState({
    templateId: 0,
    templateName: '',
    templateDescription: '',
    status: '',
    version: 0,
    createdBy: '',
    createdDate: '',
    updatedBy: '',
    updatedDate: '',
    pbiProfileId: '',
    pbiWorkspaceId: '',
    pbiReportId: '',
  });
  /* State to set object of inputs & errors forms */
  const [reportForm, setReportForm] = useState({
    /* Update Report */
    name: 'null-Report-Name-default',
    description: '',
    nameError: false,
    descriptionError: false,
    isPresent: false,
    /* Create Template */
    templateName: ' ',
    templateDescription: ' ',
    templateNameError: false,
    templateDescriptionError: false,
  });

  const [openModalNewReport, setOpenModalNewReport] = useState(false);

  const [openModal, setOpenModal] = useState<{
    isOpen: boolean;
    id: number;
    name: string;
    description: string;
  }>({
    isOpen: false,
    id: -1,
    name: ' ',
    description: ' ',
  });

  const [openModalDelete, setOpenModalDelete] = useState<{
    isOpen: boolean;
    id: number;
    name: string;
    description: string;
  }>({
    isOpen: false,
    id: -1,
    name: ' ',
    description: ' ',
  });

  const [openModalClone, setOpenModalClone] = useState<{
    isOpen: boolean;
    id: number;
  }>({
    isOpen: false,
    id: -1,
  });

  /* State that set file from create template form*/
  const [bulk, setBulk] = useState<File | null>(null);
  const canAction = useHasEdit('reportsDetails', 'reportManagementAccessAll');

  /* This useMemo its used to prevent the non data inputs update report form
  when the user reloads the page. */
  useMemo(() => {
    if (getReport !== undefined) {
      setReportForm({
        name: getReport.getReport?.reportName ?? '',
        description: getReport.getReport?.reportDescription ?? '',
        nameError: false,
        descriptionError: false,
        isPresent: false,
        templateName: ' ',
        templateDescription: ' ',
        templateNameError: false,
        templateDescriptionError: false,
      });
    }
  }, [getReport]);

  /* This useEffect set the header title page & functionality */
  useEffect(() => {
    dispatch(
      setHeader({
        pageTitle: t('pages.reports.reportsDetails'),
        previousPage: t('pages.reports.title'),
        route: `Reports`,
      })
    );
  }, [dispatch, t]);

  const handleSuccess = useCallback(
    (type: string, customMessage: string) => {
      dispatch(
        addMessage({
          message: customMessage,
          type: MessageType.Success,
          actionType: MessageActionType.None,
        })
      );

      switch (type) {
        case 'report':
          refetReport().then((e) => {
            if (e.isSuccess && e.isFetching === false) {
              setIsLoadingTemplates(false);
            }
          });
          break;
        case 'active':
          refetchReport().then((e) => {
            if (e.isSuccess && e.isFetching === false) {
              if (e.data) {
                refetReport();
                setCard({ ...card, status: 'ACTIVE' });
                setIsLoadingTemplates(false);
                setData(e.data);
              }
            }
          });
          break;
        case 'inactive':
          refetchReport().then((e) => {
            if (e.isSuccess && e.isFetching === false) {
              if (e.data) {
                refetReport();
                setCard({ ...card, status: 'INACTIVE' });
                setIsLoadingTemplates(false);
                setData(e.data);
              }
            }
          });
          break;
        default:
          refetchReport().then((e) => {
            if (e.isSuccess && e.isFetching === false && e.data) {
              setData(e.data);
              setIsLoadingTemplates(false);
              setOpenModalClone({
                isOpen: false,
                id: -1,
              });
            }
          });
          break;
      }
    },
    [card, dispatch, refetReport, refetchReport]
  );

  const handleError = useCallback(() => {
    dispatch(
      addMessage({
        message: t('pages.manageReusableContent.networkError'),
        type: MessageType.Error,
        actionType: MessageActionType.None,
      })
    );
    setIsLoadingTemplates(false);
  }, [dispatch, t]);

  /* This useCallback function request to update reports*/
  const updateReports = useCallback(
    (
      id: number,
      reportName: string,
      reportDescription: string,
      moduleId: number
    ) => {
      setIsLoadingTemplates(true);
      updateReport(
        {
          report: {
            reportId: id,
            reportName: reportName,
            reportDescription: reportDescription,
            moduleId: moduleId ? Number(moduleId) : 0,
          },
        },
        {
          onSuccess: () =>
            handleSuccess('report', t('pages.reports.message.updateReport')),
          onError: handleError,
        }
      );
    },
    [handleError, handleSuccess, t, updateReport]
  );

  const updateTemplates = useCallback(
    (id: number, tempName: string, tempDescription: string) => {
      setIsLoadingTemplates(true);
      updateTemplate(
        {
          report: {
            templateId: id,
            templateName: tempName,
            templateDescription: tempDescription,
          },
        },
        {
          onSuccess: () =>
            handleSuccess('temp', t('pages.reports.message.updateTemplate')),
          onError: handleError,
        }
      );
      setOpenModal({
        isOpen: false,
        id: -1,
        name: ' ',
        description: ' ',
      });
    },
    [handleError, handleSuccess, t, updateTemplate]
  );
  /* This useCallback function request to create templates*/
  const createTemplates = useCallback(
    (
      id: number,
      templateName: string,
      templateDescription: string,
      templateFile: File
    ) => {
      setOpenModalNewReport(false);
      setBulk(null);
      setIsLoadingTemplates(true);
      const reader = new FileReader();
      reader.onloadend = () => {
        createReportTemplate(
          {
            report: {
              reportId: id,
              templateName: templateName,
              templateDescription: templateDescription,
              templateFile: String(reader.result)
                .replace('data:', '')
                .replace(/^.+,/, ''),
            },
          },
          {
            onSuccess: () =>
              handleSuccess(
                'template',
                t('pages.reports.message.createTemplate')
              ),
            onError: handleError,
          }
        );
      };
      reader.readAsDataURL(templateFile);
    },
    [createReportTemplate, handleError, handleSuccess, t]
  );
  /* This useCallback function request to active templates*/
  const activeTemplate = useCallback(
    (templateId: number) => {
      setIsLoadingTemplates(true);
      activateReportTemplate(
        {
          report: {
            templateId: templateId,
          },
        },
        {
          onSuccess: () =>
            handleSuccess('active', t('pages.reports.message.activeTemplate')),
          onError: handleError,
        }
      );
    },
    [activateReportTemplate, handleError, handleSuccess, t]
  );

  const deactiveTemplate = useCallback(
    (templateId: number) => {
      setIsLoadingTemplates(true);
      deactivateReportTemplate(
        {
          report: {
            templateId: templateId,
          },
        },
        {
          onSuccess: () =>
            handleSuccess(
              'inactive',
              t('pages.reports.message.deactiveTemplate')
            ),
          onError: handleError,
        }
      );
    },
    [deactivateReportTemplate, handleError, handleSuccess, t]
  );

  const deleteTemplates = useCallback(
    (templateId: number) => {
      setIsLoadingTemplates(true);
      setOpenModalDelete({
        isOpen: false,
        id: -1,
        name: ' ',
        description: ' ',
      });
      deleteTemplate(
        {
          report: {
            templateId: templateId,
          },
        },
        {
          onSuccess: () =>
            handleSuccess('temp', t('pages.reports.message.deleteTemplate')),
          onError: handleError,
        }
      );
    },
    [deleteTemplate, handleError, handleSuccess, t]
  );

  /* This useMemo convert data of service*/
  useMemo(() => {
    if (getReportTemplate !== undefined) {
      getReportTemplate.getReportTemplates.forEach((value, index) => {
        getReportTemplate.getReportTemplates[index].createdDate = new Date(
          String(value.createdDate)
        ).toLocaleDateString('en-US');
        getReportTemplate.getReportTemplates[index].updatedDate = new Date(
          String(value.updatedDate)
        ).toLocaleDateString('en-US');
      });
      setData(getReportTemplate);
    }
  }, [getReportTemplate]);

  /* This useMemo memoize function to handle the selected row to 
set & sort the data of lateral card*/
  useMemo(() => {
    if (rowData) {
      setCard({
        templateId: rowData.original.templateId,
        templateName: rowData.original.templateName,
        templateDescription: rowData.original.templateDescription,
        status: rowData.original.status,
        version: rowData.original.version,
        createdBy: rowData.original.createdBy,
        createdDate: rowData.original.createdDate,
        updatedBy: rowData.original.updatedBy,
        updatedDate: rowData.original.updatedDate,
        pbiProfileId: String(rowData.original.pbiProfileId),
        pbiWorkspaceId: String(rowData.original.pbiWorkspaceId),
        pbiReportId: String(rowData.original.pbiReportId),
      });
    }
  }, [rowData]);

  const hasAccess = useHasAccess('powerBi', 'view');

  const cloneTemplate = useCallback(
    (tempId: {
      templateId: number;
      reportId: number;
      templateName: string;
      templateDescription: string;
    }) => {
      setIsLoadingTemplates(true);
      cloneTemp(
        {
          report: tempId,
        },
        {
          onSuccess: () =>
            handleSuccess('temp', t('pages.reports.message.cloneTemplate')),
          onError: handleError,
        }
      );
    },
    [cloneTemp, handleError, handleSuccess, t]
  );

  const createColumns = useCallback(
    (set: object) =>
      Object.entries(set).map((value) => {
        return {
          header: () => <HeaderColumnNext localization={String(value[1])} />,
          accessorKey: value[0],
          cell: ({
            row,
          }: {
            row: {
              original: DataSet;
            };
          }) => {
            switch (value[0]) {
              case 'iconDelete':
                return createPbimoduleColumnTables(
                  value[0],
                  row.original,
                  <DeleteIcon />,
                  setOpenModalDelete,
                  {
                    isOpen: true,
                    id: row?.original?.templateId ?? -1,
                    name: ' ',
                    description: ' ',
                  },
                  row.original.status === 'DRAFT' && canAction
                );
              case 'iconEdit':
                return createPbimoduleColumnTables(
                  value[0],
                  row.original,
                  <EditIcon />,
                  navigate,
                  `/${module}/Reports/${row.original.reportId}/edit/${row.original.templateId}/${row.original.templateName}/${row.original.templateDescription}`,
                  row.original.status === 'DRAFT' && canAction
                );
              case 'iconView':
                return createPbimoduleColumnTables(
                  value[0],
                  row.original,
                  <VisibilityIcon />,
                  navigate,
                  `/${module}/Reports/${row.original.reportId}/view/${row.original.templateId}/${row.original.templateName}/${row.original.templateDescription}`,
                  hasAccess
                );
              case 'iconUpdate':
                return createPbimoduleColumnTables(
                  value[0],
                  row.original,
                  <DriveFileRenameOutlineIcon />,
                  setOpenModal,
                  {
                    isOpen: true,
                    id: row?.original?.templateId ?? -1,
                    name: row?.original?.templateName ?? '',
                    description: row?.original?.templateDescription ?? '',
                  },

                  row.original.status === 'DRAFT' && canAction
                );
              case 'iconDownload':
                return createPbimoduleColumnTables(
                  value[0],
                  row.original,
                  <FileDownloadIcon />,
                  setDownloadFileState,
                  {
                    download: true,
                    id: Number(row.original.templateId),
                    name: row.original.templateName + '.pbix',
                  },
                  canAction
                );
              case 'iconClone':
                return createPbimoduleColumnTables(
                  value[0],
                  row.original,
                  <FileCopyIcon />,
                  setOpenModalClone,
                  { isOpen: true, id: row.original.templateId },
                  canAction
                );
              default:
                return createPbimoduleColumnTables(value[0], row.original);
            }
          },
        };
      }),

    [canAction, hasAccess, module, navigate]
  );

  const Columns: ColumnDef<DataSet>[] = useMemo(
    () =>
      createColumns(
        t('pages.reports.reportDetailHeaderTable', {
          returnObjects: true,
        })
      ),
    [createColumns, t]
  );

  /* This function his called to handle the change value of inputs in all the component*/
  const handleUpdate = (event: ChangeEvent<HTMLInputElement>) => {
    switch (event.target.id) {
      case 'reportName':
        if (event.target.value === ' ') {
          setReportForm((prev: ReportFormType) => ({
            ...prev,
            name: '',
            nameError: true,
          }));
        } else if (event.target.value.length > 50) {
          setReportForm((prev: ReportFormType) => ({
            ...prev,
            nameError: true,
          }));
        } else {
          setReportForm((prev: ReportFormType) => ({
            ...prev,
            name: event.target.value,
            nameError: false,
          }));
        }
        break;
      case 'templateName':
        if (event.target.value === ' ') {
          setReportForm((prev: ReportFormType) => ({
            ...prev,
            templateName: '',
            templateNameError: false,
          }));
        } else if (event.target.value.length > 50) {
          setReportForm((prev: ReportFormType) => ({
            ...prev,
            templateNameError: true,
          }));
        } else {
          setReportForm((prev: ReportFormType) => ({
            ...prev,
            templateName: event.target.value,
            templateNameError: false,
          }));
        }
        break;
      case 'reportDescription':
        if (event.target.value === ' ') {
          setReportForm((prev: ReportFormType) => ({
            ...prev,
            description: '',
            descriptionError: false,
          }));
        } else if (event.target.value.length > 200) {
          setReportForm((prev: ReportFormType) => ({
            ...prev,
            descriptionError: true,
          }));
        } else {
          setReportForm((prev: ReportFormType) => ({
            ...prev,
            description: event.target.value,
            descriptionError: false,
          }));
        }
        break;
      case 'templateDescription':
        if (event.target.value === ' ') {
          setReportForm((prev: ReportFormType) => ({
            ...prev,
            templateDescription: '',
            templateDescriptionError: true,
          }));
        } else if (event.target.value.length > 200) {
          setReportForm((prev: ReportFormType) => ({
            ...prev,
            templateDescriptionError: true,
          }));
        } else {
          setReportForm((prev: ReportFormType) => ({
            ...prev,
            templateDescription: event.target.value,
            templateDescriptionError: false,
          }));
        }
        break;

      default:
        break;
    }
  };

  const existRegistry = useCallback(
    (item: string, keyFound: string) => {
      if (data !== undefined) {
        return (
          Object(data.getReportTemplates).filter(
            (x: Registry) => x[keyFound as keyof Registry] === item
          ).length > 0
        );
      }
    },
    [data]
  );

  return (
    <>
      {!isLoadingTemplates && reportForm.name !== 'null-Report-Name-default' ? (
        <>
          <Dialog
            id="dialogIdConfirm"
            open={openModalDelete.isOpen}
            title={t('pages.reports.confirmationDeleteTemplate')}
            type="danger"
            dangerModalDangerButtonText="Delete"
            width={360}
            height={180}
            handleCancelClick={() => {
              setOpenModalDelete({
                isOpen: false,
                id: -1,
                name: ' ',
                description: ' ',
              });
            }}
            handleDangerClick={() => {
              deleteTemplates(openModalDelete?.id);
            }}
            handleClose={() => {
              setOpenModalDelete({
                isOpen: false,
                id: -1,
                name: ' ',
                description: ' ',
              });
            }}
          />
          <Dialog
            id="dialogId"
            title="New Template"
            open={openModalNewReport}
            children={
              <Box sx={{ display: 'grid', rowGap: '16px' }}>
                <Input
                  id="templateName"
                  label={'Template Name'}
                  value={reportForm.templateName}
                  placeholder={'Required'}
                  onChange={handleUpdate}
                  required={true}
                  error={
                    reportForm.templateNameError ||
                    reportForm.templateName === '' ||
                    reportForm.templateName.length > 50
                  }
                  helperText={
                    reportForm.templateNameError ||
                    reportForm.templateName === '' ||
                    reportForm.templateName.length > 50
                      ? 'Error Template Name'
                      : ''
                  }
                />
                <Input
                  id="templateDescription"
                  label={'Template Description'}
                  value={reportForm.templateDescription}
                  placeholder={'Required'}
                  onChange={handleUpdate}
                  required={true}
                  error={
                    reportForm.templateDescriptionError ||
                    reportForm.templateDescription === '' ||
                    reportForm.templateDescription.length > 200
                  }
                  helperText={
                    reportForm.templateDescriptionError ||
                    reportForm.templateDescription === '' ||
                    reportForm.templateDescription.length > 200
                      ? 'Error Description'
                      : ''
                  }
                />
                <Dropzone
                  id={'dropzone'}
                  accessToken={'string123'}
                  acceptedFiles={[{ 'text/pbix': ['.pbix'] }]}
                  acceptFormat={'.pbix'}
                  maxFiles={1}
                  noClick={true}
                  noKeyboard={true}
                  upload={() => {}}
                  label={bulk?.name ?? 'Drop file here'}
                  setFile={setBulk}
                  isLoading={false}
                  addStyles={{ backgroundColor: 'white', height: '80%' }}
                />
              </Box>
            }
            type="transactional"
            transactionModalTransactionButtonText="Create"
            width={360}
            height={400}
            sx={{
              padding: '0px',
              fontFamily: 'Calibri regular',
              fontSize: '14px',
              borderRadius: '4px',
              fontWeight: 'normal',
            }}
            handleClose={() => {
              setOpenModalNewReport(false);
              setReportForm({
                ...reportForm,
                templateName: ' ',
                templateDescription: ' ',
                templateNameError: false,
                templateDescriptionError: false,
              });
            }}
            handleCancelClick={() => {
              setOpenModalNewReport(false);
              setReportForm({
                ...reportForm,
                templateName: ' ',
                templateDescription: ' ',
                templateNameError: false,
                templateDescriptionError: false,
              });
            }}
            handleTransactionClick={() => {
              if (
                !reportForm.templateNameError &&
                !reportForm.templateDescriptionError &&
                reportForm.templateName !== ' ' &&
                reportForm.templateDescription !== ' '
              ) {
                if (bulk !== null) {
                  createTemplates(
                    Number(reportId),
                    reportForm.templateName,
                    reportForm.templateDescription,
                    bulk
                  );
                } else {
                  navigate(
                    `/${module}/Reports/${reportId}/create/0/${String(
                      reportForm.templateName
                    )}/${String(reportForm.templateDescription)}`
                  );
                }
              }
            }}
          />
          <Dialog
            id="Updatedialog"
            title="Update Template"
            open={openModal.isOpen}
            children={
              <Box sx={{ display: 'grid', rowGap: '16px' }}>
                <Input
                  id="templateName"
                  label={'Template Name'}
                  value={
                    reportForm.templateName === ' '
                      ? openModal.name
                      : reportForm.templateName
                  }
                  onChange={handleUpdate}
                  required={true}
                  error={
                    reportForm.templateNameError ||
                    reportForm.templateName === '' ||
                    reportForm.templateName.length > 50
                  }
                  helperText={
                    reportForm.templateNameError ||
                    reportForm.templateName === '' ||
                    reportForm.templateName.length > 50
                      ? 'Error Template Name'
                      : ''
                  }
                />
                <Input
                  id="templateDescription"
                  label={'Template Description'}
                  value={
                    reportForm.templateDescription === ' '
                      ? openModal.description
                      : reportForm.templateDescription
                  }
                  onChange={handleUpdate}
                  required={true}
                  error={
                    reportForm.templateDescriptionError ||
                    reportForm.templateDescription === '' ||
                    reportForm.templateDescription.length > 200
                  }
                  helperText={
                    reportForm.templateDescriptionError ||
                    reportForm.templateDescription === '' ||
                    reportForm.templateDescription.length > 200
                      ? 'Error Description'
                      : ''
                  }
                />
              </Box>
            }
            type="transactional"
            transactionModalTransactionButtonText="Update"
            width={360}
            height={400}
            sx={{
              padding: '0px',
              fontFamily: 'Calibri regular',
              fontSize: '14px',
              borderRadius: '4px',
              fontWeight: 'normal',
            }}
            handleClose={() => {
              setOpenModal({
                isOpen: false,
                id: -1,
                name: ' ',
                description: ' ',
              });
              setReportForm({
                ...reportForm,
                templateName: ' ',
                templateDescription: ' ',
                templateNameError: false,
                templateDescriptionError: false,
              });
            }}
            handleCancelClick={() => {
              setOpenModal({
                isOpen: false,
                id: -1,
                name: ' ',
                description: ' ',
              });
              setReportForm({
                ...reportForm,
                templateName: ' ',
                templateDescription: ' ',
                templateNameError: false,
                templateDescriptionError: false,
              });
            }}
            handleTransactionClick={() => {
              if (
                !reportForm.templateNameError &&
                !reportForm.templateDescriptionError &&
                reportForm.templateName !== '' &&
                reportForm.templateDescription !== ''
              ) {
                updateTemplates(
                  openModal.id,
                  reportForm.templateName === ' '
                    ? openModal.name
                    : reportForm.templateName,
                  reportForm.templateDescription == ' '
                    ? openModal.description
                    : reportForm.templateDescription
                );
              }
            }}
          />
          <Dialog
            id="CloneDialog"
            title="Clone Template"
            open={openModalClone.isOpen}
            children={
              <Box sx={{ display: 'grid', rowGap: '16px' }}>
                <Input
                  id="templateName"
                  label={'Template Name'}
                  value={reportForm.templateName}
                  onChange={handleUpdate}
                  required={true}
                  error={
                    reportForm.templateNameError ||
                    reportForm.templateName === '' ||
                    reportForm.templateName.length > 50 ||
                    existRegistry(
                      String(reportForm.templateName),
                      'templateName'
                    )
                  }
                  helperText={
                    reportForm.templateNameError ||
                    reportForm.templateName === '' ||
                    reportForm.templateName.length > 50 ||
                    existRegistry(reportForm.templateName, 'templateName')
                      ? 'Error Template Name'
                      : ''
                  }
                />
                <Input
                  id="templateDescription"
                  label={'Template Description'}
                  value={reportForm.templateDescription}
                  onChange={handleUpdate}
                  required={true}
                  error={
                    reportForm.templateDescriptionError ||
                    reportForm.templateDescription === '' ||
                    reportForm.templateDescription.length > 200
                  }
                  helperText={
                    reportForm.templateDescriptionError ||
                    reportForm.templateDescription === '' ||
                    reportForm.templateDescription.length > 200
                      ? 'Error Description'
                      : ''
                  }
                />
              </Box>
            }
            type="transactional"
            transactionModalTransactionButtonText="Create"
            width={360}
            height={400}
            sx={{
              padding: '0px',
              fontFamily: 'Calibri regular',
              fontSize: '14px',
              borderRadius: '4px',
              fontWeight: 'normal',
            }}
            handleClose={() => {
              setOpenModalClone({
                isOpen: false,
                id: -1,
              });
              setReportForm({
                ...reportForm,
                templateName: ' ',
                templateDescription: ' ',
                templateNameError: false,
                templateDescriptionError: false,
              });
            }}
            handleCancelClick={() => {
              setOpenModalClone({
                isOpen: false,
                id: -1,
              });
              setReportForm({
                ...reportForm,
                templateName: ' ',
                templateDescription: ' ',
                templateNameError: false,
                templateDescriptionError: false,
              });
            }}
            handleTransactionClick={() => {
              if (
                !reportForm.templateNameError &&
                !reportForm.templateDescriptionError &&
                reportForm.templateName !== ' ' &&
                reportForm.templateDescription !== ' ' &&
                data?.getReportTemplates.filter(
                  (x) => x.templateName === reportForm.templateName
                ).length === 0
              ) {
                cloneTemplate({
                  reportId: Number(reportId),
                  templateId: openModalClone.id,
                  templateName: reportForm.templateName,
                  templateDescription: reportForm.templateDescription,
                });
              }
            }}
          />
          <Box
            sx={{
              display: 'grid',
              alignItems: 'end',
              gridAutoFlow: 'row',
            }}
          >
            <Box sx={{ display: 'grid', alignItems: 'end' }}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sx={{ display: 'grid', gridAutoFlow: 'column' }}
                >
                  <Box sx={{ display: 'grid', gridAutoFlow: 'column' }}>
                    <Grid
                      item
                      sx={{
                        display: 'grid',
                        gridAutoFlow: 'column',
                        width: 'fit-content',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Card>
                        <CardHeader
                          title={<Typography>{'Update Report'}</Typography>}
                          sx={{ backgroundColor: '#CED7E4' }}
                        />
                        <CardContent>
                          <Box
                            sx={{
                              display: 'grid',
                              gridAutoFlow: 'row',
                              width: 'fit-content',
                            }}
                          >
                            <Typography>Report Name</Typography>

                            <Input
                              id="reportName"
                              ariaLabel="report name"
                              value={reportForm.name}
                              placeholder={'Required'}
                              onChange={handleUpdate}
                              error={
                                reportForm.nameError ||
                                reportForm.name === '' ||
                                reportForm.name.length >= 50
                              }
                              helperText={
                                reportForm.nameError ||
                                reportForm.name === '' ||
                                reportForm.name.length >= 50
                                  ? 'Error Name'
                                  : ''
                              }
                            />

                            <Typography>Report Description</Typography>

                            <Input
                              id="reportDescription"
                              ariaLabel="report description"
                              value={reportForm.description}
                              placeholder={'Required'}
                              onChange={handleUpdate}
                              error={
                                reportForm.descriptionError ||
                                reportForm.description === '' ||
                                reportForm.description.length >= 200
                              }
                              helperText={
                                reportForm.descriptionError ||
                                reportForm.description === '' ||
                                reportForm.description.length >= 200
                                  ? 'Error Description'
                                  : ''
                              }
                            />
                          </Box>
                        </CardContent>
                      </Card>
                      {getReport !== undefined ? (
                        <Grid
                          sx={{
                            display: 'grid',
                            gridAutoFlow: 'row',
                            marginLeft: '8px',
                          }}
                        >
                          <Box>
                            <Select
                              disabledOptions={[]}
                              id="UpdateReportModuleId"
                              label="Module"
                              onChange={(x) => {
                                setModuleUpdateReport(Number(x.target.value));
                              }}
                              value={
                                moduleUpdateReport === -1
                                  ? String(getReport.getReport.moduleId)
                                  : String(moduleUpdateReport)
                              }
                              options={[
                                {
                                  desc: 'Platform',
                                  key: '1',
                                },
                                {
                                  desc: 'Property',
                                  key: '2',
                                },
                                {
                                  desc: 'Revenue',
                                  key: '3',
                                },
                              ]}
                            />
                          </Box>
                          <Box
                            sx={{
                              display: 'grid',
                              gridAutoFlow: 'column',
                              alignContent: 'end',
                            }}
                          >
                            <Button
                              id="cancelBtn"
                              data-testid="cancelButton"
                              type="secondary"
                              sx={{ mt: 1, mb: 1, minWidth: '128px' }}
                              onClick={() => {
                                setReportForm({
                                  ...reportForm,
                                  name: getReport?.getReport?.reportName ?? '',
                                  description:
                                    getReport?.getReport?.reportDescription ??
                                    '',
                                });
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              id="saveBtn-updateContent"
                              data-testid="updateButton"
                              sx={{ mt: 1, mb: 1, ml: 1, minWidth: '128px' }}
                              disabled={!canAction}
                              onClick={() => {
                                if (
                                  !reportForm.nameError &&
                                  !reportForm.descriptionError &&
                                  reportForm.name !== ' ' &&
                                  reportForm.description !== ' '
                                ) {
                                  updateReports(
                                    Number(reportId),
                                    reportForm.name,
                                    reportForm.description,
                                    Number(moduleUpdateReport)
                                  );
                                }
                              }}
                            >
                              Save Changes
                            </Button>
                          </Box>
                        </Grid>
                      ) : null}
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={8}>
                  <Card>
                    <Box
                      sx={{
                        display: 'flex',
                        gridAutoFlow: 'column',
                        backgroundColor: '#CED7E4',
                      }}
                    >
                      <CardHeader
                        title={
                          <Typography>
                            {t('pages.reports.cardsHeader.historyTemplate')}
                          </Typography>
                        }
                      />
                    </Box>
                    <Grid
                      sx={{
                        flexGrow: '1 !important',
                        maxWidth: '100% !important',
                        padding: '24px!important',
                      }}
                    >
                      <>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'end',
                            justifyContent: 'space-between',
                            width: '304px ',
                          }}
                        >
                          <Button
                            id="createContent_upload"
                            sx={{ minWidth: '128px', mt: 1 }}
                            onClick={() => setOpenModalNewReport(true)}
                            disabled={!canAction}
                          >
                            {t(
                              'pages.reports.reportsDetailsButtons.addTemplate'
                            )}
                          </Button>
                          <Button
                            id="downloadIdButton"
                            sx={{ minWidth: '128px', mt: 1 }}
                            onClick={() =>
                              setDownloadFileState({
                                download: true,
                                id: 0,
                                name: 'blanck_template.pbix',
                              })
                            }
                            disabled={!canAction}
                          >
                            {t('pages.reports.reportsDetailsButtons.download')}
                          </Button>
                        </Box>
                      </>
                      {isError ? (
                        <Grid
                          sx={{
                            display: 'grid',
                            justifyContent: 'center',
                            alignItems: 'center',
                            margin: '24px',
                          }}
                        >
                          {t('pages.reports.noTemplates')}
                        </Grid>
                      ) : null}
                      {!isLoadingTemplates !== undefined &&
                      data !== undefined &&
                      !isError ? (
                        <DefaultDataTableNext
                          columns={
                            Columns as ColumnDef<{
                              templateId: number;
                              reportId: number;
                              templateName: string;
                              templateDescription: string;
                              createdBy: string;
                              createdDate: string;
                              status: string;
                              version: number;
                              updatedBy: string;
                              updatedDate: string;
                              pbiProfileId?: string;
                              pbiWorkspaceId?: string;
                              pbiReportId?: string;
                            }>[]
                          }
                          data={
                            data
                              ? (data.getReportTemplates as Array<{
                                  templateId: number;
                                  reportId: number;
                                  templateName: string;
                                  templateDescription: string;
                                  createdBy: string;
                                  createdDate: string;
                                  status: string;
                                  version: number;
                                  updatedBy: string;
                                  updatedDate: string;
                                  pbiProfileId?: string;
                                  pbiWorkspaceId?: string;
                                  pbiReportId?: string;
                                }>)
                              : []
                          }
                          fetchCursorSelectedRow={setRowData}
                          sx={{
                            justifyContent: 'end',
                            gridAutoFlow: 'column',
                            width: '100%',
                          }}
                        />
                      ) : (
                        <Grid
                          sx={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: isError ? 'none' : 'grid',
                          }}
                        >
                          <Loading fullScreen={false} />
                        </Grid>
                      )}
                    </Grid>
                  </Card>
                </Grid>
                {rowData && card ? (
                  <Grid item xs={4}>
                    <Card>
                      <Box
                        sx={{
                          display: 'grid',
                          gridAutoFlow: 'column',
                          justifyContent: 'space-between',
                          alignItems: 'center',

                          backgroundColor: '#CED7E4',
                        }}
                      >
                        <CardHeader
                          title={
                            <Typography>
                              {t(
                                'pages.reports.cardsHeader.attributesTemplate'
                              )}
                            </Typography>
                          }
                        />
                      </Box>
                      <CardContent>
                        <Grid>
                          <Grid
                            sx={{
                              gridTemplateColumns: 'repeat(2, 1fr)',
                              display: 'grid !important',
                            }}
                          >
                            {Object.entries(card).map((value, index) => {
                              return (
                                <Grid
                                  key={
                                    (card.templateId,
                                    String(value[1]),
                                    value[0])
                                  }
                                >
                                  <Box sx={{ width: 'fit-content' }}>
                                    {Number(index) <= 7 ? (
                                      <>
                                        <Typography>
                                          {String(value[0])[0].toUpperCase() +
                                            String(value[0])
                                              .replace(
                                                /([a-z])([A-Z])/g,
                                                '$1 $2'
                                              )
                                              .substring(1)}
                                        </Typography>
                                        <Typography
                                          sx={{ marginBottom: '32px' }}
                                        >
                                          {String(value[1])}
                                        </Typography>
                                      </>
                                    ) : null}
                                  </Box>
                                  <Box sx={{ width: 'fit-content' }}>
                                    {Number(index) >= 8 ? (
                                      <>
                                        <Typography>
                                          {String(value[0])[0].toUpperCase() +
                                            String(value[0])
                                              .replace(
                                                /([a-z])([A-Z])/g,
                                                '$1 $2'
                                              )
                                              .substring(1)}
                                        </Typography>
                                        <Typography
                                          sx={{ marginBottom: '32px' }}
                                        >
                                          {String(value[1])}
                                        </Typography>
                                      </>
                                    ) : null}
                                  </Box>
                                </Grid>
                              );
                            })}
                          </Grid>

                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'flex-end',

                              justifyContent: 'space-between',
                            }}
                          >
                            <Button
                              id="activateButtonid"
                              sx={{ minWidth: '128px', mt: 1 }}
                              onClick={() =>
                                activeTemplate(rowData.original.templateId)
                              }
                              disabled={!canAction || card.status === 'ACTIVE'}
                            >
                              {t('pages.reports.activeButton')}
                            </Button>
                            <Button
                              type="secondary"
                              id="deactivateButtonid"
                              sx={{ minWidth: '128px', mt: 1 }}
                              onClick={() =>
                                deactiveTemplate(rowData.original.templateId)
                              }
                              disabled={card.status !== 'ACTIVE' || !canAction}
                            >
                              {t('pages.reports.deactiveButton')}
                            </Button>
                          </Box>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                ) : null}
              </Grid>
            </Box>
          </Box>
        </>
      ) : (
        <Loading />
      )}
    </>
  );
}

export default ReportsDetails;
