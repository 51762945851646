/** @jsxImportSource @emotion/react */
import { useEffect, useState, useCallback } from 'react';

import AccountTreeIcon from '@mui/icons-material/AccountTree';

import { Grid, Stack, Typography } from '@mui/material';
import { Card, Checkbox } from '@revenue-solutions-inc/revxcoreui';
import Box from '@mui/material/Box';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import { customFilter } from 'components/manageConfigTools/Shared/FilterHeader/FilterHeader';
import { configHeaderColumns } from 'components/manageConfigTools/Shared/ConfigHeader/ConfigHeader';
import { Row, Table } from '@tanstack/react-table';
import Collapse from '@mui/material/Collapse';
import ConfigurationDomainsList from 'components/manageConfigTools/ConfigDomainsList/ConfigDomainsList';
import {
  MessageActionType,
  MessageType,
} from '@revenue-solutions-inc/revxcoreui/material/messaging/Message/Message';
import {
  expandGrid,
  collapseGrid,
} from 'components/manageConfigTools/Shared/CollapseStyling/CollapseStyling';
import Loading from 'components/Loading';
import ConfigurationDetails from 'components/manageConfigTools/ConfigurationDetails';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { addMessage, clearActionValue } from 'redux/messageSlice';
import {
  configurationInfoDefault,
  configurationResponseDefault,
} from 'types/configurations';
import { Error } from 'types/graphqlErrors';
import {
  useCreateConfigurationMutation,
  useUpdateConfigurationMutation,
  useDeleteConfigurationMutation,
  ConfigurationResponse,
  ConfigurationSection,
  useGetConfigurationTypesQuery,
  ConfigurationGroup,
  useGetConfigurationGroupsQuery,
  useGetConfigurationRecordsQuery,
} from 'generated/graphql';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import ExpandButton from 'components/manageConfigTools/Shared/ExpandButton/ExpandButton';
import { useHasEdit } from 'hooks/useHasAccess';
import ConfigSectionDetails from 'components/manageConfigTools/ConfigSectionDetails';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import {
  ConfigurationDomains,
  ConfigurationModules,
} from 'common/platformConfigUtils/platformConfigUtils';
import { deepCopy } from 'utils/deepCopy';
import extractMeaningfulMessage from 'utils/errorMessage';
import ConfigLayoutTable from 'components/manageConfigTools/Layouts/ConfigLayoutTable';
import { EditAccessUtil } from './editAccessUtil';

const EXTENDED_ATTRIBUTES = 'Extended Attributes';
/* 
  Since the group in the selectedConfigType object is saved only by ID, we need
  a way to map names from the ID and viceversa. And this from the updated groups
  in the server. We only care of the name "Extended Attributes", but ID
  could be assigned by the backend, it could be different if the group is deleted
  and then added again, or just ID could be different in QA than in Dev.
*/
function getGroupName(
  groups: ConfigurationGroup[],
  groupId: number | undefined | null
): string {
  return (
    groups.find((group) => group.platformConfigurationGroupId === groupId)
      ?.name || ''
  );
}
function getGroupId(
  groups: ConfigurationGroup[],
  groupName: string
): number | undefined {
  return (
    groups.find(({ name }) => name === groupName)
      ?.platformConfigurationGroupId ?? undefined
  );
}

function filterConfigTypes(
  data: ConfigurationResponse[] | undefined,
  configDomainId: string
): ConfigurationResponse[] {
  if (!data) return [];
  return data.filter(
    (config: ConfigurationResponse) =>
      config.configurationDomain == configDomainId
  );
}

function ConfigTypeEditor(): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  let tableInstance: Table<ConfigurationResponse> | undefined = undefined;
  const [selectedConfigType, setSelectedConfigType] =
    useState<ConfigurationResponse>();
  const [selectedConfigDomainFilter, setSelectedConfigDomainFilter] =
    useState<string>(ConfigurationDomains.ReferenceSchema.toString());
  const { data: configGroups } = useGetConfigurationGroupsQuery(
    {},
    {
      staleTime: 60 * 1000,
    }
  );
  const actionValue = useAppSelector((state) => state.message.actionValue);
  const defaultMessage = t('components.message.networkerror');
  const editAccess = new EditAccessUtil({
    isFullAccess: useHasEdit('configTypeEditor', 'platformconfigaccessall'),
    isLimitAccess: useHasEdit('configTypeEditor', 'platformconfiglimitaccess'),
    isExtendedAttributes:
      getGroupName(
        configGroups?.getConfigurationGroups ?? [],
        selectedConfigType?.platformConfigurationGroupId
      ) === EXTENDED_ATTRIBUTES,
  });

  const {
    isLoading,
    data,
    refetch: refetchTypes,
  } = useGetConfigurationTypesQuery(
    { configurationDomain: '' },
    {
      onError(error: Error[]) {
        dispatch(
          addMessage({
            message: extractMeaningfulMessage(error, defaultMessage),
            type: MessageType.Error,
            actionType: MessageActionType.None,
          })
        );
      },
    }
  );

  const { data: recordData } = useGetConfigurationRecordsQuery(
    {
      configurationType: selectedConfigType?.configurationType ?? '',
      configurationModule: '',
    },
    {
      enabled: !!selectedConfigType?.configurationType,
      onError(error: Error[]) {
        dispatch(
          addMessage({
            message: extractMeaningfulMessage(error, defaultMessage),
            type: MessageType.Error,
          })
        );
      },
    }
  );

  const { mutate: addConfiguration, isLoading: loadingAddConfig } =
    useCreateConfigurationMutation();
  const { mutate: updateConfiguration, isLoading: loadingUpdateConfig } =
    useUpdateConfigurationMutation();
  const { mutate: deleteConfiguration, isLoading: loadingDeleteConfig } =
    useDeleteConfigurationMutation();

  const [tempVersion, setTempVersion] = useState<boolean>(false);
  const [displayPanel, setDisplayPanel] = useState<boolean>(false);
  const [expandWidth, setExpandWidth] = useState<number>(7);
  const [isNewSection, setNewSection] = useState<boolean>(false);
  const [expandAll, setExpandAll] = useState<boolean>(true);
  const isLoadingAnything =
    loadingAddConfig || loadingUpdateConfig || loadingDeleteConfig;

  const configTypes = filterConfigTypes(
    data?.getConfigurationTypes,
    selectedConfigDomainFilter
  );

  const saveDisabled =
    selectedConfigType?.configurationType.trim() == '' ||
    selectedConfigType?.configurationModule == '' ||
    selectedConfigType?.configurationDomain == '' ||
    !selectedConfigType?.platformConfigurationGroupId;

  const removeConfiguration = useCallback(() => {
    deleteConfiguration(
      {
        deleteConfigInput: {
          platformConfigurationId:
            selectedConfigType?.platformConfigurationId || '',
        },
      },
      {
        onSuccess: () => {
          refetchTypes();
          setSelectedConfigType(undefined);
          dispatch(
            addMessage({
              message: t('components.message.success'),
              type: MessageType.Success,
              actionType: MessageActionType.None,
            })
          );
        },
        onError: (e: Error[] | unknown) => {
          const message = extractMeaningfulMessage(e, defaultMessage);
          dispatch(
            addMessage({
              message: String(message),
              type: MessageType.Error,
            })
          );
        },
      }
    );
  }, [
    defaultMessage,
    deleteConfiguration,
    dispatch,
    refetchTypes,
    selectedConfigType?.platformConfigurationId,
    t,
  ]);

  const deleteConfigSection = (
    oldSection: ConfigurationSection | undefined
  ) => {
    if (!!selectedConfigType && !!oldSection) {
      const newConfig: ConfigurationResponse = {
        ...selectedConfigType,
      };
      if (newConfig.platformConfigurationInfo) {
        newConfig.platformConfigurationInfo.configurationSection =
          newConfig?.platformConfigurationInfo?.configurationSection.filter(
            (configSection) =>
              configSection.configurationSectionName !==
              oldSection.configurationSectionName
          ) ?? [];
        //if there are no sections, we do not want the platformconfigurationinfo property to be populated
        if (
          newConfig.platformConfigurationInfo.configurationSection.length === 0
        )
          newConfig.platformConfigurationInfo = null;

        setSelectedConfigType(newConfig);
      }
    }
  };

  const updateConfigSection = (
    newConfigSection: ConfigurationSection,
    oldSection: ConfigurationSection | null = null
  ) => {
    let sectionNameExists = undefined;

    if (
      !isNewSection &&
      oldSection?.configurationSectionName ===
        newConfigSection.configurationSectionName
    ) {
      sectionNameExists = undefined;
    } else {
      sectionNameExists =
        selectedConfigType?.platformConfigurationInfo?.configurationSection.find(
          (section) =>
            section.configurationSectionName ===
            newConfigSection.configurationSectionName
        );
    }

    if (!!selectedConfigType && sectionNameExists === undefined) {
      const newConfig: ConfigurationResponse = {
        ...selectedConfigType,
      };

      if (
        !newConfig.platformConfigurationInfo ||
        !newConfig.platformConfigurationInfo?.configurationSection
      ) {
        newConfig.platformConfigurationInfo = {
          configurationSection: [],
        };
      }
      const configSections = [
        ...newConfig?.platformConfigurationInfo?.configurationSection,
      ];

      // new section is added, existing section is replaced
      if (isNewSection) {
        configSections.push(newConfigSection);
      } else if (!!oldSection) {
        const oldInd =
          newConfig?.platformConfigurationInfo?.configurationSection.findIndex(
            (configSection) =>
              configSection.configurationSectionName ===
              oldSection.configurationSectionName
          );

        configSections[oldInd] = newConfigSection;
      }

      newConfig.platformConfigurationInfo.configurationSection = configSections;
      setSelectedConfigType(newConfig);
      if (!!recordData?.getConfigurations.length) {
        dispatch(
          addMessage({
            message: t('pages.configTypeEditor.typeChangesWarning'),
            type: MessageType.Warning,
          })
        );
      }
      return true;
    } else return false;
  };

  function updateConfigurationType() {
    if (selectedConfigType == undefined) return;
    updateConfiguration(
      {
        newConfiguration: {
          configurationDomain: parseInt(selectedConfigType.configurationDomain),
          configurationModule: parseInt(selectedConfigType.configurationModule),
          isSchema: true,
          isNewVersion: tempVersion,
          version: selectedConfigType.version || 1,
          configurationName: selectedConfigType.configurationName,
          configurationType: selectedConfigType.configurationType,
          configurationDescription: selectedConfigType.configurationDescription,
          platformConfigurationId: selectedConfigType.platformConfigurationId,
          platformConfigurationGroupId:
            selectedConfigType.platformConfigurationGroupId,
          platformConfigurationInfo:
            selectedConfigType.platformConfigurationInfo,
        },
      },
      {
        onSuccess: () => {
          refetchTypes();
          dispatch(
            addMessage({
              message: t('components.message.success'),
              type: MessageType.Success,
            })
          );
          setTempVersion(false);
        },
        onError: (e: Error[] | unknown) => {
          const message = extractMeaningfulMessage(e, defaultMessage);
          dispatch(
            addMessage({
              message: String(message),
              type: MessageType.Error,
            })
          );
        },
      }
    );
  }

  function addConfigurationType() {
    if (selectedConfigType == undefined) return;
    addConfiguration(
      {
        newConfiguration: {
          configurationDomain: parseInt(selectedConfigType.configurationDomain),
          configurationModule: parseInt(selectedConfigType.configurationModule),
          isSchema: true,
          isNewVersion: tempVersion,
          version: selectedConfigType.version || 1,
          configurationName: selectedConfigType.configurationType,
          configurationType: selectedConfigType.configurationType,
          configurationDescription: selectedConfigType.configurationDescription,
          platformConfigurationGroupId:
            selectedConfigType.platformConfigurationGroupId,
          platformConfigurationInfo:
            selectedConfigType.platformConfigurationInfo,
        },
      },
      {
        onSuccess: (response) => {
          setSelectedConfigType({
            ...selectedConfigType,
            platformConfigurationId: response.createConfiguration,
            configurationName: selectedConfigType.configurationType,
          });
          refetchTypes();
          dispatch(
            addMessage({
              message: t('components.message.success'),
              type: MessageType.Success,
            })
          );
        },
        onError: (e: Error[] | unknown) => {
          const message = extractMeaningfulMessage(e, defaultMessage);
          dispatch(
            addMessage({
              message: String(message),
              type: MessageType.Error,
            })
          );
        },
      }
    );
  }

  const saveConfiguration = () => {
    if (selectedConfigType?.platformConfigurationInfo) {
      if (
        selectedConfigType.platformConfigurationInfo.configurationSection.find(
          (s) => s.group.length < 1
        )
      ) {
        dispatch(
          addMessage({
            message: t('pages.configTypeEditor.groupWarning'),
            type: MessageType.Warning,
          })
        );
      } else if (
        selectedConfigType.platformConfigurationInfo.configurationSection.find(
          (s) => s.group.find((g) => g.attribute.length < 1)
        )
      ) {
        dispatch(
          addMessage({
            message: t('pages.configTypeEditor.fieldWarning'),
            type: MessageType.Warning,
          })
        );
      } else {
        if (selectedConfigType?.platformConfigurationId) {
          if (!!recordData?.getConfigurations.length) {
            dispatch(
              addMessage({
                message: t('pages.configTypeEditor.typeChangesWarning'),
                type: MessageType.Warning,
              })
            );
          }
          updateConfigurationType();
        } else {
          addConfigurationType();
        }
      }
    } else {
      if (selectedConfigType?.platformConfigurationId) {
        updateConfigurationType();
      } else {
        addConfigurationType();
      }
    }
  };

  useEffect(() => {
    if (actionValue === 'message_continue' && !!selectedConfigType) {
      removeConfiguration();
      dispatch(clearActionValue());
    }
  }, [
    actionValue,
    dispatch,
    recordData?.getConfigurations.length,
    removeConfiguration,
    selectedConfigType,
    t,
  ]);

  const fetchConfigurationDetails = (rowdata: Row<ConfigurationResponse>) => {
    const configToSelect = configTypes.find(
      (ct) => ct.configurationName === rowdata.original.configurationName
    );
    setSelectedConfigType(deepCopy(configToSelect));
    setTempVersion(false);
  };

  const handleSlidePanel = () => {
    setDisplayPanel(!displayPanel);
    if (expandWidth === 7) {
      setTimeout(() => setExpandWidth(12), 600);
    } else if (expandWidth == 12) {
      setExpandWidth(7);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid
        item
        sm={displayPanel ? 0 : 5}
        xs={displayPanel ? 0 : 12}
        className={`${displayPanel ? expandGrid : ''}`}
        sx={{
          height: `${displayPanel ? '0 !important' : '100%'}`,
        }}
      >
        <Collapse
          in={!displayPanel}
          orientation="horizontal"
          sx={{
            overflow: 'hidden',
            '& .MuiCollapse-wrapper': {
              display: 'block',
            },
          }}
        >
          <Stack direction="row" sx={{ mb: '1em' }}>
            <AccountTreeIcon />
            <Typography variant="h1" sx={{ ml: '.4em' }}>
              {t('pages.configTypeEditor.configTypes')}
            </Typography>
            <Button
              id={'addBtn-configTypeEditor'}
              onClick={() => {
                const newConfig: ConfigurationResponse = {
                  ...configurationResponseDefault,
                  configurationDomain:
                    ConfigurationDomains.ReferenceSchema.toString(),
                  configurationModule: ConfigurationModules.Platform.toString(),
                  isOOTB: false,
                  isOOTBEditable: null,
                  platformConfigurationGroupId: editAccess.isFullAccess
                    ? undefined
                    : getGroupId(
                        configGroups?.getConfigurationGroups ?? [],
                        EXTENDED_ATTRIBUTES
                      ),
                  platformConfigurationInfo: deepCopy(configurationInfoDefault),
                };
                setSelectedConfigType(newConfig);
              }}
              sx={{ ml: 'auto' }}
              disabled={
                !editAccess.generalEdit ||
                !configGroups?.getConfigurationGroups.length ||
                isLoadingAnything
              }
              // we need the list of groups from the server first to look for the ID of the
              // extendedAttributes group, we can't add a new type if the configGroups is not ready
            >
              {t('pages.configTypeEditor.addType')}
            </Button>
          </Stack>
          <ConfigurationDomainsList
            value={selectedConfigDomainFilter}
            handleChange={(e) => {
              setSelectedConfigDomainFilter(e.target.value as string);
              if (tableInstance) tableInstance.setPageIndex(0);
            }}
          />
          {isLoading && <Loading />}
          {!isLoading && configTypes.length > 0 && (
            <DefaultDataTableNext<ConfigurationResponse>
              data={configTypes}
              columns={configHeaderColumns}
              customFilter={customFilter as unknown as string}
              pageSize={10}
              cursorHover={true}
              fetchCursorSelectedRow={fetchConfigurationDetails}
              customHeader={({ table }) => {
                // custom header is just used to get the table instance, we couldn't fit here the domain filter
                tableInstance = table;
                return <></>;
              }}
            />
          )}
        </Collapse>
      </Grid>
      <Grid
        item
        xs={12}
        sm={expandWidth}
        className={`${displayPanel ? collapseGrid : ''}`}
      >
        {selectedConfigType && (
          <>
            <Stack
              direction="row"
              alignItems="end"
              spacing={2}
              sx={{ marginLeft: '.15em', mb: '1em' }}
            >
              <ExpandButton
                displayPanel={displayPanel}
                handleSlidePanel={handleSlidePanel}
              />
              <Typography variant="h1">
                {selectedConfigType?.platformConfigurationId
                  ? t('pages.configTypeEditor.edit') +
                    ' ' +
                    selectedConfigType.configurationType
                  : t('pages.configTypeEditor.newConfiguration')}
              </Typography>
            </Stack>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                minHeight: '36.3em',
                p: '1.5em',
              }}
            >
              <ConfigurationDetails
                selectedConfigType={selectedConfigType}
                handleChange={(newConfig: ConfigurationResponse) => {
                  setSelectedConfigType(newConfig);
                }}
                editAccess={editAccess}
                configGroups={configGroups?.getConfigurationGroups ?? []}
              />
              {/** only show layouts section if type has been created (has id)
              and group is of type 'Extended Attribute' **/}
              {selectedConfigType?.platformConfigurationId &&
                editAccess.isExtendedAttributes && (
                  <Box sx={{ mb: '2em' }}>
                    <ConfigLayoutTable
                      selectedConfigType={selectedConfigType}
                    />
                  </Box>
                )}
              <Box>
                <Button
                  startIcon={<UnfoldMoreIcon />}
                  id={'expandAll-configTypeEditor'}
                  onClick={() => {
                    setExpandAll(true);
                  }}
                  type={'secondary'}
                  disabled={
                    !selectedConfigType.platformConfigurationInfo
                      ?.configurationSection.length
                  }
                  sx={{ marginRight: '10px' }}
                >
                  {t('pages.configTypeEditor.expandAll')}
                </Button>
                <Button
                  startIcon={<UnfoldLessIcon />}
                  id={'collapseAll-configTypeEditor'}
                  onClick={() => {
                    setExpandAll(false);
                  }}
                  type={'secondary'}
                  disabled={
                    !selectedConfigType.platformConfigurationInfo
                      ?.configurationSection.length
                  }
                >
                  {t('pages.configTypeEditor.collapseAll')}
                </Button>
                <Button
                  id={'addConfigSectionBtn-configTypeEditor'}
                  onClick={() => {
                    setNewSection(true);
                  }}
                  type={'secondary'}
                  disabled={!editAccess.controlExtendedGroup() || isNewSection}
                  sx={{ float: 'right' }}
                >
                  {t('pages.configTypeEditor.addConfigSection')}
                </Button>
              </Box>
              {isNewSection && (
                <ConfigSectionDetails
                  isNewSection={true}
                  setNewSection={setNewSection}
                  configSection={undefined}
                  handleChange={(newSection) => {
                    return updateConfigSection(newSection);
                  }}
                  handleDelete={() => {
                    deleteConfigSection(undefined);
                  }}
                  editAccess={editAccess}
                  hasRecords={!!recordData?.getConfigurations.length}
                />
              )}
              {/* for each section, have an accordion with the section name */}
              {selectedConfigType.platformConfigurationInfo?.configurationSection.map(
                (section) => {
                  return (
                    <ConfigSectionDetails
                      isNewSection={false}
                      setNewSection={setNewSection}
                      configSection={section}
                      handleChange={(newSection) => {
                        return updateConfigSection(newSection, section);
                      }}
                      handleDelete={() => {
                        deleteConfigSection(section);
                      }}
                      editAccess={editAccess}
                      isExpanded={expandAll}
                      key={section.configurationSectionName}
                      hasRecords={!!recordData?.getConfigurations.length}
                    />
                  );
                }
              )}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '20px',
                }}
              >
                {selectedConfigType?.platformConfigurationId && (
                  <Checkbox
                    id={'versionId'}
                    label={t('pages.configTypeEditor.isNewVersion')}
                    checked={tempVersion}
                    onChange={(event) => {
                      setTempVersion(event.target.checked);
                    }}
                    disabled={!editAccess.controlExtendedGroup()}
                  />
                )}
                <Button
                  id={'saveButton-configurationDetails'}
                  onClick={saveConfiguration}
                  sx={{ mr: '1em' }}
                  disabled={
                    saveDisabled ||
                    !editAccess.controlExtendedGroup() ||
                    isLoadingAnything
                  }
                  loading={loadingAddConfig || loadingUpdateConfig}
                >
                  {t('pages.configTypeEditor.save')}
                </Button>
                <Button
                  id={'cancelButton-configurationDetails'}
                  onClick={() => {
                    setSelectedConfigType(undefined);
                  }}
                  type={'secondary'}
                  disabled={
                    !editAccess.controlExtendedGroup() || isLoadingAnything
                  }
                >
                  {t('pages.configTypeEditor.cancel')}
                </Button>
                {selectedConfigType?.platformConfigurationId && (
                  <Button
                    disabled={
                      !editAccess?.controlWithOOTB(
                        !!selectedConfigType.isOOTB
                      ) ||
                      !editAccess.controlExtendedGroup() ||
                      isLoadingAnything
                    }
                    id={'deleteButton-configurationDetails'}
                    onClick={() => {
                      dispatch(
                        addMessage({
                          message: t('pages.configTypeEditor.confirmButtonMsg'),
                          type: MessageType.Info,
                          actionType: MessageActionType.Continue,
                        })
                      );
                    }}
                    type={'secondary'}
                    sx={{
                      position: 'absolute',
                      right: '1.5em',
                    }}
                    loading={loadingDeleteConfig}
                  >
                    {t('pages.configTypeEditor.delete')}
                  </Button>
                )}
              </Box>
            </Card>
          </>
        )}
        {!selectedConfigType && (
          <>
            <Typography variant="h1" sx={{ pt: '2em' }}>
              {t('pages.configTypeEditor.howToUse')}
            </Typography>
            <Typography variant="h2" sx={{ pt: '2em' }}>
              {t('pages.configTypeEditor.instructions')}
            </Typography>
            <Typography variant="h2" sx={{ pt: '2em', fontWeight: 'bold' }}>
              {t('pages.configTypeEditor.mvpWarning')}
            </Typography>
          </>
        )}
      </Grid>
    </Grid>
  );
}

export default ConfigTypeEditor;
