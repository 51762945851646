import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { ValidateBulkFileResponse } from 'types/roles';

interface UploadFileCommand {
  file: File;
  token: string;
}

export default function useValidatebulkfile() {
  return useMutation(async ({ file, token }: UploadFileCommand) => {
    const formData = new FormData();
    formData.append('file', file);
    const result = await axios.post(
      `${process.env.REACT_APP_ROLE}/command/users/bulk/validate`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    return result.data as ValidateBulkFileResponse[];
  });
}
