import {
  useState,
  useEffect,
  ChangeEvent,
  useMemo,
  useCallback,
  Fragment,
} from 'react';

import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { Grid, SelectChangeEvent } from '@mui/material';
import {
  Input,
  SelectTypeProps,
  Select,
} from '@revenue-solutions-inc/revxcoreui';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import { emailExp, phoneExp } from 'common/regexp';
import useMultiMaskInput, { MaskReturn } from 'hooks/useMultiMaskInput';
import { useTranslation } from 'react-i18next';
import { Contact } from 'types/tenants';

interface Props {
  isFullName?: true;
  contacts: Contact[];
  onChange: (contacts: Contact[]) => void;
  phoneRequired: boolean;
  isReadOnly: boolean;
  showThirdPartyType: boolean;
  showOrganization: boolean;
  allowFirstRowRemoval: boolean;
  onError: (hasError: boolean) => void;
}

function RsiContactManager({
  isFullName,
  contacts,
  onChange,
  showOrganization,
  showThirdPartyType,
  isReadOnly,
  allowFirstRowRemoval,
  phoneRequired,
  onError,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const requiredLengthPhone = 12;
  const [email, setEmail] = useState<(string | null)[]>(['']);
  const [phone, setPhone] = useState<(string | null)[]>(['']);

  const selectContactTypes: SelectTypeProps[] = useMemo(() => {
    const selectContacts = [
      { key: 'Primary', desc: 'Primary' },
      { key: 'Secondary', desc: 'Secondary' },
    ];

    if (showThirdPartyType)
      selectContacts.push({ key: 'ThirdParty', desc: '3rd Party Contact' });

    return selectContacts;
  }, [showThirdPartyType]);

  const emailMask = useMultiMaskInput(
    '',
    t('pages.tenantConfig.errors.email'),
    emailExp,
    email
  );

  const phoneMask = useMultiMaskInput(
    '999-999-9999',
    t('pages.tenantConfig.errors.phone'),
    phoneExp,
    phone
  );

  const handleListContactTypes = useCallback(
    (index: number) => {
      if (showThirdPartyType && index !== 0) {
        const listContactTypes = [
          ...selectContactTypes.filter(({ key }) => key !== 'Primary'),
        ];
        return listContactTypes;
      }

      return selectContactTypes;
    },
    [selectContactTypes, showThirdPartyType]
  );

  useEffect(() => {
    let isError = true;
    isError = !(
      emailMask.hasErrors === false &&
      phoneMask.hasErrors === false &&
      contacts.length > 0
    );

    if (phoneMask.inputValues.length === requiredLengthPhone) {
      isError = false;
    }
    onError(isError);
  }, [
    phoneMask,
    phoneMask.hasErrors,
    emailMask,
    emailMask.hasErrors,
    contacts,
    onError,
  ]);

  const handleHelperTextChange = (mask: MaskReturn, index: number) => {
    return mask.maskErrors[index];
  };

  useEffect(() => {
    const phoneValues: (string | null)[] = [];
    const emailValues: (string | null)[] = [];

    contacts.forEach((contact, index) => {
      phoneValues[index] = contact?.userPhone ?? null;
    });

    contacts.forEach((contact, index) => {
      emailValues[index] = contact?.userEmail ?? emailValues[0];
    });

    setPhone(phoneValues);
    setEmail(emailValues);
  }, [contacts]);

  const phoneError = (phoneNumber: string) => {
    let isError = false;
    if (phoneMask.inputValues.length === 0) {
      isError = true;
    }
    if (phoneNumber.length !== requiredLengthPhone) {
      isError = true;
    }
    if (phoneMask.hasErrors) {
      isError = true;
    }
    return isError;
  };

  return (
    <>
      {contacts.length > 0 &&
        contacts.map((currContact, index) => (
          <Fragment
            key={
              currContact.userId
                ? `${currContact?.userId}_container`
                : `${index}_container`
            }
          >
            <Grid container spacing={2}>
              <Grid item mb={2} data-testid="contactType">
                <Select
                  id="contactType"
                  value={currContact?.userContactType ?? ''}
                  options={handleListContactTypes(index)}
                  label={t('pages.tenantConfig.rsiContacts.type')}
                  required
                  onChange={(event: SelectChangeEvent<string | number>) => {
                    let newContacts = [...contacts];
                    const contactType = event.target.value as string;

                    if (contactType === 'Primary' && newContacts?.length > 0) {
                      newContacts = newContacts.map((contact) =>
                        contact.userContactType === 'Primary'
                          ? { ...contact, userContactType: 'Secondary' }
                          : contact
                      );
                    }
                    newContacts[index].userContactType = contactType;
                    onChange(newContacts);
                  }}
                  disabled={
                    index === 0 && !allowFirstRowRemoval && showThirdPartyType
                  }
                />
              </Grid>
              <Grid item pb={2} data-testid="contactName">
                <Input
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    const newContacts = [...contacts];
                    if (isFullName)
                      newContacts[index].userFullName = event.target.value;
                    else newContacts[index].userFirstName = event.target.value;
                    onChange(newContacts);
                  }}
                  id={'name'}
                  value={currContact?.userFullName ?? ''}
                  label={t('pages.tenantConfig.rsiContacts.name')}
                  required
                  disabled={isReadOnly}
                />
              </Grid>
              <Grid item pb={2} data-testid="contactEmail">
                <Input
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    emailMask.handleMaskChange(event, index);
                    const newContacts = [...contacts];
                    newContacts[index].userEmail = event.target.value;
                    onChange(newContacts);
                  }}
                  id={'email'}
                  value={currContact?.userEmail ?? ''}
                  label={t('pages.tenantConfig.rsiContacts.email')}
                  required
                  disabled={isReadOnly}
                  helperText={handleHelperTextChange(emailMask, index)}
                />
              </Grid>
              <Grid item pb={2} data-testid="contactPhone">
                <Input
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    phoneMask.handleMaskChange(event, index);
                    const newContacts = [...contacts];
                    newContacts[index].userPhone = event.target.value;
                    onChange(newContacts);
                  }}
                  id={'phone'}
                  value={currContact?.userPhone}
                  label={t('pages.tenantConfig.rsiContacts.phone')}
                  required={phoneRequired}
                  helperText={
                    phoneError(currContact?.userPhone ?? '')
                      ? handleHelperTextChange(phoneMask, index)
                      : ''
                  }
                  error={phoneError(currContact?.userPhone ?? '')}
                />
              </Grid>
              {showOrganization && (
                <Grid item pb={2} data-testid="contactOrg">
                  <Input
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      const newContacts = [...contacts];
                      newContacts[index].userOrganization = event.target.value;
                      onChange(newContacts);
                    }}
                    id={'organization'}
                    value={currContact?.userOrganization}
                    label={t('pages.tenantConfig.rsiContacts.organization')}
                    required
                  />
                </Grid>
              )}
              <Grid item mt={2}>
                {(allowFirstRowRemoval || index !== 0) && (
                  <Button
                    onClick={() => {
                      if (contacts.length > 0) {
                        const newContacts = contacts.filter(
                          (contact, position) => position !== index
                        );
                        onChange(newContacts);
                      }
                      emailMask.maskErrors.splice(index, 1);
                      phoneMask.maskErrors.splice(index, 1);
                    }}
                    aria-label="delete"
                    id="clearButton"
                    size="medium"
                    variant="outlined"
                    type="secondary"
                    data-testid="delete-button"
                    sx={{ ml: 1, mt: 0.5 }}
                  >
                    <ClearRoundedIcon
                      sx={{
                        fontSize: '15px',
                        color: 'linkBlue.main',
                        fontWeight: 'bold',
                      }}
                    />
                  </Button>
                )}
              </Grid>
            </Grid>
          </Fragment>
        ))}
    </>
  );
}

export default RsiContactManager;
