import { useEffect, useState } from 'react';

import { Grid } from '@mui/material';

import {
  DestinationObject,
  LogixGroupType,
  MessageType,
} from '@revenue-solutions-inc/revxcoreui';
import LogiX from 'components/LogiX';
import {
  CmGetLogix,
  LogiXgroupInput,
  useGetChannelLogixUiMutation,
} from 'generated/graphql';

import { useAppDispatch } from 'redux/hooks';
import { useTranslation } from 'react-i18next';

import { Channel } from 'types/channels';
import Loading from 'components/Loading';
import dataDestination from 'pages/admin/LogixTestHarness/destination.json';
import { UseFormGetValues } from 'react-hook-form';
import { addMessage } from 'redux/messageSlice';

interface Props {
  setLogixGroup: React.Dispatch<React.SetStateAction<LogiXgroupInput | null>>;
  LogixGroup: LogiXgroupInput | null;
  displayFields: boolean;
  uploadFileChannel: boolean;
  getValues: UseFormGetValues<Channel>;
}

function ChannelLogix({
  setLogixGroup,
  displayFields,
  uploadFileChannel,
  LogixGroup,
  getValues,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [getJson, setGetJson] = useState<boolean>(false);
  const [intialRender, setIntialRender] = useState<boolean>(false);
  const fileInput = getValues(`Channel.FileGroup.Files`);
  const fileGroup = getValues(`Channel.FileGroup`);

  const { mutate: mutateLogix, isLoading: logixIsLoading } =
    useGetChannelLogixUiMutation();
  const dispatch = useAppDispatch();

  const handleSelectedField = () => {};

  const handleGetLogixUpdate = () => {
    setGetJson(false);
  };

  useEffect(() => {
    if (
      intialRender === false &&
      displayFields === true &&
      uploadFileChannel === false
    ) {
      mutateLogix(
        {
          channel: {
            FileGroup: {
              FileGroupName: fileGroup.FileGroupName,
              FileGroupType: fileGroup.FileGroupType,
              FileType: fileGroup.FileType,
              FileProcessingType: fileGroup.FileProcessingType,
              DelimiterValue: fileGroup.DelimiterValue,
              Files: fileInput,
            },
            LogixGroup,
          } as unknown as CmGetLogix,
        },
        {
          onSuccess: (response) => {
            setLogixGroup(
              response.GetChannelLogixUI as unknown as LogiXgroupInput
            );
          },
          onError: () => {
            dispatch(
              addMessage({
                type: MessageType.Error,
                message: t('components.message.networkerror'),
              })
            );
          },
        }
      );
      setIntialRender(true);
    }
  }, [
    LogixGroup,
    dispatch,
    displayFields,
    fileGroup,
    fileInput,
    intialRender,
    mutateLogix,
    setLogixGroup,
    t,
    uploadFileChannel,
  ]);

  return logixIsLoading === false && LogixGroup !== null ? (
    <Grid container spacing={0} alignItems="center" justifyContent="center">
      <Grid item xs={12} md={6}>
        <LogiX
          data={LogixGroup as unknown as LogixGroupType}
          handleSelectedField={handleSelectedField}
          config={{
            domain: {
              label: t('pages.manageChannel.file'),
              canCreate: false,
            },
          }}
          showDestination={false}
          dataDestination={dataDestination as unknown as DestinationObject}
          cleanSelectedFields={false}
          getJson={getJson}
          handleGetLogixUpdate={handleGetLogixUpdate}
        />
      </Grid>
    </Grid>
  ) : (
    <Loading />
  );
}

export default ChannelLogix;
