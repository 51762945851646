import { Typography, Button } from '@mui/material';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import { useQueryClient } from '@tanstack/react-query';
import { formatWorkflowVariantDataForTable } from 'components/AssignedUnassignedWork/tableUtils';
import {
  useDeleteWorkflowVariantObjectMutation,
  WorkflowVariantObject,
} from 'generated/graphql';
import WorkflowIDCell from './WorkflowIDCell';

interface Props {
  data: Record<string, unknown>[];
}

const DeleteWorkflowCell = ({
  original: { workflow_Key },
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
any) => {
  const queryClient = useQueryClient();

  const { mutate } = useDeleteWorkflowVariantObjectMutation();

  return (
    <Button
      size="small"
      id={`delete-variant-option-${workflow_Key}`}
      onClick={() =>
        mutate(
          { deleteWorkflowVariantObjectId: workflow_Key },
          {
            onSuccess: () => {
              queryClient.refetchQueries(['GetWorkflows']);
            },
          }
        )
      }
    >
      Delete
    </Button>
  );
};

export const WorkflowTable = ({ data }: Props) => {
  const tableData = formatWorkflowVariantDataForTable(
    data as WorkflowVariantObject[]
  );

  return (
    <DefaultDataTableNext
      columns={[
        {
          accessorKey: 'DisplayId',
          header: () => <Typography>Work ID</Typography>,
          id: 'DisplayId',
          enableSorting: true,
          cell: ({ row }) => (
            <WorkflowIDCell
              workflowKey={row.original.Workflow_Key as string}
              variantId={row.original.Workflow_Variant_Id as string}
              displayId={row.original.DisplayId as string}
            />
          ),
        },
        {
          accessorKey: 'Workflow_Variant_Id',
          header: () => <Typography>Variant ID</Typography>,
          id: 'Workflow_Variant_Id',
          enableSorting: true,
        },
        {
          cell: ({ row }) => DeleteWorkflowCell(row),
          id: 'delete_item',
        },
      ]}
      data={tableData}
      tableName="Workflows"
    />
  );
};
