export enum Keys {
  ENTER = 'Enter',
}

export const handleIsChecked = (elements: string[], rowId: string) =>
  elements.includes(rowId);

export const handleElementsSelected = (
  elements: string[],
  isValid: boolean,
  rowId: string
): string[] => {
  if (isValid) {
    return elements.filter((cSystemPolicyId) => cSystemPolicyId !== rowId);
  } else {
    return [...elements, rowId];
  }
};
