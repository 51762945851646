import { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { Stack, Typography, ListItem, ListItemText } from '@mui/material';
import { useGetWorkflowByIdQuery } from 'generated/graphql';

export type NoteDisplay = {
  CreatedDate?: string;
  NotesMessage: string;
  NotesSubject: string;
  CreatedBy: string;
};

type NoteRowItemProps = {
  note: string;
  setSelectedNote: (note: NoteDisplay) => () => void;
};

const NoteRowItem = ({ note, setSelectedNote }: NoteRowItemProps) => {
  const [noteData, setNoteData] = useState<NoteDisplay>();
  const { data, isLoading } = useGetWorkflowByIdQuery({
    id: String(note),
  });

  useEffect(() => {
    if (data?.GetWorkflowById) {
      const noteParsed = JSON.parse(
        data?.GetWorkflowById?.[0]?.wF_Full_Object ?? ''
      );
      setNoteData({
        CreatedDate: noteParsed.CreatedDate,
        CreatedBy: noteParsed.CreatedBy,
        NotesSubject: noteParsed.NotesSubject,
        NotesMessage: noteParsed.NotesMessage,
      });
    }
  }, [data]);

  if (!data || isLoading) {
    return null;
  }

  return (
    <ListItem
      onClick={noteData ? setSelectedNote(noteData) : () => {}}
      sx={{
        position: 'relative',
        bgcolor: 'background.paper',
        mb: 1,
        border: (theme) => `1px solid ${theme.palette.grey[100]}`,
      }}
    >
      <ListItemText
        primary={
          <>
            <Stack direction="row" justifyContent="space-between">
              <Typography
                component="p"
                variant="h3"
                sx={{ padding: '4px', fontStyle: 'italic' }}
              >
                {noteData?.CreatedBy}
              </Typography>
              {Boolean(noteData?.CreatedDate) && (
                <Typography>
                  {format(new Date(noteData?.CreatedDate ?? ''), 'MMM d, yyyy')}
                </Typography>
              )}
            </Stack>
            <Typography component="p" variant="h3" sx={{ padding: '4px' }}>
              {noteData?.NotesSubject}
            </Typography>
          </>
        }
        secondary={
          <Typography
            sx={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              padding: '2px 4px',
            }}
            component="p"
          >
            {noteData?.NotesMessage}
          </Typography>
        }
      />
    </ListItem>
  );
};

export default NoteRowItem;
