import { useContext } from 'react';

import { Email, Phone } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import { Theme } from '@mui/system';
import EntityManagementContext from 'components/contexts/EntityManagement';
import { useTranslation } from 'react-i18next';
import {
  EntityForm,
  EntityAddress,
  EntityEmailAddress,
  EntityIdentifier,
  EntityName,
  EntityPhoneNumber,
} from 'types/entities';
import { formatIdentifier } from 'utils/formatIdentifier';

interface Props {
  entityData: EntityForm;
}

const basicDisplay = {
  display: 'flex',
  justifyContent: 'space-between',
};

const horizontalDisplay = {
  ...basicDisplay,
  flexDirection: 'row',
};

const verticalDisplay = {
  ...basicDisplay,
  flexDirection: 'column',
};

const optionalsDisplay = {
  ...basicDisplay,
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
};

const optionalsText = {
  fontSize: '1.2em',
  paddingLeft: '3px',
};

const individualSection = {
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '15px',
};

const borderElement = {
  borderBottom: '2px',
  borderBottomColor: 'wheat',
  borderBottomStyle: 'solid',
  marginBottom: '5px',
};

const typeBackground = {
  minWidth: '80px',
  maxWidth: '110px',
  backgroundColor: 'papayawhip',
  padding: '4px',
};

const confidentialBackground = {
  ...typeBackground,
  backgroundColor: 'wheat',
};

const mailReturnedSize = {
  ...typeBackground,
  maxWidth: '130px',
  backgroundColor: 'wheat',
};

const iconColor = (theme: Theme) => {
  return {
    fill: theme.palette.primary.main,
  };
};

function EntityDetail({ entityData }: Props): JSX.Element {
  const ctx = useContext(EntityManagementContext);
  const { t } = useTranslation();

  const getPrimaryName = (): EntityName | undefined => {
    return entityData?.names?.find((name) => name.isPrimary === 'true');
  };

  const getPrimaryIdentifier = (): EntityIdentifier | undefined => {
    return entityData?.identifiers?.find((id) => id.isPrimary === 'true');
  };

  const getPrimaryEmail = (): EntityEmailAddress | undefined => {
    return entityData?.emailAddresses?.find(
      (email) => email.isPrimary === 'true'
    );
  };

  const getPrimaryAddress = (): EntityAddress | undefined => {
    return entityData?.addresses?.find(
      (address) => address.isPrimary === 'true'
    );
  };

  const getPrimaryPhone = (): EntityPhoneNumber | undefined => {
    return entityData?.phoneNumbers?.find(
      (phone) => phone.isPrimary === 'true'
    );
  };

  const getFullName = (): string => {
    const primaryName = getPrimaryName();
    return primaryName !== undefined && primaryName.value
      ? primaryName.value
      : '';
  };

  const getEntityType = (): string => {
    const primaryName = getPrimaryName();
    return primaryName !== undefined ? primaryName.NameType : '';
  };

  const getConfidentiality = (): JSX.Element => {
    const primaryName = getPrimaryName();
    if (primaryName && primaryName.isConfidential === 'true')
      return (
        <Typography sx={confidentialBackground} variant="h3">
          {t('pages.entitySummary.details.isConfidential')}
        </Typography>
      );
    return <></>;
  };

  const getIdentifier = (): string => {
    const primaryId = getPrimaryIdentifier();
    return primaryId !== undefined
      ? formatIdentifier(primaryId.value, ctx.idFormats, primaryId.IdType)
      : '';
  };

  const getIdType = (): string => {
    const primaryId = getPrimaryIdentifier();
    return primaryId !== undefined ? primaryId.IdType : '';
  };

  const getEmail = (): string => {
    const primaryEmail = getPrimaryEmail();
    return primaryEmail !== undefined ? primaryEmail.value : '';
  };

  const getAddress = (): string => {
    const primaryAddress = getPrimaryAddress();
    if (primaryAddress) {
      return primaryAddress.addressLine1
        .concat(' ')
        .concat(primaryAddress.addressLine2 ?? '');
    }
    return '';
  };

  const getMoreAddressInfo = (): string => {
    const primaryAddress = getPrimaryAddress();
    if (primaryAddress) {
      return primaryAddress.cityId
        .concat(' ')
        .concat(primaryAddress.stateProvinceId)
        .concat(' ')
        .concat(primaryAddress.postalCode);
    }
    return '';
  };

  const getHasMailReturned = (): JSX.Element => {
    const primaryAddress = getPrimaryAddress();
    if (primaryAddress && primaryAddress.hasMailReturned === 'true')
      return (
        <Typography noWrap sx={mailReturnedSize} variant="h3">
          {t('pages.entitySummary.details.hasMailReturned')}
        </Typography>
      );
    return <></>;
  };

  const getPhoneNumber = (): string => {
    const primaryPhone = getPrimaryPhone();
    return primaryPhone !== undefined ? primaryPhone.value : '';
  };

  return (
    <Grid container spacing={1}>
      {getPrimaryName() && (
        <Grid sx={individualSection} item xs={12}>
          <Grid sx={{ ...horizontalDisplay, width: 'max-content' }}>
            <Typography
              noWrap
              sx={{ fontSize: '1.3em', fontWeight: 'bold' }}
              variant="h2"
            >
              {getFullName()}
            </Typography>
            <Typography align="center" sx={typeBackground} variant="h3">
              {getEntityType()}
            </Typography>
          </Grid>
          {getConfidentiality()}
        </Grid>
      )}
      <Grid sx={horizontalDisplay} container item xs={12}>
        {getPrimaryAddress() && (
          <Grid sx={individualSection} item xs={6} md={6}>
            <Typography sx={borderElement} variant="h2">
              {t('pages.entitySummary.details.address')}
            </Typography>
            <Grid sx={verticalDisplay}>
              <Typography noWrap sx={{ fontSize: '1.2em' }} variant="h2">
                {getAddress()}
              </Typography>
              <Typography noWrap sx={{ fontSize: '1.2em' }} variant="h2">
                {getMoreAddressInfo()}
              </Typography>
              {getHasMailReturned()}
            </Grid>
          </Grid>
        )}
        {getPrimaryIdentifier() && (
          <Grid sx={individualSection} item xs={5} md={5}>
            <Typography sx={borderElement} variant="h2">
              {t('pages.entitySummary.details.identifier')}
            </Typography>
            <Grid sx={horizontalDisplay}>
              <Typography noWrap sx={{ fontSize: '1.2em' }} variant="h2">
                {getIdentifier()}
              </Typography>
              <Typography
                align="center"
                noWrap
                sx={typeBackground}
                variant="h3"
              >
                {getIdType()}
              </Typography>
            </Grid>
          </Grid>
        )}
        {getPrimaryEmail() && (
          <Grid sx={individualSection} item xs={6} md={6}>
            <Typography sx={borderElement} variant="h2">
              {t('pages.entitySummary.details.email')}
            </Typography>
            <Grid sx={optionalsDisplay}>
              <Email sx={iconColor} fontSize="small" />
              <Typography noWrap sx={optionalsText} variant="h2">
                {getEmail()}
              </Typography>
            </Grid>
          </Grid>
        )}
        {getPrimaryPhone() && (
          <Grid sx={individualSection} item xs={5} md={5}>
            <Typography sx={borderElement} variant="h2">
              {t('pages.entitySummary.details.phone')}
            </Typography>
            <Grid sx={optionalsDisplay}>
              <Phone sx={iconColor} fontSize="small" />
              <Typography noWrap sx={optionalsText} variant="h2">
                {getPhoneNumber()}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default EntityDetail;
