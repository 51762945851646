import { AssetForm, AssetResponse, StepsConfig } from 'types/assets';

export const getAsset: AssetForm = {
  other: [
    {
      assetType: '',
      assetCreationSource: 'Online',
      commenceDate: null,
      ceaseDate: null,
    },
  ],
  names: [
    {
      nameType: '',
      value: '',
      isPrimary: 'true',
      commenceDate: null,
      ceaseDate: null,
    },
  ],
  identifiers: [
    {
      IdType: '',
      value: '',
      isPrimary: 'true',
      commenceDate: null,
      ceaseDate: null,
    },
  ],
  addresses: [
    {
      addressType: '',
      attention: '',
      cityId: '',
      countyId: '',
      countryId: '',
      postalCode: '',
      stateProvinceId: '',
      addressLine1: '',
      addressLine2: '',
      isPrimary: 'true',
      isConfidential: 'true',
      hasMailReturned: 'true',
      commenceDate: null,
      ceaseDate: null,
    },
  ],
};

export const getAssetResponse: AssetResponse = {
  id: '',
  status: 'Ok',
};

export const defaultSectionsConfig: StepsConfig[] = [
  { step: 0, name: 'other', isRequired: true, showOptional: false },
  { step: 1, name: 'names', isRequired: true, showOptional: false },
  { step: 2, name: 'identifiers', isRequired: true, showOptional: false },
  { step: 3, name: 'addresses', isRequired: false, showOptional: false },
];
