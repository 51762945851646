export const textNotEmptyExp = /^(?!\s*$).+/;

export const emailExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

export const phoneExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

export const ssnValExp = /^(?!666|000|9\d{2})\d{3}(?!00)\d{2}(?!0{4})\d{4}$/;

export const feinValExp = /^\d{2}\d{7}$/;

export const itinValExp =
  /^9\d{2}((5[0-9]|6[0-5])|(8[3-8])|(9[0-2])|(9[4-9]))\d{4}$/;

export const vinValExp = /^\\b[(A-H|J-N|P|R-Z|0-9)]{17}\\b$/;

export const dateExp = /(\d{4})\-(\d{2})\-(\d{2})/;

export const urlExp =
  /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;

export const guidExp =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

export const intExp = /^\d+$/;
