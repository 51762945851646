export const EmptyTemplateRecord = {
  templateId: '',
  templateDisplayId: '',
  systemFieldDictionaryId: 0,
  templateOpenXml: '',
  contentType: '',
  status: '',
  version: null,
  createdBy: '',
  createdDate: '',
  updatedBy: '',
  updatedDate: '',
  description: '',
  reusableContents: [],
};
export const EmptyReusableContentRecord = {
  reusableContentId: '',
  description: '',
  reusableContentDisplayId: '',
  status: '',
  version: 0,
  createdBy: '',
  createdDate: '',
  updatedBy: '',
  updatedDate: '',
  contentOpenXml: '',
};

export const EmptyCorrespondenceRecord = {
  correspondenceId: 1,
  correspondenceTypeDisplayId: '',
  name: '',
  roleIds: 0,
  hasActiveTemplate: 'false',
  canDelete: false,
  latestVersion: 1,
  latestStatus: '',
  createdBy: '',
  createdDate: '',
  updatedBy: '',
  updatedDate: '',
  correspondenceTypeCategoryName: '',
};
