import { ConfigItem } from './Sidebar';
const commonPropertyAndRevenue: Array<ConfigItem> = [
  {
    id: 'entity',
    label: 'pages.navigation.entityManagement',
    items: [
      {
        id: 'createEntity',
        label: 'pages.createEntity.title',
        route: '/entity/create',
      },
      {
        id: 'createAsset',
        label: 'pages.createAsset.title',
        route: '/asset/create',
      },
    ],
  },
  {
    id: 'batches',
    label: 'pages.navigation.batches',
    items: [
      {
        id: 'batches',
        label: 'pages.manageBatches.title',
        route: '/managebatches',
      },
      {
        id: 'refundBatches',
        label: 'pages.refundBatch.title',
        route: '/refundbatches',
      },
    ],
  },
  {
    id: 'deposits',
    label: 'pages.navigation.deposits',
    items: [
      {
        id: 'deposits',
        label: 'pages.manageDeposits.title',
        route: '/managedeposits',
      },
    ],
  },
  {
    id: 'workflows',
    label: 'pages.workflow.title',
    route: '/workflows',
  },
  {
    id: 'formEntry',
    label: 'pages.navigation.forms',
    items: [
      {
        id: 'formEntry',
        label: 'pages.forms.naveTitle',
        route: '/forms',
      },
      {
        id: 'viewForms',
        label: 'pages.forms.navTitleViewForms',
        route: '/viewForms',
      },
    ],
  },
];
const commonAdminMenuConfig: Array<ConfigItem> = [
  {
    id: 'channel',
    label: 'pages.navigation.channel',
    items: [
      {
        id: 'managechannels',
        label: 'pages.manageChannel.title',
        route: '/managechannels',
      },
    ],
  },
  {
    id: 'configuration',
    label: 'pages.navigation.configuration',
    items: [
      {
        id: 'configTypeEditor',
        label: 'pages.configTypeEditor.title',
        route: `/configTypeEditor`,
      },
      {
        id: 'configRecordEditor',
        label: 'pages.configRecordEditor.title',
        route: `/configRecordEditor`,
      },
      {
        id: 'configGroupEditor',
        label: 'pages.configGroupEditor.title',
        route: '/configGroupEditor',
      },
      {
        id: 'generateIdConfig',
        label: 'pages.generateIdConfig.title',
        route: '/generateIdConfig',
      },
      {
        id: 'layoutEditor',
        label: 'pages.navigation.layoutBuilder',
        route: '/layoutbuilder',
      },
    ],
  },
  {
    id: 'correspondence',
    label: 'pages.navigation.correspondence',
    items: [
      {
        id: 'correspondenceTemplates',
        label: 'pages.templates.title',
        route: '/correspondenceTypes',
      },
      {
        id: 'reusableContent',
        label: 'pages.reusableContent.title',
        route: '/reusableContents',
      },
      {
        id: 'activeDictionary',
        label: 'pages.manageReusableContent.buttons.activeDictionary',
        route: '/activeDictionary',
      },
    ],
  },
  {
    id: 'dataMapper',
    label: 'pages.navigation.dataMapper',
    items: [
      {
        id: 'dataMappertestharness',
        label: 'pages.dataMapperTestHarness.navTitle',
        route: '/datamapper',
      },
      {
        id: 'sinkObjects',
        label: 'pages.sinkObjects.navTitle',
        route: '/sinkobjects',
      },
    ],
  },
  {
    id: 'forms',
    label: 'pages.navigation.docs',
    items: [
      {
        id: 'manageForms',
        label: 'pages.forms.docsDesign',
        route: '/manageforms',
      },
    ],
  },
  {
    id: 'reports',
    label: 'Reports',
    items: [
      {
        id: 'reportsManagement',
        label: 'pages.reports.reportsManager',
        route: '/Reports',
      },
      {
        id: 'dashboardsManager',
        label: 'pages.dashboards.title',
        route: '/dashboards',
      },
    ],
  },
  {
    id: 'Scheduled Tasks',
    label: 'pages.navigation.scheduledTasks',
    items: [
      {
        id: 'manageScheduledTasks',
        label: 'pages.manageChannel.titleTask',
        route: '/managescheduledtasks',
      },
    ],
  },
];
const rolesAndPoliciesAdminMenuConfig: Array<ConfigItem> = [
  {
    id: 'RolesAndPolicies',
    label: 'pages.navigation.rolesAndPolicies',
    items: [
      {
        id: 'manageBusinessPolicies',
        label: 'pages.businessPolicies.navTitle',
        route: '/manageBusinessPolicies',
      },
      {
        id: 'manageRoles',
        label: 'pages.roles.navTitle',
        route: '/manageRoles',
      },
      {
        id: 'addUser',
        label: 'pages.addUser.navTitle',
        route: '/addUser',
      },
      {
        id: 'bulkUserUpload',
        label: 'pages.bulkUserUpload.navTitle',
        route: '/bulkUserUpload',
      },
      {
        id: 'manageUserRoles',
        label: 'pages.userRolesList.navTitle',
        route: '/manageuserroles',
      },
    ],
  },
];
const rsiAdminMenuConfig: Array<ConfigItem> = [
  {
    id: 'logix',
    label: 'pages.navigation.logix',
    items: [
      {
        id: 'docs',
        label: 'pages.manageFormsProcessing.navTitle',
        route: '/manageforms',
      },
      {
        id: 'logixTest',
        label: 'pages.logixTestHarness.navTitle',
        route: '/logixtestharness',
      },
    ],
  },
  {
    id: 'tenants',
    label: 'pages.navigation.tenants',
    items: [
      {
        id: 'customerList',
        label: 'pages.customerList.navTitle',
        route: '/customerlist',
      },
      {
        id: 'tenantList',
        label: 'pages.tenantList.navTitle',
        route: '/tenantList',
      },
    ],
  },
  {
    id: 'managePolicyGroups',
    label: 'pages.navigation.policyGroups',
    items: [
      {
        id: 'managePolicyGroups',
        label: 'pages.policyGroup.navTitle',
        route: '/managePolicyGroups',
      },
    ],
  },
  {
    id: 'workflows',
    label: 'pages.navigation.workflows',
    items: [
      {
        id: 'workflowsLink',
        label: 'pages.workflow.title',
        route: '/workflows',
      },
      {
        id: 'approvalLink',
        label: 'pages.approvals.title',
        route: '/viewapprovalspage',
      },
      {
        id: 'workflowEditor',
        label: 'pages.workflowEditor.title',
        route: '/workflowEditor',
      },
    ],
  },
  {
    id: 'roles',
    label: 'pages.navigation.roles',
    items: [
      {
        id: 'manageRoles',
        label: 'pages.roles.navTitle',
        route: '/manageRoles',
      },
      {
        id: 'manageBusinessPolicies',
        label: 'pages.businessPolicies.navTitle',
        route: '/manageBusinessPolicies',
      },
    ],
  },
  {
    id: 'users',
    label: 'pages.navigation.users',
    items: [
      {
        id: 'bulkUserUpload',
        label: 'pages.bulkUserUpload.navTitle',
        route: '/bulkUserUpload',
      },
      {
        id: 'manageUserRoles',
        label: 'pages.userRolesList.navTitle',
        route: '/manageuserroles',
      },
      {
        id: 'addUser',
        label: 'pages.addUser.navTitle',
        route: '/addUser',
      },
    ],
  },
];

const adminMenuConfig: Array<ConfigItem> = [
  ...rsiAdminMenuConfig,
  ...commonAdminMenuConfig,
];

type Item = {
  [key: string]: string;
};

const removeItems = (
  list: ConfigItem[],
  itemsToRemove: Item[]
): ConfigItem[] => {
  return list.map((obj: ConfigItem) => ({
    ...obj,
    items: obj.items?.filter((item: ConfigItem) => {
      for (const itemToRemove of itemsToRemove) {
        const [key, value] = Object.entries(itemToRemove)[0];

        if (item.id === value && key) {
          return false;
        }
      }
      return true;
    }),
  }));
};

const itemsToRemove: Item[] = [
  { configuration: 'layoutEditor' },
  { dataMapper: 'dataMappertestharness' },
];

const commonAdminToolsMenuConfig: Array<ConfigItem> = removeItems(
  commonAdminMenuConfig,
  itemsToRemove
);

const adminToolsMenuConfig: Array<ConfigItem> = [
  ...rolesAndPoliciesAdminMenuConfig,
  ...commonAdminToolsMenuConfig,
];

const propertyMenuConfig: Array<ConfigItem> = [...commonPropertyAndRevenue];

const revenueMenuConfig: Array<ConfigItem> = [...commonPropertyAndRevenue];

export {
  adminToolsMenuConfig,
  adminMenuConfig,
  revenueMenuConfig,
  propertyMenuConfig as default,
};
