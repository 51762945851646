import { useRef, useState } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import Checkbox from '@revenue-solutions-inc/revxcoreui/material/controls/Checkbox';
import DateCell from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/TableCells/DateCell';
import DataTableNextDateRangeFilter from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DataTableNextDateRangeFilter';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import { ColumnDef } from '@tanstack/react-table';
import SideScrollBox from 'components/SideScrollBox';
import { useTranslation } from 'react-i18next';
import Loading from 'components/Loading';
import {
  AvailableBatchResponse,
  useGetAvailableBatchesQuery,
} from 'generated/graphql';
import { MessageType, Select } from '@revenue-solutions-inc/revxcoreui';
import extractMeaningfulMessage from 'utils/errorMessage';
import { addMessage } from 'redux/messageSlice';
import { useAppDispatch } from 'redux/hooks';
import { toDate } from 'utils/date-util';
import { CreateDepositFlexible } from 'pages/CreateDeposit/types/CreateDepositFlexible';

export type DateType = Date | null | undefined;

export type Batch = {
  adjustmentAmount?: number | null;
  batchStatus?: string | null;
  batchType?: string | null;
  branchLocationId?: string | null;
  createdDate?: string | null;
  settlementDate?: string | null;
  dateClosed?: string | null;
  defaultTransactionEffectiveDate?: string | null;
  depositId?: number | null;
  depositLabel?: string | null;
  externalId?: string | null;
  fileId?: string | null;
  id: number | null;
  label: string | null;
  paidDate?: string | null;
  recordCount?: number | null;
  source?: string | null;
};

export type BatchTypeOption = {
  id: number;
  name: string;
};

type AvailableBatchResponseExtended = AvailableBatchResponse & {
  label?: string | null;
  id?: string | null;
};

interface Props {
  selectedBatches: AvailableBatchResponseExtended[];
  setSelectedBatches: (batches: AvailableBatchResponse[]) => void;
  deposit: CreateDepositFlexible;
  setDeposit: (deposit: CreateDepositFlexible) => void;
}

function DepositFindBatches({
  selectedBatches,
  setSelectedBatches,
  deposit,
  setDeposit,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [beginDate, setBeginDate] = useState<Date | null>();
  const [endDate, setEndDate] = useState<Date | null>();
  const dispatch = useAppDispatch();
  const [batchTypeOptions, setBatchTypeOptions] = useState<BatchTypeOption[]>(
    []
  );

  const { isFetching, data } = useGetAvailableBatchesQuery(
    {},
    {
      onSuccess: (item) => {
        const aBatchTypeOptions: BatchTypeOption[] = [];
        const batchTypes: string[] = item.GetAvailableBatches.map(
          (b) => b.paymentBatchType
        ).filter((value, index, self) => self.indexOf(value) === index);

        for (let i = 0; i < batchTypes.length; i++) {
          const batchTypeOption = {
            id: i + 1,
            name: batchTypes[i],
          };
          aBatchTypeOptions.push(batchTypeOption);
        }
        setBatchTypeOptions(aBatchTypeOptions);
      },
      onError: (e) => {
        const message = extractMeaningfulMessage(
          e,
          t('pages.manageBatches.refundBatchDetail.approveError')
        );
        dispatch(
          addMessage({
            type: MessageType.Error,
            message: message,
          })
        );
      },
    }
  );
  const dropdownFocus = useRef<HTMLSelectElement>(null);

  const [selectedBatchTypeId, setSelectedBatchTypeId] = useState<string>('');

  function lookupByDateRange(
    sDateToCheck: string,
    pBeginDate: DateType,
    pEndDate: DateType
  ) {
    let foundDate = false;
    if (sDateToCheck) {
      const dateToCheck = toDate(sDateToCheck);
      if (dateToCheck && pBeginDate && pEndDate) {
        foundDate = dateToCheck >= pBeginDate && dateToCheck <= pEndDate;
      } else if (dateToCheck && pBeginDate && !pEndDate) {
        foundDate = dateToCheck >= pBeginDate;
      } else if (dateToCheck && pEndDate && !pBeginDate) {
        foundDate = dateToCheck <= pEndDate;
      }
    }
    return foundDate;
  }

  const filterByDateRange = (
    batches: AvailableBatchResponse[] | undefined,
    pBeginDate: DateType,
    pEndDate: DateType
  ) => {
    let filtered: AvailableBatchResponse[] = [];

    if (batches) {
      filtered = batches.filter((item) => {
        const foundCreatedDate = lookupByDateRange(
          item.createdDate,
          pBeginDate,
          pEndDate
        );
        const foundSettlementDate = lookupByDateRange(
          item.settlementDate ?? '',
          pBeginDate,
          pEndDate
        );

        return foundCreatedDate || foundSettlementDate;
      });
    }
    return filtered;
  };

  const filteredData: AvailableBatchResponse[] | undefined =
    data?.GetAvailableBatches.filter((item) => {
      const selectedBatchTypeName =
        selectedBatchTypeId && batchTypeOptions
          ? batchTypeOptions[Number(selectedBatchTypeId) - 1].name
          : null;

      return (
        !selectedBatchTypeName ||
        (item.paymentBatchType &&
          item.paymentBatchType === selectedBatchTypeName)
      );
    });

  const showData = (): AvailableBatchResponse[] => {
    let aFilteredData: AvailableBatchResponse[];
    if (filteredData) {
      aFilteredData =
        beginDate || endDate
          ? filterByDateRange(filteredData, beginDate, endDate)
          : filteredData;
    } else {
      aFilteredData = [];
    }
    return aFilteredData.map(
      (item) =>
        ({
          status: item.status,
          paymentBatchType: item.paymentBatchType,
          createdDate: item.createdDate.toString(),
          identifier: item.identifier,
          paymentAmount: item.paymentAmount,
          id: item.id,
          settlementDate: item.settlementDate,
        } as unknown as AvailableBatchResponse)
    );
  };

  const Columns: ColumnDef<AvailableBatchResponse>[] = [
    {
      header: () => {
        return (
          <Checkbox
            id={'selectAllCheck'}
            checked={
              beginDate || endDate
                ? selectedBatches.length ===
                    filterByDateRange(filteredData, beginDate, endDate)
                      .length &&
                  filterByDateRange(filteredData, beginDate, endDate).length > 0
                : selectedBatches.length === filteredData?.length &&
                  filteredData?.length > 0
            }
            label={''}
            onChange={(event) => {
              const newBatches: AvailableBatchResponse[] = [];
              const newBatchIds: string[] = [];
              if (event.target.checked) {
                let batches = filteredData;
                if (beginDate || endDate) {
                  batches = filterByDateRange(
                    filteredData,
                    beginDate,
                    endDate
                  ) as unknown as AvailableBatchResponse[];
                }
                batches?.forEach((batch) => {
                  newBatches.push(batch);
                  if (batch.id) {
                    newBatchIds.push(`${batch.id}`);
                  }
                });
              } else {
                newBatches.splice(0, newBatches.length);
                newBatchIds.splice(0, newBatchIds.length);
              }
              setSelectedBatches(newBatches);
              setDeposit({
                ...deposit,
                paymentBatchIds: newBatchIds,
              });
            }}
            sx={{ ml: 1 }}
          />
        );
      },
      id: 'id',
      enableSorting: false,
      cell: ({ row }) => {
        return (
          <Checkbox
            id={'batchChk'}
            checked={
              selectedBatches.findIndex((p) => p.id === `${row.original.id}`) >
              -1
            }
            label={''}
            onChange={(event) => {
              let newBatches = [...selectedBatches];
              let newBatchIds: string[] = [];

              if (
                deposit?.paymentBatchIds &&
                deposit.paymentBatchIds?.length > 0
              )
                newBatchIds = [...deposit.paymentBatchIds];

              if (event.target.checked) {
                newBatches.push(row.original);
                if (row.original.id) {
                  newBatchIds.push(row.original.id);
                }
              } else {
                newBatches = newBatches.filter((p) => p.id !== row.original.id);
                newBatchIds = newBatchIds.filter((p) => p !== row.original.id);
              }
              setSelectedBatches(newBatches);
              setDeposit({
                ...deposit,
                paymentBatchIds: newBatchIds,
              });
            }}
            sx={{ ml: 1 }}
          />
        );
      },
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.manageBatches.status')} />
      ),
      accessorKey: 'status',
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.manageBatches.type')} />
      ),
      accessorKey: 'paymentBatchType',
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.manageBatches.name')} />
      ),
      accessorKey: 'identifier',
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.manageBatches.createdDate')} />
      ),
      accessorKey: 'createdDate',
      cell: ({ getValue }) => {
        return <DateCell dateString={getValue() as string} />;
      },
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.manageBatches.paidDate')} />
      ),
      accessorKey: 'settlementDate',
      cell: ({ getValue }) => {
        return <DateCell dateString={getValue() as string} />;
      },
    },
  ];

  const selectBatchType = (value: string | number) => {
    setSelectedBatchTypeId(String(value));
  };

  return (
    <>
      {isFetching && <Loading />}
      {filteredData && data && (
        <Grid container>
          <Grid
            item
            xs={4}
            pr={2}
            sx={{
              flexGrow: '1',
              maxWidth: '100%',
              mb: 1,
              maxHeight: '700px',
            }}
          >
            <Typography variant="body1" sx={{ mb: 1 }}>
              {t('pages.createDeposit.selectBatchesBodyText1')}
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              {t('pages.createDeposit.selectBatchesBodyText2')}
            </Typography>
            <Grid item xs={10} sx={{ height: '80%' }}>
              <>
                <SideScrollBox
                  title={t('pages.createDeposit.selectedBatches')}
                  options={selectedBatches?.map((batch) => {
                    return {
                      key: batch.id ?? '',
                      name: batch.identifier ?? '',
                    };
                  })}
                  id="batchChkBox-createDeposit"
                  scrollBoxValues={selectedBatches as unknown as string[]}
                  onChangeState={setSelectedBatches}
                  width={'100%'}
                />
              </>
            </Grid>
          </Grid>
          <Grid item xs={8} sx={{ flexGrow: '1', maxWidth: '100%' }}>
            <Typography variant="h2" mb={2}>
              {t('pages.createDeposit.availableBatches')}
            </Typography>
            <DefaultDataTableNext
              enableGlobalFilter={false}
              columns={Columns as ColumnDef<Record<string, unknown>, unknown>[]}
              data={showData()}
              customHeader={
                <Box sx={{ display: 'flex', alignItems: 'end' }}>
                  <Grid item mx={2} sx={{ mt: 1 }}>
                    <Box>
                      <Select
                        id="datatable-selectfilter-type"
                        data-testid="datatable-selectfilter-type"
                        label={t('pages.manageBatches.type')}
                        value={selectedBatchTypeId}
                        options={batchTypeOptions.map(({ id, name }) => {
                          return { key: `${id}`, desc: name };
                        })}
                        onChange={(e) => selectBatchType(e.target.value)}
                        inputProps={{
                          ref: dropdownFocus,
                        }}
                      />
                    </Box>
                  </Grid>
                  <DataTableNextDateRangeFilter
                    data-testid="datatable-date-range"
                    beginDate={beginDate ? beginDate : null}
                    endDate={endDate ? endDate : null}
                    setBeginDate={setBeginDate}
                    setEndDate={setEndDate}
                  />
                </Box>
              }
              globalFilterLabel={t('pages.manageBatches.filterBatches')}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default DepositFindBatches;
