import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { useAppDispatch } from 'redux/hooks';
import DataCard from 'components/DataCard';
import InfoIcon from '@mui/icons-material/Info';
import { setHeader } from 'redux/contentSlice';
import { DataDisplay } from '@revenue-solutions-inc/revxcoreui';
import { getDate } from 'common/helpers';
import { PageHistory } from 'pages/PaymentDetails/PaymentDetails';
import {
  BillDetailsResponse,
  useGetBillDetailsByIdQuery,
} from 'generated/graphql';

function BillDetails(): JSX.Element {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { id } = useParams() as { id: string };
  const location = useLocation();
  const [pageHistory, setPageHistory] = useState<PageHistory>();
  const [bill, setBill] = useState<BillDetailsResponse>();

  useGetBillDetailsByIdQuery(
    {
      Id: id,
    },
    {
      onSuccess: (response) => {
        setBill(response?.GetBillDetailsById);
      },
    }
  );

  useEffect(() => {
    if (
      location.state &&
      location.state.prevPageName &&
      location.state.prevPath
    ) {
      setPageHistory({
        prevPageName: location.state.prevPageName,
        prevPath: location.state.prevPath,
      });
    }
  }, [location.state]);

  useEffect(() => {
    dispatch(
      setHeader({
        previousPage: pageHistory?.prevPageName,
        route: pageHistory?.prevPath.substring(1),
        pageTitle: t('pages.bills.billDetails'),
      })
    );
  }, [dispatch, pageHistory?.prevPageName, pageHistory?.prevPath, t]);

  const billDetailsChildren = (
    <>
      <Grid container sx={{ display: 'inline-flex' }}>
        <Grid item xs={4} mt={1}>
          <DataDisplay
            id="billType"
            label={t('pages.bills.billType')}
            data={bill && bill.billType ? bill.billType : '-'}
          />
        </Grid>
        <Grid item xs={4} mt={1}>
          <DataDisplay
            id="invoiceNumber"
            label={t('pages.bills.invoiceNumber')}
            data={
              bill && bill.invoiceNumber ? bill.invoiceNumber?.toString() : '-'
            }
          />
        </Grid>
        <Grid item xs={4} mt={1}>
          <DataDisplay
            id="amount"
            label={t('pages.bills.amount')}
            dataFormat="currency"
            data={bill ? `$${bill?.amount?.toFixed(2).toString()}` : ''}
          />
        </Grid>
        <Grid item xs={4} mt={1}>
          <DataDisplay
            id="thruDate"
            label={t('pages.bills.PIThruDate')}
            data={
              bill && bill?.pnIThroughDate ? getDate(bill.pnIThroughDate) : '-'
            }
          />
        </Grid>
        <Grid item xs={4} mt={1}>
          <DataDisplay
            id="issueDate"
            label={t('pages.bills.issueDate')}
            data={bill && bill?.issueDate ? getDate(bill.issueDate) : '-'}
          />
        </Grid>
        <Grid item xs={4} mt={1}>
          <DataDisplay
            id="dueDate"
            label={t('pages.bills.dueDate')}
            data={bill && bill?.dueDate ? getDate(bill.dueDate) : '-'}
          />
        </Grid>
        <Grid item xs={4} mt={1}>
          <DataDisplay
            id="nextStepDate"
            label={t('pages.bills.nextStepDate')}
            data={bill && bill?.nextStepDate ? getDate(bill.nextStepDate) : '-'}
          />
        </Grid>
      </Grid>
    </>
  );

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} xl={8}>
          <DataCard
            title={t('pages.bills.details')}
            avatar={<InfoIcon sx={{ fill: 'primary.main' }} />}
            children={billDetailsChildren}
          />
        </Grid>
      </Grid>
    </>
  );
}
export default BillDetails;
