import { useState, useEffect, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ColumnDef } from '@tanstack/react-table';
import { useHasEdit } from 'hooks/useHasAccess';

import VisibilityIcon from '@mui/icons-material/Visibility';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';

import { setHeader } from 'redux/contentSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import Loading from 'components/Loading';
import { addMessage } from 'redux/messageSlice';
import {
  MessageActionType,
  MessageType,
} from '@revenue-solutions-inc/revxcoreui';

import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';

import {
  useGetDashboardsQuery,
  GetDashboardsQuery,
  useActivateOrDeactivateDashboardMutation,
} from 'generated/graphql';

type DataSet = {
  dashboardId?: number | null;
  dashboardDisplayName: string | null;
  dashboardDescription?: string | null;
  pbiDashboardId?: string | null;
  status?: string | null;
  isOOTB?: boolean | null;
};

function Dashboards(): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    data: dashboardsData,
    isLoading,
    isError,
    refetch,
  } = useGetDashboardsQuery();
  const [data, setData] = useState<GetDashboardsQuery | undefined>(undefined);
  const module = useAppSelector((state) => state.user.module);
  const navigate = useNavigate();

  const { mutate: dashboardsActiveDeactive } =
    useActivateOrDeactivateDashboardMutation();
  const [isLoadingDashboards, setIsLoadingDashboards] = useState(false);
  const canAction = useHasEdit(
    'dashboardsManager',
    'reportManagementAccessAll'
  );

  useEffect(() => {
    dispatch(
      setHeader({
        pageTitle: t('pages.dashboards.title'),
      })
    );
  }, [dispatch, t]);

  useMemo(() => {
    if (dashboardsData !== undefined) {
      setData(dashboardsData);
    }
  }, [dashboardsData]);

  const handleSuccess = useCallback(
    (type: string, customMessage: string) => {
      dispatch(
        addMessage({
          message: customMessage,
          type: MessageType.Success,
          actionType: MessageActionType.None,
        })
      );

      if (type === 'active') {
        refetch()
          .then((e) => {
            if (e.isSuccess && e.isFetching === false) {
              setIsLoadingDashboards(false);
            }
          })
          .catch(() => {});
      } else {
        refetch()
          .then((e) => {
            if (e.isSuccess && e.isFetching === false && e.data) {
              setData(e.data);
              setIsLoadingDashboards(false);
            }
          })
          .catch(() => {});
      }
    },
    [dispatch, refetch]
  );

  const handleError = useCallback(() => {
    dispatch(
      addMessage({
        message: t('pages.manageReusableContent.networkError'),
        type: MessageType.Error,
        actionType: MessageActionType.None,
      })
    );
    setIsLoadingDashboards(false);
  }, [dispatch, t]);

  const handleActiveOrDeactive = useCallback(
    (id: number, status: string) => {
      setIsLoadingDashboards(true);

      dashboardsActiveDeactive(
        {
          dashboard: {
            dashboardId: id,
            status: status !== 'ACTIVE',
          },
        },
        {
          onSuccess: () =>
            handleSuccess(
              'active',
              status !== 'ACTIVE'
                ? t('pages.dashboards.message.activeDashboards')
                : t('pages.dashboards.message.deactiveDashboards')
            ),
          onError: handleError,
        }
      );
    },
    [dashboardsActiveDeactive, handleError, handleSuccess, t]
  );

  const createColumns = useCallback(
    (set: object) =>
      Object.entries(set).map((value) => {
        return {
          header: () => <HeaderColumnNext localization={String(value[1])} />,
          accessorKey: value[0],
          cell: ({
            row,
          }: {
            row: {
              original: DataSet;
            };
          }) => {
            switch (value[0]) {
              case 'optView':
                return (
                  <>
                    <IconButton
                      onClick={() => {
                        navigate(
                          `/${module}/dashboards/view/${row.original.dashboardId}`,
                          { replace: true }
                        );
                      }}
                      disabled={false}
                      data-testid="viewButton"
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </>
                );
              case 'optActive':
                return (
                  <>
                    <IconButton
                      onClick={() => {
                        handleActiveOrDeactive(
                          row?.original?.dashboardId ?? 0,
                          row?.original?.status ?? 'string'
                        );
                      }}
                      disabled={!canAction}
                      data-testid="viewButton"
                    >
                      {row.original.status === 'ACTIVE' ? (
                        <ToggleOnIcon />
                      ) : (
                        <ToggleOffIcon />
                      )}
                    </IconButton>
                  </>
                );
              case 'optlocked':
                return (
                  <>
                    <IconButton disabled data-testid="lockButton">
                      {row.original.isOOTB ? <LockIcon /> : <LockOpenIcon />}
                    </IconButton>
                  </>
                );
              default:
                return Object.entries(row.original)
                  .map((x) => (x[0] === value[0] ? x[1] : null))
                  .filter((x) => x)[0];
            }
          },
        };
      }),

    [canAction, handleActiveOrDeactive, module, navigate]
  );

  const Columns: ColumnDef<DataSet>[] = useMemo(
    () =>
      createColumns(
        t('pages.dashboards.dashboardsTable', {
          returnObjects: true,
        })
      ),
    [createColumns, t]
  );

  return (
    <>
      {!isLoading && !isLoadingDashboards && data !== undefined && !isError ? (
        <DefaultDataTableNext
          columns={Columns as ColumnDef<Record<string, unknown>>[]}
          data={
            data
              ? (data.getDashboards as Array<{
                  dashboardId: number;
                  pbiDashboardId: string;
                  dashboardName: string;
                  dashboardDescription: string;
                  createdBy: string;
                  createdDate: string;
                  status: string;
                  updatedBy: string;
                  updatedDate: string;
                  isOOTB: boolean;
                }>)
              : []
          }
          sx={{
            justifyContent: 'end',
            gridAutoFlow: 'column',
            width: '100%',
          }}
        />
      ) : (
        <Grid
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            display: isError ? 'none' : 'grid',
          }}
        >
          <Loading />
        </Grid>
      )}
      {isError || data?.getDashboards?.length === 0 ? (
        <Grid
          sx={{
            display: 'grid',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '24px',
          }}
        >
          {t('pages.dashboards.noDashboards')}
        </Grid>
      ) : null}
    </>
  );
}

export default Dashboards;
