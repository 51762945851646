import { Dispatch, SetStateAction } from 'react';
import {
  parseConfiguration,
  MessageType,
} from '@revenue-solutions-inc/revxcoreui';
import {
  ConfigurationResponse,
  GetAllFormLayoutsQuery,
  useCreateFormLayoutMutation,
  useGetFormLayoutQuery,
  useUpdateFormLayoutMutation,
} from 'generated/graphql';
import { IMasterLayout } from '@revenue-solutions-inc/revxcoreui/material/layoutBuilder/types/fields';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import Loading from 'components/Loading';
import extractMeaningfulMessage from 'utils/errorMessage';
import {
  RefetchOptions,
  RefetchQueryFilters,
  QueryObserverResult,
} from '@tanstack/react-query';
import LayoutBuilderWrapper from 'pages/admin/LayoutBuilderWrapper/LayoutBuilderWrapper';

interface ConfigLayoutProps {
  selectedConfigType: ConfigurationResponse;
  selectedLayoutId: string | null | undefined;
  layoutsLength: number;
  setSelectedLayoutId: Dispatch<SetStateAction<string | null | undefined>>;
  setOpenDialog: Dispatch<SetStateAction<boolean>>;
  refetchAllLayouts: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<GetAllFormLayoutsQuery, unknown>>;
}

function ConfigLayoutBuilder({
  selectedConfigType,
  selectedLayoutId,
  layoutsLength,
  setSelectedLayoutId,
  setOpenDialog,
  refetchAllLayouts,
}: ConfigLayoutProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const defaultMessage = t('components.message.networkerror');
  // get layout
  const { data, isFetching } = useGetFormLayoutQuery(
    {
      getFormLayoutId: selectedLayoutId || '',
    },
    { enabled: !!selectedLayoutId }
  );
  // create layout mutation
  const { mutate: createLayoutMutation } = useCreateFormLayoutMutation();
  //update layout mutation
  const { mutate: updateLayoutMutation } = useUpdateFormLayoutMutation();

  // ADD LAYOUT
  function createLayout(masterLayout: IMasterLayout) {
    // TODO: Update when LayoutBuilder is complete
    createLayoutMutation(
      {
        layoutConfigurationInput: {
          configurationId: selectedConfigType.platformConfigurationId,
          configurationVersion: String(selectedConfigType.version),
          context: 'Default' + String(layoutsLength),
          layout: JSON.stringify(masterLayout),
          layoutType: 'Platform Configuration' + String(layoutsLength),
        },
      },
      {
        onSuccess: () => {
          dispatch(
            addMessage({
              message: t('components.message.success'),
              type: MessageType.Success,
            })
          );
          setOpenDialog(false);
          setSelectedLayoutId(undefined);
          refetchAllLayouts();
        },
        onError: (e: Error[] | unknown) => {
          const message = extractMeaningfulMessage(e, defaultMessage);
          dispatch(
            addMessage({
              message: message,
              type: MessageType.Error,
            })
          );
        },
      }
    );
  }

  // UPDATE LAYOUT
  function updateLayout(masterLayout: IMasterLayout) {
    // TODO: Update when LayoutBuilder is complete
    updateLayoutMutation(
      {
        layoutConfigurationInput: {
          configurationId: data?.getFormLayout.configurationId,
          configurationVersion: data?.getFormLayout.configurationVersion,
          context: data?.getFormLayout.context,
          isNewVersion: true,
          layout: JSON.stringify(masterLayout),
          layoutType: data?.getFormLayout.layoutType,
          layoutConfigurationId: data?.getFormLayout.layoutConfigurationId,
        },
      },
      {
        onSuccess: () => {
          dispatch(
            addMessage({
              message: t('components.message.success'),
              type: MessageType.Success,
            })
          );
          setOpenDialog(false);
          setSelectedLayoutId(undefined);
          refetchAllLayouts();
        },
        onError: (e: Error[] | unknown) => {
          const message = extractMeaningfulMessage(e, defaultMessage);
          dispatch(
            addMessage({
              message: message,
              type: MessageType.Error,
            })
          );
        },
      }
    );
  }

  return (
    <>
      {isFetching && <Loading />}
      {!isFetching && (
        <LayoutBuilderWrapper
          fields={parseConfiguration(selectedConfigType)}
          saveConfiguration={!!selectedLayoutId ? updateLayout : createLayout}
          masterLayout={
            !!selectedLayoutId
              ? (JSON.parse(
                  data?.getFormLayout.layout as string
                ) as IMasterLayout)
              : undefined
          }
        />
      )}
    </>
  );
}

export default ConfigLayoutBuilder;
