import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, IconButton } from '@mui/material';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import {
  Attribute,
  useGetAccountByIdQuery,
  useGetAssetByIdQuery,
  useGetEntityByIdQuery,
  useGetAccountPeriodsAndBalanceQuery,
} from 'generated/graphql';
import {
  WorkflowContext,
  WorkflowContextType as WorkflowContextTypeEnum,
} from 'types/WorkflowContext';

type ContextDemographicsProps = {
  workflowContext: WorkflowContext;
  onRemoveContext: (context: WorkflowContext) => void;
};

type DisplayField = {
  label: string;
  value: string | null | undefined;
};

// Retrieve the attribute value from a primary group
const getPrimaryGroupValue = (
  groupData: { groupName?: string | null; attribute: Attribute[] }[],
  groupName: string,
  groupAttribute: string
) => {
  const group = groupData.find((groupItem) => {
    const isPrimary = !!groupItem.attribute.find(
      (attributeItem) =>
        attributeItem.attributeName === 'isPrimary' &&
        attributeItem.attributeValue === 'true'
    );
    return groupItem.groupName === groupName && isPrimary;
  });
  const attribute = group?.attribute.find(
    (attributeItem) => attributeItem.attributeName === groupAttribute
  );
  return attribute?.attributeValue;
};

export const ContextDemographics = ({
  workflowContext,
  onRemoveContext,
}: ContextDemographicsProps) => {
  const { t } = useTranslation();
  const [displayData, setDisplayData] = useState<
    { label: string; value?: string | null }[]
  >([]);

  const { ContextId: contextId, WorkflowContextType: contextType } =
    workflowContext ?? {};

  const hasContextId = Boolean(contextId);
  const contextIsEntity = contextType == WorkflowContextTypeEnum.Entity;
  const contextIsAccount = contextType == WorkflowContextTypeEnum.Account;
  const contextIsPeriod = contextType == WorkflowContextTypeEnum.Period;
  const contextIsAsset = contextType == WorkflowContextTypeEnum.Asset;

  const { data: accountData, isFetching: loadingAccount } =
    useGetAccountByIdQuery(
      { accountId: `${contextId}` },
      {
        enabled: hasContextId && contextIsAccount,
      }
    );

  const { data: entityData, isFetching: loadingEntity } = useGetEntityByIdQuery(
    {
      entityId: contextIsAccount
        ? `${accountData?.GetAccountById?.entityInfoId}`
        : `${contextId}`,
    },
    {
      enabled: hasContextId && (contextIsEntity || contextIsAccount),
    }
  );

  const { data: periodData, isFetching: loadingPeriod } =
    useGetAccountPeriodsAndBalanceQuery(
      { accountId: `${contextId}` },
      {
        enabled: hasContextId && contextIsPeriod,
        select: (data) => {
          return data.GetAccountPeriodsAndBalance.accountPeriods;
        },
      }
    );
  const { data: assetData, isFetching: loadingAsset } = useGetAssetByIdQuery(
    { assetId: `${contextId}` },
    {
      enabled: hasContextId && contextIsAsset,
    }
  );

  const isLoading =
    loadingEntity || loadingAccount || loadingPeriod || loadingAsset;

  useEffect(() => {
    if (
      !contextType &&
      !entityData &&
      !accountData &&
      !periodData &&
      !assetData
    ) {
      return;
    }

    const data = {
      entity: entityData?.GetEntityById,
      account: accountData?.GetAccountById,
      periods: periodData,
      asset: assetData?.GetAssetById,
    };

    const displayFields: DisplayField[] = [];

    if (data.entity?.group && (contextIsEntity || contextIsAccount)) {
      displayFields.push(
        ...[
          {
            label: t('pages.workflow.demographics.entityName'),
            value: getPrimaryGroupValue(data.entity.group, 'names', 'value'),
          },
          {
            label: t('pages.workflow.demographics.entityType'),
            value: data.entity.entityType,
          },
          {
            label: t('pages.workflow.demographics.phone'),
            value: getPrimaryGroupValue(data.entity.group, 'phones', 'value'),
          },
          {
            label: t('pages.workflow.demographics.email'),
            value: getPrimaryGroupValue(
              data.entity.group,
              'emailAddresses',
              'value'
            ),
          },
          {
            label: t('pages.workflow.demographics.address'),
            value: getPrimaryGroupValue(
              data.entity.group,
              'addresses',
              'addressLine1'
            ),
          },
        ]
      );
    }
    if (data.account?.group && contextIsAccount) {
      displayFields.push(
        ...[
          {
            label: t('pages.workflow.demographics.accountType'),
            value: data.account.accountType,
          },
          {
            label: t('pages.workflow.demographics.accountNumber'),
            value: data.account.primaryIdentifier,
          },
        ]
      );
    }

    if (contextIsPeriod) {
      displayFields.push({
        label: t('pages.workflow.demographics.periodRange'),
        value: 'Sample Period Range',
      });
    }

    if (data.asset?.group && contextIsAsset) {
      displayFields.push(
        ...[
          {
            label: t('pages.workflow.demographics.assetType'),
            value: 'Sample Asset Type',
          },
          {
            label: t('pages.workflow.demographics.entityName'),
            value: getPrimaryGroupValue(data.asset.group, 'names', 'value'),
          },
          {
            label: t('pages.workflow.demographics.address'),
            value: getPrimaryGroupValue(
              data.asset.group,
              'addresses',
              'addressLine1'
            ),
          },
        ]
      );
    }

    setDisplayData(displayFields);
  }, [
    contextType,
    contextId,
    entityData,
    accountData,
    assetData,
    periodData,
    contextIsEntity,
    contextIsAccount,
    contextIsPeriod,
    contextIsAsset,
    t,
  ]);

  if (isLoading) {
    return null;
  }

  return (
    <Grid
      rowSpacing={2}
      columnSpacing={2}
      width={'100%'}
      sx={{
        position: 'relative',
        th: {
          textAlign: 'start',
          whiteSpace: 'nowrap',
        },
        td: {
          lineHeight: 1,
          padding: 0.5,
        },
      }}
    >
      <IconButton
        id="delete-context-button"
        aria-label="delete context"
        color="default"
        size="small"
        onClick={onRemoveContext.bind(null, workflowContext)}
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
        }}
      >
        <CancelRoundedIcon fontSize="small" />
      </IconButton>
      <table>
        <tbody>
          {displayData.map(({ label, value }, index) => {
            return (
              <tr key={`${label}-${index}`}>
                <th>{label}</th>
                <td>{value}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Grid>
  );
};
