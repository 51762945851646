import { useState, useMemo, createContext, useEffect } from 'react';

import { UserRole } from 'types/roles';

interface ContextProps {
  userRole: UserRole;
  tenantId: string;
  refetch: () => void;
  children?: JSX.Element | JSX.Element[];
}

export interface Role {
  roleId: string;
  isEdit: boolean;
  startDate: Date | null;
  endDate: Date | null;
  initialStartUnixTime?: number;
  initialEndUnixTime?: number;
  startDateError?: string;
  endDateError?: string;
}

interface AssignRolesProps {
  userRole: UserRole | undefined;
  tenantId: string;
  roles: Role[];
  isReset: boolean;
  refetch: () => void;
}

interface AssignedRolesCtx {
  assignedRoles: AssignRolesProps | undefined;
  setAssignedRoles: React.Dispatch<React.SetStateAction<AssignRolesProps>>;
}

const initialContext: AssignedRolesCtx = {
  assignedRoles: undefined,
  setAssignedRoles() {},
};

export const AssignedRolesContext = createContext(initialContext);

export default function AssignedRolesProvider({
  userRole,
  tenantId,
  refetch,
  children,
}: ContextProps) {
  const [assignedRoles, setAssignedRoles] = useState<AssignRolesProps>({
    tenantId,
    refetch,
    userRole: undefined,
    roles: [],
    isReset: false,
  });

  const ctx = useMemo(() => {
    return {
      assignedRoles,
      setAssignedRoles,
    };
  }, [assignedRoles]);

  useEffect(() => {
    if (assignedRoles.isReset) {
      assignedRoles.refetch();
      setAssignedRoles({
        ...assignedRoles,
        isReset: false,
      });
    }
  }, [assignedRoles]);

  useEffect(() => {
    setAssignedRoles((data) => ({ ...data, userRole }));
  }, [userRole, setAssignedRoles]);

  return (
    <AssignedRolesContext.Provider value={ctx}>
      {children}
    </AssignedRolesContext.Provider>
  );
}
