import { CardContent, Grid } from '@mui/material';
import DataDisplay from '@revenue-solutions-inc/revxcoreui/material/controls/DataDisplay';
import { useTranslation } from 'react-i18next';

import PropertyCardLayout from '../PropertyCardLayout';

interface Props {
  setActive: React.Dispatch<React.SetStateAction<string>>;
}

function FiduciaryCard({ setActive }: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <PropertyCardLayout
      title={t('pages.propertyView.fiduciary')}
      setActive={setActive}
      activeCard="ActiveFiduciaryCard"
    >
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <DataDisplay
              id="fiduciarycode"
              label={t('pages.propertyView.fiduciaryCode')}
              data={'2221'}
            />
          </Grid>
          <Grid item xs={12}>
            <DataDisplay
              id="name"
              label={t('pages.propertyView.name')}
              data={'Mortgage Company'}
            />
          </Grid>
          <Grid item xs={12}>
            <DataDisplay
              id="address"
              label={t('pages.propertyView.address')}
              data={'123 Fake St'}
            />
          </Grid>
          <Grid item xs={12}>
            <DataDisplay
              id="correspondence"
              label={t('pages.propertyView.correspondence')}
              data={'Certified Statement - Delinquent Notice'}
            />
          </Grid>
          <Grid item xs={12}>
            <DataDisplay
              id="courtesy"
              label={t('pages.propertyView.courtesy')}
              data={'Certified Statement'}
            />
          </Grid>
        </Grid>
      </CardContent>
    </PropertyCardLayout>
  );
}

export default FiduciaryCard;
