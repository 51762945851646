import { useEffect, useState } from 'react';
import { Grid, Typography, IconButton } from '@mui/material';
import {
  Input,
  Button,
  MessageType,
  Select,
} from '@revenue-solutions-inc/revxcoreui';
import { setHeader } from 'redux/contentSlice';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { addMessage } from 'redux/messageSlice';
import { Error } from 'types/graphqlErrors';
import {
  FieldSetInput,
  useConfigureIdGenerationMutation,
  IdTypeGenerationInput,
} from 'generated/graphql';
import { useAppDispatch } from 'redux/hooks';
import {
  configDomainsList,
  configModulesList,
} from 'common/platformConfigUtils/platformConfigUtils';
import { Box } from '@mui/system';
import RefreshIcon from '@mui/icons-material/Refresh';
import FieldSetForm from './FieldSetForm';

function generateNumberPreview(value: unknown): string {
  // we have some inconcitencies with the type of data in the graphQL
  // we say .length is float, but then the input field allows strings
  // and we cannot change the input type to be Number so as to just receive
  // numbers, because we also want Hexadecimal numbers
  // for the sake of the demo I will keep this validation function outside
  // also if the number is hex what should we print?
  // change this as soon as we have more info

  // the input could be a number, undefined, or an string so validate that
  const length = parseInt(value as unknown as string);
  if (isNaN(length)) return '**Check input in Number**';
  // validate no negative numbers
  if (length < 0) return '**Check negative input in Number**';
  // Localize the above checks in the future

  const array = Array(length).fill(0);
  return array.join('');
}

function ConfigureId() {
  const { control, formState, trigger, getValues } = useForm({
    mode: 'all',
  });

  const { isValid } = formState;
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.generateIdConfig',
  });
  const dispatch = useAppDispatch();
  const [preview, setPreview] = useState('');

  const saveIdConfig = useConfigureIdGenerationMutation();

  function submitCreateId() {
    //Trigger form validation
    if (isValid) {
      trigger().then(() => {
        const payload: IdTypeGenerationInput = {
          configurationModule: Number(getValues('configModule')),
          configurationDomain: Number(getValues('configDomain')),
          configurationType: getValues('configType'),
          configurationEntity: getValues('configEntity'),
          configurationName: getValues('configName'),
          configurationDescription: getValues('configDescription'),
          uniqueWithin: Number(getValues('uniqueWithin')),
          fieldSet: getValues('fieldSet').map((fl: FieldSetInput) => {
            return { ...fl, length: Number(fl.length) };
          }),
        };
        saveIdConfig.mutate(
          { newIdTypeGeneration: payload },
          {
            onSuccess: () => {
              dispatch(
                addMessage({
                  message: t('success'),
                  type: MessageType.Success,
                })
              );
            },
            onError: (errors) => {
              const message = (errors as Error[])[0].message;
              dispatch(
                addMessage({
                  message: message,
                  type: MessageType.Error,
                })
              );
            },
          }
        );
      });
    }
  }

  useEffect(() => {
    dispatch(
      setHeader({
        pageTitle: t('title'),
        route: 'generateIdConfig',
      })
    );
  }, [dispatch, t]);

  useEffect(() => {
    {
      const prev =
        getValues('fieldSet') &&
        getValues('fieldSet')
          .map((field: FieldSetInput) => {
            switch (field.fieldType) {
              case 'DateTime':
                return field.dateFormat;
              case 'Number':
                return generateNumberPreview(field.length);
              case 'Expression':
                return '{Expression}';
              default:
                return field.fieldValue;
            }
          })
          .join('');
      setPreview(prev);
    }
  }, [formState, getValues]);

  return (
    <Box>
      <Grid
        container
        m={1}
        sx={(theme) => {
          return {
            background: theme.palette.white.main,
            width: '100%',
            p: '8px 16px 16px 8px',
          };
        }}
        spacing={2}
      >
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            control={control}
            name="configDomain"
            rules={{ required: true }}
            render={({
              field: { value, onChange },
              fieldState: { error: configDomainError },
            }) => {
              return (
                <Select
                  required
                  autowidth={false}
                  options={configDomainsList}
                  id="configDomain-config-list"
                  data-testid="configDomain"
                  label={t('configDomain')}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  value={value}
                  error={configDomainError?.message}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            control={control}
            name="configModule"
            rules={{ required: true }}
            render={({
              field: { value, onChange },
              fieldState: { error: configModuleError },
            }) => {
              return (
                <Select
                  required
                  autowidth={false}
                  options={configModulesList}
                  id="configModule-list-admin"
                  data-testid="configModule"
                  label={t('configModule')}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  value={value}
                  error={configModuleError?.message}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            control={control}
            name="configType"
            rules={{
              required: true,
              validate: (value) => value.trim() !== '',
            }}
            render={({
              field: { value, onChange },
              fieldState: { error: configTypeError },
            }) => {
              return (
                <Input
                  required
                  id="configType"
                  label={t('configType')}
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                  error={!!configTypeError}
                  helperText={''}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            control={control}
            name="configEntity"
            rules={{
              required: true,
              validate: (value) => value.trim() !== '',
            }}
            render={({
              field: { value, onChange },
              fieldState: { error: configEntityError },
            }) => {
              return (
                <Input
                  required
                  id="configEntity"
                  label={t('configEntity')}
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                  error={!!configEntityError}
                  helperText={''}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            control={control}
            name="configDescription"
            render={({
              field: { value, onChange },
              fieldState: { error: configDescriptionError },
            }) => {
              return (
                <Input
                  id="configDescription"
                  label={t('configDescription')}
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                  error={!!configDescriptionError}
                  helperText={''}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            control={control}
            name="uniqueWithin"
            render={({
              field: { value, onChange },
              fieldState: { error: uniqueWithinError },
            }) => {
              return (
                <Select
                  required
                  autowidth={false}
                  options={[
                    { key: '0', desc: 'Tenant' },
                    { key: '1', desc: 'Module' },
                    { key: '2', desc: 'Domain' },
                    { key: '3', desc: 'Entity' },
                  ]}
                  id="uniqueWithin-config-id"
                  data-testid="uniqueWithin"
                  label={t('uniqueWithin')}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  value={value}
                  error={uniqueWithinError?.message}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          {t('instructions')}
        </Grid>
      </Grid>
      <Grid container spacing={2} m={1} ml={0}>
        <FieldSetForm control={control} />
      </Grid>
      <Box>
        <Box
          m={1}
          p={2}
          sx={(theme) => {
            return {
              background: theme.palette.white.main,
              minWidth: '200px',
              minHeight: '100px',
            };
          }}
        >
          <Box
            p={1}
            pr={0}
            sx={(theme) => {
              return {
                borderColor: theme.palette.grey2.light,
                background: theme.palette.grey6.main,
                borderWidth: '1px',
                borderStyle: 'solid',
                borderRadius: '5px',
                fontSize: '1.5em',
              };
            }}
            mb={2}
          >
            <IconButton
              aria-label="refresh-button"
              id="refreshButton"
              size="small"
              data-testid="refresh-button"
              sx={{ float: 'right', fontSize: '.7em' }}
              onClick={() => {
                trigger();
              }}
            >
              <RefreshIcon />
            </IconButton>
            <Typography
              sx={(theme) => {
                return { fontSize: '.7em', color: theme.palette.grey2.main };
              }}
            >
              {t('preview')}
            </Typography>
            {preview}
          </Box>
          <Button
            id={'saveIdBtn'}
            onClick={submitCreateId}
            disabled={!isValid || getValues('fieldSet').length == 0}
          >
            {t('save')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default ConfigureId;
