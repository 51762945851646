import { useContext, useState } from 'react';

import { SxProps } from '@mui/system';
import { Theme } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import Select, {
  SelectType,
} from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import { MessageType } from '@revenue-solutions-inc/revxcoreui/material/messaging/Message/Message';
import {
  ConfigurationDomains,
  ConfigurationModules,
} from 'common/platformConfigUtils/platformConfigUtils';
import EntityManagementContext from 'components/contexts/EntityManagement';
import {
  useGetConfigurationTypeQuery,
  useGetLookupConfigurationQuery,
  useGetLookupTypesConfigurationQuery,
} from 'generated/graphql';
import { useAppDispatch } from 'redux/hooks';
import { useTranslation } from 'react-i18next';
import { addMessage } from 'redux/messageSlice';
import { Error } from 'types/graphqlErrors';
import extractMeaningfulMessage from 'utils/errorMessage';

interface Props {
  id?: string;
  label: string;
  required?: boolean;
  errorMsg?: string;
  value?: string | number;
  datasource: string;
  groupName?: string;
  attributeName?: string;
  extraType?: string;
  context?: string;
  maxWidth?: boolean;
  idTypeChange?: (idType: string) => void;
  fetchLayoutInfo: React.Dispatch<SelectChangeEvent<string | number>>;
  disabled?: boolean;
  sx?: SxProps<Theme>;
  isPlatform?: boolean;
}

enum ContextTypes {
  EntityType = 'EntityType',
  AccountType = 'EntityToAccountTypes',
  AssetType = 'AssetType',
}

function DatasourceSelect({
  id = 'datasource-select',
  label,
  required,
  errorMsg,
  value,
  idTypeChange,
  datasource,
  groupName,
  attributeName,
  extraType = '',
  context = '',
  maxWidth = false,
  disabled = false,
  fetchLayoutInfo,
  sx,
  isPlatform = false,
}: Props): JSX.Element {
  const MAIN_CONFIG_DOMAIN = ConfigurationDomains.ReferenceSchema;
  const MAIN_CONFIG_MODULE = ConfigurationModules.Platform;
  const [types, setTypes] = useState<SelectType[]>([]);
  const [platformTypes, setPlatformTypes] = useState<SelectType[]>([]);
  const [selected, setSelected] = useState<string>('');
  const ctx = useContext(EntityManagementContext);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  /**
   * lookupTypesIsFetching is used to get types from platform domain
   */
  const { isFetching: lookupTypesIsFetching } =
    useGetLookupTypesConfigurationQuery(
      {
        configurationModule: MAIN_CONFIG_MODULE,
        configurationDomain: MAIN_CONFIG_DOMAIN,
        configurationType: datasource,
      },
      {
        enabled: context === '' && datasource !== '' && isPlatform,
        onSuccess: (data) => {
          const options: SelectType[] = [];
          data.GetLookupTypesConfiguration.forEach((type) => {
            const optionType: SelectType = {
              key: type.configurationName,
              desc: type.configurationName
                ? type.configurationName
                : type.configurationDescription ?? '',
            };
            options.push(optionType);
          });
          setPlatformTypes(options);
        },
        onError: () => {
          dispatch(
            addMessage({
              type: MessageType.Error,
              message: t('components.message.lookupError'),
            })
          );
        },
      }
    );

  /**
   * This renders a dropdown by using only a single parameter (datasource)
   */
  const { isFetching: lookupIsFetching } = useGetLookupConfigurationQuery(
    {
      configurationDomain: MAIN_CONFIG_DOMAIN,
      configurationType: datasource,
    },
    {
      enabled: context === '' && datasource !== '' && !isPlatform,
      onSuccess: (data) => {
        const options: SelectType[] = [];
        data.GetLookupConfiguration.forEach((type) => {
          const optionType: SelectType = {
            key: type.configurationName,
            desc: type.configurationName
              ? type.configurationName
              : type.configurationDescription ?? '',
          };
          options.push(optionType);
        });
        setTypes(options);
      },
      onError: () => {
        dispatch(
          addMessage({
            type: MessageType.Error,
            message: t('components.message.lookupError'),
          })
        );
      },
    }
  );

  /**
   * This renders a dropdown that uses multiple parameters as filters, ocasionally
   * used to filter the values that will be included (datasource, group and attributeName)
   */
  const { isFetching: configTypeFetching } = useGetConfigurationTypeQuery(
    {
      configurationName: extraType || ctx.selectedType,
      configurationType: datasource,
      groupName: groupName ?? '',
      attributeName: attributeName ?? '',
    },
    {
      enabled:
        (extraType !== '' || ctx.selectedType !== '') &&
        groupName !== '' &&
        attributeName !== '' &&
        context !== '' &&
        datasource !== '',
      onSuccess: (data) => {
        const options: SelectType[] = [];
        data.GetConfigurationType.forEach((configType) => {
          const optionType: SelectType = {
            key: configType.attributeValue,
            desc: configType.attributeValue,
          };
          options.push(optionType);
        });
        setTypes(options);
      },
      onError: (e: Error[] | unknown) => {
        let message: string = t('components.message.configTypeError');
        message = extractMeaningfulMessage(e, message);
        dispatch(
          addMessage({
            type: MessageType.Error,
            message: message,
          })
        );
      },
    }
  );

  /**
   * Used to set a value that can be used as a filter in the dropdown to be displayed
   * @param selectedValue Value selected in a main dropdown
   */
  const setContextValues = (selectedValue: string) => {
    if (
      Object.values(ContextTypes).includes(
        datasource as unknown as ContextTypes
      )
    ) {
      ctx.onTypeChange(selectedValue.toString());
    } else if (
      datasource === 'IdType' ||
      datasource === 'EntityToIdTypes' ||
      datasource === 'AccountToIdTypes' ||
      datasource === 'AssetToIdTypes'
    ) {
      ctx.onIdTypeChange(selectedValue.toString());
      if (idTypeChange) idTypeChange(selectedValue.toString());
    }
  };

  if (lookupIsFetching || configTypeFetching || lookupTypesIsFetching)
    return (
      <Select
        disabled
        id={id}
        label={label}
        options={[{ key: '0', desc: 'Loading' }] as SelectType[]}
        value={'0'}
        onChange={() => {}}
        required={required}
        error={errorMsg}
        sx={maxWidth ? { width: '100%' } : {}}
      />
    );

  return (
    <Select
      disabled={disabled}
      id={id}
      label={label}
      options={isPlatform ? platformTypes : types}
      value={value ?? selected}
      onChange={(ev) => {
        fetchLayoutInfo(ev);
        setSelected(ev.target.value as string);
        setContextValues(ev.target.value as string);
      }}
      required={required}
      error={errorMsg}
      sx={sx ? sx : maxWidth ? { width: '100%' } : {}}
    />
  );
}

export default DatasourceSelect;
