import Typography from '@mui/material/Typography';
import NoResults from 'components/NoResults';
import { PolicyGroup } from 'generated/graphql';
import { useTranslation } from 'react-i18next';

interface PolicyGroupsInfoProps {
  policyGroups: PolicyGroup[];
}

const PolicyGroupsByRoleInfo = ({
  policyGroups,
}: PolicyGroupsInfoProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <>
      {policyGroups && policyGroups.length > 0 && (
        <>
          <Typography
            variant="h3"
            sx={{
              fontSize: 16,
              fontWeight: 'bold',
              marginTop: 1,
              marginBottom: 1,
            }}
          >
            {t('pages.roles.assignedPolicyGroups')}
          </Typography>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            {policyGroups.map(
              ({ policyGroupId, policyGroupName }: PolicyGroup) => (
                <span
                  key={policyGroupId}
                  style={{ width: '50%', marginBottom: '10pxS' }}
                >
                  {policyGroupName}
                </span>
              )
            )}
          </div>
        </>
      )}
      {policyGroups && policyGroups.length === 0 && <NoResults />}
    </>
  );
};

export default PolicyGroupsByRoleInfo;
