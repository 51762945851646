export type ReducerAction = {
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
};

export type ActionName = {
  [key: string]: string;
};

export type ActionMap<T> = {
  [key: keyof ActionName]: (state: T, action: ReducerAction) => T;
};

type ReducerFunction<T> = (state: T, action: ReducerAction) => T;

export function createReducer<T>(
  initialState: T,
  actions: ActionMap<T>
): ReducerFunction<T> {
  // eslint-disable-next-line @typescript-eslint/default-param-last
  return (state = initialState, action) => {
    if (actions[action.type]) {
      return actions[action.type](state, action);
    }
    return state;
  };
}
