import {
  Box,
  FormLabel,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { Control, Controller, UseFormWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Channel } from 'types/channels';

export const labelStyle = (theme: Theme) => {
  return {
    fontWeight: theme.typography.fontWeightMedium,
    color: '#333',
    borderBottom: 'none',
    '& .MuiFormLabel-asterisk': {
      fontWeight: theme.typography.fontWeightMedium,
    },
  };
};

interface Days {
  value: number;
}

interface Props {
  index: number;
  control: Control<Channel, unknown>;
  watch: UseFormWatch<Channel>;
}
// TODO: replace with lookup once created
const daysOfMonth: Days[] = [
  { value: 1 },
  { value: 2 },
  { value: 3 },
  { value: 4 },
  { value: 5 },
  { value: 6 },
  { value: 7 },
  { value: 8 },
  { value: 9 },
  { value: 10 },
  { value: 11 },
  { value: 12 },
  { value: 13 },
  { value: 14 },
  { value: 15 },
  { value: 16 },
  { value: 17 },
  { value: 18 },
  { value: 19 },
  { value: 20 },
  { value: 21 },
  { value: 22 },
  { value: 23 },
  { value: 24 },
  { value: 25 },
  { value: 26 },
  { value: 27 },
  { value: 28 },
  { value: 29 },
  { value: 30 },
  { value: 31 },
  { value: -1 },
];

function MonthDays({ index, control }: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box>
          <FormLabel sx={labelStyle} id="weekDays">
            {t('pages.manageChannel.monthDays')}
          </FormLabel>
        </Box>
        <Box>
          <Controller
            control={control}
            name={`Channel.Pipeline.PipelineSchedule.${index}.RecurrenceSchedule.MonthDays`}
            render={({ field: { value, onChange, ...fieldProps } }) => (
              <ToggleButtonGroup
                {...fieldProps}
                value={value}
                onChange={(event, newValue: number) => onChange(newValue)}
                aria-label="month days"
                sx={[{ display: '-webkit-inline-box' }]}
              >
                {daysOfMonth.map((day) => (
                  <ToggleButton
                    key={day.value}
                    value={day.value}
                    sx={[
                      (theme) =>
                        day.value === -1
                          ? {
                              backgroundColor: 'white.main',
                              width: '150px !important',
                              ':hover': {
                                backgroundColor: 'linkBlue.main',
                                color: 'white.main',
                              },
                            }
                          : {
                              backgroundColor: 'white.main',
                              color: theme.palette.grey1.main,
                              ':hover': {
                                backgroundColor: 'linkBlue.main',
                                color: 'white.main',
                              },
                            },
                      { backgroundColor: 'white.main' },
                      { marginLeft: '.3em' },
                      { marginBottom: '.2em' },
                      { border: '1px solid' },
                      { width: '80px' },
                      { marginRight: '0.2rem' },
                      { marginLeft: '0' },
                      { borderLeft: 'solid 1px !important' },
                      { borderRadius: '4px !important' },
                    ]}
                  >
                    {day.value === -1
                      ? t('pages.manageChannel.last')
                      : day.value}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            )}
          />
        </Box>
      </Box>
    </>
  );
}
export default MonthDays;
