export type FunctionListType = {
  id: string;
  category: string;
  functionName: string;
  syntax: string;
  displaySyntax: string;
  description: string;
};

export const functionList: FunctionListType[] = [
  {
    id: '1',
    category: 'Logic',
    functionName: 'AND',
    syntax: 'AND()',
    displaySyntax: 'AND(p1, p2, p3)',
    description:
      'Returns a Boolean, will return true if all of the parameters are true.',
  },
  {
    id: '2',
    category: 'Logic',
    functionName: 'OR',
    syntax: 'OR()',
    displaySyntax: 'OR(p1, p2, p3)',
    description:
      'Returns a Boolean, will return true if any of the parameters are true.',
  },
  {
    id: '3',
    category: 'Logic',
    functionName: 'NOT',
    syntax: 'NOT()',
    displaySyntax: 'NOT(p1)',
    description:
      'Flips the Boolean value of p1 from true to false, or false to true.',
  },
  {
    id: '4',
    category: 'Logic',
    functionName: 'IF',
    syntax: 'IF()',
    displaySyntax: 'IF(p1, p2, p3)',
    description: 'If p1 is true, then return p2, otherwise return p3.',
  },
  {
    id: '5',
    category: 'Math',
    functionName: 'SUM',
    syntax: 'SUM()',
    displaySyntax: 'SUM(p1,p2,…)',
    description: 'Sum of all parameters, returns number.',
  },
  {
    id: '6',
    category: 'Math',
    functionName: 'MOD',
    syntax: 'MOD()',
    displaySyntax: 'MOD(p1,p2)',
    description:
      'p1 is the number and p2 is the divisor, returns the remainder.',
  },
  {
    id: '7',
    category: 'Math',
    functionName: 'ABS',
    syntax: 'ABS()',
    displaySyntax: 'ABS(p1)',
    description: 'Absolute value of p1',
  },
  {
    id: '8',
    category: 'Math',
    functionName: '+',
    syntax: '+',
    displaySyntax: 'p1+p2',
    description: 'Addition',
  },
  {
    id: '9',
    category: 'Math',
    functionName: '-',
    syntax: '-',
    displaySyntax: 'p1-p2',
    description: 'Subtraction',
  },
  {
    id: '10',
    category: 'Math',
    functionName: '*',
    syntax: '*',
    displaySyntax: 'p1*p2',
    description: 'Multiplication',
  },
  {
    id: '11',
    category: 'Math',
    functionName: '/',
    syntax: '/',
    displaySyntax: 'p1/p2',
    description: 'Division',
  },
  {
    id: '12',
    category: 'Math',
    functionName: '(  )',
    syntax: '()',
    displaySyntax: '(p1+p2)/7',
    description:
      'Prioritize operations, will perform first the sum then the division.',
  },
  {
    id: '13',
    category: 'Date',
    functionName: 'DateDiff',
    syntax: '',
    displaySyntax: '',
    description: '',
  },
  {
    id: '14',
    category: 'Date',
    functionName: 'BsnDay',
    syntax: '',
    displaySyntax: '',
    description: '',
  },
  {
    id: '15',
    category: 'Date',
    functionName: 'DayAdd',
    syntax: '',
    displaySyntax: '',
    description: '',
  },
];
