import { useState } from 'react';

import { Box, Dialog, DialogContent } from '@mui/material';
import { Button } from '@revenue-solutions-inc/revxcoreui';
import AddWorkflow from 'components/AddWorkflow/AddWorkflow';
import { useTranslation } from 'react-i18next';

export default function AddWorkflowButton() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box>
      <Box
        sx={{
          justifyContent: 'flex-end',
          display: 'flex',
          marginRight: '.5em',
        }}
      >
        <Button
          id="add-workflow-btn"
          onClick={handleOpen}
          sx={{ minWidth: 'max-content' }}
        >
          {t('pages.workflow.addWork')}
        </Button>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="create-new-workflow"
        aria-describedby="create-new-workflow"
      >
        <DialogContent sx={{ minWidth: 320 }}>
          <AddWorkflow onCloseCallback={handleClose} />
        </DialogContent>
      </Dialog>
    </Box>
  );
}
