import { useCallback, useEffect, useMemo, useState } from 'react';
import { CardContent, Grid, Box, Modal } from '@mui/material';
import {
  MessageType,
  MessageActionType,
} from '@revenue-solutions-inc/revxcoreui';
import Loading from 'components/Loading';
import HorizontalNonLinearStepper from 'components/stepperAdmin/HorizontalNonLinearStepper';
import SelectModules from 'components/tenantConfig/SelectModules';
import RsiContacts from 'components/tenantConfig/RsiContacts';
import TenancyConfig from 'components/tenantConfig/TenancyConfig';
import TenantInformation from 'components/tenantConfig/TenantInformation';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { setHeader } from 'redux/contentSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import {
  modulesDefault as mandatoryModule,
  LocaleInfo,
  Modules,
  RsiContactInfo,
  Tenant,
  tenantDefault,
  SaveTenantDraftResponse,
} from 'types/tenants';
import {
  useCreateTenantMutation,
  useGetTenantDraftByIdQuery,
  useSaveTenantDraftMutation,
  GetTenantDraftByIdQuery,
  SaveTenantDraftRequest,
} from 'generated/graphql';
import { getErrorMsg } from 'utils/getErrorMsg';
import { delay } from 'utils/delay';
import extractMeaningfulMessage from 'utils/errorMessage';
import TenantDialog from './TenantDialog';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '20%',
  minWidth: 320,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
} as const;

function TenantConfig(): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const tenantListUrl = 'tenantList';
  const [currentStep, setCurrentStep] = useState(0);
  const [reset, setReset] = useState(false);
  const [completed, setCompleted] = useState<{
    [k: number]: boolean;
  }>({});
  const { tenantId } = useParams() as { tenantId: string };
  const selectedModule = useAppSelector((state) => state.user.module);
  const [tenant, setTenant] = useState<Tenant>(tenantDefault);
  const [isTenantInfoValid, setTenantInfoValid] = useState(false);
  const [isRsiContactsValid, setRsiContactsValid] = useState(false);
  const [isTenancyConfigValid, setTenancyConfigValid] = useState(false);
  const [isModulesSelected, setIsModulesSelected] = useState(false);

  const [stepPassedValidation, setStepPassedValidation] =
    useState<boolean>(true);

  const saveTenantDraft = useSaveTenantDraftMutation<{
    tenantDraft: SaveTenantDraftRequest;
  }>();

  const createTenant = useCreateTenantMutation();
  const [isDone, setIsDone] = useState(false);
  const [onePrimaryContact, setOnePrimaryContact] = useState(false);
  const [firstRender, setFirstRender] = useState(false);
  const [validTenantName, setValidTenantName] = useState(false);
  const [showTenantNameError, setShowTenantNameError] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    dispatch(
      setHeader({
        pageTitle: t('pages.tenantConfig.title'),
        previousPage: t('pages.tenantList.navTitle'),
        route: `${tenantListUrl}`,
      })
    );
  }, [dispatch, t]);

  useEffect(() => {
    if (localStorage.getItem('customerSelected'))
      localStorage.removeItem('customerSelected');
  }, []);

  const handleReset = () => {
    setIsDone(false);
    setCurrentStep(0);
    setCompleted({});
  };

  const { isFetching, refetch } =
    useGetTenantDraftByIdQuery<GetTenantDraftByIdQuery>(
      {
        tenantId: tenantId,
      },
      {
        enabled: false,
        onSuccess: (data) => {
          const newCompleted: { [k: number]: boolean } = {
            0: false,
            1: false,
            2: false,
            3: false,
          };
          if (data) {
            const id = data?.TenantDraftById?.id ?? '';
            const tenantName = data?.TenantDraftById?.tenantName ?? '';
            const rsiContacts =
              data?.TenantDraftById?.rsiContactInfo?.rsiContacts ?? [];
            if (id !== '' && tenantName !== '' && rsiContacts.length > 0) {
              newCompleted[0] = true;
              setRsiContactsValid(true);
            }

            const countryCode =
              data?.TenantDraftById?.contractualInfo?.tenantInfo?.countryCode ??
              '';
            const customer = {
              name:
                data?.TenantDraftById?.contractualInfo?.tenantInfo?.customer
                  ?.name ?? '',
              id:
                data?.TenantDraftById?.contractualInfo?.tenantInfo?.customer
                  ?.id ?? '',
            };

            const managementAdminEmail =
              data?.TenantDraftById?.contractualInfo?.tenantInfo
                ?.managementAdminEmail ?? '';
            const managementAdminName =
              data?.TenantDraftById?.contractualInfo?.tenantInfo
                ?.managementAdminName ?? '';

            const contacts =
              data?.TenantDraftById?.contractualInfo?.tenantInfo?.contacts ??
              [];

            const customerName = customer.name;
            const customerId = customer.id;

            if (
              countryCode !== '' &&
              customerName !== '' &&
              customerId !== '' &&
              managementAdminEmail !== '' &&
              managementAdminName !== '' &&
              contacts.length > 0
            ) {
              newCompleted[1] = true;
              setTenantInfoValid(true);
            }

            const locationName =
              data?.TenantDraftById?.localeInfo?.locationName ?? '';
            const currency = data?.TenantDraftById?.localeInfo?.currency ?? '';
            const languages =
              data?.TenantDraftById?.localeInfo?.languages ?? [];
            if (
              locationName !== '' &&
              currency !== '' &&
              languages.length > 0
            ) {
              newCompleted[2] = true;
              setTenancyConfigValid(true);
            }

            const modules =
              data?.TenantDraftById?.contractualInfo?.modules ?? [];
            if (modules.length > 1) {
              newCompleted[3] = true;
              setIsModulesSelected(true);
            }

            setCompleted((completes) => ({ ...completes, ...newCompleted }));
          }

          localStorage.removeItem('tenantName');
          if (data) {
            setTenant({
              id: data.TenantDraftById.id ? data.TenantDraftById.id : '',
              tenantName: data.TenantDraftById.tenantName
                ? data.TenantDraftById.tenantName
                : '',
              rsiContactInfo: {
                rsiContacts: data?.TenantDraftById?.rsiContactInfo?.rsiContacts
                  ?.length
                  ? data.TenantDraftById.rsiContactInfo.rsiContacts.map(
                      (contact) => {
                        return {
                          userContactType: contact?.userContactType ?? '',
                          userFullName: contact?.userFullName ?? '',
                          userId: contact?.userId ?? '',
                          userEmail: contact?.userEmail ?? '',
                          userPhone: contact?.userPhone ?? '',
                        };
                      }
                    )
                  : [],
              },
              contractualInfo: {
                modules: data?.TenantDraftById?.contractualInfo?.modules?.length
                  ? data.TenantDraftById.contractualInfo.modules.map(
                      (module) => {
                        return {
                          moduleId: module?.moduleId ?? null,
                          moduleName: module?.moduleName ?? '',
                          selected: module?.moduleId > 0,
                        };
                      }
                    )
                  : [],

                tenantInfo: {
                  countryCode:
                    data?.TenantDraftById?.contractualInfo?.tenantInfo
                      ?.countryCode ?? '',
                  customer: {
                    name:
                      data?.TenantDraftById?.contractualInfo?.tenantInfo
                        ?.customer?.name ?? '',
                    id:
                      data?.TenantDraftById?.contractualInfo?.tenantInfo
                        ?.customer?.id ?? '',
                  },
                  managementAdminEmail:
                    data?.TenantDraftById?.contractualInfo?.tenantInfo
                      ?.managementAdminEmail ?? '',
                  managementAdminName:
                    data?.TenantDraftById?.contractualInfo?.tenantInfo
                      ?.managementAdminName ?? '',
                  contacts: data?.TenantDraftById?.contractualInfo?.tenantInfo
                    ?.contacts.length
                    ? data.TenantDraftById.contractualInfo.tenantInfo.contacts.map(
                        (contact) => {
                          return {
                            userContactType: contact?.userContactType ?? '',
                            userFullName: contact?.userFullName ?? '',
                            userId: contact?.userId ?? '',
                            userEmail: contact?.userEmail ?? '',
                            userPhone: contact?.userPhone ?? '',
                            userOrganization: contact?.userOrganization ?? '',
                            userLastName: contact?.userLastName ?? '',
                            userFirstName: contact?.userFirstName ?? '',
                          };
                        }
                      )
                    : [],
                },
              },
              localeInfo: {
                locationName:
                  data?.TenantDraftById?.localeInfo?.locationName ?? '',
                currency: data?.TenantDraftById?.localeInfo?.currency ?? '',
                languages: data?.TenantDraftById?.localeInfo?.languages.length
                  ? data.TenantDraftById.localeInfo.languages.map(
                      (lenguage) => {
                        return {
                          languageName: lenguage?.languageName ?? '',
                        };
                      }
                    )
                  : [],

                timeZones: data?.TenantDraftById?.localeInfo?.timeZones.length
                  ? data.TenantDraftById.localeInfo.timeZones.map(
                      (timeZone) => {
                        return {
                          timeZoneName: timeZone?.timeZoneName ?? '',
                        };
                      }
                    )
                  : [],
              },
            });
            const tenantName = data?.TenantDraftById?.tenantName ?? '';
            localStorage.setItem('tenantName', JSON.stringify(tenantName));
          }
        },
        onError: (error) => {
          const message = extractMeaningfulMessage(
            error,
            t('pages.manageReusableContent.networkError')
          );
          dispatch(
            addMessage({
              message: message,
              type: MessageType.Error,
              actionType: MessageActionType.None,
            })
          );
        },
      }
    );

  useEffect(() => {
    if (tenantId) {
      refetch();
    } else {
      setReset(true);
    }
  }, [refetch, tenantId]);

  const handleOpenModal = () => setOpenModal(true);

  const handleCloseModal = () => {
    setOpenModal(false);
    navigate(`/${selectedModule}/tenantList`);
  };

  const handleLocalInfo = (newLocaleInfo: LocaleInfo) => {
    setTenant({ ...tenant, localeInfo: newLocaleInfo });
  };

  const handleSaveTenantDraft = (isDraft: boolean) => {
    const { id, ...customTenant } = tenant;

    const contractualInfo = {
      ...tenant.contractualInfo,
      modules: [...tenant.contractualInfo.modules, ...[mandatoryModule]].filter(
        (mod: Modules) => mod.selected === true
      ),
    };

    const customTenantParams = {
      tenantName: customTenant.tenantName,
      rsiContactInfo: customTenant.rsiContactInfo,
      contractualInfo: contractualInfo,
      localeInfo: customTenant.localeInfo,
    };

    const tenantParams = {
      id: tenant.id,
      tenantName: tenant.tenantName,
      rsiContactInfo: tenant.rsiContactInfo,
      contractualInfo: contractualInfo,
      localeInfo: tenant.localeInfo,
    };

    const tenantWithId = tenantId ? tenantId : tenant.id;

    localStorage.removeItem('customerSelected');

    saveTenantDraft.mutate(
      {
        tenantDraft: tenantWithId !== '' ? tenantParams : customTenantParams,
      },
      {
        onSuccess: (response: SaveTenantDraftResponse) => {
          if (response.SaveTenantDraft.tenantId) {
            const tenantIdToSave = response.SaveTenantDraft.tenantId;
            localStorage.setItem('tenantId', JSON.stringify(tenantIdToSave));
            setTenant({ ...tenant, id: tenantIdToSave });
          }
          if (isDraft) {
            dispatch(
              addMessage({
                message: t('components.message.success'),
                type: MessageType.Success,
                actionType: MessageActionType.None,
              })
            );
            delay(3000).then(() =>
              navigate(`/${selectedModule}/${tenantListUrl}`)
            );
          } else {
            localStorage.setItem('createNewTenant', JSON.stringify(true));
          }
        },
        onError: (response) => {
          dispatch(
            addMessage({
              message: getErrorMsg(response),
              type: MessageType.Error,
              actionType: MessageActionType.None,
            })
          );
        },
      }
    );
  };

  const handleCreateTenant = () => {
    let idToCreateNewTenant = '';
    if (tenant.id && tenant.id !== '') {
      idToCreateNewTenant = tenant.id;
    } else if (tenant.id && tenantId !== '') {
      idToCreateNewTenant = tenantId;
    } else if (localStorage.getItem('tenantId')) {
      idToCreateNewTenant = JSON.parse(localStorage.getItem('tenantId') ?? '');
    }
    if (idToCreateNewTenant && idToCreateNewTenant !== '') {
      createTenant.mutate(
        {
          createTenantId: idToCreateNewTenant,
        },
        {
          onSuccess: () => {
            if (localStorage.getItem('createNewTenant')) {
              localStorage.removeItem('createNewTenant');
              handleOpenModal();
            }
          },
          onError: (response) => {
            dispatch(
              addMessage({
                message: getErrorMsg(response),
                type: MessageType.Error,
                actionType: MessageActionType.None,
              })
            );
          },
        }
      );
    }
  };

  const isSaveAndCreateEnabled = useMemo(() => {
    return (
      isTenantInfoValid &&
      isRsiContactsValid &&
      isTenancyConfigValid &&
      isModulesSelected
    );
  }, [
    isTenantInfoValid,
    isRsiContactsValid,
    isTenancyConfigValid,
    isModulesSelected,
  ]);

  const validateSteps = useCallback(() => {
    let isValid = true;
    switch (currentStep) {
      case 0:
        isValid = isRsiContactsValid;
        break;
      case 1:
        isValid = isTenantInfoValid;
        break;
      case 2:
        isValid = isTenancyConfigValid;
        break;
      case 3:
        isValid = isModulesSelected;
        break;
      default:
        isValid = false;
        break;
    }
    setStepPassedValidation(isValid);
  }, [
    currentStep,
    isRsiContactsValid,
    isTenancyConfigValid,
    isTenantInfoValid,
    isModulesSelected,
    setStepPassedValidation,
  ]);

  useEffect(() => {
    const newCompleted: { [k: number]: boolean } = {};
    switch (currentStep) {
      case 0:
        newCompleted[currentStep] = isRsiContactsValid;
        break;
      case 1:
        newCompleted[currentStep] = isTenantInfoValid;
        break;
      case 2:
        newCompleted[currentStep] = isTenancyConfigValid;
        break;
      case 3:
        newCompleted[currentStep] = isModulesSelected;
        break;
      default:
        break;
    }

    setCompleted((completes) => ({ ...completes, ...newCompleted }));
  }, [
    currentStep,
    isRsiContactsValid,
    isTenantInfoValid,
    isTenancyConfigValid,
    isModulesSelected,
    setCompleted,
  ]);

  useEffect(() => {
    const primaryContact = tenant?.rsiContactInfo?.rsiContacts?.find(
      ({ userContactType }) => userContactType === 'Primary'
    );

    if (onePrimaryContact === false) {
      let newTenant = { ...tenant };
      let newPrimaryContact = false;
      if (primaryContact !== undefined) {
        newPrimaryContact = true;
        const { userFullName, userEmail } = primaryContact;
        newTenant = {
          ...tenant,
          contractualInfo: {
            ...tenant.contractualInfo,
            tenantInfo: {
              ...tenant.contractualInfo.tenantInfo,
              managementAdminName: `${userFullName}`,
              managementAdminEmail: `${userEmail}`,
            },
          },
        };
        setTenant(newTenant);
        setOnePrimaryContact(newPrimaryContact);
      } else if (primaryContact === undefined && firstRender == false) {
        newTenant = {
          ...tenant,
          contractualInfo: {
            ...tenant.contractualInfo,
            tenantInfo: {
              ...tenant.contractualInfo.tenantInfo,
              managementAdminName: '',
              managementAdminEmail: '',
            },
          },
        };
        setTenant(newTenant);
        setOnePrimaryContact(newPrimaryContact);
        setFirstRender(true);
      }
    }
  }, [tenant, onePrimaryContact, firstRender]);

  useEffect(() => {
    validateSteps();
  }, [validateSteps]);

  return !isFetching ? (
    <CardContent>
      <Grid container spacing={2}>
        <Grid item xs={20} mt={4}>
          <HorizontalNonLinearStepper
            completed={completed}
            steps={[
              {
                title: t('pages.tenantConfig.rsiContacts.title'),
                description: t('pages.tenantConfig.rsiContacts.description'),
              },
              {
                title: t('pages.tenantConfig.tenantInfo.title'),
                description: t('pages.tenantConfig.tenantInfo.description'),
              },
              {
                title: t('pages.tenantConfig.tenancyConfiguration.title'),
              },
              {
                title: t('pages.tenantConfig.selectModules.title'),
              },
            ]}
            isDone={isDone}
            setIsDone={setIsDone}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            handleCurrentStep={(activeStep: number) => {
              setCurrentStep(activeStep);
            }}
            handleSave={handleSaveTenantDraft}
            handleCreate={handleCreateTenant}
            handleReset={handleReset}
            isCompleteStepBtnDisabled={!stepPassedValidation}
            isSaveAndCreateDisabled={isSaveAndCreateEnabled}
          >
            {currentStep === 0 && (
              <RsiContacts
                rsiContactInfo={tenant.rsiContactInfo}
                handleChange={(newRsiContactInfo: RsiContactInfo) => {
                  if (newRsiContactInfo?.rsiContacts) {
                    const newTenant = {
                      ...tenant,
                      rsiContactInfo: newRsiContactInfo,
                    };

                    if (newRsiContactInfo.rsiContacts.length === 0) {
                      setFirstRender(false);
                    }
                    setOnePrimaryContact(false);
                    setTenant(newTenant);
                  }
                }}
                handleValidation={setRsiContactsValid}
              />
            )}
            {currentStep === 1 && (
              <TenantInformation
                reset={reset}
                setReset={setReset}
                tenantInfo={tenant.contractualInfo.tenantInfo}
                tenantName={tenant.tenantName}
                validTenantName={validTenantName}
                showTenantNameError={showTenantNameError}
                setValidTenantName={setValidTenantName}
                setShowTenantNameError={setShowTenantNameError}
                handleChange={(newTenantInfo, tenantName) => {
                  setTenant({
                    ...tenant,
                    tenantName,
                    contractualInfo: {
                      modules: tenant.contractualInfo.modules,
                      tenantInfo: newTenantInfo,
                    },
                  });
                }}
                handleValidation={setTenantInfoValid}
              />
            )}
            {currentStep === 2 && (
              <TenancyConfig
                localeInfo={tenant.localeInfo}
                securityInfo={
                  tenant.securityInfo !== undefined
                    ? tenant.securityInfo
                    : tenantDefault.securityInfo
                }
                tenantInfo={tenant.contractualInfo.tenantInfo}
                handleChange={handleLocalInfo}
                handleValidation={setTenancyConfigValid}
              />
            )}

            {currentStep === 3 && (
              <SelectModules
                contractualInfoModules={tenant.contractualInfo.modules}
                handleChange={(modules: Modules[]) => {
                  setTenant({
                    ...tenant,
                    contractualInfo: {
                      modules,
                      tenantInfo: tenant.contractualInfo.tenantInfo,
                    },
                  });
                }}
                handleValidation={setIsModulesSelected}
              />
            )}

            <Modal
              open={openModal}
              aria-labelledby="create-new-workflow"
              aria-describedby="create-new-workflow"
            >
              <Box sx={style}>
                <TenantDialog handleCloseModal={handleCloseModal} />
              </Box>
            </Modal>
          </HorizontalNonLinearStepper>
        </Grid>
      </Grid>
    </CardContent>
  ) : (
    <Loading />
  );
}

export default TenantConfig;
