import { CSSProperties } from 'react';

export const widthHeight: CSSProperties = {
  width: '1.5em',
  height: '1.5em',
};

export const fontSize = '1em';

export const cancelButton = {
  ...widthHeight,
  backgroundColor: 'error.main',
  '&:hover': {
    backgroundColor: 'error.main',
  },
  '&:disabled': {
    backgroundColor: 'grey1.main',
  },
};

export const checkButton = {
  ...widthHeight,
  backgroundColor: 'secondaryGreen.main',
  '&:hover': {
    backgroundColor: 'secondaryGreen.main',
  },
  '&:disabled': {
    backgroundColor: 'grey1.main',
  },
};

export const editButton = {
  ...widthHeight,
  color: 'linkBlue.main',
};

export const icon = {
  color: 'white.main',
  fontSize,
};

export const copyButton = {
  color: 'primary.main',
};

export const deleteButton = {
  ...widthHeight,
  float: 'right',
  color: 'linkBlue.main',
};

export const recordIcons = {
  color: 'linkBlue.main',
};

export const lockIcon = {
  pointerEvents: 'none',
};

export const checkIcon = {
  color: 'secondaryGreen.main',
  ml: '-.5em',
};

export const cancelIcon = {
  color: 'error.main',
  ml: '-.5em',
};

export const expandButton = {
  fontSize,
  color: 'linkBlue.main',
};

export const collapseButton = {
  fontSize,
  color: 'linkBlue.main',
  transform: 'rotate(180deg)',
};

export const ootbIcon = {
  margin: '6px',
  display: 'inline-flex',
  color: 'grey1.main',
};
