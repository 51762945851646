import { useState } from 'react';
import { Grid, SelectChangeEvent } from '@mui/material';

import { Box, useTheme } from '@mui/system';
import { useTranslation } from 'react-i18next';

import {
  AdjustmentDetailResponse,
  FinancialTransactionDetail,
  ImpactTypesResponse,
} from 'generated/graphql';

import { ColumnDef } from '@tanstack/react-table';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import CurrencyCell from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/TableCells/CurrencyCell';
import DataCard from 'components/DataCard';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import DateCell from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/TableCells/DateCell';
import { Link, useParams } from 'react-router-dom';
import { Select, DataDisplay } from '@revenue-solutions-inc/revxcoreui';
import { SelectType } from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import { formatCurrency } from 'common/helpers';
import { useAppSelector } from 'redux/hooks';
import { selectViewStyle } from '../accounts/maintain/PeriodTransactions/PeriodTransactions';
import ApproveDenyAdjustment from '../accounts/maintain/ApproveDenyAdjustment/ApproveDenyAdjustment';
const Translation = () => {
  const { t } = useTranslation();
  return t;
};

interface AdjustmentDetailProps {
  adjustment?: AdjustmentDetailResponse;
}

export const impactColumn: ColumnDef<FinancialTransactionDetail> = {
  id: 'impactType',
  accessorKey: 'name',
  enableSorting: false,
  header: () => (
    <HeaderColumnNext
      localization={Translation()?.(
        'pages.paymentDetails.application.impactType'
      )}
    />
  ),
  cell: ({ getValue }) => getValue() as string,
};

const amountColumn: ColumnDef<FinancialTransactionDetail> = {
  id: 'amount',
  accessorKey: 'amount',
  enableSorting: false,
  header: () => (
    <HeaderColumnNext
      localization={Translation()?.('pages.paymentDetails.application.amount')}
    />
  ),
  cell: ({ getValue }) => (
    <Box sx={{ width: '3.5em' }}>
      <CurrencyCell
        invalidValue={Translation()?.('pages.tableCell.invalidValue')}
        amountString={getValue() as string}
      />
    </Box>
  ),
};
const percentColumn: ColumnDef<FinancialTransactionDetail> = {
  id: 'amount',
  accessorKey: 'amount',
  enableSorting: false,
  header: () => (
    <HeaderColumnNext
      localization={Translation()?.('pages.adjustmentDetails.percent')}
    />
  ),
  cell: ({ getValue }) => getValue() + '%',
};

function AdjustmentDetails({ adjustment }: AdjustmentDetailProps) {
  const { t } = useTranslation();
  const module = useAppSelector((state) => state.user.module);
  const { adjustmentId } = useParams() as {
    adjustmentId: string;
  };
  const theme = useTheme();
  const [selectedView, setSelectedView] = useState<string>('ImpactView');
  const viewOptions: SelectType[] = [
    { key: 'ImpactView', desc: t('pages.adjustmentDetails.impactView') },
    {
      key: 'TransactionView',
      desc: t('pages.adjustmentDetails.transactionView'),
    },
  ];

  const isPendingApproval = adjustment?.isPendingApproval;
  const canUserApprove = adjustment?.canUserApprove;

  const handleChange = (event: SelectChangeEvent<string | number>) => {
    setSelectedView(String(event.target.value));
  };

  const transactionColumns: ColumnDef<FinancialTransactionDetail>[] = [
    {
      id: 'id',
      accessorKey: 'id',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.periodDetails.transactions.transactionId')}
        />
      ),
      cell: ({ getValue }) => {
        return (
          <Link
            to={{
              pathname: `/${module}/financialtransaction/${getValue()}`,
            }}
            state={{
              prevPath: `/adjustmentdetail/${adjustmentId}`,
              prevPageName: t('pages.adjustmentDetails.title'),
            }}
            style={{ color: theme.palette.linkBlue.dark }}
          >
            {getValue() as string}
          </Link>
        );
      },
    },
    {
      id: 'group',
      accessorKey: 'financialTransactionGroupId',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.periodDetails.transactions.transactionGroup')}
        />
      ),
      cell: ({ getValue }) => {
        return (
          <Link
            to={{
              pathname: `/${module}/viewfinancialtransgroup/${getValue()}`,
            }}
            state={{
              prevPath: `/adjustmentdetail/${adjustmentId}`,
              prevPageName: t('pages.adjustmentDetails.title'),
            }}
            style={{ color: theme.palette.linkBlue.dark }}
          >
            {getValue() as string}
          </Link>
        );
      },
    },
    {
      id: 'type',
      accessorKey: 'financialTransactionType',
      header: () => (
        <HeaderColumnNext
          localization={Translation()?.(
            'pages.periodDetails.transactions.type'
          )}
        />
      ),
    },
    {
      id: 'amount',
      accessorKey: 'amount',
      header: () => (
        <HeaderColumnNext
          localization={Translation()?.(
            'pages.paymentDetails.application.amount'
          )}
        />
      ),
      cell: ({ getValue }) => (
        <Box sx={{ width: '3.5em' }}>
          <CurrencyCell
            invalidValue={Translation()?.('pages.tableCell.invalidValue')}
            amountString={getValue() as string}
          />
        </Box>
      ),
    },
    {
      id: 'effectiveDate',
      accessorKey: 'effectiveDate',
      header: () => (
        <HeaderColumnNext
          localization={Translation()?.(
            'pages.FinancialTransaction.effectiveDate'
          )}
        />
      ),
      cell: ({ getValue }) => <DateCell dateString={getValue() as string} />,
    },
  ];
  function getColumns() {
    if (adjustment?.reductionType?.toLocaleLowerCase() === 'percent') {
      return [impactColumn, percentColumn];
    } else {
      return [impactColumn, amountColumn];
    }
  }

  const selectViewDropdown = (
    <Box sx={selectViewStyle}>
      <Select
        label=""
        id="selectAdjustmentDetailView"
        onChange={handleChange}
        value={selectedView}
        options={viewOptions}
      />
    </Box>
  );

  return (
    <Grid container>
      <Grid item xs={12} xl={12}>
        <DataCard
          title={t('pages.adjustmentDetails.title')}
          avatar={<AccountBalanceIcon />}
          action={
            isPendingApproval && canUserApprove ? (
              <ApproveDenyAdjustment adjustmentId={adjustmentId} />
            ) : (
              <></>
            )
          }
          children={
            <>
              <Grid container sx={{ display: 'inline-flex' }}>
                <Grid item xs={3} mt={1}>
                  <DataDisplay
                    id="adjustmentType-34324"
                    label={t('pages.adjustmentDetails.adjustmentType')}
                    data={adjustment?.adjustmentType ?? '-'}
                  />
                </Grid>
                <Grid item xs={3} mt={1}>
                  <DataDisplay
                    id="adjustmentReason-090090"
                    label={t('pages.adjustmentDetails.adjustmentReason')}
                    data={adjustment?.adjustmentReason ?? '-'}
                  />
                </Grid>
                <Grid item xs={3} mt={1}>
                  <DataDisplay
                    sx={{ textTransform: 'capitalize' }}
                    id="reductionType-0999"
                    label={t('pages.adjustmentDetails.reductionType')}
                    data={adjustment?.reductionType ?? '-'}
                  />
                </Grid>
                <Grid item xs={3} mt={1}>
                  <DataDisplay
                    id="adjustmentTotal-0989"
                    label={t('pages.adjustmentDetails.adjustmentTotal')}
                    data={
                      adjustment?.financialTransactions?.amount?.toString()
                        ? formatCurrency(
                            adjustment?.financialTransactions?.amount?.toString()
                          )
                        : '-'
                    }
                  />
                </Grid>
                <Grid item xs={3} mt={2}>
                  <DataDisplay
                    id="adjustmentStatus"
                    label={t(
                      'pages.periodDetails.adjustments.adjustmentStatus'
                    )}
                    data={adjustment?.adjustmentStatus ?? '-'}
                  />
                </Grid>
              </Grid>
            </>
          }
        />
      </Grid>
      <Grid item xs={12} xl={12} mt={3}>
        <DataCard
          title={t('pages.FinancialTransaction.transactionDetails')}
          avatar={<AccountBalanceIcon sx={{ fill: 'primary.main' }} />}
          action={selectViewDropdown}
          children={
            <>
              <Box
                sx={
                  !adjustment?.financialTransactions ? { display: 'none' } : {}
                }
              >
                <DefaultDataTableNext
                  columns={
                    selectedView.toLocaleLowerCase() === 'impactview'
                      ? (getColumns() as ColumnDef<Record<string, unknown>>[])
                      : (transactionColumns as ColumnDef<
                          Record<string, unknown>
                        >[])
                  }
                  data={
                    selectedView.toLocaleLowerCase() === 'impactview'
                      ? [...(adjustment?.applyTo as Array<ImpactTypesResponse>)]
                      : adjustment?.financialTransactions?.transactions ?? []
                  }
                  enableGlobalFilter={false}
                />
              </Box>
              {!adjustment?.financialTransactions &&
                t('pages.paymentDetails.noInfo')}
            </>
          }
        />
      </Grid>
    </Grid>
  );
}
export default AdjustmentDetails;
