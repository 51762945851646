import { getValueType } from 'common/entity';
import { Attribute, GetAssetByIdQuery, Group } from 'generated/graphql';
import {
  AssetAddress,
  AssetIdentifier,
  AssetName,
  AssetOther,
  AssetForm,
  AssetResponse,
} from 'types/assets';
import { ExtendedAttributeValues, Section } from 'types/forms';
import { toStringDate } from 'utils/date-util';

import { getExtendedAttributeGroup } from './entityManagementUtils';
import { getAsset, getAssetResponse } from './defaults/asset';
import {
  AddressFields,
  IdentifierFields,
  NameFields,
  OtherFields,
} from './fields/assets';

const assetFields: Section[] = [
  AddressFields,
  NameFields,
  IdentifierFields,
  OtherFields,
];
export const dateAttributes = ['commenceDate', 'ceaseDate'];
export type AssetSectionNames = 'names' | 'identifiers' | 'addresses' | 'other';
export type AssetTypes =
  | AssetName
  | AssetAddress
  | AssetIdentifier
  | AssetOther;
export const sectionNames: string[] = [
  'other',
  'names',
  'identifiers',
  'addresses',
];

const getAmountOfElements = (data: AssetForm, section: string): number => {
  const sectionNameKey = section as keyof typeof data;
  const sectionSelected = data?.[sectionNameKey];
  return sectionSelected?.length ?? 0;
};

const deleteEmptySections = (data: AssetForm) => {
  sectionNames.forEach((firstLevel) => {
    const sectionKey = firstLevel as keyof typeof data;
    const sectionData = data[sectionKey];
    if (sectionData) {
      let isSectionEmpty = false;
      sectionData.forEach((sectionElement) => {
        const sectionValues = Object.values(sectionElement);
        const filledSection = sectionValues.find((value) => {
          return value !== '' && value !== 'true';
        });
        if (filledSection == undefined) isSectionEmpty = true;
      });
      if (isSectionEmpty) {
        delete data[sectionKey];
      }
    }
  });
};

const getValue = (
  data: AssetForm,
  section: string,
  attribute: string,
  index: number
): string => {
  const sectionNameKey = section as keyof typeof data;
  const sectionSelected = data[sectionNameKey];
  if (sectionSelected) {
    const sectionData = sectionSelected[index];
    const targetAttribute = attribute as keyof typeof sectionData;
    if (dateAttributes.includes(attribute)) {
      if (
        sectionData[targetAttribute] === 'Invalid Date' ||
        sectionData[targetAttribute] === null
      ) {
        if (attribute === 'commenceDate')
          return toStringDate(data.other?.[0].commenceDate);
        return '';
      }
      return toStringDate(sectionData[targetAttribute]);
    }
    return sectionData[targetAttribute]?.toString() ?? '';
  }
  return '';
};

const getAttributes = (section: string): string[] => {
  const sectionNameKey = section as keyof typeof getAsset;
  const sectionAttributes = getAsset?.[sectionNameKey];
  if (sectionAttributes) {
    return Object.keys(sectionAttributes[0]);
  }
  return [];
};

export const findSection = (sectionName: string): Section | undefined => {
  return assetFields.find((fields) => fields.sectionIdentifier === sectionName);
};

export const getDisplayName = (attribute: string, section: string): string => {
  if (!attribute || !section) return '';
  let displayName = attribute;
  let field;
  switch (section) {
    case NameFields.sectionIdentifier:
      field = NameFields.fields.find((f) => f.fieldIdentifier === attribute);
      break;
    case IdentifierFields.sectionIdentifier:
      field = IdentifierFields.fields.find(
        (f) => f.fieldIdentifier === attribute
      );
      break;
    case AddressFields.sectionIdentifier:
      field = AddressFields.fields.find((f) => f.fieldIdentifier === attribute);
      break;
    case OtherFields.sectionIdentifier:
      field = OtherFields.fields.find((f) => f.fieldIdentifier === attribute);
      break;
    default:
      break;
  }
  displayName = !field ? displayName : field.label;
  return displayName;
};

export const getAssetPrimaryItem = (
  data: GetAssetByIdQuery,
  section: string
): Attribute[] => {
  const primaryGroup = data.GetAssetById?.group
    ?.filter((group) => group?.groupName?.toLowerCase() === section)
    .filter((attr) =>
      attr.attribute.find(
        (att) =>
          att.attributeName === 'isPrimary' && att.attributeValue === 'true'
      )
    );
  if (primaryGroup) return primaryGroup[0].attribute;
  return [];
};

export const buildAssetRequest = (
  data: AssetForm,
  assetType: string | undefined,
  platformConfigId: string | undefined,
  extendedData: ExtendedAttributeValues[],
  assetId?: string | undefined
): AssetResponse => {
  const assetResponse: AssetResponse = getAssetResponse;
  // Delete empty sections
  deleteEmptySections(data);
  // First level data
  assetResponse.id = assetId;
  assetResponse.platformConfigurationId = platformConfigId;
  assetResponse.assetCreationSourceId = data.other?.[0].assetCreationSource;
  assetResponse.assetType = assetType;
  assetResponse.primaryIdentifierType = data.identifiers?.[0].IdType;
  assetResponse.primaryIdentifier = data.identifiers?.[0].value;
  assetResponse.commenceDate = toStringDate(data.other?.[0].commenceDate);
  if (data.other?.[0].ceaseDate !== null) {
    if (String(data.other?.[0].ceaseDate) === 'Invalid Date') {
      assetResponse.ceaseDate = null;
    } else assetResponse.ceaseDate = toStringDate(data.other?.[0].ceaseDate);
  } else assetResponse.ceaseDate = data.other?.[0].ceaseDate;
  assetResponse.group = [];
  // Array of groups (each group is a section)
  sectionNames.forEach((section) => {
    const amountOfElements = getAmountOfElements(data, section);
    for (
      let indexElement = 0;
      indexElement < amountOfElements;
      indexElement++
    ) {
      const group: Group = {
        groupName: section,
        groupOrder: '1',
        groupAttributeType: 0,
        repeatingActionLabel: '',
        attribute: [],
      };
      const sectionAttributes = getAttributes(section);
      sectionAttributes.forEach((attribute) => {
        const singleAttribute: Attribute = {
          attributeName: attribute,
          attributeDisplayName: getDisplayName(attribute, section),
          attributeType: getValueType(attribute),
          attributeValue: getValue(data, section, attribute, indexElement),
          repeatingProperties: false,
          possibleValues: null,
          repeatingValue: null,
        };
        group.attribute?.push(singleAttribute);
      });
      assetResponse.group?.push(group);
    }
  });

  // Add extended attributes if existent
  if (extendedData && extendedData.length > 0) {
    const group = getExtendedAttributeGroup(extendedData);
    assetResponse.group?.push(group);
  }
  return assetResponse;
};
