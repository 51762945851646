import { useEffect, useMemo, useState } from 'react';

import { Grid } from '@mui/material';
import {
  AccountBalance,
  ContactMail,
  OpenInNew,
  Savings,
  History,
  Work,
} from '@mui/icons-material';
import { Theme } from '@mui/system';
import Dialog from '@revenue-solutions-inc/revxcoreui/material/controls/Dialog';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import { ConfigurationDomains } from 'common/platformConfigUtils/platformConfigUtils';
import EntityManagementContext from 'components/contexts/EntityManagement';
import DataCard from 'components/DataCard';
import {
  buildAccountForm,
  updateLayoutConfiguration,
} from 'components/entityManagement/common/accountUtils';
import { CoreSchema } from 'components/entityManagement/common/entityManagementUtils';
import EntityAuditHistory from 'components/entityManagement/common/EntityAuditHistory';
import {
  getIdFormats,
  getMaskRules,
  getValidationRules,
} from 'components/entityManagement/common/formatValidations';
import AddLetterInfo from 'components/correspondenceAndHeaderFooter/letter/AddLetterInfo';
import LetterHistory from 'components/correspondenceAndHeaderFooter/letter/LetterHistory';
import WorkflowModal from 'components/entityManagement/common/WorkflowModal';
import BankAccountsList from 'components/bankAccounts/BankAccountsList';
import AddBankAccountModal from 'components/bankAccounts/AddBankAccountModal';
import EditBankAccountModal from 'components/bankAccounts/EditBankAccountModal';
import { WorkHistoryTable } from 'components/Workflow/WorkHistoryTable/WorkHistoryTable';
import { SuppressionsTable } from 'components/Workflow/SuppressionsTable/SuppressionsTable';
import { useFormatSuppressionsDataTable } from 'components/Workflow/SuppressionsTable/useFormatSuppressionsDataTable';
import {
  Account,
  useGetBankAccountsListQuery,
  useGetConfigurationSchemaQuery,
  useGetLookupConfigurationQuery,
  useGetSuppressionsByContextsQuery,
  useGetWorkflowsByContextTypeQuery,
} from 'generated/graphql';
import { useFormatWorkflowDataForTable } from 'hooks/useFormatWorkflowDataForTable';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { WorkflowContextType } from 'types/WorkflowContext';

import AccountDetail from '../AccountDetail';
import AccountSectionView from '../AccountSectionView';
import AccountPeriods from '../AccountPeriods';
import AccountPayments from '../AccountPayments';
import ReportsLogic from '../../../ReportsLogic';

interface Props {
  data: Account;
  refetchData: () => void;
  showEdit?: boolean;
  showAdd?: boolean;
}

export const openModalTheme = (theme: Theme) => ({
  fill: theme.palette.primary.main,
  cursor: 'pointer',
  '&:hover': { backgroundColor: theme.palette.secondary.main },
});

const setIconColor = (theme: Theme) => ({ fill: theme.palette.primary.main });

function AccountView({ data, refetchData }: Props): JSX.Element {
  const CONFIG_DOMAIN = ConfigurationDomains.ReferenceSchema;
  const CORE_DOMAIN = ConfigurationDomains.EntityManagement;
  const { t } = useTranslation();
  const { accountId } = useParams() as { accountId: string };
  const { entityId } = useParams() as {
    entityId: string;
  };
  const { formatDataForTable } = useFormatWorkflowDataForTable();
  const [openAccountData, setOpenAccountData] = useState<boolean>(false);
  const [addWorkflowModalStatus, setAddWorkflowModalStatus] = useState<
    boolean | string
  >(false);
  const [addBankAccountModalOpen, setAddBankAccountModalOpen] =
    useState<boolean>(false);
  const [selectedBankAccountId, setSelectedBankAccountId] =
    useState<string>('');
  const [editBankAccountModalOpen, setEditBankAccountModalOpen] =
    useState<boolean>(false);
  const [isHistoryRefetch, setIsHistoryRefetch] = useState<boolean>(false);

  const { data: accountWorkflowsData } = useGetWorkflowsByContextTypeQuery({
    contextId: data.id as string,
    contextType: WorkflowContextType[WorkflowContextType.Account],
  });

  const accountWorkflowsTableData = formatDataForTable(
    accountWorkflowsData?.GetWorkflowsByContextType ?? []
  );

  const { data: entitySuppressionsData } = useGetSuppressionsByContextsQuery(
    {
      contextId: data.id as string,
      contextType: WorkflowContextType[WorkflowContextType.Account],
    },
    { enabled: !!data?.id }
  );
  const entitySuppressionsTableData = useFormatSuppressionsDataTable(
    entitySuppressionsData?.GetSuppressionsByContexts ?? []
  );

  const {
    data: bankAccountsData,
    isLoading: isBankAccountsDataLoading,
    refetch: bankAccountsRefetch,
  } = useGetBankAccountsListQuery({
    Id: accountId,
    EntityInfoId: entityId,
  });

  const { data: configuredIdTypes } = useGetLookupConfigurationQuery({
    configurationDomain: CONFIG_DOMAIN,
    configurationType: 'IdType',
  });

  useGetConfigurationSchemaQuery(
    {
      configurationDomain: CORE_DOMAIN,
      configurationName: CoreSchema.Account,
    },
    {
      enabled: data.accountType !== '',
      onSuccess: (layoutConfigData) =>
        updateLayoutConfiguration(layoutConfigData.GetConfigurationSchema),
    }
  );

  useEffect(() => {}, [isHistoryRefetch]);

  const createWorkflowBtn = (): JSX.Element => {
    return (
      <Button
        id="create-workflow-link"
        type="secondary"
        variant="text"
        onClick={() => setAddWorkflowModalStatus(true)}
      >
        {t('pages.createAccount.buttonTitle')}
      </Button>
    );
  };

  const createSuppressionBtn = (): JSX.Element => {
    return (
      <Button
        id="create-suppression-link"
        type="secondary"
        variant="text"
        onClick={() => setAddWorkflowModalStatus('suppression')}
      >
        {t('pages.createAccount.buttonTitle')}
      </Button>
    );
  };

  const createBankAccountBtn = (): JSX.Element => {
    return (
      <Button
        id="create-bank-account-link"
        type="secondary"
        variant="text"
        onClick={() => setAddBankAccountModalOpen(true)}
      >
        {t('pages.bankAccount.addBankAccount.buttonTitle')}
      </Button>
    );
  };

  const activeTemplatesBtn = (): JSX.Element => {
    return (
      <AddLetterInfo
        isFromTemplate={true}
        isHistoryRefetch={isHistoryRefetch}
        setIsHistoryRefetch={setIsHistoryRefetch}
        entityId={''}
        isFromEntity={false}
        accountId={data.id ?? ''}
      />
    );
  };

  /**
   * Provides the needed values to the Entity/Asset Context
   */
  const contextValue = useMemo(() => {
    return {
      selectedType: data.accountType ?? '',
      selectedIdType: '',
      selectedCommenceDate: data.commenceDate ?? '',
      onTypeChange: () => {},
      onIdTypeChange: () => {},
      onCommenceDateChange: () => {},
      idValidationRules: getValidationRules(configuredIdTypes),
      idFormats: getIdFormats(configuredIdTypes),
      idMaxLengths: getMaskRules(configuredIdTypes),
    };
  }, [data, configuredIdTypes]);

  const GetFullAccountDetail = (): JSX.Element => {
    return (
      <EntityManagementContext.Provider value={contextValue}>
        <AccountSectionView
          data={data}
          refetchData={refetchData}
          accountFormData={buildAccountForm(data)}
        />
      </EntityManagementContext.Provider>
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid
        sx={{ display: 'flex', flexDirection: 'column' }}
        item
        md={5}
        sm={12}
        order={{ xs: 2, sm: 1, md: -1 }}
      >
        <Grid sx={{ marginBottom: '20px' }}>
          <DataCard
            title={t('pages.accountSummary.sectionTitles.accountDetail')}
            avatar={<Savings sx={setIconColor} />}
            action={
              <button
                id="open-account-detail-modal-button"
                onClick={() => setOpenAccountData(!openAccountData)}
                style={{
                  color: '#1B75E2',
                  border: '1px solid #1B75E2',
                  borderRadius: '4px',
                }}
              >
                <OpenInNew
                  data-testid="open-account-detail-modal"
                  sx={openModalTheme}
                  onClick={() => setOpenAccountData(!openAccountData)}
                />
              </button>
            }
            children={<AccountDetail accountData={data} />}
          />

          <Dialog
            id="all-account-data"
            type="passive"
            title={t('pages.accountSummary.sectionTitles.accountDetail')}
            open={openAccountData}
            handleClose={() => setOpenAccountData(!openAccountData)}
            height={580}
            maxWidth="xl"
          >
            {<GetFullAccountDetail />}
          </Dialog>
        </Grid>
        <Grid>
          <DataCard
            title={t('pages.accountSummary.sectionTitles.correspondence')}
            avatar={<ContactMail sx={setIconColor} />}
            action={activeTemplatesBtn()}
            children={
              <LetterHistory
                isHistoryRefetch={isHistoryRefetch}
                entityId={''}
                isFromEntity={false}
                accountId={data.id ?? ''}
              />
            }
          />
          <Grid sx={{ display: 'flex' }} item xs={12} lg={12} mt={3}>
            <DataCard
              title={t('pages.accountSummary.sectionTitles.auditHistory')}
              avatar={<History sx={setIconColor} />}
              children={<EntityAuditHistory tenantId={data.tenantId ?? ''} />}
            />
          </Grid>
          <Grid sx={{ display: 'flex' }} item xs={12} lg={12} mt={3}>
            <ReportsLogic id={data?.primaryIdentifier} contextualTypeId={1} />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        sx={{ display: 'flex', flexDirection: 'column' }}
        item
        sm={12}
        md={7}
        order={{ xs: -1, sm: -1 }}
      >
        <Grid>
          <AccountPeriods accountData={data} />
        </Grid>
        <Grid sx={{ marginY: '1.5em' }}>
          <AccountPayments />
        </Grid>
        <DataCard
          title={t('pages.accountSummary.sectionTitles.workflows')}
          avatar={<Work sx={setIconColor} />}
          children={
            <WorkHistoryTable tableData={accountWorkflowsTableData ?? []} />
          }
          action={createWorkflowBtn()}
        />

        <Grid sx={{ marginTop: '20px' }}>
          <DataCard
            title={t('pages.accountSummary.sectionTitles.bankAccount')}
            avatar={<AccountBalance sx={setIconColor} />}
            children={
              <BankAccountsList
                data={bankAccountsData?.GetBankAccountsList}
                isLoading={isBankAccountsDataLoading}
                setEditBankAccountModalOpen={setEditBankAccountModalOpen}
                setSelectedBankAccountId={setSelectedBankAccountId}
              />
            }
            action={createBankAccountBtn()}
          />
        </Grid>
        <Grid sx={{ marginTop: '20px' }}>
          <DataCard
            title={t('pages.accountSummary.sectionTitles.suppressions')}
            avatar={<Work sx={setIconColor} />}
            children={
              <SuppressionsTable
                tableData={entitySuppressionsTableData ?? []}
              />
            }
            action={createSuppressionBtn()}
          />
        </Grid>
        <WorkflowModal
          selectedGroupName={
            typeof addWorkflowModalStatus === 'string'
              ? addWorkflowModalStatus
              : undefined
          }
          open={!!addWorkflowModalStatus}
          onClose={() => setAddWorkflowModalStatus(false)}
          contextId={data.id ?? ''}
          contextType={WorkflowContextType.Account}
        />
        <AddBankAccountModal
          open={addBankAccountModalOpen}
          onClose={() => setAddBankAccountModalOpen(false)}
          refetch={() => void bankAccountsRefetch()}
        />
        <EditBankAccountModal
          open={editBankAccountModalOpen}
          selectedBankAccountId={selectedBankAccountId}
          onClose={() => {
            setEditBankAccountModalOpen(false);
            setSelectedBankAccountId('');
          }}
          refetch={() => bankAccountsRefetch()}
        />
      </Grid>
    </Grid>
  );
}

export default AccountView;
