import { useRef, useEffect, useMemo, useState } from 'react';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, useTheme } from '@mui/system';
import { DatePicker } from '@revenue-solutions-inc/revxcoreui';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import Input from '@revenue-solutions-inc/revxcoreui/material/controls/Input';
import Select, {
  SelectType,
} from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import { ColumnDef } from '@tanstack/react-table';
import Loading from 'components/Loading';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useAppSelector } from 'redux/hooks';
import { InputAdornment, Typography } from '@mui/material';
import DateCell from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/TableCells/DateCell';
import { Fields, SearchName } from '../../SearchConfig';
import { CollapseProp } from '../AdvancedSearch';

type KeyValue = {
  [key: string]: string | number | null;
};

interface SearchOptionsProps {
  search: SearchName;
  setResults: React.Dispatch<React.SetStateAction<JSX.Element | JSX.Element[]>>;
  collapse: CollapseProp;
  setCollapse: React.Dispatch<React.SetStateAction<CollapseProp[]>>;
}

interface CollapseParamsProps {
  show: boolean;
}

interface SelectCompProp {
  label: string;
  options?: SelectType[];
  stateName: string;
  id?: string;
  dataType?: string;
}

function SearchOptions({
  search,
  setResults,
  collapse,
  setCollapse,
}: SearchOptionsProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [searchOp, setSearchOp] = useState<KeyValue>({});
  const searchState = useRef<KeyValue | null>(null);
  const [click, setClick] = useState(false);
  const module = useAppSelector((state) => state.user.module);

  useEffect(() => {
    if (search.state) {
      setSearchOp(search.state as KeyValue);

      if (!searchState.current) {
        searchState.current = search.state as KeyValue;
      }
    }
  }, [search]);

  const SelectComp = ({
    label,
    options,
    stateName,
  }: SelectCompProp): JSX.Element => {
    return (
      <Select
        id={`${label.replaceAll(' ', '')}-id`}
        label={label}
        options={options ?? []}
        value={searchOp?.[stateName] ?? 'Choose an option'}
        onChange={(e) => {
          setSearchOp({
            ...searchOp,
            [stateName]: e.target.value as string,
          });
        }}
        sx={{ mr: 1 }}
      />
    );
  };

  const DatepickerComp = ({
    label,
    stateName,
    id,
  }: SelectCompProp): JSX.Element => {
    const [keyboardValue, setKeyboardValue] = useState<string | undefined>(
      undefined
    );
    const [value, setValue] = useState<Date | null>(
      searchOp?.[stateName] ? new Date(`${searchOp[stateName]} 00:00:00`) : null
    );

    const handleDateChange = (
      date: Date | null,
      keyboardInputValue: string | undefined
    ) => {
      setValue(date);
      setKeyboardValue(keyboardInputValue);
      if (!keyboardInputValue && date) {
        setSearchOp({
          ...searchOp,
          [stateName]: `${format(new Date(date), 'yyyy-MM-dd')}`,
        });
      } else if (!keyboardInputValue && !date) {
        setSearchOp({
          ...searchOp,
          [stateName]: '',
        });
      }
    };

    return (
      <DatePicker
        id={`datePicker-${id}`}
        label={label}
        value={value}
        handleChange={handleDateChange}
        onBlur={() => {
          if (
            keyboardValue &&
            keyboardValue.split('/').length - 1 === 2 &&
            keyboardValue.split('//').length - 1 === 0
          ) {
            setSearchOp({
              ...searchOp,
              [stateName]: `${format(new Date(keyboardValue), 'yyyy-MM-dd')}`,
            });
          }
        }}
        sx={{ mr: 1 }}
      />
    );
  };

  const { data, refetch, isFetching, isError } = search.searchQuery(
    { [search.searchParam]: searchOp },
    { enabled: false }
  );

  const columns: ColumnDef<KeyValue>[] = useMemo(() => {
    return search.columns.map((col) => {
      if (col.clickable) {
        return {
          id: col.accessorKey,
          accessorKey: col.accessorKey,
          header: () => <HeaderColumnNext localization={col.columnName} />,
          cell: ({ row }) => {
            return (
              <Link
                to={{
                  pathname: `/${module}${col.route}/${
                    row.original[col.routeParam ? col.routeParam : 'id']
                  }`,
                }}
                style={{ color: theme.palette.linkBlue.dark }}
              >
                {row.original[col.accessorKey] ?? col.columnValue}
              </Link>
            );
          },
        };
      }
      if (col.isDate) {
        return {
          id: col.accessorKey,
          accessorKey: col.accessorKey,
          header: () => <HeaderColumnNext localization={col.columnName} />,
          cell: ({ getValue }) => {
            return <DateCell dateString={getValue() as string} />;
          },
        };
      }
      return {
        id: col.accessorKey,
        accessorKey: col.accessorKey,
        header: () => <HeaderColumnNext localization={col.columnName} />,
      };
    });
  }, [search.columns, module, theme.palette.linkBlue.dark]);

  useEffect(() => {
    if (data && !isFetching && click) {
      setResults(
        <DefaultDataTableNext
          columns={columns as ColumnDef<Record<string, unknown>, unknown>[]}
          data={data[search.dataQuery]}
          tableName={t('pages.search.searchResults')}
        />
      );
      setCollapse((prev) => {
        return prev.map((item) => {
          const isSelectedSearch = item.searchName === search.searchName;
          return {
            collapsed: !isSelectedSearch,
            searchName: item.searchName,
            selected: isSelectedSearch,
            results: isSelectedSearch ? (
              <DefaultDataTableNext
                columns={
                  columns as ColumnDef<Record<string, unknown>, unknown>[]
                }
                data={data[search.dataQuery]}
                tableName={t('pages.search.searchResults')}
              />
            ) : (
              item.results
            ),
          };
        });
      });
    } else if (isError && !isFetching) {
      setResults(<></>);
    }
  }, [
    data,
    search.dataQuery,
    isFetching,
    columns,
    setResults,
    t,
    search.searchName,
    setCollapse,
    isError,
    click,
    refetch,
  ]);

  const CollapseParams = ({ show }: CollapseParamsProps) => {
    const ExecuteSetCollapse = () => {
      setCollapse((prev) => {
        return prev.map((item) => {
          if (item.searchName === search.searchName) {
            return {
              collapsed: !item.collapsed,
              searchName: search.searchName,
              selected: true,
              results: item.results,
            };
          }
          return {
            collapsed: true,
            searchName: item.searchName,
            selected: false,
            results: item.results,
          };
        });
      });
    };

    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Typography sx={{ fontWeight: 'bold' }}>
          {t('pages.search.parameters')}
        </Typography>
        {show ? (
          <ExpandLessIcon
            sx={{ cursor: 'pointer' }}
            onClick={ExecuteSetCollapse}
          />
        ) : (
          <ExpandMoreIcon
            sx={{ cursor: 'pointer' }}
            onClick={ExecuteSetCollapse}
          />
        )}
      </Box>
    );
  };

  function helperText(fields: Fields) {
    const inputText = searchOp[fields.stateName];
    return typeof inputText === 'string' &&
      fields.isCurrency &&
      inputText.match(/^(\d*\.{0,1}\d{0,2}$)/)
      ? ''
      : t('pages.search.amountError');
  }

  return (
    <>
      {collapse.selected && <CollapseParams show={!collapse.collapsed} />}
      {!collapse.collapsed && (
        <>
          <Box sx={{ m: 1 }}>
            {Object.values(search.searchFields).map((fields, index) => {
              if (fields.type === 'select') {
                return (
                  <SelectComp
                    label={fields.label}
                    options={fields.options}
                    stateName={fields.stateName}
                    key={fields.id}
                  />
                );
              } else if (fields.type === 'datepicker') {
                return (
                  <DatepickerComp
                    id={`${index}_${fields.id}`}
                    label={fields.label}
                    stateName={fields.stateName}
                    key={fields.id}
                  />
                );
              } else if (fields.type === 'input') {
                return (
                  <Input
                    label={fields.label}
                    value={searchOp?.[fields.stateName] ?? ''}
                    id={fields.id}
                    key={fields.id}
                    onChange={(e) => {
                      setSearchOp({
                        ...searchOp,
                        [fields.stateName]:
                          fields.dataType === 'string'
                            ? e.target.value
                            : Number(e.target.value),
                      });
                    }}
                    sx={{
                      '&.MuiFormControl-root': { mr: 1 },
                      '.MuiFormControl-root.MuiTextField-root': { m: 0 },
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        refetch();
                        setClick(true);
                      }
                    }}
                    endAdornment={
                      fields.isCurrency ? (
                        <InputAdornment position="start">$</InputAdornment>
                      ) : (
                        <></>
                      )
                    }
                    helperText={helperText(fields)}
                  />
                );
              }
            })}
          </Box>
          <Box sx={{ display: 'flex' }}>
            <Button
              id="search-btn-id"
              onClick={() => {
                refetch();
                setClick(true);
              }}
              sx={{ mt: 1, mr: 1 }}
            >
              {t('pages.search.title')}
            </Button>
            <Button
              id="clear-mtn-btn-id"
              onClick={() => {
                setSearchOp(searchState.current as KeyValue);
                setClick(false);
              }}
              type="secondary"
              sx={{ mt: 1 }}
            >
              {t('pages.search.clearBtn')}
            </Button>
          </Box>
          {isFetching && <Loading />}
        </>
      )}
    </>
  );
}

export default SearchOptions;
