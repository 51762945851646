import { CardContent, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import EditPropertyCardLayout from '../EditPropertyCardLayout';

interface Props {
  setActive: React.Dispatch<React.SetStateAction<string>>;
}
// TODO: update with finished design placeholder content for now.
function EditCorrespondanceCard({ setActive }: Props): JSX.Element {
  const { t } = useTranslation();

  // TODO: Localize hard coded labels once the Edit Correspondance Card is finalized
  return (
    <EditPropertyCardLayout
      setActive={setActive}
      title={t('pages.propertyView.correspondence')}
    >
      <CardContent>
        <Grid container spacing={1}></Grid>
      </CardContent>
    </EditPropertyCardLayout>
  );
}

export default EditCorrespondanceCard;
