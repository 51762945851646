import { SyntheticEvent, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Tabs, Tab } from '@mui/material';
import {
  initialState,
  actions,
  crmTabReducer,
  CRMTabIndex,
} from './crmTabReducer';
import { LettersPanel } from './LettersPanel';
import { NotesPanel } from './NotesPanel';
import { SuppressionsPanel } from './SuppressionsPanel';
import { AttachmentsPanel } from './AttachmentsPanel';

type CRMTabProps = {
  notes: string[];
  isEdit?: boolean;
};

export const CRMTab = ({ notes, isEdit }: CRMTabProps) => {
  const { t } = useTranslation();
  const [state, localDispatch] = useReducer(crmTabReducer, initialState);

  const handleChangeTab = (_: SyntheticEvent, tabIndex: number) => {
    localDispatch({
      type: actions.SET_ACTIVE_TAB,
      payload: { activeTab: tabIndex },
    });
  };
  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: 'background.paper',
        display: 'flex',
        '.crm-tab__panel': {
          backgroundColor: (theme) => theme.palette.grey[50],
          paddingLeft: 2,
          width: '83%',
          position: 'relative',
        },
      }}
    >
      <Tabs
        orientation="vertical"
        value={state.activeTab}
        onChange={handleChangeTab}
      >
        <Tab
          value={CRMTabIndex.LETTERS}
          label={t('pages.workflow.subtabs.letters')}
        />
        <Tab
          value={CRMTabIndex.NOTES}
          label={t('pages.workflow.subtabs.notes')}
        />
        <Tab
          value={CRMTabIndex.SUPPRESSIONS}
          label={t('pages.workflow.subtabs.suppressions')}
        />
        <Tab
          value={CRMTabIndex.ATTACHMENTS}
          label={t('pages.workflow.subtabs.attachments')}
        />
      </Tabs>
      {state.activeTab === CRMTabIndex.LETTERS && (
        <LettersPanel
          selectedIndex={state.activeTab}
          index={CRMTabIndex.LETTERS}
        />
      )}
      {state.activeTab === CRMTabIndex.NOTES && (
        <NotesPanel
          selectedIndex={state.activeTab}
          index={CRMTabIndex.NOTES}
          notes={notes}
          isEdit={isEdit}
        />
      )}
      {state.activeTab === CRMTabIndex.SUPPRESSIONS && (
        <SuppressionsPanel
          selectedIndex={state.activeTab}
          index={CRMTabIndex.SUPPRESSIONS}
          isEdit={isEdit}
        />
      )}
      {state.activeTab === CRMTabIndex.ATTACHMENTS && (
        <AttachmentsPanel
          selectedIndex={state.activeTab}
          index={CRMTabIndex.ATTACHMENTS}
          isEdit={isEdit}
        />
      )}
    </Box>
  );
};
