import { useState } from 'react';

import { Box, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { useManageConfigToolsContext } from 'components/contexts/ManageConfigToolsProvider/ManageConfigToolsProvider';
import {
  Attribute,
  ConfigurationResponse,
  ConfigurationSection,
  Group,
} from 'generated/graphql';
import { deepCopy } from 'utils/deepCopy';
import { ConfigurationModules } from 'common/platformConfigUtils/platformConfigUtils';
import ExpressionGeneratorDialog from './ExpressionGeneratorDialog/ExpressionGeneratorDialog';
import AttributeInput from '../AttributeInput';
import RepeatingAttributes from '../RepeatingAttributes';

interface Props {
  currentData: ConfigurationResponse;
  currentSchema: ConfigurationResponse;
  editDisabled: boolean;
  errors: { [key: string]: string };
  selectedModule?: string;
}

export default function DynamicAttributes({
  currentData,
  currentSchema,
  editDisabled,
  errors,
  selectedModule = ConfigurationModules.Platform.toString(),
}: Props) {
  const { selectedRecord, setSelectedRecord } = useManageConfigToolsContext();

  const [openExpressionDialog, setOpenExpressionDialog] =
    useState<boolean>(false);

  const [attrForExpressionDialog, setAttrForExpressionDialog] = useState<
    Attribute | undefined
  >(undefined);

  const handleAttrChange = (
    attr: Attribute,
    date?: Date | null,
    event?:
      | React.ChangeEvent<HTMLInputElement>
      | SelectChangeEvent<string | number>,
    checked?: boolean
  ) => {
    // when the option that changed was a checkbox
    if (selectedRecord && checked !== undefined) {
      const value = checked === true ? 'true' : 'false';
      attr.attributeValue = value;
      const temp = { ...selectedRecord };
      setSelectedRecord(temp);
    }
    if (selectedRecord && date && checked === undefined) {
      attr.attributeValue = date.toISOString();
      const temp = { ...selectedRecord };
      setSelectedRecord(temp);
    }
    // when the option that changed was a select or text
    if (selectedRecord && event && checked === undefined) {
      // if the thextbox is an expression
      if (!!attr.isExpression) {
        attr.expressionValue = event.target.value.toString();
      } else {
        // if it is a normal input
        attr.attributeValue = event.target.value.toString();
      }
      const temp = { ...selectedRecord };
      setSelectedRecord(temp);
    }
  };

  const handleGroupChange = (
    oldSect: ConfigurationSection,
    newGroup: Group
  ) => {
    if (selectedRecord && newGroup) {
      const tempRecord = { ...selectedRecord };
      if (tempRecord.platformConfigurationInfo) {
        const sectInd =
          tempRecord.platformConfigurationInfo?.configurationSection.findIndex(
            (s) =>
              s.configurationSectionName == oldSect.configurationSectionName
          );
        const groupInd =
          tempRecord.platformConfigurationInfo?.configurationSection[
            sectInd
          ].group.findIndex((g) => g.groupName === newGroup.groupName);
        tempRecord.platformConfigurationInfo.configurationSection[
          sectInd
        ].group[groupInd] = newGroup;
        setSelectedRecord(tempRecord);
      }
    }
  };

  const findGroup = (
    schemaSection: ConfigurationSection,
    schemaGroup: Group
  ) => {
    //first find matching section by name in the data
    const dataSection =
      currentData.platformConfigurationInfo?.configurationSection.find(
        (section) =>
          section.configurationSectionName ==
          schemaSection.configurationSectionName
      );

    let result = undefined;
    if (dataSection) {
      // then find the matching group by name within that section
      result = dataSection.group.find(
        (group) => group.groupName == schemaGroup.groupName
      );
    }
    if (result) return result;
    else {
      // This group exists in the schema, but not in the record
      // So we need to clone it and add it to the current section
      const groupCopy = deepCopy(schemaGroup);
      dataSection?.group.push(groupCopy);
      return groupCopy;
    }
  };

  const findGroupAttr = (
    schemaSection: ConfigurationSection,
    schemaGroup: Group,
    schemaAttribute: Attribute
  ) => {
    const dataGroup = findGroup(schemaSection, schemaGroup);
    //loop through all attributes in this group until we find the one we want.
    const result = dataGroup.attribute.find(
      (attr) => attr.attributeName == schemaAttribute.attributeName
    );
    // we don't just want to return the old attribute, but also update it to reflect the new schema.
    // so we replace the old attributes business drivers with the ones from the same attribute in the schema attribute
    if (result) {
      const tempAttr = result;
      tempAttr.extensibleBusinessDriver =
        schemaAttribute.extensibleBusinessDriver;
      return tempAttr;
    } else {
      // This attribute exists in the schema, but not in the record
      // So we need to clone it and add it to the current group
      const attrCopy = deepCopy(schemaAttribute);
      dataGroup.attribute.push(attrCopy);
      return attrCopy;
    }
  };

  function expressionClickHandler(attr: Attribute) {
    setOpenExpressionDialog(true);
    setAttrForExpressionDialog(attr);
  }

  function expressionCloseHandler() {
    setOpenExpressionDialog(false);
  }

  return (
    <Box
      sx={{
        justifyContent: 'space-around',
        pl: '3em',
      }}
    >
      {/* loop over schema instead of data, and map data into it
      TODO: remove merging of schema and data once the backend updates records to keep them in sync */}
      <Stack direction={{ xs: 'column' }} sx={{ flexWrap: 'wrap' }}>
        {currentSchema?.platformConfigurationInfo?.configurationSection.map(
          (configSect) =>
            configSect.group
              .sort(
                (a: Group, b: Group) =>
                  Number(a.groupOrder) - Number(b.groupOrder)
              )
              .map((group, i) => {
                if (group.groupAttributeType === 1) {
                  return (
                    <Box sx={{ mb: '1em' }} key={`${group.groupName}-${i}`}>
                      <Typography fontWeight={'bold'}>
                        {group.groupName}
                      </Typography>
                      <Stack direction={{ xs: 'column' }}>
                        <RepeatingAttributes
                          handleChange={(newGroup: Group) => {
                            handleGroupChange(configSect, newGroup);
                          }}
                          schemaGroup={group}
                          group={findGroup(configSect, group)}
                          queriesVariables={{
                            schemaDomain: currentSchema.configurationDomain,
                            schemaModule: currentSchema.configurationModule,
                            currentDataModule: currentData.configurationModule,
                            selectedModule,
                          }}
                          editDisabled={editDisabled}
                          expressionClickHandler={expressionClickHandler}
                          errors={errors}
                        />
                      </Stack>
                    </Box>
                  );
                } else {
                  return (
                    <Box sx={{ mb: '1em' }} key={`${group.groupName}-${i}`}>
                      {configSect.group.length !== 1 && (
                        <Typography fontWeight={'bold'}>
                          {group.groupName}
                        </Typography>
                      )}
                      <Stack
                        direction={{ xs: 'row' }}
                        flexWrap="wrap"
                        key={group.groupName}
                      >
                        {group.attribute.map((attr, j) => {
                          // map through the attributes in the group of the schema, BUT put in values from the actual current data
                          const currentDataAttr = findGroupAttr(
                            configSect,
                            group,
                            attr
                          );
                          if (currentDataAttr)
                            return (
                              <AttributeInput
                                key={`${attr.attributeName}-${attr.attributeType}-${j}`}
                                attr={currentDataAttr}
                                handleAttrChange={handleAttrChange}
                                queriesVariables={{
                                  schemaDomain:
                                    currentSchema.configurationDomain,
                                  schemaModule:
                                    currentSchema.configurationModule,
                                  currentDataModule:
                                    currentData.configurationModule,
                                  selectedModule,
                                }}
                                editDisabled={editDisabled}
                                expressionClickHandler={expressionClickHandler}
                                error={
                                  errors[group.groupName + attr.attributeName]
                                }
                              />
                            );
                        })}
                      </Stack>
                    </Box>
                  );
                }
              })
        )}
      </Stack>
      {/* Dialog Expression tool*/}
      <ExpressionGeneratorDialog
        open={openExpressionDialog}
        handleClose={expressionCloseHandler}
        selectedRecord={selectedRecord}
        attr={attrForExpressionDialog}
      />
    </Box>
  );
}
