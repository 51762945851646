import { useCallback, useMemo, useState, useEffect } from 'react';
import {
  Checkbox,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import {
  Card,
  Button,
  MessageActionType,
  MessageType,
} from '@revenue-solutions-inc/revxcoreui';
import { Box } from '@mui/system';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import { ColumnDef, Column, Table, Row } from '@tanstack/react-table';
import Loading from 'components/Loading';
import SelectRole from 'components/SelectRole';
import { isBefore, isToday } from 'date-fns';
import { AssignAction } from 'hooks/useAssignRoleUsers';
import useGetAccessToken from 'hooks/useGetAccessToken';
import useMutationRequest from 'hooks/useMutationRequest';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import { RolesByModuleResponse } from 'types/graphTypes';
import { AssignedUserRole } from 'types/roles';
import { checkDatesEqual, isDateInvalid } from 'utils/date-util';
import { getTime } from 'utils/getTime';
import extractMeaningfulMessage from 'utils/errorMessage';
import {
  ModuleResponse,
  useQueryPlatformOrModuleQuery,
  useRolesByModuleQuery,
} from 'generated/graphql';
import useTenantZone from 'hooks/useTenantZone';
import { updateRoleQuery } from './AvailableRolesQuery';
import RowSubTable from '../RowSubTable';
export type ModuleWithRoles = ModuleResponse & {
  roles: RolesByModuleResponse[];
};

type SelectedRole = RolesByModuleResponse & {
  toggleRowExpanded?: (expanded?: boolean | undefined) => void;
};

export type SelectedModuleWithRoles = ModuleResponse & {
  roles: SelectedRole[];
};

type RoleDates = {
  roleId: string;
  startDate: Date | undefined;
  endDate: Date | undefined;
  startDateError?: string;
  endDateError?: string;
  assignedDates?: true;
};

type AvailableRolesProps = {
  userId: string;
  roles: AssignedUserRole[];
  refetchUserRoles: () => void;
};

export default function AvailableRoles({
  userId,
  roles,
  refetchUserRoles,
}: AvailableRolesProps) {
  const dispatch = useAppDispatch();
  const accessToken = useGetAccessToken();
  const { t: n } = useTranslation();
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.editUserRole',
  });
  let tableReset: Table<RolesByModuleResponse> | undefined = undefined;
  const { isFetching: isFetchingTenantZone, tenantDate } = useTenantZone();
  const [rolesDate, setRolesDate] = useState<RoleDates[]>([]);
  const [moduleId, setModuleId] = useState<string>('');
  const [modulesRoles, setModulesRoles] = useState<ModuleWithRoles[]>([]);
  const [filteredRows, setFilteredRows] = useState<
    Row<RolesByModuleResponse>[]
  >([]);
  const [isSeaching, setIsSeaching] = useState<{
    status: boolean;
    value: string;
  }>({
    status: false,
    value: '',
  });
  const [selectedModulesRoles, setSelectedModulesRoles] = useState<
    SelectedModuleWithRoles[]
  >([]);
  const { mutate: addUsers } = useMutationRequest();

  const { isFetching: isFetchingModules, refetch: refetchModules } =
    useQueryPlatformOrModuleQuery<{
      Modules: ModuleResponse[];
    }>(
      {},
      {
        onSuccess: (data) => {
          if (data?.Modules) {
            const newModules = data.Modules;
            const firstModuleId = newModules[0]?.moduleId ?? '';

            const newModulesWithRoles: ModuleWithRoles[] = newModules.map(
              (mod) => ({
                ...mod,
                roles: [],
              })
            );
            setModuleId(`${firstModuleId}`);
            setModulesRoles(newModulesWithRoles);
            setSelectedModulesRoles(newModulesWithRoles);
          }
        },
        onError: (error) => {
          const message = extractMeaningfulMessage(
            error,
            t('pages.manageReusableContent.networkError')
          );
          dispatch(
            addMessage({
              message: message,
              type: MessageType.Error,
              actionType: MessageActionType.None,
            })
          );
        },
      }
    );

  const { isFetching: isFetchingRolesByModule, refetch: refetchRolesByModule } =
    useRolesByModuleQuery<{
      RolesByModule: RolesByModuleResponse[];
    }>(
      {
        moduleId: moduleId,
      },
      {
        enabled: moduleId !== '',
        onSuccess: (data) => {
          if (data?.RolesByModule) {
            const filterRolesByModule = (
              roleByModule: RolesByModuleResponse
            ) => {
              const role = roles.find((r) => r.roleId === roleByModule.roleId);
              return role === undefined;
            };

            const newRoles: RolesByModuleResponse[] =
              data.RolesByModule.filter(filterRolesByModule) || [];

            const addedRolesByModule = modulesRoles.map((obj) =>
              `${obj?.moduleId}` === moduleId
                ? { ...obj, roles: newRoles }
                : obj
            );
            setModulesRoles(addedRolesByModule);
          }
        },
        onError: (error) => {
          const message = extractMeaningfulMessage(
            error,
            t('pages.manageReusableContent.networkError')
          );
          dispatch(
            addMessage({
              message: message,
              type: MessageType.Error,
              actionType: MessageActionType.None,
            })
          );
        },
      }
    );

  const getRolesByModule = useMemo(() => {
    const moduleRoles = modulesRoles.find(
      (obj) => `${obj?.moduleId}` === moduleId
    );
    return moduleRoles?.roles ?? [];
  }, [moduleId, modulesRoles]);

  const getSelectedRolesByModule = useMemo(() => {
    const selectedModuleRoles = selectedModulesRoles.find(
      (obj) => `${obj?.moduleId}` === moduleId
    );
    return selectedModuleRoles?.roles ?? [];
  }, [moduleId, selectedModulesRoles]);

  const getAllSelectedRolesByModule = useMemo(() => {
    return selectedModulesRoles.flatMap((obj) => obj.roles);
  }, [selectedModulesRoles]);

  const onSelectModule = (newModuleId: string) => {
    if (newModuleId) {
      setModuleId(newModuleId);
      if (tableReset) {
        tableReset.resetExpanded();
      }
    }
  };

  const onResetRoleDate = (roleId: string) =>
    setRolesDate(rolesDate.filter((r) => r.roleId !== roleId));

  const onCheckSelectedRoles =
    (newSelectedRole: SelectedRole, searchByModule?: boolean) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.checked) {
        let selectedRoleRemoved: ModuleWithRoles[] = [];

        if (searchByModule) {
          selectedRoleRemoved = selectedModulesRoles.map((obj) =>
            `${obj?.moduleId}` === moduleId
              ? {
                  ...obj,
                  roles: obj.roles.filter(
                    (p) => p.roleId !== newSelectedRole.roleId
                  ),
                }
              : obj
          );
        } else {
          selectedRoleRemoved = selectedModulesRoles.flatMap((obj) => {
            return {
              ...obj,
              roles: obj.roles.filter(
                (p) => p.roleId !== newSelectedRole.roleId
              ),
            };
          });
        }

        if (newSelectedRole.toggleRowExpanded)
          newSelectedRole.toggleRowExpanded(false);
        setSelectedModulesRoles(selectedRoleRemoved);
        onResetRoleDate(newSelectedRole.roleId);
      } else {
        const addedSelectedRole = selectedModulesRoles.map((obj) =>
          `${obj?.moduleId}` === moduleId
            ? { ...obj, roles: [...obj.roles, newSelectedRole] }
            : obj
        );
        if (newSelectedRole.toggleRowExpanded)
          newSelectedRole.toggleRowExpanded(true);
        setSelectedModulesRoles(addedSelectedRole);
      }
    };

  /**
   * Creates header component
   * @param {roleId}.
   * @param {'startDate' | 'endDate'} key - kind of date will be changed
   * @return {function(Date | null): void}
   */
  const onChangeDates = useCallback(
    (roleId: string, key: 'startDate' | 'endDate') => (date: Date | null) => {
      const role = rolesDate.find((r) => r.roleId === roleId);
      const START_DATE = 'startDate';
      const END_DATE = 'endDate';

      if (role) {
        const currentDate = getTime(new Date(tenantDate.tenantDate));

        const newTimeSelected = date !== null ? getTime(date) : 0;
        let startDate = role?.startDate ? getTime(role.startDate) : 0;
        let endDate = role?.endDate ? getTime(role.endDate) : 0;
        let startDateError = '';
        let endDateError = '';

        if (key == START_DATE) {
          startDate = newTimeSelected;
        } else {
          endDate = newTimeSelected;
        }

        startDateError =
          startDate !== 0 && startDate < currentDate
            ? t('availableRolesErrors.startDateErrorBeforeNow')
            : '';

        endDateError =
          endDate !== 0 && endDate < currentDate
            ? t('availableRolesErrors.endDateErrorBeforeNow')
            : '';

        if (startDate >= currentDate && endDate >= currentDate) {
          startDateError =
            startDate > endDate && key == START_DATE
              ? t('availableRolesErrors.startDateError')
              : '';

          endDateError =
            endDate < startDate && key == END_DATE
              ? t('availableRolesErrors.endDateError')
              : '';
        }

        setRolesDate(
          rolesDate.map((r) =>
            r.roleId !== roleId
              ? r
              : {
                  ...r,
                  [key]: date,
                  startDateError: startDateError,
                  endDateError: endDateError,
                }
          )
        );
      } else {
        let startDate = undefined;
        let endDate = undefined;

        if (key === START_DATE && date) startDate = date;
        if (key === END_DATE && date) endDate = date;

        setRolesDate([
          ...rolesDate,
          {
            roleId,
            startDate,
            endDate,
            assignedDates: true,
          },
        ]);
      }
    },
    [rolesDate, t, tenantDate]
  );

  const handleRadio = (roleId: string, value: 'true' | 'false') => {
    if (value === 'false') {
      setRolesDate([
        ...rolesDate.map((role) =>
          role.roleId !== roleId
            ? role
            : {
                ...role,
                startDate: undefined,
                endDate: undefined,
                assignedDates: undefined,
              }
        ),
      ]);
    } else {
      setRolesDate([
        ...rolesDate,
        {
          roleId,
          startDate: undefined,
          endDate: undefined,
          assignedDates: true,
        },
      ]);
    }
  };

  const resetRoles = () => {
    if (tableReset) {
      tableReset.resetExpanded();
    }
    setModulesRoles([]);
    setSelectedModulesRoles([]);
    setRolesDate([]);
    refetchModules();
    refetchRolesByModule();
    refetchUserRoles();
  };

  const assignRoles = () => {
    const userRoleMapEntries = getAllSelectedRolesByModule.map(({ roleId }) => {
      const roleDate = rolesDate.find((r) => r.roleId === roleId);
      return {
        userId,
        roleId: roleId,
        startDate: roleDate?.startDate,
        endDate: roleDate?.endDate,
      };
    });

    addUsers(
      {
        params: {
          userRoleMapEntries,
          mapAction: AssignAction.ASSIGN,
        },
        paramsId: 'user',
        query: updateRoleQuery,
        token: accessToken,
        mutationKey: 'updateUserRole',
      },
      {
        onSuccess: () => {
          resetRoles();
          dispatch(
            addMessage({
              message: n('components.message.success'),
              type: MessageType.Success,
              actionType: MessageActionType.None,
            })
          );
        },
        onError: (error) => {
          const message = extractMeaningfulMessage(
            error,
            t('pages.manageReusableContent.networkError')
          );
          dispatch(
            addMessage({
              type: MessageType.Error,
              message: message,
              actionType: MessageActionType.None,
            })
          );
        },
      }
    );
  };

  const areSelectedDates = useMemo(() => {
    const status =
      rolesDate.filter(
        ({ startDate, endDate, assignedDates }) =>
          assignedDates && !startDate && !endDate
      ).length !== 0;

    return status;
  }, [rolesDate]);

  const areValidStartDates = useMemo(() => {
    let status = true;

    rolesDate.forEach(({ startDate, startDateError }) => {
      if (startDate) {
        if (startDateError && startDateError.length > 0) {
          status = false;
          return;
        }

        if (isDateInvalid(startDate)) {
          status = false;
          return;
        }

        const tenantTimeZone = tenantDate.tenantDate;
        const tenantStartDate = new Date(tenantTimeZone);
        const currentDate = new Date();

        if (
          isBefore(tenantStartDate, currentDate) &&
          !isToday(tenantStartDate)
        ) {
          status = false;
          return;
        }
      }
    });

    return status;
  }, [rolesDate, tenantDate]);

  const areValidEndDates = useMemo(() => {
    let status = true;

    rolesDate.forEach(({ startDate, endDate, endDateError }) => {
      if (endDate) {
        if (endDateError && endDateError.length > 0) {
          status = false;
          return;
        }
        if (isDateInvalid(endDate)) {
          status = false;
          return;
        }

        const today = new Date(tenantDate.tenantDate);

        if (
          !checkDatesEqual(startDate || today, endDate) &&
          isBefore(endDate, startDate || today)
        ) {
          status = false;
          return;
        }
      }
    });

    return status;
  }, [rolesDate, tenantDate]);

  const areInValidDates = useMemo(() => {
    return !areValidStartDates || !areValidEndDates || areSelectedDates;
  }, [areValidStartDates, areValidEndDates, areSelectedDates]);

  const updateRoles = (
    newModuleId: string,
    newRoles: RolesByModuleResponse[] | [],
    reset: boolean
  ): ModuleWithRoles[] => {
    return selectedModulesRoles.map((obj) => {
      if (`${obj?.moduleId}` === newModuleId) {
        const combinedRoles = [...obj.roles];

        for (const role of newRoles) {
          if (!combinedRoles.includes(role)) {
            combinedRoles.push(role);
          }
        }

        return {
          ...obj,
          roles: reset ? [] : combinedRoles,
        };
      } else return obj;
    });
  };

  const handleCheckAll = useMemo((): boolean => {
    if (isSeaching.status && filteredRows.length > 0) {
      return filteredRows
        .map((row) => row.original)
        .every((cRole) =>
          getSelectedRolesByModule.map((p) => p.roleId).includes(cRole.roleId)
        );
    } else if (getSelectedRolesByModule && !isSeaching.status) {
      return (
        getSelectedRolesByModule.length > 0 &&
        getRolesByModule.length > 0 &&
        getSelectedRolesByModule.length === getRolesByModule.length
      );
    }

    return false;
  }, [
    filteredRows,
    getRolesByModule.length,
    getSelectedRolesByModule,
    isSeaching.status,
  ]);

  const rolesColumns: ColumnDef<RolesByModuleResponse>[] = [
    {
      header: ({ table }) => (
        <Checkbox
          id={'selectAllCheck'}
          checked={handleCheckAll}
          onChange={(event) => {
            tableReset = table;

            if (event.target.checked) {
              if (isSeaching.status) {
                if (
                  filteredRows.length > 0 &&
                  filteredRows
                    .map((row) => row.original)
                    .every((item) => `${item.moduleId}` === moduleId)
                ) {
                  let rolesFound: SelectedRole[] = [];
                  let addedRoles: SelectedModuleWithRoles[] = [];

                  rolesFound = filteredRows
                    .map((row) => row.original)
                    .map((item) => {
                      return { ...item, toggleRowExpanded: undefined };
                    });

                  addedRoles = updateRoles(moduleId, rolesFound, false);
                  filteredRows.forEach((row) => row.toggleExpanded());
                  setSelectedModulesRoles(addedRoles);
                }
              } else {
                let newSelectedRoles: SelectedRole[] = [];
                const uniqueRoles: SelectedRole[] = [];
                newSelectedRoles = [
                  ...getSelectedRolesByModule,
                  ...getRolesByModule,
                ];

                for (const obj of newSelectedRoles) {
                  let isDuplicate = false;
                  for (const uniqueObj of uniqueRoles) {
                    if (uniqueObj.roleId === obj.roleId) {
                      isDuplicate = true;
                      break;
                    }
                  }
                  if (!isDuplicate) {
                    uniqueRoles.push(obj);
                  }
                }
                const addedRoles = updateRoles(moduleId, uniqueRoles, false);
                setSelectedModulesRoles(addedRoles);
                tableReset.toggleAllRowsExpanded(true);
              }
            } else if (isSeaching.status && selectedModulesRoles.length > 0) {
              const rolesIdFound = filteredRows
                .map((row) => row.original)
                .map((xRole) => xRole.roleId);

              const onlySelectedRolesByModule = selectedModulesRoles.map(
                (obj) =>
                  `${obj?.moduleId}` === moduleId
                    ? {
                        ...obj,
                        roles: [
                          ...obj.roles.filter(
                            (cRole) => !rolesIdFound.includes(cRole.roleId)
                          ),
                        ],
                      }
                    : obj
              );
              setSelectedModulesRoles(onlySelectedRolesByModule);
              setRolesDate([]);
              tableReset.toggleAllRowsExpanded(false);
            } else {
              tableReset.toggleAllRowsExpanded(false);
              const onlySelectedRolesByModule = updateRoles(moduleId, [], true);
              setSelectedModulesRoles(onlySelectedRolesByModule);
              setRolesDate([]);
            }
          }}
        />
      ),
      enableSorting: false,
      id: 'roleId',
      cell: ({ row, table }) => {
        tableReset = table;
        const currentRoleId = row.original.roleId;
        return (
          <Checkbox
            {...{
              onClick: () => {
                row.toggleExpanded();
              },
            }}
            checked={
              getSelectedRolesByModule.findIndex(
                (p) => p.roleId === currentRoleId
              ) > -1
            }
            onChange={onCheckSelectedRoles(
              {
                toggleRowExpanded: row.toggleExpanded,
                ...(row.original as RolesByModuleResponse),
              },
              true
            )}
          />
        );
      },
    },
    {
      header: () => <HeaderColumnNext localization={t('roleName')} />,
      accessorKey: 'roleName',
    },
    {
      header: () => <HeaderColumnNext localization={t('description')} />,
      accessorKey: 'roleDescription',
    },
    {
      header: () => <HeaderColumnNext localization={t('module')} />,
      accessorKey: 'moduleName',
    },
  ];

  const getSeachingValue = useCallback((value: string) => {
    const valueLength = value.length > 0;
    setIsSeaching({ status: valueLength, value: value });
  }, []);

  const getFilteredRows = useCallback(
    (newFilteredRows: Row<RolesByModuleResponse>[]) => {
      if (isSeaching.status && isSeaching.value.length > 0) {
        setFilteredRows(newFilteredRows);
      }
    },
    [isSeaching.status, isSeaching.value]
  );

  useEffect(() => {
    if (tableReset && filteredRows.length === selectedModulesRoles.length) {
      tableReset?.toggleAllRowsExpanded(true);
    }
  }, [filteredRows.length, selectedModulesRoles, tableReset]);

  return (
    <Grid container>
      <Grid
        item
        xs={4}
        minWidth="15rem"
        sx={{
          flexGrow: '1 !important',
          maxWidth: '100% !important',
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography sx={{ mt: 1, mb: 2 }} variant="h4">
            {t('textAvailableRoles')}
          </Typography>
          <Typography sx={{ mt: 1, mb: 2 }} variant="h4">
            {t('tenantTimeZoneInstructions', {
              tenantTimeZone: `${
                (!isFetchingTenantZone && tenantDate?.timezone) ?? ''
              }`,
            })}
          </Typography>
          <Typography variant="h3" sx={{ mb: 1 }}>
            {t('labelAvailableSelectRoles')}
          </Typography>
          <Card>
            <List
              dense
              sx={{
                bgcolor: 'background.paper',
                overflow: 'auto',
                height: 400,
                px: 1,
              }}
            >
              {getAllSelectedRolesByModule.length === 0
                ? t('noRolesSelected')
                : getAllSelectedRolesByModule.map((s, key) => (
                    <ListItem key={key} disablePadding>
                      <ListItemButton sx={{ p: 0, m: 0 }}>
                        <ListItemIcon>
                          <Checkbox
                            disableRipple
                            checked={
                              getAllSelectedRolesByModule.findIndex(
                                (p) => p.roleId === s.roleId
                              ) > -1
                            }
                            onChange={onCheckSelectedRoles(s, false)}
                          />
                        </ListItemIcon>
                        <ListItemText primary={s.roleName} />
                      </ListItemButton>
                    </ListItem>
                  ))}
            </List>
          </Card>
          <Button
            id="assignSelectedRoles"
            sx={{ mt: 2 }}
            onClick={assignRoles}
            disabled={
              getAllSelectedRolesByModule.length === 0 || areInValidDates
            }
          >
            {t('assignSelectedRolesButton')}
          </Button>
        </Box>
      </Grid>
      <Grid
        item
        xs={8}
        sx={{ flexGrow: '1 !important', maxWidth: '100% !important' }}
      >
        {(isFetchingModules || isFetchingRolesByModule) && <Loading />}
        <DefaultDataTableNext
          columns={rolesColumns as Column<RolesByModuleResponse>[]}
          data-testid="user-roles-data"
          data={getRolesByModule}
          getFilteredRows={getFilteredRows}
          getSeachingValue={getSeachingValue}
          renderRowSubComponent={(row) => {
            const roleDates = rolesDate.find(
              (r) => r.roleId === row.original.roleId
            );
            return (
              <RowSubTable
                roleId={row.original.roleId as string}
                startDate={roleDates?.startDate || null}
                endDate={roleDates?.endDate || null}
                startDateError={roleDates?.startDateError ?? ''}
                endDateError={roleDates?.endDateError ?? ''}
                onChangeDates={onChangeDates}
                handleRadio={handleRadio}
              />
            );
          }}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'end',
          }}
          customHeader={
            <>
              <SelectRole
                moduleList={modulesRoles.map((obj) => {
                  return {
                    moduleId: obj.moduleId,
                    name: obj.name,
                  };
                })}
                handleChange={onSelectModule}
                selectedRole={moduleId}
                showAll={false}
              />
            </>
          }
        />
      </Grid>
    </Grid>
  );
}
