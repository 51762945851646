/* eslint-disable @typescript-eslint/no-explicit-any */
export function formatObject(obj: { [key: string]: any }) {
  return Object.keys(obj).reduce(
    (accumulator: { [key: string]: string }, key) => {
      accumulator[`${key.charAt(0).toLowerCase() + key.slice(1)}`] = obj[key];
      return accumulator;
    },
    {}
  );
}
