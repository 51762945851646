import { PaymentDetailsResponse } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import DataCard from 'components/DataCard';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import { Link } from 'react-router-dom';
import { ColumnDef } from '@tanstack/react-table';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import DateCell from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/TableCells/DateCell';
import CurrencyCell from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/TableCells/CurrencyCell';
import { Box, Theme, useTheme } from '@mui/system';
import { useAppSelector } from 'redux/hooks';

const T = () => {
  const { t } = useTranslation();
  return t;
};

export const paymentColumns = (
  theme: Theme,
  module: string,
  currentPageName: string
) => {
  const columns: ColumnDef<PaymentDetailsResponse>[] = [
    {
      id: 'paymentType',
      accessorKey: 'paymentType',
      header: () => (
        <HeaderColumnNext
          localization={T()?.('pages.paymentDetails.paymentType')}
        />
      ),
      cell: ({ getValue, row }) => (
        <Box
          sx={() => {
            return {
              a: {
                color: theme.palette.linkBlue.dark,
              },
            };
          }}
        >
          <Link
            to={{
              pathname: `/${module}/paymentdetails/${row.original.id}`,
            }}
            state={{
              prevPath: location.pathname,
              prevPageName: currentPageName,
            }}
          >
            {getValue() as string}
          </Link>
        </Box>
      ),
    },
    {
      id: 'amount',
      accessorKey: 'amount',
      cell: ({ getValue }) => (
        <Box sx={{ width: '3.6em' }}>
          <CurrencyCell
            invalidValue={T()?.('pages.tableCell.invalidValue')}
            amountString={getValue() as string}
          />
        </Box>
      ),
      header: () => (
        <HeaderColumnNext
          localization={T()?.('pages.viewFinancialTransGroup.amount')}
        />
      ),
    },
    {
      id: 'paymentStatus',
      accessorKey: 'paymentStatus',
      header: () => (
        <HeaderColumnNext
          localization={T()?.('pages.paymentDetails.paymentStatus')}
        />
      ),
    },
    {
      id: 'effectiveDate',
      accessorKey: 'effectiveDate',
      header: () => (
        <HeaderColumnNext
          localization={T()?.('pages.viewFinancialTransGroup.effectiveDate')}
        />
      ),
      cell: ({ getValue }) => <DateCell dateString={getValue() as string} />,
    },
  ];
  return columns;
};

interface Props {
  payments: PaymentDetailsResponse[];
  currentPageName: string;
}

function PaymentList({ payments, currentPageName }: Props): JSX.Element {
  const { t } = useTranslation();
  const theme = useTheme();
  const module = useAppSelector((state) => state.user.module);

  return (
    <DataCard
      title={t('pages.paymentDetails.payments')}
      avatar={<MonetizationOnIcon sx={{ fill: theme.palette.primary.main }} />}
      children={
        <>
          {payments.length > 0 && (
            <DefaultDataTableNext
              data={payments}
              columns={
                paymentColumns(
                  theme,
                  module,
                  currentPageName
                ) as unknown as ColumnDef<Record<string, unknown>>[]
              }
              enableGlobalFilter={false}
            />
          )}
          {payments.length === 0 && t('pages.paymentDetails.noPayments')}
        </>
      }
    />
  );
}

export default PaymentList;
