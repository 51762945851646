import { Box, Stack, useTheme } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'redux/hooks';
import {
  PaymentDetailsResponse,
  PeriodDetailsResponse,
} from 'generated/graphql';
import { IconButton, Typography } from '@mui/material';
import { Control, FieldValues, UseFormGetValues } from 'react-hook-form';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import EditPeriodRow from 'components/EditPeriodRow';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import CurrencyCell from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/TableCells/CurrencyCell';
import { ColumnDef, Row } from '@tanstack/table-core';
import { paymentColumns } from 'components/PaymentList/PaymentList';
import {
  recordIcons,
  checkButton,
  icon,
  cancelButton,
  collapseButton,
  expandButton,
  fontSize,
} from 'components/manageConfigTools/Shared/IconButtonStyling/IconButtonStyling';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';

interface Props {
  payments: PaymentDetailsResponse[];
  currentRowEdit: string;
  control: Control<FieldValues, unknown>;
  getValues: UseFormGetValues<FieldValues>;
  handleCancel: () => void;
  handleSaveRow: () => void;
  handleEditRow: (rowId: string) => void;
  showPreview: boolean;
}

function EditPaymentsDataTable({
  payments,
  currentRowEdit,
  control,
  getValues,
  handleCancel,
  handleSaveRow,
  handleEditRow,
  showPreview,
}: Props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const module = useAppSelector((state) => state.user.module);

  const handleExpandRow = (row: Row<PaymentDetailsResponse>) => {
    row.toggleExpanded();
  };

  const paymentCols: ColumnDef<PaymentDetailsResponse>[] = [
    {
      header: () => <HeaderColumnNext localization={''} />,
      id: 'edit',
      cell: ({ row }) => {
        return row.original.id !== currentRowEdit ? (
          <IconButton
            onClick={() => {
              handleEditRow(row.original.id as string);
              if (!row.getIsExpanded()) {
                handleExpandRow(row);
              }
            }}
            sx={recordIcons}
            aria-label="edit-button"
            title={t('pages.configGroupEditor.editGroup')}
          >
            <EditIcon sx={{ fontSize }} />
          </IconButton>
        ) : (
          <Stack direction="row" spacing={1}>
            <IconButton
              aria-label="check-button"
              onClick={() => {
                handleSaveRow();
                row.toggleExpanded();
              }}
              title={t('pages.configRecordEditor.confirmIcon')}
              size="small"
              sx={checkButton}
            >
              <CheckIcon fontSize="small" sx={icon} />
            </IconButton>
            <IconButton
              onClick={() => {
                handleCancel();
                row.toggleExpanded();
              }}
              title={t('pages.configRecordEditor.cancelIcon')}
              size="small"
              sx={cancelButton}
            >
              <CloseIcon fontSize="small" sx={icon} />
            </IconButton>
          </Stack>
        );
      },
    },
    ...paymentColumns(theme, module, t('pages.submissionDetails.title')).filter(
      (item) => {
        return item.id !== 'amount';
      }
    ),
    {
      id: 'amount',
      accessorKey: 'amount',
      header: () => (
        <HeaderColumnNext localization={t('pages.submissionDetails.amount')} />
      ),
      cell: ({ getValue, row }) => {
        return (
          <Box sx={{ width: '3.3em' }}>
            <CurrencyCell
              invalidValue={t('pages.tableCell.invalidValue')}
              amountString={
                showPreview
                  ? (getValue() as string)
                  : (row.original.amount as unknown as string)
              }
            />
          </Box>
        );
      },
    },
    {
      id: 'expandRow',
      header: () => (
        <div style={{ width: '7em' }}>
          <HeaderColumnNext
            localization={t('pages.submissionDetails.periods')}
          />
        </div>
      ),
      cell: ({ row }) => {
        return (
          <IconButton
            {...{
              onClick: () => {
                handleExpandRow(row);
              },
            }}
            sx={row.getIsExpanded() ? collapseButton : expandButton}
          >
            <ExpandCircleDownIcon />
          </IconButton>
        );
      },
    },
  ];

  return (
    <>
      <Typography variant="h3" sx={{ mt: 2 }}>
        {t('pages.submissionDetails.payments')}
      </Typography>
      {payments.length > 0 ? (
        <DefaultDataTableNext
          columns={
            paymentCols.filter((item) => {
              return item.id !== (showPreview ? 'edit' : 'correctedAmount');
            }) as ColumnDef<Record<string, unknown>>[]
          }
          data={payments ?? []}
          enableGlobalFilter={false}
          renderRowSubComponent={(row) => (
            <EditPeriodRow
              periods={row.original.periods as PeriodDetailsResponse[]}
              rowId={row.original.id as string}
              currentRowEdit={currentRowEdit ?? ''}
              control={control}
              getValues={getValues}
              showPreview={showPreview}
            />
          )}
        />
      ) : (
        t('pages.paymentDetails.noPayments')
      )}
    </>
  );
}
export default EditPaymentsDataTable;
