import { useCallback, useEffect, useState } from 'react';
import { Grid } from '@mui/material';

import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import {
  Button,
  MessageActionType,
  MessageType,
  Select,
  TextArea,
} from '@revenue-solutions-inc/revxcoreui';
import Dialog from '@revenue-solutions-inc/revxcoreui/material/controls/Dialog';
import { addMessage } from 'redux/messageSlice';
import {
  PaymentReversalInput,
  useGetPaymentReversalTypesQuery,
  useReversePaymentMutation,
} from 'generated/graphql';
import { useAppDispatch } from 'redux/hooks';
import { useQueryClient } from '@tanstack/react-query';
import { Controller, useForm } from 'react-hook-form';
import extractMeaningfulMessage from 'utils/errorMessage';
import { SelectType } from '@revenue-solutions-inc/revxcoreui/material/controls/Select';

interface ReversePaymentProps {
  paymentId: string;
}

function ReversePayment({ paymentId }: ReversePaymentProps) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const [reversalTypes, setReversalTypes] = useState<SelectType[]>([]);
  const [reversalReasons, setReversalReasons] = useState<SelectType[]>([]);
  const [noteRequired, setNoteRequired] = useState<boolean>(false);
  const { control, formState, trigger, getValues } = useForm({
    mode: 'all',
  });
  const { isValid } = formState;
  const maximumCharactersAllowed = 245;

  const closeModal = () => {
    setOpen(false);
  };

  const openModal = () => {
    setOpen(true);
  };

  const { data: paymentReversalTypes, error: paymentReversalTypesError } =
    useGetPaymentReversalTypesQuery();

  useEffect(() => {
    if (paymentReversalTypesError) {
      dispatch(
        addMessage({
          message: t('pages.paymentDetails.reversalLookupTypesError'),
          type: MessageType.Error,
          actionType: MessageActionType.None,
        })
      );
    }
  }, [dispatch, paymentReversalTypesError, t]);

  useEffect(() => {
    const reversalTypesList = paymentReversalTypes?.GetPaymentReversalTypes.map(
      (type) => {
        return {
          key: type.name ? type.name : '',
          desc: type.description ?? '',
        };
      }
    );
    if (reversalTypesList) {
      setReversalTypes(reversalTypesList);
    }
  }, [paymentReversalTypes?.GetPaymentReversalTypes]);

  const buildPaymentReversalReasons = useCallback(
    (selectedReversalType: string) => {
      const reasons = paymentReversalTypes?.GetPaymentReversalTypes?.find(
        (type) => type.name === selectedReversalType
      )?.reasons;

      const typeList: SelectType[] = [];
      reasons?.map((item) => {
        typeList?.push({
          key: item.name ?? '',
          desc: item.description ?? '',
        });
      });
      setReversalReasons(typeList);
    },
    [paymentReversalTypes?.GetPaymentReversalTypes]
  );

  const findIfNoteRequired = useCallback(
    (selectedReversalReason: string) => {
      const reasons = paymentReversalTypes?.GetPaymentReversalTypes?.find(
        (type) => type.name === getValues('reversalType')
      )?.reasons;

      const isNoteRequired = reasons?.find(
        (item) => item.name === selectedReversalReason
      )?.isNoteRequired;

      setNoteRequired(isNoteRequired ? true : false);
    },
    [getValues, paymentReversalTypes?.GetPaymentReversalTypes]
  );

  const { mutate, isLoading } = useReversePaymentMutation({
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: ['GetPaymentDetailsById'],
      });
      void queryClient.invalidateQueries({
        queryKey: ['GetPaymentTransactionsById'],
      });
      closeModal();
      dispatch(
        addMessage({
          message: t('pages.paymentDetails.reversalSuccess'),
          type: MessageType.Success,
          actionType: MessageActionType.None,
        })
      );
    },
    onError: (error) => {
      const message = extractMeaningfulMessage(
        error,
        t('pages.paymentDetails.reversalError')
      );
      dispatch(
        addMessage({
          type: MessageType.Error,
          message: message,
        })
      );
    },
  });

  const reversePaymentBtn = () => {
    return (
      <Box sx={{ width: 220, textAlign: 'right', mr: '8px' }}>
        <Button
          id="reverse-payment-link"
          type="secondary"
          variant="text"
          onClick={openModal}
        >
          {t('pages.paymentDetails.reverse')}
        </Button>
      </Box>
    );
  };

  function submitPaymentReversal() {
    void trigger().then(() => {
      if (isValid) {
        const payload: PaymentReversalInput = {
          reversalType: getValues('reversalType'),
          reversalReason: getValues('reversalReason'),
          note: getValues('note'),
        };
        mutate({ paymentReversal: payload, paymentId: paymentId });
      }
    });
  }

  const reversePaymentModalContent = (
    <Grid container spacing={2} mb={1}>
      <Grid item xs={4}>
        <Controller
          control={control}
          name="reversalType"
          rules={{ required: true }}
          render={({
            field: { value, onChange },
            fieldState: { error: reversalTypeError },
          }) => {
            return (
              <Select
                required
                autowidth={false}
                options={reversalTypes}
                id="reversalType"
                data-testid="reversalType"
                label={t('pages.paymentDetails.reversalType')}
                onChange={(e) => {
                  const selectedReversalType = e.target.value as string;
                  buildPaymentReversalReasons(selectedReversalType);
                  onChange(e);
                }}
                fullWidth
                value={value}
                error={
                  reversalTypeError
                    ? t('pages.paymentDetails.reversalTypeRequired')
                    : ''
                }
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <Controller
          control={control}
          name="reversalReason"
          rules={{ required: true }}
          render={({
            field: { value, onChange },
            fieldState: { error: reversalReasonError },
          }) => {
            return (
              <Select
                required
                autowidth={false}
                options={reversalReasons}
                id="reversalReason"
                data-testid="reversalReason"
                label={t('pages.paymentDetails.reversalReason')}
                onChange={(e) => {
                  const selectedReversalReason = e.target.value as string;
                  findIfNoteRequired(selectedReversalReason);
                  onChange(e);
                }}
                fullWidth
                value={value}
                error={
                  reversalReasonError
                    ? t('pages.paymentDetails.reversalReasonRequired')
                    : ''
                }
              />
            );
          }}
        />
      </Grid>
      {noteRequired && (
        <Grid item xs={12}>
          <Controller
            control={control}
            name="note"
            rules={{ required: true }}
            render={({
              field: { value, onChange },
              fieldState: { error: noteError },
            }) => {
              return (
                <TextArea
                  id="note"
                  label="Note"
                  required
                  value={value}
                  onChange={onChange}
                  multiline
                  error={noteError ? true : false}
                  helperText={
                    noteError ? t('pages.paymentDetails.noteRequired') : ''
                  }
                  sx={{ width: '100%' }}
                  inputProps={{
                    maxLength: maximumCharactersAllowed + 1,
                  }}
                />
              );
            }}
          />
        </Grid>
      )}
    </Grid>
  );

  const handleSave = () => {
    submitPaymentReversal();
  };

  return (
    <>
      {reversePaymentBtn()}
      <Dialog
        id="reversePaymentDialog"
        open={open}
        children={reversePaymentModalContent}
        title={t('pages.paymentDetails.reverse')}
        type="transactional"
        loading={isLoading}
        maxWidth="sm"
        transactionModalTransactionButtonText={t(
          'pages.FinancialTransaction.reverseTransactionConfirmButton'
        )}
        handleClose={closeModal}
        handleCancelClick={closeModal}
        handleTransactionClick={handleSave}
      />
    </>
  );
}
export default ReversePayment;
