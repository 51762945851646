import {
  OOTBIdFormats,
  OOTBIdValidationRules,
  OOTBIdMaxLength,
} from 'components/contexts/EntityManagement/EntityManagementContext';
import { GetLookupConfigurationQuery } from 'generated/graphql';

interface Options {
  key: string;
  desc: string;
}

export const getIdFormats = (
  configuredIdTypes: GetLookupConfigurationQuery | undefined
) => {
  const idFormats = OOTBIdFormats;
  if (!configuredIdTypes) return idFormats;

  configuredIdTypes.GetLookupConfiguration.forEach((type) => {
    const format = type.platformConfigurationInfo?.configurationSection
      .find(
        (s) =>
          'IdTypes'.localeCompare(s.configurationSectionName ?? '', undefined, {
            sensitivity: 'accent',
          }) == 0
      )
      ?.group.find(
        (g) =>
          'IdTypes'.localeCompare(g.groupName ?? '', undefined, {
            sensitivity: 'accent',
          }) == 0
      )
      ?.attribute.find(
        (a) =>
          'Format'.localeCompare(a.attributeName, undefined, {
            sensitivity: 'accent',
          }) == 0
      )?.attributeValue;

    if (type.configurationName && format) {
      idFormats[type.configurationName] = format;
    }
  });

  return idFormats;
};

export const getValidationRules = (
  configuredIdTypes: GetLookupConfigurationQuery | undefined
) => {
  const idValidationRules = OOTBIdValidationRules;
  if (!configuredIdTypes) return idValidationRules;

  configuredIdTypes.GetLookupConfiguration.forEach((type) => {
    const validationRule = type.platformConfigurationInfo?.configurationSection
      .find(
        (s) =>
          'IdTypes'.localeCompare(s.configurationSectionName ?? '', undefined, {
            sensitivity: 'accent',
          }) == 0
      )
      ?.group.find(
        (g) =>
          'IdTypes'.localeCompare(g.groupName ?? '', undefined, {
            sensitivity: 'accent',
          }) == 0
      )
      ?.attribute.find(
        (a) =>
          'Validation'.localeCompare(a.attributeName, undefined, {
            sensitivity: 'accent',
          }) == 0
      )?.attributeValue;

    if (type.configurationName && validationRule) {
      idValidationRules[type.configurationName] = new RegExp(validationRule);
    }
  });
  return idValidationRules;
};

export const getMaskRules = (
  configuredIdTypes: GetLookupConfigurationQuery | undefined
) => {
  const maxLengthRules = OOTBIdMaxLength;
  if (!configuredIdTypes) return maxLengthRules;

  configuredIdTypes.GetLookupConfiguration.forEach((type) => {
    const maxLengthRule = type.platformConfigurationInfo?.configurationSection
      .find(
        (s) =>
          'IdTypes'.localeCompare(s.configurationSectionName ?? '', undefined, {
            sensitivity: 'accent',
          }) == 0
      )
      ?.group.find(
        (g) =>
          'IdTypes'.localeCompare(g.groupName ?? '', undefined, {
            sensitivity: 'accent',
          }) == 0
      )
      ?.attribute.find(
        (a) =>
          'Mask'.localeCompare(a.attributeName, undefined, {
            sensitivity: 'accent',
          }) == 0
      )?.attributeValue;

    if (type.configurationName && maxLengthRule) {
      maxLengthRules[type.configurationName] = maxLengthRule.length;
    }
  });
  return maxLengthRules;
};

export const setOptions = (options: string[]): Options[] => {
  const listOptions: Options[] = [];
  options.forEach((option) => {
    const newOption: Options = { key: option, desc: option };
    listOptions.push(newOption);
  });
  return listOptions;
};
