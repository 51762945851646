import { ChangeEvent, Fragment, useEffect, useState } from 'react';

import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { Grid, IconButton, Typography } from '@mui/material';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import Input from '@revenue-solutions-inc/revxcoreui/material/controls/Input';
import Loading from 'components/Loading';
import useMultiMaskInput from 'hooks/useMultiMaskInput';
import { useTranslation } from 'react-i18next';
import { TaxRate, taxRateDefault } from 'types/taxingAuthority/entities';

interface Props {
  taxRates: TaxRate[];
  onChange: (taxRate: TaxRate[]) => void;
  onError: (hasError: boolean) => void;
}

function TaxRatesManager({ taxRates, onChange, onError }: Props): JSX.Element {
  const [inputValues, setInputValues] = useState<string[]>(['']);

  useEffect(() => {
    const newRates = [...taxRates];
    const newInputValues: string[] = [];
    newRates.forEach((item, index) => {
      newInputValues[index] = item.rate;
    });
    setInputValues(newInputValues);
  }, [taxRates]);

  const { t } = useTranslation();

  const decimalNumberMask = useMultiMaskInput(
    '',
    t('pages.manageTaxingAuthority.validation.invalidDecimal'),
    /^\d+(\.\d+)?$/,
    inputValues
  );

  useEffect(() => {
    onError(decimalNumberMask.hasErrors);
  }, [decimalNumberMask.hasErrors, onError]);

  const handleHelperTextChange = (index: number) => {
    return decimalNumberMask.maskErrors[index];
  };

  return (
    <>
      {<Loading />}
      <Typography variant="h2">
        {t('pages.manageTaxingAuthority.taxRates')}
      </Typography>
      <Grid item xs={12} mt={2} pb={2}>
        {taxRates.map((_currTaxRate, index) => {
          return (
            <Fragment key={`${index}_container`}>
              <Grid container spacing={2}>
                <Grid item pb={2}></Grid>
                <Grid item pb={2}>
                  <Input
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      const newTaxRates = [...taxRates];
                      newTaxRates[index].rate = event.target.value;
                      onChange(newTaxRates);
                    }}
                    id="taxRateId"
                    value={taxRates[index].rate}
                    label={t('pages.manageTaxingAuthority.taxRate')}
                    required
                    helperText={handleHelperTextChange(index)}
                  />
                </Grid>
                <Grid item xs={1} mt={2}>
                  {index !== 0 && (
                    <IconButton
                      onClick={() => {
                        if (taxRates) {
                          taxRates.splice(index, 1);
                          onChange(taxRates);
                        }
                        decimalNumberMask.maskErrors.splice(index, 1);
                      }}
                      aria-label="delete"
                      color="default"
                      size="small"
                    >
                      <CancelRoundedIcon fontSize="small" />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            </Fragment>
          );
        })}
        <Grid item xs={2} pt={2}>
          <Button
            id="addTaxRate"
            variant="outlined"
            type="secondary"
            onClick={() => {
              if (taxRates) {
                taxRates.push({
                  ...taxRateDefault,
                  taxingAuthorityRateType: '',
                  taxingAuthorityTaxSubtype: '',
                  rate: '',
                  taxYear: '',
                });
                onChange(taxRates);
              }
            }}
          >
            {t('pages.manageTaxingAuthority.addTaxRate')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default TaxRatesManager;
