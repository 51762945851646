import { Fragment } from 'react';

import { SelectChangeEvent } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import DatePicker from '@revenue-solutions-inc/revxcoreui/material/controls/DatePicker';
import Input from '@revenue-solutions-inc/revxcoreui/material/controls/Input';
import { AttributeTypes } from 'common/platformConfigUtils/platformConfigUtils';
import { radioOptions } from 'components/entityManagement/common/defaults/entity';
import {
  getExtendedValue,
  getExtendedRequired,
  isExtendedFieldValid,
  getExtendedMinDate,
  getExtendedMaxDate,
} from 'components/entityManagement/common/entityManagementUtils';
import DatasourceSelect from 'components/DatasourceSelect';
import RadioGroup from 'components/RadioGroup';
import { Attribute } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { ExtendedAttributeValues } from 'types/forms';
import { toDate, toStringDate } from 'utils/date-util';

interface Props {
  extendedAttributes: Attribute[];
  extendedValues: ExtendedAttributeValues[];
  handleExtendedValues: (extendedVals: ExtendedAttributeValues[]) => void;
  displayTitle?: boolean;
  validateHandleDate?: boolean;
}

function ExtendedAttributes({
  extendedAttributes,
  extendedValues,
  handleExtendedValues,
  displayTitle = true,
  validateHandleDate = false,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const handleChange = (
    ev:
      | React.ChangeEvent<HTMLInputElement>
      | SelectChangeEvent<string | number>
      | null,
    attrName: string,
    date?: Date | null,
    checked?: boolean
  ) => {
    const updateExtended = extendedValues.map((attribute) => {
      let newValue = '';
      if (ev === null) {
        if (date) newValue = toStringDate(date);
        else if (checked) newValue = String(checked);
      } else newValue = String(ev.target.value);
      if (attribute.name === attrName) {
        return {
          ...attribute,
          isDirty: true,
          value: newValue,
        };
      }
      return attribute;
    });
    handleExtendedValues(updateExtended);
  };

  return extendedAttributes.length > 0 ? (
    <>
      {displayTitle && (
        <Typography
          sx={{ marginTop: '15px', marginBottom: '15px' }}
          variant="h2"
        >
          {t('pages.createAccount.extendedAttributes')}
        </Typography>
      )}
      <Grid container spacing={3}>
        {extendedAttributes.map((attribute, index) => {
          const attrName = attribute.attributeName;
          const attributeType = attribute.attributeType?.toLowerCase() ?? '';
          const errorMsg = attribute.attributeDisplayName.concat(
            t('pages.createAccount.extendedAttrRequired')
          );
          switch (attributeType) {
            case AttributeTypes.referenceData.toLowerCase():
              return (
                <Grid key={attrName + '_container'} item xs={12} sm={6} md={3}>
                  <DatasourceSelect
                    id={attrName}
                    key={attrName}
                    label={attribute.attributeDisplayName}
                    value={getExtendedValue(extendedValues, attrName)}
                    datasource={attribute.dataSource ?? ''}
                    fetchLayoutInfo={(ev) => handleChange(ev, attrName)}
                    required={getExtendedRequired(extendedValues, attrName)}
                    errorMsg={
                      !isExtendedFieldValid(extendedValues, attrName)
                        ? errorMsg
                        : undefined
                    }
                    maxWidth={true}
                  />
                </Grid>
              );
            case AttributeTypes.date.toLowerCase():
              return (
                <Grid key={attrName + '_container'} item xs={12} sm={6} md={3}>
                  <DatePicker
                    id={attrName}
                    value={toDate(getExtendedValue(extendedValues, attrName))}
                    handleChange={(date: Date | null) => {
                      if (
                        date !== null &&
                        date?.toString() !== 'Invalid Date'
                      ) {
                        handleChange(null, attrName, date);
                      }
                      if (
                        validateHandleDate &&
                        date?.toString() !== 'Invalid Date'
                      ) {
                        handleChange(null, attrName, date);
                      }
                    }}
                    label={attribute.attributeDisplayName}
                    requiredErrorMessage={
                      !isExtendedFieldValid(extendedValues, attrName)
                        ? errorMsg
                        : undefined
                    }
                    showErrorMessageWithoutFocus={true}
                    required={getExtendedRequired(extendedValues, attrName)}
                    minDate={getExtendedMinDate(extendedValues, attrName)}
                    maxDate={getExtendedMaxDate(extendedValues, attrName)}
                    fullWidth={true}
                  />
                </Grid>
              );
            case AttributeTypes.boolean.toLowerCase():
              return (
                <Grid key={attrName + '_container'} item xs={12} sm={6} md={3}>
                  <RadioGroup
                    id={attrName}
                    label={attribute.attributeDisplayName}
                    fieldIdentifier={attribute.attributeName}
                    value={getExtendedValue(extendedValues, attrName)}
                    onChange={(ev) => {
                      if (ev) handleChange(ev, attrName);
                    }}
                    radioOptions={radioOptions}
                    isRequired={getExtendedRequired(extendedValues, attrName)}
                    error={
                      !isExtendedFieldValid(extendedValues, attrName)
                        ? errorMsg
                        : undefined
                    }
                  />
                </Grid>
              );
            case AttributeTypes.int.toLowerCase():
              return (
                <Grid key={attrName + '_container'} item xs={12} sm={6} md={3}>
                  <Input
                    key={attrName}
                    id={`attrNameInput_${index}`}
                    value={getExtendedValue(extendedValues, attrName)}
                    onChange={(ev) => {
                      ev.target.value = Math.floor(
                        Number(ev.target.value)
                      ).toString();
                      handleChange(ev, attrName);
                    }}
                    inputProps={{ type: 'number', step: 1 }}
                    label={attribute.attributeDisplayName}
                    required={getExtendedRequired(extendedValues, attrName)}
                    endAdornment={<Fragment />}
                    helperText={
                      !isExtendedFieldValid(extendedValues, attrName)
                        ? errorMsg
                        : undefined
                    }
                    sx={{
                      width: '100%',
                    }}
                  />
                </Grid>
              );
            default:
              return (
                <Grid key={attrName + '_container'} item xs={12} sm={6} md={3}>
                  <Input
                    id={attrName}
                    key={attrName}
                    value={getExtendedValue(extendedValues, attrName)}
                    onChange={(ev) => handleChange(ev, attrName)}
                    label={attribute.attributeDisplayName}
                    required={getExtendedRequired(extendedValues, attrName)}
                    helperText={
                      !isExtendedFieldValid(extendedValues, attrName)
                        ? errorMsg
                        : undefined
                    }
                    sx={{
                      width: '100%',
                    }}
                  />
                </Grid>
              );
          }
        })}
      </Grid>
    </>
  ) : (
    <></>
  );
}

export default ExtendedAttributes;
