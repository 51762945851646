import { useState } from 'react';
import {
  Input,
  Select,
  Checkbox,
  Button,
} from '@revenue-solutions-inc/revxcoreui';
import {
  Control,
  Controller,
  FieldValues,
  useFieldArray,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { Grid, IconButton, Stack, Theme } from '@mui/material';
import { Box } from '@mui/system';

interface Props {
  control: Control<FieldValues, unknown>;
}

function FieldSetForm({ control }: Props) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.generateIdConfig',
  });
  const [selectedIdTypes, setSelectedIdTypes] = useState<string[]>([]);

  const { fields, remove, append } = useFieldArray({
    control,
    name: `fieldSet`,
  });

  return (
    <>
      {fields.map((item: Record<'id', string>, index: number) => {
        return (
          <Grid
            key={item.id}
            item
            sx={(theme: Theme) => {
              return {
                borderColor: theme.palette.grey4.main,
                borderWidth: '1px',
                borderStyle: 'solid',
                borderRadius: '3px',
                background: theme.palette.white.main,
                paddingLeft: '0 !important',
                paddingTop: '0 !important',
                minWidth: '200px',
                minHeight: '200px',
              };
            }}
            m={1}
          >
            <IconButton
              aria-label="clear-button"
              id="clearButton"
              size="medium"
              data-testid="clear-button"
              sx={{ float: 'right' }}
              onClick={() => {
                selectedIdTypes.splice(index, 1);
                setSelectedIdTypes([...selectedIdTypes]);
                remove(index);
              }}
            >
              <ClearRoundedIcon
                sx={{
                  fontWeight: 'bold',
                }}
              />
            </IconButton>
            <Box p={1} sx={{ background: 'rgba(180, 188, 201, 1)' }}>
              <Controller
                control={control}
                name={`fieldSet[${index}].fieldType`}
                rules={{ required: true }}
                render={({
                  field: { value, onChange },
                  fieldState: { error: fieldTypeError },
                }) => {
                  return (
                    <Select
                      required
                      autowidth={false}
                      options={[
                        { key: 'Number', desc: 'Number' },
                        { key: 'Constant', desc: 'Constant' },
                        { key: 'DateTime', desc: 'DateTime' },
                        { key: 'Separator', desc: 'Separator' },
                        { key: 'Expression', desc: 'Expression' },
                      ]}
                      id={`fieldSet[${index}].fieldType`}
                      data-testid="fieldType"
                      label={t('fieldType')}
                      onChange={(e) => {
                        selectedIdTypes[index] = e.target.value as string;
                        setSelectedIdTypes([...selectedIdTypes]);
                        onChange(e);
                      }}
                      value={value}
                      error={fieldTypeError?.message}
                    />
                  );
                }}
              />
            </Box>
            <Stack m={2}>
              {selectedIdTypes[index] == 'Number' && (
                <>
                  <Controller
                    control={control}
                    name={`fieldSet[${index}].length`}
                    rules={{
                      required: true,
                      validate: {
                        // prenvents negatives, but also strings or empty because the parseInt
                        negative: (v) => parseInt(v) > 0,
                        // prevents chars to be after numbers. Modify to allow hex
                        onlyNum: (v) => {
                          return /^\d+$/.test(v);
                        },
                      },
                    }}
                    render={({
                      field: { value, onChange },
                      fieldState: { error: lengthError },
                    }) => {
                      return (
                        <Input
                          required
                          id="length"
                          label={t('length')}
                          value={value}
                          onChange={(e) => {
                            onChange(e.target.value);
                          }}
                          error={!!lengthError}
                        />
                      );
                    }}
                  />
                  <Controller
                    control={control}
                    name={`fieldSet[${index}].sequentialWithin`}
                    rules={{ required: true }}
                    render={({
                      field: { value, onChange },
                      fieldState: { error: sequentialWithinError },
                    }) => {
                      return (
                        <Select
                          required
                          autowidth={false}
                          options={[
                            { key: 'sameType', desc: 'Same Type' },
                            { key: 'sameEntity', desc: 'Same Entity' },
                          ]}
                          id={`fieldSet[${index}].sequentialWithin`}
                          data-testid="sequentialWithin"
                          label={t('sequentialWithin')}
                          onChange={(e) => {
                            onChange(e);
                          }}
                          value={value}
                          error={sequentialWithinError?.message}
                        />
                      );
                    }}
                  />
                  <Controller
                    control={control}
                    name={`fieldSet[${index}].isHexadecimal`}
                    render={({ field: { onChange } }) => {
                      return (
                        <Checkbox
                          label="isHexadecimal"
                          id={t('isHexadecimal')}
                          onChange={(e) => {
                            onChange(e);
                          }}
                        />
                      );
                    }}
                  />
                </>
              )}
              {selectedIdTypes[index] == 'DateTime' && (
                <Controller
                  control={control}
                  name={`fieldSet[${index}].dateFormat`}
                  rules={{ required: true }}
                  render={({
                    field: { value, onChange },
                    fieldState: { error: dateFormatError },
                  }) => {
                    return (
                      <Select
                        required
                        autowidth={false}
                        options={[
                          { key: 'MMDDYY', desc: 'MMDDYY' },
                          { key: 'DDMMYY', desc: 'DDMMYY' },
                          { key: 'YYYYMMDD', desc: 'YYYYMMDD' },
                          { key: 'YYMMDDHHMM', desc: 'YYMMDDHHMM' },
                          { key: 'YYMMDDHHMMSS', desc: 'YYMMDDHHMMSS' },
                        ]}
                        id={`fieldSet[${index}].dateFormat`}
                        data-testid="dateFormat"
                        label={t('dateFormat')}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        value={value}
                        error={dateFormatError?.message}
                      />
                    );
                  }}
                />
              )}
              {selectedIdTypes[index] == 'Constant' && (
                <Controller
                  control={control}
                  name={`fieldSet[${index}].fieldValue`}
                  rules={{ required: true }}
                  render={({
                    field: { value, onChange },
                    fieldState: { error: fieldValueError },
                  }) => {
                    return (
                      <Input
                        required
                        id="fieldValue"
                        label={t('fieldValue')}
                        value={value}
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                        error={!!fieldValueError}
                      />
                    );
                  }}
                />
              )}
              {selectedIdTypes[index] == 'Separator' && (
                <Controller
                  control={control}
                  name={`fieldSet[${index}].fieldValue`}
                  rules={{ required: true }}
                  render={({
                    field: { value, onChange },
                    fieldState: { error: fieldValueError },
                  }) => {
                    return (
                      <Select
                        required
                        autowidth={false}
                        options={[
                          { key: '-', desc: '-' },
                          { key: '/', desc: '/' },
                          { key: '_', desc: '_' },
                          { key: ' ', desc: 'Space' },
                        ]}
                        id={`fieldSet[${index}].fieldValue-seperator`}
                        data-testid="fieldValue"
                        label={t('fieldValue')}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        value={value}
                        error={fieldValueError?.message}
                      />
                    );
                  }}
                />
              )}
            </Stack>
          </Grid>
        );
      })}
      <Button
        onClick={() => {
          append({
            fieldType: '',
          });
        }}
        aria-label="add"
        id="addButton"
        size="medium"
        variant="outlined"
        type="secondary"
        data-testid="add-button"
        sx={{ m: 1 }}
        disabled={fields.length >= 10}
      >
        {t('add')}
      </Button>
    </>
  );
}

export default FieldSetForm;
