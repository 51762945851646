import { useEffect, useState } from 'react';

import { Edit, Delete } from '@mui/icons-material';
import { MessageType } from '@revenue-solutions-inc/revxcoreui';
import Dialog from '@revenue-solutions-inc/revxcoreui/material/controls/Dialog';
import DataTable from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import HeaderColumn from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import { ColumnDef } from '@tanstack/react-table';
import IdentifierLink from 'components/entityManagement/common/IdentifierLink';
import {
  getDestinationType,
  getRelationshipList,
  LinkTypes,
} from 'components/entityManagement/common/relationshipUtils';
import MaintainRelationship from 'components/entityManagement/relationships/MaintainRelationship';
import Loading from 'components/Loading';
import {
  useDeleteRelationshipMutation,
  useGetAllRelationshipsQuery,
} from 'generated/graphql';
import { useAppDispatch } from 'redux/hooks';
import { useTranslation } from 'react-i18next';
import { Relationships, RelationshipForm } from 'types/relationships';
import { getFormatDate } from 'utils/date-util';
import { addMessage } from 'redux/messageSlice';
import extractMeaningfulMessage from 'utils/errorMessage';

interface Props {
  id: string | undefined | null;
  sourceType: string;
  sourceName: string;
  sourceId: string;
  executeRefetch: boolean;
}

function RelationshipList({
  id,
  sourceType,
  sourceName,
  sourceId,
  executeRefetch,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [openMaintain, setOpenMaintain] = useState<boolean>(false);
  const [relationshipList, setRelationshipList] = useState<Relationships[]>([]);
  const [relationshipEdit, setRelationshipEdit] = useState<
    Relationships | undefined
  >(undefined);
  const [relationshipForm, setRelationshipForm] = useState<
    RelationshipForm | undefined
  >(undefined);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
  const { mutate: deleteRelationship } = useDeleteRelationshipMutation({});
  const dispatch = useAppDispatch();

  const { isFetching, refetch } = useGetAllRelationshipsQuery(
    {
      id: id ? id : '',
    },
    {
      enabled: !!id,
      onSuccess: async (relationshipsData) => {
        const tempRelationships = getRelationshipList(relationshipsData);
        setRelationshipList(tempRelationships);
      },
    }
  );

  const setMaintainRelationship = (currentRelationship: Relationships) => {
    setRelationshipForm({
      relationships: [
        {
          relationshipType: currentRelationship.relationshipType,
          commenceDate: currentRelationship.commenceDate,
          ceaseDate: currentRelationship.ceaseDate,
        },
      ],
    });
  };

  const closeMaintainOnSuccess = () => {
    setOpenMaintain(false);
  };

  const onDelete = () => {
    deleteRelationship(
      {
        relationship: {
          id: relationshipEdit?.id,
          linkType: relationshipEdit?.linkType,
        },
      },
      {
        onSuccess: () => {
          dispatch(
            addMessage({
              type: MessageType.Success,
              message: t('pages.relationships.confirmationDeleteMessage'),
            })
          );
          setIsOpenDeleteModal(false);
          refetch();
        },
        onError: (e: Error[] | unknown) => {
          let message: string = t('pages.relationships.error');
          message = extractMeaningfulMessage(e, message);
          dispatch(
            addMessage({
              type: MessageType.Error,
              message: message,
            })
          );
        },
      }
    );
  };

  useEffect(() => {
    refetch();
  }, [executeRefetch, refetch]);

  const columns: ColumnDef<Relationships>[] = [
    {
      id: 'destination',
      accessorKey: 'destination',
      header: () => (
        <HeaderColumn
          localization={t('pages.relationships.list.destinationId')}
        />
      ),
      cell: ({ row }) => {
        return id ? (
          <IdentifierLink
            id={row.original.destination}
            section="identifiers"
            linkType={row.original.type}
          />
        ) : (
          '-'
        );
      },
    },
    {
      id: 'type',
      accessorKey: 'type',
      header: () => (
        <HeaderColumn localization={t('pages.relationships.list.type')} />
      ),
      cell: ({ getValue }) => {
        const linkType = getValue() as number;
        const type = getDestinationType(linkType);
        return t(`pages.relationships.${type}`);
      },
    },
    {
      id: 'relationshipType',
      accessorKey: 'relationshipType',
      header: () => (
        <HeaderColumn
          localization={t('pages.relationships.list.relationshipType')}
        />
      ),
    },
    {
      id: 'destinationName',
      accessorKey: 'destinationName',
      header: () => (
        <HeaderColumn
          localization={t('pages.relationships.list.destinationName')}
        />
      ),
      cell: ({ row }) => {
        return id ? (
          <IdentifierLink
            id={row.original.destination}
            section={
              row.original.type !== LinkTypes.EntityToAccount
                ? 'names'
                : 'account'
            }
            linkType={row.original.type}
          />
        ) : (
          '-'
        );
      },
    },
    {
      id: 'commenceDate',
      accessorKey: 'commenceDate',
      header: () => (
        <HeaderColumn
          localization={t('pages.relationships.list.commenceDate')}
        />
      ),
      cell: ({ getValue }) =>
        getFormatDate(new Date((getValue() as string) || '')),
    },
    {
      id: 'ceaseDate',
      accessorKey: 'ceaseDate',
      header: () => (
        <HeaderColumn localization={t('pages.relationships.list.ceaseDate')} />
      ),
      cell: ({ getValue }) => {
        const ceaseDate = getValue() as string;
        return ceaseDate !== null ? getFormatDate(new Date(ceaseDate)) : '-';
      },
    },
    {
      id: 'edit',
      header: () => (
        <HeaderColumn localization={t('pages.relationships.list.edit')} />
      ),
      cell: ({ row }) => {
        return (
          <button
            onClick={() => {
              setRelationshipEdit(row.original);
              setMaintainRelationship(row.original);
              setOpenMaintain(true);
            }}
            style={{
              color: '#1B75E2',
              border: '1px solid #1B75E2',
              borderRadius: '4px',
            }}
          >
            <Edit
              style={{ cursor: 'pointer' }}
              color="primary"
              onClick={() => {
                setRelationshipEdit(row.original);
                setMaintainRelationship(row.original);
                setOpenMaintain(true);
              }}
            />
          </button>
        );
      },
    },
    {
      id: 'delete',
      header: () => (
        <HeaderColumn localization={t('pages.relationships.list.delete')} />
      ),
      cell: ({ row }) => {
        return (
          <button
            onClick={() => {
              setRelationshipEdit(row.original);
              setIsOpenDeleteModal(true);
            }}
            style={{
              color: '#1B75E2',
              border: '1px solid #1B75E2',
              borderRadius: '4px',
            }}
          >
            <Delete
              style={{ cursor: 'pointer' }}
              color="primary"
              onClick={() => {
                setRelationshipEdit(row.original);
                setIsOpenDeleteModal(true);
              }}
            />
          </button>
        );
      },
    },
  ];

  return (
    <>
      {isFetching && <Loading fullScreen={false} />}
      {relationshipList.length === 0 &&
        !isFetching &&
        t('pages.relationships.noData')}
      {relationshipList.length > 0 && !isFetching && (
        <DataTable
          data={relationshipList}
          columns={columns as ColumnDef<Record<string, unknown>>[]}
        />
      )}
      <Dialog
        id="maintain-relationship-modal-form"
        type="passive"
        title={t('pages.relationships.maintain')}
        open={openMaintain}
        handleClose={() => setOpenMaintain(!openMaintain)}
        height={350}
        maxWidth="md"
      >
        {relationshipEdit && relationshipForm ? (
          <MaintainRelationship
            sourceType={sourceType}
            sourceName={sourceName}
            sourceId={sourceId}
            relationship={relationshipEdit}
            relationshipForm={relationshipForm}
            refetchData={refetch}
            handleMaintainModal={closeMaintainOnSuccess}
          />
        ) : (
          <></>
        )}
      </Dialog>
      <Dialog
        id="delete-relationship-modal"
        type="danger"
        title={t('pages.relationships.deleteModalTitle')}
        open={isOpenDeleteModal}
        dangerModalDangerButtonText={'Delete'}
        handleClose={() => setIsOpenDeleteModal(false)}
        handleCancelClick={() => setIsOpenDeleteModal(false)}
        handleDangerClick={() => {
          onDelete();
        }}
        maxWidth="sm"
      >
        {t('pages.relationships.deleteModalMessage')}
      </Dialog>
    </>
  );
}

export default RelationshipList;
