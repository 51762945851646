import { useState } from 'react';
import Loading from 'components/Loading';
import { useParams } from 'react-router-dom';

import {
  useRolesByModuleQuery,
  useTemplateHistoryQuery,
} from 'generated/graphql';

import CorrespondenceDetails from '../../../../components/correspondenceAndHeaderFooter/detailsPage/DetailsPage';
import { EmptyTemplateRecord } from '../../../../components/correspondenceAndHeaderFooter/common/InitialTableRecords';

function ManageCorrespondenceType(): JSX.Element {
  const [moduleId] = useState<string>('0');

  const { corespId } = useParams() as {
    corespId: string;
  };

  const {
    data: templateHistory,
    isFetching: isFetching,
    refetch,
  } = useTemplateHistoryQuery({ correspondenceId: corespId ? corespId : '' });

  const { data: rolesByModule, isFetching: rolesFetch } = useRolesByModuleQuery(
    {
      moduleId: moduleId,
    }
  );

  return (
    <>
      {(isFetching || rolesFetch) && <Loading />}

      {!isFetching && !rolesFetch && templateHistory?.TemplateHistory ? (
        <CorrespondenceDetails
          contentData={[]}
          templateData={
            templateHistory?.TemplateHistory ?? [EmptyTemplateRecord]
          }
          isFromTemplate={true}
          reFetch={refetch}
          correspondenceId={parseInt(corespId)}
          rolesData={rolesByModule?.RolesByModule ?? []}
          rcId={1}
        />
      ) : null}
    </>
  );
}

export default ManageCorrespondenceType;
