import { useContext } from 'react';
import { WorkflowForm } from 'components/Workflow/Form';
import { WorkflowFormReducerState } from 'components/Workflow/Form/workflowFormReducer';
import {
  GetWorkflowGroupsQuery,
  useUpdateWorkflowVariantObjectMutation,
} from 'generated/graphql';
import { useNavigate } from 'react-router-dom';
import { WorkflowFormContext } from 'components/Workflow/WorkflowFormContext';
import { Groups, processAttributesForBackend } from '../workflowCommon';

type UpdateCaseProps = {
  variantId: string;
  variantKey: string;
  module: string;
  variantData: GetWorkflowGroupsQuery | undefined;
  workflowData: GetWorkflowGroupsQuery | undefined;
  workType: string;
};

const UpdateCase = ({
  variantId,
  variantKey: key,
  module,
  variantData,
  workflowData,
  workType,
}: UpdateCaseProps) => {
  const navigate = useNavigate();

  const { setCaseStateCopy } = useContext(WorkflowFormContext);

  const { mutate } = useUpdateWorkflowVariantObjectMutation();
  const fieldsToHide = ['FormID'];

  const handleSubmit = (state: WorkflowFormReducerState) => {
    // get steps from attributes
    const step =
      state.attributes?.find((a) => a.attributeName === 'Steps')
        ?.attributeValue || 'step';

    const attributesForBackend = processAttributesForBackend(state.attributes);
    const existingWorkflowData = JSON.parse(
      workflowData?.GetWorkflowGroups[0]?.groupName ?? ''
    );

    const groups: Groups = {
      ...existingWorkflowData,
      ...attributesForBackend,
      customAttributes: state.customAttributes,
      DisplayId: state.workId,
      WorkflowContexts: state.workflowContext,
      NoteIds: state.notes.map((n) => String(n)),
    };

    mutate(
      {
        groups: JSON.stringify(groups),
        variantId,
        step,
        key,
      },
      {
        onSuccess: () => {
          setCaseStateCopy(null);
          navigate(`/${module}/workflows`, { state: undefined });
        },
      }
    );
  };

  return (
    <WorkflowForm
      isEdit
      data={variantData}
      workflowData={workflowData}
      variantId={variantId}
      onSubmit={handleSubmit}
      workType={workType}
      hiddenFields={fieldsToHide}
    />
  );
};

export default UpdateCase;
