import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { Card, CardHeader } from '@revenue-solutions-inc/revxcoreui';
import { Box } from '@mui/system';

interface Props {
  setActive: React.Dispatch<React.SetStateAction<string>>;
  title: string;
  children?: JSX.Element;
  setActiveNoteCard?: React.Dispatch<React.SetStateAction<string>>;
}

function EditPropertyCardLayout({
  setActive,
  title,
  children,
  setActiveNoteCard,
}: Props): JSX.Element {
  return (
    <Box>
      <Card
        sx={{
          minWidth: '80rem',
          minHeight: '40rem',
          borderRadius: '5px',
        }}
      >
        <CardHeader
          action={
            <IconButton
              onClick={() => {
                setActive('');
                if (setActiveNoteCard) {
                  setActiveNoteCard('');
                }
              }}
            >
              <CloseIcon sx={{ fill: 'white', marginTop: '-10px' }} />
            </IconButton>
          }
          title={title}
        />
        {children}
      </Card>
    </Box>
  );
}

export default EditPropertyCardLayout;
