import { Row } from '@tanstack/react-table';
import { Batch } from 'types/batches/entities';

export const customFilter = (
  rows: Array<Row<Batch>>,
  columnIds: Array<string>,
  filterValue: string
) => {
  return rows.filter((row) => {
    let include = false;
    const searchValue = filterValue.toLowerCase();
    columnIds.forEach((columnId: string) => {
      if (
        row.getValue<string>(columnId) &&
        row
          .getValue<string>(columnId)
          .toString()
          .toLowerCase()
          .includes(searchValue)
      ) {
        include = true;
        return;
      }

      if (include === false && columnId === 'modules') {
        include = row
          .getValue<[]>(columnId)
          .some((m: { moduleName: string }) =>
            m.moduleName.toLowerCase().includes(searchValue)
          );
      }
    });
    return include;
  });
};
