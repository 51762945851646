import {
  LogixGroupType,
  DestinationObject,
  ConfigLogix,
} from '@revenue-solutions-inc/revxcoreui/material/logix/types/entities';
import DataMapper from '@revenue-solutions-inc/revxcoreui/material/logix/DataMapper/DataMapper';
import { Grid } from '@mui/material';
import { useGetAllSinkObjectCollectionQuery } from 'generated/graphql';
import Loading from 'components/Loading';
import Logix from '../LogiX';

type MapTableHeaderProps = {
  srNumberTitle: string;
  sourceHeaderTitle: string;
  targetHeaderTitle: string;
  actionHeaderTitle: string;
};
export interface DataMapperProps {
  logixGroupObject: LogixGroupType;
  config: ConfigLogix;
  handleMappedLogixGroupSchema?: (logixUpdated: LogixGroupType) => void;
  tableConfig?: MapTableHeaderProps;
  getJson?: boolean;
}

function DataMapperComponent({
  logixGroupObject,
  config,
  tableConfig,
  handleMappedLogixGroupSchema,
  getJson = false,
}: DataMapperProps): JSX.Element {
  //TODO: i will use this when onError event is working
  //const { mutate } = useCheckCompileExpressionMutation();
  const { data, isFetching } = useGetAllSinkObjectCollectionQuery();

  return (
    <Grid container>
      {isFetching && <Loading />}
      {!isFetching && (
        <Grid item xs={12} md={12}>
          <DataMapper
            logixGroupObject={logixGroupObject}
            sinkObjects={
              data
                ? (data.GetAllSinkObjectCollection as unknown as DestinationObject)
                : ({} as unknown as DestinationObject)
            }
            config={config}
            LogixGroup={Logix}
            tableConfig={tableConfig}
            handleMappedLogixGroupSchema={handleMappedLogixGroupSchema}
            getJson={getJson}
          />
        </Grid>
      )}
    </Grid>
  );
}

export default DataMapperComponent;
