import { Controller, UseFormReturn } from 'react-hook-form';
import { Checkbox } from '@revenue-solutions-inc/revxcoreui';

interface ControlledCheckBoxProps {
  // eslint-disable-next-line
  control: UseFormReturn['control'] | any;
  name: string;
  disabled?: boolean;
  id: string;
  label: string;
}

function ControlledCheckBox({
  control,
  name,
  id,
  disabled,
  label,
}: ControlledCheckBoxProps) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => {
        return (
          <Checkbox
            id={id}
            disabled={disabled}
            label={label}
            checked={value ?? false}
            onChange={onChange}
          />
        );
      }}
    />
  );
}

export default ControlledCheckBox;
