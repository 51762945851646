import { ColumnDef, CellContext } from '@tanstack/react-table';
import { ConfigurationResponse } from 'generated/graphql';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

const Translation = () => {
  const { t } = useTranslation();
  return t;
};

const DisplayText = ({
  getValue,
  row: {
    original: { isOOTB },
  },
}: CellContext<ConfigurationResponse, unknown>): JSX.Element => {
  return (
    <Typography sx={{ fontWeight: !!isOOTB ? 'bold' : 'regular' }}>
      {getValue() as string}
    </Typography>
  );
};

export const configHeaderColumns: ColumnDef<ConfigurationResponse>[] = [
  {
    header: () => (
      <HeaderColumnNext
        localization={Translation()?.('pages.configTypeEditor.configType')}
      />
    ),
    id: 'configurationType',
    accessorKey: 'configurationType',
    cell: DisplayText,
  },
  {
    header: () => (
      <HeaderColumnNext
        localization={Translation()?.(
          'pages.configTypeEditor.configTypeGroupName'
        )}
      />
    ),
    id: 'GroupName',
    accessorKey: 'groupName',
    cell: DisplayText,
  },
  {
    header: () => (
      <HeaderColumnNext
        localization={Translation()?.(
          'pages.configTypeEditor.configTypeModuleName'
        )}
      />
    ),
    id: 'ConfigurationModuleDescription',
    accessorKey: 'configurationModuleDescription',
    cell: DisplayText,
  },
];
