import { useState, useEffect } from 'react';
import {
  LogixGroupType,
  DestinationObject,
  GetViewLogixFunctionByType,
  CompileExpressionResponse,
} from '@revenue-solutions-inc/revxcoreui/material/logix/types/entities';
import { MessageType } from '@revenue-solutions-inc/revxcoreui';
import DataMapperV2 from '@revenue-solutions-inc/revxcoreui/material/logix/DataMapperV2/DataMapper';
import { Grid } from '@mui/material';
import {
  useGetAllSinkObjectCollectionQuery,
  useGetViewLogixFunctionByTypeQuery,
} from 'generated/graphql';
import Loading from 'components/Loading';
import { addMessage } from 'redux/messageSlice';
import { useAppDispatch } from 'redux/hooks';
import { useTranslation } from 'react-i18next';

import useMutationRequest from 'hooks/useMutationRequest';
import checkCompileExpession from 'pages/admin/LogixTestHarness/checkCompileExpession';
import useGetAccessToken from 'hooks/useGetAccessToken';
import { ErrorResponse } from 'types/graphqlErrors';

import extractMeaningfulMessage from 'utils/errorMessage';

type ChangeSchemaType = {
  openDialog: boolean;
  closeDialog: () => void;
};
export interface DataMapperProps {
  logixGroupObject: LogixGroupType;
  changeSchema?: ChangeSchemaType;
  handleMappedLogixGroupSchema?: (logixUpdated: LogixGroupType) => void;
}

function DataMapperV2UIComponent({
  logixGroupObject,
  handleMappedLogixGroupSchema,
  changeSchema,
}: DataMapperProps): JSX.Element {
  const [functionOpt, setFunctionOpt] = useState<
    GetViewLogixFunctionByType[] | []
  >([]);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const accessToken = useGetAccessToken();
  const mutationRequest = useMutationRequest<CompileExpressionResponse>();

  const { data, isFetching } = useGetAllSinkObjectCollectionQuery(
    {},
    {
      onError: (e: Error[] | unknown) => {
        let message: string = t('pages.dataMapperV2.networkError');
        message = extractMeaningfulMessage(e, message);
        dispatch(
          addMessage({
            type: MessageType.Error,
            message: message,
          })
        );
      },
    }
  );
  const { data: value } = useGetViewLogixFunctionByTypeQuery({
    type: 'Field',
  });
  useEffect(() => {
    if (value) {
      setFunctionOpt(
        value.GetViewLogixFunctionByType as unknown as GetViewLogixFunctionByType[]
      );
    }
  }, [value]);

  const checkCompileExpresion = async (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataSend: any
  ): Promise<CompileExpressionResponse> => {
    return new Promise((resolve, reject) => {
      mutationRequest.mutate(
        {
          query: checkCompileExpession,
          params: dataSend,
          paramsId: 'RootLogix',
          mutationKey: 'checkCompileExpression',
          token: accessToken ?? '',
        },
        {
          onSuccess: async (
            response: ErrorResponse | CompileExpressionResponse
          ) => {
            if ((response as ErrorResponse).errors) {
              reject((response as ErrorResponse).errors);
            } else {
              resolve(response as CompileExpressionResponse);
            }
          },
          onError: async (error) => {
            reject(error);
          },
        }
      );
    });
  };

  return (
    <Grid container>
      {isFetching && <Loading />}
      {!isFetching && (
        <Grid item xs={12} md={12}>
          <DataMapperV2
            logixGroupObject={logixGroupObject}
            sinkObjects={
              data
                ? (data.GetAllSinkObjectCollection as unknown as DestinationObject)
                : ({} as unknown as DestinationObject)
            }
            changeSchema={changeSchema}
            handleMappedLogixGroupSchema={handleMappedLogixGroupSchema}
            functionTypes={functionOpt}
            checkCompileExpresionAPI={checkCompileExpresion}
          />
        </Grid>
      )}
    </Grid>
  );
}

export default DataMapperV2UIComponent;
