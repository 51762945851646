import { useEffect, useMemo } from 'react';

import { useIsAuthenticated } from '@azure/msal-react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { Navigate, useNavigate } from 'react-router-dom';
import Loading from 'components/Loading';
import { useUserRolesByModuleQuery } from 'generated/graphql';
import { setRoles } from 'redux/userSlice';
import PageNotFound from 'components/PageNotFound';
import { useHasAccess } from 'hooks/useHasAccess';

interface Props {
  exact?: boolean;
  path?: string | string[];
  policy?: string;
  componentName?: string;
  Component: JSX.Element;
  actionName?: 'edit' | 'view';
}

const SecureRoute = ({
  Component,
  componentName = '',
  actionName = 'view',
}: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const roles = useAppSelector((state) => state.user.roles);
  const modules = useAppSelector((state) => state.content.modules);
  const module = useAppSelector((state) => state.user?.module);
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();

  const moduleId: number = useMemo(
    () =>
      modules?.find((item) => item.name?.toLowerCase() === module.toLowerCase())
        ?.id ?? -1,
    [module, modules]
  );

  const { data: userRoles, isFetching } = useUserRolesByModuleQuery(
    { moduleId: moduleId },
    { enabled: moduleId > -1 }
  );

  // This useEffect is useful to avoid to set preferred modules again and again when we change the modules
  useEffect(() => {
    if (isAuthenticated)
      if (userRoles?.UserRolesByModule) {
        dispatch(setRoles(userRoles?.UserRolesByModule));
      }
  }, [dispatch, userRoles?.UserRolesByModule, isAuthenticated]);

  const policyGroupExist: boolean = useHasAccess(componentName, actionName);

  useEffect(() => {
    if (!isFetching && !roles) {
      navigate('/');
    }
  }, [isFetching, roles, navigate, module]);

  if (!isFetching && roles) {
    if (isAuthenticated) {
      if (policyGroupExist) {
        return Component;
      }
      return isFetching ? <Loading /> : <PageNotFound />;
    }
    return <Navigate to="/login" />;
  }
  return isFetching ? <Loading /> : <PageNotFound />;
};

export default SecureRoute;
