import { useState, useEffect } from 'react';

import { Grid } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import CurrencyCell from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/TableCells/CurrencyCell';
import DateCell from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/TableCells/DateCell/DateCell';
import DataTableNextDateRangeFilter from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DataTableNextDateRangeFilter';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import { ColumnDef } from '@tanstack/react-table';
import { filterDepositsByDateRange } from 'common/helpers';
import Loading from 'components/Loading';
import { gql } from 'graphql-request';
import useGetAccessToken from 'hooks/useGetAccessToken';
import useQueryRequest from 'hooks/useQueryRequest';
import Hotkeys from 'react-hot-keys';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { setHeader } from 'redux/contentSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { Deposit } from 'types/entities';

const query = gql`
  query ($depositBeginDate: String!) {
    FindDeposit(depositBeginDate: $depositBeginDate) {
      id
      label
      depositDate
      notes
      depositStatus
      depositAmount
      batchesCount
      source
      depositAccount
      documentCount
      createdDate
      updatedDate
      updatedBy
      createdBy
    }
  }
`;

function ManageDepositsProperty(): JSX.Element {
  const { t } = useTranslation();
  const theme = useTheme();
  const accessToken = useGetAccessToken();
  const [beginDate, setBeginDate] = useState<Date | null>();
  const [endDate, setEndDate] = useState<Date | null>();
  const dispatch = useAppDispatch();
  const module = useAppSelector((state) => state.user.module);

  const {
    isLoading,
    error,
    data: { FindDeposit } = {},
  } = useQueryRequest<{
    FindDeposit: [Deposit];
  }>(['depositList', `01-01-2000`], accessToken, query, {
    depositBeginDate: `01-01-2000`,
  });

  const Columns: ColumnDef<Deposit>[] = [
    {
      id: 'label',
      accessorKey: 'label',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.manageDeposits.depositLabel')}
        />
      ),
      cell: ({ getValue, row }) => (
        <Link
          to={{ pathname: `/${module}/depositDetails/${row.original.id}` }}
          style={{ color: theme.palette.linkBlue.dark }}
        >
          {getValue() as string}
        </Link>
      ),
    },

    {
      id: 'depositStatus',
      accessorKey: 'depositStatus',
      header: () => (
        <HeaderColumnNext localization={t('pages.manageDeposits.status')} />
      ),
    },

    {
      id: 'depositDate',
      accessorKey: 'depositDate',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.manageDeposits.depositDate')}
        />
      ),
      cell: ({ getValue }) => <DateCell dateString={getValue() as string} />,
    },

    {
      id: 'depositAmount',
      accessorKey: 'depositAmount',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.manageDeposits.depositAmount')}
        />
      ),
      cell: ({ getValue }) => (
        <Box sx={{ mr: '50%' }}>
          <CurrencyCell
            invalidValue={t('pages.tableCell.invalidValue')}
            amountString={getValue() as string}
          />
        </Box>
      ),
    },

    {
      id: 'depositAccount',
      accessorKey: 'depositAccount',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.manageDeposits.depositAccount')}
        />
      ),
    },

    {
      id: 'createdBy',
      accessorKey: 'createdBy',
      header: () => (
        <HeaderColumnNext localization={t('pages.manageDeposits.createdBy')} />
      ),
    },

    {
      id: 'createdDate',
      accessorKey: 'createdDate',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.manageDeposits.createdDate')}
        />
      ),
      cell: ({ getValue }) => <DateCell dateString={getValue() as string} />,
    },
  ];

  const onKeyPress = (keyName: string) => {
    if (keyName === 'escape') setBeginDate(null);
  };

  useEffect(() => {
    dispatch(setHeader({ pageTitle: t('pages.manageDeposits.title') }));
  }, [dispatch, t]);

  if (!isLoading && FindDeposit) {
    return (
      <>
        <Hotkeys
          keyName="escape"
          onKeyDown={onKeyPress}
          onKeyUp={onKeyPress}
        ></Hotkeys>
        {isLoading && <Loading />}
        {FindDeposit && (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <DefaultDataTableNext
                columns={Columns as ColumnDef<Record<string, unknown>>[]}
                data={
                  beginDate || endDate
                    ? filterDepositsByDateRange(FindDeposit, beginDate, endDate)
                    : FindDeposit
                }
                customHeader={
                  <>
                    <DataTableNextDateRangeFilter
                      data-testid="datatable-date-range"
                      beginDate={beginDate ? beginDate : null}
                      endDate={endDate ? endDate : null}
                      setBeginDate={setBeginDate}
                      setEndDate={setEndDate}
                    />
                  </>
                }
                globalFilterLabel={t('pages.manageDeposits.findDeposits')}
              />
            </Grid>
          </Grid>
        )}
      </>
    );
  }
  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <h1>{t('components.message.error')}</h1>;
  }
  return <></>;
}
export default ManageDepositsProperty;
