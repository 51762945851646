import { useMemo, useState } from 'react';

import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import DateCell from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/TableCells/DateCell';
import { ColumnDef } from '@tanstack/react-table';
import Loading from 'components/Loading';
import { HistoryResponse, useAuditHistoryQuery } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

type Props = {
  tenantId: string;
};

const EntityAuditHistory = ({ tenantId }: Props) => {
  const { t } = useTranslation();
  const { entityId, accountId, assetId } = useParams() as {
    entityId: string | undefined;
    accountId: string | undefined;
    assetId: string | undefined;
  };
  const [auditHistoryData, setAuditHistoryData] = useState<HistoryResponse[]>(
    []
  );

  const { isFetching } = useAuditHistoryQuery(
    {
      historyInput: {
        entityId,
        tenantId,
        accountId,
        assetId,
      },
    },
    {
      enabled: !!tenantId,
      onSuccess(data) {
        setAuditHistoryData(data.AuditHistory);
      },
    }
  );

  const columns: ColumnDef<HistoryResponse>[] = useMemo(
    () => [
      {
        id: 'executedBy',
        accessorKey: 'user',
        header: () => (
          <HeaderColumnNext
            localization={t('pages.entitySummary.auditHistory.executedBy')}
          />
        ),
      },
      {
        id: 'updatedDate',
        accessorKey: 'updatedDate',
        header: () => (
          <HeaderColumnNext
            localization={t('pages.entitySummary.auditHistory.updateDate')}
          />
        ),
        sortDescFirst: false,
        cell: ({ getValue }) => <DateCell dateString={String(getValue())} />,
      },
      {
        id: 'type',
        accessorKey: 'type',
        header: () => (
          <HeaderColumnNext
            localization={t('pages.entitySummary.auditHistory.type')}
          />
        ),
      },
      {
        id: 'operation',
        accessorKey: 'operation',
        header: () => (
          <HeaderColumnNext
            localization={t('pages.entitySummary.auditHistory.operation')}
          />
        ),
      },
    ],
    [t]
  );

  return (
    <>
      {isFetching && <Loading fullScreen={false} />}
      {auditHistoryData.length === 0 &&
        !isFetching &&
        t('pages.entitySummary.auditHistory.noData')}
      {auditHistoryData.length > 0 && !isFetching && (
        <DefaultDataTableNext columns={columns} data={auditHistoryData} />
      )}
    </>
  );
};

export default EntityAuditHistory;
