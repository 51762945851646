import { useMemo, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { Card, CardContent } from '@revenue-solutions-inc/revxcoreui';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import { ColumnDef } from '@tanstack/react-table';
import { t } from 'i18next';
import { useGetAllPrintBatchesQuery } from 'generated/graphql';
import { batchHistoryFilterByDateRange } from 'common/helpers';
import DateCell from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/TableCells/DateCell';
import Loading from 'components/Loading';
import DataTableNextDateRangeFilter from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DataTableNextDateRangeFilter';

function BatchHistory(): JSX.Element {
  type BatchHistoryData = {
    batchPrintId: number;
    printGroup: string;
    records: number;
    pages: number;
    generatedDate: string;
    status: string;
  };
  const [beginDate, setBeginDate] = useState<Date | null>();
  const [endDate, setEndDate] = useState<Date | null>();

  const { data, isLoading } = useGetAllPrintBatchesQuery();

  const BatchHistoryColumns: ColumnDef<BatchHistoryData>[] = useMemo(() => {
    return [
      {
        header: () => (
          <HeaderColumnNext localization={t('pages.printManagement.batchId')} />
        ),
        accessorKey: 'printBatchDisplayId',
      },
      {
        header: () => (
          <HeaderColumnNext
            localization={t('pages.printManagement.printGroup')}
          />
        ),
        accessorKey: 'printGroupName',
      },

      {
        header: () => (
          <HeaderColumnNext localization={t('pages.printManagement.records')} />
        ),
        accessorKey: 'recordCount',
      },
      {
        header: () => (
          <HeaderColumnNext localization={t('pages.printManagement.pages')} />
        ),
        accessorKey: 'totalPageCount',
      },
      {
        header: () => (
          <HeaderColumnNext
            localization={t('pages.printManagement.generatedDate')}
          />
        ),
        accessorKey: 'completionTime',
        cell(props) {
          return (
            <DateCell
              dateString={
                props.getValue()
                  ? new Date(String(props.getValue())).toLocaleDateString() ||
                    'null'
                  : ''
              }
            />
          );
        },
      },
      {
        header: () => (
          <HeaderColumnNext localization={t('pages.printManagement.status')} />
        ),
        accessorKey: 'status',
      },
    ];
  }, []);

  return (
    <>
      <Grid container spacing={3}>
        <Card
          sx={{
            borderRadius: '5px',
          }}
        ></Card>
        <CardContent>
          {isLoading && <Loading />}
          <Box>
            <DefaultDataTableNext
              data={
                beginDate || endDate
                  ? batchHistoryFilterByDateRange(
                      data?.getAllPrintBatches,
                      beginDate,
                      endDate
                    )
                  : data?.getAllPrintBatches ?? []
              }
              columns={
                BatchHistoryColumns as ColumnDef<
                  Record<string, unknown>,
                  unknown
                >[]
              }
              sx={{ alignItems: 'end' }}
              customHeader={
                <Box sx={{ display: 'flex', alignItems: 'end' }}>
                  <DataTableNextDateRangeFilter
                    data-testid="datatable-date-range"
                    beginDate={beginDate ? beginDate : null}
                    endDate={endDate ? endDate : null}
                    setBeginDate={setBeginDate}
                    setEndDate={setEndDate}
                  />
                </Box>
              }
            ></DefaultDataTableNext>
          </Box>
        </CardContent>
      </Grid>
    </>
  );
}

export default BatchHistory;
