import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  MessageType,
  FormErrors,
  ProgressLoader,
  LogixGroupType,
} from '@revenue-solutions-inc/revxcoreui';
import { setHeader } from 'redux/contentSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { Grid } from '@mui/material';
import {
  LogiXgroupInput,
  FormTemplate,
  useUpdateFormMutation,
  useGetFormQuery,
  BusinessSectionInput,
  FormLayoutConfiguration,
  AActionsInput,
  Field4Input,
  useGetConfigurationRecordsQuery,
  FormDataObject,
} from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { addMessage } from 'redux/messageSlice';
import { useParams } from 'react-router';
import Loading from 'components/Loading';
import { Error } from 'types/graphqlErrors';
import { LayoutBuilderHandle } from '@revenue-solutions-inc/revxcoreui/material/layoutBuilder/builder/AppPanel';
import { JsonPath } from 'utils/filterJsonData';
import FormLogix from '../CreateForms/FormLogix';

import FormDataMapper from '../CreateForms/FormDataMapper';
import FormLayoutBuilder from '../CreateForms/FormLayoutBuilder';

interface VersionArray {
  [key: string]: FormLayoutConfiguration;
}

interface ActionProps {
  name: string;
  category: string;
  repeatable: boolean;
  statusToBeChanged: string;
  domain: string;
  operationId: string;
  operationType: string;
}

function EditForm(): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const module = useAppSelector((state) => state.user.module);
  const { formId } = useParams() as { formId: string };

  const {
    data,
    isFetching,
    refetch: getFormQuery,
  } = useGetFormQuery({
    formGroupId: formId,
  });
  const [loading, setLoading] = useState<boolean>(isFetching);

  const navigate = useNavigate();
  const [formErrors, setFormErrors] = useState<FormErrors[] | []>([]);

  const [completed, setCompleted] = useState<{
    [k: number]: boolean;
  }>({});
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [LogixGroup, setLogixGroup] = useState<LogiXgroupInput | null>(null);
  const [stepPassedValidation, setStepPassedValidation] =
    useState<boolean>(true);
  const [actionsTypes, setActionsTypes] = useState<ActionProps[] | []>([]);
  const refBtnDM = useRef<HTMLButtonElement>(null);
  const [isSubmiting, setIsSubmiting] = useState<boolean>(false);

  //TODO: Layouts
  const layoutRef = useRef<LayoutBuilderHandle>(null);
  const latestVersionOfLayout: VersionArray = {};

  const handleReset = () => {
    setCurrentStep(0);
    setCompleted({});
  };

  const { mutate, isLoading: isLoadingUpdate } = useUpdateFormMutation({});

  const editForm = async () => {
    setIsSubmiting(true);
    mutate(
      {
        formId: formId,
        formTemplate: LogixGroup as LogiXgroupInput,
      },
      {
        onSuccess: () => {
          getFormQuery();
          dispatch(
            addMessage({
              type: MessageType.Success,
              message: t('pages.manageFormsProcessing.updated'),
            })
          );
          navigate(`/${module}/manageforms`);
          setIsSubmiting(false);
        },
        onError: (errors) => {
          const formErr = (errors as Error[])[0].extensions.response.body
            .errors;
          if (formErr && formErr?.length > 0) {
            setFormErrors(formErr as FormErrors[]);
            dispatch(
              addMessage({
                type: MessageType.Error,
                message: (errors as Error[])[0].extensions.response.body
                  .detail as string,
              })
            );
            setIsSubmiting(false);
          } else {
            dispatch(
              addMessage({
                type: MessageType.Error,
                message: t('components.message.networkerror'),
              })
            );
            setIsSubmiting(false);
          }
        },
      }
    );
  };

  const handleMappedLogixGroupSchema = (logixUpdated: LogixGroupType) => {
    setLogixGroup(logixUpdated as LogiXgroupInput);
  };

  useEffect(() => {
    dispatch(
      setHeader({
        pageTitle: t('pages.forms.editForm'),
        previousPage: t('pages.forms.manageList'),
        route: 'manageForms',
      })
    );
  }, [dispatch, t]);

  useEffect(() => {
    setLoading(true);
    if (data !== undefined) {
      const forms: FormTemplate[] = data.getForm.Forms ?? [];
      if (forms.length > 0) {
        const BusinessSections = forms[0].BusinessSections?.map(
          (bsection: BusinessSectionInput) => ({
            ...bsection,
            Fields: bsection?.Fields?.map((d) => ({
              BaseField: d.BaseField,
              FieldValues: [],
            })) as Field4Input[],
            SubSections: [],
          })
        );
        const formUpdate: LogiXgroupInput = {
          Contexts: [
            {
              ContextName: forms[0].FormName ?? '',
              ProcessingOrder: 1,
              BusinessSections: BusinessSections as BusinessSectionInput[],
              GlobalRules: [],
              CustomAttributes: forms[0].CustomAttributes ?? [],
              Actions: forms[0].Actions as AActionsInput[],
            },
          ],
          ProcessingOutput: {
            Messages: [],
            Actions: [],
          },
        };
        setLogixGroup(formUpdate);
      }
      setLoading(false);
    }
  }, [data]);

  useEffect(() => {
    const newCompleted = completed;
    newCompleted[currentStep] = true;

    switch (currentStep) {
      case 0:
        newCompleted[currentStep] = true;
        break;
    }
    setCompleted(newCompleted);
  }, [completed, currentStep]);

  const validateSteps = () => {
    let isValid = true;
    switch (currentStep) {
      case 0:
        isValid = true;
        break;
    }
    setStepPassedValidation(isValid);
  };

  useEffect(() => {
    validateSteps();
  });

  const handleGetLogixUpdate = (logixUpdated: LogixGroupType) => {
    setLogixGroup(logixUpdated as LogiXgroupInput);
  };

  const { data: actionTypesQuery } = useGetConfigurationRecordsQuery({
    configurationModule: '',
    configurationType: 'Form Action Type',
  });
  useEffect(() => {
    if (actionTypesQuery && actionTypesQuery.getConfigurations) {
      const acTypes: ActionProps[] = [];
      actionTypesQuery.getConfigurations.forEach((acType) => {
        const logixFields = JsonPath({
          keyName: 'groupName',
          value: 'Configuration',
          schema: acType,
          searchWith: '',
        });
        const objAct = {
          actionCategory: '',
          actionRepetable: false,
          actionStatus: '',
          actionOperationId: '',
          actionDomain: '',
          actionOperationType: '',
        };
        if (logixFields.length > 0) {
          objAct.actionOperationType =
            logixFields[0].value.attribute.find(
              (it: { attributeName: string }) =>
                it.attributeName === 'OperationType'
            )?.attributeValue ?? '';
          objAct.actionStatus =
            logixFields[0].value.attribute.find(
              (it: { attributeName: string }) =>
                it.attributeName === 'StatusToBeChanged'
            )?.attributeValue ?? false;
          objAct.actionCategory =
            logixFields[0].value.attribute.find(
              (it: { attributeName: string }) => it.attributeName === 'Category'
            )?.attributeValue ?? '';
          objAct.actionRepetable =
            logixFields[0].value.attribute.find(
              (it: { attributeName: string }) =>
                it.attributeName === 'Repeatable'
            )?.attributeValue ?? false;
          objAct.actionDomain =
            logixFields[0].value.attribute.find(
              (it: { attributeName: string }) => it.attributeName === 'Domain'
            )?.attributeValue ?? '';

          objAct.actionOperationId =
            logixFields[0].value.attribute.find(
              (it: { attributeName: string }) =>
                it.attributeName === 'OperationId'
            )?.attributeValue ?? '';
        }
        const action: ActionProps = {
          name: acType.configurationName,
          category: objAct.actionCategory,
          repeatable: Boolean(objAct.actionRepetable),
          statusToBeChanged: objAct.actionStatus,
          domain: objAct.actionDomain,
          operationId: objAct.actionOperationId,
          operationType: objAct.actionOperationType,
        };
        acTypes.push(action);
      });
      setActionsTypes(acTypes);
    }
  }, [actionTypesQuery]);

  return loading || isLoadingUpdate || isSubmiting ? (
    <Loading />
  ) : (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ProgressLoader
          completed={completed}
          handleReset={handleReset}
          setCurrentStep={setCurrentStep}
          handleStepTransition={(cStep: number, previousStep: number) => {
            if (cStep === 0 && previousStep === 1) {
              refBtnDM.current?.click();
            } else if (cStep === 2 && previousStep === 1) {
              refBtnDM.current?.click();
            } else if (cStep === 1 && previousStep === 2) {
              //masterLayoutChangesCache.current = layoutRef.current?.getLayout();
            }
          }}
          steps={[
            t('pages.forms.formStructure'),
            t('pages.manageChannel.dataMapper'),
            t('pages.manageForms.layoutBuilder'),
          ]}
          nonLinear={false}
          currentStep={currentStep}
          handleCurrentStep={(activeStep: number) => {
            setCurrentStep(activeStep);
          }}
          centerAlign
          handleSave={editForm}
          isCompleteStepBtnDisabled={!stepPassedValidation}
          isSaveAndCreateDisabled={false}
        >
          {currentStep === 0 && (
            <FormLogix
              LogixGroup={LogixGroup}
              formErrors={formErrors}
              handleGetLogixUpdate={handleGetLogixUpdate}
            />
          )}
          {currentStep === 1 && (
            <FormDataMapper
              LogixGroup={LogixGroup}
              actions={actionsTypes}
              handleMappedLogixGroupSchema={handleMappedLogixGroupSchema}
              refBtnDM={refBtnDM}
            />
          )}

          {currentStep === 2 && (
            <FormLayoutBuilder
              LogixGroup={LogixGroup}
              layoutRef={layoutRef}
              latestVersionOfLayout={latestVersionOfLayout}
              form={data?.getForm as FormDataObject}
              setFormErrors={setFormErrors}
            />
          )}
        </ProgressLoader>
      </Grid>
    </Grid>
  );
}

export default EditForm;
