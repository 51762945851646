import { useEffect, useState } from 'react';

import FilterListIcon from '@mui/icons-material/FilterList';
import { Slide, Typography } from '@mui/material';
import Menu from '@mui/material/Menu';
import { Box } from '@mui/system';

import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';

import { CheckboxGroup } from '@revenue-solutions-inc/revxcoreui/material/controls/Checkbox/Checkbox';
import Checkbox from '@revenue-solutions-inc/revxcoreui/material/controls/Checkbox';
import Search from '@revenue-solutions-inc/revxcoreui/material/controls/Search/Search';
import appTheme from '@revenue-solutions-inc/revxcoreui/material/themes/defaultTheme';

export interface GlobalFilterProps {
  searchFilter: string;
  setSearchFilter: (filterValue: string) => void;
  label?: string;
  filterBtnText?: string;
  checkboxGroup: CheckboxGroup[];
  update: (list: CheckboxGroup[], val: string) => void;
  resetFilter: boolean;
  setResetFilter: () => void;
}

const boxFlexStyle = {
  display: 'flex',
  width: '200px',
  alignItems: 'center',
  justifyContent: 'space-between',
};

function SearchFilter({
  searchFilter,
  setSearchFilter,
  label,
  filterBtnText = 'Filters',
  checkboxGroup,
  update,
  resetFilter,
  setResetFilter,
}: GlobalFilterProps): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [checked, setChecked] = useState(checkboxGroup);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    if (resetFilter) {
      setResetFilter();
      const checkedClone = checked.map((item) => {
        item.checked = false;
        return item;
      });
      setChecked(checkedClone);
      update(checkedClone, '');
    }
  }, [checked, resetFilter, setResetFilter, update]);

  return (
    <Box sx={{ mt: 0 }}>
      <Typography
        variant="body2"
        sx={label ? { minHeight: '1.44em' } : { display: 'none' }}
      >
        {label}
      </Typography>
      <Box sx={{ display: 'flex' }}>
        <Search
          placeholder="Search"
          value={searchFilter || ''}
          handleChange={setSearchFilter}
        />
        <Button
          id="filter-btn"
          type="secondary"
          size="medium"
          startIcon={<FilterListIcon />}
          sx={{ ml: 2, color: 'linkBlue.dark' }}
          onClick={handleClick}
        >
          {filterBtnText}
        </Button>
      </Box>

      <Menu
        id={`menu`}
        anchorEl={anchorEl}
        open={open}
        sx={{
          '.MuiMenu-list': { backgroundColor: 'common.white' },
        }}
        onClose={handleClose}
      >
        <Slide direction="down" in={open} mountOnEnter unmountOnExit>
          <Box
            component="div"
            sx={{
              p: 1,
              '& .MuiTypography-root': {
                color: appTheme.palette.black.light,
              },
            }}
          >
            <Box sx={boxFlexStyle}>
              <Typography
                sx={{ marginRight: 1, fontSize: 17, fontWeight: 600 }}
              >
                Filter By
              </Typography>
              <Button
                id="reset-filter"
                size="small"
                type="ghost"
                onClick={() => {
                  const checkedClone = checked.map((item) => {
                    item.checked = false;
                    return item;
                  });
                  setChecked(checkedClone);
                  update(checkedClone, '');
                }}
              >
                Reset Filter
              </Button>
            </Box>
            <Checkbox
              id="filter-checkbox"
              onChange={(e) => {
                const checkedClone = [...checked];
                checkedClone.map((t) => (t.checked = false));
                checkedClone.map((item) => {
                  if (e.target.value === item.value)
                    item.checked = !item.checked;
                });
                setChecked(checkedClone);
                update(checkedClone, e.target.value);
              }}
              checkboxGroup={checked}
            />
          </Box>
        </Slide>
      </Menu>
    </Box>
  );
}

export default SearchFilter;
