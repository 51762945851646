import { useEffect, useState } from 'react';

import LockIcon from '@mui/icons-material/Lock';
import {
  Box,
  Collapse,
  IconButton,
  SelectChangeEvent,
  Stack,
  Theme,
  Typography,
} from '@mui/material';
import { Input, Select, Checkbox } from '@revenue-solutions-inc/revxcoreui';
import { SelectType } from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import {
  Attribute,
  ExtensibleBusinessDriver,
  useGetAllSchemasBriefQuery,
} from 'generated/graphql';
import { EditAccessUtil } from 'pages/admin/ConfigTypeEditor/editAccessUtil';
import { deepCopy } from 'utils/deepCopy';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import {
  AttributeTypes,
  attributeTypesList,
  ConfigurationDomains,
  ConfigurationModules,
} from 'common/platformConfigUtils/platformConfigUtils';
import {
  cancelButton,
  checkButton,
  collapseButton,
  deleteButton,
  editButton,
  expandButton,
  fontSize,
  icon,
  ootbIcon,
} from '../Shared/IconButtonStyling/IconButtonStyling';
import ExtensibleBusinessDrivers from '../ExtensibleBusinessDrivers';

interface Props {
  attribute?: Attribute;
  handleChange: (oldAttrName: string, attribute: Attribute) => boolean;
  handleDelete: (oldAttrName: string) => void;
  recordMode?: boolean;
  isNewField?: boolean;
  setNewField?: React.Dispatch<React.SetStateAction<boolean>>;
  editAccess?: EditAccessUtil;
  isExpanded?: boolean;
  isRepeating?: boolean;
  hasRecords?: boolean;
}
const attributeDefault: Attribute = {
  attributeDisplayName: '',
  attributeName: '',
  attributeType: 'string',
  attributeDescription: '',
  extensibleBusinessDriver: [],
  repeatingProperties: false,
  attributeValue: '',
  layoutInfo: {
    format: '',
    mask: '',
    layoutOrder: '0',
    layoutStyle: '',
    validation: '',
  },
  isOOTB: false,
  isOOTBEditable: false,
};
// /^(string|int|boolean|date)$/i
const regexEnableIsExpression = new RegExp(
  `^(${AttributeTypes.string}|${AttributeTypes.int}|${AttributeTypes.boolean}|${AttributeTypes.date})$`,
  'i'
);

function getTenantTypes(types: SelectType[]) {
  // get the list without Extended, schema and Dynamic
  return types.filter(
    ({ key }) =>
      key !== AttributeTypes.extendedAttribute &&
      key !== AttributeTypes.schema &&
      key !== AttributeTypes.dynamicAttribute
  );
}

function AttributeDetails({
  attribute = attributeDefault,
  handleChange,
  handleDelete,
  recordMode = false,
  isNewField,
  setNewField,
  editAccess,
  isExpanded = false,
  isRepeating,
  hasRecords,
}: Props) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.configTypeEditor',
  });

  const [inEditMode, setInEditMode] = useState<boolean>(false);
  const [tempAttribute, setTempAttribute] = useState<Attribute>(
    deepCopy(attribute)
  );
  const [attributeNameExists, setAttributeNameExists] = useState(false);
  const [expanded, setExpanded] = useState(false);

  // if typeSelected = referenceData fetch domain ReferenceSchema module Platform
  // if schema, fetch module Platform and all the domains
  const configurationDomainToFetch =
    tempAttribute.attributeType?.toLowerCase() ===
    AttributeTypes.schema.toLowerCase()
      ? ''
      : ConfigurationDomains.ReferenceSchema.toString();

  const { data: referenceSchemaData, isFetching: isFetchingReferences } =
    useGetAllSchemasBriefQuery(
      {
        configurationDomain: configurationDomainToFetch,
        configurationModule: ConfigurationModules.Platform.toString(),
      },
      { staleTime: 60 * 1000 }
    );

  const optionsReferenceSchema =
    referenceSchemaData?.getAllSchemas.map(
      (option) =>
        ({
          key: option.key,
          desc: option.key,
        } as SelectType)
    ) ?? [];

  /*
    To prevent form sending isOOTB flag true if user is in one group and then changes the group
    to extended attributes. When group changes a re-render is triggered in this component.
    TODO: Maybe we will implement a way to roll back to previous state when deselect extended
    attributes, but from now let see if other ui parts will be hidden.
  */
  if (tempAttribute.isOOTB && editAccess?.isExtendedAttributes) {
    tempAttribute.isOOTB = false;
  }

  const handleAttrType = (event: SelectChangeEvent<string | number>) => {
    const typeSelected = (event.target as HTMLSelectElement).value;
    const newAttribute = { ...tempAttribute };
    newAttribute.attributeType = typeSelected;
    // every time the user selected another type we clear dataSource, in case previously was filled
    newAttribute.dataSource = '';

    if (!regexEnableIsExpression.test(typeSelected)) {
      /*
       * isExpression-checkbox will be hidden if user change from string, int, date... to reference, schema...
       * we need to remove remove that value saved, if the user previously checked the isExpression-checkbox and
       * returns to one type that doesn't allow isExpression.
       */
      newAttribute.isExpression = false;
    }

    setTempAttribute(newAttribute);
  };

  const handleEditClick = () => {
    setInEditMode(true);
    setExpanded(true);
  };

  const handleCancelClick = () => {
    setTempAttribute(attribute);
    setInEditMode(false);
    setNewField?.(false);
  };

  const handleSaveClick = () => {
    const success = handleChange(attribute.attributeName, tempAttribute);
    if (success) {
      setInEditMode(false);
      setAttributeNameExists(false);
      setNewField?.(false);
    } else {
      setAttributeNameExists(true);
    }
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    setTempAttribute(attribute);

    /* each time the user change for another record we disable edit mode */
    if (!isNewField) {
      setInEditMode(false);
    }
  }, [attribute, isNewField]);

  useEffect(() => {
    if (isNewField) {
      setInEditMode(true);
    }
  }, [isNewField]);

  useEffect(() => {
    setExpanded(isExpanded);
  }, [isExpanded]);

  return (
    <Box
      sx={(theme: Theme) => {
        return {
          border: inEditMode ? `solid 2px ${theme.palette.grey[200]}` : 'none',
          backgroundColor: inEditMode ? 'white' : theme.palette.grey[100],
          borderRadius: '5px',
          m: '0px 0px 20px 20px',
          p: '30px 10px 10px 10px',
        };
      }}
    >
      <Stack direction="row" spacing={2.5}>
        <IconButton
          onClick={handleExpandClick}
          sx={editButton}
          aria-label="expand-button"
        >
          <ExpandCircleDownIcon sx={expanded ? collapseButton : expandButton} />
        </IconButton>
        {!inEditMode && !expanded && (
          <>
            <Box mt="-20px !important">
              <Input
                required
                id="attributeNameId"
                onChange={(e) => {
                  setTempAttribute({
                    ...tempAttribute,
                    attributeName: e.target.value,
                  });
                }}
                label={t('attributeName')}
                value={tempAttribute?.attributeName || ''}
                sx={{ minWidth: '200px' }}
                error={attributeNameExists}
                helperText={attributeNameExists ? t('attributeExists') : ''}
                disabled={!inEditMode || hasRecords}
              />
            </Box>
            <IconButton
              onClick={handleEditClick}
              sx={editButton}
              aria-label="edit-button"
              disabled={!editAccess?.controlExtendedGroup()}
            >
              <EditIcon sx={{ icon }} />
            </IconButton>
          </>
        )}
        <Collapse in={expanded || inEditMode} timeout="auto" unmountOnExit>
          <>
            <Stack
              direction="column"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={1.5}
              mt="-20px !important"
            >
              <Stack direction="row" spacing={2.5}>
                <Input
                  required
                  id="attributeNameId"
                  onChange={(e) => {
                    setTempAttribute({
                      ...tempAttribute,
                      attributeName: e.target.value,
                    });
                  }}
                  label={t('attributeName')}
                  value={tempAttribute?.attributeName || ''}
                  sx={{ minWidth: '200px' }}
                  error={attributeNameExists}
                  helperText={attributeNameExists ? t('attributeExists') : ''}
                  disabled={!inEditMode || (hasRecords && !isNewField)}
                />
                {!inEditMode && (
                  <Box
                    sx={{
                      marginTop: '20px !important',
                    }}
                  >
                    <IconButton
                      onClick={handleEditClick}
                      sx={editButton}
                      aria-label="edit-button"
                      disabled={!editAccess?.controlExtendedGroup()}
                    >
                      <EditIcon sx={{ icon }} />
                    </IconButton>
                  </Box>
                )}
                {inEditMode && (
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{ mt: '20px !important', float: 'right' }}
                  >
                    <IconButton
                      size="small"
                      onClick={handleSaveClick}
                      disabled={
                        !tempAttribute.attributeDisplayName ||
                        !tempAttribute.attributeName ||
                        !tempAttribute.attributeType ||
                        (tempAttribute.attributeType.toLowerCase() ===
                          AttributeTypes.referenceData.toLowerCase() &&
                          tempAttribute.dataSource === '') ||
                        (tempAttribute.attributeType.toLowerCase() ===
                          AttributeTypes.schema.toLowerCase() &&
                          tempAttribute.dataSource === '')
                      }
                      sx={checkButton}
                      aria-label="check-button"
                    >
                      <CheckIcon sx={icon} />
                    </IconButton>
                    <IconButton
                      size="small"
                      sx={cancelButton}
                      onClick={handleCancelClick}
                      aria-label="cancel-button"
                    >
                      <CloseIcon fontSize="small" sx={icon} />
                    </IconButton>
                  </Stack>
                )}
              </Stack>
              <Input
                required
                id="attributeDisplayNameId"
                onChange={(e) => {
                  setTempAttribute({
                    ...tempAttribute,
                    attributeDisplayName: e.target.value,
                  });
                }}
                label={t('attributeDisplayName')}
                value={tempAttribute?.attributeDisplayName || ''}
                sx={{ minWidth: '200px' }}
                disabled={!inEditMode}
              />
              <Input
                id="attributeDescription"
                onChange={(e) => {
                  setTempAttribute({
                    ...tempAttribute,
                    attributeDescription: e.target.value,
                  });
                }}
                label={t('attributeDesc')}
                value={tempAttribute?.attributeDescription || ''}
                sx={{ minWidth: '200px' }}
                disabled={!inEditMode}
              />
              <Select
                required
                id="attributeTypeId"
                label={t('attributeType')}
                options={
                  editAccess?.isFullAccess
                    ? attributeTypesList
                    : getTenantTypes(attributeTypesList)
                }
                // attributeTypeList has the format of the enum the source of truth "camelCase" attributeType from server has format before
                // this standar (Capitalized first letter "Schema"), to match the selector we must convert to lower case only the first char
                value={
                  tempAttribute?.attributeType?.replace(/^\w/, (m) =>
                    m.toLowerCase()
                  ) || ''
                }
                onChange={handleAttrType}
                sx={{ minWidth: '200px', maxWidth: '200px' }}
                disabled={!inEditMode}
              />
              {isFetchingReferences && (
                <Typography sx={{ margin: '10px' }}>
                  {t('loadingDatasource')}
                </Typography>
              )}
              {(tempAttribute.attributeType?.toLowerCase() ===
                AttributeTypes.referenceData.toLowerCase() ||
                tempAttribute.attributeType?.toLowerCase() ===
                  AttributeTypes.schema.toLowerCase()) &&
                !isFetchingReferences && (
                  <Select
                    required
                    id="dataSourceID"
                    label={t('attributeDatasource')}
                    options={optionsReferenceSchema}
                    onChange={(e) =>
                      setTempAttribute({
                        ...tempAttribute,
                        dataSource: (e.target as HTMLSelectElement).value,
                      })
                    }
                    value={tempAttribute?.dataSource || ''}
                    sx={{ minWidth: '200px', maxWidth: '200px' }}
                    disabled={!inEditMode}
                  />
                )}
              {tempAttribute.attributeType?.toLowerCase() ===
                AttributeTypes.boolean.toLowerCase() && (
                <Select
                  id="defaultValueSelect"
                  label={t('defaultValue')}
                  options={[
                    { key: 'true', desc: 'True' },
                    { key: 'false', desc: 'False' },
                  ]}
                  onChange={(e) =>
                    setTempAttribute({
                      ...tempAttribute,
                      attributeValue: (e.target as HTMLSelectElement).value,
                    })
                  }
                  value={tempAttribute?.attributeValue || ''}
                  sx={{ minWidth: '200px', maxWidth: '200px' }}
                  disabled={!inEditMode}
                />
              )}
              {/* Only if user is full access and the group is not extended attributes can see the checkbox */}
              {editAccess?.isFullAccess &&
                !editAccess?.isExtendedAttributes && (
                  <Checkbox
                    label={t('attributeSetOOTB')}
                    id="ootbCheckId"
                    checked={!!tempAttribute.isOOTB}
                    onChange={(e) => {
                      setTempAttribute({
                        ...tempAttribute,
                        isOOTB: (e.target as HTMLInputElement).checked,
                      });
                    }}
                    disabled={!inEditMode}
                  />
                )}
              {/* isExpression will be shown only if user select type: string, int, boolean, date, dateTime */}
              {regexEnableIsExpression.test(
                tempAttribute.attributeType || ''
              ) && (
                <Checkbox
                  label={t('isExpression')}
                  id="isExpression"
                  checked={!!tempAttribute.isExpression}
                  onChange={(e) => {
                    setTempAttribute({
                      ...tempAttribute,
                      isExpression: (e.target as HTMLInputElement).checked,
                    });
                  }}
                  disabled={!inEditMode}
                />
              )}
              {recordMode && (
                <Input
                  id="valueId"
                  onChange={(e) => {
                    setTempAttribute({
                      ...tempAttribute,
                      attributeValue: e.target.value,
                    });
                  }}
                  label={t('attributeValue')}
                  value={tempAttribute?.attributeValue || ''}
                  sx={{ minWidth: '200px' }}
                  disabled={!inEditMode}
                />
              )}
            </Stack>
          </>
        </Collapse>
        <Box sx={{ right: '20px', position: 'absolute' }}>
          {!inEditMode && (
            <>
              {tempAttribute.isOOTB && (
                <Box sx={ootbIcon}>
                  <LockIcon titleAccess={t('coreAttributeLegend')} />
                </Box>
              )}
              {!recordMode && (
                <IconButton
                  onClick={() => handleDelete(attribute.attributeName)}
                  disabled={!editAccess?.controlExtendedGroup() || hasRecords}
                  sx={deleteButton}
                  aria-label="delete-button"
                >
                  <Delete sx={{ fontSize }} />
                </IconButton>
              )}
            </>
          )}
        </Box>
      </Stack>
      <Collapse in={expanded || inEditMode} timeout="auto" unmountOnExit>
        <ExtensibleBusinessDrivers
          businessDrivers={tempAttribute.extensibleBusinessDriver ?? []}
          updateBusinessDrivers={(newDrivers: ExtensibleBusinessDriver[]) => {
            setTempAttribute({
              ...tempAttribute,
              extensibleBusinessDriver: newDrivers,
            });
          }}
          editAccess={editAccess}
          inEditMode={inEditMode}
          attributeType={tempAttribute?.attributeType}
          isRepeating={isRepeating}
          isExpression={!!tempAttribute?.isExpression}
        />
      </Collapse>
    </Box>
  );
}
export default AttributeDetails;
