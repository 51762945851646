const searchIds = ['global', 'Entity_ID', 'Account_ID', 'Asset_ID'];
const searchLabels = ['Search', 'Entity', 'Account', 'Asset'];

const listGeneralSearch = searchIds.map((searchid, index) => {
  return { id: searchid, label: searchLabels[index] };
});

export const textStyle = {
  fontSize: '16px',
  p: 0,
  pl: 1,
};

export const containerResultStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  py: '5px',
};

export const resultStyle = {
  justifyContent: 'flex-start',
  minWidth: '100%',
  color: 'grey1.dark',
  borderColor: 'white.main',
  textTransform: 'none',
  ':hover': {
    backgroundColor: 'grey3.light',
    borderColor: 'white.main',
  },
};

export const containerErrorMessageStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  py: '5px',
  color: 'red',
  marginLeft: '10px',
};

export default listGeneralSearch;
