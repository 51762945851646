import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios from 'axios';

const fetchFile = async (
  token: string,
  apiUrl: string,
  name: string,
  body: object,
  typeContent: string
): Promise<void | null> => {
  await axios
    .get(apiUrl, {
      headers: {
        'Content-Type': typeContent,
        Authorization: `Bearer ${token}`,
      },
      responseType: 'arraybuffer',
    })
    .then((response) => {
      const fileName = name;
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      link.click();
    });
};

export default function useDownloadFile(
  downloadFile: boolean,
  accessToken: string | undefined,
  apiUrl: string,
  name: string,
  body: object,
  typeContent: string
): UseQueryResult<void | null, Error> {
  return useQuery<void | null, Error>(
    ['downloadFile'],
    () => {
      return fetchFile(
        accessToken ? accessToken : '',
        apiUrl,
        name.toLowerCase(),
        body,
        typeContent
      );
    },
    {
      enabled: !!accessToken && downloadFile,
    }
  );
}
