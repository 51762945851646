import { Grid } from '@mui/material';

import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { DataDisplay } from '@revenue-solutions-inc/revxcoreui';
import {
  FinancialTransactionDetail,
  ViewFinancialTransactionResponse,
} from 'generated/graphql';
import DataCard from 'components/DataCard';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import { ColumnDef } from '@tanstack/react-table';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import CurrencyCell from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/TableCells/CurrencyCell';
import DateCell from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/TableCells/DateCell';
import { getDate } from 'common/helpers';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import InfoIcon from '@mui/icons-material/Info';
import ReverseTransaction from '../ReverseTransaction';

const Translation = () => {
  const { t } = useTranslation();
  return t;
};

export const transactionColumns: ColumnDef<FinancialTransactionDetail>[] = [
  {
    id: 'amount',
    accessorKey: 'amount',
    header: () => (
      <HeaderColumnNext
        localization={Translation()?.(
          'pages.paymentDetails.application.amount'
        )}
      />
    ),
    cell: ({ getValue }) => (
      <Box sx={{ width: '3.5em' }}>
        <CurrencyCell
          invalidValue={Translation()?.('pages.tableCell.invalidValue')}
          amountString={getValue() as string}
        />
      </Box>
    ),
  },
  {
    id: 'impactType',
    accessorKey: 'attributes.impactType',
    header: () => (
      <HeaderColumnNext
        localization={Translation()?.(
          'pages.paymentDetails.application.impactType'
        )}
      />
    ),
    cell: ({ getValue }) => getValue() as string,
  },
];

interface TransactionDetailProps {
  transaction?: ViewFinancialTransactionResponse;
}

function FinancialTransactionDetails({ transaction }: TransactionDetailProps) {
  const { t } = useTranslation();

  const columns: ColumnDef<FinancialTransactionDetail>[] = [
    ...transactionColumns,
    {
      id: 'effectiveDate',
      accessorKey: 'attributes.effectiveDate',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.FinancialTransaction.effectiveDate')}
        />
      ),
      cell: ({ getValue }) => <DateCell dateString={getValue() as string} />,
    },
  ];

  return (
    <Grid container>
      <Grid item xs={12} xl={12}>
        <DataCard
          title={t('pages.FinancialTransaction.details')}
          avatar={<InfoIcon sx={{ fill: 'primary.main' }} />}
          action={
            transaction?.canReverse &&
            transaction?.financialTransactionSourceId ? (
              <ReverseTransaction
                data-testid="reverse-transaction"
                sourceId={transaction?.financialTransactionSourceId}
              />
            ) : (
              <></>
            )
          }
          children={
            <>
              <Grid container sx={{ display: 'inline-flex' }}>
                <Grid item xs={3} mt={1}>
                  <DataDisplay
                    id="transactionType"
                    label={t('pages.FinancialTransaction.transactionType')}
                    data={transaction?.financialTransactionType ?? '-'}
                  />
                </Grid>
                <Grid item xs={3} mt={1}>
                  <DataDisplay
                    id="amount"
                    label={t('pages.FinancialTransaction.amount')}
                    dataFormat="currency"
                    data={
                      transaction?.amount
                        ? `$${transaction?.amount?.toFixed(2).toString()}`
                        : '-'
                    }
                  />
                </Grid>
                <Grid item xs={3} mt={1}>
                  <DataDisplay
                    id="effectiveDate"
                    label={t('pages.FinancialTransaction.effectiveDate')}
                    data={getDate(transaction?.effectiveDate ?? '')}
                  />
                </Grid>
                <Grid item xs={3} mt={1}>
                  <DataDisplay
                    id="revenuePostDate"
                    label={t('pages.FinancialTransaction.revenuePostDate')}
                    data={getDate(transaction?.revenuePostDate ?? '')}
                  />
                </Grid>
                <Grid item xs={3} mt={1}>
                  {
                    <DataDisplay
                      id="source"
                      label={t('pages.FinancialTransaction.source')}
                      data={transaction?.sourceFieldName ?? '-'}
                    />
                  }
                </Grid>
                <Grid item xs={3} mt={1}>
                  <DataDisplay
                    id="impactType"
                    label={t('pages.FinancialTransaction.impactType')}
                    data={
                      transaction?.financialTransactionDetails &&
                      transaction?.financialTransactionDetails[0].attributes
                        ?.impactType
                        ? transaction?.financialTransactionDetails[0].attributes
                            ?.impactType
                        : '-'
                    }
                  />
                </Grid>
                <Grid item xs={3} mt={1}>
                  <DataDisplay
                    id="dueDate"
                    label={t('pages.FinancialTransaction.dueDate')}
                    data={getDate(transaction?.effectiveDate ?? '')}
                  />
                </Grid>
              </Grid>
            </>
          }
        />
      </Grid>
      <Grid item xs={12} xl={12} mt={3}>
        <DataCard
          title={t('pages.FinancialTransaction.transactionDetails')}
          avatar={<AccountBalanceIcon sx={{ fill: 'primary.main' }} />}
          children={
            <>
              <Box sx={!transaction ? { display: 'none' } : {}}>
                <DefaultDataTableNext
                  columns={columns as ColumnDef<Record<string, unknown>>[]}
                  data={transaction?.financialTransactionDetails ?? []}
                  enableGlobalFilter={false}
                />
              </Box>
              {!transaction && t('pages.paymentDetails.noInfo')}
            </>
          }
        />
      </Grid>
    </Grid>
  );
}
export default FinancialTransactionDetails;
