import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { WorkflowAttachment } from 'types/WorkflowAttachment';
import useGetAccessToken from 'hooks/useGetAccessToken';

type UploadAttachmentHookProps = {
  onSuccess: (attachment: WorkflowAttachment) => void;
};

const endpoint = process.env.REACT_APP_WORKFLOW_MANAGEMENT;

export type UploadAttachmentCommand = {
  contentType: string;
  description: string;
  file: File;
  isConfidential?: boolean;
  attachmentType?: string;
  workflowId: number;
};

export default function useUploadAttachment({
  onSuccess,
}: UploadAttachmentHookProps) {
  const accessToken = useGetAccessToken();

  const uploadAttachment = async ({
    file,
    contentType,
    description,
    isConfidential,
    attachmentType,
    workflowId,
  }: UploadAttachmentCommand) => {
    const formData = new FormData();
    formData.append('ContentType', contentType);
    formData.append('Description', description);
    formData.append('FileObject', file);
    formData.append('IsConfidential', `${isConfidential ?? false}`);
    formData.append('AttachmentType', `${attachmentType ?? ''}`);
    formData.append('WorkflowId', workflowId.toString());
    const { data } = await axios.post(
      `${endpoint}/v1/workflow/attachment`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    return data;
  };

  const { mutate, isLoading } = useMutation(uploadAttachment, { onSuccess });

  return { mutate, isLoading };
}

type DownloadAttachmentProps = {
  workflowId: number;
  displayId: string;
};

export const useDownloadAttachment = () => {
  const accessToken = useGetAccessToken();

  const download = async ({
    workflowId,
    displayId,
  }: DownloadAttachmentProps) => {
    return axios({
      url: `${endpoint}/v1/workflow/attachment`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      responseType: 'blob',
      params: {
        workflowId: workflowId.toString(),
        displayId,
      },
    }).then((response) => {
      const url = window.URL.createObjectURL(response.data);
      return url;
    });
  };

  return { download };
};
