export const getShortText = (
  text: string,
  maxLength = 30,
  allowedWords = 8
) => {
  const complement = '...';
  const size = 1;
  if (text.length > maxLength) {
    const words = text.split(' ');
    if (words.length === size) {
      return words[0].slice(0, maxLength) + complement;
    } else {
      return words.slice(0, allowedWords).join(' ') + complement;
    }
  } else return text;
};
