import { Box, Divider, Grid, Typography, useTheme } from '@mui/material';
import ButtonLink from '@revenue-solutions-inc/revxcoreui/material/controls/ButtonLink';
import DataDisplay from '@revenue-solutions-inc/revxcoreui/material/controls/DataDisplay';
import { useTranslation } from 'react-i18next';
import { getDate } from 'common/helpers';
import { FieldValues, UseFormGetValues } from 'react-hook-form';
import { Attribute, Group } from 'generated/graphql';

interface EditPeriodPreviewProps {
  periodBeginDate: string;
  periodEndDate: string;
  periodAttributeGroup?: Group[] | null;
  currPeriodAttributeGroup?: Group[] | null;
  getValues: UseFormGetValues<FieldValues>;
  handleEditPeriod: () => void;
}

function EditPeriodPreview({
  periodBeginDate,
  periodEndDate,
  periodAttributeGroup,
  currPeriodAttributeGroup,
  getValues,
  handleEditPeriod,
}: EditPeriodPreviewProps) {
  const { t } = useTranslation();
  const theme = useTheme();

  const attrGroupNotEmpty =
    periodAttributeGroup && periodAttributeGroup.length > 0;

  const currAttrGroupNotEmpty =
    currPeriodAttributeGroup && currPeriodAttributeGroup.length > 0;

  const dividerStyle = {
    mt: '20px',
    mb: '15px',
    backgroundColor: theme.palette.grey2.light,
  };

  const labelStyle = {
    color: theme.palette.grey1.dark,
  };

  const editPeriodBtnStyle = {
    display: 'inline-flex',
    justifyContent: 'flex-start',
    width: '100%',
    mt: '20px',
  };

  const getAttributeValue = (attr: Attribute) => {
    switch (attr.attributeType) {
      case 'date':
        return attr.attributeValue !== '' ? getDate(attr.attributeValue) : '-';
      case 'integer':
        return attr.attributeValue !== '' ? attr.attributeValue : '-';
      default:
        return attr.attributeValue;
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h2" sx={{ textAlign: 'center' }}>
          {t('pages.periodDetails.edit.confirmationMessage')}
        </Typography>
        <Divider sx={dividerStyle} />
      </Grid>
      <Grid item xs={6} mb={1}>
        <Typography variant="h3" sx={labelStyle}>
          {t('pages.periodDetails.edit.previousPeriod')}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h3">
          {t('pages.periodDetails.edit.newPeriod')}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <DataDisplay
          id="periodBeginDate"
          label={t('pages.periodDetails.edit.beginDate')}
          sxLabel={labelStyle}
          sx={labelStyle}
          data={getDate(periodBeginDate) ?? '-'}
        />
      </Grid>
      <Grid item xs={2}>
        <DataDisplay
          id="periodEndDate"
          label={t('pages.periodDetails.edit.endDate')}
          sxLabel={labelStyle}
          sx={labelStyle}
          data={getDate(periodEndDate) ?? '-'}
        />
      </Grid>
      <Grid item xs={2}>
        {currAttrGroupNotEmpty &&
          currPeriodAttributeGroup[0].attribute.map((attr) => {
            return (
              <DataDisplay
                id={attr.attributeName}
                key={attr.attributeName}
                label={attr.attributeDisplayName}
                sxLabel={labelStyle}
                sx={{ color: theme.palette.grey1.dark, mb: 1 }}
                data={getAttributeValue(attr)}
              />
            );
          })}
      </Grid>
      <Grid item xs={2} mb={1}>
        <DataDisplay
          id="newBeginDate"
          label={t('pages.periodDetails.edit.beginDate')}
          sx={{ mb: 1 }}
          data={getDate(getValues('beginDate')) ?? '-'}
        />
      </Grid>
      <Grid item xs={2}>
        <DataDisplay
          id="newEndDate"
          label={t('pages.periodDetails.edit.endDate')}
          data={getDate(getValues('endDate')) ?? '-'}
        />
      </Grid>
      <Grid item xs={2}>
        <DataDisplay
          id="maintainPeriodReason"
          label={t('pages.periodDetails.edit.reason')}
          data={getValues('maintainPeriodReason') ?? '-'}
        />
      </Grid>
      <Grid item xs={6}></Grid>
      {attrGroupNotEmpty &&
        periodAttributeGroup[0].attribute.map((attr) => {
          return (
            <Grid key={attr.attributeName + '_container'} item xs={2}>
              <DataDisplay
                id={attr.attributeName}
                key={attr.attributeName}
                label={attr.attributeDisplayName}
                sx={{ mb: 1 }}
                data={getAttributeValue(attr)}
              />
            </Grid>
          );
        })}
      <Grid item xs={12} mt={1}>
        <Box sx={editPeriodBtnStyle}>
          <ButtonLink
            id="editPeriodBtn"
            text={t('pages.periodDetails.edit.editPeriod')}
            href="#"
            color="primary"
            handleChange={handleEditPeriod}
          />
        </Box>
      </Grid>
    </Grid>
  );
}
export default EditPeriodPreview;
