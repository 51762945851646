import { useState } from 'react';
import { Grid, Link } from '@mui/material';
import Dialog from '@revenue-solutions-inc/revxcoreui/material/controls/Dialog';
import {
  CmFiles,
  CmRunList,
  useArchiveChannelMutation,
  useGetChannelRunListByNameQuery,
} from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { useNavigate, useParams } from 'react-router-dom';
import HistoryIcon from '@mui/icons-material/History';
import DataCard from 'components/DataCard';
import Loading from 'components/Loading';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import { ColumnDef, Row } from '@tanstack/react-table';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import { MessageType } from '@revenue-solutions-inc/revxcoreui';
import ErrorMessageModal from './ErrorMessageModal';

const optionalStyles = {
  '.MuiDialogTitle-root': {
    color: 'lightBlack.main !important',
    padding: '10px 24px',
  },
  fontSize: '16px',
  fontWeight: 'normal',
};

export const dialogStyle = {
  '& .MuiSvgIcon-root': {
    display: 'none',
  },
  '.MuiDialogTitle-root': {
    fontWeight: 'bold',
  },
  '& .MuiDialogActions-root': {
    textAlign: 'center',
    display: 'inline-block',
  },
  '& .MuiDialogContent-root': {
    fontSize: '16px',
    fontWeight: 'normal',
  },
};

interface RunHistoryTableProps {
  isOpenDelete: boolean;
  setIsOpenDelete: React.Dispatch<React.SetStateAction<boolean>>;
  openErrorMessage: boolean;
  setOpenErrorMessage: React.Dispatch<React.SetStateAction<boolean>>;
  handleClose: () => void;
  currentModule: string;
}

const RunHistoryTable: React.FC<RunHistoryTableProps> = ({
  isOpenDelete,
  setIsOpenDelete,
  openErrorMessage,
  setOpenErrorMessage,
  currentModule,
}) => {
  const { t } = useTranslation();
  const [messages, setMessage] = useState<string[]>([]);
  const [fileExceptions, setFileExceptions] = useState<CmFiles[]>([]);

  const module = useAppSelector((state) => state.user.module);

  const { channelName } = useParams() as {
    channelName: string;
  };

  const { data: runListData, isLoading } = useGetChannelRunListByNameQuery({
    channelName,
    module: currentModule,
  });

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { mutate, isLoading: loadingDelete } = useArchiveChannelMutation();
  const children = <>{t('pages.manageChannel.warning')}</>;

  const columnsData: {
    id: string;
    localize: string;
    accessorKey: keyof CmRunList;
  }[] = [
    {
      id: 'Run-Id',
      localize: 'runId',
      accessorKey: 'RunId',
    },
    {
      id: 'Channel-Version',
      localize: 'channelVersion',
      accessorKey: 'ChannelVersion',
    },
    {
      id: 'Channel-Status',
      localize: 'channelStatus',
      accessorKey: 'ChannelStatus',
    },
    {
      id: 'start-time',
      localize: 'startTime',
      accessorKey: 'StartTime',
    },
    {
      id: 'end-time',
      localize: 'endTime',
      accessorKey: 'EndTime',
    },
    {
      id: 'Elapsed-Time',
      localize: 'elapsedTime',
      accessorKey: 'ElapsedTime',
    },
    {
      id: 'initiated-type',
      localize: 'initiatedType',
      accessorKey: 'RunInitiatedType',
    },
    {
      id: 'initial-by',
      localize: 'initiatedBy',
      accessorKey: 'RunInitiatedBy',
    },
    {
      id: 'Run-Status',
      localize: 'runStatus',
      accessorKey: 'RunStatus',
    },
    {
      id: 'Total-Records',
      localize: 'totalRecords',
      accessorKey: 'TotalRecords',
    },
    {
      id: 'Records-Success',
      localize: 'recordsSuccess',
      accessorKey: 'RecordsSuccess',
    },
    {
      id: 'Records-Failed',
      localize: 'recordsFailed',
      accessorKey: 'RecordsFailed',
    },
  ];

  const formatStartTimeCell = (row: Row<CmRunList>) => {
    return row.original.StartTime
      ? format(
          new Date(row.original.StartTime?.toString()),
          'MM/dd/yyyy hh:mm:ss a'
        )
      : '';
  };

  const formatEndTimeCell = (row: Row<CmRunList>) => {
    return row.original.EndTime
      ? format(
          new Date(row.original.EndTime?.toString()),
          'MM/dd/yyyy hh:mm:ss a'
        )
      : '';
  };

  const formatRunStatusCell = (
    row: Row<CmRunList>,
    setRunMessages: React.Dispatch<React.SetStateAction<string[]>>,
    setRunFileExceptions: React.Dispatch<React.SetStateAction<CmFiles[]>>,
    openRunErrorMessage: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    if (row.original.RunStatus === 'Failed') {
      return (
        <Link
          component="button"
          onClick={() => {
            setRunMessages(row.original.Messages as unknown as string[]);
            setRunFileExceptions(row.original.Files ? row.original.Files : []);
            openRunErrorMessage(true);
          }}
          sx={{ color: 'linkBlue.main' }}
        >
          {row.original.RunStatus}
        </Link>
      );
    } else {
      return row.original.RunStatus;
    }
  };

  const Column: ColumnDef<CmRunList>[] = columnsData.map((columnData) => {
    const column: ColumnDef<CmRunList> = {
      header: () => (
        <HeaderColumnNext
          localization={t(`pages.manageChannel.${columnData.localize}`)}
        />
      ),
      id: columnData.id,
      accessorKey: columnData.accessorKey,
    };

    if (columnData.id === 'start-time') {
      column.cell = ({ row }) => formatStartTimeCell(row);
    } else if (columnData.id === 'end-time') {
      column.cell = ({ row }) => formatEndTimeCell(row);
    } else if (columnData.id === 'Run-Status') {
      column.cell = ({ row }) =>
        formatRunStatusCell(
          row,
          setMessage,
          setFileExceptions,
          setOpenErrorMessage
        );
    }

    return column;
  });

  if (!isLoading && runListData && !loadingDelete) {
    return (
      <DataCard
        avatar={<HistoryIcon sx={{ fill: 'primary.main' }} />}
        title={t('pages.manageChannel.runHistory')}
        children={
          <>
            <Grid sx={{ marginBottom: '20px' }} data-testid="loading">
              <DefaultDataTableNext
                columns={Column}
                data={
                  runListData?.getChannelRunListByName as unknown as Record<
                    string,
                    unknown
                  >[]
                }
                enableGlobalFilter={false}
              />
              <Dialog
                id="delete-dialog"
                title={channelName}
                open={isOpenDelete}
                type="danger"
                optionalStyles={optionalStyles}
                optionalLabel={t('pages.manageChannel.confirmation')}
                sx={dialogStyle}
                dangerModalDangerButtonText={t('pages.manageChannel.archive')}
                children={children}
                width={450}
                handleCancelClick={() => {
                  setIsOpenDelete(false);
                }}
                handleDangerClick={() => {
                  setIsOpenDelete(false);
                  mutate(
                    {
                      channel: {
                        ChannelName: channelName,
                        Module: currentModule,
                      },
                    },
                    {
                      onSuccess: () => {
                        dispatch(
                          addMessage({
                            type: MessageType.Success,
                            message: t('pages.manageChannel.runDeleteIcon'),
                          })
                        );
                        navigate(`/${module}/managechannels`);
                      },
                      onError: () => {
                        dispatch(
                          addMessage({
                            type: MessageType.Error,
                            message: t('components.message.networkerror'),
                          })
                        );
                      },
                    }
                  );
                }}
              />
              <ErrorMessageModal
                openErrorMessage={openErrorMessage}
                handleClose={() => setOpenErrorMessage(false)}
                messages={messages}
                fileExceptions={fileExceptions}
              />
            </Grid>
          </>
        }
      />
    );
  }
  if (isLoading || loadingDelete) {
    return <Loading />;
  }
  return <></>;
};
export default RunHistoryTable;
