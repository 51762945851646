import { useEffect, useState } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { setHeader } from 'redux/contentSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import { Link } from 'react-router-dom';
import {
  FormDataObjectViewForms,
  useGetViewFormsQuery,
} from 'generated/graphql';
import DateCell from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/TableCells/DateCell';
import Loading from 'components/Loading';
import {
  MessageActionType,
  MessageType,
  Radio,
} from '@revenue-solutions-inc/revxcoreui';
import { addMessage } from 'redux/messageSlice';
import extractMeaningfulMessage from 'utils/errorMessage';

function ViewForms() {
  const dispatch = useAppDispatch();
  const module = useAppSelector((state) => state.user.module);
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.forms.viewForms',
  });
  const { t: t2 } = useTranslation();
  const [formCategory, setFormCategory] = useState<string>('Taxpayer');
  const { data, isFetching } = useGetViewFormsQuery(
    {
      formCategory: formCategory,
    },
    {
      staleTime: 1000 * 60,
      onError(error) {
        dispatch(
          addMessage({
            message: extractMeaningfulMessage(
              error,
              t2('components.message.networkerror')
            ),
            type: MessageType.Error,
            actionType: MessageActionType.None,
          })
        );
      },
    }
  );

  useEffect(() => {
    dispatch(setHeader({ pageTitle: t('title') }));
  }, [dispatch, t]);

  const columnDefinition: ColumnDef<FormDataObjectViewForms>[] = [
    {
      id: 'dln',
      accessorFn: (row) => row.Forms?.[0].DLN,
      header: () => <HeaderColumnNext localization={t('headerDln')} />,
      cell({ getValue, row }) {
        return (
          <Link to={{ pathname: `/${module}/formView/${row.original?.Id}` }}>
            {getValue() as string}
          </Link>
        );
      },
    },
    {
      id: 'formName',
      accessorFn: (row) => row.Forms?.[0].FormName,
      header: () => <HeaderColumnNext localization={t('headerName')} />,
    },
    {
      id: 'creationDate',
      accessorFn: (row) => row.Forms?.[0].CreatedDate,
      header: () => <HeaderColumnNext localization={t('headerDate')} />,
      cell: ({ getValue }) => <DateCell dateString={String(getValue())} />,
    },
  ];

  const forms: FormDataObjectViewForms[] = data?.getViewForms || [];

  const handleRadioChange = (_event: React.ChangeEvent<HTMLInputElement>) => {
    setFormCategory(_event.target.value);
  };

  return isFetching ? (
    <Loading />
  ) : (
    <DefaultDataTableNext
      data={forms}
      columns={columnDefinition}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'end',
      }}
      customHeader={({ table }) => (
        <div
          style={{
            display: 'flex',
          }}
        >
          <Radio
            value="Taxpayer"
            color="primary"
            id="correspondenceEnabled"
            checked={formCategory === 'Taxpayer'}
            onChange={(e) => {
              handleRadioChange(e);
              table.setPageIndex(0);
            }}
            label="Taxpayer"
          />
          <Radio
            value="Process"
            color="primary"
            id="correspondenceEnabled2"
            checked={formCategory === 'Process'}
            onChange={(e) => {
              handleRadioChange(e);
              table.setPageIndex(0);
            }}
            label="Process"
          />
        </div>
      )}
    />
  );
}

export default ViewForms;
