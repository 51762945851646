import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { DataDisplay } from '@revenue-solutions-inc/revxcoreui';
import {
  AttributeTypes,
  BusinessDrivers,
} from 'common/platformConfigUtils/platformConfigUtils';
import { Attribute } from 'generated/graphql';
import { useTranslation } from 'react-i18next';

interface Props {
  attribute: Attribute;
}

function AttributePreview({ attribute }: Props) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.configTypeEditor',
  });
  return (
    <>
      <DataDisplay
        label={t('attributeName')}
        data={attribute.attributeName}
        id="fieldName"
      />
      <DataDisplay
        label={t('attributeDisplayName')}
        data={attribute.attributeDisplayName}
        id="displayName"
      />
      <DataDisplay
        label={t('attributeDesc')}
        data={attribute.attributeDescription ?? ''}
        id="description"
      />
      <DataDisplay
        label={t('attributeType')}
        data={attribute.attributeType ?? ''}
        id="attributeType"
      />
      {
        //only show datasource if its the right attribute type
        (attribute.attributeType?.toLowerCase() ===
          AttributeTypes.referenceData.toLowerCase() ||
          attribute.attributeType?.toLowerCase() ===
            AttributeTypes.schema.toLowerCase()) && (
          <DataDisplay
            label={t('attributeDatasource')}
            data={attribute.dataSource ?? ''}
            id="datasource"
          />
        )
      }
      <hr></hr>
      {attribute.extensibleBusinessDriver &&
        attribute.extensibleBusinessDriver.length > 0 && (
          <Typography variant="h2" mb={'1em'}>
            {t('extensibleBusinessDrivers')}
          </Typography>
        )}

      {attribute.extensibleBusinessDriver?.map((constraint) => {
        return (
          <Stack direction={'row'} spacing={2}>
            <Typography>
              {BusinessDrivers[constraint.driverNameType ?? 0]}
            </Typography>
            <Typography>{constraint.driverValue ?? ''}</Typography>
          </Stack>
        );
      })}
    </>
  );
}

export default AttributePreview;
