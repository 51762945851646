import { SyntheticEvent, useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import {
  DataDisplay,
  MessageActionType,
  MessageType,
  SelectAutoComplete,
} from '@revenue-solutions-inc/revxcoreui';
import Dialog from '@revenue-solutions-inc/revxcoreui/material/controls/Dialog';

import {
  useGetAccountPeriodsAndBalanceQuery,
  useGetPaymentTransferTypesQuery,
  useSearchByIndexQuery,
  useTransferPaymentMutation,
} from 'generated/graphql';

import { useForm, useWatch } from 'react-hook-form';
import { SelectType } from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import HorizontalNonLinearStepper from 'components/stepper/HorizontalNonLinearStepper';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import DateCell from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/TableCells/DateCell';
import { ColumnDef } from '@tanstack/table-core';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import { AvailablePeriod } from 'components/entityManagement/accounts/maintain/AccountPeriods/AccountPeriods';
import ControlledAmountField from 'components/controls/ControlledAmountField';

import { CallMade } from '@mui/icons-material';
import ControlledSelectField from 'components/controls/ControlledSelectField';
import CurrencyCell from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/TableCells/CurrencyCell';
import { useAppDispatch } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import ControlledTextArea from 'components/controls/ControlledTextArea';
import extractMeaningfulMessage from 'utils/errorMessage';
import { useQueryClient } from '@tanstack/react-query';
import { formatCurrency } from 'common/helpers';
import { getFormatDate } from 'utils/date-util';
import { parseISO } from 'date-fns';

interface TransferPaymentProps {
  paymentId: string;
  paymentAmount: string;
  paymentPeriodId: string;
}

function TransferPayment({
  paymentId,
  paymentAmount,
  paymentPeriodId,
}: TransferPaymentProps) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { control, formState, trigger, getValues, reset } = useForm({
    mode: 'all',
  });
  const emptyOption = [
    {
      key: '0',
      desc: '',
    },
  ];
  const [currentStep, setCurrentStep] = useState<number>(0);
  const queryClient = useQueryClient();
  const [selectedAccount, setSelectedAccount] = useState<
    SelectType | undefined
  >();
  const [selectedPeriod, setSelectedPeriod] = useState<AvailablePeriod>();
  const [periodError, setPeriodError] = useState<boolean>(false);

  const [options, setOptions] = useState<SelectType[]>(emptyOption);
  const [inputValue, setInputValue] = useState('');
  const [accountFormDirty, setAccountFormDirty] = useState(false);
  const [validated, setValidated] = useState<{ [k: number]: boolean }>({
    3: true,
  });
  const [showNote, setShowNote] = useState(false);
  const maxResults = 5;
  const noteRequired = useWatch({ control, name: 'transferReason' });

  const { refetch } = useSearchByIndexQuery(
    {
      maxResults,
      indexName: `${process.env.REACT_APP_ACCOUNT_INDEX}`,
      searchPhrase: inputValue,
    },
    {
      enabled: true,
      onSuccess: (accounts) => {
        if (inputValue === '' || !accounts.SearchByIndex.accountData.length) {
          setOptions(emptyOption);
        } else {
          const dataMappedToLookups = accounts.SearchByIndex.accountData.map(
            (account) => {
              return {
                key: account.accountInfoId as string,
                desc: account.displayString as string,
              };
            }
          );
          setOptions(dataMappedToLookups);
        }
      },
    }
  );

  const { data: transferTypes } = useGetPaymentTransferTypesQuery(
    {},
    {
      select: (transferReasons) => {
        return transferReasons?.GetPaymentTransferTypes?.find((transfers) => {
          return transfers.name?.toLocaleLowerCase() === 'transfer';
        })?.reasons?.map((item) => {
          return {
            key: item.name,
            desc: item.description,
            isNoteRequired: item.isNoteRequired,
          };
        });
      },
      onError: () => {
        dispatch(
          addMessage({
            message: t('pages.paymentDetails.transferTypesError'),
            type: MessageType.Error,
            actionType: MessageActionType.None,
          })
        );
      },
    }
  );
  useEffect(() => {
    const isNoteRequired = transferTypes?.find((noteField) => {
      return noteField.desc === noteRequired;
    })?.isNoteRequired;
    setShowNote(!!isNoteRequired);
  }, [noteRequired, transferTypes]);

  function buildRequiredError(fieldName: string) {
    return (
      fieldName + ' ' + `${t('pages.periodDetails.transactions.required')}`
    );
  }
  const {
    data: availablePeriods,
    isLoading: availablePeriodsLoading,
    error: availablePeriodsError,
  } = useGetAccountPeriodsAndBalanceQuery(
    {
      accountId:
        selectedAccount && selectedAccount.key ? selectedAccount.key : '',
    },
    {
      enabled: !!selectedAccount && !!selectedAccount.key,
      select: (periods) => {
        return periods.GetAccountPeriodsAndBalance.accountPeriods;
      },
    }
  );
  const closeModal = () => {
    setSelectedPeriod(undefined);
    setSelectedAccount(undefined);
    setPeriodError(false);
    setInputValue('');
    reset();
    setAccountFormDirty(false);
    setOpen(false);
  };

  const openModal = () => {
    setOpen(true);
  };

  const { mutate: transferPayment, isLoading: isMutationLoading } =
    useTransferPaymentMutation({
      onSuccess: () => {
        void queryClient.invalidateQueries({
          queryKey: ['GetPaymentDetailsById'],
        });
        void queryClient.invalidateQueries({
          queryKey: ['GetAccountPeriodsAndBalance'],
        });
        void queryClient.invalidateQueries({
          queryKey: ['GetPaymentTransactionsById'],
        });
        closeModal();
        dispatch(
          addMessage({
            message: t('pages.paymentDetails.transferSuccess'),
            type: MessageType.Success,
            actionType: MessageActionType.None,
          })
        );
      },
      onError: (error) => {
        const message = extractMeaningfulMessage(
          error,
          t('pages.paymentDetails.transferError')
        );
        dispatch(
          addMessage({
            type: MessageType.Error,
            message: message,
          })
        );
      },
    });

  useEffect(() => {
    refetch();
    reset();
  }, [inputValue, refetch, reset]);

  const transferPaymentBtn = () => {
    return (
      <IconButton aria-label="transfer payment" onClick={openModal}>
        <CallMade />
      </IconButton>
    );
  };

  const sections = ['Account', 'Period', 'Amount', 'Confirmation'];
  const addPeriodColumns: ColumnDef<AvailablePeriod>[] = [
    {
      id: 'id',
      cell: ({ row }) => {
        return (
          <Checkbox
            id={'periodChk-' + row.id}
            inputProps={{
              'aria-label': t('pages.paymentDetails.selectPeriod'),
            }}
            checked={selectedPeriod?.id === row.original.id}
            onChange={() => {
              setSelectedPeriod(row.original);
            }}
            sx={{ ml: 1 }}
          />
        );
      },
    },
    {
      id: 'beginDate',
      accessorKey: 'beginDate',
      header: () => (
        <HeaderColumnNext localization={t('pages.accountSummary.startDate')} />
      ),
      cell: ({ getValue }) => <DateCell dateString={getValue() as string} />,
    },
    {
      id: 'endDate',
      accessorKey: 'endDate',
      header: () => (
        <HeaderColumnNext localization={t('pages.accountSummary.endDate')} />
      ),
      cell: ({ getValue }) => <DateCell dateString={getValue() as string} />,
    },
    {
      id: 'balance',
      accessorKey: 'balance',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.paymentDetails.application.balance')}
        />
      ),
      cell: ({ getValue }) => (
        <Box sx={{ width: '3.5em' }}>
          <CurrencyCell
            invalidValue={t('pages.tableCell.invalidValue')}
            amountString={getValue() as string}
          />
        </Box>
      ),
    },
  ];
  /**
   * Sets the validation result for the current step
   * @param isValid If step is valid or not
   */
  const setStepValidationResult = (isValid: boolean) => {
    const newValidated = validated;
    newValidated[currentStep] = isValid;
    setValidated(newValidated);
  };

  async function validateSteps() {
    if (currentStep === 0) {
      setAccountFormDirty(true);
      setSelectedPeriod(undefined);
      setPeriodError(false);
      setStepValidationResult(!!selectedAccount);
    } else if (currentStep === 1) {
      setPeriodError(!selectedPeriod);
      setStepValidationResult(!!selectedPeriod);
    } else if (currentStep === 2) {
      await trigger();
      setStepValidationResult(Object.keys(formState.errors).length === 0);
    } else {
      return;
    }
  }

  function submitTransfer() {
    if (Object.keys(formState.errors).length === 0) {
      const transferArgs = {
        transferPaymentId: paymentId,
        paymentTransfer: {
          transferReason: getValues('transferReason'),
          transferType: 'Transfer',
          sourcePeriodId: Number.parseInt(paymentPeriodId),
          targetPeriodId: selectedPeriod?.id,
          amount: Number.parseFloat(getValues('transactionAmount')),
          note: showNote ? getValues('transfer-note') : null,
        },
      };
      transferPayment(transferArgs);
    }
  }
  const transferPaymentContent = () => {
    return (
      <HorizontalNonLinearStepper
        steps={sections}
        saveSuccessful={false}
        currentStep={currentStep}
        handleCurrentStep={(activeStep: number) => {
          setCurrentStep(activeStep);
        }}
        handleSave={() => {
          submitTransfer();
        }}
        saveBtnLabel={t('pages.paymentDetails.transferPayment')}
        nonLinear={false}
        validatedSteps={validated}
        handleStepValidation={validateSteps}
        loading={isMutationLoading}
      >
        {currentStep === 0 && (
          <Grid item xs={8}>
            <SelectAutoComplete
              options={options}
              required={true}
              onChange={(e: SyntheticEvent, account) => {
                setAccountFormDirty(true);
                const foundAccount = options.find((acc) => {
                  return acc.desc === account;
                });
                setSelectedAccount(foundAccount);
              }}
              error={
                !selectedAccount && accountFormDirty
                  ? t('pages.paymentDetails.accountRequired')
                  : ''
              }
              value={inputValue}
              label={t('pages.paymentDetails.accountLookup')}
              id="account-lookup-autocomplete"
              onInputChange={(e: SyntheticEvent, newValue: string) => {
                setInputValue(newValue);
              }}
            />
          </Grid>
        )}
        {currentStep === 1 && (
          <Grid container>
            {availablePeriods && availablePeriods.length > 0 && (
              <>
                <DefaultDataTableNext
                  columns={
                    addPeriodColumns as ColumnDef<
                      Record<string, unknown>,
                      unknown
                    >[]
                  }
                  data={
                    availablePeriods as unknown as Record<string, unknown>[]
                  }
                  enableGlobalFilter
                  isSimplePagination
                  sx={{ justifyContent: 'flex-start' }}
                />
                {periodError && (
                  <Typography sx={{ color: theme.palette.error.main }}>
                    {t('pages.paymentDetails.periodRequired')}
                  </Typography>
                )}
              </>
            )}
            {availablePeriodsLoading && (
              <Typography variant="inherit">
                <CircularProgress sx={{ margin: '20px' }} />
              </Typography>
            )}
            {availablePeriods &&
              availablePeriods.length === 0 &&
              !availablePeriodsLoading && (
                <Typography variant="inherit">
                  {availablePeriodsError
                    ? t('pages.accountSummary.availablePeriodError')
                    : t('pages.accountSummary.noAvailablePeriods')}
                </Typography>
              )}
          </Grid>
        )}
        {currentStep === 2 && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ControlledAmountField
                control={control}
                allowNegative={false}
                label={t('pages.periodDetails.transactions.amount')}
                rules={{
                  required: buildRequiredError(
                    `${t('pages.periodDetails.transactions.amount')}`
                  ),
                  pattern: /^-?(\d*\.{0,1}\d{0,2}$)/,
                  max: {
                    value: Math.abs(parseFloat(paymentAmount)),
                    message: t('pages.paymentDetails.amountError'),
                  },
                }}
                name="transactionAmount"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <ControlledSelectField
                required
                control={control}
                rules={{
                  required: 'Transfer Reason Required',
                }}
                id="period-transfer-reason"
                options={transferTypes as SelectType[]}
                name={`transferReason`}
                label={'Reason'}
              />
            </Grid>

            {showNote && (
              <Grid item xs={12}>
                <ControlledTextArea
                  control={control}
                  id={'transfer_note'}
                  name="transfer-note"
                  multiline={true}
                  helperText="Note Required"
                  label="Transfer Note"
                  required
                  rules={{ required: true }}
                  sx={{ width: '100%' }}
                />
              </Grid>
            )}
          </Grid>
        )}
        {currentStep === 3 && (
          <Grid spacing={2}>
            <Typography align="center" sx={{ fontWeight: 'bold' }}>
              {t('pages.paymentDetails.confirmTransfer')}
            </Typography>
            <DataDisplay
              label={'Destination Account'}
              id="account-allocated"
              data={selectedAccount?.desc as string}
            />
            <DataDisplay
              label={'Destination Period'}
              id="transfer-destination-period"
              data={`${
                selectedPeriod?.beginDate
                  ? getFormatDate(new Date(parseISO(selectedPeriod?.beginDate)))
                  : t('pages.tableCell.invalidDate')
              } to ${
                selectedPeriod?.endDate
                  ? getFormatDate(new Date(parseISO(selectedPeriod?.endDate)))
                  : t('pages.tableCell.invalidDate')
              }`}
            />
            <DataDisplay
              label={'Transfer Amount'}
              id="transfer-account-amount"
              data={formatCurrency(getValues('transactionAmount'))}
            />
            <DataDisplay
              label={'Transfer Reason'}
              id="transfer-reason"
              data={getValues('transferReason')}
            />
          </Grid>
        )}
      </HorizontalNonLinearStepper>
    );
  };

  return (
    <>
      {transferPaymentBtn()}
      <Dialog
        id="transferPaymentDialog"
        open={open}
        children={transferPaymentContent()}
        title={t('pages.paymentDetails.transferPayment')}
        maxWidth="md"
        transactionModalTransactionButtonText={t(
          'pages.FinancialTransaction.reverseTransactionConfirmButton'
        )}
        handleClose={closeModal}
        handleCancelClick={closeModal}
      />
    </>
  );
}
export default TransferPayment;
