import { useState, useEffect } from 'react';

import { SelectChangeEvent } from '@mui/material/Select';
import Select, {
  SelectType,
} from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import { MessageType } from '@revenue-solutions-inc/revxcoreui/material/messaging/Message/Message';
import Loading from 'components/Loading';
import { Attribute, useGetEntityByGroupQuery } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import { AccountAddress } from 'types/accounts';

interface Props {
  id?: string;
  entityId: string;
  label: string;
  required?: boolean;
  errorMsg?: string;
  value?: string | number;
  onChange: React.Dispatch<SelectChangeEvent<string | number>>;
  onChangeEntityData?: (data: AccountAddress, value: string) => void;
}

function AddressSelect({
  id = 'entity-address-list',
  entityId,
  label,
  required,
  errorMsg,
  value,
  onChange,
  onChangeEntityData,
}: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [addresses, setAddresses] = useState<SelectType[]>([]);
  const [addressesMap, setAddressesMap] = useState<AccountAddress[]>([]);

  const {
    data: groupData,
    isLoading,
    isFetched,
    error,
  } = useGetEntityByGroupQuery({
    entityId: entityId,
    groupName: 'addresses',
  });

  const setAddressDescription = (attributes: Attribute[]): string => {
    const addressLine1 =
      attributes.find((attr) => attr.attributeName === 'addressLine1')
        ?.attributeValue ?? '';
    const postalCode =
      attributes.find((attr) => attr.attributeName === 'postalCode')
        ?.attributeValue ?? '';
    const city =
      attributes.find((attr) => attr.attributeName === 'cityId')
        ?.attributeValue ?? '';
    const state =
      attributes.find((attr) => attr.attributeName === 'stateProvinceId')
        ?.attributeValue ?? '';
    const country =
      attributes.find((attr) => attr.attributeName === 'countryId')
        ?.attributeValue ?? '';
    return [addressLine1, postalCode, city, state, country].join(', ');
  };

  const getAddressesAttributes = (attributes: Attribute[]): AccountAddress => {
    let address: AccountAddress = { isPrimary: '' };
    attributes.forEach((attr) => {
      address = { ...address, [attr.attributeName]: attr.attributeValue };
    });
    return address;
  };

  useEffect(() => {
    if (groupData && !error) {
      const options: SelectType[] = [];
      const addressesToMap: AccountAddress[] = [];
      groupData.GetEntityByGroup?.group?.forEach((address) => {
        const addressType = address.attribute?.find(
          (attr) => attr.attributeName === 'AddressType'
        );

        addressesToMap.push(getAddressesAttributes(address.attribute ?? []));
        const addressValue = setAddressDescription(address.attribute ?? []);
        if (addressType && addressType.attributeValue) {
          const optionAddress: SelectType = {
            key: addressType.attributeValue,
            desc: addressType.attributeValue + ': ' + addressValue,
          };
          options.push(optionAddress);
        }
      });
      setAddressesMap((prevState) => [...prevState, ...addressesToMap]);
      setAddresses(options);
    }
  }, [error, groupData, setAddresses, setAddressesMap]);

  useEffect(() => {
    if (!isLoading && isFetched && (error || !groupData)) {
      dispatch(
        addMessage({
          type: MessageType.Error,
          message: t('components.message.addressError'),
        })
      );
    }
  }, [error, groupData, isLoading, isFetched, dispatch, t]);

  if (isLoading) return <Loading />;

  return groupData ? (
    <Select
      id={id}
      label={label}
      options={addresses}
      value={value ?? ''}
      onChange={(ev) => {
        onChange(ev);
        if (onChangeEntityData !== undefined) {
          const addressSelected = addressesMap.filter(
            (attr) =>
              attr.AddressType?.toLocaleLowerCase() ===
              ev.target.value.toString().toLocaleLowerCase()
          )[0];
          onChangeEntityData?.(addressSelected, ev.target.value.toString());
        }
      }}
      required={required}
      error={errorMsg}
      sx={{
        width: '100%',
      }}
    />
  ) : (
    <></>
  );
}

export default AddressSelect;
