import { useEffect, useState, useRef } from 'react';
import { Box, Grid } from '@mui/material';
import {
  Input,
  MessageType,
  Button,
  MessageActionType,
} from '@revenue-solutions-inc/revxcoreui';
import AddUserSummary from 'components/AddUserSummary';
import { useCreateUserMutation } from 'generated/graphql';
import useMultiMaskInput, { MaskReturn } from 'hooks/useMultiMaskInput';
import { useTranslation } from 'react-i18next';
import { setHeader } from 'redux/contentSlice';
import { useAppDispatch } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import { getErrorMsgAddUser } from 'utils/getErrorMsgAddUser';

function AddUser(): JSX.Element {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.addUser',
  });
  const { t: p } = useTranslation();
  const dispatch = useAppDispatch();
  const inputFocus = useRef<HTMLInputElement>(null);
  const [userEmail, setUserEmail] = useState<(string | null)[]>(['']);
  const [firstName, setFirstName] = useState<string | null>('');
  const [lastName, setLastName] = useState<string | null>('');
  const [email, setEmail] = useState<string | null>('');
  const [summary, setSummary] = useState(false);
  const [valid, setValid] = useState(false);
  const [userValues, setUserValues] = useState({
    firstName: '',
    lastName: '',
    userEmail: '',
  });

  const emailExp =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  const emailMask = useMultiMaskInput(
    '',
    t('errors.emailRequired'),
    emailExp,
    userEmail
  );

  const {
    isSuccess,
    isError,
    data: dataResponse,
    error,
    mutate: createUser,
  } = useCreateUserMutation();

  useEffect(() => {
    const timeout = setTimeout(() => {
      inputFocus?.current?.focus();
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const addUserIntoPlatform = () => {
    if (valid) createUser({ user: userValues });
  };

  const handleHelperTextChange = (mask: MaskReturn, index: number) => {
    return mask.maskErrors[index];
  };

  const fullNameLength =
    firstName?.length != undefined && lastName?.length != undefined
      ? firstName?.length + lastName?.length
      : 0;
  const firstNameLength =
    firstName?.length != undefined ? firstName?.length : 0;
  const lastNameLength = lastName?.length != undefined ? lastName?.length : 0;
  const emailLength = email?.length != undefined ? email?.length : 0;

  const handleHelperTextFirstNameChange = () => {
    return firstNameLength > 150 ? t('errors.firstNameLength') : '';
  };
  const handleHelperTextLastNameChange = () => {
    return lastNameLength > 150 ? t('errors.lastNameLength') : '';
  };
  const handleHelperTextEmailChange = () => {
    return emailLength > 255
      ? t('errors.emailLength')
      : handleHelperTextChange(emailMask, 0);
  };

  useEffect(() => {
    if (isSuccess) {
      setSummary(true);
      dispatch(
        addMessage({
          message: t('userAdded'),
          type: MessageType.Success,
          actionType: MessageActionType.None,
        })
      );
      setFirstName('');
      setLastName('');
      setEmail('');
    }
  }, [isSuccess, setSummary, dispatch, t]);

  useEffect(() => {
    if (
      emailMask.hasErrors ||
      firstName?.length === 0 ||
      lastName?.length === 0 ||
      email?.length === 0 ||
      fullNameLength > 150 ||
      emailLength > 255
    )
      setValid(false);
    else {
      setValid(true);
    }
  }, [
    setValid,
    emailMask,
    userValues,
    fullNameLength,
    emailLength,
    firstName,
    lastName,
    email,
  ]);

  useEffect(() => {
    if (isError) {
      if (getErrorMsgAddUser(error) == 'Existing user') {
        dispatch(
          addMessage({
            type: MessageType.Error,
            message: t('errors.existingUser'),
          })
        );
      } else {
        dispatch(
          addMessage({
            type: MessageType.Error,
            message: p('components.message.networkerror'),
          })
        );
      }
    }
  }, [isError, dispatch, p, error, t]);

  useEffect(() => {
    dispatch(setHeader({ pageTitle: t('title') }));
  }, [dispatch, t]);

  return (
    <>
      {summary ? (
        <AddUserSummary
          userValues={[userValues]}
          setSummary={setSummary}
          dataResponse={dataResponse?.CreateUser}
        />
      ) : (
        <>
          <Grid
            container
            spacing={2}
            direction="column"
            sx={{
              maxWidth: '400px',
            }}
          >
            <Grid
              item
              xs={4}
              sx={{
                maxWidth: '400px',
              }}
            >
              <Input
                id="firstName"
                label={t('firstName')}
                value={firstName as string}
                required
                helperText={handleHelperTextFirstNameChange()}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFirstName(event.target.value);
                  setUserValues({
                    ...userValues,
                    firstName: event.target.value,
                  });
                }}
                inputProps={{
                  'data-testid': 'addUser-input',
                  ref: inputFocus,
                }}
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid
              item
              xs={4}
              sx={{
                maxWidth: '400px',
              }}
            >
              <Input
                id="lastName"
                label={t('lastName')}
                value={lastName as string}
                helperText={handleHelperTextLastNameChange()}
                required
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setLastName(event.target.value);
                  setUserValues({
                    ...userValues,
                    lastName: event.target.value,
                  });
                }}
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid
              item
              xs={4}
              sx={{
                maxWidth: '400px',
              }}
            >
              <Input
                id="emailAddress"
                label={t('emailAddress')}
                value={email as string}
                required
                helperText={handleHelperTextEmailChange()}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  emailMask.handleMaskChange(event, 0);
                  setUserEmail([event.target.value]);
                  setEmail(event.target.value);
                  setUserValues({
                    ...userValues,
                    userEmail: event.target.value,
                  });
                }}
                sx={{ width: '100%' }}
              />
            </Grid>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '1rem',
              }}
            >
              <Button
                id="addUser-button"
                onClick={addUserIntoPlatform}
                disabled={!valid}
              >
                {t('addUserButton')}
              </Button>
            </Box>
          </Grid>
        </>
      )}
    </>
  );
}

export default AddUser;
