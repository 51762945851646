/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo, useState } from 'react';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import { MessageType } from '@revenue-solutions-inc/revxcoreui';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import Input from '@revenue-solutions-inc/revxcoreui/material/controls/Input';
import Select from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import { ColumnDef, Row } from '@tanstack/react-table';
import { formatCurrency } from 'common/helpers';
import { ConfigurationDomains } from 'common/platformConfigUtils/platformConfigUtils';
import DatasourceSelect from 'components/DatasourceSelect';
import { getIdFormats } from 'components/entityManagement/common/formatValidations';
import Loading from 'components/Loading';
import { useGetLookupConfigurationQuery } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import { getFormatDate, toStringDate } from 'utils/date-util';
import { formatIdentifier } from 'utils/formatIdentifier';

import { ColumnFields, SearchName } from '../../SearchConfig';
import { CollapseProp } from '../AdvancedSearch';

type KeyValue = {
  [key: string]: string | null;
};

interface SearchOptionsProps {
  search: SearchName;
  setResults: React.Dispatch<React.SetStateAction<JSX.Element | JSX.Element[]>>;
  collapse: CollapseProp;
  setCollapse: React.Dispatch<React.SetStateAction<CollapseProp[]>>;
}

interface CollapseParamsProps {
  show: boolean;
}

function SearchMutationOptions({
  search,
  setResults,
  collapse,
  setCollapse,
}: SearchOptionsProps) {
  const MAIN_CONFIG_DOMAIN = ConfigurationDomains.ReferenceSchema;
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [searchOp, setSearchOp] = useState(search.state);
  const module = useAppSelector((state) => state.user.module);

  const { mutate: mutateSearch, isLoading } = search.mutationQuery({});
  const { data: configuredIdTypes } = useGetLookupConfigurationQuery({
    configurationDomain: MAIN_CONFIG_DOMAIN,
    configurationType: 'IdType',
  });

  /**
   * This methods builds the link/route a cell will display, i.e. a link to an
   * entity or an account. For an account, it needs two parameters
   * @param path Where the route and parameters are contained
   * @param row The value to attach the route to be defined
   * @returns The complete route/link to be displayed in the cell
   */
  const buildRoute = (path: ColumnFields, row: Row<KeyValue>): string => {
    if (path.secondRoute && path.secondRouteParam) {
      const firstRoute = path.route ?? '';
      const firstRouteParam =
        row.original[path.routeParam ? path.routeParam : 'id'] ?? '';
      const secondRouteParam =
        row.original[path.secondRouteParam ? path.secondRouteParam : 'id'] ??
        '';
      return (
        `/${module}` +
        firstRoute
          .concat('/')
          .concat(firstRouteParam)
          .concat(path.secondRoute)
          .concat('/')
          .concat(secondRouteParam)
      );
    } else {
      const route = path.route ?? '';
      const routeParam =
        row.original[path.routeParam ? path.routeParam : 'id'] ?? '';
      return `/${module}` + route.concat('/').concat(routeParam);
    }
  };

  const columns: ColumnDef<KeyValue>[] = useMemo(() => {
    return search.columns.map((col) => {
      return {
        id: col.accessorKey,
        accessorKey: col.accessorKey,
        header: () => <HeaderColumnNext localization={col.columnName} />,
        cell: ({ row }) => {
          if (col.clickable) {
            return (
              <Link
                to={{
                  pathname: buildRoute(col, row),
                }}
                style={{ color: theme.palette.linkBlue.dark }}
              >
                {row.original[col.accessorKey] ?? col.columnValue}
              </Link>
            );
          } else if (col.isDate) {
            return row.original[col.accessorKey]
              ? toStringDate(
                  getFormatDate(
                    new Date(row.original[col.accessorKey] as string)
                  )
                )
              : col.columnValue;
          } else if (col.accessorKey === 'balance') {
            return row.original[col.accessorKey]
              ? formatCurrency(row.original[col.accessorKey] as string)
              : '0';
          } else return row.original[col.accessorKey] ?? col.columnValue;
        },
      };
    });
  }, [search.columns]);

  function getData(data: any) {
    const isArray = Array.isArray(data[search.dataQuery]);
    if (!isArray) return data[search.dataQuery];
    const tempList: any[] = [];
    const arr = data[search.dataQuery] as [];
    // find and format identifiers
    arr.forEach((row: any) => {
      const entityId = row.primaryEntityIdentifier;
      const entityIdType = row.primaryEntityIdentifierType;
      const formattedEntityId =
        entityId && entityIdType && Number(entityId)
          ? formatIdentifier(
              entityId,
              getIdFormats(configuredIdTypes),
              entityIdType
            )
          : entityId;
      let formattedRow = {
        ...row,
        primaryEntityIdentifier: formattedEntityId,
      };
      if (
        'AdvancedSearchAccount'.toUpperCase() === search.dataQuery.toUpperCase()
      ) {
        const accountId = row.primaryAccountIdentifier;
        const accountIdType = row.primaryAccountIdentifierType;
        const formattedAccountId =
          accountId && accountIdType && Number(accountId)
            ? formatIdentifier(
                accountId,
                getIdFormats(configuredIdTypes),
                accountIdType
              )
            : accountId;
        formattedRow = {
          ...formattedRow,
          primaryAccountIdentifier: formattedAccountId,
        };
      }
      if (
        'AdvancedSearchAsset'.toUpperCase() === search.dataQuery.toUpperCase()
      ) {
        const assetId = row.primaryAssetIdentifier;
        const assetIdType = row.primaryAssetIdentifierType;
        const formattedAssettId =
          assetId && assetIdType && Number(assetId)
            ? formatIdentifier(
                assetId,
                getIdFormats(configuredIdTypes),
                assetIdType
              )
            : assetId;
        formattedRow = {
          ...formattedRow,
          primaryAssetIdentifier: formattedAssettId,
        };
      }

      tempList.push(formattedRow);
    });
    return tempList;
  }

  const fetchResults = () => {
    if (
      search.searchName === 'Accounts' &&
      searchOp.accountIdentifier === '' &&
      searchOp.accountName === ''
    ) {
      dispatch(
        addMessage({
          type: MessageType.Error,
          message: t('pages.search.accountFieldsRequired'),
        })
      );
      return;
    }
    return mutateSearch(
      {
        [search.searchParam]: searchOp,
      },
      {
        onSuccess: async (mutationData: any) => {
          setResults(
            <DefaultDataTableNext
              columns={columns as ColumnDef<Record<string, unknown>, unknown>[]}
              data={getData(mutationData)}
              tableName={t('pages.search.searchResults')}
            />
          );
          setCollapse((prev) => {
            return prev.map((item) => {
              if (item.searchName === search.searchName) {
                return {
                  collapsed: true,
                  searchName: item.searchName,
                  selected: true,
                  results: (
                    <DefaultDataTableNext
                      columns={
                        columns as ColumnDef<Record<string, unknown>, unknown>[]
                      }
                      data={getData(mutationData)}
                      tableName={t('pages.search.searchResults')}
                    />
                  ),
                };
              }
              return {
                collapsed: true,
                searchName: item.searchName,
                selected: false,
                results: item.results,
              };
            });
          });
        },
        onError: () => {
          setResults(<></>);
          dispatch(
            addMessage({
              type: MessageType.Error,
              message: t('pages.search.noresult'),
            })
          );
        },
      }
    );
  };

  const CollapseParams = ({ show }: CollapseParamsProps) => {
    const ExecuteSetCollapse = () => {
      setCollapse((prev) => {
        return prev.map((item) => {
          if (item.searchName === search.searchName) {
            return {
              collapsed: !item.collapsed,
              searchName: search.searchName,
              selected: true,
              results: item.results,
            };
          }
          return {
            collapsed: true,
            searchName: item.searchName,
            selected: false,
            results: item.results,
          };
        });
      });
    };

    return (
      <Grid
        container
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          marginBottom: '10px',
        }}
      >
        <Typography sx={{ fontWeight: 'bold' }}>
          {t('pages.search.parameters')}
        </Typography>
        {show ? (
          <ExpandLessIcon
            sx={{ cursor: 'pointer' }}
            onClick={ExecuteSetCollapse}
          />
        ) : (
          <ExpandMoreIcon
            sx={{ cursor: 'pointer' }}
            onClick={ExecuteSetCollapse}
          />
        )}
      </Grid>
    );
  };

  return (
    <>
      {collapse.selected && <CollapseParams show={!collapse.collapsed} />}
      {!collapse.collapsed && (
        <>
          <Grid container justifyContent="center" spacing={2}>
            {Object.values(search.searchFields).map((fields) => {
              switch (fields.type) {
                case 'input':
                  return (
                    <Grid key={fields.id + '-grid'} item xs={12} sm={3} md={2}>
                      <Input
                        key={fields.id}
                        id={fields.id}
                        label={fields.label}
                        value={searchOp[fields.stateName]}
                        onChange={(e) => {
                          setSearchOp({
                            ...searchOp,
                            [fields.stateName]: e.target.value,
                          });
                        }}
                        sx={{
                          width: '100%',
                          '&.MuiFormControl-root': { mr: 1 },
                          '.MuiFormControl-root.MuiTextField-root': { m: 0 },
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            fetchResults();
                          }
                        }}
                      />
                    </Grid>
                  );
                case 'select':
                  return (
                    <Grid key={fields.id + '-grid'} item xs={12} sm={3} md={2}>
                      <Select
                        id={fields.id}
                        label={fields.label}
                        options={fields.options ?? []}
                        value={
                          searchOp[fields.stateName] ??
                          t('pages.search.chooseOption')
                        }
                        onChange={(e) => {
                          setSearchOp({
                            ...searchOp,
                            [fields.stateName]: e.target.value,
                          });
                        }}
                        sx={{ width: '100%' }}
                      />
                    </Grid>
                  );
                case 'datasource':
                  return (
                    <Grid key={fields.id + '-grid'} item xs={12} sm={3} md={2}>
                      <DatasourceSelect
                        key={fields.id}
                        id={fields.id}
                        label={fields.label}
                        value={
                          searchOp[fields.stateName] ??
                          t('pages.search.chooseOption')
                        }
                        datasource={fields.dataType ?? ''}
                        fetchLayoutInfo={(e) => {
                          setSearchOp({
                            ...searchOp,
                            [fields.stateName]: e.target.value,
                          });
                        }}
                        maxWidth={true}
                        sx={{ mr: 1, width: '100%' }}
                      />
                    </Grid>
                  );
              }
            })}
          </Grid>
          <Box alignItems="center" display="flex" mt={2}>
            <Button
              id="search-mtn-btn-id"
              onClick={() => {
                fetchResults();
              }}
              sx={{ mr: 1 }}
            >
              {t('pages.search.searchBtn')}
            </Button>
            <Button
              id="clear-mtn-btn-id"
              onClick={() => {
                setSearchOp(search.state);
              }}
              type="secondary"
            >
              {t('pages.search.clearBtn')}
            </Button>
          </Box>
          {isLoading && <Loading />}
        </>
      )}
    </>
  );
}

export default SearchMutationOptions;
