import { EntityForm } from 'types/entities';
export type ViewColumn = {
  header: JSX.Element;
  accessorKey: string;
};

export const verifyIfIsString = (d: number | undefined) => {
  return typeof d === 'string' ? parseInt(d) : d;
};

export const mapToEntity = (response: EntityForm | undefined): EntityForm => {
  return {
    names: response?.names,
    addresses: response?.addresses,
    identifiers: response?.identifiers,
    emailAddresses: response?.emailAddresses,
    phoneNumbers: response?.phoneNumbers,
    others: response?.others,
  };
};

export const getValueType = (attribute: string): string => {
  const dateType = ['commenceDate', 'ceaseDate'];
  const guidType = ['entityNameId', 'stateProvinceId', 'id'];
  const boolType = ['isPrimary', 'isConfidential', 'hasMailReturned'];
  const intType = ['postalCode', 'periodFrequencyMonth'];
  if (guidType.includes(attribute)) return 'Guid';
  else if (boolType.includes(attribute)) return 'bool';
  else if (dateType.includes(attribute)) return 'Date';
  else if (intType.includes(attribute)) return 'int';
  return 'String';
};

export const setBooleanText = (value: string | undefined): string =>
  value && value === 'true' ? 'Yes' : 'No';
