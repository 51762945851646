import { Grid, Typography } from '@mui/material';
import { dateAttributes } from 'components/entityManagement/common/entityUtils';
import { Asset, Attribute } from 'generated/graphql';
import { getFormatDate } from 'utils/date-util';
import { v4 as uuid } from 'uuid';

interface Props {
  data: Asset;
}

function AssetDetail({ data }: Props): JSX.Element {
  const sections: string[] = ['other', 'names', 'addresses'];
  const itemsToDisplay: string[] = [
    'assetCreationSource',
    'nameType',
    'addressType',
    'cityId',
    'countryId',
    'postalCode',
    'stateProvinceId',
  ];

  const getGroup = (section: string) => {
    return data.group?.find(
      (group) => group.groupName?.toLowerCase() === section
    );
  };

  const displayValue = (attribute: Attribute): string => {
    if (dateAttributes.includes(attribute.attributeName)) {
      if (attribute.attributeValue === '') return '-';
      return getFormatDate(new Date(attribute.attributeValue || ''));
    }
    return attribute.attributeValue ?? '-';
  };

  const displayItems = (): JSX.Element[] => {
    const cards: JSX.Element[] = [];
    let isCeaseDateDisplayed = true;
    sections.forEach((section) => {
      getGroup(section)?.attribute?.forEach((attribute) => {
        if (
          itemsToDisplay.includes(attribute.attributeName) ||
          (attribute.attributeName === 'ceaseDate' && isCeaseDateDisplayed)
        ) {
          if (attribute.attributeName === 'ceaseDate')
            isCeaseDateDisplayed = false;
          cards.push(
            <Grid
              key={`${uuid()}-item`}
              sx={{ display: 'flex', flexDirection: 'column' }}
              item
              xs={4}
            >
              <Typography variant="h5" id={`${uuid()}-label`} component="label">
                {attribute.attributeDisplayName}
              </Typography>
              <Typography variant="h3" id={uuid() + attribute.attributeName}>
                {displayValue(attribute)}
              </Typography>
            </Grid>
          );
        }
      });
    });
    return cards;
  };

  return (
    <Grid container spacing={3}>
      {displayItems()}
    </Grid>
  );
}

export default AssetDetail;
