import { useMemo, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import {
  MessageActionType,
  MessageType,
} from '@revenue-solutions-inc/revxcoreui';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import { ColumnDef } from '@tanstack/react-table';
import Loading from 'components/Loading';
import NoResults from 'components/NoResults';
import extractMeaningfulMessage from 'utils/errorMessage';
import { useTranslation } from 'react-i18next';
import { setHeader } from 'redux/contentSlice';
import { useAppDispatch } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import { useGetConfigurationsQuery } from 'generated/graphql';
import {
  getCustomers,
  ConfigurationsResponse,
  CustomerMetadata,
} from 'utils/getConfigurations';

function CustomerList(): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const previousPage = t('pages.forms.previousPage');
  const [customers, setCustomers] = useState<CustomerMetadata[]>([]);

  useEffect(() => {
    dispatch(
      setHeader({
        pageTitle: t('pages.customerList.title'),
        previousPage: previousPage,
        route: previousPage,
      })
    );
  }, [dispatch, previousPage, t]);

  const {
    isSuccess: isSuccessCustomers,
    isLoading: isLoadingCustomers,
    isError: isErrorCustomers,
  } = useGetConfigurationsQuery<ConfigurationsResponse>(
    {
      configurationType: 'CustomerInformation',
      configurationModule: '',
    },
    {
      onSuccess: (response) => {
        if (response?.getConfigurations) {
          const validCustomers: CustomerMetadata[] = getCustomers(
            response.getConfigurations
          );
          if (validCustomers && validCustomers.length) {
            setCustomers(validCustomers);
          }
        }
      },
      onError: (error) => {
        const message = extractMeaningfulMessage(
          error,
          t('components.message.networkerror')
        );
        dispatch(
          addMessage({
            message: message,
            type: MessageType.Error,
            actionType: MessageActionType.None,
          })
        );
      },
    }
  );

  const customersColumns: ColumnDef<CustomerMetadata[]>[] = useMemo(
    () => [
      {
        header: () => (
          <HeaderColumnNext localization={t('pages.customerList.customerId')} />
        ),
        accessorKey: 'CustomerID',
      },
      {
        header: () => (
          <HeaderColumnNext localization={t('pages.customerList.name')} />
        ),
        accessorKey: 'CustomerName',
      },
      {
        header: () => (
          <HeaderColumnNext localization={t('pages.customerList.contractId')} />
        ),
        accessorKey: 'ContractID',
      },
      {
        header: () => (
          <HeaderColumnNext localization={t('pages.customerList.modules')} />
        ),
        accessorKey: 'Module',
      },
    ],
    [t]
  );

  return (
    <Box>
      {isLoadingCustomers && <Loading />}
      {isErrorCustomers && <p>{t('pages.customerList.isErrorCustomers')}</p>}
      {customers && customers.length === 0 && <NoResults />}
      {isSuccessCustomers && customers.length > 0 && (
        <DefaultDataTableNext
          columns={customersColumns as ColumnDef<Record<string, unknown>>[]}
          data={customers}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'end',
          }}
          customHeader={<div></div>}
        />
      )}
    </Box>
  );
}
export default CustomerList;
