import { useState, useEffect } from 'react';

import { Grid } from '@mui/material';
import DateCell from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/TableCells/DateCell/DateCell';
import DataTableNextDateRangeFilter from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DataTableNextDateRangeFilter';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import { ColumnDef } from '@tanstack/react-table';
import Loading from 'components/Loading';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { setHeader } from 'redux/contentSlice';
import { useAppDispatch } from 'redux/hooks';
import { Deposit } from 'types/entities';
import { useTheme, Box } from '@mui/system';
import {
  useGetDepositSummaryQuery,
  DepositSummaryResponse,
} from 'generated/graphql';
import { addMessage } from 'redux/messageSlice';
import { MessageType } from '@revenue-solutions-inc/revxcoreui';
import extractMeaningfulMessage from 'utils/errorMessage';
import CurrencyCell from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/TableCells/CurrencyCell';
import { toDate } from 'utils/date-util';

function ManageDepositsRevenue(): JSX.Element {
  const { t } = useTranslation();
  const theme = useTheme();

  const [beginDate, setBeginDate] = useState<Date | null>();
  const [endDate, setEndDate] = useState<Date | null>();
  const dispatch = useAppDispatch();

  const { isLoading, data } = useGetDepositSummaryQuery(
    {},
    {
      onError: (error) => {
        const message = extractMeaningfulMessage(
          error,
          t('pages.manageDeposits.depositError')
        );
        dispatch(
          addMessage({
            type: MessageType.Error,
            message: message,
          })
        );
      },
    }
  );

  const COLUMNS: ColumnDef<Deposit>[] = [
    {
      id: 'depositDate',
      accessorKey: 'depositDate',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.manageDeposits.depositDate')}
        />
      ),
      cell: ({ getValue }) => <DateCell dateString={getValue() as string} />,
    },

    {
      id: 'label',
      accessorKey: 'label',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.manageDeposits.depositNumber')}
        />
      ),
      cell: ({ getValue, row }) => (
        <Link
          to={{
            pathname: `/revenue/depositDetails/${row.original.id}`,
          }}
          style={{ color: theme.palette.linkBlue.dark }}
        >
          {getValue() ? (getValue() as string) : '-'}
        </Link>
      ),
    },

    {
      id: 'depositType',
      accessorKey: 'depositType',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.manageDeposits.depositType')}
        />
      ),
    },

    {
      id: 'depositAmount',
      accessorKey: 'amount',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.manageDeposits.depositAmount')}
        />
      ),
      cell: ({ getValue }) => (
        <Box sx={{ width: '3.5em' }}>
          <CurrencyCell
            invalidValue={t('pages.tableCell.invalidValue')}
            amountString={getValue() as string}
          />
        </Box>
      ),
    },

    {
      id: 'batchesCount',
      accessorKey: 'numberOfBatches',
      header: () => (
        <HeaderColumnNext localization={t('pages.manageDeposits.numBatches')} />
      ),
    },
    {
      id: 'depositStatus',
      accessorKey: 'status',
      header: () => (
        <HeaderColumnNext localization={t('pages.manageDeposits.status')} />
      ),
    },
  ];

  const filterDepositsByDateRange = (
    deposits: DepositSummaryResponse[] | undefined
  ) => {
    let filtered: DepositSummaryResponse[] = [];

    if (deposits) {
      filtered = deposits.filter((item) => {
        if (item.depositDate) {
          //NOTE: converting this many dates is causing a perfomance issue TODO: fix this pref issue
          const openDate = toDate(item.depositDate);

          if (openDate) {
            if (beginDate && endDate) {
              return openDate >= beginDate && openDate <= endDate;
            } else if (beginDate && !endDate) {
              return openDate >= beginDate;
            } else if (endDate && !beginDate) {
              return openDate <= endDate;
            }
          }
        }
      });
    }
    return filtered;
  };

  useEffect(() => {
    dispatch(setHeader({ pageTitle: t('pages.manageDeposits.title') }));
  }, [dispatch, t]);

  return (
    <>
      {isLoading && <Loading />}
      {data && (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <DefaultDataTableNext
              columns={COLUMNS as ColumnDef<Record<string, unknown>>[]}
              data={
                beginDate || endDate
                  ? filterDepositsByDateRange(
                      data.GetDepositSummary as DepositSummaryResponse[]
                    )
                  : data.GetDepositSummary
              }
              customHeader={
                <>
                  <DataTableNextDateRangeFilter
                    data-testid="datatable-date-range"
                    beginDate={beginDate ? beginDate : null}
                    endDate={endDate ? endDate : null}
                    setBeginDate={setBeginDate}
                    setEndDate={setEndDate}
                  />
                </>
              }
              globalFilterLabel={t('pages.manageDeposits.findDeposits')}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
}
export default ManageDepositsRevenue;
