export type TenantConsole = {
  id: string;
  tenantDomain: string;
  tenantName: string;
  tenantContact: string;
  configurationStatus: ConfigurationStatus;
  serviceAdmin: string;
  messages: number;
  activationUrl: string;
  creationDate: string;
  createdBy: string;
  lastModifiedDate: string;
  lastModifiedBy: string;
  modules: [TenantModule];
};

export type SaveTenantDraftResponse = {
  SaveTenantDraft: {
    tenantId?: string | null;
  };
};

export interface TenantModule {
  moduleId: number;
  moduleName: string;
  selected: boolean;
  taxTypes: [TenantTaxType];
}
export interface TenantTaxType {
  selected: boolean;
  taxTypeId: number;
  taxTypeName: string;
  features: [string];
}
export interface Tenant {
  id?: string;
  tenantName: string;
  securityInfo?: SecurityInfo;
  rsiContactInfo: RsiContactInfo;
  contractualInfo: ContractualInfo;
  localeInfo: LocaleInfo;
}
export interface TenantView {
  id: string;
  tenantName: string;
  configurationStatus?: ConfigurationStatus;
  tenantContacts: ContactView[];
  rsiContacts: ContactView[];
  tenancyConfiguration: TenancyConfiguration;
  tenantIdentityProvider: TenantIdentityProviderView;
  modules: ModulesView[];
}
export interface TenantIdentityProviderView {
  openIdMetadataUrl: string;
  clientId: string;
}
export interface TenancyConfiguration {
  locale: string;
  language: string;
  timeZone: string;
  currency: string;
  defaultDomains: string[];
  customDomains: string[];
  managementAdminName: string;
  managementAdminEmail: string;
}
export interface TenantList {
  value: [Tenant];
}
export interface TenantCommand {
  id?: string;
  tenantName: string;
  securityInfo: SecurityInfo;
  rsiContactInfo: RsiContactInfo;
  contractualInfo: ContractualInfo;
  localeInfo: LocaleInfo;
}
export interface SecurityInfo {
  frontDoor: FrontDoor;
  mfaInfo?: MfaInfo;
  tenantIdp?: TenantIdp;
  adminUser?: AdminUser;
}

export interface FrontDoor {
  domainName: string;
}

export interface ConfigurationStatus {
  name?: string;
  description?: string;
  type?: string;
}

export interface RsiContactInfo {
  rsiContacts?: Contact[];
  dave?: string;
}

export interface ContractualInfo {
  modules: Modules[];
  tenantInfo: TenantInfo;
}

export interface Modules {
  moduleId: number;
  moduleName: string;
  selected?: boolean;
  taxTypes?: TaxTypes[] | null;
}
export interface ModulesView {
  id: number;
  name: string;
  selected?: boolean;
  taxTypes?: TaxTypesView[] | null;
}

interface Customer {
  id: string;
  name: string;
}
export interface TenantInfo {
  countryCode: string;
  customer: Customer;
  managementAdminEmail: string;
  managementAdminName: string;
  contacts: Contact[];
  identityProvider?: TenantIdentityProvider;
  rsiOpenIdMetadataUrl?: string;
}
export interface InfoLocalesList {
  locationId: string;
  locationName: string;
  currency: string;
  languages: Language[];
  timeZones: TimeZone[];
}
export interface LocaleInfo {
  locationName?: string;
  currency?: string;
  languages: Language[];
  timeZones: TimeZone[];
}
export interface Language {
  languageName: string;
  selected?: boolean;
}
export interface TimeZone {
  timeZoneName: string;
  selected?: boolean;
}
export interface AuditInfo {
  createdInfo: CreatedInfo;
  modificationInfo: boolean | null;
}
export interface MfaInfo {
  mfaEnforcement: string;
  mfaMethodType: string;
}
export interface TenantIdp {
  identityProvider: TenantIdentityProvider;
  openIdMetadataUrl: string;
}
export interface TenantIdentityProvider {
  name: string;
  openIdMetadataUrl: string;
  clientId: string;
  clientSecret: string;
}
export interface AdminUser {
  userFirstName: string;
  userLastName: string;
  userEmail: string;
}
export interface Contact {
  userContactType?: string;
  userFullName?: string;
  userId?: string;
  userEmail?: string;
  userPhone?: string;
  userOrganization?: string;
  userFirstName?: string;
  userLastName?: string;
}
export interface ContactView {
  contactType: string;
  email: string;
  fullName: string;
  phone?: string;
  organization?: string;
  id?: string;
}
export interface CreatedInfo {
  username: string;
  userEmail: string;
  dateTime: string;
}
export interface TaxType {
  taxTypeId: number;
  taxTypeName: string;
  selected: boolean;
}

export interface Module {
  moduleId: number;
  moduleName: string;
  selected: boolean;
  taxTypes?: TaxType[];
}
export interface QueryModule {
  moduleId: number;
  name: string;
}
export interface Policy {
  authorizationPolicyId: string;
  policyName: string;
  policyDescription: string;
}
export interface IdentityProvider {
  tenantId: string;
  tenantOpenIdMetadataUrl: string;
  clientId: string;
  clientSecret: string;
}

const contactDefault: Contact = {
  userContactType: '',
  userFullName: '',
  userId: '',
  userEmail: '',
  userPhone: '',
  userOrganization: '',
  userLastName: '',
  userFirstName: '',
};

export const tenantIdpDefault: TenantIdp = {
  identityProvider: {
    name: '',
    openIdMetadataUrl: '',
    clientId: '',
    clientSecret: '',
  },
  openIdMetadataUrl: '',
};

const frontDoorDefault: FrontDoor = {
  domainName: '',
};

export const securityInfoDefault: SecurityInfo = {
  frontDoor: frontDoorDefault,
  // mfaInfo: mfaInfoDefault,
  // tenantIdp: tenantIdpDefault,
  // adminUser: adminUserDefault,
};

const rsiContactInfoDefault: RsiContactInfo = {
  rsiContacts: [],
};

export const modulesDefault: Modules = {
  moduleId: 3,
  moduleName: 'Platform',
  selected: true,
};

const tenantInfoDefault: TenantInfo = {
  identityProvider: {
    name: '',
    openIdMetadataUrl: '',
    clientId: '',
    clientSecret: '',
  },
  countryCode: 'US',
  customer: {
    id: '',
    name: '',
  },
  managementAdminEmail: '',
  managementAdminName: '',
  contacts: [{ ...contactDefault, userContactType: 'Primary' }],
  rsiOpenIdMetadataUrl: '',
};

const contractualInfoDefault: ContractualInfo = {
  modules: [modulesDefault],
  tenantInfo: tenantInfoDefault,
};

export const localeInfoDefault: LocaleInfo = {
  locationName: '',
  currency: '',
  languages: [],
  timeZones: [],
};
export interface Features {
  featureId: number;
  featureName: string;
  selected: boolean;
}
export interface TaxTypes {
  taxTypeId?: number;
  taxTypeName?: string;
  selected?: boolean;
  features?: Features[] | null;
}
export interface TaxTypesView {
  id: number;
  name: string;
  selected?: boolean;
  features?: Features[] | null;
}

const tenantDefault: Tenant = {
  id: '',
  tenantName: '',
  rsiContactInfo: rsiContactInfoDefault,
  contractualInfo: contractualInfoDefault,
  localeInfo: localeInfoDefault,
};

const tenantCommandDefault: TenantCommand = {
  id: '',
  tenantName: '',
  securityInfo: securityInfoDefault,
  rsiContactInfo: rsiContactInfoDefault,
  contractualInfo: contractualInfoDefault,
  localeInfo: localeInfoDefault,
};

export { tenantDefault, tenantCommandDefault, contactDefault };
