import { useEffect, useRef } from 'react';
import { Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { setHeader } from 'redux/contentSlice';
import { useAppDispatch } from 'redux/hooks';
import { useParams } from 'react-router-dom';
import {
  LayoutType,
  MasterLayout,
  MasterLayoutType,
  RepeatingValue,
} from '@revenue-solutions-inc/revxcoreui/material/layoutBuilder/types/layouts';
import MasterLayoutView from '@revenue-solutions-inc/revxcoreui/material/layoutBuilder/renderer/MasterLayoutView';
import { deepCopy } from 'utils/deepCopy';
import {
  FormTemplateDataObject2,
  useGetAllFormLayoutsQuery,
  useGetConfigurationRecordsQuery,
  useGetFormDataQuery,
} from 'generated/graphql';
import Loading from 'components/Loading';
import { getMasterLayout } from 'utils/getMasterLayout';
import { JsonPath } from 'utils/filterJsonData';
import { Field } from '@revenue-solutions-inc/revxcoreui/material/layoutBuilder/types/fields';
import { addMessage } from 'redux/messageSlice';
import extractMeaningfulMessage from 'utils/errorMessage';
import {
  MessageActionType,
  MessageType,
} from '@revenue-solutions-inc/revxcoreui/material/messaging/Message';

function setDisabled(input: MasterLayout | undefined) {
  if (!input) return undefined;
  const copy: MasterLayout = deepCopy(input);

  copy?.sections?.forEach((section) => {
    section.layouts.forEach((layout) => {
      layout.fields.forEach((field) => {
        if (field) {
          field.rules.Disabled = true;
          field.rules.Required = false;
        }
      });

      if (layout.type === LayoutType.RepeatingGroup) {
        layout.static = true;
      }

      layout.repeatingFields?.[0]?.fields.forEach((field) => {
        if (field) {
          field.rules.Disabled = true;
          field.rules.Required = false;
        }
      });
    });
  });

  return copy;
}

function findFieldsLayout(
  fieldId: string | undefined,
  schema: MasterLayout | undefined
): [{ value: Field; path: string }] | undefined {
  if (!schema || !fieldId) return undefined;

  return JsonPath<Field>({
    keyName: 'id',
    schema,
    value: fieldId,
  });
}

function findFieldsForm(
  fieldId: string | undefined,
  schema: FormTemplateDataObject2 | undefined
): RepeatingValue[] | undefined {
  if (!schema || !fieldId) return undefined;
  let repeatingValueFound: RepeatingValue[] = [];

  // find RepeatingValues array where the baseID matches
  // since I need the parent unfortunately JsonPath doesn't give us the parent u.u
  schema.BusinessSections?.forEach((businessSection) => {
    businessSection.RepeatingGroups?.forEach((rGroup) => {
      rGroup.ColumnDefinitions?.forEach((column) => {
        const baseFieldId = column.RepeatingField?.BaseField?.FieldID;
        if (!baseFieldId) return undefined;

        if (baseFieldId.toString() == fieldId) {
          repeatingValueFound = column.RepeatingField?.RepeatingValues ?? [];
        }
      });
    });
  });

  return repeatingValueFound;
}

/*
 * The form contains the repeating values, but the layout does not, it only contains 1 template inside repeatingFields, in order to
 * display all the rows inside a repeatingLayout we have to clone the first template the number of times the form contains repeating values
 * if a field id from layout contains in form 3 repeating values, we add 2 more templates because there is 1 already present
 */
function insertRepeatingTemplate(
  masterLayout: MasterLayout | undefined,
  form: FormTemplateDataObject2 | undefined
) {
  masterLayout?.sections.forEach((section) => {
    section.layouts.forEach((layout) => {
      let maxFieldsQuantity = 0;
      layout.repeatingFields?.[0]?.fields.forEach((field) => {
        const templateFields = findFieldsForm(field.id, form);
        const quantity = templateFields?.length ?? 0;
        maxFieldsQuantity = Math.max(maxFieldsQuantity, quantity);
      });

      while (maxFieldsQuantity > 1) {
        const repeatingFieldCopy = deepCopy(layout.repeatingFields?.[0]);
        layout.repeatingFields.push(repeatingFieldCopy);
        maxFieldsQuantity--;
      }
    });
  });
}

function fillValues(
  layout: MasterLayout | undefined,
  form: FormTemplateDataObject2 | undefined
) {
  if (!layout) return undefined;
  if (!form) return layout;
  const copyForm = deepCopy(form);
  const copyLayout = deepCopy(layout);

  copyForm?.BusinessSections?.forEach((bSection) => {
    bSection?.Fields?.forEach((field) => {
      const fieldId = field.BaseField?.FieldID?.toString();
      const fieldValue = field.FieldValues?.[0]?.Value;
      const layoutFields = findFieldsLayout(fieldId, copyLayout);

      if (layoutFields?.[0]) {
        layoutFields[0].value.value = fieldValue ?? '';
      }
    });

    // sideEffect in copyLayout.
    insertRepeatingTemplate(copyLayout, copyForm);

    bSection.RepeatingGroups?.forEach((rGroup) => {
      rGroup.ColumnDefinitions?.forEach((column) => {
        const fieldId = column.RepeatingField?.BaseField?.FieldID?.toString();
        const layoutFields = findFieldsLayout(fieldId, copyLayout);

        column.RepeatingField?.RepeatingValues?.forEach((repValue, index) => {
          const fieldValue = repValue.FieldValues?.[0]?.Value;
          if (layoutFields?.[index]) {
            layoutFields[index].value.value = fieldValue ?? '';
          }
        });
      });
    });
  });

  return copyLayout;
}

interface FormViewProps {
  context?: string;
}

const FormView = ({ context }: FormViewProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { formDataId } = useParams<{ formDataId: string }>();
  const saveButtonRef = useRef<HTMLButtonElement>(null);

  const { data: formData, isFetching: isLoadingFormData } = useGetFormDataQuery(
    { formDataId: formDataId ?? '' },
    {
      enabled: !!formDataId,
      staleTime: 1000 * 60,
      onError(error) {
        dispatch(
          addMessage({
            message: extractMeaningfulMessage(
              error,
              t('components.message.networkerror')
            ),
            type: MessageType.Error,
            actionType: MessageActionType.None,
          })
        );
      },
    }
  );

  const { data: layoutData, isFetching: isLoadingLayout } =
    useGetAllFormLayoutsQuery(
      { configurationId: formData?.getFormData.FormGroupId },
      {
        enabled: !!formData?.getFormData.FormGroupId,
        staleTime: 1000 * 60,
        onError(error: Error[]) {
          dispatch(
            addMessage({
              message: extractMeaningfulMessage(
                error,
                t('components.message.networkerror')
              ),
              type: MessageType.Error,
              actionType: MessageActionType.None,
            })
          );
        },
      }
    );

  const { data: adjustmentData, isFetching: isLoadingReasons } =
    useGetConfigurationRecordsQuery(
      {
        configurationType: 'AdjustmentReason',
        configurationModule: '', //TODO add module here based on current context
      },
      {
        enabled: !!context,
      }
    );

  const masterLayout = getMasterLayout(
    layoutData?.getAllFormLayouts,
    context,
    adjustmentData?.getConfigurations.map((item) => item.configurationName)
  );

  const masterLayoutMapped = fillValues(
    masterLayout,
    formData?.getFormData.Forms?.[0]
  );
  const readOnly = setDisabled(masterLayoutMapped);

  useEffect(() => {
    dispatch(
      setHeader({
        pageTitle: t('pages.forms.formView.title'),
        previousPage: t('pages.forms.formView.backTo'),
        route: 'viewForms',
      })
    );
  }, [t, dispatch]);

  return isLoadingFormData || isLoadingLayout || isLoadingReasons ? (
    <Loading />
  ) : (
    <Stack spacing={2}>
      <Box paddingLeft={2}>
        <Typography variant="h2">
          {formData?.getFormData.Forms?.[0].FormName}
        </Typography>
        <Typography variant="h2">
          {formData?.getFormData.Forms?.[0].DLN}
        </Typography>
      </Box>
      {masterLayoutMapped && readOnly && (
        <MasterLayoutView
          masterLayout={
            context === MasterLayoutType.Suspense
              ? masterLayoutMapped
              : readOnly
          }
          saveButtonRef={saveButtonRef}
          handleSave={(layout: MasterLayout) => {
            throw new Error(`Function not implemented. ${layout}`);
          }}
        />
      )}
    </Stack>
  );
};

export default FormView;
