import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { setHeader } from 'redux/contentSlice';
import { useAppDispatch } from 'redux/hooks';

import AdjustmentDetails from 'components/entityManagement/adjustments/AdjustmentDetails';
import {
  AdjustmentDetailResponse,
  useGetAdjustmentDetailQuery,
} from 'generated/graphql';
import { useLocation, useParams } from 'react-router-dom';
import extractMeaningfulMessage from 'utils/errorMessage';
import { addMessage } from 'redux/messageSlice';
import { MessageType } from '@revenue-solutions-inc/revxcoreui';
import Loading from 'components/Loading';
import Error from 'components/Error';

function Adjustment(): JSX.Element {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const { adjustmentId } = useParams() as {
    adjustmentId: string;
  };
  const { data, isLoading, isError } = useGetAdjustmentDetailQuery(
    {
      getAdjustmentDetailId: adjustmentId,
    },
    {
      onError: (error) => {
        const message = extractMeaningfulMessage(
          error,
          t('pages.manageReusableContent.networkError')
        );
        dispatch(
          addMessage({
            message: message,
            type: MessageType.Error,
          })
        );
      },
      select: (adjustmentData) => {
        return adjustmentData.GetAdjustmentDetail as AdjustmentDetailResponse;
      },
    }
  );

  useEffect(() => {
    if (
      location.state &&
      location.state.prevPageName &&
      location.state.prevPath
    ) {
      dispatch(
        setHeader({
          pageTitle: t('pages.adjustmentDetails.title'),
          previousPage: location.state.prevPageName,
          route: location.state.prevPath.substring(1),
        })
      );
    }
  });

  return (
    <>
      {isLoading && !isError && <Loading />}
      {!isLoading && isError && <Error />}
      {!isLoading && data && <AdjustmentDetails adjustment={data} />}
    </>
  );
}

export default Adjustment;
