import { useState } from 'react';
import FeedIcon from '@mui/icons-material/Feed';
import { Box, Theme } from '@mui/system';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import CurrencyCell from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/TableCells/CurrencyCell';
import DateCell from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/TableCells/DateCell';
import { ColumnDef } from '@tanstack/react-table';
import DataCard from 'components/DataCard';
import {
  BillDetailsResponse,
  useGetBillsByTransGroupIdQuery,
} from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import {
  MessageActionType,
  MessageType,
} from '@revenue-solutions-inc/revxcoreui';
import extractMeaningfulMessage from 'utils/errorMessage';

interface Props {
  transactionGroupId: string;
}

const cardIconStyle = (theme: Theme) => {
  return {
    fill: theme.palette.primary.main,
  };
};

function BillList({ transactionGroupId }: Props): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [bills, setBills] = useState<BillDetailsResponse[]>([]);
  const module = useAppSelector((state) => state.user.module);

  const { isLoading } = useGetBillsByTransGroupIdQuery(
    {
      transactionGroupId: transactionGroupId,
    },
    {
      onSuccess: (response) => {
        setBills(response?.GetBillsByTransGroupId);
      },
      onError: (error) => {
        const message = extractMeaningfulMessage(
          error,
          t('pages.bills.billsError')
        );
        dispatch(
          addMessage({
            message: message,
            type: MessageType.Error,
            actionType: MessageActionType.None,
          })
        );
      },
    }
  );

  const columns: ColumnDef<BillDetailsResponse>[] = [
    {
      id: 'billType',
      accessorKey: 'billType',
      header: () => (
        <HeaderColumnNext localization={t('pages.bills.billType')} />
      ),
    },
    {
      id: 'invoiceNumber',
      accessorKey: 'invoiceNumber',
      header: () => (
        <HeaderColumnNext localization={t('pages.bills.invoiceNumber')} />
      ),
      cell: ({ getValue, row }) => (
        <Box
          sx={(theme: Theme) => {
            return {
              a: {
                color: theme.palette.linkBlue.main,
              },
            };
          }}
        >
          <Link
            to={{
              pathname: `/${module}/billdetails/${row.original.id}`,
            }}
            state={{
              prevPath: location.pathname,
              prevPageName: t('pages.viewFinancialTransGroup.title'),
            }}
          >
            {getValue() as string}
          </Link>
        </Box>
      ),
    },
    {
      id: 'amount',
      accessorKey: 'amount',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.viewFinancialTransGroup.amount')}
        />
      ),
      cell: ({ getValue }) => (
        <Box sx={{ width: '3.5em' }}>
          <CurrencyCell
            invalidValue={t('pages.tableCell.invalidValue')}
            amountString={getValue() as string}
          />
        </Box>
      ),
    },
    {
      id: 'pnIThroughDate',
      accessorKey: 'pnIThroughDate',
      header: () => (
        <HeaderColumnNext localization={t('pages.bills.PIThruDate')} />
      ),
      cell: ({ getValue }) => <DateCell dateString={getValue() as string} />,
    },
    {
      id: 'issueDate',
      accessorKey: 'issueDate',
      header: () => (
        <HeaderColumnNext localization={t('pages.bills.issueDate')} />
      ),
      cell: ({ getValue }) => <DateCell dateString={getValue() as string} />,
    },
    {
      id: 'dueDate',
      accessorKey: 'dueDate',
      header: () => (
        <HeaderColumnNext localization={t('pages.bills.dueDate')} />
      ),
      cell: ({ getValue }) => <DateCell dateString={getValue() as string} />,
    },
    {
      id: 'workflowId',
      accessorKey: 'workflowId',
      header: () => (
        <HeaderColumnNext localization={t('pages.bills.workflowId')} />
      ),
    },
  ];

  return (
    <>
      {!isLoading && bills && (
        <DataCard
          title={t('pages.bills.title')}
          avatar={<FeedIcon sx={cardIconStyle} />}
          children={
            <>
              {bills.length > 0 && (
                <DefaultDataTableNext
                  data={bills}
                  columns={columns as ColumnDef<Record<string, unknown>>[]}
                  enableGlobalFilter={false}
                />
              )}
              {bills.length === 0 && t('pages.bills.noBills')}
            </>
          }
        />
      )}
    </>
  );
}

export default BillList;
