import { useState, useEffect } from 'react';

import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import { Box, Divider, Grid } from '@mui/material';
import {
  Card,
  CardContent,
  CardHeader,
  DataDisplay,
  MessageActionType,
  MessageType,
  TextArea,
} from '@revenue-solutions-inc/revxcoreui';
import Dialog from '@revenue-solutions-inc/revxcoreui/material/controls/Dialog';
import DateCell from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/TableCells/DateCell';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import { ColumnDef } from '@tanstack/react-table';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { setHeader } from 'redux/contentSlice';
import { useAppDispatch } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import {
  TemplateResponse,
  useDeleteTemplateMutation,
  useUpdateTemplateStatusMutation,
  ReusableContentsResponse,
  useGetRcTypeQuery,
  useUpdateRcStatusMutation,
  useDeleteReusableContentMutation,
  TemplateHistoryResponse,
  RolesByModuleResponse,
} from 'generated/graphql';
import { dateFormat, dateTimeFormat } from 'utils/date-util';
import TopSection from './TopSection';
import DownloadAndImport from './DownloadAndImport';
import TemplatePreview from './TemplatePreview';
import { EmptyReusableContentRecord } from '../common/InitialTableRecords';

type Props = {
  contentData: ReusableContentsResponse[];
  templateData: TemplateHistoryResponse[];
  isFromTemplate: boolean;
  reFetch: () => void;
  correspondenceId: number;
  rolesData: RolesByModuleResponse[];
  rcId: number;
};

type Attributes = {
  id: string;
  displayId: string;
  status: string;
  version: number;
  createdBy: string;
  createdOn: string;
  updatedBy: string;
  updatedOn: string;
  description: string;
  header: string;
  footer: string;
};
const defaultAttributes: Attributes = {
  id: '',
  displayId: '',
  status: '',
  version: 1,
  createdBy: '',
  createdOn: '',
  updatedBy: '',
  updatedOn: '',
  description: '',
  header: '',
  footer: '',
};

function DetailsPage(dataProps: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [data, setData] = useState<Attributes>(defaultAttributes);
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [isFromDownload, setIsFromDownload] = useState(false);
  const [rowId, setRowId] = useState('');

  const [contentAttributes, setContentAttributes] =
    useState<ReusableContentsResponse>();
  const [templateAttributes, setTemplateAttributes] =
    useState<TemplateResponse>();
  const [associatedTemplates, setAssociatedTemplates] = useState<string[]>([]);
  const [isTemplatesAssociated, setIsTemplatesAssociated] = useState(false);
  const [content, setContent] = useState<ReusableContentsResponse>(
    EmptyReusableContentRecord
  );
  const [preview, setPreview] = useState(false);
  const { mutate: deleteTemplate } = useDeleteTemplateMutation();
  const { mutate: deleteRc } = useDeleteReusableContentMutation();
  const { mutate: updateTemplateStatus } = useUpdateTemplateStatusMutation();
  const { mutate: updateRcStatus } = useUpdateRcStatusMutation();

  const { data: RcType, refetch: refetchRcType } = useGetRcTypeQuery(
    {
      getRcTypeId: dataProps.rcId,
    },
    { enabled: false }
  );

  const updateHeaderFooter = (currentStatus?: string) => {
    updateRcStatus(
      {
        content: {
          Id: parseInt(data.id),
          status: currentStatus == 'DRAFT' ? 'ACTIVE' : 'INACTIVE',
        },
      },
      {
        onSuccess: () => {
          dataProps.reFetch();
          dispatch(
            addMessage({
              message: t('pages.reusableContent.message.updateRCStatus'),
              type: MessageType.Success,
              actionType: MessageActionType.None,
            })
          );
        },
        onError: () => {
          dispatch(
            addMessage({
              message: t('pages.manageReusableContent.networkError'),
              type: MessageType.Error,
              actionType: MessageActionType.None,
            })
          );
        },
      }
    );
  };

  const historyHandler = () => {
    if (dataProps.isFromTemplate && dataProps.templateData) {
      const sortedArray = dataProps.templateData.sort((a, b) => {
        if (!a.version || !b.version) return 0;
        else return b.version - a.version;
      });
      return sortedArray;
    } else if (!dataProps.isFromTemplate && dataProps.contentData) {
      const sortedArray = dataProps.contentData.sort((a, b) => {
        if (!a.version || !b.version) return 0;
        else return b.version - a.version;
      });
      return sortedArray;
    }
    return [];
  };

  useEffect(() => {
    if (dataProps.isFromTemplate) {
      setData((prev) => ({
        ...prev,
        id: templateAttributes?.templateId ?? '',
        displayId: templateAttributes?.templateDisplayId ?? '',
        createdBy: templateAttributes?.createdBy ?? '',
        createdOn: templateAttributes?.createdDate ?? '',
        status: templateAttributes?.status ?? '',
        updatedBy: templateAttributes?.updatedBy ?? '',
        updatedOn: templateAttributes?.updatedDate ?? '',
        version: templateAttributes?.version ?? 0,
        description: templateAttributes?.description ?? '',
        header:
          dataProps.templateData
            .filter(
              (item) =>
                item.templateDisplayId == templateAttributes?.templateDisplayId
            )[0]
            ?.reusableContents?.filter(
              (rcItem) =>
                rcItem.reusableCategoryName.toLocaleUpperCase() == 'HEADER'
            )[0]?.reusableContentTypeName ?? 'NA',
        footer:
          dataProps.templateData
            .filter(
              (item) =>
                item.templateDisplayId == templateAttributes?.templateDisplayId
            )[0]
            ?.reusableContents?.filter(
              (rcItem) =>
                rcItem.reusableCategoryName.toLocaleUpperCase() == 'FOOTER'
            )[0]?.reusableContentTypeName ?? 'NA',
      }));
    } else {
      setData((prev) => ({
        ...prev,
        id: contentAttributes?.reusableContentId ?? '',
        displayId: contentAttributes?.reusableContentDisplayId ?? '',
        createdBy: contentAttributes?.createdBy ?? '',
        createdOn: contentAttributes?.createdDate ?? '',
        status: contentAttributes?.status ?? '',
        updatedBy: contentAttributes?.updatedBy ?? '',
        updatedOn: contentAttributes?.updatedDate ?? '',
        version: contentAttributes?.version ?? 0,
        description: contentAttributes?.description ?? '',
      }));
    }
  }, [templateAttributes, contentAttributes, dataProps]);

  useEffect(() => {
    if (dataProps && !dataProps.isFromTemplate && dataProps.contentData) {
      if (dataProps.contentData.length < 1)
        setContent(EmptyReusableContentRecord);
      else {
        setContent(dataProps.contentData[0]);
        const templateNames = dataProps.contentData.filter(
          (item) =>
            item.templateNames &&
            item.templateNames?.length > 0 &&
            item.status?.toLocaleUpperCase() === 'ACTIVE'
        );
        if (templateNames.length > 0 && templateNames[0].templateNames) {
          setAssociatedTemplates(templateNames[0].templateNames);
        }
      }
    }
  }, [dataProps, dataProps.contentData, dataProps.templateData]);

  useEffect(() => {
    let templateResp: TemplateHistoryResponse;
    let contentResp: ReusableContentsResponse;
    if (dataProps.isFromTemplate && dataProps.templateData.length > 0) {
      templateResp = dataProps.templateData.filter(
        (item) =>
          item.status.toUpperCase() == 'DRAFT' ||
          item.status.toUpperCase() == 'ACTIVE' ||
          item.status.toUpperCase() == 'INACTIVE'
      )[0];
      setTemplateAttributes((prev) => ({
        ...prev,
        reusableContents: [],
        id: templateResp.templateId,
        status: templateResp.status,
        version: templateResp.version,
        createdBy: templateResp.createdBy,
        createdDate: format(
          new Date(templateResp.createdDate ?? ''),
          dateFormat
        ),
        updatedBy: templateResp.updatedBy,
        updatedDate: format(
          new Date(templateResp.updatedDate ?? ''),
          dateFormat
        ),
        systemFieldDictionaryId: templateResp.systemFieldDictionaryId,
        templateDisplayId: templateResp.templateDisplayId,
        templateId: templateResp.templateId,
        templateOpenXml: templateResp.templateOpenXml,
        description: templateResp.description,
        header:
          templateResp.reusableContents &&
          (templateResp.reusableContents?.filter(
            (rcItem) =>
              rcItem.reusableCategoryName.toLocaleUpperCase() == 'HEADER'
          ).length > 0
            ? templateResp.reusableContents?.filter(
                (rcItem) =>
                  rcItem.reusableCategoryName.toLocaleUpperCase() == 'HEADER'
              )[0]?.reusableContentTypeName
            : 'NA'),
        footer:
          templateResp.reusableContents &&
          (templateResp.reusableContents?.filter(
            (rcItem) =>
              rcItem.reusableCategoryName.toLocaleUpperCase() == 'FOOTER'
          ).length > 0
            ? templateResp.reusableContents?.filter(
                (rcItem) =>
                  rcItem.reusableCategoryName.toLocaleUpperCase() == 'FOOTER'
              )[0]?.reusableContentTypeName
            : 'NA'),
      }));
    } else if (!dataProps.isFromTemplate && dataProps.contentData.length > 0) {
      contentResp = dataProps.contentData.filter(
        (item) =>
          item.status?.toUpperCase() == 'DRAFT' ||
          item.status?.toUpperCase() == 'ACTIVE' ||
          item.status?.toUpperCase() == 'INACTIVE'
      )[0];
      setContentAttributes((prev) => ({
        ...prev,
        reusableContentId: contentResp.reusableContentId,
        reusableContentDisplayId: contentResp.reusableContentDisplayId,
        status: contentResp.status,
        version: contentResp.version,
        createdBy: contentResp.createdBy,
        createdDate: format(
          new Date(contentResp.createdDate ?? ''),
          dateFormat
        ),
        updatedBy: contentResp.updatedBy,
        updatedDate: format(
          new Date(contentResp.updatedDate ?? ''),
          dateFormat
        ),
        description: contentResp.description,
      }));
    }
  }, [
    dataProps.contentData,
    dataProps.isFromTemplate,
    dataProps.templateData,
    t,
  ]);

  const downloadHistoryData = () => {
    let keys, values;
    if (dataProps.isFromTemplate) {
      keys = [
        'Version, Created By,  Created Date, Status , Updated By, Updated Date ',
      ];
      values = dataProps.templateData.reduce(
        (acc: Array<string>, curr: TemplateHistoryResponse) => {
          acc.push(
            [
              curr.version,
              curr.createdBy,
              `${format(new Date(curr.createdDate ?? ''), dateTimeFormat)}`,
              curr.status,
              curr.updatedBy,
              `${format(new Date(curr.updatedDate ?? ''), dateTimeFormat)}`,
            ].join(',')
          );
          return acc;
        },
        []
      );
    } else {
      keys = [
        'Version, Created By,  Created Date, Status , Updated By, Updated Date ',
      ];
      values = dataProps.contentData.reduce(
        (acc: Array<string>, curr: ReusableContentsResponse) => {
          acc.push(
            [
              curr.version,
              curr.createdBy,
              `${format(new Date(curr.createdDate ?? ''), dateTimeFormat)}`,
              curr.status,
              curr.updatedBy,
              `${format(new Date(curr.updatedDate ?? ''), dateTimeFormat)}`,
            ].join(',')
          );
          return acc;
        },
        []
      );
    }
    const url = window.URL.createObjectURL(
      new Blob([[...keys, ...(values ?? [])].join('\n')], { type: 'text/csv' })
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${content.reusableContentDisplayId}.csv`);
    document.body.appendChild(link);
    link.click();
  };

  const columns: ColumnDef<TemplateResponse & ReusableContentsResponse>[] = [
    {
      id: 'version',
      accessorKey: 'version',
      header: () => (
        <HeaderColumnNext localization={t('pages.templates.version')} />
      ),
    },

    {
      id: 'createdBy',
      accessorKey: 'createdBy',
      header: () => (
        <HeaderColumnNext localization={t('pages.correspondence.createdBy')} />
      ),
    },
    {
      id: 'createdDate',
      accessorKey: 'createdDate',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.correspondence.createdDate')}
        />
      ),
      cell: ({ row }) => {
        return <DateCell dateString={row.original.createdDate as string} />;
      },
    },
    {
      id: 'status',
      accessorKey: 'status',
      header: () => (
        <HeaderColumnNext localization={t('pages.reusableContent.status')} />
      ),
    },
    {
      id: 'content-download',
      header: () => (
        <HeaderColumnNext localization={t('pages.reusableContent.download')} />
      ),
      cell: ({ row }) => {
        return (
          <DownloadRoundedIcon
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setIsFromDownload(true);
              if (dataProps.isFromTemplate)
                setRowId(row.original.templateDisplayId);
              else setRowId(row.original.reusableContentId);
            }}
          />
        );
      },
    },
  ];

  // eslint-disable-next-line
  const fetchTemplateDetails = (row: any) => {
    setData((prev) => ({
      ...prev,
      id: dataProps.isFromTemplate
        ? row.original.templateId
        : row.original.reusableContentId,
      displayId: row.original.templateDisplayId,
      status: row.original.status,
      version: row.original.version as number,
      createdBy: row.original.createdBy as string,
      updatedBy: row.original.updatedBy as string,
      createdOn: format(new Date(row.original.createdDate ?? ''), dateFormat),
      updatedOn: format(new Date(row.original.updatedDate ?? ''), dateFormat),
      description: row.original.description as string,
      header:
        dataProps.templateData
          .filter(
            (item) => item.templateDisplayId == row.original.templateDisplayId
          )[0]
          .reusableContents?.filter(
            (rcItem) =>
              rcItem.reusableCategoryName.toLocaleUpperCase() == 'HEADER'
          )[0]?.reusableContentTypeName ?? 'NA',
      footer:
        dataProps.templateData
          .filter(
            (item) => item.templateDisplayId == row.original.templateDisplayId
          )[0]
          .reusableContents?.filter(
            (rcItem) =>
              rcItem.reusableCategoryName.toLocaleUpperCase() == 'FOOTER'
          )[0]?.reusableContentTypeName ?? 'NA',
    }));
  };
  // eslint-disable-next-line
  const fetchContentDetails = (row: any) => {
    setData((prev) => ({
      ...prev,
      id: dataProps.isFromTemplate
        ? row.original.templateId
        : row.original.reusableContentId,
      displayId: row.original.reusableContentDisplayId,
      status: row.original.status,
      version: row.original.version as number,
      createdBy: row.original.createdBy as string,
      updatedBy: row.original.updatedBy as string,
      createdOn: format(new Date(row.original.createdDate ?? ''), dateFormat),
      updatedOn: format(new Date(row.original.updatedDate ?? ''), dateFormat),
      description: row.original.description as string,
    }));
  };

  const templateStatusUpdate = (currentStatus?: string) => {
    if (dataProps.isFromTemplate)
      updateTemplateStatus(
        {
          template: {
            Id: parseInt(data.id),
            status: currentStatus == 'DRAFT' ? 'ACTIVE' : 'INACTIVE',
          },
        },
        {
          onSuccess: () => {
            dataProps.reFetch();
            dispatch(
              addMessage({
                message: t('pages.correspondence.message.updateTemplateStatus'),
                type: MessageType.Success,
                actionType: MessageActionType.None,
              })
            );
          },
          onError: () => {
            dispatch(
              addMessage({
                message: t('pages.manageReusableContent.networkError'),
                type: MessageType.Error,
                actionType: MessageActionType.None,
              })
            );
          },
        }
      );
    else {
      if (associatedTemplates.length > 0) {
        setIsTemplatesAssociated(true);
      } else {
        updateHeaderFooter(currentStatus);
      }
    }
  };
  const DeletePopupChildren = (
    <DataDisplay
      label={t('pages.correspondence.warningMessage')}
      id="warningMessage"
      data=""
    />
  );

  const AssociatedTemplatesChildren = (
    <>
      {`By modifying this particular ${
        RcType && RcType.GetRcType?.reusableContentTypeCategory
      } you will be affecting the following ${
        associatedTemplates?.length
      } Correspondence Type(s)`}
      <ul>
        {associatedTemplates.map((template1, key) => (
          <li key={template1}>
            <DataDisplay id={String(key)} label={template1} data={''} />
          </li>
        ))}
      </ul>
      {`Would you like to proceed?`}
    </>
  );

  const deleteTemplateOrContent = (item: string) => {
    if (dataProps.isFromTemplate)
      deleteTemplate(
        {
          template: {
            templateId: item,
          },
        },
        {
          onSuccess: () => {
            dataProps.reFetch();
            dispatch(
              addMessage({
                message: t('pages.correspondence.message.deleteTemplate'),
                type: MessageType.Success,
                actionType: MessageActionType.None,
              })
            );
          },
          onError: () => {
            dispatch(
              addMessage({
                message: t('pages.manageReusableContent.networkError'),
                type: MessageType.Error,
                actionType: MessageActionType.None,
              })
            );
          },
        }
      );
    else
      deleteRc(
        {
          content: {
            reusableContentId: String(item),
          },
        },
        {
          onSuccess: () => {
            dataProps.reFetch();
            dispatch(
              addMessage({
                message: t('pages.reusableContent.message.deleteRCType'),
                type: MessageType.Success,
                actionType: MessageActionType.None,
              })
            );
          },
          onError: () => {
            dispatch(
              addMessage({
                message: t('pages.manageReusableContent.networkError'),
                type: MessageType.Error,
                actionType: MessageActionType.None,
              })
            );
          },
        }
      );
  };
  useEffect(() => {
    if (dataProps.isFromTemplate) {
      dispatch(
        setHeader({
          pageTitle: t('pages.correspondence.details'),
          previousPage: t('pages.templates.title'),
          route: `correspondenceTypes`,
        })
      );
    } else
      dispatch(
        setHeader({
          pageTitle: t('pages.reusableContent.details'),
          previousPage: t('pages.reusableContent.title'),
          route: `reusableContents`,
        })
      );
  }, [dataProps.isFromTemplate, dispatch, t]);

  return (
    <>
      <Dialog
        id="deleteTemplate"
        open={openPopup}
        children={DeletePopupChildren}
        title={t('pages.correspondence.delete')}
        type="transactional"
        transactionModalTransactionButtonText="Delete"
        width={350}
        height={190}
        handleClose={() => {
          setOpenPopup(false);
        }}
        handleCancelClick={() => {
          setOpenPopup(false);
        }}
        handleTransactionClick={() => {
          deleteTemplateOrContent(data.id);
        }}
      />
      <Dialog
        id="warningForStatusChange"
        transactionModalTransactionButtonText="Proceed"
        open={isTemplatesAssociated}
        children={AssociatedTemplatesChildren}
        title={t('pages.correspondence.associatedTemplates')}
        type="transactional"
        width={450}
        height={270}
        handleClose={() => {
          setIsTemplatesAssociated(false);
        }}
        handleTransactionClick={() =>
          updateHeaderFooter(data.status?.toLocaleUpperCase())
        }
        handleCancelClick={() => {
          setIsTemplatesAssociated(false);
        }}
      />
      <Dialog
        id="previewTemplate"
        open={preview}
        children={
          data && (
            <TemplatePreview
              templateId={parseInt(data.id)}
              setPreview={setPreview}
            />
          )
        }
        title={t('pages.correspondence.previewSteps.title')}
        width={700}
        handleClose={() => {
          setPreview(false);
        }}
      />
      <TopSection
        isFromTemplate={dataProps.isFromTemplate}
        rolesData={dataProps.rolesData}
        correspondenceId={dataProps.correspondenceId}
        rcId={dataProps.rcId}
        rcType={RcType}
        reFetch={dataProps.reFetch}
        refetchRcType={refetchRcType}
      />
      <Divider sx={{ mt: 1 }}></Divider>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8} mt={1}>
          <DownloadAndImport
            isFromTemplate={dataProps.isFromTemplate}
            contentData={dataProps.contentData}
            templateData={dataProps.templateData}
            reFetch={dataProps.reFetch}
            correspondenceId={dataProps.correspondenceId}
            rcId={dataProps.rcId}
            rcType={RcType}
            isFromDownload={isFromDownload}
            setIsFromDownload={setIsFromDownload}
            rowId={rowId}
          />

          {dataProps.templateData.length > 0 ||
          dataProps.contentData.length > 0 ? (
            <Card>
              {dataProps.isFromTemplate ? (
                <CardHeader title={t('pages.correspondence.history')} />
              ) : (
                <CardHeader title={t('pages.reusableContent.history')} />
              )}
              <CardContent>
                <>
                  {dataProps.isFromTemplate ? (
                    <DefaultDataTableNext
                      columns={columns as ColumnDef<Record<string, unknown>>[]}
                      data={historyHandler()}
                      sx={{
                        flexDirection: 'row-reverse',
                        justifyContent: 'flex-start',
                      }}
                      customHeader={
                        <Button
                          onClick={() => downloadHistoryData()}
                          id="saveBtn-updateContent"
                          type="secondary"
                          sx={{ ml: 2, mt: 1, minWidth: '128px' }}
                        >
                          {t('pages.correspondence.export')}
                        </Button>
                      }
                      cursorHover={true}
                      fetchCursorSelectedRow={fetchTemplateDetails}
                    />
                  ) : (
                    <DefaultDataTableNext
                      columns={columns as ColumnDef<Record<string, unknown>>[]}
                      data={historyHandler()}
                      sx={{
                        flexDirection: 'row-reverse',
                        justifyContent: 'flex-start',
                      }}
                      customHeader={
                        <Button
                          onClick={() => downloadHistoryData()}
                          id="saveBtn-updateContent"
                          type="secondary"
                          sx={{ ml: 2, mt: 1, minWidth: '128px' }}
                        >
                          {t('pages.correspondence.export')}
                        </Button>
                      }
                      cursorHover={true}
                      fetchCursorSelectedRow={fetchContentDetails}
                    />
                  )}
                </>
              </CardContent>
            </Card>
          ) : null}
        </Grid>
        <Grid item sx={{ display: 'flex' }} xs={12} md={4} mt={1}>
          {dataProps.templateData.length > 0 ||
          dataProps.contentData.length > 0 ? (
            <Card>
              {dataProps.isFromTemplate ? (
                <CardHeader title={t('pages.correspondence.attributes')} />
              ) : (
                <CardHeader title={t('pages.reusableContent.attributes')} />
              )}
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <DataDisplay
                      id={'id'}
                      label={
                        dataProps.isFromTemplate
                          ? t('pages.templates.id')
                          : t('pages.reusableContent.id')
                      }
                      data={data.displayId}
                    />
                    <DataDisplay
                      id={'status'}
                      label={t('pages.reusableContent.status')}
                      data={data.status}
                      sxLabel={{ display: 'inline-block', mt: 1 }}
                    />
                    <DataDisplay
                      id={'version'}
                      label={t('pages.templates.version')}
                      data={data.version as unknown as string}
                      sxLabel={{ display: 'inline-block', mt: 1 }}
                    />
                    <DataDisplay
                      id={'createdBy'}
                      label={t('pages.correspondence.createdBy')}
                      data={data.createdBy}
                      sxLabel={{ display: 'inline-block', mt: 1 }}
                      sx={{ wordWrap: 'break-word' }}
                    />
                    <DataDisplay
                      id={'createdDate'}
                      label={t('pages.correspondence.createdDate')}
                      data={data.createdOn}
                      sxLabel={{ display: 'inline-block', mt: 1 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {dataProps.isFromTemplate && (
                      <>
                        <DataDisplay
                          id={'header'}
                          label="Header"
                          data={data.header}
                          sx={{ wordWrap: 'break-word' }}
                        />
                        <DataDisplay
                          id={'footer'}
                          label="Footer"
                          data={data.footer}
                          sxLabel={{ display: 'inline-block', mt: 1 }}
                        />
                      </>
                    )}
                    <DataDisplay
                      id={'templateDescription'}
                      label={
                        dataProps.isFromTemplate
                          ? t('pages.templates.import.templateDescription')
                          : t('pages.reusableContent.RCimport.rcDescription')
                      }
                      data={''}
                      sxLabel={{ display: 'inline-block', mt: 1 }}
                    />

                    <TextArea
                      ariaLabel="add description"
                      value={data.description ?? ''}
                      multiline
                      disabled
                      onChange={() => {}}
                    />
                  </Grid>
                </Grid>
                <Box
                  sx={{ display: 'flex', justifyContent: 'center', mt: '5%' }}
                >
                  {' '}
                  {data.status?.toLocaleUpperCase() != 'INACTIVE' && (
                    <>
                      <Button
                        id="saveBtn-updateContent"
                        data-testid="update-button"
                        sx={{ minWidth: '98px' }}
                        onClick={() =>
                          templateStatusUpdate(data.status?.toLocaleUpperCase())
                        }
                      >
                        {data.status?.toLocaleUpperCase() == 'DRAFT'
                          ? t('pages.correspondence.activate')
                          : t('pages.correspondence.inactivate')}
                      </Button>
                      {dataProps.isFromTemplate && (
                        <Button
                          id="previewBtn"
                          data-testid="preview-button"
                          sx={{ ml: 1, minWidth: '98px' }}
                          type="secondary"
                          onClick={() => setPreview(true)}
                        >
                          {t(
                            'pages.correspondence.previewSteps.generatePreview'
                          )}
                        </Button>
                      )}
                    </>
                  )}
                  {data.status?.toLocaleUpperCase() == 'DRAFT' && (
                    <Button
                      id="delteBtn"
                      data-testid="update-button"
                      sx={{ ml: 1, minWidth: '98px' }}
                      type="secondary"
                      onClick={() => setOpenPopup(true)}
                    >
                      {t('pages.correspondence.delete')}
                    </Button>
                  )}
                </Box>
              </CardContent>
            </Card>
          ) : null}
        </Grid>
      </Grid>
    </>
  );
}

export default DetailsPage;
