import { useState } from 'react';

import { Typography } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Box, Theme } from '@mui/system';
import { useTranslation } from 'react-i18next';

import { OwnerType } from '../EditOwnerContainer/EditOwnerContainer';

interface Props {
  options: OwnerType[];
  setEditOwnerData: React.Dispatch<React.SetStateAction<OwnerType>>;
}
const EditOwnerPanel = ({ options, setEditOwnerData }: Props): JSX.Element => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { t } = useTranslation();

  const handleListItemClick = (index: number) => {
    setSelectedIndex(index);
  };
  return (
    <Box
      sx={(theme: Theme) => {
        return {
          borderStyle: 'solid',
          borderColor: theme.palette.secondary.main,
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          borderWidth: '.02rem',
          marginTop: '10px',
        };
      }}
    >
      <Typography variant="h4" component="label" sx={{ marginBottom: '15px' }}>
        {t('pages.propertyView.ownerHistory')}
      </Typography>
      <List>
        {options?.map((option: OwnerType, index: number) => {
          return (
            <ListItem
              selected={selectedIndex === index}
              sx={(theme: Theme) => {
                return {
                  '&.Mui-selected': {
                    backgroundColor: theme.palette.linkBlue.main,
                  },
                };
              }}
              button
              key={option.date}
              onClick={() => {
                handleListItemClick(index);
                setEditOwnerData(option);
              }}
            >
              <ListItemText primary={option.date} />
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

export default EditOwnerPanel;
