import { useState, useMemo, useEffect } from 'react';
import { Button } from '@mui/material';
import {
  useGetWorkflowsForApprovalQuery,
  useUpdateWorkflowApprovalFlagMutation,
} from 'generated/graphql';
import Error from 'components/Error';
import { useTranslation } from 'react-i18next';
import Loading from 'components/Loading';
import NoData from 'components/NoData';
import { WorkHistoryTable } from 'components/Workflow/WorkHistoryTable/WorkHistoryTable';
import {
  RowDataDrawer,
  WorkflowDataDetailsType,
} from 'components/RowDataDisplay/RowDataDrawer';
import { useAppDispatch } from 'redux/hooks';
import { MessageType } from '@revenue-solutions-inc/revxcoreui';
import { addMessage } from 'redux/messageSlice';

const ApprovalsTable = (): JSX.Element => {
  const { t } = useTranslation();
  const { data, isLoading, isError } = useGetWorkflowsForApprovalQuery();
  const [tableKey, setTableKey] = useState(0);
  const dispatch = useAppDispatch();
  const { mutate } = useUpdateWorkflowApprovalFlagMutation();

  // memoized array of data objects fetched from API
  const tableData = useMemo(
    () =>
      data?.GetWorkflowsForApproval?.map(
        ({ EntityWorkflowVariant, QueueName }) => ({
          ...EntityWorkflowVariant,
          QueueName,
        })
      ),
    [data?.GetWorkflowsForApproval]
  );

  const [selectedWorkflow, setSelectedWorkflow] = useState<
    WorkflowDataDetailsType | undefined
  >();

  const getRowData = ({
    original,
  }: {
    original: { [key: string]: string };
  }) => {
    const rowData = Object.keys(original).reduce(
      (accumulator: { [key: string]: string }, key) => {
        accumulator[`${key.charAt(0).toLowerCase() + key.slice(1)}`] =
          original[key];
        return accumulator;
      },
      {}
    );
    setSelectedWorkflow(rowData);
  };

  // function to approve || reject workflow items
  const toggleAction = (flag: boolean, state: string, id: number) => {
    mutate(
      {
        approvalFlag: flag,
        approvalStep: state,
        updateWorkflowApprovalFlagId: id,
      },
      {
        onSuccess: () => {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          dispatch(
            addMessage({
              type: MessageType.Success,
              message: t(
                flag
                  ? `Workflow #${id} Was Approved`
                  : `Workflow #${id} Was Rejected`
              ),
            })
          );
          setSelectedWorkflow(undefined);
        },
        onError: () => {
          dispatch(
            addMessage({
              type: MessageType.Error,
              message: t('pages.approvals.approvalMutation.error'),
            })
          );
          setSelectedWorkflow(undefined);
        },
      }
    );
  };

  useEffect(() => {
    setTableKey(tableData?.length ?? 0);
  }, [tableData]);

  if (isError) {
    return <Error />;
  }

  if (isLoading) {
    return <Loading />;
  }
  if (!tableData || tableData?.length === 0) {
    return <NoData />;
  }

  return (
    <>
      <WorkHistoryTable
        key={tableKey}
        tableName={t('pages.approvals.title')}
        cursorHover
        tableData={tableData}
        onRowClick={getRowData as (row: unknown) => void}
      />
      <RowDataDrawer
        title={`Work ${selectedWorkflow?.displayId ?? ''}`}
        open={!!selectedWorkflow}
        data={selectedWorkflow}
        onClose={() => setSelectedWorkflow(undefined)}
        footer={
          selectedWorkflow && (
            <>
              <Button
                variant="contained"
                size="large"
                sx={{ flex: 1 }}
                onClick={() => {
                  toggleAction(
                    true,
                    selectedWorkflow?.state as string,
                    Number(selectedWorkflow?.workflow_Key)
                  );
                }}
              >
                {t(`components.rowDisplay.approveBtn`)}
              </Button>
              <Button
                variant="contained"
                size="large"
                color="error"
                sx={{ flex: 1 }}
                onClick={() => {
                  toggleAction(
                    false,
                    selectedWorkflow?.state as string,
                    Number(selectedWorkflow?.workflow_Key)
                  );
                }}
              >
                {t(`components.rowDisplay.rejectBtn`)}
              </Button>
            </>
          )
        }
      />
    </>
  );
};

export default ApprovalsTable;
