import { useState, useEffect } from 'react';

import { MessageType } from '@revenue-solutions-inc/revxcoreui';
import ProgressLoader from '@revenue-solutions-inc/revxcoreui/material/controls/ProgressLoader';
import DepositDetailsStep from 'components/DepositDetailsStep';
import DepositFindBatches from 'components/DepositFindBatches';
import DepositReview from 'components/DepositReview';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { setHeader } from 'redux/contentSlice';
import { useAppDispatch } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import { Deposit } from 'types/entities';
import {
  useCreateDepositMutation,
  CreateDepositInput,
  AvailableBatchResponse,
} from 'generated/graphql';
import extractMeaningfulMessage from 'utils/errorMessage';
import { CreateDepositFlexible } from './types/CreateDepositFlexible';

const createDepositDefault: CreateDepositFlexible = {
  paymentBatchIds: [],
  voucherNumber: '',
  depositType: '',
  depositDate: null,
};
function CreateDeposit(): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const [deposit, setDeposit] =
    useState<CreateDepositFlexible>(createDepositDefault);
  const [selectedBatches, setSelectedBatches] = useState<
    AvailableBatchResponse[]
  >([]);
  const [stepPassedValidation, setStepPassedValidation] =
    useState<boolean>(true);
  const [completed, setCompleted] = useState<{
    [k: number]: boolean;
  }>({});

  const createDeposit = useCreateDepositMutation<Deposit>();
  const saveDeposit = () => {
    const depositToSend: CreateDepositInput = {
      paymentBatchIds: deposit?.paymentBatchIds?.map((id) => `${id}`) ?? [],
      depositDate:
        deposit?.depositDate?.toISOString() ?? new Date().toISOString(),
      voucherNumber: deposit?.voucherNumber ?? '',
      depositType: deposit?.depositType,
    };
    createDeposit.mutate(
      {
        deposit: depositToSend,
      },
      {
        onSuccess: () => {
          dispatch(
            addMessage({
              message: t('pages.createDeposit.depositCreated'),
              type: MessageType.Success,
            })
          );
          navigate(-1);
        },
        onError: (error) => {
          const mesage = extractMeaningfulMessage(
            error,
            t('components.message.networkerror')
          );
          dispatch(
            addMessage({
              message: mesage,
              type: MessageType.Error,
            })
          );
        },
      }
    );
  };

  const validateStepOne = () => {
    let isValid = false;

    if (deposit?.paymentBatchIds && deposit.paymentBatchIds.length > 0) {
      isValid = true;
    }
    return isValid;
  };

  const validateStepTwo = () => {
    let isValid = true;

    if (deposit?.depositDate === null) {
      isValid = false;
    }

    if (deposit?.depositType === '') {
      isValid = false;
    }
    return isValid;
  };

  const validateSteps = () => {
    let isValid = true;

    switch (currentStep) {
      case 0:
        isValid = validateStepOne();
        break;
      case 1:
        isValid = validateStepTwo();
        break;
    }
    setStepPassedValidation(isValid);
  };

  useEffect(() => {
    validateSteps();
  });

  useEffect(() => {
    const newCompleted: { [k: number]: boolean } = {};
    newCompleted[currentStep] = stepPassedValidation;
    setCompleted((completes: { [k: number]: boolean }) => ({
      ...completes,
      ...newCompleted,
    }));
  }, [currentStep, setCompleted, stepPassedValidation]);

  dispatch(
    setHeader({
      pageTitle: t('pages.createDeposit.title'),
      previousPage: t('pages.manageDeposits.title'),
      route: `manageDeposits`,
    })
  );

  return (
    <>
      <ProgressLoader
        steps={[
          t('pages.createDeposit.findBatches'),
          t('pages.createDeposit.enterDepositDetails'),
          t('pages.createDeposit.reviewAndSave'),
        ]}
        currentStep={currentStep}
        handleCurrentStep={(activeStep: number) => {
          setCurrentStep(activeStep);
        }}
        handleSave={saveDeposit}
        isCompleteStepBtnDisabled={!stepPassedValidation}
        nonLinear={false}
        centerAlign
        handleReset={() => {}}
        isSaveAndCreateDisabled={false}
        setCurrentStep={setCurrentStep}
        saveBtnText={t('pages.createDeposit.title')}
        completed={completed}
      >
        {currentStep === 0 && (
          <DepositFindBatches
            selectedBatches={selectedBatches}
            setSelectedBatches={setSelectedBatches}
            deposit={deposit}
            setDeposit={setDeposit}
          />
        )}
        {currentStep === 1 && (
          <>
            <DepositDetailsStep deposit={deposit} setDeposit={setDeposit} />
          </>
        )}
        {currentStep === 2 && (
          <>
            <DepositReview
              selectedBatches={selectedBatches}
              deposit={deposit}
            />
          </>
        )}
      </ProgressLoader>
    </>
  );
}

export default CreateDeposit;
