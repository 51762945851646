import { Box } from '@mui/material';

import { DestinationObject } from '@revenue-solutions-inc/revxcoreui';
import SynkObjectGroup from './SynkObjectGroup';

interface SinkViewProps {
  sinkObjects: DestinationObject;
}
function SinkView({ sinkObjects }: SinkViewProps): JSX.Element {
  return (
    <Box
      sx={{
        flexGrow: 1,
        padding: '5px',
        position: 'relative',
        height: '100%',
      }}
    >
      <SynkObjectGroup sinkObjects={sinkObjects} />
    </Box>
  );
}
export default SinkView;
