import { Error } from 'types/graphqlErrors';

export const getErrorMsg = (response: unknown) => {
  const extensions = (response as unknown as Error[])[0].extensions;
  const dictionary = extensions.response.body.errors;
  const messages = [];

  for (const key in dictionary) {
    const value = dictionary[key as unknown as number];
    messages.push(value);
  }
  const message = messages
    .flat()
    .filter((item, i, ar) => ar.indexOf(item) === i)
    .join('');
  return String(message);
};
