import { useState } from 'react';

import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { Divider, Grid, Typography } from '@mui/material';
import {
  Card,
  CardContent,
  CardHeader,
} from '@revenue-solutions-inc/revxcoreui';
import { Box, Theme } from '@mui/system';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import DatePicker from '@revenue-solutions-inc/revxcoreui/material/controls/DatePicker';
import { useTranslation } from 'react-i18next';

function PropertyLiabilityCard(): JSX.Element {
  const [dateValue, setDateValue] = useState<Date | null>(null);
  const { t } = useTranslation();
  // TODO: Remove hardcoded values once services are in place
  return (
    <Box>
      <Card>
        <CardHeader
          action={
            <OpenInFullIcon
              fontSize="inherit"
              sx={(theme: Theme) => {
                return {
                  fill: theme.palette.linkBlue.main,
                  cursor: 'pointer',
                };
              }}
            />
          }
          title={t('pages.propertyView.liabilitySummary')}
        />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <DatePicker
                id="effectivePostingDate"
                label={t('pages.propertyView.effectivePostingDate')}
                value={dateValue}
                handleChange={(newDate: Date | null) => setDateValue(newDate)}
                sx={{
                  width: '150px',
                  '& .MuiOutlinedInput-input': {
                    fontSize: '20px',
                    padding: 0,
                    fontWeight: 700,
                  },
                  '& fieldset': {
                    border: 'none',
                  },
                  '& .MuiIconButton-root': {
                    marginRight: '-25px',
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h2">
                {t('pages.propertyView.currentYearDue')}
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'center' }}>
              <Typography data-testid="current-year" variant="h2">
                {'$3,000.00'}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="h2">
                {t('pages.propertyView.delinquentDue')}
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'center' }}>
              <Typography data-testid="delinquent-due" variant="h2">
                {'$2,000.00'}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography data-testid="total-due" variant="h2">
                {t('pages.propertyView.totalDue')}
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'center' }}>
              <Typography variant="h2">{'$5,000.00'}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Button
                sx={(theme: Theme) => {
                  return {
                    display: 'block',
                    margin: '0 auto',
                    backgroundColor: theme.palette.linkBlue.main,
                  };
                }}
                id="acceptPayment"
              >
                {t('pages.propertyView.acceptPayment')}
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
}

export default PropertyLiabilityCard;
