import { useState, useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import {
  Box,
  Collapse,
  Divider,
  IconButton,
  Stack,
  Typography,
  Button,
} from '@mui/material';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import { useTranslation } from 'react-i18next';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import { functionList, FunctionListType } from '../functionsObject';
import {
  expandButton,
  collapseButton,
} from '../../../Shared/IconButtonStyling/IconButtonStyling';

const collapsedSize = 40;

type FunctionsTableProps = {
  clickFunctionHandler: (funcSyntax: string) => void;
};

function FunctionsTable({ clickFunctionHandler }: FunctionsTableProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.configRecordEditor.expressionGenerator',
  });

  const [showFunctions, setShowFunctions] = useState<boolean>(false);

  const showFunctionHandler = () => {
    setShowFunctions((previous) => !previous);
  };

  const columnsDefinition: ColumnDef<FunctionListType>[] = useMemo(
    () => [
      {
        header: () => (
          <HeaderColumnNext localization={t('headerFunctionName')} />
        ),
        accessorKey: 'functionName',
      },
      {
        header: () => <HeaderColumnNext localization={t('headerCategory')} />,
        accessorKey: 'category',
      },
      {
        header: () => <HeaderColumnNext localization={t('headerSyntax')} />,
        accessorKey: 'displaySyntax',
        cell: ({ row, getValue }) => {
          return (
            <Button
              id="syntaxButton"
              variant="text"
              onClick={() => clickFunctionHandler(row.original.syntax)}
              sx={{ justifyContent: 'flex-start' }}
              fullWidth
            >
              {getValue<string>()}
            </Button>
          );
        },
      },
      {
        header: () => (
          <HeaderColumnNext localization={t('headerDescription')} />
        ),
        accessorKey: 'description',
      },
    ],
    [clickFunctionHandler, t]
  );

  return (
    <Collapse
      orientation="vertical"
      in={showFunctions}
      collapsedSize={collapsedSize}
      sx={(theme) => ({
        backgroundColor: theme.palette.grey[100],
        padding: '0 0.5em',
        mt: '40px',
        borderRadius: '5px',
      })}
    >
      <Stack
        direction="column"
        sx={{ mb: '5px' }}
        divider={<Divider orientation="horizontal" flexItem />}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            mr: '5px',
            ml: '5px',
          }}
        >
          <Typography
            variant={'h3'}
            sx={{
              width: '100%',
              height: collapsedSize,
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
            onClick={showFunctionHandler}
          >
            {t('functionTitle')}
          </Typography>
          <IconButton
            onClick={showFunctionHandler}
            sx={showFunctions ? collapseButton : expandButton}
            aria-label="expand-button"
          >
            <ExpandCircleDownIcon />
          </IconButton>
        </Box>
        <DefaultDataTableNext<FunctionListType>
          data={functionList}
          columns={columnsDefinition}
          isSimplePagination
          pageSize={5}
        />
      </Stack>
    </Collapse>
  );
}

export default FunctionsTable;
