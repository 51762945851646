import { useEffect } from 'react';

import { MessageType } from '@revenue-solutions-inc/revxcoreui';
import { getDate } from 'common/helpers';
import AssetView from 'components/entityManagement/assets/maintain/AssetView';
import { getIdFormats } from 'components/entityManagement/common/formatValidations';
import Loading from 'components/Loading';
import {
  useGetAssetByIdQuery,
  useGetLookupConfigurationQuery,
} from 'generated/graphql';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IHeader, setHeader } from 'redux/contentSlice';
import { useAppDispatch } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import { formatIdentifier } from 'utils/formatIdentifier';
import { ConfigurationDomains } from 'common/platformConfigUtils/platformConfigUtils';
import { useTheme } from '@mui/system';

function MaintainAsset(): JSX.Element {
  const MAIN_CONFIG_DOMAIN = ConfigurationDomains.ReferenceSchema;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const { assetId } = useParams() as { assetId: string };

  const { data, isLoading, refetch } = useGetAssetByIdQuery(
    { assetId },
    {
      enabled: assetId !== '',
      onError: () => {
        dispatch(
          addMessage({
            type: MessageType.Error,
            message: t('pages.assetSummary.message.noAsset'),
          })
        );
      },
    }
  );

  const { data: configuredIdTypes } = useGetLookupConfigurationQuery({
    configurationDomain: MAIN_CONFIG_DOMAIN,
    configurationType: 'IdType',
  });

  useEffect(() => {
    if (data) {
      const header: IHeader = {
        pageTitle: t('pages.assetSummary.title'),
        previousPage: 'Dashboard',
        route: 'dashboard',
        icon: {
          props: { fill: theme.palette.primary.main },
          icon: 'assignmentIcon',
          fontSize: 'medium',
        },
        data: [
          {
            id: 'assetName',
            label: t(`pages.assetSummary.header.assetName`),
            value:
              data.GetAssetById.group
                ?.find((group) => group.groupName?.toLowerCase() === 'names')
                ?.attribute?.find((name) => name.attributeName === 'value')
                ?.attributeValue ?? '',
            first: true,
          },
          {
            id: 'assetId',
            label: t(`pages.assetSummary.header.assetId`),
            value: formatIdentifier(
              data.GetAssetById.primaryIdentifier || '',
              getIdFormats(configuredIdTypes),
              data.GetAssetById.primaryIdentifierType || ''
            )
              .concat(' (')
              .concat(data.GetAssetById.primaryIdentifierType || '')
              .concat(')'),
          },
          {
            id: 'type',
            label: t(`pages.assetSummary.header.type`),
            value: data.GetAssetById.assetType || '',
          },
          {
            id: 'address',
            label: t(`pages.assetSummary.header.address`),
            value:
              data.GetAssetById.group
                ?.find(
                  (group) => group.groupName?.toLowerCase() === 'addresses'
                )
                ?.attribute?.find(
                  (name) => name.attributeName === 'addressLine1'
                )?.attributeValue ?? '',
          },
          {
            id: 'effectiveDate',
            label: t(`pages.assetSummary.header.effectiveDate`),
            value: getDate(data.GetAssetById.commenceDate ?? ''),
          },
        ],
      };
      const cleanHeaderData = header.data?.filter((headerItem) => {
        if ('value' in headerItem && headerItem.value !== '') return headerItem;
      });
      if (cleanHeaderData) header.data = cleanHeaderData;
      dispatch(setHeader(header));
    }
  }, [
    configuredIdTypes,
    data,
    isLoading,
    dispatch,
    t,
    theme.palette.primary.main,
  ]);

  return (
    <>
      {isLoading && <Loading />}
      {data && !isLoading && (
        <AssetView data={data.GetAssetById} refetchData={refetch} />
      )}
    </>
  );
}

export default MaintainAsset;
