export type Batch = {
  id?: number;
  depositId?: number;
  depositLabel?: string;
  batchDetails: BatchDetail[];
  batchStatus?: string;
  batchType?: string;
  paidDate?: Date | null;
  defaultTransactionEffectiveDate: Date | null;
  createdDate?: Date | null;
  dateClosed?: Date | null;
  branchLocationId: string;
  label: string;
  source: string | null;
  adjustmentAmount: number;
  recordCount: number;
  externalId?: number;
};

export type BatchDetail = {
  batchId?: number;
  receiptDate?: string;
  batchDetailType?: string;
  documentType?: string;
  documentId?: number;
  beginningDln?: string;
  endingDln?: string;
  accountType?: string;
  accountId?: number;
  periodStart?: string;
  periodEnd?: string;
  amount?: number;
  batchDetailStatus?: string;
  batchLabel?: string;
};

export type BatchDetailExtended = {
  batchId?: number;
  receiptDate?: string;
  batchDetailType?: string;
  documentType?: string;
  documentId?: number;
  beginningDln?: string;
  endingDln?: string;
  accountType?: string;
  accountId?: number;
  periodStart?: string;
  periodEnd?: string;
  amount?: number;
  batchDetailStatus?: string;
  batchLabel?: string;
  depositLabel?: string;
  depositId?: number;
};

export interface CashDenominationSum {
  cashDenomination: string;
  count: number;
  amount: number;
}
export interface RemittanceSum {
  remittanceType: string;
  amount: number;
  cashDenominationSums: CashDenominationSum[] | null;
}
export interface BatchSums {
  paymentAmount: number;
  remittanceAmount: number;
  remittanceSums: RemittanceSum[];
}

export interface BatchStatus {
  batchStatus: string;
  dateClosed: Date;
}

const batchDefault: Batch = {
  paidDate: new Date(),
  defaultTransactionEffectiveDate: new Date(),
  batchDetails: [],
  branchLocationId: '',
  label: '',
  batchType: '',
  source: null,
  adjustmentAmount: 0,
  recordCount: 0,
};

export { batchDefault };
