import { useCallback, useEffect, useMemo, useState } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { ButtonGroup, InputAdornment } from '@mui/material';
import Divider from '@mui/material/Divider';
import { Box, useTheme } from '@mui/system';
import { MessageType } from '@revenue-solutions-inc/revxcoreui';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import Input from '@revenue-solutions-inc/revxcoreui/material/controls/Input';
import Loading from 'components/Loading';
import { useSearchQuery, GeneralDataResult } from 'generated/graphql';
import { useHasAccess } from 'hooks/useHasAccess';
import { IAdvancedSearch } from 'pages/Search/SearchConfig';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { setGlobalSearchPhrase, setGlobalSearchRepeat } from 'redux/userSlice';
import { useTranslation } from 'react-i18next';
import { addMessage } from 'redux/messageSlice';

import SearchMutationOptions from './SearchMutationOptions';
import SearchOptions from './SearchOptions';
import SearchOptionsLetters from './SearchOptionsLetters';
import DisplaySearch from '../DisplaySearch';

export interface CollapseProp {
  searchName: string;
  collapsed: boolean;
  selected: boolean;
  results: JSX.Element | JSX.Element[] | undefined;
}

interface ButtonCompProps {
  name: string;
  collapseValue: CollapseProp;
  results: JSX.Element | JSX.Element[] | undefined;
}

interface AdvancedSearchProps {
  advancedSearch: IAdvancedSearch;
  advancedMutationSearch: IAdvancedSearch;
  paymentSearch: IAdvancedSearch;
  setResults: React.Dispatch<React.SetStateAction<JSX.Element | JSX.Element[]>>;
}

const entityIndex = process.env.REACT_APP_ENTITY_INDEX ?? '';
const accountIndex = process.env.REACT_APP_ACCOUNT_INDEX ?? '';
const assetIndex = process.env.REACT_APP_ASSET_INDEX ?? '';

const fieldDataName = [
  { name: 'entityData', indexName: entityIndex },
  { name: 'accountData', indexName: accountIndex },
  { name: 'assetsData', indexName: assetIndex },
];

function AdvancedSearch({
  advancedSearch,
  advancedMutationSearch,
  paymentSearch,
  setResults,
}: AdvancedSearchProps): JSX.Element {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const searchPhrase = useAppSelector((state) => state.user.globalSearchPhrase);
  const searchAgain = useAppSelector((state) => state.user.globalSearchRepeat);
  const module = useAppSelector((state) => state.user.module);
  const canViewEntitySearch = useHasAccess('maintainEntity', 'view');
  const canViewAccountSearch = useHasAccess('maintainAccount', 'view');
  const canViewAssetSearch = useHasAccess('maintainAsset', 'view');
  const canViewBatchesPropertySearch = useHasAccess(
    'manageBatchesProperty',
    'view'
  );
  const canViewBatchesRevenueSearch = useHasAccess(
    'manageBatchesRevenue',
    'view'
  );
  const [searchName, setSearchName] = useState(
    searchPhrase ? searchPhrase : ''
  );

  const {
    data: searchResults,
    isFetching,
    refetch,
  } = useSearchQuery(
    {
      maxResults: 1000,
      searchPhrase: searchPhrase ? searchPhrase : '',
      indexName: `${entityIndex},${accountIndex},${assetIndex}`,
    },
    { enabled: searchPhrase ? searchPhrase.length > 2 : false }
  );

  const [collapse, setCollapse] = useState<CollapseProp[]>([]);

  const options = useMemo(
    () => [
      ...Object.values(advancedSearch),
      ...Object.values(advancedMutationSearch),
      ...Object.values(paymentSearch),
    ],
    [advancedSearch, advancedMutationSearch, paymentSearch]
  );

  useEffect(() => {
    const col: CollapseProp[] = [
      {
        searchName: 'general',
        collapsed: false,
        selected: true,
        results: <></>,
      },
      ...options.map((search) => ({
        searchName: search.searchName,
        collapsed: true,
        selected: false,
        results: <></>,
      })),
    ];
    setCollapse(col);
  }, [options]);

  useEffect(() => {
    if (searchAgain) {
      refetch();
      dispatch(setGlobalSearchRepeat(false));
    }
  }, [dispatch, refetch, searchAgain]);

  useEffect(() => {
    setSearchName(searchPhrase ?? '');
    if (searchPhrase) {
      setCollapse((prev) => {
        return prev.map((item) => {
          if (item.searchName === 'general') {
            return {
              collapsed: false,
              searchName: 'general',
              selected: true,
              results: item.results,
            };
          }
          return {
            collapsed: true,
            searchName: item.searchName,
            selected: false,
            results: item.results,
          };
        });
      });
    }
  }, [searchPhrase]);

  useEffect(() => {
    if (searchResults && searchResults.SearchByIndex.errorMessage) {
      dispatch(
        addMessage({
          type: MessageType.Error,
          message: searchResults.SearchByIndex.errorMessage,
        })
      );
    }
  }, [dispatch, searchResults]);

  useEffect(() => {
    if (searchResults && !isFetching) {
      if (searchResults.SearchByIndex.count) {
        const resData = fieldDataName.map((item) => {
          return (
            <DisplaySearch
              searchData={
                searchResults.SearchByIndex[
                  item.name as keyof typeof searchResults.SearchByIndex
                ] as GeneralDataResult[]
              }
              indexName={item.indexName}
              key={item.name}
            />
          );
        });
        setResults(resData);
        setCollapse((prev) => {
          return prev.map((item) => {
            if (item.searchName === 'general') {
              return {
                collapsed: false,
                searchName: 'general',
                selected: true,
                results: resData,
              };
            }
            return {
              collapsed: true,
              searchName: item.searchName,
              selected: false,
              results: item.results,
            };
          });
        });
      } else {
        setResults(<></>);
        setCollapse((prev) => {
          return prev.map((item) => {
            if (item.searchName === 'general') {
              return {
                collapsed: false,
                searchName: 'general',
                selected: true,
                results: <></>,
              };
            }
            return {
              collapsed: true,
              searchName: item.searchName,
              selected: false,
              results: item.results,
            };
          });
        });
        dispatch(
          addMessage({
            type: MessageType.Error,
            message: t('pages.search.noresult'),
          })
        );
      }
    }
  }, [searchResults, dispatch, isFetching, t, setResults]);

  const getCollapsedVal = useCallback(
    (searchNameVal: string) => {
      return (
        collapse.find((item) => item.searchName === searchNameVal) ?? {
          collapsed: true,
          searchName: '',
          selected: false,
          results: <></>,
        }
      );
    },
    [collapse]
  );

  const collapseValGen = useMemo(() => {
    return getCollapsedVal('general');
  }, [collapse]); // eslint-disable-line react-hooks/exhaustive-deps

  const ButtonComp = ({
    name,
    collapseValue,
    results,
  }: ButtonCompProps): JSX.Element => {
    return (
      <Button
        sx={
          collapseValue.selected
            ? {
                background: theme.palette.linkBlue.dark,
                color: 'common.white',
              }
            : {}
        }
        id={`btn-id-${name}`}
        type="secondary"
        onClick={() => {
          setCollapse((prev) => {
            return prev.map((item) => ({
              collapsed: item.searchName !== collapseValue.searchName,
              searchName: item.searchName,
              selected: item.searchName === collapseValue.searchName,
              results: item.results,
            }));
          });
          setResults(results ? results : <></>);
        }}
      >
        {name}
      </Button>
    );
  };

  return (
    <>
      <Box
        sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}
      >
        <ButtonGroup>
          <ButtonComp
            name={t('pages.search.general')}
            collapseValue={collapseValGen}
            results={collapseValGen.results}
          />
          {Object.values(advancedSearch)
            .filter((value) => {
              if (
                (module === 'property' && !canViewBatchesPropertySearch) ||
                (module === 'revenue' && !canViewBatchesRevenueSearch)
              ) {
                return value.searchName !== 'Batches';
              }
              return value;
            })
            .map((search) => {
              const collapseVal = getCollapsedVal(search.searchName);
              return (
                <ButtonComp
                  name={search.searchName}
                  collapseValue={collapseVal}
                  key={search.searchName}
                  results={collapseVal.results}
                />
              );
            })}
          {Object.values(advancedMutationSearch).map((search) => {
            if (
              (canViewEntitySearch && search.searchName === 'Entities') ||
              (canViewAccountSearch && search.searchName === 'Accounts') ||
              (canViewAssetSearch && search.searchName === 'Assets')
            ) {
              const collapseVal = getCollapsedVal(search.searchName);
              return (
                <ButtonComp
                  name={search.searchName}
                  collapseValue={collapseVal}
                  key={search.searchName}
                  results={collapseVal.results}
                />
              );
            }
          })}
          {Object.values(paymentSearch).map((search) => {
            const collapseVal = getCollapsedVal(search.searchName);
            return (
              <ButtonComp
                name={search.searchName}
                collapseValue={collapseVal}
                key={search.searchName}
                results={collapseVal.results}
              />
            );
          })}
        </ButtonGroup>
      </Box>
      <Divider
        sx={{
          borderColor: theme.palette.linkBlue.dark,
          width: '100%',
          mt: 4,
          mb: 4,
        }}
      />
      <Box
        sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}
      >
        {!collapseValGen.collapsed && (
          <Box sx={{ display: 'flex' }}>
            <Input
              onChange={(e) => setSearchName(e.target.value)}
              id="search-input-id"
              ariaLabel="search input id"
              value={searchName}
              sx={{
                width: '400px',
                '.MuiInputBase-root.MuiInput-root': {
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                },
              }}
              placeholder={t('pages.appMenuBar.searchPlaceHolder')}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  if (searchName === searchPhrase) refetch();
                  else dispatch(setGlobalSearchPhrase(searchName));
                }
              }}
              endAdornment={
                <InputAdornment
                  position="end"
                  sx={{
                    cursor: 'pointer',
                  }}
                >
                  <CloseIcon
                    onClick={() => dispatch(setGlobalSearchPhrase(''))}
                  />
                </InputAdornment>
              }
            />
            <Button
              ariaLabel={'Search'}
              id="search-btn-id"
              onClick={() => {
                if (searchName === searchPhrase) refetch();
                else dispatch(setGlobalSearchPhrase(searchName));
              }}
              sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
            >
              <SearchIcon />
            </Button>
          </Box>
        )}
        {Object.values(advancedSearch).map((search) => {
          return search.searchName === 'Letters' ? (
            <SearchOptionsLetters
              search={search}
              setResults={setResults}
              key={search.searchName}
              collapse={getCollapsedVal(search.searchName)}
              setCollapse={setCollapse}
            />
          ) : (
            <SearchOptions
              search={search}
              setResults={setResults}
              key={search.searchName}
              collapse={getCollapsedVal(search.searchName)}
              setCollapse={setCollapse}
            />
          );
        })}
        {Object.values(advancedMutationSearch).map((search) => {
          return (
            <SearchMutationOptions
              search={search}
              setResults={setResults}
              key={search.searchName}
              collapse={getCollapsedVal(search.searchName)}
              setCollapse={setCollapse}
            />
          );
        })}
        {Object.values(paymentSearch).map((search) => {
          return (
            <SearchOptions
              search={search}
              setResults={setResults}
              key={search.searchName}
              collapse={getCollapsedVal(search.searchName)}
              setCollapse={setCollapse}
            />
          );
        })}
      </Box>
      {!collapseValGen.selected && (
        <Divider
          sx={{
            borderColor: theme.palette.linkBlue.dark,
            width: '100%',
            mt: 4,
            mb: 4,
          }}
        />
      )}
      {isFetching && <Loading />}
    </>
  );
}

export default AdvancedSearch;
