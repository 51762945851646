import { useEffect } from 'react';

import { Grid, SelectChangeEvent } from '@mui/material';
import Input from '@revenue-solutions-inc/revxcoreui/material/controls/Input';
import Radio from '@revenue-solutions-inc/revxcoreui/material/controls/Radio';
import Select from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import { phoneExp, emailExp } from 'common/regexp';
import useMaskInput from 'hooks/useMaskInput';
import { useTranslation } from 'react-i18next';
import { ContactTypes } from 'types/constants';
import { Contact as ContactType } from 'types/contact';
import { phoneNumberType, emailAddressType } from 'types/rsiTypes';

interface Props {
  contact: ContactType;
  handleChange: (contact: ContactType) => void;
  contactType: string;
  setDisabled?: React.Dispatch<React.SetStateAction<boolean>>;
  closeButton?: React.ReactNode;
}

function Contact({
  contact,
  handleChange,
  contactType,
  setDisabled,
  closeButton,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const emailMask = useMaskInput(
    '',
    t('pages.manageTaxingAuthority.contact.invalidEmail'),
    emailExp,
    contact.id ? contact.value : ''
  );
  const phoneMask = useMaskInput(
    '999-999-9999',
    t('pages.manageTaxingAuthority.contact.invalidPhone'),
    phoneExp,
    contact.id ? contact.value : ''
  );

  const handleContactChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (contactType === ContactTypes.Email) {
      emailMask.handleMaskChange(event);
      handleChange({ ...contact, value: event.target.value });
    } else if (contactType === ContactTypes.Phone) {
      phoneMask.handleMaskChange(event);
      const str = event.target.value;
      const newStr = str.replace(/-/g, '');
      handleChange({ ...contact, value: newStr });
    }
  };

  useEffect(() => {
    if (contactType === ContactTypes.Email) {
      setDisabled?.(emailMask.disabledValue);
    } else {
      setDisabled?.(phoneMask.disabledValue);
    }
  }, [
    emailMask.disabledValue,
    phoneMask.disabledValue,
    setDisabled,
    contactType,
  ]);

  const handleValueChange = () => {
    if (contact.value === '') {
      return '';
    }
    if (contact.value !== '' && contactType === ContactTypes.Email) {
      return emailMask.inputValue;
    }
    return phoneMask.maskedValue;
  };

  const handleHelperTextChange = () => {
    if (contactType === ContactTypes.Email && contact.value.length > 0) {
      return emailMask.maskError;
    }
    if (contactType === ContactTypes.Phone && contact.value.length > 0) {
      return phoneMask.maskError;
    }
    return '';
  };

  const handleRadioChange = (
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    handleChange({ ...contact, isPrimary: checked });
  };

  return (
    <>
      <Grid container mt={0.5}>
        <Grid item xs={3}>
          <Input
            value={handleValueChange()}
            id="value"
            label={
              contactType === ContactTypes.Email
                ? t('components.contact.email')
                : t('components.contact.phone')
            }
            helperText={handleHelperTextChange()}
            onChange={handleContactChange}
          />
        </Grid>
        <Grid item xs={2} ml={1}>
          <Select
            options={
              contactType === ContactTypes.Phone
                ? phoneNumberType.map((numType) => {
                    return { key: numType.id, desc: numType.value };
                  })
                : emailAddressType.map((numType) => {
                    return { key: numType.id, desc: numType.value };
                  })
            }
            value={contact.type}
            id="type"
            label={t('components.contact.type')}
            onChange={(event: SelectChangeEvent<string | number>) => {
              handleChange({ ...contact, type: event.target.value as string });
            }}
          />
        </Grid>
        <Grid item xs={1}>
          {closeButton}
        </Grid>
        <Grid item xs={12}>
          <Radio
            value="test_Checkbox_value"
            color="primary"
            id="correspondenceEnabled"
            checked={contact.isPrimary}
            onChange={handleRadioChange}
            label="Primary"
          />
        </Grid>
      </Grid>
    </>
  );
}

export default Contact;
