import {
  Attribute,
  WorkflowItem,
  WorkflowVariantObject,
} from 'generated/graphql';

export type TableData = { [key: string]: string };

// this function is no longer being used after API reconfiguration 11/30/22
export const getTableDataFromAttributes = (attributes: Attribute[]) => {
  return attributes.reduce((acc: TableData, attr: Attribute) => {
    acc[attr.attributeName] = attr.attributeValue;
    return acc;
  }, {});
};

export const formatWorkflowDataForTable = (
  rawDataItems: WorkflowItem[] = []
) => {
  const tableData = rawDataItems.map((item) => {
    try {
      const wf = JSON.parse(item?.wF_Full_Object ?? '');
      return {
        ...wf,
        QueueName: item.queueName ?? '',
        DisplayId: wf.DisplayId ?? '',
        VariantStartDate: wf.VariantStartDate ?? '',
        VariantEndDate: wf.VariantEndDate ?? '',
        State: wf.State ?? '',
        VariantGroupCode: wf.VariantGroupCode ?? '',
        WorkflowVariantCode: wf.WorkflowVariantCode ?? '',
        PriorityIndicator: wf.PriorityIndicator ?? '',
      };
    } catch (error) {}
  });

  return tableData as Record<string, unknown>[];
};

const parseWorkflowFullObject = (wfObjectString = '{}') => {
  try {
    return JSON.parse(wfObjectString);
  } catch (e) {
    return {}; // return empty object on parse error
  }
};

export const formatWorkflowVariantDataForTable = (
  rawDataItems: WorkflowVariantObject[] = []
) => {
  const tableData = rawDataItems.map((item) => {
    const wf = parseWorkflowFullObject(item?.wF_Full_Object);

    return {
      ...wf,
      Workflow_Variant_Id: item?.workflow_Variant_Id ?? '',
      DisplayId: wf.DisplayId ?? '',
    };
  });
  return tableData as Record<string, unknown>[];
};
