import { useReducer, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { useNavigate, NavigateOptions, useParams } from 'react-router';
import {
  Dialog,
  DialogContent,
  Stack,
  Typography,
  SelectChangeEvent,
  List,
  DialogTitle,
  DialogActions,
  Button,
} from '@mui/material';
import { useAppSelector } from 'redux/hooks';
import { WorkflowFormContext } from 'components/Workflow/WorkflowFormContext';
import { initialState, actions, notesPanelReducer } from './notesPanelReducer';
import NoteRowItem from './NoteRowItem';
import { PanelLayout } from './PanelLayout';
import type { NoteDisplay } from './NoteRowItem';
import type { PanelProps, VariantGroup } from '../types';

type NotesPanelProps = PanelProps & {
  notes: string[];
  isEdit?: boolean;
};

const variantType = 'Notes';

export const NotesPanel = ({
  selectedIndex,
  index,
  notes,
  isEdit,
}: NotesPanelProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { variantId, key } = useParams() as { variantId: string; key: string };
  const module = useAppSelector((state) => state.user.module);
  const [state, localDispatch] = useReducer(notesPanelReducer, initialState);
  const { caseStateCopy } = useContext(WorkflowFormContext);

  const setGroups = (variants: VariantGroup[]) => {
    localDispatch({
      type: actions.SET_VARIANT_OPTIONS,
      payload: {
        options: variants.map((variant) => ({
          key: String(variant.id),
          desc: variant.shortDescription,
        })),
      },
    });
  };

  const handleChangeSelectedVariant = (
    event: SelectChangeEvent<string | number>
  ) => {
    localDispatch({
      type: actions.SELECT_VARIANT,
      payload: {
        selectedVariant: String(event.target.value),
      },
    });
  };

  const openAddNotesModal = () => {
    localDispatch({
      type: actions.OPEN_ADD_NOTES_MODAL,
    });
  };

  const closeAddNotesModal = () => {
    localDispatch({
      type: actions.CLOSE_ADD_NOTES_MODAL,
    });
  };

  const setSelectedNote = (note: NoteDisplay | null) => () => {
    localDispatch({
      type: actions.SET_SELECTED_NOTE,
      payload: { selectedNote: note },
    });
  };

  const onAddNote = () => {
    navigate(`/${module}/workflow/create/${state.selectedVariant}`, {
      state: {
        __workType: 'Notes',
        __previousUrl: {
          variantId,
          key,
        },
        __previousState: caseStateCopy.current,
      },
    } as NavigateOptions);
  };

  return (
    <PanelLayout
      selectedIndex={selectedIndex}
      index={index}
      isEdit={isEdit}
      variantType={variantType}
      setGroups={setGroups}
      onChangeSelectedVariant={handleChangeSelectedVariant}
      addModalOpen={state.addNotesModalOpen}
      openAddModal={openAddNotesModal}
      closeAddModal={closeAddNotesModal}
      selectedVariant={state.selectedVariant}
      onAddItem={onAddNote}
      variantOptions={state.variantOptions}
    >
      <>
        <List>
          {notes.map((note) => (
            <NoteRowItem
              key={note}
              note={note}
              setSelectedNote={setSelectedNote}
            />
          ))}
        </List>
        {Boolean(state.selectedNote) && (
          <Dialog
            open={Boolean(state.selectedNote)}
            onClose={setSelectedNote(null)}
          >
            <DialogTitle>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="h2" sx={{ fontStyle: 'italic' }}>
                  {state?.selectedNote?.CreatedBy}
                </Typography>
                {Boolean(state?.selectedNote?.CreatedDate) && (
                  <Typography>
                    {format(
                      new Date(state?.selectedNote?.CreatedDate ?? ''),
                      'MMM d, yyyy'
                    )}
                  </Typography>
                )}
              </Stack>
              <Typography
                component="h2"
                variant="h1"
                sx={(theme) => ({
                  paddingTop: 2,
                  marginTop: 1,
                  borderTop: `1px solid ${theme.palette.primary}`,
                })}
              >
                {state?.selectedNote?.NotesSubject}
              </Typography>
            </DialogTitle>
            <DialogContent sx={{ minWidth: 350 }}>
              <Typography variant="body2">
                {state.selectedNote?.NotesMessage}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                id="close-note-btn"
                variant="contained"
                onClick={setSelectedNote(null)}
              >
                {t('pages.workflow.close')}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </>
    </PanelLayout>
  );
};
