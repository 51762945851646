import { useCallback, useEffect, useState } from 'react';

import { Grid } from '@mui/material';
import {
  MessageActionType,
  MessageType,
} from '@revenue-solutions-inc/revxcoreui';
import DateCell from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/TableCells/DateCell/DateCell';
import DataTableNextDateRangeFilter from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DataTableNextDateRangeFilter';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import Select from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import { SelectType } from '@revenue-solutions-inc/revxcoreui/material/controls/Select/Select';
import { ColumnDef } from '@tanstack/react-table';
import { filterByDateRange } from 'common/helpers';
import Loading from 'components/Loading';
import useGetAccessToken from 'hooks/useGetAccessToken';
import useMutationRequest from 'hooks/useMutationRequest';
import { updateStatus } from 'pages/ManageBatches/ManageBatchesProperty/ManageBatchesPropertyQuery';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { setHeader } from 'redux/contentSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { addMessage, clearActionValue } from 'redux/messageSlice';
import { Batch } from 'types/batches/entities';
import { BatchStatusInput } from 'types/graphTypes';
import { useHasAccess } from 'hooks/useHasAccess';
import { useTheme } from '@mui/system';
import { useFindBatchQuery } from 'generated/graphql';

const selectStatus: SelectType[] = [
  { key: 'Open', desc: 'Open' },
  { key: 'Closed', desc: 'Closed' },
];

function ManageBatchesProperty(): JSX.Element {
  const { t } = useTranslation();
  const theme = useTheme();
  const accessToken = useGetAccessToken();
  const dispatch = useAppDispatch();
  const updateBatch = useMutationRequest<BatchStatusInput>();
  const [beginDate, setBeginDate] = useState<Date | null>();
  const [endDate, setEndDate] = useState<Date | null>();
  const [rowBatchId, setRowBatchId] = useState<number>();
  const actionValue = useAppSelector((state) => state.message.actionValue);
  const module = useAppSelector((state) => state.user.module);
  const canEdit = useHasAccess('manageBatchesProperty', 'edit');

  const {
    isLoading,
    error,
    data,
    refetch: refetchBatch,
  } = useFindBatchQuery({
    openDateBegin: `2000-01-01`,
  });

  const updateBatchStatus = useCallback(
    (id: number, status: string) => {
      updateBatch.mutate(
        {
          token: accessToken,
          mutationKey: 'update-status',
          query: updateStatus,
          params: {
            id: id.toString(),
            status: status,
          },
          paramsId: 'batchStatus',
        },
        {
          onSuccess: () => {
            refetchBatch();
            if (status === 'Closed') {
              dispatch(
                addMessage({
                  message: t('pages.manageBatches.message.batchClosed'),
                  type: MessageType.Info,
                  actionType: MessageActionType.Undo,
                })
              );
            }
          },
          onError: () => {
            dispatch(
              addMessage({
                message: t('components.message.networkerror'),
                type: MessageType.Error,
              })
            );
          },
        }
      );
    },
    [accessToken, dispatch, refetchBatch, t, updateBatch]
  );

  useEffect(() => {
    dispatch(setHeader({ pageTitle: t('pages.manageBatches.title') }));
  }, [dispatch, t]);

  useEffect(() => {
    if (actionValue === 'message_undo' && rowBatchId) {
      updateBatchStatus(rowBatchId, 'Open');
      dispatch(clearActionValue());
    }
  }, [actionValue, dispatch, rowBatchId, updateBatchStatus]);

  const Columns: ColumnDef<Batch>[] = [
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.manageBatches.status')} />
      ),
      accessorKey: 'batchStatus',
      cell: ({ row, getValue }) => {
        if (!canEdit) return <>{getValue() as string}</>;
        return (
          <Select
            id={String(row.original.id)}
            value={getValue() as string}
            options={selectStatus}
            label=""
            disabled={getValue() == 'Closed'}
            onChange={(event) => {
              if (row.original.id) {
                if (event.target.value === 'Closed') {
                  updateBatchStatus(row.original.id, 'Closed');
                  setRowBatchId(row.original.id);
                }
              }
            }}
            size="small"
          />
        );
      },
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.manageBatches.name')} />
      ),
      accessorKey: 'label',
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.manageBatches.batchType')} />
      ),
      accessorKey: 'batchType',
    },
    {
      header: () => (
        <HeaderColumnNext
          localization={t('pages.manageBatches.transactions')}
        />
      ),
      id: 'transactions',
      cell: ({ row }) => {
        if (!canEdit) return <></>;
        return (
          <Link
            to={{ pathname: `/${module}/batchDetails/${row.original.id}` }}
            style={{ color: theme.palette.linkBlue.dark }}
          >
            {t('pages.manageBatches.viewTransactions')}
          </Link>
        );
      },
    },
    {
      header: () => (
        <HeaderColumnNext
          localization={t('pages.manageBatches.associatedDeposit')}
        />
      ),
      accessorKey: 'depositLabel',
      cell: ({ row, getValue }) => {
        return (
          <Link
            to={{
              pathname: `/${module}/depositDetails/${row.original.depositId}`,
            }}
            style={{ color: theme.palette.linkBlue.dark }}
            aria-label={t('pages.manageBatches.associatedDepositDetail')}
          >
            {getValue() as string}
          </Link>
        );
      },
    },
    {
      header: () => (
        <HeaderColumnNext
          localization={t('pages.manageBatches.effectivePostingDate')}
        />
      ),
      accessorKey: 'defaultTransactionEffectiveDate',
      cell: ({ getValue }) => {
        return <DateCell dateString={getValue() as string} />;
      },
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.manageBatches.paidDate')} />
      ),
      accessorKey: 'paidDate',
      cell: ({ getValue }) => {
        return <DateCell dateString={getValue() as string} />;
      },
    },
    {
      header: () => (
        <HeaderColumnNext
          localization={t('pages.manageBatches.branchLocation')}
        />
      ),
      accessorKey: 'branchLocationId',
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.manageBatches.extId')} />
      ),
      accessorKey: 'externalId',
    },
  ];

  if (!isLoading && data) {
    return (
      <>
        {isLoading && <Loading />}
        {data && (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <DefaultDataTableNext
                columns={
                  Columns as ColumnDef<Record<string, unknown>, unknown>[]
                }
                data={
                  beginDate || endDate
                    ? filterByDateRange(data.FindBatch, beginDate, endDate)
                    : data.FindBatch
                }
                customHeader={
                  <>
                    <DataTableNextDateRangeFilter
                      data-testid="datatable-date-range"
                      beginDate={beginDate ? beginDate : null}
                      endDate={endDate ? endDate : null}
                      setBeginDate={setBeginDate}
                      setEndDate={setEndDate}
                    />
                  </>
                }
                globalFilterLabel={t('pages.manageBatches.findBatches')}
              />
            </Grid>
          </Grid>
        )}
      </>
    );
  }
  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <h1>{t('components.message.error')}</h1>;
  }
  return <></>;
}
export default ManageBatchesProperty;
