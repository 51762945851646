import { Control, Controller, useFieldArray, useWatch } from 'react-hook-form';
import { Grid, Stack, IconButton, Typography } from '@mui/material';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { Button } from '@revenue-solutions-inc/revxcoreui';
import { useTranslation } from 'react-i18next';
import DatasourceSelect from 'components/DatasourceSelect';

import { WorkflowFormObject, WorkflowAttribute } from '../WorkflowEditor.types';

type OtherAttributesProps = {
  control: Control<WorkflowFormObject, null>;
  editDisabled: boolean;
  grpIndex: number;
  groupName: string;
  groupLabel: string;
};

export default function OtherAttributes({
  control,
  editDisabled,
  grpIndex,
  groupName,
  groupLabel,
}: OtherAttributesProps) {
  const { fields, remove, append } = useFieldArray({
    control,
    name: `workflowConfig.${grpIndex}.attribute`,
  });

  const watchParameters = useWatch({
    control,
    name: `workflowConfig.${grpIndex}.attribute`,
  });

  const { t } = useTranslation();

  const closeReasonBase = {
    attributeName: 'Close reason',
    attributeDisplayName: t('pages.workflowEditor.closeReason'),
    attributeType: 'ReferenceData',
    attributeDescription: t('pages.workflowEditor.closeReason'),
    dataSource: 'WorkFlowCloseReasons',
  };

  const createNewAttribute = () => {
    //TODO Other cases are going to be here in the future
    switch (groupName) {
      case 'CloseReasonConfiguration':
        return { ...closeReasonBase, attributeValue: '' };
      default:
        return { attributeValue: '' } as WorkflowAttribute;
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <Stack direction="row" justifyContent="space-between" spacing={3}>
          <Typography variant="h1">{groupName}</Typography>
          <Button
            id={`add-${groupName}-btn`}
            size="small"
            disabled={editDisabled}
            onClick={() => {
              const lastField =
                fields.length > 0
                  ? watchParameters[fields.length - 1]
                  : undefined;
              if (!lastField || lastField.attributeValue) {
                append(createNewAttribute());
              }
            }}
          >
            {groupLabel}
          </Button>
        </Stack>
      </Grid>
      {fields.map((attr, index) => {
        return (
          <Grid item key={attr.id} xs={6}>
            <Stack direction="row" alignItems="start" spacing={3}>
              <Controller
                defaultValue=""
                name={`workflowConfig.${grpIndex}.attribute.${index}.attributeValue`}
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <DatasourceSelect
                      maxWidth
                      disabled={editDisabled}
                      id={`${attr.attributeName}-${index}`}
                      datasource={attr.dataSource ?? ''}
                      label={attr.attributeDisplayName}
                      fetchLayoutInfo={onChange}
                      value={value}
                    />
                  );
                }}
              />
              <IconButton
                id={`delete-${groupName}-btn`}
                aria-label={`delete ${groupName}`}
                color="default"
                size="small"
                disabled={editDisabled}
                onClick={() => {
                  remove(index);
                }}
              >
                <CancelRoundedIcon fontSize="small" />
              </IconButton>
            </Stack>
          </Grid>
        );
      })}
    </>
  );
}
