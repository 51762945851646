import { useState, useEffect, useContext } from 'react';

import { SelectChangeEvent } from '@mui/material/Select';
import Select, {
  SelectType,
} from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import { MessageType } from '@revenue-solutions-inc/revxcoreui/material/messaging/Message/Message';
import EntityManagementContext from 'components/contexts/EntityManagement';
import Loading from 'components/Loading';
import { useGetLookupTypesConfigurationQuery } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import {
  ConfigurationDomains,
  ConfigurationModules,
} from 'common/platformConfigUtils/platformConfigUtils';

interface Props {
  id?: string;
  label: string;
  required?: boolean;
  disabled?: boolean;
  errorMsg?: string;
  value?: string | number;
  configurationType: string;
  fetchLayoutInfo: React.Dispatch<SelectChangeEvent<string | number>>;
}

function LookupConfiguration({
  id = 'lookup-types-select',
  label,
  required,
  disabled,
  errorMsg,
  value,
  configurationType,
  fetchLayoutInfo,
}: Props): JSX.Element {
  const contextTypes = ['EntityType', 'AccountType'];
  const MAIN_CONFIG_MODULE = ConfigurationModules.Platform;
  const MAIN_CONFIG_DOMAIN = ConfigurationDomains.ReferenceSchema;
  const [types, setTypes] = useState<SelectType[]>([]);
  const [selected, setSelected] = useState<string>('');
  const ctx = useContext(EntityManagementContext);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const {
    data: lookupTypes,
    isLoading,
    isFetched,
    error,
  } = useGetLookupTypesConfigurationQuery({
    configurationDomain: MAIN_CONFIG_DOMAIN,
    configurationModule: MAIN_CONFIG_MODULE,
    configurationType: configurationType,
  });

  useEffect(() => {
    if (lookupTypes && !error) {
      const options: SelectType[] = [];
      lookupTypes.GetLookupTypesConfiguration.forEach((type) => {
        const optionType: SelectType = {
          key: type.configurationName,
          desc: type.configurationName,
        };
        options.push(optionType);
      });
      setTypes(options);
    }
  }, [setTypes, error, lookupTypes]);

  useEffect(() => {
    if (!isLoading && isFetched && (error || !lookupTypes)) {
      dispatch(
        addMessage({
          type: MessageType.Error,
          message: t('components.message.lookupError'),
        })
      );
    }
  }, [error, lookupTypes, isLoading, isFetched, dispatch, t]);

  if (isLoading) return <Loading />;

  return lookupTypes ? (
    <Select
      id={id}
      label={label}
      options={types}
      value={value ?? selected}
      onChange={(ev) => {
        fetchLayoutInfo(ev);
        setSelected(ev.target.value as string);
        if (contextTypes.includes(configurationType)) {
          ctx.onTypeChange(ev.target.value.toString());
        } else if (configurationType === 'IdType') {
          ctx.onIdTypeChange(ev.target.value.toString());
        }
      }}
      required={required}
      error={errorMsg}
      disabled={disabled}
    />
  ) : (
    <></>
  );
}

export default LookupConfiguration;
