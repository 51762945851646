import { useTranslation } from 'react-i18next';
import {
  Control,
  useFieldArray,
  UseFormSetValue,
  UseFormTrigger,
} from 'react-hook-form';
import { Grid, Divider } from '@mui/material';
import { Button } from '@revenue-solutions-inc/revxcoreui';
import {
  NonNestedAttribute,
  WorkflowFormObject,
} from '../WorkflowEditor.types';
import { RepeatingAttribute } from './';

const newRepeatingValue: NonNestedAttribute = {
  attributeDescription: '',
  attributeDisplayName: '',
  attributeName: '',
  attributeType: '',
  attributeValue: '',
  dataSource: '',
  extensibleBusinessDriver: [],
  isOOTB: false,
  isOOTBEditable: false,
};

interface RepeatingProps {
  grpIndex: number;
  attributeIndex: number;
  control: Control<WorkflowFormObject, null>;
  editDisabled: boolean;
  setValue: UseFormSetValue<WorkflowFormObject>;
  isCustomTables: boolean;
  enableHiddenAttribute?: boolean;
  isEditMode?: boolean;
  triggerValidation?: UseFormTrigger<WorkflowFormObject>;
}

function RepeatingAttributes({
  grpIndex,
  attributeIndex,
  control,
  editDisabled,
  isCustomTables,
  enableHiddenAttribute,
  isEditMode,
  triggerValidation,
}: RepeatingProps) {
  const { t } = useTranslation();

  const {
    fields: repeatingAttributes,
    append,
    remove,
  } = useFieldArray({
    control,
    name: `workflowConfig.${grpIndex}.attribute.${attributeIndex}.repeatingValue`,
  });

  return (
    <>
      <Grid item xs={12}>
        <Button
          size={'small'}
          id={'addEditRow'}
          onClick={() => {
            append(newRepeatingValue);
          }}
          disabled={editDisabled}
        >
          {isCustomTables
            ? t('pages.attributes.addColumn')
            : t('pages.attributes.addField')}
        </Button>
      </Grid>
      {repeatingAttributes.map(
        (repeatingAttribute, repeatingAttributeIndex) => (
          <RepeatingAttribute
            triggerValidation={triggerValidation}
            key={repeatingAttribute.id}
            attr={repeatingAttribute}
            grpIndex={grpIndex}
            attributeIndex={attributeIndex}
            repeatingAttributeIndex={repeatingAttributeIndex}
            editDisabled={editDisabled}
            control={control}
            remove={remove}
            isCustomTables={isCustomTables}
            enableHiddenAttribute={enableHiddenAttribute}
            isEditMode={isEditMode}
          />
        )
      )}
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </>
  );
}

export default RepeatingAttributes;
