import { useEffect, useMemo, useState } from 'react';

import { Grid } from '@mui/material';
import {
  Contacts,
  ContactMail,
  Link as RelationshipLink,
  OpenInNew,
  History,
  Savings,
  Work,
} from '@mui/icons-material';
import { Theme } from '@mui/system';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import Dialog from '@revenue-solutions-inc/revxcoreui/material/controls/Dialog';
import { ConfigurationDomains } from 'common/platformConfigUtils/platformConfigUtils';
import EntityManagementContext from 'components/contexts/EntityManagement';
import AddLetterInfo from 'components/correspondenceAndHeaderFooter/letter/AddLetterInfo';
import LetterHistory from 'components/correspondenceAndHeaderFooter/letter/LetterHistory';
import DataCard from 'components/DataCard';
import { buildEntityForm } from 'components/entityManagement/common/entityUtils';
import { OtherFields } from 'components/entityManagement/common/fields/entities';
import {
  getIdFormats,
  getMaskRules,
  getValidationRules,
} from 'components/entityManagement/common/formatValidations';
import { SourceType } from 'components/entityManagement/common/relationshipUtils';
import WorkflowModal from 'components/entityManagement/common/WorkflowModal/WorkflowModal';
import CreateRelationship from 'components/entityManagement/relationships/CreateRelationship';
import RelationshipList from 'components/entityManagement/relationships/RelationshipList';
import UnderConstruction from 'components/UnderConstruction';
import { WorkHistoryTable } from 'components/Workflow/WorkHistoryTable/WorkHistoryTable';
import { SuppressionsTable } from 'components/Workflow/SuppressionsTable/SuppressionsTable';
import {
  Entity,
  useGetLookupConfigurationQuery,
  useGetWorkflowsByContextTypeQuery,
  useGetSuppressionsByContextsQuery,
} from 'generated/graphql';
import { useFormatWorkflowDataForTable } from 'hooks/useFormatWorkflowDataForTable';
import { useFormatSuppressionsDataTable } from 'components/Workflow/SuppressionsTable/useFormatSuppressionsDataTable';
import { useHasAccess } from 'hooks/useHasAccess';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { WorkflowContextType } from 'types/WorkflowContext';
import { formatIdentifier } from 'utils/formatIdentifier';

import AccountList from '../AccountList';
import EntityDetail from '../EntityDetail';
import EntitySectionView from '../EntitySectionView';
import EntityPayments from '../EntityPayments';
import ReportsLogic from '../../../ReportsLogic';
import EntityAuditHistory from '../../../common/EntityAuditHistory';

interface Props {
  data: Entity;
  refetchData: () => void;
}

const openModalTheme = (theme: Theme) => ({
  fill: theme.palette.primary.main,
  cursor: 'pointer',
  '&:hover': { backgroundColor: theme.palette.secondary.main },
});

const setIconColor = (theme: Theme) => ({ fill: theme.palette.primary.main });

function EntityView({ data, refetchData }: Props): JSX.Element {
  const MAIN_CONFIG_DOMAIN = ConfigurationDomains.ReferenceSchema;
  const canEdit = useHasAccess('maintainEntity', 'edit');
  const { formatDataForTable } = useFormatWorkflowDataForTable();
  const { t } = useTranslation();
  const [openEntityData, setOpenEntityData] = useState<boolean>(false);
  const [openRelationships, setOpenRelationships] = useState<boolean>(false);
  const [isHistoryRefetch, setIsHistoryRefetch] = useState<boolean>(false);
  const [refetchRelationships, setRefetchRelationships] =
    useState<boolean>(false);
  const [addWorkflowModalStatus, setAddWorkflowModalStatus] = useState<
    boolean | string
  >(false);

  const { data: configuredIdTypes } = useGetLookupConfigurationQuery({
    configurationDomain: MAIN_CONFIG_DOMAIN,
    configurationType: 'IdType',
  });

  const { data: entityOrganizationTypeData } = useGetLookupConfigurationQuery({
    configurationDomain: MAIN_CONFIG_DOMAIN,
    configurationType: 'EntityToOrganizationTypes',
  });

  const { data: entityWorkflowsData } = useGetWorkflowsByContextTypeQuery(
    {
      contextId: data.id as string,
      contextType: WorkflowContextType[WorkflowContextType.Entity],
    },
    { enabled: !!data?.id }
  );
  const entityWorkflowsTableData = formatDataForTable(
    entityWorkflowsData?.GetWorkflowsByContextType ?? []
  );

  const { data: entitySuppressionsData } = useGetSuppressionsByContextsQuery(
    {
      contextId: data.id as string,
      contextType: WorkflowContextType[WorkflowContextType.Entity],
    },
    { enabled: !!data?.id }
  );
  const entitySuppressionsTableData = useFormatSuppressionsDataTable(
    entitySuppressionsData?.GetSuppressionsByContexts ?? []
  );

  const handleRelationshipRefetch = () => {
    setRefetchRelationships(!refetchRelationships);
  };

  const getPrimaryName = (): string => {
    const formData = buildEntityForm(data);
    const primaryName = formData?.names?.find(
      (name) => name.isPrimary === 'true'
    );
    return primaryName !== undefined && primaryName.value
      ? primaryName.value
      : '';
  };

  const getFormattedPrimaryId = (): string => {
    if (
      configuredIdTypes &&
      data.primaryIdentifier &&
      data.primaryIdentifierType
    ) {
      return formatIdentifier(
        data.primaryIdentifier,
        getIdFormats(configuredIdTypes),
        data.primaryIdentifierType
      );
    }
    return '';
  };

  useEffect(() => {
    const organizationType = OtherFields.fields.find(
      (field) => field.fieldIdentifier === 'organizationType'
    );
    if (!organizationType) return;
    const found = entityOrganizationTypeData?.GetLookupConfiguration.find(
      (type) =>
        type.configurationName.toUpperCase() === data.entityType?.toUpperCase()
    );
    if (found) {
      organizationType.hidden = false;
    } else {
      organizationType.hidden = true;
    }
  });

  useEffect(() => {}, [isHistoryRefetch]);

  const createAccountBtn = (): JSX.Element => {
    if (!canEdit) return <></>;
    return (
      <Link
        style={{ width: '-webkit-fill-available', textDecoration: 'none' }}
        to={{
          pathname: 'account/create',
        }}
      >
        <Button id="create-account-link" type="secondary" variant="text">
          {t('pages.createAccount.buttonTitle')}
        </Button>
      </Link>
    );
  };

  const createRelationshipBtn = (): JSX.Element => {
    return (
      <Button
        id="create-relationship-btn"
        type="secondary"
        variant="text"
        onClick={() => setOpenRelationships(!openRelationships)}
      >
        {t('pages.createAccount.buttonTitle')}
      </Button>
    );
  };

  const createWorkflowBtn = (): JSX.Element => {
    if (!canEdit) return <></>;
    return (
      <Button
        id="create-workflow-link"
        type="secondary"
        variant="text"
        onClick={() => setAddWorkflowModalStatus(true)}
      >
        {t('pages.createAccount.buttonTitle')}
      </Button>
    );
  };

  const createSuppressionBtn = (): JSX.Element => {
    return (
      <Button
        id="create-suppression-link"
        type="secondary"
        variant="text"
        onClick={() => setAddWorkflowModalStatus('suppression')}
      >
        {t('pages.createAccount.buttonTitle')}
      </Button>
    );
  };

  const activeTemplatesBtn = (): JSX.Element => {
    return (
      <AddLetterInfo
        isFromTemplate={true}
        isHistoryRefetch={isHistoryRefetch}
        setIsHistoryRefetch={setIsHistoryRefetch}
        entityId={data.id ?? ''}
        isFromEntity={true}
        accountId={''}
      />
    );
  };

  /**
   * Provides the needed values to the Entity/Asset Context
   */
  const contextValue = useMemo(() => {
    return {
      selectedType: data.entityType ?? '',
      selectedIdType: '',
      selectedCommenceDate: data.commenceDate ?? '',
      onTypeChange: () => {},
      onIdTypeChange: () => {},
      onCommenceDateChange: () => {},
      idValidationRules: getValidationRules(configuredIdTypes),
      idFormats: getIdFormats(configuredIdTypes),
      idMaxLengths: getMaskRules(configuredIdTypes),
    };
  }, [data, configuredIdTypes]);

  const GetFullEntityDetail = (): JSX.Element => {
    return (
      <EntityManagementContext.Provider value={contextValue}>
        <EntitySectionView
          data={data}
          refetchData={refetchData}
          entityFormData={buildEntityForm(data)}
        />
      </EntityManagementContext.Provider>
    );
  };

  return (
    <Grid container spacing={3}>
      <Grid sx={{ display: 'flex' }} item xs={12} md={6} lg={3}>
        <DataCard
          title={t('pages.entitySummary.sectionTitles.entityDetail')}
          avatar={<Contacts sx={setIconColor} />}
          action={
            <button
              id="open-entity-detail-modal-button"
              onClick={() => setOpenEntityData(!openEntityData)}
              style={{
                color: '#1B75E2',
                border: '1px solid #1B75E2',
                borderRadius: '4px',
              }}
            >
              <OpenInNew
                data-testid="open-entity-detail-modal"
                sx={openModalTheme}
                onClick={() => setOpenEntityData(!openEntityData)}
              />
            </button>
          }
          children={<EntityDetail entityData={buildEntityForm(data)} />}
        />
        <Dialog
          id="all-entity-data"
          type="passive"
          title={t('pages.entitySummary.sectionTitles.entityDetail')}
          open={openEntityData}
          handleClose={() => setOpenEntityData(!openEntityData)}
          height={580}
          maxWidth="xl"
        >
          {<GetFullEntityDetail />}
        </Dialog>
      </Grid>
      <Grid sx={{ display: 'flex' }} item xs={12} md={6} lg={3}>
        <DataCard
          title={t('pages.entitySummary.sectionTitles.liabilitySummary')}
          avatar={<History sx={setIconColor} />}
          children={<UnderConstruction setFpo={true} />}
        />
      </Grid>
      <Grid sx={{ display: 'flex' }} item xs={12} lg={6}>
        <DataCard
          title={t('pages.entitySummary.sectionTitles.correspondence')}
          avatar={<ContactMail sx={setIconColor} />}
          action={activeTemplatesBtn()}
          children={
            <LetterHistory
              isHistoryRefetch={isHistoryRefetch}
              entityId={data.id ?? ''}
              isFromEntity={true}
              accountId={''}
            />
          }
        />
      </Grid>
      <Grid sx={{ display: 'flex' }} item xs={12} lg={6}>
        <DataCard
          title={t('pages.entitySummary.sectionTitles.accounts')}
          avatar={<Savings sx={setIconColor} />}
          action={createAccountBtn()}
          children={<AccountList entityId={data.id} dataEntity={data} />}
        />
      </Grid>
      <Grid sx={{ display: 'flex' }} item xs={12} lg={6}>
        <DataCard
          title={t('pages.entitySummary.sectionTitles.relationships')}
          avatar={<RelationshipLink sx={setIconColor} />}
          action={createRelationshipBtn()}
          children={
            <RelationshipList
              id={data.id}
              sourceType={data.entityType ?? ''}
              sourceName={getPrimaryName()}
              sourceId={getFormattedPrimaryId()}
              executeRefetch={refetchRelationships}
            />
          }
        />
      </Grid>
      <Dialog
        id="relationships-modal-form"
        type="passive"
        title={t('pages.entitySummary.sectionTitles.relationships')}
        open={openRelationships}
        handleClose={() => setOpenRelationships(!openRelationships)}
        height={380}
        maxWidth="lg"
      >
        <CreateRelationship
          sourceInfoId={data.id ?? ''}
          sourceType={data.entityType ?? ''}
          generalSourceType={SourceType.Entity}
          refetchRelationships={handleRelationshipRefetch}
        />
      </Dialog>
      <Grid sx={{ display: 'flex' }} item xs={12} lg={6}>
        <DataCard
          title={t('pages.entitySummary.sectionTitles.workflows')}
          avatar={<Work sx={setIconColor} />}
          children={
            <WorkHistoryTable tableData={entityWorkflowsTableData ?? []} />
          }
          action={createWorkflowBtn()}
        />
      </Grid>
      <Grid sx={{ display: 'flex' }} item xs={12} lg={6}>
        <EntityPayments />
      </Grid>
      <Grid sx={{ display: 'flex' }} item xs={12} lg={6}>
        <DataCard
          title={t('pages.entitySummary.sectionTitles.auditHistory')}
          avatar={<History sx={setIconColor} />}
          children={<EntityAuditHistory tenantId={data.tenantId ?? ''} />}
        />
      </Grid>
      <Grid sx={{ display: 'flex' }} item xs={12} lg={6}>
        <DataCard
          title={t('pages.accountSummary.sectionTitles.suppressions')}
          avatar={<Work sx={setIconColor} />}
          children={
            <SuppressionsTable tableData={entitySuppressionsTableData ?? []} />
          }
          action={createSuppressionBtn()}
        />
      </Grid>
      <Grid sx={{ display: 'flex' }} item xs={12} lg={6} mt={3}>
        <ReportsLogic id={data?.primaryIdentifier} contextualTypeId={2} />
      </Grid>
      <WorkflowModal
        selectedGroupName={
          typeof addWorkflowModalStatus === 'string'
            ? addWorkflowModalStatus
            : undefined
        }
        open={!!addWorkflowModalStatus}
        onClose={() => setAddWorkflowModalStatus(false)}
        contextId={data.id ?? ''}
        contextType={WorkflowContextType.Entity}
      />
    </Grid>
  );
}

export default EntityView;
