import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DataCard from 'components/DataCard';
import PaymentsIcon from '@mui/icons-material/Payments';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import {
  FinancialTransaction,
  PaymentApplication,
  useGetPaymentTransactionsByIdQuery,
} from 'generated/graphql';
import { Box, Grid } from '@mui/material';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import { useGetFinancialTransactionColumns } from 'pages/ViewFinancialTransGroup/ViewFinancialTransGroup';
import { ColumnDef } from '@tanstack/table-core';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import DateCell from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/TableCells/DateCell';
import { transactionColumns } from 'components/entityManagement/transactions/FinancialTransactionDetails/FinancialTransactionDetails';
import ReversePayment from 'components/entityManagement/transactions/ReversePayment';

import TransferPayment from 'components/entityManagement/transactions/TransferPayment/TransferPayment';
import { useTheme } from '@mui/system';

interface PaymentDetailsProps {
  paymentId: string;
  canReversePayment: boolean;
}

function PaymentTransactionsDataCard({
  paymentId,
  canReversePayment,
}: PaymentDetailsProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [paymentTransactionList, setPaymentTransactionList] = useState<
    FinancialTransaction[]
  >([]);
  const [paymentApplicationList, setPaymentApplicationList] = useState<
    PaymentApplication[]
  >([]);

  const { data: paymentTransactions } = useGetPaymentTransactionsByIdQuery({
    paymentId: paymentId,
  });

  const buildTransactionList = useCallback(() => {
    if (
      paymentTransactions &&
      paymentTransactions.GetPaymentTransactionsById.financialTransactions
    ) {
      setPaymentTransactionList(
        paymentTransactions.GetPaymentTransactionsById.financialTransactions
      );
    }
  }, [paymentTransactions]);

  const buildPaymentApplicationList = useCallback(() => {
    if (
      paymentTransactions &&
      paymentTransactions.GetPaymentTransactionsById.application
    ) {
      setPaymentApplicationList(
        paymentTransactions.GetPaymentTransactionsById.application
      );
    }
  }, [paymentTransactions]);

  useEffect(() => {
    buildTransactionList();
    buildPaymentApplicationList();
  }, [buildPaymentApplicationList, buildTransactionList]);

  const columns: ColumnDef<Record<string, unknown>>[] = [
    {
      id: 'accountType',
      accessorKey: 'accountType',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.paymentDetails.application.accountType')}
        />
      ),
    },
    {
      id: 'financialTransactionGroupType',
      accessorKey: 'financialTransactionGroupType',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.paymentDetails.application.groupType')}
        />
      ),
    },
    ...transactionColumns,
    {
      id: 'periodType',
      accessorKey: 'periodType',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.paymentDetails.application.periodType')}
        />
      ),
    },
    {
      id: 'periodBeginDate',
      accessorKey: 'periodBeginDate',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.paymentDetails.application.periodBeginDate')}
        />
      ),
      cell: ({ getValue }) => <DateCell dateString={getValue() as string} />,
    },
    {
      id: 'periodEndDate',
      accessorKey: 'periodEndDate',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.paymentDetails.application.periodEndDate')}
        />
      ),
      cell: ({ getValue }) => <DateCell dateString={getValue() as string} />,
    },
    {
      id: 'transfer-payment',
      header: () => (
        <HeaderColumnNext localization={t('pages.paymentDetails.transfer')} />
      ),
      cell: ({ row }) => {
        return (
          <TransferPayment
            paymentId={paymentId}
            paymentAmount={row.original.amount as string}
            paymentPeriodId={row.original.financialAccountPeriodId as string}
          />
        );
      },
    },
  ];

  const getApplicationDetailsFlat = () => {
    let flat: Record<string, unknown>[] = [];
    if (paymentApplicationList.length > 0) {
      flat = paymentApplicationList.flatMap(({ details, ...o }) =>
        details ? details.map((p) => ({ ...o, ...p })) : []
      );
    }
    return flat;
  };

  const transactionsChildren = (
    <Box sx={paymentTransactionList.length === 0 ? { display: 'none' } : {}}>
      <DefaultDataTableNext
        columns={useGetFinancialTransactionColumns(
          t('pages.paymentDetails.title')
        )}
        data={paymentTransactionList}
        enableGlobalFilter={false}
      />
    </Box>
  );

  const currentApplicationChildren = (
    <Box sx={paymentApplicationList.length === 0 ? { display: 'none' } : {}}>
      <DefaultDataTableNext
        columns={columns}
        data={getApplicationDetailsFlat()}
        enableGlobalFilter={false}
      />
    </Box>
  );

  return (
    <Grid item xs={12} md={12} lg={9}>
      <DataCard
        title={t('pages.paymentDetails.transactions')}
        avatar={<PaymentsIcon sx={{ fill: theme.palette.primary.main }} />}
        action={
          canReversePayment ? <ReversePayment paymentId={paymentId} /> : <></>
        }
        children={
          paymentTransactionList.length > 0
            ? transactionsChildren
            : t('pages.paymentDetails.noTransactions')
        }
      />
      <Grid item mt={3}>
        <DataCard
          title={t('pages.paymentDetails.currentApplication')}
          avatar={
            <AccountBalanceIcon sx={{ fill: theme.palette.primary.main }} />
          }
          children={
            paymentApplicationList.length > 0
              ? currentApplicationChildren
              : t('pages.paymentDetails.noInfo')
          }
        />
      </Grid>
    </Grid>
  );
}
export default PaymentTransactionsDataCard;
