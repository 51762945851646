import { useRef } from 'react';

import { useTheme } from '@mui/system';
import { ConfigurationDomains } from 'common/platformConfigUtils/platformConfigUtils';
import { getAccountPrimaryItem } from 'components/entityManagement/common/accountUtils';
import { getAssetPrimaryItem } from 'components/entityManagement/common/assetUtils';
import { getEntityPrimaryItem } from 'components/entityManagement/common/entityUtils';
import { getIdFormats } from 'components/entityManagement/common/formatValidations';
import {
  getDestinationType,
  LinkTypes,
} from 'components/entityManagement/common/relationshipUtils';
import {
  Attribute,
  useGetAccountByIdQuery,
  useGetAssetByIdQuery,
  useGetEntityByIdQuery,
  useGetLookupConfigurationQuery,
} from 'generated/graphql';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { formatIdentifier } from 'utils/formatIdentifier';

interface Props {
  id: string;
  section: string;
  linkType: number;
  onlyText?: boolean;
}

function IdentifierLink({
  id,
  section,
  linkType,
  onlyText = false,
}: Props): JSX.Element {
  const CORE_DOMAIN = ConfigurationDomains.ReferenceSchema;
  const module = useAppSelector((state) => state.user.module);
  const theme = useTheme();
  const primaryAttribute = useRef<Attribute[]>([]);

  useGetEntityByIdQuery(
    { entityId: id },
    {
      enabled:
        linkType === LinkTypes.EntityToEntity ||
        linkType === LinkTypes.AssetToEntity,
      onSuccess: (entityData) => {
        primaryAttribute.current = getEntityPrimaryItem(entityData, section);
      },
    }
  );

  useGetAccountByIdQuery(
    { accountId: id },
    {
      enabled: linkType === LinkTypes.EntityToAccount,
      onSuccess: (accountData) => {
        primaryAttribute.current = getAccountPrimaryItem(accountData, section);
      },
    }
  );

  useGetAssetByIdQuery(
    { assetId: id },
    {
      enabled:
        linkType === LinkTypes.EntityToAsset ||
        linkType === LinkTypes.AssetToAsset,
      onSuccess: (assetData) => {
        primaryAttribute.current = getAssetPrimaryItem(assetData, section);
      },
    }
  );

  const { data: configuredIdTypes } = useGetLookupConfigurationQuery({
    configurationDomain: CORE_DOMAIN,
    configurationType: 'IdType',
  });

  const getPrimary = (): string => {
    if (configuredIdTypes) {
      const idValue =
        primaryAttribute.current.find(
          (attr) =>
            attr.attributeName === 'value' ||
            attr.attributeName === 'AccountName'
        )?.attributeValue ?? '';
      const idType =
        primaryAttribute.current.find((attr) => attr.attributeName === 'IdType')
          ?.attributeValue ?? '';
      if (section !== 'identifiers') return idValue;
      return formatIdentifier(idValue, getIdFormats(configuredIdTypes), idType);
    }
    return '';
  };

  if (primaryAttribute.current.length > 0) {
    return !onlyText ? (
      <Link
        to={`/${module}/${getDestinationType(linkType)}/${id}`}
        style={{ color: theme.palette.linkBlue.dark }}
      >
        {getPrimary()}
      </Link>
    ) : (
      <>{getPrimary()}</>
    );
  }
  return <>-</>;
}

export default IdentifierLink;
