import { useMemo, useEffect, useReducer, useState } from 'react';
import { formatObject } from 'utils/object-utils';
import { Box, Checkbox } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  useGetWorkflowAssignedByQueueQuery,
  useUpdateVariantOwnerNameMutation,
  UpdateVariantOwnerNameInput,
  WorkflowItem,
} from 'generated/graphql';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import Error from 'components/Error';
import { ColumnDef, Row } from '@tanstack/react-table';
import { useQueryClient } from '@tanstack/react-query';
import { MessageType } from '@revenue-solutions-inc/revxcoreui';
import { addMessage } from 'redux/messageSlice';
import { useTranslation } from 'react-i18next';
import { getFormatDate } from 'utils/date-util';
import { formatWorkflowDataForTable } from 'components/AssignedUnassignedWork/tableUtils';
import WorkManagerActionsCard, {
  AssignmentActions,
} from 'components/WorkManagerActionsCard/WorkManagerActionsCard';
import Loading from 'components/Loading';
import NoData from 'components/NoData';
import {
  RowDataDrawer,
  WorkflowDataDetailsType,
} from 'components/RowDataDisplay/RowDataDrawer';
import { WorkHistoryTable } from 'components/Workflow/WorkHistoryTable/WorkHistoryTable';
import {
  initialState,
  actions,
  workManagerTableReducer,
} from './workManagerTableReducer';

export const WorkManagerTable = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const module = useAppSelector((state) => state.user.module);
  const { data, isLoading, isError, error } =
    useGetWorkflowAssignedByQueueQuery({
      module: module.charAt(0).toUpperCase() + module.slice(1),
    });

  const [state, localDispatch] = useReducer(
    workManagerTableReducer,
    initialState
  );
  const queryClient = useQueryClient();

  const { mutate: updateVariantOwnerName } =
    useUpdateVariantOwnerNameMutation();

  // memoized array of data objects fetched from API
  const tableD = useMemo(() => {
    return formatWorkflowDataForTable(
      data?.GetWorkflowAssignedByQueue?.map(
        ({ queueName, workflowObject }) => ({
          ...workflowObject,
          queueName,
        })
      ) as WorkflowItem[] | undefined
    );
  }, [data?.GetWorkflowAssignedByQueue]);

  const [selectedWorkflow, setSelectedWorkflow] = useState<
    WorkflowDataDetailsType | undefined
  >();

  const toggleSelectAll = () => {
    localDispatch({
      type: actions.TOGGLE_SELECT_ALL,
      payload: {
        selectAllChecked: !state.selectAllChecked,
        selectedRows: !state.selectAllChecked
          ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
            tableD.map((r: any) => r.Workflow_Key)
          : [],
      },
    });
  };

  // Exporting all data to a .csv file
  //TODO: update this function to match changed display card data values
  const exportWorkManagerDataXML = () => {
    const keys = [
      `${t('pages.workManager.workManagerTable.workflow_Key')},\
      ${t('pages.workManager.workManagerTable.queueName')},\
      ${t('pages.workManager.workManagerTable.variantGroupCode')},\
      ${t('pages.workManager.workManagerTable.workflowVariantCode')},\
      ${t('pages.workManager.workManagerTable.priorityIndicator')},\
      ${t('pages.workManager.workManagerTable.variantStartDate')},\
      ${t('pages.workManager.workManagerTable.variantEndDate')},\
      ${t('pages.workManager.workManagerTable.state')},\
      ${t('components.rowDisplay.description')},\
      ${t('components.rowDisplay.createdBy')},\
      ${t('components.rowDisplay.createdDate')},\
      ${t('components.rowDisplay.owner')},\
      ${t('components.rowDisplay.queueName')},\
      ${t('components.rowDisplay.status')},\
      ${t('components.rowDisplay.closedDate')},\
      ${t('components.rowDisplay.assignedBy')},\
      ${t('components.rowDisplay.assignedDate')},\
      ${t('components.rowDisplay.entityName')}`,
    ];
    const values = tableD?.reduce(
      (acc: Array<string>, curr: Record<string, unknown>) => {
        acc.push(
          [
            curr.DisplayId,
            curr.QueueName,
            curr.VariantGroupCode,
            curr.WorkflowVariantCode,
            curr.PriorityIndicator,
            getFormatDate(new Date(curr.VariantStartDate as Date)),
            getFormatDate(new Date(curr.VariantEndDate as Date)),
            curr.State,
            curr.WorkflowVariantDescription,
            curr.CreatedBy,
            getFormatDate(new Date(curr.CreatedDate as Date)),
            curr.VariantOwnerName,
            curr.QueueName,
            /**
              TODO: Add in missing values to export once they are included in return object
               ClosedDate, AssignedBy, AssignedDate, EntityName, Status,        
             * */
          ].join(',')
        );
        return acc;
      },
      []
    );
    const url = window.URL.createObjectURL(
      new Blob([[...keys, ...(values ?? [])].join('\n')], { type: 'text/csv' })
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `Work Manager - ${getFormatDate(new Date())}.csv`
    );
    document.body.appendChild(link);
    link.click();
  };

  const handleAssignment = (action: string) => () => {
    if (state.selectedRows.length) {
      let payload: UpdateVariantOwnerNameInput[] = [];
      if (
        action === AssignmentActions.ASSIGN &&
        state.selectedUser !== undefined
      ) {
        payload = state.selectedRows.map(
          (row) =>
            ({
              workflowId: +row,
              variantOwnerName: state.selectedUser,
            } as UpdateVariantOwnerNameInput)
        );
      } else if (action === AssignmentActions.UNASSIGN) {
        payload = state.selectedRows.map(
          (row) =>
            ({
              workflowId: +row,
              variantOwnerName: '',
            } as UpdateVariantOwnerNameInput)
        );
      }

      updateVariantOwnerName(
        {
          payload,
        },
        {
          onSuccess: () => {
            dispatch(
              addMessage({
                type: MessageType.Success,
                message:
                  action === AssignmentActions.UNASSIGN
                    ? t('pages.workManager.messages.unassignSuccess')
                    : t('pages.workManager.messages.assignSuccess'),
              })
            );
            queryClient.invalidateQueries({
              queryKey: ['GetWorkflowAssignedByQueue'],
            });
            localDispatch({
              type: actions.ON_UPDATE_VARIANT_OWNER_SUCCESS,
            });
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onError: () => {
            dispatch(
              addMessage({
                type: MessageType.Error,
                message:
                  action === AssignmentActions.UNASSIGN
                    ? t('pages.workManager.messages.unassignError')
                    : t('pages.workManager.messages.assignError'),
              })
            );
          },
        }
      );
    }
  };

  // Table Column Array Definition
  const customColumns: ColumnDef<Record<string, unknown>>[] = useMemo(() => {
    const onSelectRow = (row: Row<Record<string, unknown>>) => () => {
      const id = row?.original.Workflow_Key;
      const selectedRowsList = state.selectedRows.includes(id as string)
        ? state.selectedRows.filter((r) => r !== id)
        : [...state.selectedRows, id];
      localDispatch({
        type: actions.SET_SELECTED_ROWS,
        payload: {
          selectedRows: selectedRowsList,
          selectAllChecked: selectedRowsList.length === tableD.length,
        },
      });
    };
    return [
      {
        id: 'Checkbox_column',
        header: () => (
          <Checkbox
            checked={state.selectAllChecked}
            id="work-management-select-all"
            onClick={toggleSelectAll}
          />
        ),
        cell: ({ row }) => {
          return (
            <Checkbox
              checked={
                state.selectAllChecked ||
                state.selectedRows.includes(row.original.Workflow_Key as string)
              }
              id={row.id}
              onClick={(e) => {
                onSelectRow(row)();
                e.stopPropagation();
              }}
            />
          );
        },
      },
    ];
  }, [t, state.selectedRows, state.selectAllChecked]); // eslint-disable-line react-hooks/exhaustive-deps

  const setUser = (user: string | undefined) => {
    if (user) {
      const regExp = /\(([^)]+)\)/;
      const cleanUser = regExp.exec(user);
      const email = cleanUser && cleanUser[1];
      localDispatch({
        type: actions.SET_SELECTED_USER,
        payload: email,
      });
    } else {
      localDispatch({
        type: actions.SET_SELECTED_USER,
        payload: user,
      });
    }
  };

  useEffect(() => {
    if (state.selectedRows.length === 0) {
      localDispatch({
        type: actions.SET_SELECTED_USER,
        payload: undefined,
      });
    }
  }, [state.selectedRows.length]);

  if (isError) {
    //this is a hotfix and will be removed when the error responding back end is changed
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (error && (error as any)[0].extensions.response.status === 400) {
      return <NoData />;
    }
    return <Error />;
  }

  if (isLoading) {
    return <Loading />;
  }

  if (!tableD || tableD?.length === 0) {
    return <NoData />;
  }

  return (
    <Box
      data-testid="work-manager-table"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: '1em',
      }}
    >
      <WorkHistoryTable
        tableName={t('pages.workManager.workManagerTable.workManagerTitle')}
        cursorHover={true}
        tableData={tableD}
        customColumns={customColumns}
        onRowClick={({ original }) =>
          setSelectedWorkflow(formatObject(original))
        }
        customHeader={
          <Button
            onClick={() => exportWorkManagerDataXML()}
            id="export-btn"
            data-testid="export-btn"
            type="secondary"
            sx={{ ml: 2, mt: 1, minWidth: '128px', order: 3 }}
          >
            {t('pages.workManager.export')}
          </Button>
        }
      />
      <RowDataDrawer
        title={`Work ${selectedWorkflow?.displayId ?? ''}`}
        open={!!selectedWorkflow}
        data={selectedWorkflow}
        onClose={() => setSelectedWorkflow(undefined)}
      />
      {state.selectedRows.length > 0 && (
        <WorkManagerActionsCard
          handleAssignment={handleAssignment}
          setUser={setUser}
          selectedUser={state.selectedUser}
        />
      )}
    </Box>
  );
};
