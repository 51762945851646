import { Section } from 'types/forms';

export const RelationshipFields: Section = {
  sectionTitle: 'Relationships',
  sectionIdentifier: 'relationships',
  fields: [
    {
      label: 'Relationship Type',
      fieldIdentifier: 'relationshipType',
      type: 'datasource',
      datasource: 'RelationshipType',
      rules: { required: 'Relationship type is required' },
      isRequired: true,
      requiredErrorMessage: 'Relationship type is required',
    },
    {
      label: 'Commence Date',
      fieldIdentifier: 'commenceDate',
      type: 'date',
      rules: { required: 'Commence date is required' },
      isRequired: true,
      requiredErrorMessage: 'Commence date is required',
    },
    {
      label: 'Cease Date',
      fieldIdentifier: 'ceaseDate',
      type: 'date',
    },
  ],
};
