import { useState } from 'react';

import { useFieldArray, Control, UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from '@revenue-solutions-inc/revxcoreui';
import { Box, Typography, Stack } from '@mui/material';
import { Attribute } from 'generated/graphql';
import { WorkflowCondition } from './WorkflowCondition';
import {
  WorkflowCondition as TWorkflowCondition,
  WorkflowFormObject,
} from '../WorkflowEditor.types';

interface Props {
  stepIndex: number;
  control: Control<WorkflowFormObject, null>;
  disabled?: boolean;
  customAttributes: Attribute[];
  setValue: UseFormSetValue<WorkflowFormObject>;
}

const defaultWokflowCondition: TWorkflowCondition = {
  condition: {
    attributeDisplayName: 'condition',
    attributeName: 'Condition',
    attributeValue: '',
    dataSource: '',
  },
  source: {
    attributeDisplayName: 'source',
    attributeName: 'Source Parameter',
    attributeValue: '',
    dataSource: '',
  },
  target: {
    attributeDisplayName: 'target',
    attributeName: 'Target Parameter',
    attributeValue: '',
    dataSource: '',
  },
  targetValue: {
    attributeDisplayName: 'targetValue',
    attributeName: 'Target Value',
    attributeValue: '',
    dataSource: '',
  },
};

export const WorkflowConditionsFields = ({
  stepIndex,
  control,
  disabled,
  customAttributes,
  setValue,
}: Props) => {
  const { t } = useTranslation();
  const sourceOptions = (customAttributes as Attribute[]).map((attr) => {
    return {
      desc: attr.attributeDisplayName,
      key: attr.attributeName,
    };
  });

  const targetOptions = [{ key: 'val', desc: 'Value' }, ...sourceOptions];

  const { fields, append, remove } = useFieldArray({
    control,
    name: `workflowSteps.${stepIndex}.workflowConditions`,
  });

  const [disableValue, setDisableValue] = useState<boolean[]>(
    fields.map((field) => {
      return field.target.attributeValue !== 'val';
    })
  );

  const appendRow = () => {
    setDisableValue((old) => {
      return [...old, true];
    });
    append(defaultWokflowCondition);
  };
  return (
    <>
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between' }}
        marginTop={2}
      >
        <Typography fontWeight={'bold'}>
          {t('pages.workflowEditor.conditionsFields.conditions')}
        </Typography>
        <Button
          id={'add-condition-' + stepIndex}
          size="small"
          sx={{ minWidth: '8em' }}
          onClick={() => {
            appendRow();
          }}
          disabled={disabled}
        >
          {t('pages.workflowEditor.addCondition')}
        </Button>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Stack spacing={2}>
          {fields.map((condition, conditionIndex) => {
            if (condition.target.attributeValue !== 'val') {
              condition.targetValue.attributeValue = '';
            }

            return (
              <WorkflowCondition
                key={condition.id}
                control={control}
                conditionIndex={conditionIndex}
                condition={condition}
                stepIndex={stepIndex}
                remove={remove}
                targetOptions={targetOptions}
                sourceOptions={sourceOptions}
                setDisableValue={setDisableValue}
                disableValue={disableValue}
                setValue={setValue}
                disabled={disabled}
              />
            );
          })}
        </Stack>
      </Box>
    </>
  );
};
