import { useEffect } from 'react';

import { Box } from '@mui/material';
import Loading from 'components/Loading';
import { useGetWorkflowsQuery } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { setHeader } from 'redux/contentSlice';
import { useAppDispatch } from 'redux/hooks';

import { WorkflowTable } from 'components/Workflow/WorkflowTable';

function Workflows(): JSX.Element {
  const { data, isLoading } = useGetWorkflowsQuery();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(setHeader({ pageTitle: t('pages.workflow.title') }));
  }, [dispatch, t]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Box py="16px">
        {data?.GetWorkflows && (
          <WorkflowTable
            data={data.GetWorkflows as Record<string, unknown>[]}
          />
        )}
      </Box>
    </>
  );
}

export default Workflows;
