import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { Box, IconButton, SelectChangeEvent, Stack } from '@mui/material';
import { Button } from '@revenue-solutions-inc/revxcoreui';
import { useTranslation } from 'react-i18next';
import { Attribute, Group } from 'generated/graphql';

import { deepCopy } from 'utils/deepCopy';
import AttributeInput from '../AttributeInput';
import AttributeDetails from '../AttributeDetails';
import { QueryVariablesAttributeInput } from '../AttributeInput/AttributeInput';

interface Props {
  schemaGroup: Group;
  group: Group;
  handleChange: (group: Group) => void;
  queriesVariables: QueryVariablesAttributeInput;
  editDisabled: boolean;
  expressionClickHandler?: (attr: Attribute) => void;
  errors?: { [key: string]: string };
}

function RepeatingAttributes({
  schemaGroup,
  group,
  handleChange,
  queriesVariables,
  editDisabled,
  expressionClickHandler = () => {},
  errors = {},
}: Props) {
  const { t } = useTranslation();

  const updateRepeatingAttributes = (attribute: Attribute) => {
    if (attribute.attributeType?.toLowerCase() != 'dynamicattribute') {
      let newVals = attribute?.repeatingValue?.filter((a) => {
        return schemaGroup.attribute.some(
          (at) => a.attributeName == at.attributeName
        );
      });
      if (!newVals) newVals = [];
      schemaGroup.attribute.forEach((schemaAttr) => {
        if (
          !newVals?.some((at) => at.attributeName == schemaAttr.attributeName)
        ) {
          newVals?.push(deepCopy(schemaAttr));
        }
      });

      attribute.repeatingValue = newVals;
    }
    return attribute.repeatingValue;
  };

  return (
    <>
      <Button
        sx={{ mt: '.5em', mb: '.5em' }}
        id={'addEditRow'}
        onClick={() => {
          if (group.attribute) {
            const tempAttributes = [...group.attribute];

            const tempAttr = { ...schemaGroup?.attribute[0] };
            tempAttr.repeatingValue = [];
            schemaGroup?.attribute.forEach((attr) =>
              tempAttr.repeatingValue?.push(deepCopy(attr))
            );
            tempAttributes.push(tempAttr);
            handleChange({ ...group, attribute: tempAttributes });
          }
        }}
        disabled={editDisabled || group.attribute.length > 50}
      >
        {group.repeatingActionLabel ?? t('components.button.add')}
      </Button>
      {group.attribute.map((attr, i) => (
        <Stack
          direction={'row'}
          flexWrap="wrap"
          mb="1.2em"
          key={`${attr.attributeName}-${attr.attributeType}-${i}`}
        >
          {
            //here we need to only display the attributes which are in the schema group
            // but also add the ones which are not here
            // so we loop through the attributes in the row , compare with the schema group to match and show the values of the ones which are in the row
            // go through all the attributes in schema and check if they are in repeating, if not, add, if deleted, remove
            updateRepeatingAttributes(attr)?.map((repAttr, j) => {
              if (attr.attributeType?.toLowerCase() === 'dynamicattribute') {
                return (
                  <Box
                    sx={{ p: '5px' }}
                    key={`${repAttr.attributeName}-${repAttr.attributeType}-${j}`}
                  >
                    <AttributeDetails
                      attribute={repAttr}
                      handleChange={(attrName, newAttr) => {
                        if (group) {
                          const tempAttributes = [...group.attribute];
                          const tempRepeat = tempAttributes[i].repeatingValue;
                          if (tempRepeat) {
                            tempRepeat[j] = newAttr;
                            handleChange({
                              ...group,
                              attribute: tempAttributes,
                            });
                          }
                        }
                        return true;
                      }}
                      handleDelete={function (): void {
                        throw new Error(
                          t('pages.configRecordEditor.noFunctionError')
                        );
                      }}
                      recordMode={true}
                    />
                  </Box>
                );
              } else
                return (
                  <AttributeInput
                    key={`${repAttr.attributeName}-${repAttr.attributeType}-${j}`}
                    attr={repAttr}
                    handleAttrChange={(
                      attribute: Attribute,
                      date?: Date | null,
                      event?:
                        | React.ChangeEvent<HTMLInputElement>
                        | SelectChangeEvent<string | number>,
                      checked?: boolean
                    ) => {
                      if (group) {
                        const tempAttributes = [...group.attribute];
                        const tempRepeat = tempAttributes[i].repeatingValue;

                        if (tempRepeat && date && checked === undefined) {
                          tempRepeat[j].attributeValue = date
                            .toISOString()
                            .split('T')[0];
                          handleChange({ ...group, attribute: tempAttributes });
                        }
                        if (tempRepeat && checked !== undefined) {
                          const value = checked === true ? 'true' : 'false';
                          tempRepeat[j].attributeValue = value;
                          handleChange({ ...group, attribute: tempAttributes });
                        }

                        if (tempRepeat && event && checked === undefined) {
                          if (!!tempRepeat[j].isExpression) {
                            tempRepeat[j].expressionValue = event.target
                              .value as string;
                          } else {
                            tempRepeat[j].attributeValue = event.target
                              .value as string;
                          }
                          handleChange({ ...group, attribute: tempAttributes });
                        }
                      }
                    }}
                    queriesVariables={queriesVariables}
                    editDisabled={editDisabled}
                    expressionClickHandler={expressionClickHandler}
                    // this is how we uniquely identify this repeating attribute (row and column) to retrieve its error message
                    error={errors[group.groupName + repAttr.attributeName + i]}
                  />
                );
            })
          }
          <IconButton
            onClick={() => {
              if (group) {
                const tempAttributes = [...group.attribute];
                tempAttributes.splice(i, 1);
                handleChange({ ...group, attribute: tempAttributes });
              }
            }}
            aria-label="delete"
            color="default"
            size="small"
            disabled={editDisabled}
          >
            <CancelRoundedIcon fontSize="small" />
          </IconButton>
        </Stack>
      ))}
    </>
  );
}

export default RepeatingAttributes;
