export function capitalizeFirstLetter(str: string) {
  if (!str) return '';

  // converting first letter to uppercase
  const capitalized = str.charAt(0).toUpperCase() + str.slice(1);
  return capitalized;
}

/**
 * converting first letter to lowercase
 * @param str
 * @returns string with first letter in lowercase or empty string
 */
export const lowerFirstLetter = (str?: string) =>
  str ? str.charAt(0).toLowerCase() + str.slice(1) : '';

/**
 * Retrive an array of emails contained on a string
 * @param {string} text user label format exam. John Doe (johndoe@domain.com)
 * @returns {string[]|undefined} email matches on the string
 */
export function getEmailsFromText(text: string) {
  return text.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi);
}
