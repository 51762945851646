import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Grid } from '@mui/material';
import Dialog from '@revenue-solutions-inc/revxcoreui/material/controls/Dialog';
import {
  DatePicker,
  Input,
  MessageActionType,
  MessageType,
  Select,
} from '@revenue-solutions-inc/revxcoreui';
import { useTranslation } from 'react-i18next';
import {
  ConfigurationResponse,
  PaymentDetailInput,
  useGetConfigurationRecordsQuery,
  useUpdatePaymentDetailsByIdMutation,
} from 'generated/graphql';
import { useAppDispatch } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import {
  Control,
  Controller,
  FieldValues,
  FormState,
  UseFormGetValues,
  UseFormTrigger,
} from 'react-hook-form';
import { getDate } from 'common/helpers';
import { ConfigurationModules } from 'common/platformConfigUtils/platformConfigUtils';
import { SelectType } from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import { useQueryClient } from '@tanstack/react-query';
import extractMeaningfulMessage from 'utils/errorMessage';

interface EditPaymentDetailsProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  paymentId: string;
  control: Control<FieldValues, unknown>;
  formState: FormState<FieldValues>;
  trigger: UseFormTrigger<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
}

function EditPaymentDetailsModal({
  open,
  setOpen,
  paymentId,
  control,
  formState,
  trigger,
  getValues,
}: EditPaymentDetailsProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const { isValid } = formState;
  const [idTypes, setIdTypes] = useState<SelectType[]>([]);
  const [accountTypes, setAccountTypes] = useState<SelectType[]>([]);

  const { mutate } = useUpdatePaymentDetailsByIdMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['GetPaymentDetailsById'],
      });
      setOpen(false);
      dispatch(
        addMessage({
          message: t('pages.paymentDetails.editPaymentDetailsSuccess'),
          type: MessageType.Success,
          actionType: MessageActionType.None,
        })
      );
    },
    onError: (error) => {
      const message = extractMeaningfulMessage(
        error,
        t('pages.paymentDetails.editPaymentDetailsError')
      );
      dispatch(
        addMessage({
          message: message,
          type: MessageType.Error,
          actionType: MessageActionType.None,
        })
      );
    },
  });

  const buildSelectList = (list: ConfigurationResponse[]) => {
    const options: SelectType[] = [];
    list.forEach((type) => {
      const optionType: SelectType = {
        key: type.configurationName,
        desc: type.configurationName,
      };
      options.push(optionType);
    });
    return options;
  };

  /* TODO: overFetching issue in this component */
  const { data: idTypesLookup, error } = useGetConfigurationRecordsQuery({
    configurationModule: ConfigurationModules.Platform.toString(),
    configurationType: 'IdType',
  });

  const { data: accountTypesLookup } = useGetConfigurationRecordsQuery({
    configurationModule: ConfigurationModules.Platform.toString(),
    configurationType: 'AccountType',
  });

  const buildIdTypes = useCallback(() => {
    if (idTypesLookup && idTypesLookup.getConfigurations && !error) {
      const options = buildSelectList(idTypesLookup.getConfigurations);
      setIdTypes(options);
    }
  }, [error, idTypesLookup]);

  const buildAccountTypes = useCallback(() => {
    if (accountTypesLookup && accountTypesLookup.getConfigurations && !error) {
      const options = buildSelectList(accountTypesLookup.getConfigurations);
      setAccountTypes(options);
    }
  }, [accountTypesLookup, error]);

  useEffect(() => {
    buildIdTypes();
    buildAccountTypes();
  }, [buildAccountTypes, buildIdTypes]);

  const savePaymentDetails = () => {
    trigger().then(() => {
      if (isValid) {
        const payload: PaymentDetailInput = {
          financialAccountPeriodId: null,
          primaryIdentifierType: getValues('primaryIdType'),
          primaryIdentifier: getValues('primaryId'),
          periodCoveredDate: getDate(getValues('periodCovered')),
          accountType: getValues('accountType'),
        };
        mutate({ paymentId: paymentId, paymentDetails: payload });
      }
    });
  };

  const paymentDetailsModalContent = (
    <Grid container spacing={2} mb={1} pt={1}>
      <Grid item xs={2} mt={2}>
        <Controller
          control={control}
          rules={{ required: true }}
          name="primaryIdType"
          render={({
            field: { value, onChange },
            fieldState: { error: primaryIdTypeError },
          }) => {
            return (
              <Select
                required
                options={idTypes}
                id="idTypeSelect"
                data-testid="idTypeSelect"
                label={t('pages.paymentDetails.primaryIdType')}
                onChange={onChange}
                error={
                  primaryIdTypeError
                    ? t('pages.paymentDetails.validation.primaryIdTypeRequired')
                    : ''
                }
                value={value}
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={3} mt={2}>
        <Controller
          control={control}
          rules={{
            required: true,
          }}
          name="primaryId"
          render={({
            field: { value, onChange },
            fieldState: { error: primaryIdError },
          }) => {
            return (
              <Input
                required
                id="modal-primaryId"
                label={t('pages.paymentDetails.primaryId')}
                value={value}
                onChange={(e) => {
                  onChange(e.target.value);
                }}
                error={!!primaryIdError}
                helperText={
                  primaryIdError
                    ? t('pages.paymentDetails.validation.primaryIdRequired')
                    : ''
                }
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={3} mt={2}>
        <Controller
          control={control}
          rules={{
            required: true,
          }}
          name="periodCovered"
          render={({
            field: { value, onChange },
            fieldState: { error: periodCoveredError },
          }) => {
            return (
              <DatePicker
                required
                id="modal-periodCoveredDate"
                label={t('pages.paymentDetails.periodCovered')}
                value={value}
                handleChange={onChange}
                requiredErrorMessage={
                  periodCoveredError
                    ? t('pages.paymentDetails.validation.periodCoveredRequired')
                    : ''
                }
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={3} mt={2}>
        <Controller
          control={control}
          rules={{
            required: true,
          }}
          name="accountType"
          render={({
            field: { value, onChange },
            fieldState: { error: accountTypeError },
          }) => {
            return (
              <Select
                required
                options={accountTypes}
                id="accountTypeSelect"
                data-testid="accountTypeSelect"
                label={t('pages.paymentDetails.application.accountType')}
                onChange={onChange}
                error={
                  accountTypeError
                    ? t('pages.paymentDetails.validation.accountTypeRequired')
                    : ''
                }
                value={value}
              />
            );
          }}
        />
      </Grid>
    </Grid>
  );

  return (
    <Dialog
      id="editPaymentDetailsDialog"
      open={open}
      children={paymentDetailsModalContent}
      title={t('pages.paymentDetails.editPaymentDetails')}
      type="transactional"
      transactionModalTransactionButtonText={t('components.button.save')}
      handleClose={() => {
        setOpen(false);
      }}
      handleCancelClick={() => {
        setOpen(false);
      }}
      handleTransactionClick={() => {
        savePaymentDetails();
      }}
    />
  );
}
export default EditPaymentDetailsModal;
