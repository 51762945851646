import { ReactNode } from 'react';

import Input from '@revenue-solutions-inc/revxcoreui/material/controls/Input';
import { Controller, UseFormReturn } from 'react-hook-form';
import { SxProps, Theme } from '@mui/system';

interface Props {
  // eslint-disable-next-line
  control: UseFormReturn['control'] | any;
  name: string;
  rules: Record<string, unknown>;
  disabled?: boolean;
  label: string;
  required: boolean;
  allowNegative?: boolean;
  endAdornment?: ReactNode;
  sx?: SxProps<Theme>;
}

function ControlledAmountField({
  control,
  name,
  disabled = false,
  label,
  rules,
  required,
  allowNegative,
  endAdornment,
  sx,
}: Props) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <Input
            required={required}
            value={value}
            onChange={(e) => {
              const valueWithDecimal = !allowNegative
                ? e.target.value.match(/^(\d*\.{0,1}\d{0,2}$)/)
                : e.target.value.match(/^-?(\d*\.{0,1}\d{0,2}$)/);

              if (valueWithDecimal) {
                onChange(e.target.value);
              }
            }}
            label={label}
            id={'controlledAmountFieldId'}
            sx={sx}
            error={!!error}
            helperText={error?.message}
            disabled={disabled}
            endAdornment={endAdornment}
          />
        );
      }}
    />
  );
}

export default ControlledAmountField;
