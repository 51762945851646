import { Fragment } from 'react';
import { WorkflowContext } from 'types/WorkflowContext';
import { Grid, Divider, Card, CardContent, Typography } from '@mui/material';
import { CardHeader } from '@revenue-solutions-inc/revxcoreui';
import { GeneralDataResult } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import ContextSearch from './ContextSearch';
import { ContextDemographics } from './ContextDemographics';

type WorkflowTabInfoProps = {
  showDemographic?: boolean;
  workflowContext?: WorkflowContext[];
  onSelectContext?: (context: GeneralDataResult) => void;
  onRemoveContext: (context: WorkflowContext) => void;
};

const WorkflowTabInfo = ({
  showDemographic = false,
  workflowContext = [],
  onSelectContext,
  onRemoveContext,
}: WorkflowTabInfoProps) => {
  const { t } = useTranslation();

  return (
    <Grid
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        width: 300,
        maxWidth: 300,
      }}
    >
      {showDemographic && (
        <Card sx={{ height: 'fit-content', width: 300 }} variant="outlined">
          <CardHeader
            title={
              <Typography variant="h2">
                {t('components.titles.demographics')}
              </Typography>
            }
          />
          <CardContent>
            {workflowContext?.map((context) => {
              return (
                <Fragment
                  key={`${context.ContextId}-${context.WorkflowContextType}`}
                >
                  <ContextDemographics
                    workflowContext={context}
                    onRemoveContext={onRemoveContext}
                  />
                  <Divider variant="middle" sx={{ margin: '8px auto' }} />
                </Fragment>
              );
            })}
            <ContextSearch onSelectContext={onSelectContext} />
          </CardContent>
        </Card>
      )}
    </Grid>
  );
};

export default WorkflowTabInfo;
