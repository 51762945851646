import { CardContent, Grid } from '@mui/material';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import { ColumnDef } from '@tanstack/react-table';
import DataDisplay from '@revenue-solutions-inc/revxcoreui/material/controls/DataDisplay';
import { useTranslation } from 'react-i18next';

import PropertyCardLayout from '../PropertyCardLayout';

type TaxableValuesDataTypes = {
  taxingAuthority: string;
  value: string;
};
interface Props {
  setActive: React.Dispatch<React.SetStateAction<string>>;
}

// TODO: remove once the services are in place for TaxableValuesCard
const taxableValuesData: TaxableValuesDataTypes[] = [
  { taxingAuthority: 'Taxing Authority 1 - City', value: '100,000' },
  { taxingAuthority: 'Taxing Authority 2 - County', value: '200,000' },
  { taxingAuthority: 'Taxing Authority 3 - School', value: '300,000' },
];

function TaxableValuesCard({ setActive }: Props): JSX.Element {
  const { t } = useTranslation();

  const COLUMNS: ColumnDef<TaxableValuesDataTypes>[] = [
    {
      id: 'taxingAuthority',
      accessorKey: 'taxingAuthority',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.propertyView.taxingAuthority')}
        />
      ),
    },

    {
      id: 'value',
      accessorKey: 'value',
      header: () => (
        <HeaderColumnNext localization={t('pages.propertyView.value')} />
      ),
    },
  ];

  return (
    <PropertyCardLayout
      title={t('pages.propertyView.taxableValues')}
      setActive={setActive}
      activeCard="ActiveTaxableValues"
    >
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <DataDisplay
              id="note"
              label={t('pages.propertyView.currentValue')}
              data={'113,140'}
            />
          </Grid>
          <Grid item xs={6}>
            <DataDisplay
              id="note"
              label={t('pages.propertyView.lastUpdate')}
              data={'Reason and date'}
            />
          </Grid>
          <Grid item xs={12}>
            <DefaultDataTableNext
              columns={COLUMNS as ColumnDef<Record<string, unknown>>[]}
              data={taxableValuesData}
              enableGlobalFilter={false}
            />
          </Grid>
        </Grid>
      </CardContent>
    </PropertyCardLayout>
  );
}

export default TaxableValuesCard;
