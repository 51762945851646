export interface ParameterInfo {
  Name: string;
  DisplayName: string;
  DataType: string;
  Required: boolean;
  Description: string;
  FieldLength: number;
}

export interface ScheduledTasksInput {
  TaskId: string;
  ScheduleTaskName: string;
  DisplayName: string;
  Description: string;
  Status: string;
  Version?: number;
  VersionName?: string;
  Stages: StageScheduleInput[];
  PipelineSchedules: PipelineScheduleInput[];
  Module: string;
  ModuleId: number;
  BusinessPolicy: string;
  NextRunOccurrence: string;
  NextRunTriggeredBy: string;
}

export interface PIIFields {
  Name: string;
  Value: string;
}

export interface SinkField {
  FieldValues: FieldValueChannel[];
  BaseField: BaseFieldInput;
  Value: string[];
}

export interface AccountInfo {
  AccountType: string;
  AccountSubType: string;
  PIIFields: PIIFields[];
}

export interface ProcessingOutput {
  Messages: string[];
  Actions: string[];
}

export interface ConnectorInfo {
  Name: string;
  DisplayName: string;
  Parameters: ParameterInfo[];
}

export interface Channel {
  Channel: ChannelInput;
}

export interface ScheduledTasks {
  ScheduleTask: ScheduledTasksInput;
}

export interface DataMaps {
  SinkObjectId: string;
  Domainkey: string;
  SinkFields: SinkField[];
  OperationID: string;
}

export interface BaseFieldInput {
  FieldProcessingOrder: number | null;
  Ignore: boolean;
  IsPII: boolean;
  IsFixed: boolean;
  LineNumber: string;
  IsMultiselect: boolean;
  GlobalRuleNames: string[];
  FieldID: number | null;
  FieldName: string;
  FieldDisplayName: string;
  FieldType: string;
  Show: boolean;
  RequiredField: boolean;
  Enable: boolean;
  FieldValueType: string;
  FieldFormat: string;
  PossibleValues: string[];
  Rules: string[];
  LastExecutionRuleChain: string[];
  CustomAttributes: CustomAttributesChannel[];
  // TODO: Go back and fix the circular dependency issue
  DataMaps: string[];
}

export interface RepeatingFieldInput {
  BaseField: BaseFieldInput;
  TotalValue: string;
  AverageValue: string;
  RepeatingValues: string[];
}

export interface ColumnDefinitionsChannel {
  ColumnLabel: string;
  ColumnNumber: number | null;
  AddTotal: boolean;
  AddAverage: boolean;
  RepeatingField: RepeatingFieldInput;
}

export interface RepeatingGroupChannel {
  RepeatingGroupName: string;
  ColumnDefinitions: ColumnDefinitionsChannel[];
  CustomAttributes: CustomAttributesChannel[];
}

export interface SubSectionChannel {
  SectionName: string;
  Fields: Field4Channel[];
  RepeatingGroups: RepeatingGroupChannel[];
  // TODO: Go back and fix the circular dependency issue
  SubSections: string[];
  SectionProcessingOrder: number | null;
  SectionDisplayText: string;
  CustomAttributes: CustomAttributesChannel[];
}

export interface BusinessSectionsInput {
  SectionName: string;
  SectionDisplayText: string;
  Fields: Field4Channel[];
  RepeatingGroups: RepeatingGroupChannel[];
  // TODO: Go back and fix the circular dependency issue
  SubSections: string[];
  SectionProcessingOrder: number | null;
  CustomAttributes: CustomAttributesChannel[];
}

export interface ContextsInput {
  ContextName: string;
  ProcessingOrder: number | null;
  BusinessSections: BusinessSectionsInput[];
  GlobalRules: GlobalRule[];
}

export interface LogixGroupInput {
  Contexts: ContextsInput[];
  ProcessingOutput: ProcessingOutput;
  TenantID: string | null;
}

export interface ChannelInput {
  ChannelName: string;
  DisplayName: string;
  Version: number;
  ActivityName: string;
  Id: string;
  Pipeline: PipelineInput;
  Module: string;
  ModuleId: number;
  Status: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  LogixGroup: any;
  FileGroup: FileGroupInput;
  IsCompressed: boolean;
  IsEncrypted: boolean;
  IsFti: boolean;
}

export interface FileGroupInput {
  FileGroupName: string;
  FileGroupType: string;
  FileType: string;
  FileProcessingType: string;
  DelimiterValue: string;
  Files: FileInput[];
}

export interface FileInput {
  FileName: string;
  FileMetadata: string;
  FileProcessingOrder: number | null;
  HasHeader: boolean;
  FileId: string | null;
}

export interface PipelineInput {
  Id: string;
  Name: string;
  InputDataConnector: string;
  PipelineSchedule: PipelineScheduleInput[] | [];
  Parameters: ParametersInput;
}

export interface PipelineScheduleInput {
  Id: string;
  Name: string;
  StartTime: Date | null;
  EndTime: Date | null;
  Interval: number | null;
  Frequency: string;
  TaskParameters?: JSON | null;
  PipelineReferenceName: string;
  IsDelete: boolean;
  RecurrenceSchedule: RecurrenceScheduleInput;
}

export interface StageScheduleInput {
  StageName: string;
  StageDisplayName: string;
  StageDescription: string;
  ExecutionOrder: number;
  DomainName: string;
  SinkObjectName: string;
  SinkObjectId: string;
  OperationName: string;
  OperationId: string;
  OperationType: string;
  ContinueOnStageFailure: boolean;
}

export interface RecurrenceScheduleInput {
  Minutes: number[];
  Hours: number[];
  WeekDays: number[];
  MonthDays: number[];
}

export interface ExceptionHandlersChannel {
  ErrorCondition: string;
  Handler: string[];
}

export interface InputParameterChannel {
  ParameterName: string;
  ParameterType: string;
  ParameterValue: string;
}

export interface GlobalRule {
  GlobalRuleName: string;
  RuleType: string;
  Expression: string;
  InputParameters: InputParameterChannel[];
  ExceptionHandler: ExceptionHandlersChannel;
}

export interface FieldValueChannel {
  Value: string;
  Status: string;
  CreatedBy: string;
  CreatedDate: string;
}

export interface FunctionPropertiesChannel {
  PropertyName: string;
  PropertyValue: string;
}

export interface RuleChannel {
  RuleName: string;
  RuleType: string;
  expression: string;
  InputParameters: InputParameterChannel[];
  FunctionProperties: FunctionPropertiesChannel[];
  ExceptionHandlers: ExceptionHandlersChannel;
  Description: string;
}

export interface CustomAttributesChannel {
  Name: string;
  Value: string;
  Elements: string[];
}

export interface BaseFieldChannel {
  FieldProcessingOrder: number | null;
  FieldNumber: number | null;
  PdfFieldID: number | null;
  Ignore: boolean;
  IsPII: boolean;
  Show: boolean;
  Enable: boolean;
  IsFixed: boolean;
  IsMultiselect: boolean;
  ApplyStandardBackfillRule: boolean;
  GlobalRuleNames: string[];
  FieldID: number | null;
  RequiredField: boolean | null;
  FieldName: string;
  FieldDisplayName: string;
  FieldType: string;
  FieldValueType: string;
  FieldFormat: string;
  Rules: RuleChannel[];
  PossibleValues: string[];
  LastExecutionRuleChain: string[];
  CustomAttributes: CustomAttributesChannel[];
  CustomFieldGroups: string[];
  DataMaps: string[];
}

export interface Field4Channel {
  FieldValues: FieldValueChannel[];
  BaseField: BaseFieldChannel;
}

export interface ParametersInput {
  [Key: string]: string;
}

export interface MonthlyOccurrencesInput {
  Day: number;
  Occurrence: number;
}

const recurrenceScheduleDefault: RecurrenceScheduleInput = {
  Minutes: [],
  Hours: [],
  WeekDays: [],
  MonthDays: [],
};

const parametersDefault: ParametersInput = {};

const pipelineScheduleDefault: PipelineScheduleInput = {
  Id: '',
  Name: '',
  StartTime: new Date(),
  EndTime: null,
  Interval: 1,
  Frequency: 'Hour',
  PipelineReferenceName: '',
  IsDelete: false,
  RecurrenceSchedule: recurrenceScheduleDefault,
};

const taskScheduleDefault: PipelineScheduleInput = {
  Id: '',
  Name: '',
  StartTime: new Date(),
  EndTime: null,
  Interval: 1,
  Frequency: 'Hour',
  PipelineReferenceName: '',
  TaskParameters: null,
  IsDelete: false,
  RecurrenceSchedule: recurrenceScheduleDefault,
};

const taskStagesScheduleDefault: StageScheduleInput = {
  StageName: '',
  StageDisplayName: '',
  StageDescription: '',
  ExecutionOrder: 1,
  DomainName: '',
  SinkObjectName: '',
  SinkObjectId: '',
  OperationName: '',
  OperationId: '',
  OperationType: '',
  ContinueOnStageFailure: false,
};

const noScheduleDefault: PipelineScheduleInput = {
  Id: '',
  Name: '',
  StartTime: null,
  EndTime: null,
  Interval: null,
  Frequency: '',
  PipelineReferenceName: '',
  IsDelete: false,
  RecurrenceSchedule: recurrenceScheduleDefault,
};

const pipelineDefault: PipelineInput = {
  Id: '',
  Name: '',
  InputDataConnector: '',
  PipelineSchedule: [pipelineScheduleDefault],
  Parameters: parametersDefault,
};

const fileInputDefault: FileInput = {
  FileName: '',
  FileMetadata: '',
  FileProcessingOrder: null,
  HasHeader: false,
  FileId: '',
};

const fileGroupDefault: FileGroupInput = {
  FileGroupName: '',
  FileGroupType: '',
  FileType: '',
  FileProcessingType: '',
  DelimiterValue: '',
  Files: [fileInputDefault],
};

const CustomAttributesChannelDefault: CustomAttributesChannel = {
  Name: '',
  Value: '',
  Elements: [],
};

const BaseFieldDefault: BaseFieldInput = {
  FieldProcessingOrder: null,
  LineNumber: '',
  Enable: false,
  Show: false,
  RequiredField: false,
  Ignore: false,
  IsPII: false,
  IsFixed: false,
  IsMultiselect: false,
  GlobalRuleNames: [],
  FieldID: null,
  FieldName: '',
  FieldDisplayName: '',
  FieldType: '',
  FieldValueType: '',
  FieldFormat: '',
  PossibleValues: [],
  Rules: [],
  LastExecutionRuleChain: [],
  CustomAttributes: [CustomAttributesChannelDefault],
  DataMaps: [],
};

const RepeatingFieldDefault: RepeatingFieldInput = {
  BaseField: BaseFieldDefault,
  TotalValue: '',
  AverageValue: '',
  RepeatingValues: [],
};

const ColumnDefinitionsDefault: ColumnDefinitionsChannel = {
  ColumnLabel: '',
  ColumnNumber: null,
  AddTotal: false,
  AddAverage: false,
  RepeatingField: RepeatingFieldDefault,
};

const RepeatingGroupsDefault: RepeatingGroupChannel = {
  RepeatingGroupName: '',
  ColumnDefinitions: [ColumnDefinitionsDefault],
  CustomAttributes: [],
};

const InputParameterChannelDefault: InputParameterChannel = {
  ParameterName: '',
  ParameterType: '',
  ParameterValue: '',
};

const FunctionPropertiesChannelDefault: FunctionPropertiesChannel = {
  PropertyName: '',
  PropertyValue: '',
};

const ExceptionHandlersChannelDefault: ExceptionHandlersChannel = {
  ErrorCondition: '',
  Handler: [],
};

const RuleChannelDefault: RuleChannel = {
  RuleName: '',
  RuleType: '',
  expression: '',
  InputParameters: [InputParameterChannelDefault],
  FunctionProperties: [FunctionPropertiesChannelDefault],
  ExceptionHandlers: ExceptionHandlersChannelDefault,
  Description: '',
};

const BaseFieldChannelDefault: BaseFieldChannel = {
  FieldProcessingOrder: null,
  FieldNumber: null,
  PdfFieldID: null,
  Ignore: false,
  IsPII: false,
  Show: false,
  Enable: false,
  RequiredField: false,
  IsFixed: false,
  IsMultiselect: false,
  ApplyStandardBackfillRule: false,
  GlobalRuleNames: [],
  FieldID: null,
  FieldName: '',
  FieldDisplayName: '',
  FieldType: '',
  FieldValueType: '',
  FieldFormat: '',
  Rules: [RuleChannelDefault],
  PossibleValues: [],
  LastExecutionRuleChain: [],
  CustomAttributes: [CustomAttributesChannelDefault],
  CustomFieldGroups: [],
  DataMaps: [],
};

const FieldValueChannelDefault: FieldValueChannel = {
  Value: '',
  Status: '',
  CreatedBy: '',
  CreatedDate: '',
};

const Field4ChannelDefault: Field4Channel = {
  FieldValues: [FieldValueChannelDefault],
  BaseField: BaseFieldChannelDefault,
};

const BusinessSectionsDefault: BusinessSectionsInput = {
  SectionName: '',
  SectionDisplayText: '',
  SectionProcessingOrder: null,
  Fields: [Field4ChannelDefault],
  RepeatingGroups: [RepeatingGroupsDefault],
  SubSections: [],
  CustomAttributes: [],
};

const GlobalRuleDefault: GlobalRule = {
  GlobalRuleName: '',
  RuleType: '',
  Expression: '',
  InputParameters: [InputParameterChannelDefault],
  ExceptionHandler: ExceptionHandlersChannelDefault,
};

const ContextsDefault: ContextsInput = {
  ContextName: '',
  ProcessingOrder: null,
  BusinessSections: [BusinessSectionsDefault],
  GlobalRules: [GlobalRuleDefault],
};

const ProcessingOutputDefault: ProcessingOutput = {
  Messages: [],
  Actions: [],
};

const LogixGroupDefault: LogixGroupInput = {
  Contexts: [ContextsDefault],
  ProcessingOutput: ProcessingOutputDefault,
  TenantID: '',
};

const channelDefault: ChannelInput = {
  ChannelName: '',
  DisplayName: '',
  ActivityName: 'inbound',
  Id: '',
  Pipeline: pipelineDefault,
  LogixGroup: null,
  Module: 'Property',
  ModuleId: 2,
  Status: '',
  Version: 0,
  FileGroup: fileGroupDefault,
  IsCompressed: true,
  IsEncrypted: true,
  IsFti: false,
};

const taskDefault: ScheduledTasksInput = {
  TaskId: '',
  ScheduleTaskName: '',
  DisplayName: '',
  Description: '',
  BusinessPolicy: '',
  Status: 'Active',
  PipelineSchedules: [taskScheduleDefault],
  Stages: [taskStagesScheduleDefault],
  Module: 'Property',
  ModuleId: 1,
  NextRunOccurrence: '',
  NextRunTriggeredBy: '',
};

const taskObject: ScheduledTasks = {
  ScheduleTask: taskDefault,
};

const channelObject: Channel = {
  Channel: channelDefault,
};

export {
  channelDefault,
  channelObject,
  pipelineDefault,
  pipelineScheduleDefault,
  recurrenceScheduleDefault,
  parametersDefault,
  fileGroupDefault,
  fileInputDefault,
  taskObject,
  noScheduleDefault,
  LogixGroupDefault,
  taskScheduleDefault,
  taskStagesScheduleDefault,
  ContextsDefault,
};
