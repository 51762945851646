import { useAppSelector } from 'redux/hooks';
import { accessConfig } from 'utils/routeAccessConfig';

export function useHasAccess(
  componentName: string,
  actionName: 'edit' | 'view'
): boolean {
  const roles = useAppSelector((state) => state.user.roles);
  let isPolicyGroupExist = false;

  if (
    accessConfig[componentName] &&
    accessConfig[componentName][actionName].length
  ) {
    roles?.forEach((role) => {
      role.policyGroups.forEach((item) => {
        if (
          accessConfig[componentName][actionName].some(
            (pGroup) =>
              pGroup.toLowerCase() == item.name.replace(/\s/g, '').toLowerCase()
          )
        ) {
          isPolicyGroupExist = true;
        }
      });
    });
  } else {
    // this line is temporal, because there are not defined yet all the policyGroups and components
    // in routeAccesConfig, basically if the component is not defined yet or has an empty array of policyGroups
    // we allow the view. This for Navigation, SecureRoute and Sidebar components to work correctly
    return true;
  }

  return isPolicyGroupExist;
}

// in the component side the programmer just need to ask for the policyGroup he wants to check
// const isFullAccess = useHasEdit('configTypeEditor', 'Platformconfigaccessall');
export function useHasEdit(
  componentName: string,
  policyGroupName: string
): boolean {
  const roles = useAppSelector((state) => state.user.roles);
  const matchedPolicyG: string[] = [];
  const rolesCount = roles?.length ?? 0;
  if (accessConfig[componentName] && rolesCount > 0) {
    roles?.forEach((role) => {
      role.policyGroups.forEach((item) => {
        if (
          accessConfig[componentName].edit.some(
            (pGroup) =>
              pGroup.toLowerCase() ===
              item.name.replace(/\s/g, '').toLowerCase()
          )
        ) {
          matchedPolicyG.push(item.name.replace(/\s/g, '').toLowerCase());
        }
      });
    });
    return matchedPolicyG.includes(policyGroupName.toLowerCase());
  }
  return false;
}
