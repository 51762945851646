import {
  LogixGroupTypeDM,
  Action,
} from '@revenue-solutions-inc/revxcoreui/material/datamapperV3/types';

interface ActionProps {
  name: string;
  category: string;
  repeatable: boolean;
  statusToBeChanged: string;
  domain: string;
  operationId: string;
  operationType: string;
}

export const getActionsList = (
  LogixGroup: LogixGroupTypeDM,
  actionsList: ActionProps[]
) => {
  const actions: Action[] = [];
  if (LogixGroup.Contexts.length > 0) {
    LogixGroup.Contexts.forEach((context) =>
      context.Actions.filter(
        (actFilter) => actFilter.Category === 'NonWorkflow'
      ).forEach((act) => {
        const selectedAction = actionsList.find(
          (action) => action.name === act.Type
        );
        actions.push({
          key: act.Name,
          desc: act.Name,
          sink: {
            domain: selectedAction?.domain ?? '',
            type: selectedAction?.operationType ?? '',
            name: selectedAction?.operationId ?? '',
          },
        });
      })
    );
  }

  return actions as Action[];
};
