import { useMemo } from 'react';

import {
  TableBody,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableContainer,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

interface DataProps {
  row: string[];
}

interface Props {
  dataSource: DataProps[];
}

const tableStyle = {
  border: '1px solid #ddd',
  borderLeft: 'none',
  borderRight: 'none',
  p: 0,
  pr: 1,
  textAlign: 'center',
};

export default function TableInfo({ dataSource }: Props) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.bulkUserUpload',
  });

  const header = useMemo(() => {
    return t('instructions.example.header').split(',');
  }, [t]);

  return (
    <TableContainer>
      <Table
        size="small"
        sx={{
          border: '1px solid #ddd',
        }}
      >
        <TableHead>
          <TableRow sx={{ backgroundColor: '#F1F7FF' }}>
            {header.map((column: string, index: number) => (
              <TableCell key={index} sx={[tableStyle, { fontWeight: 600 }]}>
                {column}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {dataSource.map((data, rowIndex) => (
            <TableRow key={rowIndex}>
              {data.row.map((column, columnIndex) => (
                <TableCell key={columnIndex} sx={tableStyle}>
                  {column}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
