import { SelectChangeEvent } from '@mui/material';
import Grid from '@mui/material/Grid';
import Input from '@revenue-solutions-inc/revxcoreui/material/controls/Input';
import Select from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import countries from 'common/countries';
import states from 'common/states';
import { useTranslation } from 'react-i18next';
import { Address as AddressType } from 'types/contact';
import { addressType as addressTypes } from 'types/rsiTypes';

export interface AddressProps {
  address: AddressType;
  onChange: (address: AddressType) => void;
  addressType?: { key: string; desc: string };
  required?: boolean;
  omitAddressType?: { key: string; desc: string };
}

function Address({
  address,
  onChange,
  addressType,
  required,
  omitAddressType,
}: AddressProps): JSX.Element {
  const { t } = useTranslation();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newAddress: AddressType;
    switch (event.target.id) {
      case 'street1':
        newAddress = { ...address, street: event.target.value };
        break;
      case 'street2':
        newAddress = { ...address, street2: event.target.value };
        break;
      case 'city':
        newAddress = { ...address, city: event.target.value };
        break;
      case 'postalCode':
        newAddress = { ...address, postalCode: event.target.value };
        break;
      default:
        newAddress = { ...address };
    }
    if (newAddress) {
      onChange(newAddress);
    }
  };

  return (
    <>
      <Grid item xs={8} mb={0.5} mt={1}>
        <Select
          options={addressTypes
            .filter((value) =>
              omitAddressType ? value.id !== omitAddressType?.key : value
            )
            .map((addType) => {
              return { key: addType.id, desc: addType.value };
            })}
          disabled={addressType !== undefined}
          value={address.type}
          id="type"
          label={t('components.address.type')}
          onChange={(event: SelectChangeEvent<string | number>) => {
            onChange({
              ...address,
              type: (event.target as HTMLSelectElement).value,
            });
          }}
          required={required}
        />
      </Grid>
      <Grid item mb={0.5}>
        <Input
          value={address.street}
          id="street1"
          label={t('components.address.address1')}
          onChange={handleInputChange}
          required={required}
          inputProps={{ 'data-testid': 'street-address' }}
        />
      </Grid>
      <Grid item mb={0.5}>
        <Input
          value={address.street2}
          id="street2"
          label={t('components.address.address2')}
          onChange={handleInputChange}
          inputProps={{ 'data-testid': 'street-address-two' }}
        />
      </Grid>
      <Grid item mb={0.5}>
        <Input
          value={address.city}
          id="city"
          label={t('components.address.city')}
          onChange={handleInputChange}
          required={required}
          inputProps={{ 'data-testid': 'city-address' }}
        />
      </Grid>
      <Grid item mb={0.5}>
        <Select
          options={states}
          value={address.stateProvince}
          id="stateProvince"
          label={t('components.address.state')}
          onChange={(event: SelectChangeEvent<string | number>) => {
            onChange({
              ...address,
              stateProvince: (event.target as HTMLSelectElement).value,
            });
          }}
          required={required}
          inputProps={{ 'data-testid': 'state-address' }}
        />
      </Grid>
      <Grid item mb={0.5}>
        <Input
          value={address.postalCode}
          id="postalCode"
          label={t('components.address.zip')}
          onChange={handleInputChange}
          required={required}
          inputProps={{ 'data-testid': 'postal-address' }}
        />
      </Grid>
      <Grid item mb={0.5}>
        <Select
          options={countries}
          value={address.countryCode}
          id="countryCode"
          label={t('components.address.country')}
          onChange={(event: SelectChangeEvent<string | number>) => {
            onChange({
              ...address,
              countryCode: (event.target as HTMLSelectElement).value,
            });
          }}
          required={required}
          inputProps={{ 'data-testid': 'country-address' }}
        />
      </Grid>
    </>
  );
}

export default Address;
