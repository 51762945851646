import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { Card, CardContent } from '@revenue-solutions-inc/revxcoreui';
import { Theme } from '@mui/system';
import DataDisplay from '@revenue-solutions-inc/revxcoreui/material/controls/DataDisplay';
import { useTranslation } from 'react-i18next';

import { OwnerType } from '../EditOwnerContainer/EditOwnerContainer';

interface Props {
  cardTitle?: string;
  editOwnerData: OwnerType;
}

function EditOwnerCard({ cardTitle, editOwnerData }: Props): JSX.Element {
  const { t } = useTranslation();
  return (
    <Box>
      <Paper variant="outlined" sx={{ width: '66rem', height: '23rem' }}>
        <Box
          sx={(theme: Theme) => {
            return {
              backgroundColor: theme.palette.white.main,
              height: '12%',
              padding: '10px',
              marginBottom: '10px',
              display: 'flex',
              justifyContent: 'space-between',
            };
          }}
        >
          <Typography
            variant="h2"
            sx={(theme: Theme) => {
              return {
                color: theme.palette.linkBlue.dark,
                marginBottom: '15px',
              };
            }}
          >
            {t('pages.propertyView.updated')} {editOwnerData.date}
          </Typography>
          <Box>
            <ArrowCircleLeftIcon
              sx={(theme: Theme) => {
                return {
                  fill: theme.palette.linkBlue.main,
                  fontSize: '2rem',
                };
              }}
            />
            <ArrowCircleRightIcon
              sx={(theme: Theme) => {
                return {
                  fill: theme.palette.linkBlue.main,
                  fontSize: '2rem',
                };
              }}
            />
          </Box>
        </Box>
        <Card
          sx={{
            width: '30rem',
            borderRadius: '5px',
            height: '15.3rem',
            marginLeft: '20px',
          }}
        >
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography
                  variant="h3"
                  component="label"
                  sx={{ marginBottom: '15px' }}
                >
                  {cardTitle}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <DataDisplay
                  id="ownerName"
                  label="Name"
                  data={editOwnerData.name}
                />
              </Grid>
              <Grid item xs={6}>
                <DataDisplay
                  id="ownerId"
                  label="Owner ID"
                  data={editOwnerData.id}
                />
              </Grid>
              <Grid item xs={6}>
                <DataDisplay
                  id="ownerId"
                  label="Address 1"
                  data={editOwnerData.address}
                />
              </Grid>
              <Grid item xs={6}>
                <DataDisplay
                  id="ownerPercentage"
                  label="Owner Percentage"
                  data={editOwnerData.percentage}
                />
              </Grid>
              <Grid item xs={12}>
                <DataDisplay
                  id="ownerCity"
                  label="City"
                  data={editOwnerData.city}
                />
              </Grid>
              <Grid item xs={6}>
                <DataDisplay
                  id="ownerState"
                  label="State"
                  data={editOwnerData.state}
                />
              </Grid>
              <Grid item xs={6}>
                <DataDisplay
                  id="ownerZip"
                  label="Zip"
                  data={editOwnerData.zipcode}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Paper>
    </Box>
  );
}

export default EditOwnerCard;
