import { Dispatch, SetStateAction, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Control, Controller, FieldValues } from 'react-hook-form';
import ControlledDateField from 'components/controls/ControlledDateField';
import {
  MessageActionType,
  MessageType,
  Select,
} from '@revenue-solutions-inc/revxcoreui';
import ExtendedAttributes from 'components/entityManagement/common/ExtendedAttributes';
import { Group, useGetLookupNamesConfigurationQuery } from 'generated/graphql';
import { ConfigurationModules } from 'common/platformConfigUtils/platformConfigUtils';
import { addMessage } from 'redux/messageSlice';
import { useAppDispatch } from 'redux/hooks';
import { ExtendedAttributeValues } from 'types/forms';
import { buildTypeList } from '../AccountPeriod/CreateManualCharge';

interface EditPeriodFormProps {
  control: Control<FieldValues, unknown>;
  periodAttributeGroup?: Group[] | null;
  extendedValues: ExtendedAttributeValues[];
  setExtendedValues: Dispatch<SetStateAction<ExtendedAttributeValues[]>>;
}

function EditPeriodForm({
  control,
  periodAttributeGroup,
  extendedValues,
  setExtendedValues,
}: EditPeriodFormProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { data: periodReasonsLookup, error: periodReasonsLookupError } =
    useGetLookupNamesConfigurationQuery({
      configurationType: 'MaintainPeriodReason',
      configurationModule: ConfigurationModules.Platform,
    });

  useEffect(() => {
    if (periodReasonsLookupError) {
      dispatch(
        addMessage({
          message: t('pages.periodDetails.edit.periodReasonsLookupError'),
          type: MessageType.Error,
          actionType: MessageActionType.None,
        })
      );
    }
  }, [dispatch, periodReasonsLookupError, t]);

  const handleExtendedAttributeValues = (
    newExtendedValues: ExtendedAttributeValues[]
  ) => {
    setExtendedValues(newExtendedValues);
  };

  return (
    <Grid container spacing={2} mb={1}>
      <Grid item xs={3}>
        <ControlledDateField
          required={true}
          rules={{ required: true }}
          name="beginDate"
          control={control}
          id="beginDate"
          label={t('pages.periodDetails.edit.beginDate')}
          isCalendarRequired={true}
          size="medium"
          requiredErrorMessage={t('pages.periodDetails.edit.beginDateRequired')}
        />
      </Grid>
      <Grid item xs={3}>
        <ControlledDateField
          required={true}
          rules={{ required: true }}
          name="endDate"
          control={control}
          id="endDate"
          label={t('pages.periodDetails.edit.endDate')}
          isCalendarRequired={true}
          size="medium"
          requiredErrorMessage={t('pages.periodDetails.edit.endDateRequired')}
        />
      </Grid>
      <Grid item xs={3}>
        <Controller
          control={control}
          name="maintainPeriodReason"
          rules={{ required: true }}
          render={({
            field: { value, onChange },
            fieldState: { error: maintainPeriodReasonError },
          }) => {
            return (
              <Select
                required
                autowidth={false}
                options={buildTypeList(periodReasonsLookup)}
                id="maintainPeriodReason"
                label={t('pages.periodDetails.edit.reason')}
                onChange={onChange}
                value={value}
                fullWidth
                error={
                  maintainPeriodReasonError
                    ? t('pages.periodDetails.edit.reasonRequired')
                    : ''
                }
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <ExtendedAttributes
          extendedAttributes={
            periodAttributeGroup && periodAttributeGroup.length > 0
              ? periodAttributeGroup[0].attribute
              : []
          }
          extendedValues={extendedValues}
          handleExtendedValues={handleExtendedAttributeValues}
          displayTitle={false}
          validateHandleDate
        />
      </Grid>
    </Grid>
  );
}
export default EditPeriodForm;
