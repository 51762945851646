import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetWorkflowGroupVariantsQuery } from 'generated/graphql';

export const useAddWorkflow = () => {
  const { t } = useTranslation();
  const [groupSelected, setGroupSelected] = useState<string>('');
  const [variantSelected, setVariantSelected] = useState<string>('');

  const [workTypeDynamicLabel, setWorkTypeDynamicLabel] = useState<string>(
    t('pages.workflow.selectType')
  );

  const { data } = useGetWorkflowGroupVariantsQuery();

  const groupOptions = useMemo(() => {
    if (data) {
      return data.GetWorkflowGroupVariants.map((group) => ({
        key: String(group.id),
        desc: group.shortDescription,
      }));
    }
    return [];
  }, [data]);

  const variantOptions = useMemo(() => {
    if (data && groupSelected) {
      return data.GetWorkflowGroupVariants.find(
        (group) => String(group.id) === groupSelected
      )?.variants?.map((variant) => ({
        key: String(variant.id),
        desc: variant.shortDescription,
      }));
    }

    return [];
  }, [data, groupSelected]);

  return {
    groupOptions,
    variantOptions,
    groupSelected,
    setGroupSelected,
    variantSelected,
    setVariantSelected,
    workTypeDynamicLabel,
    setWorkTypeDynamicLabel,
  };
};
