import { SelectType } from '@revenue-solutions-inc/revxcoreui/material/controls/Select';

export enum ConfigurationDomains {
  EntityManagement,
  EntityAccounting,
  Asset,
  TenantManagement,
  RoleManagement,
  Correspondence,
  WorkflowManagement,
  ReferenceSchema,
  ReportService,
}

export enum ConfigurationModules {
  Property = 0,
  Revenue = 1,
  Platform = 3,
}

export enum AttributeTypes {
  string = 'string',
  int = 'int',
  boolean = 'boolean',
  date = 'date',
  // datetime = 'datetime',
  currency = 'currency',
  referenceData = 'referenceData',
  businessPolicy = 'businessPolicy',
  extendedAttribute = 'extendedAttribute',
  schema = 'schema',
  dynamicAttribute = 'dynamicAttribute',
}

export enum BusinessDrivers {
  Required = 0,
  Confidential = 1,
  MatchScore = 2,
  FieldMinimum = 3,
  FieldMaximum = 4,
  RegularExpression = 5,
  Expression = 6,
  Unique = 7,
  PrimaryKey = 10,
  Hidden = 15,
}

/**
 * Creates select options based on an Enum
 * @param enumInput  Enum
 * @returns SelectType to be used in dropdowns
 */
export function createSelectTypeFromEnum(
  enumInput:
    | typeof ConfigurationDomains
    | typeof ConfigurationModules
    | typeof AttributeTypes
    | typeof BusinessDrivers
): SelectType[] {
  return Object.entries(enumInput)
    .filter(([, value]) => typeof value === 'string')
    .map(([key, desc]) => ({ key, desc } as SelectType));
}

/**
 * Add spaces to camelCase, and Capitalize first letter of desc property
 * hence:
 * referenceData => Reference Data
 * string => String
 * int => Integer (especial case)
 * useful when you want to display a more friendly data to the user.
 */
function prettify(options: SelectType[]): SelectType[] {
  return options.map(({ key, desc }) => ({
    key,
    desc:
      desc === 'int'
        ? 'Integer'
        : desc
            .replace(/([a-z])([A-Z])/g, '$1 $2')
            .replace(/(^\w)/, (match) => match.toUpperCase()),
  }));
}

/**
 * You can use this object in a select List or dropDown list
 * [{ key: '0', desc: 'EntityManagement' }, { key: '1', desc: 'EntityAccounting' }...]
 */
export const configDomainsList: SelectType[] =
  createSelectTypeFromEnum(ConfigurationDomains);

/**
 * You can use this object in a select list or dropDown list
 * [{ key: '0', desc: 'Property' }, { key: '1', desc: 'Revenue' }...]
 */
export const configModulesList: SelectType[] =
  createSelectTypeFromEnum(ConfigurationModules);

/**
 * You can use this object in a select list or dropDown list
 * [{ key: 'referenceData', desc: 'Reference Data' }, { key: 'string', desc: 'String' }...]
 */
export const attributeTypesList: SelectType[] = prettify(
  createSelectTypeFromEnum(AttributeTypes)
);

/**
 * You can use this object in a select list or dropDown list
 * [{ key: '0', desc: 'Required' }, { key: '10', desc: 'PrimaryKey' }...]
 */
export const businessDriverList: SelectType[] =
  createSelectTypeFromEnum(BusinessDrivers);
