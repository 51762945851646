import { createReducer } from '../../utils/createLocalReducer';
import type { ActionMap } from '../../utils/createLocalReducer';

export interface WorkManagerTableReducerState {
  cardData: { [key: string]: string } | undefined | null;
  selectedRows: string[];
  selectedUser: string | undefined;
  selectAllChecked: boolean;
}

export const initialState: WorkManagerTableReducerState = {
  cardData: null,
  selectedRows: [],
  selectedUser: undefined,
  selectAllChecked: false,
};

export const actions = {
  ON_UPDATE_VARIANT_OWNER_SUCCESS: 'wm_table/ON_UPDATE_VARIANT_OWNER_SUCCESS',
  SET_CARD_DATA: 'wm_table/SET_CARD_DATA',
  SET_SELECTED_ROWS: 'wm_table/SET_SELECTED_ROWS',
  SET_SELECTED_USER: 'wm_table/SET_SELECTED_USER',
  TOGGLE_SELECT_ALL: 'wm_table/TOGGLE_SELECT_ALL',
};

export const actionMap: ActionMap<WorkManagerTableReducerState> = {
  [actions.ON_UPDATE_VARIANT_OWNER_SUCCESS]: (state) => ({
    ...state,
    selectedRows: [],
    selectedUser: undefined,
    selectAllChecked: false,
    cardData: null,
  }),
  [actions.SET_CARD_DATA]: (state, { payload }) => ({
    ...state,
    cardData: payload,
  }),
  [actions.SET_SELECTED_ROWS]: (state, { payload }) => ({
    ...state,
    selectAllChecked: payload.selectAllChecked,
    selectedRows: payload.selectedRows,
  }),
  [actions.SET_SELECTED_USER]: (state, { payload }) => ({
    ...state,
    selectedUser: payload,
  }),
  [actions.TOGGLE_SELECT_ALL]: (state, { payload }) => ({
    ...state,
    selectAllChecked: payload.selectAllChecked,
    selectedRows: payload.selectedRows,
  }),
};

export const workManagerTableReducer =
  createReducer<WorkManagerTableReducerState>(initialState, actionMap);
