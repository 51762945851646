import { createReducer } from 'utils/createLocalReducer';
import type { ActionMap } from 'utils/createLocalReducer';
import type { NoteDisplay } from './NoteRowItem';

type NotesPanelReducerState = {
  searchVal: string;
  addNotesModalOpen: boolean;
  selectedVariant: string;
  variantOptions: { key: string; desc: string }[];
  selectedNote: NoteDisplay | null;
};

export const initialState: NotesPanelReducerState = {
  searchVal: '',
  addNotesModalOpen: false,
  selectedVariant: '',
  variantOptions: [],
  selectedNote: null,
};

export const actions = {
  CLOSE_ADD_NOTES_MODAL: 'notes/CLOSE_ADD_NOTES_MODAL',
  OPEN_ADD_NOTES_MODAL: 'notes/OPEN_ADD_NOTES_MODAL',
  SELECT_VARIANT: 'notes/SELECT_VARIANT',
  SET_SELECTED_NOTE: 'notes/SET_SELECTED_NOTE',
  SET_VARIANT_OPTIONS: 'notes/SET_VARIANT_OPTIONS',
};

export const actionMap: ActionMap<NotesPanelReducerState> = {
  [actions.CLOSE_ADD_NOTES_MODAL]: (state) => ({
    ...state,
    addNotesModalOpen: false,
  }),
  [actions.OPEN_ADD_NOTES_MODAL]: (state) => ({
    ...state,
    addNotesModalOpen: true,
  }),
  [actions.SELECT_VARIANT]: (state, { payload: { selectedVariant } }) => ({
    ...state,
    selectedVariant,
  }),
  [actions.SET_SELECTED_NOTE]: (state, { payload: { selectedNote } }) => ({
    ...state,
    selectedNote,
  }),
  [actions.SET_VARIANT_OPTIONS]: (state, { payload: { options } }) => ({
    ...state,
    variantOptions: options,
  }),
};

export const notesPanelReducer = createReducer<NotesPanelReducerState>(
  initialState,
  actionMap
);
