import { dateExp } from 'common/regexp';
import { format } from 'date-fns';

export const dateFormat = 'MM/dd/yyyy';
export const dateTimeFormat = 'MM/dd/yyyy HH:mm:ss';

const valueFormat = 'MM/dd/yyyy';
const nullDate = '9999-12-31';

export const isMaxValueDate = (dateValue: Date | string) => {
  const value = typeof dateValue === 'string' ? new Date(dateValue) : dateValue;
  const year = value.getFullYear();
  return year === 9999;
};

export const isDateInvalid = (dateValue: Date | string | null) => {
  if (!dateValue) return true;
  const value = typeof dateValue === 'string' ? new Date(dateValue) : dateValue;
  const month = value.getMonth();
  const year = value.getFullYear();
  const { isNaN } = Number;
  return isNaN(month) || year.toString().length <= 3;
};

type ArrayDateType = null | [string, number, number, number];

/**
 * Solves string date and it turns into date
 * @param {string | Date | undefined} value
 * @returns {Date | null} Returns the value of x for the equation.
 */
export const toDate = (value?: string | Date | null): Date | null => {
  if (!value) return null;

  if (typeof value === 'string') {
    const array = value.match(dateExp) as ArrayDateType;
    if (array && array[0] !== nullDate)
      return new Date(array[1], array[2] - 1, array[3]);
    return null;
  }

  return value;
};

export const toStringDate = (
  value: Date | string | null | undefined
): string => {
  if (value instanceof Date) {
    try {
      return value.toISOString();
    } catch {
      return value as unknown as string;
    }
  }
  return value ?? '';
};

export const toStringDateNull = (
  value: Date | string | null | undefined
): string | null => {
  if (value === '') return null;
  if (value instanceof Date) return value.toISOString();
  return value ?? null;
};

export const getFormatDate = (value: Date | null | undefined): string => {
  if (!value) return '';

  let formattedDate = '';
  try {
    formattedDate = format(value, valueFormat);
  } catch {
    formattedDate = '';
  }
  return formattedDate;
};

export const checkDatesEqual = (dateOne: Date | null, dateTwo: Date | null) => {
  if (
    dateOne &&
    dateTwo &&
    !isDateInvalid(dateOne) &&
    !isDateInvalid(dateTwo)
  ) {
    return getFormatDate(dateOne) === getFormatDate(dateTwo);
  }
  return false;
};
