import { MouseEventHandler } from 'react';
import { Box, Stack } from '@mui/system';
import { useTranslation } from 'react-i18next';
import {
  PaymentDetailsResponse,
  RemittanceDetail,
  RemittanceDetailInput,
} from 'generated/graphql';
import { IconButton, Typography } from '@mui/material';
import { Control, FieldValues, UseFormGetValues } from 'react-hook-form';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import CurrencyCell from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/TableCells/CurrencyCell';
import { ColumnDef, Row } from '@tanstack/table-core';
import {
  recordIcons,
  checkButton,
  icon,
  cancelButton,
  fontSize,
} from 'components/manageConfigTools/Shared/IconButtonStyling/IconButtonStyling';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { remittanceColumns } from 'components/RemittanceList/RemittanceList';
import ControlledInputField from 'components/controls/ControlledInputField';

interface Props {
  remittances: RemittanceDetail[];
  currentRowEdit: string;
  control: Control<FieldValues, unknown>;
  getValues: UseFormGetValues<FieldValues>;
  handleCancel: () => void;
  handleSaveRow: () => void;
  handleEditRow: (rowId: string) => void;
  newRemittances: RemittanceDetailInput[];
  showPreview: boolean;
}

function EditRemittanceDataTable({
  remittances,
  currentRowEdit,
  control,
  getValues,
  handleCancel,
  handleSaveRow,
  handleEditRow,
  newRemittances,
  showPreview,
}: Props) {
  const { t } = useTranslation();

  const editRowButton = (
    row: Row<RemittanceDetail> | Row<PaymentDetailsResponse>
  ) => {
    return (
      <IconButton
        onClick={() => {
          handleEditRow(row.original.id as string);
        }}
        sx={recordIcons}
        aria-label="edit-button"
        title={t('pages.configGroupEditor.editGroup')}
      >
        <EditIcon sx={{ fontSize }} />
      </IconButton>
    );
  };

  const cancelConfirmButtons = (
    onCancel: MouseEventHandler<HTMLButtonElement>
  ) => {
    return (
      <Stack direction="row" spacing={1}>
        <IconButton
          aria-label="check-button"
          onClick={handleSaveRow}
          title={t('pages.configRecordEditor.confirmIcon')}
          size="small"
          sx={checkButton}
        >
          <CheckIcon fontSize="small" sx={icon} />
        </IconButton>
        <IconButton
          onClick={onCancel}
          title={t('pages.configRecordEditor.cancelIcon')}
          size="small"
          sx={cancelButton}
        >
          <CloseIcon fontSize="small" sx={icon} />
        </IconButton>
      </Stack>
    );
  };

  const remittanceCols: ColumnDef<RemittanceDetail>[] = [
    {
      header: () => <HeaderColumnNext localization={''} />,
      id: 'edit',
      cell: ({ row }) => {
        return row.original.id !== currentRowEdit
          ? editRowButton(row)
          : cancelConfirmButtons(handleCancel);
      },
    },
    ...remittanceColumns.filter((item) => {
      return item.id !== 'amount';
    }),
    {
      id: 'amount',
      accessorKey: 'amount',
      header: () => (
        <HeaderColumnNext localization={t('pages.submissionDetails.amount')} />
      ),
      cell: ({ getValue, row }) => {
        return row.original.id === currentRowEdit ? (
          <ControlledInputField
            control={control}
            rules={{
              required: true,
            }}
            name={`${row.original.id}`}
            required
            id={`remittance_${row.original.id}`}
            label={t('pages.submissionDetails.amount')}
          />
        ) : (
          <Box sx={{ width: '3.3em' }}>
            <CurrencyCell
              invalidValue={t('pages.tableCell.invalidValue')}
              amountString={
                showPreview
                  ? (getValue() as string)
                  : (getValues(`${row.original.id}`) as string)
              }
            />
          </Box>
        );
      },
    },
    {
      id: 'correctedAmount',
      accessorKey: 'correctedAmount',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.submissionDetails.correctedAmount')}
        />
      ),
      cell: ({ row }) => (
        <Box sx={{ width: '7.7em' }}>
          <CurrencyCell
            invalidValue={t('pages.tableCell.invalidValue')}
            amountString={
              newRemittances?.find(
                (item) => item.remittanceId === row.original.id
              )?.correctedAmount as unknown as string
            }
          />
        </Box>
      ),
    },
  ];

  return (
    <>
      <Typography variant="h3" sx={{ mt: 2 }}>
        {t('pages.submissionDetails.remittances')}
      </Typography>
      {remittances.length > 0 ? (
        <DefaultDataTableNext
          columns={
            remittanceCols.filter((item) => {
              return item.id !== (showPreview ? 'edit' : 'correctedAmount');
            }) as ColumnDef<Record<string, unknown>>[]
          }
          data={remittances ?? []}
          enableGlobalFilter={false}
        />
      ) : (
        t('pages.submissionDetails.noRemittances')
      )}
    </>
  );
}
export default EditRemittanceDataTable;
