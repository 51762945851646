import { CardContent, Grid } from '@mui/material';
import DataDisplay from '@revenue-solutions-inc/revxcoreui/material/controls/DataDisplay';
import { useTranslation } from 'react-i18next';

import PropertyCardLayout from '../PropertyCardLayout';

interface Props {
  setActive: React.Dispatch<React.SetStateAction<string>>;
}

function ExemptionsCard({ setActive }: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <PropertyCardLayout
      title={t('pages.propertyView.exemptionsCredits')}
      setActive={setActive}
      activeCard="ActiveExemptionsCard"
    >
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <DataDisplay
              id="codes"
              label={t('pages.propertyView.codes')}
              data={'HS, O65, DV'}
            />
          </Grid>
        </Grid>
      </CardContent>
    </PropertyCardLayout>
  );
}

export default ExemptionsCard;
