import { EntityForm, EntityStepsConfig } from 'types/entities';

export const getEntity: EntityForm = {
  names: [
    {
      NameType: '',
      firstName: '',
      middleName: '',
      lastName: '',
      nameSuffix: '',
      value: '',
      isPrimary: 'true',
      isConfidential: 'true',
      commenceDate: null,
      ceaseDate: null,
    },
  ],
  addresses: [
    {
      AddressType: '',
      attention: '',
      cityId: '',
      countyId: '',
      countryId: '',
      postalCode: '',
      stateProvinceId: '',
      addressLine1: '',
      addressLine2: '',
      isPrimary: 'true',
      isConfidential: 'true',
      hasMailReturned: 'true',
      commenceDate: null,
      ceaseDate: null,
    },
  ],
  identifiers: [
    {
      IdType: '',
      value: '',
      isPrimary: 'true',
      commenceDate: null,
      ceaseDate: null,
    },
  ],
  emailAddresses: [
    {
      EmailAddressType: '',
      value: '',
      isPrimary: 'true',
      commenceDate: null,
      ceaseDate: null,
    },
  ],
  phoneNumbers: [
    {
      PhoneNumberType: '',
      value: '',
      isPrimary: 'true',
      commenceDate: null,
      ceaseDate: null,
    },
  ],
  others: [
    {
      entityType: '',
      entityCreationSourceId: 'Online',
      organizationType: null,
      commenceDate: null,
      ceaseDate: null,
    },
  ],
};

export const getEntityRequestDefaults = {
  id: null,
  status: 'Ok',
};

export const radioOptions = [
  { id: '1', value: 'true', label: 'Yes' },
  { id: '2', value: 'false', label: 'No' },
];

export const defaultSectionsConfig: EntityStepsConfig[] = [
  { step: 0, name: 'others', isRequired: true, showOptional: false },
  { step: 1, name: 'names', isRequired: true, showOptional: false },
  { step: 2, name: 'identifiers', isRequired: true, showOptional: false },
  { step: 3, name: 'addresses', isRequired: true, showOptional: false },
  { step: 4, name: 'phonenumbers', isRequired: true, showOptional: false },
  { step: 4, name: 'emailaddresses', isRequired: true, showOptional: false },
];

export function getDefault(key: string) {
  if (key in getEntity) {
    const attrName = key as keyof typeof getEntity;
    const defaultSection = getEntity?.[attrName];
    if (defaultSection) {
      return defaultSection[0];
    }
  }
  return {};
}
