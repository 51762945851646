import { useEffect, useState, useRef } from 'react';

import { Grid } from '@mui/material';
import { LogixGroupType, MessageType } from '@revenue-solutions-inc/revxcoreui';
import ProgressLoader from '@revenue-solutions-inc/revxcoreui/material/controls/ProgressLoader';
import Loading from 'components/Loading';
import { useTranslation } from 'react-i18next';
import {
  useCreateChannelMutation,
  useUpdateChannelObjectMutation,
  useGetChannelQuery,
  CmChannel,
  LogiXgroupInput,
  ConfigurationResponse,
  useCreateAndPublishChannelMutation,
  useUpdateAndPublishChannelMutation,
  useGetConfigurationRecordsQuery,
  useQueryPlatformOrModuleQuery,
  QueryPlatformOrModuleQueryVariables,
} from 'generated/graphql';
import { IHeader, setHeader } from 'redux/contentSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { JsonPath } from 'utils/filterJsonData';
import { addMessage } from 'redux/messageSlice';
import { ChannelInput, Channel, channelObject } from 'types/channels';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, useWatch } from 'react-hook-form';
import FormDataMapper from 'pages/admin/CreateForms/FormDataMapper';
import { getChannel } from './defaultChannel';
import ChannelLogix from '../ChannelLogix';
import ChannelDetails from '../ChannelDetails';
import ChannelSchedule from '../ChannelSchedule';
import ChannelSchemaDefintion from '../ChannelSchemaDefintion';

enum ActionType {
  EDIT = 'edit',
  CREATE = 'create',
}

interface ActionProps {
  name: string;
  category: string;
  repeatable: boolean;
  statusToBeChanged: string;
  domain: string;
  operationId: string;
  operationType: string;
}

function CreateChannel(): JSX.Element {
  const { t } = useTranslation();
  const module = useAppSelector((state) => state.user.module);
  const channelForm: Channel = getChannel;
  const { watch, getValues, setValue, control } = useForm<Channel>({
    defaultValues: channelForm,
    mode: 'onChange',
  });

  const { mutate, isLoading } = useCreateChannelMutation();

  const { mutate: createPublishChannel, isLoading: createLoading } =
    useCreateAndPublishChannelMutation();

  const { mutate: updatePublishChannel, isLoading: updateLoading } =
    useUpdateAndPublishChannelMutation();

  const { mutate: mutateEdit, isLoading: editIsLoading } =
    useUpdateChannelObjectMutation();
  const { channelId, channelName, currentModule, action } = useParams() as {
    channelId: string;
    channelName: string;
    action: string;
    currentModule: string;
  };
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [completed, setCompleted] = useState<{
    [k: number]: boolean;
  }>({});

  const [channel, setChannel] = useState<Channel>(channelObject);
  const [currentStep, setCurrentStep] = useState(0);
  const [isPipelineDetailsValid, setPipelineDetailsValid] = useState(false);
  const [isChannelScheduleValid, setChannelScheduleValid] = useState(false);
  const [isChannelSchemaDefValid, setChannelSchemaDefValid] = useState(false);
  const [currentData, setCurrentData] = useState<ConfigurationResponse[]>([]);
  const [LogixGroup, setLogixGroup] = useState<LogiXgroupInput | null>(null);
  const [showSchedule, setShowSchedule] = useState<boolean>(false);
  const [checkedValue, setCheckedValue] = useState<string>('no');
  const [displayFields, setDisplayFields] = useState<boolean>(false);
  const [delimiterValue, setDelimiterValue] = useState<string>('');
  const [uploadFileChannel, setUploadFileChannel] = useState<boolean>(false);
  const [stepPassedValidation, setStepPassedValidation] =
    useState<boolean>(true);
  const [actionsTypes, setActionsTypes] = useState<ActionProps[] | []>([]);
  const refBtnDM = useRef<HTMLButtonElement>(null);

  const { data: value, isLoading: getLoading } = useGetChannelQuery(
    {
      channelName,
      channelId,
      module: currentModule,
    },
    {
      enabled: !!channelName && !!channelId,
    }
  );

  const channelModuleName = useWatch({
    control,
    name: 'Channel.Module',
  });

  const { data: moduleResult } = useQueryPlatformOrModuleQuery<{
    Modules: [QueryPlatformOrModuleQueryVariables];
  }>();

  useEffect(() => {
    if (moduleResult) {
      const currentModuleData = moduleResult.Modules.find(
        (mod) => mod.name === channelModuleName
      );

      if (currentModuleData) {
        setValue('Channel.ModuleId', currentModuleData.moduleId);
      } else {
        setValue('Channel.ModuleId', 1);
      }
    }
  }, [moduleResult, channelModuleName, setValue]);

  useEffect(() => {
    if (value) {
      setDisplayFields(true);
      setValue(`Channel`, value.getChannel as unknown as ChannelInput);
      setLogixGroup(value.getChannel.LogixGroup as unknown as LogiXgroupInput);
      setValue(
        `Channel.Pipeline.Parameters`,
        value.getChannel.Pipeline.Parameters
      );

      setValue(`Channel.Module`, value.getChannel.Module);
    }
    if (value?.getChannel.Pipeline.PipelineSchedule === null) {
      setValue(`Channel.Pipeline.PipelineSchedule`, []);
    }
    if (value?.getChannel.Pipeline.PipelineSchedule) {
      setCheckedValue('yes');
      setShowSchedule(true);
    }
  }, [setValue, value]);

  function mapChannel() {
    const newChannel = { ...channel };
    newChannel.Channel = getValues(`Channel`);
    newChannel.Channel.LogixGroup = LogixGroup;
    if (delimiterValue !== '') {
      newChannel.Channel.FileGroup.DelimiterValue = delimiterValue;
    }
    return newChannel;
  }

  const handleReset = () => {
    setCurrentStep(0);
    setCompleted({});
  };

  const createChannel = () => {
    mutate(
      {
        channel: mapChannel() as unknown as CmChannel,
      },
      {
        onSuccess: () => {
          dispatch(
            addMessage({
              type: MessageType.Success,
              message: t('pages.manageChannel.success'),
            })
          );
          setChannel(channelObject);

          navigate(`/${module}/managechannels`);
        },
        onError: () => {
          dispatch(
            addMessage({
              type: MessageType.Error,
              message: t('components.message.networkerror'),
            })
          );
        },
      }
    );
  };

  const saveChannel = () => {
    createPublishChannel(
      {
        channel: mapChannel() as unknown as CmChannel,
      },
      {
        onSuccess: () => {
          dispatch(
            addMessage({
              type: MessageType.Success,
              message: t('pages.manageChannel.success'),
            })
          );
          setChannel(channelObject);

          navigate(`/${module}/managechannels`);
        },
        onError: () => {
          dispatch(
            addMessage({
              type: MessageType.Error,
              message: t('components.message.networkerror'),
            })
          );
        },
      }
    );
  };

  const { data: actionTypesQuery } = useGetConfigurationRecordsQuery({
    configurationModule: '',
    configurationType: 'Form Action Type',
  });

  useEffect(() => {
    if (actionTypesQuery && actionTypesQuery.getConfigurations) {
      const acTypes: ActionProps[] = [];
      actionTypesQuery.getConfigurations.forEach((acType) => {
        const logixFields = JsonPath({
          keyName: 'groupName',
          value: 'Configuration',
          schema: acType,
          searchWith: '',
        });
        const objAct = {
          actionCategory: '',
          actionRepetable: false,
          actionStatus: '',
          actionDomain: '',
          actionOperationType: '',
          actionOperationId: '',
        };
        if (logixFields.length > 0) {
          objAct.actionOperationType =
            logixFields[0].value.attribute.find(
              (it: { attributeName: string }) =>
                it.attributeName === 'OperationType'
            )?.attributeValue ?? '';
          objAct.actionDomain =
            logixFields[0].value.attribute.find(
              (it: { attributeName: string }) => it.attributeName === 'Domain'
            )?.attributeValue ?? '';
          objAct.actionStatus =
            logixFields[0].value.attribute.find(
              (it: { attributeName: string }) =>
                it.attributeName === 'StatusToBeChanged'
            )?.attributeValue ?? false;
          objAct.actionCategory =
            logixFields[0].value.attribute.find(
              (it: { attributeName: string }) => it.attributeName === 'Category'
            )?.attributeValue ?? '';
          objAct.actionRepetable =
            logixFields[0].value.attribute.find(
              (it: { attributeName: string }) =>
                it.attributeName === 'Repeatable'
            )?.attributeValue ?? false;

          objAct.actionOperationId =
            logixFields[0].value.attribute.find(
              (it: { attributeName: string }) =>
                it.attributeName === 'OperationId'
            )?.attributeValue ?? '';
        }
        const actionValues: ActionProps = {
          name: acType.configurationName,
          category: objAct.actionCategory,
          repeatable: Boolean(objAct.actionRepetable),
          statusToBeChanged: objAct.actionStatus,
          domain: objAct.actionDomain,
          operationId: objAct.actionOperationId,
          operationType: objAct.actionOperationType,
        };
        acTypes.push(actionValues);
      });
      setActionsTypes(acTypes);
    }
  }, [actionTypesQuery]);

  const updateChannel = () => {
    updatePublishChannel(
      {
        channel: mapChannel() as unknown as CmChannel,
      },
      {
        onSuccess: () => {
          dispatch(
            addMessage({
              type: MessageType.Success,
              message: t('pages.manageChannel.success'),
            })
          );
          setChannel(channelObject);

          navigate(`/${module}/managechannels`);
        },
        onError: () => {
          dispatch(
            addMessage({
              type: MessageType.Error,
              message: t('components.message.networkerror'),
            })
          );
        },
      }
    );
  };

  const editChannel = () => {
    mutateEdit(
      {
        channel: mapChannel() as unknown as CmChannel,
      },
      {
        onSuccess: () => {
          dispatch(
            addMessage({
              type: MessageType.Success,
              message: t('pages.manageChannel.update'),
            })
          );
          setChannel(channelObject);
          navigate(`/${module}/managechannels`);
        },
        onError: () => {
          dispatch(
            addMessage({
              type: MessageType.Error,
              message: t('pages.manageChannel.error'),
            })
          );
        },
      }
    );
  };

  useEffect(() => {
    if (action === ActionType.CREATE) {
      const headerData: IHeader = {
        pageTitle: t('pages.manageChannel.newChannel'),
        previousPage: t('pages.manageChannel.title'),
        route: 'manageChannels',
      };
      dispatch(setHeader(headerData));
    }
    if (action === ActionType.EDIT && !getLoading && value) {
      const headerData: IHeader = {
        pageTitle: t('pages.manageChannel.editChannel'),
        previousPage: t('pages.manageChannel.title'),
        route: `managechannels/`,
        icon: {
          props: { fill: 'black' },
          icon: 'assignmentIcon',
          fontSize: 'large',
        },
        data: [
          {
            id: 'task-display-name',
            first: true,
            label: t('pages.manageChannel.channelName'),
            value: value?.getChannel.ChannelName,
          },
          {
            id: 'current-version',
            label: t('pages.manageChannel.currentVersion'),
            value: value?.getChannel.Version
              ? value?.getChannel.Version?.toString()
              : '-',
          },
          {
            id: 'current-status',
            label: t('pages.manageChannel.currentStatus'),
            value: value?.getChannel.Status,
          },
        ],
      };
      dispatch(setHeader(headerData));
    }
  }, [dispatch, action, t, getLoading, value]);

  useEffect(() => {
    const newCompleted = completed;
    if (isPipelineDetailsValid) newCompleted[currentStep] = true;

    switch (currentStep) {
      case 0:
        newCompleted[currentStep] = isPipelineDetailsValid;
        break;
      case 1:
        newCompleted[currentStep] = isChannelSchemaDefValid;
        break;
    }

    setCompleted(newCompleted);
  }, [
    completed,
    currentStep,
    isPipelineDetailsValid,
    isChannelScheduleValid,
    isChannelSchemaDefValid,
  ]);

  const validateSteps = () => {
    let isValid = true;

    switch (currentStep) {
      case 0:
        isValid = isPipelineDetailsValid;
        break;
      case 1:
        isValid = isChannelSchemaDefValid;
    }
    setStepPassedValidation(isValid);
  };

  useEffect(() => {
    validateSteps();
  });

  const handleMappedLogixGroupSchema = (logixUpdated: LogixGroupType) => {
    setLogixGroup(logixUpdated as LogiXgroupInput);
    return logixUpdated;
  };

  return (!isLoading && action === ActionType.CREATE && !createLoading) ||
    (!editIsLoading &&
      action === ActionType.EDIT &&
      !getLoading &&
      !updateLoading) ? (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ProgressLoader
            completed={completed}
            handleReset={handleReset}
            setCurrentStep={setCurrentStep}
            handleStepTransition={(cStep: number, previousStep: number) => {
              if (
                (cStep === 2 && previousStep === 3) ||
                (cStep === 4 && previousStep === 3)
              ) {
                refBtnDM.current?.click();
              }
            }}
            steps={[
              t('pages.manageChannel.channelDetails'),
              t('pages.manageChannel.sourceSchemaDef'),
              t('pages.manageChannel.logix'),
              t('pages.manageChannel.dataMapper'),
              t('pages.manageChannel.scheduleCreation'),
            ]}
            nonLinear={false}
            currentStep={currentStep}
            handleCurrentStep={(activeStep: number) => {
              setCurrentStep(activeStep);
            }}
            centerAlign
            handleSaveDraft={
              action === ActionType.EDIT ? editChannel : createChannel
            }
            handleSave={
              action === ActionType.EDIT ? updateChannel : saveChannel
            }
            isCompleteStepBtnDisabled={!stepPassedValidation}
            isSaveAndCreateDisabled={!isChannelScheduleValid}
            draftButton={true}
            draftButtonText={t('pages.manageChannel.saveDraft')}
            saveBtnText={t('pages.manageChannel.publish')}
          >
            {currentStep === 0 && (
              <ChannelDetails
                setPipelineDetailsValid={setPipelineDetailsValid}
                control={control}
                setValue={setValue}
              />
            )}
            {currentStep === 1 && (
              <ChannelSchemaDefintion
                displayFields={displayFields}
                setDisplayFields={setDisplayFields}
                uploadFileChannel={uploadFileChannel}
                setUploadFileChannel={setUploadFileChannel}
                setChannelSchemaDef={setChannelSchemaDefValid}
                control={control}
                watch={watch}
                setValue={setValue}
                getValues={getValues}
                setDelimiterValue={setDelimiterValue}
                delimiterValue={delimiterValue}
              />
            )}
            {currentStep === 2 && (
              <ChannelLogix
                displayFields={displayFields}
                uploadFileChannel={uploadFileChannel}
                setLogixGroup={setLogixGroup}
                LogixGroup={LogixGroup}
                getValues={getValues}
              />
            )}
            {currentStep === 3 && (
              <FormDataMapper
                LogixGroup={LogixGroup}
                handleMappedLogixGroupSchema={handleMappedLogixGroupSchema}
                actions={actionsTypes}
                refBtnDM={refBtnDM}
              />
            )}
            {currentStep === 4 && (
              <ChannelSchedule
                taskSchedule={false}
                setShowSchedule={setShowSchedule}
                showSchedule={showSchedule}
                setChannelScheduleValid={setChannelScheduleValid}
                checkedValue={checkedValue}
                currentData={currentData}
                setCurrentData={setCurrentData}
                setCheckedValue={setCheckedValue}
                control={control}
                setValue={setValue}
                watch={watch}
              />
            )}
          </ProgressLoader>
        </Grid>
      </Grid>
    </>
  ) : (
    <Loading />
  );
}

export default CreateChannel;
