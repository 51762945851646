import { useEffect, useState } from 'react';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SearchIcon from '@mui/icons-material/Search';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import NavigationIcon from '@mui/icons-material/Navigation';
import {
  Avatar,
  Box,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Tooltip,
} from '@mui/material';
import NavSidebar from '@revenue-solutions-inc/revxcoreui/material/layout/Sidebar';
import AuthenticatedComponent from 'components/AuthenticatedComponent';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from 'redux/hooks';
import { useSelector } from 'react-redux';
import { setModule } from 'redux/userSlice';

import HeaderLogo from '@revenue-solutions-inc/revxcoreui/material/layout/HeaderLogo';
import { FavoritesResponse } from 'generated/graphql';
import { useHasAccess } from 'hooks/useHasAccess';
import { Stack, useTheme } from '@mui/system';

import HandymanIcon from '@mui/icons-material/Handyman';
import FactoryIcon from '@mui/icons-material/Factory';
import { RootState } from '../../../redux/store';

export interface ConfigItem {
  id: string;
  label: string;
  route?: string;
  items?: ConfigItem[];
}
export interface SidebarProps {
  handleWidth: (arg: number) => void;
  width: number;
}

export const selectLabelStyle = () => {
  return {
    backgroundColor: '#0071FF',
    height: '48px',
    width: '48px',
  };
};

function Sidebar({ handleWidth, width }: SidebarProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const favorites = useAppSelector(
    (state) => state.user.userPreferences.favorites
  );
  const module = useAppSelector((state) => state.user.module);
  const [open, setOpen] = useState<{ [key: string]: boolean }>({
    favorites: false,
  });
  const [bookmarks, setBookmarks] = useState<FavoritesResponse[]>([]);
  const [isDashboardFocused, setDashboardFocused] = useState<boolean>(false);
  const [isSearchFocused, setSearchFocused] = useState<boolean>(false);
  const [isNavigationFocused, setNavigationFocused] = useState<boolean>(false);
  const [isBookmarksFocused, setBookmarksFocused] = useState<boolean>(false);

  // setting tenant && environment names from content slice
  const envName = useSelector<RootState>(
    (state) => state.content.environmentName
  );
  const tenantName = useSelector<RootState>(
    (state) => state.content.tenantName
  );

  useEffect(() => {
    if (favorites) {
      setBookmarks(favorites);
    }
  }, [favorites, module]);

  // Sidebar can use useHasAccess since inside render has no any loop or conditional
  const PolicyGroupExist = (componentName: string): boolean => {
    return useHasAccess(componentName, 'view');
  };

  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    const newOpen = { ...open };
    Object.keys(newOpen).forEach((key) =>
      key !== event.currentTarget.id
        ? (newOpen[key] = false)
        : (newOpen[key] = !newOpen[key])
    );
    setOpen({
      ...newOpen,
    });
  };
  const menuItems = (
    <AuthenticatedComponent>
      <List
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          '&:hover': {
            color: 'white !important',
          },
        }}
      >
        <ListItem disablePadding>
          <ListItemButton
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
            component={Link}
            to={`/${module}/dashboard`}
            aria-label={t('pages.dashboard.title')}
            disabled={!PolicyGroupExist('dashboard')}
            onFocus={() => setDashboardFocused(true)}
            onBlur={() => setDashboardFocused(false)}
          >
            <ListItemIcon sx={{ minWidth: 0 }}>
              <Avatar
                data-testid="drawer-id"
                sx={
                  location.pathname === `/${module}/dashboard`
                    ? selectLabelStyle
                    : {
                        height: '48px',
                        width: '48px',
                        backgroundColor: isDashboardFocused
                          ? theme.palette.linkBlue.main
                          : 'primary.main',
                        ':hover': {
                          backgroundColor: theme.palette.linkBlue.main,
                        },
                      }
                }
              >
                <DashboardIcon fontSize="large" htmlColor="white.main" />
              </Avatar>
            </ListItemIcon>
            {width === 160 && (
              <Typography sx={{ color: theme.palette.white.main }}>
                {' '}
                {t('pages.dashboard.title')}
              </Typography>
            )}
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            sx={{
              display: module?.toLowerCase() !== 'admin' ? 'flex' : 'none',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
            component={Link}
            to={`/${module}/search`}
            aria-label={t('pages.search.title')}
            disabled={!PolicyGroupExist('search')}
            onFocus={() => setSearchFocused(true)}
            onBlur={() => setSearchFocused(false)}
          >
            <ListItemIcon sx={{ minWidth: 0 }}>
              <Avatar
                sx={
                  location.pathname === `/${module}/search`
                    ? selectLabelStyle
                    : {
                        height: '48px',
                        width: '48px',
                        backgroundColor: isSearchFocused
                          ? theme.palette.linkBlue.main
                          : 'primary.main',
                        ':hover': {
                          backgroundColor: theme.palette.linkBlue.main,
                        },
                      }
                }
              >
                <SearchIcon fontSize="large" htmlColor="white.main" />
              </Avatar>
            </ListItemIcon>
            {width === 160 && (
              <Typography sx={{ color: theme.palette.white.main }}>
                {t('pages.search.title')}
              </Typography>
            )}
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
            component={Link}
            to={`/${module}/navigation`}
            aria-label={t('pages.navigation.title')}
            disabled={!PolicyGroupExist('navigation')}
            onFocus={() => setNavigationFocused(true)}
            onBlur={() => setNavigationFocused(false)}
          >
            <ListItemIcon sx={{ minWidth: 0 }}>
              <Avatar
                sx={
                  location.pathname === `/${module}/navigation`
                    ? selectLabelStyle
                    : {
                        height: '48px',
                        width: '48px',
                        backgroundColor: isNavigationFocused
                          ? theme.palette.linkBlue.main
                          : 'primary.main',
                        ':hover': {
                          backgroundColor: theme.palette.linkBlue.main,
                        },
                      }
                }
              >
                <NavigationIcon fontSize="large" htmlColor="white.main" />
              </Avatar>
            </ListItemIcon>
            {width === 160 && (
              <Typography sx={{ color: theme.palette.white.main }}>
                {t('pages.navigation.title')}
              </Typography>
            )}
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding sx={{ display: 'block' }}>
          <ListItemButton
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'end',
            }}
            id={'favorites'}
            onClick={handleClick}
            disabled={!PolicyGroupExist('bookmarks')}
            component={Link}
            to={`/${module}/bookmarks`}
            aria-label={t('pages.bookmarks.title')}
            onFocus={() => setBookmarksFocused(true)}
            onBlur={() => setBookmarksFocused(false)}
          >
            <ListItemIcon sx={{ minWidth: 0 }}>
              <Avatar
                sx={
                  location.pathname === `/${module}/bookmarks`
                    ? selectLabelStyle
                    : {
                        height: '48px',
                        width: '48px',
                        backgroundColor: isBookmarksFocused
                          ? theme.palette.linkBlue.main
                          : 'primary.main',
                        ':hover': {
                          backgroundColor: theme.palette.linkBlue.main,
                        },
                      }
                }
              >
                <CollectionsBookmarkIcon
                  fontSize="large"
                  htmlColor="white.main"
                />
              </Avatar>
            </ListItemIcon>
            {width === 160 && (
              <Box
                component="span"
                sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent:
                    bookmarks && bookmarks.length ? 'flex-end' : 'center',
                }}
              >
                <Typography
                  sx={{
                    color: theme.palette.white.main,
                    paddingRight: bookmarks && bookmarks.length ? '7px' : 0,
                  }}
                >
                  {t('pages.bookmarks.title')}
                </Typography>
                {bookmarks && bookmarks.length ? (
                  open.favorites ? (
                    <ExpandLess color="secondary" />
                  ) : (
                    <ExpandMore color="secondary" />
                  )
                ) : (
                  <></>
                )}
              </Box>
            )}
          </ListItemButton>
          {width === 160 && (
            <Collapse in={open.favorites} timeout="auto" unmountOnExit>
              <List
                sx={{
                  backgroundColor: 'linkBlue.dark',
                }}
                component="div"
                disablePadding
              >
                {bookmarks?.map((subList, index) =>
                  index < 5 ? (
                    <ListItem
                      button
                      key={subList.url}
                      component={Link}
                      to={subList.url}
                      onClick={() => {
                        dispatch(
                          setModule(subList.url.split('/')[1].toLowerCase())
                        );
                      }}
                      sx={{
                        '&:hover': {
                          backgroundColor: '#ff6055',
                        },
                      }}
                    >
                      <ListItemText
                        primary={t(
                          subList.pageName && subList.pageDescription
                            ? `${subList.pageName} - ${subList.pageDescription}`
                            : `${subList.pageName}`
                        )}
                        primaryTypographyProps={{
                          color: 'secondary.main',
                        }}
                        sx={{
                          '.MuiTypography-root': {
                            marginLeft: '8px',
                          },
                        }}
                      />
                    </ListItem>
                  ) : (
                    <></>
                  )
                )}
              </List>
            </Collapse>
          )}
        </ListItem>
        {/*  Environment Icon  */}
        <ListItem
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end', // aligns final Icon to bottom of sidebar panel DON'T TOUCH
            flexGrow: 1,
          }}
        >
          <Stack>
            <ListItemIcon
              sx={{
                minWidth: 0,
                margin: 'auto',
              }}
            >
              <Tooltip
                title={
                  `${envName}`.toLowerCase() === 'prd'
                    ? 'Production'
                    : `${envName} - ${tenantName}`
                }
                placement="top-start"
                role="tooltip"
              >
                <Avatar
                  sx={() => {
                    return {
                      backgroundColor: theme.palette.primary.main,
                      height: '48px',
                      width: '48px',
                    };
                  }}
                >
                  {(envName as string)?.toLowerCase() === 'prd' ? (
                    <FactoryIcon fontSize="large" htmlColor="white.main" />
                  ) : (
                    <HandymanIcon fontSize="large" htmlColor="white.main" />
                  )}
                </Avatar>
              </Tooltip>
            </ListItemIcon>
            {width === 160 && (
              <Box>
                <Typography
                  sx={{
                    color: theme.palette.white.main,
                    fontSize: 12,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                  }}
                >
                  {`${envName}`.toLowerCase() === 'prd'
                    ? 'Production'
                    : `${envName}`}
                </Typography>
                {`${envName}`.toLowerCase() === 'prd' ? (
                  <></>
                ) : (
                  <Typography
                    sx={{
                      color: theme.palette.white.main,
                      fontSize: 12,
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                    }}
                  >
                    {` ${tenantName}`}
                  </Typography>
                )}
              </Box>
            )}
          </Stack>
        </ListItem>
      </List>
    </AuthenticatedComponent>
  );
  return (
    <nav>
      <NavSidebar
        sideBarMarginTop={120}
        menuList={menuItems}
        handleWidth={handleWidth}
        openWidth={160}
        closedWidth={80}
        headerLogo={<HeaderLogo />}
      />
    </nav>
  );
}

export default Sidebar;
