import { useEffect } from 'react';

import { Card } from '@revenue-solutions-inc/revxcoreui';
import CardContent from '@mui/material/CardContent';
import AccountLayout from 'components/entityManagement/accounts/create/AccountLayout';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { setHeader } from 'redux/contentSlice';
import { useAppDispatch } from 'redux/hooks';

function CreateAccount(): JSX.Element {
  const { entityId } = useParams() as { entityId: string };
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      setHeader({
        pageTitle: t('pages.createAccount.title'),
        previousPage: t('pages.entitySummary.title'),
        route: `entity\\${entityId}`,
      })
    );
  }, [dispatch, entityId, t]);

  return (
    <Card>
      <CardContent>
        <AccountLayout entityId={entityId} />
      </CardContent>
    </Card>
  );
}

export default CreateAccount;
