/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext, ReactFragment, ReactPortal } from 'react';
import { feinValExp, itinValExp, ssnValExp, vinValExp } from 'common/regexp';
import { IdFormats, IdMaxLengths, IdValidationRules } from 'types/entities';

export const OOTBIdFormats: IdFormats = {
  SSN: '^(\\d{3})(\\d{2})(\\d{4})$',
  FEIN: '^(\\d{2})(\\d{7})$',
  ITIN: '^(\\d{3})(\\d{2})(\\d{4})$',
  VIN: '^(\\b[(A-H|J-N|P|R-Z|0-9)]{17}\\b)$',
};

export const OOTBIdValidationRules: IdValidationRules = {
  SSN: ssnValExp,
  FEIN: feinValExp,
  ITIN: itinValExp,
  VIN: vinValExp,
};

export const OOTBIdMaxLength: IdMaxLengths = {
  SSN: 9,
  FEIN: 9,
  ITIN: 9,
};

const EntityManagementContext = createContext({
  selectedType: '',
  selectedIdType: '',
  selectedCommenceDate: '',
  onTypeChange: (_type: string) => {},
  onIdTypeChange: (_type: string) => {},
  onCommenceDateChange: (_type: string) => {},
  idValidationRules: OOTBIdValidationRules,
  idFormats: OOTBIdFormats,
  idMaxLengths: OOTBIdMaxLength,
});

export const EntityManagementContextProvider = (props: {
  children:
    | string
    | number
    | boolean
    | ReactFragment
    | ReactPortal
    | null
    | undefined;
}) => {
  return (
    <EntityManagementContext.Provider
      value={{
        selectedType: '',
        selectedIdType: '',
        selectedCommenceDate: '',
        onTypeChange: (_type: string) => {},
        onIdTypeChange: (_type: string) => {},
        onCommenceDateChange: (_type: string) => {},
        idValidationRules: {},
        idFormats: OOTBIdFormats,
        idMaxLengths: OOTBIdMaxLength,
      }}
    >
      {props.children}
    </EntityManagementContext.Provider>
  );
};

export default EntityManagementContext;
