import NoResults from 'components/NoResults';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { RoleByCustomerBusinessPolicyIdResponse } from 'generated/graphql';

interface RolesByCustomerBusinessPolicyIdInfoProps {
  isLoading: boolean;
  roles: RoleByCustomerBusinessPolicyIdResponse[];
}

const RolesByCustomerBusinessPolicyIdInfo = ({
  isLoading,
  roles,
}: RolesByCustomerBusinessPolicyIdInfoProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <>
      {roles && roles.length > 0 && (
        <>
          <Typography
            variant="h3"
            sx={{
              fontSize: 16,
              fontWeight: 'bold',
              marginTop: 1,
              marginBottom: 1,
            }}
          >
            {t('pages.businessPolicies.assignedRoles')}
          </Typography>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            {roles.map(
              ({ id, name }: RoleByCustomerBusinessPolicyIdResponse) => (
                <span key={id} style={{ width: '50%', marginBottom: '10pxS' }}>
                  {name}
                </span>
              )
            )}
          </div>
        </>
      )}
      {!isLoading && roles.length === 0 && <NoResults />}
    </>
  );
};

export default RolesByCustomerBusinessPolicyIdInfo;
