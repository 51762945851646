import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

interface Props {
  open?: boolean;
  fullScreen?: boolean;
  /** The size in pixels, default is 40.
   * If using a number, the pixel unit is assumed.
   * If using a string, you need to provide the CSS unit, e.g '3rem'.
   */
  size?: number | string;
}

function Loading({
  open = true,
  fullScreen = true,
  size = 40,
}: Props): JSX.Element {
  return (
    <div data-testid="loading">
      {fullScreen ? (
        <Backdrop open={open}>
          <CircularProgress color="inherit" size={size} />
        </Backdrop>
      ) : (
        <CircularProgress color="inherit" size={size} />
      )}
    </div>
  );
}

export default Loading;
