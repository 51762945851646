import { useEffect, useMemo, useState } from 'react';
import { Box, SelectChangeEvent } from '@mui/material';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import {
  MessageType,
  MessageActionType,
  Input,
} from '@revenue-solutions-inc/revxcoreui';
import { addMessage } from 'redux/messageSlice';
import {
  CorrespondenceTypeManagerResponse,
  ReusableContentTypeResponse,
  useCorrespondenceTypeCategoryQuery,
  useCreateCorrespondenceTypeMutation,
  useCreateRcTypeMutation,
} from 'generated/graphql';
import { useAppDispatch } from 'redux/hooks';
import Select, {
  SelectType,
} from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import Dialog from '@revenue-solutions-inc/revxcoreui/material/controls/Dialog';
import { useTranslation } from 'react-i18next';
import { useAddWorkflow } from 'hooks/workflow/useAddWorkflow';

type Props = {
  correspondence_data: CorrespondenceTypeManagerResponse[];
  rc_data: ReusableContentTypeResponse[];
  isFromTemplate: boolean;
  reFetch: () => void;
};
function CreateCorrespTypeAndHdrFtr(dataProps: Props) {
  const dispatch = useAppDispatch();
  const { mutate: createCorrespondence } =
    useCreateCorrespondenceTypeMutation();
  const { mutate: createRCType } = useCreateRcTypeMutation();
  const { data: corrTypeCategories, refetch: refetchCorrTypeCategories } =
    useCorrespondenceTypeCategoryQuery({}, { enabled: false, cacheTime: 0 });
  const { t } = useTranslation();
  const [corrTypeStates, setCorrTypeStates] = useState({
    openCorrespondence: false,
    typeName: '',
    category: '',
    contextLevel: '0',
    contextLevelError: false,
    typeNameError: false,
    typeCategoryError: false,
    isPresent: false,
    workflowError: false,
  });
  const [RCStates, setRCStates] = useState({
    openReusableContent: false,
    RCtypeName: '',
    RCcategory: '',
    RCtypeNameError: false,
    RCtypeCategoryError: false,
    RCisPresent: false,
  });
  const RCTypeCategory: SelectType[] = [
    { key: '0', desc: 'Header' },
    { key: '1', desc: 'Footer' },
  ];
  const allSelectType: SelectType = { key: '-1', desc: 'All' };
  const contextLevel: SelectType[] = [
    { key: '0', desc: 'Account' },
    { key: '1', desc: 'Billing' },
    { key: '2', desc: 'Entity' },
    { key: '3', desc: 'PaymentCoupon' },
    { key: '4', desc: 'Workflow' },
  ];
  const typeCategory: SelectType[] = useMemo(() => {
    const list: SelectType[] = [];
    corrTypeCategories?.CorrespondenceTypeCategory.map(
      (cat: { correspondenceTypeCategoryId: string; name: string }) => {
        list.push({
          key: cat.correspondenceTypeCategoryId,
          desc: cat.name,
        });
      }
    );
    return list;
  }, [corrTypeCategories]);

  const {
    groupOptions,
    variantOptions,
    groupSelected,
    setGroupSelected,
    variantSelected,
    setVariantSelected,
  } = useAddWorkflow();

  const handleCategory = (event: SelectChangeEvent<string | number>) => {
    {
      setRCStates((prev) => ({
        ...prev,
        RCtypeCategoryError: false,
      }));
      if (dataProps.isFromTemplate)
        setCorrTypeStates((prev) => ({
          ...prev,
          category: event.target.value as string,
        }));
      else
        setRCStates((prev) => ({
          ...prev,
          RCcategory: event.target.value as string,
        }));
    }
  };
  const handleContextLevel = (event: SelectChangeEvent<string | number>) => {
    {
      if (dataProps.isFromTemplate)
        setCorrTypeStates((prev) => ({
          ...prev,
          contextLevel: event.target.value as string,
        }));
    }
  };

  const handleTypeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (dataProps.isFromTemplate)
      setCorrTypeStates((prev) => ({
        ...prev,
        typeNameError: false,
      }));
    else
      setRCStates((prev) => ({
        ...prev,
        RCtypeNameError: false,
      }));

    if (event.target.value === ' ') {
      if (dataProps.isFromTemplate)
        setCorrTypeStates((prev) => ({
          ...prev,
          typeName: '',
        }));
      else
        setRCStates((prev) => ({
          ...prev,
          RCtypeName: '',
        }));
    } else if (dataProps.isFromTemplate)
      setCorrTypeStates((prev) => ({
        ...prev,
        typeName: event.target.value,
      }));
    else
      setRCStates((prev) => ({
        ...prev,
        RCtypeName: event.target.value,
      }));

    if (corrTypeStates.isPresent) {
      if (dataProps.isFromTemplate)
        setCorrTypeStates((prev) => ({
          ...prev,
          isPresent: false,
        }));
      else
        setRCStates((prev) => ({
          ...prev,
          isPresent: false,
        }));
    }
  };
  const handleCorrespondenceType = () => {
    createCorrespondence(
      {
        correspondenceType: {
          name: corrTypeStates.typeName,
          correspondenceTypeCategoryId:
            typeof corrTypeStates.category === 'number'
              ? corrTypeStates.category
              : parseInt(corrTypeStates.category),
          contextLevel: contextLevel.filter(
            (context) => context.key == corrTypeStates.contextLevel
          )[0].desc,
          workflowTypeId: parseInt(groupSelected),
          workflowSubTypeId: parseInt(variantSelected),
        },
      },
      {
        onSuccess: () => {
          dataProps.reFetch();
          dispatch(
            addMessage({
              message: t(
                'pages.correspondence.message.createCorrespondenceType'
              ),
              type: MessageType.Success,
              actionType: MessageActionType.None,
            })
          );
        },
        onError: () => {
          dispatch(
            addMessage({
              message: t('pages.manageReusableContent.networkError'),
              type: MessageType.Error,
              actionType: MessageActionType.None,
            })
          );
        },
      }
    );
  };
  const handleRCType = () => {
    createRCType(
      {
        reusableContentTypes: {
          name: RCStates.RCtypeName,
          reusableContentTypeCategory:
            RCStates.RCcategory === '0' ? 'Header' : 'Footer',
        },
      },
      {
        onSuccess: () => {
          dataProps.reFetch();
          dispatch(
            addMessage({
              message: t('pages.reusableContent.message.createRCType'),
              type: MessageType.Success,
              actionType: MessageActionType.None,
            })
          );
        },
        onError: () => {
          dispatch(
            addMessage({
              message: t('pages.manageReusableContent.networkError'),
              type: MessageType.Error,
              actionType: MessageActionType.None,
            })
          );
        },
      }
    );
  };
  const handleHelperTextChangeRC = () => {
    if (RCStates.RCtypeNameError && RCStates.RCtypeName === '') {
      return t('pages.manageReusableContent.typeNameError');
    } else if (RCStates.RCtypeName.length >= 50) {
      return t('pages.manageReusableContent.maxCharacterLimit');
    } else if (RCStates.RCisPresent) {
      return t('pages.manageReusableContent.alreadyPresentRecord');
    }
    return '';
  };
  const handleHelperTextChangeCorres = () => {
    if (corrTypeStates.typeNameError && corrTypeStates.typeName === '') {
      return t('pages.manageReusableContent.typeNameError');
    } else if (corrTypeStates.typeName.length >= 50) {
      return t('pages.manageReusableContent.maxCharacterLimit');
    } else if (corrTypeStates.isPresent) {
      return t('pages.manageReusableContent.alreadyPresentRecord');
    }
    return '';
  };
  const handleCorrTypeCreate = () => {
    const record = dataProps?.correspondence_data.find(
      (item) =>
        item.name.toLowerCase() === corrTypeStates.typeName.toLowerCase()
    );
    if (record) {
      setCorrTypeStates((prev) => ({
        ...prev,
        isPresent: true,
      }));
      return;
    } else
      setCorrTypeStates((prev) => ({
        ...prev,
        isPresent: false,
      }));

    if (!corrTypeStates.typeName) {
      setCorrTypeStates((prev) => ({
        ...prev,
        typeNameError: true,
      }));
    } else
      setCorrTypeStates((prev) => ({
        ...prev,
        typeNameError: false,
      }));
    if (!corrTypeStates.contextLevel) {
      setCorrTypeStates((prev) => ({
        ...prev,
        contextLevelError: true,
      }));
    } else
      setCorrTypeStates((prev) => ({
        ...prev,
        contextLevelError: false,
      }));
    if (
      corrTypeStates.contextLevel === '4' &&
      (!groupSelected || !variantSelected)
    ) {
      setCorrTypeStates((prev) => ({
        ...prev,
        workflowError: true,
      }));
      return;
    }
    if (corrTypeStates.typeName && corrTypeStates.typeName.length <= 50) {
      handleCorrespondenceType();
      setCorrTypeStates((prev) => ({
        ...prev,
        openCorrespondence: false,
        typeName: '',
        category: '',
        typeNameError: false,
        isPresent: false,
        workflowError: false,
      }));
    } else return;
  };
  const handleRCTypeCreate = () => {
    const record = dataProps?.rc_data.find(
      (item) => item.name.toLowerCase() === RCStates.RCtypeName.toLowerCase()
    );
    if (record) {
      setRCStates((prev) => ({
        ...prev,
        RCisPresent: true,
      }));
      return;
    } else
      setRCStates((prev) => ({
        ...prev,
        RCisPresent: false,
      }));

    if (!RCStates.RCtypeName) {
      setRCStates((prev) => ({
        ...prev,
        RCtypeNameError: true,
      }));
    } else
      setRCStates((prev) => ({
        ...prev,
        RCtypeNameError: false,
      }));
    if (!RCStates.RCcategory) {
      setRCStates((prev) => ({
        ...prev,
        RCtypeCategoryError: true,
      }));
    } else
      setRCStates((prev) => ({
        ...prev,
        RCtypeCategoryError: false,
      }));
    if (
      RCStates.RCtypeName &&
      RCStates.RCtypeName.length <= 50 &&
      RCStates.RCcategory.length > 0
    ) {
      handleRCType();
      setRCStates((prev) => ({
        ...prev,
        openReusableContent: false,
        RCtypeName: '',
        RCcategory: '',
        RCtypeNameError: false,
        RCisPresent: false,
      }));
    } else return;
  };
  const CorrespondenceTypeChildren = (
    <Box sx={{ display: 'grid' }}>
      <Input
        id="correspondenceTypeName"
        label={t('pages.correspondence.typeName')}
        value={corrTypeStates.typeName}
        placeholder={t('pages.correspondence.typeName')}
        onChange={handleTypeName}
        required={true}
        error={
          (corrTypeStates.typeNameError && corrTypeStates.typeName === '') ||
          corrTypeStates.typeName.length >= 50
        }
        helperText={handleHelperTextChangeCorres()}
      />
      <Select
        options={typeCategory}
        id="category"
        data-testid="content-select"
        selectLabelStyle={{
          color: 'black.light',
        }}
        sx={{
          '.MuiSelect-select': {
            color: 'black.light',
          },
        }}
        label={t('pages.correspondence.typeCategory')}
        onChange={handleCategory}
        value={corrTypeStates.category}
        width="25em"
      />
      <Select
        options={contextLevel}
        id="contextLevel"
        data-testid="content-select"
        selectLabelStyle={{
          color: 'black.light',
        }}
        sx={{
          '.MuiSelect-select': {
            color: 'black.light',
          },
        }}
        required={true}
        label={t('pages.correspondence.contextLevel')}
        onChange={handleContextLevel}
        value={corrTypeStates.contextLevel}
        width="25em"
        error={
          corrTypeStates.contextLevelError && corrTypeStates.contextLevel === ''
            ? t('pages.manageReusableContent.contextError')
            : ''
        }
      />
      {corrTypeStates.contextLevel === '4' && (
        <>
          {
            <Select
              label={t('pages.correspondence.workflow.type')}
              id="workflow-group-select"
              value={groupSelected}
              selectLabelStyle={{
                color: 'black.light',
              }}
              sx={{
                '.MuiSelect-select': {
                  color: 'black.light',
                },
              }}
              required={true}
              onChange={(e) => setGroupSelected(String(e.target.value))}
              options={groupOptions ?? []}
              error={
                corrTypeStates.workflowError && !groupSelected
                  ? t('pages.manageReusableContent.workflowGroupRequired')
                  : ''
              }
            />
          }

          <Select
            label={t('pages.correspondence.workflow.subType')}
            id="workflow-variant-select"
            value={variantSelected}
            disabled={!groupSelected}
            selectLabelStyle={{
              color: 'black.light',
            }}
            sx={{
              '.MuiSelect-select': {
                color: 'black.light',
              },
            }}
            required={true}
            onChange={(e) => setVariantSelected(String(e.target.value))}
            options={[allSelectType, ...(variantOptions ?? [])] ?? []}
            error={
              corrTypeStates.workflowError && groupSelected && !variantSelected
                ? t('pages.manageReusableContent.workflowSubGroupRequired')
                : ''
            }
          />
        </>
      )}
    </Box>
  );
  const ReusableContentChildren = (
    <Box sx={{ display: 'grid' }}>
      <Input
        id="RCTypeName"
        label={t('pages.reusableContent.typeName')}
        value={RCStates.RCtypeName}
        placeholder={t('pages.reusableContent.typeName')}
        onChange={handleTypeName}
        required={true}
        error={
          (RCStates.RCtypeNameError && RCStates.RCtypeName === '') ||
          RCStates.RCtypeName.length >= 50
        }
        helperText={handleHelperTextChangeRC()}
      />
      <Select
        id="category"
        options={RCTypeCategory}
        data-testid="content-select"
        label={t('pages.reusableContent.rctypeCategory')}
        selectLabelStyle={{
          '& .MuiFormLabel-asterisk': {
            color: 'black.light',
          },
          color: 'black.light',
        }}
        sx={{
          '.MuiSelect-select': {
            color: 'black.light',
          },
        }}
        required={true}
        onChange={handleCategory}
        value={RCStates.RCcategory}
        width="25em"
        error={
          RCStates.RCtypeCategoryError && RCStates.RCcategory === ''
            ? t('pages.manageReusableContent.categoryError')
            : ''
        }
      />
    </Box>
  );

  useEffect(() => {
    if (dataProps.isFromTemplate) {
      refetchCorrTypeCategories();
    }
  }, [dataProps.isFromTemplate, refetchCorrTypeCategories]);
  return (
    <>
      {dataProps.isFromTemplate ? (
        <Dialog
          id="dialogId"
          open={corrTypeStates.openCorrespondence}
          children={CorrespondenceTypeChildren}
          title={t('pages.correspondence.title')}
          type="transactional"
          transactionModalTransactionButtonText="Create"
          width={360}
          handleClose={() => {
            setCorrTypeStates((prev) => ({
              ...prev,
              openCorrespondence: false,
              typeName: '',
              category: '',
              typeNameError: false,
              contextLevelError: false,
              isPresent: false,
              contextLevel: '0',
              workflowError: false,
            }));
            setGroupSelected('');
            setVariantSelected('');
          }}
          handleCancelClick={() => {
            setCorrTypeStates((prev) => ({
              ...prev,
              openCorrespondence: false,
              typeName: '',
              category: '',
              typeNameError: false,
              contextLevelError: false,
              isPresent: false,
              contextLevel: '0',
              workflowError: false,
            }));
            setGroupSelected('');
            setVariantSelected('');
          }}
          handleTransactionClick={() => {
            handleCorrTypeCreate();
          }}
        />
      ) : (
        <Dialog
          id="dialogId"
          open={RCStates.openReusableContent}
          children={ReusableContentChildren}
          title={t('pages.reusableContent.HeaderFooterTitle')}
          type="transactional"
          transactionModalTransactionButtonText={t(
            'pages.manageReusableContent.buttons.create'
          )}
          width={360}
          height={270}
          handleClose={() => {
            setRCStates((prev) => ({
              ...prev,
              openReusableContent: false,
              RCtypeName: '',
              RCcategory: '',
              RCtypeNameError: false,
              RCtypeCategoryError: false,
              RCisPresent: false,
            }));
          }}
          handleCancelClick={() => {
            setRCStates((prev) => ({
              ...prev,
              openReusableContent: false,
              RCtypeName: '',
              RCcategory: '',
              RCtypeNameError: false,
              RCtypeCategoryError: false,
              RCisPresent: false,
            }));
          }}
          handleTransactionClick={() => {
            handleRCTypeCreate();
          }}
        />
      )}

      {dataProps.isFromTemplate ? (
        <Button
          id="createContent_upload"
          onClick={() =>
            setCorrTypeStates((prev) => ({
              ...prev,
              openCorrespondence: true,
            }))
          }
          sx={{ ml: 1, mt: '18px' }}
        >
          {t('pages.manageReusableContent.buttons.newCorrespondenceType')}
        </Button>
      ) : (
        <Button
          id="createContent_upload"
          onClick={() =>
            setRCStates((prev) => ({
              ...prev,
              openReusableContent: true,
            }))
          }
          sx={{ ml: '10px', mt: '18px', minWidth: '128px' }}
        >
          {t('pages.reusableContent.newRcType')}
        </Button>
      )}
    </>
  );
}

export default CreateCorrespTypeAndHdrFtr;
