import { getValueType } from 'common/entity';
import {
  Attribute,
  Entity,
  GetEntityByIdQuery,
  GetLookupConfigurationQuery,
  Group,
} from 'generated/graphql';
import {
  EntityAddress,
  EntityEmailAddress,
  EntityForm,
  EntityName,
  EntityPhoneNumber,
  EntityOther,
  EntityIdentifier,
  IdMaxLengths,
} from 'types/entities';
import { ExtendedAttributeValues, Section } from 'types/forms';
import { toStringDate } from 'utils/date-util';
import { deepCopy } from 'utils/deepCopy';

import { getExtendedAttributeGroup } from './entityManagementUtils';
import { getEntity, getEntityRequestDefaults } from './defaults/entity';
import {
  NameFields,
  IdentifiersFields,
  AddressFields,
  EmailAddressFields,
  PhoneNumberFields,
  OtherFields,
} from './fields/entities';

const entityFields: Section[] = [
  AddressFields,
  NameFields,
  IdentifiersFields,
  EmailAddressFields,
  PhoneNumberFields,
  OtherFields,
];
const firstGroupName = ['firstName', 'middleName', 'lastName', 'nameSuffix'];
const secondGroupName = ['value'];
export const dateAttributes = ['commenceDate', 'ceaseDate'];
export const boolAttributes = [
  'isPrimary',
  'isConfidential',
  'hasMailReturned',
];
export type EntityTypes =
  | EntityAddress
  | EntityEmailAddress
  | EntityIdentifier
  | EntityName
  | EntityPhoneNumber
  | EntityOther;
export const sectionNames: string[] = [
  'names',
  'addresses',
  'identifiers',
  'emailAddresses',
  'phoneNumbers',
  'others',
];

export const getMaxLength = (idType: string, idMaxLengths: IdMaxLengths) => {
  if (!idMaxLengths || !idType || !idMaxLengths[idType]) return {};
  const len = idMaxLengths[idType];
  return { maxLength: len };
};

export const findSection = (sectionName: string): Section | undefined => {
  return entityFields.find(
    (fields) => fields.sectionIdentifier === sectionName
  );
};

const getAttributes = (section: string): string[] => {
  const sectionNameKey = section as keyof typeof getEntity;
  const sectionAttributes = getEntity?.[sectionNameKey];
  if (sectionAttributes) {
    return Object.keys(sectionAttributes[0]);
  }
  return [];
};

const getAmountOfElements = (data: EntityForm, section: string): number => {
  const sectionNameKey = section as keyof typeof data;
  const sectionSelected = data?.[sectionNameKey];
  return sectionSelected?.length ?? 0;
};

const getDisplayName = (attribute: string, sectionName: string): string => {
  if (!attribute || !sectionName) return '';
  let displayName = attribute;
  let field;
  const section = findSection(sectionName);
  if (section) {
    field = section.fields.find((f) => f.fieldIdentifier === attribute);
  }
  displayName = !field ? displayName : field.label;
  return displayName;
};

const getValue = (
  data: EntityForm,
  section: string,
  attribute: string,
  index: number
): string => {
  const sectionNameKey = section as keyof typeof data;
  const sectionSelected = data?.[sectionNameKey];
  if (sectionSelected) {
    const sectionData = sectionSelected[index];
    type InternalSectionKey = keyof typeof sectionData;
    const targetAttribute = attribute as InternalSectionKey;

    if (dateAttributes.includes(attribute)) {
      if (String(sectionData[targetAttribute]) === 'Invalid Date') {
        if (attribute === 'commenceDate')
          return toStringDate(data.others?.[0].commenceDate);
        return '';
      }
      return toStringDate(sectionData[targetAttribute]);
    }
    if (
      section === 'names' &&
      attribute === 'value' &&
      data.names &&
      (sectionData[targetAttribute]?.toString() === undefined ||
        sectionData[targetAttribute]?.toString().trim() === '')
    ) {
      const firstName = data.names[index].firstName?.toString() ?? '';
      const middleName = data.names[index].middleName?.toString() ?? '';
      const lastName = data.names[index].lastName?.toString() ?? '';
      return firstName
        .concat(' ')
        .concat(middleName)
        .concat(' ')
        .concat(lastName);
    }
    return sectionData[targetAttribute]?.toString() ?? '';
  }
  return '';
};

const buildOtherSection = (data: Entity, formData: EntityForm, grp: Group) => {
  if (formData.others) {
    formData.others[0] = {
      entityCreationSourceId: data.entityCreationSourceId || 'Online',
      commenceDate: data.commenceDate,
      ceaseDate: data.ceaseDate,
    } as EntityOther;

    const orgType = grp.attribute.find(
      (att) => att.attributeName === 'organizationType'
    );
    if (orgType && orgType.attributeValue && orgType.attributeValue !== '') {
      formData.others[0] = {
        organizationType: orgType.attributeValue,
        ...formData.others[0],
      };
    }
  }
};

export const preprocessNames = (names: EntityName[] | undefined) => {
  if (names) {
    names.forEach((name) => {
      if (name.firstName === '' && name.lastName === '' && name.value !== '') {
        delete name.firstName;
        delete name.middleName;
        delete name.lastName;
        delete name.nameSuffix;
      } else if (name.value === '') {
        delete name.value;
      }
    });
  }
};

export const getNameFieldsByType = (
  configData: GetLookupConfigurationQuery,
  nameType: string,
  currentSection: Section
): Section => {
  let useNameComponent = '';
  let nameComponentFound = false;
  const newActiveSection = deepCopy(currentSection);
  const nameTypeFound = configData.GetLookupConfiguration.find(
    (nameItem) => nameItem.configurationName === nameType
  );
  if (nameTypeFound) {
    nameTypeFound.platformConfigurationInfo?.configurationSection[0].group.forEach(
      (grp) => {
        const attribute = grp.attribute.find(
          (attr) => attr.attributeName === 'UseBusinessNameComponent'
        );
        if (attribute) {
          nameComponentFound = true;
          useNameComponent = attribute.attributeValue;
        }
      }
    );
    if (nameComponentFound) {
      const nameComponentValue = useNameComponent === 'true' ? true : false;
      newActiveSection.fields.forEach((field) => {
        if (
          (nameComponentValue &&
            firstGroupName.includes(field.fieldIdentifier)) ||
          (!nameComponentValue &&
            secondGroupName.includes(field.fieldIdentifier))
        ) {
          field.hidden = true;
        } else field.hidden = false;
      });
    }
  }
  return newActiveSection;
};

export const getEntityPrimaryItem = (
  data: GetEntityByIdQuery,
  section: string
): Attribute[] => {
  const primaryGroup = data.GetEntityById?.group
    ?.filter((group) => group?.groupName?.toLowerCase() === section)
    .filter((attr) =>
      attr?.attribute?.find(
        (att) =>
          att.attributeName === 'isPrimary' && att.attributeValue === 'true'
      )
    );
  if (primaryGroup && primaryGroup.length > 0)
    return primaryGroup[0].attribute ?? [];
  return [];
};

export const buildEntityForm = (data: Entity): EntityForm => {
  const entityFormData: EntityForm = {
    names: [],
    addresses: [],
    identifiers: [],
    emailAddresses: [],
    phoneNumbers: [],
    others: [],
  };
  sectionNames.forEach((sectionName) => {
    data.group?.forEach((grp) => {
      if (grp.attribute && grp.attribute.length > 0 && grp.groupName) {
        if (grp.groupName.toLowerCase() === sectionName.toLowerCase()) {
          if (sectionName.toLowerCase() === 'others') {
            buildOtherSection(data, entityFormData, grp);
          } else {
            const attributes: Attribute[] = grp.attribute;
            let newAttribute = {};
            attributes.forEach((attribute: Attribute) => {
              newAttribute = {
                ...newAttribute,
                [attribute.attributeName]: attribute.attributeValue,
              };
            });
            if (newAttribute) {
              const attrKey = sectionName as keyof EntityForm;
              entityFormData[attrKey]?.push(
                newAttribute as EntityName &
                  EntityAddress &
                  EntityIdentifier &
                  EntityEmailAddress &
                  EntityPhoneNumber &
                  EntityOther
              );
            }
          }
        }
      }
    });
  });
  return entityFormData;
};

export const buildEntityRequest = (
  data: EntityForm,
  entityType: string,
  platformConfigId: string,
  extendedData: ExtendedAttributeValues[],
  entityId?: string | undefined
): Entity => {
  const entityRequest: Entity = getEntityRequestDefaults;
  // Top level data
  entityRequest.id = entityId;
  entityRequest.platformConfigurationId = platformConfigId;
  entityRequest.entityType = entityType;
  entityRequest.entityCreationSourceId =
    data.others?.[0].entityCreationSourceId;
  entityRequest.primaryIdentifierType =
    data.identifiers && data.identifiers.length > 0
      ? data.identifiers?.[0].IdType
      : '';
  entityRequest.primaryIdentifier =
    data.identifiers && data.identifiers.length > 0
      ? data.identifiers?.[0].value
      : undefined;
  entityRequest.commenceDate = toStringDate(data.others?.[0].commenceDate);
  if (data.others?.[0].ceaseDate !== null) {
    if (String(data.others?.[0].ceaseDate) === 'Invalid Date') {
      entityRequest.ceaseDate = null;
    } else entityRequest.ceaseDate = toStringDate(data.others?.[0].ceaseDate);
  } else entityRequest.ceaseDate = data.others?.[0].ceaseDate;
  entityRequest.group = [];
  // Array of groups (each group is a section)
  sectionNames.forEach((section) => {
    const amountOfElements = getAmountOfElements(data, section);
    for (
      let indexElement = 0;
      indexElement < amountOfElements;
      indexElement++
    ) {
      const group: Group = {
        groupName: section,
        groupOrder: '1',
        groupAttributeType: 0,
        repeatingActionLabel: '',
        attribute: [],
      };
      const sectionAttributes = getAttributes(section);
      sectionAttributes.forEach((attribute) => {
        const singleAttribute: Attribute = {
          attributeName: attribute,
          attributeDisplayName: getDisplayName(attribute, section),
          attributeType: getValueType(attribute),
          attributeValue: getValue(data, section, attribute, indexElement),
          repeatingProperties: false,
          possibleValues: null,
          repeatingValue: null,
        };
        group.attribute?.push(singleAttribute);
      });
      entityRequest.group?.push(group);
    }
  });
  // Add extended attributes if existent
  if (extendedData && extendedData.length > 0) {
    const group = getExtendedAttributeGroup(extendedData);
    entityRequest.group?.push(group);
  }
  return entityRequest;
};
