import { Buffer } from 'buffer';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { Box, Grid, SelectChangeEvent } from '@mui/material';
import {
  Dropzone,
  DataDisplay,
  TextArea,
  MessageActionType,
  MessageType,
} from '@revenue-solutions-inc/revxcoreui';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import Dialog from '@revenue-solutions-inc/revxcoreui/material/controls/Dialog';
import useGetAccessToken from 'hooks/useGetAccessToken';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'redux/hooks';
import FileSaver from 'file-saver';
import {
  useCreateTemplateMutation,
  useCreateReusableContentMutation,
  useUpdateTemplateMutation,
  useUpdateReusableContentMutation,
  TemplateHistoryResponse,
  ReusableContentsResponse,
  useDictionaryResponseQuery,
  useGetManualFieldInfoQuery,
  useGetReusableContentTypesByCategoryQuery,
  useReusableContentByIdQuery,
  GetRcTypeQuery,
} from 'generated/graphql';
import { addMessage } from 'redux/messageSlice';
import Select, {
  SelectType,
} from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import { useMsal } from '@azure/msal-react';
import { BASIC_TEMPLATATE_DATA } from '../common/Basic_Template';
import {
  EmptyReusableContentRecord,
  EmptyTemplateRecord,
} from '../common/InitialTableRecords';

type Props = {
  isFromTemplate: boolean;
  contentData: ReusableContentsResponse[];
  templateData: TemplateHistoryResponse[];
  reFetch: () => void;
  correspondenceId: number;
  rcId: number;
  rcType: GetRcTypeQuery | undefined;
  isFromDownload: boolean;
  setIsFromDownload: (value: boolean) => void;
  rowId: string;
};
function DownloadAndImport(dataProps: Props) {
  const { t } = useTranslation();

  const { accounts } = useMsal();
  const accessToken = useGetAccessToken();
  const dispatch = useAppDispatch();
  const [contentFile, setContentFile] = useState(new File([''], ''));
  const [maxLengthError, setMaxLengthError] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isValidJson, setIsValidJson] = useState<boolean>(true);
  const [downloadRC, setDownloadRC] = useState(false);
  const [wordXML, setWordXML] = useState<Document>();

  const [openTemplate, setOpenTemplate] = useState({
    initialDownload: false,
    versionDownload: false,
  });

  const [rcSelection, setRcSelection] = useState({
    header: '',
    footer: '',
  });

  const {
    data: headerOpenXml,
    refetch: refetchHeaderOpenXml,
    isFetched: headerFetched,
  } = useReusableContentByIdQuery(
    { reusableContentByIdId: String(rcSelection.header) },
    { enabled: false, cacheTime: 0 }
  );
  const {
    data: footerOpenXml,
    refetch: refetchFooterOpenXml,
    isFetched: footerFetched,
  } = useReusableContentByIdQuery(
    { reusableContentByIdId: String(rcSelection.footer) },
    { enabled: false, cacheTime: 0 }
  );

  const { data: Dictionary, isFetched: isDictionaryFetched } =
    useDictionaryResponseQuery({});

  const [content, setContent] = useState<ReusableContentsResponse>(
    EmptyReusableContentRecord
  );
  const [template, setTemplate] =
    useState<TemplateHistoryResponse>(EmptyTemplateRecord);

  const { mutate: createTemplate } = useCreateTemplateMutation();
  const { mutate: createRc } = useCreateReusableContentMutation();
  const { mutate: updateTemplate } = useUpdateTemplateMutation();
  const { mutate: updateRc } = useUpdateReusableContentMutation();

  const [importTemplate, setImportTemplate] = useState({
    description: '',
    openUpload: false,
    fileName: '',
  });
  const {
    data: manualFieldInfo,
    refetch: refetchManualFieldInfo,
    isFetched: manualFieldsFetched,
  } = useGetManualFieldInfoQuery(
    { getManualFieldInfoByTemplateId: parseInt(template.templateId) },
    { enabled: false, cacheTime: 0 }
  );
  const { data: headerData } = useGetReusableContentTypesByCategoryQuery({
    category: 'header',
  });
  const { data: footerData } = useGetReusableContentTypesByCategoryQuery({
    category: 'footer',
  });

  const cancelClickHandler = useCallback(() => {
    if (openTemplate.initialDownload || openTemplate.versionDownload) {
      setOpenTemplate({
        initialDownload: false,
        versionDownload: false,
      });
      setRcSelection({
        header: '',
        footer: '',
      });
      setWordXML(undefined);
      dataProps.setIsFromDownload(false);
    }
  }, [dataProps, openTemplate]);

  const handleTextArea = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setImportTemplate((prev) => ({
      ...prev,
      description: event.target.value,
    }));
    if (event.target.value && event.target.value.length > 2000) {
      setMaxLengthError(true);
    }
  };
  const xmlSavingHandler = useCallback(
    (initialData: string, isSample: boolean) => {
      const serialize = new XMLSerializer();
      let TemplateWithJSON = '';
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(initialData, 'application/xml');
      const propetiesNode = Object.values(
        xmlDoc.getElementsByTagName('Properties')
      ).filter((item) => item?.namespaceURI?.includes('custom'))[0];
      if (!propetiesNode) {
        dispatch(
          addMessage({
            message: t('pages.correspondence.message.warningMessage'),
            type: MessageType.Warning,
            actionType: MessageActionType.None,
          })
        );
        return;
      }
      if (
        xmlDoc.getElementsByName('FooterId').length < 1 &&
        xmlDoc.getElementsByName('HeaderXml').length < 1
      ) {
        const variant = xmlDoc.createElement('vt:lpwstr');
        const variant2 = xmlDoc.createElement('vt:lpwstr');
        const variant3 = xmlDoc.createElement('vt:lpwstr');
        const variant4 = xmlDoc.createElement('vt:lpwstr');

        const HeaderId = xmlDoc.createElement('property');
        HeaderId.setAttribute(
          'fmtid',
          '{D5CDD505-2E9C-101B-9397-08002B2CF9AE}'
        );
        HeaderId.setAttribute('pid', '6');
        HeaderId.setAttribute('name', 'HeaderId');

        propetiesNode.appendChild(HeaderId);
        HeaderId.appendChild(variant);
        const HeaderXml = xmlDoc.createElement('property');
        const FooterId = xmlDoc.createElement('property');
        const FooterXml = xmlDoc.createElement('property');

        HeaderXml.setAttribute(
          'fmtid',
          '{D5CDD505-2E9C-101B-9397-08002B2CF9AE}'
        );
        FooterId.setAttribute(
          'fmtid',
          '{D5CDD505-2E9C-101B-9397-08002B2CF9AE}'
        );
        FooterXml.setAttribute(
          'fmtid',
          '{D5CDD505-2E9C-101B-9397-08002B2CF9AE}'
        );

        HeaderXml.setAttribute('pid', '7');
        FooterId.setAttribute('pid', '8');
        FooterXml.setAttribute('pid', '9');

        HeaderXml.setAttribute('name', 'HeaderXml');
        FooterId.setAttribute('name', 'FooterId');
        FooterXml.setAttribute('name', 'FooterXml');

        HeaderXml.appendChild(variant2);
        propetiesNode.appendChild(HeaderXml);
        FooterId.appendChild(variant3);
        propetiesNode.appendChild(FooterId);
        FooterXml.appendChild(variant4);
        propetiesNode.appendChild(FooterXml);
      }
      if (xmlDoc.getElementsByName('ManualFields').length < 1) {
        const variant5 = xmlDoc.createElement('vt:lpwstr');
        const ManualFields = xmlDoc.createElement('property');
        ManualFields.setAttribute(
          'fmtid',
          '{D5CDD505-2E9C-101B-9397-08002B2CF9AE}'
        );
        ManualFields.setAttribute('pid', '40');
        ManualFields.setAttribute('name', 'ManualFields');
        ManualFields.appendChild(variant5);
        propetiesNode.appendChild(ManualFields);
      }
      if (
        Dictionary &&
        Dictionary.DictionaryResponse?.length > 0 &&
        !Dictionary.DictionaryResponse[0].logixInputSchemaJson.includes(
          '{"Groups":[]}'
        )
      ) {
        xmlDoc.getElementsByTagName('dc:creator')[0].textContent =
          accounts[0]?.name ?? '';
        xmlDoc.getElementsByTagName('dcterms:created')[0].textContent =
          new Date().toISOString();
        xmlDoc.getElementsByName(
          'DictionaryJSON'
        )[0].childNodes[0].textContent =
          Dictionary?.DictionaryResponse[0].logixInputSchemaJson;
        xmlDoc.getElementsByName('DictionaryID')[0].childNodes[0].textContent =
          Dictionary?.DictionaryResponse[0].systemFieldDictionaryId;
        xmlDoc.getElementsByName(
          'DictionaryName'
        )[0].childNodes[0].textContent = 'DictionaryName';
        xmlDoc.getElementsByName(
          'DictionaryCreatedBy'
        )[0].childNodes[0].textContent =
          Dictionary?.DictionaryResponse[0].createdBy ?? '';
        if (dataProps.isFromTemplate) {
          xmlDoc.getElementsByName('HeaderId')[0].childNodes[0].textContent =
            rcSelection.header;
        } else if (
          dataProps.rcType?.GetRcType?.reusableContentTypeCategory?.toLocaleLowerCase() ==
          'header'
        ) {
          xmlDoc.getElementsByName('HeaderId')[0].childNodes[0].textContent =
            '0';
        } else
          xmlDoc.getElementsByName('HeaderId')[0].childNodes[0].textContent =
            '-1';

        if (dataProps.isFromTemplate)
          xmlDoc.getElementsByName('FooterId')[0].childNodes[0].textContent =
            rcSelection.footer;
        else if (
          dataProps.rcType?.GetRcType?.reusableContentTypeCategory?.toLocaleLowerCase() ==
          'footer'
        )
          xmlDoc.getElementsByName('FooterId')[0].childNodes[0].textContent =
            '0';
        else
          xmlDoc.getElementsByName('FooterId')[0].childNodes[0].textContent =
            '-1';
        if (rcSelection.header || rcSelection.footer) {
          if (rcSelection.header) {
            refetchHeaderOpenXml();
          }
          if (rcSelection.footer) {
            refetchFooterOpenXml();
          }
          setWordXML(xmlDoc);
        } else if (
          manualFieldsFetched ||
          !dataProps.isFromTemplate ||
          template.templateId === ''
        ) {
          if (xmlDoc.getElementsByName('ManualFields').length > 0)
            xmlDoc.getElementsByName(
              'ManualFields'
            )[0].childNodes[0].textContent =
              manualFieldInfo?.GetManualFieldInfoByTemplate ??
              '{"freeformFields":[],"multiChoiceGroups":[]}';

          TemplateWithJSON = serialize.serializeToString(xmlDoc);
          TemplateWithJSON = TemplateWithJSON.replaceAll('xmlns=""', '');
          const blob = new Blob([TemplateWithJSON], {
            type: 'xml;charset=utf-8',
          });
          if (openTemplate.initialDownload || isSample) {
            FileSaver.saveAs(blob, 'Sample_Template.xml', { autoBom: false });
          } else {
            if (dataProps.isFromTemplate)
              FileSaver.saveAs(blob, `${template.templateDisplayId}.xml`, {
                autoBom: false,
              });
            else
              FileSaver.saveAs(
                blob,
                `${content.reusableContentDisplayId}.xml`,
                { autoBom: false }
              );
          }
          cancelClickHandler();
        }
      } else if (isDictionaryFetched) {
        setIsValidJson(false);
      }
    },
    [
      Dictionary,
      isDictionaryFetched,
      dispatch,
      t,
      accounts,
      dataProps,
      rcSelection.footer,
      rcSelection.header,
      manualFieldsFetched,
      template.templateId,
      template.templateDisplayId,
      refetchHeaderOpenXml,
      refetchFooterOpenXml,
      manualFieldInfo?.GetManualFieldInfoByTemplate,
      openTemplate.initialDownload,
      content.reusableContentDisplayId,
      cancelClickHandler,
    ]
  );
  const downloadSampleFile = () => {
    xmlSavingHandler(BASIC_TEMPLATATE_DATA, true);
  };
  const headerList: SelectType[] = useMemo(() => {
    const list: SelectType[] = [];
    headerData?.getReusableContentTypesByCategory.map((cat) => {
      list.push({
        key: cat.reusableContentId as unknown as string,
        desc: cat.reusableContentTypeName as unknown as string,
      });
    });
    return list;
  }, [headerData]);

  const footerList: SelectType[] = useMemo(() => {
    const list: SelectType[] = [];
    footerData?.getReusableContentTypesByCategory.map((cat) => {
      list.push({
        key: cat.reusableContentId as unknown as string,
        desc: cat.reusableContentTypeName as unknown as string,
      });
    });
    return list;
  }, [footerData]);

  const handleHeader = (event: SelectChangeEvent<string | unknown>) => {
    {
      setRcSelection((prev) => ({
        ...prev,
        header: event.target.value as string,
      }));
    }
  };
  const handleFooter = (event: SelectChangeEvent<string | unknown>) => {
    {
      setRcSelection((prev) => ({
        ...prev,
        footer: event.target.value as string,
      }));
    }
  };
  const templateCreation = () => {
    setIsDisabled(true);
    setTemplate((PrevState) => ({
      ...PrevState,
      description: importTemplate.description,
    }));
    if (importTemplate.description.length > 2000) {
      setMaxLengthError(true);
      return;
    } else setMaxLengthError(false);

    const parser = new DOMParser();
    const serialize = new XMLSerializer();
    let contentIds: number[] = [];
    const xmlDoc = parser.parseFromString(
      dataProps.isFromTemplate
        ? template.templateOpenXml
        : content.contentOpenXml ?? '',
      'application/xml'
    );
    const propetiesNode = Object.values(
      xmlDoc.getElementsByTagName('Properties')
    ).filter((item) => item?.namespaceURI?.includes('custom'))[0];

    if (
      ((dataProps.isFromTemplate && template.templateOpenXml) ||
        (!dataProps.isFromTemplate && content.contentOpenXml)) &&
      propetiesNode
    ) {
      const footerId = parseInt(
        xmlDoc.getElementsByName('FooterId')[0]?.textContent ?? ''
      );
      const headerId = parseInt(
        xmlDoc.getElementsByName('HeaderId')[0]?.textContent ?? ''
      );
      xmlDoc.getElementsByName('DictionaryJSON')[0].childNodes[0].textContent =
        'ReplaceWithAPI';
      if (dataProps.isFromTemplate) {
        xmlDoc.getElementsByName('HeaderId')[0].childNodes[0].textContent =
          'ReplaceWithHeaderId';
        xmlDoc.getElementsByName('HeaderXml')[0].childNodes[0].textContent =
          'ReplaceWithHeaderXml';
        xmlDoc.getElementsByName('FooterId')[0].childNodes[0].textContent =
          'ReplaceWithFooterId';
        xmlDoc.getElementsByName('FooterXml')[0].childNodes[0].textContent =
          'ReplaceWithFooterXml';
      }
      if (
        (headerId || footerId) &&
        (headerId > 0 || footerId > 0) &&
        (headerList.filter((item) => parseInt(item.key) === headerId).length >
          0 ||
          footerList.filter((item) => parseInt(item.key) === footerId).length >
            0)
      ) {
        if (headerId && footerId) contentIds = [headerId, footerId];
        else if (headerId) contentIds = [headerId];
        else contentIds = [footerId];
      }
    }
    if (
      (!content.contentOpenXml && !template.templateOpenXml) ||
      contentFile.size < 2
    ) {
      setIsDisabled(false);
      dispatch(
        addMessage({
          message: t('pages.manageReusableContent.errorMessage'),
          type: MessageType.Warning,
          actionType: MessageActionType.None,
        })
      );
    } else if (
      dataProps.templateData.length > 0 &&
      dataProps.templateData.filter(
        (item) => item.status.toUpperCase() == 'DRAFT'
      ).length > 0 &&
      dataProps.isFromTemplate
    ) {
      updateTemplate(
        {
          template: {
            templateId: parseInt(
              dataProps.templateData.filter(
                (item) => item.status.toUpperCase() == 'DRAFT'
              )[0].templateId
            ),
            templateOpenXml: template.templateOpenXml
              .toUpperCase()
              .includes('XML')
              ? Buffer.from(serialize.serializeToString(xmlDoc)).toString(
                  'base64'
                )
              : template.templateOpenXml,
            description: importTemplate.description,
            reusableContentIds: contentIds,
          },
        },
        {
          onSuccess: () => {
            dataProps.reFetch();
            setImportTemplate({
              description: '',
              openUpload: false,
              fileName: '',
            });
            setIsDisabled(true);
            dispatch(
              addMessage({
                message: t('pages.correspondence.message.updateTemplate'),
                type: MessageType.Success,
                actionType: MessageActionType.None,
              })
            );
          },
          onError: () => {
            setIsDisabled(false);
            dispatch(
              addMessage({
                message: t('pages.manageReusableContent.networkError'),
                type: MessageType.Error,
                actionType: MessageActionType.None,
              })
            );
          },
        }
      );
    } else if (
      dataProps.contentData.length > 0 &&
      dataProps.contentData.filter(
        (item) => item.status?.toUpperCase() == 'DRAFT'
      ).length > 0 &&
      !dataProps.isFromTemplate
    ) {
      updateRc(
        {
          content: {
            reusableContentId:
              dataProps &&
              dataProps.contentData &&
              parseInt(
                dataProps.contentData.filter(
                  (item) => item.status?.toUpperCase() == 'DRAFT'
                )[0].reusableContentId ?? ''
              ),
            contentOpenXml: content?.contentOpenXml
              ? content?.contentOpenXml.toUpperCase().includes('XML')
                ? Buffer.from(serialize.serializeToString(xmlDoc)).toString(
                    'base64'
                  )
                : content.contentOpenXml ?? ''
              : '',

            description: importTemplate.description,
          },
        },
        {
          onSuccess: () => {
            dataProps.reFetch();
            setImportTemplate({
              description: '',
              openUpload: false,
              fileName: '',
            });
            setIsDisabled(true);
            dispatch(
              addMessage({
                message: t('pages.reusableContent.message.updateRC'),
                type: MessageType.Success,
                actionType: MessageActionType.None,
              })
            );
          },
          onError: () => {
            setIsDisabled(false);
            dispatch(
              addMessage({
                message: t('pages.manageReusableContent.networkError'),
                type: MessageType.Error,
                actionType: MessageActionType.None,
              })
            );
          },
        }
      );
    } else {
      if (dataProps.isFromTemplate) {
        createTemplate(
          {
            template: {
              corresepondenceTypeId: dataProps.correspondenceId,
              templateOpenXml: Buffer.from(
                serialize.serializeToString(xmlDoc)
              ).toString('base64'),
              description: importTemplate.description,
              reusableContentIds: contentIds,
            },
          },
          {
            onSuccess: () => {
              dataProps.reFetch();
              setImportTemplate({
                description: '',
                openUpload: false,
                fileName: '',
              });
              setIsDisabled(true);
              dispatch(
                addMessage({
                  message: t('pages.correspondence.message.createTemplate'),
                  type: MessageType.Success,
                  actionType: MessageActionType.None,
                })
              );
            },
            onError: () => {
              setIsDisabled(false);
              dispatch(
                addMessage({
                  message: t('pages.manageReusableContent.networkError'),
                  type: MessageType.Error,
                  actionType: MessageActionType.None,
                })
              );
            },
          }
        );
      } else if (content.contentOpenXml) {
        createRc(
          {
            content: {
              reusableContentTypeId: dataProps.rcId,
              contentOpenXml: Buffer.from(
                serialize.serializeToString(xmlDoc)
              ).toString('base64'),
              description: importTemplate.description,
            },
          },
          {
            onSuccess: () => {
              dataProps.reFetch();
              setImportTemplate({
                description: '',
                openUpload: false,
                fileName: '',
              });
              setIsDisabled(true);
              dispatch(
                addMessage({
                  message: t('pages.reusableContent.message.createRC'),
                  type: MessageType.Success,
                  actionType: MessageActionType.None,
                })
              );
            },
            onError: () => {
              setIsDisabled(false);
              dispatch(
                addMessage({
                  message: t('pages.manageReusableContent.networkError'),
                  type: MessageType.Error,
                  actionType: MessageActionType.None,
                })
              );
            },
          }
        );
      }
    }
  };

  const saveXMLFile = useCallback(() => {
    xmlSavingHandler(
      dataProps.isFromTemplate
        ? Buffer.from(template.templateOpenXml, 'base64').toString()
        : Buffer.from(content?.contentOpenXml ?? '', 'base64').toString(),
      false
    );
  }, [
    content?.contentOpenXml,
    dataProps.isFromTemplate,
    template.templateOpenXml,
    xmlSavingHandler,
  ]);
  const DropzoneChildren = (
    <Grid container spacing={2}>
      <Grid
        item
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
        xs={12}
      >
        <Dropzone
          id={'dropzone'}
          accessToken={accessToken}
          acceptedFiles={[{ 'text/xml': ['.xml'] }]}
          acceptFormat={'.xml'}
          maxFiles={1}
          noClick={true}
          noKeyboard={true}
          dropzoneWithButton={true}
          labelButton={t('pages.correspondence.upload')}
          setFile={setContentFile}
          upload={() => {}}
          isLoading={isLoading}
        />
        <DataDisplay
          id={'fileName'}
          label={''}
          data={importTemplate.fileName}
          sx={{ fontWeight: 'bold', marginLeft: '10px', fontSize: '15px' }}
        />
      </Grid>
      <Grid item>
        <DataDisplay
          id={'message-draft'}
          label={
            dataProps.isFromTemplate
              ? t('pages.templates.import.draftMessage')
              : t('pages.reusableContent.RCimport.draftMessage')
          }
          data={''}
        />
      </Grid>
      <Grid
        item
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
        xs={12}
      >
        <DataDisplay
          id={'template-description'}
          label={
            dataProps.isFromTemplate
              ? t('pages.templates.import.templateDescription')
              : t('pages.reusableContent.RCimport.rcDescription')
          }
          data={''}
        />
        <TextArea
          ariaLabel="add description"
          value={importTemplate.description ?? ''}
          multiline
          onChange={handleTextArea}
          error={maxLengthError && importTemplate.description.length > 2000}
          helperText={
            maxLengthError && importTemplate.description.length > 2000
              ? t('pages.templates.import.warningMessage')
              : ''
          }
        />
        <DataDisplay
          id={'message'}
          label={
            dataProps.isFromTemplate
              ? t('pages.templates.import.description')
              : t('pages.reusableContent.RCimport.description')
          }
          data={''}
        />
      </Grid>
      <Grid item sx={{ display: 'flex', justifyContent: 'center' }} xs={12}>
        <Button
          id={'cancel'}
          type="secondary"
          sx={{ minWidth: '128px' }}
          onClick={() => {
            setImportTemplate({
              description: '',
              openUpload: false,
              fileName: '',
            });
            setIsDisabled(true);
          }}
        >
          {t('pages.correspondence.cancel')}
        </Button>
        <Button
          id="btn_import"
          sx={{ ml: 2, minWidth: '128px' }}
          disabled={
            isDisabled ||
            (maxLengthError && importTemplate.description.length > 2000)
          }
          onClick={() => templateCreation()}
        >
          {t('pages.manageReusableContent.buttons.save')}
        </Button>
      </Grid>
    </Grid>
  );

  const DownloadTemplateChildren = (
    <Box sx={{ display: 'grid' }}>
      <Select
        options={headerList}
        id="header"
        data-testid="content-select"
        label={t('pages.downloadTemplate.Header')}
        onChange={handleHeader}
        value={
          String(rcSelection.header)?.length > 0
            ? parseInt(rcSelection.header)
            : 0
        }
        width="25em"
      />
      <Select
        options={footerList}
        id="footer"
        data-testid="content-select"
        label={t('pages.downloadTemplate.Footer')}
        onChange={handleFooter}
        value={
          String(rcSelection.footer)?.length > 0
            ? parseInt(rcSelection.footer)
            : 0
        }
        width="25em"
      />
      <Grid
        item
        sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}
        xs={12}
      >
        <Button
          id={'cancel'}
          type="secondary"
          sx={{ minWidth: '128px' }}
          onClick={() => cancelClickHandler()}
        >
          {t('pages.correspondence.cancel')}
        </Button>
        <Button
          id="btn_download"
          sx={{ ml: 2, minWidth: '128px' }}
          onClick={() => {
            if (openTemplate.initialDownload) downloadSampleFile();
            else {
              saveXMLFile();
            }
          }}
        >
          {t('pages.reusableContent.download')}
        </Button>
      </Grid>
    </Box>
  );

  useEffect(() => {
    if (template.templateId && template.templateId.length > 0)
      refetchManualFieldInfo();
  }, [refetchManualFieldInfo, template.templateId]);

  useEffect(() => {
    async function updateXML() {
      if (contentFile.type === 'text/xml') {
        setIsLoading(true);
        setImportTemplate((prev) => ({
          ...prev,
          fileName: contentFile.name,
        }));
        const fileData = await contentFile.text();
        if (dataProps.isFromTemplate) {
          setIsDisabled(false);
          setTemplate((PrevState) => ({
            ...PrevState,
            templateOpenXml: fileData,
          }));
        } else {
          setIsDisabled(false);
          setContent((PrevState) => ({
            ...PrevState,
            contentOpenXml: fileData,
          }));
        }
      } else {
        dispatch(
          addMessage({
            message: t('pages.manageReusableContent.errorMessage'),
            type: MessageType.Warning,
            actionType: MessageActionType.None,
          })
        );
      }
    }
    if (contentFile.size > 1) updateXML();
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, [contentFile, dataProps.isFromTemplate, dispatch, t]);

  useEffect(() => {
    if (downloadRC) {
      saveXMLFile();
      setDownloadRC(false);
    }
  }, [downloadRC, saveXMLFile]);
  useEffect(() => {
    const localXML = wordXML;
    if (
      localXML &&
      (openTemplate.initialDownload || openTemplate.versionDownload)
    ) {
      const parser = new DOMParser();
      const serialize = new XMLSerializer();
      if (rcSelection.footer && rcSelection.header) {
        if (
          headerFetched &&
          footerFetched &&
          headerOpenXml?.ReusableContentById.contentOpenXml &&
          footerOpenXml?.ReusableContentById.contentOpenXml
        ) {
          const headerXmlDoc = parser.parseFromString(
            Buffer.from(
              headerOpenXml?.ReusableContentById.contentOpenXml ?? '',
              'base64'
            ).toString(),
            'application/xml'
          );
          const footerXmlDoc = parser.parseFromString(
            Buffer.from(
              footerOpenXml?.ReusableContentById.contentOpenXml ?? '',
              'base64'
            ).toString() ?? '',
            'application/xml'
          );

          localXML.getElementsByName('FooterXml')[0].childNodes[0].textContent =
            footerXmlDoc.getElementsByName('FooterXml')[0]?.textContent ?? '';
          localXML.getElementsByName('HeaderXml')[0].childNodes[0].textContent =
            headerXmlDoc.getElementsByName('HeaderXml')[0]?.textContent ?? '';
          localXML.getElementsByName(
            'ManualFields'
          )[0].childNodes[0].textContent =
            manualFieldInfo?.GetManualFieldInfoByTemplate ?? '';
          let TemplateWithJSON = serialize.serializeToString(localXML);
          TemplateWithJSON = TemplateWithJSON.replaceAll('xmlns=""', '');
          const blob = new Blob([TemplateWithJSON], {
            type: 'xml;charset=utf-8',
          });
          if (openTemplate.initialDownload) {
            FileSaver.saveAs(blob, 'Sample_Template.xml', { autoBom: false });
          } else {
            if (dataProps.isFromTemplate)
              FileSaver.saveAs(blob, `${template.templateDisplayId}.xml`, {
                autoBom: false,
              });
            else
              FileSaver.saveAs(
                blob,
                `${content.reusableContentDisplayId}.xml`,
                { autoBom: false }
              );
          }
          cancelClickHandler();
        }
      } else if (rcSelection.footer) {
        if (
          footerFetched &&
          footerOpenXml?.ReusableContentById.contentOpenXml
        ) {
          const footerXmlDoc = parser.parseFromString(
            Buffer.from(
              footerOpenXml?.ReusableContentById.contentOpenXml ?? '',
              'base64'
            ).toString() ?? '',
            'application/xml'
          );
          localXML.getElementsByName('FooterXml')[0].childNodes[0].textContent =
            footerXmlDoc.getElementsByName('FooterXml')[0]?.textContent ?? '';
          localXML.getElementsByName(
            'ManualFields'
          )[0].childNodes[0].textContent =
            manualFieldInfo?.GetManualFieldInfoByTemplate ?? '';
          let TemplateWithJSON = serialize.serializeToString(localXML);
          TemplateWithJSON = TemplateWithJSON.replaceAll('xmlns=""', '');
          const blob = new Blob([TemplateWithJSON], {
            type: 'xml;charset=utf-8',
          });
          if (openTemplate.initialDownload) {
            FileSaver.saveAs(blob, 'Sample_Template.xml', { autoBom: false });
          } else {
            if (dataProps.isFromTemplate)
              FileSaver.saveAs(blob, `${template.templateDisplayId}.xml`, {
                autoBom: false,
              });
            else
              FileSaver.saveAs(
                blob,
                `${content.reusableContentDisplayId}.xml`,
                { autoBom: false }
              );
          }
          cancelClickHandler();
        }
      } else if (rcSelection.header) {
        if (
          headerFetched &&
          headerOpenXml?.ReusableContentById.contentOpenXml
        ) {
          const headerXmlDoc = parser.parseFromString(
            Buffer.from(
              headerOpenXml?.ReusableContentById.contentOpenXml ?? '',
              'base64'
            ).toString(),
            'application/xml'
          );
          localXML.getElementsByName('HeaderXml')[0].childNodes[0].textContent =
            headerXmlDoc.getElementsByName('HeaderXml')[0].textContent ?? '';
          localXML.getElementsByName(
            'ManualFields'
          )[0].childNodes[0].textContent =
            manualFieldInfo?.GetManualFieldInfoByTemplate ?? '';
          let TemplateWithJSON = serialize.serializeToString(localXML);
          TemplateWithJSON = TemplateWithJSON.replaceAll('xmlns=""', '');
          const blob = new Blob([TemplateWithJSON], {
            type: 'xml;charset=utf-8',
          });
          if (openTemplate.initialDownload) {
            FileSaver.saveAs(blob, 'Sample_Template.xml', { autoBom: false });
          } else {
            if (dataProps.isFromTemplate)
              FileSaver.saveAs(blob, `${template.templateDisplayId}.xml`, {
                autoBom: false,
              });
            else
              FileSaver.saveAs(
                blob,
                `${content.reusableContentDisplayId}.xml`,
                { autoBom: false }
              );
          }
          cancelClickHandler();
        }
      }
    }
  }, [
    wordXML,
    headerFetched,
    footerFetched,
    headerOpenXml?.ReusableContentById.contentOpenXml,
    footerOpenXml?.ReusableContentById.contentOpenXml,
    openTemplate,
    dataProps.isFromTemplate,
    template.templateDisplayId,
    content.reusableContentDisplayId,
    rcSelection.footer,
    rcSelection.header,
    manualFieldInfo?.GetManualFieldInfoByTemplate,
    cancelClickHandler,
  ]);

  useEffect(() => {
    if (dataProps.isFromDownload) {
      if (dataProps.isFromTemplate) {
        setTemplate(
          dataProps.templateData.filter(
            (item) => item.templateDisplayId == dataProps.rowId
          )[0]
        );
        const isRcSaved = dataProps.templateData.filter(
          (item) => item.templateDisplayId == dataProps.rowId
        )[0].reusableContents;
        const isHeaderAvailable = isRcSaved
          ? dataProps.templateData
              .filter((item) => item.templateDisplayId == dataProps.rowId)[0]
              .reusableContents?.filter(
                (rcItem) =>
                  rcItem.reusableCategoryName.toLocaleUpperCase() == 'HEADER'
              )
          : [];
        const isFooterAvaliable = isRcSaved
          ? dataProps.templateData
              .filter((item) => item.templateDisplayId == dataProps.rowId)[0]
              .reusableContents?.filter(
                (rcItem) =>
                  rcItem.reusableCategoryName.toLocaleUpperCase() == 'FOOTER'
              )
          : [];
        if (isRcSaved && isRcSaved.length > 0) {
          setRcSelection({
            header:
              isHeaderAvailable && isHeaderAvailable.length > 0
                ? isHeaderAvailable[0].reusableContentId
                : '',
            footer:
              isFooterAvaliable && isFooterAvaliable.length > 0
                ? isFooterAvaliable[0].reusableContentId
                : '',
          });
        }
      } else {
        setContent(
          dataProps.contentData.filter(
            (item) => item.reusableContentId == dataProps.rowId
          )[0]
        );
        dataProps.setIsFromDownload(false);
        setDownloadRC(true);
      }
      if (dataProps.isFromTemplate)
        setOpenTemplate({
          initialDownload: false,
          versionDownload: true,
        });
    }
    if (dataProps && dataProps.isFromTemplate && dataProps.templateData) {
      if (dataProps.templateData.length < 1) setTemplate(EmptyTemplateRecord);
    }
  }, [dataProps]);

  return (
    <>
      {dataProps.isFromTemplate ? (
        <>
          <Dialog
            id="downloadTemplate"
            open={openTemplate.initialDownload || openTemplate.versionDownload}
            children={DownloadTemplateChildren}
            title={t('pages.correspondence.headerFooter')}
            transactionModalTransactionButtonText="Select"
            width={360}
            height={270}
            handleClose={() => cancelClickHandler()}
          />
        </>
      ) : null}
      <Dialog
        id="openTemplate"
        open={!isValidJson}
        children={
          <DataDisplay
            id={'warning'}
            label={t('pages.correspondence.jsonWarning')}
            data={''}
          />
        }
        title={''}
        width={350}
        height={110}
        handleClose={() => {
          setIsValidJson(true);
        }}
      />
      <Grid
        item
        xs={12}
        sx={{
          mb: 1,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Dialog
          id="dialogId"
          open={importTemplate.openUpload}
          children={DropzoneChildren}
          title={
            dataProps.isFromTemplate
              ? t('pages.correspondence.import')
              : t('pages.reusableContent.importRC')
          }
          width={680}
          height={520}
          handleClose={() => {
            setImportTemplate({
              description: '',
              openUpload: false,
              fileName: '',
            });
            setIsDisabled(true);
          }}
          handleCancelClick={() => {
            setImportTemplate({
              description: '',
              openUpload: false,
              fileName: '',
            });
            setIsDisabled(true);
          }}
          handleTransactionClick={() => {
            templateCreation();
          }}
        />
        <Button
          id="saveBtn-updateContent"
          data-testid="update-button"
          sx={{ mt: 2, ml: 1, minWidth: '128px' }}
          onClick={() =>
            setImportTemplate((prev) => ({ ...prev, openUpload: true }))
          }
        >
          {dataProps.isFromTemplate
            ? t('pages.correspondence.import')
            : t('pages.reusableContent.import')}
        </Button>
        <Button
          type="secondary"
          id="newContent"
          sx={{ ml: '10px', mt: '18px', minWidth: '128px' }}
          onClick={() =>
            dataProps.isFromTemplate
              ? setOpenTemplate({
                  initialDownload: true,
                  versionDownload: false,
                })
              : downloadSampleFile()
          }
        >
          {dataProps.isFromTemplate
            ? t('pages.templates.buttons.create')
            : t('pages.reusableContent.buttons.create')}
        </Button>
      </Grid>
    </>
  );
}

export default DownloadAndImport;
