import { useCallback, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import Input from '@revenue-solutions-inc/revxcoreui/material/controls/Input';
import Select, {
  SelectType,
} from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import { useTranslation } from 'react-i18next';
import { ScheduledTasks } from 'types/channels';
import {
  Control,
  Controller,
  UseFormGetValues,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';
import {
  useCheckTaskNameQuery,
  useGetBusinessPoliciesByModuleIdQuery,
  useGetModulesByUserQuery,
  ModuleResponse,
} from 'generated/graphql';
import useMaskInput from 'hooks/useMaskInput';
import { TextArea } from '@revenue-solutions-inc/revxcoreui';
import Loading from 'components/Loading';
import { useParams } from 'react-router-dom';

enum ActionType {
  EDIT = 'edit',
  CREATE = 'create',
}

interface Props {
  setTaskDetailsValid: React.Dispatch<React.SetStateAction<boolean>>;
  control: Control<ScheduledTasks, unknown>;
  watch: UseFormGetValues<ScheduledTasks>;
  setValue: UseFormSetValue<ScheduledTasks>;
  isLoading: boolean;
}

function ScheduledTasksDetails({
  setTaskDetailsValid,
  control,
  watch,
  isLoading,
  setValue,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [ScheduleTaskNameExists, setScheduleTaskNameExists] =
    useState<boolean>(false);
  const [moduleId, setModuleId] = useState<number>();

  const { action } = useParams() as {
    action: string;
  };

  const [businessPolicyValues, setBusinessPolicyValues] = useState<
    SelectType[]
  >([{ key: 'empty', desc: 'empty' }]);

  const ScheduleTaskName = useWatch({
    control,
    name: 'ScheduleTask.ScheduleTaskName',
  });

  const pipelineNameMask = useMaskInput(
    '',
    t('pages.manageChannel.channelScheduleNameMask'),
    /^[A-Za-z0-9\s_-]*$/,
    ScheduleTaskName ?? ''
  );

  const ScheduleTaskModule = useWatch({
    control,
    name: 'ScheduleTask.Module',
  });

  const { data } = useCheckTaskNameQuery(
    {
      taskName: ScheduleTaskName,
      module: ScheduleTaskModule,
    },
    {
      enabled: ScheduleTaskName !== '' && action === ActionType.CREATE,
    }
  );

  const { data: policyData } = useGetBusinessPoliciesByModuleIdQuery(
    {
      moduleId: moduleId as unknown as number,
    },
    {
      enabled: moduleId !== undefined,
    }
  );

  const { data: modulesData } = useGetModulesByUserQuery();

  const handleHelperTextChange = () => {
    return pipelineNameMask.maskError;
  };

  const validateRequiredFields = () => {
    let isValid = true;

    if (
      watch(`ScheduleTask.ScheduleTaskName`) === '' ||
      watch(`ScheduleTask.DisplayName`) === '' ||
      watch(`ScheduleTask.Module`) === '' ||
      watch(`ScheduleTask.BusinessPolicy`) === '' ||
      pipelineNameMask.maskError ||
      (data?.CheckScheduledTaskExists === true && action === ActionType.CREATE)
    ) {
      isValid = false;
    }

    setTaskDetailsValid(isValid);
  };

  const updateModuleId = useCallback(() => {
    if (modulesData?.ModulesByUser.results) {
      const valueIndex = modulesData.ModulesByUser.results.findIndex(function (
        module: ModuleResponse
      ) {
        return module.name === watch(`ScheduleTask.Module`);
      });

      if (valueIndex !== -1) {
        setModuleId(modulesData?.ModulesByUser.results[valueIndex].moduleId);
        setValue(
          `ScheduleTask.ModuleId`,
          modulesData?.ModulesByUser.results[valueIndex].moduleId
        );
      }
    }
  }, [modulesData, watch, setValue]);

  const updateBusinessPolicyValues = useCallback(() => {
    if (policyData !== undefined) {
      const values = policyData.GetBusinessPoliciesByModuleId.map((policy) => {
        return {
          key: policy.name,
          desc: policy.name,
        } as unknown as SelectType;
      });
      setBusinessPolicyValues(values);
    }
  }, [policyData]);

  useEffect(() => {
    updateBusinessPolicyValues();
  }, [policyData, updateBusinessPolicyValues]);

  const watchedModule = watch(`ScheduleTask.Module`);

  useEffect(() => {
    updateModuleId();
  }, [watchedModule, updateModuleId]);

  useEffect(() => {
    validateRequiredFields();
  });

  useEffect(() => {
    if (data) {
      setScheduleTaskNameExists(data.CheckScheduledTaskExists);
    } else {
      setScheduleTaskNameExists(false);
    }
  }, [data]);

  return !isLoading ? (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            name={`ScheduleTask.Module`}
            render={({ field: { value, onChange } }) => {
              return (
                <Select
                  required
                  id="scheduleTaskModule"
                  sx={{ width: '100%', maxWidth: '20em' }}
                  label={t('pages.manageChannel.module')}
                  value={value}
                  options={
                    modulesData
                      ? modulesData.ModulesByUser.results.map(
                          (module: ModuleResponse) => {
                            return {
                              key: module.name,
                              desc: module.name,
                            } as unknown as SelectType;
                          }
                        )
                      : ([{ key: 'empty' }] as unknown as SelectType[])
                  }
                  onChange={(e) => {
                    setValue(`ScheduleTask.BusinessPolicy`, '');
                    onChange(e.target.value);
                  }}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            name={`ScheduleTask.ScheduleTaskName`}
            render={({ field: { value, onChange } }) => (
              <Input
                required
                id="scheduleTaskName"
                sx={{ width: '100%', maxWidth: '20em' }}
                disabled={action === ActionType.EDIT}
                helperText={
                  ScheduleTaskNameExists && action === ActionType.CREATE
                    ? t('pages.manageChannel.taskNameExists')
                    : handleHelperTextChange()
                }
                label={t('pages.manageChannel.scheduleTaskName')}
                value={value}
                onChange={(e) => {
                  onChange(e.target.value);
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            name={`ScheduleTask.DisplayName`}
            render={({ field: { value, onChange } }) => {
              return (
                <Input
                  required
                  id="scheduleDisplayName"
                  sx={{ width: '100%', maxWidth: '20em' }}
                  label={t('pages.manageChannel.displayName')}
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name={`ScheduleTask.Description`}
            render={({ field: { value, onChange } }) => (
              <TextArea
                id="scheduleDescription"
                multiline
                sx={{ width: '100%', maxWidth: '20em' }}
                label={t('pages.manageChannel.description')}
                value={value}
                onChange={(e) => {
                  onChange(e.target.value);
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            name={`ScheduleTask.BusinessPolicy`}
            render={({ field: { value, onChange } }) => {
              return (
                <Select
                  required
                  id="scheduleTaskPolicy"
                  sx={{ width: '100%', maxWidth: '20em' }}
                  label={t('pages.manageChannel.businessPolicy')}
                  value={value}
                  disabled={policyData === undefined}
                  options={businessPolicyValues}
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                />
              );
            }}
          />
        </Grid>
      </Grid>
    </>
  ) : (
    <Loading />
  );
}

export default ScheduledTasksDetails;
