import { useState, useEffect } from 'react';

import AdvancedSearch from 'pages/Search/AdvancedSearch';
import {
  advancedSearch,
  advancedMutationSearch,
  paymentSearch,
} from 'pages/Search/SearchConfig';
import { useTranslation } from 'react-i18next';
import { setHeader } from 'redux/contentSlice';
import { useAppDispatch } from 'redux/hooks';

function Search(): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [results, setResults] = useState<JSX.Element | JSX.Element[]>(<></>);

  useEffect(() => {
    dispatch(setHeader({ pageTitle: t('pages.search.title') }));
  }, [dispatch, t]);

  return (
    <>
      <AdvancedSearch
        advancedSearch={advancedSearch}
        advancedMutationSearch={advancedMutationSearch}
        paymentSearch={paymentSearch}
        setResults={setResults}
      />
      <>{results}</>
    </>
  );
}

export default Search;
