import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { setHeader } from 'redux/contentSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Card,
  CardContent,
  DataDisplay,
  LogixGroupType,
  MessageActionType,
  MessageType,
  ProgressLoader,
  RootAttributes,
  TextArea,
  WorkflowProps,
} from '@revenue-solutions-inc/revxcoreui';
import {
  ContextInput,
  LogiXgroupInput,
  UpdateDictionary,
  UpdateDictionaryResponse,
  useCreateDictionaryMutation,
  useDictionaryByIdQuery,
  useGetConfigurationRecordsQuery,
  useSystemFieldDictionariesQuery,
  useUpdateDictionaryMutation,
} from 'generated/graphql';
import { dictionaryDefault } from 'types/Dictionary';
import { addMessage } from 'redux/messageSlice';
import Dialog from '@revenue-solutions-inc/revxcoreui/material/controls/Dialog';
import FormDetails from 'pages/admin/CreateForms/FormDetails';
import { JsonPath } from 'utils/filterJsonData';
import CorrespondenceLogix from 'pages/admin/correspondence/CorrespondenceLogix';
import CorrespondenceDataMapper from 'pages/admin/correspondence/CorrespondenceDataMapper';

type Props = {
  isFromTemplate: boolean;
};
interface ActionProps {
  name: string;
  category: string;
  repeatable: boolean;
  statusToBeChanged: string;
  domain: string;
  operationId: string;
  operationType: string;
}

const EditDictionary = (dataProps: Props) => {
  const { t } = useTranslation();
  const module = useAppSelector((state) => state.user.module);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [popUp, setPopUp] = useState<boolean>(false);
  const [LogixGroup, setLogixGroup] = useState<LogiXgroupInput | null>(null);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [completed, setCompleted] = useState<{
    [k: number]: boolean;
  }>({});
  const [stepPassedValidation, setStepPassedValidation] =
    useState<boolean>(true);
  const [, setPipelineDetailsValid] = useState<boolean>(false);
  const [, setWorkFlowAttributes] = useState<RootAttributes[] | []>([]);
  const [forWF, setForWF] = useState<string>('');
  const [type1, setType1] = useState<string>('');
  const [subType, setSubType] = useState<string>('');
  const [typeObj, setTypeObj] = useState<WorkflowProps | null>(null);
  const [subTypeObj, setSubTypeObj] = useState<WorkflowProps | null>(null);
  const [actionsTypes, setActionsTypes] = useState<ActionProps[] | []>([]);
  const [dictionary, setDictionary] =
    useState<UpdateDictionaryResponse>(dictionaryDefault);
  const { dictionaryId } = useParams() as {
    dictionaryId: string;
  };
  const { mutate: updateDictionary } =
    useUpdateDictionaryMutation<UpdateDictionary>();

  dispatch(
    setHeader({
      pageTitle: t('pages.activeDictionary.editTitle'),
      previousPage: t('pages.activeDictionary.title'),
      route: `activeDictionary`,
    })
  );
  const onCancel = () => {
    navigate(`/${module}/activeDictionary`);
  };
  const handleReset = () => {
    setCurrentStep(0);
    setCompleted({});
  };
  const validateSteps = () => {
    let isValid = true;
    if (currentStep == 0) {
      isValid = true;
    } else if (currentStep == 3) {
      isValid = false;
    }
    setStepPassedValidation(isValid);
  };
  const {
    data: DictionaryData,
    refetch: dictionaryRefetch,
    isFetched,
  } = useDictionaryByIdQuery<{
    DictionaryById: UpdateDictionaryResponse;
  }>({
    dictionaryByIdId: parseInt(dictionaryId),
  });
  const { data: systemFieldDictionaryData } = useSystemFieldDictionariesQuery();

  const { data: actionTypesQuery } = useGetConfigurationRecordsQuery({
    configurationModule: '',
    configurationType: 'Form Action Type',
  });
  useEffect(() => {
    if (actionTypesQuery && actionTypesQuery.getConfigurations) {
      const acTypes: ActionProps[] = [];
      actionTypesQuery.getConfigurations.forEach((acType) => {
        const logixFields = JsonPath({
          keyName: 'groupName',
          value: 'Configuration',
          schema: acType,
          searchWith: '',
        });
        const objAct = {
          actionCategory: '',
          actionRepetable: false,
          actionStatus: '',
          actionDomain: '',
          actionOperationType: '',
          actionOperationId: '',
        };
        if (logixFields.length > 0) {
          objAct.actionOperationType =
            logixFields[0].value.attribute.find(
              (it: { attributeName: string }) =>
                it.attributeName === 'OperationType'
            )?.attributeValue ?? '';
          objAct.actionDomain =
            logixFields[0].value.attribute.find(
              (it: { attributeName: string }) => it.attributeName === 'Domain'
            )?.attributeValue ?? '';
          objAct.actionStatus =
            logixFields[0].value.attribute.find(
              (it: { attributeName: string }) =>
                it.attributeName === 'StatusToBeChanged'
            )?.attributeValue ?? false;
          objAct.actionCategory =
            logixFields[0].value.attribute.find(
              (it: { attributeName: string }) => it.attributeName === 'Category'
            )?.attributeValue ?? '';
          objAct.actionRepetable =
            logixFields[0].value.attribute.find(
              (it: { attributeName: string }) =>
                it.attributeName === 'Repeatable'
            )?.attributeValue ?? false;

          objAct.actionOperationId =
            logixFields[0].value.attribute.find(
              (it: { attributeName: string }) =>
                it.attributeName === 'OperationId'
            )?.attributeValue ?? '';
        }
        const action: ActionProps = {
          name: acType.configurationName,
          category: objAct.actionCategory,
          repeatable: Boolean(objAct.actionRepetable),
          statusToBeChanged: objAct.actionStatus,
          domain: objAct.actionDomain,
          operationId: objAct.actionOperationId,
          operationType: objAct.actionOperationType,
        };
        acTypes.push(action);
      });
      setActionsTypes(acTypes);
    }
  }, [actionTypesQuery]);

  useEffect(() => {
    if (DictionaryData?.DictionaryById) {
      const dataFromDictionary = DictionaryData?.DictionaryById;

      setDictionary({
        systemFieldDictionaryId:
          dataFromDictionary.systemFieldDictionaryId as string,
        version: dataFromDictionary.version,
        createdBy: dataFromDictionary.createdBy,
        createdDate: dataFromDictionary.createdDate,
        fixedId: dataFromDictionary.fixedId,
        logixInputSchemaJson: dataFromDictionary.logixInputSchemaJson,
        status: dataFromDictionary.status,
        updatedBy: dataFromDictionary.updatedBy,
        updatedDate: dataFromDictionary.updatedDate,
        description: dataFromDictionary.description,
      });
      if (
        JSON.parse(dataFromDictionary.logixInputSchemaJson).Contexts.length >
          0 &&
        !JSON.parse(dataFromDictionary.logixInputSchemaJson).Contexts[0].Actions
      ) {
        setLogixGroup(
          Object.assign(JSON.parse(dataFromDictionary.logixInputSchemaJson), {
            Contexts: JSON.parse(
              dataFromDictionary.logixInputSchemaJson
            ).Contexts.map((item: ContextInput) => ({ ...item, Actions: [] })),
          }) as unknown as LogiXgroupInput
        );
      } else
        setLogixGroup(
          JSON.parse(
            dataFromDictionary.logixInputSchemaJson
          ) as unknown as LogiXgroupInput
        );
    }
  }, [DictionaryData, DictionaryData?.DictionaryById]);

  useEffect(() => {
    validateSteps();
  });

  useEffect(() => {
    const newCompleted = completed;
    newCompleted[currentStep] = true;

    if (currentStep == 0) {
      newCompleted[currentStep] = true;
    }

    setCompleted(newCompleted);
  }, [completed, currentStep]);
  const { mutate: dictionaryMutate } = useCreateDictionaryMutation({});

  const handleMappedLogixGroupSchema = (logixUpdated: LogixGroupType) => {
    setLogixGroup(logixUpdated as LogiXgroupInput);
    return logixUpdated;
  };
  const handleGetLogixUpdate = (logixUpdated: LogixGroupType) => {
    setLogixGroup(logixUpdated as LogiXgroupInput);
  };

  const DictionaryUpdate = () => {
    if (popUp) {
      if (
        systemFieldDictionaryData?.SystemFieldDictionaries &&
        systemFieldDictionaryData?.SystemFieldDictionaries.length > 0 &&
        systemFieldDictionaryData?.SystemFieldDictionaries?.filter(
          (item) => item.status.toUpperCase() == 'DRAFT'
        ).length > 0
      ) {
        updateDictionary(
          {
            dictionaryUpdate: {
              description: dictionary.description,
              dictionaryId: parseInt(
                systemFieldDictionaryData?.SystemFieldDictionaries?.filter(
                  (item) => item.status.toUpperCase() == 'DRAFT'
                )[0].systemFieldDictionaryId
              ),
              logixInputSchemaJson: JSON.stringify(LogixGroup),
            },
          },
          {
            onSuccess: () => {
              dictionaryRefetch();
              dispatch(
                addMessage({
                  message: t('pages.correspondence.message.updateDictionary'),
                  type: MessageType.Success,
                  actionType: MessageActionType.None,
                })
              );
            },
            onError: () => {
              dispatch(
                addMessage({
                  message: t('pages.manageReusableContent.networkError'),
                  type: MessageType.Error,
                  actionType: MessageActionType.None,
                })
              );
            },
          }
        );
      } else {
        dictionaryMutate(
          {
            createDictionary: {
              fixedId:
                systemFieldDictionaryData?.SystemFieldDictionaries[0]
                  ?.fixedId ?? 0,
              allowCreateIfLatestActive: true,
              logixInputSchemaJson: JSON.stringify(LogixGroup),
              description: dictionary.description,
            },
          },
          {
            onSuccess: () => {
              dictionaryRefetch();
              dispatch(
                addMessage({
                  type: MessageType.Success,
                  message: t('pages.activeDictionary.message.createDictionary'),
                })
              );
            },
            onError: () => {
              dispatch(
                addMessage({
                  type: MessageType.Error,
                  message: t(
                    'pages.activeDictionary.message.errorCreateDictionary'
                  ),
                })
              );
            },
          }
        );
      }
    }
  };

  const handleTextArea = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDictionary((prev) => ({
      ...prev,
      description: event.target.value,
    }));
  };
  const SavePopUpChildren = (
    <DataDisplay
      label={t('pages.activeDictionary.warning')}
      id="warningMessage"
      data=""
    />
  );
  return (
    <>
      <Grid>
        {dataProps.isFromTemplate ? (
          <Dialog
            id="dialogId"
            open={popUp}
            children={SavePopUpChildren}
            title={t('components.message.warning')}
            type="transactional"
            transactionModalTransactionButtonText={t(
              'components.message.proceed'
            )}
            width={350}
            handleClose={() => {
              setPopUp(false);
            }}
            handleCancelClick={() => {
              setPopUp(false);
            }}
            handleTransactionClick={() => {
              setPopUp(false);
              DictionaryUpdate();
            }}
          />
        ) : null}
      </Grid>
      <Grid container spacing={1}>
        <Grid sx={{ display: 'flex' }} item xs={2.5}>
          <Card>
            <CardContent>
              <Grid sx={{ display: 'flex' }}>
                <DataDisplay
                  id={'status'}
                  label={t('pages.activeDictionary.id')}
                  data={dictionary.systemFieldDictionaryId}
                  sxLabel={{ display: 'inline-block' }}
                />
                <DataDisplay
                  id={'version'}
                  label={t('pages.activeDictionary.dictionaryVersion')}
                  data={String(dictionary.version)}
                  sxLabel={{ display: 'inline-block', ml: 4 }}
                  sx={{ ml: 4 }}
                />
              </Grid>
              <DataDisplay
                id={'templateDescription'}
                label={t('pages.activeDictionary.description')}
                data={''}
                sxLabel={{ display: 'inline-block', mt: 2 }}
              />
              <TextArea
                multiline
                ariaLabel={'add description'}
                value={dictionary.description ?? ''}
                sx={{ width: '100%' }}
                onChange={handleTextArea}
              />
              <Grid item mt={2} sx={{ display: 'flex' }}>
                <Button
                  id="deleteBtn"
                  data-testid="update-button"
                  sx={{ ml: 1, minWidth: '128px' }}
                  size="medium"
                  onClick={() => {
                    setPopUp(true);
                  }}
                >
                  {t('pages.activeDictionary.buttons.save')}
                </Button>
                <Button
                  id="cancelBtn"
                  type="secondary"
                  data-testid="update-button"
                  sx={{ ml: 1, minWidth: '128px' }}
                  size="medium"
                  onClick={onCancel}
                >
                  {t('pages.activeDictionary.buttons.cancel')}
                </Button>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        {isFetched && (
          <Grid item xs={9.5}>
            {DictionaryData &&
            DictionaryData?.DictionaryById?.logixInputSchemaJson.length > 0 ? (
              <Card>
                <CardContent>
                  <ProgressLoader
                    completed={completed}
                    handleReset={handleReset}
                    setCurrentStep={setCurrentStep}
                    steps={[
                      t('pages.forms.formStructure'),
                      t('pages.manageChannel.dataMapper'),
                    ]}
                    handleSave={() => {}}
                    nonLinear={false}
                    currentStep={currentStep}
                    handleCurrentStep={(activeStep: number) => {
                      setCurrentStep(activeStep);
                    }}
                    centerAlign
                    isCompleteStepBtnDisabled={!stepPassedValidation}
                    isSaveAndCreateDisabled={true}
                  >
                    {currentStep === 0 && (
                      <CorrespondenceLogix
                        LogixGroup={LogixGroup}
                        handleGetLogixUpdate={handleGetLogixUpdate}
                        formErrors={[]}
                      />
                    )}
                    {currentStep === 1 && (
                      <CorrespondenceDataMapper
                        LogixGroup={LogixGroup}
                        handleMappedLogixGroupSchema={
                          handleMappedLogixGroupSchema
                        }
                        actions={actionsTypes}
                      />
                    )}
                  </ProgressLoader>{' '}
                </CardContent>
              </Card>
            ) : (
              <Card>
                <CardContent>
                  {' '}
                  <ProgressLoader
                    completed={completed}
                    handleReset={handleReset}
                    setCurrentStep={setCurrentStep}
                    steps={[
                      t('pages.forms.formDetails'),
                      t('pages.forms.formStructure'),
                      t('pages.manageChannel.dataMapper'),
                    ]}
                    nonLinear={false}
                    currentStep={currentStep}
                    handleCurrentStep={(activeStep: number) => {
                      setCurrentStep(activeStep);
                    }}
                    centerAlign
                    handleSave={() => {}}
                    isCompleteStepBtnDisabled={!stepPassedValidation}
                    isSaveAndCreateDisabled={true}
                  >
                    {currentStep === 0 && (
                      <FormDetails
                        LogixGroup={LogixGroup}
                        setLogixGroup={setLogixGroup}
                        setPipelineDetailsValid={setPipelineDetailsValid}
                        setWorkFlowAttributes={setWorkFlowAttributes}
                        forWF={forWF}
                        setForWF={setForWF}
                        type={type1}
                        setType={setType1}
                        subType={subType}
                        setSubType={setSubType}
                        typeObj={typeObj}
                        setTypeObj={setTypeObj}
                        subTypeObj={subTypeObj}
                        setSubTypeObj={setSubTypeObj}
                        formCategory={'' as string}
                      />
                    )}
                    {currentStep === 1 && (
                      <CorrespondenceLogix
                        LogixGroup={LogixGroup}
                        formErrors={[]}
                        handleGetLogixUpdate={() => LogixGroup}
                      />
                    )}
                    {currentStep === 2 && (
                      <CorrespondenceDataMapper
                        LogixGroup={LogixGroup}
                        handleMappedLogixGroupSchema={
                          handleMappedLogixGroupSchema
                        }
                        actions={actionsTypes}
                      />
                    )}
                  </ProgressLoader>
                </CardContent>
              </Card>
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default EditDictionary;
