import { RoleResponse } from 'generated/graphql';

const RoleFormDefault: RoleResponse = {
  roleId: '',
  statusId: 'Inactivated',
  moduleId: 1,
  moduleName: '',
  roleName: '',
  roleDescription: '',
  isOOTB: false,
  isRsiOnly: false,
  policyGroups: [],
  authorizationPolicy: [],
};

export { RoleFormDefault };
