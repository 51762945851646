import { useEffect } from 'react';
import { setHeader } from 'redux/contentSlice';

import { useAppDispatch } from 'redux/hooks';
import { DestinationObject } from '@revenue-solutions-inc/revxcoreui';

import { useGetAllSinkObjectCollectionQuery } from 'generated/graphql';

import { useTranslation } from 'react-i18next';
import Loading from 'components/Loading';
import SinkView from './SinkView';

function SinkObjects(): JSX.Element {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(
      setHeader({
        pageTitle: t('Sink Object Browser'),
        route: `dashboard`,
      })
    );
  }, [dispatch, t]);
  const { data, isFetching } = useGetAllSinkObjectCollectionQuery();

  return (
    <>
      {isFetching && <Loading />}
      {!isFetching && (
        <div style={{ height: '100%' }}>
          <SinkView
            sinkObjects={
              data
                ? (data.GetAllSinkObjectCollection as unknown as DestinationObject)
                : ({} as unknown as DestinationObject)
            }
          />
        </div>
      )}
    </>
  );
}

export default SinkObjects;
