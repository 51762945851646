import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { DataDisplay } from '@revenue-solutions-inc/revxcoreui';
import { useAppSelector } from 'redux/hooks';
import { PeriodDetailsResponse } from 'generated/graphql';
import { Grid, Typography } from '@mui/material';
import ControlledInputField from 'components/controls/ControlledInputField';
import { Link } from 'react-router-dom';
import { formatCurrency, getDate } from 'common/helpers';
import { Control, FieldValues, UseFormGetValues } from 'react-hook-form';

interface EditPeriodRowProps {
  periods: PeriodDetailsResponse[];
  rowId: string;
  currentRowEdit: string;
  control: Control<FieldValues, unknown>;
  getValues: UseFormGetValues<FieldValues>;
  showPreview: boolean;
}

function EditPeriodRow({
  periods,
  rowId,
  currentRowEdit,
  control,
  getValues,
  showPreview,
}: EditPeriodRowProps) {
  const { t } = useTranslation();
  const module = useAppSelector((state) => state.user.module);

  const getPeriodDates = (period: PeriodDetailsResponse) => {
    return `${getDate(period.periodBeginDate as string)} - ${getDate(
      period.periodEndDate as string
    )}`;
  };

  return (
    <>
      <Box sx={{ width: '100%', margin: 'auto' }}>
        <>
          {periods.map((period) => (
            <Grid
              container
              my={2}
              key={`period_${period.financialAccountPeriodId}`}
            >
              <Grid item xs={3} ml={'70px'}>
                <Typography variant="h3">
                  {t('pages.submissionDetails.period')}
                </Typography>
                <Link
                  to={{
                    pathname: `/${module}/entity/${period.entityId}/account/${period.externalAccountId}/period/${period.financialAccountPeriodId}`,
                  }}
                >
                  {getPeriodDates(period)}
                </Link>
              </Grid>
              <Grid item xs={3}>
                <DataDisplay
                  id="accountType"
                  label={t('pages.submissionDetails.accountType')}
                  data={period.accountType as string}
                />
              </Grid>
              <Grid item xs={2}>
                {rowId === currentRowEdit ? (
                  <ControlledInputField
                    control={control}
                    rules={{
                      required: true,
                    }}
                    name={`${period.financialAccountPeriodId}`}
                    required
                    id={`period_${rowId}`}
                    label={t('pages.submissionDetails.amount')}
                  />
                ) : (
                  <DataDisplay
                    id="amount"
                    label={t('pages.submissionDetails.amount')}
                    data={
                      showPreview
                        ? formatCurrency(period.amount as unknown as string)
                        : formatCurrency(
                            getValues(
                              `${period.financialAccountPeriodId}`
                            ) as unknown as string
                          )
                    }
                  />
                )}
              </Grid>
              {showPreview && (
                <Grid item xs={2}>
                  <DataDisplay
                    id="correctedAmount"
                    label={t('pages.submissionDetails.correctedAmount')}
                    data={formatCurrency(
                      getValues(
                        `${period.financialAccountPeriodId}`
                      ) as unknown as string
                    )}
                  />
                </Grid>
              )}
            </Grid>
          ))}
        </>
      </Box>
    </>
  );
}
export default EditPeriodRow;
