import { RefObject, useEffect, useState } from 'react';
import { Grid, SelectChangeEvent } from '@mui/material';
import {
  CreateLayoutConfigurationInput,
  FormDataObject,
  FormLayoutConfiguration,
  LogiXgroupInput,
  useCreateFormLayoutMutation,
  useGetAllFormLayoutsQuery,
  useGetLookupTypesConfigurationQuery,
  useUpdateFormLayoutMutation,
} from 'generated/graphql';
import {
  Select,
  FormErrors,
  MessageType,
  generateDefaultLayout,
} from '@revenue-solutions-inc/revxcoreui';
import LayoutBuilderWrapper from 'pages/admin/LayoutBuilderWrapper';
import { parseLogixGroupInput } from 'common/adapters/formInputAdapter';
import { LayoutBuilderHandle } from '@revenue-solutions-inc/revxcoreui/material/layoutBuilder/builder/AppPanel';
import {
  LayoutType,
  MasterLayout,
  MasterLayoutType,
} from '@revenue-solutions-inc/revxcoreui/material/layoutBuilder/types/layouts';
import { SelectType } from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import {
  ConfigurationDomains,
  ConfigurationModules,
} from 'common/platformConfigUtils/platformConfigUtils';
import { Error } from 'types/graphqlErrors';
import { IMasterLayout } from '@revenue-solutions-inc/revxcoreui/material/layoutBuilder/types/fields';
import { useAppDispatch } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import { useTranslation } from 'react-i18next';
import { getMasterLayout } from 'utils/getMasterLayout';
import Loading from 'components/Loading';

interface Props {
  LogixGroup: LogiXgroupInput | null;
  layoutRef: RefObject<LayoutBuilderHandle>;
  latestVersionOfLayout: VersionArray;
  form: FormDataObject;
  setFormErrors: (errors: FormErrors[]) => void;
  hideSaveButton?: boolean;
}
interface VersionArray {
  [key: string]: FormLayoutConfiguration;
}

function FormLayoutBuilder({
  LogixGroup,
  layoutRef,
  latestVersionOfLayout,
  form,
  setFormErrors,
  hideSaveButton = false,
}: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [layouts, setLayouts] = useState<FormLayoutConfiguration[]>([]);
  const [masterLayout, setMasterLayout] = useState<MasterLayout | undefined>(
    undefined
  );
  const [contextOptions, setContextOptions] = useState<SelectType[] | []>([]);
  const [formContext, setFormContext] = useState<string>('');
  const [checkRequest, setCheckRequest] = useState<boolean>(false);
  const [updateAllComponent, setUpdateAllComponent] = useState<boolean>(true);
  const [multipleLayouts, setMultipleLayouts] = useState<
    CreateLayoutConfigurationInput[]
  >([]);

  const { data } = useGetLookupTypesConfigurationQuery({
    configurationDomain: ConfigurationDomains.ReferenceSchema,
    configurationModule: ConfigurationModules.Platform,
    configurationType: 'LayoutContext',
  });
  const {
    data: fetchLayoutData,
    isLoading: isLoadingGetLayout,
    refetch: refetchGetLayout,
  } = useGetAllFormLayoutsQuery(
    {
      configurationId: form?.Forms ? form?.Id : 'UhOh',
    },
    {
      enabled: !!(form.Forms && form.Forms.length > 0),
    }
  );
  const { mutate: updateLayoutMutation, isLoading: isLoadingUpdateLayout } =
    useUpdateFormLayoutMutation({});
  const { mutate: createNewLayoutMutation, isLoading: isLoadingCreateLayout } =
    useCreateFormLayoutMutation({});

  useEffect(() => {
    if (fetchLayoutData !== undefined) {
      const allLayouts = fetchLayoutData.getAllFormLayouts;
      setLayouts(allLayouts);
      allLayouts.forEach((layout) => {
        //If there's an existing entry
        if (layout.context && latestVersionOfLayout[layout.context]) {
          const currentLatest = latestVersionOfLayout[layout.context];

          if (
            layout.version &&
            currentLatest.version &&
            layout.version > currentLatest.version
          ) {
            latestVersionOfLayout[layout.context] = layout;
          }
        } else if (layout.context) {
          latestVersionOfLayout[layout.context] = layout;
        }
      });
      if (updateAllComponent) {
        const allOpts: SelectType[] =
          allLayouts
            ?.filter((layout) => {
              if (
                layout.context &&
                latestVersionOfLayout[
                  layout.context
                ]?.context?.toLowerCase() === layout.context.toLowerCase()
              ) {
                return true;
              }
              return false;
            })
            .map((layout) => {
              return {
                key: layout.context || '',
                desc: layout.context || '',
              };
            }) || [];
        setContextOptions(allOpts);
        setCheckRequest(true);
        setUpdateAllComponent(false);
        if (allOpts.length > 0) setFormContext(allOpts[0].key);
      }
      setMasterLayout(getMasterLayout(allLayouts, formContext));
    } else {
      setCheckRequest(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchLayoutData]);
  useEffect(() => {
    if (
      data !== undefined &&
      data.GetLookupTypesConfiguration.length > 0 &&
      checkRequest
    ) {
      const moreContextOpts = data.GetLookupTypesConfiguration.filter((it) => {
        return !contextOptions
          .map((ctxO) => ctxO.desc)
          .includes(it.configurationDescription?.toString() ?? '');
      }).map((it) => ({
        key: it.configurationName,
        desc: it.configurationDescription,
      }));
      setContextOptions((prev) => [
        ...prev,
        ...(moreContextOpts as SelectType[]),
      ]);
      setCheckRequest(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, checkRequest]);

  const onContextChange = (event: SelectChangeEvent<string | number>) => {
    setFormContext(event.target.value.toString());
    setMasterLayout(getMasterLayout(layouts, event.target.value.toString()));
  };

  const dispatchMessage = (type: MessageType, message: string) => {
    dispatch(
      addMessage({
        type: type,
        message: message,
      })
    );
  };

  const saveLayout = (layout: IMasterLayout) => {
    setMasterLayout(layout);
    const desc = contextOptions.find((co) => co.key === formContext)?.desc;

    const existingLayout = layouts?.find((l) => {
      return l.context === desc;
    });
    if (layouts && existingLayout) {
      const updatedLayout = { ...layout };
      updatedLayout.version = existingLayout.version || 0;
      updateLayoutMutation(
        {
          layoutConfigurationInput: {
            context: existingLayout.context || 'undefined',
            isNewVersion: true,
            layout: JSON.stringify(updatedLayout),
            layoutType: existingLayout.layoutType,
            layoutConfigurationId: existingLayout.layoutConfigurationId,
            configurationId: existingLayout.configurationId,
            configurationVersion: existingLayout.configurationVersion,
          },
        },
        {
          onSuccess: () => {
            refetchGetLayout();
            dispatchMessage(
              MessageType.Success,
              t('pages.manageForms.updateSuccessLayout')
            );
          },
          onError: (errors) => {
            const formErr = (errors as Error[])[0].extensions.response.body
              .errors;
            if (formErr && formErr?.length > 0) {
              setFormErrors(formErr as FormErrors[]);
              dispatchMessage(
                MessageType.Error,
                (errors as Error[])[0].extensions.response.body.detail as string
              );
            } else {
              dispatchMessage(
                MessageType.Error,
                t('components.message.networkerror')
              );
            }
          },
        }
      );
    } else if (layouts && data && form.Forms && form.Forms.length > 0) {
      const currentOpt = contextOptions.find((opt) => opt.key === formContext);
      if (currentOpt) {
        createNewLayoutMutation(
          {
            layoutConfigurationInput: {
              context: currentOpt.desc,
              layout: JSON.stringify({ ...layout }),
              layoutType: 'LOGIX',
              configurationId: form.Id,
              configurationVersion: form.Version,
            },
          },
          {
            onSuccess: () => {
              refetchGetLayout();
              dispatchMessage(
                MessageType.Success,
                t('pages.manageForms.saveSuccessLayout')
              );
            },
            onError: (errors) => {
              const formErr = (errors as Error[])[0].extensions.response.body
                .errors;
              if (formErr && formErr?.length > 0) {
                setFormErrors(formErr as FormErrors[]);
                dispatchMessage(
                  MessageType.Error,
                  (errors as Error[])[0].extensions.response.body
                    .detail as string
                );
              } else {
                dispatchMessage(
                  MessageType.Error,
                  t('components.message.networkerror')
                );
              }
            },
          }
        );
      } else {
        dispatch(
          addMessage({
            type: MessageType.Warning,
            message: 'Could not find option to create new layout',
          })
        );
      }
    } else {
      dispatch(
        addMessage({
          type: MessageType.Warning,
          message: 'layouts or existingLayout missing',
        })
      );
    }
  };

  return (
    <>
      {(isLoadingUpdateLayout ||
        isLoadingCreateLayout ||
        isLoadingGetLayout) && <Loading />}
      <Grid item container xs={12}>
        <Grid item xs={2}>
          <Select
            options={contextOptions}
            label={t('pages.manageForms.layoutContext')}
            id="CTX_SELECT"
            onChange={onContextChange}
            value={formContext}
          />
        </Grid>
      </Grid>
      <LayoutBuilderWrapper
        fields={parseLogixGroupInput(LogixGroup as LogiXgroupInput)}
        saveConfiguration={(mLayout: IMasterLayout) => {
          saveLayout(mLayout);
        }}
        masterLayout={masterLayout}
        hideSaveButton={hideSaveButton}
        ref={layoutRef}
        viewLayout={(view) => {
          let tempLayout = [...multipleLayouts];
          const currentContext = contextOptions.find(
            (c) => c.key === formContext
          );
          const currentLayout: MasterLayout =
            layoutRef.current?.getLayout() as MasterLayout;

          //This is only for suspense context
          if (currentContext?.desc === 'Suspense') {
            // first check that suspense present in the multipleLayouts
            // if yes then we must replace, do not add new layout in array
            if (
              tempLayout.some((item) => item.context === 'Suspense') &&
              !view
            ) {
              // if masterLayout has atleast one layout then only add it in a multipleLayout
              if (
                currentLayout.sections.some((section) => {
                  return section.layouts.length > 0 || section?.helptext;
                }) ||
                ((currentLayout.type === 'TABS' ||
                  currentLayout.type === 'WIZARD') &&
                  currentLayout.sections.length !== 2) ||
                currentLayout.sections.some((section, index) => {
                  if (currentLayout.type === 'TABS') {
                    return section.name !== `Tab ${index + 1}`;
                  } else if (currentLayout.type === 'WIZARD') {
                    return section.name !== `Step ${index + 1}`;
                  }
                })
              ) {
                // UPDATE
                tempLayout = multipleLayouts.map((item) => {
                  if (item.context === 'Suspense') {
                    item.layout = JSON.stringify(currentLayout);
                  }
                  return item;
                });
                // ADD in final array
                setMultipleLayouts([...tempLayout]);
                // set Mlayout
                setMasterLayout(currentLayout);
              } else {
                // REMOVE
                tempLayout.splice(
                  tempLayout.findIndex((item) => item.context === 'Suspense'),
                  1
                );
                // REMOVE in final array
                setMultipleLayouts([...tempLayout]);
                // set Mlayout to default suspense renderer
                setMasterLayout(
                  generateDefaultLayout(
                    parseLogixGroupInput(LogixGroup as LogiXgroupInput),
                    LayoutType.OneColumn,
                    MasterLayoutType.Suspense
                  )
                );
              }
            } else if (
              !view &&
              !tempLayout.some((item) => item.context === 'Suspense')
            ) {
              // if no suspense present in multipleLayouts, add new layout
              // add only if we have one layout in masterLayout
              if (
                currentLayout.sections.some((section) => {
                  return section.layouts.length > 0 || section?.helptext;
                }) ||
                ((currentLayout.type === 'TABS' ||
                  currentLayout.type === 'WIZARD') &&
                  currentLayout.sections.length !== 2) ||
                currentLayout.sections.some((section, index) => {
                  if (currentLayout.type === 'TABS') {
                    return section.name !== `Tab ${index + 1}`;
                  } else if (currentLayout.type === 'WIZARD') {
                    return section.name !== `Step ${index + 1}`;
                  }
                })
              ) {
                // ADD
                tempLayout.push({
                  context: currentContext?.desc || 'Default',
                  layout: JSON.stringify(currentLayout),
                  layoutType: 'DummyType',
                });
                // ADD in final array
                setMultipleLayouts([...tempLayout]);
                // set Mlayout
                setMasterLayout(currentLayout);
              } else {
                // NO container present in masterLayout and click on view layout button
                // add default suspense renderer
                setMasterLayout(
                  generateDefaultLayout(
                    parseLogixGroupInput(LogixGroup as LogiXgroupInput),
                    LayoutType.OneColumn,
                    MasterLayoutType.Suspense
                  )
                );
              }
            } else if (
              view &&
              !tempLayout.some((item) => item.context === 'Suspense')
            ) {
              setMasterLayout(undefined);
            }
          }
        }}
      />
    </>
  );
}

export default FormLayoutBuilder;
