import { useEffect, useRef, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

import { useTranslation } from 'react-i18next';
import { Button, IconButton, Input } from '@mui/material';

type AttachmentSearchProps = {
  onSearchChange?: (value: string) => void;
};

const AttachmentSearch = ({
  onSearchChange: onSearch,
}: AttachmentSearchProps) => {
  const { t } = useTranslation();
  const [isTextMode, setTextMode] = useState(false);
  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (isTextMode) {
      inputRef.current?.focus();
    }
  }, [isTextMode]);

  return isTextMode ? (
    <Input
      aria-label={t('components.button.search')}
      placeholder={t('components.button.search')}
      inputRef={inputRef}
      onBlur={() => {
        if (inputRef?.current?.value === '') {
          setTextMode(false);
        }
      }}
      onChange={(e) => {
        if (onSearch) onSearch(e.target.value);
      }}
      endAdornment={
        <IconButton
          size="small"
          onClick={() => {
            setTextMode(false);
            if (onSearch) onSearch('');
          }}
        >
          <CloseIcon />
        </IconButton>
      }
      sx={{
        flex: 1,
      }}
    />
  ) : (
    <Button
      onClick={() => {
        setTextMode(true);
      }}
      variant="contained"
    >
      <SearchIcon />
      {t('components.button.search')}
    </Button>
  );
};

export default AttachmentSearch;
