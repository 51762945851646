import { useMemo } from 'react';
import { useGetWorkflowGroupVariantsQuery } from 'generated/graphql';

export function useVariantGroupData(variantType: string) {
  const { data: groupVariantData } = useGetWorkflowGroupVariantsQuery();

  const variantGroup = useMemo(() => {
    return groupVariantData
      ? groupVariantData?.GetWorkflowGroupVariants.find(
          (grp) => String(grp.code) === variantType
        )
      : undefined;
  }, [groupVariantData, variantType]);

  return {
    variantGroup,
  };
}
