import { Fragment, useContext } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button/Button';
import DatePicker from '@revenue-solutions-inc/revxcoreui/material/controls/DatePicker';
import Select from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import EntityManagementContext from 'components/contexts/EntityManagement';
import ControlledField from 'components/ControlledField';
import DatasourceSelect from 'components/DatasourceSelect';
import { processAddressForm } from 'components/entityManagement/common/entityManagementUtils';
import { getDefault } from 'components/entityManagement/common/defaults/account';
import {
  Controller,
  UseFormReturn,
  useFieldArray,
  useWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Section, SectionField } from 'types/forms';
import { toDate } from 'utils/date-util';

interface Props {
  register: UseFormReturn['register'];
  control: UseFormReturn['control'];
  setValue: UseFormReturn['setValue'];
  getValues: UseFormReturn['getValues'];
  section: Section;
  isRequired?: boolean;
  showOptional?: boolean;
  entityId?: string;
  handleShowOptional?: (display: boolean, name?: string) => void;
}

const formLayout = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  overflow: 'hidden',
  marginTop: '25px',
  marginBottom: '30px',
};

const elementTitle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
};

function SectionContainer({
  control,
  section,
  isRequired = true,
  showOptional = false,
  entityId,
  setValue,
  getValues,
  handleShowOptional,
}: Props): JSX.Element {
  let activeSection = section;
  const multipleSections = ['emailAddresses', 'phoneNumbers'];
  const requiredMultipleSections = ['filingFrequencies'];
  const { t } = useTranslation();
  const ctx = useContext(EntityManagementContext);

  const { fields, remove } = useFieldArray({
    control,
    name: section.sectionIdentifier,
  });
  const countryValue = useWatch({
    control,
    name: `${section.sectionIdentifier}.0.countryId`,
  });

  if (section.sectionIdentifier === 'addresses') {
    activeSection = processAddressForm(section, countryValue);
  }

  const getCommenceDate = (
    field: SectionField,
    value: Date | string
  ): Date | null => {
    if (field.fieldIdentifier === 'commenceDate') {
      return value !== null
        ? toDate(value)
        : value !== ''
        ? toDate(ctx.selectedCommenceDate)
        : null;
    }
    return value !== '' ? toDate(value) : null;
  };

  const handleOptionalForm = (active: boolean) => () => {
    if (active) {
      setValue(section.sectionIdentifier, [
        getDefault(section.sectionIdentifier),
      ]);
    } else setValue(section.sectionIdentifier, []);
    if (handleShowOptional) {
      if (multipleSections.includes(section.sectionIdentifier))
        handleShowOptional(active, section.sectionIdentifier);
      else handleShowOptional(active);
    }
  };

  const addSection = () => {
    setValue(section.sectionIdentifier, [
      ...getValues(section.sectionIdentifier),
      {
        ...getDefault(section.sectionIdentifier),
      },
    ]);
  };

  const removeSection = (index: number) => {
    remove(index);
  };

  const showFilingFrequencies = (index: number): JSX.Element => {
    return (
      <>
        {activeSection.fields
          .filter((field) => !field.hidden)
          .map((field) => {
            const keyElement =
              activeSection.sectionIdentifier +
              '.' +
              index +
              '.' +
              field.fieldIdentifier;
            return (
              <Grid
                sx={{ marginBottom: '15px' }}
                key={keyElement}
                item
                xs={12}
                sm={6}
                md={3}
              >
                <Controller
                  name={keyElement}
                  control={control}
                  rules={field.rules}
                  defaultValue={''}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => {
                    const errorMessage = error?.message;
                    switch (field.type?.toLowerCase()) {
                      case 'select':
                        return (
                          <Select
                            id={keyElement}
                            options={field.options ?? []}
                            value={value}
                            onChange={onChange}
                            label={field.label}
                            required={field.isRequired}
                            error={errorMessage}
                            disabled={field.disabled}
                            sx={{
                              width: '100%',
                            }}
                          />
                        );
                      case 'datasource':
                        return (
                          <DatasourceSelect
                            id={keyElement}
                            label={field.label}
                            value={value}
                            errorMsg={errorMessage}
                            datasource={field.datasource ?? ''}
                            groupName={field.groupName ?? ''}
                            attributeName={field.attributeName ?? ''}
                            extraType={field.extraType ?? ''}
                            context={field.context ?? ''}
                            fetchLayoutInfo={onChange}
                            required={field.isRequired}
                            maxWidth={true}
                          />
                        );
                      default:
                        return (
                          <DatePicker
                            id={keyElement}
                            value={getCommenceDate(field, value)}
                            handleChange={(ev) => onChange(ev)}
                            label={field.label}
                            requiredErrorMessage={errorMessage}
                            required={field.isRequired}
                            disabled={field.disabled}
                            fullWidth={true}
                          />
                        );
                    }
                  }}
                />
              </Grid>
            );
          })}
      </>
    );
  };

  if (!isRequired) {
    return (
      <>
        {showOptional && (
          <Box sx={formLayout}>
            <Box sx={elementTitle}>
              <Typography variant="h2">{section.sectionTitle}</Typography>
              <IconButton
                aria-label="delete"
                onClick={handleOptionalForm(false)}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
            {fields.map((item, index) => {
              return (
                <Fragment key={item.id}>
                  <ControlledField
                    control={control}
                    section={activeSection}
                    entityId={entityId}
                    setValue={setValue}
                    haveIndex={true}
                    index={index}
                  />
                </Fragment>
              );
            })}
          </Box>
        )}
        {!showOptional && (
          <Button
            id={'add-new-' + section.sectionTitle}
            type="secondary"
            sx={{
              marginTop: '10px',
              marginBottom: '15px',
              marginRight: '15px',
            }}
            onClick={handleOptionalForm(true)}
          >
            {t('pages.createAccount.buttonTitle')} {section.sectionTitle}
          </Button>
        )}
      </>
    );
  }

  return (
    <Box sx={formLayout}>
      <Typography sx={{ marginBottom: '15px' }} variant="h2">
        {section.sectionTitle}
      </Typography>
      {fields.map((item, index) => {
        return (
          <Fragment key={item.id}>
            <>
              {requiredMultipleSections.includes(section.sectionIdentifier) ? (
                <Grid container spacing={4}>
                  {showFilingFrequencies(index)}
                  {index !== 0 && (
                    <Grid
                      key={'delete-filingFrequency-' + item.id}
                      sx={{ marginTop: '20px' }}
                      item
                      xs={12}
                      sm={6}
                      md={3}
                    >
                      <IconButton
                        aria-label="delete"
                        onClick={() => removeSection(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              ) : (
                <ControlledField
                  control={control}
                  section={activeSection}
                  entityId={entityId}
                  setValue={setValue}
                  haveIndex={true}
                  index={index}
                />
              )}
            </>
          </Fragment>
        );
      })}
      {requiredMultipleSections.includes(section.sectionIdentifier) && (
        <Button
          id={'add-new-' + section.sectionTitle}
          type="secondary"
          sx={{
            marginTop: '15px',
            marginBottom: '5px',
          }}
          onClick={addSection}
        >
          {t('pages.createAccount.buttonTitle')} {section.sectionTitle}
        </Button>
      )}
    </Box>
  );
}

export default SectionContainer;
