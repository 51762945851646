import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios from 'axios';

//Due to limitation from Graphql to download pdf data
//We are downloading file from UI by skipping Graphql
//This is a hook to download letter from content management

const fetchLetterByContentMgmtId = async (
  token: string | undefined,
  contentId: string,
  letterId?: string
): Promise<void | null> => {
  const fileName = `${letterId}.pdf`;
  await axios
    .get(
      `${process.env.REACT_APP_CONTENT_MANAGEMENT}/content/contentManagementId/${contentId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'blob',
      }
    )
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      link.click();
    });
  return null;
};

export default function useGetContentMgmtLetter(
  token: string | undefined,
  contentId: string,
  letterId?: string
): UseQueryResult<void | null, Error> {
  return useQuery<void | null, Error>(
    ['ContentMgmtLetter'],
    () => {
      if (token && token?.length > 1 && contentId && contentId.length > 1)
        return fetchLetterByContentMgmtId(token, contentId, letterId);
      else return null;
    },
    {
      enabled: false,
    }
  );
}
