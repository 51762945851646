import { useState } from 'react';
import { formatObject } from 'utils/object-utils';
import Loading from 'components/Loading';
import { useGetUnassignedOwnerQuery, WorkflowItem } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { useFormatWorkflowDataForTable } from 'hooks/useFormatWorkflowDataForTable';
import { WorkHistoryTable } from 'components/Workflow/WorkHistoryTable/WorkHistoryTable';
import {
  RowDataDrawer,
  WorkflowDataDetailsType,
} from 'components/RowDataDisplay/RowDataDrawer';
import UpdateWorkflowButton from './UpdateWorkflowButton';

export const UnassignedWorkflowsTable = (): JSX.Element => {
  const {
    data: fetchedData,
    isLoading,
    isError,
  } = useGetUnassignedOwnerQuery<{
    GetUnassignedOwner: WorkflowItem[];
  }>();
  const { formatDataForTable } = useFormatWorkflowDataForTable();

  const tableData = formatDataForTable(fetchedData?.GetUnassignedOwner ?? []);

  const { t } = useTranslation();
  const [selectedWorkflow, setSelectedWorkflow] = useState<
    WorkflowDataDetailsType | undefined
  >();

  //this condition returns an empty fragment avoiding two error components rendering on the dashboard page for Assigned && Unassigned workflow tables
  if (isError) {
    return <div data-testid="error-return"></div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  if (tableData?.length === 0) {
    return <div data-testid="noData-return"></div>;
  }

  {
    return (
      <>
        <WorkHistoryTable
          tableName={t('pages.workflow.assignedTable.unassignedTitle')}
          cursorHover
          tableData={tableData as unknown[]}
          onRowClick={({ original }) =>
            setSelectedWorkflow(formatObject(original))
          }
        />
        <RowDataDrawer
          title={`Work ${selectedWorkflow?.displayId ?? ''}`}
          open={!!selectedWorkflow}
          data={selectedWorkflow}
          onClose={() => setSelectedWorkflow(undefined)}
          footer={
            <UpdateWorkflowButton
              workflowKey={`${selectedWorkflow?.workflow_Key}`}
              workflowVariantId={`${selectedWorkflow?.workflow_Variant_Id}`}
            />
          }
        />
      </>
    );
  }
};
