import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { Grid } from '@mui/material';
import {
  Card,
  CardContent,
  CardHeader,
} from '@revenue-solutions-inc/revxcoreui';
import { Theme } from '@mui/system';
import DataDisplay from '@revenue-solutions-inc/revxcoreui/material/controls/DataDisplay';
import { useTranslation } from 'react-i18next';

interface Props {
  propertyId: string;
  legalDescription: string;
  propertyType: string;
  propertySubType: string;
  setActive: React.Dispatch<React.SetStateAction<string>>;
}

function PropertySummaryCard({
  propertyId,
  legalDescription,
  propertyType,
  propertySubType,
  setActive,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <Card
      sx={{
        borderRadius: '5px',
        boxShadow: '0px 0px 5px rgb(0 0 0 / 30%)',
      }}
    >
      <CardHeader
        sx={(theme: Theme) => {
          return {
            backgroundColor: theme.palette.white.main,
            height: '40px',
          };
        }}
        action={
          <OpenInFullIcon
            onClick={() => {
              setActive('ActivePropertySummaryCard');
            }}
            fontSize="inherit"
            sx={(theme: Theme) => {
              return {
                fill: theme.palette.linkBlue.main,
                cursor: 'pointer',
              };
            }}
          />
        }
        title={t('pages.propertyView.propertySummary')}
      />
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <DataDisplay
              id="propertyId"
              label={t('pages.propertyView.propertyId')}
              data={propertyId}
            />
          </Grid>
          <Grid item xs={6}>
            <DataDisplay
              id="legalDescription"
              label={t('pages.propertyView.legalDescription')}
              data={legalDescription}
            />
          </Grid>
          <Grid item xs={12}>
            <DataDisplay
              id="propertyType"
              label={t('pages.propertyView.propertyType')}
              data={propertyType}
            />
          </Grid>
          <Grid item xs={6}>
            <DataDisplay
              id="propertySubType"
              label={t('pages.propertyView.propertySubType')}
              data={propertySubType}
            />
          </Grid>
          <Grid item xs={6}>
            <DataDisplay
              id="propertySubType"
              label={t('pages.propertyView.taxStatement')}
              data={'June 1, 2021'}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default PropertySummaryCard;
