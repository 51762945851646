import { useCallback, useEffect, useState } from 'react';
import {
  Attribute,
  Group,
  useGetAccountPeriodDetailQuery,
  useGetAccountPeriodsAndBalanceQuery,
} from 'generated/graphql';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { HeaderButtonLink, IHeader, setHeader } from 'redux/contentSlice';
import { addMessage } from 'redux/messageSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
interface Details {
  label: string;
  value: string;
}
import {
  MessageActionType,
  MessageType,
} from '@revenue-solutions-inc/revxcoreui';

import { getDate } from 'common/helpers';
import extractMeaningfulMessage from 'utils/errorMessage';
import { deepCopy } from 'utils/deepCopy';
import Grid from '@mui/material/Grid';
import AccountPeriodTransactions from './AccountPeriodTransactions';
import EditPeriodDetails from '../EditPeriodDetails';

function AccountPeriod() {
  const { periodId, entityId, accountId } = useParams() as {
    periodId: string;
    entityId: string;
    accountId: string;
  };
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const module = useAppSelector((state) => state.user.module);
  const [details, setDetails] = useState<Details[] | null>(null);
  const [currPeriodAttributeGroup, setCurrPeriodAttributeGroup] = useState<
    Group[] | null
  >();
  const [isPnIUpToDate, setIsPnIUpToDate] = useState<boolean>(false);
  const [prevPeriod, setPrevPeriod] = useState<number>();
  const [nextPeriod, setNextPeriod] = useState<number>();

  const { data: periodDetails, isLoading } = useGetAccountPeriodDetailQuery(
    {
      getAccountPeriodDetailId: periodId,
    },
    {
      enabled: !!periodId,
      onSuccess: (periodData) => {
        const data = periodData.GetAccountPeriodDetail;
        const detailsData: Details[] = [];

        //TODO: need a better way to grab period due date
        let attributeDueDate = '-';
        data.periodJsonInfo.group.some((group: Group) => {
          return group.attribute.some((attribute: Attribute) => {
            if (attribute.attributeName.toLocaleLowerCase() === 'duedate') {
              attributeDueDate = attribute.attributeValue;
              return true;
            }
          });
        });

        data.periodJsonInfo.group.forEach(
          (item: { attribute: { reapeatingValue: unknown }[] }) =>
            delete item.attribute[0].reapeatingValue
        );

        const currentPeriodAttrGroup = deepCopy(data.periodJsonInfo.group);
        setCurrPeriodAttributeGroup(currentPeriodAttrGroup);

        detailsData.push({
          label: 'periodType',
          value: data.periodType ? data.periodType : '',
        });
        detailsData.push({
          label: 'periodStartDate',
          value: data.beginDate ? getDate(data.beginDate) : '',
        });
        detailsData.push({
          label: 'periodEndDate',
          value: data.endDate ? getDate(data.endDate) : '',
        });

        detailsData.push({
          label: 'dueDate',
          value: getDate(attributeDueDate),
        });
        detailsData.push({
          label: 'lastEffectiveDate',
          value: data.lastEffectiveDate ? getDate(data.lastEffectiveDate) : '-',
        });
        setDetails(detailsData);
        setIsPnIUpToDate(data.isPnIUpToDate ? true : false);
      },
      onError: (error) => {
        const message = extractMeaningfulMessage(
          error,
          t('components.message.networkerror')
        );
        dispatch(
          addMessage({
            message: message,
            type: MessageType.Error,
            actionType: MessageActionType.None,
          })
        );
      },
    }
  );

  //React query should  cache this call so that we are not making a new one each page visit
  const { data: accountPeriods } = useGetAccountPeriodsAndBalanceQuery(
    {
      accountId: accountId,
    },
    {
      enabled: !!accountId,
      staleTime: 3 * (60 * 1000), //3 minutes
    }
  );

  const getHeaderLinks = useCallback(() => {
    const headerLinks = [];
    if (prevPeriod) {
      headerLinks.push({
        id: 'create_prev_period',
        title: t('pages.periodDetails.previous'),
        type: 'primary',
        startIcon: <KeyboardArrowLeftIcon />,
        route: `/${module}/entity/${entityId}/account/${accountId}/period/${prevPeriod}`,
      } as HeaderButtonLink);
    }
    if (nextPeriod) {
      headerLinks.push({
        id: 'create_next_period',
        title: t('pages.periodDetails.next'),
        type: 'primary',
        endIcon: <KeyboardArrowRightIcon />,
        route: `/${module}/entity/${entityId}/account/${accountId}/period/${nextPeriod}`,
      } as HeaderButtonLink);
    }
    return headerLinks;
  }, [prevPeriod, nextPeriod, t, module, accountId, entityId]);

  //Update sub-header
  useEffect(() => {
    if (!isLoading && entityId && accountId) {
      const header: IHeader = {
        pageTitle: t('pages.periodDetails.title'),
        previousPage: t('pages.accountSummary.title'),
        route: `entity/${entityId}/account/${accountId}`,
        buttonLinks: getHeaderLinks(),
        data: details?.map((detail, index) => {
          return {
            id: index.toString(),
            label: t(`pages.periodDetails.detail.${detail.label}`),
            value: detail.value,
          };
        }),
      };
      dispatch(setHeader(header));
    }
  }, [
    isLoading,
    dispatch,
    accountId,
    entityId,
    t,
    prevPeriod,
    nextPeriod,
    details,
    module,
    getHeaderLinks,
  ]);

  //Build previous and next period links
  useEffect(() => {
    if (
      accountPeriods?.GetAccountPeriodsAndBalance.accountPeriods &&
      accountId
    ) {
      const periodList =
        accountPeriods.GetAccountPeriodsAndBalance.accountPeriods;
      const currentPeriodIndex = periodList?.findIndex((per) => {
        return per.id.toString() === periodId;
      });
      const nPeriod = periodList[currentPeriodIndex + 1]?.id;
      const pPeriod = periodList[currentPeriodIndex - 1]?.id;

      setNextPeriod(nPeriod);
      setPrevPeriod(pPeriod);
    }
  }, [periodId, accountId, accountPeriods]);

  return (
    <Grid container spacing={2}>
      <EditPeriodDetails
        periodId={periodId}
        accountId={accountId}
        periodBeginDate={periodDetails?.GetAccountPeriodDetail.beginDate}
        periodEndDate={periodDetails?.GetAccountPeriodDetail.endDate}
        periodType={periodDetails?.GetAccountPeriodDetail.periodType}
        currPeriodAttributeGroup={currPeriodAttributeGroup}
      />
      <AccountPeriodTransactions isPnIUpToDate={isPnIUpToDate} />
    </Grid>
  );
}

export default AccountPeriod;
