import { useContext, useEffect, useState } from 'react';

import { Box, useTheme } from '@mui/system';
import DataTable from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import HeaderColumn from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import CurrencyCell from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/TableCells/CurrencyCell';
import { ColumnDef } from '@tanstack/react-table';
import { getDate } from 'common/helpers';
import EntityManagementContext from 'components/contexts/EntityManagement';
import Loading from 'components/Loading';
import {
  Entity,
  useGetAccountsByEntityQuery,
  useGetBalancesByEntityIdQuery,
} from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { formatIdentifier } from 'utils/formatIdentifier';

interface Props {
  entityId: string | undefined | null;
  dataEntity?: Entity;
}

type Accounts = {
  type: string;
  status: string;
  name: string;
  id: string;
  primaryIdentifier: string;
  address: string;
  startDate: string;
  frequency: string;
  city: string;
  balance: string | null;
};

function AccountList({ entityId, dataEntity }: Props): JSX.Element {
  const { t } = useTranslation();
  const theme = useTheme();
  const [accountsList, setAccountsList] = useState<Accounts[]>([]);
  const ctx = useContext(EntityManagementContext);

  const {
    data: accountsData,
    isFetching,
    refetch,
  } = useGetAccountsByEntityQuery(
    {
      accountId: entityId ? entityId : '',
    },
    { enabled: !!entityId }
  );

  const { data: balanceData } = useGetBalancesByEntityIdQuery({
    Id: entityId ?? '',
  });

  useEffect(() => {
    if (dataEntity && refetch) {
      refetch();
    }
  }, [dataEntity, refetch]);

  const columns: ColumnDef<Accounts>[] = [
    {
      id: 'type',
      accessorKey: 'type',
      header: () => (
        <HeaderColumn localization={t('pages.accountSummary.list.type')} />
      ),
      cell: ({ getValue, row }) => (
        <Link
          to={{ pathname: `account/${row.original.id}` }}
          style={{ color: theme.palette.linkBlue.dark }}
        >
          {getValue() as string}
        </Link>
      ),
    },
    {
      id: 'status',
      accessorKey: 'status',
      header: () => (
        <HeaderColumn localization={t('pages.accountSummary.list.status')} />
      ),
    },
    {
      id: 'primaryIdentifier',
      accessorKey: 'primaryIdentifier',
      header: () => (
        <HeaderColumn localization={t('pages.accountSummary.list.id')} />
      ),
      cell: ({ getValue, row }) => (
        <Link
          to={{ pathname: `account/${row.original.id}` }}
          style={{ color: theme.palette.linkBlue.dark }}
        >
          {getValue() as string}
        </Link>
      ),
    },
    {
      id: 'name',
      accessorKey: 'name',
      header: () => (
        <HeaderColumn localization={t('pages.accountSummary.list.name')} />
      ),
    },
    {
      id: 'startDate',
      accessorKey: 'startDate',
      header: () => (
        <HeaderColumn localization={t('pages.accountSummary.list.startDate')} />
      ),
    },
    {
      id: 'frequency',
      accessorKey: 'frequency',
      header: () => (
        <HeaderColumn localization={t('pages.accountSummary.list.frequency')} />
      ),
    },
    {
      id: 'address',
      accessorKey: 'address',
      header: () => (
        <HeaderColumn localization={t('pages.accountSummary.list.address')} />
      ),
    },
    {
      id: 'city',
      accessorKey: 'city',
      header: () => (
        <HeaderColumn localization={t('pages.accountSummary.list.city')} />
      ),
    },
    {
      id: 'balance',
      accessorKey: 'balance',
      header: () => (
        <HeaderColumn localization={t('pages.accountSummary.list.balance')} />
      ),
      cell: ({ getValue }) => {
        return (
          <Box sx={{ width: '3.5em' }}>
            <CurrencyCell
              invalidValue={''}
              amountString={getValue() as string}
            />
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    if (accountsData?.GetAccountsByEntity && entityId) {
      const tempAccountList: Accounts[] = [];
      accountsData.GetAccountsByEntity.forEach((account) => {
        const singleAccount: Accounts = {
          type: account.accountType ?? '',
          status: account.status ?? '',
          name:
            account.group
              ?.find((group) => group.groupName?.toLowerCase() === 'account')
              ?.attribute?.find((name) => name.attributeName === 'AccountName')
              ?.attributeValue ?? '',
          id: account.id ?? '',
          primaryIdentifier: formatIdentifier(
            account.primaryIdentifier ?? '',
            ctx.idFormats,
            account.primaryIdentifierType ?? ''
          ),
          address:
            account.group
              ?.find((group) => group.groupName?.toLowerCase() === 'addresses')
              ?.attribute?.find(
                (address) =>
                  address.attributeName.toLowerCase() === 'addressline1'
              )?.attributeValue ?? '',
          city:
            account.group
              ?.find((group) => group.groupName?.toLowerCase() === 'addresses')
              ?.attribute?.find(
                (address) => address.attributeName.toLowerCase() === 'cityid'
              )?.attributeValue ?? '',
          startDate: getDate(account.commenceDate ?? ''),
          frequency:
            account.group
              ?.find((group) => group.groupName?.toLowerCase() === 'other')
              ?.attribute?.find(
                (name) => name.attributeName === 'periodFrequency'
              )?.attributeValue ?? '',
          balance:
            balanceData?.GetBalancesByEntityId?.find(
              (a) => a.accountExternalId === account.id
            )?.balance?.toString() ?? null,
        };
        tempAccountList.push(singleAccount);
      });
      setAccountsList(tempAccountList);
    } else setAccountsList([]);
  }, [
    accountsData,
    balanceData?.GetBalancesByEntityId,
    ctx.idFormats,
    entityId,
  ]);

  return (
    <>
      {isFetching && <Loading fullScreen={false} />}
      {accountsList.length === 0 &&
        !isFetching &&
        t('pages.entitySummary.message.errorAccountList')}
      {accountsList.length > 0 && !isFetching && (
        <DataTable
          id="account-list-table"
          data={accountsList}
          columns={columns as ColumnDef<Record<string, unknown>>[]}
        />
      )}
    </>
  );
}

export default AccountList;
