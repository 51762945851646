import { useEffect, useState } from 'react';
import { Grid, SelectChangeEvent } from '@mui/material';
import Loading from 'components/Loading';
import {
  useGetWorkflowSchemasQuery,
  useGetWorkflowSchemaVariantsQuery,
  WorkflowSchemaVariantObject,
  WorkflowSchema,
} from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { setHeader } from 'redux/contentSlice';
import { useAppDispatch } from 'redux/hooks';
import { Select } from '@revenue-solutions-inc/revxcoreui';
import TableManager from './TableManager';

function WorkflowEditor(): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [workflowSchema, setWorkflowSchema] = useState<WorkflowSchema[]>([]);
  const [selectedWorkflowSchema, setSelectedWorkflowSchema] =
    useState<WorkflowSchema>();
  const [workflowSchemaVariantData, setWorkflowSchemaVariantData] = useState<
    WorkflowSchemaVariantObject[]
  >([]);
  const { data: workflowSchemaData } = useGetWorkflowSchemasQuery();

  const {
    isRefetching,
    data,
    refetch: refetchTypes,
  } = useGetWorkflowSchemaVariantsQuery(
    {
      id: selectedWorkflowSchema?.workflowSchemaId.toString() ?? '',
    },
    {
      enabled: selectedWorkflowSchema?.workflowSchemaId !== undefined,
    }
  );

  useEffect(() => {
    setWorkflowSchemaVariantData(data ? data.GetWorkflowSchemaVariants : []);
  }, [data]);

  useEffect(() => {
    if (workflowSchemaData) {
      setWorkflowSchema(workflowSchemaData.GetWorkflowSchemas);
    }
  }, [workflowSchemaData]);

  useEffect(() => {
    dispatch(setHeader({ pageTitle: t('pages.workflowEditor.title') }));
  }, [dispatch, t]);

  const onSelectSchema = (e: SelectChangeEvent<string | number>) => {
    const { value } = e.target;
    const selected = workflowSchema.find(
      (schema) => schema.workflowSchemaId === +value
    );
    if (selected) {
      setSelectedWorkflowSchema(selected);
    }
  };

  const getSelectedWorkflowSchemaVariant = () => {
    const structure = JSON.parse(
      selectedWorkflowSchema?.workflowFullSchema ?? ''
    );

    return structure;
  };

  return (
    <>
      <Grid container spacing={2} sx={{ mt: 0.5 }}>
        <Grid xs={12} item>
          <Select
            label={t('pages.workflowEditor.configList')}
            id="workflow-schema-list"
            options={workflowSchema.map((schema) => ({
              key: schema.workflowSchemaId,
              desc: schema.displayName ?? schema.workflowSchemaType,
            }))}
            value={selectedWorkflowSchema?.workflowSchemaId ?? ''}
            onChange={onSelectSchema}
          />
        </Grid>
        <Grid
          item
          sm={5}
          xs={12}
          sx={{
            height: '100%',
          }}
        ></Grid>

        {selectedWorkflowSchema && (
          <Grid item xs={12}>
            {isRefetching && <Loading />}
            {selectedWorkflowSchema && workflowSchemaVariantData && (
              <TableManager
                key={selectedWorkflowSchema.workflowSchemaId}
                workflowSchemaVariants={workflowSchemaVariantData}
                setWorkflowSchemaVariants={setWorkflowSchemaVariantData}
                refetchTypes={refetchTypes}
                selectedWorkflowSchemaVariant={getSelectedWorkflowSchemaVariant()}
                selectedSchema={selectedWorkflowSchema}
              />
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default WorkflowEditor;
