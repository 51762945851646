import { useMemo, useState } from 'react';
import { SelectChangeEvent, Typography } from '@mui/material';
import DataCard from 'components/DataCard';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { useTranslation } from 'react-i18next';
import {
  AdjustmentDetailResponse,
  PeriodFinancialTransaction,
  TransactionPosting,
} from 'generated/graphql';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import { ColumnDef } from '@tanstack/react-table';
import { useHasAccess } from 'hooks/useHasAccess';
import DataTable from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import DateCell from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/TableCells/DateCell';
import { Link, useParams } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { Box, useTheme } from '@mui/system';
import { Select } from '@revenue-solutions-inc/revxcoreui';
import { SelectType } from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import CurrencyCell from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/TableCells/CurrencyCell';
import CreateManualCharge from '../AccountPeriod/CreateManualCharge';
import { simplifiedTransactionColumns } from '../PeriodTransactionsSimplified/PeriodTransactionsSimplified';

export const selectViewStyle = {
  display: 'flex',
  marginLeft: '5px',
};
interface Props {
  simplifiedTransactions?: TransactionPosting[] | null;
  detailedTransactions?: PeriodFinancialTransaction[] | null;
  pendingAdjustments?: AdjustmentDetailResponse[] | null;
  isLoading: boolean;
}

function PeriodTransactions({
  simplifiedTransactions,
  detailedTransactions,
  pendingAdjustments,
  isLoading,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const module = useAppSelector((state) => state.user.module);
  const theme = useTheme();
  const { periodId, accountId, entityId } = useParams() as {
    periodId: string;
    accountId: string;
    entityId: string;
  };
  const [selectedView, setSelectedView] = useState<string>('SimplifiedView');
  const viewOptions: SelectType[] = [
    { key: 'SimplifiedView', desc: t(`pages.periodDetails.simplifiedView`) },
    { key: 'DetailedView', desc: t(`pages.periodDetails.detailedView`) },
    {
      key: 'PendingAdjustmentsView',
      desc: t(`pages.periodDetails.pendingView`),
    },
  ];
  const canEdit = useHasAccess('createManualCharge', 'edit');

  const detailedTransactionColumns: ColumnDef<Record<string, unknown>>[] =
    useMemo(() => {
      return [
        {
          id: 'id',
          accessorKey: 'id',
          header: () => (
            <HeaderColumnNext
              localization={t('pages.periodDetails.transactions.transactionId')}
            />
          ),
          cell: ({ getValue, row }) => {
            const transactionLink =
              (
                row?.original?.financialTransactionSourceType as string
              ).toLowerCase() === 'adjustment'
                ? `/${module}/adjustmentdetail/${row.original?.financialTransactionSourceIdentifier}`
                : `/${module}/financialtransaction/${getValue()}`;
            return (
              <Link
                to={{
                  pathname: `${transactionLink}`,
                }}
                state={{
                  prevPath: `/entity/${entityId}/account/${accountId}/period/${periodId}`,
                  prevPageName: t('pages.periodDetails.title'),
                }}
                style={{ color: theme.palette.linkBlue.dark }}
              >
                {getValue() as string}
              </Link>
            );
          },
        },
        {
          id: 'group',
          accessorKey: 'financialTransactionGroupId',
          header: () => (
            <HeaderColumnNext
              localization={t(
                'pages.periodDetails.transactions.transactionGroup'
              )}
            />
          ),
          cell: ({ getValue }) => {
            return (
              <Link
                to={{
                  pathname: `/${module}/viewfinancialtransgroup/${getValue()}`,
                }}
                state={{
                  prevPath: `/entity/${entityId}/account/${accountId}/period/${periodId}`,
                  prevPageName: t('pages.periodDetails.title'),
                }}
                style={{ color: theme.palette.linkBlue.dark }}
              >
                {getValue() as string}
              </Link>
            );
          },
        },
        {
          id: 'postDate',
          accessorKey: 'revenuePostDate',
          header: () => (
            <HeaderColumnNext
              localization={t('pages.periodDetails.transactions.processDate')}
            />
          ),
          cell: ({ getValue }) => (
            <DateCell dateString={getValue() as string} />
          ),
        },
        ...simplifiedTransactionColumns,
      ];
    }, [accountId, entityId, module, periodId, t, theme.palette.linkBlue.dark]);

  const adjustmentColumns: ColumnDef<AdjustmentDetailResponse>[] = [
    {
      id: 'adjustmentType',
      accessorKey: 'adjustmentType',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.periodDetails.adjustments.adjustmentType')}
        />
      ),
      cell: ({ getValue, row }) => {
        return (
          <Link
            to={{
              pathname: `/${module}/adjustmentdetail/${row.original.id}`,
            }}
            state={{
              prevPath: `/entity/${entityId}/account/${accountId}/period/${periodId}`,
              prevPageName: t('pages.periodDetails.title'),
            }}
            style={{ color: theme.palette.linkBlue.dark }}
          >
            {getValue() as string}
          </Link>
        );
      },
    },
    {
      id: 'adjustmentReason',
      accessorKey: 'adjustmentReason',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.periodDetails.adjustments.adjustmentReason')}
        />
      ),
    },
    {
      id: 'reductionType',
      accessorKey: 'reductionType',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.periodDetails.adjustments.reductionType')}
        />
      ),
    },
    {
      id: 'adjustmentStatus',
      accessorKey: 'adjustmentStatus',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.periodDetails.adjustments.adjustmentStatus')}
        />
      ),
    },
    {
      id: 'amount',
      accessorKey: 'amount',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.periodDetails.transactions.amount')}
        />
      ),
      cell: ({ getValue }) => {
        return (
          <Box sx={{ width: '3.3em' }}>
            <CurrencyCell
              invalidValue={t('pages.tableCell.invalidValue')}
              amountString={getValue() as string}
            />
          </Box>
        );
      },
    },
  ];

  const handleChange = (event: SelectChangeEvent<string | number>) => {
    setSelectedView(String(event.target.value));
  };

  const currentTableData = () => {
    switch (selectedView) {
      case 'SimplifiedView':
        return simplifiedTransactions ?? [];
      case 'DetailedView':
        return detailedTransactions ?? [];
      case 'PendingAdjustmentsView':
        return pendingAdjustments ?? [];
      default:
        return simplifiedTransactions ?? [];
    }
  };

  const renderTableColumns = () => {
    switch (selectedView) {
      case 'SimplifiedView':
        return simplifiedTransactionColumns;
      case 'DetailedView':
        return detailedTransactionColumns;
      case 'PendingAdjustmentsView':
        return adjustmentColumns;
      default:
        return simplifiedTransactionColumns;
    }
  };

  const selectViewDropdown = (
    <Box sx={selectViewStyle}>
      <Select
        label=""
        id="selectView"
        onChange={handleChange}
        value={selectedView}
        options={viewOptions}
      />
      {canEdit ? <CreateManualCharge /> : <></>}
    </Box>
  );

  return (
    <>
      {!isLoading && detailedTransactions && (
        <DataCard
          title={t(`pages.periodDetails.transactions.title`)}
          avatar={<MonetizationOnIcon sx={{ fill: 'primary.main' }} />}
          action={selectViewDropdown}
          children={
            <>
              {currentTableData().length > 0 && (
                <DataTable
                  data={currentTableData() as Record<string, unknown>[]}
                  columns={renderTableColumns()}
                  enableGlobalFilter={false}
                />
              )}
              {currentTableData().length === 0 && (
                <Typography sx={{ margin: '10px' }}>
                  {selectedView === 'PendingAdjustmentsView'
                    ? t('pages.periodDetails.transactions.noPendingAdjustments')
                    : t('pages.periodDetails.transactions.noTransactions')}
                </Typography>
              )}
            </>
          }
        />
      )}
    </>
  );
}

export default PeriodTransactions;
