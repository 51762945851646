import { useContext } from 'react';

import { Typography } from '@mui/material';
import { Input } from '@revenue-solutions-inc/revxcoreui';
import { ManageConfigGroupsContext } from 'components/contexts/ManageConfigGroupsProvider/ManageConfigGroupsProvider';
import { CellContext } from '@tanstack/react-table';
import { ConfigurationGroup } from 'types/graphTypes';
import { useTranslation } from 'react-i18next';

export default function ConfigurationGroupName({
  row,
}: CellContext<ConfigurationGroup, unknown>) {
  const { t } = useTranslation();
  const { selectedGroup, setSelectedGroup } = useContext(
    ManageConfigGroupsContext
  );

  return (
    <>
      {row.original.platformConfigurationGroupId ===
      selectedGroup?.platformConfigurationGroupId ? (
        <Input
          label={t('pages.configGroupEditor.configName')}
          id={`configGroupName_${row.id}`}
          value={selectedGroup?.name}
          onChange={(event) => {
            if (selectedGroup)
              setSelectedGroup({
                ...selectedGroup,
                name: event?.target.value,
              });
          }}
          required
        />
      ) : (
        <Typography>{row.original.name}</Typography>
      )}
    </>
  );
}
