import { useEffect, useState } from 'react';

import { Grid, Typography } from '@mui/material';
import {
  MessageActionType,
  MessageType,
} from '@revenue-solutions-inc/revxcoreui/material/messaging/Message/Message';
import ManageConfigGroupsProvider from 'components/contexts/ManageConfigGroupsProvider';
import Loading from 'components/Loading';
import ConfigurationGroupsTableManager from 'components/manageConfigTools/ConfigurationGroupsTableManager';
import { useTranslation } from 'react-i18next';
import { setHeader } from 'redux/contentSlice';
import { useAppDispatch } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import { ConfigurationGroup } from 'types/graphTypes';
import { useGetConfigurationGroupsQuery } from 'generated/graphql';
import { Card } from '@revenue-solutions-inc/revxcoreui';
import extractMeaningfulMessage from 'utils/errorMessage';
import { Error } from 'types/graphqlErrors';

function ConfigGroupEditor(): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [configData, setConfigData] = useState<ConfigurationGroup[]>();

  const {
    isRefetching,
    data,
    refetch: refetchTypes,
  } = useGetConfigurationGroupsQuery(
    {},
    {
      onError(error: Error[]) {
        dispatch(
          addMessage({
            message: extractMeaningfulMessage(
              error,
              t('components.message.networkerror')
            ),
            type: MessageType.Error,
            actionType: MessageActionType.None,
          })
        );
      },
    }
  );

  useEffect(() => {
    if (data) setConfigData(data?.getConfigurationGroups);
  }, [data]);

  useEffect(() => {
    dispatch(setHeader({ pageTitle: t('pages.configGroupEditor.title') }));
  }, [dispatch, t]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={12}>
        <Typography variant="h1" mb="1em">
          {t('pages.configGroupEditor.configGroups')}
        </Typography>
        {configData && (
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              position: 'relative',
              p: '.5em .75em .75em .75em',
            }}
          >
            <ManageConfigGroupsProvider>
              <ConfigurationGroupsTableManager
                configData={configData}
                setConfigData={setConfigData}
                refetchTypes={refetchTypes}
              />
            </ManageConfigGroupsProvider>
          </Card>
        )}
        {isRefetching && <Loading />}
      </Grid>
    </Grid>
  );
}

export default ConfigGroupEditor;
