import { Controller, UseFormReturn } from 'react-hook-form';
import { Input } from '@revenue-solutions-inc/revxcoreui';
import { SxProps, Theme } from '@mui/system';

interface ControlledInputFieldProps {
  // eslint-disable-next-line
  control: UseFormReturn['control'] | any;
  name: string;
  rules: Record<string, unknown>;
  disabled?: boolean;
  label: string;
  required?: boolean;
  id: string;
  sx?: SxProps<Theme>;
  helperText?: string;
  defaultValue?: string | number | boolean;
}

function ControlledInputField({
  control,
  name,
  disabled = false,
  defaultValue = '',
  rules,
  required,
  helperText,
  label,
  id,
  sx,
}: ControlledInputFieldProps) {
  return (
    <Controller
      defaultValue={defaultValue}
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <Input
            id={id}
            required={required}
            value={value}
            onChange={onChange}
            label={label}
            disabled={disabled}
            sx={sx}
            error={!!error}
            helperText={error?.message ? error.message : helperText}
          />
        );
      }}
    />
  );
}

export default ControlledInputField;
