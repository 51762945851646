import { useState } from 'react';
import { Grid } from '@mui/material';
import HistoryIcon from '@mui/icons-material/History';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import DataCard from 'components/DataCard';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import { ColumnDef } from '@tanstack/react-table';
import {
  StagesRuns,
  useGetScheduledTaskRunDetailsByHistoryIdQuery,
} from 'generated/graphql';
import { format } from 'date-fns';
import Loading from 'components/Loading';
import ErrorMessageModal from './ErrorMessageModal';

export const dialogStyle = {
  '& .MuiSvgIcon-root': {
    display: 'none',
  },
  '.MuiDialogTitle-root': {
    fontWeight: 'bold',
  },
  '& .MuiDialogActions-root': {
    textAlign: 'center',
    display: 'inline-block',
  },
  '& .MuiDialogContent-root': {
    fontSize: '16px',
    fontWeight: 'normal',
  },
};

interface RunHistoryProps {
  taskRunHistoryId?: string;
}

function RunHistory({ taskRunHistoryId }: RunHistoryProps): JSX.Element {
  const { t } = useTranslation();
  const [openErrorMessage, setOpenErrorMessage] = useState(false);
  const [messages, setMessages] = useState<string[]>([]);

  const runHistoryId = parseFloat(taskRunHistoryId || '0');

  const { data: runListData, isLoading } =
    useGetScheduledTaskRunDetailsByHistoryIdQuery({
      taskRunHistoryId: runHistoryId,
    });

  let stagesRunWithExecutionOrder: (StagesRuns & { ExecutionOrder: number })[] =
    [];

  if (
    runListData &&
    runListData.getScheduledTaskRunDetailsByHistoryId?.StageRuns
  ) {
    stagesRunWithExecutionOrder =
      runListData.getScheduledTaskRunDetailsByHistoryId.StageRuns.map(
        (stageRun, index) => {
          return { ...stageRun, ExecutionOrder: index + 1 };
        }
      );
  }

  type StagesRunsWithOrder = StagesRuns & { ExecutionOrder: number };

  const StageRunsColumnsData: {
    id: string;
    localize: string;
    accessorKey: keyof StagesRunsWithOrder;
  }[] = [
    {
      id: 'StageName',
      localize: 'stageTaskName',
      accessorKey: 'StageName',
    },
    {
      id: 'StartTime',
      localize: 'startTime',
      accessorKey: 'StartTime',
    },
    {
      id: 'EndTime',
      localize: 'endTime',
      accessorKey: 'EndTime',
    },
    {
      id: 'ExecutionOrder',
      localize: 'taskStagesExecutionOrder',
      accessorKey: 'ExecutionOrder',
    },
    {
      id: 'ElapsedTime',
      localize: 'elapsedTime',
      accessorKey: 'ElapsedTime',
    },
    {
      id: 'RunStatus',
      localize: 'stageStatus',
      accessorKey: 'RunStatus',
    },
    {
      id: 'TotalRecords',
      localize: 'totalRecords',
      accessorKey: 'TotalRecords',
    },
    {
      id: 'RecordsSuccess',
      localize: 'recordsSuccess',
      accessorKey: 'RecordsSuccess',
    },
    {
      id: 'RecordsFailed',
      localize: 'recordsFailed',
      accessorKey: 'RecordsFailed',
    },
  ];

  const StageRunsColumns: ColumnDef<Record<string, unknown>, unknown>[] =
    StageRunsColumnsData.map((columnData) => {
      const column: ColumnDef<Record<string, unknown>, unknown> = {
        header: () => (
          <HeaderColumnNext
            localization={t(`pages.manageChannel.${columnData.localize}`)}
          />
        ),
        id: columnData.id,
        accessorKey: columnData.accessorKey,
        accessorFn: (row: Record<string, unknown>) =>
          row[columnData.accessorKey],
      };

      if (columnData.id === 'StartTime' || columnData.id === 'EndTime') {
        column.cell = ({ row }) => {
          const dateStr = row.original[columnData.accessorKey];
          if (dateStr === undefined || dateStr === null) {
            return '';
          }

          const date = new Date(dateStr.toString());
          return format(date, 'MM/dd/yyyy hh:mm:ss a');
        };
      }

      if (columnData.id === 'RunStatus') {
        column.cell = ({ row }) => {
          if (row.original.RunStatus === 'Failed') {
            return (
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  setMessages(row.original.Messages as unknown as string[]);
                  setOpenErrorMessage(true);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setMessages(row.original.Messages as unknown as string[]);
                    setOpenErrorMessage(true);
                  }
                }}
                style={{ color: 'linkBlue.main', cursor: 'pointer' }}
                to={''}
              >
                {row.original.RunStatus}
              </Link>
            );
          } else {
            return row.original.RunStatus;
          }
        };
      }

      return column;
    });

  if (!isLoading && runListData) {
    return (
      <DataCard
        avatar={<HistoryIcon sx={{ fill: 'primary.main' }} />}
        title={t('pages.manageChannel.runHistory')}
        children={
          <>
            <Grid sx={{ marginBottom: '20px' }}>
              <DefaultDataTableNext
                columns={StageRunsColumns}
                data={
                  stagesRunWithExecutionOrder as unknown as Record<
                    string,
                    unknown
                  >[]
                }
                enableGlobalFilter={false}
              />
              <ErrorMessageModal
                openErrorMessage={openErrorMessage}
                handleClose={() => setOpenErrorMessage(false)}
                messages={messages}
              />
            </Grid>
          </>
        }
      />
    );
  }
  if (isLoading) {
    return <Loading />;
  }
  return <></>;
}

export default RunHistory;
