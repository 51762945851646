import { useEffect, Fragment } from 'react';

import InfoIcon from '@mui/icons-material/Info';
import { Grid, IconButton, Theme, Tooltip } from '@mui/material';
import Input from '@revenue-solutions-inc/revxcoreui/material/controls/Input';
import { Channel, ConnectorInfo, ParameterInfo } from 'types/channels';
import {
  Control,
  Controller,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';

interface Props {
  connectorData: ConnectorInfo[];
  connectorClicked: string;
  setParametersValid: React.Dispatch<React.SetStateAction<boolean>>;
  control: Control<Channel, unknown>;
  setValue: UseFormSetValue<Channel>;
}

export const labelStyle = (theme: Theme) => {
  return {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.grey1.main,
    borderBottom: 'none',
    '& .MuiFormLabel-asterisk': {
      fontWeight: theme.typography.fontWeightMedium,
    },
  };
};

const iconButton = {
  marginTop: '-10px',
  marginLeft: '-40px',
  '&:hover': { color: 'linkBlue.main' },
};

function ConnectorParameters({
  connectorData,
  connectorClicked,
  setParametersValid,
  control,
}: Props): JSX.Element {
  const connectorNames: string[] = connectorData
    ? connectorData.map((connector: ConnectorInfo) => {
        return connector.Name;
      })
    : [];

  const indexValue = connectorNames.indexOf(connectorClicked);

  const watchParameters = useWatch({
    control,
    name: `Channel.Pipeline.Parameters`,
  });

  const parameterValues = connectorData
    ? connectorData.map((object: ConnectorInfo) => {
        return object.Parameters;
      })
    : [];
  const parameterValue = parameterValues[indexValue];

  const validateRequiredFields = () => {
    let isValid = true;

    parameterValue?.map((value: ParameterInfo) => {
      if (value.Required) {
        if (
          !watchParameters[value.Name] ||
          watchParameters[value.Name] === ''
        ) {
          isValid = false;
        }
      }
    });

    setParametersValid(isValid);
  };

  useEffect(() => {
    validateRequiredFields();
  });

  return (
    <Grid container>
      {parameterValue ? (
        parameterValue.map((valueParameter: ParameterInfo, index) => (
          <Fragment key={`${index}_container`}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                name={`Channel.Pipeline.Parameters.${valueParameter.Name}`}
                render={({ field: { value, onChange } }) => {
                  return (
                    <Input
                      required={valueParameter.Required}
                      id="channelParameter"
                      label={valueParameter.DisplayName}
                      value={value || ''}
                      sx={{
                        width: '100%',
                        maxWidth: '20em',
                        marginBottom: '1em',
                      }}
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                    />
                  );
                }}
              />
              <Tooltip
                title={valueParameter.Description}
                placement="left-start"
                PopperProps={{
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [5, -12],
                      },
                    },
                  ],
                }}
              >
                <IconButton sx={iconButton}>
                  <InfoIcon sx={{ width: '22px' }} />
                </IconButton>
              </Tooltip>
            </Grid>
          </Fragment>
        ))
      ) : (
        <></>
      )}
    </Grid>
  );
}
export default ConnectorParameters;
