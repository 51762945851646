import { useState, useEffect, useCallback } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DatasourceSelect from 'components/DatasourceSelect';
import { Box, Card, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import Checkbox from '@revenue-solutions-inc/revxcoreui/material/controls/Checkbox';
import { Button, Input } from '@revenue-solutions-inc/revxcoreui';
import { Attribute } from 'generated/graphql';
import { WorkflowConditionsFields } from './workflowConditionsFields';
import { WorkflowConditionActionsFields } from './workflowConditionActionsFields';
import {
  WorkflowFormObject,
  WorkflowStepConfig,
} from '../WorkflowEditor.types';

interface Props {
  editDisabled: boolean;
}

const newStep: WorkflowStepConfig = {
  currentStep: {
    attributeName: 'currentStep',
    attributeDisplayName: 'Current Step',
    attributeType: 'ReferenceData',
    attributeDescription: null,
    dataSource: 'WorkflowStepTypes',
    repeatingProperties: null,
    possibleValues: null,
    attributeValue: '',
    isOOTB: null,
    isOOTBEditable: null,
  },
  nextStep: {
    attributeName: 'nextStep',
    attributeDisplayName: 'Next Step',
    attributeType: 'ReferenceData',
    attributeDescription: null,
    dataSource: 'WorkflowStepTypes',
    repeatingProperties: null,
    possibleValues: null,
    attributeValue: '',
    isOOTB: null,
    isOOTBEditable: null,
  },
  order: {
    attributeName: 'order',
    attributeDisplayName: 'Order',
    attributeType: 'int',
    attributeDescription: null,
    dataSource: null,
    repeatingProperties: null,
    possibleValues: null,
    attributeValue: '',
    extensibleBusinessDriver: null,
    isOOTB: null,
    isOOTBEditable: null,
  },
  manualStep: {
    attributeName: 'manualStep',
    attributeDisplayName: 'Manual Step',
    attributeType: 'boolean',
    repeatingProperties: null,
    possibleValues: null,
    attributeValue: 'true',
    layoutInfo: null,
    extensibleBusinessDriver: null,
  },
  automaticStep: {
    attributeName: 'automaticStep',
    attributeDisplayName: 'Automatic Step',
    attributeType: 'boolean',
    repeatingProperties: null,
    possibleValues: null,
    attributeValue: 'true',
    layoutInfo: null,
    extensibleBusinessDriver: null,
  },
  workflowActions: [],
  workflowConditions: [],
};

export const WorkflowSteps = ({ editDisabled }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    control,
    setValue,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext<WorkflowFormObject>();

  const { fields, remove, append } = useFieldArray({
    control,
    name: 'workflowSteps',
  });

  const [customAttributes, setCustomAttributes] = useState<Attribute[]>([]);
  const [actionAttributes, setActionAttributes] = useState<Attribute[]>([]);

  useEffect(() => {
    // watch for changes to CustomAttributes and repeatingValues
    const subscription = watch((data) => {
      const vals = data.workflowConfig;
      const customAttrsArray = vals?.find(
        (grp) => grp?.groupName === 'CustomAttributes'
      )?.attribute as Attribute[];

      if (customAttrsArray?.length) {
        const values = customAttrsArray.flatMap(
          (x) => x.repeatingValue
        ) as Attribute[];
        const completeValues = values.filter(
          (attr) =>
            attr.attributeDisplayName &&
            attr.attributeName &&
            attr.attributeType
        );
        setCustomAttributes(completeValues);
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [watch]);

  useEffect(() => {
    const subscription = watch((data) => {
      const vals = data.workflowConfig;

      const actions = vals?.find((grp) => grp?.groupName === 'ActionAttributes')
        ?.attribute as Attribute[];

      if (actions?.length) {
        const values = actions.filter(
          (attr) => attr.attributeName && attr.attributeDisplayName
        );

        setActionAttributes(values);
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [watch]);

  const handleStepTransitionTypeValidation = useCallback(
    (index: number): void => {
      const subscription = watch((data) => {
        const vals = data?.workflowSteps;
        if (vals) {
          const manualStepCheck = vals[index]?.manualStep?.attributeValue;
          const automaticStepCheck = vals[index]?.automaticStep?.attributeValue;
          if (!manualStepCheck && !automaticStepCheck) {
            setError(
              `workflowSteps.${index}`,
              {
                type: 'focus',
                message: t(
                  'pages.workflowEditor.stepsFields.errorTransitionSteps'
                ),
              },
              { shouldFocus: true }
            );
            subscription.unsubscribe();
          } else {
            clearErrors(`workflowSteps.${index}`);
            subscription.unsubscribe();
          }
        }
      });
    },
    [watch, setError, clearErrors, t]
  );

  return (
    <Box
      sx={{
        width: '100%',
        borderRadius: 4,
      }}
      padding={2}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 4,
        }}
      >
        <Typography fontSize={16} fontWeight={'bold'}>
          {t('pages.workflowEditor.stepConfigurationRecords')}
        </Typography>
        <Button
          id="add-step-configuration"
          size="small"
          onClick={() => {
            append(newStep);
          }}
          disabled={editDisabled}
        >
          {t('pages.workflowEditor.addStepConfiguration')}
        </Button>
      </Box>
      {fields.map((step, index: number) => {
        return (
          <Card
            sx={{ marginBottom: 3 }}
            variant="outlined"
            key={`stepBox_${index}`}
          >
            <Box padding={2} key={'workflow-step-config-section-' + index}>
              <Grid container spacing={3}>
                <Grid item>
                  <Controller
                    defaultValue={step.order.attributeValue ?? ''}
                    name={`workflowSteps.${index}.order.attributeValue`}
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: t('pages.workflowEditor.reqValue'),
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <Input
                        sx={{ width: '100%' }}
                        disabled={editDisabled}
                        required={true}
                        id={`${step.order.attributeName}-${index}`}
                        label={step.order.attributeDisplayName}
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <Controller
                    defaultValue={step?.currentStep?.attributeValue ?? ''}
                    name={`workflowSteps.${index}.currentStep.attributeValue`}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <DatasourceSelect
                        maxWidth
                        disabled={editDisabled}
                        id={`${step.currentStep.attributeName}-${index}`}
                        datasource={step.currentStep.dataSource ?? ''}
                        label={step.currentStep.attributeDisplayName}
                        fetchLayoutInfo={onChange}
                        value={value}
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <Controller
                    defaultValue={step?.nextStep?.attributeValue ?? ''}
                    name={`workflowSteps.${index}.nextStep.attributeValue`}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <DatasourceSelect
                        maxWidth
                        disabled={editDisabled}
                        id={`${step.nextStep.attributeName}-${index}`}
                        datasource={step.nextStep.dataSource ?? ''}
                        label={step.nextStep.attributeDisplayName}
                        fetchLayoutInfo={onChange}
                        value={value}
                      />
                    )}
                  />
                </Grid>
                {step?.manualStep && (
                  <Grid item>
                    <Controller
                      defaultValue={step?.manualStep?.attributeValue ?? true}
                      name={`workflowSteps.${index}.manualStep.attributeValue`}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Checkbox
                          id={`${step.manualStep.attributeName}-${index}`}
                          label={t(
                            'pages.workflowEditor.stepsFields.manualStep'
                          )}
                          checked={value ? true : false}
                          disabled={editDisabled}
                          onChange={(e) => {
                            const eventOverride = {
                              target: {
                                ...e.target,
                                checked: e.target.checked,
                                // server expects strings not booleans
                                value: e.target.checked ? 'true' : '',
                              },
                            };
                            handleStepTransitionTypeValidation(index);
                            onChange(eventOverride);
                          }}
                          value={value.toString()}
                        />
                      )}
                    />
                    <Controller
                      defaultValue={step?.automaticStep?.attributeValue ?? true}
                      name={`workflowSteps.${index}.automaticStep.attributeValue`}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Checkbox
                          id={`${step.automaticStep.attributeName}-${index}`}
                          label={t(
                            'pages.workflowEditor.stepsFields.automaticStep'
                          )}
                          checked={value ? true : false}
                          disabled={editDisabled}
                          onChange={(e) => {
                            const eventOverride = {
                              target: {
                                ...e.target,
                                checked: e.target.checked,
                                // server expects strings not booleans
                                value: e.target.checked ? 'true' : '',
                              },
                            };
                            handleStepTransitionTypeValidation(index);
                            onChange(eventOverride);
                          }}
                          value={value.toString()}
                        />
                      )}
                    />
                    {errors.workflowSteps &&
                      errors.workflowSteps[`${index}`] && (
                        <p
                          style={{
                            color: theme.palette.error.main,
                            marginTop: 'auto',
                          }}
                        >
                          {errors.workflowSteps[`${index}`]?.message}
                        </p>
                      )}
                  </Grid>
                )}
              </Grid>
              <hr />
              {
                <WorkflowConditionsFields
                  stepIndex={index}
                  control={control}
                  disabled={editDisabled}
                  customAttributes={customAttributes}
                  setValue={setValue}
                />
              }
              <hr />
              {
                <WorkflowConditionActionsFields
                  index={index}
                  control={control}
                  disabled={editDisabled}
                  customAttributes={customAttributes}
                  actionAttributes={actionAttributes}
                />
              }
            </Box>
            <Box
              sx={{
                justifyContent: 'flex-end',
                display: 'flex',
              }}
            >
              {index === fields.length - 1 ? (
                <Button
                  id="remove-configuration"
                  size="small"
                  sx={{ maxWidth: '8em', marginTop: 2, marginBottom: 2 }}
                  onClick={() => {
                    remove(index);
                  }}
                  disabled={editDisabled}
                >
                  {t('pages.workflowEditor.removeConfig')}
                </Button>
              ) : (
                <></>
              )}
            </Box>
          </Card>
        );
      })}
    </Box>
  );
};
