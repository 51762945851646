import { Deposit } from 'types/entities';
import {
  BatchResponse,
  CorrespondenceTypeManagerResponse,
  FileContentResponse,
  PrintBatchesResponse,
  ReusableContentTypeResponse,
} from 'generated/graphql';

import { Address, Contact, Name } from '../types/contact';

interface GenericEntity {
  id?: string;
  addresses: Address[] | null;
  name?: Name | undefined;
  phoneNumbers?: Contact[];
  emailAddresses?: Contact[];
}

type DateType = Date | null | undefined;

export const deleteContactsIfEmpty = (entity: GenericEntity): GenericEntity => {
  if (
    entity.emailAddresses &&
    entity.emailAddresses[0].type === '' &&
    entity.emailAddresses[0].value === ''
  ) {
    delete entity.emailAddresses;
  }
  if (
    entity.phoneNumbers &&
    entity.phoneNumbers[0].type === '' &&
    entity.phoneNumbers[0].value === ''
  ) {
    delete entity.phoneNumbers;
  }
  return entity;
};

export const deleteIds = (entity: GenericEntity): GenericEntity => {
  delete entity.id;
  delete entity?.name?.id;
  entity.addresses?.forEach((prop) => {
    delete prop.id;
  });
  entity.emailAddresses?.forEach((prop) => {
    delete prop.id;
  });
  entity.phoneNumbers?.forEach((prop) => {
    delete prop.id;
  });
  return entity;
};

export const validateContact = (
  entity: GenericEntity,
  validOne: boolean
): boolean => {
  let valid = validOne;
  if (entity && entity.emailAddresses) {
    const email = entity.emailAddresses[0];
    if (email.type !== '' && email.value === '') {
      valid = false;
    }
    if (email.value !== '' && email.type === '') {
      valid = false;
    }
    if (email.isPrimary === true && email.value === '' && email.type === '') {
      valid = false;
    }
  }
  if (entity && entity.phoneNumbers) {
    const phone = entity.phoneNumbers[0];
    if (phone.type !== '' && phone.value === '') {
      valid = false;
    }
    if (phone.value !== '' && phone.type === '') {
      valid = false;
    }
    if (phone.isPrimary === true && phone.value === '' && phone.type === '') {
      valid = false;
    }
  }
  return valid;
};

export const formatAddress = (address: Address) => {
  return (
    address.street +
    ', ' +
    (address.street2 ? address.street2 : '') +
    ' ' +
    address.city +
    ' ' +
    address.stateProvince +
    ' ' +
    address.postalCode
  );
};

export const formatCurrency = (amountString: string) => {
  return new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'narrowSymbol',
  }).format(parseFloat(amountString));
};

export const formatPhone = (phone: string) => {
  return phone.toString().replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
};

export const formatNumber = (value: number) => {
  return new Intl.NumberFormat('en-US').format(value);
};

export const filterByDateRange = (
  batches:
    | (
        | BatchResponse
        | ReusableContentTypeResponse
        | CorrespondenceTypeManagerResponse
      )[]
    | undefined,
  beginDate: DateType,
  endDate: DateType
) => {
  let filtered: (
    | BatchResponse
    | ReusableContentTypeResponse
    | CorrespondenceTypeManagerResponse
  )[] = [];

  if (batches) {
    filtered = batches.filter((item) => {
      if (item.createdDate) {
        const openDate = new Date(item.createdDate);
        if (beginDate && endDate) {
          return openDate >= beginDate && openDate <= endDate;
        }
        if (beginDate && !endDate) {
          return openDate >= beginDate;
        }
        if (endDate && !beginDate) {
          return openDate <= endDate;
        }
      }
    });
  }
  return filtered;
};
export const batchHistoryFilterByDateRange = (
  contents: PrintBatchesResponse[] | undefined,
  beginDate: Date | null | undefined,
  endDate: Date | null | undefined
) => {
  let filtered: PrintBatchesResponse[] = [];

  if (contents) {
    filtered = contents.filter((item) => {
      if (item.completionTime) {
        const openDate = new Date(item.completionTime);
        if (beginDate && endDate) {
          return openDate >= beginDate && openDate <= endDate;
        }
        if (beginDate && !endDate) {
          return openDate >= beginDate;
        }
        if (endDate && !beginDate) {
          return openDate <= endDate;
        }
      }
    });
  }
  return filtered;
};

export const corrFilterByDateRangeContent = (
  contents: CorrespondenceTypeManagerResponse[] | undefined,
  beginDate: Date | null | undefined,
  endDate: Date | null | undefined
) => {
  let filtered: CorrespondenceTypeManagerResponse[] = [];

  if (contents) {
    filtered = contents.filter((item) => {
      if (item.createdDate) {
        const openDate = new Date(item.createdDate);
        if (beginDate && endDate) {
          return openDate >= beginDate && openDate <= endDate;
        }
        if (beginDate && !endDate) {
          return openDate >= beginDate;
        }
        if (endDate && !beginDate) {
          return openDate <= endDate;
        }
      }
    });
  }
  return filtered;
};

export const getDate = (date: string) => {
  return new Date(date).toLocaleDateString('en-US', {
    timeZone: 'UTC',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
};

export const filterDepositsByDateRange = (
  deposits: Deposit[] | undefined,
  beginDate: DateType,
  endDate: DateType
) => {
  let filtered: Deposit[] = [];

  if (deposits) {
    filtered = deposits.filter((item) => {
      if (item.depositDate) {
        const openDate = new Date(item.depositDate);
        if (beginDate && endDate) {
          return openDate >= beginDate && openDate <= endDate;
        }
        if (beginDate && !endDate) {
          return openDate >= beginDate;
        }
        if (endDate && !beginDate) {
          return openDate <= endDate;
        }
      }
    });
  }
  return filtered;
};

export const downloadFile = async (data: FileContentResponse) => {
  const url = `data:${data.contentType};base64,${data.fileContents}`;
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', decodeURI(data.fileDownloadName ?? ''));
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(url);
  link.remove();
};
