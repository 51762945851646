import { useEffect, useMemo, useState } from 'react';
import { Grid, Theme, Typography } from '@mui/material';
import {
  Card,
  CardContent,
  CardHeader,
  DataDisplay,
} from '@revenue-solutions-inc/revxcoreui';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import {
  useGetFindByJsonParameterQuery,
  useGetLetterDetailByTemplateIdQuery,
} from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { ColumnDef } from '@tanstack/react-table';
import Loading from 'components/Loading';
import { useAppDispatch } from 'redux/hooks';
import { setHeader } from 'redux/contentSlice';
import { useLocation, useParams } from 'react-router-dom';
import format from 'date-fns/format';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import useGetAccessToken from 'hooks/useGetAccessToken';
import useGetContentMgmtLetter from 'hooks/useGetContentMgmtLetter';
import { useTheme } from '@mui/system';

function LetterDetails(): JSX.Element {
  type Attributes = {
    TemplateId: number;
    CreatedBy: string;
    CreatedDate: string;
    PrintDate: string;
    DocumentStatus: string;
    EntityName: string;
    LetterId: string;
    AddressLine1: string;
    AddressLine2: string;
    City: string;
    State: string;
    PostalCode: number;
    CountryCode: string;
    LetterRequestId: string;
  };
  type LetterListData = {
    IsFTI: boolean;
    LetterId: string;
    AddressLine1: string;
    AddressLine2: string;
    EntityName: string;
    City: string;
    StateCode: string;
    PostalCode: number;
    ContentManagementId: string;
    RecipientType: string;
  };
  const { letterId, templateId } = useParams() as {
    letterId: string;
    templateId: string;
  };
  const dispatch = useAppDispatch();
  const accessToken = useGetAccessToken();
  const location = useLocation();
  const [letterIds, setLetterIds] = useState({
    contentId: '',
    letterId: '',
  });
  const { refetch: refetchContentData } = useGetContentMgmtLetter(
    accessToken,
    letterIds.contentId,
    letterIds.letterId
  );
  const [historyState, setHistoryState] = useState<Attributes[]>([]);
  const { t } = useTranslation();
  const appTheme = useTheme();
  const {
    data: letterDetailsData,
    isLoading,
    refetch: letterHistoryRefetch,
  } = useGetFindByJsonParameterQuery({
    searchItem: 'WorkflowVariantCode=CorrespondenceWorkflow',
  });

  useEffect(() => {
    if (
      location.state &&
      location.state.prevPageName &&
      location.state.prevPath
    ) {
      dispatch(
        setHeader({
          pageTitle: t('pages.letterDetails.title'),
          previousPage: location.state.prevPageName,
          route: location.state.prevPath.substring(1),
        })
      );
    } else {
      dispatch(
        setHeader({
          pageTitle: t('pages.letterDetails.title'),
          previousPage: t('pages.search.title'),
          route: `search`,
        })
      );
    }
  });

  useEffect(() => {
    if (letterIds.contentId && letterIds.contentId.length > 0) {
      refetchContentData();
      setLetterIds({ contentId: '', letterId: '' });
    }
  }, [letterIds, refetchContentData]);

  useEffect(() => {
    if (letterHistoryRefetch) letterHistoryRefetch();
  }, [letterHistoryRefetch]);

  useEffect(() => {
    if (letterDetailsData && letterDetailsData?.GetFindByJsonParameter) {
      const letterData: Attributes[] =
        letterDetailsData?.GetFindByJsonParameter.map(
          (item: { wF_Full_Object: string }) => {
            return JSON.parse(item.wF_Full_Object);
          }
        );

      const Details = letterData.filter(
        (attribute) =>
          attribute.LetterRequestId ==
          letterData.filter((item) => item.LetterId === letterId)[0]
            .LetterRequestId
      );
      setHistoryState(Details);
    }
  }, [letterDetailsData, letterId]);

  const { data: letterDetailById } = useGetLetterDetailByTemplateIdQuery({
    getLetterDetailByTemplateIdId: parseInt(templateId),
  });

  const LetterDetailsColumns: ColumnDef<LetterListData>[] = useMemo(() => {
    return [
      {
        header: () => (
          <HeaderColumnNext
            localization={t('pages.correspondence.correspondenceData.id')}
          />
        ),
        accessorKey: 'LetterId',
      },
      {
        header: () => (
          <HeaderColumnNext
            localization={t('pages.letterDetails.recipientType')}
          />
        ),
        accessorKey: 'RecipientType',
      },
      {
        header: () => (
          <HeaderColumnNext localization={t('pages.letterDetails.fti')} />
        ),
        accessorKey: 'IsFTI',
        cell: ({ row }) => {
          if (row.original.IsFTI) {
            return 'Yes';
          } else {
            return 'No';
          }
        },
      },
      {
        header: () => (
          <HeaderColumnNext localization={t('pages.letterDetails.entity')} />
        ),
        accessorKey: 'EntityName',
      },
      {
        header: () => (
          <HeaderColumnNext localization={t('pages.letterDetails.Address1')} />
        ),
        accessorKey: 'AddressLine1',
      },
      {
        header: () => (
          <HeaderColumnNext localization={t('pages.letterDetails.Address2')} />
        ),
        accessorKey: 'AddressLine2',
      },
      {
        header: () => (
          <HeaderColumnNext localization={t('pages.letterDetails.city')} />
        ),
        accessorKey: 'City',
      },
      {
        header: () => (
          <HeaderColumnNext localization={t('pages.letterDetails.state')} />
        ),
        accessorKey: 'StateCode',
      },
      {
        header: () => (
          <HeaderColumnNext localization={t('pages.letterDetails.country')} />
        ),
        accessorKey: 'CountryCode',
      },
      {
        header: () => (
          <HeaderColumnNext localization={t('pages.letterDetails.zip')} />
        ),
        accessorKey: 'PostalCode',
      },
      {
        id: 'content-download',
        header: () => (
          <HeaderColumnNext
            localization={t('pages.correspondence.correspondenceData.actions')}
          />
        ),
        cell: ({ row }) => {
          return (
            <DownloadRoundedIcon
              style={{
                cursor:
                  row.original.ContentManagementId == null ? '' : 'pointer',
              }}
              color={
                row.original.ContentManagementId == null
                  ? 'disabled'
                  : 'primary'
              }
              onClick={() => {
                if (row.original.ContentManagementId != null) {
                  setLetterIds({
                    contentId: row.original.ContentManagementId,
                    letterId: String(row.original.LetterId),
                  });
                }
              }}
            ></DownloadRoundedIcon>
          );
        },
      },
    ];
  }, [t]);
  return (
    <>
      <>
        <Typography fontSize="3em">{letterId}</Typography>
        <Grid container spacing={2}>
          <Grid item xs={6} md={6}>
            <Card
              sx={{
                borderRadius: '5px',
                boxShadow: '0px 0px 5px rgb(0 0 0 / 30%)',
              }}
            >
              <CardHeader
                sx={(theme: Theme) => {
                  return {
                    backgroundColor: theme.palette.secondary.light,
                    '& .MuiCardHeader-title': {
                      fontSize: '0.875rem',
                      fontWeight: 700,
                    },
                  };
                }}
                title={t('pages.letterDetails.letterDetails')}
              />
              <CardContent>
                <Grid container>
                  <Grid item xs={4}>
                    <DataDisplay
                      id={'letterType'}
                      label={t('pages.letterDetails.letterType')}
                      data={
                        letterDetailById?.GetLetterDetailByTemplateId
                          .correspondenceTypeName ?? ''
                      }
                    />
                    <DataDisplay
                      id={'letterTypeId'}
                      label={t('pages.letterDetails.letterTypeId')}
                      data={
                        letterDetailById?.GetLetterDetailByTemplateId
                          .correspondenceTypeDisplayId ?? ''
                      }
                      sxLabel={{ display: 'inline-block', mt: 4 }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <DataDisplay
                      id={'letterTypeCategory'}
                      label={t('pages.letterDetails.letterTypeCategory')}
                      data={
                        letterDetailById?.GetLetterDetailByTemplateId
                          .correspondenceCategoryName ?? ''
                      }
                      sxLabel={{ display: 'inline-block' }}
                    />
                    <DataDisplay
                      id={'templateId'}
                      label={t('pages.letterDetails.templateId')}
                      data={
                        letterDetailById?.GetLetterDetailByTemplateId
                          .templateDisplayId ?? ''
                      }
                      sxLabel={{ display: 'inline-block', mt: 4 }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <DataDisplay
                      id={'templateVersIon'}
                      label={t('pages.letterDetails.templateVersIon')}
                      data={
                        letterDetailById?.GetLetterDetailByTemplateId
                          .templateVersion as unknown as string
                      }
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} md={6}>
            <Card
              sx={{
                borderRadius: '5px',
                boxShadow: '0px 0px 5px rgb(0 0 0 / 30%)',
              }}
            >
              <CardHeader
                sx={(theme: Theme) => {
                  return {
                    backgroundColor: theme.palette.secondary.light,
                    '& .MuiCardHeader-title': {
                      fontSize: '0.875rem',
                      fontWeight: 700,
                    },
                  };
                }}
                title={t('pages.letterDetails.requestorDetails')}
              />
              <CardContent>
                <Grid container>
                  <Grid item xs={4}>
                    <DataDisplay
                      id={'createdBy'}
                      label={t('pages.letterDetails.generatedBy')}
                      data={historyState[0]?.CreatedBy ?? ''}
                      sxLabel={{ display: 'inline-block' }}
                      sx={{ color: appTheme.palette.linkBlue.dark }}
                    />
                    <DataDisplay
                      id={'status'}
                      label={t('pages.letterDetails.status')}
                      data={historyState[0]?.DocumentStatus ?? ''}
                      sxLabel={{ display: 'inline-block', mt: 4 }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <DataDisplay
                      id={'generatedDate'}
                      label={t('pages.letterDetails.generatedDate')}
                      data={
                        historyState?.length > 0 && historyState[0].CreatedDate
                          ? format(
                              new Date(historyState[0].CreatedDate.toString()),
                              'MM/dd/yyyy'
                            )
                          : '--'
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <DataDisplay
                      id={'datePrinted'}
                      label={t('pages.letterDetails.datePrinted')}
                      data={
                        historyState?.length > 0 && historyState[0].PrintDate
                          ? format(
                              new Date(historyState[0].PrintDate.toString()),
                              'MM/dd/yyyy'
                            )
                          : '--'
                      }
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid mt={1}>
          <Card>
            <CardHeader
              sx={(theme: Theme) => {
                return {
                  backgroundColor: theme.palette.secondary.light,
                  '& .MuiCardHeader-title': {
                    fontSize: '0.875rem',
                    fontWeight: 700,
                  },
                };
              }}
              title={t('pages.letterDetails.letterSentTo')}
            />
            <CardContent>
              {isLoading && <Loading />}

              {!isLoading &&
                letterDetailsData?.GetFindByJsonParameter &&
                letterDetailsData?.GetFindByJsonParameter?.length > 0 && (
                  <DefaultDataTableNext
                    data={historyState as Record<string, unknown>[]}
                    columns={
                      LetterDetailsColumns as ColumnDef<
                        Record<string, unknown>,
                        unknown
                      >[]
                    }
                  ></DefaultDataTableNext>
                )}
            </CardContent>
          </Card>
        </Grid>
      </>
    </>
  );
}
export default LetterDetails;
