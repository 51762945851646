import { useEffect, useState } from 'react';

import { Grid } from '@mui/material';
import FileDefinitionFields from 'components/channelScheduling/FileDefinitionFields';
import UploadFileDefinition from 'components/UploadFileDefinition';
import { MessageType } from '@revenue-solutions-inc/revxcoreui';
import { useTranslation } from 'react-i18next';
import { Channel } from 'types/channels';
import useUploadXSD from 'hooks/useUploadXSD';
import { useAppDispatch } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import {
  Control,
  UseFormGetValues,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';

interface Props {
  setChannelSchemaDef: React.Dispatch<React.SetStateAction<boolean>>;
  uploadFileChannel: boolean;
  setUploadFileChannel: React.Dispatch<React.SetStateAction<boolean>>;
  displayFields: boolean;
  setDisplayFields: React.Dispatch<React.SetStateAction<boolean>>;
  control: Control<Channel, unknown>;
  watch: UseFormWatch<Channel>;
  setValue: UseFormSetValue<Channel>;
  getValues: UseFormGetValues<Channel>;
  setDelimiterValue: React.Dispatch<React.SetStateAction<string>>;
  delimiterValue: string;
}

function FileDefintion({
  uploadFileChannel,
  setUploadFileChannel,
  displayFields,
  setDisplayFields,
  setChannelSchemaDef,
  control,
  getValues,
  watch,
  setDelimiterValue,
  delimiterValue,
  setValue,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    isLoading,
    isSuccess,
    isError,
    data,
    mutate: upload,
  } = useUploadXSD();

  const [fileParametersValid, setFileParametersValid] = useState<boolean>(true);
  const [uploadedFile, setUploadedFile] = useState(new File([''], ''));

  useEffect(() => {
    if (data) {
      setUploadFileChannel(true);
      setValue(`Channel.FileGroup.FileGroupName`, data.FileGroup.FileGroupName);
      setValue(`Channel.FileGroup.FileGroupType`, data.FileGroup.FileGroupType);
      setValue(`Channel.FileGroup.FileType`, data.FileGroup.FileType);
      setValue(
        `Channel.FileGroup.FileProcessingType`,
        data.FileGroup.FileProcessingType
      );
      setValue(
        `Channel.FileGroup.DelimiterValue`,
        data.FileGroup.DelimiterValue
      );
      setValue(
        `Channel.FileGroup.Files.${0}.FileName`,
        data.FileGroup.Files[0].FileName
      );
      setValue(
        `Channel.FileGroup.Files.${0}.FileMetadata`,
        data.FileGroup.Files[0].FileMetaData
      );
      setValue(
        `Channel.FileGroup.Files.${0}.FileProcessingOrder`,
        data.FileGroup.Files[0].FileProcessingOrder
      );
    }
  });

  useEffect(() => {
    if (isError) {
      if (uploadedFile.type != 'application/xsd') {
        dispatch(
          addMessage({
            type: MessageType.Error,
            message: t('components.message.typeError'),
          })
        );
      } else {
        dispatch(
          addMessage({
            type: MessageType.Error,
            message: t('components.message.networkerror'),
          })
        );
      }
    } else if (isSuccess) {
      dispatch(
        addMessage({
          type: MessageType.Success,
          message: t('pages.manageChannel.successFile'),
        })
      );
    }
  }, [isError, dispatch, t, uploadedFile, isSuccess]);

  const validateRequiredFields = () => {
    let isValid = true;

    if (fileParametersValid === false) {
      isValid = false;
    }
    setChannelSchemaDef(isValid);
  };

  useEffect(() => {
    validateRequiredFields();
  });

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <UploadFileDefinition
          setUploadedFile={setUploadedFile}
          upload={upload}
          isLoading={isLoading}
          uploadedFile={uploadedFile}
          fileGroupBuilderOptions={t(
            'pages.manageFormsProcessing.start.fileGroupBuilderOptions'
          )}
          optionOne={t('pages.manageFormsProcessing.start.optionOne')}
          titleOne={t('pages.manageFormsProcessing.start.createYourFileTitle')}
          subTitleOne={t(
            'pages.manageFormsProcessing.start.createYourFileSubtitle'
          )}
          createYourFileSubtitle={t(
            'pages.manageFormsProcessing.start.createYourFileSubtitle'
          )}
          optionTwo={t('pages.manageFormsProcessing.start.optionTwo')}
          titleTwo={t('pages.manageFormsProcessing.start.uploadFormTitle')}
          subTitleTwo={t(
            'pages.manageFormsProcessing.start.uploadFormSubtitle'
          )}
          uploadTitle={t('pages.manageFormsProcessing.start.uploadTitle')}
          uploadByDragFile={t(
            'pages.manageFormsProcessing.start.uploadByDragFile'
          )}
          setDisplayFields={setDisplayFields}
          setUploadFileChannel={setUploadFileChannel}
        />
      </Grid>
      <Grid item xs={12}>
        <FileDefinitionFields
          displayFields={displayFields}
          uploadFileChannel={uploadFileChannel}
          setFileParametersValid={setFileParametersValid}
          control={control}
          watch={watch}
          setValue={setValue}
          getValues={getValues}
          setDelimiterValue={setDelimiterValue}
          delimiterValue={delimiterValue}
        />
      </Grid>
    </Grid>
  );
}

export default FileDefintion;
