import {
  ConfigurationResponse,
  PlatformConfigurationInfo,
} from 'generated/graphql';

const configurationResponseDefault: ConfigurationResponse = {
  __typename: 'ConfigurationResponse',
  configurationDescription: '',
  configurationDomain: '',
  configurationModule: '',
  configurationName: '',
  configurationType: '',
  createDate: '',
  createdBy: '',
  isSchema: false,
  modifiedBy: '',
  modifiedDate: '',
  platformConfigurationId: '',
  platformConfigurationGroupId: null,
  platformConfigurationInfo: null,
  groupDescription: '',
  configurationModuleDescription: '',
  stateOf: '',
  tenantID: '',
  version: 1,
};

const configurationInfoDefault: PlatformConfigurationInfo = {
  configurationSection: [
    {
      configurationSectionName: 'Default',
      group: [{ groupName: 'Default', attribute: [] }],
    },
  ],
};

export { configurationResponseDefault, configurationInfoDefault };
