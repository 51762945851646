import { useEffect } from 'react';
import { Grid } from '@mui/material';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import Loading from 'components/Loading';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { IHeader, setHeader } from 'redux/contentSlice';
import { useAppDispatch } from 'redux/hooks';
import { ColumnDef } from '@tanstack/react-table';
import {
  BankAccount,
  useGetAccountByIdQuery,
  useGetBankAccountDetailQuery,
  useGetLookupConfigurationQuery,
} from 'generated/graphql';
import { addMessage } from 'redux/messageSlice';
import { formatIdentifier } from 'utils/formatIdentifier';
import { MessageType } from '@revenue-solutions-inc/revxcoreui';
import { getIdFormats } from 'components/entityManagement/common/formatValidations';
import { ConfigurationDomains } from 'common/platformConfigUtils/platformConfigUtils';
import { getFormatDate } from 'utils/date-util';

function BankAccountDetails(): JSX.Element {
  const CONFIG_DOMAIN = ConfigurationDomains.ReferenceSchema;
  const dispatch = useAppDispatch();
  const { entityId } = useParams() as { entityId: string };
  const { accountId } = useParams() as { accountId: string };
  const { bankaccountId } = useParams() as { bankaccountId: string };

  const { t } = useTranslation();

  const { data, isLoading } = useGetBankAccountDetailQuery(
    {
      Id: bankaccountId,
    },
    {
      enabled: bankaccountId !== '',
      onError: () => {
        dispatch(
          addMessage({
            type: MessageType.Error,
            message: t('pages.bankAccount.bankAccountDetails.error'),
          })
        );
      },
    }
  );

  const { data: accountData, isLoading: isAccountLoading } =
    useGetAccountByIdQuery(
      { accountId },
      {
        enabled: accountId !== '',
        onError: () => {
          dispatch(
            addMessage({
              type: MessageType.Error,
              message: t('pages.accountSummary.message.noAccount'),
            })
          );
        },
      }
    );

  const { data: configuredIdTypes } = useGetLookupConfigurationQuery(
    {
      configurationDomain: CONFIG_DOMAIN,
      configurationType: 'IdType',
    },
    {
      onError: () => {
        dispatch(
          addMessage({
            type: MessageType.Error,
            message: t('pages.bankAccount.bankAccountDetails.lookupError'),
          })
        );
      },
    }
  );

  useEffect(() => {
    if (!isLoading && data && !isAccountLoading && accountData) {
      const headerData: IHeader = {
        pageTitle: t('pages.bankAccount.bankAccountDetails.title'),
        previousPage: t('pages.accountSummary.title'),
        route: `entity/${entityId}/account/${accountId}`,
        icon: {
          props: { fill: 'black' },
          icon: 'accountBalanceIcon',
          fontSize: 'large',
        },
        data: [
          {
            id: 'accountName',
            label: t(`pages.accountSummary.header.accountName`),
            value:
              accountData.GetAccountById.group
                ?.filter(
                  (group) => group.groupName?.toLowerCase() === 'account'
                )
                .find((attr) =>
                  attr.attribute.some(
                    (att) =>
                      att.attributeName === 'isPrimary' &&
                      att.attributeValue === 'true'
                  )
                )
                ?.attribute.find(
                  (name) => name.attributeName?.toLowerCase() === 'accountname'
                )?.attributeValue ?? '',
            first: true,
          },
          {
            id: 'accountId',
            label: t(`pages.accountSummary.header.accountId`),
            value: formatIdentifier(
              accountData.GetAccountById.group
                ?.filter(
                  (group) => group.groupName?.toLowerCase() === 'identifiers'
                )
                .find((attr) =>
                  attr.attribute.some(
                    (att) =>
                      att.attributeName === 'isPrimary' &&
                      att.attributeValue === 'true'
                  )
                )
                ?.attribute.find(
                  (name) => name.attributeName?.toLowerCase() === 'value'
                )?.attributeValue ?? '',
              getIdFormats(configuredIdTypes),
              accountData.GetAccountById.group
                ?.filter(
                  (group) => group.groupName?.toLowerCase() === 'identifiers'
                )
                .find((attr) =>
                  attr.attribute.some(
                    (att) =>
                      att.attributeName === 'isPrimary' &&
                      att.attributeValue === 'true'
                  )
                )
                ?.attribute.find(
                  (name) => name.attributeName?.toLowerCase() === 'idtype'
                )?.attributeValue ?? ''
            ),
          },
          {
            id: 'bankAccountNumber',
            label: t('pages.bankAccount.bankAccountData.accountNumber'),
            value: data.getBankAccountDetail.bankAccountNumber,
          },
          {
            id: 'bankAccountType',
            label: t('pages.bankAccount.bankAccountDetails.bankAccountType'),
            value: data.getBankAccountDetail.bankAccountType,
          },
        ],
      };
      dispatch(setHeader(headerData));
    }
  }, [
    isLoading,
    data,
    dispatch,
    t,
    accountId,
    entityId,
    isAccountLoading,
    accountData,
    configuredIdTypes,
  ]);

  const columns: ColumnDef<BankAccount>[] = [
    {
      id: 'bankAccountNumber',
      accessorKey: 'bankAccountNumber',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.bankAccount.bankAccountData.accountNumber')}
        />
      ),
    },
    {
      id: 'ownership',
      accessorKey: 'ownership',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.bankAccount.bankAccountDetails.ownership')}
        />
      ),
    },
    {
      id: 'bankAccountType',
      accessorKey: 'bankAccountType',
      header: () => (
        <HeaderColumnNext
          localization={t(
            'pages.bankAccount.bankAccountDetails.bankAccountType'
          )}
        />
      ),
    },
    {
      id: 'routingNumber',
      accessorKey: 'routingNumber',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.bankAccount.bankAccountData.routingNumber')}
        />
      ),
    },
    {
      id: 'financialInstitution',
      accessorKey: 'financialInstitution',
      header: () => (
        <HeaderColumnNext
          localization={t(
            'pages.bankAccount.bankAccountDetails.financialInstitution'
          )}
        />
      ),
    },
    {
      id: 'commenceDate',
      accessorKey: 'commenceDate',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.bankAccount.bankAccountData.commenceDate')}
        />
      ),
    },
    {
      id: 'ceaseDate',
      accessorKey: 'ceaseDate',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.bankAccount.bankAccountData.ceaseDate')}
        />
      ),
    },
    {
      id: 'status',
      accessorKey: 'status',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.bankAccount.bankAccountData.status')}
        />
      ),
    },
    {
      id: 'reason',
      accessorKey: 'reason',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.bankAccount.bankAccountDetails.reason')}
        />
      ),
    },
  ];

  return (
    <>
      {isLoading && <Loading />}
      {data && (
        <Grid data-testid="bankAccountDetails-body" container spacing={1}>
          <Grid item xs={12}>
            <DefaultDataTableNext
              columns={columns}
              data={[
                {
                  ...data.getBankAccountDetail,
                  commenceDate: getFormatDate(
                    new Date(data.getBankAccountDetail.commenceDate)
                  ),
                  ceaseDate: data.getBankAccountDetail.ceaseDate
                    ? getFormatDate(
                        new Date(data.getBankAccountDetail.ceaseDate)
                      )
                    : data.getBankAccountDetail.ceaseDate,
                },
              ]}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default BankAccountDetails;
