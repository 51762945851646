import { AccountForm, AccountResponse, StepsConfig } from 'types/accounts';

export const getAccount: AccountForm = {
  account: [
    {
      AccountType: '',
      accountCreationSource: 'Online',
      commenceDate: null,
      ceaseDate: null,
      reason: '',
      active: 'true',
    },
  ],
  names: [
    {
      AccountName: '',
      commenceDate: null,
      ceaseDate: null,
      isPrimary: 'true',
    },
  ],
  addresses: [
    {
      AddressType: '',
      attention: '',
      cityId: '',
      countyId: '',
      countryId: '',
      postalCode: '',
      stateProvinceId: '',
      addressLine1: '',
      addressLine2: '',
      isPrimary: 'true',
      isConfidential: 'true',
      hasMailReturned: 'true',
      commenceDate: null,
      ceaseDate: null,
    },
  ],
  identifiers: [
    {
      IdType: '',
      value: '',
      commenceDate: null,
      ceaseDate: null,
      isPrimary: 'true',
    },
  ],
  emailAddresses: [
    {
      EmailAddressType: '',
      value: '',
      commenceDate: null,
      ceaseDate: null,
      isPrimary: 'true',
    },
  ],
  phoneNumbers: [
    {
      PhoneNumberType: '',
      value: '',
      commenceDate: null,
      ceaseDate: null,
      isPrimary: 'true',
    },
  ],
  industryCode: [
    {
      code: '',
      codeDescription: '',
    },
  ],
  filingFrequencies: [
    {
      periodFrequency: '',
      periodFrequencyMonth: null,
      commenceDate: null,
      ceaseDate: null,
    },
  ],
};

export const getAccountResponse: AccountResponse = {
  id: '',
  accountCreationSourceId: 'Online',
  status: 'Ok',
};

export const defaultSectionsConfig: StepsConfig[] = [
  { step: 0, name: 'account', isRequired: true, showOptional: false },
  { step: 1, name: 'names', isRequired: true, showOptional: false },
  { step: 2, name: 'identifiers', isRequired: true, showOptional: false },
  { step: 3, name: 'addresses', isRequired: true, showOptional: false },
  { step: 4, name: 'phoneNumbers', isRequired: false, showOptional: false },
  { step: 4, name: 'emailAddresses', isRequired: false, showOptional: false },
  { step: 5, name: 'industryCodes', isRequired: true, showOptional: false },
  { step: 6, name: 'filingFrequencies', isRequired: true, showOptional: false },
];

export function getDefault(key: string) {
  if (key in getAccount) {
    const attrName = key as keyof typeof getAccount;
    const defaultSection = getAccount?.[attrName];
    if (defaultSection) {
      return defaultSection[0];
    }
  }
  return {};
}
