import { useState, useEffect, useMemo, useCallback, Fragment } from 'react';
import {
  FileCopy as FileCopyIcon,
  Delete as DeleteIcon,
  Check as CheckIcon,
  Close as CloseIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import { Box, IconButton, Theme, Stack } from '@mui/material';
import {
  Button,
  MessageActionType,
  MessageType,
} from '@revenue-solutions-inc/revxcoreui';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import { ColumnDef, Column, Row, Table } from '@tanstack/react-table';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { addMessage, clearActionValue } from 'redux/messageSlice';
import {
  useDeleteVariantObjectMutation,
  WorkflowSchemaVariantObject,
  WorkflowSchemaVariant,
  useCreateWorkflowSchemaVariantMutation,
  useUpdateWorkflowSchemaVariantMutation,
  WorkflowSchemaVariantInput,
  WorkflowStepConfigurationInput,
  WorkflowStepConfiguration,
  PlatformConfigurationInfo,
  Group,
  WorkflowSchema,
} from 'generated/graphql';
import { cloneDeep } from 'lodash';
import { ConfigurationDomains } from 'common/platformConfigUtils/platformConfigUtils';
import WorkflowDetailsTab from '../WorkflowDetails/WorkflowDetailsTab';
import type {
  WorkflowFormObject,
  ConfigGroup,
  WorkflowStepConfig,
} from '../WorkflowEditor.types';

const fontSize = '1em';

interface Props {
  workflowSchemaVariants: WorkflowSchemaVariantObject[];
  setWorkflowSchemaVariants: (
    configData: WorkflowSchemaVariantObject[]
  ) => void;
  refetchTypes: () => void;
  selectedWorkflowSchemaVariant: WorkflowSchemaVariantObject;
  selectedSchema: WorkflowSchema;
}

function TableManager({
  workflowSchemaVariants,
  setWorkflowSchemaVariants,
  refetchTypes,
  selectedWorkflowSchemaVariant,
  selectedSchema,
}: Props) {
  const formMethods = useForm<WorkflowFormObject>();
  const { t } = useTranslation();
  const [selectedRecord, setSelectedRecord] = useState<
    WorkflowSchemaVariantObject | undefined
  >(undefined);
  const [recordToDelete, setRecordToDelete] = useState<number | undefined>();
  const actionValue = useAppSelector((state) => state.message.actionValue);
  const deleteWorkflowSchemaVariant = useDeleteVariantObjectMutation();
  const updateWorkflowSchemaVariant = useUpdateWorkflowSchemaVariantMutation();
  const createWorkflowSchemaVariant = useCreateWorkflowSchemaVariantMutation();
  const dispatch = useAppDispatch();
  const [dataTableKey, setDataTableKey] = useState(0);
  const [shouldEditBeDefault, setShouldEditBeDefault] =
    useState<boolean>(false);

  const isBankruptcy = selectedSchema.workflowSchemaType === 'Bankruptcy';

  const overrideValuesForBankruptcy = (template: WorkflowSchemaVariant) => ({
    ...template,
    platformConfigurationInfo: {
      ...template.platformConfigurationInfo,
      configurationSection: (
        template.platformConfigurationInfo?.configurationSection ?? []
      ).map((section) => ({
        ...section,
        group: section.group.map((group) => ({
          ...group,
          attribute: group.attribute.map((attr) => {
            if (
              ['IncludeDemographic', 'IncludeCRM', 'IncludeHistory'].includes(
                attr.attributeName
              )
            ) {
              attr.attributeValue = 'true';
            } else if (attr.attributeName === 'VariantGroupCode') {
              attr.attributeValue = 'Bankruptcy';
            }
            return attr;
          }),
        })),
      })),
    },
  });

  const removeConfiguration = useCallback(
    (workflowVariantId: number) => {
      deleteWorkflowSchemaVariant.mutate(
        {
          workflowVariantId,
        },
        {
          onSuccess: () => {
            refetchTypes();
            setSelectedRecord(undefined);
            dispatch(
              addMessage({
                type: MessageType.Success,
                message: t('components.message.success'),
                actionType: MessageActionType.None,
              })
            );
          },
          onError: () => {
            dispatch(
              addMessage({
                type: MessageType.Error,
                message: t('components.message.networkerror'),
              })
            );
          },
        }
      );
    },
    [deleteWorkflowSchemaVariant, dispatch, refetchTypes, setSelectedRecord, t]
  );

  const saveConfiguration = useCallback(
    (toggleExpand: (expand?: boolean) => SubmitHandler<WorkflowFormObject>) =>
      () => {
        if (selectedRecord === undefined) {
          return;
        }
        const vals = formMethods.getValues();

        const closeReasonGroup = vals.workflowConfig.find(
          (wc) => wc.groupName == 'CloseReasonConfiguration'
        );
        const variantDetailsGroup = vals.workflowConfig.find(
          (wc) => wc.groupName == 'VariantDetails'
        );
        const customAttributesGroup = vals.workflowConfig.find(
          (wc) => wc.groupName == 'CustomAttributes'
        );

        const repeatingAttributes = customAttributesGroup?.attribute.flatMap(
          (attr) => {
            return attr?.repeatingValue?.map((value) => {
              return value;
            });
          }
        );

        if (
          repeatingAttributes?.find((att) => {
            return (
              !att?.attributeName ||
              !att?.attributeDisplayName ||
              !att?.attributeType
            );
          })
        ) {
          // block the workflow creation if custom attributes required are empty
          return;
        }

        if (
          variantDetailsGroup?.attribute.find((att) => {
            const name = att.attributeName?.toLowerCase();
            return (
              !att.attributeValue &&
              (name === 'workflowvariantcode' ||
                name === 'variantgroupcode' ||
                name === 'priority' ||
                name === 'queue')
            );
          })
        ) {
          // block the workflow creation if WorkflowType or case type are empty
          return;
        }

        if (closeReasonGroup) {
          // remove empty closeReason values from vals
          closeReasonGroup.attribute = closeReasonGroup.attribute.filter(
            (att) =>
              att.attributeValue !== '' && att.attributeValue !== undefined
          );
        }

        if (selectedRecord.variantObject.platformConfigurationId) {
          const platformConfig =
            selectedRecord.variantObject.platformConfigurationInfo;
          if (
            platformConfig != undefined &&
            platformConfig.configurationSection[0] != undefined
          )
            platformConfig.configurationSection[0].group =
              vals.workflowConfig as Group[];
          const config: WorkflowSchemaVariantInput = {
            tenantID: '',
            configurationDescription:
              selectedRecord.variantObject.configurationDescription ?? '',
            configurationDomain: ConfigurationDomains.ReferenceSchema,
            configurationModule:
              +selectedRecord.variantObject.configurationModule.toString(),
            version: selectedRecord.variantObject.version || 1,
            isSchema: false,
            configurationName: selectedRecord.variantObject.configurationName,
            configurationType: selectedRecord.variantObject.configurationType,
            platformConfigurationId:
              selectedRecord.variantObject.platformConfigurationId,
            platformConfigurationGroupId:
              selectedRecord.variantObject.platformConfigurationGroupId,
            platformConfigurationInfo: platformConfig,
            WorkflowStepConfiguration: vals.workflowSteps.reduce(
              (acc, step) => {
                if (
                  step.workflowActions.every(
                    (action) => action.parameter?.attributeName
                  )
                ) {
                  acc.push(step);
                }
                return acc;
              },
              [] as WorkflowStepConfigurationInput[]
            ),
            createDate: null,
            createdBy: null,
            modifiedBy: null,
            modifiedDate: null,
          };
          updateWorkflowSchemaVariant.mutate(
            {
              variantObjectId: selectedRecord.workflowVariantId,
              schemaId: selectedSchema?.workflowSchemaId.toString(),
              newVariant: config,
            },
            {
              onSuccess: function () {
                refetchTypes();
                setSelectedRecord(undefined);
                dispatch(
                  addMessage({
                    type: MessageType.Success,
                    message: t('components.message.success'),
                  })
                );
              },
              onError: () => {
                dispatch(
                  addMessage({
                    type: MessageType.Error,
                    message: t('components.message.networkerror'),
                  })
                );
              },
            }
          );
        } else {
          const platformConfig =
            selectedRecord.variantObject.platformConfigurationInfo;
          if (
            platformConfig != undefined &&
            platformConfig.configurationSection[0] != undefined
          )
            platformConfig.configurationSection[0].group =
              vals.workflowConfig as Group[];
          const config: WorkflowSchemaVariantInput = {
            tenantID: '',
            configurationDescription:
              selectedRecord.variantObject.configurationDescription ?? '',
            configurationDomain: ConfigurationDomains.ReferenceSchema,
            configurationModule: selectedRecord?.variantObject
              ?.configurationModule
              ? +selectedRecord.variantObject.configurationModule
              : -1,
            version: selectedRecord.variantObject.version || 1,
            isSchema: false,
            isOOTB: false,
            isOOTBEditable: null,
            configurationName:
              selectedRecord.variantObject.configurationName ?? '',
            configurationType:
              selectedRecord.variantObject.configurationType ?? '',
            platformConfigurationInfo: platformConfig,
            platformConfigurationGroupId:
              selectedRecord.variantObject.platformConfigurationGroupId,
            WorkflowStepConfiguration: vals.workflowSteps.reduce(
              (acc, step) => {
                if (
                  step.workflowActions.every(
                    (action) => action.parameter?.attributeName
                  )
                ) {
                  acc.push(step);
                }
                return acc;
              },
              [] as WorkflowStepConfigurationInput[]
            ),
            createDate: null,
            createdBy: null,
            modifiedBy: null,
            modifiedDate: null,
          };

          createWorkflowSchemaVariant.mutate(
            {
              schemaId: selectedSchema?.workflowSchemaId.toString(),
              newVariant: config,
            },
            {
              onSuccess: function () {
                setSelectedRecord(undefined);
                refetchTypes();

                const cancelIndex = workflowSchemaVariants.findIndex(
                  (variant) => {
                    return variant.variantObject.platformConfigurationId === '';
                  }
                );
                if (cancelIndex !== undefined) {
                  const newVariants = [...workflowSchemaVariants];
                  newVariants.splice(cancelIndex, 1);
                  setWorkflowSchemaVariants([...newVariants]);
                }
                toggleExpand(false);

                dispatch(
                  addMessage({
                    type: MessageType.Success,
                    message: t('components.message.success'),
                  })
                );
              },
              onError: () => {
                dispatch(
                  addMessage({
                    type: MessageType.Error,
                    message: t('components.message.networkerror'),
                  })
                );
              },
            }
          );
        }
      },
    [
      dispatch,
      formMethods,
      refetchTypes,
      selectedRecord,
      selectedSchema,
      setSelectedRecord,
      setWorkflowSchemaVariants,
      workflowSchemaVariants,
      t,
      updateWorkflowSchemaVariant,
      createWorkflowSchemaVariant,
    ]
  );

  const handleCopyRow = useCallback(
    (workflowToCopy: WorkflowSchemaVariantObject) => {
      const currIndex = workflowSchemaVariants
        .map((e) => e.variantObject.platformConfigurationId)
        .indexOf(workflowToCopy.variantObject.platformConfigurationId);
      const temp = cloneDeep(workflowSchemaVariants[currIndex]);
      if (temp?.variantObject) {
        temp.variantObject.platformConfigurationId = '';
      }
      //update workflow type in variant details

      const groups =
        temp.variantObject.platformConfigurationInfo?.configurationSection[0]
          .group;
      const detailsIndex = groups?.findIndex((group) => {
        return group.groupName === 'VariantDetails';
      });
      const codeIndex = groups?.[detailsIndex ?? -1].attribute.findIndex(
        (attr) => {
          return attr.attributeName === 'WorkflowVariantCode';
        }
      );

      if (
        codeIndex !== undefined &&
        detailsIndex !== undefined &&
        temp.variantObject.platformConfigurationInfo?.configurationSection[0]
          ?.group[detailsIndex]?.attribute[codeIndex] !== undefined
      ) {
        temp.variantObject.platformConfigurationInfo.configurationSection[0].group[
          detailsIndex
        ].attribute[codeIndex].attributeValue =
          temp.variantObject.platformConfigurationInfo.configurationSection[0]
            .group[detailsIndex].attribute[codeIndex].attributeValue + '_copy';
      }

      setWorkflowSchemaVariants([temp, ...workflowSchemaVariants]);
      setSelectedRecord(temp);
    },
    [setSelectedRecord, setWorkflowSchemaVariants, workflowSchemaVariants]
  );

  const handleAddRow = useCallback(() => {
    if (
      workflowSchemaVariants.filter(
        (row) => !row.variantObject.platformConfigurationId
      ).length === 0
    ) {
      let template =
        selectedWorkflowSchemaVariant as unknown as WorkflowSchemaVariant;

      if (isBankruptcy) {
        template = overrideValuesForBankruptcy(template);
      }

      const temp = {
        workflowVariantId: -1,
        variantObject: {
          platformConfigurationId: '',
          configurationName: template.configurationName,
          configurationDescription: template.configurationDescription,
          configurationDomain: ConfigurationDomains.ReferenceSchema.toString(),
          configurationType: template.configurationType,
          configurationModule: template.configurationModule,
          version: template.version,
          platformConfigurationGroupId: template.platformConfigurationGroupId,
          tenantID: '',
          platformConfigurationInfo: JSON.parse(
            JSON.stringify(template.platformConfigurationInfo)
          ),
        },
      };

      // force the data table to update
      setDataTableKey((_dataTableKey) => _dataTableKey + 1);
      setWorkflowSchemaVariants([temp, ...workflowSchemaVariants]);
      setSelectedRecord(temp);
      setShouldEditBeDefault(true);
    }
  }, [
    selectedWorkflowSchemaVariant,
    setWorkflowSchemaVariants,
    workflowSchemaVariants,
    isBankruptcy,
  ]);

  const handleEditRow = useCallback(
    (
      configHeader: WorkflowSchemaVariantObject,
      toggleExpand: (expand?: boolean) => void,
      table: Table<WorkflowSchemaVariantObject>
    ) => {
      table.toggleAllRowsExpanded(false);
      toggleExpand(true);

      if (isBankruptcy) {
        configHeader.variantObject = overrideValuesForBankruptcy(
          configHeader.variantObject
        );
      }

      setSelectedRecord({ ...configHeader });
    },
    [setSelectedRecord, isBankruptcy]
  );

  const handleCancel = useCallback(
    (toggleExpand: (expand?: boolean) => void) => {
      //if we were creating a new record and cancelled before saving, take it back out of the data.
      if (selectedRecord?.variantObject.platformConfigurationId === '') {
        const cancelIndex = workflowSchemaVariants.findIndex((variant) => {
          return variant.variantObject.platformConfigurationId === '';
        });
        if (cancelIndex !== undefined) {
          const newVariants = [...workflowSchemaVariants];
          newVariants.splice(cancelIndex, 1);
          setWorkflowSchemaVariants([...newVariants]);
        }
      }
      toggleExpand(false);
      setSelectedRecord(undefined);
    },
    [
      selectedRecord?.variantObject.platformConfigurationId,
      workflowSchemaVariants,
      setWorkflowSchemaVariants,
    ]
  );

  useEffect(() => {
    setSelectedRecord(undefined);
  }, [selectedWorkflowSchemaVariant, setSelectedRecord]);

  useEffect(() => {
    if (actionValue === 'message_continue' && recordToDelete) {
      removeConfiguration(recordToDelete);
      dispatch(clearActionValue());
    }
  }, [actionValue, dispatch, recordToDelete, removeConfiguration]);

  const setFormModel = useCallback(
    (
      workflowSteps: WorkflowStepConfiguration[],
      platformConfigInfo: PlatformConfigurationInfo
    ) => {
      formMethods.reset({
        workflowSteps: (workflowSteps as WorkflowStepConfig[]) ?? [],
        workflowConfig: platformConfigInfo.configurationSection[0]
          .group as ConfigGroup[],
      });
    },
    [formMethods]
  );

  const handleEditWorkflowRecord = useCallback(
    (
      row: Row<WorkflowSchemaVariantObject>,
      table: Table<WorkflowSchemaVariantObject>
    ) => {
      handleEditRow(row.original, row.toggleExpanded, table);
      setFormModel(
        row.original.variantObject.WorkflowStepConfiguration ??
          ([] as WorkflowStepConfiguration[]),
        row.original.variantObject.platformConfigurationInfo ??
          ({} as PlatformConfigurationInfo)
      );
      setShouldEditBeDefault(false);
    },
    [handleEditRow, setFormModel]
  );

  const configHeaderColumns = useMemo<ColumnDef<WorkflowSchemaVariantObject>[]>(
    () => [
      {
        header: () => (
          <HeaderColumnNext localization={t('components.actions.remove')} />
        ),
        id: 'remove',
        cell: ({ row }) => {
          if (
            row.original.variantObject.platformConfigurationId !==
            selectedRecord?.variantObject.platformConfigurationId
          ) {
            return (
              <IconButton
                size="medium"
                sx={{ color: 'primary.main' }}
                onClick={() => {
                  if (row.original) {
                    setRecordToDelete(row?.original?.workflowVariantId);
                  }
                  dispatch(
                    addMessage({
                      message: t('pages.configGroupEditor.confirmButtonMsg'),
                      type: MessageType.Info,
                      actionType: MessageActionType.Continue,
                    })
                  );
                }}
                aria-label="delete-button"
              >
                <DeleteIcon sx={{ fontSize }} />
              </IconButton>
            );
          }
          return <Fragment />;
        },
      },
      {
        header: () => (
          <HeaderColumnNext localization={t('components.actions.copy')} />
        ),
        id: 'copy',
        cell: ({ row }) => {
          if (
            row.original.variantObject.platformConfigurationId !==
            selectedRecord?.variantObject.platformConfigurationId
          ) {
            return (
              <IconButton
                size="medium"
                sx={(theme: Theme) => ({
                  color: theme.palette.primary.main,
                })}
                onClick={() =>
                  handleCopyRow(row.original as WorkflowSchemaVariantObject)
                }
                aria-label="copy-button"
                disabled={selectedRecord !== undefined}
              >
                <FileCopyIcon sx={{ fontSize }} />
              </IconButton>
            );
          }
          return <Fragment />;
        },
      },

      {
        header: () => (
          <HeaderColumnNext
            localization={t('pages.workflowEditor.workflowType')}
          />
        ),
        id: 'WorkflowType',
        accessorKey: 'workflowType',
      },
      {
        header: () => (
          <HeaderColumnNext
            localization={t('pages.workflowEditor.workflowSubType')}
          />
        ),
        id: 'WorkflowSubType',
        accessorKey: 'workflowSubtype',
      },

      {
        header: () => (
          <HeaderColumnNext localization={t('components.actions.edit')} />
        ),
        id: 'edit',
        cell: ({ row, table }) => {
          if (
            row.original.variantObject.platformConfigurationId !==
            selectedRecord?.variantObject.platformConfigurationId
          ) {
            if (
              !row.original.variantObject.platformConfigurationId &&
              shouldEditBeDefault
            )
              handleEditWorkflowRecord(row, table);
            return (
              <IconButton
                size="small"
                onClick={() => {
                  handleEditWorkflowRecord(row, table);
                }}
                sx={(theme: Theme) => ({
                  border: `${theme.palette.grey1.main} 1px solid`,
                })}
                aria-label="edit-button"
                disabled={
                  selectedRecord !== undefined ||
                  (row.original.variantObject.isOOTB === true &&
                    row.original.variantObject.isOOTBEditable ===
                      !!(false || null))
                }
              >
                <EditIcon sx={{ fontSize }} />
              </IconButton>
            );
          }
          return (
            <Stack spacing={1} direction="row">
              <IconButton
                size="small"
                style={{ color: 'white' }}
                onClick={formMethods.handleSubmit(
                  saveConfiguration(
                    row.toggleExpanded as (
                      toggleExpand: boolean | undefined
                    ) => SubmitHandler<WorkflowFormObject>
                  ),
                  (formErrors) => {
                    const errors =
                      formErrors?.workflowConfig?.[0]?.attribute ?? [];
                    if (errors !== undefined && errors.length) {
                      formMethods.trigger('workflowConfig');
                    }
                  }
                )}
                sx={{
                  backgroundColor: 'secondaryGreen.main',
                  '&:hover': {
                    backgroundColor: 'secondaryGreen.main',
                  },
                  '&:disabled': {
                    backgroundColor: 'grey1.main',
                  },
                }}
                aria-label="check-button"
                disabled={
                  selectedRecord.variantObject.configurationName.trim() === ''
                }
              >
                <CheckIcon sx={{ fontSize }} />
              </IconButton>
              <IconButton
                style={{ color: 'white' }}
                size="small"
                sx={{
                  backgroundColor: 'error.main',
                  '&:hover': {
                    backgroundColor: 'error.main',
                  },
                }}
                onClick={() => handleCancel(row.toggleExpanded)}
              >
                <CloseIcon fontSize="small" sx={{ fontSize }} />
              </IconButton>
            </Stack>
          );
        },
      },
      {
        header: () => <HeaderColumnNext localization={t('')} />,
        id: 'expander',
        cell: ({ row, table }) => {
          if (row.original.variantObject.platformConfigurationInfo) {
            return (
              <Box
                {...{
                  onClick: () => {
                    setFormModel(
                      row.original.variantObject.WorkflowStepConfiguration ??
                        ([] as WorkflowStepConfiguration[]),
                      row.original.variantObject.platformConfigurationInfo ??
                        ({} as PlatformConfigurationInfo)
                    );
                    if (!row.getIsExpanded()) {
                      table.toggleAllRowsExpanded(false);
                    }
                    row.toggleExpanded();
                  },
                  sx: { cursor: 'pointer' },
                }}
                component="span"
              >
                {row.getIsExpanded() ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </Box>
            );
          } else {
            if (row.getIsExpanded()) {
              table.toggleAllRowsExpanded(false);
            }
            return <Fragment />;
          }
        },
      },
    ],
    [
      dispatch,
      handleCancel,
      handleCopyRow,
      saveConfiguration,
      handleEditWorkflowRecord,
      shouldEditBeDefault,
      selectedRecord,
      setFormModel,
      t,
      formMethods,
    ]
  );

  // Adding the Workflow Type and Workflow Subtype column to the table
  const configDataToDisplayInTable = useMemo(() => {
    return workflowSchemaVariants.map((d) => {
      const variantDetailsGroup =
        d.variantObject.platformConfigurationInfo?.configurationSection[0].group.find(
          (g) => g.groupName === 'VariantDetails'
        );
      const workflowType = variantDetailsGroup?.attribute.find(
        (attr) => attr.attributeName === 'VariantGroupCode'
      )?.attributeValue;
      const workflowSubtype = variantDetailsGroup?.attribute.find(
        (attr) => attr.attributeName === 'WorkflowVariantCode'
      )?.attributeValue;
      return { ...d, workflowType, workflowSubtype };
    });
  }, [workflowSchemaVariants]);

  return (
    <DefaultDataTableNext
      key={dataTableKey}
      columns={configHeaderColumns as Column<WorkflowSchemaVariantObject>[]}
      data={configDataToDisplayInTable}
      customHeader={
        <Button
          onClick={handleAddRow}
          id={'tableManager-AddBtn'}
          disabled={selectedRecord !== undefined}
          sx={{ mr: '1em', mt: '.5em' }}
        >
          {t('components.button.add')}
        </Button>
      }
      renderRowSubComponent={(row) => (
        <FormProvider {...formMethods}>
          <WorkflowDetailsTab
            triggerValidation={formMethods.trigger}
            isEditMode={Boolean(
              selectedRecord?.variantObject.platformConfigurationId &&
                (row.original as WorkflowSchemaVariantObject).variantObject
                  .platformConfigurationId ===
                  selectedRecord?.variantObject.platformConfigurationId
            )}
            editDisabled={
              (row.original as WorkflowSchemaVariantObject).variantObject
                .platformConfigurationId !==
              selectedRecord?.variantObject.platformConfigurationId
            }
            selectedRecord={selectedRecord}
            selectedSchema={selectedSchema}
          />
        </FormProvider>
      )}
    />
  );
}

export default TableManager;
