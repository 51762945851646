import {
  ChangeEvent,
  useCallback,
  useEffect,
  useState,
  useMemo,
  useRef,
} from 'react';
import { Box, Grid, List, SelectChangeEvent, Tab, Tabs } from '@mui/material';
import {
  MessageActionType,
  MessageType,
} from '@revenue-solutions-inc/revxcoreui';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import Checkbox from '@revenue-solutions-inc/revxcoreui/material/controls/Checkbox';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import Input from '@revenue-solutions-inc/revxcoreui/material/controls/Input';
import Select from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import TabPanel from '@revenue-solutions-inc/revxcoreui/material/controls/TabPanel';
import TextArea from '@revenue-solutions-inc/revxcoreui/material/controls/TextArea';
import { ColumnDef, Column, Row } from '@tanstack/react-table';
import Loading from 'components/Loading';
import SideScrollBox from 'components/SideScrollBox';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { IHeader, setHeader } from 'redux/contentSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import { RoleStatus } from 'types/Status/roleStatus';
import {
  Keys,
  handleIsChecked,
  handleElementsSelected,
} from 'utils/checkboxes';
import {
  QueryPlatformOrModuleQueryVariables,
  useGetDoesRoleExistQuery,
  useGetRoleByIdQuery,
  useUsersByRoleQuery,
  useQueryPlatformOrModuleQuery,
  usePolicyGroupsByModuleIdQuery,
  CreateRoleInput,
  CloneRoleInput,
  UpdateRoleInput,
  useUpdateRoleMutation,
  useCreateRoleMutation,
  useCloneRoleMutation,
  RoleResponse,
  User,
  PolicyGroupResponse,
} from 'generated/graphql';
import { RoleFormDefault } from 'types/RoleFormDefault';
import extractMeaningfulMessage from 'utils/errorMessage';
import { useHasEdit } from 'hooks/useHasAccess';

enum ActionType {
  EDIT = 'edit',
  CLONE = 'clone',
  CREATE = 'create',
}

function ManageRole(): JSX.Element {
  const { t } = useTranslation();
  const [role, setRole] = useState<RoleResponse>(RoleFormDefault);
  const [policyGroupsSelected, setPolicyGroupsSelected] = useState<string[]>(
    []
  );
  const { roleId } = useParams() as { roleId: string };
  const location = useLocation();
  const [roleExists, setRoleExists] = useState<boolean>(false);
  const [moduleName, setModuleName] = useState<string>('');
  const [roleName, setRoleName] = useState<string>('');
  const [users, setUsers] = useState<User[] | undefined>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const selectFocus = useRef<HTMLSelectElement>(null);
  const inputFocus = useRef<HTMLInputElement>(null);
  const module = useAppSelector((state) => state.user.module);
  const [currentTab, setCurrentTab] = useState(0);
  const createRole = useCreateRoleMutation<CreateRoleInput>();
  const cloneRole = useCloneRoleMutation<CloneRoleInput>();
  const updateRole = useUpdateRoleMutation<UpdateRoleInput>();
  const minimumCharactersToDisplayError = 0;
  const minimumCharactersToRemoveError = 3;
  const maximumCharactersAllowedRoleName = 100;
  const maximumCharactersAllowed = 245;
  const [roleNameExistsFetchError, setRoleNameExistsFetchError] =
    useState<string>('');

  const action = useMemo(() => {
    const noMatch = -1;
    const pathname = location.pathname;
    const isEdit = pathname.search(ActionType.EDIT) != noMatch;
    const isClone = pathname.search(ActionType.CLONE) != noMatch;
    const isCreate = pathname.search(ActionType.CREATE) != noMatch;
    if (isEdit) return ActionType.EDIT;
    if (isClone) return ActionType.CLONE;
    if (isCreate) return ActionType.CREATE;
    return '';
  }, [location]);
  const accessEditRoleOOTB = useHasEdit(
    'manageRolesEditRole',
    'EditRoleEnableOOTBRecordUpdate'
  );
  const { data: moduleResult } = useQueryPlatformOrModuleQuery<{
    Modules: [QueryPlatformOrModuleQueryVariables];
  }>();
  const [filteredRows, setFilteredRows] = useState<PolicyGroupResponse[]>([]);
  const [isSeaching, setIsSeaching] = useState<{
    status: boolean;
    value: string;
  }>({
    status: false,
    value: '',
  });

  const {
    data: policyGroupsResult,
    isLoading: isLoadingPolicyGroupsResult,
    isError: isErrorPolicyGroupsResult,
  } = usePolicyGroupsByModuleIdQuery<{
    PolicyGroupsByModuleId: [PolicyGroupResponse];
  }>({
    moduleId: role.moduleId.toString(),
  });

  const { refetch: roleResultRefetch } = useGetRoleByIdQuery<{
    Role: RoleResponse;
  }>(
    {
      roleId: roleId,
    },
    {
      enabled: false,
      onSuccess: (roleResultData) => {
        if (roleResultData?.Role) {
          const tempRoleResult = roleResultData?.Role;
          const roleNametemp = tempRoleResult.roleName;
          setRoleName(roleNametemp);
          setRole({
            roleId: tempRoleResult.roleId,
            moduleId: tempRoleResult.moduleId,
            moduleName: tempRoleResult.moduleName,
            roleName:
              action === ActionType.CLONE
                ? `Clone of ${tempRoleResult.roleName}`
                : tempRoleResult.roleName,
            roleDescription: tempRoleResult.roleDescription,
            statusId:
              action === ActionType.CLONE
                ? RoleStatus.Inactive
                : tempRoleResult.statusId,
            authorizationPolicy: [],
            policyGroups: tempRoleResult.policyGroups,
            isOOTB: tempRoleResult.isOOTB,
            isRsiOnly: tempRoleResult.isOOTB,
          });
          setPolicyGroupsSelected(
            tempRoleResult.policyGroups.map(
              (policyGroups) => policyGroups.policyGroupId
            )
          );
          setModuleName(tempRoleResult.moduleName);
        }
      },
      onError: (errors) => {
        const messageDisplayError = extractMeaningfulMessage(
          errors,
          t('pages.manageReusableContent.networkError')
        );
        dispatch(
          addMessage({
            type: MessageType.Error,
            message: messageDisplayError,
            actionType: MessageActionType.None,
          })
        );
      },
    }
  );

  const { refetch: roleExistsResultRefetch } = useGetDoesRoleExistQuery<{
    DoesRoleExist: boolean;
  }>(
    {
      moduleId: role.moduleId,
      roleName: role.roleName.trim(),
    },
    {
      enabled: false,
      onSuccess: (data) => {
        if (ActionType.CREATE === action || ActionType.CLONE === action) {
          if (role.roleName.trim().length >= minimumCharactersToRemoveError) {
            setRoleExists(data.DoesRoleExist);
          }
        }

        if (ActionType.EDIT === action) {
          if (
            role.roleName.trim() !== roleName &&
            role.roleName.trim().length >= minimumCharactersToRemoveError
          ) {
            setRoleExists(data.DoesRoleExist);
          }
        }
      },
      onError: (errorData) => {
        const messageError = extractMeaningfulMessage(
          errorData,
          t('pages.manageReusableContent.networkError')
        );
        setRoleNameExistsFetchError(messageError);
      },
    }
  );

  const {
    isLoading: isLoadingusersData,
    isError: isErrorusersData,
    refetch: usersDataRefetch,
  } = useUsersByRoleQuery<{
    UsersByRole: [User];
  }>(
    {
      userId: roleId,
      isAssigned: true,
    },
    {
      enabled: !!roleId && ActionType.EDIT === action,
      onSuccess: (userData) => {
        if (userData?.UsersByRole) {
          const newUsers = userData.UsersByRole;
          setUsers(newUsers);
        }
      },
      onError: (userError) => {
        const message = extractMeaningfulMessage(
          userError,
          t('pages.manageReusableContent.networkError')
        );
        dispatch(
          addMessage({
            type: MessageType.Error,
            actionType: MessageActionType.None,
            message: message,
          })
        );
      },
    }
  );

  useEffect(() => {
    if (action === ActionType.CREATE || action === ActionType.CLONE) {
      if (
        !!role.roleName &&
        role.roleName.length >= minimumCharactersToRemoveError
      ) {
        roleExistsResultRefetch();
      }
    }
    if (action === ActionType.EDIT) {
      if (
        !!role.roleName &&
        role.roleName.length >= minimumCharactersToRemoveError &&
        role.roleName !== roleName
      ) {
        roleExistsResultRefetch();
      }
    }
  }, [
    action,
    role.roleName,
    roleExistsResultRefetch,
    roleName,
    roleResultRefetch,
  ]);

  useEffect(() => {
    if (!!roleId && ActionType.EDIT) {
      usersDataRefetch();
    }
    if (!!roleId && ActionType.CREATE !== action) {
      roleResultRefetch();
    }
  }, [action, roleId, roleResultRefetch, usersDataRefetch]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (action === ActionType.CREATE) selectFocus.current?.focus();
      else inputFocus.current?.focus();
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, [action]);

  const getTitle = useCallback(() => {
    let title = 'Manage Role';
    switch (action) {
      case ActionType.EDIT:
        title = 'pages.manageRole.editRole';
        break;
      case ActionType.CLONE:
        title = 'pages.manageRole.cloneRole';
        break;
      default:
        title = 'pages.manageRole.createRole';
        break;
    }
    return t(title);
  }, [action, t]);

  useEffect(() => {
    const headerData: IHeader = {
      pageTitle: getTitle(),
      previousPage: t('pages.roles.title'),
      route: 'manageRoles',
    };
    if (action === ActionType.EDIT) {
      headerData.icon = {
        icon: 'assignmentTurnedInIcon',
        props: { fill: 'black' },
        fontSize: 'large',
      };
      headerData.data = [
        {
          id: 'module-header',
          value: moduleName,
          label: t('pages.manageRole.module'),
          first: true,
        },
        {
          id: 'name-header',
          value: role.roleName ?? '',
          label: t('pages.manageRole.roleName'),
        },
        {
          id: 'description-header',
          label: t('pages.manageRole.roleDescription'),
          value: role.roleDescription ?? '',
        },
      ];
    }
    dispatch(setHeader(headerData));
  }, [role, dispatch, t, getTitle, roleId, action, moduleName]);

  useEffect(() => {
    if (moduleResult?.Modules && moduleResult.Modules.length > 0)
      setModuleName(moduleResult.Modules[0].name);
  }, [moduleResult?.Modules]);

  const handleBack = () => {
    navigate(`/${module}/manageRoles`);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  const handleSaveRole = () => {
    if (action === ActionType.EDIT) {
      updateRole.mutate(
        {
          role: {
            roleId: role.roleId,
            moduleId: role.moduleId,
            roleName: role.roleName,
            description: role.roleDescription,
            statusId: role.statusId,
            policyGroupsId: policyGroupsSelected.map(
              (systemPolicy) => systemPolicy
            ),
          },
        },
        {
          onSuccess: () => {
            dispatch(
              addMessage({
                message: t('pages.manageRole.successMessage'),
                type: MessageType.Success,
                actionType: MessageActionType.None,
              })
            );
            handleBack();
          },
          onError: () => {
            dispatch(
              addMessage({
                message: t('components.message.networkerror'),
                type: MessageType.Error,
                actionType: MessageActionType.None,
              })
            );
          },
        }
      );
    } else if (action === ActionType.CREATE) {
      setRole({ ...role, roleId: '' });
      createRole.mutate(
        {
          role: {
            moduleId: role.moduleId,
            roleName: role.roleName,
            description: role.roleDescription,
            statusId: role.statusId,
            policyGroupsId: policyGroupsSelected.map((policy) => {
              return policy;
            }),
          },
        },
        {
          onSuccess: () => {
            dispatch(
              addMessage({
                type: MessageType.Success,
                actionType: MessageActionType.None,
                message: t('pages.manageRole.successMessage'),
              })
            );
            handleBack();
          },
          onError: (e: Error[] | unknown) => {
            const onCreateDefaultError = t('components.message.networkerror');
            dispatch(
              addMessage({
                message: extractMeaningfulMessage(e, onCreateDefaultError),
                actionType: MessageActionType.None,
                type: MessageType.Error,
              })
            );
          },
        }
      );
    } else {
      setRole({ ...role, roleId: '' });
      cloneRole.mutate(
        {
          role: {
            moduleId: role.moduleId,
            roleName: role.roleName,
            description: role.roleDescription,
            statusId: role.statusId,
            policyGroupsId: policyGroupsSelected.map((policy) => {
              return policy;
            }),
            cloneBaseRoleId: roleId,
          },
        },
        {
          onSuccess: () => {
            dispatch(
              addMessage({
                type: MessageType.Success,
                actionType: MessageActionType.None,
                message: t('pages.manageRole.cloneSuccessMessage'),
              })
            );
            handleBack();
          },
          onError: (e: Error[] | unknown) => {
            const onCreateDefaultError = t('components.message.networkerror');
            dispatch(
              addMessage({
                message: extractMeaningfulMessage(e, onCreateDefaultError),
                actionType: MessageActionType.None,
                type: MessageType.Error,
              })
            );
          },
        }
      );
    }
  };

  const getFieldErrors = (
    types: string,
    fields: string
  ): {
    errorsField: boolean;
    errorMessages: string;
  } => {
    if (types === 'roleName') {
      if (!!fields && fields.length > maximumCharactersAllowedRoleName) {
        return {
          errorsField: true,
          errorMessages: t('pages.manageRole.roleNameFieldMaxLengthError'),
        };
      }

      if (roleExists && fields.length >= minimumCharactersToRemoveError) {
        return {
          errorsField: true,
          errorMessages: t('pages.manageRole.roleExistsMessage'),
        };
      } else if (roleExists && fields.length < minimumCharactersToRemoveError) {
        setRoleExists(false);
      }

      if (fields === '') {
        return {
          errorsField: true,
          errorMessages: '',
        };
      }

      if (roleNameExistsFetchError !== '') {
        return {
          errorsField: true,
          errorMessages: roleNameExistsFetchError,
        };
      }
    }

    if (fields.length > maximumCharactersAllowed) {
      return {
        errorsField: true,
        errorMessages: t('pages.manageRole.roleFieldMaxLengthError'),
      };
    }

    if (
      fields.length > minimumCharactersToDisplayError &&
      fields.length < minimumCharactersToRemoveError
    ) {
      return {
        errorsField: true,
        errorMessages: t('pages.manageRole.roleFieldError'),
      };
    }

    return { errorsField: false, errorMessages: '' };
  };

  const disableButton = (): boolean => {
    if (
      getFieldErrors('roleName', role.roleName).errorsField ||
      getFieldErrors('roleDescription', role.roleDescription).errorsField ||
      policyGroupsSelected.length < 1 ||
      (ActionType.EDIT === action && role.isOOTB && !accessEditRoleOOTB)
    ) {
      return true;
    } else return false;
  };

  const handleCheckAll = useMemo((): boolean => {
    if (isSeaching.status) {
      return filteredRows
        .map((pGroup) => pGroup.id)
        .every((item) => policyGroupsSelected.includes(item));
    } else {
      return (
        policyGroupsSelected.length ===
        policyGroupsResult?.PolicyGroupsByModuleId?.length
      );
    }
  }, [
    filteredRows,
    isSeaching.status,
    policyGroupsResult?.PolicyGroupsByModuleId?.length,
    policyGroupsSelected,
  ]);

  const policyColumns: ColumnDef<PolicyGroupResponse>[] = [
    {
      header: () => {
        return (
          <Checkbox
            {...{
              onKeyDown: (event) => {
                if (event.key === Keys.ENTER) {
                  if (
                    policyGroupsResult?.PolicyGroupsByModuleId?.length ===
                    policyGroupsSelected.length
                  ) {
                    setPolicyGroupsSelected([]);
                  } else {
                    const newPolicyGroups =
                      policyGroupsResult?.PolicyGroupsByModuleId?.map(
                        (p) => `${p.id}`
                      ) ?? [];
                    setPolicyGroupsSelected(newPolicyGroups);
                  }
                }
              },
            }}
            id={'selectAllCheck'}
            label={''}
            checked={handleCheckAll}
            onChange={(event) => {
              let newPolicies: string[] = [];
              if (event.target.checked) {
                if (isSeaching.status) {
                  newPolicies = filteredRows.map((pGroup) => pGroup.id);
                  if (policyGroupsSelected.length > 0) {
                    newPolicies = [...policyGroupsSelected, ...newPolicies];
                    newPolicies = newPolicies.filter(
                      (item, index) => newPolicies.indexOf(item) === index
                    );
                  }
                } else if (
                  policyGroupsResult?.PolicyGroupsByModuleId &&
                  policyGroupsResult.PolicyGroupsByModuleId.length > 0
                ) {
                  newPolicies = policyGroupsResult.PolicyGroupsByModuleId.map(
                    (pGroup) => pGroup.id
                  );
                }
              } else {
                if (isSeaching.status && policyGroupsSelected.length > 0) {
                  newPolicies = policyGroupsSelected.filter(
                    (item) =>
                      !filteredRows.map((pGroup) => pGroup.id).includes(item)
                  );
                }
              }
              setPolicyGroupsSelected(newPolicies);
            }}
            sx={{ ml: 1 }}
          />
        );
      },
      id: 'id',
      cell: ({ row }) => {
        return (
          <Checkbox
            {...{
              onKeyDown: (event) => {
                if (event.key === Keys.ENTER) {
                  const isChecked = handleIsChecked(
                    policyGroupsSelected,
                    `${row.original.id}`
                  );
                  const newPolicyGroupsSelected = handleElementsSelected(
                    [...policyGroupsSelected],
                    isChecked,
                    `${row.original.id}`
                  );
                  setPolicyGroupsSelected(newPolicyGroupsSelected);
                }
              },
            }}
            id={'policyChk'}
            checked={
              policyGroupsSelected.length > 0 &&
              policyGroupsSelected.findIndex(
                (policyData) => policyData === row.original.id
              ) > -1
            }
            label={''}
            onChange={(e) => {
              if (e.target.checked) {
                setPolicyGroupsSelected([
                  ...policyGroupsSelected,
                  row.original.id,
                ]);
              } else {
                const newPolicies = [...policyGroupsSelected].filter(
                  (policyDataId) => policyDataId !== row.original.id
                );
                setPolicyGroupsSelected(newPolicies);
              }
            }}
            sx={{ ml: 1 }}
          />
        );
      },
    },
    {
      id: 'policyGroupName',
      accessorKey: 'name',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.manageRole.policyGroupName')}
        />
      ),
      cell: ({ getValue }) => {
        return <Box sx={{ width: '15em' }}>{getValue() as string}</Box>;
      },
    },
    {
      id: 'policyGroupDescription',
      accessorKey: 'description',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.manageRole.policyGroupDescription')}
        />
      ),
      cell: ({ getValue }) => {
        return (
          <p style={{ width: '30em', wordBreak: 'break-word' }}>
            {getValue() as string}
          </p>
        );
      },
    },
    {
      id: 'technicalName',
      accessorKey: 'technicalName',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.manageRole.policyGroupTechnicalName')}
        />
      ),
      cell: ({ getValue }) => {
        return (
          <Box sx={{ width: '15em', wordBreak: 'break-word' }}>
            {getValue() as string}
          </Box>
        );
      },
    },
  ];

  const userColumns: ColumnDef<User>[] = [
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.manageRole.userName')} />
      ),
      accessorKey: 'name',
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.manageRole.userEmail')} />
      ),
      accessorKey: 'email',
    },
  ];

  const onSelectModule = (event: SelectChangeEvent<string | number>) => {
    const newSelectedModule = `${(event.target as HTMLSelectElement).value}`;
    if (newSelectedModule.length !== 0) {
      setPolicyGroupsSelected([]);
      setRole({
        ...role,
        policyGroups: [],
        moduleId: parseInt(newSelectedModule),
      });
      setModuleName(
        moduleResult?.Modules?.find((m) => m.moduleId == newSelectedModule)
          ?.name ?? ''
      );
    }
  };

  const getSeachingValue = useCallback((value: string) => {
    const valueLength = value.length > 0;
    setIsSeaching({ status: valueLength, value: value });
  }, []);

  const getFilteredRows = useCallback(
    (newFilteredRows: Row<PolicyGroupResponse>[]) => {
      if (isSeaching.status && isSeaching.value.length > 0) {
        const newOriginalRows: PolicyGroupResponse[] = newFilteredRows.map(
          (row) => row.original
        );
        setFilteredRows(newOriginalRows);
      }
    },
    [isSeaching]
  );

  return (
    <>
      <Grid container>
        <Grid
          item
          xs={4}
          minWidth="15rem"
          sx={{
            flexGrow: '1 !important',
            maxWidth: '100% !important',
          }}
        >
          <Grid item xs={10} mb={2}>
            <Select
              id="roleModule-createRole"
              label={t('pages.manageRole.module')}
              required
              inputProps={{
                'data-testid': 'roleModule-select',
                ref: selectFocus,
              }}
              options={
                moduleResult &&
                moduleResult.Modules &&
                Array.isArray(moduleResult.Modules)
                  ? moduleResult?.Modules?.map((m) => {
                      return { key: m.moduleId, desc: m.name };
                    })
                  : [{ key: '', desc: 'Select Module' }]
              }
              value={role.moduleId}
              onChange={onSelectModule}
              disabled={
                action === ActionType.EDIT || action === ActionType.CLONE
              }
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={10} mb={2}>
            <Input
              id="roleName-createRole"
              data-testid="roleName-input"
              label={t('pages.manageRole.roleName')}
              required
              inputProps={{
                'data-testid': 'roleName-input',
                ref: inputFocus,
                maxLength: 101,
              }}
              value={role?.roleName ?? ''}
              onChange={(event) => {
                setRole({ ...role, roleName: event.target.value });
              }}
              error={getFieldErrors('roleName', role.roleName).errorsField}
              helperText={
                getFieldErrors('roleName', role.roleName).errorMessages
              }
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={10} mb={2}>
            <TextArea
              id="roleDescription-createRole"
              label={t('pages.manageRole.roleDescription')}
              value={role?.roleDescription ?? ''}
              onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                setRole({ ...role, roleDescription: event.target.value })
              }
              error={
                getFieldErrors('roleDescription', role.roleDescription)
                  .errorsField
              }
              helperText={
                getFieldErrors('roleDescription', role.roleDescription)
                  .errorMessages
              }
              multiline
              sx={{ width: '100%' }}
              inputProps={{
                maxLength: 246,
              }}
            />
          </Grid>
          <Grid item xs={10} mb={2}>
            <List
              sx={{
                height: 400,
              }}
            >
              <SideScrollBox
                title={t('pages.manageRole.SelectedPolicyGroups')}
                options={
                  policyGroupsSelected && policyGroupsSelected.length > 0
                    ? policyGroupsSelected.map((policyId) => {
                        const name =
                          policyGroupsResult?.PolicyGroupsByModuleId?.find(
                            (p) => p.id === policyId
                          )?.name ?? policyId;
                        return { key: policyId, name: name };
                      })
                    : []
                }
                id="policy-box"
                scrollBoxValues={policyGroupsSelected as unknown as string[]}
                onChangeState={setPolicyGroupsSelected}
                width={'100%'}
              />
            </List>
          </Grid>
          <Grid item xs={10} mb={2} mt={2}>
            {action === ActionType.EDIT &&
              users &&
              role.statusId === RoleStatus.Inactive &&
              users.length > 0 && (
                <Box>{t('pages.manageRole.userWarning')}</Box>
              )}
            <Button
              onClick={() => handleSaveRole()}
              id="saveBtn-createRole"
              sx={{ mt: 1, mb: 1 }}
              disabled={disableButton()}
            >
              {action === ActionType.EDIT
                ? t('pages.manageRole.updateRole')
                : t('pages.manageRole.save')}
            </Button>
          </Grid>
        </Grid>
        <Grid
          item
          xs={8}
          sx={{ flexGrow: '1 !important', maxWidth: '100% !important' }}
        >
          <Box sx={{ borderColor: 'divider' }}>
            <Tabs
              value={currentTab}
              onChange={handleTabChange}
              aria-label="role Tabs"
            >
              <Tab
                label={t('pages.manageRole.assignedPolicyGroups')}
                sx={{ textTransform: 'capitalize', fontSize: '1rem' }}
              />
              {action === ActionType.EDIT && (
                <Tab
                  label={t('pages.manageRole.assignedUsers')}
                  sx={{ textTransform: 'capitalize', fontSize: '1rem' }}
                />
              )}
            </Tabs>
          </Box>
          <TabPanel selectedIndex={currentTab} index={0}>
            {isLoadingPolicyGroupsResult ? (
              <Loading />
            ) : (
              isErrorPolicyGroupsResult && (
                <p>{t('pages.manageRole.noPolicyGroupsForSelectedModule')}</p>
              )
            )}
            {role?.moduleId > 0 &&
              policyGroupsResult?.PolicyGroupsByModuleId && (
                <div data-testid="authorization-policies-data">
                  <DefaultDataTableNext
                    columns={policyColumns as Column<PolicyGroupResponse>[]}
                    data={policyGroupsResult?.PolicyGroupsByModuleId}
                    data-testid="authorization-policies-data"
                    getSeachingValue={getSeachingValue}
                    getFilteredRows={getFilteredRows}
                  />
                </div>
              )}
          </TabPanel>
          <TabPanel selectedIndex={currentTab} index={1}>
            {isLoadingusersData ? (
              <Loading />
            ) : (
              isErrorusersData && (
                <p>{t('pages.manageRole.noUsersForSelectedModule')}</p>
              )
            )}
            {users && (
              <DefaultDataTableNext
                columns={
                  userColumns as ColumnDef<Record<string, unknown>, unknown>[]
                }
                data={users}
              />
            )}
          </TabPanel>
        </Grid>
      </Grid>
    </>
  );
}

export default ManageRole;
