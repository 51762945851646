import { useState, useEffect, ReactNode } from 'react';
import {
  FormHelperText,
  FormLabel,
  Paper,
  SxProps,
  TextField,
  Box,
  Theme,
} from '@mui/material';
import AutocompleteMui from '@mui/material/Autocomplete';
import { SystemStyleObject } from '@mui/system';
import appTheme from '@revenue-solutions-inc/revxcoreui/material/themes/defaultTheme';

export interface SelectType {
  key: string;
  desc: string;
  selected?: boolean;
}

export interface SelectAutocompleteProps {
  label: string;
  value: string | null;
  options: SelectType[];
  disabled?: boolean;
  id: string;

  onChange: (value: string, reason?: string, details?: string) => void;
  sx?: SxProps<Theme>;
  errorStyle?: SxProps<Theme>;
  selectLabelStyle?:
    | SystemStyleObject<Theme>
    | ((appTheme: Theme) => SystemStyleObject<Theme>);
  error?: string;
  required?: boolean;
  size?: 'medium' | 'small' | undefined;
  filterOptions?: (x: string[]) => string[];
  onInputChange?: (event: React.SyntheticEvent, value: string) => void;
  onClear?: () => void;
}

const inputStyle = () => {
  return {
    backgroundColor: appTheme.palette.white.main,
    color: 'primary.main',
    '.Mui-disabled': {
      backgroundColor: 'grey6.main',
      '& .MuiOutlinedInput-notchedOutline': {
        color: 'secondary.dark',
      },
    },
    '& .Mui-focused': {
      '.MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${appTheme.palette.lightBlue1.main} !important`,
        boxShadow: `0px 0px 5px ${appTheme.palette.lightBlue1.main}`,
      },
    },
    '.MuiSvgIcon-root': {
      color: 'primary.main',
    },
    ':hover': {
      '.MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${appTheme.palette.black.main} `,
      },
    },
    '.MuiInputBase-input': {
      color: 'black.main',
    },
  };
};
const helperTextStyle = () => {
  return {
    color: appTheme.palette.primaryRed.main,
    fontWeight: appTheme.typography.fontWeightMedium,
    fontSize: '12px',
    marginTop: 0,
    marginLeft: 0,
  };
};
const labelStyle = () => {
  return {
    fontWeight: appTheme.typography.fontWeightMedium,
    color: 'primary.main',
    '& .MuiFormLabel-asterisk': {
      fontWeight: appTheme.typography.fontWeightMedium,
      color: appTheme.palette.primaryRed.dark,
    },
  };
};
const errorStateStyle = () => {
  return {
    backgroundColor: appTheme.palette.white.main,
    color: 'primary.main',
    '& .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${appTheme.palette.primaryRed.main}`,
    },
    '.Mui-disabled': {
      backgroundColor: 'grey6.main',
      '& .MuiOutlinedInput-notchedOutline': {
        color: 'secondary.dark',
      },
    },
    '& .Mui-focused': {
      '.MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${appTheme.palette.primaryRed.main} !important`,
        boxShadow: `0px 0px 5px ${appTheme.palette.lightBlue1.main}`,
      },
    },
    '.MuiSvgIcon-root': {
      color: 'primary.main',
    },
  };
};

function SearchAutocomplete({
  value,
  options,
  disabled,
  id,
  onChange,
  required,
  sx,
  error,
  errorStyle,
  selectLabelStyle,
  label,
  size = 'small',
  filterOptions,
  onClear,
}: SelectAutocompleteProps): JSX.Element {
  const [inputValue, setInputValue] = useState('');
  const [searchOptions, setSearchOptions] = useState<SelectType[]>(options);
  const [selectValue, setSelectValue] = useState(value);
  const [isEnterClicked, setIsEnterClicked] = useState<boolean>(false);
  const [errorText, setErrorText] = useState('');
  const handleOnChange = async (
    event: React.SyntheticEvent,
    newValue: string | SelectType | null,
    reason: string
  ) => {
    if (reason === 'clear') {
      onChange('', reason);
      if (onClear) {
        onClear();
      }
    }
    if (!isEnterClicked) {
      if (typeof newValue === 'string') {
        onChange(newValue, reason);
        await setSelectValue(newValue);
      }
    }
  };
  useEffect(() => {
    if (inputValue === '') {
      setSearchOptions(options);
      setSelectValue('');
      if (onClear) {
        onClear();
      }
    } else {
      const updatedOptions = options.filter((option) =>
        option.desc.toLowerCase().includes(inputValue.toLowerCase())
      );
      setSearchOptions(updatedOptions);
    }
  }, [inputValue, onClear, options]);
  useEffect(() => {
    setSelectValue(value);
  }, [value]);
  useEffect(() => {
    if (inputValue?.length) {
      if (searchOptions.length) {
        setErrorText('');
      } else {
        setErrorText('Please enter valid sink object');
      }
    } else {
      setErrorText('');
    }
  }, [inputValue, searchOptions, setErrorText]);
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <FormLabel
        disabled={disabled || !options.length}
        sx={
          !(disabled || !options.length) && selectLabelStyle
            ? [labelStyle, selectLabelStyle]
            : { labelStyle }
        }
        id={`${id}-label`}
        required={required}
      >
        {label}
      </FormLabel>
      <AutocompleteMui
        freeSolo={true}
        fullWidth
        id={id}
        size={size}
        value={selectValue}
        autoComplete
        filterSelectedOptions
        includeInputInList
        forcePopupIcon
        noOptionsText="No Sink Objects"
        onChange={handleOnChange}
        disabled={disabled || !options.length}
        options={searchOptions.map((item) => {
          return item.desc;
        })}
        data-testid="autocomplete-id"
        filterOptions={filterOptions}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        sx={
          error
            ? [
                errorStateStyle,
                ...(errorStyle
                  ? Array.isArray(errorStyle)
                    ? errorStyle
                    : [errorStyle]
                  : []),
              ]
            : [inputStyle, ...(sx ? (Array.isArray(sx) ? sx : [sx]) : [])]
        }
        PaperComponent={({ children }) => (
          <Paper
            sx={() => {
              return {
                color: 'primary.main',
                fontSize: '0.875rem',
                '.MuiAutocomplete-listbox': {
                  '.MuiAutocomplete-option.Mui-focused': {
                    backgroundColor: 'linkBlue.main',
                    color: 'white.main',
                  },
                  '.MuiAutocomplete-option[aria-selected="true"].Mui-focused': {
                    backgroundColor: 'linkBlue.main',
                    color: 'white.main',
                  },
                  '.MuiAutocomplete-option[aria-selected="true"]': {
                    backgroundColor: `${appTheme.palette.linkBlue.main} !important`,
                    color: 'white.main',
                  },
                },
              };
            }}
          >
            {children as ReactNode}
          </Paper>
        )}
        renderInput={(params) => (
          <TextField
            {...(params as object)}
            placeholder={
              required ? 'Required' : 'Click here to Search / Select Domain'
            }
            required={required}
            InputProps={{
              ...params.InputProps,
              onKeyDown: async (e) => {
                if (e.keyCode === 13) {
                  if (errorText.length) {
                    e.stopPropagation();
                  } else {
                    await setIsEnterClicked(true);
                    await setSelectValue(searchOptions[0].desc);
                    await onChange(searchOptions[0].desc);
                    setIsEnterClicked(false);
                  }
                }
              },
            }}
          />
        )}
      />
      {errorText.length ? (
        <FormHelperText sx={helperTextStyle}>{errorText}</FormHelperText>
      ) : null}
    </Box>
  );
}
export default SearchAutocomplete;
