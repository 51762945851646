import { Section } from 'types/forms';

export type EntitySectionNames =
  | 'names'
  | 'addresses'
  | 'identifiers'
  | 'emailAddresses'
  | 'phoneNumbers'
  | 'others';

export interface Batch {
  id?: number;
  depositId?: number;
  depositLabel?: string;
  batchStatus?: string;
  batchType?: string;
  paidDate?: Date | null;
  defaultTransactionEffectiveDate: Date | null;
  dateOpened?: Date | null;
  dateClosed?: Date | null;
  branchLocationId: string;
  label: string;
}

export interface BatchStatus {
  batchStatus: string;
  dateClosed: Date;
}

export interface CheckRegister {
  checkNumber: string;
  amount: number;
  identifierValue: string;
  remitterName: string;
}

export interface IdFormats {
  [key: string]: string | undefined | null;
}

export interface IdValidationRules {
  [key: string]: RegExp | undefined | null;
}

export interface IdMaxLengths {
  [key: string]: number | undefined | null;
}

export type NullableString = string | null | undefined;

export type Deposit = {
  id?: number;
  label: string;
  depositDate: Date | null;
  batchIds: number[];
  notes: string;
  depositStatus: string;
  depositAmount: number;
  depositType: string;
  batchesCount: number;
  source: string;
  depositAccount: string;
  documentCount: number;
  createdDate: Date;
  updatedDate: Date;
  createdBy: string;
  updatedBy: string;
  depositDateHasError: boolean;
};

export interface DepositSums {
  remittanceAmount: number;
}

export interface EntityStepsConfig {
  step: number;
  name: string;
  isRequired: boolean;
  showOptional: boolean;
}

export interface EntityRenderSection {
  step: number;
  section: Section;
}

export interface EntityForm {
  names?: EntityName[];
  addresses?: EntityAddress[];
  identifiers?: EntityIdentifier[];
  emailAddresses?: EntityEmailAddress[];
  phoneNumbers?: EntityPhoneNumber[];
  others?: EntityOther[];
}

export interface EntityAddress {
  AddressType: string;
  attention: string;
  cityId: string;
  countyId: string;
  countryId: string;
  postalCode: string;
  stateProvinceId: string;
  addressLine1: string;
  addressLine2?: string;
  isPrimary: string;
  isConfidential?: string;
  hasMailReturned?: string;
  commenceDate?: string | null;
  ceaseDate?: string | null;
}

export interface EntityEmailAddress {
  EmailAddressType: string;
  value: string;
  isPrimary: string;
  commenceDate?: string | null;
  ceaseDate?: string | null;
}

export interface EntityIdentifier {
  IdType: string;
  value: string;
  isPrimary: string;
  commenceDate?: string | null;
  ceaseDate?: string | null;
}

export interface EntityName {
  NameType: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  nameSuffix?: string;
  value?: string;
  isPrimary: string;
  isConfidential?: string;
  commenceDate?: string | null;
  ceaseDate?: string | null;
}

export interface EntityPhoneNumber {
  PhoneNumberType: string;
  value: string;
  isPrimary: string;
  commenceDate?: string | null;
  ceaseDate?: string | null;
}

export interface EntityOther {
  entityType: string;
  entityCreationSourceId: string;
  organizationType?: string | null;
  commenceDate?: string | null;
  ceaseDate?: string | null;
  isPrimary?: string;
}

export interface FiscalDate {
  fiscalDateId: string;
  beginDate: Date | null;
  endDate: Date | null;
  taxYear: string;
}

export interface RadioOptions {
  id: string;
  checked?: boolean;
  value: string | boolean;
  label?: string;
}

export type UserAndRoleId = {
  roleId: string;
  userId: string;
  name: string;
  email: string;
};

export type RoleUser = {
  userId: string;
  name: string;
  email: string;
};

const depositDefault: Deposit = {
  label: '',
  depositDate: new Date(),
  batchIds: [],
  notes: '',
  depositStatus: '',
  depositAmount: 0,
  depositType: 'Check21',
  batchesCount: 0,
  source: '',
  depositAccount: '',
  documentCount: 0,
  createdDate: new Date(),
  updatedDate: new Date(),
  createdBy: '',
  updatedBy: '',
  depositDateHasError: false,
};

const fiscalDateDefault: FiscalDate = {
  fiscalDateId: '0',
  beginDate: null,
  endDate: null,
  taxYear: '',
};

export { fiscalDateDefault, depositDefault };
