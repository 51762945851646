export enum WorkflowContextType {
  Entity = 1,
  Account = 2,
  Period = 3,
  TransactionGroup = 4,
  FinancialTransaction = 5,
  Asset = 6,
}

export type WorkflowContext = {
  WorkflowContextType: WorkflowContextType;
  ContextId: string;
};
