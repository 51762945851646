export const getTime = (date: Date | number) => {
  if (typeof date === 'number') {
    return date;
  }
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  const newDate = new Date(year, month, day);
  return newDate.getTime();
};
