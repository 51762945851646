import { Fragment } from 'react';
import { Tooltip, Stack, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import {
  SinkObject,
  Operation,
  Field,
} from '@revenue-solutions-inc/revxcoreui';

interface CatTypes {
  cat: string;
  id: number;
}

interface Properties {
  SinkObjects: SinkObject;
  Operations: Operation;
  SinkFields: Field;
}

export interface SinkObjectsProptiesProps {
  catInfo: CatTypes;
  properties: Properties;
  setCatInfo: (catino: CatTypes) => void;
}

function SinkObjectsPropties({
  catInfo,
  properties,
  setCatInfo,
}: SinkObjectsProptiesProps): JSX.Element {
  type TooltipCompProps = {
    val: string | number | boolean | undefined;
    type: string;
  };

  const headingStyles = {
    margin: 0,
    padding: 0,
    color: '#000',
    fontSize: '16px',
    marginBottom: '10px',
    marginTop: '5px',
    paddingBottom: '10px',
    paddingLeft: '0px',
    borderBottom: '1px solid #eeee',
    fontWeight: 'normal',
  };

  const TooltipComp = ({ val, type }: TooltipCompProps): JSX.Element => {
    return (
      <>
        <Tooltip title={<>{val}</>}>
          <span
            style={{
              fontSize: '0.875rem',
              display: 'inline-block',
              color: `${type === 'key' ? '#666' : '#000'}`,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              maxWidth: `${type === 'key' ? '140px' : '180px'}`,
              height: '20px',
              whiteSpace: 'nowrap',
              marginRight: `${type === 'key' ? '2px' : '0'}`,
              marginLeft: `${type === 'key' ? '0px' : '10px'}`,
            }}
          >
            {val}
          </span>
        </Tooltip>
      </>
    );
  };

  function SinkObjects() {
    if (properties && properties.SinkObjects) {
      const sinkObjects: SinkObject = properties.SinkObjects;

      const list = [];
      list.push({ key: 'SinkObjectId', value: sinkObjects.SinkObjectId });
      list.push({ key: 'SinkObjectName', value: sinkObjects.SinkObjectName });
      list.push({ key: 'Operations', value: sinkObjects.Operations.length });

      return (
        <div>
          <h6 style={headingStyles}>{sinkObjects.SinkObjectName}</h6>
          {list.map((item) => {
            return (
              <Fragment key={item.key.toString()}>
                <>
                  <div
                    style={{
                      borderBottom: '1px solid #eee',
                      padding: '2px 0px',
                      marginBottom: '4px',
                      display: 'flex',
                    }}
                  >
                    <Stack direction="row" spacing={1}>
                      <TooltipComp val={item.key} type={'key'} />
                    </Stack>
                    <Stack direction="row">:</Stack>
                    <Stack direction="row" spacing={1}>
                      <TooltipComp val={item.value} type={'val'} />
                    </Stack>
                  </div>
                </>
              </Fragment>
            );
          })}
        </div>
      );
    } else {
      return <div></div>;
    }
  }
  const Operations = () => {
    if (properties && properties.Operations) {
      const operations: Operation = properties.Operations;

      const list = [];
      list.push({ key: 'OperationID', value: operations.OperationID });
      list.push({ key: 'OperationName', value: operations.OperationName });
      list.push({ key: 'SinkFields', value: operations.SinkFields.length });

      return (
        <div>
          <h6 style={headingStyles}>{operations.OperationName}</h6>
          {list.map((item) => {
            return (
              <Fragment key={item.key.toString()}>
                <>
                  <div
                    style={{
                      borderBottom: '1px solid #eee',
                      padding: '2px 8px',
                      marginBottom: '4px',
                      display: 'flex',
                    }}
                  >
                    <Stack direction="row" spacing={1}>
                      <TooltipComp val={item.key} type={'key'} />
                    </Stack>
                    <Stack direction="row">:</Stack>
                    <Stack direction="row" spacing={1}>
                      <TooltipComp val={item.value} type={'val'} />
                    </Stack>
                  </div>
                </>
              </Fragment>
            );
          })}
        </div>
      );
    } else {
      return <div></div>;
    }
  };
  const SinkFields = () => {
    if (properties && properties.Operations) {
      const sinkFields = properties.SinkFields;

      const list = [];
      list.push({
        key: 'FieldProcessingOrder',
        value: sinkFields.BaseField.FieldProcessingOrder,
      });
      list.push({ key: 'IsPII', value: sinkFields.BaseField.IsPII });
      list.push({ key: 'Show', value: sinkFields.BaseField.Show });
      list.push({ key: 'Enable', value: sinkFields.BaseField.Enable });
      list.push({ key: 'IsFixed', value: sinkFields.BaseField.IsFixed });
      list.push({
        key: 'IsMultiselect',
        value: sinkFields.BaseField.IsMultiselect,
      });
      list.push({
        key: 'ApplyStandardBackfillRule',
        value: sinkFields.BaseField.ApplyStandardBackfillRule,
      });
      list.push({
        key: 'FieldFormat',
        value: sinkFields.BaseField.FieldFormat,
      });
      list.push({ key: 'PdfFieldID', value: sinkFields.BaseField.PdfFieldID });
      list.push({ key: 'FieldName', value: sinkFields.BaseField.FieldName });
      list.push({
        key: 'FieldNumber',
        value: sinkFields.BaseField.FieldNumber,
      });
      list.push({
        key: 'FieldValueType',
        value: sinkFields.BaseField.FieldValueType,
      });
      list.push({
        key: 'FieldRequired',
        value: sinkFields.BaseField.FieldRequired,
      });
      list.push({ key: 'Ignore', value: sinkFields.BaseField.Ignore });
      list.push({ key: 'Rules', value: sinkFields.BaseField.Rules.length });
      list.push({
        key: 'FieldDisplayName',
        value: sinkFields.BaseField.FieldDisplayName,
      });
      list.push({
        key: 'PossibleValues',
        value: sinkFields.BaseField.PossibleValues.length,
      });
      list.push({
        key: 'GlobalRuleNames',
        value: sinkFields.BaseField.GlobalRuleNames.length,
      });
      list.push({
        key: 'LastExecutionRuleChain',
        value: sinkFields.BaseField.LastExecutionRuleChain.length,
      });
      list.push({
        key: 'CustomAttributes',
        value: sinkFields.BaseField.CustomAttributes.length,
      });
      list.push({ key: 'FieldID', value: sinkFields.BaseField.FieldID });
      list.push({
        key: 'DataMaps',
        value:
          sinkFields.BaseField.DataMaps && sinkFields.BaseField.DataMaps.length
            ? sinkFields.BaseField.DataMaps.length
            : 0,
      });
      list.push({ key: 'LineNumber', value: sinkFields.BaseField.LineNumber });
      list.push({
        key: 'RequiredField',
        value: sinkFields.BaseField.RequiredField,
      });
      return (
        <div>
          <h6 style={headingStyles}>{sinkFields.BaseField.FieldName}</h6>
          {list.map((item) => {
            return (
              <Fragment key={item.key.toString()}>
                <>
                  <div
                    style={{
                      borderBottom: '1px solid #eee',
                      padding: '2px 8px',
                      marginBottom: '4px',
                      display: 'flex',
                    }}
                  >
                    <Stack direction="row" spacing={1}>
                      <TooltipComp val={item.key} type={'key'} />
                    </Stack>
                    <Stack direction="row">:</Stack>
                    <Stack direction="row" spacing={1}>
                      <TooltipComp val={item.value} type={'val'} />
                    </Stack>
                  </div>
                </>
              </Fragment>
            );
          })}
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  return (
    <div>
      {catInfo.cat.length ? (
        <Box
          onClick={() => setCatInfo({ cat: '', id: 0 })}
          sx={{
            float: 'right',
            cursor: 'pointer',
            color: 'red',
            display: 'flex',
            alignItems: 'center',
            marginRight: '10px',
          }}
          fontSize="medium"
        >
          Clear
          <CloseIcon
            sx={{
              float: 'right',
              cursor: 'pointer',
              color: 'red',
              fontSize: '16px',
              marginLeft: '4px',
            }}
          />
        </Box>
      ) : null}
      {catInfo?.cat == 'SinkObjects' && <SinkObjects />}
      {catInfo?.cat == 'Operations' && <Operations />}
      {catInfo?.cat == 'SinkFields' && <SinkFields />}
    </div>
  );
}

export default SinkObjectsPropties;
