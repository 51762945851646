import { IdFormats } from 'types/entities';

export function formatIdentifier(
  value: string,
  idFormats: IdFormats,
  selectedIdType: string | undefined | null
) {
  if (!value || !idFormats || !selectedIdType || selectedIdType === '')
    return value;

  const idFormat = idFormats[selectedIdType];
  if (!idFormat) return value;

  let formattedValue = value.replace(/[^\w]|_/g, '');
  const regex = new RegExp(idFormat, 'g');
  const match = regex.exec(formattedValue);
  if (match) {
    match.shift();
    formattedValue = match.join('-');
  }
  return formattedValue;
}
