import {
  Card,
  CardContent,
  CardHeader,
} from '@revenue-solutions-inc/revxcoreui';

interface Props {
  title: string;
  avatar: JSX.Element;
  action?: JSX.Element;
  children?: JSX.Element;
}

function DataCard({ title, avatar, action, children }: Props): JSX.Element {
  return (
    <Card>
      <CardHeader
        title={title}
        avatar={avatar}
        action={action}
        sx={{
          display: 'flex',
          '.MuiCardHeader-title': {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          },
          '.MuiCardHeader-content': {
            overflow: 'hidden',
          },
        }}
      />
      <CardContent>{children}</CardContent>
    </Card>
  );
}

export default DataCard;
