import { useEffect, useState } from 'react';

import { Grid, SelectChangeEvent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  useGetFormByFormNameQuery,
  ContextInput,
  LogiXgroupInput,
  useGetWorkflowTypeAndSubTypeQuery,
  useGetWorkflowAttributesQuery,
} from 'generated/graphql';
import {
  Input,
  RootAttributes,
  Select,
  Variants,
  WorkflowTypesAndSubTypes,
} from '@revenue-solutions-inc/revxcoreui';
import { SelectType } from '@revenue-solutions-inc/revxcoreui/material/controls/SelectAutocomplete/SelectAutocomplete';
import Loading from 'components/Loading';

export interface ErrorTypeProps {
  error: boolean;
  message: string;
}
interface WorkflowProps {
  code: string;
  id: number;
}
interface ErrorsProps {
  name: ErrorTypeProps;
  version: ErrorTypeProps;
  forWF: ErrorTypeProps;
  type: ErrorTypeProps;
  subType: ErrorTypeProps;
}

interface Props {
  LogixGroup: LogiXgroupInput | null;
  setLogixGroup: React.Dispatch<React.SetStateAction<LogiXgroupInput | null>>;
  setPipelineDetailsValid: React.Dispatch<React.SetStateAction<boolean>>;
  setWorkFlowAttributes: React.Dispatch<
    React.SetStateAction<RootAttributes[] | []>
  >;
  forWF: string;
  setForWF: React.Dispatch<React.SetStateAction<string>>;
  type: string;
  setType: React.Dispatch<React.SetStateAction<string>>;
  subType: string;
  setSubType: React.Dispatch<React.SetStateAction<string>>;
  typeObj: WorkflowProps | null;
  setTypeObj: React.Dispatch<React.SetStateAction<WorkflowProps | null>>;
  subTypeObj: WorkflowProps | null;
  setSubTypeObj: React.Dispatch<React.SetStateAction<WorkflowProps | null>>;
  formCategory: string;
}

function FormDetails({
  LogixGroup,
  setLogixGroup,
  setPipelineDetailsValid,
  setWorkFlowAttributes,
  forWF,
  setForWF,
  type,
  setType,
  subType,
  setSubType,
  typeObj,
  setTypeObj,
  subTypeObj,
  setSubTypeObj,
  formCategory,
}: Props): JSX.Element {
  const [validName, setValidName] = useState<boolean>(false);
  const [validWF, setValidWF] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const { t } = useTranslation();
  const [enabledQuery, setEnabledQuery] = useState<boolean>(false);
  const { data } = useGetFormByFormNameQuery(
    {
      formName: name,
    },
    { enabled: enabledQuery }
  );

  const [errors, setErrors] = useState<ErrorsProps>({
    name: {
      error: false,
      message: '',
    },
    version: {
      error: false,
      message: '',
    },
    forWF: {
      error: false,
      message: '',
    },
    type: {
      error: false,
      message: '',
    },
    subType: {
      error: false,
      message: '',
    },
  });

  useEffect(() => {
    if (LogixGroup && LogixGroup !== undefined) {
      setName(LogixGroup?.Contexts?.[0].ContextName ?? '');
    }
  }, [LogixGroup]);

  useEffect(() => {
    if (name.length >= 3) {
      setEnabledQuery(true);
    }
  }, [name]);

  useEffect(() => {
    if (data !== undefined && data?.getFormByName?.length > 0) {
      setErrors((prev) => ({
        ...prev,
        name: {
          error: true,
          message: t('pages.forms.formNameExists'),
        },
      }));
    } else if (data !== undefined && data?.getFormByName?.length === 0) {
      setValidName(true);
      setErrors((prev) => ({
        ...prev,
        name: {
          error: false,
          message: '',
        },
      }));
    }
  }, [data, setValidName, setErrors, t]);

  const validate = () => {
    const formNaeAux = name.replace(/  +/g, ' ').trim();
    if (formNaeAux.length === 0) {
      setErrors((prev) => ({
        ...prev,
        name: { error: true, message: t('pages.forms.fieldRequired') },
      }));
    } else {
      setEnabledQuery(true);
      const contexts: ContextInput[] = [];
      const newContext: ContextInput = {
        BusinessSections: [],
        ContextName: formNaeAux,
        GlobalRules: [],
        ProcessingOrder: 1,
        Actions: [],
      };
      contexts.push(newContext);
      setLogixGroup({
        ...LogixGroup,
        Contexts: contexts,
      });
      setName(formNaeAux);
    }
  };

  const [workFlowTypesAndSubTypes, setWorkFlowTypesAndSubTypes] = useState<
    WorkflowTypesAndSubTypes[] | []
  >([]);
  const { data: workflowTypesAndSubTypesQuery } =
    useGetWorkflowTypeAndSubTypeQuery();
  const { data: workflowAttributesQuery, isFetching } =
    useGetWorkflowAttributesQuery(
      {
        typeCode: typeObj?.code,
        subTypeCode: subTypeObj?.code,
      },
      {
        enabled: typeObj !== null && subTypeObj !== null,
      }
    );
  const typeOptions: SelectType[] = workFlowTypesAndSubTypes.map((it) => ({
    key: it.id.toString(),
    desc: it.code,
  }));
  const [subTypesOptions, setSubTypesOptions] = useState<SelectType[] | []>([]);
  const [variants, setVariants] = useState<Variants[] | []>([]);

  const handleGroupSelect = (event: SelectChangeEvent<string | number>) => {
    setForWF(String(event.target.value));
    if (event.target.value === 'No') {
      setType('');
      setTypeObj(null);
      setSubType('');
      setSubTypeObj(null);
      setErrors((prev) => ({
        ...prev,
        type: { error: false, message: '' },
        subType: { error: false, message: '' },
      }));
      setValidWF(true);
    } else {
      setErrors((prev) => ({
        ...prev,
        type: { error: true, message: t('pages.forms.fieldRequired') },
        subType: { error: true, message: t('pages.forms.fieldRequired') },
      }));
      setValidWF(false);
    }
  };

  useEffect(() => {
    if (
      workflowTypesAndSubTypesQuery &&
      workflowTypesAndSubTypesQuery.GetWorkflowTypeAndSubType
    ) {
      setWorkFlowTypesAndSubTypes(
        workflowTypesAndSubTypesQuery.GetWorkflowTypeAndSubType as WorkflowTypesAndSubTypes[]
      );
    }
  }, [workflowTypesAndSubTypesQuery]);
  useEffect(() => {
    if (type) {
      const wType = workFlowTypesAndSubTypes.find(
        (it) => it.id === parseInt(type)
      );
      if (wType) {
        setTypeObj({
          id: wType.id,
          code: wType.code,
        });
        //TODO get the variant(subtypes)
        setSubTypesOptions(
          wType?.variants.map((it) => ({
            key: it.id.toString(),
            desc: it.code,
          }))
        );
        setVariants(wType?.variants as Variants[]);
      } else {
        setTypeObj(null);
      }
    } else {
      setTypeObj(null);
      setSubTypesOptions([]);
    }
    if (subType) {
      const wSType = variants.find((it) => it.id === parseInt(subType));
      if (wSType) {
        setSubTypeObj({
          id: wSType.id,
          code: wSType.code,
        });
      } else {
        //setSubTypeobj(null);
      }
    } else {
      setSubTypeObj(null);
    }
    if (type && subType) {
      setValidWF(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, workFlowTypesAndSubTypes, subType, variants]);
  useEffect(() => {
    if (workflowAttributesQuery) {
      const workflowAttributes: RootAttributes[] = [];
      workflowAttributesQuery.GetWorkflowAttributes?.forEach((elem) => {
        if (elem.customAttributes) {
          workflowAttributes.push(
            ...(elem.customAttributes as RootAttributes[])
          );
        }
      });
      setWorkFlowAttributes(workflowAttributes);
    } else {
      setWorkFlowAttributes([]);
    }
  }, [workflowAttributesQuery, setWorkFlowAttributes]);

  useEffect(() => {
    if (
      validName &&
      (validWF || formCategory === 'Taxpayer') &&
      !(
        errors.name.error &&
        errors.version.error &&
        errors.forWF.error &&
        errors.type.error &&
        errors.subType.error
      )
    ) {
      setPipelineDetailsValid(true);
    } else {
      setPipelineDetailsValid(false);
    }
  }, [errors, setPipelineDetailsValid, validName, validWF, formCategory]);

  return (
    <>
      {isFetching && <Loading />}
      <Grid container>
        <Grid item xs={12} mb={3}>
          <Input
            id="formName"
            label={t('pages.forms.name')}
            required
            error={errors.name.error}
            value={name}
            onChange={(e: SelectChangeEvent<string | number>) => {
              setName(e.target.value as string);
            }}
            onBlur={validate}
            helperText={errors.name.message}
            inputProps={{ 'data-testid': 'form-name' }}
          />
        </Grid>
        <Grid item xs={12} mb={3}>
          <Input
            id="version"
            label={t('pages.forms.version')}
            required={true}
            error={errors.version.error}
            value="1"
            helperText={errors.version.message}
            disabled={true}
            inputProps={{ 'data-testid': 'version' }}
          />
        </Grid>
        {formCategory === 'Process' && (
          <Grid item xs={12} mb={3}>
            <Select
              label={t('pages.forms.forWorkflow')}
              id="for-workflow-select"
              value={forWF}
              onChange={handleGroupSelect}
              options={[
                { desc: 'Yes', key: 'Yes' },
                { desc: 'No', key: 'No' },
              ]}
            />
          </Grid>
        )}
        {forWF === 'Yes' && (
          <>
            <Grid item xs={12} mb={3}>
              <Select
                label="Type"
                id="for-workflow-select"
                value={type}
                onChange={(event: SelectChangeEvent<string | number>) =>
                  setType(String(event.target.value))
                }
                options={typeOptions}
              />
            </Grid>
            <Grid item xs={12} mb={3}>
              <Select
                label="Subtype"
                id="for-workflow-select"
                value={subType}
                onChange={(event: SelectChangeEvent<string | number>) =>
                  setSubType(String(event.target.value))
                }
                options={subTypesOptions}
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}

export default FormDetails;
