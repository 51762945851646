import { useState, useEffect, useMemo } from 'react';
import {
  DatePicker,
  Input,
  Select,
  TextArea,
  Checkbox,
} from '@revenue-solutions-inc/revxcoreui';
import {
  useGetWorkflowStepTypesQuery,
  useGetWorkflowCloseReasonsQuery,
} from 'generated/graphql';
import { Attribute } from 'types/graphTypes';
import DatasourceSelect from 'components/DatasourceSelect';
import { UserLookupAutocomplete } from 'components/UserLookupAutocomplete';
import { useTranslation } from 'react-i18next';
import { getEmailsFromText } from 'utils/string-util';
import { SelectType } from '@revenue-solutions-inc/revxcoreui/material/controls/Select';

interface Props {
  attribute: Attribute;
  variantId: string;
  disabled?: boolean;
  required?: boolean;
  error?: boolean;
  helperText?: string;
  posibleValues?: { [name: string]: string[] };
  handleChange: (
    value: string | number | Date,
    group: string,
    name: string
  ) => void;
  group: 'VariantDetails' | 'CustomAttributes';
  isDisabled?: boolean;
}

export function StepsAttribute({
  attribute,
  variantId,
  handleChange,
  required,
  helperText,
  group,
}: Props) {
  const { data } = useGetWorkflowStepTypesQuery({ variantId });

  const options = data?.GetWorkflowStepTypes.map((option) => ({
    desc: option.code,
    key: option.code,
    selected: option.code === attribute.attributeValue,
  }));
  return (
    <Select
      id={attribute.attributeName}
      label={attribute.attributeDisplayName}
      onChange={(e) =>
        handleChange(e.target.value, group, attribute.attributeName)
      }
      options={options ?? []}
      value={attribute.attributeValue}
      required={required}
      error={helperText}
    />
  );
}

export function CloseReasonAttribute({
  attribute,
  variantId,
  handleChange,
  required,
  helperText,
  group,
}: Props) {
  const { data } = useGetWorkflowCloseReasonsQuery({ variantId });

  const options = data?.GetWorkflowCloseReasons.map((option) => ({
    desc: option.closingReason,
    key: option.closingReason,
    selected: option.closingReason === attribute.attributeValue,
  }));

  return (
    <Select
      id={attribute.attributeName}
      label={attribute.attributeDisplayName}
      onChange={(e) =>
        handleChange(e.target.value, group, attribute.attributeName)
      }
      options={options ?? []}
      value={attribute.attributeValue}
      required={required}
      error={helperText}
    />
  );
}

function AttributeDetails({
  attribute,
  variantId,
  handleChange,
  required,
  helperText,
  group,
  posibleValues,
  isDisabled,
}: Props) {
  const { t } = useTranslation();
  const isDate =
    attribute.attributeType && attribute.attributeType === 'DateTime';
  const [value, setValue] = useState<Date | null>(null);

  const datasource = useMemo(() => {
    return attribute?.dataSource ?? '';
  }, [attribute]);

  useEffect(() => {
    const val =
      isDate && attribute.attributeValue
        ? new Date(attribute.attributeValue)
        : null;
    setValue(val);
  }, [isDate, attribute.attributeValue]);

  if (attribute.attributeName === 'Steps') {
    return (
      <StepsAttribute
        attribute={attribute}
        variantId={variantId}
        handleChange={handleChange}
        group={group}
        required={required}
        helperText={helperText}
      />
    );
  }
  if (attribute.attributeName === 'CloseReason') {
    return (
      <CloseReasonAttribute
        attribute={attribute}
        variantId={variantId}
        handleChange={handleChange}
        group={group}
        required={required}
        helperText={helperText}
      />
    );
  }
  if (isDate) {
    const handleDateChange = (date: Date | null) => {
      handleChange(date || '', group, attribute.attributeName);
      setValue(date);
    };

    return (
      <DatePicker
        id={attribute.attributeName}
        label={attribute.attributeDisplayName}
        value={value}
        handleChange={handleDateChange}
        required={required}
      />
    );
  }
  if (
    attribute.attributeName === 'OwnerName' ||
    attribute.attributeName === 'VariantOwnerName'
  ) {
    const handleOwnerNameChange = (owner: string | undefined): void => {
      handleChange(
        getEmailsFromText(owner ?? '')?.pop() ?? '',
        group,
        attribute.attributeName
      );
    };
    return (
      <UserLookupAutocomplete
        setUser={handleOwnerNameChange}
        initialValue={attribute.attributeValue}
        labelTitle={t('components.userLookupAutocomplete.ownerNameLabel')}
      />
    );
  }
  if (attribute.attributeType === 'ReferenceData') {
    return (
      <DatasourceSelect
        maxWidth
        disabled={isDisabled}
        id={`${attribute.attributeName}`}
        datasource={datasource}
        label={attribute.attributeDisplayName}
        fetchLayoutInfo={(e) => {
          handleChange(e.target.value, group, attribute.attributeName);
        }}
        value={attribute.attributeValue}
        isPlatform={true}
      />
    );
  }
  if (attribute.attributeType === 'Dropdown') {
    return (
      <Select
        disabled={isDisabled}
        id={`${attribute.attributeName}`}
        label={attribute.attributeDisplayName}
        sx={{ minWidth: 200 }}
        options={
          (posibleValues && posibleValues[attribute.attributeName]
            ? posibleValues[attribute.attributeName].map((val) => ({
                key: val,
                desc: val,
              }))
            : []) as unknown as SelectType[]
        }
        value={attribute.attributeValue}
        onChange={(e) =>
          handleChange(e.target.value, group, attribute.attributeName)
        }
      />
    );
  }
  if (attribute.attributeName === 'NotesMessage') {
    return (
      <TextArea
        id="note-message"
        label={attribute.attributeDisplayName}
        required
        value={attribute.attributeValue}
        multiline
        onChange={(e) =>
          handleChange(e.target.value, group, attribute.attributeName)
        }
        sx={{ minWidth: '470px' }}
      />
    );
  }
  if (attribute.attributeType === 'boolean') {
    const handleCheckboxChange = (checked: boolean) => {
      // server expects strings not booleans
      const stringValue = checked ? checked.toString() : '';
      handleChange(stringValue, group, attribute.attributeName);
    };
    return (
      <Checkbox
        onChange={(e) => {
          handleCheckboxChange(e.target.checked);
        }}
        id={`${attribute.attributeName} / ${attribute.attributeDisplayName}`}
        label={attribute.attributeDisplayName}
        checked={attribute.attributeValue ? true : false}
        value={attribute.attributeValue}
      />
    );
  }

  return (
    <Input
      id={attribute.attributeName}
      label={attribute.attributeDisplayName}
      error={required && attribute.attributeValue === ''}
      value={attribute.attributeValue}
      onChange={(e) =>
        handleChange(e.target.value, group, attribute.attributeName)
      }
      required={required}
      helperText={attribute.attributeValue === '' ? helperText : ''}
      disabled={isDisabled}
    />
  );
}
export default AttributeDetails;
