import { GetAllRelationshipsQuery, Relationships } from 'generated/graphql';
import {
  Relationships as RelationshipsList,
  RelationshipForm,
  RelationshipRequest,
} from 'types/relationships';
import { toStringDate } from 'utils/date-util';

export enum LinkTypes {
  EntityToEntity = 0,
  EntityToAsset = 1,
  AssetToEntity = 2,
  AssetToAsset = 3,
  EntityToAccount = 4,
}

export enum SourceType {
  Entity = 0,
  Asset = 1,
  Account = 2,
}

enum RelationshipConfigType {
  EntityToEntity = 'EntityToEntityRelationshipTypes',
  EntityToAsset = 'EntityToAssetRelationshipTypes',
  AssetToEntity = 'AssetToEntityRelationshipTypes',
  AssetToAsset = 'AssetToAssetRelationshipTypes',
  EntityToAccount = 'EntityToAccountRelationshipTypes',
}

export const getDestinationType = (linkType: number): string => {
  if (
    linkType === LinkTypes.EntityToEntity ||
    linkType === LinkTypes.AssetToEntity
  ) {
    return 'entity';
  } else if (
    linkType === LinkTypes.EntityToAsset ||
    linkType === LinkTypes.AssetToAsset
  ) {
    return 'asset';
  }
  return 'account';
};

export const getSourceType = (linkType: number): string => {
  if (
    linkType === LinkTypes.EntityToEntity ||
    linkType === LinkTypes.EntityToAccount ||
    linkType === LinkTypes.EntityToAsset
  ) {
    return 'entity';
  } else if (
    linkType === LinkTypes.AssetToEntity ||
    linkType === LinkTypes.AssetToAsset
  ) {
    return 'asset';
  }
  return 'account';
};

export const getRelationshipConfigType = (type: number): string => {
  if (type === 0) return RelationshipConfigType.EntityToEntity;
  else if (type === 1) return RelationshipConfigType.EntityToAsset;
  else if (type === 2) return RelationshipConfigType.AssetToEntity;
  else if (type === 3) return RelationshipConfigType.AssetToAsset;
  return RelationshipConfigType.EntityToAccount;
};

export const getLinkType = (source: number, destination: number): number => {
  if (source === 0) {
    if (destination === 0) return LinkTypes.EntityToEntity;
    else if (destination === 1) return LinkTypes.EntityToAsset;
    return LinkTypes.EntityToAccount;
  } else {
    if (destination === 0) return LinkTypes.AssetToEntity;
    return LinkTypes.AssetToAsset;
  }
};

/**
 * Returns a list of all the relationships of an Entity / Asset
 * @param relationshipsData Relationships data
 */
export const getRelationshipList = (
  relationshipsData: GetAllRelationshipsQuery
): RelationshipsList[] => {
  const sources = relationshipsData.GetAllRelationships.sourceRelationships;
  if (sources && sources.length > 0) {
    const tempRelationships: RelationshipsList[] = [];
    sources.forEach((source) => {
      const relationship: RelationshipsList = {
        id: source.id ?? '',
        linkType: source.linkType ?? 0,
        platformId: source.platformConfigurationId ?? '',
        sourceId: source.sourceInfoId ?? '',
        destination: source.destinationInfoId ?? '',
        destinationName: source.destinationInfoId ?? '',
        type: source.linkType ?? 0,
        relationshipType: source.relationshipType ?? '',
        commenceDate: source.commenceDate ?? '',
        ceaseDate: source.ceaseDate === undefined ? null : source.ceaseDate,
      };
      tempRelationships.push(relationship);
    });
    return tempRelationships;
  }
  return [];
};

export const buildRelationshipRequest = (
  data: RelationshipForm,
  platformConfigId: string,
  linkType: number,
  sourceId: string,
  destinationId: string,
  relationshipId?: string | undefined
): Relationships => {
  const relationshipRequest: Relationships = { relationships: [] };
  const relationshipData: RelationshipRequest = {};
  relationshipData.id = relationshipId;
  relationshipData.linkType = linkType;
  relationshipData.platformConfigurationId = platformConfigId;
  relationshipData.sourceInfoId = sourceId;
  relationshipData.destinationInfoId = destinationId;
  relationshipData.relationshipType = data.relationships?.[0].relationshipType;
  relationshipData.status = 'Ok';
  relationshipData.commenceDate = toStringDate(
    data.relationships?.[0].commenceDate
  );
  if (data.relationships?.[0].ceaseDate !== null) {
    if (String(data.relationships?.[0].ceaseDate) === 'Invalid Date') {
      relationshipData.ceaseDate = null;
    } else
      relationshipData.ceaseDate = toStringDate(
        data.relationships?.[0].ceaseDate
      );
  } else relationshipData.ceaseDate = data.relationships?.[0].ceaseDate;
  relationshipData.correlationId = '';
  relationshipData.group = [];
  relationshipRequest.relationships?.push(relationshipData);
  return relationshipRequest;
};
