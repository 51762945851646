import { useEffect } from 'react';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Address from 'components/addresses/Address';
import Contact from 'components/contacts/Contact';
import { useTranslation } from 'react-i18next';
import { ContactTypes } from 'types/constants';
import {
  Address as AddressType,
  addressDefault,
  Contact as ContactType,
  contactDefault,
} from 'types/contact';
import { TaxingAuthority } from 'types/taxingAuthority/entities';
interface Props {
  taxingAuthority: TaxingAuthority;
  setTaxingAuthority: (arg1: TaxingAuthority) => void;
  setTaxAddressValid: (valid: boolean) => void;
}

function TaxingAuthorityAddress({
  taxingAuthority,
  setTaxingAuthority,
  setTaxAddressValid,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const handleAddressChange = (newAddress: AddressType) => {
    setTaxingAuthority({ ...taxingAuthority, addresses: [newAddress] });
  };

  const handleEmailChange = (email: ContactType) => {
    setTaxingAuthority({ ...taxingAuthority, emailAddresses: [email] });
  };

  const handlePhoneChange = (phone: ContactType) => {
    setTaxingAuthority({ ...taxingAuthority, phoneNumbers: [phone] });
  };

  const validateAddress = () => {
    let isValid = true;

    if (taxingAuthority?.addresses) {
      // Assuming 1st address is the primary address
      const addr = taxingAuthority.addresses[0];
      isValid =
        addr.street !== '' &&
        addr.city !== '' &&
        addr.postalCode !== '' &&
        addr.stateProvince !== '' &&
        addr.countryCode !== '';
    } else {
      isValid = false;
    }
    return isValid;
  };

  useEffect(() => {
    const isValid = validateAddress();
    setTaxAddressValid(isValid);
  });

  return (
    <>
      <Grid item xs={2}>
        <Typography variant="h2">
          {t('pages.manageTaxingAuthority.address')}
        </Typography>
        <Address
          required
          address={
            taxingAuthority.addresses && taxingAuthority.addresses.length
              ? taxingAuthority.addresses[0]
              : addressDefault
          }
          onChange={handleAddressChange}
        />
      </Grid>
      <Grid item xs={6}>
        <Contact
          contactType={ContactTypes.Email}
          contact={
            taxingAuthority.emailAddresses
              ? taxingAuthority.emailAddresses[0]
              : contactDefault
          }
          handleChange={handleEmailChange}
        />
      </Grid>
      <Grid item xs={6}>
        <Contact
          contactType={ContactTypes.Phone}
          contact={
            taxingAuthority.phoneNumbers
              ? taxingAuthority.phoneNumbers[0]
              : contactDefault
          }
          handleChange={handlePhoneChange}
        />
      </Grid>
      <Divider />
    </>
  );
}

export default TaxingAuthorityAddress;
