import {
  useContext,
  useState,
  SetStateAction,
  useEffect,
  useCallback,
} from 'react';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Box, IconButton, Stack } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import {
  Button,
  MessageActionType,
  MessageType,
} from '@revenue-solutions-inc/revxcoreui';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import {
  ColumnDef,
  ColumnDefTemplate,
  CellContext,
} from '@tanstack/react-table';
import { ManageConfigGroupsContext } from 'components/contexts/ManageConfigGroupsProvider/ManageConfigGroupsProvider';
import { useTranslation } from 'react-i18next';
import { setHeader } from 'redux/contentSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { addMessage, clearActionValue } from 'redux/messageSlice';
import { Error } from 'types/graphqlErrors';
import { ConfigurationGroup } from 'types/graphTypes';
import {
  useAddConfigurationGroupMutation,
  useDeleteConfigurationGroupMutation,
  useUpdateConfigurationGroupMutation,
} from 'generated/graphql';
import { useHasEdit } from 'hooks/useHasAccess';
import { EditAccessUtil } from 'pages/admin/ConfigTypeEditor/editAccessUtil';
import extractMeaningfulMessage from 'utils/errorMessage';
import Loading from 'components/Loading';
import {
  fontSize,
  checkButton,
  cancelButton,
  icon,
  lockIcon,
  recordIcons,
} from '../Shared/IconButtonStyling/IconButtonStyling';
import ConfigurationGroupDescription from '../ConfigurationGroupDescription';
import ConfigurationGroupName from '../ConfigurationGroupName';

interface Props {
  configData: ConfigurationGroup[];
  setConfigData: (configData: ConfigurationGroup[]) => void;
  refetchTypes: () => void;
}

function ConfigurationGroupsTableManager({
  configData,
  setConfigData,
  refetchTypes,
}: Props) {
  const { t } = useTranslation();
  const { selectedGroup, setSelectedGroup } = useContext(
    ManageConfigGroupsContext
  );
  const [groupToDelete, setGroupToDelete] = useState<number>(0);
  const actionValue = useAppSelector((state) => state.message.actionValue);
  const { mutate: createConfigurationGroup, isLoading: loadingCreateGroup } =
    useAddConfigurationGroupMutation();
  const { mutate: deleteConfigurationGroup, isLoading: loadingDeleteGroup } =
    useDeleteConfigurationGroupMutation();
  const { mutate: updateConfigurationGroup, isLoading: loadingUpdateGroup } =
    useUpdateConfigurationGroupMutation();
  const dispatch = useAppDispatch();
  const defaultMessage = t('components.message.networkerror');
  const isLoadingAnything =
    loadingCreateGroup || loadingDeleteGroup || loadingUpdateGroup;

  const editAccess = new EditAccessUtil({
    isFullAccess: useHasEdit('configGroupEditor', 'platformconfigaccessall'),
    isLimitAccess: useHasEdit('configGroupEditor', 'platformconfiglimitaccess'),
  });

  const removeConfigurationGroup = useCallback(
    (platformConfigurationGroupId: number) => {
      deleteConfigurationGroup(
        {
          deleteConfigGroupInput: {
            platformConfigurationGroupId,
          },
        },
        {
          onSuccess: () => {
            refetchTypes();
            setSelectedGroup(undefined);
            dispatch(
              addMessage({
                type: MessageType.Success,
                message: t('components.message.success'),
                actionType: MessageActionType.None,
              })
            );
          },
          onError: (e: Error[] | unknown) => {
            const message = extractMeaningfulMessage(e, defaultMessage);
            dispatch(
              addMessage({
                type: MessageType.Error,
                message: message,
              })
            );
          },
        }
      );
    },
    [
      deleteConfigurationGroup,
      dispatch,
      refetchTypes,
      setSelectedGroup,
      t,
      defaultMessage,
    ]
  );

  useEffect(() => {
    dispatch(setHeader({ pageTitle: t('pages.configGroupEditor.title') }));
  }, [dispatch, t]);

  useEffect(() => {
    if (actionValue === 'message_continue' && groupToDelete) {
      dispatch(clearActionValue());
      // TODO take out this temporary fix when we figure out a better way to fix our messaging
      setTimeout(() => removeConfigurationGroup(groupToDelete), 500);
    }
  }, [actionValue, dispatch, groupToDelete, removeConfigurationGroup]);

  const saveConfigurationGroup = () => {
    if (selectedGroup == undefined) return;
    if (selectedGroup?.platformConfigurationGroupId) {
      updateConfigurationGroup(
        {
          newConfigurationGroup: {
            name: selectedGroup.name,
            description: selectedGroup.description ?? '',
            platformConfigurationGroupId:
              selectedGroup.platformConfigurationGroupId,
          },
        },
        {
          onSuccess: () => {
            refetchTypes();
            setSelectedGroup(undefined);
            dispatch(
              addMessage({
                type: MessageType.Success,
                message: t('components.message.success'),
              })
            );
          },
          onError: (e: Error[] | unknown) => {
            const message = extractMeaningfulMessage(e, defaultMessage);
            dispatch(
              addMessage({
                type: MessageType.Error,
                message: message,
              })
            );
          },
        }
      );
    } else {
      createConfigurationGroup(
        {
          newConfigurationGroup: {
            name: selectedGroup.name,
            description: selectedGroup.description ?? '',
            platformConfigurationGroupId:
              selectedGroup.platformConfigurationGroupId,
          },
        },
        {
          onSuccess: () => {
            setSelectedGroup(undefined);
            refetchTypes();
            dispatch(
              addMessage({
                type: MessageType.Success,
                message: t('components.message.success'),
              })
            );
          },
          onError: (e: Error[] | unknown) => {
            const message = extractMeaningfulMessage(e, defaultMessage);
            dispatch(
              addMessage({
                type: MessageType.Error,
                message: message,
              })
            );
          },
        }
      );
    }
  };

  const handleAddRow = () => {
    if (
      configData.filter((row) => !row.platformConfigurationGroupId).length === 0
    ) {
      const temp: ConfigurationGroup = {
        platformConfigurationGroupId: null,
        name: '',
        description: '',
      };
      setConfigData([temp, ...configData]);
      setSelectedGroup(temp);
    }
  };

  const handleEditRow = (configHeader: ConfigurationGroup) => {
    setSelectedGroup({ ...configHeader });
  };

  const handleCancel = () => {
    //if we were creating a new record and cancelled before saving, take it back out of the data.
    setSelectedGroup(undefined);
    setConfigData([
      ...configData.filter((row) => !!row.platformConfigurationGroupId),
    ]);
  };

  const configHeaderColumns: ColumnDef<ConfigurationGroup>[] = [
    {
      header: () => <HeaderColumnNext localization={''} />,
      id: 'edit',
      cell: ({ row }) => {
        if (
          row.original.platformConfigurationGroupId !==
          selectedGroup?.platformConfigurationGroupId
        ) {
          return (
            <IconButton
              onClick={() => handleEditRow(row.original as ConfigurationGroup)}
              sx={recordIcons}
              aria-label="edit-button"
              disabled={
                selectedGroup !== undefined ||
                !editAccess.controlWithOOTB(!!row.original.isOOTB)
              }
              title={t('pages.configGroupEditor.editGroup')}
            >
              <EditIcon sx={{ fontSize }} />
            </IconButton>
          );
        } else {
          return (
            <>
              <Stack direction="row" spacing={1}>
                <IconButton
                  aria-label="check-button"
                  onClick={saveConfigurationGroup}
                  title={t('pages.configRecordEditor.confirmIcon')}
                  size="small"
                  sx={checkButton}
                  disabled={selectedGroup?.name.trim() === ''}
                >
                  <CheckIcon fontSize="small" sx={icon} />
                </IconButton>
                <IconButton
                  onClick={handleCancel}
                  title={t('pages.configRecordEditor.cancelIcon')}
                  size="small"
                  sx={cancelButton}
                >
                  <CloseIcon fontSize="small" sx={icon} />
                </IconButton>
              </Stack>
            </>
          );
        }
      },
    },
    {
      header: () => (
        <HeaderColumnNext
          localization={t('pages.configGroupEditor.configName')}
        />
      ),
      id: 'name',
      accessorKey: 'name',
      cell: ConfigurationGroupName as unknown as ColumnDefTemplate<
        CellContext<ConfigurationGroup, unknown>
      >,
    },
    {
      header: () => (
        <HeaderColumnNext
          localization={t('pages.configGroupEditor.configDesc')}
        />
      ),
      id: 'description',
      accessorKey: 'description',
      cell: ConfigurationGroupDescription as unknown as ColumnDefTemplate<
        CellContext<ConfigurationGroup, unknown>
      >,
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.configRecordEditor.locked')} />
      ),
      id: 'isOOTB',
      accessorKey: 'isOOTB',
      cell: ({ row }) => {
        if (
          row.original.platformConfigurationGroupId !==
          selectedGroup?.platformConfigurationGroupId
        ) {
          if (!!row.original.isOOTB)
            return (
              <Box title={t('pages.configGroupEditor.coreGroup')}>
                <IconButton sx={lockIcon} disabled aria-label="lock-button">
                  <LockIcon sx={{ fontSize }} />
                </IconButton>
              </Box>
            );
          else {
            return (
              <Box title={t('pages.configGroupEditor.editableGroup')}>
                <IconButton sx={lockIcon} disabled aria-label="unlock-button">
                  <LockOpenIcon sx={{ fontSize }} />
                </IconButton>
              </Box>
            );
          }
        } else {
          return <></>;
        }
      },
      enableColumnFilter: false,
    },
    {
      header: () => <HeaderColumnNext localization={''} />,
      id: 'remove',
      cell: ({ row }) => {
        if (
          row.original.platformConfigurationGroupId !==
          selectedGroup?.platformConfigurationGroupId
        ) {
          return (
            <IconButton
              sx={recordIcons}
              onClick={() => {
                if (row.original) {
                  setGroupToDelete(
                    row.original
                      .platformConfigurationGroupId as unknown as SetStateAction<number>
                  );
                }
                dispatch(
                  addMessage({
                    message: t('pages.configGroupEditor.confirmButtonMsg'),
                    type: MessageType.Info,
                    actionType: MessageActionType.Continue,
                  })
                );
              }}
              aria-label="delete-button"
              data-testid="delete-button"
              disabled={
                selectedGroup !== undefined ||
                !editAccess.controlWithOOTB(!!row.original.isOOTB)
              }
              title={t('pages.configGroupEditor.deleteGroup')}
            >
              <DeleteIcon sx={{ fontSize }} />
            </IconButton>
          );
        } else {
          return <></>;
        }
      },
    },
  ];

  return (
    <>
      <DefaultDataTableNext
        columns={configHeaderColumns}
        data={configData}
        customHeader={({ setFilterValue, table }) => (
          <Box
            sx={{
              justifyContent: 'start',
              width: '100%',
              mb: '.25em',
            }}
          >
            <Button
              onClick={() => {
                setFilterValue('');
                table.setPageIndex(0);
                handleAddRow();
              }}
              id={'tableManager-AddBtn'}
              disabled={selectedGroup !== undefined || !editAccess.generalEdit}
              sx={{ mt: '.5em' }}
            >
              {t('pages.configGroupEditor.addGroup')}
            </Button>
          </Box>
        )}
      />
      {isLoadingAnything && <Loading />}
    </>
  );
}

export default ConfigurationGroupsTableManager;
