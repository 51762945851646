import { useEffect, useState, useRef } from 'react';
import { Grid, SelectChangeEvent } from '@mui/material';
import { useTheme } from '@mui/system';
import Loading from 'components/Loading/Loading';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext/DefaultDataTableNext';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext/HeaderColumnNext';
import { Select } from '@revenue-solutions-inc/revxcoreui';
import { useTranslation } from 'react-i18next';
import DataTableNextDateRangeFilter from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DataTableNextDateRangeFilter/DataTableNextDateRangeFilter';
import { IHeader, setHeader } from 'redux/contentSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getFormatDate } from 'utils/date-util';
import { formatCurrency } from 'common/helpers';
import { ColumnDef } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import { useGetRefundBatchSummaryQuery } from 'generated/graphql';
import { RefundBatchSummaryType, DateType } from './types/refundBatchSummary';

function RefundBatchSummary(): React.JSX.Element {
  const [processedData, setProcessedData] = useState<RefundBatchSummaryType[]>(
    []
  );
  const [selectedRefundFormId, setSelectedRefundFormId] = useState<string>('');
  const [filteredByDropdown, setFilteredByDropdown] = useState<
    RefundBatchSummaryType[]
  >([]);
  const [beginDate, setBeginDate] = useState<Date | null>();
  const [endDate, setEndDate] = useState<Date | null>();
  const dropdownFocus = useRef<HTMLSelectElement>(null);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isLoading, data } = useGetRefundBatchSummaryQuery();
  const module: string = useAppSelector((state) => state.user.module);
  const theme = useTheme();

  const RefundFormOptions = [
    {
      id: 1,
      name: t('pages.refundBatch.refundBatchSummary.dropdownOptions.all'),
    },
    {
      id: 2,
      name: t('pages.refundBatch.refundBatchSummary.dropdownOptions.check'),
    },
    {
      id: 3,
      name: t('pages.refundBatch.refundBatchSummary.dropdownOptions.dirDep'),
    },
  ];

  useEffect(() => {
    if (!isLoading && data?.GetRefundBatchSummary) {
      const header: IHeader = {
        pageTitle: t('pages.refundBatch.refundBatchSummary.title'),
        previousPage: t('pages.refundBatch.refundBatchSummary.prevPageTitle'),
        route: `navigation`,
        icon: {
          props: { fill: 'black' },
          icon: 'accountBalanceIcon',
          fontSize: 'large',
        },
      };

      const refundData: RefundBatchSummaryType[] =
        data?.GetRefundBatchSummary.map(
          ({
            id,
            refundBatchIdLabel,
            processedDate,
            createdDate,
            status,
            refundsCount,
            refundsTotal,
            isPaperCheck,
          }) => {
            const newRefund: RefundBatchSummaryType = {
              id,
              refundBatchIdLabel,
              processedDate: processedDate
                ? getFormatDate(new Date(processedDate))
                : '',
              createdDate: getFormatDate(new Date(createdDate)),
              status,
              refundsCount,
              refundsTotal: formatCurrency(refundsTotal.toString()),
              refundsForm: isPaperCheck ? 'Check' : 'DirDep',
            };
            return newRefund;
          }
        );
      dispatch(setHeader(header));
      setProcessedData(refundData);
    }
  }, [data, isLoading, dispatch, t]);

  const filterByDropdown = (
    dataToFilter: RefundBatchSummaryType[],
    refundForm: string
  ) => {
    return dataToFilter?.filter((item) => item.refundsForm === refundForm);
  };

  const onSelectRefundForm = (event: SelectChangeEvent<string | number>) => {
    const newSelectedRefundForm = `${
      (event.target as HTMLSelectElement).value
    }`;

    if (!newSelectedRefundForm || newSelectedRefundForm.length === 0) return;

    let filtered: RefundBatchSummaryType[] = [];
    if (processedData) {
      switch (newSelectedRefundForm) {
        case '1':
          setSelectedRefundFormId(newSelectedRefundForm);
          setFilteredByDropdown([]);
          break;
        case '2':
          setSelectedRefundFormId(newSelectedRefundForm);
          filtered = filterByDropdown(
            processedData,
            t('pages.refundBatch.refundBatchSummary.dropdownOptions.check')
          );

          break;
        case '3':
          setSelectedRefundFormId(newSelectedRefundForm);
          filtered = filterByDropdown(
            processedData,
            t('pages.refundBatch.refundBatchSummary.dropdownOptions.dirDep')
          );
          break;
        default:
          setFilteredByDropdown([]);
          break;
      }
    }
    setFilteredByDropdown(filtered);
  };

  const filterRefundBatchSummaryByDateRange = (
    refundBatches: RefundBatchSummaryType[],
    propBeginDate: DateType,
    propEndDate: DateType
  ) => {
    let filtered: RefundBatchSummaryType[] = [];
    if (!refundBatches || refundBatches.length === 0) return;
    filtered = refundBatches.filter((item) => {
      if (!item.createdDate) return;

      const openDate = new Date(item.createdDate);
      if (propBeginDate && propEndDate) {
        return openDate >= propBeginDate && openDate <= propEndDate;
      }
      if (propBeginDate && !propEndDate) {
        return openDate >= propBeginDate;
      }
      if (propEndDate && !propBeginDate) {
        return openDate <= propEndDate;
      }
    });
    return filtered;
  };

  const columns: ColumnDef<RefundBatchSummaryType>[] = [
    {
      id: 'refundBatchIdLabel',
      accessorKey: 'refundBatchIdLabel',
      header: () => (
        <HeaderColumnNext
          localization={t(
            'pages.refundBatch.refundBatchSummary.refundBatchIdLabel'
          )}
        />
      ),
      cell: ({ getValue, row }) => (
        <Link
          to={{
            pathname: `/${module}/refundBatchDetails/${row.original.id}`,
          }}
          style={{ color: theme.palette.linkBlue.dark }}
        >
          {getValue() as string}
        </Link>
      ),
    },
    {
      id: 'status',
      accessorKey: 'status',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.refundBatch.refundBatchSummary.status')}
        />
      ),
    },
    {
      id: 'createdDate',
      accessorKey: 'createdDate',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.refundBatch.refundBatchSummary.createdDate')}
        />
      ),
    },
    {
      id: 'processedDate',
      accessorKey: 'processedDate',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.refundBatch.refundBatchSummary.processedDate')}
        />
      ),
    },
    {
      id: 'refundsForm',
      accessorKey: 'refundsForm',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.refundBatch.refundBatchSummary.refundForm')}
        />
      ),
    },
    {
      id: 'refundsCount',
      accessorKey: 'refundsCount',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.refundBatch.refundBatchSummary.refundsCount')}
        />
      ),
    },
    {
      id: 'refundsTotal',
      accessorKey: 'refundsTotal',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.refundBatch.refundBatchSummary.refundsTotal')}
        />
      ),
    },
  ];

  const getData = () => {
    const filteredOrProcessedData = selectedRefundFormId
      ? filteredByDropdown
      : processedData;

    if (beginDate || endDate)
      return filterRefundBatchSummaryByDateRange(
        filteredOrProcessedData,
        beginDate,
        endDate
      );

    if (filteredByDropdown && filteredByDropdown.length > 0)
      return filteredByDropdown;

    return processedData;
  };

  return (
    <>
      {isLoading && <Loading />}
      <Grid item xs={2} mt={1}>
        <DefaultDataTableNext
          tableName={t('pages.refundBatch.refundBatchSummary.title')}
          columns={columns}
          data={getData() ?? []}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'end',
          }}
          customHeader={
            <>
              <Select
                id="datatable-selectfilter"
                data-testid="datatable-selectfilter"
                label={t('pages.refundBatch.refundBatchSummary.dropdownTitle')}
                value={selectedRefundFormId}
                options={RefundFormOptions.map(({ id, name }) => {
                  return { key: `${id}`, desc: name };
                })}
                onChange={onSelectRefundForm}
                inputProps={{
                  ref: dropdownFocus,
                }}
                sx={{ width: '18%' }}
              />
              <DataTableNextDateRangeFilter
                data-testid="datatable-date-range"
                beginDate={beginDate ? beginDate : null}
                endDate={endDate ? endDate : null}
                setBeginDate={setBeginDate}
                setEndDate={setEndDate}
              />
            </>
          }
        />
      </Grid>
    </>
  );
}

export default RefundBatchSummary;
