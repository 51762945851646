import { useState, forwardRef, useEffect } from 'react';
import { LayoutBuilder } from '@revenue-solutions-inc/revxcoreui';
import {
  LayoutBuilderProps,
  IMasterLayout,
} from '@revenue-solutions-inc/revxcoreui/material/layoutBuilder/types/fields';
import Loading from 'components/Loading/Loading';
import { useGetConfigurationRecordsQuery } from 'generated/graphql';
import { LayoutBuilderHandle } from '@revenue-solutions-inc/revxcoreui/material/layoutBuilder/builder/AppPanel';

const LayoutBuilderWrapper = forwardRef<
  LayoutBuilderHandle,
  LayoutBuilderProps
>((props: LayoutBuilderProps, ref) => {
  const {
    saveConfiguration,
    fields,
    masterLayout,
    hideSaveButton,
    viewLayout,
  } = props;

  const [layout, setLayout] = useState<IMasterLayout | undefined>(masterLayout);
  const [editMode, setEditMode] = useState(true);
  const { data, isFetching, refetch } = useGetConfigurationRecordsQuery(
    {
      configurationType: 'Form Messages',
      configurationModule: '',
    },
    {
      enabled: false,
    }
  );
  useEffect(() => {
    setLayout(masterLayout);
  }, [masterLayout]);

  return (
    <>
      {isFetching ? (
        <Loading />
      ) : (
        <LayoutBuilder
          fields={fields}
          saveConfiguration={saveConfiguration}
          adjustmentReasons={data?.getConfigurations.map(
            (item) => item.configurationName
          )}
          fetchAdjustmentReasons={(
            newLayout: IMasterLayout,
            modeEdit: boolean
          ) => {
            refetch();
            setLayout(newLayout);
            setEditMode(modeEdit);
          }}
          masterLayout={layout}
          mode={editMode}
          ref={ref}
          hideSaveButton={hideSaveButton}
          viewLayout={viewLayout}
        />
      )}
    </>
  );
});

export default LayoutBuilderWrapper;
