import { useEffect, useState } from 'react';
import { Box, Grid, Theme } from '@mui/material';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  DataDisplay,
  MessageActionType,
  MessageType,
  TextArea,
} from '@revenue-solutions-inc/revxcoreui';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext/DefaultDataTableNext';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext/HeaderColumnNext';
import DateCell from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/TableCells/DateCell/DateCell';
import { ColumnDef } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { setHeader } from 'redux/contentSlice';
import { useTranslation } from 'react-i18next';
import {
  DictionaryResponseWithOutJson,
  UpdateDictionaryStatus,
  useCreateDictionaryMutation,
  useDeleteDictionaryMutation,
  useSystemFieldDictionariesQuery,
  useUpdateDictionaryStatusMutation,
} from 'generated/graphql';
import { format } from 'date-fns';
import { addMessage } from 'redux/messageSlice';
import Dialog from '@revenue-solutions-inc/revxcoreui/material/controls/Dialog';
import { dateFormat } from 'utils/date-util';
type Attributes = {
  systemFieldDictionaryId: number;
  status: string;
  version: string;
  updatedBy: string;
  updatedDate: string;
  description: string;
};
const defaultAttributes: Attributes = {
  systemFieldDictionaryId: 0,
  status: '',
  version: '',
  updatedBy: '',
  updatedDate: '',
  description: '',
};
type Props = {
  isFromTemplate: boolean;
};

function ActiveDictionary(dataProps: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [popUp, setPopUp] = useState<boolean>(false);
  const [openDictionary, setOpenDictionary] = useState<boolean>(false);
  const [systemFieldDictionary, setSystemFieldDictionary] =
    useState<DictionaryResponseWithOutJson>();
  const [dictionaryDesc, setDictionaryDesc] = useState<string>('');
  const [data, setData] = useState<Attributes>(defaultAttributes);
  const module = useAppSelector((state) => state.user.module);
  const { data: systemFieldDictionaryData, refetch: dictionaryRefetch } =
    useSystemFieldDictionariesQuery();
  const deleteTemplate = useDeleteDictionaryMutation();
  const { mutate: updateDictionaryStatus } =
    useUpdateDictionaryStatusMutation<UpdateDictionaryStatus>();

  const onEditDictionary = (dictionaryId: number) => {
    navigate(`/${module}/editDictionary/${dictionaryId}`);
  };

  const dictionaryDataHandler = () => {
    if (systemFieldDictionaryData?.SystemFieldDictionaries) {
      const sortedArray =
        systemFieldDictionaryData?.SystemFieldDictionaries.sort((a, b) => {
          if (!a.version || !b.version) return 0;
          else return b.version - a.version;
        });
      return sortedArray;
    } else {
      return [];
    }
  };

  useEffect(() => {
    dispatch(
      setHeader({
        pageTitle: t('pages.activeDictionary.title'),
        previousPage: t('pages.navigation.title'),
        route: `navigation`,
      })
    );
  }, [dispatch, t]);

  useEffect(() => {
    if (
      systemFieldDictionaryData &&
      systemFieldDictionaryData.SystemFieldDictionaries?.length > 0
    ) {
      const contentResp =
        systemFieldDictionaryData.SystemFieldDictionaries.filter(
          (item) =>
            item.status.toUpperCase() == 'DRAFT' ||
            item.status.toUpperCase() == 'ACTIVE' ||
            item.status.toUpperCase() == 'INACTIVE'
        )[0];

      setData((prev) => ({
        ...prev,
        systemFieldDictionaryId: parseInt(contentResp.systemFieldDictionaryId),
        status: contentResp.status,
        updatedBy: contentResp.updatedBy as string,
        updatedDate: format(
          new Date(contentResp.updatedDate ?? ''),
          dateFormat
        ),
        version: contentResp.version as unknown as string,
        description: contentResp.description as string,
      }));
      setSystemFieldDictionary(
        systemFieldDictionaryData?.SystemFieldDictionaries.filter(
          (item) =>
            item.systemFieldDictionaryId === contentResp.systemFieldDictionaryId
        )[0]
      );
    }
  }, [
    systemFieldDictionaryData,
    systemFieldDictionaryData?.SystemFieldDictionaries,
  ]);
  const handleTextArea = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDictionaryDesc(event.target.value);
  };
  const DictionaryStatusUpdate = (currentStatus?: string) => {
    updateDictionaryStatus(
      {
        dictionary: {
          dictionaryId: parseInt(
            systemFieldDictionary?.systemFieldDictionaryId ?? ''
          ),
          status: currentStatus == 'DRAFT' ? 'ACTIVE' : 'INACTIVE',
        },
      },
      {
        onSuccess: () => {
          dictionaryRefetch();
          dispatch(
            addMessage({
              message: t(
                'pages.activeDictionary.message.updateDictionaryStatus'
              ),
              type: MessageType.Success,
              actionType: MessageActionType.None,
            })
          );
        },
        onError: () => {
          dispatch(
            addMessage({
              message: t(
                'pages.activeDictionary.message.errorDictionaryStatus'
              ),
              type: MessageType.Error,
              actionType: MessageActionType.None,
            })
          );
        },
      }
    );
  };
  const SystemFieldDictionaryColumns: ColumnDef<DictionaryResponseWithOutJson>[] =
    [
      {
        header: () => (
          <HeaderColumnNext
            localization={t('pages.activeDictionary.version')}
          />
        ),
        accessorKey: 'version',
      },
      {
        header: () => (
          <HeaderColumnNext localization={t('pages.activeDictionary.status')} />
        ),
        accessorKey: 'status',
      },
      {
        header: () => (
          <HeaderColumnNext
            localization={t('pages.activeDictionary.updatedDate')}
          />
        ),
        cell: ({ row }) => {
          return <DateCell dateString={row.original.updatedDate as string} />;
        },
        accessorKey: 'updatedDate',
      },
      {
        header: () => (
          <HeaderColumnNext
            localization={t('pages.activeDictionary.updatedBy')}
          />
        ),
        accessorKey: 'updatedBy',
      },
    ];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fetchDictionaryDetails = (row: any) => {
    setData((prev) => ({
      ...prev,
      systemFieldDictionaryId: parseInt(row.original.systemFieldDictionaryId),
      status: row.original.status,
      version: row.original.version as string,
      updatedBy: row.original.updatedBy as string,
      updatedDate: format(new Date(row.original.updatedDate ?? ''), dateFormat),
      description: row.original.description as string,
    }));
    setSystemFieldDictionary(
      systemFieldDictionaryData?.SystemFieldDictionaries.filter(
        (item) =>
          item.systemFieldDictionaryId === row.original.systemFieldDictionaryId
      )[0]
    );
  };
  const DeletePopupChildren = (
    <DataDisplay
      label={t('pages.activeDictionary.warningMessage')}
      id="warningMessage"
      data=""
    />
  );
  const DictionaryChildren = (
    <Box sx={{ display: 'grid' }}>
      <DataDisplay
        id={'message'}
        label={t('pages.templates.description')}
        data={''}
      />
      <TextArea
        ariaLabel="add description"
        value={dictionaryDesc ?? ''}
        multiline
        onChange={handleTextArea}
        error={dictionaryDesc.length > 1999}
        helperText={
          dictionaryDesc.length > 1999
            ? t('pages.templates.import.warningMessage')
            : ''
        }
      />
    </Box>
  );
  const deleteDictionary = (item: number) => {
    deleteTemplate.mutate(
      {
        dictionary: {
          dictionaryId: item,
        },
      },
      {
        onSuccess: () => {
          dictionaryRefetch();
          setData(defaultAttributes);
          setDictionaryDesc('');
          dispatch(
            addMessage({
              message: t('pages.activeDictionary.message.deleteDictionary'),
              type: MessageType.Success,
              actionType: MessageActionType.None,
            })
          );
        },
        onError: () => {
          dispatch(
            addMessage({
              message: t(
                'pages.activeDictionary.message.errordeleteDictionary'
              ),
              type: MessageType.Error,
              actionType: MessageActionType.None,
            })
          );
        },
      }
    );
  };
  const { mutate: dictionaryMutate } = useCreateDictionaryMutation({});
  const handleDictionaryCreate = () => {
    if (dictionaryDesc && dictionaryDesc.length <= 1999) {
      let paramCreateDict;
      if (
        systemFieldDictionaryData?.SystemFieldDictionaries &&
        systemFieldDictionaryData?.SystemFieldDictionaries.length > 0
      ) {
        paramCreateDict = {
          fixedId:
            systemFieldDictionaryData?.SystemFieldDictionaries[0]?.fixedId ?? 0,
          allowCreateIfLatestActive: true,
          logixInputSchemaJson: '{ "Contexts": [],"ProcessingOutput": {}}',
          description: dictionaryDesc,
        };
      } else {
        paramCreateDict = {
          allowCreateIfLatestActive: false,
          logixInputSchemaJson: '{ "Contexts": [],"ProcessingOutput": {}}',
          description: dictionaryDesc,
        };
      }
      dictionaryMutate(
        {
          createDictionary: paramCreateDict,
        },
        {
          onSuccess: () => {
            dictionaryRefetch();
            dispatch(
              addMessage({
                type: MessageType.Success,
                message: t('pages.activeDictionary.message.createDictionary'),
              })
            );
            setOpenDictionary(false);
          },
          onError: () => {
            dispatch(
              addMessage({
                type: MessageType.Error,
                message: t(
                  'pages.activeDictionary.message.errorCreateDictionary'
                ),
              })
            );
          },
        }
      );
    }
  };
  return (
    <>
      <Grid>
        {dataProps.isFromTemplate ? (
          <>
            <Dialog
              id="dialogId"
              open={popUp}
              children={DeletePopupChildren}
              title={t('pages.activeDictionary.buttons.delete')}
              type="transactional"
              transactionModalTransactionButtonText={t(
                'pages.activeDictionary.buttons.delete'
              )}
              width={350}
              handleClose={() => {
                setPopUp(false);
              }}
              handleCancelClick={() => {
                setPopUp(false);
              }}
              handleTransactionClick={() => {
                deleteDictionary(data.systemFieldDictionaryId);
                setPopUp(false);
              }}
            />
            <Dialog
              id="dialogId"
              open={openDictionary}
              children={DictionaryChildren}
              title={t('pages.activeDictionary.buttons.createDictionary')}
              type="transactional"
              transactionModalTransactionButtonText={t(
                'pages.activeDictionary.buttons.create'
              )}
              width={360}
              handleClose={() => {
                setOpenDictionary(false);
                setDictionaryDesc('');
              }}
              handleCancelClick={() => {
                setOpenDictionary(false);
                setDictionaryDesc('');
              }}
              handleTransactionClick={() => {
                handleDictionaryCreate();
              }}
            />
          </>
        ) : null}
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <DefaultDataTableNext
                columns={
                  SystemFieldDictionaryColumns as ColumnDef<
                    Record<string, unknown>,
                    unknown
                  >[]
                }
                data={dictionaryDataHandler()}
                sx={{ alignItems: 'end' }}
                cursorHover={true}
                fetchCursorSelectedRow={fetchDictionaryDetails}
                customHeader={
                  <Box sx={{ display: 'flex' }}>
                    {!data.status?.toLocaleUpperCase() ? (
                      <Button
                        id="create_dictionary"
                        onClick={() => setOpenDictionary(true)}
                        sx={{ mr: '10px', minWidth: '128px' }}
                      >
                        {t('pages.activeDictionary.buttons.createDictionary')}
                      </Button>
                    ) : null}
                  </Box>
                }
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card
            sx={{
              borderRadius: '5px',
              boxShadow: '0px 0px 5px rgb(0 0 0 / 30%)',
            }}
          >
            <CardHeader
              sx={(theme: Theme) => {
                return {
                  backgroundColor: theme.palette.secondary.light,
                  '& .MuiCardHeader-title': {
                    fontSize: '0.875rem',
                    fontWeight: 700,
                  },
                };
              }}
              title={t('pages.activeDictionary.attributes')}
            />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={6} md={4}>
                  <DataDisplay
                    id={'status'}
                    label={t('pages.activeDictionary.status')}
                    data={data.status}
                  />
                  <DataDisplay
                    id={'version'}
                    label={t('pages.activeDictionary.version')}
                    data={data.version}
                    sxLabel={{ display: 'inline-block', mt: 1 }}
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <DataDisplay
                    id={'updatedDate'}
                    label={t('pages.activeDictionary.updatedDate')}
                    data={data.updatedDate}
                  />
                  <DataDisplay
                    id={'updatedBy'}
                    label={t('pages.activeDictionary.updatedBy')}
                    data={data.updatedBy}
                    sxLabel={{ display: 'inline-block', mt: 1 }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <DataDisplay
                    id={'templateDescription'}
                    label={t('pages.activeDictionary.description')}
                    data={''}
                  />

                  <TextArea
                    ariaLabel="add description"
                    value={data?.description ?? ''}
                    multiline
                    disabled
                    onChange={() => {}}
                    sx={{ minWidth: '300px' }}
                  />
                </Grid>
                <Grid item xs={12} md={12} display="flex">
                  <>
                    {data.status?.toLocaleUpperCase() == 'DRAFT' ? (
                      <Button
                        id="saveBtn-updateContent"
                        type="secondary"
                        data-testid="update-button"
                        sx={{ minWidth: '98px' }}
                        onClick={() =>
                          DictionaryStatusUpdate(
                            systemFieldDictionary?.status?.toUpperCase()
                          )
                        }
                      >
                        {t('pages.correspondence.activate')}
                      </Button>
                    ) : null}
                    {systemFieldDictionaryData &&
                    systemFieldDictionaryData.SystemFieldDictionaries?.length >
                      0 ? (
                      <Button
                        disabled={
                          data.status?.toLocaleUpperCase() == 'INACTIVE'
                        }
                        id="editBtn"
                        data-testid="update-button"
                        sx={{ ml: 1, minWidth: '98px' }}
                        size="medium"
                        onClick={() =>
                          onEditDictionary(data.systemFieldDictionaryId)
                        }
                      >
                        {t('pages.activeDictionary.buttons.edit')}
                      </Button>
                    ) : null}

                    {data.status?.toLocaleUpperCase() == 'DRAFT' ? (
                      <Button
                        id="delteBtn"
                        type="secondary"
                        data-testid="update-button"
                        sx={{ ml: 1, minWidth: '98px' }}
                        size="medium"
                        onClick={() => setPopUp(true)}
                      >
                        {t('pages.correspondence.delete')}
                      </Button>
                    ) : null}
                  </>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default ActiveDictionary;
