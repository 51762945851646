import { useState, useEffect, RefObject, useRef } from 'react';
import {
  AActionsInput,
  GetDomainSinkObjectDocument,
  InputFieldMappingsInput,
  LogiXgroupInput,
  Messages,
  ParametersInput,
  useGetViewLogixFunctionByTypeQuery,
} from 'generated/graphql';
import { ErrorResponse } from 'types/graphqlErrors';
import DatamapperDnd from '@revenue-solutions-inc/revxcoreui/material/datamapperV3/DatamapperDnd';
import {
  Action,
  ActionMapping,
  CompileExpressionResponseDM,
  FieldMappingsTypesDM,
  LogixGroupTypeDM,
  Mapping,
  RuleDM,
  RuleOutputDM,
  RulesFieldsType,
  SinkFieldDM,
  SinkObjects,
} from '@revenue-solutions-inc/revxcoreui/material/datamapperV3/types';
import {
  parseLogixFields,
  parseSinkObject,
  buildTree,
} from '@revenue-solutions-inc/revxcoreui/material/datamapperV3/utils';
import { getActionsList } from 'utils/getActionsList';
import useGetAccessToken from 'hooks/useGetAccessToken';
import {
  LogixGroupType,
  GetViewLogixFunctionByType,
} from '@revenue-solutions-inc/revxcoreui';
import { JsonPath } from 'utils/filterJsonData';
import useMutationRequest from 'hooks/useMutationRequest';
import checkCompileExpession from 'pages/admin/LogixTestHarness/checkCompileExpession';
import Loading from 'components/Loading';

interface ActionProps {
  name: string;
  category: string;
  repeatable: boolean;
  statusToBeChanged: string;
  domain: string;
  operationId: string;
  operationType: string;
}
interface ActionSelectedProp {
  domain: string;
  operationId: string;
  operationType: string;
  SinkObjectId: string;
}

interface Props {
  LogixGroup: LogiXgroupInput | null;
  actions: ActionProps[] | [];
  handleMappedLogixGroupSchema?: (logixUpdated: LogixGroupType) => void;
  refBtnDM: RefObject<HTMLButtonElement>;
}

function FormDataMapper({
  LogixGroup,
  actions,
  handleMappedLogixGroupSchema,
  refBtnDM,
}: Props): JSX.Element {
  const parsedLogixFields = parseLogixFields(LogixGroup as LogixGroupTypeDM);
  const accessToken = useGetAccessToken();
  const actionSelectedRef = useRef<ActionSelectedProp | null>(null);
  const [isBuildingAction, setBuildingAction] = useState<boolean>(true);
  const [changeSchema, setChangeSchema] = useState({
    openDialog: false,
    closeDialog: () => {
      setChangeSchema({ ...changeSchema, openDialog: false });
    },
  });

  const mutationRequest = useMutationRequest<CompileExpressionResponseDM>();

  const [functionOpt, setFunctionOpt] = useState<
    GetViewLogixFunctionByType[] | []
  >([]);
  const { data: functionData } = useGetViewLogixFunctionByTypeQuery({
    type: 'Field',
  });

  const [actionsList, setActionsList] = useState<Action[]>([]);

  useEffect(() => {
    const tempActionsList = getActionsList(
      LogixGroup as LogixGroupTypeDM,
      actions
    );
    setActionsList(tempActionsList.filter((action) => action.sink?.domain));
    setBuildingAction(false);
  }, [LogixGroup, actions]);

  const selectOptionsHandler = () => {};

  const sinkObjectHandler = async (
    domain: string,
    operationType: string,
    operationId: string
  ) => {
    //TODO: Fire request to api to get the sinkObject
    //graphql requested fired
    const url = process.env.REACT_APP_GRAPH || '';
    const resp = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        query: GetDomainSinkObjectDocument,
        variables: {
          operationId: operationId,
          operationType: operationType,
          domain: domain,
        },
      }),
    });
    const res = await resp.json();
    //handle is comming the info if not we set static value for the moment
    const data = res.data.GetDomainSinkObject
      ? res.data.GetDomainSinkObject
      : undefined;

    actionSelectedRef.current = {
      operationId: operationId,
      domain: domain,
      operationType: operationType,
      SinkObjectId: data?.SinkObjectId ?? '',
    };
    let response: Promise<SinkObjects> | undefined = undefined;
    //TODO: Parse the strcuture in a redable structure for DataMapperDND
    const parsedFinancialTransactionSourcesSink = parseSinkObject(data);
    const financialTransactionSourcesInput =
      parsedFinancialTransactionSourcesSink.filter((sink) => {
        return sink.fieldType === 'Input';
      });
    const financialTransactionSourcesOutput =
      parsedFinancialTransactionSourcesSink.filter((sink) => {
        return sink.fieldType === 'Output';
      });
    const financialTransactionSourcesInputSink = buildTree(
      financialTransactionSourcesInput,
      0,
      operationId
    );
    const financialTransactionSourcesOutputSink = buildTree(
      financialTransactionSourcesOutput,
      0,
      operationId
    );

    response = new Promise<SinkObjects>((resolve) => {
      const sink: SinkObjects = {
        input: financialTransactionSourcesInputSink,
        output: financialTransactionSourcesOutputSink,
      };
      resolve(sink);
    });
    return response;
  };

  const mapOutput = (ruleOutputDM: RuleOutputDM | undefined) => {
    if (!ruleOutputDM) {
      return;
    }

    return {
      Condition: ruleOutputDM.Condition,
      Messages: ruleOutputDM.Messages as Messages[],
      Actions: ruleOutputDM.Actions.map((dmFailureAction) => {
        return {
          Description: dmFailureAction.Description,
          Name: dmFailureAction.Name,
          ShortLabel: dmFailureAction.ShortLabel,
          Parameters: dmFailureAction.Parameters as ParametersInput[],
        };
      }),
    };
  };

  const mapDMMappingToLogix = (dmAction: Mapping) => {
    const newMapping: InputFieldMappingsInput = {
      SinkFieldReferences: [
        { Id: dmAction.sinkField.id, Path: dmAction.sinkField.path },
      ],
      SourceFieldIds: dmAction.sourceFields.map((sField) => {
        return sField.id;
      }),
      MappingRule: {
        Description: dmAction.rules?.Description,
        Expression: dmAction.rules?.Expression,
        RuleName: dmAction.rules?.RuleName,
        RuleScope: dmAction.rules?.RuleScope,
        RuleType: dmAction.rules?.RuleType,
        Output: {
          Failure: mapOutput(dmAction.rules?.Output.Failure),
          Success: mapOutput(dmAction.rules?.Output.Success),
        },
      },
    };
    return newMapping;
  };

  const mapActionMappingToDataMapInput = (
    actionMapping: ActionMapping,
    logixAction: AActionsInput
  ) => {
    if (!logixAction.DataMap) {
      logixAction.DataMap = {};
    }

    logixAction.DataMap.Domainkey =
      actionSelectedRef.current?.domain ?? logixAction.DataMap.Domainkey;
    logixAction.DataMap.OperationID =
      actionSelectedRef.current?.operationId ?? logixAction.DataMap.OperationID;
    logixAction.DataMap.SinkObjectId =
      actionSelectedRef.current?.SinkObjectId ??
      logixAction.DataMap.SinkObjectId;

    logixAction.DataMap.InputFieldMappings = [];

    logixAction.DataMap.InputFieldMappings =
      actionMapping.input.map(mapDMMappingToLogix);

    logixAction.DataMap.OutputFieldMappings =
      actionMapping.output.map(mapDMMappingToLogix);

    if (
      logixAction.DataMap.InputFieldMappings.length === 0 &&
      logixAction.DataMap.OutputFieldMappings.length === 0
    ) {
      logixAction.DataMap = null;
    }

    return logixAction;
  };

  const handleDMSave = (mappedData: ActionMapping[]) => {
    LogixGroup?.Contexts?.forEach((context) => {
      //Find the action with the name
      context.Actions?.forEach((action: AActionsInput) => {
        //Find an action with the same name
        const actionToMap = mappedData.find((mappedAction) => {
          return mappedAction.action === action.Name;
        });

        if (!actionToMap) {
          action.DataMap = null;
          return;
        }
        mapActionMappingToDataMapInput(actionToMap, action);
      });
    });

    if (handleMappedLogixGroupSchema) {
      handleMappedLogixGroupSchema(LogixGroup as LogixGroupType);
    }
  };

  const buildNewSourceField = (sourceFieldsId: number[]) => {
    const tempSourceField = sourceFieldsId.map((sourceField: number) => {
      const logixFields = JsonPath({
        keyName: 'FieldID',
        value: `${sourceField}`,
        schema: LogixGroup?.Contexts,
        searchWith: '',
      });
      const name = logixFields.length > 0 ? logixFields[0].value.FieldName : '';
      const type =
        logixFields.length > 0 ? logixFields[0].value.FieldValueType : '';
      return {
        id: sourceField,
        name: name,
        type: type,
      };
    });
    return tempSourceField;
  };

  function parseLogixSchema() {
    let LogixActions;
    if (LogixGroup?.Contexts) {
      LogixActions = LogixGroup?.Contexts[0].Actions;
    }
    const tmpFields: ActionMapping[] = [];

    LogixActions?.forEach((field) => {
      tmpFields.push({
        action: field.Name as unknown as string,
        input: [],
        output: [],
      });
    });
    const dataMapsInput: FieldMappingsTypesDM[][] = [];
    const dataMapsOutput: FieldMappingsTypesDM[][] = [];
    if (LogixActions) {
      LogixActions.forEach((value) => {
        if (
          value.DataMap !== undefined &&
          value.DataMap !== null &&
          value.DataMap.InputFieldMappings !== undefined
        ) {
          dataMapsInput.push(
            value.DataMap
              .InputFieldMappings as unknown as FieldMappingsTypesDM[]
          );
        } else {
          dataMapsInput.push([]);
        }
      });

      LogixActions.forEach((value) => {
        if (
          value.DataMap !== undefined &&
          value.DataMap !== null &&
          value.DataMap.OutputFieldMappings !== undefined
        ) {
          dataMapsOutput.push(
            value.DataMap
              .OutputFieldMappings as unknown as FieldMappingsTypesDM[]
          );
        }
      });
    }
    const fieldValues: InputFieldMappingsInput[][] = [];
    const fieldOutput: InputFieldMappingsInput[][] = [];
    dataMapsInput.forEach((field, index) => {
      if (field) {
        fieldValues.push(field as unknown as InputFieldMappingsInput[]);
      }
      if (fieldValues.length > 0 && Array.isArray(fieldValues)) {
        fieldValues[index].forEach((value) => {
          const newSinkField = value.SinkFieldReferences?.length
            ? {
                id: value.SinkFieldReferences[0].Id,
                name: 'id',
                path: value.SinkFieldReferences[0].Path,
              }
            : {};
          const newSourceField = buildNewSourceField(
            value.SourceFieldIds as unknown as number[]
          );
          if (Object.keys(newSinkField).length > 0) {
            tmpFields[index].input.push({
              sinkField: newSinkField as unknown as SinkFieldDM,
              sourceFields: newSourceField,
              rules: value.MappingRule as unknown as RuleDM | undefined,
            });
          }
        });
      }
    });

    dataMapsOutput.forEach((field, index) => {
      if (field) {
        fieldOutput.push(field as unknown as InputFieldMappingsInput[]);
      }
      if (fieldOutput.length > 0 && Array.isArray(fieldValues)) {
        fieldOutput[index].forEach((value) => {
          const newSinkField = value.SinkFieldReferences?.length
            ? {
                id: value.SinkFieldReferences[0].Id,
                name: 'id',
                path: value.SinkFieldReferences[0].Path,
              }
            : {};
          const newSourceField = buildNewSourceField(
            value.SourceFieldIds as unknown as number[]
          );
          if (Object.keys(newSinkField).length > 0) {
            tmpFields[index].output.push({
              sinkField: newSinkField as unknown as SinkFieldDM,
              sourceFields: newSourceField,
              rules: value.MappingRule as unknown as RuleDM | undefined,
            });
          }
        });
      }
    });
    return tmpFields;
  }

  const checkCompileExpresionAPI = async (
    rulesFields: RulesFieldsType
  ): Promise<CompileExpressionResponseDM> => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let fields = JsonPath({
        keyName: 'FieldID',
        value: '',
        schema: LogixGroup,
        searchWith: 'keyName',
      });
      fields = JSON.parse(JSON.stringify([...fields]));

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const field: any = {
        BaseField: fields.length ? fields[0].value : {},
        FieldValues: [],
      };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const rules: any = JSON.parse(
        JSON.stringify({
          ...{
            RuleName: '',
            RuleType: '',
            Expression: '',
            Description: '',
            RuleScope: 'Field',
            Output: {
              Success: {
                Condition: 'Success',
                Actions: [],
                Messages: [],
              },
              Failure: {
                Condition: 'Failure',
                Actions: [],
                Messages: [],
              },
            },
          },
        })
      );

      rules.Expression = rulesFields.expression;
      rules.RuleName = rulesFields.ruleName;
      rules.RuleType = rulesFields.ruleType;
      field.BaseField.Rules = [];
      field.BaseField.Rules.push(rules);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const apiData: any = {
        LogiXGroup: LogixGroup as LogiXgroupInput,
        Field: field,
        RuleNumber: 0,
      };
      mutationRequest.mutate(
        {
          query: checkCompileExpession,
          params: apiData,
          paramsId: 'RootLogix',
          mutationKey: 'checkCompileExpression',
          token: accessToken ?? '',
        },
        {
          onSuccess: async (
            response: ErrorResponse | CompileExpressionResponseDM
          ) => {
            if ((response as ErrorResponse).errors) {
              reject((response as ErrorResponse).errors);
            } else {
              resolve(response as CompileExpressionResponseDM);
            }
          },
          onError: async (error) => {
            reject(error);
          },
        }
      );
    });
  };

  useEffect(() => {
    if (functionData) {
      setFunctionOpt(
        functionData.GetViewLogixFunctionByType as unknown as GetViewLogixFunctionByType[]
      );
    }
  }, [functionData]);

  return (
    <>
      {isBuildingAction ? (
        <Loading />
      ) : (
        <DatamapperDnd
          fieldsToMap={parsedLogixFields}
          actions={actionsList}
          domainOptions={[]}
          operationNameOptions={[]}
          operationTypeOptions={[]}
          functionTypes={functionOpt}
          handleSave={handleDMSave}
          saveButtonRef={refBtnDM}
          selectOptionsHandler={selectOptionsHandler}
          sinkObjectHandler={sinkObjectHandler}
          mappings={parseLogixSchema()}
          checkCompileExpresionAPI={checkCompileExpresionAPI}
        />
      )}
    </>
  );
}

export default FormDataMapper;
