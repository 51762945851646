import {
  useContext,
  useState,
  useEffect,
  SetStateAction,
  useCallback,
} from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import LockIcon from '@mui/icons-material/Lock';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { deepCopy } from 'utils/deepCopy';
import { IconButton, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import {
  Button,
  MessageActionType,
  MessageType,
  Select,
} from '@revenue-solutions-inc/revxcoreui';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import {
  ColumnDef,
  ColumnDefTemplate,
  CellContext,
  Table,
} from '@tanstack/react-table';
import { ManageConfigToolsContext } from 'components/contexts/ManageConfigToolsProvider/ManageConfigToolsProvider';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { addMessage, clearActionValue } from 'redux/messageSlice';
import { Error } from 'types/graphqlErrors';
import {
  ConfigurationResponse,
  useCreateConfigurationMutation,
  useUpdateConfigurationMutation,
  useDeleteConfigurationMutation,
} from 'generated/graphql';
import { EditAccessUtil } from 'pages/admin/ConfigTypeEditor/editAccessUtil';
import {
  ConfigurationDomains,
  ConfigurationModules,
  configModulesList,
  BusinessDrivers,
} from 'common/platformConfigUtils/platformConfigUtils';
import extractMeaningfulMessage from 'utils/errorMessage';

import {
  checkRequiredAttr,
  findAttributesByDriver,
  validateConfiguration,
} from 'utils/attributeUtils';
import Loading from 'components/Loading/Loading';
import ConfigurationDescription from '../ConfigurationDescription';
import ConfigurationName from '../ConfigurationName';
import DynamicAttributes from '../DynamicAttributes';
import {
  recordIcons,
  fontSize,
  checkButton,
  cancelButton,
  icon,
  lockIcon,
  expandButton,
  collapseButton,
} from '../Shared/IconButtonStyling/IconButtonStyling';

interface Props {
  configData: ConfigurationResponse[];
  setConfigData: (configData: ConfigurationResponse[]) => void;
  refetchTypes: () => void;
  selectedConfigType: ConfigurationResponse;
  editAccess?: EditAccessUtil;
}

function TableManager({
  configData,
  setConfigData,
  refetchTypes,
  selectedConfigType,
  editAccess,
}: Props) {
  const { t } = useTranslation();
  let tableInstance: Table<ConfigurationResponse> | undefined = undefined;
  const { selectedRecord, setSelectedRecord } = useContext(
    ManageConfigToolsContext
  );
  const [recordToDelete, setRecordToDelete] = useState('');
  const [selectedConfigHeader, setSelectedConfigHeader] = useState<
    ConfigurationResponse | undefined
  >(undefined);
  const actionValue = useAppSelector((state) => state.message.actionValue);
  const { mutate: addConfiguration, isLoading: loadingCreateConfig } =
    useCreateConfigurationMutation();
  const { mutate: deleteConfiguration, isLoading: loadingDeleteConfig } =
    useDeleteConfigurationMutation();
  const { mutate: updateConfiguration, isLoading: loadingUpdateConfig } =
    useUpdateConfigurationMutation();
  const dispatch = useAppDispatch();
  const defaultMessage = t('components.message.networkerror');
  const isLoadingAnything =
    loadingCreateConfig || loadingDeleteConfig || loadingUpdateConfig;
  const [errors, setErrors] = useState({});

  const removeConfiguration = useCallback(
    (platformConfigurationId: string) => {
      deleteConfiguration(
        {
          deleteConfigInput: {
            platformConfigurationId,
          },
        },
        {
          onSuccess: () => {
            refetchTypes();
            setSelectedRecord(undefined);
            dispatch(
              addMessage({
                type: MessageType.Success,
                message: t('components.message.success'),
                actionType: MessageActionType.None,
              })
            );
          },
          onError: (e: Error[] | unknown) => {
            const message = extractMeaningfulMessage(e, defaultMessage);
            dispatch(
              addMessage({
                type: MessageType.Error,
                message: String(message),
              })
            );
          },
        }
      );
    },
    [
      defaultMessage,
      deleteConfiguration,
      dispatch,
      refetchTypes,
      setSelectedRecord,
      t,
    ]
  );

  const generateConfigName = (configResponse: ConfigurationResponse) => {
    let configName = configResponse.configurationName;
    const pkAttributes = findAttributesByDriver(
      configResponse,
      BusinessDrivers.PrimaryKey
    );
    if (pkAttributes.length)
      configName = pkAttributes.map((attr) => attr.attributeValue).join('-');
    return configName;
  };

  const saveConfiguration = () => {
    if (selectedRecord == undefined) return;

    const tempErrors = validateConfiguration(selectedRecord);
    setErrors(tempErrors);
    //if there are any errors, skip the save
    if (Object.keys(tempErrors).length > 0) return;

    if (selectedRecord?.platformConfigurationId) {
      updateConfiguration(
        {
          newConfiguration: {
            configurationDescription:
              selectedRecord.configurationDescription ?? '',
            configurationDomain: ConfigurationDomains.ReferenceSchema,
            configurationModule: parseInt(selectedRecord.configurationModule),
            version: selectedRecord.version || 1,
            isSchema: false,
            configurationName: generateConfigName(selectedRecord).trim(),
            configurationType: selectedRecord.configurationType,
            platformConfigurationId: selectedRecord.platformConfigurationId,
            platformConfigurationGroupId:
              selectedRecord.platformConfigurationGroupId,
            platformConfigurationInfo: selectedRecord.platformConfigurationInfo,
          },
        },
        {
          onSuccess: () => {
            refetchTypes();
            setSelectedRecord(undefined);
            setSelectedConfigHeader(undefined);
            setErrors([]);
            dispatch(
              addMessage({
                type: MessageType.Success,
                message: t('components.message.success'),
              })
            );
          },
          onError: (e: Error[] | unknown) => {
            const message = extractMeaningfulMessage(e, defaultMessage);
            dispatch(
              addMessage({
                type: MessageType.Error,
                message: String(message),
              })
            );
          },
        }
      );
    } else {
      addConfiguration(
        {
          newConfiguration: {
            configurationDescription:
              selectedRecord.configurationDescription ?? '',
            configurationDomain: ConfigurationDomains.ReferenceSchema,
            configurationModule: parseInt(selectedRecord.configurationModule),
            version: selectedRecord.version || 1,
            isSchema: false,
            isOOTB: false,
            isOOTBEditable: null,
            configurationName: generateConfigName(selectedRecord).trim(),
            configurationType: selectedRecord.configurationType,
            platformConfigurationInfo: selectedRecord.platformConfigurationInfo,
            platformConfigurationGroupId:
              selectedRecord.platformConfigurationGroupId,
          },
        },
        {
          onSuccess: () => {
            setSelectedRecord(undefined);
            refetchTypes();
            dispatch(
              addMessage({
                type: MessageType.Success,
                message: t('components.message.success'),
              })
            );
          },
          onError: (e: Error[] | unknown) => {
            const message = extractMeaningfulMessage(e, defaultMessage);
            dispatch(
              addMessage({
                type: MessageType.Error,
                message: String(message),
              })
            );
          },
        }
      );
    }
  };

  const handleCopyRow = (configHeader: ConfigurationResponse) => {
    const currIndex = configData.indexOf(configHeader);
    const temp = deepCopy(configData[currIndex]);
    temp.platformConfigurationId = '';
    temp.configurationName = temp.configurationName + '_copy';
    configData.splice(currIndex, 0, temp);
    setConfigData([...configData]);
    setSelectedRecord(temp);
  };

  const handleAddRow = () => {
    if (configData.filter((row) => !row.platformConfigurationId).length === 0) {
      const temp: ConfigurationResponse = {
        configurationName: '',
        configurationDescription: '',
        configurationDomain: ConfigurationDomains.ReferenceSchema.toString(),
        configurationType: selectedConfigType.configurationType,
        configurationModule: selectedConfigType.configurationModule,
        platformConfigurationId: '',
        version: selectedConfigType.version,
        platformConfigurationGroupId:
          selectedConfigType.platformConfigurationGroupId,
        tenantID: '',
        platformConfigurationInfo: deepCopy(
          selectedConfigType.platformConfigurationInfo
        ),
      };
      // For repeating groups, we are using the schema as a template to create new records
      // so we transform the attributes (get rid of them) for a new record,
      // as when adding new attributes it will refer to the original schema to get the attribute configuration
      temp.platformConfigurationInfo?.configurationSection?.forEach(
        (section) => {
          section.group.forEach((g) => {
            if (g.groupAttributeType === 1) {
              g.attribute = [];
            }
          });
        }
      );

      // expand the new row, it has and Id = '', our table is working with id instead of index because we set "customGetRowId"
      tableInstance?.setExpanded((old) => ({
        '': true,
        ...(old as Record<string, boolean>),
      }));
      tableInstance?.setPageIndex(0);

      setConfigData([temp, ...configData]);
      setSelectedRecord(temp);
    }
  };

  const handleEditRow = (
    configHeader: ConfigurationResponse,
    toggleExpand: (expand?: boolean) => void
  ) => {
    toggleExpand(true);
    setSelectedConfigHeader(deepCopy(configHeader));
    setSelectedRecord({ ...configHeader });
  };

  const handleCancel = (toggleExpand: (expand?: boolean) => void) => {
    //if we were creating a new record and cancelled before saving, take it back out of the data.
    toggleExpand(false);
    setSelectedRecord(undefined);
    setConfigData(
      configData
        .map((config) =>
          config.platformConfigurationId ===
          selectedConfigHeader?.platformConfigurationId
            ? selectedConfigHeader
            : config
        )
        .filter((row) => !!row.platformConfigurationId)
    );
    // after the selectedConfigHeader was used to roll back the data
    setSelectedConfigHeader(undefined);
    setErrors([]);
  };

  useEffect(() => {
    setSelectedRecord(undefined);
    // when selectedConfigType changes from the parent
    tableInstance?.setPageSize(10);
    tableInstance?.setPageIndex(0);
  }, [selectedConfigType, setSelectedRecord, tableInstance]);

  useEffect(() => {
    if (actionValue === 'message_continue' && recordToDelete) {
      removeConfiguration(recordToDelete);
      dispatch(clearActionValue());
    }
  }, [actionValue, dispatch, recordToDelete, removeConfiguration]);

  const configHeaderColumns: ColumnDef<ConfigurationResponse>[] = [
    {
      header: () => <HeaderColumnNext localization={''} />,
      id: 'edit',
      cell: ({ row }) => {
        if (
          row.original.platformConfigurationId !==
          selectedRecord?.platformConfigurationId
        ) {
          return (
            <IconButton
              onClick={() =>
                handleEditRow(
                  row.original as ConfigurationResponse,
                  row.toggleExpanded
                )
              }
              sx={recordIcons}
              aria-label="edit-button"
              title={t('pages.configRecordEditor.editIcon')}
              disabled={
                selectedRecord !== undefined ||
                !editAccess?.controlWithOOTBEditable(
                  !!row.original.isOOTB,
                  !!row.original.isOOTBEditable
                )
              }
            >
              <EditIcon sx={{ fontSize }} />
            </IconButton>
          );
        } else {
          return (
            <>
              <Stack direction="row" spacing={0.5} mr={'0.5em'}>
                <IconButton
                  onClick={saveConfiguration}
                  aria-label="check-button"
                  title={t('pages.configRecordEditor.confirmIcon')}
                  disabled={checkRequiredAttr(row.original, selectedRecord)}
                  sx={checkButton}
                  size="small"
                >
                  <CheckIcon fontSize="small" sx={icon} />
                </IconButton>
                <IconButton
                  title={t('pages.configRecordEditor.cancelIcon')}
                  onClick={() => handleCancel(row.toggleExpanded)}
                  size="small"
                  sx={cancelButton}
                >
                  <CloseIcon fontSize="small" sx={icon} />
                </IconButton>
              </Stack>
            </>
          );
        }
      },
    },
    {
      header: () => (
        <HeaderColumnNext
          localization={t('pages.configRecordEditor.configName')}
        />
      ),
      id: 'configurationName',
      accessorKey: 'configurationName',
      cell: ConfigurationName as unknown as ColumnDefTemplate<
        CellContext<ConfigurationResponse, unknown>
      >,
    },
    {
      header: () => (
        <HeaderColumnNext
          localization={t('pages.configRecordEditor.configDesc')}
        />
      ),
      id: 'configurationDescription',
      accessorKey: 'configurationDescription',
      cell: ConfigurationDescription as unknown as ColumnDefTemplate<
        CellContext<ConfigurationResponse, unknown>
      >,
    },
    {
      header: () => (
        <HeaderColumnNext
          localization={t('pages.configRecordEditor.configModule')}
        />
      ),
      id: 'configurationModule',
      accessorKey: 'configurationModule',
      cell: ({ row }) => {
        if (
          row.original.platformConfigurationId !==
          selectedRecord?.platformConfigurationId
        ) {
          return (
            <Typography>
              {ConfigurationModules[Number(row.original.configurationModule)] ??
                row.original.configurationModule}
            </Typography>
          );
        } else {
          return (
            <Select
              id="module-dropdown-table-manager"
              width="15em"
              label={t('pages.configRecordEditor.configModule')}
              value={selectedRecord.configurationModule}
              options={configModulesList}
              onChange={(event) => {
                const tempRecord = { ...selectedRecord };
                tempRecord.configurationModule = (
                  event.target as HTMLSelectElement
                ).value;
                setSelectedRecord(tempRecord);
              }}
              disabled={selectedConfigType.configurationModule !== '3'}
              required
            />
          );
        }
      },
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.configRecordEditor.locked')} />
      ),
      id: 'isOOTB',
      accessorKey: 'isOOTB',
      cell: ({ row }) => {
        if (
          row.original.platformConfigurationId !==
          selectedRecord?.platformConfigurationId
        ) {
          if (!!row.original.isOOTB)
            return (
              <Box title={t('pages.configRecordEditor.coreRecordLegend')}>
                <IconButton sx={lockIcon} disabled aria-label="ootb-button">
                  <LockIcon sx={{ fontSize }} />
                </IconButton>
              </Box>
            );
          else {
            return (
              <Box title={t('pages.configRecordEditor.editableRecord')}>
                <IconButton sx={lockIcon} disabled aria-label="ootb-button">
                  <LockOpenIcon sx={{ fontSize }} />
                </IconButton>
              </Box>
            );
          }
        } else {
          return <></>;
        }
      },
    },
    {
      header: () => <HeaderColumnNext localization={''} />,
      id: 'clone',
      cell: ({ row }) => {
        return (
          <IconButton
            sx={recordIcons}
            onClick={() => handleCopyRow(row.original)}
            aria-label="copy-button"
            title={t('pages.configRecordEditor.cloneRecord')}
            disabled={
              selectedRecord !== undefined ||
              !editAccess?.controlWithOOTBEditable(
                !!row.original.isOOTB,
                !!row.original.isOOTBEditable
              )
            }
          >
            <FileCopyIcon sx={{ fontSize }} />
          </IconButton>
        );
      },
    },
    {
      header: () => <HeaderColumnNext localization={''} />,
      id: 'remove',
      cell: ({ row }) => {
        return (
          <IconButton
            sx={recordIcons}
            onClick={() => {
              if (row.original) {
                setRecordToDelete(
                  row.original.platformConfigurationId as SetStateAction<string>
                );
              }
              dispatch(
                addMessage({
                  message: t('pages.configGroupEditor.confirmButtonMsg'),
                  type: MessageType.Info,
                  actionType: MessageActionType.Continue,
                })
              );
            }}
            aria-label="delete-button"
            title={t('pages.configRecordEditor.deleteRecord')}
            disabled={
              selectedRecord !== undefined ||
              !editAccess?.controlWithOOTBEditable(
                !!row.original.isOOTB,
                !!row.original.isOOTBEditable
              )
            }
          >
            <DeleteIcon sx={{ fontSize }} />
          </IconButton>
        );
      },
    },
    {
      header: () => <HeaderColumnNext localization={''} />,
      id: 'expander',
      cell: ({ row, table }) => {
        if (row.original.platformConfigurationInfo) {
          return (
            <IconButton
              {...{
                onClick: () => row.toggleExpanded(),
              }}
              sx={row.getIsExpanded() ? collapseButton : expandButton}
              title={t('pages.configRecordEditor.viewFields')}
              aria-label="expand-button"
            >
              <ExpandCircleDownIcon />
            </IconButton>
          );
        } else {
          if (row.getIsExpanded()) table.toggleAllRowsExpanded(false);
          return <></>;
        }
      },
    },
  ];

  return (
    <>
      <DefaultDataTableNext
        columns={configHeaderColumns}
        data={configData}
        customGetRowId={(row) => row.platformConfigurationId}
        customHeader={({ setFilterValue, table }) => {
          tableInstance = table;
          return (
            <Box
              sx={{
                justifyContent: 'start',
                width: '100%',
                marginTop: '.5em',
              }}
            >
              <Button
                onClick={() => {
                  setFilterValue('');
                  handleAddRow();
                }}
                id={'tableManager-AddBtn'}
                disabled={
                  selectedRecord !== undefined || !editAccess?.generalEdit
                }
              >
                {t('pages.configRecordEditor.addRecord')}
              </Button>
            </Box>
          );
        }}
        renderRowSubComponent={(row) => (
          <DynamicAttributes
            currentData={row.original}
            currentSchema={selectedConfigType}
            editDisabled={
              row.original.platformConfigurationId !==
              selectedRecord?.platformConfigurationId
            }
            errors={errors}
            selectedModule={selectedRecord?.configurationModule}
          />
        )}
      />
      {isLoadingAnything && <Loading />}
    </>
  );
}

export default TableManager;
