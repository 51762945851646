import { gql } from 'graphql-request';

const preProcessCheckCompileExpession = gql`
  mutation CheckPreProcessCompileExpression(
    $rootLogix: PreProcessRootLogixInput!
  ) {
    checkPreProcessCompileExpression(RootLogix: $rootLogix) {
      Result
    }
  }
`;

export default preProcessCheckCompileExpession;
