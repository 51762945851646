import { useState } from 'react';
import { addMessage } from 'redux/messageSlice';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'redux/hooks';
import {
  MessageType,
  MessageActionType,
} from '@revenue-solutions-inc/revxcoreui';
import extractMeaningfulMessage from 'utils/errorMessage';
import {
  useQueryPlatformOrModuleQuery,
  QueryPlatformOrModuleQueryVariables,
} from 'generated/graphql';

export type ModuleExpanded = {
  name: string;
  moduleId: number;
  idx: number;
};

const ModuleDefault = {
  name: '',
  moduleId: 0,
  idx: 0,
};

const useModules = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [modules, setModules] = useState<ModuleExpanded[]>([ModuleDefault]);

  const { isFetching: isFetchingModules } = useQueryPlatformOrModuleQuery<{
    Modules: QueryPlatformOrModuleQueryVariables[];
  }>(
    {},
    {
      onSuccess: (data) => {
        if (data?.Modules?.length > 0) {
          const modulesWithOrder: ModuleExpanded[] = data.Modules.map(
            ({ name, moduleId }, index) => {
              return {
                name,
                moduleId,
                idx: index + 1,
              };
            }
          );
          setModules(modulesWithOrder);
        }
      },
      onError: (error) => {
        const message = extractMeaningfulMessage(
          error,
          t('pages.manageReusableContent.networkError')
        );
        dispatch(
          addMessage({
            message: message,
            type: MessageType.Error,
            actionType: MessageActionType.None,
          })
        );
      },
    }
  );

  return {
    modules,
    isFetchingModules,
  };
};
export default useModules;
