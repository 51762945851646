import { Grid, Typography } from '@mui/material';
import error from 'assets/errorbg.png';
import ExploreIcon from '@mui/icons-material/Explore';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function PageNotFound(): JSX.Element {
  const { t } = useTranslation();

  const history = useNavigate();
  return (
    <Grid
      container
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        mt: '16%',
      }}
    >
      <Grid
        item
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          backgroundImage: `url(${error})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          p: 3,
          borderTopRightRadius: '24px',
          width: '100%',
        }}
        xs={4}
      >
        <Typography variant="h1" color="common.white">
          {t('components.pagenotfound.title')}
        </Typography>
        <ExploreIcon
          sx={{
            color: 'common.white',
            fontSize: 30,
          }}
        />
      </Grid>
      <Grid
        item
        xs={4}
        sx={{
          p: 2,
          backgroundColor: 'common.white',
          width: '100%',
        }}
      >
        <Typography variant="h2">
          {t('components.pagenotfound.goto')}
          <Link to="/"> {t('components.pagenotfound.dashboard')}</Link>
          {t('components.pagenotfound.or')}
          <Link
            to={' '}
            onClick={() => {
              history(-1);
            }}
          >
            {t('components.pagenotfound.back')}
          </Link>
          {t('components.pagenotfound.towhere')}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default PageNotFound;
