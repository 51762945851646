import { Theme } from '@mui/system';
import { DataDisplay } from '@revenue-solutions-inc/revxcoreui';

export interface HeaderElementProps {
  id: string;
  label: string | null | undefined;
  value: string | null | undefined;
  first?: boolean;
}

function HeaderElement({
  id,
  label,
  value,
  first = false,
}: HeaderElementProps): JSX.Element {
  const props = first
    ? (theme: Theme) => ({
        color: theme.palette.primaryRed.dark,
        fontSize: '1em',
        width: '100%',
      })
    : { width: '100%', color: 'primary.main', fontSize: '1em' };
  return (
    <DataDisplay
      id={`label-${id}`}
      label={label ?? ''}
      data={value ?? ''}
      sx={props}
      sxLabel={{ fontSize: '16px' }}
    />
  );
}

export default HeaderElement;
