export type TBusinessPolicy = {
  customerBusinessPolicyId: string;
  name: string;
  description: string;
  roles: string[];
};

export interface IBusinessPolicyError {
  isError: boolean;
  message: string;
}

export interface IBusinessPolicyErrors {
  customerBusinessPolicyId: IBusinessPolicyError;
  name: IBusinessPolicyError;
  description: IBusinessPolicyError;
  disableButton: boolean;
}
export interface IBusinessPolicy {
  customerBusinessPolicyId: string;
  name: string;
  description: string;
  roles: string[];
}

export const businessPolicyDefault: IBusinessPolicy = {
  customerBusinessPolicyId: '',
  description: '',
  name: '',
  roles: [],
};

export const businessPolicyDefaultError: IBusinessPolicyError = {
  isError: false,
  message: '',
};

export const businessPolicyDefaultErrors: IBusinessPolicyErrors = {
  customerBusinessPolicyId: businessPolicyDefaultError,
  name: businessPolicyDefaultError,
  description: businessPolicyDefaultError,
  disableButton: false,
};
