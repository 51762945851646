import { useEffect, useState } from 'react';
import Loading from 'components/Loading';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'redux/hooks';
import { IHeader, setHeader } from 'redux/contentSlice';
import { useGetChannelHistoryQuery } from 'generated/graphql';
import DownloadIcon from '@mui/icons-material/Download';
import RunHistoryTable from './RunHistoryTable';
import VersionHistory from './VersionHistoryTable';

function ChannelDetailsPage(): JSX.Element {
  const dispatch = useAppDispatch();
  const [initialRender, setInitialRender] = useState<boolean>(true);
  const [versionValue, setVersionValue] = useState<number>(0);
  const { channelId, channelName, currentModule } = useParams() as {
    channelId: string;
    channelName: string;
    currentModule: string;
  };
  const { t } = useTranslation();

  const { data, isLoading } = useGetChannelHistoryQuery({
    module: currentModule,
    channelName: channelName,
  });

  const [openErrorMessage, setOpenErrorMessage] = useState<boolean>(false);

  const handleClose = () => {
    setOpenErrorMessage(false);
  };

  const [openDelete, setIsOpenDelete] = useState<boolean>(false);

  useEffect(() => {
    if (initialRender === false && !isLoading && data) {
      const versionData = data.getChannelHistory.map((item) => {
        if (item.Status === 'Active') {
          return item.Version as unknown as number;
        } else {
          return 0;
        }
      });

      const versionValueData = versionData.find((element) => element > 0);
      if (versionValueData) {
        setVersionValue(versionValueData);
      }
    }
    setInitialRender(false);
  }, [data, data?.getChannelHistory, initialRender, isLoading]);

  useEffect(() => {
    if (!isLoading && data) {
      const headerData: IHeader = {
        pageTitle: t('pages.manageChannel.channelDetails'),
        previousPage: t('pages.manageChannel.title'),
        route: `managechannels/`,
        icon: {
          props: { fill: 'black' },
          icon: 'assignmentIcon',
          fontSize: 'large',
        },
        data: [
          {
            id: 'channel-display-name',
            first: true,
            label: t('pages.manageChannel.name'),
            value: channelName,
          },
          {
            id: 'channel-display-id',
            label: t('pages.manageChannel.id'),
            value: channelId,
          },
          {
            id: 'active-version',
            label: t('pages.manageChannel.activeVersion'),
            value: versionValue > 0 ? versionValue.toString() : 'N/A',
          },
        ],
        buttonLinks: [
          {
            action() {
              setIsOpenDelete(true);
            },
            id: 'archive-channel',
            title: t('pages.manageChannel.archiveChannel'),
            type: 'secondary',
            startIcon: <DownloadIcon />,
          },
        ],
      };
      dispatch(setHeader(headerData));
    }
  }, [isLoading, data, dispatch, t, channelName, channelId, versionValue]);

  if (!isLoading && !initialRender) {
    return (
      <Grid container>
        <Grid item xs={12} mb={3}>
          <RunHistoryTable
            isOpenDelete={openDelete}
            setIsOpenDelete={setIsOpenDelete}
            openErrorMessage={openErrorMessage}
            setOpenErrorMessage={setOpenErrorMessage}
            handleClose={handleClose}
            currentModule={currentModule}
          />
        </Grid>
        <Grid item xs={12}>
          <VersionHistory
            channelName={channelName}
            currentModule={currentModule}
          />
        </Grid>
      </Grid>
    );
  }

  if (isLoading || initialRender) {
    return <Loading />;
  }
  return <></>;
}

export default ChannelDetailsPage;
