import { Box, CardActions, Typography } from '@mui/material';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import { ColumnDef } from '@tanstack/react-table';
import { CreateUserInput, CreateUserResponse } from 'generated/graphql';
import { useHasAccess } from 'hooks/useHasAccess';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';

interface Props {
  userValues: CreateUserInput[];
  setSummary: React.Dispatch<React.SetStateAction<boolean>>;
  dataResponse: CreateUserResponse | undefined;
}

export default function AddUserSummary({
  userValues,
  setSummary,
  dataResponse,
}: Props) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.addUser',
  });
  const module = useAppSelector((state) => state.user.module);
  const navigate = useNavigate();
  const accessAssignRole = useHasAccess('addUser', 'edit');

  const columns: ColumnDef<CreateUserInput>[] = [
    {
      id: 'First Name',
      accessorKey: 'firstName',
      header: () => <HeaderColumnNext localization={t('firstName')} />,
    },

    {
      id: 'Last Name',
      accessorKey: 'lastName',
      header: () => <HeaderColumnNext localization={t('lastName')} />,
    },

    {
      id: 'User Email',
      accessorKey: 'userEmail',
      header: () => <HeaderColumnNext localization={t('emailAddress')} />,
    },
  ];

  return (
    <>
      <Typography color="text.primary" sx={{ mb: 1 }}>
        {t('userAdded')}
      </Typography>
      <Box sx={{ ml: '1.5em', mb: '1.5em' }}>
        <DefaultDataTableNext
          data={userValues}
          columns={columns as ColumnDef<Record<string, unknown>>[]}
          enableGlobalFilter={false}
        />
      </Box>
      <CardActions sx={{ justifyContent: 'flex-end', py: 3, px: 2 }}>
        <Button
          disabled={!accessAssignRole}
          id="assignRoleButton"
          onClick={() =>
            navigate(
              `/${module}/manageuserroles/edit/${dataResponse?.userId}?from=addUserSummary`
            )
          }
        >
          {t('buttons.assignRole')}
        </Button>
        <Button
          id="createAnotherNewUserButton"
          onClick={() => {
            setSummary(false);
          }}
        >
          {t('buttons.createAnotherNewUser')}
        </Button>
        <Button
          id="cancelButton"
          onClick={() => navigate(`/${module + '/dashboard'}`)}
        >
          {t('buttons.cancel')}
        </Button>
      </CardActions>
    </>
  );
}
