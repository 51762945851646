import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import { CircularProgress, Grid, Typography } from '@mui/material';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import Dialog from '@revenue-solutions-inc/revxcoreui/material/controls/Dialog';
import { deepCopy } from 'utils/deepCopy';
import { ColumnDef, Row } from '@tanstack/react-table';
import { Checkbox, MessageType } from '@revenue-solutions-inc/revxcoreui';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import DateCell from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/TableCells/DateCell';
import { useTranslation } from 'react-i18next';
import {
  CreateAccountPeriodInput,
  CreateMultipleAccountPeriodsInput,
  InputMaybe,
  useCreateMultipleAccountPeriodsMutation,
} from 'generated/graphql';
import { useAppDispatch } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import extractMeaningfulMessage from 'utils/errorMessage';
import { AvailablePeriod } from '../AccountPeriods/AccountPeriods';

interface AddPeriodProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  availablePeriods: AvailablePeriod[];
  setAvailablePeriods: Dispatch<SetStateAction<AvailablePeriod[]>>;
  availablePeriodsLoading: boolean;
  availablePeriodsError: boolean;
}

function AddPeriodModal({
  open,
  setOpen,
  availablePeriods,
  setAvailablePeriods,
  availablePeriodsLoading,
  availablePeriodsError,
}: AddPeriodProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const { accountId } = useParams() as { accountId: string };
  const [selectedPeriods, setSelectedPeriods] =
    useState<CreateMultipleAccountPeriodsInput>({
      financialAccountPeriods: [],
    });
  const [selectAllPeriods, setSelectAllPeriods] = useState<boolean>(false);

  const { mutate } = useCreateMultipleAccountPeriodsMutation({});

  const periodSelected = () => {
    return (
      selectedPeriods.financialAccountPeriods &&
      selectedPeriods.financialAccountPeriods?.length > 0
    );
  };

  const handleSavePeriods = () => {
    if (periodSelected()) {
      mutate(
        {
          periods: selectedPeriods,
          accountId: accountId,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({
              queryKey: ['GetAccountPeriodsAndBalance'],
            });
            queryClient.invalidateQueries({
              queryKey: ['GetPotentialPeriodsByAccountId'],
            });
            setSelectedPeriods({
              financialAccountPeriods: [],
            });
            dispatch(
              addMessage({
                type: MessageType.Success,
                message:
                  selectedPeriods.financialAccountPeriods &&
                  selectedPeriods.financialAccountPeriods?.length > 1
                    ? t('pages.accountSummary.message.createPeriods')
                    : t('pages.accountSummary.message.createPeriod'),
              })
            );
            setOpen(false);
          },
          onError: (error) => {
            const message = extractMeaningfulMessage(
              error,
              t('pages.accountSummary.message.createPeriodError')
            );
            dispatch(
              addMessage({
                type: MessageType.Error,
                message: message,
              })
            );
          },
        }
      );
    }
  };

  const handleSelectAll = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const newPeriods = deepCopy(availablePeriods);
    newPeriods.forEach((period) => (period.checked = checked));
    setAvailablePeriods(newPeriods);
    setSelectAllPeriods(event.target.checked);
    const newSelectedPeriods: InputMaybe<CreateAccountPeriodInput[]> = [];

    if (checked) {
      availablePeriods?.map((period) => {
        newSelectedPeriods?.push({
          id: period.id,
          beginDate: period.beginDate,
          endDate: period.endDate,
          group: [
            {
              groupName: 'Period Attribute',
              groupOrder: '0',
              groupAttributeType: 0,
              repeatingActionLabel: null,
              attribute: [
                {
                  attributeName: 'DueDate',
                  attributeDisplayName: 'Due Date',
                  attributeType: 'date',
                  attributeValue: period.dueDate
                    ? period.dueDate?.toString()
                    : '',
                  repeatingProperties: false,
                  possibleValues: null,
                },
              ],
            },
          ],
        });
      });
    }
    setSelectedPeriods({
      financialAccountPeriods: newSelectedPeriods,
    });
  };

  const addPeriod = (
    currentPeriod: AvailablePeriod,
    selectedPeriodsList: InputMaybe<CreateAccountPeriodInput[]> | undefined
  ) => {
    const periodList = selectedPeriodsList;
    periodList?.push({
      id: currentPeriod.id,
      beginDate: currentPeriod.beginDate,
      endDate: currentPeriod.endDate,
      group: [
        {
          groupName: 'Period Attribute',
          groupOrder: '0',
          groupAttributeType: 0,
          repeatingActionLabel: null,
          attribute: [
            {
              attributeName: 'DueDate',
              attributeDisplayName: 'Due Date',
              attributeType: 'date',
              attributeValue: currentPeriod.dueDate
                ? currentPeriod.dueDate?.toString()
                : '',
              repeatingProperties: false,
              possibleValues: null,
            },
          ],
        },
      ],
    });
    return periodList;
  };

  const removePeriod = (
    id: number,
    selectedPeriodsList: InputMaybe<CreateAccountPeriodInput[]> | undefined
  ) => {
    return selectedPeriodsList?.filter((item) => item.id !== id);
  };

  const handleSelectPeriod = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
    row: Row<AvailablePeriod>
  ) => {
    const newPeriods = deepCopy(availablePeriods);
    const currentPeriod = newPeriods[row.original.id];
    let newSelectedPeriods = selectedPeriods?.financialAccountPeriods;

    if (checked) {
      newSelectedPeriods = addPeriod(currentPeriod, newSelectedPeriods);
    } else {
      newSelectedPeriods = removePeriod(currentPeriod.id, newSelectedPeriods);
    }
    currentPeriod.checked = event.target.checked;
    setAvailablePeriods(newPeriods);
    setSelectedPeriods({
      financialAccountPeriods: newSelectedPeriods,
    });
  };

  const addPeriodColumns: ColumnDef<AvailablePeriod>[] = [
    {
      header: () => {
        return (
          <Checkbox
            id={'selectAllCheckbox'}
            label={''}
            checked={selectAllPeriods}
            onChange={handleSelectAll}
            sx={{ ml: 1 }}
          />
        );
      },
      id: 'id',
      cell: ({ row }) => {
        return (
          <Checkbox
            id={'periodChk'}
            checked={availablePeriods[row.original.id].checked}
            label={''}
            onChange={(event, checked) => {
              handleSelectPeriod(event, checked, row);
            }}
            sx={{ ml: 1 }}
          />
        );
      },
    },
    {
      id: 'beginDate',
      accessorKey: 'beginDate',
      header: () => (
        <HeaderColumnNext localization={t('pages.accountSummary.startDate')} />
      ),
      cell: ({ getValue }) => <DateCell dateString={getValue() as string} />,
    },
    {
      id: 'endDate',
      accessorKey: 'endDate',
      header: () => (
        <HeaderColumnNext localization={t('pages.accountSummary.endDate')} />
      ),
      cell: ({ getValue }) => <DateCell dateString={getValue() as string} />,
    },
    {
      id: 'dueDate',
      accessorKey: 'dueDate',
      header: () => (
        <HeaderColumnNext localization={t('pages.accountSummary.dueDate')} />
      ),
      cell: ({ getValue }) => <DateCell dateString={getValue() as string} />,
    },
  ];

  const modalInnerContent = (
    <Grid container>
      {availablePeriods.length > 0 && (
        <DefaultDataTableNext
          columns={
            addPeriodColumns as ColumnDef<Record<string, unknown>, unknown>[]
          }
          data={availablePeriods as unknown as Record<string, unknown>[]}
          enableGlobalFilter
          isSimplePagination
          sx={{ justifyContent: 'flex-start' }}
        />
      )}
      {availablePeriodsLoading && (
        <Typography variant="inherit">
          <CircularProgress sx={{ margin: '20px' }} />
        </Typography>
      )}
      {availablePeriods.length === 0 && !availablePeriodsLoading && (
        <Typography variant="inherit">
          {availablePeriodsError
            ? t('pages.accountSummary.availablePeriodError')
            : t('pages.accountSummary.noAvailablePeriods')}
        </Typography>
      )}
    </Grid>
  );

  return (
    <Dialog
      id="addPeriodDialog"
      open={open}
      children={modalInnerContent}
      title={t('pages.accountSummary.addPeriod')}
      type={availablePeriods.length > 0 ? 'transactional' : 'passive'}
      transactionModalTransactionButtonText={t(
        'pages.accountSummary.createPeriods'
      )}
      sx={{
        '& .MuiButtonBase-root': {
          textTransform: 'initial',
        },
      }}
      handleClose={() => {
        setOpen(false);
      }}
      handleCancelClick={() => {
        setOpen(false);
      }}
      handleTransactionClick={() => {
        handleSavePeriods();
      }}
    />
  );
}
export default AddPeriodModal;
