import { Box, Divider, Grid, Typography, useTheme } from '@mui/material';
import ButtonLink from '@revenue-solutions-inc/revxcoreui/material/controls/ButtonLink';
import DataDisplay from '@revenue-solutions-inc/revxcoreui/material/controls/DataDisplay';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import CurrencyCell from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/TableCells/CurrencyCell';
import { ColumnDef } from '@tanstack/react-table';
import Loading from 'components/Loading';
import { CreateAdjustmentResponse } from 'generated/graphql';
import { useTranslation } from 'react-i18next';

interface AdjustmentPreviewProps {
  previewData: CreateAdjustmentResponse | undefined;
  handleEditAdjustment: () => void;
  isLoading: boolean;
}

function AdjustmentPreview({
  previewData,
  handleEditAdjustment,
  isLoading,
}: AdjustmentPreviewProps) {
  const { t } = useTranslation();
  const theme = useTheme();

  const totalAmount = previewData?.financialTransactions?.amount;

  function impactTypesData() {
    return previewData?.applyTo
      ? (previewData?.applyTo as Record<string, unknown>[])
      : [];
  }

  const columns: ColumnDef<Record<string, unknown>>[] = [
    {
      id: 'ImpactType',
      accessorKey: 'name',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.periodDetails.adjustments.impactType')}
        />
      ),
    },
    {
      id: 'Amount',
      accessorKey: 'amount',
      header: () => (
        <HeaderColumnNext
          localization={
            previewData?.reductionType === 'Percent'
              ? t('pages.periodDetails.adjustments.percent')
              : t('pages.periodDetails.adjustments.amount')
          }
        />
      ),
      cell: ({ getValue }) => {
        return (
          <Box sx={{ width: '3.5em' }}>
            {previewData?.reductionType === 'Percent' ? (
              `${getValue() as string}%`
            ) : (
              <CurrencyCell
                invalidValue={''}
                amountString={getValue() as string}
              />
            )}
          </Box>
        );
      },
    },
  ];

  const header = (
    <Grid item xs={12}>
      <Typography variant="h2" sx={{ textAlign: 'center' }}>
        {t('pages.periodDetails.adjustments.confirmationMsg')}
      </Typography>
      <Divider
        sx={{
          mt: '20px',
          backgroundColor: theme.palette.grey2.light,
        }}
      />
    </Grid>
  );

  const bodyContent = (
    <>
      <Grid item xs={4} mt={1}>
        <DataDisplay
          id="adjustmentType"
          label={t('pages.periodDetails.adjustments.adjustmentType')}
          data={previewData?.adjustmentType ?? '-'}
        />
      </Grid>
      <Grid item xs={4} mt={1}>
        <DataDisplay
          id="adjustmentReason"
          label={t('pages.periodDetails.adjustments.adjustmentReason')}
          data={previewData?.adjustmentReason ?? '-'}
        />
      </Grid>
      <Grid item xs={4} mt={1}>
        <DataDisplay
          id="reductionType"
          label={t('pages.periodDetails.adjustments.reductionType')}
          data={previewData?.reductionType ?? '-'}
        />
      </Grid>
      <Grid item xs={12}>
        <DefaultDataTableNext
          data={impactTypesData()}
          columns={columns}
          enableGlobalFilter={false}
        />
      </Grid>
    </>
  );

  const footer = (
    <Grid item xs={12} mt={1}>
      <Box
        sx={{
          display: 'inline-flex',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ display: 'inline-flex' }}>
          <Typography variant="h2" sx={{ mr: '5px' }}>
            {t('pages.periodDetails.adjustments.total')}:{' '}
          </Typography>
          <Typography variant="h3" sx={{ mt: '2px' }}>
            <CurrencyCell
              invalidValue={''}
              amountString={totalAmount as unknown as string}
            />
          </Typography>
        </Box>
        <ButtonLink
          id="editAdjustment"
          text={t('pages.periodDetails.adjustments.edit')}
          href="#"
          color="primary"
          handleChange={() => handleEditAdjustment()}
        />
      </Box>
    </Grid>
  );

  const loadingIndicator = (
    <>
      {isLoading && (
        <Box sx={{ margin: '0 auto', my: '4em' }}>
          <Loading fullScreen={false} />
        </Box>
      )}
    </>
  );

  return (
    <>
      {loadingIndicator}
      {!isLoading && (
        <>
          {header}
          {bodyContent}
          {footer}
        </>
      )}
    </>
  );
}
export default AdjustmentPreview;
