import ListItemIcon from '@mui/material/ListItemIcon';
import { Link } from 'react-router-dom';
import { List, ListItem, ListItemText, Grid, Icon } from '@mui/material';
import { useTheme } from '@mui/system';
import { useTranslation } from 'react-i18next';
import SettingsInputHdmiIcon from '@mui/icons-material/SettingsInputHdmi';
import SettingsIcon from '@mui/icons-material/Settings';
import EmailIcon from '@mui/icons-material/Email';
import SettingsInputComponentIcon from '@mui/icons-material/SettingsInputComponent';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CodeIcon from '@mui/icons-material/Code';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import ContactsIcon from '@mui/icons-material/Contacts';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import AssignmentIcon from '@mui/icons-material/Assignment';
import EventIcon from '@mui/icons-material/Event';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import AddchartIcon from '@mui/icons-material/Addchart';
import { accessConfig } from 'utils/routeAccessConfig';
import { useAppSelector } from 'redux/hooks';
import { ConfigItem } from 'components/layout/Sidebar/Sidebar';

type MenuConfigProps = {
  links: ConfigItem[];
  module: string;
};

const MenuConfig = ({ links, module }: MenuConfigProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const roles = useAppSelector((state) => state.user.roles);

  const getIcon = (label: string) => {
    const sx = {
      fontSize: '20px',
    };

    const labelMap: { [key: string]: () => React.JSX.Element } = {
      channel: () => <SettingsInputHdmiIcon sx={sx} />,
      configuration: () => <SettingsIcon sx={sx} />,
      correspondence: () => <EmailIcon sx={sx} />,
      'data mapper': () => <SettingsInputComponentIcon sx={sx} />,
      forms: () => <InsertDriveFileIcon sx={sx} />,
      docs: () => <InsertDriveFileIcon sx={sx} />,
      logix: () => <CodeIcon sx={sx} />,
      'policy groups': () => <GroupWorkIcon sx={sx} />,
      roles: () => <AssignmentIcon sx={sx} />,
      'roles and policies': () => <AssignmentIcon sx={sx} />,
      'scheduled tasks': () => <EventIcon sx={sx} />,
      tenants: () => <ContactsIcon sx={sx} />,
      users: () => <AssignmentIndIcon sx={sx} />,
      work: () => <AutorenewIcon sx={sx} />,
      workflows: () => <AutorenewIcon sx={sx} />,
      'entity management': () => <VpnKeyIcon sx={sx} />,
      batches: () => <ShoppingBasketIcon sx={sx} />,
      deposits: () => <SystemUpdateAltIcon sx={sx} />,
      test: () => <DeveloperModeIcon sx={sx} />,
      reports: () => <AddchartIcon sx={sx} />,
      dashboards: () => <DashboardCustomizeIcon sx={sx} />,
    } as const;

    if (labelMap[label.toLowerCase()]) {
      return labelMap[label.toLowerCase()]();
    }

    return null;
  };

  // this code is the same as the hook useHasAccess we can not use the hook here since the render are
  // inside maps and loops we would get "Render fewer hooks than expected", keep working like this meanwhile
  const PolicyGroupExist = (componentName: string): boolean => {
    let isPolicyGroupExist = false;
    if (accessConfig[componentName]?.view?.length) {
      roles?.forEach((role) => {
        role.policyGroups.forEach((item) => {
          if (
            accessConfig[componentName].view.some(
              (pGroup: string) =>
                pGroup.toLowerCase() ===
                item.name.replace(/\s/g, '').toLowerCase()
            )
          ) {
            isPolicyGroupExist = true;
          }
        });
      });
    } else {
      isPolicyGroupExist = true;
    }
    return isPolicyGroupExist;
  };

  const sortItems = (items: ConfigItem[] | []) => {
    if (items.length === 0) return items;
    const compareLabels = (a: ConfigItem, b: ConfigItem) => {
      const labelA = t(a.label).toLowerCase();
      const labelB = t(b.label).toLowerCase();

      if (labelA > labelB) {
        return 1;
      } else if (labelB > labelA) {
        return -1;
      } else {
        return 0;
      }
    };

    return items.sort(compareLabels);
  };

  return (
    <>
      {sortItems(links).map((listItem: ConfigItem) => {
        if ((listItem?.items?.length ?? 0) > 0) {
          return (
            <Grid
              item
              xs={12}
              sm={6}
              lg={3}
              sx={{ marginBottom: '32px', display: 'flex' }}
              key={t(listItem.label)}
            >
              <Grid>
                <ListItemIcon
                  sx={{
                    mt: 1,
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Icon
                    sx={{
                      backgroundColor: 'grey1.main',
                      color: 'white.main',
                      borderRadius: '50%',
                      width: '1.5em',
                      height: '1.5em',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      '&.MuiButtonBase-root:hover': {
                        backgroundColor: 'grey1.main',
                        color: 'white.main',
                      },
                    }}
                  >
                    {getIcon(t(listItem.label))}
                  </Icon>
                </ListItemIcon>
              </Grid>
              <Grid>
                <ListItemText
                  sx={{
                    mb: '20px',
                    height: '20px',
                    '& .MuiListItemText-primary': {
                      fontWeight: '500',
                      fontSize: '20px',
                      color: theme.palette.primary.main,
                    },
                  }}
                  primary={t(listItem.label)}
                />

                <List disablePadding>
                  {sortItems(listItem?.items ?? []).map(
                    (subList: ConfigItem) => {
                      return (
                        <ListItem
                          key={subList.id}
                          disablePadding={true}
                          sx={{
                            mb: '5px',
                            fontSize: '16px',
                            '& a': {
                              color: theme.palette.linkBlue.dark,
                              textDecoration: 'none',
                              ':hover': {
                                textDecoration: 'underline',
                              },
                            },
                          }}
                          disabled={!PolicyGroupExist(subList.id)}
                        >
                          <Link
                            to={{
                              pathname: `/${module}${subList.route}`,
                            }}
                            style={
                              !PolicyGroupExist(subList.id)
                                ? { pointerEvents: 'none' }
                                : {}
                            }
                          >
                            {t(subList.label)}
                          </Link>
                        </ListItem>
                      );
                    }
                  )}
                </List>
              </Grid>
            </Grid>
          );
        } else {
          return (
            <Grid
              item
              xs={12}
              sm={3}
              sx={{ marginBottom: '32px', display: 'flex' }}
              key={t(listItem.label)}
            >
              <Grid>
                <ListItemIcon
                  sx={{
                    mt: 1,
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Icon
                    sx={{
                      backgroundColor: 'grey1.main',
                      color: 'white.main',
                      borderRadius: '50%',
                      width: '1.5em',
                      height: '1.5em',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      '&.MuiButtonBase-root:hover': {
                        backgroundColor: 'grey1.main',
                        color: 'white.main',
                      },
                    }}
                  >
                    {getIcon(t(listItem.label))}
                  </Icon>
                </ListItemIcon>
              </Grid>
              <Grid>
                <ListItemText
                  sx={{
                    mb: '20px',
                    height: '20px',
                    '& .MuiListItemText-primary': {
                      fontWeight: '500',
                      fontSize: '20px',
                      color: theme.palette.primary.main,
                    },
                  }}
                  primary={t(listItem.label)}
                />
                <List>
                  <ListItem
                    key={listItem.id}
                    disablePadding={true}
                    sx={{
                      fontSize: '16px',
                      mb: '5px',
                      '& a': {
                        color: theme.palette.linkBlue.dark,
                        textDecoration: 'none',
                        ':hover': {
                          textDecoration: 'underline',
                        },
                      },
                    }}
                    disabled={!PolicyGroupExist(listItem.id)}
                  >
                    <Link
                      to={{
                        pathname: `/${module}${listItem.route}`,
                      }}
                      style={
                        !PolicyGroupExist(listItem.id)
                          ? { pointerEvents: 'none' }
                          : {}
                      }
                    >
                      {t(listItem.label)}
                    </Link>
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          );
        }
      })}
    </>
  );
};

export default MenuConfig;
