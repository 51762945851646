import {
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Stack,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { WorkflowAttachment } from 'types/WorkflowAttachment';
import { useTranslation } from 'react-i18next';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageIcon from '@mui/icons-material/Image';
import VideocamIcon from '@mui/icons-material/Videocam';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import ArticleIcon from '@mui/icons-material/Article';
import ErrorIcon from '@mui/icons-material/Error';
import { useDownloadAttachment } from 'hooks/useUploadAttachment';

type AttachmentRowItemProps = {
  attachment: WorkflowAttachment;
};

export const AttachmentRowItem = ({ attachment }: AttachmentRowItemProps) => {
  const { download } = useDownloadAttachment();
  const { t } = useTranslation();
  const extensionIcon = {
    pdf: PictureAsPdfIcon,
    png: ImageIcon,
    jpeg: ImageIcon,
    jpg: ImageIcon,
    doc: ArticleIcon,
    docx: ArticleIcon,
    txt: ArticleIcon,
    mp3: VolumeUpIcon,
    wav: VolumeUpIcon,
    mp4: VideocamIcon,
    mov: VideocamIcon,
    avi: VideocamIcon,
  };
  const attachmentExtension: string | undefined = attachment.displayName
    .split('.')
    .pop()
    ?.toLocaleLowerCase();
  const Icon =
    extensionIcon[attachmentExtension as keyof typeof extensionIcon] ??
    ErrorIcon;

  const onDownload = async () => {
    const result = await download({
      workflowId: attachment.workflowId,
      displayId: attachment.displayId,
    });

    const downloadLink = document.createElement('a');
    downloadLink.href = result;
    downloadLink.download = attachment.displayId;
    downloadLink.click();

    // cleanup to prevent memory leak
    URL.revokeObjectURL(result);
  };

  return (
    <ListItem sx={{ position: 'relative', bgcolor: 'background.paper', mb: 1 }}>
      <ListItemButton onClick={onDownload}>
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
        <ListItemText
          primary={
            <Stack direction="row" justifyContent="space-between">
              <Typography>{attachment.displayId}</Typography>
              <Typography>
                {`${t('pages.workflow.attachmentType')} : ${
                  attachment.attachmentType ?? ''
                }`}
              </Typography>
              <Typography>
                {format(new Date(attachment.timestamp ?? ''), 'MMM d, yyyy')}
              </Typography>
            </Stack>
          }
          secondary={attachment.displayName}
        />
      </ListItemButton>
    </ListItem>
  );
};

export default AttachmentRowItem;
