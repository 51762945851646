import { useState, ReactNode } from 'react';
import Input from '@revenue-solutions-inc/revxcoreui/material/controls/Input';
import { ConfigurationDomains } from 'common/platformConfigUtils/platformConfigUtils';
import { useAppDispatch } from 'redux/hooks';
import { useGetLookupConfigurationQuery } from 'generated/graphql';
import { addMessage } from 'redux/messageSlice';
import { MessageType } from '@revenue-solutions-inc/revxcoreui/material/messaging/Message/Message';
import { useTranslation } from 'react-i18next';
import List from '@mui/material/List';
import { resultStyle, containerResultStyle } from 'common/search';
import { v4 as uuid } from 'uuid';
import ListItem from '@mui/material/ListItem';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button/Button';
import { ClickAwayListener, Popper } from '@mui/material';
import { UseFormReturn } from 'react-hook-form';

interface Props {
  id?: string;
  label: string;
  required?: boolean;
  errorMsg?: string;
  value: string;
  groupName?: string;
  attributeName?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setValue: UseFormReturn['setValue'];
}

function IndustryCodeSearch({
  id,
  value,
  onChange,
  required,
  errorMsg,
  label,
  setValue,
}: Props): JSX.Element {
  const MAIN_CONFIG_DOMAIN = ConfigurationDomains.ReferenceSchema;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [industryCodes, setIndustryCodes] = useState<string[]>([]);
  const [filteredIndustryCodes, setFilteredIndustryCodes] = useState<string[]>(
    []
  );
  const [anchorEl, setAnchorEl] = useState<HTMLInputElement | null>(null);
  const open = Boolean(anchorEl);

  const { isFetching } = useGetLookupConfigurationQuery(
    {
      configurationDomain: MAIN_CONFIG_DOMAIN,
      configurationType: 'IndustryCodeType',
    },
    {
      enabled: true,
      onSuccess: (data) => {
        const descriptions: string[] = [];
        data.GetLookupConfiguration.forEach(({ configurationDescription }) => {
          descriptions.push(configurationDescription as string);
        });
        setIndustryCodes([...descriptions]);
      },
      onError: () => {
        dispatch(
          addMessage({
            type: MessageType.Error,
            message: t('components.message.lookupError'),
          })
        );
      },
    }
  );

  const generateResults = (): ReactNode => {
    if (filteredIndustryCodes.length !== 0) {
      return (
        <List>
          <>
            {filteredIndustryCodes?.map((description: string) => (
              <ListItem sx={containerResultStyle} key={uuid()}>
                <Button
                  id={'btn_' + uuid()}
                  size="small"
                  type="secondary"
                  variant="text"
                  sx={resultStyle}
                  onClick={() => {
                    setValue(id as string, description);
                    setAnchorEl(null);
                  }}
                >
                  {description}
                </Button>
              </ListItem>
            ))}
          </>
        </List>
      );
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const eInputValue = event.target.value;

    const filteredData = industryCodes.filter((item) =>
      item.toLowerCase().includes(eInputValue.toLowerCase())
    );
    setFilteredIndustryCodes(filteredData);
    setAnchorEl(event.target);
  };

  return (
    <>
      <Input
        id={id as string}
        value={value}
        onChange={(e) => {
          if (e?.target?.value.length > 3) {
            handleInputChange(e);
          } else {
            setAnchorEl(null);
          }
          setValue(id as string, e?.target?.value);
          onChange(e);
        }}
        required={required}
        label={label}
        error={!!errorMsg}
        helperText={errorMsg}
        sx={{
          width: '100%',
        }}
        disabled={isFetching}
      />
      <ClickAwayListener
        onClickAway={() => {
          setAnchorEl(null);
        }}
      >
        <Popper
          id={'popper-id'}
          open={open}
          anchorEl={anchorEl}
          placement="bottom"
          sx={{
            background: 'white',
            backgroundColor: 'white',
            border: `1px solid black`,
            borderRadius: 1,
            inset: '9px auto auto 40px !important',
            minWidth: '25%',
            marginLeft: 5,
            zIndex: 2000,
          }}
        >
          {generateResults()}
        </Popper>
      </ClickAwayListener>
    </>
  );
}

export default IndustryCodeSearch;
