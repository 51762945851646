import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface TenantDialogProps {
  handleCloseModal: () => void;
}

function TenantDialog({ handleCloseModal }: TenantDialogProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <Stack spacing={2}>
      <Typography variant="h2" style={{ marginBottom: 20 }}>
        {t('pages.tenantDialog.title')}
      </Typography>

      <Typography variant="h4" style={{ marginBottom: 10 }}>
        {t('pages.tenantDialog.description1')}
      </Typography>

      <Typography variant="h4" style={{ marginBottom: 10 }}>
        {t('pages.tenantDialog.description2')}
      </Typography>

      <Typography variant="h4" style={{ marginBottom: 20 }}>
        {t('pages.tenantDialog.description3')}
      </Typography>

      <Box
        sx={{
          justifyContent: 'flex-end',
          display: 'flex',
        }}
      >
        <Button
          id={'redirect-tenantList-button'}
          type="primary"
          size="medium"
          endIcon={true}
          onClick={handleCloseModal}
        >
          {t('pages.tenantDialog.buttons.accept')}
        </Button>
      </Box>
    </Stack>
  );
}

export default TenantDialog;
