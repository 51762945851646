import {
  ChangeEvent,
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import Checkbox from '@revenue-solutions-inc/revxcoreui/material/controls/Checkbox';
import Select from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import Input from '@revenue-solutions-inc/revxcoreui/material/controls/Input';
import TabPanel from '@revenue-solutions-inc/revxcoreui/material/controls/TabPanel';
import TextArea from '@revenue-solutions-inc/revxcoreui/material/controls/TextArea';
import Loading from 'components/Loading';
import { ColumnDef, Column, Row } from '@tanstack/react-table';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import SideScrollBox from 'components/SideScrollBox';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Grid, List, SelectChangeEvent, Tab, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IHeader, setHeader } from 'redux/contentSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { policyGroupDefault } from 'types/policyGroup';
import extractMeaningfulMessage from 'utils/errorMessage';
import { getShortText } from 'utils/getShortText';
import useModules, { ModuleExpanded } from 'hooks/useModules';
import {
  MessageType,
  MessageActionType,
} from '@revenue-solutions-inc/revxcoreui';
import { addMessage } from 'redux/messageSlice';
import {
  useCreatePolicyGroupMutation,
  useDoesPolicyGroupsExistsQuery,
  CreatePolicyGroupInput,
  PolicyResponse,
  GetPolicyGroupByIdResponse,
  useGetPoliciesByModuleQuery,
  useGetPolicyGroupByIdQuery,
  useUpdatePolicyGroupMutation,
  UpdatePolicyGroupInput,
} from 'generated/graphql';
import { PolicyGroupForm } from 'types/graphTypes';
import {
  Keys,
  handleIsChecked,
  handleElementsSelected,
} from 'utils/checkboxes';

enum ActionType {
  EDIT = 'edit',
  CLONE = 'clone',
}

function PolicyGroup(): JSX.Element {
  const { t } = useTranslation();
  const invalidModuleId = -1;
  const minimumCharactersToDisplayError = 0;
  const minimumCharactersToRemoveError = 3;
  const maximumCharactersAllowed = 245;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const selectFocus = useRef<HTMLSelectElement>(null);
  const textAreaFocus = useRef<HTMLTextAreaElement>(null);
  const { modules, isFetchingModules } = useModules();
  const [moduleName, setModuleName] = useState<string>('Platform');
  const module = useAppSelector((state) => state.user.module);
  const [policyGroup, setPolicyGroup] =
    useState<PolicyGroupForm>(policyGroupDefault);
  const [currentTab, setCurrentTab] = useState(0);
  const [systemPolicies, setSystemPolicies] = useState<PolicyResponse[]>([]);
  const [policiesSelected, setPoliciesSelected] = useState<string[]>([]);
  const [policyGroupExists, setPolicyGroupExists] = useState(false);
  const { policeGroupId, action } = useParams() as {
    policeGroupId: string;
    action: string;
  };
  const [filteredRows, setFilteredRows] = useState<PolicyResponse[]>([]);
  const [isSeaching, setIsSeaching] = useState<{
    status: boolean;
    value: string;
  }>({
    status: false,
    value: '',
  });

  const createPolicyGroup =
    useCreatePolicyGroupMutation<CreatePolicyGroupInput>();

  const updatePolicyGroup =
    useUpdatePolicyGroupMutation<UpdatePolicyGroupInput>();

  const { refetch: refetchPolicyGroupExists } = useDoesPolicyGroupsExistsQuery<{
    DoesPolicyGroupsExists: boolean;
  }>(
    {
      moduleId: policyGroup.moduleId,
      policyGroupName: `${policyGroup.policyGroupName}`.trim(),
    },
    {
      enabled: false,
      onSuccess: (policyGroupExistsResult) => {
        setPolicyGroupExists(policyGroupExistsResult.DoesPolicyGroupsExists);
      },
      onError: (error) => {
        const message = extractMeaningfulMessage(
          error,
          t('pages.manageReusableContent.networkError')
        );
        dispatch(
          addMessage({
            message: message,
            actionType: MessageActionType.None,
            type: MessageType.Error,
          })
        );
      },
    }
  );

  const { isFetching: isFetchingSystemPolicies } = useGetPoliciesByModuleQuery<{
    PoliciesByModule: PolicyResponse[];
  }>(
    {
      moduleId: policyGroup.moduleId.toString(),
    },
    {
      onSuccess: (data) => {
        if (data?.PoliciesByModule) {
          setSystemPolicies(data.PoliciesByModule);
        }
      },
      onError: (error) => {
        const message = extractMeaningfulMessage(
          error,
          t('pages.manageReusableContent.networkError')
        );
        dispatch(
          addMessage({
            message: message,
            type: MessageType.Error,
            actionType: MessageActionType.None,
          })
        );
      },
    }
  );

  const { refetch: refetchPolicyGroupById } = useGetPolicyGroupByIdQuery<{
    GetPolicyGroupById: GetPolicyGroupByIdResponse;
  }>(
    {
      policyGroupId: policeGroupId,
    },
    {
      enabled: false,
      onSuccess: (policyGroupIdData) => {
        if (policyGroupIdData?.GetPolicyGroupById) {
          const tempPolicyGroupId = policyGroupIdData.GetPolicyGroupById;
          setPolicyGroup({
            policyGroupId: tempPolicyGroupId.policyGroupId,
            moduleId: tempPolicyGroupId.moduleId,
            moduleName: tempPolicyGroupId.moduleName,
            policyGroupName: tempPolicyGroupId.policyGroupName,
            policyGroupTechnicalName:
              tempPolicyGroupId.policyGroupTechnicalName,
            policyGroupDescription: tempPolicyGroupId.policyGroupDescription,
            authorizationPolicy: tempPolicyGroupId.authorizationPolicy,
          });
          setPoliciesSelected(
            tempPolicyGroupId.authorizationPolicy.map(
              (policyId) => `${policyId.authorizationPolicyId}`
            )
          );
          setModuleName(tempPolicyGroupId.moduleName);
        }
      },
      onError: (error) => {
        if (action === ActionType.EDIT) {
          const message = extractMeaningfulMessage(
            error,
            t('pages.manageReusableContent.networkError')
          );
          dispatch(
            addMessage({
              message: message,
              type: MessageType.Error,
              actionType: MessageActionType.None,
            })
          );
        }
      },
    }
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (action === ActionType.EDIT) textAreaFocus.current?.focus();
      else selectFocus.current?.focus();
    }, 100);
    return () => {
      clearTimeout(timeout);
    };
  }, [action]);

  useEffect(() => {
    if (policyGroup.policyGroupName.length >= minimumCharactersToRemoveError) {
      refetchPolicyGroupExists();
    }
  }, [policyGroup.policyGroupName, refetchPolicyGroupExists]);

  useEffect(() => {
    const headerData: IHeader = {
      pageTitle: t('pages.policyGroupCreate.title'),
      previousPage: t('pages.policyGroupCreate.previousPage'),
      route: 'managePolicyGroups',
    };

    if (action === ActionType.EDIT) {
      headerData.pageTitle = t('pages.policyGroupEdit.title');
      headerData.icon = {
        icon: 'assignmentTurnedInIcon',
        props: { fill: 'black' },
        fontSize: 'large',
      };

      headerData.data = [
        {
          id: 'module-header',
          label: t('pages.policyGroupEdit.module'),
          first: true,
          value: moduleName,
        },
        {
          label: t('pages.policyGroupEdit.policyGroupName'),
          id: 'name-header',
          value: getShortText(policyGroup?.policyGroupName ?? ''),
        },
        {
          id: 'description-header',
          value: getShortText(policyGroup?.policyGroupDescription ?? ''),
          label: t('pages.policyGroupEdit.policyGroupDescription'),
        },
      ];
    }
    dispatch(setHeader(headerData));
  }, [
    action,
    dispatch,
    moduleName,
    policyGroup.policyGroupDescription,
    policyGroup.policyGroupName,
    t,
  ]);

  useEffect(() => {
    if (action === ActionType.EDIT) {
      refetchPolicyGroupById();
    }
  }, [action, refetchPolicyGroupById]);

  const capitalizeFirstLetter = (word: string): string =>
    word.charAt(0).toUpperCase() + word.slice(1);

  const createTechnicalName = (): string => {
    let words = policyGroup.policyGroupName.split(' ');
    words = words.map((word: string) => capitalizeFirstLetter(word));
    return `${moduleName}${words.join('')}`;
  };

  const handleBack = () => {
    navigate(`/${module}/managePolicyGroups`);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  const handleSavePolicyGroup = () => {
    if (action === ActionType.EDIT) {
      updatePolicyGroup.mutate(
        {
          policyGroup: {
            policyGroupId: policyGroup.policyGroupId,
            description: policyGroup.policyGroupDescription,
            authorizationPolicyIds: policiesSelected.map((policyG) =>
              parseInt(policyG)
            ),
          },
        },
        {
          onSuccess: () => {
            dispatch(
              addMessage({
                message: t('pages.policyGroupEdit.successMessage'),
                type: MessageType.Success,
                actionType: MessageActionType.None,
              })
            );
            handleBack();
          },
          onError: (e: Error[] | unknown) => {
            const onCreateDefaultError = t('components.message.networkerror');
            dispatch(
              addMessage({
                actionType: MessageActionType.None,
                type: MessageType.Error,
                message: extractMeaningfulMessage(e, onCreateDefaultError),
              })
            );
          },
        }
      );
    } else {
      setPolicyGroup({ ...policyGroup, moduleId: 3 });
      createPolicyGroup.mutate(
        {
          policyGroup: {
            moduleId: policyGroup.moduleId,
            policyGroupName: policyGroup.policyGroupName,
            description: policyGroup.policyGroupDescription,
            authorizationPolicyIds: policiesSelected.map((policyGroupData) =>
              parseInt(policyGroupData)
            ),
          },
        },
        {
          onSuccess: () => {
            dispatch(
              addMessage({
                message: t('pages.policyGroupCreate.successMessage'),
                type: MessageType.Success,
                actionType: MessageActionType.None,
              })
            );
            handleBack();
          },
          onError: (e: Error[] | unknown) => {
            const onCreateDefaultError = t('components.message.networkerror');
            dispatch(
              addMessage({
                type: MessageType.Error,
                actionType: MessageActionType.None,
                message: extractMeaningfulMessage(e, onCreateDefaultError),
              })
            );
          },
        }
      );
    }
  };

  const getFieldError = (
    type: string,
    field: string
  ): {
    error: boolean;
    errorMessage: string;
  } => {
    if (type === 'policyGroupName') {
      if (
        policyGroupExists &&
        action !== ActionType.EDIT &&
        field.length >= minimumCharactersToRemoveError
      ) {
        return {
          error: true,
          errorMessage: t('pages.policyGroupCreate.policyGroupExistsMessage'),
        };
      } else if (
        policyGroupExists &&
        field.length < minimumCharactersToRemoveError
      ) {
        setPolicyGroupExists(false);
      }
    }

    if (type === 'policyGroupName') {
      if (field === '') {
        return {
          error: true,
          errorMessage: '',
        };
      }
    }

    if (field.length > maximumCharactersAllowed) {
      return {
        error: true,
        errorMessage: t(
          'pages.policyGroupCreate.policyGroupFieldMaxLengthError'
        ),
      };
    }

    if (
      field.length > minimumCharactersToDisplayError &&
      field.length < minimumCharactersToRemoveError
    ) {
      return {
        error: true,
        errorMessage: t('pages.policyGroupCreate.policyGroupFieldError'),
      };
    }

    return { error: false, errorMessage: '' };
  };

  const disableButton = (): boolean => {
    if (
      getFieldError('policyGroupName', policyGroup.policyGroupName).error ===
        true ||
      getFieldError(
        'policyGroupDescription',
        policyGroup.policyGroupDescription
      ).error === true ||
      policiesSelected.length < 1
    ) {
      return true;
    } else return false;
  };

  const handleCheckAll = useMemo((): boolean => {
    if (isSeaching.status) {
      return filteredRows
        .map((policy) => `${policy.authorizationPolicyId}`)
        .every((item) => policiesSelected.includes(item));
    } else {
      return policiesSelected.length === systemPolicies.length;
    }
  }, [
    filteredRows,
    isSeaching.status,
    systemPolicies.length,
    policiesSelected,
  ]);

  const policyColumns: ColumnDef<PolicyResponse>[] = [
    {
      header: () => {
        return (
          <Checkbox
            {...{
              onKeyDown: (event) => {
                if (event.key === Keys.ENTER) {
                  if (systemPolicies.length === policiesSelected.length) {
                    setPoliciesSelected([]);
                  } else {
                    const newPolicyGroups =
                      systemPolicies.map((p) => `${p.authorizationPolicyId}`) ??
                      [];
                    setPoliciesSelected(newPolicyGroups);
                  }
                }
              },
            }}
            id={'selectAllCheck'}
            label={''}
            checked={handleCheckAll}
            onChange={(event) => {
              let newPolicies: string[] = [];

              if (event.target.checked) {
                if (isSeaching.status) {
                  newPolicies = filteredRows.map(
                    (policy) => `${policy.authorizationPolicyId}`
                  );
                  if (policiesSelected.length > 0) {
                    newPolicies = [...policiesSelected, ...newPolicies];
                    newPolicies = newPolicies.filter(
                      (item, index) => newPolicies.indexOf(item) === index
                    );
                  }
                } else if (systemPolicies.length > 0)
                  newPolicies = systemPolicies.map(
                    (policy) => `${policy.authorizationPolicyId}`
                  );
              } else if (isSeaching.status && policiesSelected.length > 0) {
                newPolicies = policiesSelected.filter(
                  (item) =>
                    !filteredRows
                      .map((policy) => `${policy.authorizationPolicyId}`)
                      .includes(item)
                );
              }

              setPoliciesSelected(newPolicies);
            }}
            sx={{ ml: 1 }}
          />
        );
      },
      id: 'authorizationPolicyId',
      cell: ({ row }) => {
        return (
          <Checkbox
            {...{
              onKeyDown: (event) => {
                if (event.key === Keys.ENTER) {
                  const isChecked = handleIsChecked(
                    policiesSelected,
                    `${row.original.authorizationPolicyId}`
                  );
                  const newPolicyGroupsSelected = handleElementsSelected(
                    [...policiesSelected],
                    isChecked,
                    `${row.original.authorizationPolicyId}`
                  );
                  setPoliciesSelected(newPolicyGroupsSelected);
                }
              },
            }}
            id={'policyChk'}
            checked={
              policiesSelected.length > 0 &&
              policiesSelected.findIndex(
                (policyGroupId) =>
                  parseInt(policyGroupId) === row.original.authorizationPolicyId
              ) > -1
            }
            label={''}
            onChange={(event) => {
              if (event.target.checked) {
                setPoliciesSelected([
                  ...policiesSelected,
                  `${row.original.authorizationPolicyId}`,
                ]);
              } else {
                const newPolicies = [...policiesSelected].filter(
                  (policyData) =>
                    policyData !== `${row.original.authorizationPolicyId}`
                );
                setPoliciesSelected(newPolicies);
              }
            }}
            sx={{ ml: 1 }}
          />
        );
      },
    },
    {
      accessorKey: 'policyName',
      header: () => (
        <HeaderColumnNext localization={t('pages.policyGroup.policyName')} />
      ),
    },
    {
      accessorKey: 'policyDescription',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.policyGroup.policyDescription')}
        />
      ),
    },
  ];

  const onSelectModule = (event: SelectChangeEvent<string | number>) => {
    const moduleIndex = parseInt((event.target as HTMLSelectElement).value);

    const moduleIdSelected =
      modules.find((mod: ModuleExpanded) => mod.idx === moduleIndex)
        ?.moduleId ?? invalidModuleId;

    if (moduleIdSelected !== invalidModuleId) {
      setPoliciesSelected([]);
      setPolicyGroup({
        ...policyGroup,
        authorizationPolicy: [],
        moduleId: moduleIdSelected,
      });
      setModuleName(
        modules.find((m: ModuleExpanded) => m.moduleId == event.target.value)
          ?.name ?? ''
      );
    }
  };

  const getSeachingValue = useCallback((value: string) => {
    const valueLength = value.length > 0;
    setIsSeaching({ status: valueLength, value: value });
  }, []);

  const getFilteredRows = useCallback(
    (newFilteredRows: Row<PolicyResponse>[]) => {
      if (isSeaching.status && isSeaching.value.length > 0) {
        const newOriginalRows: PolicyResponse[] = newFilteredRows.map(
          (row) => row.original
        );
        setFilteredRows(newOriginalRows);
      }
    },
    [isSeaching]
  );

  const getModuleIdx = useMemo(() => {
    if (modules.length > 0) {
      return (
        modules.find(
          (mod: ModuleExpanded) => mod.moduleId === policyGroup.moduleId
        )?.idx ?? 1
      );
    } else return 0;
  }, [modules, policyGroup.moduleId]);

  return (
    <>
      <Grid container>
        <Grid
          item
          xs={4}
          minWidth="15rem"
          sx={{
            flexGrow: '1 !important',
            maxWidth: '100% !important',
          }}
        >
          <Grid item mb={2} xs={10}>
            <Select
              id="policyGroupModule-createPolicyGroup"
              label={t('pages.policyGroup.module')}
              required
              inputProps={{
                'data-testid': 'policyGroupModule-select',
                ref: selectFocus,
                autoFocus: true,
              }}
              disabled={action === ActionType.EDIT}
              options={modules.map((m: ModuleExpanded) => {
                return { key: m.idx, desc: m.name };
              })}
              value={`${getModuleIdx}`}
              onChange={onSelectModule}
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={10} mb={2}>
            <Input
              id="policyGroupName-createPolicyGroup"
              data-testid="policyGroupName-input"
              label={t('pages.policyGroup.policyGroupName')}
              required
              disabled={action === ActionType.EDIT}
              inputProps={{
                'data-testid': 'policyGroupName-input',
                maxLength: 100,
              }}
              value={policyGroup?.policyGroupName ?? ''}
              onChange={(event) => {
                setPolicyGroup({
                  ...policyGroup,
                  policyGroupName: event.target.value,
                });
              }}
              error={
                action === ActionType.EDIT
                  ? false
                  : getFieldError(
                      'policyGroupName',
                      policyGroup.policyGroupName
                    ).error
              }
              helperText={
                action === ActionType.EDIT
                  ? ''
                  : getFieldError(
                      'policyGroupName',
                      policyGroup.policyGroupName
                    ).errorMessage
              }
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid item mb={2} xs={10}>
            <Input
              id="policyGroupTechnicalName-createPolicyGroup"
              data-testid="policyGroupTechnicalName-input"
              label={t('pages.policyGroup.policyGroupTechnicalName')}
              inputProps={{
                'data-testid': 'policyGroupTechnicalName-input',
                maxLength: 100,
              }}
              value={createTechnicalName()}
              disabled
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={10} mb={2}>
            <TextArea
              id="PolicyGroupDescription-createPolicyGroup"
              data-testid="policyGroupDescription"
              label={t('pages.policyGroup.policyGroupDescription')}
              value={policyGroup?.policyGroupDescription ?? ''}
              onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                setPolicyGroup({
                  ...policyGroup,
                  policyGroupDescription: event.target.value,
                })
              }
              multiline
              error={
                getFieldError(
                  'PolicyGroupDescription',
                  policyGroup.policyGroupDescription
                ).error
              }
              helperText={
                getFieldError(
                  'PolicyGroupDescription',
                  policyGroup.policyGroupDescription
                ).errorMessage
              }
              sx={{ width: '100%' }}
              inputProps={{
                maxLength: maximumCharactersAllowed + 1,
                ref: textAreaFocus,
                autoFocus: true,
              }}
            />
          </Grid>
          <Grid item mb={2} xs={10}>
            <List
              sx={{
                height: 400,
              }}
            >
              <SideScrollBox
                title={t('pages.policyGroup.selectedPolicies')}
                options={
                  policiesSelected && policiesSelected.length > 0
                    ? policiesSelected.map((pGroup) => {
                        const name =
                          systemPolicies.find(
                            (p) => p.authorizationPolicyId === parseInt(pGroup)
                          )?.policyName ?? `${pGroup}`;
                        return { key: pGroup, name: name };
                      })
                    : []
                }
                id="policy-box"
                scrollBoxValues={policiesSelected as unknown as string[]}
                onChangeState={setPoliciesSelected}
                width={'100%'}
              />
            </List>
          </Grid>
          <Grid item xs={10} mb={2}>
            <Button
              onClick={() => handleSavePolicyGroup()}
              id="saveBtn-createPolicyGroup"
              data-testid="save-button"
              sx={{ mt: 1, mb: 1 }}
              disabled={disableButton()}
            >
              {action === ActionType.CLONE || action === ActionType.EDIT
                ? t('pages.policyGroupEdit.updatePolicyGroup')
                : t('pages.policyGroup.save')}
            </Button>
          </Grid>
        </Grid>
        <Grid
          item
          xs={8}
          sx={{ flexGrow: '1 !important', maxWidth: '100% !important' }}
        >
          <Box sx={{ borderColor: 'divider' }}>
            <Tabs
              value={currentTab}
              onChange={handleTabChange}
              aria-label="role Tabs"
            >
              <Tab label={t('pages.policyGroup.assignedSystemPolicies')} />
            </Tabs>
          </Box>
          <TabPanel selectedIndex={currentTab} index={0}>
            {(isFetchingModules || isFetchingSystemPolicies) && <Loading />}
            {policyGroup.moduleId > invalidModuleId && systemPolicies && (
              <div data-testid="authorization-policies-data">
                <DefaultDataTableNext
                  columns={policyColumns as Column<PolicyResponse>[]}
                  data={systemPolicies}
                  getSeachingValue={getSeachingValue}
                  getFilteredRows={getFilteredRows}
                />
              </div>
            )}
          </TabPanel>
        </Grid>
        <Grid
          item
          xs={8}
          sx={{ flexGrow: '1 !important', maxWidth: '100% !important' }}
        ></Grid>
      </Grid>
    </>
  );
}

export default PolicyGroup;
