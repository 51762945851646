import { gql } from 'graphql-request';

const checkCompileExpession = gql`
  mutation checkCompileExpression($RootLogix: RootLogixInput!) {
    checkCompileExpression(RootLogix: $RootLogix) {
      Result
    }
  }
`;

export default checkCompileExpession;
