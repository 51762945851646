import NoResults from 'components/NoResults';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { BusinessPolicyByRoleResponse } from 'generated/graphql';

interface BusinessPoliciesInfoProps {
  isLoading: boolean;
  businessPolicies: BusinessPolicyByRoleResponse[];
}

const BusinessPoliciesByRoleInfo = ({
  isLoading,
  businessPolicies,
}: BusinessPoliciesInfoProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <>
      {businessPolicies && businessPolicies.length > 0 && (
        <>
          <Typography
            variant="h3"
            sx={{
              fontSize: 16,
              fontWeight: 'bold',
              marginTop: 1,
              marginBottom: 1,
            }}
          >
            {t('pages.roles.assignedBusinessPolicies')}
          </Typography>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            {businessPolicies.map(
              ({
                customerBusinessPolicyId,
                name,
              }: BusinessPolicyByRoleResponse) => (
                <span
                  key={customerBusinessPolicyId}
                  style={{ width: '50%', marginBottom: '10pxS' }}
                >
                  {name}
                </span>
              )
            )}
          </div>
        </>
      )}
      {!isLoading && businessPolicies.length === 0 && <NoResults />}
    </>
  );
};

export default BusinessPoliciesByRoleInfo;
