import { css } from '@emotion/react';
import { keyframes } from '@mui/system';

export const DecreaseLeftAnimation = keyframes`{
 to: {
        visibility:hidden ;
        width:0;
        height:0;
      },
}`;
export const IncreaseRightAnimation = keyframes`{
 to: {
        width:100%;
      },
}`;
export const expandGrid = css`
  animation: ${DecreaseLeftAnimation} 1s;
  animationfillmode: forwards;
`;
export const collapseGrid = css`
  animation: ${IncreaseRightAnimation} 1s;
  animationfillmode: reverse;
`;
