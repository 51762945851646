import { useLocation, useNavigate, NavigateOptions } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import {
  GetWorkflowGroupsQuery,
  useCreateWorkflowVariantObjectMutation,
} from 'generated/graphql';
import { WorkflowForm } from 'components/Workflow/Form';
import { WorkflowFormReducerState } from 'components/Workflow/Form/workflowFormReducer';
import { Groups, processAttributesForBackend } from '../workflowCommon';

type CreateSpecificWorkProps = {
  variantId: string;
  data: GetWorkflowGroupsQuery;
  module: string;
  workType: string;
};

const CreateSpecificWork = ({
  variantId,
  data,
  module,
  workType,
}: CreateSpecificWorkProps) => {
  const { state: locationState } = useLocation();
  const navigate = useNavigate();
  const contextTypesToNavigateBack = [1, 2];
  const fieldsToHide = [
    'VariantGroupCode',
    'WorkflowVariantCode',
    'WorkflowVariantDescription',
    'FormID',
  ];
  const queryClient = useQueryClient();

  const { mutate } = useCreateWorkflowVariantObjectMutation({
    onSuccess: () => {
      queryClient.refetchQueries(['GetWorkflows']);
    },
  });

  const handleSubmit = (state: WorkflowFormReducerState) => {
    const groups: Groups = {
      ...processAttributesForBackend(state.attributes),
      TenantId: '',
      CreatedBy: '',
      PriorityIndicator: 'Low', // hack for now
      WorkflowContexts: state.workflowContext,
      customAttributes: state.customAttributes,
    };
    mutate(
      {
        groups: JSON.stringify(groups),
        variantId,
        step: 'step',
      },
      {
        onSuccess: (args) => {
          if (
            contextTypesToNavigateBack.includes(
              locationState?.__workflowContext?.WorkflowContextType
            )
          ) {
            navigate(-1);
          } else {
            const workflowKey = JSON.parse(
              args.CreateWorkflowVariantObject.wF_Full_Object
            ).Workflow_Key;
            const { key, variantId: previousVariantId } =
              locationState?.__previousUrl ?? {};
            const returnUrl = key
              ? `update/${key}/${previousVariantId}`
              : `create/${previousVariantId}`;

            navigate(`/${module}/workflow/${returnUrl}`, {
              state: {
                __workflowContext: {
                  ...(locationState?.__previousContext ?? {}),
                },
                __workType: '',
                __previousContext: undefined,
                __previousUrl: undefined,
                __noteWorkflowKey: workflowKey,
                __previousState: locationState?.__previousState,
              } as NavigateOptions,
            });
          }
        },
      }
    );
  };

  return (
    <WorkflowForm
      isEdit={false}
      data={data}
      variantId={variantId}
      onSubmit={handleSubmit}
      workType={workType}
      hiddenFields={fieldsToHide}
    />
  );
};

export default CreateSpecificWork;
