import { useEffect, useState } from 'react';
import { Checkbox, Grid } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import Loading from 'components/Loading';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import { useTranslation } from 'react-i18next';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { IHeader, setHeader } from 'redux/contentSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import {
  ApproveRefundBatchInput,
  useApproveRefundBatchMutation,
  useGetLookupConfigurationQuery,
  useGetRefundBatchDetailQuery,
} from 'generated/graphql';
import { addMessage } from 'redux/messageSlice';
import { formatIdentifier } from 'utils/formatIdentifier';
import {
  Button,
  MessageActionType,
  MessageType,
} from '@revenue-solutions-inc/revxcoreui';
import { getIdFormats } from 'components/entityManagement/common/formatValidations';
import { ConfigurationDomains } from 'common/platformConfigUtils/platformConfigUtils';
import { getFormatDate, toDate } from 'utils/date-util';
import { formatCurrency } from 'common/helpers';
import extractMeaningfulMessage from 'utils/errorMessage';

type Refund = {
  refundId: number;
  accountName: string | null | undefined;
  accountId: string;
  accountInfoId: string;
  entityInfoId: string | null | undefined;
  accountType: string;
  periodEndDate: string;
  refundAmount: string;
  approvedDate: string;
};

function RefundBatchDetails(): React.JSX.Element {
  const CONFIG_DOMAIN = ConfigurationDomains.ReferenceSchema;
  const dispatch = useAppDispatch();
  const [refundList, setRefundList] = useState<Refund[]>([]);
  const { refundBatchId } = useParams() as { refundBatchId: string };
  const theme = useTheme();
  const module: string = useAppSelector((state) => state.user.module);
  const { t } = useTranslation();
  const [selectedRefundIds, setSelectedRefundIds] = useState<number[]>([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { mutate } = useApproveRefundBatchMutation({});
  const navigate = useNavigate();

  const { data, isLoading } = useGetRefundBatchDetailQuery(
    {
      getRefundBatchDetailId: Number(refundBatchId),
    },
    {
      enabled: refundBatchId !== '',
      onError: () => {
        dispatch(
          addMessage({
            type: MessageType.Error,
            message: t('pages.refundBatch.refundBatchDetail.error'),
          })
        );
      },
    }
  );

  const { data: configuredIdTypes } = useGetLookupConfigurationQuery(
    {
      configurationDomain: CONFIG_DOMAIN,
      configurationType: 'IdType',
    },
    {
      onError: () => {
        dispatch(
          addMessage({
            type: MessageType.Error,
            message: t('pages.refundBatch.refundBatchDetail.lookupError'),
          })
        );
      },
    }
  );

  useEffect(() => {
    if (!isLoading && data) {
      const headerData: IHeader = {
        pageTitle: t('pages.refundBatch.refundBatchDetail.title'),
        previousPage: t('pages.refundBatch.refundBatchSummary.title'),
        route: `refundbatches`,
        icon: {
          props: { fill: 'black' },
          icon: 'accountBalanceIcon',
          fontSize: 'large',
        },
        data: [
          {
            id: 'refundBatchIdLabel',
            label: t(`pages.refundBatch.refundBatchIdLabel`),
            value: data.GetRefundBatchDetail.refundBatchIdLabel,
            first: true,
          },
          {
            id: 'status',
            label: t(`pages.refundBatch.status`),
            value: data.GetRefundBatchDetail.status,
          },
          {
            id: 'creationDate',
            label: t('pages.refundBatch.creationDate'),
            value: getFormatDate(
              toDate(data.GetRefundBatchDetail.creationDate)
            ),
          },
          {
            id: 'processedDate',
            label: t('pages.refundBatch.processedDate'),
            value: data.GetRefundBatchDetail.processedDate
              ? data.GetRefundBatchDetail.processedDate
              : '-',
          },
          {
            id: 'refundForm',
            label: t('pages.refundBatch.refundForm'),
            value: data.GetRefundBatchDetail.refundPaymentMethod
              ? 'Paper'
              : 'DirDep',
          },
          {
            id: 'refundCount',
            label: t('pages.refundBatch.refundCount'),
            value: data.GetRefundBatchDetail.refundCount.toString(),
          },
          {
            id: 'refundTotal',
            label: t('pages.refundBatch.refundTotal'),
            value: formatCurrency(
              data.GetRefundBatchDetail.refundTotal.toString()
            ),
          },
        ],
      };
      dispatch(setHeader(headerData));
    }
  }, [data, dispatch, isLoading, refundBatchId, t]);

  // status is not approved and approved date is null means the refund batch can be approved
  const canBeApproved =
    data?.GetRefundBatchDetail.approvedDate == null &&
    'approved' !== data?.GetRefundBatchDetail.status.toLocaleLowerCase();

  const columns: ColumnDef<Refund>[] = [
    {
      id: 'accountName',
      accessorKey: 'accountName',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.refundBatch.refundBatchDetail.accountName')}
        />
      ),
    },
    {
      id: 'accountId',
      accessorKey: 'accountId',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.refundBatch.refundBatchDetail.accountId')}
        />
      ),
    },
    {
      id: 'accountType',
      accessorKey: 'accountType',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.refundBatch.refundBatchDetail.accountType')}
        />
      ),
      cell: ({ getValue, row }) => (
        <Link
          to={{
            pathname: `../../${module}/entity/${row.original.entityInfoId}/account/${row.original.accountInfoId}`,
          }}
          style={{ color: theme.palette.linkBlue.dark }}
        >
          {getValue() as string}
        </Link>
      ),
    },
    {
      id: 'periodEndDate',
      accessorKey: 'periodEndDate',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.refundBatch.refundBatchDetail.periodEndDate')}
        />
      ),
    },
    {
      id: 'refundAmount',
      accessorKey: 'refundAmount',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.refundBatch.refundBatchDetail.refundAmount')}
        />
      ),
    },
    {
      id: 'approvedDate',
      accessorKey: 'approvedDate',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.refundBatch.refundBatchDetail.approvedDate')}
        />
      ),
    },
    ...(canBeApproved
      ? [
          {
            id: 'refundId',
            header: () => (
              <HeaderColumnNext
                localization={t('pages.refundBatch.refundBatchDetail.exclude')}
              />
            ),
            cell: ({ row }: CellContext<Refund, unknown>) => {
              return (
                <Checkbox
                  id={'refundChk'}
                  checked={
                    selectedRefundIds.findIndex(
                      (p) => p === row.original.refundId
                    ) > -1
                  }
                  onChange={(event) => {
                    let newRefundIds = [...selectedRefundIds];
                    if (event.target.checked) {
                      newRefundIds.push(row.original.refundId);
                    } else {
                      newRefundIds = newRefundIds.filter(
                        (p) => p !== row.original.refundId
                      );
                    }
                    setSelectedRefundIds(newRefundIds);
                  }}
                />
              );
            },
          },
        ]
      : []),
  ];

  useEffect(() => {
    if (data && data?.GetRefundBatchDetail.refundBatchDetailRows) {
      const aRefundList: Refund[] = [];
      data.GetRefundBatchDetail.refundBatchDetailRows.forEach((refund) => {
        const singleRefund: Refund = {
          refundId: refund.refundId,
          accountName: refund.accountName,
          accountType: refund.accountType,
          accountInfoId: refund.accountInfoId,
          entityInfoId: refund.entityInfoId,
          periodEndDate: getFormatDate(toDate(refund.periodEndDate)),
          refundAmount: formatCurrency(refund.refundAmount.toString()),
          approvedDate: getFormatDate(
            toDate(data.GetRefundBatchDetail.approvedDate)
          ),
          accountId: formatIdentifier(
            refund.primaryIdentifier ?? '',
            getIdFormats(configuredIdTypes),
            refund.primaryIdentifierType ?? ''
          ),
        };
        aRefundList.push(singleRefund);
      });
      setRefundList(aRefundList);
    } else setRefundList([]);
  }, [
    configuredIdTypes,
    data,
    data?.GetRefundBatchDetail.approvedDate,
    data?.GetRefundBatchDetail.refundBatchDetailRows,
    refundBatchId,
  ]);

  const approveRefundBatch = () => {
    setIsSaving(true);
    const payload: ApproveRefundBatchInput = {
      refundBatchId: Number(refundBatchId),
      refundIdsToExclude: selectedRefundIds,
    };

    mutate(
      {
        approveRefundBatchId: Number(refundBatchId),
        input: payload,
      },
      {
        onSuccess: () => {
          dispatch(
            addMessage({
              message: t('pages.refundBatch.refundBatchDetail.approveSuccess'),
              type: MessageType.Success,
              actionType: MessageActionType.None,
            })
          );
          setIsSaving(false);
          navigate(`../../${module}/refundBatchSummary`);
        },
        onError: (error) => {
          setIsSaving(false);
          const message = extractMeaningfulMessage(
            error,
            t('pages.refundBatch.refundBatchDetail.approveError')
          );
          dispatch(
            addMessage({
              type: MessageType.Error,
              message: message,
            })
          );
        },
      }
    );
  };

  return (
    <>
      {(isLoading || isSaving) && <Loading />}
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            flexGrow: '1 !important',
            maxWidth: '100% !important',
          }}
        >
          <Box sx={{ p: 2 }}>
            {data && (
              <DefaultDataTableNext columns={columns} data={refundList} />
            )}
            <Grid
              item
              xs={12}
              sx={{
                marginBottom: '-4.2em',
                marginLeft: '1em',
                paddingTop: '2.2em',
                width: '100%',
              }}
              data-testid="approve-button"
            >
              {data && canBeApproved && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginBottom: '4em',
                    width: '100%',
                  }}
                >
                  <Button
                    id="button-approveRefundBatch"
                    onClick={approveRefundBatch}
                    sx={{ mt: '1em', mb: '1em', mr: '1em', width: '100%' }}
                  >
                    {t('pages.refundBatch.approve')}
                  </Button>
                </Box>
              )}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default RefundBatchDetails;
