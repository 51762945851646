type Action = {
  view: string[];
  edit: string[];
};

type KeyValueRole = {
  [key: string]: Action;
};

// TODO: add remaining routes or change policy groups names accordingly
// policy group name for view tenant list is 'view_tenant_list', this policy group needs available on server
// and policy group should be assigned to roles
// and roles should be assigned to user, then only you are able to access the tenant list page
// user -> roles -> policy groups
export const accessConfig: KeyValueRole = {
  navigation: {
    view: [],
    edit: [],
  },
  dashboard: {
    view: [],
    edit: [],
  },
  dashboardWorkflowSupervisor: {
    view: [
      'WorkManagerAccessAll',
      'WMWorkItemOnly',
      'WMCases',
      'WMCollection',
      'WMAudit',
      'WMPaymentPlan',
      'WMRefunds',
      'WMTaxSale',
    ],
    edit: [],
  },
  settings: {
    view: [],
    edit: [],
  },
  addUser: {
    view: ['AddUserAccessAll'],
    edit: ['ManageUserRoleEdit'],
  },
  customerList: {
    view: ['CustomerListViewOnly'],
    edit: [],
  },
  createTenant: {
    view: ['CreateTenantEdit'],
    edit: ['CreateTenantEdit'],
  },
  tenantList: {
    view: ['TenantListViewOnly'],
    edit: ['ViewTenantViewOnly', 'CreateTenantEdit'],
  },
  viewTenant: {
    view: ['ViewTenantViewOnly'],
    edit: ['ViewTenantEdit'],
  },
  tenantConfig: {
    view: ['CreateTenantEdit'],
    edit: ['CreateTenantEdit'],
  },
  manageUserRoles: {
    view: ['ManageUserRoleViewOnly'],
    edit: ['ManageUserRoleEdit'],
  },
  createPolicyGroup: {
    view: ['CreatePolicyGroupEdit'],
    edit: [],
  },
  managePolicyGroups: {
    view: ['ManagePolicyGroupsViewOnly'],
    edit: [],
  },
  policyGroup: {
    view: ['EditPolicyGroupEdit'],
    edit: ['EditPolicyGroupEdit'],
  },
  manageBusinessPolicies: {
    view: ['ManageBusinessPoliciesViewOnly'],
    edit: [],
  },
  createBusinessPolicy: {
    view: [],
    edit: ['CreateBusinessPolicyEdit'],
  },
  editBusinessPolicy: {
    view: [],
    edit: ['EditBusinessPolicyEdit'],
  },
  createManageRoles: {
    view: ['CreateRoleEdit'],
    edit: [],
  },
  manageRoles: {
    view: ['ManageRolesViewOnly'],
    edit: [],
  },
  manageRolesEditRole: {
    view: ['EditRoleEdit'],
    edit: ['EditRoleEdit', 'EditRoleEnableOOTBRecordUpdate'],
  },
  manageRolesCloneRole: {
    view: ['CloneRoleEdit'],
    edit: ['CloneRoleEdit'],
  },
  manageChannels: {
    view: [],
    edit: [],
  },
  publishTask: {
    view: ['PlatformPublishScheduledTask'],
    edit: [],
  },
  createChannels: {
    view: [],
    edit: [],
  },
  channelDetails: {
    view: [],
    edit: [],
  },
  bulkUserUpload: {
    view: ['BulkUploadAccessAll'],
    edit: [],
  },
  workflows: {
    view: [],
    edit: [],
  },
  configTypeEditor: {
    view: ['platformconfigviewonly'],
    edit: ['platformconfigaccessall', 'platformconfiglimitaccess'],
  },
  configRecordEditor: {
    view: ['platformconfigviewonly'],
    edit: ['platformconfigaccessall', 'platformconfiglimitaccess'],
  },
  configGroupEditor: {
    view: ['platformconfigviewonly'],
    edit: ['platformconfigaccessall', 'platformconfiglimitaccess'],
  },
  workflowEditor: {
    view: [],
    edit: [],
  },
  maintainEntity: {
    view: ['EntityViewOnly'],
    edit: ['EntityEdit'],
  },
  createEntity: {
    view: ['EntityEdit'], // create entity link requires an edit access to be enabled
    edit: [],
  },
  maintainAccount: {
    view: ['AccountViewOnly'],
    edit: ['AccountEdit'],
  },
  createAccount: {
    view: ['AccountEdit'],
    edit: [],
  },
  maintainAsset: {
    view: ['AssetViewOnly'],
    edit: ['AssetEdit'],
  },
  createAsset: {
    view: ['AssetEdit'],
    edit: [],
  },
  manageForms: {
    view: ['FormsDesignAccessAll'],
    edit: [],
  },
  formEntry: {
    view: ['FormsEntryAccessAll'],
    edit: ['FormsDesignAccessAll', 'FormsEntryAccessAll'],
  },
  viewForms: {
    view: ['FormsEntryAccessAll'],
    edit: ['FormsDesignAccessAll', 'FormsEntryAccessAll'],
  },
  formView: {
    view: ['FormsEntryAccessAll'],
    edit: ['FormsDesignAccessAll', 'FormsEntryAccessAll'],
  },
  formSuspense: {
    view: ['FormsEntryAccessAll'],
    edit: ['FormsDesignAccessAll', 'FormsEntryAccessAll'],
  },
  logixTest: {
    view: ['FormsDesignAccessAll'],
    edit: [],
  },
  batches: {
    view: ['PropertyBatchViewOnly', 'RevenueBatchViewOnly'],
    edit: [],
  },
  createBatch: {
    view: ['PropertyBatchViewOnly', 'RevenueBatchViewOnly'],
    edit: [],
  },
  batchDetails: {
    view: ['PropertyBatchViewOnly', 'RevenueBatchViewOnly'],
    edit: ['PropertyBatchEdit', 'RevenueBatchEdit'],
  },
  manageBatchesProperty: {
    view: ['PropertyBatchViewOnly'],
    edit: ['PropertyBatchEdit'],
  },
  manageBatchesRevenue: {
    view: ['RevenueBatchViewOnly'],
    edit: ['RevenueBatchEdit'],
  },
  createManualCharge: {
    view: [],
    edit: ['PropertyManualChargeEdit', 'RevenueManualChargeEdit'],
  },
  reverseTransaction: {
    view: [],
    edit: ['PropertyTransactionEdit', 'RevenueTransactionEdit'],
  },
  reportsManagement: {
    view: ['reportManagementViewReportsList'],
    edit: ['reportManagementAccessAll'],
  },
  reportsDetails: {
    view: ['reportManagementDetailViewTemplatesList'],
    edit: ['reportManagementAccessAll'],
  },
  powerBi: {
    view: ['reportManagementDetailViewTemplate'],
    edit: ['reportManagementAccessAll'],
  },
  dashboardsManager: {
    view: ['reportManagementViewDashboardsList'],
    edit: ['reportManagementAccessAll'],
  },
  powerBiDashboards: {
    view: ['reportManagementDetailViewDashboard'],
    edit: ['reportManagementAccessAll'],
  },
};
