import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import {
  Box,
  SelectChangeEvent,
  InputAdornment,
  IconButton,
} from '@mui/material';
import {
  Checkbox,
  DatePicker,
  Input,
  Select,
} from '@revenue-solutions-inc/revxcoreui';
import {
  Attribute,
  useGetAllSchemasBriefQuery,
  useGetConfigurationsByGroupQuery,
  useGetDynamicLookupsConfigurationsQuery,
} from 'generated/graphql';
import KeyIcon from '@mui/icons-material/Key';
import { getDate } from 'common/helpers';
import { useTranslation } from 'react-i18next';
import useMaskInput from 'hooks/useMaskInput';
import { useAttributeValidations } from 'hooks/useAttributeValidations';
import { SelectType } from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import { intExp } from 'common/regexp';
import FunctionsIcon from '@mui/icons-material/Functions';
import InfoIcon from '@mui/icons-material/Info';
import {
  AttributeTypes,
  BusinessDrivers,
  ConfigurationModules,
} from 'common/platformConfigUtils/platformConfigUtils';
import { getDriverValue, validateConstraints } from 'utils/attributeUtils';
import LoadingHelper from 'components/Loading/LoadingHelper';
import Dialog from '@revenue-solutions-inc/revxcoreui/material/controls/Dialog';
import AttributePreview from '../AttributePreview';

export type QueryVariablesAttributeInput = {
  schemaModule: string;
  schemaDomain: string;
  currentDataModule: string;
  selectedModule?: string;
};

interface Props {
  attr: Attribute;
  handleAttrChange: (
    attr: Attribute,
    date?: Date | null,
    event?:
      | React.ChangeEvent<HTMLInputElement>
      | SelectChangeEvent<string | number>,
    checked?: boolean
  ) => void;
  queriesVariables: QueryVariablesAttributeInput;
  expressionClickHandler?: (attr: Attribute) => void;
  editDisabled?: boolean;
  error?: string;
}

const pkBoxStyle = {
  display: 'flex',
  direction: 'row',
  justifyContent: 'center',
  alignItems: 'end',
};

// TODO: Finish implementing an indicator to the user that a field is hidden in another part of the app
// const checkIsHidden = (attr: Attribute) => {
//   return attr.extensibleBusinessDriver?.some(
//     (driver) => driver.driverNameType === BusinessDrivers.Hidden
//   );
// };

function AttributeInput({
  attr,
  handleAttrChange,
  editDisabled = false,
  expressionClickHandler = () => {},
  queriesVariables: {
    schemaDomain,
    currentDataModule,
    selectedModule = ConfigurationModules.Platform.toString(),
  },
  error = '',
}: Props) {
  const { t } = useTranslation();
  const [integer, setInteger] = useState<string>(attr.attributeValue);
  const integerMask = useMaskInput('999999', '', intExp, attr.attributeValue);
  const [localError, setLocalError] = useState('');

  const { checkIsRequired, checkIsPK } = useAttributeValidations();

  const pkIcon = () => (
    <IconButton title={t('pages.dynamicAttributes.primaryKey')} disabled>
      <KeyIcon sx={{ pointerEvents: 'all' }} />
    </IconButton>
  );

  const [showPreview, setShowPreview] = useState(false);
  const minDate = useMemo(() => {
    return getDriverValue(attr, BusinessDrivers.FieldMinimum);
  }, [attr]);
  const maxDate = useMemo(() => {
    return getDriverValue(attr, BusinessDrivers.FieldMaximum);
  }, [attr]);

  const { data: lookups, status: lookupsStatus } =
    useGetDynamicLookupsConfigurationsQuery(
      {
        // if the record is not in editMode, we get the module from the currentData, otherwise, all the other records that are not in
        // editMode will also refresh their data when the user changes the module from the one that is in edit mode, which is not good
        selectedModule: editDisabled
          ? currentDataModule.toString()
          : selectedModule.toString(),
        configurationType: attr.dataSource ?? '',
      },
      {
        staleTime: 60 * 1000,
        enabled:
          attr.attributeType?.toLowerCase() ===
            AttributeTypes.referenceData.toLowerCase() &&
          !!attr.dataSource &&
          attr.dataSource !== '',
      }
    );

  const { data: allSchemas, status: allSchemasStatus } =
    useGetAllSchemasBriefQuery(
      {
        configurationDomain: schemaDomain,
        configurationModule: editDisabled ? currentDataModule : selectedModule,
      },
      {
        staleTime: 60 * 1000,
        enabled:
          attr.attributeType?.toLowerCase() ===
          AttributeTypes.schema.toLowerCase(),
      }
    );
  // get all schemas based on group name, in this case we only want schemas for extended attribute group
  const { data: extendedConfigTypes, status: extendedConfigTypesStatus } =
    useGetConfigurationsByGroupQuery(
      {
        groupName: 'Extended Attributes',
      },
      {
        staleTime: 60 * 1000,
        enabled:
          attr.attributeType?.toLocaleLowerCase() ===
          AttributeTypes.extendedAttribute.toLowerCase(),
      }
    );

  const optionsReferenceSchema =
    allSchemas?.getAllSchemas.map(
      (option) =>
        ({
          key: option.key,
          desc: option.key,
        } as SelectType)
    ) ?? [];

  const optionsReferenceData =
    lookups?.getDynamicLookupsConfigurations.map(
      (option) =>
        ({
          key: option.configurationName,
          desc: option.configurationName,
        } as SelectType)
    ) ?? [];

  const optionsExtendedAttributes =
    extendedConfigTypes?.getConfigurationsByGroup.map(
      (option) =>
        ({
          key: option.platformConfigurationId,
          desc: option.configurationName,
        } as SelectType)
    ) ?? [];

  //if an error is coming in, use that, otherwise overwrite it with the local error
  useEffect(() => {
    setLocalError(error);
  }, [error]);

  const renderSwitch = (attribute: Attribute) => {
    switch (attribute.attributeType?.toLowerCase() as AttributeTypes) {
      case AttributeTypes.referenceData.toLowerCase():
        return (
          <LoadingHelper
            label={attr.attributeDisplayName ?? attr.attributeName}
            queryStatus={lookupsStatus}
            isDataEmpty={!optionsReferenceData.length}
            toRender={
              <Box sx={pkBoxStyle}>
                <Select
                  id="refdata-select-dynamicattribute"
                  label={attr.attributeDisplayName ?? attr.attributeName}
                  value={attr.attributeValue}
                  onChange={(event) => handleAttrChange(attr, null, event)}
                  options={optionsReferenceData}
                  required={checkIsRequired(attr)}
                  disabled={editDisabled}
                  data-testid="select-dynamicattributes"
                  width="15em"
                />
                {checkIsPK(attr) && pkIcon()}
              </Box>
            }
          />
        );
      case AttributeTypes.schema.toLowerCase():
        return (
          <LoadingHelper
            label={attr.attributeDisplayName ?? attr.attributeName}
            queryStatus={allSchemasStatus}
            isDataEmpty={!optionsReferenceSchema.length}
            toRender={
              <Select
                id="schema-select-attribute"
                label={attr.attributeDisplayName ?? attr.attributeName}
                value={attr.attributeValue}
                onChange={(event) => handleAttrChange(attr, null, event)}
                options={optionsReferenceSchema}
                required={checkIsRequired(attr)}
                disabled={editDisabled}
                width="15em"
              />
            }
          />
        );
      case AttributeTypes.extendedAttribute.toLowerCase():
        return (
          <LoadingHelper
            label={attr.attributeDisplayName ?? attr.attributeName}
            queryStatus={extendedConfigTypesStatus}
            isDataEmpty={!optionsExtendedAttributes.length}
            toRender={
              <Select
                id="extended-select-attribute"
                label={attr.attributeDisplayName ?? attr.attributeName}
                value={attr.attributeValue}
                onChange={(event) => handleAttrChange(attr, null, event)}
                options={optionsExtendedAttributes}
                required={checkIsRequired(attr)}
                disabled={editDisabled}
                width="15em"
              />
            }
          />
        );
      // TODO: enable this if needed when datetime component is working properly
      //  case AttributeTypes.datetime.toLowerCase():
      //   return (
      //     <DateTimePicker
      //       required={checkIsRequired(attr)}
      //       id="datetimepicker"
      //       label={attr.attributeDisplayName ?? attr.attributeName}
      //       value={attr.attributeValue ? new Date(attr.attributeValue) : ''}
      //       handleChange={(date: Date | null) => {
      //         if (date !== null && date?.toString() !== 'Invalid Date') {
      //           handleAttrChange(attr, date);
      //           setLocalError(validateConstraints(attr));
      //         }
      //       }}
      //       disabled={editDisabled}
      //       data-testid="datetimepicker"
      //       minDate={minDate}
      //       maxDate={maxDate}
      //     />
      //   );
      case AttributeTypes.date.toLowerCase():
        return (
          <DatePicker
            required={checkIsRequired(attr)}
            id="datepicker-dynamicattribute"
            label={attr.attributeDisplayName ?? attr.attributeName}
            value={new Date(getDate(attr.attributeValue))}
            handleChange={(date: Date | null) => {
              if (date !== null && date?.toString() !== 'Invalid Date') {
                handleAttrChange(attr, date);
              }
            }}
            isCalendarRequired={true}
            size="medium"
            disabled={editDisabled}
            minDate={minDate}
            maxDate={maxDate}
            onBlur={() => {
              setLocalError(validateConstraints(attr));
            }}
          />
        );
      case AttributeTypes.boolean.toLowerCase():
        return (
          <Checkbox
            id="checkbox-dynamicattribute"
            checked={
              attr.attributeValue === '' || attr.attributeValue === 'false'
                ? false
                : true
            }
            onChange={(event, checked: boolean | undefined) =>
              handleAttrChange(attr, null, event, checked)
            }
            label={attr.attributeDisplayName ?? attr.attributeName}
            disabled={editDisabled}
            sx={{ mt: '.85em' }}
          />
        );
      case AttributeTypes.int.toLowerCase():
        return (
          <Box sx={pkBoxStyle}>
            <Input
              id="integer-input-dynamicattribute"
              label={attr.attributeDisplayName ?? attr.attributeName}
              value={integer}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                integerMask.handleMaskChange(event);
                setInteger(event.target.value);
                attr.attributeValue = integer;
                handleAttrChange(attr, null, event);
              }}
              required={checkIsRequired(attr)}
              disabled={editDisabled}
              inputProps={{ maxLength: 250 }}
              error={!!localError}
              helperText={t(localError)}
              onBlur={() => {
                setLocalError(validateConstraints(attr));
              }}
            />
            {checkIsPK(attr) && pkIcon()}
          </Box>
        );
      default:
        return (
          <Box sx={pkBoxStyle}>
            <Input
              id="input-dynamicattribute"
              label={attr.attributeDisplayName ?? attr.attributeName}
              value={attr.attributeValue}
              onChange={(event) => {
                handleAttrChange(attr, null, event);
              }}
              required={checkIsRequired(attr)}
              disabled={editDisabled}
              inputProps={{ maxLength: 250 }}
              error={!!localError}
              helperText={t(localError)}
              onBlur={() => {
                setLocalError(validateConstraints(attr));
              }}
            />
            {checkIsPK(attr) && pkIcon()}
          </Box>
        );
    }
  };

  const IsExpressionComponent = (
    <Input
      id="isExpression-input-dynamicattribute"
      label={attr.attributeDisplayName ?? attr.attributeName}
      value={attr.expressionValue ?? ''}
      required={checkIsRequired(attr)}
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        handleAttrChange(attr, null, event);
      }}
      disabled={editDisabled}
      inputProps={{ maxLength: 250, readOnly: true }}
      onBlur={() => {
        setLocalError(validateConstraints(attr));
      }}
      error={!!localError}
      helperText={t(localError)}
      endAdornment={
        <InputAdornment
          position="end"
          sx={{
            cursor: 'pointer',
            fontSize: '20px',
          }}
        >
          <IconButton
            data-testid="isExpression-button-dynamicattribute"
            onClick={() => expressionClickHandler(attr)}
            disabled={editDisabled}
          >
            <FunctionsIcon sx={{ fontSize: '20px' }} />
          </IconButton>
        </InputAdornment>
      }
    />
  );

  return (
    <Box key={attr.attributeName} sx={{ mr: '1em', display: 'inherit' }}>
      {attr && !!attr.isExpression ? IsExpressionComponent : renderSwitch(attr)}
      <IconButton onClick={() => setShowPreview(true)}>
        <InfoIcon />
      </IconButton>
      <Dialog
        id={'attributeDetailsModal'}
        title={t('pages.configTypeEditor.attributeDetails')}
        open={showPreview}
        handleClose={() => {
          setShowPreview(false);
        }}
        handleCancelClick={() => {
          setShowPreview(false);
        }}
        maxWidth={'sm'}
      >
        <AttributePreview attribute={attr}></AttributePreview>
      </Dialog>
    </Box>
  );
}

export default AttributeInput;
