import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { QueryStatus } from '@tanstack/react-query';
import Loading from 'components/Loading';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import { useTranslation } from 'react-i18next';
function LoadingHelper({
  label,
  queryStatus = 'success',
  isDataEmpty = false,
  toRender,
}: {
  label: string;
  /** The query status coming from your query hook, this status contains 3 strings describing the state of your query
   * @example
   * const { data, status } = useMyExampleHookQuery('');
   * queryStatus={status}
   */
  queryStatus?: QueryStatus;
  /** optional, if you want to display a message when your data is empty set this to true, you can add a conditional inside
   * to check if your data is empty
   * @example
   * isDataEmpty={!myArray.length}
   * isDataEmpty={myData === ''}
   * isDataEmpty={!myData}
   */
  isDataEmpty?: boolean;
  toRender: JSX.Element;
}) {
  const { t } = useTranslation();
  const loadingElement = (
    <Stack
      alignItems="center"
      justifyContent="flex-end"
      height="100%"
      minWidth="130px"
    >
      <Typography variant="inherit">{label}</Typography>
      <Loading fullScreen={false} size={20} />
    </Stack>
  );

  const errorElement = (
    <Stack
      alignItems="center"
      justifyContent="flex-end"
      height="100%"
      minWidth="130px"
      mr={'2px'}
      ml={'2px'}
    >
      <Typography variant="inherit">{label}</Typography>
      <ReportProblemIcon
        sx={(theme) => ({ color: theme.palette.warning.main })}
      />
      <Typography
        variant="inherit"
        sx={(theme) => ({ color: theme.palette.error.main })}
      >
        {t('components.message.errorFetching')}
      </Typography>
    </Stack>
  );

  const dataEmptyElement = (
    <Stack
      alignItems="center"
      justifyContent="flex-end"
      height="100%"
      minWidth="100px"
      mr={'2px'}
      ml={'2px'}
    >
      <Typography variant="inherit">{label}</Typography>
      <SearchOffIcon />
      <Typography
        variant="inherit"
        sx={(theme) => ({ color: theme.palette.primary.light })}
      >
        {t('components.message.noDataToDisplay')}
      </Typography>
    </Stack>
  );

  return queryStatus == 'loading'
    ? loadingElement
    : queryStatus == 'error'
    ? errorElement
    : isDataEmpty
    ? dataEmptyElement
    : toRender;
}
export default LoadingHelper;
