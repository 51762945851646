import { phoneExp } from 'common/regexp';
import { Section } from 'types/forms';

import { radioOptions } from '../defaults/entity';
import states from '../defaults/states';
import { setOptions } from '../formatValidations';

export const creationSourceField = {
  label: 'Creation Source',
  fieldIdentifier: 'accountCreationSource',
  type: 'select',
  isRequired: true,
  options: setOptions(['Channel', 'Online']),
  rules: { required: 'Creation Source is required' },
  requiredErrorMessage: 'Creation Source is required',
  disabled: false,
};

export const commenceDateField = {
  label: 'Commence Date',
  fieldIdentifier: 'commenceDate',
  type: 'date',
  isRequired: true,
};

export const ceaseDateField = {
  label: 'Cease Date',
  fieldIdentifier: 'ceaseDate',
  type: 'date',
};

export const AccountFields: Section = {
  sectionTitle: 'Account',
  sectionIdentifier: 'account',
  fields: [
    {
      label: 'Account Type',
      fieldIdentifier: 'AccountType',
      type: 'datasource',
      datasource: 'EntityToAccountTypes',
      isRequired: true,
      groupName: 'DestinationAccountTypes',
      attributeName: 'AccountType',
      extraType: '',
      context: 'accountType',
      rules: { required: 'Account type is required' },
      requiredErrorMessage: 'Account type is required',
    },
    creationSourceField,
    {
      label: 'Commence Date',
      fieldIdentifier: 'commenceDate',
      type: 'date',
      isRequired: true,
      rules: { required: 'Commence date is required' },
      requiredErrorMessage: 'Commence date is required',
    },
    ceaseDateField,
    {
      label: 'Reason',
      fieldIdentifier: 'reason',
      type: 'selectOnlyEdit',
      options: setOptions([
        'Adjustment',
        'Bad Check',
        'Bankruptcy Liability',
        'Converted Manually',
        'Deferral',
        'Delinquent',
        'Late Payment',
        'Misc Penalties',
        'Reversal',
      ]),
    },
    {
      label: 'Active',
      fieldIdentifier: 'active',
      type: 'radio',
      isRequired: true,
      radioOptions: radioOptions,
      rules: { required: 'Active is required' },
    },
  ],
};

export const NameFields: Section = {
  sectionTitle: 'Name',
  sectionIdentifier: 'names',
  fields: [
    {
      label: 'Account Name',
      fieldIdentifier: 'AccountName',
      type: 'text',
      isRequired: true,
      rules: { required: 'Account Name is required' },
      requiredErrorMessage: 'Account Name is required',
    },
    commenceDateField,
    ceaseDateField,
    {
      label: 'Is Primary',
      fieldIdentifier: 'isPrimary',
      type: 'radio',
      rules: { required: 'Is Primary is required' },
      isRequired: true,
      radioOptions: radioOptions,
      requiredErrorMessage: 'Is Primary is required',
    },
  ],
};

export const IdentifierFields: Section = {
  sectionTitle: 'Identifiers',
  sectionIdentifier: 'identifiers',
  fields: [
    {
      label: 'Type',
      fieldIdentifier: 'IdType',
      type: 'datasource',
      datasource: 'AccountToIdTypes',
      isRequired: true,
      groupName: 'DestinationIdTypes',
      attributeName: 'IdType',
      context: 'accountType',
      rules: { required: 'ID type is required' },
      requiredErrorMessage: 'ID type is required',
    },
    {
      label: 'Account ID',
      fieldIdentifier: 'value',
      type: 'identifier',
      isRequired: true,
      rules: { required: 'Account ID is required' },
      requiredErrorMessage: 'Account ID is required',
    },
    {
      label: 'Commence Date',
      fieldIdentifier: 'commenceDate',
      isRequired: true,
      type: 'date',
    },
    {
      label: 'Cease Date',
      fieldIdentifier: 'ceaseDate',
      type: 'date',
    },
    {
      label: 'Is Primary',
      fieldIdentifier: 'isPrimary',
      type: 'radio',
      rules: { required: 'Is Primary is required' },
      isRequired: true,
      radioOptions: radioOptions,
      requiredErrorMessage: 'Is Primary is required',
    },
  ],
};

export const AddressFields: Section = {
  sectionTitle: 'Addresses',
  sectionIdentifier: 'addresses',
  fields: [
    {
      label: 'Entity Addresses',
      fieldIdentifier: 'type',
      type: 'entityAddress',
    },
    {
      label: 'Address Type',
      fieldIdentifier: 'AddressType',
      type: 'datasource',
      datasource: 'AccountToAddressTypes',
      groupName: 'DestinationAddressTypes',
      attributeName: 'AddressType',
      context: 'accountType',
      isRequired: true,
      rules: { required: 'Address type is required' },
      requiredErrorMessage: 'Address type is required',
    },
    {
      label: 'Country',
      fieldIdentifier: 'countryId',
      type: 'select',
      isRequired: true,
      rules: { required: 'Country is required' },
      requiredErrorMessage: 'Country is required',
      options: setOptions(['USA', 'Canada', 'International']),
    },
    {
      label: 'Attention',
      fieldIdentifier: 'attention',
      type: 'text',
    },
    {
      label: 'Address 1',
      fieldIdentifier: 'addressLine1',
      type: 'text',
      isRequired: true,
      rules: { required: 'Address is required' },
      requiredErrorMessage: 'Address is required',
    },
    {
      label: 'Address 2',
      fieldIdentifier: 'addressLine2',
      type: 'text',
    },
    {
      label: 'City',
      fieldIdentifier: 'cityId',
      type: 'text',
      isRequired: true,
      rules: { required: 'City is required' },
      requiredErrorMessage: 'City is required',
    },
    {
      label: 'County',
      fieldIdentifier: 'countyId',
      type: 'text',
    },
    {
      label: 'State',
      fieldIdentifier: 'stateProvinceId',
      type: 'select',
      options: setOptions(states),
      isRequired: true,
      rules: { required: 'State is required' },
      requiredErrorMessage: 'State is required',
    },
    {
      label: 'Zip',
      fieldIdentifier: 'postalCode',
      type: 'text',
      isRequired: true,
      rules: { required: 'Zip is required' },
      requiredErrorMessage: 'Zip is required',
    },
    {
      label: 'Commence Date',
      fieldIdentifier: 'commenceDate',
      isRequired: true,
      type: 'date',
    },
    {
      label: 'Cease Date',
      fieldIdentifier: 'ceaseDate',
      type: 'date',
    },
    {
      label: 'Is Primary',
      fieldIdentifier: 'isPrimary',
      type: 'radio',
      rules: { required: 'Is Primary is required' },
      isRequired: true,
      requiredErrorMessage: 'Is Primary is required',
      radioOptions: radioOptions,
    },
    {
      label: 'Is Confidential',
      fieldIdentifier: 'isConfidential',
      type: 'radio',
      rules: { required: 'Is Confidential is required' },
      isRequired: true,
      requiredErrorMessage: 'Is Confidential is required',
      radioOptions: radioOptions,
    },
    {
      label: 'Has Mail Returned',
      fieldIdentifier: 'hasMailReturned',
      type: 'radio',
      rules: { required: 'Has Mail Returned is required' },
      isRequired: true,
      requiredErrorMessage: 'Has Mail Returned is required',
      radioOptions: radioOptions,
    },
  ],
};

export const PhoneNumberFields: Section = {
  sectionTitle: 'Phone Numbers',
  sectionIdentifier: 'phoneNumbers',
  fields: [
    {
      label: 'Type',
      fieldIdentifier: 'PhoneNumberType',
      type: 'datasource',
      datasource: 'PhoneType',
      isRequired: true,
      rules: { required: 'Phone type is required' },
      requiredErrorMessage: 'Phone type is required',
    },
    {
      label: 'Phone Number',
      fieldIdentifier: 'value',
      type: 'text',
      isRequired: true,
      rules: {
        required: 'Phone number is required',
        pattern: {
          value: phoneExp,
          message: 'Please provide a valid phone number',
        },
      },
      requiredErrorMessage: 'Phone number is required',
    },
    commenceDateField,
    ceaseDateField,
    {
      label: 'Is Primary',
      fieldIdentifier: 'isPrimary',
      type: 'radio',
      rules: { required: 'Is Primary is required' },
      isRequired: true,
      requiredErrorMessage: 'Is Primary is required',
      radioOptions: radioOptions,
    },
  ],
};

export const EmailAddressFields: Section = {
  sectionTitle: 'Email Addresses',
  sectionIdentifier: 'emailAddresses',
  fields: [
    {
      label: 'Type',
      fieldIdentifier: 'EmailAddressType',
      type: 'datasource',
      datasource: 'EmailAddressType',
      isRequired: true,
      rules: { required: 'Email type is required' },
      requiredErrorMessage: 'Email type is required',
    },
    {
      label: 'Email Address',
      fieldIdentifier: 'value',
      type: 'text',
      isRequired: true,
      rules: {
        required: 'Email is required',
        pattern: {
          value:
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
          message: 'Please provide a valid email',
        },
      },
      requiredErrorMessage: 'Email is required',
    },
    commenceDateField,
    ceaseDateField,
    {
      label: 'Is Primary',
      fieldIdentifier: 'isPrimary',
      type: 'radio',
      rules: { required: 'Is Primary is required' },
      isRequired: true,
      requiredErrorMessage: 'Is Primary is required',
      radioOptions: radioOptions,
    },
  ],
};

export const IndustryCodes: Section = {
  sectionTitle: 'Industry Codes',
  sectionIdentifier: 'industryCode',
  fields: [
    {
      label: 'Industry Code',
      fieldIdentifier: 'code',
      type: 'text',
      isRequired: true,
      rules: { required: 'Industry Code is required' },
      requiredErrorMessage: 'Industry Code is required',
    },
    {
      label: 'Description',
      fieldIdentifier: 'codeDescription',
      type: 'industrycodesource',
      isRequired: true,
      rules: {
        required: 'Description is required',
      },
      requiredErrorMessage: 'Description is required',
    },
  ],
};

export const FilingFrequencyFields: Section = {
  sectionTitle: 'Filing Frequencies',
  sectionIdentifier: 'filingFrequencies',
  fields: [
    {
      label: 'Period Frequency',
      fieldIdentifier: 'periodFrequency',
      type: 'datasource',
      datasource: 'AccountToPeriodFrequencyTypes',
      groupName: 'DestinationPeriodFrequencyTypes',
      attributeName: 'PeriodFrequencyType',
      context: 'accountType',
      isRequired: true,
      rules: { required: 'Period Frequency is required' },
      requiredErrorMessage: 'Period Frequency is required',
    },
    {
      label: 'Fiscal Year End Month',
      fieldIdentifier: 'periodFrequencyMonth',
      type: 'select',
      context: 'accountType',
      options: [
        { key: '1', desc: 'January' },
        { key: '2', desc: 'February' },
        { key: '3', desc: 'March' },
        { key: '4', desc: 'April' },
        { key: '5', desc: 'May' },
        { key: '6', desc: 'June' },
        { key: '7', desc: 'July' },
        { key: '8', desc: 'August' },
        { key: '9', desc: 'September' },
        { key: '10', desc: 'October' },
        { key: '11', desc: 'November' },
      ],
      isRequired: true,
      rules: { required: 'Period frequency month is required' },
      hidden: true,
    },
    commenceDateField,
    ceaseDateField,
  ],
};
