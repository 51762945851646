import { Controller, UseFormReturn } from 'react-hook-form';
import { SxProps, Theme } from '@mui/system';

import { TextArea } from '@revenue-solutions-inc/revxcoreui';

interface ControlledTextFieldProps {
  // eslint-disable-next-line
  control: UseFormReturn['control'] | any;
  name: string;
  helperText: string;
  multiline: boolean;
  rules: Record<string, unknown>;
  disabled?: boolean;
  label: string;
  required?: boolean;
  id: string;
  sx?: SxProps<Theme>;
}

function ControlledTextField({
  control,
  helperText,
  multiline,
  name,
  disabled = false,
  rules,
  required,
  label,
  id,
  sx,
}: ControlledTextFieldProps) {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({
        field: { value, onChange },
        fieldState: { error: noteError },
      }) => {
        return (
          <TextArea
            id={id}
            label={label}
            required={required}
            value={value}
            onChange={onChange}
            disabled={disabled}
            multiline={multiline}
            error={noteError ? true : false}
            helperText={helperText}
            sx={sx}
          />
        );
      }}
    />
  );
}
export default ControlledTextField;
