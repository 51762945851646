import { Section } from 'types/forms';

import { radioOptions } from '../defaults/entity';
import states from '../defaults/states';
import { setOptions } from '../formatValidations';

export const OtherFields: Section = {
  sectionTitle: 'Asset',
  sectionIdentifier: 'other',
  fields: [
    {
      label: 'Asset Type',
      fieldIdentifier: 'assetType',
      type: 'datasource',
      datasource: 'AssetType',
      rules: { required: 'Asset type is required' },
      isRequired: true,
      requiredErrorMessage: 'Asset type is required',
    },
    {
      label: 'Creation Source',
      fieldIdentifier: 'assetCreationSource',
      type: 'select',
      options: setOptions(['Channel', 'Online']),
      rules: { required: 'Creation Source is required' },
      isRequired: true,
      requiredErrorMessage: 'Creation Source is required',
    },
    {
      label: 'Commence Date',
      fieldIdentifier: 'commenceDate',
      type: 'date',
      rules: { required: 'Commence date is required' },
      isRequired: true,
      requiredErrorMessage: 'Commence date is required',
    },
    {
      label: 'Cease Date',
      fieldIdentifier: 'ceaseDate',
      type: 'date',
    },
  ],
};

export const NameFields = {
  sectionTitle: 'Name',
  sectionIdentifier: 'names',
  fields: [
    {
      label: 'Type',
      fieldIdentifier: 'nameType',
      type: 'datasource',
      datasource: 'AssetToNameTypes',
      groupName: 'DestinationNameTypes',
      attributeName: 'NameType',
      context: 'AssetType',
      rules: { required: 'Name type is required' },
      isRequired: true,
    },
    {
      label: 'Name',
      fieldIdentifier: 'value',
      type: 'text',
      rules: { required: 'Name is required' },
      isRequired: true,
    },
    {
      label: 'Commence Date',
      fieldIdentifier: 'commenceDate',
      type: 'date',
      isRequired: true,
    },
    {
      label: 'Cease Date',
      fieldIdentifier: 'ceaseDate',
      type: 'date',
    },
    {
      label: 'Is Primary',
      fieldIdentifier: 'isPrimary',
      type: 'radio',
      rules: { required: 'Is Primary is required' },
      isRequired: true,
      radioOptions: radioOptions,
    },
  ],
};

export const IdentifierFields: Section = {
  sectionTitle: 'Identifier',
  sectionIdentifier: 'identifiers',
  fields: [
    {
      label: 'Type',
      fieldIdentifier: 'IdType',
      type: 'datasource',
      datasource: 'AssetToIdTypes',
      rules: { required: 'ID type is required' },
      isRequired: true,
      groupName: 'DestinationIdTypes',
      attributeName: 'IdType',
      context: 'AssetType',
    },
    {
      label: 'ID',
      fieldIdentifier: 'value',
      type: 'identifier',
      rules: { required: 'ID is required' },
      isRequired: true,
      requiredErrorMessage: 'ID is required',
    },
    {
      label: 'Commence Date',
      fieldIdentifier: 'commenceDate',
      type: 'date',
      isRequired: true,
    },
    {
      label: 'Cease Date',
      fieldIdentifier: 'ceaseDate',
      type: 'date',
    },
    {
      label: 'Is Primary',
      fieldIdentifier: 'isPrimary',
      type: 'radio',
      rules: { required: 'Is Primary is required' },
      isRequired: true,
      radioOptions: radioOptions,
    },
  ],
};

export const AddressFields: Section = {
  sectionTitle: 'Address',
  sectionIdentifier: 'addresses',
  fields: [
    {
      label: 'Type',
      fieldIdentifier: 'addressType',
      type: 'datasource',
      datasource: 'AssetToAddressTypes',
      groupName: 'DestinationAddressTypes',
      attributeName: 'AddressType',
      context: 'AssetType',
      rules: { required: 'Address type is required' },
      isRequired: true,
    },
    {
      label: 'Country',
      fieldIdentifier: 'countryId',
      type: 'select',
      isRequired: true,
      rules: { required: 'Country is required' },
      requiredErrorMessage: 'Country is required',
      options: setOptions(['USA', 'Canada', 'International']),
    },
    {
      label: 'Attention',
      fieldIdentifier: 'attention',
      type: 'text',
    },
    {
      label: 'Address 1',
      fieldIdentifier: 'addressLine1',
      type: 'text',
      rules: { required: 'Address is required' },
      isRequired: true,
      requiredErrorMessage: 'Address is required',
    },
    {
      label: 'Address 2',
      fieldIdentifier: 'addressLine2',
      type: 'text',
    },
    {
      label: 'City',
      fieldIdentifier: 'cityId',
      type: 'text',
      rules: { required: 'City is required' },
      isRequired: true,
      requiredErrorMessage: 'City is required',
    },
    {
      label: 'County',
      fieldIdentifier: 'countyId',
      type: 'text',
    },
    {
      label: 'State',
      fieldIdentifier: 'stateProvinceId',
      type: 'select',
      options: setOptions(states),
      rules: { required: 'State is required' },
      isRequired: true,
      requiredErrorMessage: 'State is required',
    },
    {
      label: 'Zip',
      fieldIdentifier: 'postalCode',
      type: 'text',
      isRequired: true,
      rules: { required: 'Zip is required' },
      requiredErrorMessage: 'Zip is required',
    },
    {
      label: 'Commence Date',
      fieldIdentifier: 'commenceDate',
      type: 'date',
      isRequired: true,
    },
    {
      label: 'Cease Date',
      fieldIdentifier: 'ceaseDate',
      type: 'date',
    },
    {
      label: 'Is Primary',
      fieldIdentifier: 'isPrimary',
      type: 'radio',
      rules: { required: 'Is Primary is required' },
      isRequired: true,
      radioOptions: radioOptions,
    },
    {
      label: 'Is Confidential',
      fieldIdentifier: 'isConfidential',
      type: 'radio',
      rules: { required: 'Is Confidential is required' },
      isRequired: true,
      radioOptions: radioOptions,
    },
    {
      label: 'Has Mail Returned',
      fieldIdentifier: 'hasMailReturned',
      type: 'radio',
      rules: { required: 'Has Mail Returned is required' },
      isRequired: true,
      radioOptions: radioOptions,
    },
  ],
};
