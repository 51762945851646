import { useTheme } from '@mui/system';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';

type WorkflowIDCellProps = {
  displayId: string;
  variantId: string;
  workflowKey: string;
};

const WorkflowIDCell = ({
  displayId,
  variantId,
  workflowKey,
}: WorkflowIDCellProps) => {
  const module = useAppSelector((state) => state.user.module);
  const theme = useTheme();

  return (
    <Link
      to={`/${module}/workflow/update/${workflowKey}/${variantId}`}
      style={{ color: theme.palette.linkBlue.dark }}
    >
      {displayId}
    </Link>
  );
};

export default WorkflowIDCell;
