import { ChangeEvent, useState, useEffect } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Input from '@revenue-solutions-inc/revxcoreui/material/controls/Input';
import ExemptionsManager from 'components/exemptions';
import TaxRatesManager from 'components/taxRates/TaxRatesManager';
import useMaskInput from 'hooks/useMaskInput';
import { useTranslation } from 'react-i18next';
import {
  exemptionDefault,
  TaxExemption,
  TaxRate,
  taxRateDefault,
} from 'types/taxingAuthority/entities';

interface Props {
  setIsPeriodValid: (arg1: boolean) => void;
  setTaxRates: (arg1: TaxRate[]) => void;
  setTaxExemptions: (arg1: TaxExemption[]) => void;
  setTaxYear: (arg1: string) => void;
  taxExemptions: TaxExemption[];
  taxRates: TaxRate[];
  taxYear: string | undefined;
}
function TaxingAuthorityPeriod({
  setIsPeriodValid,
  taxRates,
  setTaxRates,
  taxExemptions,
  setTaxExemptions,
  setTaxYear,
  taxYear,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const [taxRatesHaveErrors, setRateError] = useState<boolean>(false);
  const [exemptionsHaveErrors, setExemptionError] = useState<boolean>(false);

  const yearMask = useMaskInput('', 'Invalid Year', /^(19|20)\d{2}$/, taxYear);
  const handleHelperTextChange = () => {
    return yearMask.maskError;
  };

  function validateTaxRatesAndExemptions() {
    let isValid = true;
    if (taxYear === '' || yearMask.maskError) isValid = false;

    if (taxRates) {
      if (taxRatesHaveErrors) {
        isValid = false;
      }
      taxRates.forEach((item) => {
        if (item.rate === '' || item.taxingAuthorityRateType === '') {
          isValid = false;
        }
      });
    }

    if (taxExemptions) {
      if (exemptionsHaveErrors) {
        isValid = false;
      }
      taxExemptions.forEach((item) => {
        if (
          item.taxingAuthorityExemptionType === '' ||
          item.taxingAuthorityRateType === ''
        ) {
          isValid = false;
        }
        if (item.amount === '' && item.percentage === '') {
          isValid = false;
        }
      });
    }
    return isValid;
  }

  useEffect(() => {
    const isValid = validateTaxRatesAndExemptions();
    setIsPeriodValid(isValid);
  });

  return (
    <>
      <Typography variant="h2">
        {t('pages.manageTaxingAuthority.taxPeriod')}
      </Typography>
      <Grid item mt={2} xs={12}>
        <Input
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            yearMask.handleMaskChange(event);
            setTaxYear(event.target.value);
          }}
          id="taxYear"
          value={taxYear}
          label={t('pages.manageTaxingAuthority.taxYear')}
          required
          helperText={handleHelperTextChange()}
        />
      </Grid>
      <Grid item mt={2} xs={12}>
        <TaxRatesManager
          taxRates={taxRates ?? [taxRateDefault]}
          onChange={(newTaxRates: TaxRate[]) => {
            setTaxRates(newTaxRates);
          }}
          onError={(hasError: boolean) => {
            setRateError(hasError);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <ExemptionsManager
          exemptions={taxExemptions ? taxExemptions : [exemptionDefault]}
          onChange={(newExemptions: TaxExemption[]) => {
            setTaxExemptions(newExemptions);
          }}
          onError={(hasError: boolean) => {
            setExemptionError(hasError);
          }}
        />
      </Grid>
    </>
  );
}

export default TaxingAuthorityPeriod;
