import { useCallback, useContext, useState, useEffect, useRef } from 'react';

import { Box, Grid } from '@mui/material';
import { Add } from '@mui/icons-material';
import { MessageType } from '@revenue-solutions-inc/revxcoreui';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import { ConfigurationDomains } from 'common/platformConfigUtils/platformConfigUtils';
import EntityManagementContext from 'components/contexts/EntityManagement';
import {
  buildAccountRequest,
  sectionNames,
  findSection,
} from 'components/entityManagement/common/accountUtils';
import { FilingFrequencyFields } from 'components/entityManagement/common/fields/accounts';
import CardAvatar from 'components/entityManagement/common/CardAvatar';
import {
  buildExtendedValues,
  buildMaintainExtendedValues,
  canRemoveSection,
  isCommenceDateValid,
  isCeaseDateValid,
  validateMultiplePrimaryRecords,
} from 'components/entityManagement/common/entityManagementUtils';
import ExtendedAttributesCard from 'components/entityManagement/common/ExtendedAttributes/ExtendedAttributesCard';
import ExtendedAttributesMaintain from 'components/entityManagement/common/ExtendedAttributes/ExtendedAttributesMaintain';
import MaintainSection from 'components/entityManagement/common/MaintainSection';
import SectionCard from 'components/entityManagement/common/SectionCard';
import Loading from 'components/Loading';
import {
  Account,
  useGetConfigurationSchemaByIdQuery,
  useGetSchemaGroupsQuery,
  useUpdateAccountMutation,
} from 'generated/graphql';
import { useHasAccess } from 'hooks/useHasAccess';
import { FormProvider, useForm, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import {
  AccountForm,
  AccountFilingFrequency,
  AccountResponse,
  AccountSectionNames,
} from 'types/accounts';
import { ExtendedAttributeValues, Section } from 'types/forms';
import { Error } from 'types/graphqlErrors';
import extractMeaningfulMessage from 'utils/errorMessage';
import { v4 as uuid } from 'uuid';
import { useQueryClient } from '@tanstack/react-query';

interface Props {
  data?: Account;
  refetchData?: () => void;
  accountFormData: AccountForm;
}

function AccountSectionView({
  data: AccountData,
  refetchData = () => {},
  accountFormData,
}: Props): JSX.Element {
  const CONFIG_DOMAIN = ConfigurationDomains.ReferenceSchema;
  const addSectionDisabled: string[] = ['account', 'filingFrequencies'];
  const { mutate: updateEntity } = useUpdateAccountMutation({});
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const ctx = useContext(EntityManagementContext);
  const canEdit = useHasAccess('maintainEntity', 'edit');
  const [isFormVisible, setFormVisible] = useState<boolean>(false);
  const [isAccountSaving, setAccountSaving] = useState<boolean>(false);
  const [isSectionRemovable, setSectionRemovable] = useState<boolean>(false);
  const [isRefExistent, setRefExistent] = useState<boolean>(false);
  const [isExtendedFormVisible, setExtendedFormVisible] =
    useState<boolean>(false);
  const [isExtendedFormEditable, setExtendedFormEditable] =
    useState<boolean>(false);
  const [, setPeriodFreq] = useState<boolean>(false);
  const [rowIndex, setRowIndex] = useState<number>(0);
  const [mode, setMode] = useState<string>('');
  const [extendedAttribute, setExtendedAttribute] = useState<string>('');
  const [sectionActive, setSectionActive] = useState<Section | null>(null);
  const fixedExtendedAttributes = useRef<ExtendedAttributeValues[]>([]);
  const editOnlyExtendedAttributes = useRef<ExtendedAttributeValues[]>([]);

  const methods = useForm<AccountForm>({
    defaultValues: accountFormData,
    mode: 'onChange',
  });

  const { remove } = useFieldArray({
    control: methods.control,
    name: 'identifiers',
  });

  const periodOther = methods.watch(`filingFrequencies.0.periodFrequency`);

  /**
   * Removes a specific field of a section
   * @param fields Section where the field will be removed
   * @param field The field in particular to be removed
   * @returns A filtered array without the given field
   */
  const removeSpecificField = (fields: Section, field: string) => {
    return fields?.fields.filter((attr) => attr.fieldIdentifier !== field);
  };

  /**
   * Returns the extended attributes existing inside a registered account
   */
  const getAccountExtendedAttributes =
    useCallback((): ExtendedAttributeValues[] => {
      const accountExtendedAttrs = AccountData?.group?.find(
        (attribute) =>
          attribute.groupName?.toLowerCase() === 'extendedattributes'
      )?.attribute;
      if (accountExtendedAttrs) {
        return buildExtendedValues(accountExtendedAttrs);
      } else return [];
    }, [AccountData?.group]);

  /**
   * Clean status and refs when extended attributes form needs it
   */
  const cleanEditExtendedAttributes = () => {
    editOnlyExtendedAttributes.current = [];
    setExtendedFormEditable(false);
  };

  /**
   * Determines if this account type has extended attributes configured
   */
  const { isFetching: isGroupsFetching } = useGetSchemaGroupsQuery(
    {
      configurationDomain: CONFIG_DOMAIN,
      configurationType: 'AccountType',
      configurationName: AccountData?.accountType ?? '',
    },
    {
      onSuccess: (schemaGroups) => {
        const foundExtended = schemaGroups.GetSchemaGroups.find(
          (sectionItem) => sectionItem.attributeName === 'ExtendedAttribute'
        );
        if (foundExtended) setExtendedAttribute(foundExtended.attributeValue);
        else {
          setExtendedAttribute('');
          cleanEditExtendedAttributes();
        }
      },
      onError: () => {
        setExtendedAttribute('');
        cleanEditExtendedAttributes();
      },
    }
  );

  /**
   * If the entity type has configured extended attributes, retrieve those here
   */
  const { isFetching: isConfigFetching } = useGetConfigurationSchemaByIdQuery(
    {
      platformConfigurationId: extendedAttribute,
    },
    {
      enabled: extendedAttribute !== '',
      onSuccess: (extendedAttributes) => {
        const extAttributes =
          extendedAttributes?.GetConfigurationSchemaById
            .platformConfigurationInfo?.configurationSection[0].group[0]
            .attribute;
        if (extAttributes) {
          const configVals = buildExtendedValues(extAttributes);
          const accountVals = getAccountExtendedAttributes();
          const mergedValues = buildMaintainExtendedValues(
            configVals,
            accountVals
          );
          if (mergedValues.length > 0) {
            editOnlyExtendedAttributes.current = mergedValues;
            setExtendedFormEditable(true);
          } else cleanEditExtendedAttributes();
        } else cleanEditExtendedAttributes();
      },
      onError: () => {
        cleanEditExtendedAttributes();
      },
    }
  );

  /**
   * Shows/hides the period frequency dropdown based on frequency type
   */
  const resetPeriodFrequency = useCallback(
    (frequencyType = 'default') => {
      const periodFrequencyDate = FilingFrequencyFields.fields.find(
        (field) => field.fieldIdentifier === 'periodFrequencyMonth'
      );
      if (frequencyType?.toLowerCase() === 'annual-fiscalfiler') {
        if (periodFrequencyDate) {
          periodFrequencyDate.hidden = false;
          setPeriodFreq(true);
        }
      } else {
        if (periodFrequencyDate) {
          periodFrequencyDate.hidden = true;
          methods.setValue(`filingFrequencies.0.periodFrequencyMonth`, '');
          setPeriodFreq(false);
        }
      }
    },
    [methods]
  );

  /**
   * Closes the maintain form
   */
  const closeForm = () => {
    if (mode === 'add') remove(rowIndex);
    methods.clearErrors();
    methods.resetField(sectionActive?.sectionIdentifier as AccountSectionNames);
    setSectionRemovable(false);
    setFormVisible(false);
    setMode('');
  };

  /**
   * Closes the extended attributes form
   */
  const closeExtendedForm = () => {
    setExtendedFormVisible(false);
    setMode('');
  };

  /**
   * Its used to determine the index when a new item is added
   * @param sectionName Name of the section
   * @returns Index of a new item to be added
   */
  const getSectionLength = (sectionName: string): number => {
    return accountFormData[sectionName as keyof AccountForm]?.length ?? 0;
  };

  /**
   * Compares default and new data group length to determine the action message
   * @param data Data to be sent to the endpoint
   */
  const getActionMessage = (data: Account): string => {
    const initialLength = AccountData?.group?.length ?? 0;
    const newDataLengths = data.group?.length ?? 0;
    if (initialLength === newDataLengths) return 'updated';
    else if (initialLength > newDataLengths) return 'deleted';
    else return 'added';
  };

  /**
   * Sets correctly the commence date if the section date is null
   * @param data Data contained in the Account form
   */
  const processCommenceDates = (data: AccountForm) => {
    sectionNames.forEach((sectionName) => {
      const section = data[sectionName as AccountSectionNames];
      if (section) {
        section.forEach((sectionElement) => {
          if (
            !sectionElement.hasOwnProperty('commenceDate') ||
            sectionElement.commenceDate === null ||
            sectionElement.commenceDate === ''
          ) {
            sectionElement.commenceDate = ctx.selectedCommenceDate;
          }
          if (
            !sectionElement.hasOwnProperty('ceaseDate') ||
            sectionElement.ceaseDate === null
          ) {
            sectionElement.ceaseDate = '';
          }
        });
      }
    });
  };

  /**
   * Validates commence date on the section to ensure it is not earlier than Account commence date
   * @param sectionId Name of the section
   * @returns If the commence date of the section is valid or not
   */
  const checkCommenceDate = (sectionId: AccountSectionNames): boolean => {
    if (sectionId === 'account') return true;
    let isValid = true;
    const sectionValues = methods.getValues(sectionId);
    if (sectionValues) {
      sectionValues.forEach((value) => {
        if (
          value.commenceDate &&
          isValid &&
          !isCommenceDateValid(value.commenceDate, ctx.selectedCommenceDate)
        ) {
          isValid = false;
          dispatch(
            addMessage({
              type: MessageType.Error,
              message: t('pages.accountSummary.message.commenceDateError'),
            })
          );
        }
      });
    }
    return isValid;
  };

  /**
   * Checks the cease date is set before the commence date
   * @param sectionId Name of the section
   * @returns If the cease date is valid or not
   */
  const checkCeaseDate = (sectionId: AccountSectionNames): boolean => {
    let isValid = true;
    const sectionValues = methods.getValues(sectionId);
    if (sectionValues) {
      sectionValues.forEach((value) => {
        if (
          value.ceaseDate &&
          isValid &&
          !isCeaseDateValid(
            value.commenceDate,
            value.ceaseDate,
            ctx.selectedCommenceDate
          )
        ) {
          isValid = false;
          dispatch(
            addMessage({
              type: MessageType.Error,
              message: t('pages.accountSummary.message.ceaseDateError'),
            })
          );
        }
      });
    }
    return isValid;
  };

  /**
   * Validates if the filing frequencies are set correctly
   * @param sectionId Section name
   * @returns If filing frequencies are correct
   */
  const checkFilingFrequencies = (sectionId: AccountSectionNames): boolean => {
    if (sectionId !== 'filingFrequencies') return true;
    const sectionValues = methods.getValues(sectionId);
    if (sectionValues) {
      let isFilingFrequencyValid = true;
      sectionValues.forEach((value, idx) => {
        if (isFilingFrequencyValid) {
          if (idx !== sectionValues.length - 1) {
            // Any cease date, expect the last one, can not be empty
            if (value.ceaseDate === null || value.ceaseDate === '') {
              isFilingFrequencyValid = false;
            }
            // Current cease date needs to be lower than the next commence date
            const currentCeaseDate = new Date(value.ceaseDate ?? '');
            const nextCommenceDate = new Date(
              sectionValues[idx + 1].commenceDate ?? ctx.selectedCommenceDate
            );
            if (currentCeaseDate >= nextCommenceDate) {
              isFilingFrequencyValid = false;
            }
          } else {
            // Last cease date needs to be empty
            if (value.ceaseDate !== null && value.ceaseDate !== '') {
              isFilingFrequencyValid = false;
            }
          }
        }
      });
      if (!isFilingFrequencyValid) {
        dispatch(
          addMessage({
            type: MessageType.Error,
            message: t('pages.createAccount.message.filingFrequenciesError'),
          })
        );
      }
      return isFilingFrequencyValid;
    }
    return true;
  };

  /**
   * Validates the edition of a primary in a section
   * @param data All data contained in hook form
   * @returns If its valid the change in primary or not
   */
  const validatePrimaryRecords = (data: AccountForm): boolean => {
    const sectionActiveName =
      sectionActive?.sectionIdentifier as AccountSectionNames;
    if (
      sectionActiveName === 'account' ||
      sectionActiveName === 'filingFrequencies' ||
      sectionActiveName === 'industryCode'
    )
      return true;
    const isValid = validateMultiplePrimaryRecords(
      data,
      sectionActive?.sectionIdentifier
    );
    if (!isValid) {
      const message = t('pages.accountSummary.message.onlyOnePrimary');
      dispatch(
        addMessage({
          type: MessageType.Error,
          message: `${sectionActive?.sectionTitle} ${message}`,
        })
      );
    }
    return isValid;
  };

  /**
   * Validates if only one primary is selected for each section
   * @param sectionId Section name
   * @returns If the primary selected is valid or not
   */
  const checkPrimaries = (
    sectionId: AccountSectionNames
  ): boolean | undefined => {
    if (sectionId === 'filingFrequencies' || sectionId === 'industryCode')
      return true;
    const sectionGetValues = methods.getValues(sectionId);
    const VALID_PRIMARIES = 1;
    let isPrimaryCount = 0;
    sectionGetValues?.forEach((sectionValues) => {
      for (const index in sectionValues) {
        if (
          (index.includes('isPrimary') || index.includes('active')) &&
          sectionValues[index as keyof typeof sectionValues] === 'true'
        )
          isPrimaryCount++;
      }
    });
    if (isPrimaryCount === VALID_PRIMARIES) return true;
    else {
      dispatch(
        addMessage({
          type: MessageType.Error,
          message: t('pages.createAccount.message.primariesError'),
        })
      );
      return false;
    }
  };

  /**
   * Updates the account
   * @param updateData Account with updated data
   */
  const saveAccount = async (updateData: Account) => {
    updateEntity(
      {
        account: updateData,
      },
      {
        onSuccess: () => {
          const actionMessage = getActionMessage(updateData);
          let sectionTitle = sectionActive?.sectionTitle;
          if (!sectionTitle)
            sectionTitle = t('pages.accountSummary.extendedAttributes');
          setFormVisible(false);
          setExtendedFormVisible(false);
          dispatch(
            addMessage({
              type: MessageType.Success,
              message: `${sectionTitle} ${actionMessage} successfully`,
            })
          );
          setMode('');
          void queryClient.invalidateQueries({
            queryKey: ['GetPotentialPeriodsByAccountId'],
          });
          refetchData();
        },
        onError: (error: Error[] | unknown) => {
          let message: string = t('pages.accountSummary.message.error');
          message = extractMeaningfulMessage(error, message);
          dispatch(
            addMessage({
              type: MessageType.Error,
              message: message,
            })
          );
        },
        onSettled: () => {
          setAccountSaving(false);
        },
      }
    );
  };

  /**
   * Validates all needed conditions are passing in order to save account
   * @param data Account data
   * @returns Boolean that says its valid or not
   */
  const checkAllConditions = (data: AccountForm): boolean => {
    const sectionActiveName =
      sectionActive?.sectionIdentifier as AccountSectionNames;
    if (
      data.identifiers &&
      data.identifiers?.length > 0 &&
      validatePrimaryRecords(data) &&
      checkPrimaries(sectionActiveName) &&
      checkCommenceDate(sectionActiveName) &&
      checkCeaseDate(sectionActiveName) &&
      checkFilingFrequencies(sectionActiveName)
    ) {
      return true;
    }
    return false;
  };

  /**
   * Pre-process optional and primaries data for each section
   * @param data Data cleaned and preprocessed
   */
  const onSubmit = (data: AccountForm) => {
    if (isExtendedFormVisible || checkAllConditions(data)) {
      setAccountSaving(true);
      processCommenceDates(data);
      const entityRequest: AccountResponse = buildAccountRequest(
        data,
        AccountData?.entityInfoId || undefined,
        AccountData?.accountType || undefined,
        AccountData?.platformConfigurationId || undefined,
        fixedExtendedAttributes.current,
        AccountData?.id || undefined
      );
      saveAccount(entityRequest);
    }
  };

  /**
   * Pre-process Extended Attributes and then saves normally Account data
   * @param updateExtendedAttrs Extended attributes updated
   */
  const updateExtendedAttributes = (
    updateExtendedAttrs: ExtendedAttributeValues[]
  ) => {
    fixedExtendedAttributes.current = updateExtendedAttrs;
  };

  /**
   * Renders the form that enables adding and editing
   * @returns The form of the selected section
   */
  const getForm = (): JSX.Element => {
    return (
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <MaintainSection
            section={sectionActive as Section}
            sectionIndex={rowIndex}
            enableRemove={isSectionRemovable}
            fieldIdentifierType="AccountType"
            localizedType="accountSummary"
            datasourceType="IdType"
            closeForm={closeForm}
            avatar={
              <CardAvatar
                sectionName={sectionActive?.sectionIdentifier as string}
              />
            }
            handleSave={methods.handleSubmit(onSubmit)}
          />
        </form>
      </FormProvider>
    );
  };

  /**
   * Renders the extended attributes maintain form
   * @returns The maintain form of extended attributes
   */
  const getExtendedForm = (): JSX.Element => {
    return (
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <ExtendedAttributesMaintain
            extendedValues={editOnlyExtendedAttributes.current}
            avatar={<CardAvatar sectionName="extended" />}
            closeForm={closeExtendedForm}
            handleSave={methods.handleSubmit(onSubmit)}
            handleExtendedAttributes={updateExtendedAttributes}
          />
        </form>
      </FormProvider>
    );
  };

  /**
   * Enables the removal icon when opening the form
   */
  const enableSectionRemoval = (index: number, section: Section) => {
    const canRemove = canRemoveSection(index, section, accountFormData);
    setSectionRemovable(canRemove);
  };

  /**
   * Opens the modal that contains the edition form
   */
  const openForm = (index: number, section: Section, type: string) => {
    if (type === 'edit') enableSectionRemoval(index, section);
    setFormVisible(true);
    setSectionActive(section);
    setRowIndex(index);
    setMode(type);
  };

  /**
   * Opens the modal that contains the extended attributes form
   */
  const openExtendedForm = (type: string) => {
    setExtendedFormVisible(true);
    setSectionActive(null);
    setMode(type);
  };

  /**
   * Displays the buttons at the top that enables adding new items
   * @returns An array of nodes that contains all buttons
   */
  const displayAddButtons = (): JSX.Element[] => {
    if (mode !== '') return [];
    const addButtons: JSX.Element[] = [];
    sectionNames
      .filter((sectionName) => !addSectionDisabled.includes(sectionName))
      .forEach((sectionName) => {
        const fields = { ...findSection(sectionName) } as Section;
        if (sectionName === 'addresses' && fields !== undefined) {
          fields.fields = removeSpecificField(fields, 'type');
        }
        if (fields && canEdit) {
          addButtons.push(
            <Button
              key={`add-section-${fields?.sectionIdentifier}`}
              id={`add-section-${fields?.sectionIdentifier}`}
              type="secondary"
              endIcon={<Add />}
              sx={{ width: '100%', marginRight: '20px', marginTop: '5px' }}
              onClick={() =>
                openForm(
                  getSectionLength(fields?.sectionIdentifier),
                  fields,
                  'add'
                )
              }
            >
              {fields?.sectionTitle}
            </Button>
          );
        }
      });
    if (getAccountExtendedAttributes().length === 0 && isExtendedFormEditable) {
      addButtons.push(
        <Button
          key="add-section-extended-attributes"
          id="add-section-extended-attributes"
          type="secondary"
          endIcon={<Add />}
          sx={{ width: '100%', marginRight: '20px', marginTop: '5px' }}
          onClick={() => openExtendedForm('add')}
        >
          {t('pages.accountSummary.extendedAttributes')}
        </Button>
      );
    }
    return addButtons;
  };

  /**
   * Get section fields for an account
   * @param section Section to get fields
   * @returns Sets fields for each section
   */
  const getSectionFields = (section: string): Section => {
    const fields = { ...findSection(section) } as Section;
    switch (section) {
      case 'account':
        fields.fields = removeSpecificField(fields, 'accountCreationSource');
        fields.fields = removeSpecificField(fields, 'AccountType');
        break;
      case 'addresses':
        fields.fields = removeSpecificField(fields, 'type');
        break;
    }
    return fields;
  };

  /**
   * Displays each one of the cards in Account Details
   * @param section Name of the section to be rendered
   * @returns An array of nodes with all the cards
   */
  const displaySectionCards = (section: string): JSX.Element[] => {
    if (mode !== '') return [];
    const fields = getSectionFields(section);
    const groups = accountFormData[section as keyof AccountForm];
    const cards: JSX.Element[] = [];
    if (!groups || !fields) return cards;

    if (section === 'filingFrequencies') {
      cards.push(
        <SectionCard
          group={undefined}
          specialGroup={groups as AccountFilingFrequency[]}
          fields={fields}
          index={0}
          remove={remove}
          openForm={openForm}
          canEdit={canEdit}
          handleSubmit={methods.handleSubmit(onSubmit)}
          key={`${fields.sectionIdentifier}-${uuid()}-detail-card`}
          keyName={`${fields.sectionIdentifier}-${uuid()}-detail-card`}
        />
      );
    } else {
      groups.forEach((group, index) => {
        cards.push(
          <SectionCard
            group={group}
            fields={fields}
            index={index}
            remove={remove}
            openForm={openForm}
            canEdit={canEdit}
            handleSubmit={methods.handleSubmit(onSubmit)}
            key={`${fields.sectionIdentifier}-${uuid()}-detail-card`}
            keyName={`${fields.sectionIdentifier}-${uuid()}-detail-card`}
          />
        );
      });
    }
    return cards;
  };

  useEffect(() => {
    const extAttributes = getAccountExtendedAttributes();
    if (extAttributes.length > 0) {
      fixedExtendedAttributes.current = extAttributes;
      setRefExistent(true);
    }
  }, [AccountData?.group, getAccountExtendedAttributes]);

  //TODO need a separate story to add back period frequency month once BE accepts it
  useEffect(() => {
    resetPeriodFrequency(periodOther);
  }, [periodOther, resetPeriodFrequency]);

  return (
    <Box sx={{ marginBottom: '10px' }}>
      {(isAccountSaving || isGroupsFetching || isConfigFetching) && <Loading />}
      <Box sx={{ marginBottom: '20px', width: { xs: '75%', md: '100%' } }}>
        {displayAddButtons()}
      </Box>
      {isFormVisible && canEdit && getForm()}
      {isExtendedFormVisible && canEdit && getExtendedForm()}
      <Grid container spacing={3}>
        {sectionNames.map((sectionName) => {
          return displaySectionCards(sectionName);
        })}
        {mode === '' && isRefExistent && (
          <ExtendedAttributesCard
            extendedData={fixedExtendedAttributes.current}
            canEdit={canEdit}
            isEditableByConfig={isExtendedFormEditable}
            openForm={openExtendedForm}
          />
        )}
      </Grid>
    </Box>
  );
}

export default AccountSectionView;
