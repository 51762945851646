import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';

const NoData = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Grid
      container
      data-testid="nodata-container"
      spacing={0}
      direction="column"
      alignItems="center"
    >
      <Grid item xs={3}>
        <Box
          data-testid="nodata-message"
          component="div"
          sx={{
            marginTop: 3,
            width: '100%',
          }}
        >
          <Typography variant="h1">{t('components.message.noData')}</Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default NoData;
