import { useEffect } from 'react';

import { Card } from '@revenue-solutions-inc/revxcoreui';
import CardContent from '@mui/material/CardContent';
import { getAsset } from 'components/entityManagement/common/defaults/asset';
import AssetLayout from 'components/entityManagement/assets/create/AssetLayout';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { setHeader } from 'redux/contentSlice';
import { useAppDispatch } from 'redux/hooks';
import { AssetForm } from 'types/assets';

function CreateAsset(): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const asset: AssetForm = getAsset;

  useEffect(() => {
    dispatch(
      setHeader({
        pageTitle: t('pages.createAsset.title'),
      })
    );
  }, [dispatch, t]);

  const formMethods = useForm<AssetForm>({
    defaultValues: asset,
    mode: 'onChange',
  });

  return (
    <Card>
      <CardContent>
        <AssetLayout formMethods={formMethods} asset={asset} />
      </CardContent>
    </Card>
  );
}

export default CreateAsset;
