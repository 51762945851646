import { useState, useEffect, useRef } from 'react';
import IconButton from '@mui/material/IconButton';
import { Edit } from '@mui/icons-material/';
import { Box } from '@mui/system';
import {
  Input,
  MessageType,
  MessageActionType,
} from '@revenue-solutions-inc/revxcoreui';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import DateCell from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/TableCells/DateCell';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import { ColumnDef } from '@tanstack/react-table';
import Loading from 'components/Loading';
import NoResults from 'components/NoResults';
import useGetAccessToken from 'hooks/useGetAccessToken';
import useQueryRequest from 'hooks/useQueryRequest';
import {
  recordIcons,
  expandButton,
  collapseButton,
} from 'components/manageConfigTools/Shared/IconButtonStyling/IconButtonStyling';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { setHeader } from 'redux/contentSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import { RoleResponse, User } from 'types/graphTypes';
import { isMaxValueDate, toDate } from 'utils/date-util';
import { useHasAccess } from 'hooks/useHasAccess';
import { userBySearch } from './ManageuserRolesQuery';

function ManageUserRoles(): JSX.Element {
  const inputFocus = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const accessManageUserRolesEdit = useHasAccess('manageUserRoles', 'edit');
  const [search, setSearch] = useState('');
  const module = useAppSelector((state) => state.user.module);

  const [searchText, setSearchText] = useState('');
  const accessToken = useGetAccessToken();
  const { data, isFetching, error } = useQueryRequest<{
    UsersBySearch: [User];
  }>(
    ['search', searchText],
    accessToken,
    userBySearch,
    {
      search: searchText,
    },
    'search'
  );

  const userColumns: ColumnDef<User>[] = [
    {
      id: 'userlink',
      header: () => (
        <HeaderColumnNext localization={t('pages.userRolesList.edit')} />
      ),
      cell: ({ row }) => {
        const path = `/${module}/manageuserroles/edit/${row.original.userId}`;
        if (accessManageUserRolesEdit) {
          return (
            <Link
              to={{
                pathname: path,
              }}
            >
              <Edit sx={recordIcons} />
            </Link>
          );
        } else
          return (
            <IconButton disabled={!accessManageUserRolesEdit}>
              {' '}
              <Edit />{' '}
            </IconButton>
          );
      },
    },
    {
      id: 'name',
      accessorKey: 'name',
      header: () => (
        <HeaderColumnNext localization={t('pages.userRolesList.name')} />
      ),
    },

    {
      id: 'email',
      accessorKey: 'email',
      header: () => (
        <HeaderColumnNext localization={t('pages.userRolesList.email')} />
      ),
    },

    {
      id: 'userRole',
      accessorKey: 'userRole',
      header: () => (
        <HeaderColumnNext localization={t('pages.userRolesList.roles')} />
      ),
      cell: ({ row }) => {
        return (
          <IconButton
            {...{
              onClick: () => row.toggleExpanded(),
            }}
            sx={row.getIsExpanded() ? collapseButton : expandButton}
          >
            <ExpandCircleDownIcon />
          </IconButton>
        );
      },
    },
  ];

  const userRoleColumns: ColumnDef<RoleResponse>[] = [
    {
      id: 'roleName',
      accessorKey: 'roleName',
      header: () => (
        <HeaderColumnNext localization={t('pages.userRolesList.roleName')} />
      ),
    },

    {
      id: 'moduleName',
      accessorKey: 'moduleName',
      header: () => (
        <HeaderColumnNext localization={t('pages.userRolesList.moduleName')} />
      ),
    },

    {
      id: 'startDate',
      header: () => (
        <HeaderColumnNext localization={t('pages.userRolesList.startDate')} />
      ),
      cell: ({ row }) => {
        const dateValue = toDate(row.original.startDate);
        return dateValue && !isMaxValueDate(dateValue) ? (
          <DateCell dateString={String(row.original.startDate)} />
        ) : (
          t('pages.userRolesList.noDateSpecified')
        );
      },
    },

    {
      id: 'endDate',
      accessorKey: 'endDate',
      header: () => (
        <HeaderColumnNext localization={t('pages.userRolesList.endDate')} />
      ),
      cell: ({ row }) => {
        const dateValue = toDate(row.original.endDate);
        return dateValue && !isMaxValueDate(dateValue) ? (
          <DateCell dateString={String(row.original.endDate)} />
        ) : (
          t('pages.userRolesList.noDateSpecified')
        );
      },
    },
  ];
  useEffect(() => {
    dispatch(setHeader({ pageTitle: t('pages.userRolesList.title') }));
  }, [dispatch, t]);

  useEffect(() => {
    if (error) {
      dispatch(
        addMessage({
          message: t('components.message.networkerror'),
          type: MessageType.Error,
          actionType: MessageActionType.None,
        })
      );
    }
  }, [error, dispatch, t]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      inputFocus?.current?.focus();
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <>
      <Box sx={{ mb: '1em', mt: 1 }}>
        <Input
          id={'manageuserroles-searchBox'}
          ariaLabel={'search user roles'}
          inputProps={{
            ref: inputFocus,
            autoFocus: true,
          }}
          value={search}
          onChange={(event) => {
            setSearch(event?.target.value);
          }}
          placeholder={t('pages.userRolesList.searchBy')}
          sx={{
            minWidth: '20em',
            '.MuiInputBase-root': {
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            },
          }}
          onKeyDown={(e: { key: string }) => {
            if (e.key === 'Enter') {
              setSearchText(search);
            }
          }}
          helperText={
            search && search.length < 3 ? t('pages.userRolesList.errorMsg') : ''
          }
        />
        <Button
          id={'manageuserroles-searchBtn'}
          onClick={() => setSearchText(search)}
          disabled={search.length < 3}
          sx={{
            minWidth: '5rem',
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
          }}
        >
          {t('components.button.search')}
        </Button>
      </Box>
      {isFetching && <Loading />}
      {data && data.UsersBySearch.length > 0 && (
        <DefaultDataTableNext
          columns={userColumns as ColumnDef<Record<string, unknown>>[]}
          data-testid="user-roles-data"
          data={data.UsersBySearch.sort((a: User, b: User) => {
            const nameA = a?.name?.toLocaleLowerCase() ?? '';
            const nameB = b?.name?.toLocaleLowerCase() ?? '';
            return nameA > nameB ? 1 : -1;
          })}
          tableName={t('pages.userRolesList.users')}
          renderRowSubComponent={(row) => (
            <DefaultDataTableNext
              columns={userRoleColumns as ColumnDef<Record<string, unknown>>[]}
              data={
                row.getValue('userRole') as unknown as Record<string, unknown>[]
              }
              enableGlobalFilter={false}
              data-testid="user-roles-subdata"
            />
          )}
        />
      )}
      {data && data.UsersBySearch.length < 1 && <NoResults />}
    </>
  );
}

export default ManageUserRoles;
