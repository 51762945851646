import { useState, useEffect } from 'react';

import { Grid, SelectChangeEvent } from '@mui/material';
import { MessageType } from '@revenue-solutions-inc/revxcoreui';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import DatePicker from '@revenue-solutions-inc/revxcoreui/material/controls/DatePicker';
import Input from '@revenue-solutions-inc/revxcoreui/material/controls/Input';
import Select, {
  SelectType,
} from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { setHeader } from 'redux/contentSlice';
import { useAppSelector, useAppDispatch } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import { Batch, batchDefault } from 'types/batches/entities';
import extractMeaningfulMessage from 'utils/errorMessage';
import { useCreateBatchMutation } from 'generated/graphql';
import { toStringDate } from 'utils/date-util';
import DatasourceSelect from 'components/DatasourceSelect';

//TODO: replace hardcoded branch locations with a Lookup - Jira ticket - 3332
const selectBranchLocation: SelectType[] = [
  { key: 'Main Office - Downtown', desc: 'Main Office - Downtown' },
  { key: 'Northwest Office', desc: 'Northwest Office' },
  { key: 'Kiosk #77', desc: 'Kiosk #77' },
];

function CreateBatch(): JSX.Element {
  const selectedModule = useAppSelector((state) => state.user.module);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [batch, setBatch] = useState<Batch>(batchDefault);
  const [passedValidation, setPassedValidation] = useState<boolean>(false);
  const { mutate: createBatch } = useCreateBatchMutation({});
  const navigate = useNavigate();
  const [effectivePostingDateHasError, setEffectivePostingDateHasError] =
    useState<boolean>(false);
  const [paidDateHasError, setPaidDateHasError] = useState<boolean>(false);

  const saveBatch = () => {
    createBatch(
      {
        batch: {
          adjustmentAmount: batch.adjustmentAmount,
          batchType: batch.batchType,
          branchLocationId: batch.branchLocationId,
          defaultTransactionEffectiveDate: toStringDate(
            batch.defaultTransactionEffectiveDate
          ),
          label: batch.label,
          paidDate: toStringDate(batch.paidDate),
          recordCount: batch.recordCount,
          source: batch.source,
        },
      },
      {
        onSuccess: async () => {
          dispatch(
            addMessage({
              message: t('pages.manageBatches.message.batchCreated'),
              type: MessageType.Success,
            })
          );
          navigate(`/${selectedModule}/managebatches`);
        },
        onError: async (e: Error[] | unknown) => {
          let message: string = t('components.message.networkerror');
          message = extractMeaningfulMessage(e, message);
          dispatch(
            addMessage({
              type: MessageType.Error,
              message: message,
            })
          );
        },
      }
    );
  };

  const validateRequiredFields = () => {
    let isValid = true;
    if (batch.label === '') {
      isValid = false;
    }
    if (
      effectivePostingDateHasError ||
      batch.defaultTransactionEffectiveDate === null
    ) {
      isValid = false;
    }
    if (paidDateHasError || batch.paidDate === null) {
      isValid = false;
    }
    if (batch.branchLocationId === '') {
      isValid = false;
    }
    if (batch.batchType === '') {
      isValid = false;
    }
    setPassedValidation(isValid);
  };

  useEffect(() => {
    validateRequiredFields();
  });

  dispatch(
    setHeader({
      pageTitle: t('pages.createBatch.title'),
      previousPage: t('pages.manageBatches.title'),
      route: `managebatches`,
    })
  );

  return (
    <>
      <Grid item xs={2} mt={1}>
        <div data-testid="batchLabel">
          <Input
            required
            id="batchLabel"
            label={t('pages.createBatch.batchLabel')}
            value={batch.label ?? ''}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setBatch({
                ...batch,
                label: event.target.value,
              });
            }}
            sx={{ maxWidth: '13.8rem', width: '100%' }}
          />
        </div>
      </Grid>
      <Grid item xs={2} mt={3}>
        <div data-testid="effectivePostingDate">
          <DatePicker
            id="effectivePostingDate"
            label={t('pages.createBatch.effectivePostingDate')}
            required
            sx={{
              maxWidth: '15rem',
            }}
            value={batch.defaultTransactionEffectiveDate}
            handleChange={(date: Date | null) => {
              setBatch({
                ...batch,
                defaultTransactionEffectiveDate: date,
              });
            }}
            isCalendarRequired
            onError={(reason) => {
              setEffectivePostingDateHasError(reason ? true : false);
            }}
          />
        </div>
      </Grid>
      <Grid item xs={2} mt={3}>
        <div data-testid="paidDate">
          <DatePicker
            id="paidDate"
            label={t('pages.createBatch.paidDate')}
            required
            sx={{
              maxWidth: '15rem',
            }}
            value={batch.paidDate ? batch.paidDate : null}
            handleChange={(date: Date | null) => {
              setBatch({
                ...batch,
                paidDate: date,
              });
            }}
            isCalendarRequired
            onError={(reason) => {
              setPaidDateHasError(reason ? true : false);
            }}
          />
        </div>
      </Grid>
      <Grid item mt={3}>
        <div data-testid="branchLocationId">
          {/* TODO: replace hardcoded branch locations with a Lookup - Jira ticket - 3332 */}
          <Select
            id="branchLocationId"
            value={batch.branchLocationId ?? ''}
            options={selectBranchLocation}
            label={t('pages.createBatch.branchLocation')}
            required
            onChange={(event: SelectChangeEvent<string | number>) => {
              setBatch({
                ...batch,
                branchLocationId: event.target.value as string,
              });
            }}
            sx={{ maxWidth: '13.8rem', width: '100%' }}
          />
        </div>
      </Grid>
      <Grid item mt={3}>
        <div data-testid="batchType">
          <DatasourceSelect
            id="batchType"
            value={batch.batchType ?? ''}
            label={t('pages.createBatch.batchType')}
            datasource={'batchType'}
            required
            fetchLayoutInfo={(event: SelectChangeEvent<string | number>) => {
              setBatch({
                ...batch,
                batchType: event.target.value as string,
              });
            }}
            sx={{ maxWidth: '13.8rem', width: '100%' }}
          />
        </div>
      </Grid>
      <Grid item mt={3}>
        <Button id="saveBatch" onClick={saveBatch} disabled={!passedValidation}>
          {t('pages.createBatch.saveBatch')}
        </Button>
      </Grid>
    </>
  );
}

export default CreateBatch;
