import { Fragment } from 'react';
import { Grid } from '@mui/material';
import { Checkbox, DatePicker, Input } from '@revenue-solutions-inc/revxcoreui';
import DatasourceSelect from 'components/DatasourceSelect';
import { t } from 'i18next';
import { Controller, ValidationRule, useFormContext } from 'react-hook-form';
import { Attribute } from 'generated/graphql';
import { useAttributeValidations } from 'hooks/useAttributeValidations';

type AttributeFieldProps = {
  attribute: Attribute;
  groupIndex: number;
  attributeIndex: number;
  schemaType: string;
  isDisabled?: boolean;
  customLabel?: string;
};

const CONTROL_TYPES = {
  Boolean: 'boolean',
  DateTime: 'datetime',
  ReferenceData: 'referencedata',
  String: 'string',
} as const;

const AttributeField = ({
  attribute: attr,
  customLabel,
  groupIndex,
  attributeIndex,
  isDisabled,
  schemaType,
}: AttributeFieldProps) => {
  const { control } = useFormContext();
  const ctrlType = attr.attributeType?.toLowerCase() ?? '';
  const isBankruptcy = schemaType === 'Bankruptcy';

  const defaultValue =
    attr.attributeValue ??
    (([CONTROL_TYPES.String, CONTROL_TYPES.ReferenceData] as string[]).includes(
      ctrlType
    )
      ? ''
      : undefined);

  const { checkIsRequired } = useAttributeValidations();

  const rules = {
    required: {
      value: checkIsRequired(attr),
      message: `${customLabel || attr.attributeDisplayName} ${t(
        'pages.workflowEditor.required'
      )}`,
    } as ValidationRule<boolean>,
  };

  return (
    <Grid item xs={4}>
      <Controller
        defaultValue={defaultValue}
        name={`workflowConfig.${groupIndex}.attribute.${attributeIndex}.attributeValue`}
        control={control}
        rules={rules}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          if (ctrlType === CONTROL_TYPES.String) {
            return (
              <Input
                sx={{ width: '100%' }}
                disabled={isDisabled}
                id={`${groupIndex}-${attributeIndex}-${attr.attributeName}`}
                label={customLabel ?? attr.attributeDisplayName}
                value={value}
                onChange={onChange}
                error={Boolean(error)}
                helperText={error?.message}
                required={checkIsRequired(attr)}
              />
            );
          }

          if (ctrlType === CONTROL_TYPES.DateTime) {
            return (
              <DatePicker
                disabled={isDisabled}
                id={`${groupIndex}-${attributeIndex}-${attr.attributeName}`}
                value={value === '' ? null : new Date(value)}
                handleChange={onChange}
                label={customLabel ?? attr.attributeDisplayName}
                sx={{ width: '100%' }}
                requiredErrorMessage={(Boolean(error) ? error : '') as string}
                required={checkIsRequired(attr)}
              />
            );
          }

          if (ctrlType === CONTROL_TYPES.Boolean) {
            const disabledForBankruptcy = [
              'IncludeDemographic',
              'IncludeCRM',
              'IncludeHistory',
            ];
            // server expects "true" or "false" (strings) not booleans
            const checked = isBankruptcy
              ? true
              : Boolean(value && value.toString() === 'true');

            return (
              <Checkbox
                onChange={(e) => {
                  const eventOverride = {
                    target: {
                      ...e.target,
                      checked: e.target.checked,
                      value: e.target.checked.toString(),
                    },
                  };
                  onChange(eventOverride);
                }}
                id={`${groupIndex}-${attributeIndex}-${attr.attributeName}`}
                label={customLabel ?? attr.attributeDisplayName}
                disabled={
                  isDisabled ||
                  (isBankruptcy &&
                    disabledForBankruptcy.includes(attr.attributeName))
                }
                checked={checked}
                value={value.toString()}
              />
            );
          }

          if (ctrlType === CONTROL_TYPES.ReferenceData) {
            return (
              <DatasourceSelect
                maxWidth
                disabled={isDisabled}
                id={`${groupIndex}-${attributeIndex}-${attr.attributeName}`}
                datasource={attr.dataSource ?? ''}
                label={customLabel ?? attr.attributeDisplayName}
                fetchLayoutInfo={onChange}
                errorMsg={(Boolean(error) ? error?.message : '') as string}
                value={
                  isBankruptcy && attr.attributeName === 'VariantGroupCode'
                    ? 'Bankruptcy'
                    : value
                }
                isPlatform={true}
                required={checkIsRequired(attr)}
              />
            );
          }

          return <Fragment />;
        }}
      />
    </Grid>
  );
};

export default AttributeField;
