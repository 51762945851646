import { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Loading from 'components/Loading';
import { useAppSelector } from 'redux/hooks';
import { useGetWorkflowGroupsQuery } from 'generated/graphql';
import { WorkflowFormContextProvider } from 'components/Workflow/WorkflowFormContext';
import UpdateCase from './UpdateCase';
import CreateSpecificWork from '../Create/CreateSpecificWork';

const UpdateContainer = () => {
  const { variantId, key } = useParams() as { variantId: string; key: string };
  const { state: locationState } = useLocation();
  const [workType, setWorkType] = useState<string>('');
  const module = useAppSelector((appState) => appState.user.module);

  useEffect(() => {
    if (locationState?.__workType) {
      setWorkType(locationState.__workType);
    }
  }, [locationState]);

  const { data: variantData, isLoading: isVariantLoading } =
    useGetWorkflowGroupsQuery({
      variantId,
    });

  const { data: workflowData, isLoading: isWorkflowLoading } =
    useGetWorkflowGroupsQuery({
      workflowId: key,
    });

  if (isVariantLoading || isWorkflowLoading || !variantData || !workflowData) {
    return <Loading />;
  }

  return (
    <WorkflowFormContextProvider>
      <>
        {['Notes', 'Suppression'].includes(workType) && (
          <CreateSpecificWork
            variantId={variantId}
            module={module}
            data={variantData}
            workType={workType}
          />
        )}
        {(!workType || !['Notes', 'Suppression'].includes(workType)) && (
          <UpdateCase
            variantId={variantId}
            variantKey={key}
            module={module}
            variantData={variantData}
            workflowData={workflowData}
            workType={workType}
          />
        )}
      </>
    </WorkflowFormContextProvider>
  );
};

export default UpdateContainer;
