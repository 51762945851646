import { useState, useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import {
  Box,
  Collapse,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import { useTranslation } from 'react-i18next';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import {
  ConfigurationResponse,
  FormLayoutConfiguration,
  useGetAllFormLayoutsQuery,
} from 'generated/graphql';
import EditIcon from '@mui/icons-material/Edit';
import { Button } from '@revenue-solutions-inc/revxcoreui';
import {
  collapseButton,
  expandButton,
  recordIcons,
  fontSize,
} from 'components/manageConfigTools/Shared/IconButtonStyling/IconButtonStyling';
import Dialog from '@revenue-solutions-inc/revxcoreui/material/controls/Dialog';
import Loading from 'components/Loading';
import ConfigLayoutBuilder from '../ConfigLayoutBuilder';

const collapsedSize = 40;

type LayoutTableProps = {
  selectedConfigType: ConfigurationResponse;
};

function ConfigLayoutTable({ selectedConfigType }: LayoutTableProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.configTypeEditor.layoutBuilder',
  });
  const {
    data,
    isFetching,
    refetch: refetchAllLayouts,
  } = useGetAllFormLayoutsQuery({
    configurationId: selectedConfigType.platformConfigurationId,
  });
  const layouts = data?.getAllFormLayouts || [];
  const [showLayouts, setShowLayouts] = useState<boolean>(false);
  const [selectedLayoutId, setSelectedLayoutId] = useState<
    string | undefined | null
  >('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const showLayoutHandler = () => {
    setShowLayouts((previous) => !previous);
  };

  const layoutColumns: ColumnDef<FormLayoutConfiguration>[] = useMemo(
    () => [
      {
        header: () => <HeaderColumnNext localization={''} />,
        id: 'edit',
        cell: ({ row }) => {
          if (row.original.layoutConfigurationId) {
            return (
              <IconButton
                sx={recordIcons}
                aria-label="edit-button"
                title={t('editLayout')}
                onClick={() => {
                  setSelectedLayoutId(row.original.layoutConfigurationId);
                  setOpenDialog(true);
                }}
              >
                <EditIcon sx={{ fontSize }} />
              </IconButton>
            );
          }
        },
      },
      {
        header: () => <HeaderColumnNext localization={t('layoutType')} />,
        accessorKey: 'layoutType',
      },
      {
        header: () => <HeaderColumnNext localization={t('context')} />,
        accessorKey: 'context',
      },
      {
        header: () => <HeaderColumnNext localization={t('version')} />,
        accessorKey: 'version',
      },
    ],
    [t]
  );

  return (
    <Collapse
      orientation="vertical"
      in={showLayouts}
      collapsedSize={collapsedSize}
      sx={(theme) => ({
        backgroundColor: theme.palette.grey[100],
        borderRadius: '5px',
        padding: '0 0.5em 0.5em 0.5em',
      })}
    >
      <Stack
        direction="column"
        divider={<Divider orientation="horizontal" flexItem />}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            ml: '0.5em',
          }}
        >
          <Typography
            variant={'h3'}
            onClick={showLayoutHandler}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              height: collapsedSize,
              width: '100%',
              cursor: 'pointer',
            }}
          >
            {t('title')}
          </Typography>
          <IconButton
            onClick={showLayoutHandler}
            sx={showLayouts ? collapseButton : expandButton}
            aria-label="expand-button"
            title={showLayouts ? t('collapseLayouts') : t('expandLayouts')}
          >
            <ExpandCircleDownIcon />
          </IconButton>
        </Box>
        {isFetching && <Loading />}
        {!isFetching && (
          <DefaultDataTableNext<FormLayoutConfiguration>
            data={layouts}
            columns={layoutColumns}
            isSimplePagination
            pageSize={10}
            customHeader={() => {
              return (
                <Box
                  sx={{
                    justifyContent: 'start',
                    width: '100%',
                    marginTop: '.5em',
                  }}
                >
                  <Button
                    onClick={() => {
                      setOpenDialog(true);
                    }}
                    aria-label="add-button"
                    // disable adding a layout if there aren't any fields
                    disabled={
                      !selectedConfigType.platformConfigurationInfo
                        ?.configurationSection[0].group[0].attribute[0]
                    }
                  >
                    {t('addLayout')}
                  </Button>
                </Box>
              );
            }}
          />
        )}
      </Stack>
      <Dialog
        id={'configLayoutBuilder'}
        title={'LayoutBuilder'}
        open={openDialog}
        handleClose={() => {
          setOpenDialog(false);
          setSelectedLayoutId(undefined);
        }}
        handleCancelClick={() => {
          setOpenDialog(false);
          setSelectedLayoutId(undefined);
        }}
      >
        <ConfigLayoutBuilder
          selectedConfigType={selectedConfigType}
          selectedLayoutId={selectedLayoutId}
          setSelectedLayoutId={setSelectedLayoutId}
          setOpenDialog={setOpenDialog}
          refetchAllLayouts={refetchAllLayouts}
          layoutsLength={layouts.length + 1}
        />
      </Dialog>
    </Collapse>
  );
}

export default ConfigLayoutTable;
