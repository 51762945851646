import { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Box, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ColumnDef } from '@tanstack/react-table';

import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import DeleteIcon from '@mui/icons-material/Delete';
import DescriptionIcon from '@mui/icons-material/Description';
import { useHasAccess, useHasEdit } from 'hooks/useHasAccess';
import { setHeader } from 'redux/contentSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import Loading from 'components/Loading';
import {
  MessageActionType,
  MessageType,
} from '@revenue-solutions-inc/revxcoreui';

import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import Input from '@revenue-solutions-inc/revxcoreui/material/controls/Input';
import Select from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import Dialog from '@revenue-solutions-inc/revxcoreui/material/controls/Dialog';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import {
  useGetReportsQuery,
  useDeleteReportMutation,
  useCreateReportMutation,
  GetReportsQuery,
} from 'generated/graphql';

type ReportFormType = {
  name: string;
  description: string;
  nameError: boolean;
  descriptionError: boolean;
  isPresent: boolean;
};

type DataSet = {
  reportId?: number | null;
  reportName?: string | null;
  reportDescription?: string | null;
  hasActiveTemplate?: string | null;
  createdBy?: string | null;
  createdDate?: string | null;
  latestStatus?: string | null;
  latestVersion?: number | null;
  updatedBy?: string | null;
  updatedDate?: string | null;
  canDelete?: boolean | null;
  isOOTB?: boolean | null;
};

function Reports(): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { data: reportsData, refetch, isLoading } = useGetReportsQuery({});
  const { mutate: deleteReport } = useDeleteReportMutation();
  const { mutate: createReport } = useCreateReportMutation();
  const [data, setData] = useState<GetReportsQuery | undefined>(reportsData);
  const [isLoadingReports, setIsLoadingReports] = useState(false);
  const module = useAppSelector((state) => state.user.module);
  const roles = useAppSelector((state) => state.user.roles);
  const navigate = useNavigate();
  const canAction = useHasEdit('reportsDetails', 'reportManagementAccessAll');
  const [openModalNewReport, setOpenModalNewReport] = useState(false);
  const [openModalConfirmation, setOpenModalConfirmation] = useState<{
    isOpenModal: boolean;
    id: number;
  }>({
    isOpenModal: false,
    id: -1,
  });
  const [reportForm, setReportForm] = useState({
    name: ' ',
    description: ' ',
    nameError: false,
    descriptionError: false,
    isPresent: false,
  });
  const hasAccess = useHasAccess('reportsDetails', 'view');
  const [isLandLordAdmin, setIsLandLordAdmin] = useState(false);
  const [moduleCreateReport, setModuleCreateReport] = useState<string | number>(
    ''
  );

  useEffect(() => {
    if (roles !== undefined) {
      roles.forEach((role) => {
        if (role.name === 'LandlordAdmin') {
          setIsLandLordAdmin(true);
        }
      });
    }
  }, [roles]);

  const handleSuccess = useCallback(
    (customMessage: string) => {
      setData(undefined);
      dispatch(
        addMessage({
          message: customMessage,
          type: MessageType.Success,
          actionType: MessageActionType.None,
        })
      );
      refetch().then((e) => {
        if (e.isSuccess && e.isFetching === false) {
          setData(e.data);
          setIsLoadingReports(false);
        }
      });
    },
    [dispatch, refetch]
  );

  const handleError = useCallback(() => {
    dispatch(
      addMessage({
        message: t('pages.manageReusableContent.networkError'),
        type: MessageType.Error,
        actionType: MessageActionType.None,
      })
    );
    setIsLoadingReports(false);
  }, [dispatch, t]);

  const deleteReports = useCallback(
    (reportId: number) => {
      setIsLoadingReports(true);
      setOpenModalConfirmation({
        isOpenModal: false,
        id: -1,
      });
      deleteReport(
        {
          report: {
            reportId: reportId,
          },
        },
        {
          onSuccess: () =>
            handleSuccess(t('pages.reports.message.deleteReport')),
          onError: () => handleError,
        }
      );
    },
    [deleteReport, handleError, handleSuccess, t]
  );

  const createReports = useCallback(
    (reportName: string, reportDescription: string, moduleCreate: string) => {
      setOpenModalNewReport(false);
      setReportForm({
        name: ' ',
        description: ' ',
        nameError: false,
        descriptionError: false,
        isPresent: false,
      });
      setIsLoadingReports(true);
      createReport(
        {
          report: {
            reportName: reportName,
            reportDescription: reportDescription,
            isOOTB: true,
            moduleId: Number(moduleCreate),
          },
        },
        {
          onSuccess: () =>
            handleSuccess(t('pages.reports.message.createReport')),
          onError: handleError,
        }
      );
    },
    [createReport, handleError, handleSuccess, t]
  );

  useEffect(() => {
    dispatch(
      setHeader({
        pageTitle: t('pages.reports.title'),
      })
    );
  }, [dispatch, t]);

  useMemo(() => {
    if (reportsData !== undefined) {
      reportsData.getReports.forEach((value) => {
        value.createdDate = new Date(
          String(value.createdDate)
        ).toLocaleDateString('en-US');
        value.updatedDate = new Date(
          String(value.updatedDate)
        ).toLocaleDateString('en-US');
        value.hasActiveTemplate =
          value.hasActiveTemplate === 'true' ? 'Yes' : 'No';
      });
      setData(reportsData);
    }
  }, [reportsData]);

  const verificationLock = useCallback(
    (
      canDo: boolean | null | undefined,
      ootb: boolean | null | undefined,
      island: boolean | null | undefined
    ) => {
      let verify = true;
      if (canDo && !ootb) {
        verify = false;
      }

      if (canDo && ootb && island) {
        verify = false;
      }

      return verify;
    },
    []
  );

  const createColumns = useCallback(
    (set: object) =>
      Object.entries(set).map((value) => {
        return {
          header: () => <HeaderColumnNext localization={String(value[1])} />,
          accessorKey: value[0],
          cell: ({
            row,
          }: {
            row: {
              original: DataSet;
            };
          }) => {
            switch (value[0]) {
              case 'optDelete':
                return (
                  <>
                    <IconButton
                      onClick={() =>
                        setOpenModalConfirmation({
                          isOpenModal: true,
                          id: row?.original?.reportId ?? -1,
                        })
                      }
                      disabled={verificationLock(
                        row.original.canDelete,
                        row.original.isOOTB,
                        isLandLordAdmin
                      )}
                      data-testid="deleteReportButton"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </>
                );
              case 'optEdit':
                return (
                  <>
                    <IconButton
                      onClick={() =>
                        navigate(`/${module}/Reports/${row.original.reportId}`)
                      }
                      disabled={verificationLock(
                        hasAccess,
                        false,
                        isLandLordAdmin
                      )}
                      data-testid="button-test-edition"
                    >
                      <DescriptionIcon />
                    </IconButton>
                  </>
                );
              case 'optlocked':
                return (
                  <>
                    <IconButton disabled data-testid="lockButton">
                      {row.original.isOOTB ? <LockIcon /> : <LockOpenIcon />}
                    </IconButton>
                  </>
                );
              default:
                return Object.entries(row.original)
                  .map((x) => (x[0] === value[0] ? x[1] : null))
                  .filter((x) => x)[0];
            }
          },
        };
      }),

    [hasAccess, isLandLordAdmin, module, navigate, verificationLock]
  );

  const Columns: ColumnDef<DataSet>[] = useMemo(
    () =>
      createColumns(
        t('pages.reports.reportsManagementHeaderTable', {
          returnObjects: true,
        })
      ),
    [createColumns, t]
  );

  const handleCreate = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.id === 'reportName') {
      if (event.target.value === ' ') {
        setReportForm((prev: ReportFormType) => ({
          ...prev,
          name: '',
          nameError: true,
        }));
      } else if (event.target.value.length > 50) {
        setReportForm((prev: ReportFormType) => ({
          ...prev,
          nameError: true,
        }));
      } else {
        setReportForm((prev: ReportFormType) => ({
          ...prev,
          name: event.target.value,
          nameError: false,
        }));
      }
    } else if (event.target.id === 'ReportDescription') {
      if (event.target.value === ' ') {
        setReportForm((prev: ReportFormType) => ({
          ...prev,
          description: '',
          descriptionError: true,
        }));
      } else if (event.target.value.length > 200) {
        setReportForm((prev: ReportFormType) => ({
          ...prev,
          descriptionError: true,
        }));
      } else {
        setReportForm((prev: ReportFormType) => ({
          ...prev,
          description: event.target.value,
          descriptionError: false,
        }));
      }
    }
  };

  const newReportBlock = useMemo(() => {
    return (
      <Box sx={{ display: 'grid', rowGap: '16px' }}>
        <Input
          id="reportName"
          label={'Report Name'}
          value={reportForm.name}
          placeholder={'Required'}
          onChange={handleCreate}
          required={true}
          error={
            reportForm.nameError ||
            reportForm.name === '' ||
            reportForm.name.length > 50
          }
          helperText={
            reportForm.nameError ||
            reportForm.name === '' ||
            reportForm.name.length > 50
              ? 'Error Name'
              : ''
          }
        />
        <Input
          id="ReportDescription"
          label={'Report Description'}
          value={reportForm.description}
          placeholder={'Required'}
          onChange={handleCreate}
          required={true}
          error={
            reportForm.descriptionError ||
            reportForm.description === '' ||
            reportForm.description.length > 200
          }
          helperText={
            reportForm.descriptionError ||
            reportForm.description === '' ||
            reportForm.description.length > 200
              ? 'Error Description'
              : ''
          }
        />
        <Select
          disabledOptions={[]}
          id="CreateReportModuleId"
          label="Module"
          onChange={(event) =>
            setModuleCreateReport(String(event.target.value))
          }
          error={
            moduleCreateReport === ' ' ? t('pages.reports.SelectError') : ''
          }
          required
          options={[
            {
              desc: 'Platform',
              key: '1',
              selected: true,
            },
            {
              desc: 'Property',
              key: '2',
            },
            {
              desc: 'Revenue',
              key: '3',
            },
          ]}
          value={String(moduleCreateReport)}
        />
      </Box>
    );
  }, [
    moduleCreateReport,
    reportForm.description,
    reportForm.descriptionError,
    reportForm.name,
    reportForm.nameError,
    t,
  ]);

  return (
    <>
      <Dialog
        id="dialogIdConfirm"
        open={openModalConfirmation.isOpenModal}
        title={t('pages.reports.confirmationDeleteReport')}
        type="danger"
        dangerModalDangerButtonText="Delete"
        width={360}
        height={180}
        handleCancelClick={() => {
          setOpenModalConfirmation({
            isOpenModal: false,
            id: -1,
          });
        }}
        handleDangerClick={() => {
          deleteReports(openModalConfirmation.id);
        }}
        handleClose={() => {
          setOpenModalConfirmation({
            isOpenModal: false,
            id: -1,
          });
        }}
      />

      <Dialog
        id="dialogId"
        open={openModalNewReport}
        children={newReportBlock}
        title={'New Report'}
        type="transactional"
        transactionModalTransactionButtonText="Create"
        width={360}
        height={350}
        sx={{
          padding: '0px',
          fontFamily: 'Calibri regular',
          fontSize: '14px',
          borderRadius: '4px',
          fontWeight: 'normal',
        }}
        handleCancelClick={() => {
          setOpenModalNewReport(false);
          setReportForm({
            name: ' ',
            description: ' ',
            nameError: false,
            descriptionError: false,
            isPresent: false,
          });
        }}
        handleTransactionClick={() => {
          if (
            !reportForm.nameError &&
            !reportForm.descriptionError &&
            reportForm.name !== ' ' &&
            reportForm.description !== ' ' &&
            moduleCreateReport !== '' &&
            moduleCreateReport !== ' '
          ) {
            createReports(
              reportForm.name,
              reportForm.description,
              String(moduleCreateReport)
            );
          }
        }}
        handleClose={() => {
          setOpenModalNewReport(false);
          setReportForm({
            name: ' ',
            description: ' ',
            nameError: false,
            descriptionError: false,
            isPresent: false,
          });
        }}
      />
      <Grid
        sx={{
          flexGrow: '1 !important',
          maxWidth: '100% !important',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'end',
          }}
        >
          <Button
            id="createContent_upload"
            sx={{
              minWidth: '128px',
              mt: 1,

              marginTop: '16px',
            }}
            onClick={() => setOpenModalNewReport(true)}
            disabled={!canAction}
          >
            {'New Report'}
          </Button>
        </Box>
        {isLoading === false && data !== undefined && Columns.length > 0 ? (
          <DefaultDataTableNext
            columns={Columns as ColumnDef<Record<string, unknown>>[]}
            data={data !== undefined ? data.getReports : []}
            sx={{
              justifyContent: 'end',
              gridAutoFlow: 'column',
              width: '100%',
            }}
          />
        ) : null}
        {data === undefined &&
        isLoading === false &&
        isLoadingReports === false ? (
          <Grid
            container
            sx={{ justifyContent: 'center', alignItems: 'center' }}
          >
            <Typography>{t('pages.reports.noReports')}</Typography>
          </Grid>
        ) : null}
        {isLoading || isLoadingReports ? <Loading /> : null}
      </Grid>
    </>
  );
}

export default Reports;
