import { UpdateDictionaryResponse } from 'generated/graphql';

const dictionaryDefault: UpdateDictionaryResponse = {
  systemFieldDictionaryId: '',
  fixedId: 0,
  logixInputSchemaJson: 'NULL',
  status: '',
  version: 0,
  description: 'NULL',
  createdBy: '',
  createdDate: '',
  updatedBy: '',
  updatedDate: '',
};

export { dictionaryDefault };
