import { Grid } from '@mui/material';
import {
  FormErrors,
  DestinationObject,
  LogixGroupType,
} from '@revenue-solutions-inc/revxcoreui';
import LogiX from 'components/LogiX';
import { LogiXgroupInput } from 'generated/graphql';
import Loading from 'components/Loading';
import dataDestination from './destination.json';

interface FormLogixProps {
  LogixGroup: LogiXgroupInput | null;
  formErrors: FormErrors[] | [];
  handleGetLogixUpdate: (logixUpdated: LogixGroupType) => void;
}

function FormLogix({
  LogixGroup,
  formErrors,
  handleGetLogixUpdate,
}: FormLogixProps): JSX.Element {
  return LogixGroup !== null ? (
    <Grid container spacing={0} alignItems="center" justifyContent="center">
      <Grid item xs={12} md={6}>
        <LogiX
          data={LogixGroup as unknown as LogixGroupType}
          handleSelectedField={() => {}}
          config={{
            domain: {
              label: 'Form',
              canCreate: false,
            },
          }}
          showDestination={false}
          dataDestination={dataDestination as unknown as DestinationObject}
          cleanSelectedFields={false}
          getJson={true}
          handleGetLogixUpdate={handleGetLogixUpdate}
          formErrors={formErrors}
        />
      </Grid>
    </Grid>
  ) : (
    <Loading />
  );
}

export default FormLogix;
