import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import {
  Button,
  MessageActionType,
  MessageType,
} from '@revenue-solutions-inc/revxcoreui';
import { useAppDispatch } from 'redux/hooks';
import { useQueryClient } from '@tanstack/react-query';
import {
  useApproveAdjustmentMutation,
  useRejectAdjustmentMutation,
} from 'generated/graphql';
import extractMeaningfulMessage from 'utils/errorMessage';
import { addMessage } from 'redux/messageSlice';

interface Props {
  adjustmentId: string;
}

function ApproveDenyAdjustment({ adjustmentId }: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const invalidateQueries = () => {
    void queryClient.invalidateQueries({
      queryKey: ['GetAdjustmentDetail'],
    });
  };

  const { mutate, isLoading: isApproveAdjustmentLoading } =
    useApproveAdjustmentMutation({
      onSuccess: () => {
        invalidateQueries();
        dispatch(
          addMessage({
            message: t('pages.adjustmentDetails.adjustmentApproved'),
            type: MessageType.Success,
            actionType: MessageActionType.None,
          })
        );
      },
      onError: (error) => {
        const message = extractMeaningfulMessage(
          error,
          t('pages.adjustmentDetails.adjustmentApprovedError')
        );
        dispatch(
          addMessage({
            type: MessageType.Error,
            message: message,
          })
        );
      },
    });

  const {
    mutate: mutateRejectAdjustment,
    isLoading: isRejectAdjustmentLoading,
  } = useRejectAdjustmentMutation({
    onSuccess: () => {
      invalidateQueries();
      dispatch(
        addMessage({
          message: t('pages.adjustmentDetails.adjustmentRejected'),
          type: MessageType.Warning,
          actionType: MessageActionType.None,
        })
      );
    },
    onError: (error) => {
      const message = extractMeaningfulMessage(
        error,
        t('pages.adjustmentDetails.adjustmentRejectedError')
      );
      dispatch(
        addMessage({
          type: MessageType.Error,
          message: message,
        })
      );
    },
  });

  function submitApproveAdjustment() {
    mutate({ adjustmentId: adjustmentId });
  }

  function submitRejectAdjustment() {
    mutateRejectAdjustment({ adjustmentId: adjustmentId });
  }

  return (
    <Box sx={{ width: 220, textAlign: 'right', mr: '8px' }}>
      <Button
        id="approve-adjustment-link"
        type="secondary"
        variant="text"
        loading={isApproveAdjustmentLoading}
        sx={{ mr: 1 }}
        onClick={submitApproveAdjustment}
      >
        {t('pages.adjustmentDetails.approve')}
      </Button>
      <Button
        id="deny-adjustment-link"
        type="secondary"
        variant="text"
        loading={isRejectAdjustmentLoading}
        onClick={submitRejectAdjustment}
      >
        {t('pages.adjustmentDetails.reject')}
      </Button>
    </Box>
  );
}
export default ApproveDenyAdjustment;
