import { useEffect } from 'react';
import {
  Control,
  FieldArrayWithId,
  useFormContext,
  useWatch,
  UseFormTrigger,
} from 'react-hook-form';
import { Grid, Stack, Card, Box, IconButton } from '@mui/material';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { SelectType } from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import { useTranslation } from 'react-i18next';
import ControlledInputField from 'components/controls/ControlledInputField';
import ControlledSelectField from 'components/controls/ControlledSelectField';
import ControlledCheckBox from 'components/controls/ControlledCheckbox';
import { WorkflowFormObject } from '../WorkflowEditor.types';

const attributeTypes: SelectType[] = [
  { key: 'string', desc: 'string' },
  { key: 'int', desc: 'int' },
  { key: 'boolean', desc: 'boolean' },
  { key: 'datetime', desc: 'DateTime' },
  { key: 'Dropdown', desc: 'Dropdown' },
  { key: 'ReferenceData', desc: 'ReferenceData' },
  { key: 'DependentData', desc: 'DependentData' },
  { key: 'SubData', desc: 'SubData' },
  { key: 'Schema', desc: 'Schema' },
  { key: 'DynamicAttribute', desc: 'DynamicAttribute' },
];

interface RepeatingAttributeProps {
  attr: FieldArrayWithId<
    WorkflowFormObject,
    `workflowConfig.${number}.attribute.${number}.repeatingValue`,
    'id'
  >;
  grpIndex: number;
  attributeIndex: number;
  editDisabled: boolean;
  control: Control<WorkflowFormObject, null>;
  remove: (index?: number | number[]) => void;
  repeatingAttributeIndex: number;
  isCustomTables: boolean;
  enableHiddenAttribute?: boolean;
  isEditMode?: boolean;
  triggerValidation?: UseFormTrigger<WorkflowFormObject>;
}

const RepeatingAttribute = ({
  attr,
  grpIndex,
  attributeIndex,
  editDisabled,
  control,
  repeatingAttributeIndex,
  remove,
  isCustomTables,
  enableHiddenAttribute,
  isEditMode,
  triggerValidation,
}: RepeatingAttributeProps) => {
  const attributeNameField = `workflowConfig.${grpIndex}.attribute.${attributeIndex}.repeatingValue.${repeatingAttributeIndex}.attributeName`;
  const attributeTypeField = `workflowConfig.${grpIndex}.attribute.${attributeIndex}.repeatingValue.${repeatingAttributeIndex}.attributeType`;
  const attributeDisplayNameField = `workflowConfig.${grpIndex}.attribute.${attributeIndex}.repeatingValue.${repeatingAttributeIndex}.attributeDisplayName`;
  const attributeValueField = `workflowConfig.${grpIndex}.attribute.${attributeIndex}.repeatingValue.${repeatingAttributeIndex}.attributeValue`;

  const { t } = useTranslation();
  const attributeName = useWatch({ name: attributeNameField });
  const attributeType = useWatch({ name: attributeTypeField });
  const attributeValue = useWatch({ name: attributeValueField });
  const attributeDisplayName = useWatch({ name: attributeDisplayNameField });
  const { setValue } = useFormContext();

  useEffect(() => {
    if (isEditMode === false) {
      setValue(
        attributeNameField,
        (attributeName as string)?.replaceAll(' ', '')
      );
    }
    if (attributeType === 'boolean') {
      // server expects strings not booleans
      const stringValue = attributeValue ? attributeValue.toString() : '';
      setValue(attributeValueField, stringValue);
    }
  }, [
    attributeName,
    attributeNameField,
    attributeType,
    attributeValueField,
    attributeValue,
    setValue,
    isEditMode,
  ]);

  useEffect(() => {
    if (
      (!attributeName || !attributeType || !attributeDisplayName) &&
      triggerValidation !== undefined
    ) {
      triggerValidation([
        attributeDisplayNameField as `workflowConfig.${number}.attribute.${number}.repeatingValue.${number}.attributeDisplayName`,
        attributeNameField as `workflowConfig.${number}.attribute.${number}.repeatingValue.${number}.attributeName`,
        attributeTypeField as `workflowConfig.${number}.attribute.${number}.repeatingValue.${number}.attributeType`,
      ]);
    }
  }, [attributeName, attributeType, attributeDisplayName, triggerValidation]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Grid
      id={`repeating-attribute-${grpIndex}.${attributeIndex}.${repeatingAttributeIndex}`}
      item
      xs={3}
      sx={{
        position: 'relative',
      }}
    >
      <Stack>
        <Card sx={{ p: 2, backgroundColor: 'secondary.main' }}>
          <Box>
            <ControlledInputField
              defaultValue={attr.attributeName ?? ''}
              name={`workflowConfig.${grpIndex}.attribute.${attributeIndex}.repeatingValue.${repeatingAttributeIndex}.attributeName`}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t('pages.attributes.messages.valueReq'),
                },
              }}
              sx={{ width: '100%' }}
              disabled={editDisabled}
              required
              id={`${attr.attributeName}-${repeatingAttributeIndex}`}
              label={t('pages.attributes.name')}
            />
          </Box>
          <Box>
            <ControlledInputField
              defaultValue={attr.attributeDisplayName ?? ''}
              name={`workflowConfig.${grpIndex}.attribute.${attributeIndex}.repeatingValue.${repeatingAttributeIndex}.attributeDisplayName`}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t('pages.attributes.messages.valueReq'),
                },
              }}
              sx={{ width: '100%' }}
              disabled={editDisabled}
              required
              id={`${attr.attributeValue}-${repeatingAttributeIndex}`}
              label={t('pages.attributes.displayName')}
            />
          </Box>
          <Box>
            <ControlledInputField
              defaultValue={attr.attributeDescription ?? ''}
              name={`workflowConfig.${grpIndex}.attribute.${attributeIndex}.repeatingValue.${repeatingAttributeIndex}.attributeDescription`}
              control={control}
              rules={{}}
              sx={{ width: '100%' }}
              disabled={editDisabled}
              id={`${attr.attributeDescription}-${repeatingAttributeIndex}`}
              label={t('pages.attributes.description')}
            />
          </Box>
          <Box>
            <ControlledSelectField
              control={control}
              defaultValue={attr.attributeType ?? ''}
              name={`workflowConfig.${grpIndex}.attribute.${attributeIndex}.repeatingValue.${repeatingAttributeIndex}.attributeType`}
              rules={{
                required: {
                  value: true,
                  message: t('pages.attributes.messages.valueReq'),
                },
              }}
              sx={{ width: '100%' }}
              required
              disabled={editDisabled}
              options={attributeTypes}
              id={`${attr.attributeName}-${repeatingAttributeIndex}-select-wf`}
              data-testid="configDomain"
              label={t('pages.attributes.type')}
            />
          </Box>
          {attributeType === 'ReferenceData' ? (
            <ControlledInputField
              defaultValue={attr.dataSource ?? ''}
              name={`workflowConfig.${grpIndex}.attribute.${attributeIndex}.repeatingValue.${repeatingAttributeIndex}.dataSource`}
              control={control}
              rules={{}}
              sx={{ width: '100%' }}
              disabled={editDisabled}
              id={`${attr.attributeName}-${repeatingAttributeIndex}`}
              label={t('pages.attributes.dataSource')}
            />
          ) : attributeType === 'boolean' ? (
            <Box>
              <ControlledCheckBox
                name={`workflowConfig.${grpIndex}.attribute.${attributeIndex}.repeatingValue.${repeatingAttributeIndex}.attributeValue`}
                control={control}
                disabled={editDisabled}
                id={`${attr.attributeName}-${repeatingAttributeIndex}`}
                label={t('pages.attributes.defaultValue')}
              />
            </Box>
          ) : (
            <Box>
              <ControlledInputField
                defaultValue={attr.attributeValue ?? ''}
                name={`workflowConfig.${grpIndex}.attribute.${attributeIndex}.repeatingValue.${repeatingAttributeIndex}.attributeValue`}
                control={control}
                rules={{}}
                sx={{ width: '100%' }}
                disabled={editDisabled}
                id={`${attr.attributeName}-${repeatingAttributeIndex}`}
                label={t('pages.attributes.defaultValue')}
              />
            </Box>
          )}
          {enableHiddenAttribute && (
            <Box>
              <ControlledCheckBox
                name={`workflowConfig.${grpIndex}.attribute.${attributeIndex}.repeatingValue.${repeatingAttributeIndex}.attributeHidden`}
                control={control}
                disabled={editDisabled}
                id={`${attr.attributeName}-${repeatingAttributeIndex}`}
                label={t('pages.attributes.hiddenAttribute')}
              />
            </Box>
          )}
        </Card>
        <IconButton
          disabled={editDisabled}
          title={
            isCustomTables
              ? t('pages.attributes.removeColumn')
              : t('pages.attributes.removeField')
          }
          onClick={() => {
            remove(repeatingAttributeIndex);
          }}
          size="small"
          sx={{
            position: 'absolute',
            top: 10,
            right: -10,
          }}
        >
          <CancelRoundedIcon fontSize="small" />
        </IconButton>
      </Stack>
    </Grid>
  );
};

export default RepeatingAttribute;
