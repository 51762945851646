import { Dispatch } from 'react';
import { Grid, IconButton } from '@mui/material';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { Controller, Control, UseFormSetValue } from 'react-hook-form';
import { Input, Select } from '@revenue-solutions-inc/revxcoreui';
import {
  WorkflowFormObject,
  WorkflowCondition as TWorkflowCondition,
} from '../WorkflowEditor.types';

interface Option {
  desc: string;
  key: string;
}

interface Props {
  control: Control<WorkflowFormObject>;
  setValue: UseFormSetValue<WorkflowFormObject>;
  stepIndex: number;
  condition: TWorkflowCondition & { id: string };
  conditionIndex: number;
  remove: (index: number | number[]) => void;
  targetOptions: Option[];
  setDisableValue: Dispatch<boolean[] | ((state: boolean[]) => boolean[])>;
  disableValue: boolean[];
  sourceOptions: Option[];
  disabled?: boolean;
}

const conditionOptions = [
  { desc: 'Equal to', key: '==' },
  { desc: 'Not equal to', key: '!=' },
  { desc: 'Greater than', key: '>' },
  { desc: 'Less than', key: '<' },
  { desc: 'Greater than or equal to', key: '>=' },
  { desc: 'Less than or equal to', key: '<=' },
];

export const WorkflowCondition = ({
  stepIndex,
  condition,
  conditionIndex,
  remove,
  targetOptions,
  control,
  sourceOptions,
  setValue,
  disableValue,
  setDisableValue,
  disabled,
}: Props) => (
  <Grid
    container
    spacing={3}
    data-name={`${condition.condition.attributeName}-${stepIndex}-${conditionIndex}`}
    key={condition.id}
    sx={{
      marginLeft: 0,
    }}
  >
    <Grid item>
      <Controller
        defaultValue={condition.source.attributeValue}
        name={`workflowSteps.${stepIndex}.workflowConditions.${conditionIndex}.source.attributeValue`}
        control={control}
        render={({ field: { onChange, value } }) => (
          <Select
            sx={{ width: '100%' }}
            id={`${condition.source.attributeName}-${conditionIndex}`}
            label={condition.source.attributeDisplayName}
            value={value}
            onChange={onChange}
            options={sourceOptions}
            disabled={disabled}
          />
        )}
      />
    </Grid>
    <Grid item>
      <Controller
        defaultValue={condition.condition.attributeValue}
        name={`workflowSteps.${stepIndex}.workflowConditions.${conditionIndex}.condition.attributeValue`}
        control={control}
        render={({ field: { onChange, value } }) => (
          <Select
            sx={{ width: '100%' }}
            id={`${condition.condition.attributeName}-${conditionIndex}`}
            label={condition.condition.attributeDisplayName}
            value={value}
            onChange={(e) => {
              onChange(e);
            }}
            options={conditionOptions}
            disabled={disabled}
          />
        )}
      />
    </Grid>
    <Grid item>
      <Controller
        defaultValue={condition.target.attributeValue ?? ''}
        name={`workflowSteps.${stepIndex}.workflowConditions.${conditionIndex}.target.attributeValue`}
        control={control}
        render={({ field: { onChange, value } }) => (
          <Select
            sx={{ width: '100%' }}
            id={`${condition.target.attributeName}-${conditionIndex}`}
            label={condition.target.attributeDisplayName}
            onChange={(e) => {
              setValue(
                `workflowSteps.${stepIndex}.workflowConditions.${conditionIndex}.targetValue.attributeValue`,
                ''
              );
              setDisableValue((oldValue: boolean[]) => {
                const newDisableValue = [...oldValue];
                newDisableValue[conditionIndex] = e.target.value !== 'val';
                return newDisableValue;
              });
              onChange(e);
            }}
            options={targetOptions}
            value={value}
            disabled={disabled}
          />
        )}
      />
    </Grid>
    <Grid item>
      <Controller
        defaultValue={
          disableValue[conditionIndex]
            ? ''
            : condition.targetValue.attributeValue
        }
        name={`workflowSteps.${stepIndex}.workflowConditions.${conditionIndex}.targetValue.attributeValue`}
        control={control}
        render={({ field: { onChange, value } }) => (
          <Input
            sx={{ width: '100%' }}
            id={`${condition.targetValue.attributeName}-${conditionIndex}`}
            label={condition.targetValue.attributeDisplayName}
            value={value}
            onChange={onChange}
            disabled={disabled || disableValue[conditionIndex]}
          />
        )}
      />
    </Grid>
    <Grid item>
      <IconButton
        onClick={() => {
          remove(conditionIndex);
        }}
        aria-label="delete"
        color="default"
        size="small"
        disabled={disabled}
      >
        <CancelRoundedIcon fontSize="small" />
      </IconButton>
    </Grid>
  </Grid>
);
