import { useState } from 'react';
import { Box, FormControlLabel, Grid, IconButton } from '@mui/material';
import Loading from 'components/Loading';
import { Link } from 'react-router-dom';
import HistoryIcon from '@mui/icons-material/History';
import { useTranslation } from 'react-i18next';
import DataCard from 'components/DataCard';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { useQueryClient } from '@tanstack/react-query';
import {
  CmGetChannels,
  useGetChannelHistoryQuery,
  useRunChannelMutation,
} from 'generated/graphql';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import Checkbox from '@revenue-solutions-inc/revxcoreui/material/controls/Checkbox';
import { ColumnDef } from '@tanstack/react-table';
import DateCell from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/TableCells/DateCell';
import { useAppSelector, useAppDispatch } from 'redux/hooks';
import Dialog from '@revenue-solutions-inc/revxcoreui/material/controls/Dialog';
import { addMessage } from 'redux/messageSlice';
import { MessageType } from '@revenue-solutions-inc/revxcoreui';

enum VersionStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  DRAFT = 'Draft',
}

interface Props {
  channelName: string;
  currentModule: string;
}

const optionalStyles = {
  '.MuiDialogTitle-root': {
    color: 'lightBlack.main !important',
    padding: '10px 24px',
  },
  fontSize: '16px',
  fontWeight: 'normal',
};

export const dialogStyle = {
  '& .MuiSvgIcon-root': {
    display: 'none',
  },
  '.MuiDialogTitle-root': {
    fontWeight: 'bold',
  },
  '& .MuiDialogActions-root': {
    textAlign: 'center',
    display: 'inline-block',
  },
  '& .MuiDialogContent-root': {
    fontSize: '16px',
    fontWeight: 'normal',
  },
};

function VersionHistory({ channelName, currentModule }: Props): JSX.Element {
  const [openRunChannel, setOpenRunChannel] = useState<boolean>(false);
  const [allowDuplicateFiles, setAllowDuplicateFiles] =
    useState<boolean>(false);
  const [channelId, setChannelId] = useState<string>('');
  const [channelNameValue, setChannelNameValue] = useState<string>('');
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const childrenRun = <>{t('pages.manageChannel.warningRun')}</>;
  const { mutate: mutateRun, isLoading: loadingRun } = useRunChannelMutation();

  const module = useAppSelector((state) => state.user.module);
  const { data, isLoading } = useGetChannelHistoryQuery({
    module: currentModule,
    channelName: channelName,
  });

  const disabledRunButton = (Status: string) => {
    if (
      (process.env.REACT_APP_ENVIRONMENT === 'Production Environment' &&
        Status === VersionStatus.DRAFT) ||
      Status === VersionStatus.INACTIVE
    ) {
      return true;
    }
  };

  const columnsData: ColumnDef<CmGetChannels>[] = [
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.manageChannel.taskVersion')} />
      ),
      id: 'task-edit',
      cell: ({ row }) => {
        return (
          <Link
            to={{
              pathname: `/${module}/managechannels/edit/${row.original.Id}/${row.original.ChannelName}/${row.original.Module}`,
            }}
          >
            {row.original.Version}
          </Link>
        );
      },
    },
    {
      id: 'DisplayName',
      header: () => (
        <HeaderColumnNext localization={t('pages.manageChannel.displayName')} />
      ),
      accessorKey: 'DisplayName',
    },
    {
      id: 'CreatedBy',
      header: () => (
        <HeaderColumnNext localization={t('pages.manageChannel.createdBy')} />
      ),
      accessorKey: 'CreatedBy',
    },
    {
      id: 'CreatedDttm',
      header: () => (
        <HeaderColumnNext localization={t('pages.manageChannel.createdDate')} />
      ),
      accessorKey: 'CreatedDttm',
      cell: ({ row }) => {
        return <DateCell dateString={row.original.CreatedDttm} />;
      },
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.manageChannel.updatedBy')} />
      ),
      id: 'updated-by',
      accessorKey: 'UpdatedBy',
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.manageChannel.updatedDttm')} />
      ),
      id: 'updated-dttm ',
      accessorKey: 'UpdatedDttm',
      cell: ({ row }) => {
        return <DateCell dateString={row.original.UpdatedDttm} />;
      },
    },
    {
      id: 'Version-Status',
      header: () => (
        <HeaderColumnNext localization={t('pages.manageChannel.status')} />
      ),
      accessorKey: 'Status',
    },
    {
      id: 'Version-Run',
      header: () => (
        <HeaderColumnNext localization={t('pages.manageChannel.runVersion')} />
      ),
      cell: ({ row }) => {
        return (
          <IconButton
            onClick={() => {
              setOpenRunChannel(true);
              setChannelId(row.original.Id);
              setChannelNameValue(row.original.ChannelName);
            }}
            color="primary"
            disabled={disabledRunButton(row.original.Status)}
          >
            <PlayArrowIcon />
          </IconButton>
        );
      },
    },
  ];

  if (!isLoading && data && !loadingRun) {
    return (
      <DataCard
        avatar={<HistoryIcon sx={{ fill: 'primary.main' }} />}
        title={t('pages.manageChannel.versionHistory')}
        children={
          <Grid sx={{ marginBottom: '20px' }}>
            <DefaultDataTableNext
              columns={columnsData as ColumnDef<Record<string, unknown>>[]}
              data={data?.getChannelHistory}
              enableGlobalFilter={false}
            />
            <Dialog
              id="run-dialog"
              title={channelNameValue}
              open={openRunChannel}
              type="transactional"
              optionalStyles={optionalStyles}
              optionalLabel={t('pages.manageChannel.confirmationRun')}
              sx={dialogStyle}
              transactionModalTransactionButtonText={t(
                'pages.manageChannel.runChannel'
              )}
              children={
                <>
                  <Box mb={2}>{childrenRun}</Box>
                  <Grid item xs={12} ml={1} mt={-3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={allowDuplicateFiles}
                          onChange={(event) => {
                            setAllowDuplicateFiles(event.target.checked);
                          }}
                          color="primary"
                          sx={{
                            '&.MuiFormControl-root': {
                              opacity: 1,
                            },
                            '& .PrivateSwitchBase-input': {
                              opacity: 1,
                            },
                          }}
                        />
                      }
                      label={t('pages.manageChannel.allowDuplicateFiles')}
                      sx={{
                        marginTop: '16px',
                        marginLeft: '5px',
                      }}
                    />
                  </Grid>
                </>
              }
              width={450}
              handleCancelClick={() => {
                setOpenRunChannel(false);
              }}
              handleTransactionClick={() => {
                setOpenRunChannel(false);
                mutateRun(
                  {
                    channel: {
                      PipelineRunRequest: {
                        ChannelId: channelId,
                        ChannelName: channelNameValue,
                        Module: currentModule,
                        AllowDuplicateFiles: allowDuplicateFiles,
                      },
                    },
                  },

                  {
                    onSuccess: () => {
                      queryClient.refetchQueries(['GetChannelRunList']);
                      dispatch(
                        addMessage({
                          type: MessageType.Success,
                          message: t('pages.manageChannel.runIcon'),
                        })
                      );
                    },
                  }
                );
              }}
            />
          </Grid>
        }
      />
    );
  }
  if (isLoading || loadingRun) {
    return <Loading />;
  }
  return <></>;
}
export default VersionHistory;
