import { useEffect, useMemo, useState } from 'react';

import {
  Account,
  CreateAccountPeriodResponse,
  useGetAccountPeriodsAndBalanceQuery,
  useGetPotentialPeriodsByAccountIdQuery,
} from 'generated/graphql';
import { useParams } from 'react-router-dom';
import AddPeriodModal from '../AddPeriodModal';
import AccountPeriodsDataCard from '../AccountPeriodsDataCard';

interface Props {
  accountData: Account;
}

type DateType = string | null | undefined;
export interface AvailablePeriod {
  id: number;
  checked: boolean;
  beginDate: DateType;
  endDate: DateType;
  dueDate: DateType;
}

function AccountPeriods({ accountData }: Props): JSX.Element {
  const { accountId } = useParams() as { accountId: string };
  const [open, setOpen] = useState<boolean>(false);

  const [availablePeriods, setAvailablePeriods] = useState<AvailablePeriod[]>(
    []
  );

  const { data: accountPeriods } = useGetAccountPeriodsAndBalanceQuery(
    {
      accountId: accountId,
    },
    {
      enabled: !!accountId,
      refetchOnReconnect: false,
      staleTime: 3 * (60 * 1000), //3 minutes
      select: (data) => {
        return data?.GetAccountPeriodsAndBalance?.accountPeriods;
      },
    }
  );

  const {
    data: availablePeriodsList,
    isLoading: potentialPeriodsLoading,
    isError: potentialPeriodsError,
  } = useGetPotentialPeriodsByAccountIdQuery({
    financialAccountId: accountId,
    throughDate: '',
  });

  const getAvailablePeriods = useMemo(() => {
    const periods: AvailablePeriod[] = [];
    availablePeriodsList?.GetPotentialPeriodsByAccountId.forEach(
      (period, index) => {
        const dueDate = period?.attributes?.find(
          (attr) => attr.name === 'DueDate'
        );
        periods.push({
          id: index,
          checked: false,
          beginDate: period.beginDate,
          endDate: period.endDate,
          dueDate: dueDate ? dueDate.value : '',
        });
      }
    );
    return periods;
  }, [availablePeriodsList?.GetPotentialPeriodsByAccountId]);

  useEffect(() => {
    if (availablePeriodsList?.GetPotentialPeriodsByAccountId) {
      setAvailablePeriods(getAvailablePeriods);
    }
  }, [
    availablePeriodsList?.GetPotentialPeriodsByAccountId,
    getAvailablePeriods,
  ]);

  return (
    <>
      <AccountPeriodsDataCard
        accountPeriods={accountPeriods as CreateAccountPeriodResponse[]}
        setOpenModal={setOpen}
        accountData={accountData}
      />
      <AddPeriodModal
        open={open}
        setOpen={setOpen}
        availablePeriods={availablePeriods}
        setAvailablePeriods={setAvailablePeriods}
        availablePeriodsLoading={potentialPeriodsLoading}
        availablePeriodsError={potentialPeriodsError}
      />
    </>
  );
}

export default AccountPeriods;
