import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Box } from '@mui/material';
import { MessageType } from '@revenue-solutions-inc/revxcoreui';
import { ConfigurationDomains } from 'common/platformConfigUtils/platformConfigUtils';
import EntityManagementContext from 'components/contexts/EntityManagement';
import {
  defaultSectionsConfig,
  getEntity,
} from 'components/entityManagement/common/defaults/entity';
import ExtendedAttributes from 'components/entityManagement/common/ExtendedAttributes';
import {
  NameFields,
  IdentifiersFields,
  AddressFields,
  EmailAddressFields,
  PhoneNumberFields,
  OtherFields,
} from 'components/entityManagement/common/fields/entities';
import {
  getIdFormats,
  getMaskRules,
  getValidationRules,
} from 'components/entityManagement/common/formatValidations';
import {
  buildEntityRequest,
  sectionNames,
} from 'components/entityManagement/common/entityUtils';
import {
  buildExtendedValues,
  isCommenceDateValid,
  isCeaseDateValid,
  CoreSchema,
} from 'components/entityManagement/common/entityManagementUtils';
import Loading from 'components/Loading';
import HorizontalNonLinearStepper from 'components/stepper/HorizontalNonLinearStepper';
import {
  CreateEntityMutation,
  Entity,
  useCreateEntityMutation,
  useGetConfigurationSchemaQuery,
  useGetConfigurationSchemaByIdQuery,
  useGetLookupConfigurationQuery,
  useGetSchemaGroupsQuery,
} from 'generated/graphql';
import { Control, FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import {
  EntityForm,
  EntityRenderSection,
  EntitySectionNames,
  EntityStepsConfig,
} from 'types/entities';
import { ExtendedAttributeValues } from 'types/forms';
import { Error } from 'types/graphqlErrors';
import extractMeaningfulMessage from 'utils/errorMessage';

import ConfirmationEntity from '../ConfirmationEntity';
import SectionContainer from '../SectionContainer';

function EntityLayout(): JSX.Element {
  const sections = [
    'Entity',
    'Name(s)',
    'Identifier(s)',
    'Address(es)',
    'Contact(s)',
    'Confirmation',
  ];
  const singleSections: EntityRenderSection[] = [
    { step: 0, section: OtherFields },
    { step: 1, section: NameFields },
    { step: 2, section: IdentifiersFields },
    { step: 3, section: AddressFields },
  ];
  const CONFIG_DOMAIN = ConfigurationDomains.ReferenceSchema;
  const CORE_DOMAIN = ConfigurationDomains.EntityManagement;
  const SECTION_LEVEL = 2; // Used in getSchemaGroups response
  const MULTIPLE_SECTION_INDEX = 4; // The index of email and phone sub-sections
  const CONFIRMATION_STEP = 5;
  const entity: EntityForm = getEntity;
  const { mutate: createEntity } = useCreateEntityMutation({});
  const dispatch = useAppDispatch();
  const module = useAppSelector((state) => state.user.module);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [currentDirty, setCurrentDirty] = useState<boolean>(false);
  const [saveSuccessful, setSaveSuccessful] = useState<boolean>(false);
  const [isEntitySaving, setEntitySaving] = useState<boolean>(false);
  const [showOptional, setShowOptional] = useState<boolean>(false);
  const [showPhoneOptional, setShowPhoneOptional] = useState<boolean>(false);
  const [showEmailOptional, setShowEmailOptional] = useState<boolean>(false);
  const [extendedAttribute, setExtendedAttribute] = useState<string>('');
  const [entityType, setEntityType] = useState<string>('');
  const [commenceDate, setCommenceDate] = useState<string>('');
  const [idType, setIdType] = useState<string>('');
  const [validated, setValidated] = useState<{ [k: number]: boolean }>({});
  const [extendedVals, setExtendedVals] = useState<ExtendedAttributeValues[]>(
    []
  );
  const sectionsConfig = useRef<EntityStepsConfig[]>(defaultSectionsConfig);

  const {
    control,
    watch,
    formState,
    register,
    trigger,
    handleSubmit,
    getFieldState,
    getValues,
    setValue,
    reset,
  } = useForm<EntityForm>({ defaultValues: entity, mode: 'onChange' });

  /**
   * Used to get the current section using the name or the current step
   */
  const findSection = useCallback(
    (name?: string): EntityStepsConfig | undefined => {
      return sectionsConfig.current.find((section) =>
        name
          ? section.name.toLowerCase() === name.toLowerCase()
          : section.step === currentStep
      );
    },
    [currentStep]
  );

  /**
   * Using getSchemaGroups to check if the extended attributes are available
   */
  const { isFetching: isFetchingSchemaGroups } = useGetSchemaGroupsQuery(
    {
      configurationDomain: CONFIG_DOMAIN,
      configurationType: 'EntityType',
      configurationName: entityType,
    },
    {
      enabled: entityType !== '',
      onSuccess: (schemaGroups) => {
        // This part is related only with Extended Attributes
        const foundExtended = schemaGroups.GetSchemaGroups.find(
          (sectionItem) => sectionItem.attributeName === 'ExtendedAttribute'
        );
        if (foundExtended) setExtendedAttribute(foundExtended.attributeValue);
        else {
          setExtendedAttribute('');
        }
        // This part is related only with required/optional Demographics
        schemaGroups.GetSchemaGroups.forEach((sectionItem) => {
          const sectionLevel = sectionItem.attributeName.split('__');
          if (sectionLevel.length === SECTION_LEVEL) {
            const sectionName = sectionLevel[1].toLowerCase();
            const section = findSection(sectionName);
            if (section) {
              const idxSection = sectionsConfig.current.indexOf(section);
              let required = true;
              if (sectionItem.attributeValue === 'false') {
                required = false;
              }
              const sectionToAdd: EntityStepsConfig = {
                ...section,
                isRequired: required,
              };
              sectionsConfig.current.splice(idxSection, 1, sectionToAdd);
            }
          }
        });
      },
      onError: () => {
        setExtendedAttribute('');
      },
    }
  );

  /**
   * Used to get the information related with extended attributes and builds
   * internal objects to be used in the forms
   */
  const {
    data: extendedAttrData,
    isFetching: isFetchingExtended,
    refetch: refetchExtended,
  } = useGetConfigurationSchemaByIdQuery(
    {
      platformConfigurationId: extendedAttribute,
    },
    {
      onSuccess: (extendedAttributes) => {
        const extAttributes =
          extendedAttributes?.GetConfigurationSchemaById
            .platformConfigurationInfo?.configurationSection[0].group[0]
            .attribute;
        if (extAttributes) {
          const newExtendedVals = buildExtendedValues(extAttributes);
          setExtendedVals(newExtendedVals);
        } else setExtendedVals([]);
      },
      onError: () => {
        setExtendedVals([]);
      },
    }
  );

  /**
   * Use to get configuration schema related with Entity core
   */
  const { data: layoutData, isFetching: isLayoutFetching } =
    useGetConfigurationSchemaQuery(
      {
        configurationDomain: CORE_DOMAIN,
        configurationName: CoreSchema.Entity,
      },
      { enabled: entityType !== '' }
    );

  /**
   * Used to get information about the Identifiers configuration, specifically
   * to establish ID formats, masks, and validations data
   */
  const { data: configuredIdTypes } = useGetLookupConfigurationQuery({
    configurationDomain: CONFIG_DOMAIN,
    configurationType: 'IdType',
  });

  /**
   * Used to get information about the Organization Types when the Entity type
   * has this option enabled by configuration
   */
  const { data: entityOrganizationTypeData } = useGetLookupConfigurationQuery({
    configurationDomain: CONFIG_DOMAIN,
    configurationType: 'EntityToOrganizationTypes',
  });

  /**
   * Sets the extended attributes values in its state
   * @param newExtendedValues The new values of extended attributes
   */
  const handleExtendedAttributeValues = (
    newExtendedValues: ExtendedAttributeValues[]
  ) => {
    setExtendedVals(newExtendedValues);
  };

  /**
   * Used to determine if a section has a single form
   */
  const isSingleSection = useCallback((): boolean => {
    return (
      currentStep !== MULTIPLE_SECTION_INDEX && currentStep < CONFIRMATION_STEP
    );
  }, [currentStep]);

  /**
   * Used to determine if a section has multiple sub-sections
   */
  const isMultipleSection = useCallback((): boolean => {
    return currentStep === MULTIPLE_SECTION_INDEX;
  }, [currentStep]);

  /**
   * Used to determine if a single section is required
   */
  const isSingleSectionRequired = useCallback((): boolean => {
    if (!isSingleSection()) return false;
    const section = findSection();
    return section?.isRequired ?? true;
  }, [findSection, isSingleSection]);

  /**
   * Used to determine if a sub-section inside a multiple step, is required
   */
  const isMultipleSectionRequired = useCallback(
    (sectionName: string): boolean => {
      if (!isMultipleSection()) return false;
      const multSection = sectionsConfig.current.find(
        (section) =>
          section.name.toLowerCase() === sectionName.toLowerCase() &&
          section.step === MULTIPLE_SECTION_INDEX
      );
      return multSection?.isRequired ?? true;
    },
    [isMultipleSection]
  );

  /**
   * Controls whether the user wants to fill the form of an optional section
   * @param display Controls if section is displayed or not
   * @param name Name of the section, used in multiple-type sections
   */
  const showOptionalSection = (display: boolean, name: string) => {
    if (isMultipleSection()) {
      if (name === 'phoneNumbers') setShowPhoneOptional(display);
      else if (name === 'emailAddresses') setShowEmailOptional(display);
    } else {
      const section = findSection();
      if (section) {
        const idxSection = sectionsConfig.current.indexOf(section);
        const sectionUpdate: EntityStepsConfig = {
          ...section,
          showOptional: display,
        };
        sectionsConfig.current.splice(idxSection, 1, sectionUpdate);
      }
      setShowOptional(display);
    }
  };

  /**
   * Deletes an optional section if no data is included
   * @param data Data contained in the Entity form
   */
  const processOptionalSections = (data: EntityForm) => {
    if (data.phoneNumbers != undefined && data.phoneNumbers?.length === 0) {
      delete data.phoneNumbers;
    }
    if (data.emailAddresses != undefined && data.emailAddresses.length === 0) {
      delete data.emailAddresses;
    }
  };

  /**
   * Sets correctly the commence date if the section date is null
   * @param data Data contained in the Entity form
   */
  const processCommenceDates = (data: EntityForm) => {
    sectionNames.forEach((sectionName) => {
      const section = data[sectionName as EntitySectionNames];
      if (section) {
        section.forEach((sectionElement) => {
          if (sectionElement.commenceDate === null) {
            sectionElement.commenceDate = commenceDate;
          }
        });
      }
    });
  };

  /**
   * Validates if only one primary is selected for each section
   * @param sectionId Section name
   * @returns If the primary selected is valid or not
   */
  const checkPrimaries = useCallback(
    (sectionId: EntitySectionNames): boolean => {
      const sectionValues = getValues(sectionId);
      const VALID_PRIMARIES = 1;
      let isPrimaryCount = 0;
      if (sectionValues) {
        sectionValues.forEach((value) => {
          if ('isPrimary' in value && value.isPrimary === 'true')
            isPrimaryCount++;
        });
        if (isPrimaryCount === VALID_PRIMARIES) return true;
        else {
          dispatch(
            addMessage({
              type: MessageType.Error,
              message: t('pages.createEntity.message.primariesError'),
            })
          );
          return false;
        }
      }
      return true;
    },
    [getValues, dispatch, t]
  );

  /**
   * Validates commence date on the section to ensure it is not earlier than Entity commence date
   * @param sectionId Name of the section
   * @returns If the commence date of the section is valid or not
   */
  const checkCommenceDate = useCallback(
    (sectionId: EntitySectionNames): boolean => {
      const entityCommenceDate = getValues('others')?.[0].commenceDate;
      let isValid = true;
      const sectionValues = getValues(sectionId);
      if (sectionValues) {
        sectionValues.forEach((value) => {
          if (
            value.commenceDate &&
            isValid &&
            !isCommenceDateValid(value.commenceDate, entityCommenceDate)
          ) {
            isValid = false;
            dispatch(
              addMessage({
                type: MessageType.Error,
                message: t('pages.createEntity.message.commenceDateError'),
              })
            );
          }
        });
      }
      return isValid;
    },
    [dispatch, getValues, t]
  );

  /**
   * Checks the cease date is set before the commence date
   * @param sectionId Name of the section
   * @returns If the cease date is valid or not
   */
  const checkCeaseDate = useCallback(
    (sectionId: EntitySectionNames): boolean => {
      const entityCommenceDate = getValues('others')?.[0].commenceDate;
      let isValid = true;
      const sectionValues = getValues(sectionId);
      if (sectionValues) {
        sectionValues.forEach((value) => {
          if (
            value.ceaseDate &&
            isValid &&
            !isCeaseDateValid(
              value.commenceDate,
              value.ceaseDate,
              entityCommenceDate
            )
          ) {
            isValid = false;
            dispatch(
              addMessage({
                type: MessageType.Error,
                message: t('pages.createEntity.message.ceaseDateError'),
              })
            );
          }
        });
      }
      return isValid;
    },
    [dispatch, getValues, t]
  );

  /**
   * Validates all the extended attributes when required
   * @returns If extended attributes are valid or not
   */
  const checkExtendedAttributes = (): boolean => {
    if (currentStep === 0) {
      let invalidExists = false;
      const newExtendedVals: ExtendedAttributeValues[] = [];
      extendedVals.forEach((attr) => {
        if (attr.isRequired && attr.isRequired === true && attr.value === '') {
          invalidExists = true;
        }
        newExtendedVals.push({
          ...attr,
          isDirty: true,
        });
      });
      setExtendedVals(newExtendedVals);
      if (invalidExists) return false;
      return true;
    }
    return true;
  };

  /**
   * Displays the Organization Type dropdown at first step
   */
  const toggleOrganizationTypeDropdown = useCallback(() => {
    const organizationType = OtherFields.fields.find(
      (field) => field.fieldIdentifier === 'organizationType'
    );
    if (!organizationType) return;
    const found = entityOrganizationTypeData?.GetLookupConfiguration.find(
      (type) =>
        type.configurationName.toLowerCase() === entityType.toLowerCase()
    );
    if (found) {
      organizationType.hidden = false;
    } else {
      organizationType.hidden = true;
    }
  }, [entityOrganizationTypeData?.GetLookupConfiguration, entityType]);

  /**
   * Sets the validation result for the current step
   * @param isValid If step is valid or not
   */
  const setStepValidationResult = (isTriggered: boolean) => {
    const newValidated = validated;
    newValidated[currentStep] = currentDirty && isTriggered;
    setValidated(newValidated);
  };

  /**
   * Validates separately Email and Phone sections because they are two sections in one step
   */
  const validatingEmailAndPhoneFields = useCallback(
    (
      validator: (isTriggered: boolean) => void,
      phonevalidator: boolean,
      emailValidator: boolean
    ) => {
      const { phoneNumbers, emailAddresses } = getValues();
      if (phoneNumbers != undefined || emailAddresses != undefined) {
        if (phoneNumbers != undefined && phoneNumbers?.length > 0) {
          const phonePrimaryCheck = checkPrimaries('phoneNumbers');
          const phoneCommenceCheck = checkCommenceDate('phoneNumbers');
          const phoneCeaseCheck = checkCeaseDate('phoneNumbers');
          validator(
            phonevalidator &&
              phonePrimaryCheck &&
              phoneCommenceCheck &&
              phoneCeaseCheck
          );
        }

        if (emailAddresses != undefined && emailAddresses?.length > 0) {
          const emailPrimaryCheck = checkPrimaries('emailAddresses');
          const emailCommenceCheck = checkCommenceDate('emailAddresses');
          const emailCeaseCheck = checkCeaseDate('emailAddresses');
          validator(
            emailValidator &&
              emailPrimaryCheck &&
              emailCommenceCheck &&
              emailCeaseCheck
          );
        }

        if (
          emailAddresses != undefined &&
          emailAddresses?.length > 0 &&
          phoneNumbers != undefined &&
          phoneNumbers?.length > 0
        ) {
          const phonePrimaryCheck = checkPrimaries('phoneNumbers');
          const phoneCommenceCheck = checkCommenceDate('phoneNumbers');
          const phoneCeaseCheck = checkCeaseDate('phoneNumbers');
          const emailPrimaryCheck = checkPrimaries('emailAddresses');
          const emailCommenceCheck = checkCommenceDate('emailAddresses');
          const emailCeaseCheck = checkCeaseDate('emailAddresses');
          validator(
            phonevalidator &&
              emailValidator &&
              phonePrimaryCheck &&
              emailPrimaryCheck &&
              phoneCommenceCheck &&
              emailCommenceCheck &&
              phoneCeaseCheck &&
              emailCeaseCheck
          );
        }

        if (
          emailAddresses != undefined &&
          emailAddresses?.length === 0 &&
          phoneNumbers != undefined &&
          phoneNumbers?.length === 0
        ) {
          validator(true);
        }
      }
    },
    [getValues, checkPrimaries, checkCommenceDate, checkCeaseDate]
  );

  /**
   * Determines the validation scenario for the current step when 'Next Step' is clicked
   * Single sections: Required or optional scenarios are taken in account
   * Multiple sections (email & phone): Four different scenarios are taken in account
   */
  const stepValidationProcess = async () => {
    if (isMultipleSection()) {
      let validatePhone = true;
      let validateEmail = true;
      if (isMultipleSectionRequired('phoneNumbers') || showPhoneOptional) {
        validatePhone = await trigger('phoneNumbers');
      }
      if (isMultipleSectionRequired('emailAddresses') || showEmailOptional) {
        validateEmail = await trigger('emailAddresses');
      }
      validatingEmailAndPhoneFields(
        setStepValidationResult,
        validatePhone,
        validateEmail
      );
    } else if (isSingleSectionRequired() || showOptional) {
      const section = findSection();
      let primariesCheck = true;
      let commenceDateCheck = true;
      const sectionName = section?.name as keyof typeof getEntity;
      const validateRules = await trigger([sectionName]);
      if (sectionName !== 'others') {
        primariesCheck = checkPrimaries(sectionName);
        commenceDateCheck = checkCommenceDate(sectionName);
      }
      const ceaseDateCheck = checkCeaseDate(sectionName);
      const extendedAttrCheck = checkExtendedAttributes();
      setStepValidationResult(
        validateRules &&
          primariesCheck &&
          ceaseDateCheck &&
          commenceDateCheck &&
          extendedAttrCheck
      );
    } else if (currentStep === CONFIRMATION_STEP || !showOptional) {
      setStepValidationResult(true);
    }
  };

  /**
   * Saves the Entity and manages the response
   * @param accountData Object containing all Entity information
   * @returns Success or Error when creating the Entity
   */
  const saveEntity = async (entityData: Entity) => {
    return createEntity(
      {
        entity: entityData,
      },
      {
        onSuccess: async (data: CreateEntityMutation) => {
          navigate(`/${module}/entity/${data.CreateEntity?.id}`);
          dispatch(
            addMessage({
              type: MessageType.Success,
              message: t('pages.createEntity.message.success'),
            })
          );
          setSaveSuccessful(true);
        },
        onError: async (e: Error[] | unknown) => {
          let message: string = t('pages.createEntity.message.error');
          message = extractMeaningfulMessage(e, message);
          dispatch(
            addMessage({
              type: MessageType.Error,
              message: message,
            })
          );
          setSaveSuccessful(false);
        },
        onSettled: () => {
          setEntitySaving(false);
        },
      }
    );
  };

  /**
   * Submits the data to the service once saved by the user
   * @param data Data contained in the Entity form
   */
  const onSubmit = (data: EntityForm) => {
    setEntitySaving(true);
    processOptionalSections(data);
    processCommenceDates(data);
    const entityRequest = buildEntityRequest(
      data,
      entityType,
      layoutData?.GetConfigurationSchema.platformConfigurationId ?? '',
      extendedVals
    );
    saveEntity(entityRequest);
  };

  /**
   * Validates if the current section form was 'touched' or not
   */
  useEffect(() => {
    if (isMultipleSection()) {
      let isPhoneDirty = true;
      let isEmailDirty = true;
      if (isMultipleSectionRequired('phoneNumbers') || showPhoneOptional) {
        const { isDirty } = getFieldState('phoneNumbers', formState);
        isPhoneDirty = isDirty;
      }
      if (isMultipleSectionRequired('emailAddresses') || showEmailOptional) {
        const { isDirty } = getFieldState('emailAddresses', formState);
        isEmailDirty = isDirty;
      }
      validatingEmailAndPhoneFields(
        setCurrentDirty,
        isPhoneDirty,
        isEmailDirty
      );
    } else if (isSingleSectionRequired() || showOptional) {
      const section = findSection();
      const sectionName = section?.name as keyof typeof getEntity;
      const { isDirty } = getFieldState(sectionName, formState);
      setCurrentDirty(isDirty);
    } else if (currentStep === CONFIRMATION_STEP || !showOptional) {
      setCurrentDirty(true);
    }
  }, [
    currentStep,
    formState,
    findSection,
    getFieldState,
    getValues,
    isMultipleSection,
    isMultipleSectionRequired,
    isSingleSectionRequired,
    showOptional,
    showEmailOptional,
    showPhoneOptional,
    validatingEmailAndPhoneFields,
  ]);

  /**
   * Effectively refetch extended attributes when entity type is changed
   */
  useEffect(() => {
    refetchExtended();
  }, [entityType, refetchExtended]);

  /**
   * Effectively hides or displays Org Type depending the Entity type
   */
  useEffect(() => {
    toggleOrganizationTypeDropdown();
  }, [entityType, toggleOrganizationTypeDropdown]);

  /**
   * If the current section is optional, this sets a default behavior
   */
  useEffect(() => {
    const sectionFound = sectionsConfig.current.find(
      (section) => section.step === currentStep
    );
    setShowOptional(sectionFound?.showOptional ?? false);
  }, [currentStep, sectionsConfig]);

  /**
   * Provides the needed values to the Entity/Asset Context
   */
  const contextValue = useMemo(() => {
    const idTypeChangeHandler = (selectedIdType: string) => {
      setIdType(selectedIdType);
    };
    const commenceDateHandler = (selectedCommenceDate: string) => {
      setCommenceDate(selectedCommenceDate);
    };
    const entityTypeChangeHandler = (selectedEntityType: string) => {
      setEntityType(selectedEntityType);
    };

    return {
      selectedType: entityType,
      selectedIdType: idType,
      selectedCommenceDate: commenceDate,
      onTypeChange: entityTypeChangeHandler,
      onIdTypeChange: idTypeChangeHandler,
      onCommenceDateChange: commenceDateHandler,
      idValidationRules: getValidationRules(configuredIdTypes),
      idFormats: getIdFormats(configuredIdTypes),
      idMaxLengths: getMaskRules(configuredIdTypes),
    };
  }, [idType, entityType, commenceDate, configuredIdTypes]);

  return (
    <EntityManagementContext.Provider value={contextValue}>
      <HorizontalNonLinearStepper
        steps={sections}
        saveSuccessful={saveSuccessful}
        currentStep={currentStep}
        handleCurrentStep={(activeStep: number) => {
          setCurrentStep(activeStep);
        }}
        handleSave={handleSubmit(onSubmit)}
        handleFormReset={() => {
          reset();
        }}
        handleStepValidation={stepValidationProcess}
        validatedSteps={validated}
        saveBtnLabel={t('pages.createEntity.title')}
        resetBtnLabel={t('pages.entitySummary.actions.reset')}
        nonLinear={false}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          {((isLayoutFetching && entityType !== '') || isEntitySaving) && (
            <Loading />
          )}
          {singleSections.map((entitySection) => {
            if (entitySection.step === currentStep) {
              return (
                <SectionContainer
                  key={entitySection.section.sectionIdentifier}
                  entitySection={entitySection.section}
                  validatedSteps={validated}
                  currentStep={currentStep}
                  handleStepValidation={stepValidationProcess}
                  isRequired={isSingleSectionRequired()}
                  showOptional={showOptional}
                  handleShowOptional={showOptionalSection}
                  {...{
                    control: control as Control<FieldValues, unknown>,
                    register,
                    entity,
                    getValues,
                    setValue,
                    watch,
                  }}
                />
              );
            }
          })}
          {isMultipleSection() && (
            <>
              <SectionContainer
                entitySection={PhoneNumberFields}
                validatedSteps={validated}
                currentStep={currentStep}
                handleStepValidation={stepValidationProcess}
                isRequired={isMultipleSectionRequired('phoneNumbers')}
                showOptional={showPhoneOptional}
                handleShowOptional={showOptionalSection}
                {...{
                  control: control as Control<FieldValues, unknown>,
                  register,
                  entity,
                  getValues,
                  setValue,
                  watch,
                }}
              />
              <SectionContainer
                entitySection={EmailAddressFields}
                validatedSteps={validated}
                currentStep={currentStep}
                handleStepValidation={stepValidationProcess}
                isRequired={isMultipleSectionRequired('emailAddresses')}
                showOptional={showEmailOptional}
                handleShowOptional={showOptionalSection}
                {...{
                  control: control as Control<FieldValues, unknown>,
                  register,
                  entity,
                  getValues,
                  setValue,
                  watch,
                }}
              />
            </>
          )}
          {(isFetchingExtended || isFetchingSchemaGroups) && (
            <Box sx={{ marginTop: '15px' }}>
              <Loading fullScreen={false} />
            </Box>
          )}
          {currentStep === 0 && (
            <ExtendedAttributes
              extendedAttributes={
                extendedAttrData?.GetConfigurationSchemaById
                  .platformConfigurationInfo?.configurationSection[0].group[0]
                  .attribute ?? []
              }
              extendedValues={extendedVals}
              handleExtendedValues={handleExtendedAttributeValues}
            />
          )}
          {currentStep === CONFIRMATION_STEP && (
            <ConfirmationEntity
              data={getValues()}
              extendedData={extendedVals}
            />
          )}
        </form>
      </HorizontalNonLinearStepper>
    </EntityManagementContext.Provider>
  );
}

export default EntityLayout;
