import { useContext, useEffect } from 'react';

import Edit from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import DataTable from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import { ColumnDef } from '@tanstack/react-table';
import { setBooleanText } from 'common/entity';
import EntityManagementContext from 'components/contexts/EntityManagement';
import {
  boolAttributes,
  dateAttributes,
} from 'components/entityManagement/common/entityUtils';
import {
  EntityAddress,
  EntityEmailAddress,
  EntityIdentifier,
  EntityName,
  EntityForm,
  EntityPhoneNumber,
  EntitySectionNames,
} from 'types/entities';
import { Section } from 'types/forms';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getFormatDate, toStringDate } from 'utils/date-util';
import { deepCopy } from 'utils/deepCopy';
import { formatIdentifier } from 'utils/formatIdentifier';

type PropertyToMap = {
  isPrimary: string;
};

type SummaryTypes =
  | EntityAddress
  | EntityEmailAddress
  | EntityIdentifier
  | EntityName
  | EntityPhoneNumber;

interface Props {
  section: Section;
  data: EntityForm;
  watch?: UseFormReturn['watch'];
  getValues?: UseFormReturn['getValues'];
  setValue?: UseFormReturn['setValue'];
  indexNotDisplay: number;
  setActiveItem: (index: number) => void;
}

function SectionSummary({
  section,
  data,
  indexNotDisplay,
  setActiveItem,
  watch,
  setValue,
  getValues,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const ctx = useContext(EntityManagementContext);

  useEffect(() => {
    if (getValues && setValue && watch) {
      const subscription = watch((_, { name }) => {
        const [sectionIndex, index, inputName] = name?.split(
          '.'
        ) as Array<string>;
        if (inputName === 'isPrimary') {
          const allValues = getValues(sectionIndex as keyof EntityForm).map(
            (f: PropertyToMap, indexMap: number) => {
              if (indexMap.toString() !== index) f.isPrimary = 'false';
              return f;
            }
          ) as [];
          setValue(sectionIndex as keyof EntityForm, [...allValues]);
        }
      });
      return () => subscription.unsubscribe();
    }
  }, [watch, getValues, setValue]);

  const sectionHasMoreElements = (): boolean => {
    const currentSection =
      data[section.sectionIdentifier as EntitySectionNames];
    return currentSection ? currentSection.length > 1 : false;
  };

  const formatValues = (sectionValues: SummaryTypes): SummaryTypes => {
    const newSectionValues: SummaryTypes = deepCopy(sectionValues);
    for (const [attrKey, attrValue] of Object.entries(sectionValues)) {
      if (boolAttributes.includes(attrKey)) {
        newSectionValues[attrKey as keyof SummaryTypes] = setBooleanText(
          attrValue as string
        );
      }
      if (dateAttributes.includes(attrKey)) {
        if (attrKey === 'commenceDate') {
          newSectionValues[attrKey as keyof SummaryTypes] = attrValue
            ? toStringDate(getFormatDate(new Date(attrValue)))
            : toStringDate(getFormatDate(new Date(ctx.selectedCommenceDate)));
        } else {
          newSectionValues[attrKey as keyof SummaryTypes] = attrValue
            ? toStringDate(getFormatDate(new Date(attrValue)))
            : '-';
        }
      }
      if (section.sectionIdentifier === 'identifiers') {
        if (attrKey === 'value') {
          const idValue = attrValue ?? '';
          const typeValue = newSectionValues['IdType' as keyof SummaryTypes];
          newSectionValues[attrKey as keyof SummaryTypes] = ctx.idFormats
            ? formatIdentifier(idValue, ctx.idFormats, typeValue)
            : attrValue;
        }
      }
    }
    return newSectionValues;
  };

  const handleEditButton = (index: number): JSX.Element => {
    return (
      <IconButton
        id={section.sectionIdentifier + index}
        color="primary"
        aria-label="edit"
        onClick={() => setActiveItem(index)}
      >
        <Edit />
      </IconButton>
    );
  };

  const getSummaryColumns = (): ColumnDef<EntityForm>[] => {
    const nameSummaryColumns: ColumnDef<EntityForm>[] = [];
    const columnLimit = 10;
    section.fields.forEach((field, idx) => {
      if (idx < columnLimit) {
        const colHeader: ColumnDef<EntityForm> = {
          id: field.fieldIdentifier,
          accessorKey: field.fieldIdentifier,
          header: () => <HeaderColumnNext localization={field.label} />,
        };
        nameSummaryColumns.push(colHeader);
      }
    });
    nameSummaryColumns.push({
      id: 'edit',
      accessorKey: 'edit',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.entitySummary.actions.edit')}
        />
      ),
      cell: ({ getValue }) => handleEditButton(getValue() as number),
    });
    return nameSummaryColumns;
  };

  const getDisplayData = (): Record<string, unknown>[] => {
    const displayData: Record<string, unknown>[] = [];
    const currentSection =
      data[section.sectionIdentifier as EntitySectionNames];
    if (currentSection) {
      currentSection.forEach((element, idx) => {
        if (idx !== indexNotDisplay) {
          const newValues = formatValues(element as SummaryTypes);
          const elementValues = {
            ...newValues,
            edit: idx,
          };
          displayData.push(elementValues);
        }
      });
    }
    return displayData;
  };

  return sectionHasMoreElements() ? (
    <DataTable
      data={getDisplayData()}
      columns={getSummaryColumns() as ColumnDef<Record<string, unknown>>[]}
      enableGlobalFilter={false}
    />
  ) : (
    <></>
  );
}

export default SectionSummary;
