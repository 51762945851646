interface Props {
  children: React.ReactNode;
  onClick?: () => void;
}

const TransparentButton = ({ children, onClick }: Props): JSX.Element => {
  const buttonStyle = {
    backgroundColor: 'transparent',
    color: '#000',
    border: 'none',
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
  };

  return (
    <button onClick={onClick} style={buttonStyle}>
      {children}
    </button>
  );
};

export default TransparentButton;
