import {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
  useCallback,
} from 'react';
import {
  Grid,
  InputAdornment,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import {
  AdjustmentApplicationOption,
  AdjustmentOptionsResponse,
  Maybe,
} from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { Select } from '@revenue-solutions-inc/revxcoreui';
import { SelectType } from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import {
  Control,
  Controller,
  FieldValues,
  UseFormGetValues,
  UseFormSetValue,
} from 'react-hook-form';
import { formatCurrency } from 'common/helpers';
import ControlledAmountField from 'components/controls/ControlledAmountField';

interface CreateAdjustmentProps {
  adjustmentOptions: AdjustmentOptionsResponse | undefined;
  impactTypeFields: AdjustmentApplicationOption[];
  setImpactTypeFields: Dispatch<SetStateAction<AdjustmentApplicationOption[]>>;
  control: Control<FieldValues, unknown>;
  getValues: UseFormGetValues<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  buildRequiredError: (fieldName: string) => string;
}
function CreateAdjustment({
  adjustmentOptions,
  impactTypeFields,
  setImpactTypeFields,
  control,
  getValues,
  setValue,
  buildRequiredError,
}: CreateAdjustmentProps) {
  const { t } = useTranslation();
  const [adjustmentReasons, setAdjustmentReasons] = useState<SelectType[]>([]);
  const [reductionTypes, setReductionTypes] = useState<SelectType[]>([]);
  const [showPercent, setShowPercent] = useState<boolean>(false);
  const [applicationOptions, setApplicationOptions] = useState<SelectType[]>(
    []
  );
  const maxPercent = 100;

  const buildAdjustmentTypesList = (
    options: AdjustmentOptionsResponse | undefined
  ) => {
    const typeList: SelectType[] = [];
    options?.adjustmentTypes?.map((type) => {
      typeList?.push({
        key: type.name ? type.name : '',
        desc: type.description ?? '',
      });
    });
    return typeList;
  };

  const buildAdjustmentReasons = useCallback(
    (selectedAdjustmentType: string) => {
      const reasons = adjustmentOptions?.adjustmentTypes?.find(
        (adjustmentType) => adjustmentType.name === selectedAdjustmentType
      )?.reasons;

      const typeList: SelectType[] = [];
      reasons?.map((item) => {
        typeList?.push({
          key: item,
          desc: item,
        });
      });
      setAdjustmentReasons(typeList);
    },
    [adjustmentOptions?.adjustmentTypes]
  );

  const buildReductionTypes = useCallback(
    (selectedAdjustmentType: string) => {
      const typeList: SelectType[] = [];

      const reductions = adjustmentOptions?.adjustmentTypes?.find(
        (adjustmentType) => adjustmentType.name === selectedAdjustmentType
      )?.reductionTypes;

      reductions?.map((item) => {
        typeList?.push({
          key: item,
          desc: item,
        });
      });
      setReductionTypes(typeList);
    },
    [adjustmentOptions?.adjustmentTypes]
  );

  const buildApplicationOptions = useCallback(
    (selectedAdjustmentType: string) => {
      const adjustmentTypes = adjustmentOptions?.adjustmentTypes;
      const applicationTypes = adjustmentOptions?.adjustmentApplicationTypes;
      const typeList: SelectType[] = [];

      const options = adjustmentTypes?.find(
        (adjustmentType) => adjustmentType.name === selectedAdjustmentType
      )?.applicationOptions;

      options?.map((item) => {
        typeList?.push({
          key: item,
          desc: applicationTypes
            ? (applicationTypes?.find((field) => field.name === item)
                ?.description as string)
            : item,
        });
      });
      setApplicationOptions(typeList);
    },
    [
      adjustmentOptions?.adjustmentApplicationTypes,
      adjustmentOptions?.adjustmentTypes,
    ]
  );

  function buildImpactTypes(selectedApplicationOption: string) {
    const options = adjustmentOptions?.adjustmentApplicationTypes?.find(
      (item) => item.name === selectedApplicationOption
    )?.options;
    if (getValues('reductionType')?.toLowerCase() === 'percent') {
      impactTypeFields?.forEach((item) => setValue(item.name as string, 0));
    }
    setImpactTypeFields(options ?? []);
  }

  const setImpactPercentFields = () => {
    impactTypeFields?.forEach((item) => setValue(item.name as string, 0));
  };

  const setImpactAmountFields = () => {
    const options = adjustmentOptions?.adjustmentApplicationTypes?.find(
      (item) => item.name === getValues('applyAdjustmentTo')
    )?.options;
    impactTypeFields?.forEach((item) => {
      const amount = options?.find((field) => field.name === item.name)?.amount;
      setValue(item.name as string, amount);
    });
  };

  const onReductionTypeChange = (e: SelectChangeEvent<string | number>) => {
    const reductionValue = (e.target.value as string).toLowerCase();
    setShowPercent(reductionValue === 'percent' ? true : false);

    if (reductionValue === 'percent') {
      setImpactPercentFields();
    } else {
      setImpactAmountFields();
    }
  };

  function resetFields() {
    setImpactTypeFields([]);
    setValue('adjustmentReason', undefined);
    setValue('reductionType', undefined);
    setValue('applyAdjustmentTo', undefined);
  }

  useEffect(() => {
    if (getValues('adjustmentType')) {
      buildAdjustmentReasons(getValues('adjustmentType'));
      buildReductionTypes(getValues('adjustmentType'));
      buildApplicationOptions(getValues('adjustmentType'));
    }
  }, [
    buildAdjustmentReasons,
    buildApplicationOptions,
    buildReductionTypes,
    getValues,
  ]);

  function fullAmount(impactName: Maybe<string> | undefined) {
    const applyTo = getValues('applyAdjustmentTo');
    const options = adjustmentOptions?.adjustmentApplicationTypes?.find(
      (item) => item.name === applyTo
    )?.options;
    const amount = options?.find((item) => item.name === impactName)?.amount;
    return typeof amount === 'number'
      ? formatCurrency(amount as unknown as string)
      : '-';
  }

  function disableField(impactName: Maybe<string> | undefined) {
    const applyTo = getValues('applyAdjustmentTo');
    const options = adjustmentOptions?.adjustmentApplicationTypes?.find(
      (item) => item.name === applyTo
    )?.options;
    const amount = options?.find((item) => item.name === impactName)?.amount;
    return amount === 0 ? true : false;
  }

  useEffect(() => {
    impactTypeFields.forEach((item) =>
      setValue(item.name as string, item.amount)
    );
  }, [impactTypeFields, setValue]);

  const impactTypeControls = (
    <>
      {impactTypeFields.length > 0 && (
        <Grid item xs={12}>
          <Typography variant="h3">
            {t('pages.periodDetails.adjustments.impactTypes')}
          </Typography>
        </Grid>
      )}
      {impactTypeFields.map((field, index) => (
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          mb={3}
          key={field.name ? `${field.name}_container` : `${index}_container`}
        >
          <ControlledAmountField
            key={field.name ? field.name : `${index}_impactType`}
            control={control}
            label={showPercent ? `${field.name} % ` : `${field.name} Amount`}
            rules={{
              required: buildRequiredError(
                `${t('pages.periodDetails.transactions.amount')}`
              ),
              max: {
                value: showPercent ? maxPercent : field.amount,
                message: showPercent
                  ? `Cannot exceed ${maxPercent}%`
                  : `Cannot exceed ${fullAmount(field.name)}`,
              },
              pattern: /^-?(\d*\.{0,1}\d{0,2}$)/,
            }}
            name={field.name ?? ''}
            required
            sx={{ width: '100%' }}
            endAdornment={
              <InputAdornment position="end">
                {showPercent ? '%' : '$'}
              </InputAdornment>
            }
            disabled={disableField(field.name)}
          />
          <Typography variant="body1">
            {`Full Amount:  ${fullAmount(field.name)}`}
          </Typography>
        </Grid>
      ))}
    </>
  );

  return (
    <>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Controller
          control={control}
          name="adjustmentType"
          rules={{ required: true }}
          render={({
            field: { value, onChange },
            fieldState: { error: adjustmentTypeError },
          }) => {
            return (
              <Select
                required
                autowidth={false}
                options={buildAdjustmentTypesList(adjustmentOptions)}
                id="adjustmentType"
                data-testid="adjustmentType"
                label={t('pages.periodDetails.adjustments.adjustmentType')}
                onChange={(e) => {
                  const selectedAdjustmentType = e.target.value as string;
                  resetFields();
                  buildAdjustmentReasons(selectedAdjustmentType);
                  buildReductionTypes(selectedAdjustmentType);
                  buildApplicationOptions(selectedAdjustmentType);
                  onChange(e);
                }}
                fullWidth
                value={value}
                error={
                  adjustmentTypeError
                    ? buildRequiredError(
                        t('pages.periodDetails.adjustments.adjustmentType')
                      )
                    : ''
                }
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Controller
          control={control}
          name="adjustmentReason"
          rules={{ required: true }}
          render={({
            field: { value, onChange },
            fieldState: { error: adjustmentReasonError },
          }) => {
            return (
              <Select
                required
                autowidth={false}
                options={adjustmentReasons}
                id="adjustmentReason"
                data-testid="adjustmentReason"
                label={t('pages.periodDetails.adjustments.adjustmentReason')}
                onChange={onChange}
                fullWidth
                value={value}
                disabled={getValues('adjustmentType') === undefined}
                error={
                  adjustmentReasonError
                    ? buildRequiredError(
                        t('pages.periodDetails.adjustments.adjustmentReason')
                      )
                    : ''
                }
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Controller
          control={control}
          name="applyAdjustmentTo"
          rules={{ required: true }}
          render={({
            field: { value, onChange },
            fieldState: { error: applicationOptionsError },
          }) => {
            return (
              <Select
                required
                autowidth={false}
                options={applicationOptions}
                id="applyAdjustmentTo"
                data-testid="applyAdjustmentTo"
                label={t('pages.periodDetails.adjustments.applyAdjustmentTo')}
                onChange={(e) => {
                  buildImpactTypes(e.target.value as string);
                  onChange(e);
                }}
                fullWidth
                value={value}
                disabled={getValues('adjustmentType') === undefined}
                error={
                  applicationOptionsError
                    ? buildRequiredError(
                        t('pages.periodDetails.adjustments.applyAdjustmentTo')
                      )
                    : ''
                }
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Controller
          control={control}
          name="reductionType"
          rules={{ required: true }}
          render={({
            field: { value, onChange },
            fieldState: { error: reductionTypeError },
          }) => {
            return (
              <Select
                required
                autowidth={false}
                options={reductionTypes}
                id="reductionType"
                data-testid="reductionType"
                label={t('pages.periodDetails.adjustments.reductionType')}
                onChange={(e) => {
                  onReductionTypeChange(e);
                  onChange(e);
                }}
                fullWidth
                value={value}
                disabled={getValues('applyAdjustmentTo') === undefined}
                error={
                  reductionTypeError
                    ? buildRequiredError(
                        t('pages.periodDetails.adjustments.reductionType')
                      )
                    : ''
                }
              />
            );
          }}
        />
      </Grid>
      {getValues('reductionType') && impactTypeControls}
    </>
  );
}
export default CreateAdjustment;
