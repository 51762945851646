import { useEffect, useState } from 'react';

export interface MaskReturn {
  unMasked: string;
  maskErrors: string[];
  handleMaskChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => void;
  maskedValues: (string | null)[];
  inputValues: (string | null)[];
  hasErrors: boolean;
}

function useMultiMaskInput(
  newMask: string,
  helperText: string,
  regexValue: RegExp,
  inputValues: (string | null)[]
): MaskReturn {
  const [localInputValues, setInputValues] =
    useState<(string | null)[]>(inputValues);
  const [unMasked, setunMasked] = useState('');
  const [maskErrors, setMaskErrors] = useState<string[]>(['']);
  const [maskedValues, setMaskedValues] =
    useState<(string | null)[]>(inputValues);
  const [hasErrors, setHasErrors] = useState<boolean>(false);
  const inputRegex = new RegExp(regexValue);

  function maskInput(value: string) {
    const map = new Map();
    const mask = String(newMask);
    const maskedValue = String(value).replace(/[^\da-zA-Z]/g, '');
    const result: string[] = [];

    map.set('9', /\d/);
    map.set('A', /[\da-zA-Z]/);
    map.set('S', /[a-zA-Z]/);

    let start = 0;
    mask.split('').forEach((char) => {
      if (maskedValue.length > start) {
        if (map.has(char)) {
          if (map.get(char).test(maskedValue.charAt(start))) {
            result.push(maskedValue.charAt(start));
            start += 1;
          }
        } else {
          result.push(char);
        }
      }
    });
    return result.join('');
  }

  useEffect(() => {
    const errors = [...maskErrors];

    setInputValues(inputValues);
    setMaskedValues(
      localInputValues.map((item) => {
        return maskInput(item ? item : '');
      })
    );

    inputValues.forEach((item, index) => {
      if (item) {
        if (!inputRegex.test(item) && item.length > 0) {
          errors[index] = helperText;
        } else if (item.length === 0) {
          errors[index] = '';
        } else {
          errors[index] = '';
        }
      }
    });

    setMaskErrors(errors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValues]);

  useEffect(() => {
    const hasError = maskErrors.some((item) => item !== '');
    setHasErrors(hasError);
  }, [maskErrors, hasErrors]);

  return {
    maskedValues,
    unMasked,
    inputValues,
    maskErrors,
    hasErrors,
    handleMaskChange(
      event: React.ChangeEvent<HTMLInputElement>,
      index: number
    ) {
      const { value } = event.target;
      const values = [...inputValues];
      if (newMask.length > 0) {
        event.target.value = maskInput(value);
        setunMasked(value.replace(/\D/g, ''));
        values[index] = event.target.value;
        setInputValues(values);
      } else {
        setInputValues(values);
      }
    },
  };
}
export default useMultiMaskInput;
