import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  UserPreferencesResponse,
  FavoritesResponse,
  UserRolesResponse,
} from 'generated/graphql';
import { RootState } from 'redux/store';

export interface UserState {
  uniqueId?: string;
  globalSearchPhrase?: string;
  globalSearchRepeat?: boolean;
  module: string;
  userPreferences: UserPreferencesResponse;
  roles?: UserRolesResponse[];
}

const initialState: UserState = {
  uniqueId: '',
  globalSearchPhrase: '',
  globalSearchRepeat: false,
  module: 'revenue',
  userPreferences: { preferredModule: 'revenue', favorites: [], userName: '' },
  roles: [],
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setFavorites: (state, action: PayloadAction<FavoritesResponse[]>) => {
      state.userPreferences.favorites = action.payload;
    },
    setUniqueId: (state, action: PayloadAction<string>) => {
      state.uniqueId = action.payload;
    },
    setGlobalSearchPhrase: (state, action: PayloadAction<string>) => {
      state.globalSearchPhrase = action.payload;
    },
    setGlobalSearchRepeat: (state, action: PayloadAction<boolean>) => {
      state.globalSearchRepeat = action.payload;
    },
    setUserPreferences: (
      state,
      action: PayloadAction<UserPreferencesResponse>
    ) => {
      state.userPreferences = action.payload;
    },
    setPreferredModule: (state, action: PayloadAction<string>) => {
      state.userPreferences.preferredModule = action.payload;
    },
    setUserName: (state, action: PayloadAction<string>) => {
      state.userPreferences.userName = action.payload;
    },
    setModule: (state, action: PayloadAction<string>) => {
      state.module = action.payload;
    },
    setRoles: (state, action: PayloadAction<UserRolesResponse[]>) => {
      state.roles = action.payload;
    },
    clearUserState: () => {
      return { ...initialState };
    },
  },
});

export const {
  setFavorites,
  setGlobalSearchPhrase,
  setGlobalSearchRepeat,
  setUniqueId,
  setUserPreferences,
  setModule,
  setPreferredModule,
  setUserName,
  setRoles,
  clearUserState,
} = userSlice.actions;

export const module = (state: RootState): string => {
  return state.user.module;
};

export default userSlice.reducer;
