import {
  Dispatch,
  Fragment,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Grid, IconButton } from '@mui/material';
import Input from '@revenue-solutions-inc/revxcoreui/material/controls/Input';
import Select, {
  SelectType,
} from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import Checkbox from '@revenue-solutions-inc/revxcoreui/material/controls/Checkbox';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'redux/hooks';
import { useTranslation } from 'react-i18next';
import {
  ScheduledTasks,
  StageScheduleInput,
  taskStagesScheduleDefault,
} from 'types/channels';
import { addMessage } from 'redux/messageSlice';
import {
  GetTaskSinkObjectQuery,
  useGetConfigurationQuery,
  ConfigurationResponse,
  GetConfigurationQuery,
} from 'generated/graphql';
import {
  Control,
  Controller,
  UseFormGetValues,
  UseFormSetValue,
  useFieldArray,
  UseFormWatch,
  useWatch,
} from 'react-hook-form';
import Loading from 'components/Loading';
import useMaskInput from 'hooks/useMaskInput';
import {
  Button,
  MessageType,
  TextArea,
  Card,
} from '@revenue-solutions-inc/revxcoreui';
import { isEqual } from 'lodash';
import { Parameters } from '../ScheduledTasks/ScheduledTasks';

//TODO: Remove once logic is figured out for editing Channel Name
enum ActionType {
  EDIT = 'edit',
  CREATE = 'create',
}

interface Props {
  setTaskStagesValid: React.Dispatch<React.SetStateAction<boolean>>;
  control: Control<ScheduledTasks, unknown>;
  watch: UseFormWatch<ScheduledTasks>;
  getValues: UseFormGetValues<ScheduledTasks>;
  setValue: UseFormSetValue<ScheduledTasks>;
  taskSinkObject: GetTaskSinkObjectQuery | undefined;
  setRunEdit: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
  setCurrentData: Dispatch<SetStateAction<ConfigurationResponse[]>>;
  setData: React.Dispatch<
    React.SetStateAction<GetConfigurationQuery | undefined | null>
  >;
  currentData: ConfigurationResponse[] | undefined;
  setMasterList: Dispatch<SetStateAction<ConfigurationResponse[]>>;
  operations: string[];
  arrayTest: Parameters[];
  setParameterValues: Dispatch<SetStateAction<ConfigurationResponse[][]>>;
  parameterValues: ConfigurationResponse[][];
}

function ScheduledTasksStages({
  setTaskStagesValid,
  control,
  watch,
  getValues,
  arrayTest,
  currentData,
  setMasterList,
  setData,
  taskSinkObject,
  setRunEdit,
  isLoading,
  setCurrentData,
  parameterValues,
  setValue,
  setParameterValues,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { action } = useParams() as {
    action: string;
  };
  const stagesValue = getValues(`ScheduleTask.Stages`);
  const [sinkOptions, setSinkOptions] = useState<SelectType[][]>([]);
  const [operationOptions, setOperationOptions] = useState<SelectType[][]>([]);
  const [open, setOpen] = useState(false);
  const [deleteStage, setDeleteStage] = useState<boolean>(false);
  const [toDelete, setToDelete] = useState<number | null>(null);
  const [editTaskCheck, setEditTaskCheck] = useState<boolean>(false);
  const [stageToDelete, setStageToDelete] = useState('');

  const dialogStyles = {
    '.MuiPaper-root': {
      borderRadius: '8px',
      width: '450px',
    },
    '& .MuiDialogTitle-root': {
      borderRadius: '8px',
      fontSize: '16px',
    },
    '& .MuiTypography-root .MuiTypography-h6 .MuiDialogTitle-root > h2': {
      color: 'black.main',
    },
    '& .MuiDialogActions-root': {
      textAlign: 'center',
      display: 'inline-block',
    },
    '& .MuiDialogContent-root': {
      fontSize: '16px',
      fontWeight: 'normal',
    },
  };

  const updateOptions = (
    index: number,
    newSinkOptions: SelectType[],
    newOperationOptions: SelectType[]
  ) => {
    setSinkOptions((oldSinkOptions) => {
      const newSinkOptionsState = { ...oldSinkOptions };

      if (newSinkOptions !== undefined) {
        newSinkOptionsState[index] = newSinkOptions;
      }

      return newSinkOptionsState;
    });

    setOperationOptions((oldOperationOptions) => {
      const newOperationOptionsState = { ...oldOperationOptions };

      if (newOperationOptions !== undefined) {
        newOperationOptionsState[index] = newOperationOptions;
      }

      return newOperationOptionsState;
    });
  };

  const removeStage = (index: number) => {
    setSinkOptions((oldSinkOptions) => {
      const newSinkOptions = { ...oldSinkOptions };
      delete newSinkOptions[index];
      return newSinkOptions;
    });

    setOperationOptions((oldOperationOptions) => {
      const newOperationOptions = { ...oldOperationOptions };
      delete newOperationOptions[index];
      return newOperationOptions;
    });
  };

  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const executionOrderMask = useMaskInput('99', '', /^[0-9]{2}$/);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'ScheduleTask.Stages',
  });

  const ScheduleTaskName = useWatch({
    control,
    name: 'ScheduleTask.ScheduleTaskName',
  });

  const stagesInput = useWatch({
    control,
    name: 'ScheduleTask.Stages',
  });
  const initialLoad = useRef(true);
  const [initialOperationName, setInitialOperationName] = useState<
    string | null
  >(null);

  useEffect(() => {
    if (action === ActionType.EDIT && initialLoad.current) {
      initialLoad.current = false;

      const lastOperationName = [...stagesValue]
        .reverse()
        .find((stage) => stage.OperationName !== '')?.OperationName;

      if (lastOperationName) {
        setInitialOperationName(lastOperationName);
      }
    }

    if (Array.isArray(stagesValue) && stagesValue.length > 0) {
      setSelectedIndex(stagesValue.length - 1);
    }
  }, [action, getValues, stagesValue]);

  const operationName =
    initialLoad.current && initialOperationName
      ? initialOperationName
      : selectedIndex !== null
      ? stagesInput[selectedIndex]?.OperationName
      : '';

  const {
    data: newData,
    error,
    isLoading: configuationLoading,
  } = useGetConfigurationQuery(
    {
      configurationName: operationName,
      configurationDomain: 7,
      configurationModule: 3,
    },
    {
      enabled: !!operationName,
    }
  );

  const configurationsValues: ConfigurationResponse[] | undefined = currentData;
  useEffect(() => {
    if (newData && selectedIndex !== null) {
      if (typeof currentData?.[selectedIndex] === 'undefined' && !deleteStage) {
        configurationsValues?.push(
          newData.getConfiguration as unknown as ConfigurationResponse
        );
        const masterList = JSON.parse(JSON.stringify(configurationsValues));
        setMasterList(masterList);
        setCurrentData(
          configurationsValues as unknown as ConfigurationResponse[]
        );
      } else if (deleteStage) {
        setCurrentData((prevState) => {
          return prevState.filter((item) => item !== prevState[selectedIndex]);
        });
        setMasterList((prevState) => {
          return prevState.filter((item) => item !== prevState[selectedIndex]);
        });
        setDeleteStage(false);
      } else if (
        !isEqual(newData.getConfiguration, currentData?.[selectedIndex])
      ) {
        const array = [...(currentData as unknown as ConfigurationResponse[])];
        array[selectedIndex] =
          newData.getConfiguration as unknown as ConfigurationResponse;
        setCurrentData(array);
      }
    }
  }, [
    currentData,
    deleteStage,
    newData,
    selectedIndex,
    setCurrentData,
    setMasterList,
    configurationsValues,
  ]);

  const checkExecutionOrderExists = (
    currentExecutionOrder: number,
    currentIndex: number
  ) => {
    return stagesInput?.some(
      (stage, stageIndex) =>
        stage.ExecutionOrder === currentExecutionOrder &&
        stageIndex !== currentIndex
    );
  };

  const checkStageNameExists = (
    currentStageName: string,
    currentIndex: number
  ) => {
    if (currentStageName !== '') {
      return stagesInput?.some(
        (stage, stageIndex) =>
          stage.StageName === currentStageName && stageIndex !== currentIndex
      );
    }
    return false;
  };

  const pipelineNameMask = useMaskInput(
    '',
    t('pages.manageChannel.channelScheduleNameMask'),
    /^[A-Za-z0-9\s_-]*$/,
    ScheduleTaskName ?? ''
  );

  const handleHelperTextChange = () => {
    return pipelineNameMask.maskError;
  };

  const previousOperationName = useRef<string>('');

  const validateRequiredFields = () => {
    let isValid = true;

    if (!stagesInput || stagesInput.length === 0) {
      isValid = false;
    }

    const stageNameSet = new Set<string>();
    const executionOrderSet = new Set<number>();

    stagesInput?.forEach((value: StageScheduleInput) => {
      if (
        error !== null ||
        configuationLoading ||
        value.StageName === '' ||
        value.StageDisplayName === '' ||
        value.DomainName === '' ||
        value.SinkObjectId === '' ||
        value.OperationName === '' ||
        value.ExecutionOrder < 1
      ) {
        isValid = false;
      }

      if (stageNameSet.has(value.StageName)) {
        isValid = false;
      } else {
        stageNameSet.add(value.StageName);
      }
      if (executionOrderSet.has(value.ExecutionOrder)) {
        isValid = false;
      } else {
        executionOrderSet.add(value.ExecutionOrder);
      }
    });

    if (
      error === null &&
      !configuationLoading &&
      isValid &&
      previousOperationName.current !== ''
    ) {
      dispatch(
        addMessage({
          type: MessageType.Success,
          message: t('pages.manageChannel.parametersLoaded'),
        })
      );
      previousOperationName.current = '';
    }
    if (error !== null && !configuationLoading) {
      dispatch(
        addMessage({
          type: MessageType.Error,
          message: t('pages.manageChannel.parametersError'),
        })
      );
    }
    setTaskStagesValid(isValid);
  };

  const domainNames: SelectType[] = taskSinkObject
    ? taskSinkObject?.GetTaskSinkObject.map((task) => {
        return {
          key: task.DomainName,
          desc: task.DomainName,
        } as unknown as SelectType;
      })
    : ([{ key: 'empty' }] as unknown as SelectType[]);

  const SinkObjectIds = (index: number) => {
    let stages: StageScheduleInput[];
    if (stagesValue[index]?.SinkObjectName !== '') {
      stages = getValues('ScheduleTask.Stages');
    } else {
      stages = watch('ScheduleTask.Stages');
    }
    const values = taskSinkObject?.GetTaskSinkObject.findIndex(function (task) {
      return task.DomainName === stages[index]?.DomainName;
    });
    let valueIndex;

    if (values !== -1) {
      if (stagesInput && stagesInput[index]?.SinkObjectName !== '')
        valueIndex = taskSinkObject?.GetTaskSinkObject[
          values as unknown as number
        ].SchedularSinkDto.findIndex(function (task) {
          return task.SinkObjectName === stagesInput[index]?.SinkObjectName;
        });
      const sinkValues = taskSinkObject?.GetTaskSinkObject[
        values as unknown as number
      ]?.SchedularSinkDto.map((task) => {
        return {
          key: task.SinkObjectName,
          desc: task.SinkObjectName,
        } as unknown as SelectType;
      });
      updateOptions(
        index,
        sinkValues as unknown as SelectType[],
        sinkOptions[index]
      );
    }
    if (stagesInput && stagesInput[index]?.SinkObjectName !== '') {
      const testValues = taskSinkObject?.GetTaskSinkObject[
        values as unknown as number
      ]?.SchedularSinkDto[
        valueIndex as unknown as number
      ]?.SchedularOperationDto.map((task) => {
        return {
          key: task.OperationName,
          desc: task.OperationName,
        } as unknown as SelectType;
      });

      updateOptions(
        index,
        sinkOptions[index],
        testValues as unknown as SelectType[]
      );

      const newSinkObjectId = taskSinkObject?.GetTaskSinkObject[
        values as unknown as number
      ].SchedularSinkDto[valueIndex as unknown as number]
        .SinkObjectId as unknown as string;

      if (
        getValues(`ScheduleTask.Stages.${index}.SinkObjectId`) !==
        newSinkObjectId
      ) {
        setValue(`ScheduleTask.Stages.${index}.SinkObjectId`, newSinkObjectId);
      }
    }
    if (
      stagesInput &&
      stagesInput[index]?.OperationName !== '' &&
      stagesInput[index]?.SinkObjectName !== ''
    ) {
      const operationIndex = taskSinkObject?.GetTaskSinkObject[
        values as unknown as number
      ].SchedularSinkDto[
        valueIndex as unknown as number
      ].SchedularOperationDto.findIndex(function (task) {
        return task.OperationName === stagesInput[index]?.OperationName;
      });

      const newOperationId = taskSinkObject?.GetTaskSinkObject[
        values as unknown as number
      ].SchedularSinkDto[valueIndex as unknown as number].SchedularOperationDto[
        operationIndex as unknown as number
      ].OperationId as unknown as string;

      if (
        getValues(`ScheduleTask.Stages.${index}.OperationId`) !== newOperationId
      ) {
        setValue(`ScheduleTask.Stages.${index}.OperationId`, newOperationId);
      }
      const newOperationType = taskSinkObject?.GetTaskSinkObject[
        values as unknown as number
      ].SchedularSinkDto[valueIndex as unknown as number].SchedularOperationDto[
        operationIndex as unknown as number
      ].OperationType as unknown as string;

      if (
        getValues(`ScheduleTask.Stages.${index}.OperationType`) !==
        newOperationType
      ) {
        setValue(
          `ScheduleTask.Stages.${index}.OperationType`,
          newOperationType
        );
      }
    }
  };
  const prevStagesInputRef = useRef<StageScheduleInput[] | undefined>();
  useEffect(() => {
    if (
      selectedIndex !== null &&
      selectedIndex >= 0 &&
      ((action === ActionType.EDIT && taskSinkObject !== undefined) ||
        (action === ActionType.CREATE && taskSinkObject)) &&
      !isEqual(prevStagesInputRef.current, stagesInput)
    ) {
      prevStagesInputRef.current = stagesInput;
      stagesInput?.forEach((stage, index) => {
        if (index === selectedIndex && stage.DomainName !== '') {
          SinkObjectIds(selectedIndex as number);
        }
      });
    }
    if (
      stagesValue?.length > 0 &&
      taskSinkObject !== undefined &&
      stagesValue.length === stagesInput.length
    ) {
      stagesValue.forEach((stage, index) => {
        if (index >= 0 && stagesInput[index].DomainName !== '') {
          SinkObjectIds(index);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoading,
    taskSinkObject,
    action,
    stagesInput,
    selectedIndex,
    stagesValue,
  ]);

  useEffect(() => {
    if (!editTaskCheck && action === ActionType.EDIT && arrayTest.length > 0) {
      const values = arrayTest.map((item) => {
        return item.data.getConfiguration;
      });

      setMasterList(JSON.parse(JSON.stringify(values)));
      setCurrentData(values as unknown as ConfigurationResponse[]);

      const valuesParameters = parameterValues.map(function (val) {
        return val === null
          ? (values as unknown as ConfigurationResponse[])
          : val;
      });
      setParameterValues(valuesParameters);
    }
    setEditTaskCheck(true);
  }, [
    action,
    arrayTest,
    editTaskCheck,
    parameterValues,
    setCurrentData,
    setMasterList,
    setParameterValues,
  ]);

  useEffect(() => {
    validateRequiredFields();
  });
  useEffect(() => {
    setData(newData);
  }, [operationName, newData, setData]);

  useEffect(() => {
    fields.forEach((field, index) => {
      const newExecutionOrder = index + 1;
      if (
        getValues(`ScheduleTask.Stages.${index}.ExecutionOrder`) !==
        newExecutionOrder
      ) {
        setValue(
          `ScheduleTask.Stages.${index}.ExecutionOrder`,
          newExecutionOrder
        );
      }
    });
  }, [fields, setValue, getValues]);

  return (!isLoading && action === ActionType.CREATE) ||
    (!isLoading &&
      action === ActionType.EDIT &&
      taskSinkObject !== undefined) ? (
    <>
      <Grid container spacing={3} mt={1}>
        <Card
          sx={{ minWidth: 275, maxWidth: 400, backgroundColor: 'transparent' }}
        >
          {fields.map((item: Record<'id', string>, index: number) => (
            <Fragment key={item.id}>
              <Grid
                mb={1}
                sx={{
                  backgroundColor: '#fff',
                  marginLeft: '20px',
                  marginRight: '10px',
                }}
              >
                <Grid
                  pt={0.5}
                  pl={0.5}
                  sx={{
                    marginLeft: '10px',
                    marginRight: '10px',
                  }}
                >
                  <Grid item xs={12} mt={1.5}>
                    <Controller
                      control={control}
                      rules={{
                        required: true,
                      }}
                      name={`ScheduleTask.Stages.${index}.StageName`}
                      render={({ field: { value, onChange } }) => (
                        <Input
                          required
                          id="stageName"
                          label={t('pages.manageChannel.stageTaskName')}
                          value={value}
                          sx={{ width: '100%', maxWidth: '20em' }}
                          helperText={
                            checkStageNameExists(value, index)
                              ? t('pages.manageChannel.stageTaskNameExists')
                              : handleHelperTextChange()
                          }
                          onChange={(e) => {
                            onChange(e.target.value);
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} mt={1.5}>
                    <Controller
                      control={control}
                      rules={{
                        required: true,
                      }}
                      name={`ScheduleTask.Stages.${index}.StageDisplayName`}
                      render={({ field: { value, onChange } }) => {
                        return (
                          <Input
                            required
                            id="scheduleDisplayName"
                            sx={{ width: '100%', maxWidth: '20em' }}
                            label={t(
                              'pages.manageChannel.stageTaskDisplayName'
                            )}
                            value={value}
                            onChange={(e) => {
                              onChange(e.target.value);
                            }}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} mt={1.5}>
                    <Controller
                      control={control}
                      name={`ScheduleTask.Stages.${index}.StageDescription`}
                      render={({ field: { value, onChange } }) => (
                        <TextArea
                          id="scheduleDescription"
                          multiline
                          sx={{ width: '100%', maxWidth: '20em' }}
                          label={t('pages.manageChannel.stageTaskDescription')}
                          value={value}
                          onChange={(e) => {
                            onChange(e.target.value);
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} mt={1.5}>
                    <Controller
                      control={control}
                      rules={{
                        required: true,
                        validate: (value) => {
                          return (
                            value >= 1 &&
                            checkExecutionOrderExists(value, index) === false
                          );
                        },
                      }}
                      name={`ScheduleTask.Stages.${index}.ExecutionOrder`}
                      render={({ field: { value, onChange } }) => {
                        return (
                          <Input
                            id="ExecutionOrder"
                            label={t(
                              'pages.manageChannel.stageTaskExecutionOrder'
                            )}
                            value={value === null ? '' : value.toString()}
                            sx={{ width: '100%', maxWidth: '20em' }}
                            helperText={
                              value < 1
                                ? t('pages.manageChannel.executionOrderTooLow')
                                : checkExecutionOrderExists(value, index)
                                ? t('pages.manageChannel.executionOrderExists')
                                : ''
                            }
                            onChange={(e) => {
                              executionOrderMask.handleMaskChange(e);
                              onChange(
                                e.target.value === ''
                                  ? null
                                  : parseInt(e.target.value)
                              );
                            }}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} mt={1.5}>
                    <Controller
                      control={control}
                      rules={{
                        required: true,
                      }}
                      name={`ScheduleTask.Stages.${index}.DomainName`}
                      render={({ field: { value, onChange } }) => {
                        return (
                          <Select
                            required
                            id={`scheduleDomainName-${index}`}
                            sx={{ width: '100%', maxWidth: '20em' }}
                            label={t('pages.manageChannel.domainName')}
                            value={value}
                            options={domainNames}
                            onChange={(e) => {
                              if (e.target.value === '') {
                                setRunEdit(false);
                              }
                              setValue(
                                `ScheduleTask.Stages.${index}.SinkObjectName`,
                                ''
                              );
                              setValue(
                                `ScheduleTask.Stages.${index}.OperationName`,
                                ''
                              );
                              onChange(e.target.value);
                              setSelectedIndex(index);
                            }}
                          />
                        );
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} mt={1.5}>
                    <Controller
                      control={control}
                      rules={{
                        required: true,
                      }}
                      name={`ScheduleTask.Stages.${index}.SinkObjectName`}
                      render={({ field: { value, onChange } }) => {
                        return (
                          <Select
                            required
                            id={`scheduleSinkObjectName-${index}`}
                            disabled={
                              getValues(
                                `ScheduleTask.Stages.${index}.DomainName`
                              ) === ''
                            }
                            sx={{ width: '100%', maxWidth: '20em' }}
                            label={t('pages.manageChannel.taskGroup')}
                            value={value}
                            options={sinkOptions[index] || []}
                            onChange={(e) => {
                              setValue(
                                `ScheduleTask.Stages.${index}.OperationName`,
                                ''
                              );
                              onChange(e.target.value);
                              setSelectedIndex(index);
                            }}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} mt={1.5}>
                    <Controller
                      control={control}
                      rules={{
                        required: true,
                      }}
                      name={`ScheduleTask.Stages.${index}.OperationName`}
                      render={({ field: { value, onChange } }) => {
                        return (
                          <Select
                            required
                            id={`scheduleOperationName-${index}`}
                            disabled={
                              getValues(
                                `ScheduleTask.Stages.${index}.SinkObjectName`
                              ) === ''
                            }
                            sx={{ width: '100%', maxWidth: '20em' }}
                            label={t('pages.manageChannel.task')}
                            value={value}
                            options={operationOptions[index] || []}
                            onChange={(e) => {
                              if (
                                configuationLoading &&
                                e.target.value !== '' &&
                                previousOperationName.current !==
                                  e.target.value.toString()
                              ) {
                                previousOperationName.current =
                                  e.target.value.toString();
                                dispatch(
                                  addMessage({
                                    type: MessageType.Info,
                                    message: t(
                                      'pages.manageChannel.parametersLoading'
                                    ),
                                  })
                                );
                              }
                              onChange(e.target.value);
                              setSelectedIndex(index);
                            }}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} md={10.5} pl={0.5} mt={1} mb={0}>
                      <Controller
                        control={control}
                        rules={{
                          required: true,
                        }}
                        name={`ScheduleTask.Stages.${index}.ContinueOnStageFailure`}
                        render={({ field: { value, onChange } }) => (
                          <Checkbox
                            id="continueOnStageFailure"
                            label={t('pages.manageChannel.stageTaskOnFailure')}
                            checked={value}
                            onChange={onChange}
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={1.5}
                      lg={1.5}
                      mt={0.5}
                      ml={0}
                      sx={{
                        marginLeft: '0%',
                      }}
                    >
                      <IconButton
                        onClick={() => {
                          setSelectedIndex(index);
                          const stageName = getValues(
                            `ScheduleTask.Stages.${index}.StageName`
                          );
                          setStageToDelete(stageName);
                          setToDelete(index);
                          setOpen(true);
                        }}
                        aria-label="delete"
                        color="default"
                        size="medium"
                      >
                        <CancelRoundedIcon fontSize="medium" />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Fragment>
          ))}
        </Card>
        <Grid item xs={12} pt={2} mb={2}>
          <Button
            id="addStage"
            disabled={configuationLoading && stagesInput.length > 0}
            onClick={() => {
              let newExecutionOrder;

              // We check if there are stages in stagesInput
              if (stagesInput.length > 0) {
                const lastExecutionOrder =
                  stagesInput[stagesInput.length - 1]?.ExecutionOrder;
                newExecutionOrder = lastExecutionOrder
                  ? lastExecutionOrder + 1
                  : 2;
              } else {
                // If there are no stages, we reset the count to 1
                newExecutionOrder = 1;
              }

              const newStage = {
                ...taskStagesScheduleDefault,
                ExecutionOrder: newExecutionOrder,
              };

              append(newStage);
            }}
          >
            {t('pages.manageChannel.stageTaskAdd')}
          </Button>
        </Grid>
        <Dialog
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={dialogStyles}
        >
          <DialogTitle id="alert-dialog-title">
            <Box display="flex" alignItems="center" color="black.light">
              {t('pages.manageChannel.stageTaskRemoveWarning')}
            </Box>
            <Box
              display="flex"
              alignItems="center"
              color="black.main"
              fontSize="20px"
              fontWeight="700"
            >
              {`${stageToDelete}`}
            </Box>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              color="black.light"
              fontSize="16px"
            >
              {`${t('pages.manageChannel.stageTaskRemoveFinalWarning')}`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{
                backgroundColor: 'grey3.light',
                minWidth: '64px',
                paddingLeft: '20px',
                paddingRight: '20px',
                color: 'black.light',
                '&:hover': {
                  backgroundColor: 'grey3.light',
                },
              }}
              onClick={() => setOpen(false)}
            >
              {t('pages.configTypeEditor.cancel')}
            </Button>
            <Button
              sx={{
                backgroundColor: 'primaryRed.main',
                minWidth: '64px',
                paddingLeft: '20px',
                paddingRight: '20px',
                '&:hover': {
                  backgroundColor: 'primaryRed.main',
                },
              }}
              onClick={() => {
                if (toDelete !== null) {
                  remove(toDelete);
                  removeStage(toDelete);
                  setOpen(false);
                  setDeleteStage(true);
                }
              }}
            >
              {t('pages.forms.yes')}
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </>
  ) : (
    <Loading />
  );
}

export default ScheduledTasksStages;
