import { Stack, CircularProgress, Typography } from '@mui/material';
import { BusinessPolicyByRoleResponse } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import NoResults from 'components/NoResults';

interface RoleDialogProps {
  businessPolicies: BusinessPolicyByRoleResponse[];
  isFetchingBusinessPolicies: boolean;
}

function RoleDialog({
  businessPolicies,
  isFetchingBusinessPolicies,
}: RoleDialogProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <Stack spacing={2}>
      <Typography variant="h2" style={{ marginBottom: 10, color: 'black' }}>
        {t('pages.roles.dialog.warning.subtitle')}
      </Typography>
      {!isFetchingBusinessPolicies ? (
        <ul
          style={{
            maxHeight: '120px',
            border: '1px solid #00000040',
            listStyle: 'none',
            padding: '1rem',
            overflowY: 'auto',
          }}
        >
          {businessPolicies.length > 0 ? (
            businessPolicies.map((businessPolicy) => (
              <li
                key={businessPolicy.customerBusinessPolicyId}
                style={{ width: '100%', marginBottom: '10pxS' }}
              >
                {businessPolicy.name}
              </li>
            ))
          ) : (
            <NoResults />
          )}
        </ul>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100px',
          }}
        >
          <CircularProgress />
        </div>
      )}
      <Typography variant="h4" style={{ marginTop: '10px', color: 'black' }}>
        {t('pages.roles.dialog.warning.note')}
      </Typography>
    </Stack>
  );
}

export default RoleDialog;
