import { useTranslation } from 'react-i18next';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { Grid, Box, Card, IconButton, Stack } from '@mui/material';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

import { Button, Input, Select } from '@revenue-solutions-inc/revxcoreui';
import {
  WorkflowFormObject,
  WorkflowTableDataMapObject,
} from '../WorkflowEditor.types';

const attributeBase: WorkflowTableDataMapObject = {
  SinkObjectId: '',
  Domainkey: '',
  OperationID: '',

  InputFieldMappings: [
    {
      input: '',
      sinkParameterName: '',
    },
  ],

  OutputFieldMappings: [
    {
      output: '',
      sinkParameterName: '',
    },
  ],
};

interface CustomTableRepeatingAttributesProps {
  attributeIndex: number;
  editDisabled: boolean;
}

function CustomTableRepeatingAttributes({
  attributeIndex,
  editDisabled,
}: CustomTableRepeatingAttributesProps) {
  const { t } = useTranslation();

  const { control } = useFormContext<WorkflowFormObject>();

  const {
    fields: attributeFields,
    append: appendAttributeField,
    remove: removeAttributeField,
  } = useFieldArray({
    control,
    name: `WorkflowTables.${attributeIndex}.DataMap`,
  });

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        paddingTop: 3,
        paddingBottom: 3,
      }}
    >
      <Grid item xs={12}>
        <Button
          size={'small'}
          id={'addEditRow'}
          onClick={() => {
            appendAttributeField({ ...attributeBase });
          }}
          disabled={editDisabled}
        >
          {t('pages.attributes.addColumn')}
        </Button>
      </Grid>
      <Grid spacing={3} container>
        {attributeFields.map((attr, repeatingAttributeIndex) => (
          <Grid
            key={`${attributeIndex}-${repeatingAttributeIndex}`}
            id={`custom-table-attribute-${attributeIndex}-${repeatingAttributeIndex}`}
            item
            xs={3}
            sx={{
              position: 'relative',
            }}
          >
            <Stack>
              <Card sx={{ p: 2, backgroundColor: 'secondary.main' }}>
                <Box>
                  <Controller
                    name={`WorkflowTables.${attributeIndex}.DataMap.${repeatingAttributeIndex}.Domainkey`}
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: t('pages.attributes.messages.valueReq'),
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        sx={{ width: '100%' }}
                        required
                        disabled={editDisabled}
                        autowidth={false}
                        options={[
                          { key: 1, desc: 'Dummy 1' },
                          { key: 2, desc: 'Dummy 2' },
                          { key: 3, desc: 'Dummy 3' },
                        ]}
                        id={`${attr.Domainkey}-${repeatingAttributeIndex}-select-wf`}
                        data-testid="configDomain"
                        label={'Domain'}
                        onChange={onChange}
                        value={value ?? ''}
                      />
                    )}
                  />
                </Box>
                <Box>
                  <Controller
                    name={`WorkflowTables.${attributeIndex}.DataMap.${repeatingAttributeIndex}.OperationID`}
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: t('pages.attributes.messages.valueReq'),
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        sx={{ width: '100%' }}
                        required
                        disabled={editDisabled}
                        autowidth={false}
                        options={[
                          { key: 1, desc: 'Dummy 1' },
                          { key: 2, desc: 'Dummy 2' },
                          { key: 3, desc: 'Dummy 3' },
                        ]}
                        id={`${attr.OperationID}-${repeatingAttributeIndex}-select-wf`}
                        data-testid="operator"
                        label={'Operator'}
                        onChange={onChange}
                        value={value ?? ''}
                      />
                    )}
                  />
                </Box>
                <Box>
                  <Controller
                    name={`WorkflowTables.${attributeIndex}.DataMap.${repeatingAttributeIndex}.OutputFieldMappings.0.output`}
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: t('pages.attributes.messages.valueReq'),
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <Input
                        sx={{ width: '100%' }}
                        disabled={editDisabled}
                        required={true}
                        id={`${attr.OperationID}-${repeatingAttributeIndex}`}
                        label={t('pages.attributes.displayName')}
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                </Box>
                <Box>
                  <Controller
                    name={`WorkflowTables.${attributeIndex}.DataMap.${repeatingAttributeIndex}.OutputFieldMappings.0.sinkParameterName`}
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: t('pages.attributes.messages.valueReq'),
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <Input
                        sx={{ width: '100%' }}
                        disabled={editDisabled}
                        required={true}
                        id={`${attr.OperationID}-${repeatingAttributeIndex}`}
                        label={'sinkParameterName'}
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                </Box>
              </Card>
              <IconButton
                disabled={editDisabled}
                title={t('pages.attributes.removeColumn')}
                onClick={() => {
                  removeAttributeField(repeatingAttributeIndex);
                }}
                size="small"
                sx={{
                  position: 'absolute',
                  top: 10,
                  right: -10,
                }}
              >
                <CancelRoundedIcon fontSize="small" />
              </IconButton>
            </Stack>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default CustomTableRepeatingAttributes;
