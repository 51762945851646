import { useCallback, useEffect } from 'react';
import { Box, Stack, Typography, Button as TextButton } from '@mui/material';
import Select from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import { useTranslation } from 'react-i18next';
import { useNavigate, NavigateOptions } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { WorkflowContextType } from 'types/WorkflowContext';
import { useAddWorkflow } from 'hooks/workflow/useAddWorkflow';
import { Button } from '@revenue-solutions-inc/revxcoreui';

type AddWorkflowProps = {
  selectedGroupName?: string;
  contextId?: string;
  contextType?: WorkflowContextType;
  onCloseCallback?: () => void;
};

function Workflow({
  contextId,
  contextType,
  selectedGroupName,
  onCloseCallback = () => {},
}: AddWorkflowProps): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const module = useAppSelector((state) => state.user.module);

  const {
    groupOptions,
    variantOptions,
    groupSelected,
    setGroupSelected,
    variantSelected,
    setVariantSelected,
    workTypeDynamicLabel,
    setWorkTypeDynamicLabel,
  } = useAddWorkflow();

  const selectGroup = useCallback(
    (value: string | number) => {
      setGroupSelected(String(value));
      //Search if theres a work type name and set the state with it
      const selectedWorkTypeName = groupOptions.filter((group) => {
        return group.key === value;
      })[0]?.desc;
      const newWorkTypeNameLabel = selectedWorkTypeName
        ? //this replace all the text before the Type word with the new work type name
          workTypeDynamicLabel.replace(
            /.+?(?=Type)/g,
            `${selectedWorkTypeName} `
          )
        : t('pages.workflow.selectType');
      setWorkTypeDynamicLabel(newWorkTypeNameLabel);
    },
    [
      setGroupSelected,
      setWorkTypeDynamicLabel,
      t,
      workTypeDynamicLabel,
      groupOptions,
    ]
  );

  const handleWorkflowManualLink = () => {
    navigate(`/${module}/workflow/create/${variantSelected}`, {
      state: {
        // setup context for workflow create form
        __workType:
          groupOptions.find((g) => g.key === groupSelected)?.desc || '',
        __workflowContext: {
          WorkflowContextType: contextType,
          ContextId: contextId,
        },
      },
    } as NavigateOptions);
    onCloseCallback();
  };

  useEffect(() => {
    if (selectedGroupName) {
      const selectedWorkTypeName = groupOptions.find((group) => {
        return group.desc.toLowerCase() === selectedGroupName.toLowerCase();
      });
      if (selectedWorkTypeName) selectGroup(selectedWorkTypeName.key);
    }
  }, [selectedGroupName, selectGroup, groupOptions]);

  return (
    <Stack spacing={2}>
      <Typography variant="h2" style={{ marginBottom: 20 }}>
        {selectedGroupName
          ? `Add ${selectedGroupName}`
          : t('pages.workflow.addWork')}
      </Typography>

      {!selectedGroupName && (
        <Select
          label={t('pages.workflow.selectWork')}
          id="workflow-group-select"
          value={groupSelected}
          onChange={(e) => selectGroup(e.target.value)}
          options={groupOptions ?? []}
        />
      )}

      <Select
        label={workTypeDynamicLabel}
        id="workflow-variant-select"
        value={variantSelected}
        onChange={(e) => setVariantSelected(String(e.target.value))}
        options={variantOptions ?? []}
      />
      <Box
        sx={{
          justifyContent: 'flex-end',
          display: 'flex',
          gap: 1,
        }}
      >
        <TextButton
          variant="text"
          data-testid={'cancel-workflow-button'}
          onClick={onCloseCallback}
        >
          {t('components.button.cancel')}
        </TextButton>
        <Button
          variant="contained"
          id={'create-workflow-button'}
          disabled={!variantSelected || !groupSelected}
          onClick={handleWorkflowManualLink}
        >
          {t('pages.workflow.simpleWorkflowManual')}
        </Button>
      </Box>
    </Stack>
  );
}

export default Workflow;
