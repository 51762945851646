import { useContext, useMemo, CSSProperties } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton, Stack, Theme } from '@mui/material';
import {
  MessageActionType,
  MessageType,
} from '@revenue-solutions-inc/revxcoreui';
import { Box } from '@mui/system';
import extractMeaningfulMessage from 'utils/errorMessage';
import { AssignedRolesContext } from 'components/contexts/AssignedRoles/AssignedRolesProvider';
import { isBefore, isToday } from 'date-fns';
import useAssignRoleUsers, { AssignAction } from 'hooks/useAssignRoleUsers';
import useGetAccessToken from 'hooks/useGetAccessToken';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import { AssignedUserRole } from 'types/roles';
import { checkDatesEqual, isDateInvalid, toDate } from 'utils/date-util';
import { CellContext } from '@tanstack/react-table';

const widthHeight: CSSProperties = {
  width: '1.5em',
  height: '1.5em',
};

const fontSize = '1.3em';

interface UserRoleMapEntriesDates {
  startDate: Date | undefined;
  endDate: Date | undefined;
}

const cancelButton = (theme: Theme) => {
  return {
    ...widthHeight,
    background: theme.palette.error.main,
    '&:hover': {
      background: theme.palette.error.main,
    },
  };
};

const checkButton = (theme: Theme) => {
  return {
    ...widthHeight,
    background: theme.palette.secondaryGreen.main,
    '&:hover': {
      background: theme.palette.secondaryGreen.main,
    },
    '&:disabled': {
      background: theme.palette.grey1.main,
    },
  };
};

export const editButton = {
  ...widthHeight,
  color: 'linkBlue.main',
  fontSize,
};

const icon = {
  color: 'white.main',
  fontSize,
};
export default function EditableButtonCell({
  row: { original },
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
CellContext<any, unknown>): JSX.Element {
  const { assignedRoles, setAssignedRoles } = useContext(AssignedRolesContext);

  const accessToken = useGetAccessToken();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { mutate: updateRoles } = useAssignRoleUsers();

  const { initialStartDate, initialEndDate, roleId } = useMemo(() => {
    const { startDate, endDate } = original as AssignedUserRole;
    return {
      roleId: original.roleId,
      initialStartDate: toDate(startDate),
      initialEndDate: toDate(endDate),
    };
  }, [original]);

  const { startDateValue, startDateError, endDateValue, endDateError, isEdit } =
    useMemo(() => {
      const role = assignedRoles?.roles.find((r) => r.roleId === roleId);
      return {
        ...role,
        startDateValue: role?.startDate || null,
        startDateError: role?.startDateError || null,
        endDateValue: role?.endDate || null,
        endDateError: role?.endDateError || null,
      };
    }, [assignedRoles, roleId]);

  const handleEditDates = (edit: boolean) => () => {
    if (assignedRoles) {
      if (edit)
        setAssignedRoles({
          ...assignedRoles,
          roles: [
            ...assignedRoles.roles,
            {
              roleId,
              isEdit: edit,
              startDate: initialStartDate,
              endDate: initialEndDate,
              initialStartUnixTime: 0,
              initialEndUnixTime: 0,
            },
          ],
        });
      else
        setAssignedRoles({
          ...assignedRoles,
          roles: [...assignedRoles.roles.filter((r) => r.roleId !== roleId)],
        });
    }
  };

  const isStartDateInvalid = useMemo(() => {
    if (startDateValue) {
      if (checkDatesEqual(initialStartDate, startDateValue)) return false;

      if (isDateInvalid(startDateValue)) return true;

      if (startDateError) return true;

      if (!isToday(startDateValue) && isBefore(startDateValue, new Date()))
        return true;
    }
    if (!startDateValue && startDateError) {
      return true;
    }
    return false;
  }, [startDateValue, initialStartDate, startDateError]);

  const isEndDateInvalid = useMemo(() => {
    if (endDateValue) {
      if (checkDatesEqual(initialEndDate, endDateValue)) return false;

      if (isDateInvalid(endDateValue)) return true;

      if (endDateError) return true;

      if (!isToday(endDateValue) && isBefore(endDateValue, new Date()))
        return true;
    }
    return false;
  }, [endDateValue, initialEndDate, endDateError]);

  const isStartEndDatesValid = useMemo(() => {
    if (isStartDateInvalid || isEndDateInvalid) return false;
    return true;
  }, [isStartDateInvalid, isEndDateInvalid]);
  const handleUpdateDates = () => {
    if (assignedRoles && assignedRoles.userRole) {
      const {
        tenantId,
        userRole: { userId },
        roles,
      } = assignedRoles;

      const dates: UserRoleMapEntriesDates = {
        startDate: undefined,
        endDate: undefined,
      };

      if (startDateValue) {
        dates.startDate = startDateValue;
      }
      if (endDateValue) {
        dates.endDate = endDateValue;
      }

      updateRoles(
        {
          userCommand: {
            tenantId,
            mapAction: AssignAction.ASSIGN,
            userRoleMapEntries: [
              {
                roleId,
                userId,
                ...dates,
              },
            ],
          },
          token: accessToken,
        },
        {
          onSuccess: () => {
            setAssignedRoles({
              ...assignedRoles,
              isReset: true,
              roles: [...roles.filter((r) => r.roleId !== roleId)],
            });
            dispatch(
              addMessage({
                message: t('components.message.success'),
                type: MessageType.Success,
                actionType: MessageActionType.None,
              })
            );
          },
          onError: (error) => {
            const message = extractMeaningfulMessage(
              error,
              t('pages.manageReusableContent.networkError')
            );
            dispatch(
              addMessage({
                message: message,
                type: MessageType.Error,
                actionType: MessageActionType.None,
              })
            );
          },
        }
      );
    }
  };

  return (
    <Box sx={{ flexDirection: 'row', flexShrink: 2, px: 1 }}>
      {!isEdit ? (
        <IconButton
          size="small"
          onClick={handleEditDates(true)}
          sx={editButton}
          aria-label="edit-button"
        >
          <EditIcon sx={{ fontSize }} />
        </IconButton>
      ) : (
        <Stack spacing={1} direction="row">
          <IconButton
            size="small"
            onClick={handleEditDates(false)}
            sx={cancelButton}
            aria-label="cancel-button"
          >
            <CloseIcon fontSize="small" sx={icon} />
          </IconButton>
          <IconButton
            size="small"
            sx={checkButton}
            disabled={!isStartEndDatesValid}
            onClick={handleUpdateDates}
            aria-label="check-button"
          >
            <CheckIcon sx={icon} />
          </IconButton>
        </Stack>
      )}
    </Box>
  );
}
