import { useMsal } from '@azure/msal-react';
import { clearContentState } from 'redux/contentSlice';
import { useAppDispatch } from 'redux/hooks';
import { clearMessage } from 'redux/messageSlice';
import { clearUserState } from 'redux/userSlice';

export function useLogoutApp() {
  const { accounts, instance } = useMsal();
  const dispatch = useAppDispatch();

  return () => {
    instance
      .logoutRedirect({
        account: accounts[0],
      })
      .then(() => {
        if (dispatch) {
          dispatch(clearMessage());
          dispatch(clearContentState());
          dispatch(clearUserState());
        }
      })
      .catch((response) => {
        throw response;
      });
  };
}
