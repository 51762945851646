import { ChangeEvent, Dispatch, SetStateAction, Fragment } from 'react';
import { Box, CircularProgress, Grid, List, ListItem } from '@mui/material';
import Checkbox from '@revenue-solutions-inc/revxcoreui/material/controls/Checkbox';
import { modulesDefault as mandatoryModule, Modules } from 'types/tenants';
import extractMeaningfulMessage from 'utils/errorMessage';
import { addMessage } from 'redux/messageSlice';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'redux/hooks';
import { useGetConfigurationsQuery } from 'generated/graphql';
import { getModules, ConfigurationsResponse } from 'utils/getConfigurations';
import {
  MessageType,
  MessageActionType,
} from '@revenue-solutions-inc/revxcoreui';
interface SelectModulesProps {
  contractualInfoModules: Modules[];
  handleChange: (newTenant: Modules[]) => void;
  handleValidation: Dispatch<SetStateAction<boolean>>;
}

function SelectModules({
  contractualInfoModules,
  handleChange,
  handleValidation,
}: SelectModulesProps): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { isFetching } = useGetConfigurationsQuery<ConfigurationsResponse>(
    {
      configurationType: 'ModuleSelection',
      configurationModule: '',
    },
    {
      onSuccess: (response) => {
        if (response?.getConfigurations) {
          const allModules: Modules[] = getModules(response.getConfigurations);
          const mandatoryModuleId = mandatoryModule.moduleId;
          const defaultMod = allModules.find(
            (mod: Modules) => mod.moduleId === mandatoryModuleId
          );

          if (
            defaultMod?.moduleId !== mandatoryModuleId ||
            defaultMod?.moduleName !== mandatoryModule.moduleName
          ) {
            dispatch(
              addMessage({
                message: t(
                  'pages.tenantConfig.selectModules.invalidMandatoryModule'
                ),
                type: MessageType.Error,
                actionType: MessageActionType.None,
              })
            );
          }

          const modulesWithoutMandatoryModule = allModules.filter(
            (mod: Modules) => mod.moduleId !== mandatoryModuleId
          );

          handleChange(modulesWithoutMandatoryModule);
        }
      },
      onError: (error) => {
        const message = extractMeaningfulMessage(
          error,
          t('components.message.networkerror')
        );
        dispatch(
          addMessage({
            message: message,
            type: MessageType.Error,
            actionType: MessageActionType.None,
          })
        );
      },
    }
  );

  const updateModule =
    (module: Modules) => (event: ChangeEvent<HTMLInputElement>) => {
      const modulesUpdated: Modules[] = contractualInfoModules.map(
        (mod: Modules) => {
          if (mod.moduleId === module.moduleId) {
            return { ...mod, selected: event.target.checked };
          }
          return mod;
        }
      );

      handleChange(modulesUpdated);
      handleValidation(
        modulesUpdated.filter((mod: Modules) => mod.selected === true).length >
          0
      );
    };

  const renderModules = () => {
    return (
      <>
        <ListItem key={mandatoryModule?.moduleId} alignItems="flex-start">
          <Checkbox
            id={`module-id-moduleId-${mandatoryModule?.moduleId}`}
            data-testid={`module-id-${mandatoryModule?.moduleId}`}
            label={mandatoryModule?.moduleName}
            value="module"
            color="primary"
            checked={true}
            disabled={true}
            onChange={updateModule(mandatoryModule)}
          />
        </ListItem>
        {contractualInfoModules.length > 0 &&
          contractualInfoModules
            .sort((a, b) => (a.moduleId > b.moduleId ? 1 : -1))
            .map((module: Modules, index) => (
              <Fragment key={index}>
                <ListItem key={module.moduleId} alignItems="flex-start">
                  <Checkbox
                    value="module"
                    color="primary"
                    id={`module-id-moduleId-${module.moduleId}`}
                    data-testid={`module-id-${module.moduleId}`}
                    checked={module.selected}
                    label={module.moduleName}
                    onChange={updateModule(module)}
                  />
                </ListItem>
              </Fragment>
            ))}
      </>
    );
  };

  return (
    <Grid
      container
      spacing={1}
      sx={{
        backgroundColor: 'white.main',
        padding: 2,
        borderRadius: 1,
        border: '1px solid #e2e2e2',
      }}
    >
      <Grid item xs={10} mb={0.5}>
        <List sx={{ minWidth: '400px', display: 'inline' }} disablePadding>
          {isFetching ? (
            <Box sx={{ display: 'flex', pt: 3 }}>
              {' '}
              <CircularProgress size={24} />{' '}
            </Box>
          ) : (
            renderModules()
          )}
        </List>
      </Grid>
    </Grid>
  );
}

export default SelectModules;
