import { CardContent, Grid } from '@mui/material';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import PropertyCardLayout from '../PropertyCardLayout';

interface Props {
  setActive: React.Dispatch<React.SetStateAction<string>>;
}

type CorrespondenceDataTypes = {
  correspondence: string;
  date: string;
};

// TODO: remove once the services are in place for CorrespondenceCard
const correspondenceData: CorrespondenceDataTypes[] = [
  { correspondence: 'Delinquent Notice', date: '02/01/2022' },
  { correspondence: 'Certified Statement', date: '10/01/2021' },
  { correspondence: 'Discount Promotion Letter', date: '09/01/2021' },
];

function CorrespondanceCard({ setActive }: Props): JSX.Element {
  const { t } = useTranslation();

  const COLUMNS: ColumnDef<CorrespondenceDataTypes>[] = [
    {
      id: 'correspondence',
      accessorKey: 'correspondence',
      header: () => (
        <HeaderColumnNext localization={t('pages.propertyView.lastFive')} />
      ),
    },

    {
      id: 'date',
      accessorKey: 'date',
      header: () => (
        <HeaderColumnNext localization={t('pages.propertyView.dateSent')} />
      ),
    },
  ];

  return (
    <PropertyCardLayout
      title={t('pages.propertyView.correspondence')}
      setActive={setActive}
      activeCard="ActiveCorrespondanceCard"
    >
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <DefaultDataTableNext
              columns={COLUMNS as ColumnDef<Record<string, unknown>>[]}
              data={correspondenceData}
              enableGlobalFilter={false}
            />
          </Grid>
        </Grid>
      </CardContent>
    </PropertyCardLayout>
  );
}

export default CorrespondanceCard;
