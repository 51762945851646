import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import DataCard from 'components/DataCard';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { OpenInNew } from '@mui/icons-material';
import { PaymentDetailsResponse } from 'generated/graphql';
import { Grid, Typography } from '@mui/material';
import { getDate } from 'common/helpers';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/system';
import { useAppSelector } from 'redux/hooks';
import { openModalTheme } from '../AccountView/AccountView';

interface PaymentDetailsProps {
  paymentDetails?: PaymentDetailsResponse;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

function PaymentDetailsDataCard({
  paymentDetails,
  setOpen,
}: PaymentDetailsProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const module = useAppSelector((state) => state.user.module);

  const paymentDetailsChildren = (
    <Grid container mt={1}>
      <Grid item xs={6}>
        <Typography variant="h5" id="primaryIdLabel" component="label">
          {t('pages.paymentDetails.primaryId')}
        </Typography>
        <Typography variant="h3" id="primaryId">
          {paymentDetails?.primaryIdentifier
            ? paymentDetails?.primaryIdentifier
            : '-'}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h5" id="primaryIdTypeLabel" component="label">
          {t('pages.paymentDetails.primaryIdType')}
        </Typography>
        <Typography variant="h3" id="primaryIdType">
          {paymentDetails?.primaryIdentifierType
            ? paymentDetails?.primaryIdentifierType
            : '-'}
        </Typography>
      </Grid>
      <Grid item xs={6} mt={2}>
        <Typography variant="h5" id="accountTypeLabel" component="label">
          {t('pages.paymentDetails.application.accountType')}
        </Typography>
        <Typography variant="h3" id="accountType">
          {paymentDetails?.accountType ? paymentDetails?.accountType : '-'}
        </Typography>
      </Grid>
      <Grid item xs={6} mt={2}>
        <Typography variant="h5" id="periodCoveredLabel" component="label">
          {t('pages.paymentDetails.periodCovered')}
        </Typography>
        <Typography variant="h3" id="periodCovered">
          {paymentDetails?.periodCoveredDate
            ? getDate(paymentDetails?.periodCoveredDate)
            : '-'}
        </Typography>
      </Grid>
      <Grid item xs={6} mt={2}>
        <Typography variant="h5" id="periodCoveredLabel" component="label">
          {t('pages.submissionDetails.submissionId')}
        </Typography>
        <Typography variant="h3" id="periodCovered">
          {paymentDetails?.submissionId ? (
            <Link
              to={{
                pathname: `/${module}/submissiondetails/${paymentDetails?.submissionId}`,
              }}
              state={{
                prevPath: location.pathname,
                prevPageName: t('pages.paymentDetails.title'),
              }}
              style={{
                color: theme.palette.linkBlue.dark,
                fontWeight: 'normal',
              }}
            >
              {t('pages.viewFinancialTransGroup.viewDetails')}
            </Link>
          ) : (
            '-'
          )}
        </Typography>
      </Grid>
    </Grid>
  );

  return (
    <Grid item xs={12} lg={3}>
      <DataCard
        title={t('pages.paymentDetails.title')}
        avatar={
          <MonetizationOnIcon sx={{ fill: theme.palette.primary.main }} />
        }
        action={
          paymentDetails?.canCorrect ? (
            <OpenInNew
              data-testid="open-payment-details-modal"
              sx={openModalTheme}
              onClick={() => {
                setOpen(true);
              }}
            />
          ) : (
            <></>
          )
        }
        children={paymentDetailsChildren}
      />
    </Grid>
  );
}
export default PaymentDetailsDataCard;
