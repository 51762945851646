import { useState } from 'react';
import {
  AdjustmentDetailResponse,
  PeriodFinancialImpacts,
  PeriodFinancialTransactionResponse,
  TransactionPosting,
  useGetAccountPeriodForecastBalanceQuery,
  useGetPendingAdjustmentsQuery,
  useGetPeriodFinancialTransactionsQuery,
  useGetPeriodTransactionsSimplifiedQuery,
} from 'generated/graphql';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Loading from 'components/Loading';

import { useAppDispatch } from 'redux/hooks';
import {
  MessageActionType,
  MessageType,
} from '@revenue-solutions-inc/revxcoreui';
import { mergeWith } from 'lodash';
import { addMessage } from 'redux/messageSlice';
import extractMeaningfulMessage from 'utils/errorMessage';
import Grid from '@mui/material/Grid';
import PeriodTransactions from '../PeriodTransactions';
import ForecastPeriodTotals from '../ForecastPeriodTotals';

export interface ForecastImpactAmount {
  balance?: number;
  collection?: number;
  impactType?: string;
  liability?: number;
  forecastBalance?: number | null;
  forecastCollection?: number | null;
  forecastImpactType?: string | null;
  forecastLiability?: number | null;
}

interface Props {
  isPnIUpToDate: boolean;
}

function AccountPeriodTransactions({ isPnIUpToDate }: Props) {
  const { t } = useTranslation();
  const { periodId } = useParams() as {
    periodId: string;
  };
  const [forecastDate, setForecastDate] = useState<Date | null>(null);
  const [joinedImpactAmounts, setJoinedImpactAmounts] = useState<
    ForecastImpactAmount[]
  >([]);
  const dispatch = useAppDispatch();
  const [simplifiedTransactions, setSimplifiedTransactions] = useState<
    TransactionPosting[] | null
  >();
  const [transactionData, setTransactionData] =
    useState<PeriodFinancialTransactionResponse>({
      impactAmounts: [],
      financialTransactions: [],
    });
  const [pendingAdjustments, setPendingAdjustments] =
    useState<AdjustmentDetailResponse[]>();

  function resetForecastData() {
    setForecastDate(null);
    setJoinedImpactAmounts([]);
  }

  const forcastDateFormatted = (date: Date | null) => {
    if (date && date?.toString() !== 'Invalid Date') {
      return date?.toISOString().split('T')[0];
    }
    return '';
  };

  function customizer(
    firstValue: PeriodFinancialImpacts[],
    secondValue: ForecastImpactAmount[]
  ) {
    return Object.assign({}, firstValue, secondValue);
  }

  const joinImpactAmountArrays = (
    impactAmounts: PeriodFinancialImpacts[] | null | undefined
  ) => {
    const resultArray = impactAmounts?.map((item) => ({
      forecastBalance: item.balance,
      forecastCollection: item.collection,
      forecastImpactType: item.impactType,
      forecastLiability: item.liability,
    }));
    const lodashResult = mergeWith(
      transactionData.impactAmounts,
      resultArray,
      customizer
    );

    setJoinedImpactAmounts([...(lodashResult as ForecastImpactAmount[])]);
  };

  const {
    isFetching: isAccountForecastLoading,
    refetch: getAccountPeriodForecast,
  } = useGetAccountPeriodForecastBalanceQuery(
    {
      throughDate: forcastDateFormatted(forecastDate) ?? '',
      accountPeriodId: periodId,
    },
    {
      enabled: false,
      onSuccess: (response) => {
        const forecastImpactAmounts =
          response.GetAccountPeriodForecastBalance.impactAmounts;
        const forecastImpactTotals = response.GetAccountPeriodForecastBalance;

        const impactTypeTotals = {
          impactType: 'Totals',
          liability: forecastImpactTotals.totalLiability,
          collection: forecastImpactTotals.totalCollection,
          balance: forecastImpactTotals.totalBalance,
        };

        forecastImpactAmounts?.push(impactTypeTotals);

        joinImpactAmountArrays(forecastImpactAmounts);
      },
      onError: (error) => {
        const message = extractMeaningfulMessage(
          error,
          t('pages.manageReusableContent.networkError')
        );
        dispatch(
          addMessage({
            message: message,
            type: MessageType.Error,
            actionType: MessageActionType.None,
          })
        );
      },
    }
  );

  const { isLoading, isError } = useGetPeriodFinancialTransactionsQuery(
    {
      getPeriodFinancialTransactionDetailId: periodId,
    },
    {
      enabled: !!periodId,
      retry: false,
      onError: () => {
        //TODO: Handle this error once service differeniates errors vs no transaction response
        setTransactionData({
          impactAmounts: [],
          financialTransactions: [],
        });
      },
      onSuccess: (response) => {
        const transactionDetails = response.GetPeriodFinancialTransactionDetail;
        if (transactionDetails.impactAmounts) {
          const impactTypeTotals = {
            impactType: 'Totals',
            liability: transactionDetails.totalLiability,
            collection: transactionDetails.totalCollection,
            balance: transactionDetails.totalBalance,
          };

          transactionDetails.impactAmounts.push(impactTypeTotals);
          resetForecastData();
          setTransactionData({
            canForecast: transactionDetails.canForecast,
            impactAmounts: transactionDetails.impactAmounts,
            financialTransactions: transactionDetails.financialTransactions,
          });
        }
      },
    }
  );

  const { isLoading: isTransactionsLoading } =
    useGetPeriodTransactionsSimplifiedQuery(
      {
        periodId: periodId,
      },
      {
        onSuccess: (data) => {
          setSimplifiedTransactions(
            data.GetPeriodTransactionsSimplified.financialTransactionPostings
          );
        },
        onError: (error) => {
          const message = extractMeaningfulMessage(
            error,
            t('pages.periodDetails.simplifiedTransactionsError')
          );
          dispatch(
            addMessage({
              message: message,
              type: MessageType.Error,
              actionType: MessageActionType.None,
            })
          );
        },
      }
    );

  useGetPendingAdjustmentsQuery(
    {
      periodId: periodId,
    },
    {
      onSuccess: (data) => {
        setPendingAdjustments(data.GetPendingAdjustments);
      },
      onError: (error) => {
        const message = extractMeaningfulMessage(
          error,
          t('pages.periodDetails.pendingAdjustmentsError')
        );
        dispatch(
          addMessage({
            message: message,
            type: MessageType.Error,
            actionType: MessageActionType.None,
          })
        );
      },
    }
  );

  //TODO: Add an error message once service differentiates between errors and empty period transactions
  return (
    <Grid item lg={9} xs={12}>
      {isLoading && !isError && <Loading />}
      <ForecastPeriodTotals
        forecastDate={forecastDate}
        impactAmounts={transactionData.impactAmounts}
        joinedImpactAmounts={joinedImpactAmounts}
        refetchForecast={getAccountPeriodForecast}
        resetForecastData={resetForecastData}
        setForecastDate={setForecastDate}
        canForecast={transactionData.canForecast}
        isAccountForecastLoading={isAccountForecastLoading}
        isPnIUpToDate={isPnIUpToDate}
        isLoading={isLoading}
      />
      {!isTransactionsLoading && simplifiedTransactions && (
        <PeriodTransactions
          simplifiedTransactions={simplifiedTransactions}
          detailedTransactions={transactionData.financialTransactions}
          pendingAdjustments={pendingAdjustments}
          isLoading={isLoading}
        />
      )}
    </Grid>
  );
}

export default AccountPeriodTransactions;
