import { useFieldArray, Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { IconButton, Box, Typography, Grid } from '@mui/material';
import { Button } from '@revenue-solutions-inc/revxcoreui';

import { Attribute } from 'generated/graphql';
import DatasourceSelect from 'components/DatasourceSelect';
import MultiSelect from '@revenue-solutions-inc/revxcoreui/material/controls/MultiSelect/MultiSelect';
import {
  WorkflowConditionAction,
  WorkflowFormObject,
} from '../WorkflowEditor.types';

interface Props {
  index: number;
  control: Control<WorkflowFormObject, null>;
  customAttributes: Attribute[];
  actionAttributes?: Attribute[];
  disabled?: boolean;
}

const newAction: WorkflowConditionAction = {
  action: {
    attributeName: 'action',
    attributeDisplayName: 'Action',
    attributeType: 'select',
    attributeDescription: null,
    dataSource: 'WorkflowAction',
    repeatingProperties: null,
    possibleValues: null,
    attributeValue: '',
    isOOTB: null,
    isOOTBEditable: null,
  },
  parameter: {
    attributeName: 'parameter',
    attributeDisplayName: 'Parameter',
    attributeType: null,
    attributeDescription: null,
    dataSource: null,
    repeatingProperties: null,
    possibleValues: [],
    attributeValue: '',
    isOOTB: null,
    isOOTBEditable: null,
  },
};

export const WorkflowConditionActionsFields = ({
  index,
  control,
  disabled,
  customAttributes,
  actionAttributes = [],
}: Props) => {
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `workflowSteps.${index}.workflowActions`,
  });
  const actionParameters = [...customAttributes, ...actionAttributes].map(
    (attr) => {
      return {
        desc: attr.attributeDisplayName,
        key: attr.attributeName,
      };
    }
  );

  return (
    <>
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between' }}
        marginTop={2}
      >
        <Typography fontWeight={'bold'}>Actions</Typography>
        <Button
          id={`add-action-${index}`}
          size="small"
          sx={{ minWidth: '8em' }}
          onClick={() => {
            append(newAction);
          }}
          disabled={disabled}
        >
          {t('pages.workflowEditor.addAction')}
        </Button>
      </Box>
      {fields.map((action, actionIndex) => {
        return (
          <Grid
            container
            spacing={3}
            key={`${action.action.attributeName}-${index}-${actionIndex}`}
          >
            <Grid item>
              <Controller
                defaultValue={action.action.attributeValue}
                name={`workflowSteps.${index}.workflowActions.${actionIndex}.action.attributeValue`}
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <DatasourceSelect
                      maxWidth
                      id={`${action.action.attributeName}-${index}`}
                      datasource={action.action.dataSource ?? ''}
                      label={action.action.attributeDisplayName}
                      fetchLayoutInfo={onChange}
                      value={value}
                      disabled={disabled}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item>
              <Controller
                defaultValue={action.parameter?.possibleValues ?? []}
                name={`workflowSteps.${index}.workflowActions.${actionIndex}.parameter.possibleValues`}
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <MultiSelect
                      sx={{ width: '100%' }}
                      id={`${
                        action.parameter?.attributeName ??
                        'workflow-action-parameter'
                      }-${index}`}
                      label={`${
                        action?.parameter?.attributeDisplayName ??
                        t('pages.workflowEditor.actionsFields.parameter')
                      }`}
                      onChange={onChange}
                      onDelete={(e) => {
                        onChange(value?.filter((v: string) => v !== e));
                      }}
                      disabled={disabled}
                      options={actionParameters}
                      values={value ?? []}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item>
              {actionIndex === fields.length - 1 ? (
                <IconButton
                  onClick={() => {
                    remove(actionIndex);
                  }}
                  aria-label="delete"
                  color="default"
                  size="small"
                  disabled={disabled}
                >
                  <CancelRoundedIcon fontSize="small" />
                </IconButton>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        );
      })}
    </>
  );
};
