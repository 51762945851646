import { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/system';
import {
  CircularProgress,
  ClickAwayListener,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Popper,
  TextField,
} from '@mui/material';
import { GeneralDataResult, useSearchByIndexQuery } from 'generated/graphql';
import { WorkflowContextType } from 'types/WorkflowContext';
import { useTranslation } from 'react-i18next';

type ContextSearchProps = {
  onSelectContext?: (context: GeneralDataResult) => void;
  queryMinLength?: number;
  maxResults?: number;
  contextType?: WorkflowContextType;
};

const INDEX_VALUES = {
  [WorkflowContextType.Entity]: process.env.REACT_APP_ENTITY_INDEX ?? '',
  [WorkflowContextType.Account]: process.env.REACT_APP_ACCOUNT_INDEX ?? '',
  [WorkflowContextType.Asset]: process.env.REACT_APP_ASSET_INDEX ?? '',
} as { [index: number]: string };

const ContextSearch = ({
  contextType,
  onSelectContext,
  queryMinLength = 3,
  maxResults = 5,
}: ContextSearchProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState<GeneralDataResult[]>([]);
  const [lastSearchValue, setLastSearchValue] = useState<string>();
  const [isListVisible, setIsListVisible] = useState(false);
  const { t } = useTranslation();

  const { data, refetch, isFetching } = useSearchByIndexQuery(
    {
      maxResults,
      indexName: contextType
        ? INDEX_VALUES[contextType]
        : `${INDEX_VALUES[WorkflowContextType.Entity]},${
            INDEX_VALUES[WorkflowContextType.Account]
          }`,
      searchPhrase: searchValue,
    },
    { enabled: false }
  );

  useEffect(() => {
    if (data && isFetching === false) {
      setSearchResults([
        ...(data?.SearchByIndex.entityData ?? []),
        ...(data?.SearchByIndex.accountData ?? []),
        ...(data?.SearchByIndex.assetsData ?? []),
      ]);
    }
  }, [data, isFetching]);

  useEffect(() => {
    if (
      isFetching === false &&
      searchValue.length >= queryMinLength &&
      searchValue !== lastSearchValue
    ) {
      setLastSearchValue(searchValue);
      refetch();
    }
  }, [
    isFetching,
    lastSearchValue,
    queryMinLength,
    setLastSearchValue,
    searchValue,
    refetch,
  ]);

  const handleClickAway = () => {
    if (isListVisible && document.activeElement !== inputRef.current) {
      setIsListVisible(false);
    }
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    if (e.target.value && e.target.value.length >= queryMinLength) {
      setIsListVisible(true);
    } else {
      setIsListVisible(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          maxWidth: '950px',
          justifyContent: 'center',
        }}
      >
        <TextField
          inputRef={inputRef}
          placeholder={t('pages.workflow.contextInfo.searchContext')}
          value={searchValue}
          inputProps={{
            ['data-testid']: 'context-search-input',
          }}
          InputProps={{
            endAdornment: (
              <CircularProgress
                size={15}
                style={{ opacity: isFetching ? 1 : 0 }}
              />
            ),
          }}
          sx={{
            '&.MuiTextField-root': { height: '40px' },
            '.MuiInputBase-root.MuiOutlinedInput-root': {
              height: '40px',
            },
            width: '100%',
          }}
          onChange={handleOnChange}
          onFocus={() => {
            if (
              searchValue.length >= queryMinLength &&
              searchResults.length > 0
            ) {
              setIsListVisible(true);
            }
          }}
          data-testid="search-input"
        />
      </Box>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Popper
          open={isListVisible}
          anchorEl={inputRef.current}
          placement="bottom"
          sx={{
            maxWidth: '100%',
            width: '400px',
            zIndex: 1201,
          }}
        >
          <Paper
            sx={{
              maxHeight: '500px',
              overflowY: 'auto',
            }}
          >
            <List>
              {searchResults.length === 0 && (
                <ListItemText>No results found</ListItemText>
              )}
              {searchResults.map((result, index) => {
                return (
                  <ListItemButton
                    data-testid="context-search-result"
                    key={`${result.resultType}-${index}`}
                    onClick={() => {
                      setIsListVisible(false);
                      if (onSelectContext) {
                        setSearchValue('');
                        onSelectContext(result);
                      }
                    }}
                  >
                    {result.displayString}
                  </ListItemButton>
                );
              })}
            </List>
          </Paper>
        </Popper>
      </ClickAwayListener>
    </>
  );
};

export default ContextSearch;
