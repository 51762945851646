import {
  Dispatch,
  Fragment,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';

import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import {
  Button,
  DateTimePicker,
  Card,
} from '@revenue-solutions-inc/revxcoreui';
import Input from '@revenue-solutions-inc/revxcoreui/material/controls/Input';
import Radio from '@revenue-solutions-inc/revxcoreui/material/controls/Radio';
import EditIcon from '@mui/icons-material/Edit';
import ExecuteTimes from 'components/channelScheduling/ExecuteTimes';
import Frequency from 'components/channelScheduling/Frequency';
import MonthDays from 'components/channelScheduling/MonthDays';
import WeekDays from 'components/channelScheduling/WeekDays';
import {
  Control,
  Controller,
  useFieldArray,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  PipelineScheduleInput,
  pipelineScheduleDefault,
  taskScheduleDefault,
  Channel,
} from 'types/channels';
import { useParams } from 'react-router-dom';
import { ManageConfigToolsContext } from 'components/contexts/ManageConfigToolsProvider/ManageConfigToolsProvider';
import DynamicAttributes from 'components/manageConfigTools/DynamicAttributes';
import {
  ConfigurationResponse,
  GetConfigurationQuery,
} from 'generated/graphql';
import Loading from 'components/Loading';
import {
  recordIcons,
  fontSize,
} from 'components/manageConfigTools/Shared/IconButtonStyling/IconButtonStyling';

interface Props {
  setChannelScheduleValid: React.Dispatch<React.SetStateAction<boolean>>;
  setShowSchedule: React.Dispatch<React.SetStateAction<boolean>>;
  showSchedule: boolean;
  checkedValue: string;
  setCheckedValue: React.Dispatch<React.SetStateAction<string>>;
  control: Control<Channel, unknown>;
  watch: UseFormWatch<Channel>;
  setValue: UseFormSetValue<Channel>;
  currentData: ConfigurationResponse[] | undefined;
  setCurrentData: Dispatch<SetStateAction<ConfigurationResponse[]>>;
  parametersData?: GetConfigurationQuery;
  stageNames?: string[];
  taskSchedule: boolean;
  parameterValues?: ConfigurationResponse[][];
  setParameterValues?: Dispatch<SetStateAction<ConfigurationResponse[][]>>;
}

// TODO: Remove once logic is figured out to edit Channel Schedule Name
enum ActionType {
  EDIT = 'edit',
  CREATE = 'create',
}

export const dateTimeStyle = () => {
  return {
    width: '100%',
    maxWidth: '20em',
    // must check
    '& .PrivatePickersFadeTransitionGroup-root:': {
      fontWeight: 700,
    },
  };
};

function ChannelSchedule({
  setShowSchedule,
  watch,
  checkedValue,
  control,
  setCheckedValue,
  parametersData,
  stageNames,
  setValue,
  taskSchedule,
  currentData,
  parameterValues,
  setParameterValues,
  setCurrentData,
  showSchedule,
  setChannelScheduleValid,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const { action } = useParams() as {
    action: string;
  };

  const [intervalValid, setIntervalValid] = useState<boolean>(true);
  const [startDateError, setStartDateError] = useState<boolean>(false);
  const [endDateError, setEndDateError] = useState<boolean>(false);
  const [intialRender, setIntialRender] = useState<boolean>(false);
  const [indexEdit, setIndexEdit] = useState<number>();
  const [indexSchedule, setIndexSchedule] = useState<number>();
  const [showButtons, setShowButtons] = useState<boolean>(false);
  const [intialRenderTask, setIntialRenderTask] = useState<boolean>(false);
  const [dateError, setDateError] = useState<boolean>(false);
  const [showNewSchedule, setShowNewSchedule] = useState<boolean>(false);

  const { selectedRecord, setSelectedRecord } = useContext(
    ManageConfigToolsContext
  );

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'Channel.Pipeline.PipelineSchedule',
  });
  const pipelineSchedules = watch(`Channel.Pipeline.PipelineSchedule`);
  if (pipelineSchedules === null) {
    setValue(`Channel.Pipeline.PipelineSchedule`, []);
  }

  // These functions check that no duplicates were found in the object
  const valueArray = pipelineSchedules?.map(function (item) {
    return item.Name;
  });
  const isNameDuplicate = valueArray?.some(function (item, idx) {
    return valueArray.indexOf(item) != idx;
  });

  const handleDateText = (index: number) => {
    const EndTime = watch(`Channel.Pipeline.PipelineSchedule.${index}.EndTime`);
    const StartTime = watch(
      `Channel.Pipeline.PipelineSchedule.${index}.StartTime`
    );

    if (EndTime && StartTime !== null && EndTime < StartTime) {
      return t('pages.manageChannel.endDateCheck');
    } else {
      return '';
    }
  };

  const validateRequiredFields = () => {
    let isValid = true;

    fields.forEach((item: Record<'id', string>, index: number) => {
      const EndTime = watch(
        `Channel.Pipeline.PipelineSchedule.${index}.EndTime`
      );
      const StartTime = watch(
        `Channel.Pipeline.PipelineSchedule.${index}.StartTime`
      );
      if (StartTime === null) {
        isValid = false;
      }
      if (StartTime !== null && EndTime !== null && EndTime < StartTime) {
        setDateError(true);
      } else {
        setDateError(false);
      }
    });

    if (showSchedule === true && pipelineSchedules?.length > 0) {
      pipelineSchedules?.map((value: PipelineScheduleInput) => {
        if (
          value.Name === '' ||
          startDateError === true ||
          value.StartTime === null ||
          endDateError === true ||
          intervalValid === false ||
          dateError === true ||
          isNameDuplicate === true
        ) {
          isValid = false;
        }
      });
      pipelineSchedules?.map((value: PipelineScheduleInput) => {
        if (
          value.Frequency === 'Day' ||
          value.Frequency === 'Week' ||
          value.Frequency === 'Month'
        ) {
          if (
            value?.RecurrenceSchedule?.Hours?.length === 0 ||
            value?.RecurrenceSchedule?.Minutes?.length === 0
          ) {
            isValid = false;
          }
        }
      });
    }

    setChannelScheduleValid(isValid);
  };

  useEffect(() => {
    validateRequiredFields();
  });

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedValue(event.target.value);
    if (event.target.value === 'no') {
      if (pipelineSchedules[0].Id === '') {
        setValue(`Channel.Pipeline.PipelineSchedule`, []);
      } else {
        fields.forEach((item: Record<'id', string>, index: number) => {
          setValue(`Channel.Pipeline.PipelineSchedule.${index}.IsDelete`, true);
        });
      }
      setShowSchedule(false);
      setShowNewSchedule(false);
    } else if (event.target.value === 'yes') {
      if (pipelineSchedules.length > 0) {
        fields.forEach((item: Record<'id', string>, index: number) => {
          setValue(
            `Channel.Pipeline.PipelineSchedule.${index}.IsDelete`,
            false
          );
        });
      } else {
        setValue(`Channel.Pipeline.PipelineSchedule`, [
          pipelineScheduleDefault,
        ]);
      }
      setShowSchedule(true);
      setShowNewSchedule(true);
    }
  };

  useEffect(() => {
    if (
      (intialRender === false && action === ActionType.CREATE) ||
      (intialRender === false &&
        action === ActionType.EDIT &&
        pipelineSchedules[0]?.Name === '')
    ) {
      setShowSchedule(false);
      setValue(`Channel.Pipeline.PipelineSchedule`, []);
    }

    if (checkedValue === 'yes') {
      setShowNewSchedule(true);
    }
    setIntialRender(true);
  }, [
    action,
    intialRender,
    pipelineSchedules,
    setShowSchedule,
    setValue,
    showNewSchedule,
    setShowNewSchedule,
    checkedValue,
  ]);

  useEffect(() => {
    if (
      intialRenderTask === false &&
      parametersData &&
      (action === ActionType.CREATE ||
        (action === ActionType.EDIT && parameterValues?.length === 0)) &&
      stageNames &&
      setParameterValues
    ) {
      const List = JSON.parse(JSON.stringify(currentData));
      setCheckedValue('no');
      setValue(`Channel.Pipeline.PipelineSchedule`, [taskScheduleDefault]);
      setParameterValues([List as unknown as ConfigurationResponse[]]);

      setIntialRenderTask(true);
    } else if (
      action === ActionType.EDIT &&
      !intialRenderTask &&
      currentData &&
      parameterValues &&
      setParameterValues
    ) {
      const ParameterValues = () => {
        if (parameterValues && currentData) {
          const filteredValues: ConfigurationResponse[] = [];
          const getPlatformIds = currentData.map(function (item) {
            return item.platformConfigurationId;
          });

          parameterValues.map(function (itemValue, indexValue) {
            const filterValues = parameterValues[indexValue].filter(function (
              e
            ) {
              return getPlatformIds.includes(e.platformConfigurationId);
            });
            filteredValues.push(
              filterValues as unknown as ConfigurationResponse
            );
          });

          const testing = filteredValues.map(function (item) {
            const testingArray = [
              ...(item as unknown as ConfigurationResponse[]),
              ...currentData,
            ];

            const test = testingArray.filter(
              (v, i, a) =>
                a.findIndex(
                  (v2) =>
                    v2.platformConfigurationId === v.platformConfigurationId
                ) === i
            );

            const keepOrder = test.sort((a, b) => {
              return (
                currentData.findIndex(
                  (p) => p.platformConfigurationId === a.platformConfigurationId
                ) -
                currentData.findIndex(
                  (p) => p.platformConfigurationId === b.platformConfigurationId
                )
              );
            });
            return keepOrder;
          });

          return testing;
        }
      };
      setParameterValues(
        ParameterValues() as unknown as ConfigurationResponse[][]
      );
      setIntialRenderTask(true);
      setShowNewSchedule(true);
    }
  }, [
    intialRenderTask,
    parametersData,
    setValue,
    setCurrentData,
    currentData,
    action,
    stageNames,
    setParameterValues,
    parameterValues,
    setCheckedValue,
  ]);

  if (
    (intialRenderTask && taskSchedule && pipelineSchedules !== null) ||
    (!taskSchedule && pipelineSchedules !== null)
  ) {
    return (
      <Grid container>
        <Card
          sx={{ minWidth: 275, maxWidth: 800, backgroundColor: 'transparent' }}
        >
          <Grid item xs={12} mb={2}>
            <Typography variant="h3">
              {taskSchedule
                ? t('pages.manageChannel.scheduleTask')
                : t('pages.manageChannel.scheduleChannel')}
            </Typography>
            <Box sx={{ display: 'flex' }}>
              <Radio
                checked={checkedValue === 'no'}
                onChange={handleRadioChange}
                value="no"
                color="primary"
                id="noRadio"
                label={t('pages.manageChannel.noSchedule')}
              />
              <Radio
                checked={checkedValue === 'yes'}
                onChange={handleRadioChange}
                value="yes"
                color="primary"
                id="yesRadio"
                label={t('pages.manageChannel.createSchedule')}
              />
            </Box>
          </Grid>

          {showSchedule === true && (
            <>
              {fields.map((pipeline: PipelineScheduleInput, index: number) => (
                <Fragment key={`${index}_container`}>
                  <Grid
                    sx={{
                      backgroundColor: '#fff',
                    }}
                  >
                    <Grid
                      sx={{
                        marginLeft: '10px',
                        marginRight: '10px',
                      }}
                    >
                      {index !== 0 ? (
                        <Grid
                          container
                          sx={{
                            marginTop: '1%',
                          }}
                        >
                          <Grid item xs={12} md={6} mt={1} mb={0}>
                            <Controller
                              control={control}
                              rules={{
                                required: true,
                              }}
                              name={`Channel.Pipeline.PipelineSchedule.${index}.Name`}
                              render={({ field: { value, onChange } }) => {
                                return (
                                  <Input
                                    required
                                    id="channelScheduleName"
                                    key={index}
                                    label={t(
                                      'pages.manageChannel.channelScheduleName'
                                    )}
                                    value={value}
                                    sx={{ width: '100%', maxWidth: '20em' }}
                                    disabled={
                                      action === ActionType.EDIT &&
                                      pipeline.Id === null
                                    }
                                    onChange={(event) => {
                                      onChange(event.target.value);
                                      setValue(
                                        `Channel.Pipeline.PipelineSchedule.${index}.Id`,
                                        event.target.value
                                      );
                                      setValue(
                                        `Channel.Pipeline.PipelineSchedule.${index}.PipelineReferenceName`,
                                        event.target.value
                                      );
                                    }}
                                  />
                                );
                              }}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            lg={1.5}
                            mt={2.5}
                            ml={0}
                            sx={{
                              marginLeft: '0%',
                            }}
                          >
                            <IconButton
                              onClick={() => {
                                remove(index);
                              }}
                              aria-label="delete"
                              color="default"
                              size="large"
                            >
                              <CancelRoundedIcon fontSize="medium" />
                            </IconButton>
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid container>
                          <Grid item xs={12} md={4} mt={1} mb={0}>
                            <Controller
                              control={control}
                              rules={{
                                required: true,
                              }}
                              name={`Channel.Pipeline.PipelineSchedule.${index}.Name`}
                              render={({ field: { value, onChange } }) => {
                                return (
                                  <Input
                                    required
                                    id="channelScheduleName"
                                    key={index}
                                    label={t(
                                      'pages.manageChannel.channelScheduleName'
                                    )}
                                    value={value}
                                    disabled={
                                      action === ActionType.EDIT &&
                                      pipeline.Id === null
                                    }
                                    sx={dateTimeStyle}
                                    onChange={(event) => {
                                      onChange(event.target.value);
                                      setValue(
                                        `Channel.Pipeline.PipelineSchedule.${index}.Id`,
                                        event.target.value
                                      );
                                      setValue(
                                        `Channel.Pipeline.PipelineSchedule.${index}.PipelineReferenceName`,
                                        event.target.value
                                      );
                                    }}
                                  />
                                );
                              }}
                            />
                          </Grid>
                          <Grid item md={1.5}></Grid>
                        </Grid>
                      )}
                      {taskSchedule && (
                        <Grid container mt={2}>
                          <Grid
                            item
                            xs={12}
                            md={8}
                            mb={0}
                            sx={{
                              padding: '15px',
                            }}
                          >
                            {parameterValues?.[index]?.map(
                              (data: ConfigurationResponse, indexData) => (
                                <Box key={indexData}>
                                  {showButtons &&
                                    indexEdit === indexData &&
                                    indexSchedule === index && (
                                      <Button
                                        id="saveParameters"
                                        disabled={false}
                                        onClick={() => {
                                          setShowButtons(false);
                                        }}
                                      >
                                        {t(
                                          'pages.manageChannel.saveParameters'
                                        )}
                                      </Button>
                                    )}
                                  <IconButton
                                    onClick={() => {
                                      setIndexEdit(indexData);
                                      setIndexSchedule(index);
                                      setShowButtons(true);
                                      setSelectedRecord(data);
                                    }}
                                    sx={recordIcons}
                                    aria-label="edit-button"
                                    title={t(
                                      'pages.configRecordEditor.editIcon'
                                    )}
                                    disabled={showButtons}
                                  >
                                    <EditIcon sx={{ fontSize }} />
                                  </IconButton>
                                  {stageNames && (
                                    <Typography variant="h3">
                                      {`stage: ${stageNames[indexData]}`}
                                    </Typography>
                                  )}
                                  <DynamicAttributes
                                    currentData={data}
                                    currentSchema={data}
                                    editDisabled={
                                      indexEdit !== indexData || !showButtons
                                    }
                                    errors={{}}
                                  />
                                </Box>
                              )
                            )}
                          </Grid>
                        </Grid>
                      )}

                      <Grid container mt={2}>
                        <Grid item xs={12} sm={6} md={6} mb={0}>
                          <Controller
                            control={control}
                            rules={{
                              required: true,
                            }}
                            name={`Channel.Pipeline.PipelineSchedule.${index}.StartTime`}
                            render={({ field: { value, onChange } }) => {
                              return (
                                <DateTimePicker
                                  required
                                  id="date-start-time"
                                  fullWidth={true}
                                  key={index}
                                  label={t('pages.manageChannel.startTime')}
                                  value={value as unknown as Date}
                                  sx={{ width: '100%', maxWidth: '20em' }}
                                  onError={(reason) => {
                                    setStartDateError(reason ? true : false);
                                  }}
                                  handleChange={onChange}
                                />
                              );
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} mb={0}>
                          <Controller
                            control={control}
                            name={`Channel.Pipeline.PipelineSchedule.${index}.EndTime`}
                            render={({ field: { value, onChange } }) => {
                              return (
                                <DateTimePicker
                                  id="date-end-time"
                                  fullWidth={true}
                                  key={index}
                                  label={t('pages.manageChannel.endTime')}
                                  sx={{ width: '100%', maxWidth: '20em' }}
                                  value={(value as unknown as Date) || null}
                                  helperText={handleDateText(index)}
                                  onError={(reason) => {
                                    setEndDateError(reason ? true : false);
                                  }}
                                  handleChange={onChange}
                                />
                              );
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container mt={2}>
                        <Frequency
                          index={index}
                          setIntervalValid={setIntervalValid}
                          control={control}
                          watch={watch}
                        />
                      </Grid>

                      <br />
                      {(() => {
                        switch (
                          watch(
                            `Channel.Pipeline.PipelineSchedule.${index}.Frequency`
                          )
                        ) {
                          case 'Week':
                            return (
                              <>
                                <Grid item lg={6} md={6} mt={2}>
                                  <WeekDays
                                    index={index}
                                    control={control}
                                    watch={watch}
                                  />
                                </Grid>
                                <br />
                                <Grid item lg={12} md={12} mt={2}>
                                  <ExecuteTimes
                                    index={index}
                                    watch={watch}
                                    control={control}
                                    setValue={setValue}
                                  />
                                </Grid>
                              </>
                            );
                          case 'Day':
                            return (
                              <Grid item lg={12} md={12} mt={2}>
                                <ExecuteTimes
                                  index={index}
                                  watch={watch}
                                  control={control}
                                  setValue={setValue}
                                />
                              </Grid>
                            );
                          case 'Month':
                            return (
                              <>
                                <Grid
                                  item
                                  lg={6}
                                  md={12}
                                  mt={2}
                                  sx={{
                                    maxWidth: '100% !important',
                                  }}
                                >
                                  <MonthDays
                                    index={index}
                                    control={control}
                                    watch={watch}
                                  />
                                </Grid>
                                <br />
                                <Grid item lg={12} md={12} mt={2}>
                                  <ExecuteTimes
                                    index={index}
                                    watch={watch}
                                    control={control}
                                    setValue={setValue}
                                  />
                                </Grid>
                              </>
                            );
                          default:
                            return null;
                        }
                      })()}
                    </Grid>
                  </Grid>
                </Fragment>
              ))}
            </>
          )}
        </Card>
        {showNewSchedule && (
          <Grid item xs={12} pt={2} mb={2}>
            <Button
              id="addChannelFile"
              disabled={false}
              onClick={() => {
                append({
                  Id: '',
                  Name: '',
                  StartTime: new Date(),
                  EndTime: null,
                  Interval: 1,
                  Frequency: 'Hour',
                  PipelineReferenceName: '',
                  IsDelete: false,
                  RecurrenceSchedule: {
                    Minutes: [],
                    Hours: [],
                    WeekDays: [],
                    MonthDays: [],
                  },
                });
                const values = parameterValues;
                values?.push(currentData as unknown as ConfigurationResponse[]);
                if (setParameterValues && values) {
                  setParameterValues(values);
                }
              }}
            >
              {t('pages.manageChannel.addSchedule')}
            </Button>
          </Grid>
        )}
      </Grid>
    );
  }

  if (selectedRecord === undefined) {
    return <Loading />;
  }
  return <></>;
}
export default ChannelSchedule;
