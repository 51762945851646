import { useEffect } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { IHeader, setHeader } from 'redux/contentSlice';
import { useAppDispatch } from 'redux/hooks';
import RunHistory from './RunHistoryTable';

function StagesDetailsPage(): JSX.Element {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { taskId, taskName, taskVersion, taskRunHistoryId, currentModule } =
    useParams<{
      taskId: string;
      taskName: string;
      taskVersion: string;
      taskRunHistoryId: string;
      currentModule: string;
    }>();

  useEffect(() => {
    const headerData: IHeader = {
      pageTitle: t('pages.manageChannel.taskStageDetailsPage'),
      previousPage: t('pages.manageChannel.taskDetailsPage'),
      route: `scheduledTaskDetails/${taskId}/${taskName}/${currentModule}`,
      icon: {
        props: { fill: 'black' },
        icon: 'assignmentIcon',
        fontSize: 'large',
      },
      data: [
        {
          id: 'task-schedule-name',
          first: true,
          label: t('pages.manageChannel.ScheduleTaskName'),
          value: taskName,
        },
        {
          id: 'task-schedule-version',
          label: t('pages.manageChannel.taskVersionValue'),
          value: taskVersion,
        },
        {
          id: 'task-run-history-id',
          label: t('pages.manageChannel.runId'),
          value: taskRunHistoryId,
        },
      ],
    };
    dispatch(setHeader(headerData));
  }, [
    dispatch,
    t,
    taskId,
    taskName,
    taskVersion,
    taskRunHistoryId,
    currentModule,
  ]);

  return (
    <>
      <Grid container>
        <Grid item xs={12} mb={3}>
          <RunHistory taskRunHistoryId={taskRunHistoryId || ''} />
        </Grid>
      </Grid>
    </>
  );
}

export default StagesDetailsPage;
