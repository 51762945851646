import { Box, Divider, Grid, Stack } from '@mui/material';
import { Button, Input, TextArea } from '@revenue-solutions-inc/revxcoreui';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CustomTableRepeatingAttributes from './CustomTableRepeatingAttributes';

import { WorkflowFormObject } from '../WorkflowEditor.types';

const tableBase = {
  WorkflowTable: '',
  WorkflowTableDescription: '',
  DataMap: [],
};

interface CustomTableAttributesProps {
  isEditMode: boolean;
}

function CustomTableAttributes({ isEditMode }: CustomTableAttributesProps) {
  const { t } = useTranslation();
  const { control } = useFormContext<WorkflowFormObject>();

  const {
    fields: tableFields,
    append: appendTableField,
    remove: removeTableField,
  } = useFieldArray({
    control,
    name: `WorkflowTables`,
  });

  return (
    <Grid
      id="custom-attributes-button-row"
      item
      xs={12}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
      }}
    >
      <Button
        size="small"
        sx={{ mt: '.5em', mb: '.5em' }}
        id={'addEditRow'}
        onClick={() => {
          appendTableField({ ...tableBase });
        }}
        disabled={!isEditMode}
      >
        {t('pages.attributes.addTable')}
      </Button>

      {tableFields.map((attr, attributeIndex) => {
        return (
          <Box key={attr.id}>
            <Grid item xs={12}>
              <Stack
                direction="row"
                alignItems="start"
                justifyContent="space-between"
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Controller
                    name={`WorkflowTables.${attributeIndex}.WorkflowTable`}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Input
                        disabled={!isEditMode}
                        id={`WorkflowTables-WorkflowTable`}
                        label={t('pages.attributes.tabName')}
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                  <Controller
                    name={`WorkflowTables.${attributeIndex}.WorkflowTableDescription`}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextArea
                        multiline
                        disabled={!isEditMode}
                        id={`WorkflowTables-WorkflowTable`}
                        label={'Description'}
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                </Box>
                {tableFields.length > 1 && (
                  <Button
                    size="small"
                    type="secondary"
                    sx={{ ml: 2, mt: '.5em', mb: '.5em' }}
                    id={'addEditRow'}
                    onClick={() => {
                      removeTableField(attributeIndex);
                    }}
                    disabled={!isEditMode}
                  >
                    {t('pages.attributes.removeTable')}
                  </Button>
                )}
              </Stack>
            </Grid>
            <CustomTableRepeatingAttributes
              attributeIndex={attributeIndex}
              editDisabled={!isEditMode}
            />
            <Divider />
          </Box>
        );
      })}
    </Grid>
  );
}

export default CustomTableAttributes;
