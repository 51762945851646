import { Dispatch, SetStateAction } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import DataCard from 'components/DataCard';
import DataTable from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import { CalendarMonth } from '@mui/icons-material';
import { Button } from '@revenue-solutions-inc/revxcoreui';
import { Account, CreateAccountPeriodResponse } from 'generated/graphql';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';
import { getDate } from 'common/helpers';
import CurrencyCell from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/TableCells/CurrencyCell';
import { useTheme } from '@mui/system';

interface Props {
  accountPeriods: CreateAccountPeriodResponse[];
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  accountData: Account;
}

function AccountPeriodsDataCard({
  accountPeriods,
  setOpenModal,
  accountData,
}: Props) {
  const { t } = useTranslation();
  const theme = useTheme();

  const getFilingFrequency = () => {
    if (accountData && accountData.group) {
      return accountData?.group
        ?.filter(
          (attribute) => attribute?.groupName?.toLocaleLowerCase() === 'other'
        )[0]
        ?.attribute?.filter(
          (attr) =>
            attr.attributeName === 'periodFrequency' ||
            attr.attributeName === 'filingFrequency'
        )[0]?.attributeValue;
    }
  };

  const createPeriodBtn = (): JSX.Element => {
    return (
      <Box sx={{ width: 90 }}>
        <Button
          id="create-period-link"
          type="secondary"
          variant="text"
          onClick={() => {
            setOpenModal(true);
          }}
        >
          {t('pages.accountSummary.addPeriod')}
        </Button>
      </Box>
    );
  };

  const columns: ColumnDef<CreateAccountPeriodResponse>[] = [
    {
      id: 'beginDate',
      accessorKey: 'beginDate',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.accountSummary.filingPeriod')}
        />
      ),
      cell: ({ getValue, row }) => (
        <Box
          sx={() => {
            return {
              a: {
                color: theme.palette.linkBlue.dark,
              },
            };
          }}
        >
          <Link
            to={{
              pathname: `${location.pathname}/period/${row.original.id}`,
            }}
          >
            {getDate(getValue() as string)}
            {' - '}
            {getDate(row.original.endDate as string)}
          </Link>
        </Box>
      ),
    },
    {
      id: 'balance',
      accessorKey: 'balance',
      header: () => (
        <HeaderColumnNext localization={t('pages.accountSummary.balance')} />
      ),
      cell: ({ getValue }) => {
        return (
          <Box sx={{ width: '3.5em' }}>
            <CurrencyCell
              invalidValue={t('pages.tableCell.invalidValue')}
              amountString={getValue() as string}
            />
          </Box>
        );
      },
    },
    {
      id: 'status',
      accessorKey: 'status',
      header: () => (
        <HeaderColumnNext localization={t('pages.accountSummary.status')} />
      ),
    },
  ];

  return (
    <DataCard
      title={`${t(
        'pages.accountSummary.sectionTitles.periods'
      )} - (${getFilingFrequency()})`}
      avatar={<CalendarMonth sx={{ fill: theme.palette.primary.main }} />}
      action={createPeriodBtn()}
      children={
        <>
          {accountPeriods?.length > 0 && (
            <DataTable
              data={accountPeriods}
              columns={columns as ColumnDef<Record<string, unknown>>[]}
              enableGlobalFilter={false}
            />
          )}
          {accountPeriods?.length === 0 && t('pages.accountSummary.noPeriods')}
        </>
      }
    />
  );
}
export default AccountPeriodsDataCard;
