import { Fragment } from 'react';
import { Divider, Grid, Stack } from '@mui/material';
import { Button } from '@revenue-solutions-inc/revxcoreui';
import { UseFormTrigger, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ControlledInputField from 'components/controls/ControlledInputField';
import { useAttributeValidations } from 'hooks/useAttributeValidations';
import { WorkflowFormObject } from '../WorkflowEditor.types';
import { RepeatingAttributes } from '../RepeatingAttributes';

const newCustomAttribute = {
  attributeDescription: '',
  attributeDisplayName: '',
  attributeName: 'New Attribute',
  attributeType: '',
  attributeValue: '',
  dataSource: '',
  extensibleBusinessDriver: [],
  isOOTB: false,
  isOOTBEditable: false,
  possibleValues: [],
  repeatingValue: [],
  repeatingProperties: false,
};

interface Props {
  editDisabled: boolean;
  grpIndex: number;
  isCustomTables: boolean;
  isEditMode?: boolean;
  triggerValidation?: UseFormTrigger<WorkflowFormObject>;
}

function CustomAttributes({
  editDisabled,
  grpIndex,
  isCustomTables,
  isEditMode,
  triggerValidation,
}: Props) {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext<WorkflowFormObject>();

  const {
    fields: attributes,
    append,
    remove,
  } = useFieldArray({
    control,
    name: `workflowConfig.${grpIndex}.attribute`,
  });

  const { checkIsRequired } = useAttributeValidations();

  return (
    <>
      <Grid id="custom-attributes-button-row" item xs={12}>
        <Button
          size="small"
          sx={{ mt: '.5em', mb: '.5em' }}
          id={'addEditRow'}
          onClick={() => {
            append(newCustomAttribute);
          }}
          disabled={editDisabled}
        >
          {isCustomTables
            ? t('pages.attributes.addTable')
            : t('pages.attributes.addTab')}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>

      {attributes.map((attr, attributeIndex) => {
        const rules = {
          requried: {
            value: checkIsRequired(attr),
            message: `${attr.attributeDisplayName} ${t(
              'pages.workflowEditor.required'
            )}`,
          },
        };

        return (
          <Fragment key={attr.id}>
            <Grid
              id={`CustomAttributes:${attr.attributeDisplayName}`}
              item
              xs={12}
            >
              <Stack
                spacing={3}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <ControlledInputField
                  rules={rules}
                  defaultValue={attr.attributeName ?? ''}
                  name={`workflowConfig.${grpIndex}.attribute.${attributeIndex}.attributeDisplayName`}
                  control={control}
                  disabled={editDisabled}
                  id={`${attr.attributeName}-${attributeIndex}`}
                  label={
                    isCustomTables
                      ? t('pages.attributes.tableName')
                      : t('pages.attributes.tabName')
                  }
                />
                {attributes.length > 1 && (
                  <Button
                    size="small"
                    type="secondary"
                    sx={{ ml: 2, mt: '.5em', mb: '.5em' }}
                    id={'addEditRow'}
                    onClick={() => {
                      remove(attributeIndex);
                    }}
                    disabled={editDisabled}
                  >
                    {isCustomTables
                      ? t('pages.attributes.removeTable')
                      : t('pages.attributes.removeTab')}
                  </Button>
                )}
              </Stack>
            </Grid>
            <RepeatingAttributes
              triggerValidation={triggerValidation}
              grpIndex={grpIndex}
              attributeIndex={attributeIndex}
              control={control}
              editDisabled={editDisabled}
              setValue={setValue}
              isCustomTables={isCustomTables}
              enableHiddenAttribute={true}
              isEditMode={isEditMode}
            />
          </Fragment>
        );
      })}
    </>
  );
}

export default CustomAttributes;
