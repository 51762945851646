import { useEffect } from 'react';
import ApprovalsTable from 'components/Approvals/ApprovalsTable';
import { useTranslation } from 'react-i18next';
import { setHeader } from 'redux/contentSlice';
import { useAppDispatch } from 'redux/hooks';

const ApprovalsPage = () => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(setHeader({ pageTitle: t('pages.approvals.title') }));
  }, [dispatch, t]);

  return (
    <div data-testid="approvals-page">
      <ApprovalsTable />
    </div>
  );
};

export default ApprovalsPage;
