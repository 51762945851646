import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { Link } from '@mui/material';
import { Card, CardHeader } from '@revenue-solutions-inc/revxcoreui';
import { Box, Theme } from '@mui/system';

interface Props {
  setActive: React.Dispatch<React.SetStateAction<string>>;
  title: string;
  children?: JSX.Element;
  activeCard: string;
}

function PropertyCardLayout({
  setActive,
  title,
  children,
  activeCard,
}: Props): JSX.Element {
  return (
    <Box>
      <Card
        sx={{
          borderRadius: '5px',
          boxShadow: '0px 0px 5px rgb(0 0 0 / 30%)',
        }}
      >
        <CardHeader
          sx={(theme: Theme) => {
            return {
              backgroundColor: theme.palette.white.main,
              height: '40px',
            };
          }}
          action={
            <Link href="#">
              {
                <OpenInFullIcon
                  onClick={() => {
                    setActive(activeCard);
                  }}
                  fontSize="inherit"
                  sx={(theme: Theme) => {
                    return {
                      fill: theme.palette.linkBlue.main,
                    };
                  }}
                />
              }
            </Link>
          }
          title={title}
        />
        {children}
      </Card>
    </Box>
  );
}

export default PropertyCardLayout;
