import { Fragment } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import ControlledField from 'components/ControlledField';
import { processAddressForm } from 'components/entityManagement/common/entityManagementUtils';
import { UseFormReturn, useFieldArray, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Section } from 'types/forms';

interface Props {
  register: UseFormReturn['register'];
  control: UseFormReturn['control'];
  setValue: UseFormReturn['setValue'];
  getValues: UseFormReturn['getValues'];
  section: Section;
  isRequired?: boolean;
  showOptional?: boolean;
  handleShowOptional?: (display: boolean) => void;
}

const formLayout = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  overflow: 'auto',
  marginTop: '25px',
  marginBottom: '30px',
};

const elementTitle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
};

function SectionContainer({
  control,
  section,
  isRequired = true,
  showOptional = false,
  setValue,
  handleShowOptional,
}: Props): JSX.Element {
  let activeSection = section;
  const { t } = useTranslation();

  const countryValue = useWatch({
    control,
    name: `${section.sectionIdentifier}.0.countryId`,
  });

  const { fields } = useFieldArray({
    control,
    name: section.sectionIdentifier,
  });

  if (section.sectionIdentifier === 'addresses') {
    activeSection = processAddressForm(section, countryValue);
  }

  const handleOptionalForm = (active: boolean) => () => {
    setValue(section.sectionIdentifier, [{}]);
    if (handleShowOptional) {
      handleShowOptional(active);
    }
  };

  if (!isRequired) {
    if (!showOptional) {
      return (
        <Button
          id={'add-new-' + section.sectionTitle}
          type="secondary"
          sx={{
            marginTop: '10px',
            marginBottom: '15px',
            marginRight: '15px',
          }}
          onClick={handleOptionalForm(true)}
        >
          {t('pages.createAsset.buttonTitle')} {section.sectionTitle}
        </Button>
      );
    }
    return (
      <>
        {fields.map((item, index) => {
          return (
            <Fragment key={item.id}>
              <Box sx={formLayout}>
                <Box sx={elementTitle}>
                  <Typography variant="h2">{section.sectionTitle}</Typography>
                  <IconButton
                    aria-label="delete"
                    onClick={handleOptionalForm(false)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
                <ControlledField
                  control={control}
                  section={activeSection}
                  setValue={setValue}
                  haveIndex={true}
                  index={index}
                />
              </Box>
            </Fragment>
          );
        })}
      </>
    );
  }

  return (
    <>
      {fields.map((item, index) => {
        return (
          <Fragment key={item.id}>
            <Box sx={formLayout}>
              <Typography sx={{ marginBottom: '15px' }} variant="h2">
                {section.sectionTitle}
              </Typography>
              <ControlledField
                control={control}
                section={activeSection}
                setValue={setValue}
                haveIndex={true}
                index={index}
              />
            </Box>
          </Fragment>
        );
      })}
    </>
  );
}

export default SectionContainer;
