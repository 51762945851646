import { useEffect } from 'react';

import { Card, CardContent } from '@revenue-solutions-inc/revxcoreui';
import CreateEntityLayout from 'components/entityManagement/entities/create/EntityLayout';
import { useTranslation } from 'react-i18next';
import { setHeader } from 'redux/contentSlice';
import { useAppDispatch } from 'redux/hooks';

function CreateEntity(): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setHeader({ pageTitle: t('pages.createEntity.title') }));
  }, [dispatch, t]);

  return (
    <Card>
      <CardContent>
        <CreateEntityLayout />
      </CardContent>
    </Card>
  );
}

export default CreateEntity;
