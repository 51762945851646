import { ChangeEvent } from 'react';
import {
  ConfigurationDomains,
  ConfigurationModules,
  configDomainsList,
  configModulesList,
} from 'common/platformConfigUtils/platformConfigUtils';
import { Box, SelectChangeEvent, Stack } from '@mui/material';
import { DataDisplay, Input, Select } from '@revenue-solutions-inc/revxcoreui';
import { useTranslation } from 'react-i18next';
import { ConfigurationGroup, ConfigurationResponse } from 'generated/graphql';
import { EditAccessUtil } from 'pages/admin/ConfigTypeEditor/editAccessUtil';

interface Props {
  selectedConfigType: ConfigurationResponse;
  handleChange: (config: ConfigurationResponse) => void;
  editAccess?: EditAccessUtil;
  configGroups: ConfigurationGroup[];
}

function ConfigurationDetails({
  selectedConfigType,
  handleChange,
  editAccess,
  configGroups,
}: Props) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.configTypeEditor',
  });

  const handleDomainChange = (event: SelectChangeEvent<string | number>) => {
    const newConfig = { ...selectedConfigType };
    newConfig.configurationDomain = (event.target as HTMLSelectElement).value;
    handleChange(newConfig);
  };

  const handleModuleChange = (event: SelectChangeEvent<string | number>) => {
    const newConfig = { ...selectedConfigType };
    newConfig.configurationModule = (event.target as HTMLSelectElement).value;
    handleChange(newConfig);
  };

  const handleTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newConfig = { ...selectedConfigType };
    newConfig.configurationType = event.target.value;
    handleChange(newConfig);
  };

  const handleDescChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newConfig = { ...selectedConfigType };
    newConfig.configurationDescription = event.target.value;
    handleChange(newConfig);
  };

  const handleGroupIdChange = (event: SelectChangeEvent<string | number>) => {
    const newConfig = { ...selectedConfigType };
    newConfig.platformConfigurationGroupId = Number(
      (event.target as HTMLSelectElement).value
    );
    handleChange(newConfig);
  };

  return (
    <Box
      sx={{
        pb: '1.5em',
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        flexWrap="wrap"
      >
        {selectedConfigType.platformConfigurationId !== '' && (
          <>
            <DataDisplay
              id="configurationType"
              label={t('configType')}
              data={selectedConfigType.configurationType || ''}
            />
            <DataDisplay
              id="configurationModule"
              label={t('configModule')}
              data={
                ConfigurationModules[
                  Number(selectedConfigType.configurationModule)
                ] ?? selectedConfigType.configurationModule
              }
            />
            <DataDisplay
              id="configurationDomain"
              label={t('configDomain')}
              data={
                ConfigurationDomains[
                  Number(selectedConfigType.configurationDomain)
                ] ?? selectedConfigType.configurationDomain
              }
            />
          </>
        )}
        {selectedConfigType.platformConfigurationId === '' && (
          <>
            <Input
              id="configurationType-details-tool"
              label={t('configType')}
              value={selectedConfigType.configurationType || ''}
              onChange={handleTypeChange}
              sx={{ minWidth: '7.5em', maxWidth: '15em' }}
              required={true}
            />
            <Select
              id="configurationModule-details-config"
              label={t('configModule')}
              value={selectedConfigType.configurationModule}
              onChange={handleModuleChange}
              options={configModulesList}
              sx={{ minWidth: '7.5em' }}
              required={true}
            />
            <Select
              id="configurationDomain-manage-tool"
              label={t('configDomain')}
              value={selectedConfigType.configurationDomain}
              onChange={handleDomainChange}
              options={configDomainsList}
              sx={{ minWidth: '7.5em' }}
              required={true}
            />
          </>
        )}
        <Select
          id="configurationGroupId-tool-config"
          label={t('configGroup')}
          options={configGroups.map((option) => {
            return {
              key: String(option.platformConfigurationGroupId),
              desc: option.name,
            };
          })}
          value={String(selectedConfigType.platformConfigurationGroupId || '')}
          onChange={(event) => {
            handleGroupIdChange(event);
          }}
          sx={{ minWidth: '7.5em', maxWidth: '12em' }}
          required={true}
          disabled={!editAccess?.isFullAccess}
        />
      </Stack>
      <Stack>
        <Input
          id="configurationDescription-tool-manage"
          label={t('configDesc')}
          value={selectedConfigType.configurationDescription || ''}
          onChange={handleDescChange}
          sx={{ minWidth: '10em', flexGrow: '1' }}
          required={false}
          disabled={!editAccess?.controlExtendedGroup()}
        />
      </Stack>
    </Box>
  );
}

export default ConfigurationDetails;
