import { Fragment, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import { ConfigurationDomains } from 'common/platformConfigUtils/platformConfigUtils';
import ControlledField from 'components/ControlledField';
import { getNameFieldsByType } from 'components/entityManagement/common/entityUtils';
import { processAddressForm } from 'components/entityManagement/common/entityManagementUtils';
import { getDefault } from 'components/entityManagement/common/defaults/entity';
import SectionTitle from 'components/entityManagement/common/SectionTitle';
import Loading from 'components/Loading';
import { useGetLookupConfigurationQuery } from 'generated/graphql';
import { UseFormReturn, useFieldArray, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Section } from 'types/forms';

import SectionSummary from '../SectionSummary';

interface Props {
  register: UseFormReturn['register'];
  control: UseFormReturn['control'];
  setValue: UseFormReturn['setValue'];
  getValues: UseFormReturn['getValues'];
  watch?: UseFormReturn['watch'];
  handleStepValidation: () => Promise<void>;
  handleShowOptional?: (display: boolean, name: string) => void;
  validatedSteps: { [k: number]: boolean };
  currentStep: number;
  entitySection: Section;
  isRequired?: boolean;
  showOptional?: boolean;
}

const formLayout = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  overflow: 'auto',
  marginTop: '25px',
};

const elementTitle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
};

function SectionContainer({
  control,
  setValue,
  getValues,
  watch,
  handleStepValidation,
  handleShowOptional,
  validatedSteps,
  currentStep,
  entitySection,
  isRequired = true,
  showOptional = false,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [activeSection, setActiveSection] = useState<Section>(entitySection);
  const isOther = entitySection.sectionIdentifier === 'others';

  const nameTypeValue = useWatch({
    control,
    name: `${entitySection.sectionIdentifier}.${activeIndex}.NameType`,
  });

  const countryValue = useWatch({
    control,
    name: `${entitySection.sectionIdentifier}.${activeIndex}.countryId`,
  });

  const { fields, remove } = useFieldArray({
    control,
    name: entitySection.sectionIdentifier,
  });

  const { isFetching, refetch } = useGetLookupConfigurationQuery(
    {
      configurationDomain: ConfigurationDomains.ReferenceSchema,
      configurationType: 'NameType',
    },
    {
      enabled: nameTypeValue !== '' && nameTypeValue !== undefined,
      onSuccess: (configData) => {
        const newNameFields = getNameFieldsByType(
          configData,
          nameTypeValue,
          activeSection
        );
        setActiveSection(newNameFields);
      },
    }
  );

  const addSection = () => {
    if (validatedSteps[currentStep]) {
      let isPrimaryValue = 'false';
      if (fields.length === 0) isPrimaryValue = 'true';
      setValue(entitySection.sectionIdentifier, [
        ...getValues(entitySection.sectionIdentifier),
        {
          ...getDefault(entitySection.sectionIdentifier),
          isPrimary: isPrimaryValue,
        },
      ]);
      setActiveIndex(fields.length);
      if (!isRequired && handleShowOptional) {
        handleShowOptional(true, entitySection.sectionIdentifier);
      }
    }
  };

  const removeSection = (index: number) => {
    if (fields.length > 1) {
      remove(index);
      setActiveIndex(fields.length - 2);
    }
    if (!isRequired && showOptional && fields.length === 1) {
      remove(index);
      setActiveIndex(0);
      if (handleShowOptional) {
        handleShowOptional(false, entitySection.sectionIdentifier);
      }
    }
  };

  const highlightActiveIndex = async (newIndex: number) => {
    await handleStepValidation();
    if (validatedSteps[currentStep]) {
      setActiveIndex(newIndex);
    }
  };

  const showAnotherInAddBtn = (): string => {
    if (isRequired || (showOptional && fields.length > 0))
      return t('pages.createEntity.another') + ' ';
    return '';
  };

  useEffect(() => {
    if (
      nameTypeValue !== '' &&
      nameTypeValue !== undefined &&
      entitySection.sectionIdentifier === 'names'
    ) {
      refetch();
    }
  }, [nameTypeValue, entitySection.sectionIdentifier, refetch]);

  useEffect(() => {
    if (
      countryValue !== '' &&
      countryValue !== undefined &&
      entitySection.sectionIdentifier === 'addresses'
    ) {
      const updatedAddressSection = processAddressForm(
        activeSection,
        countryValue
      );
      setActiveSection(updatedAddressSection);
    }
  }, [activeSection, countryValue, entitySection.sectionIdentifier]);

  useEffect(() => {
    if (!isRequired && !showOptional) {
      setActiveIndex(0);
      setValue(entitySection.sectionIdentifier, []);
    }
  }, [setValue, isRequired, showOptional, entitySection.sectionIdentifier]);

  return (
    <>
      <SectionSummary
        section={entitySection}
        data={getValues()}
        watch={watch}
        getValues={getValues}
        setValue={setValue}
        indexNotDisplay={activeIndex}
        setActiveItem={highlightActiveIndex}
      />
      {isFetching && <Loading />}
      {fields.map((item, index) => {
        if (index !== activeIndex) return;
        return (
          <Fragment key={item.id}>
            {!isOther ? (
              <Box sx={formLayout}>
                <Box sx={elementTitle}>
                  <SectionTitle
                    section={entitySection}
                    index={index}
                    control={control}
                  />
                  <IconButton
                    aria-label="delete"
                    onClick={() => removeSection(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
                <ControlledField
                  control={control}
                  section={activeSection}
                  setValue={setValue}
                  haveIndex={true}
                  index={index}
                />
              </Box>
            ) : (
              <ControlledField
                control={control}
                section={entitySection}
                setValue={setValue}
                haveIndex={true}
                index={index}
              />
            )}
          </Fragment>
        );
      })}
      {!isOther && (
        <Button
          id={'add-new-' + entitySection.sectionTitle}
          type="secondary"
          sx={{ marginTop: '10px', marginBottom: '15px', marginRight: '15px' }}
          onClick={async () => {
            await handleStepValidation();
            addSection();
          }}
        >
          {t('pages.createEntity.add')} {showAnotherInAddBtn()}
          {entitySection.sectionTitle}
        </Button>
      )}
    </>
  );
}

export default SectionContainer;
