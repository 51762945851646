import { Edit } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DataCard from 'components/DataCard';
import CardAvatar from 'components/entityManagement/common/CardAvatar';
import { useTranslation } from 'react-i18next';
import { ExtendedAttributeValues } from 'types/forms';
import { getFormatDate, toStringDate } from 'utils/date-util';

interface Props {
  extendedData: ExtendedAttributeValues[];
  canEdit?: boolean;
  isEditableByConfig?: boolean;
  openForm?: (type: string) => void;
}

function ExtendedAttributesCard({
  extendedData,
  canEdit = false,
  isEditableByConfig = false,
  openForm,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const displayValue = (type: string, value: string): string => {
    if (type === 'date') return toStringDate(getFormatDate(new Date(value)));
    else if (type === 'boolean') {
      if (value === 'true') return 'Yes';
      else return 'No';
    }
    return value !== '' ? value : '-';
  };

  const displayExtendedSection = (): JSX.Element[] => {
    const sectionElements: JSX.Element[] = [];
    extendedData.forEach((attribute) => {
      sectionElements.push(
        <Grid
          key={attribute.name + '-grid'}
          sx={{ display: 'flex', flexDirection: 'column' }}
          item
          lg={4}
          md={6}
          sm={6}
        >
          <Typography
            key={attribute.name + `-label`}
            id={attribute.name + `-label`}
            variant="h5"
            component="label"
          >
            {attribute.displayName}
          </Typography>
          <Typography
            key={attribute.name + `-value`}
            id={attribute.name + `-value`}
            variant="h3"
            sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
          >
            {displayValue(attribute.type, attribute.value)}
          </Typography>
        </Grid>
      );
    });
    return sectionElements;
  };

  if (extendedData.length === 0) return <></>;
  return (
    <Grid
      key="extended-attributes-card"
      sx={{ display: 'flex' }}
      item
      md={4}
      sm={6}
      order={{ xs: 2, sm: 1, md: -1 }}
    >
      <DataCard
        key="extended-attributes-datacard"
        title={t('pages.createAccount.extendedAttributes')}
        avatar={<CardAvatar sectionName="extended" />}
        action={
          canEdit && isEditableByConfig && openForm ? (
            <IconButton
              id="extended-attributes-iconBtn"
              color="primary"
              aria-label="editExtendedAttr"
              onClick={() => openForm('edit')}
            >
              <Edit />
            </IconButton>
          ) : (
            <></>
          )
        }
        children={
          <Grid container spacing={2}>
            {displayExtendedSection()}
          </Grid>
        }
      />
    </Grid>
  );
}

export default ExtendedAttributesCard;
