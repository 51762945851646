import { Add, Delete, Edit } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import { UseFieldArrayRemove } from 'react-hook-form';
import { Section } from 'types/forms';

interface Props {
  index: number;
  type: string;
  remove: UseFieldArrayRemove;
  handleSubmit: () => void;
  section: Section;
  toggleForm: (index: number, type: string) => void;
}

const ActionButton = ({
  index,
  type,
  remove,
  handleSubmit,
  section,
  toggleForm,
}: Props): JSX.Element => {
  if (type === 'delete')
    return (
      <IconButton
        id={`${type}-${index}-${section.sectionIdentifier}`}
        color="primary"
        aria-label={type}
        onClick={() => {
          // delete the selected row
          remove(index);
          handleSubmit();
        }}
      >
        <Delete />
      </IconButton>
    );
  return (
    <IconButton
      id={`${type}-${index}-${section.sectionIdentifier}`}
      color="primary"
      aria-label={type}
      onClick={() => toggleForm(index, type)}
    >
      {type === 'add' ? <Add /> : <Edit />}
    </IconButton>
  );
};

export default ActionButton;
