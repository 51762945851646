import { gql } from 'graphql-request';

const testExpression = gql`
  mutation TestExpression($expression: ExpressionInput!) {
    testExpression(expression: $expression) {
      Result
    }
  }
`;

export default testExpression;
