import { useEffect } from 'react';

import { Grid } from '@mui/material';
import { MessageType } from '@revenue-solutions-inc/revxcoreui';
import DateCell from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/TableCells/DateCell';
import StatusCell from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/TableCells/StatusCell';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import { ColumnDef } from '@tanstack/react-table';
import Loading from 'components/Loading';
import useGetAccessToken from 'hooks/useGetAccessToken';
import useQueryRequest from 'hooks/useQueryRequest';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { setHeader } from 'redux/contentSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import { TenantConsole } from 'types/tenants';

import { useHasAccess } from 'hooks/useHasAccess';
import { useTheme } from '@mui/system';
import { getTenatListQuery } from './TenantListQuery';

function TenantList(): JSX.Element {
  const { t } = useTranslation();
  const theme = useTheme();
  const accessToken = useGetAccessToken();
  const dispatch = useAppDispatch();
  const module = useAppSelector((state) => state.user.module);

  const { data, error, isFetching } = useQueryRequest<{
    getTenantsList: TenantConsole[];
  }>(['getTenantsList'], accessToken, getTenatListQuery, {});

  const accessViewTenant = useHasAccess('viewTenant', 'view');
  const accessTenantConfig = useHasAccess('tenantConfig', 'view');

  const linkConfig = (pathname: string, tenantName: string) => {
    return (
      <Link
        to={{
          pathname: pathname,
        }}
        style={{ color: theme.palette.linkBlue.dark }}
      >
        {tenantName}
      </Link>
    );
  };

  const Columns: ColumnDef<TenantConsole>[] = [
    {
      id: 'configurationStatus.type',
      header: () => (
        <HeaderColumnNext localization={t('pages.tenantList.status')} />
      ),
      cell: ({ row }) => (
        <StatusCell status={row.original.configurationStatus.type as string} />
      ),
    },

    {
      id: 'configurationStatus.description',
      accessorKey: 'configurationStatus.description',
      header: () => (
        <HeaderColumnNext localization={t('pages.tenantList.statusMessage')} />
      ),
    },
    {
      id: 'tenantName',
      accessorKey: 'tenantName',
      header: () => (
        <HeaderColumnNext localization={t('pages.tenantList.tenantName')} />
      ),
      cell: ({ row }) => {
        let uri = '';
        const tenantName = row.original.tenantName;
        const tenantStatus = row.original.configurationStatus.name;
        if (tenantStatus == 'InDraft') {
          uri = `/${module}/tenantconfig/${row.original.id}`;
          if (accessTenantConfig) return linkConfig(uri, tenantName);
          else return <p>{tenantName}</p>;
        } else {
          uri = `/${module}/tenant/view/${row.original.id}`;
          if (accessViewTenant) return linkConfig(uri, tenantName);
          else return <p>{tenantName}</p>;
        }
      },
    },
    {
      id: 'tenantContact',
      accessorKey: 'tenantContact',
      header: () => (
        <HeaderColumnNext localization={t('pages.tenantList.tenantContact')} />
      ),
    },
    {
      id: 'serviceAdmin',
      accessorKey: 'serviceAdmin',
      header: () => (
        <HeaderColumnNext localization={t('pages.tenantList.serviceAdmin')} />
      ),
    },

    {
      id: 'modules',
      accessorKey: 'modules',
      header: () => (
        <HeaderColumnNext localization={t('pages.tenantList.modules')} />
      ),
      cell: ({ row }) => {
        return row.original.modules.map(
          ({ moduleName }, index) => (index ? ', ' : '') + moduleName
        );
      },
    },
    {
      //TODO: update with email modal
      id: 'messages',
      accessorKey: 'messages',
      header: () => (
        <HeaderColumnNext localization={t('pages.tenantList.messages')} />
      ),
    },
    {
      id: 'creationDate',
      accessorKey: 'creationDate',
      header: () => (
        <HeaderColumnNext localization={t('pages.tenantList.creationDate')} />
      ),
      cell: ({ row }) => <DateCell dateString={row.original.creationDate} />,
    },
    {
      id: 'lastModifiedDate',
      accessorKey: 'lastModifiedDate',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.tenantList.lastModifiedDate')}
        />
      ),
      cell: ({ row }) => (
        <DateCell dateString={row.original.lastModifiedDate} />
      ),
    },
    {
      id: 'lastModifiedBy',
      accessorKey: 'lastModifiedBy',
      header: () => (
        <HeaderColumnNext localization={t('pages.tenantList.lastModifiedBy')} />
      ),
    },
  ];

  useEffect(() => {
    if (error) {
      dispatch(
        addMessage({
          message: t('components.message.networkerror'),
          type: MessageType.Error,
        })
      );
    }
  }, [error, dispatch, t]);

  useEffect(() => {
    if (error) {
      dispatch(
        addMessage({
          message: t('components.message.networkerror'),
          type: MessageType.Error,
        })
      );
    }
  }, [error, dispatch, t]);

  useEffect(() => {
    dispatch(setHeader({ pageTitle: t('pages.tenantList.title') }));
  }, [dispatch, t]);

  //TODO: add error handling
  return (
    <>
      {isFetching && <Loading />}
      {data?.getTenantsList && (
        <Grid container spacing={1}>
          <Grid item xs={12} sx={{ mt: 4 }}>
            <DefaultDataTableNext
              columns={Columns as ColumnDef<Record<string, unknown>, unknown>[]}
              data={data.getTenantsList}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default TenantList;
