import { useState } from 'react';

import { CardContent } from '@mui/material';
import { Box, Theme } from '@mui/system';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import { useTranslation } from 'react-i18next';

import EditOwnerCard from '../EditOwnerCard/EditOwnerCard';
import EditOwnerPanel from '../EditOwnerPanel/EditOwnerPanel';
import EditPropertyCardLayout from '../EditPropertyCardLayout';

// TODO : Remove mock Data (OwnerType and OwnerData) once services are updated
export interface OwnerType {
  date: string;
  id: string;
  name: string;
  percentage: string;
  city: string;
  state: string;
  address: string;
  zipcode: string;
}

const ownerData: OwnerType[] = [
  {
    date: '01/15/2022',
    id: '234349',
    name: 'Jesse James',
    percentage: '100%',
    city: 'Roseville',
    state: 'CA',
    address: '431 market st',
    zipcode: '89876',
  },
  {
    date: '06/01/2020',
    id: '285940',
    name: 'Dave Charles',
    percentage: '100%',
    city: 'Chico',
    state: 'CA',
    address: '541 james st',
    zipcode: '89377',
  },
  {
    date: '11/10/2002',
    id: '234590',
    name: 'Jessica Crawford',
    percentage: '100%',
    city: 'Sacramento',
    state: 'CA',
    address: '632 crossing st',
    zipcode: '58900',
  },
];

interface Props {
  setActive: React.Dispatch<React.SetStateAction<string>>;
}

function EditOwnerContainer({ setActive }: Props): JSX.Element {
  const [editOwnerData, setEditOwnerData] = useState<OwnerType>(ownerData[0]);

  const { t } = useTranslation();
  return (
    <EditPropertyCardLayout
      setActive={setActive}
      title={t('pages.propertyView.ownerInformationDetails')}
    >
      <CardContent sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Button
            id="updateOwner"
            sx={(theme: Theme) => {
              return {
                backgroundColor: theme.palette.linkBlue.main,
              };
            }}
          >
            {t('pages.propertyView.updateOwner')}
          </Button>
          <EditOwnerPanel
            options={ownerData}
            setEditOwnerData={setEditOwnerData}
          />
        </Box>
        <EditOwnerCard
          cardTitle={t('pages.propertyView.newOwner')}
          editOwnerData={editOwnerData}
        />
      </CardContent>
    </EditPropertyCardLayout>
  );
}

export default EditOwnerContainer;
