import { useState } from 'react';

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import PendingIcon from '@mui/icons-material/Pending';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box } from '@mui/system';
import NavHeader from '@revenue-solutions-inc/revxcoreui/material/layout/Header';
import HeaderElement from 'components/layout/HeaderElement';
import { HeaderElementProps } from 'components/layout/HeaderElement/HeaderElement';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { HeaderIcon, removeHistory, setHeader } from 'redux/contentSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { setModule } from 'redux/userSlice';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import { useMsal } from '@azure/msal-react';
import MenuItem from '@mui/material/MenuItem';
import { useLogoutApp } from 'hooks/useLogoutApp';
import { useHasAccess } from 'hooks/useHasAccess';

import QuestionMark from '@revenue-solutions-inc/revxcoreui/material/layout/QuestionMark';
import UserProfile from '@revenue-solutions-inc/revxcoreui/material/layout/UserProfile';
import SingleSearchWrapper from 'components/SingleSearchWrapper/SingleSearchWrapper';
import FavoritesButtonWrapper from 'components/FavoritesButtonWrapper';
import ChatbotWrapper from 'components/ChatbotWrapper';

import HeaderButton from './HeaderButton';

const menuItemStyle = {
  backgroundColor: 'primary.main',
  color: 'common.white',
  fontFamily: 'fontFamily',
  fontSize: '12px',
  ':hover': { backgroundColor: 'error.main', color: 'common.white' },
  ':focus': {
    backgroundColor: 'error.main',
  },
};

interface HeaderParams {
  drawerWidth: number;
  open: boolean;
}

function Header({ drawerWidth, open }: HeaderParams): JSX.Element {
  const currentModule = useAppSelector((state) => state.user.module);
  const headerData = useAppSelector((state) => state.content.header);
  const modules = useAppSelector((state) => state.content.modules);
  const module = useAppSelector((state) => state.user.module);
  const historyData = useAppSelector((state) => state.content.history);
  const dispatch = useAppDispatch();
  const [dialogOpen, setDialogOpen] = useState(false);
  const { accounts } = useMsal();
  const navigate = useNavigate();
  const logout = useLogoutApp();
  const { t } = useTranslation();
  const location = useLocation();

  const PolicyGroupExist = (componentName: string): boolean => {
    return useHasAccess(componentName, 'view');
  };

  const getIcon = (icon: HeaderIcon) => {
    switch (icon.icon) {
      case 'personIcon':
        return (
          <PersonIcon
            sx={icon.props}
            fontSize={icon.fontSize ? icon.fontSize : 'large'}
          />
        );
      case 'assignmentTurnedInIcon':
        return (
          <AssignmentTurnedInIcon
            sx={icon.props}
            fontSize={icon.fontSize ? icon.fontSize : 'large'}
          />
        );
      case 'settingsIcon':
        return (
          <SettingsIcon
            sx={icon.props}
            fontSize={icon.fontSize ? icon.fontSize : 'large'}
          />
        );
      case 'checkCircleIcon':
        return (
          <CheckCircleIcon
            sx={icon.props}
            fontSize={icon.fontSize ? icon.fontSize : 'large'}
          />
        );
      case 'errorIcon':
        return (
          <ErrorIcon
            sx={icon.props}
            fontSize={icon.fontSize ? icon.fontSize : 'large'}
          />
        );
      case 'pendingIcon':
        return (
          <PendingIcon
            sx={icon.props}
            fontSize={icon.fontSize ? icon.fontSize : 'large'}
          />
        );
      case 'accountBalanceIcon':
        return (
          <AccountBalanceIcon
            sx={icon.props}
            fontSize={icon.fontSize ? icon.fontSize : 'large'}
          />
        );
      case 'paymentIcon':
        return (
          <LocalAtmIcon
            sx={icon.props}
            fontSize={icon.fontSize ? icon.fontSize : 'large'}
          />
        );
      default:
        return (
          <AssignmentIcon
            sx={icon.props}
            fontSize={icon.fontSize ? icon.fontSize : 'medium'}
          />
        );
    }
  };

  const manageCustomHeader = () => {
    if (headerData?.buttonLinks) {
      return <HeaderButton buttonLinks={headerData?.buttonLinks} />;
    } else {
      return <HeaderButton pageTitle={headerData?.pageTitle} />;
    }
  };

  const formatData = () => {
    if (headerData && headerData.data && headerData.data.length > 0) {
      return (
        <>
          {headerData?.data?.map((value, index) => {
            if ((value as HeaderElementProps).id !== undefined) {
              return (
                <Box
                  key={(value as HeaderElementProps).id}
                  sx={
                    index === 1 || index === 0
                      ? { display: { xs: 'none', sm: 'none', md: 'block' } }
                      : index === 2 || index === 3 || index === 4
                      ? { display: { xs: 'none', md: 'none', lg: 'block' } }
                      : index === 5
                      ? {
                          display: {
                            xs: 'none',
                            md: 'none',
                            lg: 'none',
                            xl: 'block',
                          },
                        }
                      : { display: { xs: 'none', xl: 'block' } }
                  }
                >
                  <HeaderElement
                    id={(value as HeaderElementProps).id}
                    first={(value as HeaderElementProps).first}
                    label={(value as HeaderElementProps).label}
                    value={(value as HeaderElementProps).value}
                  />
                </Box>
              );
            } else {
              return getIcon(value as HeaderIcon);
            }
          })}
        </>
      );
    } else return undefined;
  };

  function setHeaderForUnderC(title: string, route: string) {
    dispatch(setHeader({ pageTitle: title }));
    navigate(route);
  }

  // TODO: add onclick event when links need to add
  const questionMarkItems = (
    <>
      <MenuItem
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        tabIndex={0}
        sx={menuItemStyle}
        onClick={() =>
          setHeaderForUnderC('Getting Started', `/${module}/gettingstarted`)
        }
      >
        {t('pages.header.gettingStarted')}
      </MenuItem>
      <MenuItem
        sx={menuItemStyle}
        onClick={() =>
          setHeaderForUnderC('Documentation', `/${module}/documentation`)
        }
      >
        {t('pages.header.documentation')}
      </MenuItem>
      <MenuItem
        sx={menuItemStyle}
        onClick={() => setHeaderForUnderC('Training', `/${module}/training`)}
      >
        {t('pages.header.training')}
      </MenuItem>
      <MenuItem
        sx={menuItemStyle}
        onClick={() => setHeaderForUnderC('Feedback', `/${module}/feedback`)}
      >
        {t('pages.header.feedback')}
      </MenuItem>
    </>
  );

  // TODO: add onclick event when actual links need to add
  const userProfileItems = (
    <>
      <MenuItem
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        tabIndex={0}
        sx={menuItemStyle}
        component={Link}
        aria-label={t('pages.settings.title')}
        to={`/${module}/settings`}
        disabled={!PolicyGroupExist('settings')}
      >
        {t('pages.settings.title')}
      </MenuItem>
      <MenuItem
        sx={menuItemStyle}
        onClick={() =>
          window.open('https://onlineservices.qa.revx.app/', '_blank')
        }
      >
        {t('pages.header.onlineServices')}
      </MenuItem>
      <MenuItem
        sx={menuItemStyle}
        onClick={() => {
          setDialogOpen(!dialogOpen);
        }}
      >
        {t('pages.header.chatBot')}
      </MenuItem>
      <MenuItem sx={menuItemStyle} onClick={logout}>
        {t('pages.header.signOut')}
      </MenuItem>
    </>
  );

  return (
    <>
      <>
        <ChatbotWrapper
          open={dialogOpen}
          handleOpen={(isOpen) => {
            setDialogOpen(isOpen);
          }}
        />
      </>
      <NavHeader
        handleModuleClick={(moduleId: string) => {
          dispatch(setModule(moduleId));
          navigate(moduleId + '/navigation');
          return null;
        }}
        selectedModule={currentModule}
        headerTitle={headerData?.pageTitle ?? ''}
        headerIcon={headerData?.icon ? getIcon(headerData?.icon) : undefined}
        drawerWidth={drawerWidth}
        open={open}
        prevPageName={historyData.at(-1)?.prevPageTitle ?? 'dashboard'}
        currentPageLocation={location.pathname}
        goBack={() => {
          dispatch(removeHistory(module));
        }}
        dataValues={formatData()}
        button={manageCustomHeader()}
        modules={modules ? modules : []}
        favorites={<FavoritesButtonWrapper />}
        userProfile={
          <div id="user-profile">
            <UserProfile
              userName={
                accounts[0] && accounts[0].name ? accounts[0].name : 'No Name'
              }
              dropDownItems={userProfileItems}
            />
          </div>
        }
        questionMark={<QuestionMark children={questionMarkItems} />}
        search={
          module?.toLowerCase() !== 'admin' ? <SingleSearchWrapper /> : <></>
        }
      />
    </>
  );
}

export default Header;
