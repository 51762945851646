import { RelationshipForm } from 'types/relationships';

export const getRelationship: RelationshipForm = {
  relationships: [
    {
      relationshipType: '',
      commenceDate: null,
      ceaseDate: null,
    },
  ],
};
