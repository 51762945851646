export interface Contact {
  id?: string;
  isPrimary: boolean;
  type: string;
  value: string;
}

export interface Address {
  id?: string;
  careOf?: string;
  city: string;
  countryCode: string;
  postalCode: string;
  stateProvince: string;
  street: string;
  street2: string;
  hasMailReturned?: boolean;
  isConfidential?: boolean;
  isPrimary: boolean;
  type: string;
}

export interface Name {
  id?: string;
  family: string;
  format?: string;
  given: string;
  middle: string;
  value: string;
}

const addressDefault: Address = {
  id: '',
  countryCode: '',
  street: '',
  street2: '',
  city: '',
  stateProvince: '',
  postalCode: '',
  type: '',
  isPrimary: false,
};
const contactDefault: Contact = {
  id: '',
  type: '',
  value: '',
  isPrimary: true,
};
const nameDefault: Name = {
  id: '',
  family: '',
  given: '',
  middle: '',
  value: '',
};

export { addressDefault, contactDefault, nameDefault };
