export interface RsiType {
  id: string;
  value: string;
  description?: string;
}

export interface RsiLookupType {
  id: string;
  value: string;
  name: string;
}
const addressType: RsiType[] = [
  {
    id: 'Alternate',
    value: 'Alternate',
  },
  {
    id: 'Billing',
    value: 'Billing',
  },
  {
    id: 'Mailing',
    value: 'Mailing',
  },
  {
    id: 'Situs',
    value: 'Situs',
  },
  // TODO: enable once fixed in backend
  // {
  //   id: 'Location',
  //   value: 'Location',
  // },
];

const businessOwnershipType: RsiType[] = [
  {
    id: 'BusinessTrust',
    value: 'Business Trust',
  },
  {
    id: 'Corporation',
    value: 'Corporation',
  },
  {
    id: 'LimitedPartnership',
    value: 'Limited Partnership',
  },
  {
    id: 'LlcCorporation',
    value: 'LLC Corporation',
  },
  {
    id: 'LlcDisregardedEntity',
    value: 'LLC DisregardedEntity',
  },
  {
    id: 'LlcPartnership',
    value: 'LLC Partnership',
  },
  {
    id: 'Nonprofit',
    value: 'Nonprofit',
  },

  {
    id: 'Partnership',
    value: 'Partnership',
  },
  {
    id: 'SoleCorporation',
    value: 'Sole Corporation',
  },
  {
    id: 'SoleProprietor',
    value: 'Sole Proprietor',
  },
];

const emailAddressType: RsiType[] = [
  {
    id: 'Personal',
    value: 'Personal',
  },
  {
    id: 'Work',
    value: 'Work',
  },
];

const entityIdentifierType: RsiType[] = [
  {
    id: 'EmployerIdentificationNumber',
    value: 'Employer Identification Number',
  },
  {
    id: 'GeographicalId',
    value: 'Geographical Id',
  },
  {
    id: 'PropertyId',
    value: 'Property Id',
  },
  {
    id: 'PropertyOwnerId',
    value: 'PropertyOwner Id',
  },
  {
    id: 'SocialSecurityNumber',
    value: 'Social Security Number',
  },
];

const entityProfileType = [
  {
    id: 'IndividualTaxpayer',
    value: 'Individual Taxpayer',
  },
  {
    id: 'InterestedParty',
    value: 'Interested Party',
  },
  {
    id: 'PropertyOwner',
    value: 'Property Owner',
  },
];

const phoneNumberType = [
  {
    id: 'Home',
    value: 'Home',
  },
  {
    id: 'Mobile',
    value: 'Mobile',
  },
  {
    id: 'Office',
    value: 'Office',
  },
];

interface PropertyType {
  businessPersonal: RsiType[];
  industrial: RsiType[];
  mineral: RsiType[];
  personal: RsiType[];
  real: RsiType[];
  utility: RsiType[];
}

const propertyTypeSubtype: PropertyType = {
  businessPersonal: [
    {
      id: 'Business',
      value: 'Business',
    },
    {
      id: 'Equipment',
      value: 'Equipment',
    },
    {
      id: 'FurnitureFixtures',
      value: 'FurnitureFixtures',
    },
    {
      id: 'Machinery',
      value: 'Machinery',
    },
    {
      id: 'Manufacturing',
      value: 'Manufacturing',
    },
    {
      id: 'Supplies',
      value: 'Supplies',
    },
  ],
  industrial: [
    {
      id: 'Commercial',
      value: 'Commercial',
    },
    {
      id: 'Industrial',
      value: 'Industrial',
    },
    {
      id: 'Manufacturing',
      value: 'Manufacturing',
    },
  ],
  mineral: [
    {
      id: 'MineralRights',
      value: 'Mineral Rights',
    },
    {
      id: 'OilGas',
      value: 'Oil Gas',
    },
    {
      id: 'Royalties',
      value: 'Royalties',
    },
  ],
  personal: [
    {
      id: 'Animals',
      value: 'Animals',
    },
    {
      id: 'Boat',
      value: 'Boat',
    },
    {
      id: 'MobileHome',
      value: 'MobileHome',
    },
    {
      id: 'RecreationalVehicle',
      value: 'Recreational Vehicle',
    },
    {
      id: 'Tractor',
      value: 'Tractor',
    },
    {
      id: 'Vehicle',
      value: 'Vehicle',
    },
  ],
  real: [
    {
      id: 'Land',
      value: 'Land',
    },
    {
      id: 'MobileHome',
      value: 'Mobile Home',
    },
    {
      id: 'Residential',
      value: 'Residential',
    },
    {
      id: 'MultiTenant',
      value: 'Multi-tenant',
    },
  ],
  utility: [
    {
      id: 'Electric',
      value: 'Electric',
    },
    {
      id: 'Hydro',
      value: 'Hydro',
    },
    {
      id: 'Solar',
      value: 'Solar',
    },
    {
      id: 'Wind',
      value: 'Wind',
    },
  ],
};

export {
  addressType,
  businessOwnershipType,
  emailAddressType,
  entityIdentifierType,
  entityProfileType,
  propertyTypeSubtype,
  phoneNumberType,
};
