import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { MessageType, Favorites } from '@revenue-solutions-inc/revxcoreui';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import { HeaderElementProps } from 'components/layout/HeaderElement/HeaderElement';
import {
  useUpdateUserPreferencesMutation,
  useGetUserPreferencesQuery,
  FavoritesResponse,
} from 'generated/graphql';
import BookmarkRemoveIcon from '@mui/icons-material/BookmarkRemove';
import { IconButton } from '@mui/material';
import { setFavorites } from 'redux/userSlice';
import { useTheme } from '@mui/system';
import Loading from 'components/Loading/Loading';

function FavoritesButtonWrapper(): JSX.Element {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const headerData = useAppSelector((state) => state.content.header);
  const userPreferences = useAppSelector((state) => state.user.userPreferences);
  const { mutate, isLoading } = useUpdateUserPreferencesMutation();
  const location = useLocation();

  const { data, refetch, isFetching } = useGetUserPreferencesQuery();

  const headerMeta = headerData?.data ?? '';

  useEffect(() => {
    if (data?.GetUserPreferences.favorites) {
      dispatch(setFavorites(data?.GetUserPreferences.favorites));
    }
  }, [t, data?.GetUserPreferences.favorites, dispatch]);

  const saveToFavorites = () => {
    let tempFavorite: FavoritesResponse[] = [];
    if (userPreferences.favorites?.length)
      tempFavorite = [...userPreferences.favorites];
    tempFavorite.push({
      url: location.pathname,
      pageName: headerData?.pageTitle,
      pageDescription: headerMeta.length
        ? (headerMeta[0] as HeaderElementProps).value
        : '',
    });
    mutate(
      {
        userPreferences: {
          ...userPreferences,
          favorites: [...tempFavorite],
        },
      },
      {
        onSuccess: () => {
          refetch();
          dispatch(
            addMessage({
              type: MessageType.Success,
              message: t('pages.favorites.message.success'),
            })
          );
        },
        onError: () => {
          dispatch(
            addMessage({
              type: MessageType.Error,
              message: t('pages.favorites.message.error'),
            })
          );
        },
      }
    );
  };

  const handleRemove = () => {
    const tempFavorites = userPreferences.favorites?.filter(
      (item) => item.url !== location.pathname
    );
    mutate(
      {
        userPreferences: {
          ...userPreferences,
          favorites: tempFavorites ? [...tempFavorites] : [],
        },
      },
      {
        onSuccess: () => {
          refetch();
          dispatch(
            addMessage({
              type: MessageType.Success,
              message: t('pages.favorites.message.remove'),
            })
          );
        },
        onError: () => {
          dispatch(
            addMessage({
              type: MessageType.Error,
              message: t('pages.favorites.message.error'),
            })
          );
        },
      }
    );
  };

  return (
    <>
      {isFetching || isLoading ? (
        <Loading fullScreen={false} size={25} />
      ) : data?.GetUserPreferences.favorites &&
        data?.GetUserPreferences.favorites.find(
          (obj) => obj.url === location.pathname
        ) ? (
        <IconButton id="favorites-button" onClick={() => handleRemove()}>
          <BookmarkRemoveIcon
            sx={{ fill: 'primary.main', color: theme.palette.primary.main }}
          />
        </IconButton>
      ) : (
        <Favorites
          handleClick={() => {
            saveToFavorites();
          }}
        />
      )}
    </>
  );
}
export default FavoritesButtonWrapper;
