import { useEffect, useState } from 'react';
import Loading from 'components/Loading';
import { capitalizeFirstLetter } from 'utils/string-util';
import { useTranslation } from 'react-i18next';
import { setHeader } from 'redux/contentSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { Link } from 'react-router-dom';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import { ColumnDef } from '@tanstack/react-table';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { IconButton, Typography } from '@mui/material';
import { addMessage } from 'redux/messageSlice';
import { setModule } from 'redux/userSlice';
import { MessageType } from '@revenue-solutions-inc/revxcoreui';
import {
  useUpdateUserPreferencesMutation,
  useGetUserPreferencesQuery,
  FavoritesResponse,
} from 'generated/graphql';
import { useTheme } from '@mui/system';

function ManageFavorites(): JSX.Element {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const { mutate, isLoading } = useUpdateUserPreferencesMutation();
  const [bookmarks, setBookmarks] = useState<FavoritesResponse[]>([]);
  const userPreferences = useAppSelector((state) => state.user.userPreferences);

  const {
    data: favorites,
    isFetching,
    error,
    refetch,
  } = useGetUserPreferencesQuery();

  useEffect(() => {
    dispatch(setHeader({ pageTitle: t('pages.favorites.title') }));
  }, [dispatch, t]);

  useEffect(() => {
    if (favorites?.GetUserPreferences.favorites) {
      setBookmarks(favorites?.GetUserPreferences.favorites);
    }
  }, [favorites, favorites?.GetUserPreferences]);

  const handleDelete = (url: string) => {
    // Delete locally the URL and then update user preferences
    const tempFavorites = userPreferences.favorites?.filter(
      (item) => item.url !== url
    );

    mutate(
      {
        userPreferences: {
          ...userPreferences,
          favorites: tempFavorites ? [...tempFavorites] : [],
        },
      },
      {
        onSuccess: () => {
          refetch();
          dispatch(
            addMessage({
              type: MessageType.Success,
              message: t('pages.favorites.message.remove'),
            })
          );
        },
        onError: () => {
          dispatch(
            addMessage({
              type: MessageType.Error,
              message: t('pages.favorites.message.error'),
            })
          );
        },
      }
    );
  };

  const Column: ColumnDef<FavoritesResponse>[] = [
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.favorites.pathName')} />
      ),
      accessorKey: 'pageName',
      cell: ({ row }) => {
        return (
          <Link
            to={{
              pathname: `${row.original.url}`,
            }}
            onClick={() => {
              dispatch(setModule(row.original.url.split('/')[1].toLowerCase()));
            }}
            style={{ color: theme.palette.linkBlue.dark }}
          >
            {row.original.pageName}
          </Link>
        );
      },
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.favorites.module')} />
      ),
      accessorKey: 'module',
      cell: ({ row }) => {
        return (
          <Typography>
            {capitalizeFirstLetter(
              row.original.url.split('/')[1].toLowerCase()
            )}
          </Typography>
        );
      },
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.favorites.description')} />
      ),
      accessorKey: 'pageDescription',
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.favorites.action')} />
      ),
      id: 'delete-action',
      cell: ({ row }) => {
        return (
          <IconButton
            aria-label={t('pages.favorites.delete')}
            onClick={() => handleDelete(row.original.url)}
          >
            <DeleteForeverIcon
              sx={{
                fill: 'primary.main',
                color: theme.palette.primary.main,
                '&:hover': {
                  color: theme.palette.error.main,
                },
              }}
            />
          </IconButton>
        );
      },
    },
  ];

  return (
    <>
      {(isFetching || isLoading) && <Loading />}
      {bookmarks && favorites && (
        <DefaultDataTableNext
          columns={Column as ColumnDef<Record<string, unknown>, unknown>[]}
          data={bookmarks ? bookmarks : []}
        />
      )}
      {error && <h1>{t('components.message.error')}</h1>}
    </>
  );
}

export default ManageFavorites;
