import { SelectChangeEvent } from '@mui/material';
import { Select } from '@revenue-solutions-inc/revxcoreui';
import { useTranslation } from 'react-i18next';
import { configDomainsList } from 'common/platformConfigUtils/platformConfigUtils';

interface Props {
  handleChange: (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | SelectChangeEvent<string | number>
  ) => void;
  value: string;
}

function ConfigurationDomainsList({ handleChange, value }: Props) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.configTypeEditor',
  });

  return (
    <>
      <Select
        data-testid="select-configGroups"
        id={'select-group'}
        sx={{ width: '100%', mb: '2em', mt: '1em' }}
        label={t('configDomain') + ' Filter'}
        value={value}
        onChange={handleChange}
        options={configDomainsList}
      />
    </>
  );
}

export default ConfigurationDomainsList;
