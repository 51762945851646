import { useContext } from 'react';

import { Grid, Typography } from '@mui/material';
import EntityManagementContext from 'components/contexts/EntityManagement';
import DataCard from 'components/DataCard';
import { AccountTypes } from 'components/entityManagement/common/accountUtils';
import ActionButton from 'components/entityManagement/common/ActionButton';
import CardAvatar from 'components/entityManagement/common/CardAvatar';
import { getLabelAndValueByItem } from 'components/entityManagement/common/confirmationUtils';
import { AccountFilingFrequency } from 'types/accounts';
import { Section } from 'types/forms';
import { EntityTypes } from 'components/entityManagement/common/entityUtils';
import { AssetTypes } from 'components/entityManagement/common/assetUtils';
import { v4 as uuid } from 'uuid';

interface Props {
  group: AccountTypes | EntityTypes | AssetTypes | undefined;
  specialGroup?: AccountFilingFrequency[];
  fields: Section;
  index: number;
  handleSubmit: () => void;
  remove: () => void;
  openForm: (number: number, fields: Section, type: string) => void;
  canEdit: boolean;
  keyName: string;
}

/**
 * Displays section cards of account/entity/asset details
 */
function SectionCard({
  group,
  specialGroup,
  fields,
  index,
  remove,
  handleSubmit,
  openForm,
  canEdit,
  keyName,
}: Props): JSX.Element {
  const ctx = useContext(EntityManagementContext);

  const addItemsPerSection = (
    sectionType: Section,
    sectionData: AccountTypes | EntityTypes | AssetTypes
  ): JSX.Element[] => {
    const sectionElements: JSX.Element[] = [];
    for (const attr in sectionData) {
      const itemInfo = getLabelAndValueByItem(
        sectionType,
        attr,
        ctx.idFormats,
        ctx.selectedCommenceDate,
        sectionData
      );

      if (itemInfo[0] !== '') {
        sectionElements.push(
          <Grid
            key={sectionType.sectionIdentifier + '-' + attr}
            sx={{ display: 'flex', flexDirection: 'column' }}
            item
            lg={4}
            md={6}
            sm={6}
          >
            <Typography variant="h5" id={uuid() + `-label`} component="label">
              {itemInfo[0]}
            </Typography>
            <Typography
              variant="h3"
              id={uuid() + `-value`}
              sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
              {itemInfo[1]}
            </Typography>
          </Grid>
        );
      }
    }
    return sectionElements;
  };

  /**
   * Displays all labels and values inside a card
   * @param sectionType Section to be built
   * @param sectionData Contains the data to be extracted
   * @returns An array of nodes with all labels and values
   */
  const displaySectionItems = (
    sectionType: Section,
    sectionData: AccountTypes | EntityTypes | AssetTypes | undefined,
    filingFrequencies?: AccountFilingFrequency[]
  ): JSX.Element[] => {
    let sectionElements: JSX.Element[] = [];
    if (filingFrequencies) {
      filingFrequencies.forEach((filingFreq) => {
        const tempSectionElements = addItemsPerSection(sectionType, filingFreq);
        tempSectionElements.forEach((tempSection) => {
          sectionElements.push(tempSection);
        });
      });
    } else if (sectionData) {
      sectionElements = addItemsPerSection(sectionType, sectionData);
    }
    return sectionElements;
  };

  return (
    <Grid
      key={keyName}
      sx={{ display: 'flex' }}
      item
      md={4}
      sm={6}
      order={{ xs: 2, sm: 1, md: -1 }}
    >
      <DataCard
        title={fields.sectionTitle}
        avatar={<CardAvatar sectionName={fields.sectionIdentifier} />}
        action={
          canEdit ? (
            <ActionButton
              index={index}
              type="edit"
              remove={remove}
              handleSubmit={handleSubmit}
              section={fields}
              toggleForm={() => openForm(index, fields, 'edit')}
            />
          ) : (
            <></>
          )
        }
        children={
          <Grid container spacing={2}>
            {specialGroup
              ? displaySectionItems(fields, undefined, specialGroup)
              : displaySectionItems(fields, group)}
          </Grid>
        }
      />
    </Grid>
  );
}

export default SectionCard;
