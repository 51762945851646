import { useEffect, useState } from 'react';
import Dialog from '@revenue-solutions-inc/revxcoreui/material/controls/Dialog';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'redux/hooks';
import { useQueryClient } from '@tanstack/react-query';
import {
  Attribute,
  Group,
  MaintainPeriodInput,
  useGetPeriodSchemasQuery,
  useMaintainAccountPeriodMutation,
} from 'generated/graphql';
import { addMessage } from 'redux/messageSlice';
import {
  MessageActionType,
  MessageType,
} from '@revenue-solutions-inc/revxcoreui';
import extractMeaningfulMessage from 'utils/errorMessage';
import { getDate } from 'common/helpers';
import { ExtendedAttributeValues } from 'types/forms';
import { buildExtendedValues } from 'components/entityManagement/common/entityManagementUtils';
import { useForm } from 'react-hook-form';
import InfoIcon from '@mui/icons-material/Info';
import { OpenInNew } from '@mui/icons-material';
import DataCard from 'components/DataCard';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import EditPeriodPreview from '../EditPeriodPreview/EditPeriodPreview';
import EditPeriodForm from '../EditPeriodForm/EditPeriodForm';
import { openModalTheme } from '../AccountView/AccountView';
interface Props {
  periodId: string;
  accountId: string;
  periodBeginDate?: string | null;
  periodEndDate?: string | null;
  periodType?: string | null;
  currPeriodAttributeGroup?: Group[] | null;
}

function EditPeriodDetails({
  periodId,
  accountId,
  periodBeginDate,
  periodEndDate,
  periodType,
  currPeriodAttributeGroup,
}: Props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const { control, formState, trigger, getValues, setValue, reset } = useForm({
    mode: 'all',
  });
  const [extendedValues, setExtendedValues] = useState<
    ExtendedAttributeValues[]
  >([]);

  const [periodAttributeGroup, setPeriodAttributeGroup] = useState<
    Group[] | null
  >();

  const attrGroupNotEmpty =
    periodAttributeGroup && periodAttributeGroup.length > 0;
  const { isValid } = formState;

  const closeModal = () => {
    setOpen(false);
  };

  const openModal = () => {
    setOpen(true);
    setValue('beginDate', getDate(periodBeginDate ?? ''));
    setValue('endDate', getDate(periodEndDate ?? ''));
  };

  useEffect(() => {
    if (attrGroupNotEmpty) {
      const newExtendedVals = buildExtendedValues(
        periodAttributeGroup[0].attribute
      );
      newExtendedVals.forEach((item) => {
        if (item.name.toLowerCase() === 'duedate' && periodEndDate) {
          item.min = getDate(periodEndDate);
        }
        if (currPeriodAttributeGroup) {
          item.value =
            currPeriodAttributeGroup[0].attribute.find(
              (attr) => attr.attributeName === item.name
            )?.attributeValue ?? '';
        }
      });
      setExtendedValues(newExtendedVals);
    } else setExtendedValues([]);
  }, [
    attrGroupNotEmpty,
    currPeriodAttributeGroup,
    periodAttributeGroup,
    periodEndDate,
  ]);

  const checkExtendedAttributes = (): boolean => {
    let invalidExists = false;
    const newExtendedVals: ExtendedAttributeValues[] = [];
    extendedValues.forEach((attr) => {
      if (attr.isRequired && attr.isRequired === true && attr.value === '') {
        invalidExists = true;
      }
      newExtendedVals.push({
        ...attr,
        isDirty: true,
      });
    });
    setExtendedValues(newExtendedVals);
    if (invalidExists) return false;
    return true;
  };

  const { mutate, isLoading } = useMaintainAccountPeriodMutation({
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: ['GetAccountPeriodDetail'],
      });
      closeModal();
      reset();
      setShowPreview(false);
      dispatch(
        addMessage({
          message: t('pages.periodDetails.edit.successUpdating'),
          type: MessageType.Success,
          actionType: MessageActionType.None,
        })
      );
    },
    onError: (error) => {
      const message = extractMeaningfulMessage(
        error,
        t('pages.periodDetails.edit.errorUpdating')
      );
      dispatch(
        addMessage({
          message: message,
          type: MessageType.Error,
          actionType: MessageActionType.None,
        })
      );
    },
  });

  const { isLoading: isLoadingPeriodSchema } = useGetPeriodSchemasQuery(
    {
      periodType: periodType as string,
    },
    {
      enabled: !!periodType,
      onSuccess: (response) => {
        const coreSchemaGroup =
          response?.GetPeriodSchemas.coreSchema?.platformConfigurationInfo
            ?.configurationSection[0].group[0].attribute[0];

        const extendedSchemaGroup = response?.GetPeriodSchemas.extendedSchema
          ?.platformConfigurationInfo?.configurationSection[0].group[0]
          .attribute as Attribute[];

        const mergedSchemaAttributes = [
          ...extendedSchemaGroup,
          coreSchemaGroup,
        ];

        setPeriodAttributeGroup([
          {
            groupName: 'Period Attribute',
            groupOrder: '0',
            attribute: mergedSchemaAttributes as Attribute[],
          },
        ]);
      },
      onError: (error) => {
        const message = extractMeaningfulMessage(
          error,
          t('pages.periodDetails.edit.errorRetrievingExtendedAttributes')
        );
        dispatch(
          addMessage({
            message: message,
            type: MessageType.Error,
            actionType: MessageActionType.None,
          })
        );
      },
    }
  );

  const periodDetailsModalContent = (
    <>
      {showPreview ? (
        <EditPeriodPreview
          periodBeginDate={periodBeginDate ?? ''}
          periodEndDate={periodEndDate ?? ''}
          periodAttributeGroup={periodAttributeGroup}
          currPeriodAttributeGroup={currPeriodAttributeGroup}
          getValues={getValues}
          handleEditPeriod={() => setShowPreview(false)}
        />
      ) : (
        <EditPeriodForm
          control={control}
          periodAttributeGroup={periodAttributeGroup}
          extendedValues={extendedValues}
          setExtendedValues={setExtendedValues}
        />
      )}
    </>
  );

  const updatePeriodAttributeGroup = () => {
    if (attrGroupNotEmpty) {
      periodAttributeGroup[0].attribute.forEach(
        (attr) =>
          (attr.attributeValue =
            extendedValues
              .find((item) => item.name === attr.attributeName)
              ?.value.split('T')[0] ?? '')
      );
    }
  };

  const handlePreview = () => {
    void trigger()
      .then(() => updatePeriodAttributeGroup())
      .then(() => {
        const isExtendedValid = checkExtendedAttributes();
        if (isValid && isExtendedValid) setShowPreview(true);
      });
  };

  const handleSave = () => {
    trigger().finally(() => {
      if (isValid) {
        const payload: MaintainPeriodInput = {
          id: periodId,
          accountId: accountId,
          beginDate: getDate(getValues('beginDate')),
          endDate: getDate(getValues('endDate')),
          group: periodAttributeGroup,
          reason: getValues('maintainPeriodReason'),
        };
        mutate({ maintainPeriod: payload });
      }
    });
  };

  const periodDetailsChildren = (
    <Grid container mt={1}>
      <Grid item xs={6}>
        <Typography variant="h5" id="beginDateTitle" component="label">
          {t('pages.periodDetails.edit.beginDate')}
        </Typography>
        <Typography variant="h3" id="beginDateLabel">
          {getDate(periodBeginDate ?? '')}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h5" id="endDateTitle" component="label">
          {t('pages.periodDetails.edit.endDate')}
        </Typography>
        <Typography variant="h3" id="endDateLabel">
          {getDate(periodEndDate ?? '')}
        </Typography>
      </Grid>
      <Grid item xs={6} mt={2}>
        <Typography variant="h5" id="periodTypeLabel" component="label">
          {t('pages.paymentDetails.application.periodType')}
        </Typography>
        <Typography variant="h3" id="periodType">
          {periodType}
        </Typography>
      </Grid>
    </Grid>
  );

  return (
    <Grid item lg={3} xs={12}>
      <DataCard
        title={t('pages.periodDetails.title')}
        avatar={<InfoIcon sx={{ fill: 'primary.main' }} />}
        action={<OpenInNew sx={openModalTheme} onClick={openModal} />}
        children={periodDetailsChildren}
      />
      <Dialog
        id="editPeriodDialog"
        open={open}
        children={!isLoadingPeriodSchema && periodDetailsModalContent}
        title={t('pages.periodDetails.edit.editPeriod')}
        type="transactional"
        width={900}
        loading={isLoading}
        transactionModalTransactionButtonText={
          showPreview ? t('components.button.save') : t('pages.forms.continue')
        }
        handleClose={closeModal}
        handleCancelClick={closeModal}
        handleTransactionClick={showPreview ? handleSave : handlePreview}
      />
    </Grid>
  );
}
export default EditPeriodDetails;
