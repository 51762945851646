import { Divider, Grid, Typography, useTheme } from '@mui/material';
import ButtonLink from '@revenue-solutions-inc/revxcoreui/material/controls/ButtonLink';
import DataDisplay from '@revenue-solutions-inc/revxcoreui/material/controls/DataDisplay';
import { FieldValues, UseFormGetValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface AdjustRemittancePreviewProps {
  paymentsTable: React.ReactElement;
  remittanceTable: React.ReactElement;
  getValues: UseFormGetValues<FieldValues>;
  showNote: boolean;
  handleClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

function AdjustRemittancePreview({
  paymentsTable,
  remittanceTable,
  getValues,
  showNote,
  handleClick,
}: AdjustRemittancePreviewProps) {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Grid container>
      <Grid item xs={12} mb={1}>
        <Typography variant="h2" sx={{ textAlign: 'center' }}>
          {t('pages.periodDetails.adjustments.confirmationMsg')}
        </Typography>
        <Divider
          sx={{
            mt: '20px',
            backgroundColor: theme.palette.grey2.light,
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <DataDisplay
          id="adjustmentType"
          label={t('pages.submissionDetails.paymentAdjustmentType')}
          data={getValues('paymentAdjustmentType') ?? '-'}
        />
      </Grid>
      <Grid item xs={4}>
        <DataDisplay
          id="adjustmentReason"
          label={t('pages.submissionDetails.paymentAdjustmentReason')}
          data={getValues('paymentAdjustmentReason') ?? '-'}
        />
      </Grid>
      {showNote && (
        <Grid item xs={4}>
          <DataDisplay
            id="adjustmentNote"
            label={t('pages.submissionDetails.note')}
            data={getValues('paymentAdjustmentNote') ?? '-'}
          />
        </Grid>
      )}
      <Grid item xs={12} mt={1}>
        {paymentsTable}
      </Grid>
      <Grid item xs={12} mt={1}>
        {remittanceTable}
      </Grid>
      <Grid item xs={12} mt={1}>
        <ButtonLink
          id="editAdjustment"
          text={t('pages.periodDetails.adjustments.edit')}
          href="#"
          color="primary"
          handleChange={handleClick}
        />
      </Grid>
    </Grid>
  );
}
export default AdjustRemittancePreview;
