import { forwardRef, useState } from 'react';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Slide, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { useChatbotQueryQuery } from 'generated/graphql';
import { useAppSelector } from 'redux/hooks';
import Chatbot from '@revenue-solutions-inc/revxcoreui/material/controls/Chatbot';
import { useTranslation } from 'react-i18next';

interface ChatbotWrapperProps {
  open: boolean;
  handleOpen: (isOpen: boolean) => void;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ChatbotWrapper({ open, handleOpen }: ChatbotWrapperProps) {
  const { t } = useTranslation();
  const sender = useAppSelector((state) => {
    return state.user.uniqueId;
  });
  const [message, setMessage] = useState('');

  const handleClose = () => {
    handleOpen(false);
  };

  const { data, isFetched, refetch } = useChatbotQueryQuery(
    {
      sender: sender ?? '',
      message,
    },
    {
      enabled: false,
      cacheTime: 0,
    }
  );

  return (
    <>
      <Dialog
        open={open}
        color="primary"
        onClose={handleClose}
        TransitionComponent={Transition}
        sx={{
          '& .MuiDialog-container': {
            marginTop: '5%',
            marginRight: '5%',
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
          },
        }}
      >
        <DialogTitle
          sx={(theme) => {
            return {
              backgroundColor: 'primary.main',
              color: theme.palette.grey[100],
            };
          }}
        >
          <Typography sx={{ color: 'common.white', fontSize: '18px' }}>
            {t('components.chatbot.title')}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Chatbot
            sender={sender ? sender : ''}
            isFetched={isFetched}
            data={data?.ChatbotQuery ? data.ChatbotQuery : []}
            message={message}
            setMessage={setMessage}
            refetch={() => refetch()}
          />
        </DialogContent>
        <DialogActions>
          <Button
            id={'btn-chatbot-id'}
            onClick={handleClose}
            sx={{
              backgroundColor: 'white.main',
              color: 'primary.main',
              ':hover': {
                backgroundColor: 'white.main',
              },
            }}
          >
            {t('components.chatbot.close')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ChatbotWrapper;
