import { Attribute, Group } from 'generated/graphql';
import { AccountForm, AccountSectionNames } from 'types/accounts';
import { AssetForm } from 'types/assets';
import { EntityForm, EntitySectionNames } from 'types/entities';
import { ExtendedAttributeValues, Section } from 'types/forms';
import { toDate } from 'utils/date-util';
import { deepCopy } from 'utils/deepCopy';

import { AssetSectionNames } from './assetUtils';
import { canadaStates } from './defaults/states';
import { AddressFields } from './fields/entities';
import { setOptions } from './formatValidations';

type EMSectionNames = AccountSectionNames &
  EntitySectionNames &
  AssetSectionNames;
type EMSectionType = EntityForm | AccountForm | AssetForm;
type DateType = string | null | undefined;

const optionalSections = ['emailAddresses', 'phoneNumbers'];
const addressFields = [
  'attention',
  'addressLine1',
  'addressLine2',
  'cityId',
  'countyId',
  'stateProvinceId',
  'postalCode',
];

export enum CoreSchema {
  Entity = 'EntityCoreSchema',
  Account = 'AccountCoreSchema',
  Asset = 'AssetCoreSchema',
}

/**
 * Determines if a section can be removed or not
 * @param index Index of the element that will be compared
 * @param sectionType Section to be checked
 * @param data Data received, it can be an entity, account or asset
 * @returns Boolean flag that determines if can be removed
 */
export const canRemoveSection = (
  index: number,
  sectionType: Section,
  data: EMSectionType
): boolean => {
  if (sectionType.sectionIdentifier === 'others') {
    return false;
  } else if (optionalSections.includes(sectionType.sectionIdentifier)) {
    return true;
  } else {
    // Primary sections cannot be removed
    const section = data[sectionType?.sectionIdentifier as EMSectionNames];
    if (section) {
      const sectionData = section[index];
      if (sectionData.isPrimary === 'false') return true;
    }
  }
  return false;
};

/**
 * Validates that the account only has 1 instance of a primary record
 * @param data AccountData
 * @param sectionIdentifier Current section being validated
 * @returns Boolean flag
 */
export const validateMultiplePrimaryRecords = (
  data: EMSectionType,
  sectionIdentifier: string | undefined
): boolean => {
  let amountPrimaries = 0;
  const section = data[sectionIdentifier as keyof EMSectionType];
  if (section && section.length > 0) {
    section.forEach((element) => {
      if (element.isPrimary?.toString().toLowerCase() === 'true') {
        amountPrimaries++;
      }
    });
    return amountPrimaries === 1;
  }
  return true;
};

/**
 * Determines if the commence date is valid
 * @param demographicCommenceDate Commence date of a section
 * @param generalCommenceDate General commence date
 * @returns Boolean flag that determines if commence date is valid
 */
export const isCommenceDateValid = (
  demographicCommenceDate: string,
  generalCommenceDate: DateType
): boolean => {
  const demCommenceDate = toDate(demographicCommenceDate);
  const genCommenceDate = toDate(generalCommenceDate ?? '');
  if (
    genCommenceDate &&
    demCommenceDate &&
    demCommenceDate >= genCommenceDate
  ) {
    return true;
  }
  return false;
};

/**
 * Determines if the cease date is valid
 * @param demographicCommenceDate Commence date of a section
 * @param demographicCeaseDate Cease date of a section
 * @param generalCommenceDate General commence date
 * @returns Boolean flag that determines if cease date is valid
 */
export const isCeaseDateValid = (
  demographicCommenceDate: DateType,
  demographicCeaseDate: string,
  generalCommenceDate: DateType
): boolean => {
  const demCommenceDate = toDate(demographicCommenceDate ?? '');
  const demCeaseDate = toDate(demographicCeaseDate);
  const genCommenceDate = toDate(generalCommenceDate ?? '');
  if (
    (demCommenceDate && demCeaseDate && demCommenceDate < demCeaseDate) ||
    (genCommenceDate && demCeaseDate && genCommenceDate < demCeaseDate)
  ) {
    return true;
  }
  return false;
};

/**
 * Rerenders some address fields depending on the Country selected
 * @param addressSection Address fields object
 * @param country Country selected
 * @returns New object with all address fields
 */
export const processAddressForm = (
  addressSection: Section,
  country: string
): Section => {
  const newActiveSection = deepCopy(addressSection);
  newActiveSection.fields.forEach((field) => {
    if (addressFields.includes(field.fieldIdentifier)) {
      const defaultField = AddressFields.fields.find(
        (searchingField) =>
          searchingField.fieldIdentifier === field.fieldIdentifier
      );
      if (defaultField) {
        field.label = defaultField.label;
        field.type = defaultField.type;
        field.options = defaultField.options;
      }
      if (field.fieldIdentifier === 'cityId' && country === 'Canada') {
        field.label = 'Municipality';
      }
      if (field.fieldIdentifier === 'stateProvinceId') {
        if (country === 'Canada') {
          field.label = 'Province';
          field.options = setOptions(canadaStates);
        } else if (country === 'International') {
          field.label = 'State/Province/Region';
          field.type = 'text';
        }
      }
      if (field.fieldIdentifier === 'postalCode') {
        if (country === 'Canada') field.label = 'Postal Code';
        else if (country === 'International') field.label = 'Zip/Postal Code';
      }
      if (field.isRequired) {
        field.rules = { required: field.label.concat(' is required') };
      }
    }
  });
  return newActiveSection;
};

/**
 * Returns a specific attribute inside an array of extended attributes
 * @param extendedValues Array with Extended attribute values
 * @param attrName The attribute field to find
 * @returns Attribute object
 */
const getExtendedAttribute = (
  extendedValues: ExtendedAttributeValues[],
  attrName: string
): ExtendedAttributeValues | undefined => {
  return extendedValues.find((attribute) => attribute.name === attrName);
};

/**
 * Returns the value of an extended attribute
 * @param extendedValues Array with Extended attribute values
 * @param attrName The attribute field to get
 * @returns Value of the extended attribute
 */
export const getExtendedValue = (
  extendedValues: ExtendedAttributeValues[],
  attrName: string
): string => {
  const attributeName = getExtendedAttribute(extendedValues, attrName);
  return attributeName?.value ?? '';
};

/**
 * Check if an extended attribute is required or not
 * @param extendedValues Array with Extended attribute values
 * @param attrName The attribute to check
 * @returns True or false if the extended attribute field is required
 */
export const getExtendedRequired = (
  extendedValues: ExtendedAttributeValues[],
  attrName: string
): boolean => {
  const attributeName = getExtendedAttribute(extendedValues, attrName);
  return attributeName?.isRequired ?? false;
};

/**
 * Check if an extended attribute has a min date field set
 * @param extendedValues Array with Extended attribute values
 * @param attrName The attribute to check
 * @returns the min date as a string
 */
export const getExtendedMinDate = (
  extendedValues: ExtendedAttributeValues[],
  attrName: string
): string => {
  const attributeName = getExtendedAttribute(extendedValues, attrName);
  return attributeName?.min ?? '';
};

/**
 * Check if an extended attribute has a max date field set
 * @param extendedValues Array with Extended attribute values
 * @param attrName The attribute to check
 * @returns the max date as a string
 */
export const getExtendedMaxDate = (
  extendedValues: ExtendedAttributeValues[],
  attrName: string
): string => {
  const attributeName = getExtendedAttribute(extendedValues, attrName);
  return attributeName?.max ?? '';
};

/**
 * Checks if an extended attribute is validated or not
 * @param extendedValues Array with Extended attribute values
 * @param attrName The attribute to validate
 * @returns True or false if the extended attribute field is valid
 */
export const isExtendedFieldValid = (
  extendedValues: ExtendedAttributeValues[],
  attrName: string
): boolean => {
  const attributeName = getExtendedAttribute(extendedValues, attrName);
  if (
    attributeName &&
    attributeName.isRequired &&
    attributeName.isRequired === true
  ) {
    return attributeName.value !== '' || !attributeName.isDirty;
  }
  return true;
};

/**
 * Returns a 'Group' with all the extended attributes to use inside a normal schema
 * @param extendedData An internal object with all the extended attributes
 * @returns Group with the extended attributes
 */
export const getExtendedAttributeGroup = (
  extendedData: ExtendedAttributeValues[]
): Group => {
  const group: Group = {
    groupName: 'extendedAttributes',
    groupOrder: '1',
    groupAttributeType: 0,
    repeatingActionLabel: '',
    attribute: [],
  };
  extendedData.forEach((attribute) => {
    const singleAttribute: Attribute = {
      attributeName: attribute.name,
      attributeDisplayName: attribute.displayName,
      attributeType: attribute.type,
      attributeValue: attribute.value,
      dataSource: attribute.datasource,
      repeatingProperties: false,
      possibleValues: null,
      repeatingValue: null,
      extensibleBusinessDriver: [],
    };
    if (
      singleAttribute.extensibleBusinessDriver &&
      attribute.isRequired === true
    ) {
      singleAttribute.extensibleBusinessDriver.push({
        driverNameType: 0,
        driverName: null,
        driverDataType: 'boolean',
        driverValue: 'true',
      });
    }
    group.attribute?.push(singleAttribute);
  });
  return group;
};

/**
 * Determines if the extended attribute is required or not
 * @param attribute The extended attribute object
 * @returns True or false if the attribute is required
 */
export const getExtendedValidation = (attribute: Attribute): boolean => {
  const ruleFound = attribute.extensibleBusinessDriver?.find(
    (driver) =>
      driver.driverNameType == 0 && driver.driverValue?.toLowerCase() === 'true'
  );
  if (ruleFound) return true;
  return false;
};

/**
 * Builds the extended attributes when response from service is received
 * @param attributes Array of extended attributes
 * @returns New object containing all the extended attributes
 */
export const buildExtendedValues = (
  attributes: Attribute[]
): ExtendedAttributeValues[] => {
  const newExtendedVals: ExtendedAttributeValues[] = [];
  attributes.forEach((attribute) => {
    newExtendedVals.push({
      name: attribute.attributeName,
      displayName: attribute.attributeDisplayName,
      type: attribute.attributeType ?? '',
      datasource: attribute.dataSource ? attribute.dataSource : null,
      isRequired: getExtendedValidation(attribute),
      isDirty: false,
      value: attribute.attributeValue,
    });
  });
  return newExtendedVals;
};

/**
 * Builds the Maintain Extended attributes form based in the configuration, and
 * comparing all the attributes with those included in the Entity. If an attribute
 * exists in the entity, it is preserved. If not, it is not included. Also, if an
 * attribute exists in the configuration and not in the entity, that attribute is included
 * in the Maintain form
 * @param configValues Extended attributes coming from PC configuration
 * @param entityValues Extended attributes existent in the Entity
 * @returns A merged set of extended attributes combining both sources
 */
export const buildMaintainExtendedValues = (
  configValues: ExtendedAttributeValues[],
  entityValues: ExtendedAttributeValues[]
): ExtendedAttributeValues[] => {
  const mergedValues: ExtendedAttributeValues[] = [];
  configValues.forEach((configAttribute) => {
    const entityAttributeFound = entityValues.find(
      (entityAttr) =>
        entityAttr.name === configAttribute.name &&
        entityAttr.type === configAttribute.type
    );
    if (entityAttributeFound) mergedValues.push(entityAttributeFound);
    else mergedValues.push(configAttribute);
  });
  return mergedValues;
};
