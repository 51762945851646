import { useMemo } from 'react';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import { ColumnDef } from '@tanstack/react-table';
import { Attribute } from 'types/graphTypes';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';

type CustomTableProps = {
  tableName?: string;
  tableData: Array<Attribute>;
};

type TableColumn = {
  attributeName: string;
  attributeDisplayName: string;
  attributeValue: string;
};
export const CustomTable = ({
  tableName,
  tableData = [],
}: CustomTableProps): JSX.Element => {
  const customColumns: ColumnDef<Record<string, unknown>>[] = useMemo(() => {
    return tableData?.map((column: TableColumn) => {
      return {
        enableSorting: true,
        id: column?.attributeName,
        accessorKey: column?.attributeName,
        header: () => (
          <HeaderColumnNext localization={column?.attributeDisplayName} />
        ),
      };
    });
  }, [tableData]);

  return (
    <DefaultDataTableNext
      id="wf-custom-table"
      tableName={tableName}
      data={tableData as Record<string, unknown>[]}
      columns={customColumns}
      enableGlobalFilter={false}
    />
  );
};
