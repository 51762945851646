import { useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import {
  GetWorkflowGroupsQuery,
  useCreateWorkflowVariantObjectMutation,
} from 'generated/graphql';
import { WorkflowForm } from 'components/Workflow/Form';
import type { WorkflowFormReducerState } from 'components/Workflow/Form/workflowFormReducer';
import { WorkflowFormContext } from 'components/Workflow/WorkflowFormContext';
import { Groups, processAttributesForBackend } from '../workflowCommon';

type CreateCaseProps = {
  variantId: string;
  data: GetWorkflowGroupsQuery;
  module: string;
  workType: string;
};

const CreateCase = ({ variantId, data, module, workType }: CreateCaseProps) => {
  const navigate = useNavigate();
  const { state: locationState } = useLocation();
  const contextTypesToNavigateBack = [1, 2];
  const queryClient = useQueryClient();
  const { setCaseStateCopy } = useContext(WorkflowFormContext);
  const { mutate } = useCreateWorkflowVariantObjectMutation({
    onSuccess: () => {
      queryClient.refetchQueries(['GetWorkflows']);
    },
  });
  const fieldsToHide = ['FormID'];

  const handleSubmit = (state: WorkflowFormReducerState) => {
    // get steps from attributes
    const step =
      state.attributes?.find((a) => a.attributeName === 'Steps')
        ?.attributeValue || 'step';

    const groups: Groups = {
      ...processAttributesForBackend(state.attributes),
      NoteIds: state.notes.map((n) => String(n)),
      WorkflowContexts: state.workflowContext,
      customAttributes: state.customAttributes,
    };

    mutate(
      {
        groups: JSON.stringify(groups),
        variantId,
        step,
      },
      {
        onSuccess: () => {
          setCaseStateCopy(null);
          if (
            contextTypesToNavigateBack.includes(
              locationState?.__workflowContext?.WorkflowContextType
            )
          ) {
            navigate(-1);
          } else {
            navigate(`/${module}/workflows`, { state: undefined });
          }
        },
      }
    );
  };

  return (
    <WorkflowForm
      isEdit={false}
      data={data}
      variantId={variantId}
      onSubmit={handleSubmit}
      workType={workType}
      hiddenFields={fieldsToHide}
    />
  );
};

export default CreateCase;
