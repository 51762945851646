import { TabPanel } from '@revenue-solutions-inc/revxcoreui';
import type { PanelProps } from '../types';

type LetterPanelProps = PanelProps;

export const LettersPanel = ({ selectedIndex, index }: LetterPanelProps) => {
  return (
    <div className="crm-tab__panel">
      <TabPanel selectedIndex={selectedIndex} index={index}>
        <h3>Letters placeholder</h3>
      </TabPanel>
    </div>
  );
};
