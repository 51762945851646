import { useMemo } from 'react';

import { ColumnDef, Column } from '@tanstack/react-table';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { useTranslation } from 'react-i18next';
import { GeneralDataResult } from 'generated/graphql';
import { Box, useTheme } from '@mui/system';
import { Typography } from '@mui/material';

type KeyValue = {
  [key: string]: string | null;
};

interface DisplaySearchProps {
  searchData: GeneralDataResult[];
  indexName: string | null | undefined;
}

// TODO: Localize column names
const indexKeys = {
  [`${process.env.REACT_APP_ENTITY_INDEX}`]: {
    firstContext: 'entity',
    firstContextVar: 'entityInfoId',
    displayData: [
      {
        key: 'primaryIdentifier',
        columnName: 'Primary Identifier',
        clickable: true,
      },
      {
        key: 'primaryIdentifierType',
        columnName: 'Primary Identifier Type',
        clickable: false,
      },
      {
        key: 'fullName',
        columnName: 'Full Name',
        clickable: false,
      },
      {
        key: 'address',
        columnName: 'Address',
        clickable: false,
      },
      {
        key: 'phoneNumber',
        columnName: 'Phone Number',
        clickable: false,
      },
      {
        key: 'emailAddress',
        columnName: 'Email Address',
        clickable: false,
      },
    ],
  },
  [`${process.env.REACT_APP_ACCOUNT_INDEX}`]: {
    firstContext: 'entity',
    firstContextVar: 'entityInfoId',
    secondContext: 'account',
    secondContextVar: 'accountInfoId',
    displayData: [
      {
        key: 'primaryIdentifier',
        columnName: 'Primary Identifier',
        clickable: true,
      },
      {
        key: 'primaryIdentifierType',
        columnName: 'Primary Identifier Type',
        clickable: false,
      },
      {
        key: 'address',
        columnName: 'Address',
        clickable: false,
      },
      {
        key: 'phoneNumber',
        columnName: 'Phone Number',
        clickable: false,
      },
      {
        key: 'emailAddress',
        columnName: 'Email Address',
        clickable: false,
      },
    ],
  },
  [`${process.env.REACT_APP_ASSET_INDEX}`]: {
    firstContext: 'asset',
    firstContextVar: 'assetInfoId',
    displayData: [
      {
        key: 'primaryIdentifier',
        columnName: 'Primary Identifier',
        clickable: true,
      },
      {
        key: 'primaryIdentifierType',
        columnName: 'Primary Identifier Type',
        clickable: false,
      },
      {
        key: 'assetName',
        columnName: 'Asset Name',
        clickable: false,
      },
      {
        key: 'address',
        columnName: 'Address',
        clickable: false,
      },
    ],
  },
};

function DisplaySearch({
  searchData,
  indexName,
}: DisplaySearchProps): JSX.Element {
  const module = useAppSelector((state) => state.user.module);
  const { t } = useTranslation();
  const theme = useTheme();
  const columns: ColumnDef<GeneralDataResult>[] = useMemo(() => {
    const index = indexKeys[indexName as keyof typeof indexKeys];
    if (index) {
      return index.displayData.map((item) => {
        if (item.clickable) {
          return {
            id: item.key,
            accessorKey: item.key,
            header: () => <HeaderColumnNext localization={item.columnName} />,
            cell: ({ row }) => {
              let style: KeyValue = { color: theme.palette.linkBlue.dark };
              if (row.original.isViewable !== 'true') {
                style = {
                  pointerEvents: 'none',
                  color: '#555452',
                  textDecoration: 'none',
                };
              }
              return (
                <Link
                  to={
                    index.secondContext
                      ? {
                          pathname: `/${module}/${index.firstContext}/${
                            row.original[
                              index.firstContextVar as keyof typeof row.original
                            ]
                          }/${index.secondContext}/${
                            row.original[
                              index.secondContextVar as keyof typeof row.original
                            ]
                          }`,
                        }
                      : {
                          pathname: `/${module}/${index.firstContext}/${
                            row.original[
                              index.firstContextVar as keyof typeof row.original
                            ]
                          }`,
                        }
                  }
                  style={style}
                >
                  {row.original[item.key as keyof typeof row.original]}
                </Link>
              );
            },
          };
        }
        return {
          id: item.key,
          accessorKey: item.key,
          header: () => <HeaderColumnNext localization={item.columnName} />,
        };
      });
    }
    return [];
  }, [indexName, module, theme.palette.linkBlue.dark]);

  return (
    <Box sx={{ mt: 5 }}>
      {searchData.length > 0 && (
        <DefaultDataTableNext
          id={`${searchData[0].resultType}`}
          columns={columns as Column<KeyValue>[]}
          data={searchData}
          customHeader={
            <Typography
              sx={{
                fontWeight: 'bold',
                color: 'primary.main',
                fontSize: '20px',
              }}
            >
              {searchData[0].resultType + ' ' + t('pages.search.searchResults')}
            </Typography>
          }
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        />
      )}
    </Box>
  );
}

export default DisplaySearch;
