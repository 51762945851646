// refer to confluece doc for more details
// Rule 1
function logicRuleWithOOTB(
  isFullAccess: boolean,
  isLimitAccess: boolean,
  isOOTB: boolean
): boolean {
  /*
    p q r active
    0 0 0 0  <- if no edit permissions, cannot edit
    0 1 0 1  <- if limmited permissions, can edit
    0 1 1 0  <- if limited but item is OOTB, user can't edit
    1 0 0 1  <- from now on, if user is full he can edit
  */
  return isFullAccess || (isLimitAccess && !isOOTB);
}
// Rule 2
function logicRuleWithOOTBEditable(
  isFullAccess: boolean,
  isLimitAccess: boolean,
  isOOTB: boolean,
  isOOTBEditable: boolean
): boolean {
  /*
    this is needed because "isOOTBEditable" can override if the user with limit access can edit or not
    A	B	C	D	Active
    0	1	1	0	0 <- if user is limited and the item is OOTB, user cannot edit 
    0	1	1	1	1 <- if user is limited and the item is OOTB, but now the item is OOTBEditable, user can edit
    1	0	0	0	1 <- from now on if the user is full acces, he can edit all
  */
  return (
    (isFullAccess || isLimitAccess) &&
    (isFullAccess || !isLimitAccess || !isOOTB || isOOTBEditable)
  );
}
// Rule 4
function logicRuleWithGroup(
  isFullAccess: boolean,
  isLimitAccess: boolean,
  group: boolean
) {
  /*
    With the group extended attributes, basically OOTB is not taken into account
    we removed that variable to make a simplier rule
    A	B	C	Active
    0	0	0	0 <- If no permissions -> false
    0	1	0	0 <- If Limit, group is not extended -> false
    0	1	1	1 <- If limit, group is extended, -> true
    1 0 0 1 <- If full access,               ->true
  */
  return isFullAccess || (isLimitAccess && group);
}

type EditAccessConstructorParams = {
  isFullAccess: boolean;
  isLimitAccess: boolean;
  /**
   * In typeEditor extendedAttributes is an special group where one configuration can be part of,
   * it defines some special behaviour for limitAccess user. If the user is limit, and the group
   * is extended, user can edit this configuration.
   * @example
   * // set isExtendedAttributes in the constructor of your instance.
   * const editAccess = new EditAccessUtil({
   * isFullAccess: false,
   * isLimitAccess: true,
   * isExtendedAttributes: groupName === 'extendedAttributes' // or any other statement that returns true or false
   * });
   * // now the rule controlExtendedGroup will take into account the group to define it's behaviour.
   * // In this example the user is limitAccess, only if the group is extendedAttribute this rule will return true,
   * // meaning that the limitAccess user can edit.
   * console.log(editAccess.controlExtendedGroup())
   */
  isExtendedAttributes?: boolean;
  /**
   * This flag has a higer herarchy in contrast with other rules. It is able to override any other return value.
   * It will not matter whether other rules are returning "true" (telling you that user can edit), if this readOnly
   * flag is "true" will override the rule's return value and set it to false. Telling you that user CANNOT edit
   * This is experimental, check how readOnly requirements evolves to decide whether include readOnly into the
   * logicRules.
   * @example
   * const editAccess = new EditAccessUtil({
   * isFullAccess: true,
   * isLimitAccess: false,
   * isReadOnly: true,
   * });
   * console.log(editAccess.generalEdit); // it will return => false
   * // because isReadOnly will override the generalEdit rule, without redOnly flag generalEdit rule would have returned "true"
   * // now it wont be able to do it, indeed it is read only for all users now.
   * // The same for the rules:
   * editAccess.controlWithOOTB
   * editAccess.controlWithOOTBEditable
   * editAccess.controlExtendedGroup
   */
  isReadOnly?: boolean;
};

export class EditAccessUtil {
  private _isFullAccess: boolean;

  private _isLimitAccess: boolean;

  private _generalEdit: boolean;

  private _isExtendedAttributes: boolean;

  private _isReadOnly: boolean;

  constructor({
    isFullAccess,
    isLimitAccess,
    isExtendedAttributes = false,
    isReadOnly = false,
  }: EditAccessConstructorParams) {
    this._isFullAccess = isFullAccess;
    this._isLimitAccess = isLimitAccess;
    this._isExtendedAttributes = isExtendedAttributes;
    this._isReadOnly = isReadOnly;
    // Rule 3, general edit says if user has any edit permission, so, edit should be enabled
    // overridden of generalEdit with readOnly
    this._generalEdit = isReadOnly ? false : isFullAccess || isLimitAccess;
  }

  get isFullAccess(): boolean {
    return this._isFullAccess;
  }

  get isLimitAccess(): boolean {
    return this._isLimitAccess;
  }

  get isExtendedAttributes(): boolean {
    return this._isExtendedAttributes;
  }

  /**
   * General edit says if user doesn't have any edit permission, edit should be disabled
   * if user has any edit access either limit or full, this will be true.
   * Important: readOnly overrides this rule, if it is readOnly general edit is always false. Meaning
   * the user shouldn't be able to edit even if has edit access policiesG.
   * Usefull to activate elements that any edit access user should be able to use.
   */
  get generalEdit(): boolean {
    return this._generalEdit;
  }

  get isReadOnly(): boolean {
    return this._isReadOnly;
  }

  public controlWithOOTB(isOOTB: boolean): boolean {
    if (this.isReadOnly) return false;
    return logicRuleWithOOTB(this._isFullAccess, this._isLimitAccess, isOOTB);
  }

  public controlWithOOTBEditable(
    isOOTB: boolean,
    isOOTBEditable: boolean
  ): boolean {
    if (this.isReadOnly) return false;
    return logicRuleWithOOTBEditable(
      this._isFullAccess,
      this._isLimitAccess,
      isOOTB,
      isOOTBEditable
    );
  }

  public controlExtendedGroup(): boolean {
    if (this.isReadOnly) return false;
    return logicRuleWithGroup(
      this._isFullAccess,
      this._isLimitAccess,
      this._isExtendedAttributes
    );
  }
}
