import { useEffect, useState } from 'react';

import { Typography } from '@mui/material';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import { Card, CardContent } from '@revenue-solutions-inc/revxcoreui';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import DateCell from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/TableCells/DateCell';
import { ColumnDef, Row } from '@tanstack/react-table';
import Loading from 'components/Loading';
import {
  useCorrespondenceTypesQuery,
  useGetFindByJsonParameterQuery,
  Maybe,
  useGetGeneratedLetterUserRolesQuery,
  User,
} from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import useGetAccessToken from 'hooks/useGetAccessToken';
import useGetContentMgmtLetter from 'hooks/useGetContentMgmtLetter';
import { useTheme } from '@mui/system';
import { useAppSelector } from 'redux/hooks';
import useQueryRequest from 'hooks/useQueryRequest';
import { useMsal } from '@azure/msal-react';
import { userBySearch } from 'pages/admin/ManageUserRoles/ManageuserRolesQuery';

interface Props {
  isHistoryRefetch: boolean;
  entityId: Maybe<string> | undefined;
  isFromEntity: boolean;
  accountId: Maybe<string> | undefined;
}

function LetterHistory(letterProps: Props): JSX.Element {
  const { t } = useTranslation();
  const theme = useTheme();
  const { accounts } = useMsal();
  const module = useAppSelector((state) => state.user.module);
  const { entityId, accountId } = useParams() as {
    entityId: string;
    accountId: string;
  };
  const {
    data: letterHistoryData,
    isFetching,
    refetch: letterHistoryRefetch,
  } = useGetFindByJsonParameterQuery({
    searchItem: 'WorkflowVariantCode=CorrespondenceWorkflow',
  });
  const { data: CorrespondenceData } = useCorrespondenceTypesQuery();
  const accessToken = useGetAccessToken();
  const [rolesArray, setRolesArray] = useState<number[]>([]);
  const [letterIds, setLetterIds] = useState({
    contentId: '',
    letterId: '',
  });
  const { refetch: refetchContentData } = useGetContentMgmtLetter(
    accessToken,
    letterIds.contentId,
    letterIds.letterId
  );
  const { data: userData } = useQueryRequest<{
    UsersBySearch: [User];
  }>(
    ['search', accounts[0]?.username],
    accessToken,
    userBySearch,
    {
      search: accounts[0]?.username,
    },
    'search'
  );

  useEffect(() => {
    if (letterIds.contentId && letterIds.contentId.length > 0) {
      refetchContentData();
      setLetterIds({ contentId: '', letterId: '' });
    }
  }, [letterIds, refetchContentData]);

  const {
    data: rolesData,
    refetch: refetchLetterDetails,
    isFetched,
  } = useGetGeneratedLetterUserRolesQuery(
    {
      getGeneratedLetterUserRolesId: {
        ids: rolesArray,
      },
    },
    { enabled: false, cacheTime: 0 }
  );

  type LetterListData = {
    IsFTI: boolean;
    LetterId: number;
    CorrespondenceTypeId: number | string;
    CorrespondenceCategoryType: string;
    DocumentStatus: string;
    AddressLine1: string;
    AddressLine2: string;
    City: string;
    State: string;
    PostalCode: number;
    TemplateId: number;
    ContentManagementId: string;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [historyState, setHistoryState] = useState<any>([]);
  useEffect(() => {
    if (letterProps.isHistoryRefetch) letterHistoryRefetch();
  }, [letterHistoryRefetch, letterProps.isHistoryRefetch]);

  useEffect(() => {
    if (letterHistoryData && letterHistoryData?.GetFindByJsonParameter) {
      setHistoryState(
        letterHistoryData?.GetFindByJsonParameter.map(
          (item: { wF_Full_Object: string }) => {
            return JSON.parse(item.wF_Full_Object);
          }
        )
      );
    }
  }, [letterHistoryData]);
  useEffect(() => {
    if (historyState?.length > 0) {
      let letters;
      if (letterProps.isFromEntity) {
        letters = [...historyState]
          ?.reverse()
          .filter(
            (item: {
              ContextId: string;
              ContextLevel: string;
              DocumentStatus: string;
            }) =>
              item.ContextId === letterProps.entityId &&
              item.ContextLevel?.toLocaleUpperCase() == 'ENTITY' &&
              item.DocumentStatus !== null
          );
      } else {
        letters = [...historyState]
          ?.reverse()
          .filter(
            (item: {
              ContextId: string;
              ContextLevel: string;
              DocumentStatus: string;
            }) =>
              item.ContextId == letterProps.accountId &&
              item.ContextLevel?.toLocaleUpperCase() == 'ACCOUNT' &&
              item.DocumentStatus !== null
          );
      }

      const localItems = [];
      for (let index = 0; index < letters.length; index++) {
        localItems.push(parseInt(letters[index].CorrespondenceTypeId));
      }
      setRolesArray(
        localItems.filter((el, index, self) => self.indexOf(el) == index)
      );
    }
  }, [historyState, letterProps]);

  useEffect(() => {
    if (rolesArray.length > 0 && !isFetched) {
      refetchLetterDetails();
    }
  }, [refetchLetterDetails, rolesArray, isFetched, rolesData]);

  const hasPermissions = (CorrespondenceTypeId: string | number) => {
    const userRoleIds: string[] | undefined =
      userData?.UsersBySearch[0]?.userRole?.map((el) => el.roleId);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const isCorrespondenceTypeIdExists: any =
      rolesData?.GetGeneratedLetterUserRoles?.find(
        (el) =>
          el.correspondenceTypeId?.toString() ===
          CorrespondenceTypeId.toString()
      );
    const isValidLetter: boolean =
      isCorrespondenceTypeIdExists && userRoleIds?.length
        ? isCorrespondenceTypeIdExists?.roleAssignments.find(
            (item: { roleId: string }) =>
              userRoleIds?.includes(item.roleId.toString())
          )
        : false;
    return isValidLetter;
  };

  const onDownload = (isValidLetter: boolean, row: Row<LetterListData>) => {
    if (isValidLetter) {
      setLetterIds({
        contentId: row.original.ContentManagementId,
        letterId: String(row.original.LetterId),
      });
    }
  };

  const letterListColumns: ColumnDef<LetterListData>[] = [
    {
      header: () => (
        <HeaderColumnNext
          localization={t('pages.correspondence.correspondenceData.id')}
        />
      ),
      accessorKey: 'LetterId',
      cell: ({ row }) => {
        return hasPermissions(row.original.CorrespondenceTypeId) ? (
          <Link
            to={{
              pathname: `/${module}/letterDetails/${row.original.LetterId}/${row.original.TemplateId}`,
            }}
            state={
              letterProps.isFromEntity
                ? {
                    prevPath: `/entity/${entityId}`,
                    prevPageName: t('pages.entitySummary.title'),
                  }
                : {
                    prevPath: `/entity/${entityId}/account/${accountId}`,
                    prevPageName: t('pages.accountSummary.title'),
                  }
            }
            style={{ color: theme.palette.linkBlue.dark }}
          >
            {row.original.LetterId}
          </Link>
        ) : (
          <Typography color={theme.palette.grey1.main}>
            {row.original.LetterId}
          </Typography>
        );
      },
    },
    {
      header: () => (
        <HeaderColumnNext
          localization={t(
            'pages.correspondence.correspondenceData.correspondenceType'
          )}
        />
      ),
      accessorKey: 'CorrespondenceTypeId',
      cell: ({ row }) => {
        if (CorrespondenceData?.CorrespondenceTypes) {
          return (
            <Typography>
              {
                CorrespondenceData.CorrespondenceTypes.find(
                  (item) =>
                    item.correspondenceId == row.original.CorrespondenceTypeId
                )?.name
              }
            </Typography>
          );
        }
      },
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.correspondence.fti')} />
      ),
      accessorKey: 'IsFTI',
      cell: ({ row }) => {
        if (row.original.IsFTI) {
          return 'Yes';
        } else {
          return 'No';
        }
      },
    },
    {
      header: () => (
        <HeaderColumnNext
          localization={t('pages.correspondence.correspondenceData.status')}
        />
      ),
      accessorKey: 'DocumentStatus',
    },
    {
      header: () => (
        <HeaderColumnNext
          localization={t('pages.correspondence.correspondenceData.printDate')}
        />
      ),
      accessorKey: 'PrintDate',
      cell(props) {
        return props.getValue() ? (
          <DateCell
            dateString={
              props.getValue()
                ? new Date(String(props.getValue())).toLocaleDateString() ||
                  'null'
                : ''
            }
          />
        ) : (
          <></>
        );
      },
    },
    {
      id: 'content-download',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.correspondence.correspondenceData.actions')}
        />
      ),

      cell: ({ row }) => {
        const isValidLetter =
          row.original.ContentManagementId !== null &&
          hasPermissions(row.original.CorrespondenceTypeId);
        return (
          <button
            onClick={() => {
              onDownload(isValidLetter, row);
            }}
            style={{
              color: '#1B75E2',
              border: '1px solid #1B75E2',
              borderRadius: '4px',
              cursor: isValidLetter ? 'pointer' : '',
            }}
          >
            <DownloadRoundedIcon
              style={{
                cursor: isValidLetter ? 'pointer' : '',
              }}
              color={isValidLetter ? 'primary' : 'disabled'}
              onClick={() => {
                onDownload(isValidLetter, row);
              }}
            ></DownloadRoundedIcon>
          </button>
        );
      },
    },
  ];

  return (
    <>
      {isFetching && <Loading fullScreen={false} />}
      {!isFetching &&
        rolesArray?.length === 0 &&
        t('pages.manageReusableContent.noLetterHistory')}
      {!isFetching &&
        letterHistoryData?.GetFindByJsonParameter &&
        letterHistoryData?.GetFindByJsonParameter?.length > 0 &&
        rolesData?.GetGeneratedLetterUserRoles && (
          <Card>
            <CardContent>
              <DefaultDataTableNext
                data={
                  letterProps.isFromEntity
                    ? ([...historyState]
                        ?.reverse()
                        .filter(
                          (item: {
                            ContextId: string;
                            ContextLevel: string;
                            DocumentStatus: string;
                          }) =>
                            item.ContextId === letterProps.entityId &&
                            item.ContextLevel?.toLocaleUpperCase() ==
                              'ENTITY' &&
                            item.DocumentStatus !== null
                        ) as Record<string, unknown>[])
                    : ([...historyState]
                        ?.reverse()
                        .filter(
                          (item: {
                            ContextId: string;
                            ContextLevel: string;
                            DocumentStatus: string;
                          }) =>
                            item.ContextId == letterProps.accountId &&
                            item.ContextLevel?.toLocaleUpperCase() ==
                              'ACCOUNT' &&
                            item.DocumentStatus !== null
                        ) as Record<string, unknown>[])
                }
                columns={
                  letterListColumns as ColumnDef<
                    Record<string, unknown>,
                    unknown
                  >[]
                }
              ></DefaultDataTableNext>
            </CardContent>
          </Card>
        )}
    </>
  );
}

export default LetterHistory;
