import { useCallback, useMemo, useState } from 'react';

import { Grid } from '@mui/material';
import {
  ContactMail,
  History,
  Interests,
  Link,
  MonetizationOn,
  OpenInNew,
  Savings,
  Work,
} from '@mui/icons-material';
import { Theme } from '@mui/system';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import Dialog from '@revenue-solutions-inc/revxcoreui/material/controls/Dialog';
import { ConfigurationDomains } from 'common/platformConfigUtils/platformConfigUtils';
import EntityManagementContext from 'components/contexts/EntityManagement';
import DataCard from 'components/DataCard';
import { sectionNames } from 'components/entityManagement/common/assetUtils';
import EntityAuditHistory from 'components/entityManagement/common/EntityAuditHistory';
import {
  getIdFormats,
  getMaskRules,
  getValidationRules,
} from 'components/entityManagement/common/formatValidations';
import { SourceType } from 'components/entityManagement/common/relationshipUtils';
import CreateRelationship from 'components/entityManagement/relationships/CreateRelationship';
import RelationshipList from 'components/entityManagement/relationships/RelationshipList';
import UnderConstruction from 'components/UnderConstruction';
import {
  Asset,
  Attribute,
  useGetLookupConfigurationQuery,
} from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { AssetForm } from 'types/assets';
import { formatIdentifier } from 'utils/formatIdentifier';

import AssetDetail from '../AssetDetail';
import AssetDetailsView from '../AssetDetailsView';

interface Props {
  data: Asset;
  refetchData: () => void;
}

const openModalTheme = (theme: Theme) => ({
  fill: theme.palette.primary.main,
  cursor: 'pointer',
  '&:hover': { backgroundColor: theme.palette.secondary.main },
});

const setIconColor = (theme: Theme) => ({ fill: theme.palette.primary.main });

function AssetView({ data, refetchData }: Props): JSX.Element {
  const MAIN_CONFIG_DOMAIN = ConfigurationDomains.ReferenceSchema;
  const { t } = useTranslation();
  const [openAssetData, setOpenAssetData] = useState<boolean>(false);
  const [openRelationships, setOpenRelationships] = useState<boolean>(false);
  const [refetchRelationships, setRefetchRelationships] =
    useState<boolean>(false);

  const { data: configuredIdTypes } = useGetLookupConfigurationQuery({
    configurationDomain: MAIN_CONFIG_DOMAIN,
    configurationType: 'IdType',
  });

  const getField = useCallback(
    (section: string) => {
      return data.group?.filter(
        (attribute) => attribute?.groupName?.toLocaleLowerCase() === section
      );
    },
    [data]
  );

  const handleRelationshipRefetch = () => {
    setRefetchRelationships(!refetchRelationships);
  };

  const mapToAssetForm = (): AssetForm => {
    const assetForm: AssetForm = {
      names: [],
      addresses: [],
      identifiers: [],
      other: [],
    };
    sectionNames.forEach((section) => {
      const groupsInData = getField(section);
      const sectionNameKey = section as keyof typeof assetForm;
      if (groupsInData) {
        groupsInData.forEach((group) => {
          let attributeToAdd = {};
          group.attribute.forEach((attribute: Attribute) => {
            attributeToAdd = {
              ...attributeToAdd,
              [attribute.attributeName]: attribute.attributeValue,
            };
          });
          if (attributeToAdd) {
            assetForm[sectionNameKey]?.push(attributeToAdd);
          }
        });
      }
    });
    return assetForm;
  };

  const getPrimaryName = (): string => {
    const formData = mapToAssetForm();
    const primaryName = formData?.names?.find(
      (name) => name.isPrimary === 'true'
    );
    return primaryName !== undefined && primaryName.value
      ? primaryName.value
      : '';
  };

  const getFormattedPrimaryId = (): string => {
    if (
      configuredIdTypes &&
      data.primaryIdentifier &&
      data.primaryIdentifierType
    ) {
      return formatIdentifier(
        data.primaryIdentifier,
        getIdFormats(configuredIdTypes),
        data.primaryIdentifierType
      );
    }
    return '';
  };

  const createRelationshipBtn = (): JSX.Element => {
    return (
      <Button
        id="create-asset-relationship-btn"
        type="secondary"
        variant="text"
        onClick={() => setOpenRelationships(!openRelationships)}
      >
        {t('pages.createAsset.buttonTitle')}
      </Button>
    );
  };

  /**
   * Provides the needed values to the Entity/Asset Context
   */
  const contextValue = useMemo(() => {
    return {
      selectedType: data.assetType ?? '',
      selectedIdType: '',
      selectedCommenceDate: data.commenceDate ?? '',
      onTypeChange: () => {},
      onIdTypeChange: () => {},
      onCommenceDateChange: () => {},
      idValidationRules: getValidationRules(configuredIdTypes),
      idFormats: getIdFormats(configuredIdTypes),
      idMaxLengths: getMaskRules(configuredIdTypes),
    };
  }, [data, configuredIdTypes]);

  const GetFullAssetDetail = (): JSX.Element => {
    return (
      <EntityManagementContext.Provider value={contextValue}>
        <AssetDetailsView
          data={mapToAssetForm()}
          assetData={data}
          assetId={data.id ?? ''}
          assetType={data.assetType ?? ''}
          platformConfigId={data.platformConfigurationId ?? ''}
          refetchData={refetchData}
        />
      </EntityManagementContext.Provider>
    );
  };

  return (
    <Grid container spacing={3}>
      <Grid sx={{ display: 'flex' }} item xs={12} lg={3}>
        <DataCard
          title={t('pages.assetSummary.sectionTitles.assetDetail')}
          avatar={<Interests sx={setIconColor} />}
          action={
            <OpenInNew
              data-testid="open-asset-detail-modal"
              sx={openModalTheme}
              onClick={() => setOpenAssetData(!openAssetData)}
            />
          }
          children={<AssetDetail data={data} />}
        />
        <Dialog
          id="all-asset-data"
          type="passive"
          title={t('pages.assetSummary.sectionTitles.assetDetail')}
          open={openAssetData}
          handleClose={() => setOpenAssetData(!openAssetData)}
          height={580}
          maxWidth="xl"
        >
          {<GetFullAssetDetail />}
        </Dialog>
      </Grid>
      <Grid sx={{ display: 'flex' }} item xs={12} lg={6}>
        <DataCard
          title={t('pages.assetSummary.sectionTitles.relationships')}
          avatar={<Link sx={setIconColor} />}
          action={createRelationshipBtn()}
          children={
            <RelationshipList
              id={data.id}
              sourceType={data.assetType ?? ''}
              sourceName={getPrimaryName()}
              sourceId={getFormattedPrimaryId()}
              executeRefetch={refetchRelationships}
            />
          }
        />
      </Grid>
      <Dialog
        id="asset-relationships-modal-form"
        type="passive"
        title={t('pages.assetSummary.sectionTitles.relationships')}
        open={openRelationships}
        handleClose={() => setOpenRelationships(!openRelationships)}
        height={380}
        maxWidth="lg"
      >
        <CreateRelationship
          sourceInfoId={data.id ?? ''}
          sourceType={data.assetType ?? ''}
          generalSourceType={SourceType.Asset}
          refetchRelationships={handleRelationshipRefetch}
        />
      </Dialog>
      <Grid sx={{ display: 'flex' }} item xs={12} lg={3}>
        <DataCard
          title={t('pages.assetSummary.sectionTitles.correspondence')}
          avatar={<ContactMail sx={setIconColor} />}
          children={<UnderConstruction setFpo={true} />}
        />
      </Grid>
      <Grid sx={{ display: 'flex' }} item xs={12} lg={6}>
        <DataCard
          title={t('pages.assetSummary.sectionTitles.workflows')}
          avatar={<Work sx={setIconColor} />}
          children={<UnderConstruction setFpo={true} />}
        />
      </Grid>
      <Grid sx={{ display: 'flex' }} item xs={12} lg={6}>
        <DataCard
          title={t('pages.assetSummary.sectionTitles.accounts')}
          avatar={<Savings sx={setIconColor} />}
          children={<UnderConstruction setFpo={true} />}
        />
      </Grid>
      <Grid sx={{ display: 'flex' }} item xs={12} lg={6}>
        <DataCard
          title={t('pages.assetSummary.sectionTitles.auditHistory')}
          avatar={<History sx={setIconColor} />}
          children={<EntityAuditHistory tenantId={data.tenantId ?? ''} />}
        />
      </Grid>
      <Grid sx={{ display: 'flex' }} item xs={12} lg={6}>
        <DataCard
          title={t('pages.assetSummary.sectionTitles.payments')}
          avatar={<MonetizationOn sx={setIconColor} />}
          children={<UnderConstruction setFpo={true} />}
        />
      </Grid>
    </Grid>
  );
}

export default AssetView;
