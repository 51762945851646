import { Grid, Typography } from '@mui/material';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button/Button';
import error from 'assets/errorbg.png';

import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useLogoutApp } from 'hooks/useLogoutApp';

function Error(): JSX.Element {
  const { t } = useTranslation();
  const history = useNavigate();
  const logoutAction = useLogoutApp();
  return (
    <Grid
      container
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        mt: '14%',
      }}
    >
      <Grid
        item
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          backgroundImage: `url(${error})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          p: 3,
          borderTopRightRadius: '24px',
          width: '100%',
        }}
        xs={6}
      >
        <Typography variant="h1" color="common.white">
          {t('components.error.title')}
        </Typography>
        <ReportProblemIcon
          sx={{
            color: 'common.white',
            fontSize: 30,
          }}
        />
      </Grid>
      <Grid
        item
        xs={6}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          p: 2,
          backgroundColor: 'common.white',
          alignItems: 'flex-end',
          width: '100%',
        }}
      >
        <Typography variant="h2">
          {t('components.error.msg')}
          <Link to="/"> {t('components.pagenotfound.dashboard')}</Link>
          {t('components.pagenotfound.or')}
          <Link
            to={' '}
            onClick={() => {
              history(-1);
            }}
          >
            {t('components.pagenotfound.back')}
          </Link>
          {t('components.pagenotfound.towhere')}
        </Typography>
        <Button
          id="btnId"
          type="secondary"
          variant="outlined"
          onClick={logoutAction}
          sx={{ mt: 1 }}
        >
          {t('components.error.signout')}
        </Button>
      </Grid>
    </Grid>
  );
}

export default Error;
