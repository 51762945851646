import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import DynamicAttributes from 'components/manageConfigTools/DynamicAttributes';
import { ConfigurationResponse } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { ManageConfigToolsContext } from 'components/contexts/ManageConfigToolsProvider/ManageConfigToolsProvider';
import Loading from 'components/Loading';
import { Box } from '@mui/system';
import { Parameters } from 'pages/admin/createScheduledTasks/ScheduledTasks/ScheduledTasks';
import { Button } from '@revenue-solutions-inc/revxcoreui';
import { IconButton, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import {
  fontSize,
  recordIcons,
} from 'components/manageConfigTools/Shared/IconButtonStyling/IconButtonStyling';

interface Props {
  arrayTest: Parameters[];
  dataParameters: ConfigurationResponse | undefined;
  setDataParameters: Dispatch<
    SetStateAction<ConfigurationResponse | undefined>
  >;
  setInitialJSON: Dispatch<SetStateAction<ConfigurationResponse[]>>;
  setInitialRender: Dispatch<SetStateAction<boolean>>;
  initialRender: boolean;
  stageNames: string[];
}

function TaskRunParameters({
  arrayTest,
  setDataParameters,
  setInitialRender,
  initialRender,
  setInitialJSON,
  stageNames,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const { setSelectedRecord } = useContext(ManageConfigToolsContext);
  const [parameterValues, setParameterValues] = useState<
    ConfigurationResponse[]
  >([]);
  const [showButtons, setShowButtons] = useState<boolean>(false);
  const [indexEdit, setIndexEdit] = useState<number>();

  useEffect(() => {
    if (arrayTest.length > 0 && initialRender === false) {
      const values = arrayTest.map((item) => {
        return item.data.getConfiguration;
      });

      setParameterValues(values as unknown as ConfigurationResponse[]);
      setInitialJSON(JSON.parse(JSON.stringify(values)));
      setInitialRender(true);
    }
  }, [
    arrayTest,
    initialRender,
    setDataParameters,
    setInitialJSON,
    setInitialRender,
    setSelectedRecord,
  ]);

  return (
    <>
      {initialRender ? (
        <Box sx={{ marginTop: '20px' }}>
          {parameterValues.map((data: ConfigurationResponse, indexData) => (
            <Box key={indexData}>
              {showButtons && indexEdit === indexData && (
                <Button
                  id="saveParameters"
                  disabled={false}
                  onClick={() => {
                    setShowButtons(false);
                  }}
                >
                  {t('pages.manageChannel.saveParameters')}
                </Button>
              )}
              <IconButton
                onClick={() => {
                  setIndexEdit(indexData);
                  setShowButtons(true);
                  setSelectedRecord(data);
                }}
                sx={recordIcons}
                aria-label="edit-button"
                title={t('pages.configRecordEditor.editIcon')}
                disabled={showButtons}
              >
                <EditIcon sx={{ fontSize }} />
              </IconButton>
              {stageNames && (
                <Typography variant="h3">
                  {`stage: ${stageNames[indexData]}`}
                </Typography>
              )}
              <DynamicAttributes
                currentData={data}
                currentSchema={data}
                editDisabled={indexEdit !== indexData || !showButtons}
                errors={{}}
              />
            </Box>
          ))}
        </Box>
      ) : (
        <Loading />
      )}
    </>
  );
}
export default TaskRunParameters;
