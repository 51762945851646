import { useContext, Fragment } from 'react';

import { Grid, GridProps, Box } from '@mui/material';
import Checkbox from '@revenue-solutions-inc/revxcoreui/material/controls/Checkbox';
import DatePicker from '@revenue-solutions-inc/revxcoreui/material/controls/DatePicker';
import Input from '@revenue-solutions-inc/revxcoreui/material/controls/Input';
import Select from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import MultiSelect from '@revenue-solutions-inc/revxcoreui/material/controls/MultiSelect/MultiSelect';
import EntityManagementContext from 'components/contexts/EntityManagement';
import DatasourceSelect from 'components/DatasourceSelect';
import AddressSelect from 'components/entityManagement/accounts/create/AddressSelect';
import { getMaxLength } from 'components/entityManagement/common/entityUtils';
import LookupConfiguration from 'components/LookupConfiguration/LookupConfiguration';
import RadioGroup from 'components/RadioGroup';
import { Controller, UseFormReturn } from 'react-hook-form';
import { SectionField, Section } from 'types/forms';
import { AccountAddress } from 'types/accounts';
import { toDate, toStringDate } from 'utils/date-util';
import { formatIdentifier } from 'utils/formatIdentifier';
import IndustryCodeAutocomplete from 'components/entityManagement/accounts/IndustryCodeAutocomplete';

interface Props {
  // eslint-disable-next-line
  control: UseFormReturn['control'] | any;
  setValue: UseFormReturn['setValue'];
  section: Section;
  entityId?: string;
  haveIndex?: boolean;
  index?: number;
  gridItemProps?: GridProps;
}

/**
 * ControlledField is a component that uses react-hook-form to render a set of fields
 * @param Props.control Object from react-hook-form that controls a form field
 * @param Props.section An internal object type that is used to render a set of fields
 * @param Props.entityId This is only used in Account Creation
 * @param Props.haveIndex Indicates whether we have more than one object per section
 * @param Props.index The index of the field depending if haveIndex is true
 * @returns Controlled Field (Select, Input, Datepicker, Radio)
 */
function ControlledField({
  control,
  section,
  entityId,
  haveIndex = false,
  index,
  setValue,
  gridItemProps,
}: Props): JSX.Element {
  // This couples us too tightly to entityManagement
  // We can get rid of using context entireley, and move to using watch
  // Or we can make this  file specific to entity management
  const ctx = useContext(EntityManagementContext);

  const setIdentifier = (
    sectionIdentifier: string,
    fieldIdentifier: string
  ): string => {
    if (haveIndex) return `${sectionIdentifier}.${index}.${fieldIdentifier}`;
    else return `${sectionIdentifier}.${fieldIdentifier}`;
  };

  const getRules = (field: SectionField): object | undefined => {
    if (field.type === 'identifier') {
      if (!ctx.idValidationRules || !ctx.selectedIdType) return field.rules;
      const regExp = ctx.idValidationRules[ctx.selectedIdType];
      const rules = {
        required: 'ID is required',
        pattern: {
          value: regExp,
          message: `Please provide a valid ${ctx.selectedIdType}`,
        },
      };
      return rules;
    } else return field.rules;
  };

  const getCommenceDate = (
    field: SectionField,
    value: Date | string
  ): Date | null => {
    if (field.fieldIdentifier === 'commenceDate') {
      return value !== null
        ? toDate(value)
        : value !== ''
        ? toDate(ctx.selectedCommenceDate)
        : null;
    }
    return value !== '' ? toDate(value) : null;
  };

  const setCtxCommenceDate = (date: Date | null, fieldId: string) => {
    const commenceDateSections: string[] = ['others', 'account', 'other'];
    if (
      commenceDateSections.includes(section.sectionIdentifier) &&
      fieldId === 'commenceDate'
    ) {
      ctx.onCommenceDateChange(toStringDate(date));
    }
  };

  return (
    <Grid container spacing={3}>
      {section.fields
        .filter((field) => field.type !== 'selectOnlyEdit' && !field.hidden)
        .map((field) => {
          return (
            <Grid
              key={setIdentifier(
                section.sectionIdentifier,
                field.fieldIdentifier
              )}
              item
              xs={12}
              sm={6}
              md={3}
              {...gridItemProps}
            >
              <Controller
                name={setIdentifier(
                  section.sectionIdentifier,
                  field.fieldIdentifier
                )}
                control={control}
                rules={getRules(field)}
                defaultValue={''}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  const errorMessage = error && error?.message;
                  switch (field.type?.toLowerCase()) {
                    case 'select':
                      return (
                        <Select
                          id={setIdentifier(
                            section.sectionIdentifier,
                            field.fieldIdentifier
                          )}
                          options={field.options ?? []}
                          value={value}
                          onChange={onChange}
                          label={field.label}
                          required={field.isRequired}
                          error={errorMessage}
                          disabled={field.disabled}
                          sx={{
                            width: '100%',
                          }}
                        />
                      );

                    case 'multiselect':
                      return (
                        <MultiSelect
                          id={setIdentifier(
                            section.sectionIdentifier,
                            field.fieldIdentifier
                          )}
                          label={field.label}
                          onChange={onChange}
                          onDelete={(e) => {
                            onChange(value.filter((v: string) => v !== e));
                          }}
                          options={field.options ?? []}
                          sx={{
                            width: '100%',
                          }}
                          values={value || []}
                          size="medium"
                        />
                      );

                    case 'datasource':
                      return (
                        <DatasourceSelect
                          id={setIdentifier(
                            section.sectionIdentifier,
                            field.fieldIdentifier
                          )}
                          label={field.label}
                          value={value}
                          errorMsg={errorMessage}
                          datasource={field.datasource ?? ''}
                          groupName={field.groupName ?? ''}
                          attributeName={field.attributeName ?? ''}
                          extraType={field.extraType ?? ''}
                          context={field.context ?? ''}
                          fetchLayoutInfo={onChange}
                          required={field.isRequired}
                          maxWidth={true}
                        />
                      );

                    case 'referencedata':
                      return (
                        <LookupConfiguration
                          id={setIdentifier(
                            section.sectionIdentifier,
                            field.fieldIdentifier
                          )}
                          label={field.label}
                          value={value}
                          errorMsg={errorMessage}
                          configurationType={field.datasource ?? ''}
                          fetchLayoutInfo={onChange}
                          disabled={field.disabled}
                        />
                      );

                    case 'entityaddress':
                      return (
                        <AddressSelect
                          id={setIdentifier(
                            section.sectionIdentifier,
                            field.fieldIdentifier
                          )}
                          entityId={entityId ?? ''}
                          label={field.label}
                          value={value}
                          errorMsg={errorMessage}
                          onChange={onChange}
                          onChangeEntityData={(
                            data: AccountAddress,
                            valueChange: string
                          ) => {
                            setValue(section.sectionIdentifier, [
                              {
                                ...data,
                                [field.fieldIdentifier]: valueChange,
                              },
                            ]);
                          }}
                        />
                      );
                    case 'industrycodesource':
                      return (
                        <IndustryCodeAutocomplete
                          id={setIdentifier(
                            section.sectionIdentifier,
                            field.fieldIdentifier
                          )}
                          label={field.label}
                          value={value}
                          errorMsg={errorMessage}
                          onChange={onChange}
                          setValue={setValue}
                        />
                      );

                    case 'radio':
                      return (
                        <RadioGroup
                          id={setIdentifier(
                            section.sectionIdentifier,
                            field.fieldIdentifier
                          )}
                          label={field.label}
                          fieldIdentifier={field.fieldIdentifier}
                          value={value}
                          onChange={onChange}
                          radioOptions={field.radioOptions}
                          isRequired={field.isRequired}
                          error={errorMessage}
                        />
                      );

                    case 'date':
                      return (
                        <DatePicker
                          id={setIdentifier(
                            section.sectionIdentifier,
                            field.fieldIdentifier
                          )}
                          value={getCommenceDate(field, value)}
                          handleChange={(ev) => {
                            setCtxCommenceDate(ev, field.fieldIdentifier);
                            onChange(ev);
                          }}
                          label={field.label}
                          requiredErrorMessage={errorMessage}
                          required={field.isRequired}
                          disabled={field.disabled}
                          fullWidth={true}
                        />
                      );

                    case 'decimal':
                      return (
                        <Box maxWidth={'120px'}>
                          <Input
                            key={setIdentifier(
                              section.sectionIdentifier,
                              field.fieldIdentifier
                            )}
                            id={setIdentifier(
                              section.sectionIdentifier,
                              field.fieldIdentifier
                            )}
                            value={value}
                            onChange={onChange}
                            inputProps={{ type: 'number', step: 0.1 }}
                            label={field.label}
                            error={!!error}
                            helperText={errorMessage}
                            disabled={field.disabled}
                            endAdornment={<Fragment />}
                          />
                        </Box>
                      );

                    case 'int':
                      return (
                        <Box maxWidth={'120px'}>
                          <Input
                            key={setIdentifier(
                              section.sectionIdentifier,
                              field.fieldIdentifier
                            )}
                            value={value}
                            id={setIdentifier(
                              section.sectionIdentifier,
                              field.fieldIdentifier
                            )}
                            onChange={(e) => {
                              e.target.value = Math.floor(
                                Number(e.target.value)
                              ).toString();
                              onChange(e.target.value);
                            }}
                            inputProps={{ type: 'number', step: 1 }}
                            label={field.label}
                            error={!!error}
                            helperText={errorMessage}
                            disabled={field.disabled}
                            endAdornment={<Fragment />}
                          />
                        </Box>
                      );

                    case 'boolean':
                      return (
                        <Checkbox
                          key={setIdentifier(
                            section.sectionIdentifier,
                            field.fieldIdentifier
                          )}
                          id={setIdentifier(
                            section.sectionIdentifier,
                            field.fieldIdentifier
                          )}
                          onChange={onChange}
                        />
                      );

                    case 'identifier':
                      return (
                        <Input
                          key={setIdentifier(
                            section.sectionIdentifier,
                            field.fieldIdentifier
                          )}
                          id={setIdentifier(
                            section.sectionIdentifier,
                            field.fieldIdentifier
                          )}
                          value={formatIdentifier(
                            value,
                            ctx.idFormats,
                            ctx.selectedIdType
                          )}
                          onChange={onChange}
                          label={field.label}
                          error={!!error}
                          helperText={errorMessage}
                          required={field.isRequired}
                          inputProps={getMaxLength(
                            ctx.selectedIdType,
                            ctx.idMaxLengths
                          )}
                          sx={{
                            width: '100%',
                          }}
                        />
                      );

                    default:
                      return (
                        <Input
                          id={setIdentifier(
                            section.sectionIdentifier,
                            field.fieldIdentifier
                          )}
                          key={setIdentifier(
                            section.sectionIdentifier,
                            field.fieldIdentifier
                          )}
                          value={value}
                          onChange={onChange}
                          required={field.isRequired}
                          label={field.label}
                          error={!!error}
                          helperText={errorMessage}
                          disabled={field.disabled}
                          sx={{
                            width: '100%',
                          }}
                        />
                      );
                  }
                }}
              />
            </Grid>
          );
        })}
    </Grid>
  );
}

export default ControlledField;
