import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import NoResults from 'components/NoResults';
import { User } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
export interface UsersByRole {
  isLoading: boolean;
  users: User[];
}

function UsersByRoleInfo({ isLoading, users }: UsersByRole): JSX.Element {
  const { t } = useTranslation();
  return (
    <>
      {users && users.length > 0 && (
        <>
          <Typography
            variant="h3"
            sx={{ fontSize: 16, fontWeight: 'bold', marginBottom: 1 }}
          >
            {t('pages.roles.assignedUsers')}
          </Typography>
          {users.map(({ userId, name, email }) => (
            <ListItem
              key={`${userId}-${name}`}
              sx={{ padding: '0.1em 0' }}
              data-testid="user"
            >
              <ListItemText>{name}</ListItemText>
              <ListItemText>{email}</ListItemText>
            </ListItem>
          ))}
        </>
      )}
      {!isLoading && users.length === 0 && <NoResults />}
    </>
  );
}

export default UsersByRoleInfo;
