import { createReducer } from 'utils/createLocalReducer';
import type { ActionMap } from 'utils/createLocalReducer';
import { Attribute } from 'types/graphTypes';
import { WorkflowContext } from 'types/WorkflowContext';

export type WorkflowFormReducerState = {
  activeTab: string;
  attributes: Attribute[];
  customAttributes: Attribute[];
  notes: string[];
  workflowContext: WorkflowContext[];
  customTables: Attribute[];
  workId?: string;
  formId?: string;
};

export const initialState: WorkflowFormReducerState = {
  activeTab: 'attributes',
  attributes: [],
  customAttributes: [],
  notes: [],
  workflowContext: [],
  workId: '',
  customTables: [],
};

export const actions = {
  CLEAR_FORM: 'workflowForm/CLEAR_FORM',
  REMOVE_CONTEXT: 'workflowForm/REMOVE_CONTEXT',
  RESTORE_STATE: 'workflowForm/RESTORE_STATE',
  SET_ACTIVE_TAB: 'workflowForm/SET_ACTIVE_TAB',
  SET_ATTRIBUTES: 'workflowForm/SET_ATTRIBUTES',
  SET_CONTEXT: 'workflowForm/SET_CONTEXT',
  SET_CUSTOM_ATTRIBUTES: 'workflowForm/SET_CUSTOM_ATTRIBUTES',
  SET_CUSTOM_TABLES: 'workflowForm/SET_CUSTOM_TABLES',
  SET_EXISTING_CONTEXT: 'workflowForm/SET_EXISTING_CONTEXT',
  SET_EXISTING_NOTES: 'workflowForm/SET_EXISTING_NOTES',
  SET_NOTES: 'workflowForm/SET_NOTES',
  SET_WORK_ID: 'workflowForm/SET_WORK_ID',
  SET_FORM_ID: 'workflowForm/SET_FORM_ID',
};

export const actionMap: ActionMap<WorkflowFormReducerState> = {
  [actions.CLEAR_FORM]: () => ({
    ...initialState,
  }),
  [actions.REMOVE_CONTEXT]: (state, { payload: { workflowContext } }) => {
    const filteredContext = state.workflowContext?.filter(
      (wfc) =>
        !(
          wfc.ContextId === workflowContext.ContextId &&
          wfc.WorkflowContextType === workflowContext.WorkflowContextType
        )
    );

    return {
      ...state,
      workflowContext: filteredContext,
    };
  },
  [actions.RESTORE_STATE]: (state, { payload: { previousState } }) => ({
    ...state,
    ...previousState,
  }),
  [actions.SET_ACTIVE_TAB]: (state, { payload: { activeTab } }) => ({
    ...state,
    activeTab,
  }),
  [actions.SET_ATTRIBUTES]: (state, { payload: { attributes } }) => ({
    ...state,
    attributes,
  }),
  [actions.SET_CONTEXT]: (state, { payload: { workflowContext } }) => {
    if (workflowContext.ContextId) {
      const updatedWFContext = state.workflowContext?.find(
        (wfc) =>
          Boolean(wfc.ContextId) &&
          wfc.ContextId === workflowContext.ContextId &&
          Boolean(wfc.WorkflowContextType) &&
          wfc.WorkflowContextType === workflowContext.WorkflowContextType
      )
        ? state.workflowContext.filter(
            (wfc) =>
              !(
                wfc.ContextId !== workflowContext.ContextId &&
                wfc.WorkflowContextType !== workflowContext.WorkflowContextType
              )
          )
        : [...(state.workflowContext as WorkflowContext[]), workflowContext];
      return {
        ...state,
        workflowContext: updatedWFContext,
      };
    }

    return state;
  },
  [actions.SET_CUSTOM_ATTRIBUTES]: (
    state,
    { payload: { customAttributes } }
  ) => ({
    ...state,
    customAttributes,
  }),
  [actions.SET_CUSTOM_TABLES]: (state, { payload: { customTables } }) => ({
    ...state,
    customTables,
  }),
  [actions.SET_EXISTING_CONTEXT]: (
    state,
    { payload: { workflowContext } }
  ) => ({
    ...state,
    workflowContext,
  }),
  [actions.SET_EXISTING_NOTES]: (state, { payload }) => ({
    ...state,
    // keep only unique values through re-renders
    notes: Array.from(new Set([...state.notes, ...payload])),
  }),
  [actions.SET_FORM_ID]: (state, { payload: { formId } }) => ({
    ...state,
    formId,
  }),
  [actions.SET_NOTES]: (state, { payload: { key } }) => ({
    ...state,
    // keep only unique values through re-renders
    notes: Array.from(
      new Set(
        !state.notes.includes(key) ? [key, ...state.notes] : [...state.notes]
      )
    ),
  }),
  [actions.SET_WORK_ID]: (state, { payload: { workId } }) => ({
    ...state,
    workId,
  }),
};

export const workflowFormReducer = createReducer<WorkflowFormReducerState>(
  initialState,
  actionMap
);
