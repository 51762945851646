import { Buffer } from 'buffer';
import { useState, useEffect } from 'react';
import { Edit, Delete } from '@mui/icons-material';
import { Box } from '@mui/material';
import {
  DataDisplay,
  MessageActionType,
  MessageType,
} from '@revenue-solutions-inc/revxcoreui';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import DateCell from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/TableCells/DateCell';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import { ColumnDef } from '@tanstack/react-table';
import { filterByDateRange } from 'common/helpers';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { setHeader } from 'redux/contentSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';

import DataTableNextDateRangeFilter from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DataTableNextDateRangeFilter';
import {
  CorrespondenceTypeManagerResponse,
  ReusableContentTypeResponse,
  useDeleteCorrespondenceTypeMutation,
  useDeleteRcTypeMutation,
  useGetBatchPdfQuery,
} from 'generated/graphql';
import FileSaver from 'file-saver';
import CreateCorrespTypeAndHdrFtr from '../CreateCorrespTypeAndHdrFtr';

type Props = {
  correspondence_data: CorrespondenceTypeManagerResponse[];
  rc_data: ReusableContentTypeResponse[];
  isFromTemplate: boolean;
  reFetch: () => void;
};

function ManagerPage(dataProps: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [beginDate, setBeginDate] = useState<Date | null>();
  const [endDate, setEndDate] = useState<Date | null>();

  const module = useAppSelector((state) => state.user.module);

  const { mutate: deleteCorrespondence } =
    useDeleteCorrespondenceTypeMutation();

  const { mutate: deleteRc } = useDeleteRcTypeMutation();

  const {
    data: pdfData,
    refetch: refetchPdfData,
    isError,
    fetchStatus,
    isRefetchError,
  } = useGetBatchPdfQuery({}, { enabled: false, cacheTime: 0 });

  const onPrintManagement = () => {
    navigate(`/${module}/printManagement`);
  };

  const dataTableFn = () => {
    if (dataProps.isFromTemplate) {
      if (beginDate || (endDate && dataProps.correspondence_data)) {
        return filterByDateRange(
          dataProps?.correspondence_data,
          beginDate,
          endDate
        );
      } else return dataProps?.correspondence_data;
    }

    if (beginDate || (endDate && dataProps.rc_data)) {
      return filterByDateRange(dataProps?.rc_data, beginDate, endDate);
    } else return dataProps?.rc_data;
  };

  const deleteTemplateOrContent = (item: string) => {
    if (dataProps.isFromTemplate) {
      deleteCorrespondence(
        {
          correspondence: { correspondenceTypeId: parseInt(item) },
        },
        {
          onSuccess: () => {
            dataProps.reFetch();
            dispatch(
              addMessage({
                message: t(
                  'pages.correspondence.message.deleteCorrespondenceType'
                ),
                type: MessageType.Success,
                actionType: MessageActionType.None,
              })
            );
          },
          onError: () => {
            dispatch(
              addMessage({
                message: t('pages.manageReusableContent.networkError'),
                type: MessageType.Error,
                actionType: MessageActionType.None,
              })
            );
          },
        }
      );
    } else {
      deleteRc(
        {
          rc: { reusableContentTypeId: parseInt(item) },
        },
        {
          onSuccess: () => {
            dataProps.reFetch();
            dispatch(
              addMessage({
                message: t('pages.reusableContent.message.deleteRcType'),
                type: MessageType.Success,
                actionType: MessageActionType.None,
              })
            );
          },
          onError: () => {
            dispatch(
              addMessage({
                message: t('pages.manageReusableContent.networkError'),
                type: MessageType.Error,
                actionType: MessageActionType.None,
              })
            );
          },
        }
      );
    }
  };

  const TemplateColumns: ColumnDef<CorrespondenceTypeManagerResponse>[] = [
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.correspondence.id')} />
      ),

      accessorKey: 'correspondenceTypeDisplayId',
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.correspondence.fti')} />
      ),
      accessorKey: 'ftiFlag',
      cell: ({ row }) => {
        if (row.original.ftiFlag) {
          return 'Yes';
        } else {
          return 'No';
        }
      },
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.correspondence.typeName')} />
      ),
      accessorKey: 'name',
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.correspondence.category')} />
      ),
      accessorKey: 'correspondenceTypeCategoryName',
    },
    {
      header: () => (
        <HeaderColumnNext
          localization={t('pages.correspondence.isActiveTemplate')}
        />
      ),
      accessorKey: 'hasActiveTemplate',
    },
    {
      header: () => (
        <HeaderColumnNext
          localization={t('pages.correspondence.rolesAssociated')}
        />
      ),
      cell: ({ row }) => {
        return (
          <DataDisplay
            id={'role_count'}
            label={''}
            data={
              row.original.roleIdsCount && row.original?.roleIdsCount > 0
                ? String(row.original.roleIdsCount)
                : '-'
            }
          />
        );
      },
      accessorKey: 'roleIdsCount',
    },
    {
      header: () => (
        <HeaderColumnNext
          localization={t('pages.correspondence.latestStaus')}
        />
      ),
      accessorKey: 'latestStatus',
    },
    {
      header: () => (
        <HeaderColumnNext
          localization={t('pages.correspondence.latestVersion')}
        />
      ),
      cell: ({ row }) => {
        return (
          <DataDisplay
            id={'latest_version'}
            label={''}
            data={
              row.original.latestVersion && row.original?.latestVersion > 0
                ? String(row.original.latestVersion)
                : '-'
            }
          />
        );
      },
      accessorKey: 'latestVersion',
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.correspondence.createdBy')} />
      ),
      accessorKey: 'createdBy',
    },
    {
      header: () => (
        <HeaderColumnNext
          localization={t('pages.correspondence.createdDate')}
        />
      ),
      accessorKey: 'createdDate',
      cell: ({ row }) => {
        return <DateCell dateString={row.original.createdDate ?? ''} />;
      },
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.correspondence.updatedBy')} />
      ),
      accessorKey: 'updatedBy',
    },

    {
      header: () => (
        <HeaderColumnNext
          localization={t('pages.correspondence.updatedDate')}
        />
      ),
      accessorKey: 'updatedDate',
      cell: ({ row }) => {
        return <DateCell dateString={row.original.updatedDate ?? ''} />;
      },
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.reusableContent.edit')} />
      ),
      id: 'content-edit',
      cell: ({ row }) => {
        return (
          <Link
            to={{
              pathname: `/${module}/correspondenceTypes/edit/${row.original.correspondenceId}`,
            }}
          >
            <Edit color={'primary'} style={{ cursor: 'pointer' }} />
          </Link>
        );
      },
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.reusableContent.delete')} />
      ),
      id: 'content-delete',
      cell: ({ row }) => {
        if (row.original.canDelete) {
          return (
            <Delete
              style={{ cursor: 'pointer' }}
              color={'primary'}
              onClick={() =>
                deleteTemplateOrContent(`${row.original.correspondenceId}`)
              }
            />
          );
        } else {
          return <Delete color={'disabled'} />;
        }
      },
    },
  ];
  const ReusableContentColumns: ColumnDef<ReusableContentTypeResponse>[] = [
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.reusableContent.id')} />
      ),

      accessorKey: 'reusableContentTypeDisplayId',
    },
    {
      header: () => (
        <HeaderColumnNext
          localization={t('pages.reusableContent.rctypeName')}
        />
      ),
      accessorKey: 'name',
    },
    {
      header: () => (
        <HeaderColumnNext
          localization={t('pages.reusableContent.rctypeCategory')}
        />
      ),
      accessorKey: 'reusableContentTypeCategoryName',
      cell: ({ row }) => {
        return (
          row.original.reusableContentTypeCategoryName &&
          row.original.reusableContentTypeCategoryName[0].toUpperCase() +
            row.original.reusableContentTypeCategoryName?.substring(1)
        );
      },
    },
    {
      header: () => (
        <HeaderColumnNext
          localization={t('pages.reusableContent.containsActive')}
        />
      ),
      accessorKey: 'hasActiveReusableContent',
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.reusableContent.status')} />
      ),
      accessorKey: 'latestStatus',
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.reusableContent.version')} />
      ),
      accessorKey: 'latestVersion',
    },
    {
      header: () => (
        <HeaderColumnNext
          localization={t('pages.reusableContent.affectedTemplates')}
        />
      ),
      accessorKey: 'affectedTemplatesCount',
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.reusableContent.createdBy')} />
      ),
      accessorKey: 'createdBy',
    },
    {
      header: () => (
        <HeaderColumnNext
          localization={t('pages.correspondence.createdDate')}
        />
      ),
      accessorKey: 'createdDate',
      cell: ({ row }) => {
        return <DateCell dateString={row.original.createdDate ?? ''} />;
      },
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.correspondence.updatedBy')} />
      ),
      accessorKey: 'updatedBy',
    },
    {
      header: () => (
        <HeaderColumnNext
          localization={t('pages.correspondence.updatedDate')}
        />
      ),
      accessorKey: 'updatedDate',
      cell: ({ row }) => {
        return <DateCell dateString={row.original.updatedDate ?? ''} />;
      },
    },

    {
      header: () => (
        <HeaderColumnNext localization={t('pages.reusableContent.edit')} />
      ),
      id: 'content-edit',
      cell: ({ row }) => {
        return (
          <Link
            to={{
              pathname: `/${module}/reusableContents/edit/${row.original.reusableContentTypeId}`,
            }}
          >
            <Edit color={'primary'} style={{ cursor: 'pointer' }} />
          </Link>
        );
      },
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.reusableContent.delete')} />
      ),
      id: 'content-delete',
      cell: ({ row }) => {
        if (row.original.canDelete) {
          return (
            <Delete
              style={{ cursor: 'pointer' }}
              color={'primary'}
              onClick={() =>
                deleteTemplateOrContent(`${row.original.reusableContentTypeId}`)
              }
            />
          );
        } else {
          return <Delete color={'disabled'} />;
        }
      },
    },
  ];
  const getBatchPdf = async () => {
    refetchPdfData();
  };

  useEffect(() => {
    if (pdfData) {
      const blobPart = Buffer.from(pdfData.GetBatchPdf, 'base64');
      // create the blob object with content-type "application/pdf"
      const mediaSource = new Blob([blobPart], { type: 'application/pdf' });
      FileSaver.saveAs(mediaSource ?? '', `BatchPdf.pdf`, { autoBom: false });
    }
  }, [dispatch, pdfData, t]);

  useEffect(() => {
    dispatch(
      setHeader({
        pageTitle: dataProps.isFromTemplate
          ? t('pages.templates.title')
          : t('pages.reusableContent.title'),
        previousPage: t('pages.dashboard.home'),
        route: `dashboard`,
      })
    );
  }, [dispatch, t, dataProps.isFromTemplate]);

  useEffect(() => {
    if (isError) {
      dispatch(
        addMessage({
          message: t('pages.correspondence.message.batchPdfNotFound'),
          type: MessageType.Error,
          actionType: MessageActionType.None,
        })
      );
    }
  }, [dispatch, isError, t, fetchStatus, isRefetchError]);

  return (
    <>
      <Box>
        <DefaultDataTableNext
          columns={
            dataProps.isFromTemplate
              ? (TemplateColumns as ColumnDef<
                  Record<string, unknown>,
                  unknown
                >[])
              : (ReusableContentColumns as ColumnDef<
                  Record<string, unknown>,
                  unknown
                >[])
          }
          data={dataTableFn()}
          sx={{ alignItems: 'end' }}
          customHeader={
            <Box sx={{ display: 'flex', alignItems: 'end' }}>
              {dataProps.isFromTemplate && (
                <>
                  <Button
                    id="createContent_upload"
                    sx={{ minWidth: '128px', mt: 1 }}
                    onClick={() => getBatchPdf()}
                  >
                    {t('pages.correspondence.batchPdf')}
                  </Button>
                  <Button
                    id="createContent_upload"
                    sx={{ minWidth: '128px', mt: 1, ml: 1 }}
                    onClick={() => {
                      onPrintManagement();
                    }}
                  >
                    {t('pages.correspondence.printMgmt')}
                  </Button>
                </>
              )}
              <CreateCorrespTypeAndHdrFtr
                correspondence_data={dataProps.correspondence_data}
                rc_data={dataProps.rc_data}
                isFromTemplate={dataProps.isFromTemplate}
                reFetch={dataProps.reFetch}
              />
              <DataTableNextDateRangeFilter
                data-testid="datatable-date-range"
                beginDate={beginDate ? beginDate : null}
                endDate={endDate ? endDate : null}
                setBeginDate={setBeginDate}
                setEndDate={setEndDate}
              />
            </Box>
          }
        />
      </Box>
    </>
  );
}

export default ManagerPage;
