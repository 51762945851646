/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-loop-func */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/naming-convention */
// eslint-disable-next-line @typescript-eslint/naming-convention
type initProps = {
  /** This is the key name you want to find, maybe your object has a "FieldID" key */
  keyName: string;
  /**
   * Once you defined which keyName you want to look for, you can narrow it down defining which value the key should have.
   * ** When using the param "searchWith" value must be blank
   */
  value?: string | number;
  /** The complex object or array from which you want to find the info */
  schema?: any;
  /** When this is defined, you should not use "value" to narrow down the search, "serachWith: 'keyName'" will search for all objects that contain a key with the name you defined */
  searchWith?: 'keyName' | '';
};
let result: any = [];
const findInArray = (props: initProps, path = '', acPath: any = []) => {
  const { keyName, value, schema, searchWith } = props;
  let success = false;
  let tmpPath: any = '';
  schema.forEach((ele: any, i: number) => {
    if (ele[keyName] && ele[keyName] === value) {
      success = true;
      tmpPath = !path ? '[0]' : `${path}[${i}]`;
      acPath.push(tmpPath);
      if (!result.includes(ele)) result.push(ele);
    }
    if (!success && i + 1 === schema.length) {
      schema.forEach((t: unknown, k: number) => {
        if (Array.isArray(t)) {
          const v: any = findInArray(
            { keyName, value, schema: t, searchWith },
            tmpPath,
            acPath
          );
          if (v) {
            success = true;
            if (Array.isArray(v.tmpValue) && v.tmpValue.length) {
              v.tmpValue.forEach((itm: any) => {
                if (!result.includes(itm)) result.push(itm);
              });
            } else {
              if (!result.includes(v.tmpValue)) result.push(v.tmpValue);
              success = true;
            }
          }
        } else {
          if (k < schema.length) {
            tmpPath = !path ? '[0]' : `${path}[${k}]`;
            const v: any = findinObject(
              { keyName, value, schema: t, searchWith },
              tmpPath,
              acPath
            );
            if (v && v.tmpValue) {
              success = true;
              if (Array.isArray(v.tmpValue) && v.tmpValue.length) {
                v.tmpValue.forEach((itm: any) => {
                  if (!result.includes(itm)) result.push(itm);
                });
              } else {
                if (!result.includes(v.tmpValue)) result.push(v.tmpValue);
                success = true;
              }
            }
          }
        }
      });
    }
  });

  return result.length
    ? {
        tmpValue: result,
        path: acPath,
      }
    : null;
};

const findinObject = (props: initProps, path = '', acPath: any = []) => {
  const { keyName, value, schema, searchWith } = props;

  let incr = 0;
  const size: number = Object.keys(schema).length;
  for (const key in schema) {
    incr++;

    if (
      searchWith === 'keyName'
        ? key === keyName
        : schema[keyName] && schema[keyName] == value
    ) {
      const tmpPath = !path ? key : `${path}`;
      acPath.push(tmpPath);
      if (!result.includes(schema)) result.push(schema);
    }

    if (incr === size) {
      for (const tempKey in schema) {
        if (Array.isArray(schema[tempKey])) {
          const tmpPath = !path ? tempKey : `${path}.${tempKey}`;
          if (schema[tempKey].length) {
            const v: any = findInArray(
              { keyName, value, schema: schema[tempKey], searchWith },
              tmpPath,
              acPath
            );
            if (v) {
              if (Array.isArray(v.tmpValue) && v.tmpValue.length) {
                v.tmpValue.forEach((itm: unknown) => {
                  if (!result.includes(itm)) result.push(itm);
                });
              } else {
                if (!result.includes(v.tmpValue)) result.push(v.tmpValue);
              }
            }
          }
        } else {
          if (typeof schema[tempKey] === 'object' && schema[tempKey] !== null) {
            const tmpPath = !path ? tempKey : `${path}.${tempKey}`;
            const v = findinObject(
              { keyName, value, schema: schema[tempKey], searchWith },
              tmpPath,
              acPath
            );
            if (v) {
              if (Array.isArray(v.tmpValue) && v.tmpValue.length) {
                v.tmpValue.forEach((itm: unknown) => {
                  if (!result.includes(itm)) result.push(itm);
                });
              } else {
                if (!result.includes(v.tmpValue)) result.push(v.tmpValue);
              }
            }
          }
        }
      }
    }
  }
  return result.length
    ? {
        tmpValue: result,
        path: acPath,
      }
    : null;
};

type returnValue = {
  tmpValue: unknown[];
  path: unknown[];
};

/**
 * In contrast to JSONPath-plus, this tool doesn't use eval under the hood making it secure.
 * The lookup process is not done using JSONPath expressions though, you just need to provide the object from which you want to find its properties.
 * Also refer to each param's documentation.
 *
 * @param {initProps} props
 * @returns a list with a reference to all matched objects, **Important: if you mutate the result you will be mutating the original object reference.
 * @example
 * const myObject = {
 *     Id: '6407702f9f3ec88da6395c01',
 *     BusinessSections: [
 *       {
 *         SectionName: 'NHSection',
 *         Fields: [
 *           {
 *             BaseField: {
 *               FieldID: 1,
 *               FieldName: 'F1',
 *             },
 *           },
 *           {
 *             BaseField: {
 *               FieldID: 2,
 *               FieldName: 'F2',
 *             },
 *           },
 *         ],
 *       },
 *     ],
 *   };
 * // finding all objects with the FieldID property regardless the value. It will return both fields FieldID: 1 and FieldID: 2
 * // if you are expecting to receive a certain type, you can use the optional generic, in this example Field type is expected
 * const test = JsonPath<Field>({ keyName: 'FieldID', searchWith: 'keyName', schema: myObject });
 *
 * // finding all objects with the FieldID property which contains value 1.
 * const test = JsonPath({ keyName: 'FieldID', value: '1', schema: myObject });
 */
const JsonPath = <T = any>(props: initProps): [{ path: string; value: T }] => {
  result = [];
  const { schema } = props;
  let val: returnValue | null = {
    tmpValue: [],
    path: [],
  };
  if (Array.isArray(schema)) {
    val = findInArray(props);
  } else {
    if (typeof schema === 'object' && schema !== null) {
      val = findinObject(props);
    }
  }

  const tmpVal: any = [];
  if (val) {
    const { tmpValue = [] } = val;
    tmpValue.forEach((t: unknown, i: number) => {
      const trm: any = t;
      tmpVal.push({ value: trm, path: val && val.path[i] });
    });
  }

  return tmpVal;
};

export { JsonPath };
