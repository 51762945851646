import { createReducer } from 'utils/createLocalReducer';
import type { ActionMap } from 'utils/createLocalReducer';

type SuppressionPanelReducerState = {
  searchVal: string;
  addSuppressionModalOpen: boolean;
  selectedVariant: string;
  variantOptions: { key: string; desc: string }[];
};

export const initialState: SuppressionPanelReducerState = {
  searchVal: '',
  addSuppressionModalOpen: false,
  selectedVariant: '',
  variantOptions: [],
};

export const actions = {
  CLOSE_ADD_SUPPRESSION_MODAL: 'suppression/CLOSE_ADD_SUPPRESSION_MODAL',
  OPEN_ADD_SUPPRESSION_MODAL: 'suppression/OPEN_ADD_SUPPRESSION_MODAL',
  SELECT_VARIANT: 'suppression/SELECT_VARIANT',
  SET_VARIANT_OPTIONS: 'suppression/SET_VARIANT_OPTIONS',
};

export const actionMap: ActionMap<SuppressionPanelReducerState> = {
  [actions.CLOSE_ADD_SUPPRESSION_MODAL]: (state) => ({
    ...state,
    addSuppressionModalOpen: false,
  }),
  [actions.OPEN_ADD_SUPPRESSION_MODAL]: (state) => ({
    ...state,
    addSuppressionModalOpen: true,
  }),
  [actions.SELECT_VARIANT]: (state, { payload: { selectedVariant } }) => ({
    ...state,
    selectedVariant,
  }),
  [actions.SET_VARIANT_OPTIONS]: (state, { payload: { options } }) => ({
    ...state,
    variantOptions: options,
  }),
};

export const suppressionPanelReducer =
  createReducer<SuppressionPanelReducerState>(initialState, actionMap);
