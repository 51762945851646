import { useCallback, useEffect, useRef, useState } from 'react';

import {
  Box,
  CircularProgress,
  Grid,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import Input from '@revenue-solutions-inc/revxcoreui/material/controls/Input';
import Select from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import { useTranslation } from 'react-i18next';
import { Contact, contactDefault, TenantInfo } from 'types/tenants';
import extractMeaningfulMessage from 'utils/errorMessage';
import { addMessage } from 'redux/messageSlice';
import { useAppDispatch } from 'redux/hooks';
import {
  useGetConfigurationsQuery,
  useIsTenantNameExistQuery,
} from 'generated/graphql';
import {
  MessageType,
  MessageActionType,
} from '@revenue-solutions-inc/revxcoreui';
import {
  CustomerMetadata,
  ConfigurationsResponse,
  getCustomers,
} from 'utils/getConfigurations';
import RsiContactManager from '../RsiContactManager';

interface TenantInfoProps {
  tenantName: string;
  tenantInfo: TenantInfo;
  reset: boolean;
  showTenantNameError: boolean;
  validTenantName: boolean;
  setReset: (reset: boolean) => void;
  handleChange: (tenantInfo: TenantInfo, tenantName: string) => void;
  handleValidation: (isValid: boolean) => void;
  setShowTenantNameError: (showTenantNameError: boolean) => void;
  setValidTenantName: (validTenantName: boolean) => void;
}

function TenantInformation({
  tenantName,
  tenantInfo,
  reset,
  showTenantNameError,
  validTenantName,
  setReset,
  handleChange,
  handleValidation,
  setShowTenantNameError,
  setValidTenantName,
}: TenantInfoProps): JSX.Element {
  const minimunCustomers = 1;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [contactsHaveErrors, setContactError] = useState<boolean>(false);
  const [tenantNameLocal, setTenantNameLocal] = useState<string>('');
  const [customers, setCustomers] = useState<CustomerMetadata[]>([]);

  const inputFocus = useRef<HTMLInputElement>(null);
  const {
    data: isTenantNameExist,
    refetch,
    isFetching,
  } = useIsTenantNameExistQuery({ tenantName }, { enabled: false });

  const { isFetching: isFetchingCustomers } =
    useGetConfigurationsQuery<ConfigurationsResponse>(
      {
        configurationType: 'CustomerInformation',
        configurationModule: '',
      },
      {
        onSuccess: (response) => {
          if (response?.getConfigurations) {
            const validCustomers: CustomerMetadata[] = getCustomers(
              response.getConfigurations
            );
            setCustomers(validCustomers);
          }
        },
        onError: (error) => {
          const message = extractMeaningfulMessage(
            error,
            t('components.message.networkerror')
          );
          dispatch(
            addMessage({
              message: message,
              type: MessageType.Error,
              actionType: MessageActionType.None,
            })
          );
        },
      }
    );

  const updateTenantInfo = useCallback(
    (customerFound: CustomerMetadata) => {
      const customerName = customerFound.CustomerName;
      const customerId = customerFound.CustomerID;
      localStorage.setItem('customerSelected', JSON.stringify(customerFound));
      handleChange(
        {
          ...tenantInfo,
          customer: { id: customerId, name: customerName },
        },
        tenantName
      );
    },
    [handleChange, tenantInfo, tenantName]
  );

  useEffect(() => {
    setShowTenantNameError(false);
    setValidTenantName(false);
  }, [tenantName, setShowTenantNameError, setValidTenantName]);

  useEffect(() => {
    if (localStorage.getItem('tenantName')) {
      const tenantNameLocalTemp = JSON.parse(
        localStorage.getItem('tenantName') ?? ''
      );
      setTenantNameLocal(tenantNameLocalTemp);
    }
  }, []);

  useEffect(() => {
    inputFocus.current?.focus();
  }, []);

  useEffect(() => {
    if (localStorage.getItem('customerSelected')) {
      const customerFound = JSON.parse(
        localStorage.getItem('customerSelected') ?? ''
      );
      if (customerFound.CustomerID) updateTenantInfo(customerFound);
    }
  }, [updateTenantInfo]);

  const searchTenantName = useCallback(() => {
    if (!!tenantName && tenantName.trim() !== tenantNameLocal.trim()) {
      refetch();
    }
    if (tenantName.trim() === tenantNameLocal.trim()) {
      setValidTenantName(true);
    }
  }, [tenantName, tenantNameLocal, refetch, setValidTenantName]);

  useEffect(() => {
    if (!isFetching && !isTenantNameExist?.IsTenantNameExist)
      setValidTenantName(true);
    if (!isFetching && isTenantNameExist?.IsTenantNameExist)
      setShowTenantNameError(true);
  }, [
    isFetching,
    isTenantNameExist?.IsTenantNameExist,
    setShowTenantNameError,
    setValidTenantName,
  ]);

  const validateNameAndContacts = useCallback(
    (contacts: Contact[]) => {
      if (
        contacts &&
        !contactsHaveErrors &&
        tenantInfo.customer &&
        tenantName
      ) {
        const requiredFields = contacts.filter(
          ({
            userContactType,
            userFullName,
            userEmail,
            userPhone,
            userOrganization,
          }) => {
            return (
              !userContactType ||
              !userFullName ||
              !userEmail ||
              !userPhone ||
              !userOrganization?.trim() ||
              !validTenantName
            );
          }
        );
        const onePrimary = contacts.filter(({ userContactType }) => {
          return userContactType === 'Primary';
        });
        return (
          requiredFields.length === 0 &&
          !!tenantInfo.customer.id &&
          !!tenantName &&
          onePrimary.length === 1
        );
      }
      return false;
    },
    [contactsHaveErrors, tenantInfo.customer, tenantName, validTenantName]
  );

  /*
   * Need to check that values are not blank strings bc currently our mask
   * does not error on blank strings.
   * We are calling this useEffect w/o a 2nd argument bc this will need to
   * run every time a contact gets updated
   */

  useEffect(() => {
    const isValid = validateNameAndContacts(tenantInfo.contacts);
    handleValidation(isValid);
  }, [tenantInfo.contacts, validateNameAndContacts, handleValidation]);

  useEffect(() => {
    if (reset) {
      handleChange(
        {
          ...tenantInfo,
          contacts: [{ ...contactDefault, userContactType: 'Primary' }],
        },
        tenantName
      );
      setReset(false);
    }
  }, [reset, tenantInfo, tenantName, handleChange, setReset]);

  return (
    <Grid
      container
      spacing={1}
      sx={{
        backgroundColor: 'white.main',
        padding: 2,
        borderRadius: 1,
        border: '1px solid #e2e2e2',
      }}
    >
      <Grid item xs={10} mb={0.5} data-testid="tenantName">
        <Stack direction="row" spacing={2}>
          <Input
            id="tenantName"
            inputProps={{ ref: inputFocus }}
            label={t('pages.tenantConfig.tenantInfo.tenantName')}
            required
            value={tenantName ?? ''}
            onChange={(event: React.FormEvent<HTMLInputElement>): void => {
              handleChange(tenantInfo, event.currentTarget.value);
            }}
            helperText={
              showTenantNameError
                ? t('pages.tenantConfig.errors.tenantName')
                : ''
            }
            onBlur={searchTenantName}
          />
          {isFetching && (
            <Box sx={{ display: 'flex', pt: 3 }}>
              <CircularProgress size={24} />
            </Box>
          )}
        </Stack>
      </Grid>
      <Grid item xs={10} mb={0.5} data-testid="customerName">
        <Stack direction="row" spacing={2}>
          <Select
            id="customerName-tenant-info"
            label={t('pages.tenantConfig.tenantInfo.customerName')}
            required
            value={tenantInfo?.customer?.id ?? ''}
            onChange={(event: SelectChangeEvent<string | number>) => {
              const customerFound = customers.find(
                (customer) => customer.CustomerID === event.target.value
              );
              if (customerFound) updateTenantInfo(customerFound);
            }}
            options={
              customers.length >= minimunCustomers
                ? customers.map((option) => {
                    return {
                      key: option.CustomerID,
                      desc: option.CustomerName,
                    };
                  })
                : []
            }
          />
          {isFetchingCustomers && (
            <Box sx={{ display: 'flex', pt: 3 }}>
              <CircularProgress size={24} />
            </Box>
          )}
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h2">
          {t('pages.tenantConfig.tenantInfo.contacts')}
        </Typography>
      </Grid>

      <Grid item xs={10}>
        <RsiContactManager
          isFullName={true}
          contacts={tenantInfo.contacts}
          onChange={(contacts: Contact[]) => {
            handleChange({ ...tenantInfo, contacts: contacts }, tenantName);
          }}
          showOrganization={true}
          showThirdPartyType={true}
          isReadOnly={false}
          allowFirstRowRemoval={false}
          phoneRequired={true}
          onError={(hasError: boolean) => {
            setContactError(hasError);
          }}
        />
      </Grid>
      <Grid item xs={10}>
        <Button
          id="addContacts"
          variant="outlined"
          type="secondary"
          onClick={() => {
            handleChange(
              {
                ...tenantInfo,
                contacts: [...tenantInfo.contacts, { ...contactDefault }],
              },
              tenantName
            );
          }}
        >
          {t('components.button.addContact')}
        </Button>
      </Grid>
    </Grid>
  );
}

export default TenantInformation;
