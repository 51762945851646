import { useEffect, useState } from 'react';

import PaymentList from 'components/PaymentList';
import {
  PaymentDetailsResponse,
  useGetPaymentsByEntityIdQuery,
} from 'generated/graphql';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function EntityPayments(): JSX.Element {
  const { t } = useTranslation();
  const { entityId } = useParams() as { entityId: string };
  const [entityPayments, setEntityPayments] = useState<
    PaymentDetailsResponse[]
  >([]);

  const { data: entityPaymentsResponse } = useGetPaymentsByEntityIdQuery({
    entityId: entityId,
  });

  useEffect(() => {
    if (entityPaymentsResponse) {
      setEntityPayments(entityPaymentsResponse.GetPaymentsByEntityId);
    }
  }, [entityPaymentsResponse]);

  return (
    <PaymentList
      payments={entityPayments}
      currentPageName={t('pages.entitySummary.title')}
    />
  );
}

export default EntityPayments;
