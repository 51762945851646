import { getValueType } from 'common/entity';
import { getDefault } from 'components/entityManagement/common/defaults/account';
import {
  Account,
  Attribute,
  GetAccountByIdQuery,
  Group,
  LayoutConfiguration,
  ConfigurationSection,
} from 'generated/graphql';
import {
  AccountForm,
  AccountMain,
  AccountAddress,
  AccountEmailAddress,
  AccountFilingFrequency,
  IndustryCode,
  AccountIdentifier,
  AccountName,
  AccountPhoneNumber,
  AccountResponse,
} from 'types/accounts';
import { ExtendedAttributeValues, Section } from 'types/forms';
import { toStringDate } from 'utils/date-util';

import { getExtendedAttributeGroup } from './entityManagementUtils';
import {
  AccountFields,
  NameFields,
  IdentifierFields,
  AddressFields,
  EmailAddressFields,
  PhoneNumberFields,
  IndustryCodes,
  FilingFrequencyFields,
} from './fields/accounts';
import { getAccount, getAccountResponse } from './defaults/account';

export const dateAttributes = ['commenceDate', 'ceaseDate'];
const accountFields: Section[] = [
  AccountFields,
  NameFields,
  IdentifierFields,
  AddressFields,
  EmailAddressFields,
  PhoneNumberFields,
  IndustryCodes,
  FilingFrequencyFields,
];
type AccountFormTypes = AccountMain &
  AccountName &
  AccountIdentifier &
  AccountAddress &
  AccountPhoneNumber &
  AccountEmailAddress &
  IndustryCode &
  AccountFilingFrequency;
export type AccountTypes =
  | AccountMain
  | AccountName
  | AccountIdentifier
  | AccountAddress
  | AccountEmailAddress
  | AccountPhoneNumber
  | IndustryCode
  | AccountFilingFrequency;
export const sectionNames: string[] = [
  'account',
  'names',
  'identifiers',
  'addresses',
  'emailAddresses',
  'phoneNumbers',
  'industryCode',
  'filingFrequencies',
];

const getAttributes = (section: string): string[] => {
  const sectionNameKey = section as keyof typeof getAccount;
  const sectionAttributes = getAccount?.[sectionNameKey];
  if (sectionAttributes) {
    return Object.keys(sectionAttributes[0]);
  }
  return [];
};

export const findSection = (sectionName: string): Section | undefined => {
  return accountFields.find(
    (fields) => fields.sectionIdentifier === sectionName
  );
};

const getAmountOfElements = (data: AccountForm, section: string): number => {
  const sectionNameKey = section as keyof typeof data;
  const sectionSelected = data?.[sectionNameKey];
  return sectionSelected?.length ?? 0;
};

const getValue = (
  data: AccountForm,
  section: string,
  attribute: string,
  index: number
): string => {
  const sectionNameKey = section as keyof typeof data;
  const sectionSelected = data?.[sectionNameKey];
  if (sectionSelected) {
    const sectionData = sectionSelected[index];
    type InternalSectionKey = keyof typeof sectionData;
    const targetAttribute = attribute as InternalSectionKey;

    if (dateAttributes.includes(attribute)) {
      if (String(sectionData[targetAttribute]) === 'Invalid Date') {
        if (attribute === 'commenceDate')
          return toStringDate(data.account?.[0].commenceDate);
        return '';
      }
      return toStringDate(sectionData[targetAttribute]);
    }
    return sectionData[targetAttribute]?.toString() ?? '';
  }
  return '';
};

export const getAccountPrimaryItem = (
  data: GetAccountByIdQuery,
  section: string
): Attribute[] => {
  const primaryGroup = data.GetAccountById?.group
    ?.filter((group) => group?.groupName?.toLowerCase() === section)
    .filter((attr) =>
      attr.attribute.find(
        (att) =>
          att.attributeName === 'isPrimary' && att.attributeValue === 'true'
      )
    );
  if (primaryGroup) return primaryGroup[0].attribute;
  return [];
};

const getDisplayName = (attribute: string, sectionName: string): string => {
  if (!attribute || !sectionName) return '';
  let displayName = attribute;
  let field;
  const section = findSection(sectionName);
  if (section) {
    field = section.fields.find((f) => f.fieldIdentifier === attribute);
  }
  displayName = !field ? displayName : field.label;
  return displayName;
};

const setRequired = (attribute: Attribute, sectionType: Section) => {
  if (!attribute) return;
  const aField = sectionType.fields.find(
    (f) =>
      f.fieldIdentifier.toUpperCase() === attribute?.attributeName.toUpperCase()
  );
  if (!aField) return;

  // find if the attribute is configured as required field. If configure is missing, it is not required.
  // driver name type = 0 means it is a required field configuration
  const found = attribute.extensibleBusinessDriver?.find(
    (driver) =>
      driver.driverNameType == 0 && driver.driverValue?.toUpperCase() === 'TRUE'
  );

  if (found) {
    aField.rules = {
      ...aField.rules,
      required: aField.requiredErrorMessage ?? '',
    };
    aField.isRequired = true;
  } else {
    aField.rules = {
      ...aField.rules,
      required: false,
    };
    aField.isRequired = false;
  }
};

function updateLayoutGroup(group: Group) {
  if (group.groupName?.toLowerCase() === 'account') {
    group.attribute.forEach((attribute: Attribute) => {
      setRequired(attribute, AccountFields);
    });
  } else if (group.groupName?.toLowerCase() === 'names') {
    group.attribute.forEach((attribute: Attribute) => {
      setRequired(attribute, NameFields);
    });
  } else if (group.groupName?.toLowerCase() === 'identifiers') {
    group.attribute.forEach((attribute: Attribute) => {
      setRequired(attribute, IdentifierFields);
    });
  } else if (group.groupName?.toLowerCase() === 'addresses') {
    group.attribute.forEach((attribute: Attribute) => {
      setRequired(attribute, AddressFields);
    });
  } else if (group.groupName?.toLowerCase() === 'phonenumbers') {
    group.attribute.forEach((attribute: Attribute) => {
      setRequired(attribute, PhoneNumberFields);
    });
  } else if (group.groupName?.toLowerCase() === 'emailaddresses') {
    group.attribute.forEach((attribute: Attribute) => {
      setRequired(attribute, EmailAddressFields);
    });
  } else if (group.groupName?.toLowerCase() === 'filingfrequencies') {
    group.attribute.forEach((attribute: Attribute) => {
      setRequired(attribute, FilingFrequencyFields);
    });
  }
}

export const updateLayoutConfiguration = (
  layoutConfiguration: LayoutConfiguration
) => {
  layoutConfiguration.platformConfigurationInfo?.configurationSection.forEach(
    (section: ConfigurationSection) => {
      section.group.forEach((group: Group) => {
        updateLayoutGroup(group);
      });
    }
  );
};

export const buildAccountForm = (data: Account): AccountForm => {
  const optionalSections = ['phoneNumbers', 'emailAddresses'];
  const accountFormData: AccountForm = {
    account: [],
    names: [],
    identifiers: [],
    addresses: [],
    phoneNumbers: [],
    emailAddresses: [],
    industryCode: [],
    filingFrequencies: [],
  };
  sectionNames.forEach((sectionName) => {
    const attrKey = sectionName as keyof AccountForm;
    data.group?.forEach((grp) => {
      if (grp.attribute && grp.attribute.length > 0 && grp.groupName) {
        if (grp.groupName.toLowerCase() === sectionName.toLowerCase()) {
          const attributes: Attribute[] = grp.attribute;
          let newAttribute = {};
          attributes.forEach((attribute: Attribute) => {
            newAttribute = {
              ...newAttribute,
              [attribute.attributeName]: attribute.attributeValue,
            };
          });
          if (newAttribute) {
            accountFormData[attrKey]?.push(newAttribute as AccountFormTypes);
          }
        }
      }
    });
    if (
      accountFormData[attrKey]?.length === 0 &&
      !optionalSections.includes(sectionName)
    ) {
      const defaultAttributes = getDefault(sectionName);
      accountFormData[attrKey]?.push(defaultAttributes as AccountFormTypes);
    }
  });
  return accountFormData;
};

export const buildAccountRequest = (
  data: AccountForm,
  entityId: string | undefined,
  accountType: string | undefined,
  platformConfigId: string | undefined,
  extendedData: ExtendedAttributeValues[],
  accountId?: string | undefined
): AccountResponse => {
  const accountResponse: AccountResponse = getAccountResponse;
  // First level data
  accountResponse.id = accountId;
  accountResponse.entityInfoId = entityId;
  accountResponse.platformConfigurationId = platformConfigId;
  accountResponse.accountType = accountType;
  accountResponse.accountName = data.names?.[0].AccountName;
  accountResponse.primaryIdentifierType = data.identifiers?.[0].IdType;
  accountResponse.primaryIdentifier = data.identifiers?.[0].value;
  accountResponse.commenceDate = toStringDate(data.account?.[0].commenceDate);
  if (data.account?.[0].ceaseDate !== null) {
    if (String(data.account?.[0].ceaseDate) === 'Invalid Date') {
      accountResponse.ceaseDate = null;
    } else
      accountResponse.ceaseDate = toStringDate(data.account?.[0].ceaseDate);
  } else accountResponse.ceaseDate = data.account?.[0].ceaseDate;
  accountResponse.group = [];
  // Array of groups (each group is a section)
  sectionNames.forEach((section) => {
    const amountOfElements = getAmountOfElements(data, section);
    for (
      let indexElement = 0;
      indexElement < amountOfElements;
      indexElement++
    ) {
      const group: Group = {
        groupName: section,
        groupOrder: '1',
        groupAttributeType: 0,
        repeatingActionLabel: '',
        attribute: [],
      };
      const sectionAttributes = getAttributes(section);
      sectionAttributes.forEach((attribute) => {
        const singleAttribute: Attribute = {
          attributeName: attribute,
          attributeDisplayName: getDisplayName(attribute, section),
          attributeType: getValueType(attribute),
          attributeValue: getValue(data, section, attribute, indexElement),
          repeatingProperties: false,
          possibleValues: null,
          repeatingValue: null,
        };
        group.attribute?.push(singleAttribute);
      });
      // TODO: This is a temporary workaround due to a PC validation that requires AccountName to exist here
      if (section === 'account') {
        const accountName = group.attribute?.find(
          (attr) => attr.attributeName === 'AccountName'
        );
        if (accountName === undefined) {
          group.attribute?.push({
            attributeName: 'AccountName',
            attributeDisplayName: 'Account Name',
            attributeType: 'String',
            attributeValue: data.names?.[0].AccountName ?? '-',
            repeatingProperties: false,
            possibleValues: null,
            repeatingValue: null,
          });
        }
      }
      accountResponse.group?.push(group);
    }
  });
  // Add extended attributes if existent
  if (extendedData && extendedData.length > 0) {
    const group = getExtendedAttributeGroup(extendedData);
    accountResponse.group?.push(group);
  }
  return accountResponse;
};
