import {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
  ChangeEvent,
} from 'react';
import { InputAdornment, Stack, Typography } from '@mui/material';
import appTheme from '@revenue-solutions-inc/revxcoreui/material/themes/defaultTheme';
import { useTranslation } from 'react-i18next';
import Dialog from '@revenue-solutions-inc/revxcoreui/material/controls/Dialog';
import {
  Button,
  DataDisplay,
  Input,
  MessageType,
} from '@revenue-solutions-inc/revxcoreui';
import {
  Attribute,
  ConfigurationResponse,
  useGetExpressionValidationQuery,
} from 'generated/graphql';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { Error } from 'types/graphqlErrors';
import extractMeaningfulMessage from 'utils/errorMessage';
import { useAppDispatch } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import FunctionsTable from './FunctionsTable/FunctionsTable';

interface ExpressionGeneratorDialogProps {
  open: boolean;
  handleClose: () => void;
  selectedRecord: ConfigurationResponse | undefined;
  attr: Attribute | undefined;
}

const checkIconStyleRed: CSSProperties = {
  color: appTheme.palette.error.main,
};

const checkIconStyleGreen: CSSProperties = {
  color: appTheme.palette.success.main,
};

function ExpressionGeneratorDialog({
  open,
  handleClose,
  selectedRecord,
  attr,
}: ExpressionGeneratorDialogProps) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.configRecordEditor.expressionGenerator',
  });
  const { t: t2 } = useTranslation();
  const dispatch = useAppDispatch();
  const [expressionInput, setExpressionInput] = useState<string>(
    attr?.expressionValue || ''
  );
  const [expressionResult, setExpressionResult] = useState<string>('');
  const [isExpressionValid, setIsExpressionValid] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(true);
  const cursorPosition = useRef<number | null>(null);
  const { isFetching: isLoadingCheckExpression, refetch: runValidation } =
    useGetExpressionValidationQuery(
      {
        platformConfigurationId: selectedRecord?.platformConfigurationId || '',
        expression: expressionInput,
      },
      {
        refetchOnWindowFocus: false,
        enabled: false,
        onSuccess({ expressionValidation }) {
          // TODO: Change this way I don't like to rely upon the message to decide this logic,
          // but for now while backend could gives the expressionStatusCode I asked I'll keep it
          if (/^Valid Expression$/i.test(expressionValidation)) {
            setExpressionResult('');
            setIsExpressionValid(true);
            setIsDirty(false);
          } else {
            setExpressionResult(expressionValidation);
            setIsExpressionValid(false);
            setIsDirty(false);
          }
        },
        onError(error: Error[]) {
          const message = extractMeaningfulMessage(
            error,
            t2('components.message.networkerror')
          );
          dispatch(
            addMessage({
              type: MessageType.Error,
              message: String(message),
            })
          );
        },
      }
    );

  useEffect(() => {
    setExpressionInput(attr?.expressionValue || '');
  }, [attr]);

  const onIputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setExpressionInput(event.target.value);
    setIsDirty(true);
  };

  const handleCloseDialog = () => {
    handleClose();
    // if dialog is closed roll back to default states
    setExpressionInput(attr?.expressionValue || '');
    setExpressionResult('');
    setIsDirty(true);
  };

  const handleCheckExpression = () => {
    runValidation();
  };

  const handleSaveExpression = () => {
    if (!attr) return;
    attr.expressionValue = expressionInput;
    handleCloseDialog();
  };

  const clickFunctionHandler = useCallback((funcSyntax: string): void => {
    setExpressionInput((previous) => {
      const pos = cursorPosition.current ?? previous.length;
      const appended =
        previous.slice(0, pos) + funcSyntax + previous.slice(pos);
      cursorPosition.current = appended.length; // after insertion, next insertion will be at the end
      return appended;
    });
    setIsDirty(true);
  }, []);

  const functionsTableMemo = useMemo(
    () => <FunctionsTable clickFunctionHandler={clickFunctionHandler} />,
    [clickFunctionHandler]
  );

  const dialogChildren: JSX.Element = (
    <Stack>
      <Stack>
        <Typography>{t('fieldInfo')}</Typography>
        <Stack
          direction="row"
          justifyContent="flex-start"
          flexWrap="wrap"
          spacing={2}
        >
          <DataDisplay
            id={'attributeNameId'}
            label={t2('pages.configTypeEditor.attributeName')}
            data={attr?.attributeDisplayName || ''}
            sx={{ fontSize: '1.1em' }}
          />
          <DataDisplay
            id={'attributeType'}
            label={t2('pages.configTypeEditor.attributeType')}
            data={attr?.attributeType || ''}
            sx={{ fontSize: '1.1em' }}
          />
        </Stack>
      </Stack>
      <Stack spacing={1} direction={'column'} mt={'10px'}>
        <Input
          id="expressionInput"
          label={t('expressionInput')}
          value={expressionInput}
          onChange={(event) => onIputChange(event)}
          onBlur={(event) =>
            (cursorPosition.current = event.target.selectionStart)
          }
          fullWidth={true}
          placeholder={t('inputPlaceHolder')}
          inputProps={{ maxLength: 250 }}
          endAdornment={
            <InputAdornment
              position="end"
              sx={{
                cursor: 'default',
              }}
            >
              {isDirty ? (
                <></>
              ) : !isExpressionValid ? (
                <CancelIcon sx={checkIconStyleRed} />
              ) : (
                <CheckCircleIcon sx={checkIconStyleGreen} />
              )}
            </InputAdornment>
          }
        />
        <Typography
          sx={{
            fontSize: '1em',
            color: appTheme.palette.error.main,
            minHeight: '30px',
          }}
        >
          {!isDirty ? expressionResult : ''}
        </Typography>
        <Stack direction="row" justifyContent="flex-start" spacing={2}>
          <Button
            id="checkExpressionButton"
            loading={isLoadingCheckExpression}
            onClick={() => {
              handleCheckExpression();
            }}
          >
            {t('checkExpression')}
          </Button>
          <Button
            id="saveExpressionButton"
            onClick={() => {
              handleSaveExpression();
            }}
            disabled={isDirty || !isExpressionValid}
          >
            {t('saveExpression')}
          </Button>
        </Stack>
      </Stack>
      {functionsTableMemo}
    </Stack>
  );

  return (
    <Dialog
      id="expressionGeneratorTool"
      title={t('title')}
      maxWidth={'md'}
      children={dialogChildren}
      open={open}
      handleClose={handleCloseDialog}
    />
  );
}
export default ExpressionGeneratorDialog;
