import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { IdentityProvider } from 'types/tenants';

interface IdentityProviderI {
  identityProvider: IdentityProvider;
  token: string;
}

export default function useCreateIdentityProvider() {
  return useMutation((identityProvider: IdentityProviderI) => {
    return axios
      .post(
        `${process.env.REACT_APP_TENANT}/tenant/identityprovider`,
        identityProvider.identityProvider,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${identityProvider.token}`,
          },
        }
      )
      .then((response) => response.data);
  });
}
