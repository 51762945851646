import { useState } from 'react';

import { LogixGroupType } from '@revenue-solutions-inc/revxcoreui/material/logix/types/entities';
import DataMapperUIComponent from 'components/DataMapperUIComponent';
import { Button } from '@revenue-solutions-inc/revxcoreui';
import { useTranslation } from 'react-i18next';
import logixFile from './logixFile.json';

function DataMapperTest(): JSX.Element {
  const { t } = useTranslation();

  const config = {
    domain: {
      label: t('pages.dataMapper.context'),
      canCreate: true,
      canDelete: true,
      canEdit: true,
    },
  };
  const [getJson, setGetJson] = useState<boolean>(false);
  const tableConfig = {
    srNumberTitle: t('pages.dataMapper.srNumber'),
    sourceHeaderTitle: t('pages.dataMapper.logixGroup'),
    targetHeaderTitle: t('pages.dataMapper.sinkObjects'),
    actionHeaderTitle: t('pages.dataMapper.action'),
  };

  const handleMappedLogixGroupSchema = (logixUpdated: LogixGroupType) => {
    // eslint-disable-next-line no-console
    console.log(logixUpdated);
    setGetJson(false);
  };
  const handleSaveButton = async () => {
    setGetJson(true);
  };
  return (
    <>
      <Button
        id="savebtn"
        size="medium"
        onClick={handleSaveButton}
        type="primary"
      >
        {t('components.button.save')}
      </Button>
      <DataMapperUIComponent
        logixGroupObject={logixFile as unknown as LogixGroupType}
        config={config}
        tableConfig={tableConfig}
        handleMappedLogixGroupSchema={handleMappedLogixGroupSchema}
        getJson={getJson}
      />
    </>
  );
}

export default DataMapperTest;
