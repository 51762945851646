import { useEffect } from 'react';

import { MessageType } from '@revenue-solutions-inc/revxcoreui';
import { formatCurrency, getDate } from 'common/helpers';
import AccountView from 'components/entityManagement/accounts/maintain/AccountView';
import { getIdFormats } from 'components/entityManagement/common/formatValidations';
import Loading from 'components/Loading';
import {
  useGetAccountByIdQuery,
  useGetAccountPeriodsAndBalanceQuery,
  useGetLookupConfigurationQuery,
} from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { IHeader, setHeader } from 'redux/contentSlice';
import { useAppDispatch } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import { formatIdentifier } from 'utils/formatIdentifier';
import { ConfigurationDomains } from 'common/platformConfigUtils/platformConfigUtils';
import { useTheme } from '@mui/system';

function MantainAccount(): JSX.Element {
  const MAIN_CONFIG_DOMAIN = ConfigurationDomains.ReferenceSchema;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const { accountId } = useParams() as { accountId: string };

  const { data, isLoading, refetch } = useGetAccountByIdQuery(
    { accountId },
    {
      enabled: accountId !== '',
      onError: () => {
        dispatch(
          addMessage({
            type: MessageType.Error,
            message: t('pages.accountSummary.message.noAccount'),
          })
        );
      },
    }
  );

  const { data: accountPeriodsData } = useGetAccountPeriodsAndBalanceQuery(
    { accountId },
    {
      enabled: !!accountId,
      staleTime: 3 * (60 * 1000), //3 minutes
      onError: () => {
        dispatch(
          addMessage({
            type: MessageType.Error,
            message: t('pages.accountSummary.message.noAccount'),
          })
        );
      },
    }
  );

  const { data: configuredIdTypes } = useGetLookupConfigurationQuery({
    configurationDomain: MAIN_CONFIG_DOMAIN,
    configurationType: 'IdType',
  });

  useEffect(() => {
    if (!isLoading && data) {
      const header: IHeader = {
        pageTitle: t('pages.accountSummary.title'),
        previousPage: t('pages.entitySummary.title'),
        route: `entity\\${data.GetAccountById.entityInfoId}`,
        icon: {
          icon: 'assignmentIcon',
          props: { fill: theme.palette.primary.main },
          fontSize: 'medium',
        },
        data: [
          {
            id: 'type',
            label: t(`pages.accountSummary.header.type`),
            value: data.GetAccountById.accountType ?? '',
          },
          {
            id: 'commenceDate',
            label: t(`pages.accountSummary.header.commenceDate`),
            value: getDate(data.GetAccountById.commenceDate ?? ''),
          },
          {
            id: 'accountId',
            label: t(`pages.accountSummary.header.accountId`),
            value: formatIdentifier(
              data.GetAccountById.group
                ?.filter(
                  (group) => group.groupName?.toLowerCase() === 'identifiers'
                )
                .find((attr) =>
                  attr.attribute.some(
                    (att) =>
                      att.attributeName === 'isPrimary' &&
                      att.attributeValue === 'true'
                  )
                )
                ?.attribute.find(
                  (name) => name.attributeName?.toLowerCase() === 'value'
                )?.attributeValue ?? '',
              getIdFormats(configuredIdTypes),
              data.GetAccountById.group
                ?.filter(
                  (group) => group.groupName?.toLowerCase() === 'identifiers'
                )
                .find((attr) =>
                  attr.attribute.some(
                    (att) =>
                      att.attributeName === 'isPrimary' &&
                      att.attributeValue === 'true'
                  )
                )
                ?.attribute.find(
                  (name) => name.attributeName?.toLowerCase() === 'idtype'
                )?.attributeValue ?? ''
            ),
          },
          {
            id: 'accountName',
            label: t(`pages.accountSummary.header.accountName`),
            value:
              data.GetAccountById.group
                ?.filter((group) => group.groupName?.toLowerCase() === 'names')
                .find((attr) =>
                  attr.attribute.some(
                    (att) =>
                      att.attributeName === 'isPrimary' &&
                      att.attributeValue === 'true'
                  )
                )
                ?.attribute.find(
                  (name) => name.attributeName?.toLowerCase() === 'accountname'
                )?.attributeValue ?? '',
            first: true,
          },
          {
            id: 'accountBalance',
            label: 'Balance',
            value:
              accountPeriodsData?.GetAccountPeriodsAndBalance?.accountPeriods &&
              accountPeriodsData?.GetAccountPeriodsAndBalance.totalBalance !=
                null &&
              accountPeriodsData?.GetAccountPeriodsAndBalance?.accountPeriods
                ?.length > 0
                ? formatCurrency(
                    accountPeriodsData?.GetAccountPeriodsAndBalance.totalBalance?.toString()
                  )
                : '-',
          },
        ],
      };
      dispatch(setHeader(header));
    }
  }, [
    isLoading,
    data,
    dispatch,
    t,
    configuredIdTypes,
    accountPeriodsData,
    theme.palette.primary.main,
  ]);

  return (
    <>
      {isLoading && <Loading />}
      {data && !isLoading && (
        <AccountView data={data.GetAccountById} refetchData={refetch} />
      )}
    </>
  );
}

export default MantainAccount;
