import { useEffect } from 'react';

import { MessageType } from '@revenue-solutions-inc/revxcoreui';
import { getDate } from 'common/helpers';
import { getEntityPrimaryItem } from 'components/entityManagement/common/entityUtils';
import { getIdFormats } from 'components/entityManagement/common/formatValidations';
import EntityView from 'components/entityManagement/entities/maintain/EntityView';
import Loading from 'components/Loading';
import {
  useGetEntityByIdQuery,
  useGetLookupConfigurationQuery,
} from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { IHeader, setHeader } from 'redux/contentSlice';
import { useAppDispatch } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import { useParams } from 'react-router-dom';
import { formatIdentifier } from 'utils/formatIdentifier';
import { ConfigurationDomains } from 'common/platformConfigUtils/platformConfigUtils';
import { useTheme } from '@mui/system';

function MaintainEntity(): JSX.Element {
  const MAIN_CONFIG_DOMAIN = ConfigurationDomains.ReferenceSchema;
  const dispatch = useAppDispatch();
  const { entityId } = useParams() as { entityId: string };
  const { t } = useTranslation();
  const theme = useTheme();

  const { data, isLoading, refetch } = useGetEntityByIdQuery(
    { entityId },
    {
      enabled: entityId !== '',
      onError: () => {
        dispatch(
          addMessage({
            type: MessageType.Error,
            message: t('pages.entitySummary.message.noEntity'),
          })
        );
      },
    }
  );

  const { data: configuredIdTypes } = useGetLookupConfigurationQuery({
    configurationDomain: MAIN_CONFIG_DOMAIN,
    configurationType: 'IdType',
  });

  useEffect(() => {
    if (data) {
      const headerData: IHeader = {
        pageTitle: t('pages.entitySummary.title'),
        previousPage: t('pages.dashboard.home'),
        route: 'dashboard',
        icon: {
          props: { fill: theme.palette.primary.main },
          icon: 'assignmentIcon',
          fontSize: 'medium',
        },
        data: [
          {
            id: '1',
            label: t('pages.entitySummary.name.title'),
            value:
              getEntityPrimaryItem(data, 'names').find(
                (name) => name.attributeName?.toLowerCase() === 'value'
              )?.attributeValue ?? '--',
            first: true,
          },
          {
            id: '2',
            label: t('pages.entitySummary.header.entityId'),
            value: formatIdentifier(
              getEntityPrimaryItem(data, 'identifiers').find(
                (name) => name.attributeName?.toLowerCase() === 'value'
              )?.attributeValue ?? '',
              getIdFormats(configuredIdTypes),
              getEntityPrimaryItem(data, 'identifiers').find(
                (name) => name.attributeName?.toLowerCase() === 'idtype'
              )?.attributeValue ?? ''
            )
              .concat(' (')
              .concat(
                getEntityPrimaryItem(data, 'identifiers').find(
                  (name) => name.attributeName?.toLowerCase() === 'idtype'
                )?.attributeValue ?? '--'
              )
              .concat(')'),
          },
          {
            id: '3',
            label: t('pages.entitySummary.header.type'),
            value: data.GetEntityById.entityType,
          },
          {
            id: '4',
            label: t('pages.entitySummary.header.commenceDate'),
            value: getDate(data.GetEntityById.commenceDate ?? ''),
          },
        ],
      };
      dispatch(setHeader(headerData));
    }
  }, [data, dispatch, t, configuredIdTypes, theme.palette.primary.main]);

  return (
    <>
      {isLoading && <Loading />}
      {data && !isLoading && (
        <EntityView data={data.GetEntityById} refetchData={refetch} />
      )}
    </>
  );
}

export default MaintainEntity;
