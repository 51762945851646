import {
  FormEvent,
  SyntheticEvent,
  useReducer,
  useMemo,
  useEffect,
  useContext,
} from 'react';
import {
  Card,
  CardContent,
  CardHeader,
} from '@revenue-solutions-inc/revxcoreui';
import {
  Button,
  Container,
  Stack,
  Typography,
  Grid,
  Tabs,
  Tab,
  Box,
  Theme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { WorkflowContext } from 'types/WorkflowContext';
import {
  Attribute,
  GeneralDataResult,
  GetWorkflowGroupsQuery,
} from 'generated/graphql';
import { setHeader } from 'redux/contentSlice';
import { useAppDispatch } from 'redux/hooks';
import AttributeDetails from 'components/Workflow/AttributeDetails';
import WorkflowTabInfo from 'components/workflowEditor/WorkflowTabInfo';
import {
  initialState,
  actions,
  workflowFormReducer,
  WorkflowFormReducerState,
} from './workflowFormReducer';
import { AttributesTab, CRMTab, HistoryTab, TablesTab } from './Tabs';
import { WorkflowFormContext } from '../WorkflowFormContext';
import {
  groupsToInclude,
  Groups,
} from '../../../pages/Workflows/workflowCommon';
import { useProcessWorkData } from '../../../pages/Workflows/hooks/useProcessWorkData';

function updateCustomAttributes(
  customAttributes: Attribute[],
  groups: Groups
): void {
  /*
    Note: this part is really confusing.
    Each custom attribute is meant to represent a collection of attributes stored inside a property called "repeatingValue".
    So, for each customAttribute, we need to iterate over each repeatingValue and get the new value from its corresponding location inside the groups object.
  */
  if (!Array.isArray(groups.customAttributes)) {
    return;
  }
  const groupCustomAttributes = groups.customAttributes as Attribute[];
  customAttributes.forEach((customAttribute: Attribute) => {
    if (!Array.isArray(customAttribute.repeatingValue)) {
      return;
    }
    const repeatingValue = customAttribute.repeatingValue as Attribute[];
    const customAttributeName = customAttribute.attributeName;
    if (!customAttributeName) {
      return;
    }
    const correspondingGroupCustomAttribute = groupCustomAttributes.find(
      (groupAttribute: Attribute) =>
        groupAttribute.attributeName === customAttributeName
    );
    if (!Array.isArray(correspondingGroupCustomAttribute?.repeatingValue)) {
      return;
    }
    const correspondingRepeatingValue =
      correspondingGroupCustomAttribute?.repeatingValue as Attribute[];
    repeatingValue.forEach((repeatingValueAttribute: Attribute) => {
      const repeatingValueAttributeName = repeatingValueAttribute.attributeName;
      const correspondingRepeatingValueAttribute =
        correspondingRepeatingValue.find(
          (currentCorrespondingRepeatingValueAttribute: Attribute) =>
            currentCorrespondingRepeatingValueAttribute.attributeName ===
            repeatingValueAttributeName
        );
      if (!correspondingRepeatingValueAttribute) return;
      const value = correspondingRepeatingValueAttribute.attributeValue;
      if (value) {
        repeatingValueAttribute.attributeValue = value;
      }
    });
  });
}

type WorkflowFormProps = {
  isEdit: boolean;
  data: GetWorkflowGroupsQuery | undefined;
  workflowData?: GetWorkflowGroupsQuery;
  variantId: string;
  onSubmit: (state: WorkflowFormReducerState) => void;
  workType?: string;
  hiddenFields?: string[];
};

export const WorkflowForm = ({
  isEdit,
  data,
  workflowData,
  variantId,
  onSubmit,
  workType,
  hiddenFields = [],
}: WorkflowFormProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { state: locationState } = useLocation();
  const [state, localDispatch] = useReducer(workflowFormReducer, initialState);
  const fieldsDisabledForEdit = [
    'VariantGroupCode',
    'WorkflowVariantCode',
    'Queue',
  ];
  const { setCaseStateCopy } = useContext(WorkflowFormContext);

  useEffect(() => {
    if (!workType && !locationState?.__workType && state.attributes.length) {
      setCaseStateCopy(state);
    }
  }, [state.attributes, state.customAttributes, workType, locationState]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      locationState?.__previousState?.attributes?.length &&
      !['Notes', 'Suppression'].includes(workType || '') &&
      !['Notes', 'Suppression'].includes(locationState?.__workType)
    ) {
      localDispatch({
        type: actions.RESTORE_STATE,
        payload: {
          previousState: locationState.__previousState,
        },
      });
    }
  }, [workType, locationState]);

  useEffect(() => {
    dispatch(
      setHeader({
        pageTitle: isEdit
          ? t('pages.workflow.update.title')
          : t('pages.workflow.create.title'),
      })
    );
  }, [isEdit]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isEdit && locationState?.__workflowContext) {
      localDispatch({
        type: actions.SET_CONTEXT,
        payload: { workflowContext: locationState.__workflowContext },
      });
    }
  }, [locationState, isEdit]);

  useEffect(() => {
    if (locationState?.__noteWorkflowKey) {
      localDispatch({
        type: actions.SET_NOTES,
        payload: {
          key: locationState?.__noteWorkflowKey,
        },
      });
    }
  }, [locationState?.__noteWorkflowKey]);

  const {
    getAttributesToSet,
    getCustomAttributesToSet,
    getCustomTablesToSet,
    getSteps,
    getIsNotesSubjectValid,
    getWorkflowContext,
    getWorkflowTabOptions,
    processAttributesToSet,
  } = useProcessWorkData();

  const { hasDemographics, hasCRM, hasHistory } = getWorkflowTabOptions(data);

  const isCustomAttributes = useMemo(() => {
    const hasCustomAttrs = state.customAttributes?.some(
      (ca) => ca.repeatingValue
    );
    return hasCustomAttrs;
  }, [state.customAttributes]);

  const isCustomTables = useMemo(() => {
    const hasCustomTables = state.customTables?.some((ca) => ca.repeatingValue);
    return hasCustomTables;
  }, [state.customTables]);

  useEffect(() => {
    return () => {
      localDispatch({
        type: actions.CLEAR_FORM,
      });
    };
  }, []);

  useEffect(() => {
    localDispatch({
      type: actions.SET_ACTIVE_TAB,
      payload: {
        activeTab: isCustomAttributes
          ? 'attributes'
          : hasCRM
          ? 'crm'
          : hasHistory
          ? 'history'
          : isCustomTables
          ? 'tables'
          : 'attributes', // default
      },
    });
  }, [
    data,
    isCustomAttributes,
    hasDemographics,
    hasCRM,
    hasHistory,
    isCustomTables,
  ]);

  useEffect(() => {
    if (data) {
      const formId = data.GetWorkflowGroups.find(
        ({ groupName }) => groupName === 'ActionAttributes'
      )?.attribute.find(
        ({ attributeName }) => attributeName === 'FormID'
      )?.attributeValue;
      localDispatch({
        // Setup formID
        type: actions.SET_FORM_ID,
        payload: {
          formId,
        },
      });
    }
  }, [data]);

  useEffect(() => {
    const attributesToSet = getAttributesToSet(groupsToInclude, data);

    let groups: Groups;
    if (isEdit && workflowData) {
      // Next, we need to parse the workflowData which is saved for this particular work item.
      try {
        groups = JSON.parse(
          workflowData?.GetWorkflowGroups[0].groupName as string
        );
      } catch (e) {
        return;
      }

      // Setup the workflow context
      if (groups && (groups.WorkflowContext || groups.WorkflowContexts)) {
        const workflowContext = groups.WorkflowContexts
          ? groups.WorkflowContexts
          : [groups.WorkflowContext];
        localDispatch({
          type: actions.SET_EXISTING_CONTEXT,
          payload: {
            workflowContext,
          },
        });
      }

      // Setup Notes
      if (groups && (groups?.NoteIds as string[])?.length) {
        localDispatch({
          type: actions.SET_EXISTING_NOTES,
          payload: groups.NoteIds,
        });
      }

      // Now that we have the attributes and we have the data for this work item,
      // we need to replace the default attributeValue with the data that is set for
      // this work item.
      if (groups && attributesToSet.length) {
        const processedAttributesToSet = processAttributesToSet(
          groups,
          attributesToSet
        );

        localDispatch({
          type: actions.SET_ATTRIBUTES,
          payload: {
            attributes: processedAttributesToSet,
          },
        });
      }

      // Now we process custom attributes.
      const customAttributesToSet = isEdit
        ? groups?.customAttributes
        : getCustomAttributesToSet(data);
      if (groups && customAttributesToSet) {
        updateCustomAttributes(customAttributesToSet as Attribute[], groups);
        localDispatch({
          type: actions.SET_CUSTOM_ATTRIBUTES,
          payload: {
            customAttributes: customAttributesToSet,
          },
        });
      }

      // Process custom tables.
      const customTablesToSet = getCustomTablesToSet(data);
      if (groups && customTablesToSet) {
        updateCustomAttributes(customTablesToSet as Attribute[], groups);
        localDispatch({
          type: actions.SET_CUSTOM_TABLES,
          payload: {
            customTables: customTablesToSet,
          },
        });
      }

      // and set the work id
      if (groups.DisplayId) {
        localDispatch({
          type: actions.SET_WORK_ID,
          payload: { workId: groups.DisplayId },
        });
      }
    } else {
      if (attributesToSet.length) {
        localDispatch({
          type: actions.SET_ATTRIBUTES,
          payload: { attributes: attributesToSet },
        });
      }

      const customAttributesToSet = getCustomAttributesToSet(data);

      if (customAttributesToSet) {
        localDispatch({
          type: actions.SET_CUSTOM_ATTRIBUTES,
          payload: {
            customAttributes: customAttributesToSet as Attribute[],
          },
        });
      }

      const customTablesToSet = getCustomTablesToSet(data);

      if (customTablesToSet) {
        localDispatch({
          type: actions.SET_CUSTOM_TABLES,
          payload: {
            customTables: customTablesToSet as Attribute[],
          },
        });
      }
    }
  }, [data, isEdit, workflowData]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSelectContext = (context: GeneralDataResult) => {
    const workflowContext = getWorkflowContext(context);
    localDispatch({
      type: actions.SET_CONTEXT,
      payload: {
        workflowContext,
      },
    });
  };

  const handleChangeTab = (_: SyntheticEvent, tabName: string) => {
    localDispatch({
      type: actions.SET_ACTIVE_TAB,
      payload: { activeTab: tabName },
    });
  };

  const onRemoveContext = (context: WorkflowContext) => {
    localDispatch({
      type: actions.REMOVE_CONTEXT,
      payload: { workflowContext: context },
    });
  };

  const {
    watchedStepClose,
    otherCloseReason,
    watchCloseComments,
    attributesToRender,
  } = useMemo(() => {
    return getSteps(groupsToInclude, state.attributes);
  }, [state.attributes]); // eslint-disable-line react-hooks/exhaustive-deps

  const isNotesSubjectValid: boolean = useMemo(() => {
    return getIsNotesSubjectValid(state.attributes);
  }, [state.attributes, getIsNotesSubjectValid]);

  const handleChange = (
    value: string | number | Date,
    group: string,
    name: string
  ) => {
    if (group === 'VariantDetails') {
      const newAttributes = state.attributes?.map((a) => ({
        ...a,
        attributeValue:
          a.attributeName === name ? String(value) : a.attributeValue,
      }));
      localDispatch({
        type: actions.SET_ATTRIBUTES,
        payload: {
          attributes: newAttributes,
        },
      });
    }
    if (group === 'CustomAttributes') {
      const newCustomAttributes = state.customAttributes?.map(
        (customAttribute) => {
          return {
            ...customAttribute,
            repeatingValue: customAttribute?.repeatingValue?.map(
              (repeatingValue) => {
                return {
                  ...repeatingValue,
                  attributeValue:
                    repeatingValue.attributeName === name
                      ? String(value)
                      : repeatingValue.attributeValue,
                };
              }
            ),
          };
        }
      );
      localDispatch({
        type: actions.SET_CUSTOM_ATTRIBUTES,
        payload: {
          customAttributes: newCustomAttributes,
        },
      });
    }
    if (group === 'Tables') {
      const newCustomTables = state.customTables?.map((customTable) => {
        return {
          ...customTable,
          repeatingValue: customTable?.repeatingValue?.map((repeatingValue) => {
            return {
              ...repeatingValue,
              attributeValue:
                repeatingValue.attributeName === name
                  ? String(value)
                  : repeatingValue.attributeValue,
            };
          }),
        };
      });
      localDispatch({
        type: actions.SET_CUSTOM_TABLES,
        payload: {
          customAttributes: newCustomTables,
        },
      });
    }
  };

  const getCardTitle = () => {
    if (workType === 'Notes') {
      return isEdit
        ? t('pages.workflow.noteDetailsTitle')
        : t('pages.workflow.newNote');
    }

    if (workType === 'Suppression') {
      return isEdit ? workType : t('pages.workflow.newSuppression');
    }

    return isEdit
      ? t('pages.workflow.summaryDetailsTitle', {
          workId: state.workId,
        })
      : t('pages.workflow.newWorkItem');
  };

  const getButtonText = () => {
    if (workType === 'Notes') {
      return isEdit
        ? t('pages.workflow.update.saveNote')
        : t('pages.workflow.create.createNote');
    }

    if (workType === 'Suppression') {
      return isEdit
        ? t('pages.workflow.update.saveSuppression')
        : t('pages.workflow.create.createSuppression');
    }

    return isEdit
      ? t('pages.workflow.update.saveWork')
      : t('pages.workflow.create.createWork');
  };

  const getIsButtonDisabled = () => {
    if (workType === 'Notes') {
      return !isNotesSubjectValid;
    }
    return watchedStepClose && otherCloseReason && !watchCloseComments;
  };

  const gridAttributeWidthOptions =
    workType === 'Notes'
      ? { xs: 12, md: 12, sm: 12 }
      : { xs: 12, md: 4, sm: 4 };

  const workContainerWidth = workType === 'Notes' ? 'sm' : 'md';
  return (
    <Container
      maxWidth={hasDemographics ? 'lg' : workContainerWidth}
      sx={{
        display: 'flex',
        gap: 1,
      }}
    >
      {hasDemographics && (
        <WorkflowTabInfo
          showDemographic={hasDemographics}
          workflowContext={state.workflowContext}
          onSelectContext={onSelectContext}
          onRemoveContext={onRemoveContext}
        />
      )}
      <Card>
        <CardHeader
          title={<Typography variant="h2">{getCardTitle()}</Typography>}
        />
        <Container>
          <CardContent>
            <form
              onSubmit={(e: FormEvent<HTMLFormElement>) => {
                e.preventDefault();
                onSubmit(state);
              }}
            >
              <Stack spacing={2}>
                <Grid container rowSpacing={2} columnSpacing={2}>
                  {attributesToRender
                    ?.filter(
                      (attr: Attribute) =>
                        !hiddenFields.includes(attr.attributeName)
                    )
                    .map((attr: Attribute, idx: number) => {
                      const isFieldDisabledForEdit =
                        fieldsDisabledForEdit.includes(attr.attributeName);
                      const required =
                        (attr.attributeName === 'CloseComments' &&
                          otherCloseReason) ||
                        attr.attributeName === 'NotesSubject' ||
                        attr.attributeName === 'VariantStartDate' ||
                        attr.attributeName === 'VariantEndDate';
                      return (
                        <Grid
                          key={attr.attributeName + '-' + idx}
                          item
                          {...gridAttributeWidthOptions}
                        >
                          <AttributeDetails
                            attribute={attr}
                            variantId={variantId}
                            required={required}
                            helperText={
                              required ? t('components.message.required') : ''
                            }
                            group="VariantDetails"
                            handleChange={handleChange}
                            isDisabled={isFieldDisabledForEdit}
                          />
                        </Grid>
                      );
                    })}
                </Grid>

                {(isCustomAttributes ||
                  hasCRM ||
                  hasHistory ||
                  isCustomTables) && (
                  <Tabs
                    onChange={handleChangeTab}
                    value={state.activeTab}
                    sx={{
                      borderBottom: (theme: Theme) =>
                        `1px solid ${theme.palette.grey[100]}`,
                    }}
                  >
                    <Tab
                      value="attributes"
                      label={t('pages.workflow.attributes')}
                    />
                    {isCustomTables && isEdit && (
                      <Tab value="tables" label={t('pages.workflow.tables')} />
                    )}
                    {hasCRM && isEdit && (
                      <Tab value="crm" label={t('pages.workflow.crm')} />
                    )}
                    {hasHistory && isEdit && (
                      <Tab
                        value="history"
                        label={t('pages.workflow.history')}
                      />
                    )}
                  </Tabs>
                )}
                {isCustomAttributes &&
                  state.customAttributes.length > 0 &&
                  state.activeTab === 'attributes' && (
                    <AttributesTab
                      variantId={variantId}
                      attributes={state.customAttributes}
                      handleAttributeChange={handleChange}
                      formId={state.formId}
                    />
                  )}
                {isCustomTables &&
                  state.customTables.length > 0 &&
                  state.activeTab === 'tables' && (
                    <TablesTab
                      variantId={variantId}
                      tables={state.customTables}
                      handleAttributeChange={handleChange}
                    />
                  )}
                {hasCRM && isEdit && state.activeTab === 'crm' && (
                  <CRMTab notes={state.notes} isEdit={isEdit} />
                )}
                {hasHistory && isEdit && state.activeTab === 'history' && (
                  <HistoryTab />
                )}
                <Box
                  sx={{
                    justifyContent: 'flex-end',
                    display: 'flex',
                  }}
                >
                  <Button
                    type="button"
                    id="cancel-create-note-btn"
                    variant="outlined"
                    onClick={() => navigate(-1)}
                    sx={{
                      marginRight: 1,
                    }}
                  >
                    {t('pages.workflow.cancel')}
                  </Button>
                  <Button
                    id="create-workflow-btn"
                    type="submit"
                    variant="contained"
                    disabled={getIsButtonDisabled()}
                  >
                    {getButtonText()}
                  </Button>
                </Box>
              </Stack>
            </form>
          </CardContent>
        </Container>
      </Card>
    </Container>
  );
};
