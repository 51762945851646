import { useState } from 'react';

import { Grid, SelectChangeEvent } from '@mui/material';
import { Card, CardContent } from '@revenue-solutions-inc/revxcoreui';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box, Theme } from '@mui/system';
import Select from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import { SelectType } from '@revenue-solutions-inc/revxcoreui/material/controls/Select/Select';
import CorrespondanceCard from 'components/properties/CorrespondanceCard';
import ExemptionsCard from 'components/properties/ExemptionsCard';
import FiduciaryCard from 'components/properties/FiduciaryCard';
import OwnerInformationCard from 'components/properties/OwnerInformationCard';
import TaxableValuesCard from 'components/properties/TaxableValuesCard';
import { useTranslation } from 'react-i18next';

import EditCorrespondanceCard from '../EditCorrespondanceCard';
import EditExemptionsCard from '../EditExemptionsCard';
import EditFiduciaryCard from '../EditFiduciaryCard';
import EditNoteCard from '../EditNoteCard';
import EditOwnerContainer from '../EditOwnerContainer/EditOwnerContainer';
import EditPropertySummary from '../EditPropertySummary';
import EditTaxableValues from '../EditTaxableValues';
import PropertyLiabilityCard from '../PropertyLiabilityCard';
import PropertySummaryCard from '../PropertySummaryCard';

export const selectLabelStyle = (theme: Theme) => {
  return {
    color: theme.palette.common.white,
    fontSize: '1.375rem',
    marginBottom: '8px',
    '&.Mui-focused': {
      color: theme.palette.common.white,
    },
  };
};

const taxYearTypes: SelectType[] = [
  { key: '2020', desc: '2020' },
  { key: '2021', desc: '2021' },
  { key: '2022', desc: '2022' },
];

interface Props {
  propertyId: string;
  legalDescription: string;
  propertyType: string;
  propertySubType: string;
  propertyOwnerName: string;
  propertyOwnerId: string;
  activeNoteCard: string;
  setActiveNoteCard: React.Dispatch<React.SetStateAction<string>>;
}
function PropertyContainer({
  propertyId,
  legalDescription,
  propertyType,
  propertySubType,
  activeNoteCard,
  setActiveNoteCard,
  propertyOwnerName,
  propertyOwnerId,
}: Props): JSX.Element {
  const [year, setYear] = useState<string>('');
  const [active, setActive] = useState<string>('');
  const { t } = useTranslation();

  const handleChange = (event: SelectChangeEvent<string | number>) => {
    setYear((event.target as HTMLSelectElement).value);
  };

  const tabletView = useMediaQuery('(min-width: 451px) and (max-width: 960px)');

  if (tabletView && active === '' && activeNoteCard === '') {
    return (
      <Grid container mt={2.5} spacing={2}>
        <Grid item md={6}>
          <Card
            sx={(theme: Theme) => {
              return {
                borderRadius: '5px',
                boxShadow: '0px 0px 5px rgb(0 0 0 / 30%)',
                marginBottom: '20px',
                backgroundColor: theme.palette.error.main,
              };
            }}
          >
            <CardContent>
              <Select
                options={taxYearTypes}
                value={year}
                id="type-property-container"
                label={t('pages.propertyView.taxYear')}
                onChange={handleChange}
                width="309px"
                selectLabelStyle={selectLabelStyle}
              />
            </CardContent>
          </Card>
          <Box sx={{ marginBottom: '20px' }}>
            <PropertyLiabilityCard />
          </Box>
          <Box sx={{ marginBottom: '20px' }}>
            <PropertySummaryCard
              propertyId={propertyId}
              legalDescription={legalDescription}
              propertyType={propertyType}
              propertySubType={propertySubType}
              setActive={setActive}
            />
          </Box>
          <Box sx={{ marginBottom: '20px' }}>
            <OwnerInformationCard
              setActive={setActive}
              propertyOwnerName={propertyOwnerName}
              propertyOwnerId={propertyOwnerId}
            />
          </Box>
        </Grid>
        <Grid item md={6}>
          <Box sx={{ marginBottom: '20px' }}>
            <CorrespondanceCard setActive={setActive} />
          </Box>
          <Box sx={{ marginBottom: '20px' }}>
            <TaxableValuesCard setActive={setActive} />
          </Box>
          <Box sx={{ marginBottom: '20px' }}>
            <ExemptionsCard setActive={setActive} />
          </Box>
          <Box sx={{ marginBottom: '20px' }}>
            <FiduciaryCard setActive={setActive} />
          </Box>
        </Grid>
      </Grid>
    );
  }
  if (active === '' && activeNoteCard === '') {
    return (
      <Grid container mt={2.5} spacing={2}>
        <Grid item lg={3} md={4}>
          <Card
            sx={(theme: Theme) => {
              return {
                borderRadius: '5px',
                boxShadow: '0px 0px 5px rgb(0 0 0 / 30%)',
                marginBottom: '20px',
                backgroundColor: theme.palette.error.main,
              };
            }}
          >
            <CardContent>
              <Select
                options={taxYearTypes}
                value={year}
                id="type-property-tax-year"
                label={t('pages.propertyView.taxYear')}
                onChange={handleChange}
                width="309px"
                selectLabelStyle={selectLabelStyle}
              />
            </CardContent>
          </Card>
          <PropertyLiabilityCard />
        </Grid>

        <Grid item lg={3.8} md={4}>
          <Box id="testingBox" sx={{ marginBottom: '20px' }}>
            <PropertySummaryCard
              propertyId={propertyId}
              legalDescription={legalDescription}
              propertyType={propertyType}
              propertySubType={propertySubType}
              setActive={setActive}
            />
          </Box>
          <Box sx={{ marginBottom: '20px' }}>
            <OwnerInformationCard
              setActive={setActive}
              propertyOwnerName={propertyOwnerName}
              propertyOwnerId={propertyOwnerId}
            />
          </Box>
          <Box sx={{ marginBottom: '20px' }}>
            <CorrespondanceCard setActive={setActive} />
          </Box>
        </Grid>
        <Grid item lg={3.8} md={4}>
          <Box sx={{ marginBottom: '20px' }}>
            <TaxableValuesCard setActive={setActive} />
          </Box>
          <Box sx={{ marginBottom: '20px' }}>
            <ExemptionsCard setActive={setActive} />
          </Box>
          <Box sx={{ marginBottom: '20px' }}>
            <FiduciaryCard setActive={setActive} />
          </Box>
        </Grid>
        <Grid item lg={1.4} md={0}></Grid>
      </Grid>
    );
  }
  switch (active) {
    case 'ActiveOwnerCard':
      return <EditOwnerContainer setActive={setActive} />;

    case 'ActiveFiduciaryCard':
      return <EditFiduciaryCard setActive={setActive} />;

    case 'ActiveCorrespondanceCard':
      return <EditCorrespondanceCard setActive={setActive} />;

    case 'ActivePropertySummaryCard':
      return <EditPropertySummary setActive={setActive} />;

    case 'ActiveExemptionsCard':
      return <EditExemptionsCard setActive={setActive} />;

    case 'ActiveTaxableValues':
      return <EditTaxableValues setActive={setActive} />;
    default:
      return (
        <EditNoteCard
          setActive={setActive}
          setActiveNoteCard={setActiveNoteCard}
        />
      );
  }
}

export default PropertyContainer;
