import { useEffect, useState } from 'react';

import { Grid } from '@mui/material';
import Checkbox from '@revenue-solutions-inc/revxcoreui/material/controls/Checkbox';
import Select, {
  SelectType,
} from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import ConnectorParameters from 'components/channelScheduling/ConnectorParameters';
import { useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { Channel, ConnectorInfo } from 'types/channels';
import {
  ModuleResponse,
  useCheckChannelNameQuery,
  useGetDataConnectorsQuery,
  useGetModulesByUserQuery,
} from 'generated/graphql';
import {
  Control,
  Controller,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';
import ControlledInputField from 'components/controls/ControlledInputField';
import { Input } from '@revenue-solutions-inc/revxcoreui';
import Loading from 'components/Loading';
import useMaskInput from 'hooks/useMaskInput';

//TODO: Remove once logic is figured out for editing Channel Name
enum ActionType {
  EDIT = 'edit',
  CREATE = 'create',
}

interface Props {
  setPipelineDetailsValid: React.Dispatch<React.SetStateAction<boolean>>;
  control: Control<Channel, unknown>;
  setValue: UseFormSetValue<Channel>;
}

function CreateChannel({
  setPipelineDetailsValid,
  control,
  setValue,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [isCompressed, setIsCompressed] = useState(false);
  const [parametersValid, setParametersValid] = useState<boolean>(true);
  const [isEncrypted, setIsEncrypted] = useState(false);
  const [channelNameExists, setChannelNameExists] = useState<boolean>(false);

  const { action } = useParams() as {
    action: string;
  };

  const channelName = useWatch({
    control,
    name: 'Channel.ChannelName',
  });

  const inputDataConnector = useWatch({
    control,
    name: 'Channel.Pipeline.InputDataConnector',
  });

  const displayName = useWatch({
    control,
    name: 'Channel.DisplayName',
  });

  const pipelineNameMask = useMaskInput(
    '',
    t('pages.manageChannel.channelNameMask'),
    /^[A-Za-z0-9\s_-]*$/,
    channelName ?? ''
  );

  const handleHelperTextChange = () => {
    return pipelineNameMask.maskError;
  };

  const { data: modulesData } = useGetModulesByUserQuery();

  const { data: connectorData, isLoading } = useGetDataConnectorsQuery({
    connectorType: 'Inbound',
  });

  const { data } = useCheckChannelNameQuery(
    {
      channelName: channelName,
    },
    {
      enabled: channelName !== '' && action === ActionType.CREATE,
    }
  );

  const validateRequiredFields = () => {
    let isValid = true;

    if (
      pipelineNameMask.maskError ||
      (data?.CheckChannelNameExists && action === ActionType.CREATE) ||
      channelName === '' ||
      displayName === '' ||
      inputDataConnector === '' ||
      parametersValid === false
    ) {
      isValid = false;
    }

    setPipelineDetailsValid(isValid);
  };

  useEffect(() => {
    validateRequiredFields();
  });

  useEffect(() => {
    if (data) {
      setChannelNameExists(data.CheckChannelNameExists);
    }
  }, [data]);

  const displayValues: SelectType[] = connectorData
    ? connectorData.getDataConnectors.map((connector) => {
        return {
          key: connector.Name,
          desc: connector.DisplayName,
        } as unknown as SelectType;
      })
    : ([{ key: 'empty' }] as unknown as SelectType[]);
  if (!isLoading) {
    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              name={`Channel.Module`}
              render={({ field: { value, onChange } }) => {
                return (
                  <Select
                    required
                    id="channelModule"
                    sx={{ width: '100%', maxWidth: '20em' }}
                    label={t('pages.manageChannel.module')}
                    value={value}
                    options={
                      modulesData
                        ? modulesData.ModulesByUser.results.map(
                            (module: ModuleResponse) => {
                              return {
                                key: module.name,
                                desc: module.name,
                              } as unknown as SelectType;
                            }
                          )
                        : ([{ key: 'empty' }] as unknown as SelectType[])
                    }
                    onChange={(e) => {
                      onChange(e.target.value);
                    }}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              rules={{
                required: true,
                pattern: /^[A-Za-z0-9\s_-]*$/,
              }}
              name={`Channel.ChannelName`}
              render={({ field: { value, onChange } }) => {
                return (
                  <Input
                    required
                    id="channelName"
                    sx={{ width: '100%', maxWidth: '20em' }}
                    disabled={action === ActionType.EDIT ? true : false}
                    helperText={
                      channelNameExists && action === ActionType.CREATE
                        ? t('pages.manageChannel.channelNameExists')
                        : handleHelperTextChange()
                    }
                    label={t('pages.manageChannel.name')}
                    value={value}
                    onChange={(e) => {
                      pipelineNameMask.handleMaskChange(e);
                      onChange(e.target.value);
                      setValue(`Channel.Pipeline.Name`, e.target.value);
                      setValue(`Channel.Pipeline.Id`, e.target.value);
                    }}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledInputField
              control={control}
              rules={{
                required: true,
              }}
              name={`Channel.DisplayName`}
              required
              id="channelDisplayName"
              sx={{ width: '100%', maxWidth: '20em' }}
              label={t('pages.manageChannel.displayName')}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              name={`Channel.Pipeline.InputDataConnector`}
              render={({ field: { value, onChange } }) => {
                return (
                  <Select
                    required
                    id="connector"
                    sx={{ width: '100%', maxWidth: '20em' }}
                    label={t('pages.manageChannel.connector')}
                    value={value}
                    options={displayValues}
                    onChange={(e) => {
                      setValue(`Channel.Pipeline.Parameters`, {});
                      onChange(e.target.value);
                    }}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <ConnectorParameters
              key={inputDataConnector}
              connectorData={
                connectorData?.getDataConnectors as unknown as ConnectorInfo[]
              }
              connectorClicked={inputDataConnector}
              setParametersValid={setParametersValid}
              control={control}
              setValue={setValue}
            />
          </Grid>
          <Grid item xs={12} ml={1} mt={-3}>
            <Checkbox
              id="compressed"
              checked={isCompressed}
              onChange={(event) => {
                setIsCompressed(event.target.checked);
              }}
              label={t('pages.manageChannel.isCompressed')}
              disabled
            />
          </Grid>
          <Grid item xs={12} ml={1}>
            <Checkbox
              id="encrypted"
              checked={isEncrypted}
              onChange={(event) => {
                setIsEncrypted(event.target.checked);
              }}
              label={t('pages.manageChannel.isEncrypted')}
              disabled
            />
          </Grid>
          <Grid item xs={12} ml={1} mb={2}>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              name={`Channel.IsFti`}
              render={({ field: { value, onChange } }) => {
                return (
                  <Checkbox
                    id="channelIsFti"
                    label={t('pages.manageChannel.isFti')}
                    checked={value}
                    disabled={false}
                    onChange={onChange}
                  />
                );
              }}
            />
          </Grid>
        </Grid>
      </>
    );
  }
  if (isLoading) {
    return <Loading />;
  }
  return <></>;
}

export default CreateChannel;
