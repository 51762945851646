import Box from '@mui/material/Box';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const boxStyle = {
  backgroundColor: 'linkBlue.dark',
  width: '3em',
  height: '2em',
  boxSizing: 'border-box',
  borderRadius: '5px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'common.white',
  '&:hover': {
    cursor: 'pointer',
  },
};

const arrowIconStyle = {
  cursor: 'pointer',
  fontSize: '1.25rem',
};

interface Props {
  displayPanel: boolean;
  handleSlidePanel: () => void;
}

const ExpandButton = ({
  displayPanel,
  handleSlidePanel,
}: Props): JSX.Element => {
  return (
    <Box onClick={handleSlidePanel} sx={boxStyle} data-testid="box-id">
      {displayPanel ? (
        <ArrowForwardIcon sx={arrowIconStyle} />
      ) : (
        <ArrowBackIcon sx={arrowIconStyle} />
      )}
    </Box>
  );
};

export default ExpandButton;
