import Box from '@mui/material/Box';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const GetWorkButton = ({ onClick }: any): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Box style={{ textAlign: 'right' }} data-testid="getWork-button-container">
      <Button
        id="getWork-button"
        sx={{ minWidth: 'max-content' }}
        type="primary"
        onClick={onClick}
      >
        {t('pages.dashboard.getWork')}
      </Button>
    </Box>
  );
};
