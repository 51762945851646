import { useEffect, useState } from 'react';
import { Grid, Tabs, Tab } from '@mui/material';
import { Box } from '@mui/system';
import TabPanel from '@revenue-solutions-inc/revxcoreui/material/controls/TabPanel';
import { setHeader } from 'redux/contentSlice';
import { useAppDispatch } from 'redux/hooks';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PrintManagement from './PrintManagement';
import BatchHistory from './BatchHistory';

function PrintManagementPage(): JSX.Element {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const routes = [`/admin/printManagement`, `/admin/batchHistory`];
  const [currentTab, setCurrentTab] = useState(
    location.pathname == routes[0] ? 0 : 1
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  useEffect(() => {
    dispatch(setHeader({ pageTitle: t('pages.printManagement.title') }));
  }, [dispatch, t]);

  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            flexGrow: '1 !important',
            maxWidth: '100% !important',
          }}
        >
          <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: '1em' }}>
            <Tabs
              value={currentTab}
              onChange={handleTabChange}
              aria-label="config-tool-tab"
            >
              <Tab
                label={t('pages.printManagement.printCriteria')}
                aria-label="printCriteria-tab"
              />
              <Tab
                label={t('pages.printManagement.batchHistory')}
                aria-label="batchHistory-tab"
              />
            </Tabs>
          </Box>
          <>
            <TabPanel selectedIndex={currentTab} index={0}>
              <PrintManagement />
            </TabPanel>
            <TabPanel selectedIndex={currentTab} index={1}>
              <BatchHistory />
            </TabPanel>
          </>
        </Grid>
      </Grid>
    </>
  );
}
export default PrintManagementPage;
