import { useState } from 'react';
import {
  CircularProgress,
  FormControl,
  Grid,
  RadioGroup,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import DatePicker from '@revenue-solutions-inc/revxcoreui/material/controls/DatePicker';
import Radio from '@revenue-solutions-inc/revxcoreui/material/controls/Radio';
import { useTranslation } from 'react-i18next';
import useTenantZone from 'hooks/useTenantZone';
interface Props {
  roleId: string;
  startDate: Date | null;
  endDate: Date | null;
  startDateError?: string;
  endDateError?: string;
  onChangeDates: (
    roleId: string,
    key: 'startDate' | 'endDate'
  ) => (date: Date | null) => void;
  handleRadio: (roleId: string, value: ValueType) => void;
}

type ValueType = 'true' | 'false';

export default function RowSubTable({
  roleId,
  startDate,
  endDate,
  startDateError,
  endDateError,
  onChangeDates,
  handleRadio,
}: Props) {
  const { isFetching: isFetchingTenantZone, tenantDate } = useTenantZone();
  const [value, setValue] = useState<ValueType>('false');
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.editUserRole.subTable',
  });
  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setValue(target.value as ValueType);
    handleRadio(roleId, target.value as ValueType);
  };

  return (
    <Box sx={{ px: 3 }}>
      <Typography variant="h3">{t('requiredText')}</Typography>
      <FormControl>
        <RadioGroup row value={value} onChange={handleChange}>
          <Radio
            id="radio-yes"
            data-testid="radio-yes"
            value="true"
            label={t('yes')}
            color="primary"
            onChange={() => {}}
            checked={value === 'true'}
          />
          <Radio
            id="radio-no"
            data-testid="radio-no"
            value="false"
            label={t('no')}
            color="primary"
            onChange={() => {}}
            checked={value === 'false'}
          />
        </RadioGroup>
      </FormControl>
      {value === 'true' && (
        <Box sx={{ mt: 3 }}>
          <Typography variant="h4" sx={{ mb: 3 }}>
            {t('selectText')}
          </Typography>
          <Grid container>
            <Grid item xs={3}>
              {!isFetchingTenantZone ? (
                <DatePicker
                  id="startDate"
                  label={t('startDate')}
                  value={startDate}
                  requiredErrorMessage={startDateError}
                  minDate={tenantDate.tenantDate}
                  showErrorMessageWithoutFocus={true}
                  handleChange={onChangeDates(roleId, 'startDate')}
                />
              ) : (
                <CircularProgress size={24} />
              )}
            </Grid>
            <Grid item xs={3} sx={{ mx: 4 }}>
              <DatePicker
                id="endDate"
                label={t('endDate')}
                value={endDate}
                requiredErrorMessage={endDateError}
                minDate={tenantDate.tenantDate}
                showErrorMessageWithoutFocus={true}
                handleChange={onChangeDates(roleId, 'endDate')}
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
}
