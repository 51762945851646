import { useCallback, useContext, useMemo, useState } from 'react';
import { Box } from '@mui/system';
import { DatePicker } from '@revenue-solutions-inc/revxcoreui';
import {
  AssignedRolesContext,
  Role,
} from 'components/contexts/AssignedRoles/AssignedRolesProvider';
import { useTranslation } from 'react-i18next';
import { CellContext } from '@tanstack/react-table';
import { AssignedUserRole } from 'types/roles';
import {
  getFormatDate,
  isMaxValueDate,
  toDate,
  checkDatesEqual,
} from 'utils/date-util';
import { getTime } from 'utils/getTime';
import useTenantZone from 'hooks/useTenantZone';

type KeyType = 'startDate' | 'endDate';

export default function EditableDatesCell({
  row: { original, index },
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
CellContext<any, unknown>): JSX.Element {
  const { assignedRoles, setAssignedRoles } = useContext(AssignedRolesContext);
  const { isFetching: isFetchingTenantZone, tenantDate } = useTenantZone();
  const [minDateError, setMinDateError] = useState<boolean>(false);

  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.editUserRole',
  });

  const { initialStartDate, initialEndDate, roleId } = useMemo(() => {
    const { startDate, endDate } = original as AssignedUserRole;
    return {
      roleId: original.roleId,
      initialStartDate: toDate(startDate),
      initialEndDate: toDate(endDate),
    };
  }, [original]);

  const { startDateValue, endDateValue, startDateError, endDateError, isEdit } =
    useMemo(() => {
      const role = assignedRoles?.roles.find((r) => r.roleId === roleId);
      return {
        ...role,
        startDateValue:
          role?.startDate && !isMaxValueDate(role.startDate)
            ? role.startDate
            : null,
        endDateValue:
          role?.endDate && !isMaxValueDate(role.endDate) ? role.endDate : null,
        startDateError: role?.startDateError ?? '',
        endDateError: role?.endDateError ?? '',
      };
    }, [assignedRoles?.roles, roleId]);

  const stringFormat = useCallback(
    (value?: Date | null) => {
      if (value && !isMaxValueDate(value)) return getFormatDate(value);
      return t('noDateSpecified');
    },
    [t]
  );

  const handleUpdateRole = (
    roleFound: Role,
    key: KeyType,
    value: Date | null
  ): Role => {
    const timeDateIsNull = 0;
    let initialStartUnixDate = 0;
    let initialEndUnixDate = 0;
    let newStartUnixDate = 0;
    let newEndUnixDate = 0;
    let startDateErr = '';
    let endDateErr = '';
    const invalidDateMessage = t('availableRolesErrors.dateIsRequired');
    let roleUpdated: Role = { ...roleFound };

    const unixCurrentDate = getTime(new Date(tenantDate.tenantDate));

    if (key === 'startDate') {
      newEndUnixDate = getTime(roleFound?.endDate ?? timeDateIsNull);
      if (
        !roleFound?.initialStartUnixTime ||
        roleFound?.initialStartUnixTime === 0
      )
        initialStartUnixDate = getTime(roleFound?.startDate ?? timeDateIsNull);
      else initialStartUnixDate = roleFound.initialStartUnixTime;

      newStartUnixDate = getTime(value ?? timeDateIsNull);
      startDateErr = roleFound?.startDateError ?? '';
      if (startDateErr === invalidDateMessage) startDateErr = '';

      if (newStartUnixDate.toString() === 'NaN')
        startDateErr = t('availableRolesErrors.enterValidDate');

      if (newStartUnixDate < unixCurrentDate) {
        startDateErr = t('availableRolesErrors.startDateErrorBeforeNow');
        if (newStartUnixDate === initialStartUnixDate) startDateErr = '';
      }

      if (startDateErr.length === 0 && newStartUnixDate > newEndUnixDate) {
        startDateErr = t('availableRolesErrors.startDateError');
        if (newEndUnixDate === timeDateIsNull) startDateErr = '';
      }

      if (newStartUnixDate >= unixCurrentDate) startDateErr = '';

      if (!value) startDateErr = invalidDateMessage;

      setMinDateError(startDateErr.length > 0);

      roleUpdated = {
        ...roleUpdated,
        startDate: value,
        initialStartUnixTime: initialStartUnixDate,
        startDateError: startDateErr,
      };
    } else if (key === 'endDate') {
      newStartUnixDate = getTime(roleFound?.startDate ?? timeDateIsNull);

      if (!roleFound?.initialEndUnixTime || roleFound?.initialEndUnixTime === 0)
        initialEndUnixDate = getTime(roleFound?.endDate ?? timeDateIsNull);
      else initialEndUnixDate = roleFound.initialEndUnixTime;
      newEndUnixDate = getTime(value ?? timeDateIsNull);
      endDateErr = roleFound?.endDateError ?? '';
      if (newEndUnixDate.toString() === 'NaN')
        endDateErr = t('availableRolesErrors.enterValidDate');

      if (newEndUnixDate < unixCurrentDate) {
        endDateErr = t('availableRolesErrors.endDateErrorBeforeNow');
        if (newEndUnixDate === initialEndUnixDate) endDateErr = '';
      }

      if (endDateErr.length === 0 && newEndUnixDate < newStartUnixDate) {
        endDateErr = t('availableRolesErrors.endDateError');
        if (newEndUnixDate === timeDateIsNull) endDateErr = '';
      }

      if (
        newEndUnixDate >= unixCurrentDate &&
        newEndUnixDate >= newStartUnixDate
      )
        endDateErr = '';

      if (!value) endDateErr = '';

      setMinDateError(endDateErr.length > 0);

      roleUpdated = {
        ...roleUpdated,
        endDate: value,
        initialEndUnixTime: initialEndUnixDate,
        endDateError: endDateErr,
      };
    }

    if (
      getTime(roleUpdated?.startDate ?? timeDateIsNull) ===
      getTime(roleUpdated?.endDate ?? timeDateIsNull)
    ) {
      if (!roleUpdated?.startDate) return roleUpdated;
      roleUpdated = {
        ...roleUpdated,
        startDateError: '',
        endDateError: '',
      };
      return roleUpdated;
    }

    return roleUpdated;
  };

  const getConfigDate = () => {
    if (!tenantDate.tenantDate) return null;
    const dateTenantConfig = tenantDate.tenantDate.split(',');
    const elements = dateTenantConfig[0].split('/');
    return new Date(`${elements[2]}-${elements[0]}-${elements[1]}`);
  };

  const disabledConfig = (date: Date, endDate: Date) => {
    const configDate = getConfigDate();

    const disabled =
      !checkDatesEqual(endDate, date) &&
      configDate &&
      date.getTime() < configDate.getTime();

    return disabled || false;
  };

  const disabledEndDateConfig = (date: Date) => {
    if (initialEndDate) {
      return disabledConfig(date, initialEndDate);
    }
    if (initialEndDate === null) {
      return disabledConfig(date, new Date(tenantDate.tenantDate));
    }
    return false;
  };

  const disabledStartDateConfig = (date: Date) => {
    if (initialStartDate) {
      return disabledConfig(date, initialStartDate);
    }
    return false;
  };

  const handleChangeDate = (key: KeyType) => (value: Date | null) => {
    if (assignedRoles) {
      setAssignedRoles({
        ...assignedRoles,
        roles: [
          ...assignedRoles.roles.map((role) =>
            role.roleId === roleId ? handleUpdateRole(role, key, value) : role
          ),
        ],
      });
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ flexDirection: 'row', flexShrink: 2, px: 1 }}>
        {!isEdit && !isFetchingTenantZone ? (
          stringFormat(initialStartDate)
        ) : (
          <DatePicker
            id={`datePicker-startDate-${index}`}
            label=""
            value={startDateValue}
            requiredErrorMessage={startDateError}
            showMinDateError={minDateError}
            shouldDisableDate={disabledStartDateConfig}
            disableMinDateError={true}
            showErrorMessageWithoutFocus={true}
            required={true}
            handleChange={handleChangeDate('startDate')}
          />
        )}
      </Box>
      <Box sx={{ flexDirection: 'row', flexShrink: 2, px: 1 }}>
        {!isEdit && !isFetchingTenantZone ? (
          stringFormat(initialEndDate)
        ) : (
          <DatePicker
            id={`datePicker-endDate-${index}`}
            label=""
            value={endDateValue}
            requiredErrorMessage={endDateError}
            showMinDateError={minDateError}
            shouldDisableDate={disabledEndDateConfig}
            showErrorMessageWithoutFocus={true}
            handleChange={handleChangeDate('endDate')}
          />
        )}
      </Box>
    </Box>
  );
}
