import { Controller, UseFormReturn } from 'react-hook-form';
import { DatePicker } from '@revenue-solutions-inc/revxcoreui';

interface ControlledDatepickerProps {
  // eslint-disable-next-line
  control: UseFormReturn['control'] | any;
  name: string;
  rules: Record<string, unknown>;
  disabled?: boolean;
  label: string;
  required: boolean;
  showCalendar?: boolean;
  requiredErrorMessage: string;
  isCalendarRequired: boolean;
  id: string;
  size?: 'small' | 'medium' | 'large';
  sx?: [Record<string, unknown>];
  minDate?: string;
  maxDate?: string;
}

function ControlledDateField({
  control,
  name,
  disabled = false,
  rules,
  required,
  requiredErrorMessage,
  size = 'medium',
  label,
  id,
  sx,
  minDate,
  maxDate,
}: ControlledDatepickerProps) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <DatePicker
            id={id}
            isCalendarRequired={true}
            required={required}
            value={!!value ? value : null}
            handleChange={onChange}
            label={label}
            requiredErrorMessage={!!error ? requiredErrorMessage : ''}
            minDate={minDate}
            maxDate={maxDate}
            disabled={disabled}
            size={size}
            sx={sx}
          />
        );
      }}
    />
  );
}

export default ControlledDateField;
