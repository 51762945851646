import { Controller, UseFormReturn } from 'react-hook-form';
import { SxProps, Theme } from '@mui/system';
import Select, {
  NumericSelectType,
  SelectType,
} from '@revenue-solutions-inc/revxcoreui/material/controls/Select';

interface ControlledSelectFieldProps {
  // eslint-disable-next-line
  control: UseFormReturn['control'] | any;
  name: string;
  rules: Record<string, unknown>;
  disabled?: boolean;
  label: string;
  required?: boolean;
  id: string;
  sx?: SxProps<Theme>;
  options: (SelectType | NumericSelectType)[];
  defaultValue?: string | number;
  autowidth?: boolean;
}

function ControlledSelectField({
  control,
  name,
  disabled = false,
  rules,
  required,
  options,
  label,
  id,
  sx,
  autowidth = false,
}: ControlledSelectFieldProps) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({
        field: { onChange, value },
        fieldState: { error: selectError },
      }) => {
        return (
          <Select
            id={id}
            required={required}
            value={value}
            options={options}
            onChange={onChange}
            label={label}
            disabled={disabled}
            error={selectError?.message}
            sx={sx}
            autowidth={autowidth}
          />
        );
      }}
    />
  );
}

export default ControlledSelectField;
