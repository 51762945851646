import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { setHeader } from 'redux/contentSlice';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import Select, {
  SelectType,
} from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import { MessageType } from '@revenue-solutions-inc/revxcoreui';
import { setPreferredModule } from 'redux/userSlice';
import { useUpdateUserPreferencesMutation } from 'generated/graphql';
import { Grid } from '@mui/material';
import { addMessage } from 'redux/messageSlice';
import { useTranslation } from 'react-i18next';
import Loading from 'components/Loading/Loading';

function Settings(): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const modules = useAppSelector((state) => state.content.modules);
  const preferredModule = useAppSelector(
    (state) => state.user.userPreferences.preferredModule
  );
  const [selectValue, setSelectValue] = useState(preferredModule);
  const userPreferences = useAppSelector((state) => state.user.userPreferences);
  const { mutate, isLoading } = useUpdateUserPreferencesMutation();

  useEffect(() => {
    dispatch(setHeader({ pageTitle: t('pages.settings.title') }));
    setSelectValue(preferredModule);
  }, [dispatch, preferredModule, t]);

  const moduleOptions = (): SelectType[] => {
    if (modules) {
      return modules?.map((item) => {
        return {
          key: item.name ? item.name.toLowerCase() : '',
          desc: item.name ? item.name : '',
        };
      });
    } else {
      return [];
    }
  };

  return (
    <>
      {isLoading && <Loading />}
      <Grid>
        <Select
          id="select-preferred-module"
          label={t('pages.settings.selectModule')}
          onChange={(e) => {
            setSelectValue(e.target.value.toString());
          }}
          options={moduleOptions()}
          value={selectValue ?? ''}
          sx={{ mb: 1 }}
        />
      </Grid>
      <Grid>
        <Button
          id="user-pref-btn-id"
          onClick={() => {
            dispatch(setPreferredModule(selectValue ?? ''));
            mutate(
              {
                userPreferences: {
                  ...userPreferences,
                  preferredModule: selectValue,
                },
              },
              {
                onSuccess: () => {
                  dispatch(
                    addMessage({
                      type: MessageType.Success,
                      message: t('pages.settings.messages.success'),
                    })
                  );
                },
                onError: () => {
                  dispatch(
                    addMessage({
                      type: MessageType.Success,
                      message: t('pages.settings.messages.failure'),
                    })
                  );
                },
              }
            );
          }}
          disabled={preferredModule === selectValue}
        >
          Save
        </Button>
      </Grid>
    </>
  );
}

export default Settings;
