import { useEffect, useState } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import { Clear } from '@mui/icons-material';
import { Typography, Box, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Input from '@revenue-solutions-inc/revxcoreui/material/controls/Input';
import { SelectType } from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import { CheckboxGroup } from '@revenue-solutions-inc/revxcoreui/material/controls/Checkbox/Checkbox';
import {
  ParsedSinkObjectUIType,
  DestinationObject,
  DomainSink,
  SinkFieldsType,
  SinkObject,
  Operation,
  Field,
  MessageType,
  MessageActionType,
} from '@revenue-solutions-inc/revxcoreui';
import { useTranslation } from 'react-i18next';
import { addMessage } from 'redux/messageSlice';
import { useAppDispatch } from 'redux/hooks';
import {
  useDomainInfoViewQuery,
  useDomainInfoInputMutation,
} from 'generated/graphql';
import SearchAutoComplete from 'components/SearchAutoComplete';
import DestinationSynkObjects from './DestinationSynkObjects';
import SearchFilter from './SearchFilter';
import SinkObjectsPropties from './SinkObjectsPropties';

interface SynkObjectGroupProps {
  sinkObjects: DestinationObject;
}
interface CatTypes {
  cat: string;
  id: number;
}
interface Properties {
  SinkObjects: SinkObject;
  Operations: Operation;
  SinkFields: Field;
}
interface ErrorTypeProps {
  error: boolean;
  message: string;
}
interface ErrorsProps {
  Owner: ErrorTypeProps;
  Description: ErrorTypeProps;
}

function SynkObjectGroup({ sinkObjects }: SynkObjectGroupProps): JSX.Element {
  const theme = useTheme();
  const [domains, setDomains] = useState<SelectType[] | []>([]);
  const [parsedAllSinkObjects, setParsedAllSinkObjects] = useState<
    ParsedSinkObjectUIType[] | []
  >([]);
  const [properties, setProperties] = useState<Properties[]>([]);
  const [, setTmpParsedAllSinkObjects] = useState<
    ParsedSinkObjectUIType[] | []
  >([]);
  const [tempSinkObects, setTempSinkObects] =
    useState<DestinationObject>(sinkObjects);
  const [selectedDomain, setSelectedDomain] = useState<string>('');

  const [catInfo, setCatInfo] = useState<CatTypes>({ cat: '', id: 0 });
  const [isExpandAll, setIsExpandAll] = useState<number>(0);

  const [searchFilter, setSearchFilter] = useState('');
  const [currentFilter, setCurrentFilter] = useState('');
  const [resetFilter, setResetFilter] = useState(false);
  const cgroup = [
    {
      id: '1',
      value: 'SinkObjects',
      label: 'SinkObjects',
      checked: false,
    },
    {
      id: '2',
      value: 'Operations',
      label: 'Operations',
      checked: false,
    },
    {
      id: '3',
      value: 'SinkFields',
      label: 'SinkFields',
      checked: false,
    },
  ];
  const [checkboxGroup, setCheckboxGroup] = useState<CheckboxGroup[]>(cgroup);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [owner, setOwner] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [errors, setErrors] = useState<ErrorsProps>({
    Owner: {
      error: false,
      message: '',
    },
    Description: {
      error: false,
      message: '',
    },
  });
  const { data: data, refetch: refetchDomainInfoView } = useDomainInfoViewQuery(
    { domainName: selectedDomain },
    { enabled: false, cacheTime: 0 }
  );
  const { mutate: response } = useDomainInfoInputMutation();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (data) {
      setOwner(data?.domainInfoView?.Owner ?? '');
      setDescription(data?.domainInfoView.Description ?? '');
    }
  }, [data, isUpdate]);

  useEffect(() => {
    document.body.style.height = '100%';

    const muiRoot = document.getElementsByClassName(
      'MuiPaper-root'
    )[0] as HTMLElement;
    if (muiRoot) muiRoot.style.height = '100%';

    const html = document.querySelector('html');
    if (html) html.style.height = '100%';

    const root = document.getElementById('root');
    if (root) root.style.height = '100%';

    const mainContent = document.getElementById('main-content');
    if (mainContent) {
      mainContent.style.height = 'initial';
      mainContent.style.paddingBottom = '0px';
    }
  });
  useEffect(() => {
    return () => {
      document.body.style.height = 'inherit';
      const muiRoot = document.getElementsByClassName(
        'MuiPaper-root'
      )[0] as HTMLElement;
      if (muiRoot) muiRoot.style.height = 'inherit';

      const html = document.querySelector('html');
      if (html) html.style.height = 'inherit';

      const root = document.getElementById('root');
      if (root) root.style.height = 'inherit';

      const mainContent = document.getElementById('main-content');
      if (mainContent) mainContent.style.height = 'initial';
    };
  }, []);

  useEffect(() => {
    setCatInfo({ cat: '', id: 0 });
    setCheckboxGroup(cgroup);
    setSearchFilter('');
    setCurrentFilter('');
    setIsUpdate(false);
    setOwner('');
    setDescription('');
    refetchDomainInfoView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDomain]);
  useEffect(() => {
    const cloneParsedAllSinkObjects: ParsedSinkObjectUIType[] = JSON.parse(
      JSON.stringify([...parsedAllSinkObjects])
    );
    if (!cloneParsedAllSinkObjects.length || !selectedDomain) {
      return;
    }

    const currentDomain = cloneParsedAllSinkObjects.filter(
      (item: ParsedSinkObjectUIType) => item.DomainName === selectedDomain
    );

    if (!currentDomain.length) {
      return;
    }
    const tprop = [
      {
        Operations: currentDomain[0].Operations[0],
        SinkFields: currentDomain[0].SinkFields[0],
        SinkObjects: currentDomain[0].SinkObjects[0],
      },
    ];

    if (catInfo.cat === 'SinkObjects') {
      currentDomain[0].SinkObjects.forEach((ele) => {
        if (ele.SinkObjectId == catInfo.id)
          tprop[0] = {
            SinkObjects: ele,
            Operations: tprop[0].Operations,
            SinkFields: tprop[0].SinkFields,
          };
      });
      setProperties(tprop);
    } else if (catInfo.cat === 'Operations') {
      currentDomain[0].Operations.forEach((ele) => {
        if (ele.OperationID == catInfo.id)
          tprop[0] = {
            SinkObjects: tprop[0].SinkObjects,
            Operations: ele,
            SinkFields: tprop[0].SinkFields,
          };
      });
      setProperties(tprop);
    } else if (catInfo.cat === 'SinkFields') {
      currentDomain[0].SinkFields.forEach((ele) => {
        if (ele.BaseField.FieldID == catInfo.id)
          tprop[0] = {
            SinkObjects: tprop[0].SinkObjects,
            Operations: tprop[0].Operations,
            SinkFields: ele,
          };
      });
      setProperties(tprop);
    }
  }, [catInfo, parsedAllSinkObjects, selectedDomain]);

  const ParsedAllSinkObjectsFn = () => {
    const {
      Domain: { DomainSinks = [] },
    } = sinkObjects;
    let domainKey = '';
    let domainIndex = 0;
    let SinkObjectId = '';

    const ParsedFields: ParsedSinkObjectUIType[] = [];

    DomainSinks.forEach((item: DomainSink, i: number) => {
      const sinkFields: (SinkFieldsType & { OperationType: string })[] = [];
      const ParsedSinkObjects: SinkObject[] = [];
      const ParsedOperations: Operation[] = [];
      const rootPath = `Domain.DomainSink[${i}].`;
      const { SinkObjects = [] } = item;
      domainKey = item.DomainName;
      domainIndex = i;

      SinkObjects.forEach((ele: SinkObject, p: number) => {
        SinkObjectId = ele.SinkObjectId.toString();
        ParsedSinkObjects.push(ele);
        const SinkPath = rootPath + `SinkObjects[${p}].`;
        const { Operations = [] } = ele;
        Operations.forEach((opSchema: Operation, indx) => {
          ParsedOperations.push(opSchema);
          const opPath = SinkPath + `Operations[${indx}].`;
          const { SinkFields = [] } = opSchema;
          const OperationName = opSchema.OperationName;

          const OperationID = opSchema.OperationID;

          SinkFields.forEach((field: Field, k: number) => {
            const path = opPath + `SinkFields[${k}].BaseField`;
            sinkFields.push({
              ...field,
              domainIndex: domainIndex.toString(),
              Calculations: [],
              checked: false,
              Domainkey: domainKey,
              path: path,
              OperationID,
              SinkObjectId,
              OperationName,
              OperationType: '',
            });
          });
        });
      });
      ParsedFields.push({
        DomainSink: item,
        DomainName: item.DomainName,
        index: i,
        SinkFields: sinkFields,
        SinkObjects: ParsedSinkObjects,
        Operations: ParsedOperations,
      });
    });
    return [...ParsedFields];
  };
  const filterParsedSinkObectFn = (
    currentDomain: DomainSink,
    filterSinkObjects: SinkObject[],
    filterOperations: Operation[],
    filterSinkFields: SinkFieldsType[]
  ) => {
    const {
      Domain: { DomainSinks = [] },
    } = JSON.parse(JSON.stringify({ ...sinkObjects }));

    const filterParsedSinkObect = JSON.parse(
      JSON.stringify({ ...sinkObjects })
    );

    DomainSinks.splice(0, DomainSinks.length);
    DomainSinks.push(currentDomain);

    if (currentFilter === 'SinkObjects') {
      DomainSinks[0].SinkObjects.splice(0, DomainSinks[0].SinkObjects.length);
      DomainSinks[0].SinkObjects = filterSinkObjects;
    } else if (currentFilter === 'Operations') {
      DomainSinks[0].SinkObjects.forEach((item: SinkObject) => {
        const OperationIDs: number[] = [];
        item.Operations.forEach((op: Operation) => {
          OperationIDs.push(op.OperationID);
        });

        item.Operations.splice(0, item.Operations.length);
        filterOperations.forEach((op: Operation) => {
          if (OperationIDs.includes(op.OperationID)) {
            item.Operations.push(op);
          }
        });
      });
    } else if (currentFilter === 'SinkFields') {
      DomainSinks[0].SinkObjects.forEach((item: SinkObject) => {
        item.Operations.forEach((op: Operation) => {
          const FieldIDs: number[] = [];
          op.SinkFields.forEach((sf) => {
            FieldIDs.push(sf.BaseField.FieldID);
          });

          op.SinkFields.splice(0, op.SinkFields.length);
          filterSinkFields.forEach((sf: SinkFieldsType) => {
            if (FieldIDs.includes(sf.BaseField.FieldID)) {
              op.SinkFields.push(sf);
            }
          });
        });
        const tmpOperations: Operation[] = [];
        item.Operations.forEach((op: Operation) => {
          if (op.SinkFields.length) {
            tmpOperations.push(op);
          }
        });
        item.Operations = tmpOperations;
      });
    }

    if (currentFilter === 'SinkFields' || currentFilter === 'Operations') {
      const tmpSinkObjects: SinkObject[] = [];
      DomainSinks[0].SinkObjects.forEach((item: SinkObject) => {
        if (item.Operations.length) {
          tmpSinkObjects.push(item);
        }
      });
      DomainSinks[0].SinkObjects = tmpSinkObjects;
    }
    filterParsedSinkObect.Domain.DomainSinks.splice(
      0,
      filterParsedSinkObect.Domain.DomainSinks.length
    );
    filterParsedSinkObect.Domain.DomainSinks.push(DomainSinks[0]);

    setTempSinkObects(filterParsedSinkObect);
  };

  useEffect(() => {
    const ParsedAllSinkObjects = ParsedAllSinkObjectsFn();
    setParsedAllSinkObjects(ParsedAllSinkObjects);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!selectedDomain) {
      return;
    }
    const cloneParsedAllSinkObjects: ParsedSinkObjectUIType[] = JSON.parse(
      JSON.stringify([...parsedAllSinkObjects])
    );

    if (selectedDomain) {
      const currentDomain = cloneParsedAllSinkObjects.filter(
        (item: ParsedSinkObjectUIType) => item.DomainName === selectedDomain
      );
      if (!currentDomain.length) {
        return;
      }
      const tsikObjectClone = { ...tempSinkObects };

      tsikObjectClone.Domain.DomainSinks.splice(
        0,
        tsikObjectClone.Domain.DomainSinks.length
      );

      tsikObjectClone.Domain.DomainSinks.push(currentDomain[0].DomainSink);
      setTempSinkObects(tsikObjectClone);
    }

    if (searchFilter && currentFilter) {
      const currentDomain = cloneParsedAllSinkObjects.filter(
        (item: ParsedSinkObjectUIType) => item.DomainName === selectedDomain
      );
      let filterSinkObjects: SinkObject[] = [];
      let filterOperations: Operation[] = [];
      let filterSinkFields: SinkFieldsType[] = [];
      if (currentFilter === 'SinkObjects') {
        filterSinkObjects = currentDomain[0].SinkObjects.filter((o) =>
          o.SinkObjectName.toLocaleLowerCase().includes(
            searchFilter.toLocaleLowerCase()
          )
        );
      } else if (currentFilter === 'Operations') {
        filterOperations = currentDomain[0].Operations.filter((o) =>
          o.OperationName.toLocaleLowerCase().includes(
            searchFilter.toLocaleLowerCase()
          )
        );
      } else if (currentFilter === 'SinkFields') {
        filterSinkFields = currentDomain[0].SinkFields.filter((o) =>
          o.BaseField.FieldName.toLocaleLowerCase().includes(
            searchFilter.toLocaleLowerCase()
          )
        );
      }

      filterParsedSinkObectFn(
        currentDomain[0].DomainSink,
        filterSinkObjects,
        filterOperations,
        filterSinkFields
      );
    } else {
      setTmpParsedAllSinkObjects([...parsedAllSinkObjects]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFilter, searchFilter, parsedAllSinkObjects, selectedDomain]);

  useEffect(() => {
    if (sinkObjects && sinkObjects.Domain && sinkObjects.Domain.DomainSinks) {
      const opts: SelectType[] = [];
      sinkObjects.Domain.DomainSinks.forEach(
        (elm: DomainSink, index: number) => {
          opts.push({
            key: `${index}`,
            desc: elm.DomainName,
          });
        }
      );

      setDomains(opts);
    }
  }, [sinkObjects]);

  const handleChangeDomains = (value: string) => {
    setSelectedDomain(value);
    setResetFilter(true);
    setIsExpandAll(0);
  };

  const handleSave = () => {
    if (!errors.Owner.error && !errors.Description.error) {
      response(
        {
          sinkView: {
            Description: description,
            Name: selectedDomain,
            Owner: owner,
            UpdatedBy: 'updatedBy',
            UpdatedOn: 'updatedOn',
          },
        },
        {
          onSuccess: () => {
            refetchDomainInfoView();
            setIsUpdate(false);
            dispatch(
              addMessage({
                message: t('Details Updated Successfully'),
                type: MessageType.Success,
                actionType: MessageActionType.None,
              })
            );
          },
          onError: () => {
            setIsUpdate(false);
            dispatch(
              addMessage({
                message: t('Network Error'),
                type: MessageType.Error,
                actionType: MessageActionType.None,
              })
            );
          },
        }
      );
    }
  };
  const handleClose = () => {
    setIsUpdate(false);
    setErrors({
      Owner: {
        error: false,
        message: '',
      },
      Description: {
        error: false,
        message: '',
      },
    });
  };
  const handleOwnerInputValidate = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let isValid = true;
    isValid = new RegExp(/^[a-zA-Z\s]*$/).test(event.target.value.trim());
    if (!event.target.value.length) {
      setErrors((prev) => ({
        ...prev,
        Owner: {
          error: true,
          message: t('Field Required'),
        },
      }));
    } else if (!isValid) {
      setErrors((prev) => ({
        ...prev,
        Owner: {
          error: true,
          message: t('Owner should be alphabets'),
        },
      }));
    } else if (event.target.value.length > 100) {
      setErrors((prev) => ({
        ...prev,
        Owner: {
          error: true,
          message: t('Owner should not be greater than 100 characters'),
        },
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        Owner: {
          error: false,
          message: '',
        },
      }));
      isValid = true;
    }
  };
  const handleDescriptionInputValidate = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let isValid = true;
    isValid = new RegExp(/^[a-zA-Z0-9\s]*$/).test(event.target.value.trim());
    if (!event.target.value.length) {
      setErrors((prev) => ({
        ...prev,
        Description: {
          error: true,
          message: t('Field Required'),
        },
      }));
    } else if (!isValid) {
      setErrors((prev) => ({
        ...prev,
        Description: {
          error: true,
          message: t('Description should be only alphabets and numbers'),
        },
      }));
    } else if (event.target.value.length > 100) {
      setErrors((prev) => ({
        ...prev,
        Description: {
          error: true,
          message: t('Description should not be greater than 100 characters'),
        },
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        Description: {
          error: false,
          message: '',
        },
      }));
      isValid = true;
    }
  };
  return (
    <>
      <div style={{ height: '100%', overflowY: 'auto' }}>
        <div
          style={{
            display: 'flex',
            overflowY: 'auto',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <div style={{ minHeight: '160px' }}>
            <Box
              sx={{
                display: 'flex',
                gap: '20px',
                backgroundColor: 'transparent',
              }}
            >
              <SearchAutoComplete
                sx={{ width: 300, backgroundColor: theme.palette.white.main }}
                value={selectedDomain}
                label={'Search / Select Domain'}
                onChange={(newValue: string) => {
                  handleChangeDomains(newValue);
                }}
                options={domains}
                id={'autocomplete-id'}
              />
              {selectedDomain.length ? (
                <Box
                  sx={{
                    width: '100%',
                    backgroundColor: theme.palette.white.main,
                    borderRadius: '5px',
                    border: '1px solid Secondary.dark',
                    padding: '10px',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      flex: '90%',
                      height: '70px',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flex: '70%',
                        flexDirection: 'column',
                      }}
                    >
                      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Typography
                          variant="h6"
                          display="block"
                          gutterBottom
                          m={0}
                          sx={{
                            fontSize: '13px',
                            color: 'primary.main',
                          }}
                        >
                          {t('Owner')}
                        </Typography>
                        {!isUpdate ? (
                          <Typography
                            variant="h6"
                            display="block"
                            gutterBottom
                            m={0}
                            sx={{
                              fontSize: '14px',
                              color: 'grey1.main',
                              marginLeft: '5px',
                            }}
                          >
                            {owner}
                          </Typography>
                        ) : (
                          <Input
                            value={owner}
                            ariaLabel="owner"
                            onChange={(e) => setOwner(e.target.value)}
                            inputProps={{ 'data-testid': 'owner' }}
                            required
                            onBlur={(event) => handleOwnerInputValidate(event)}
                            error={errors.Owner.error}
                            helperText={errors.Owner.message}
                          />
                        )}
                      </Box>
                      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Typography
                          variant="h6"
                          display="block"
                          gutterBottom
                          m={0}
                          sx={{
                            fontSize: '13px',
                            color: 'primary.main',
                          }}
                        >
                          {t('Description')}
                        </Typography>
                        {!isUpdate ? (
                          <Typography
                            variant="h6"
                            display="block"
                            gutterBottom
                            m={0}
                            sx={{
                              fontSize: '14px',
                              color: 'grey1.main',
                              marginLeft: '5px',
                            }}
                          >
                            {description}
                          </Typography>
                        ) : (
                          <Input
                            value={description}
                            id="description"
                            ariaLabel="description"
                            onChange={(e) => setDescription(e.target.value)}
                            inputProps={{ 'data-testid': 'description' }}
                            required
                            onBlur={(event) =>
                              handleDescriptionInputValidate(event)
                            }
                            error={errors.Description.error}
                            helperText={errors.Description.message}
                          />
                        )}
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flex: '30%',
                        flexDirection: 'column',
                      }}
                    >
                      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Typography
                          variant="h6"
                          display="block"
                          gutterBottom
                          m={0}
                          sx={{
                            fontSize: '13px',
                            color: 'primary.main',
                          }}
                        >
                          {t('Updated By')}
                        </Typography>
                        <Typography
                          variant="h6"
                          display="block"
                          gutterBottom
                          m={0}
                          sx={{
                            fontSize: '13px',
                            color: 'grey1.main',
                            marginLeft: '4px',
                          }}
                        >
                          {data?.domainInfoView?.UpdatedBy}
                        </Typography>
                      </Box>
                      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Typography
                          variant="h6"
                          display="block"
                          gutterBottom
                          m={0}
                          sx={{
                            fontSize: '13px',
                            color: 'primary.main',
                          }}
                        >
                          {t('Updated On')}
                        </Typography>
                        <Typography
                          variant="h6"
                          display="block"
                          gutterBottom
                          m={0}
                          sx={{
                            fontSize: '13px',
                            color: 'grey1.main',
                            marginLeft: '4px',
                          }}
                        >
                          {data?.domainInfoView?.UpdatedOn}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', flex: '10%' }}>
                    {!isUpdate ? (
                      <Button
                        id="update-owner-description"
                        onClick={() => setIsUpdate(true)}
                      >
                        {t('components.button.update')}
                      </Button>
                    ) : (
                      <Box sx={{ display: 'flex', gap: '10px' }}>
                        <Button
                          id="save-owner-description"
                          onClick={() => handleSave()}
                        >
                          {t('components.button.save')}
                        </Button>
                        <Button
                          id="cancel-owner-description"
                          onClick={() => handleClose()}
                        >
                          {t('components.button.cancel')}
                        </Button>
                      </Box>
                    )}
                  </Box>
                </Box>
              ) : null}
            </Box>
            {selectedDomain.length ? (
              <Box
                sx={{
                  display: 'flex',
                  marginTop: '20px',
                  marginBottom: '20px',
                  justifyContent: 'space-between',
                }}
              >
                <Button
                  id="clear-domain"
                  type="secondary"
                  onClick={() => {
                    setSelectedDomain('');
                    setOwner('');
                    setDescription('');
                    setIsExpandAll(0);
                  }}
                  startIcon={<Clear />}
                >
                  Close sink object
                </Button>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '10px',
                    justifyContent: 'flex-start',
                  }}
                >
                  <Button
                    id="expand-all"
                    type="secondary"
                    onClick={() => setIsExpandAll(1)}
                    startIcon={<UnfoldMoreIcon />}
                  >
                    Expand all
                  </Button>
                  <Button
                    id="collapse-all"
                    type="secondary"
                    onClick={() => setIsExpandAll(0)}
                    startIcon={<UnfoldLessIcon />}
                  >
                    Collapse all
                  </Button>

                  <SearchFilter
                    searchFilter={searchFilter}
                    resetFilter={resetFilter}
                    setResetFilter={() => {
                      setResetFilter(false);
                    }}
                    setSearchFilter={setSearchFilter}
                    label={''}
                    checkboxGroup={checkboxGroup}
                    update={(cbGroup, value) => {
                      setCurrentFilter(value);
                    }}
                  />
                </Box>
              </Box>
            ) : null}
          </div>

          {selectedDomain.length ? (
            <>
              <Box
                sx={{
                  fontSize: '8px',
                  display: 'flex',
                  gap: '10px',
                  paddingBottom: '5px',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                  <Box
                    sx={{
                      height: '10px',
                      width: '10px',
                      backgroundColor: '#c9594d',
                      borderRadius: '999px',
                    }}
                  ></Box>
                  <Box sx={{ fontSize: '10px' }}>Sink Object Name</Box>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                  <Box
                    sx={{
                      height: '10px',
                      width: '10px',
                      backgroundColor: '#2e7ca3',
                      borderRadius: '999px',
                    }}
                  ></Box>
                  <Box sx={{ fontSize: '10px' }}>Operation Name</Box>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                  <Box
                    sx={{
                      height: '10px',
                      width: '10px',
                      backgroundColor: '#439173',
                      borderRadius: '999px',
                    }}
                  ></Box>
                  <Box sx={{ fontSize: '10px' }}>Sink Fields</Box>
                </Box>
              </Box>
              <div
                style={{
                  overflowY: 'auto',
                  flexGrow: 1,
                  backgroundColor: theme.palette.white.main,
                  borderRadius: '5px',
                  border: '1px solid #6bb599',
                }}
              >
                <div key={selectedDomain} style={{ padding: '10px 0' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexGrow: 1,
                    }}
                  >
                    <KeyboardArrowDownIcon />
                    <Typography
                      variant="h6"
                      display="block"
                      gutterBottom
                      m={0}
                      sx={{
                        fontSize: '0.875rem',
                        cursor: 'pointer',
                      }}
                    >
                      {selectedDomain}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: 'block',
                      borderLeft: `1px dashed ${theme.palette.grey2.main}`,
                      marginLeft: '11px',
                      paddingLeft: '5px',
                    }}
                  >
                    <Grid container spacing={2} sx={{ position: 'relative' }}>
                      <Grid item xs={12} md={6}>
                        <DestinationSynkObjects
                          sinkObjects={tempSinkObects}
                          isExpandAll={isExpandAll}
                          setIsExpandAll={() => {
                            setIsExpandAll(2);
                          }}
                          indexDomain={selectedDomain}
                          setCatInfo={setCatInfo}
                          catInfo={catInfo}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} sx={{ padding: 0 }}>
                        <div>
                          <SinkObjectsPropties
                            catInfo={catInfo}
                            properties={properties[0]}
                            setCatInfo={setCatInfo}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
}

export default SynkObjectGroup;
