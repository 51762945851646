import { useEffect, useCallback, useState } from 'react';

import Loading from 'components/Loading';
import { setHeader } from 'redux/contentSlice';
import { useAppDispatch } from 'redux/hooks';

import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { addMessage } from 'redux/messageSlice';

import {
  useGetViewReportTemplateQuery,
  useCreateBlackReportQuery,
  useSaveEditTemplateMutation,
  useSaveAsTemplateMutation,
  useEditTemplateQuery,
  GetViewReportTemplateResponse,
  EditTemplateResponse,
  useGetNewAccessTokenQuery,
} from 'generated/graphql';
import {
  PowerBiClient,
  MessageActionType,
  MessageType,
} from '@revenue-solutions-inc/revxcoreui';

interface ReportState {
  id?: string;
  data: RequestType | null;
  handleSaveEdit: () => void;
  handleSaveAs: (pbiId: string, workspaceId: string, profileId: string) => void;
  typeMode: string;
}

type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

type CreateBlackReportResponseCustom = {
  id: Scalars['String'];
  datasetId: Scalars['String'];
  embedUrl: Scalars['String'];
  pbiProfileId: Scalars['String'];
  pbiWorkspaceId: Scalars['String'];
  token: Scalars['String'];
  tokenExpiration: Scalars['String'];
  tokenId: Scalars['String'];
};

type RequestType =
  | GetViewReportTemplateResponse
  | CreateBlackReportResponseCustom
  | EditTemplateResponse;

function PowerBi(): JSX.Element {
  const { t } = useTranslation();
  const { templateId, reportId, typeMode, nameT, descriptionT } =
    useParams() as {
      templateId: string;
      reportId: string;
      typeMode: string;
      nameT: string;
      descriptionT: string;
    };
  const dispatch = useAppDispatch();

  const { data, isLoading } = useGetViewReportTemplateQuery({
    report: { templateId: Number(templateId) },
  });

  const { data: getPbiDataCreate, isLoading: isLoadingCreate } =
    useCreateBlackReportQuery();

  const { refetch: fetchNewToken } = useGetNewAccessTokenQuery();

  const {
    data: getPbiDataEdit,
    isLoading: isLoadingEdit,
    refetch: fetchEdit,
  } = useEditTemplateQuery({
    report: { templateId: Number(templateId) },
  });
  const { mutate: saveEditTemplateMutation } = useSaveEditTemplateMutation();
  const { mutate: saveAsTemplateMutation } = useSaveAsTemplateMutation();

  const [stateMode, setStateMode] = useState<ReportState>({
    id: '',
    data: null,
    typeMode: '',
    handleSaveEdit: () => {},
    handleSaveAs: () => {},
  });

  const handleSaveAs = useCallback(
    (pbiId: string, workspaceId: string, profileId: string) => {
      saveAsTemplateMutation(
        {
          report: {
            templateName: nameT,
            templateDescription: descriptionT,
            reportId: Number(reportId),
            pbiProfileId: profileId,
            pbiWorkspaceId: workspaceId,
            pbiReportId: pbiId,
          },
        },
        {
          onSuccess: () => {
            dispatch(
              addMessage({
                message: t('pages.reports.message.updateReport'),
                type: MessageType.Success,
                actionType: MessageActionType.None,
              })
            );
          },
          onError: () => {
            dispatch(
              addMessage({
                message: t('pages.manageReusableContent.networkError'),
                type: MessageType.Error,
                actionType: MessageActionType.None,
              })
            );
          },
        }
      );
    },
    [descriptionT, dispatch, nameT, reportId, saveAsTemplateMutation, t]
  );

  const handleSaveEdit = useCallback(() => {
    saveEditTemplateMutation(
      {
        report: {
          templateId: Number(templateId),
          templateName: nameT,
          templateDescription: descriptionT,
        },
      },
      {
        onSuccess: () => {
          dispatch(
            addMessage({
              message: t('pages.reports.message.updateReport'),
              type: MessageType.Success,
              actionType: MessageActionType.None,
            })
          );
          return true;
        },
        onError: () => {
          dispatch(
            addMessage({
              message: t('pages.manageReusableContent.networkError'),
              type: MessageType.Error,
              actionType: MessageActionType.None,
            })
          );
          return false;
        },
      }
    );
  }, [descriptionT, dispatch, nameT, saveEditTemplateMutation, t, templateId]);

  useEffect(() => {
    switch (typeMode) {
      case 'view':
        if (data !== undefined) {
          setStateMode({
            data: data.getViewReportTemplate,
            typeMode: 'view',
            handleSaveAs: () => {},
            handleSaveEdit: () => {},
          });
        }
        break;
      case 'create':
        if (getPbiDataCreate !== undefined) {
          const copyPbiCreate = {
            id: '',
            embedUrl: '',
            pbiProfileId: '',
            pbiWorkspaceId: '',
            token: '',
            tokenExpiration: '',
            tokenId: '',
          };

          if (getPbiDataCreate !== undefined) {
            copyPbiCreate.id =
              getPbiDataCreate?.createBlackReport?.datasetId ?? '';
            copyPbiCreate.embedUrl =
              getPbiDataCreate?.createBlackReport?.embedUrl ?? '';
            copyPbiCreate.pbiProfileId =
              getPbiDataCreate?.createBlackReport?.pbiProfileId ?? '';
            copyPbiCreate.pbiWorkspaceId =
              getPbiDataCreate?.createBlackReport?.pbiWorkspaceId ?? '';
            copyPbiCreate.token =
              getPbiDataCreate?.createBlackReport?.token ?? '';
            copyPbiCreate.tokenExpiration =
              getPbiDataCreate?.createBlackReport?.tokenExpiration ?? '';
            copyPbiCreate.tokenId =
              getPbiDataCreate?.createBlackReport?.tokenId ?? '';
          }

          setStateMode({
            data: copyPbiCreate,
            handleSaveAs: handleSaveAs,
            handleSaveEdit: () => {},
            typeMode: 'create',
          });
        }

        break;
      case 'edit':
        if (getPbiDataEdit !== undefined) {
          setStateMode({
            data: getPbiDataEdit.editTemplate,
            handleSaveEdit: handleSaveEdit,
            handleSaveAs: () => {},
            typeMode: 'edit',
          });
        }

        break;
      default:
        break;
    }
  }, [
    data,
    getPbiDataCreate,
    getPbiDataEdit,
    typeMode,
    handleSaveAs,
    handleSaveEdit,
  ]);

  useEffect(() => {
    dispatch(
      setHeader({
        pageTitle: t('pages.reports.powerBi'),
        previousPage: t('pages.reports.reportsDetails'),
        route: `Reports/${reportId}`,
      })
    );
  }, [dispatch, reportId, t]);

  return (
    <>
      {stateMode.data && !isLoading && !isLoadingCreate && !isLoadingEdit ? (
        <PowerBiClient
          data={stateMode.data}
          name={String(nameT)}
          description={String(descriptionT)}
          typeMode={stateMode.typeMode}
          handleSaveAs={stateMode.handleSaveAs}
          handleSaveEdit={stateMode.handleSaveEdit}
          refetchTokenCreateMode={fetchNewToken}
          refetchTokenEditMode={fetchEdit}
          registerSaveAs={handleSaveAs}
          registerSave={handleSaveEdit}
        />
      ) : (
        <Loading />
      )}
    </>
  );
}

export default PowerBi;
