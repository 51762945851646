import { gql } from 'graphql-request';

export const userBySearch = gql`
  query UsersBySearch($search: String!) {
    UsersBySearch(search: $search) {
      email
      name
      userId
      userRole {
        moduleId
        moduleName
        roleDescription
        roleId
        roleName
        startDate
        statusId
        endDate
      }
    }
  }
`;
