import { Buffer } from 'buffer';
import { ChangeEvent, useEffect, useState } from 'react';
import { Grid, Typography, Box, RadioGroup } from '@mui/material';
import {
  Checkbox,
  Input,
  ProgressLoader,
  Radio,
  TextArea,
  MessageType,
  MessageActionType,
} from '@revenue-solutions-inc/revxcoreui';
import { useTranslation } from 'react-i18next';
import DataCard from 'components/DataCard';
import {
  useGenerateTemplatePreviewMutation,
  useGetTemplateFieldInfoForPreviewQuery,
} from 'generated/graphql';
import { addMessage } from 'redux/messageSlice';
import Loading from 'components/Loading';
import { useAppDispatch } from 'redux/hooks';

type TemplateProps = {
  templateId: number;
  setPreview: (value: boolean) => void;
};
interface PreviewGroups {
  fields: [
    {
      fieldName: string;
      value: string;
    }
  ];
  repeatingFields: [
    {
      groupName: string;
      occurrences: number;
      fields: [
        {
          fieldName: string;
          value: string;
        }
      ];
    }
  ];
  freeformFields: [
    {
      fieldName: string;
      value: string;
    }
  ];
  multiChoiceGroups: [
    {
      groupName: string;
      type: string;
      options: [
        { optionName: string; description: string; value: 'true' | 'false' }
      ];
    }
  ];
}

function TemplatePreview(templateProps: TemplateProps) {
  const [finalUpdatedJson, setFinalUpdatedJson] = useState<PreviewGroups>();
  const [currentStep, setCurrentStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [completed, setCompleted] = useState<{
    [k: number]: boolean;
  }>({});
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { mutate: generatePreview } = useGenerateTemplatePreviewMutation();

  const { data: templatePreviewData, isFetching } =
    useGetTemplateFieldInfoForPreviewQuery({
      getTemplateFieldInfoByTemplateId: templateProps.templateId,
    });

  const manualFieldInfoJsonHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (
      templatePreviewData?.GetTemplateFieldInfoByTemplate &&
      JSON.parse(templatePreviewData?.GetTemplateFieldInfoByTemplate)
        .multiChoiceGroups.length > 0
    ) {
      let localOption;
      const localJSON = finalUpdatedJson?.multiChoiceGroups;
      const localGroup = localJSON?.filter(
        (item) => item.groupName == e.target.id.split(',')[0]
      );
      if (localGroup && localGroup?.length > 0 && localGroup[0].type == 'sgl') {
        localGroup[0]?.options.map((opt) => (opt.value = 'false'));
      }
      if (localGroup && localGroup?.length > 0) {
        localOption = localGroup[0]?.options.filter(
          (item) => item.description == e.target.id.split(',')[1]
        );
      }

      if (localOption)
        localOption[0].value = e.target.checked ? 'true' : 'false';
      setFinalUpdatedJson(JSON.parse(JSON.stringify(finalUpdatedJson) ?? ''));
    }
  };

  const handleTextArea = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (
      templatePreviewData?.GetTemplateFieldInfoByTemplate &&
      JSON.parse(templatePreviewData?.GetTemplateFieldInfoByTemplate)
        .freeformFields.length > 0
    ) {
      const localJSON = finalUpdatedJson?.freeformFields;
      const localOption = localJSON?.filter(
        (item) => item.fieldName == event.target.id
      );
      if (localOption) localOption[0].value = event.target.value ?? '';
      setFinalUpdatedJson(JSON.parse(JSON.stringify(finalUpdatedJson) ?? ''));
    }
  };

  const FreeFormTextChildren = (
    <>
      {finalUpdatedJson && finalUpdatedJson?.freeformFields?.length > 0 && (
        <Typography
          variant="h3"
          sx={[{ fontWeight: 'bold', color: 'primary.main' }]}
        >
          {t('pages.correspondence.freeFormTextMessage')}
        </Typography>
      )}
      {finalUpdatedJson &&
        finalUpdatedJson.freeformFields?.length > 0 &&
        finalUpdatedJson?.freeformFields
          .filter(
            (value, index, self) =>
              index ===
              self.findIndex((fld) => fld.fieldName === value.fieldName)
          )
          .map((item) => (
            <Box key={item.fieldName}>
              <Typography
                variant="h3"
                sx={[{ fontWeight: 'bold', color: 'primary.main' }]}
              >
                {item.fieldName.replace(item.fieldName.charAt(0), '') + ':'}
              </Typography>
              <TextArea
                ariaLabel="item field name"
                multiline
                id={item.fieldName}
                onChange={handleTextArea}
                sx={{ width: '100%' }}
                placeholder={t('pages.correspondence.freeFormTextPlaceholder')}
                value={
                  finalUpdatedJson?.freeformFields.filter(
                    (item3) => item3.fieldName == item.fieldName
                  )[0].value ?? ''
                }
              />
            </Box>
          ))}
      {finalUpdatedJson && finalUpdatedJson.multiChoiceGroups?.length > 0 && (
        <>
          <Typography
            variant="h3"
            sx={[{ fontWeight: 'bold', color: 'primary.main' }]}
          >
            {t('pages.correspondence.multiChoiceTextMessage')}
          </Typography>

          <Grid container mt={1} sx={{ gap: '2%' }}>
            {finalUpdatedJson?.multiChoiceGroups.map(
              (item: {
                type: string;
                groupName: string;
                options: {
                  optionName: string;
                  description: string;
                  value: string;
                }[];
              }) => {
                if (item.type == 'sgl') {
                  return (
                    <Box width={'45%'} key={item.groupName}>
                      <DataCard
                        title={item.groupName}
                        avatar={<></>}
                        children={
                          <RadioGroup>
                            {item.options.map(
                              (option: {
                                optionName: string;
                                description: string;
                                value: string;
                              }) => {
                                return (
                                  <Radio
                                    id={
                                      option.optionName.split(':')[1] +
                                      ',' +
                                      option.description
                                    }
                                    key={option.optionName}
                                    onChange={(e) =>
                                      manualFieldInfoJsonHandler(e)
                                    }
                                    value={option.description}
                                    color={'default'}
                                    label={option.description}
                                    checked={option.value == 'true'}
                                  />
                                );
                              }
                            )}
                          </RadioGroup>
                        }
                      />
                    </Box>
                  );
                }
                if (item.type == 'mlt') {
                  return (
                    <Box width={'45%'} key={item.groupName}>
                      <DataCard
                        title={item.groupName}
                        avatar={<></>}
                        children={
                          <>
                            {item.options.map(
                              (option: {
                                optionName: string;
                                description: string;
                                value: string;
                              }) => {
                                return (
                                  <Checkbox
                                    id={
                                      option.optionName.split(':')[1] +
                                      ',' +
                                      option.description
                                    }
                                    key={option.optionName}
                                    onChange={(e) =>
                                      manualFieldInfoJsonHandler(e)
                                    }
                                    label={option.description}
                                    checked={option.value == 'true'}
                                  ></Checkbox>
                                );
                              }
                            )}
                          </>
                        }
                      />
                    </Box>
                  );
                }
              }
            )}
          </Grid>
        </>
      )}
    </>
  );

  const step1Handler = (event: ChangeEvent<HTMLInputElement>) => {
    if (templatePreviewData?.GetTemplateFieldInfoByTemplate) {
      const localJSON = finalUpdatedJson?.fields;
      const localOption = localJSON?.filter(
        (item) => item.fieldName == event.target.id
      );
      if (localOption) localOption[0].value = event.target.value ?? '';
      setFinalUpdatedJson(JSON.parse(JSON.stringify(finalUpdatedJson) ?? ''));
    }
  };

  const step2Handler = (
    event: ChangeEvent<HTMLInputElement>,
    repeatingGrpName?: string
  ) => {
    if (templatePreviewData?.GetTemplateFieldInfoByTemplate) {
      const localJSON = finalUpdatedJson?.repeatingFields.filter(
        (grp) => grp.groupName == repeatingGrpName
      )[0];
      if (localJSON && event.target.id.includes('occurrences')) {
        localJSON.occurrences = parseInt(event.target.value) ?? 0;
      } else {
        const localOption = localJSON?.fields.filter(
          (item) => item.fieldName == event.target.id
        );
        if (localOption) localOption[0].value = event.target.value ?? '';
      }
      if (localJSON && localJSON.occurrences == 0) localJSON.occurrences = 1;
      setFinalUpdatedJson(JSON.parse(JSON.stringify(finalUpdatedJson) ?? ''));
    }
  };

  const templatePreviewHandler = () => {
    setIsLoading(true);
    generatePreview(
      {
        templatePreview: {
          templateFieldInfoJson: JSON.stringify(finalUpdatedJson),
          templateId: templateProps.templateId,
        },
      },
      {
        onSuccess: (data) => {
          const blobPart = Buffer.from(data?.generateTemplatePreview, 'base64');
          const mediaSource = new Blob([blobPart], {
            type: 'application/pdf',
          });
          window.open(URL.createObjectURL(mediaSource), '_blank');
          templateProps.setPreview(false);
          setIsLoading(false);
        },
        onError: () => {
          dispatch(
            addMessage({
              message: t('pages.manageReusableContent.networkError'),
              type: MessageType.Error,
              actionType: MessageActionType.None,
            })
          );
          setIsLoading(false);
        },
      }
    );
  };
  useEffect(() => {
    if (!isFetching && templatePreviewData?.GetTemplateFieldInfoByTemplate) {
      setFinalUpdatedJson(
        JSON.parse(templatePreviewData?.GetTemplateFieldInfoByTemplate)
      );
    }
  }, [isFetching, templatePreviewData?.GetTemplateFieldInfoByTemplate]);

  useEffect(() => {
    const newCompleted = completed;
    newCompleted[currentStep] = true;
    setCompleted(newCompleted);
  }, [completed, currentStep]);

  return (
    <>
      {isFetching && <Loading />}
      {!isFetching && finalUpdatedJson && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {isLoading ? (
              <Loading />
            ) : (
              <ProgressLoader
                steps={[
                  t('pages.correspondence.previewSteps.fields'),
                  t('pages.correspondence.previewSteps.repeatingFields'),
                  t('pages.correspondence.previewSteps.freeFormMulti'),
                ]}
                completed={completed}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                handleCurrentStep={(activeStep: number) => {
                  setCurrentStep(activeStep);
                }}
                handleSave={() => templatePreviewHandler()}
                isSaveAndCreateDisabled={false}
                disabled={false}
                isError={false}
                handleReset={() => {}}
                orientation={'horizontal'}
                saveBtnText="Preview"
                nextBtnText="Next Step"
                prevBtnText="Previous Step"
              >
                {currentStep === 0 && (
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      justifyContent: 'flex-start',
                      gap: '1%',
                    }}
                  >
                    {finalUpdatedJson &&
                      finalUpdatedJson.fields?.length > 0 &&
                      finalUpdatedJson.fields.map((field) => {
                        return (
                          <Box key={field.fieldName}>
                            <Typography>{field.fieldName}</Typography>
                            <Input
                              id={field.fieldName}
                              ariaLabel="systemField"
                              placeholder={field.fieldName}
                              onChange={(e) => step1Handler(e)}
                              value={
                                finalUpdatedJson?.fields.filter(
                                  (item3) => item3.fieldName == field.fieldName
                                )[0].value ?? ''
                              }
                            />
                          </Box>
                        );
                      })}
                  </Box>
                )}
                {currentStep === 1 &&
                  finalUpdatedJson &&
                  finalUpdatedJson.repeatingFields?.length > 0 &&
                  finalUpdatedJson.repeatingFields.map((repField) => {
                    return (
                      <Box sx={{ mb: 1 }} key={repField.groupName}>
                        <DataCard
                          title={repField.groupName}
                          avatar={<></>}
                          action={
                            <>
                              <Typography>
                                {t(
                                  'pages.correspondence.previewSteps.occurances'
                                )}
                              </Typography>
                              <Input
                                id={repField.groupName + 'occurrences'}
                                inputProps={{
                                  type: 'number',
                                  value:
                                    repField.occurrences <= 0
                                      ? 1
                                      : repField.occurrences,
                                }}
                                ariaLabel="occurance"
                                placeholder={t(
                                  'pages.correspondence.previewSteps.occurances'
                                )}
                                onChange={(e) =>
                                  step2Handler(e, repField.groupName)
                                }
                                value={repField.occurrences ?? 1}
                              />
                            </>
                          }
                          children={
                            <Box
                              sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                justifyContent: 'flex-start',
                                gap: '1%',
                              }}
                            >
                              {repField.fields.map((field) => {
                                return (
                                  <Box key={field.fieldName}>
                                    <Typography>{field.fieldName}</Typography>
                                    <Input
                                      id={field.fieldName}
                                      ariaLabel="repeatingField"
                                      onChange={(e) =>
                                        step2Handler(e, repField.groupName)
                                      }
                                      placeholder={field.fieldName}
                                      value={
                                        repField.fields.filter(
                                          (item3) =>
                                            item3.fieldName == field.fieldName
                                        )[0].value ?? ''
                                      }
                                    />
                                  </Box>
                                );
                              })}
                            </Box>
                          }
                        />
                      </Box>
                    );
                  })}
                {currentStep === 2 && (
                  <>{finalUpdatedJson && FreeFormTextChildren}</>
                )}
              </ProgressLoader>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default TemplatePreview;
