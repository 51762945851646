import { useEffect, useState } from 'react';

import { SelectChangeEvent } from '@mui/material';

interface MaskReturn {
  unMasked: string;
  maskError: string;
  disabledValue: boolean;
  handleMaskChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSelectMaskChange: (event: SelectChangeEvent<string | number>) => void;
  maskedValue: string;
  inputValue: string;
}

function useMaskInput(
  newMask: string,
  helperText: string,
  regexValue: RegExp,
  initialState = ''
): MaskReturn {
  const [inputValue, setInputValue] = useState(initialState);
  const [unMasked, setunMasked] = useState('');
  const [maskError, setMaskError] = useState<string>('');
  const [disabledValue, setDisabledValue] = useState<boolean>(false);

  function maskInput(value: string) {
    const map = new Map();
    map.set('9', /\d/);
    map.set('A', /[\da-zA-Z]/);
    map.set('S', /[a-zA-Z]/);

    const mask = String(newMask);

    const maskedValue = String(value).replace(/[^\da-zA-Z]/g, '');

    const result: string[] = [];

    let start = 0;
    mask.split('').forEach((char) => {
      if (maskedValue.length > start) {
        if (map.has(char)) {
          if (map.get(char).test(maskedValue.charAt(start))) {
            result.push(maskedValue.charAt(start));
            start += 1;
          }
        } else {
          result.push(char);
        }
      }
    });
    return result.join('');
  }
  const maskedValue = maskInput(inputValue);

  const maskRegex = new RegExp(regexValue).test(maskedValue);

  useEffect(() => {
    if (!maskRegex && maskedValue.length > 0) {
      setMaskError(helperText);
      setDisabledValue(true);
    } else if (maskedValue.length === 0) {
      setMaskError('');
      setDisabledValue(false);
    } else {
      setDisabledValue(false);
      setMaskError('');
    }
  }, [maskedValue, maskRegex, helperText]);

  useEffect(() => {
    setInputValue(initialState);
  }, [initialState]);

  const inputRegex = new RegExp(regexValue).test(inputValue);

  useEffect(() => {
    if (!inputRegex && inputValue.length >= 0) {
      setMaskError(helperText);
      setDisabledValue(true);
    } else {
      setDisabledValue(false);
      setMaskError('');
    }
  }, [inputValue, inputRegex, helperText]);

  return {
    maskedValue,
    unMasked,
    inputValue,
    maskError,
    disabledValue,
    handleMaskChange(event: React.ChangeEvent<HTMLInputElement>) {
      const { value } = event.target;
      if (newMask.length > 0) {
        event.target.value = maskInput(value);
        setunMasked(value.replace(/\D/g, ''));
        setInputValue(event.target.value);
      } else {
        setInputValue(value);
      }
    },
    handleSelectMaskChange(event: SelectChangeEvent<string | number>) {
      const { value } = event.target;
      if (typeof value === 'string') {
        setInputValue(value);
      }
    },
  };
}
export default useMaskInput;
