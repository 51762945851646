import { useEffect, useState } from 'react';

import {
  PaymentDetailsResponse,
  useGetPaymentsByAccountIdQuery,
} from 'generated/graphql';
import { useParams } from 'react-router-dom';
import PaymentList from 'components/PaymentList';
import { useTranslation } from 'react-i18next';

function AccountPayments(): JSX.Element {
  const { t } = useTranslation();
  const { accountId } = useParams<{ accountId: string }>();
  const [accountPayments, setAccountPayments] = useState<
    PaymentDetailsResponse[]
  >([]);

  const { data: accountPaymentsResponse } = useGetPaymentsByAccountIdQuery({
    accountId: accountId as string,
  });

  useEffect(() => {
    if (accountPaymentsResponse) {
      setAccountPayments(accountPaymentsResponse.GetPaymentsByAccountId);
    }
  }, [accountPaymentsResponse]);

  return (
    <PaymentList
      payments={accountPayments}
      currentPageName={t('pages.accountSummary.title')}
    />
  );
}

export default AccountPayments;
