import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Alert, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { setHeader } from 'redux/contentSlice';
import { useAppDispatch } from 'redux/hooks';
import {
  BaseField,
  BusinessSection,
  FormDataInput,
  FormTemplate,
  useGetAllFormLayoutsQuery,
  useGetFormBatchQuery,
  useGetFormQuery,
  useSubmitFormToBatchMutation,
  useUpdateFormBatchStatusMutation,
} from 'generated/graphql';
import { UseFormReturn } from 'react-hook-form';
import {
  Button,
  FieldValue,
  MessageType,
  RepeatingValue,
} from '@revenue-solutions-inc/revxcoreui';
import { JSONPath } from 'jsonpath-plus';
import { format } from 'date-fns';
import { addMessage } from 'redux/messageSlice';
import Loading from 'components/Loading';
import { Error } from 'types/graphqlErrors';
import Dialog from '@revenue-solutions-inc/revxcoreui/material/controls/Dialog';
import { dateFormat } from 'utils/date-util';
import MasterLayoutView from '@revenue-solutions-inc/revxcoreui/material/layoutBuilder/renderer/MasterLayoutView';
import {
  LayoutType,
  MasterLayout,
} from '@revenue-solutions-inc/revxcoreui/material/layoutBuilder/types/layouts';
import {
  generateDefaultLayout,
  parseLogix,
} from '@revenue-solutions-inc/revxcoreui/material/layoutBuilder/builder/utils/layoutUtils';
import extractMeaningfulMessage from 'utils/errorMessage';
import { getMasterLayout } from 'utils/getMasterLayout';
import { Field } from '@revenue-solutions-inc/revxcoreui/material/layoutBuilder/types/fields';
interface RepeatingGroup {
  id: string;
  repeatingValues: RepeatingValue[];
}

/**
 * This function will creating the correct structure to set in the logix schema for
 * the values (Repeating or Field).
 * @param templateField get the strcuture where we have the field in the schema
 * @param repeatingField field from the layout builder
 * @param fieldsRG array of field repeating group to handle how many values are in the repeating group
 * @returns Array of Repeating Group to set in the schema with the value correctly
 */
const fieldsValuesGenerate = (
  templateField: { value: BaseField },
  repeatingField: Field,
  fieldsRG: RepeatingGroup[]
) => {
  if (templateField.value.FieldValueType === 'DateTime') {
    repeatingField.value = format(
      new Date(repeatingField.value.toString()),
      dateFormat
    );
  } else if (
    templateField.value.FieldValueType === 'Boolean' &&
    !repeatingField.value
  ) {
    repeatingField.value = false;
  }
  const newValue: FieldValue = {
    Status: 'AsFiled',
    Value: repeatingField.value.toString(),
    CreatedDate: '',
    CreatedBy: '',
  };

  const fIndex = fieldsRG.findIndex((fv) => fv.id === repeatingField.id);
  if (fIndex < 0) {
    const objAux: RepeatingGroup = {
      id: repeatingField.id,
      repeatingValues: [
        {
          FieldValues: [newValue],
        },
      ],
    };
    fieldsRG.push(objAux);
  } else {
    fieldsRG[fIndex].repeatingValues.push({
      FieldValues: [newValue],
    });
  }
  const fieldValues: RepeatingValue[] =
    fIndex < 0
      ? [...fieldsRG[fieldsRG.length - 1].repeatingValues]
      : [...fieldsRG[fIndex].repeatingValues];

  return fieldValues;
};
function FillForm(): JSX.Element {
  const [searchParams] = useSearchParams();
  const context = searchParams.get('context');
  const batchId = searchParams.get('batchId');
  const { formId } = useParams();
  const navigate = useNavigate();
  const [errorsValidations, setErrorsValidations] = useState<string[] | []>([]);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showBatchClosedDialog, setShowBatchClosedDialog] =
    useState<boolean>(false);
  const [showBatchConfirmationDialog, setShowBatchConfirmationDialog] =
    useState<boolean>(false);
  const layoutBuilt = useRef<MasterLayout | null | undefined>(null);

  //Layout Renderer Handles
  const formRef = useRef<UseFormReturn<MasterLayout>>(null);
  const saveButtonRef = useRef<HTMLButtonElement>(null);

  const {
    data: formTemplateData,
    isLoading: isLoadingTemplate,
    refetch: refetchFormTemplate,
  } = useGetFormQuery({
    formGroupId: formId || 'undefined',
  });

  const { data: fetchLayoutData, isLoading: isLoadingLayouts } =
    useGetAllFormLayoutsQuery({
      configurationId: formId,
    });

  //Used to update a batch status to closed
  const {
    mutate: updateFormBatchStatusMutate,
    isLoading: isFormStatusUpdating,
    status: isFormStatusUpdatingStatus,
  } = useUpdateFormBatchStatusMutation();
  const { t } = useTranslation();

  //Used to submit a form to a specific batch
  const {
    mutate: submitToBatchMutate,
    isLoading: isSubmittingToBatch,
    status: submitToBatchStatus,
  } = useSubmitFormToBatchMutation({
    retry: () => false,
    onError: (fb_errors: Error[]) => {
      if (fb_errors[0].message === t('components.pagenotfound.message')) {
      } else {
        addMessage({
          type: MessageType.Error,
          message: t('components.message.networkerror'),
        });
      }
    },
  });

  //Checks to see if a batchId is present in the query string, if it is it will load the batch and do the necessary checks. This page should be 'atomic' and not rely on any state from previous pages.
  const dispatch = useAppDispatch();

  const {
    data: getBatchData,
    refetch: refetchBatchId,
    isLoading: isBatchLoading,
    fetchStatus: getBatchStatus,
  } = useGetFormBatchQuery(
    {
      getFormBatchId: batchId || '',
    },
    {
      enabled: false,
      networkMode: 'always',
      onError: (fb_errors: Error[]) => {
        if (fb_errors[0].message === t('components.pagenotfound.message')) {
        } else {
          addMessage({
            type: MessageType.Error,
            message: t('components.message.networkerror'),
          });
        }
      },
    }
  );

  //If the batch id changes we need to refetch and check if it exists, is closed, etc.
  useEffect(() => {
    refetchBatchId().then((batchDataQuery) => {
      if (batchDataQuery.data?.getFormBatch.Status === 'close') {
        setShowBatchClosedDialog(true);
      }
    });
  }, [batchId, refetchBatchId]);

  useEffect(() => {
    dispatch(
      setHeader({
        pageTitle: t('pages.forms.fillForm'),
        previousPage: t('pages.forms.formDesign'),
        route: 'manageForms',
      })
    );
  }, [dispatch, t]);

  useEffect(() => {
    if (fetchLayoutData !== undefined) {
      if (fetchLayoutData.getAllFormLayouts.length > 0 && context) {
        layoutBuilt.current = getMasterLayout(
          fetchLayoutData.getAllFormLayouts,
          context ?? undefined
        );
      } else if (
        formTemplateData?.getForm !== undefined &&
        formTemplateData?.getForm.Forms &&
        formTemplateData?.getForm.Forms.length > 0
      ) {
        layoutBuilt.current = generateDefaultLayout(
          parseLogix(formTemplateData?.getForm.Forms[0] as FormTemplate),
          LayoutType.TwoColumn
        );
      }
    }
  }, [fetchLayoutData, context, formTemplateData?.getForm]);

  //Finds the field within the business section and returns it. Modified to act off of ID instead
  const findField = (fieldId: string, businessSections: BusinessSection[]) => {
    const result = JSONPath({
      path: `$.[?(@ && @.FieldID=="${fieldId}")]`,
      json: businessSections,
      resultType: 'all',
    });
    if (result.length > 0) return result[0];
    return null;
  };

  const onCloseBatch = () => {
    formRef.current?.trigger().then(() => {
      setShowBatchConfirmationDialog(true);
    });
  };

  const onSubmitError = useCallback(
    (errors: unknown) => {
      const formErr = (errors as Error[])[0].extensions.response.body.errors;
      if (formErr && formErr?.length > 0) {
        dispatch(
          addMessage({
            type: MessageType.Error,
            message: (errors as Error[])[0].extensions.response.body
              .detail as string,
          })
        );
        setErrorsValidations(formErr.map((it) => `${it.Field}: ${it.Error}`));
        setShowDialog(true);
      } else {
        dispatch(
          addMessage({
            type: MessageType.Error,
            message: extractMeaningfulMessage(
              errors,
              t('components.message.networkerror')
            ),
          })
        );
      }
    },
    [dispatch, t]
  );
  const [submissionCount, setCount] = useState<number>(0);

  //Called when a form is successfully submitted to a particular batch
  const onBatchSubmitSuccess = useCallback(() => {
    dispatch(
      addMessage({
        type: MessageType.Success,
        message: t('pages.manageFormsProcessing.filled'),
      })
    );

    //Reset the form and fetch a new template to be filled
    formRef.current?.trigger().then(() => {
      refetchBatchId();
      refetchFormTemplate();
      setCount(submissionCount + 1);
    });
  }, [dispatch, refetchBatchId, refetchFormTemplate, submissionCount, t]);

  useEffect(() => {
    formRef.current?.reset();
  }, [submissionCount, formRef.current?.reset]);

  //Submits the form while handling any batch state as necessary
  const onSubmit = useCallback(async () => {
    const formValues = formRef.current?.getValues();

    const valid = await formRef.current?.trigger();

    if (valid) {
      const businessSections: BusinessSection[] = [
        ...(formTemplateData?.getForm.Forms?.[0].BusinessSections ?? []),
      ];

      const fieldsRG: RepeatingGroup[] = [];
      formValues?.sections.forEach((section) => {
        section.layouts.forEach((layout) => {
          //handle repeating groups (if repeating group is an array; seems to get set to an empty object in some cases which *typescript shouldn't let happen*)
          if (typeof layout.repeatingFields?.forEach === 'function') {
            layout.repeatingFields?.forEach((repeatingGroup) => {
              repeatingGroup.fields?.forEach((repeatingField: Field) => {
                const templateField = findField(
                  repeatingField.id,
                  businessSections
                );
                const fieldValues: RepeatingValue[] = fieldsValuesGenerate(
                  templateField,
                  repeatingField,
                  fieldsRG
                );
                templateField.parent.RepeatingValues = fieldValues;
              });
            });
          }

          layout.fields?.forEach((field) => {
            const templateField = findField(
              field?.id || 'UHOH',
              businessSections
            );

            if (templateField) {
              const fieldValuesF: FieldValue[] = [
                ...templateField.parent.FieldValues,
              ];
              const formValue = field?.value || false;
              if (
                formValue ||
                templateField.value.FieldValueType === 'Boolean'
              ) {
                let value = formValue;
                if (templateField.value.FieldValueType === 'DateTime') {
                  value = format(new Date(formValue as string), dateFormat);
                } else if (
                  templateField.value.FieldValueType === 'Boolean' &&
                  !formValue
                ) {
                  value = false;
                }
                const newValue: FieldValue = {
                  Status: 'AsFiled',
                  Value: value.toString(),
                  CreatedDate: '',
                  CreatedBy: '',
                };
                //TODO: check if we have one already then updated
                const findIndex = fieldValuesF.findIndex(
                  (fV) => fV.CreatedBy === ''
                );
                if (findIndex >= 0) {
                  fieldValuesF[findIndex] = newValue;
                } else {
                  fieldValuesF.push(newValue);
                }
                templateField.parent.FieldValues = fieldValuesF;
              }
            }
          });
        });
      });

      if (
        formTemplateData?.getForm !== undefined &&
        formTemplateData.getForm.Forms != undefined
      ) {
        formTemplateData.getForm.Forms[0].BusinessSections = businessSections;
      }

      const formData: FormDataInput = {
        FormGroupName: formTemplateData?.getForm?.FormGroupName,
        FormGroupId: formId,
        Forms: formTemplateData?.getForm?.Forms?.map((it) => ({
          ...it,
          ReceivedDate: searchParams.get('received') ?? '',
          BatchId: searchParams.get('batchId') ?? '',
          Source: 'In- House Captured',
        })),
        Status: formTemplateData?.getForm?.Status?.toString(),
        TenantName: formTemplateData?.getForm?.TenantName,
        CreatedBy: formTemplateData?.getForm?.CreatedBy,
        CreatedDate: formTemplateData?.getForm?.CreatedDate,
        UpdatedBy: formTemplateData?.getForm?.UpdatedBy,
        UpdatedDate: formTemplateData?.getForm?.UpdatedDate,
      };

      //batchId will be there always, either autogenerated or passed as url param
      if (batchId) {
        submitToBatchMutate(
          { batchId, formData },
          {
            onSuccess: onBatchSubmitSuccess,
            onError: onSubmitError,
          }
        );
      }
    }
  }, [
    batchId,
    formTemplateData,
    onBatchSubmitSuccess,
    onSubmitError,
    searchParams,
    submitToBatchMutate,
    formId,
  ]);

  //Closes the batch, no more forms will be submitted. If there's a valid form it will save it then submit the batch.
  const closeBatch = async () => {
    const hasErrors =
      Object.keys(formRef.current?.formState.errors || {}).length > 0;
    //If it does not have errors submit form
    if (!hasErrors && onSubmit) {
      onSubmit().then(() => {
        updateFormBatchStatusMutate(
          {
            batchInput: { status: 'close', id: getBatchData?.getFormBatch.Id },
          },
          {
            onSuccess() {
              navigate('/property/forms');
            },
            onError() {
              dispatch(
                addMessage({
                  type: MessageType.Error,
                  message: t('pages.manageFormsProcessing.failedToCloseBatch'),
                })
              );
            },
          }
        );
      });
    } else {
      updateFormBatchStatusMutate(
        {
          batchInput: { status: 'close', id: getBatchData?.getFormBatch.Id },
        },
        {
          onSuccess() {
            navigate('/property/forms');
          },
          onError() {
            dispatch(
              addMessage({
                type: MessageType.Error,
                message: t('pages.manageFormsProcessing.failedToCloseBatch'),
              })
            );
          },
        }
      );
    }
  };

  const handleClose = () => {
    setShowDialog(false);
  };

  const errorDialog = (
    <Grid container spacing={1}>
      {errorsValidations.map((error: string, idx: number) => (
        <Grid item xs={12} key={idx}>
          <Alert severity="error" sx={{ paddingY: '2px' }}>
            {error}
          </Alert>
        </Grid>
      ))}
    </Grid>
  );

  const batchClosedDialog = (
    <Alert severity="error" sx={{ paddingY: '2px' }}>
      {t('pages.manageFormsProcessing.batchAlreadyClosed')}
    </Alert>
  );

  const closeBatchDialog = (
    <Typography>
      {Object.keys(formRef.current?.formState.errors || {}).length > 0
        ? t('pages.manageFormsProcessing.formErrorDialog')
        : t('pages.manageFormsProcessing.formCloseConfirmation')}
    </Typography>
  );

  return (
    <>
      {isLoadingTemplate || isLoadingLayouts ? (
        <Loading />
      ) : (
        <Grid container spacing={2} alignItems="center">
          <Grid container item>
            <Grid item xs={12}>
              <Typography variant="h1">
                {formTemplateData?.getForm.Forms?.[0].FormName}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h2">
                {batchId}-
                {getBatchData?.getFormBatch.NextSequenceNumber?.toString().padStart(
                  5,
                  '0'
                ) || '00001'}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {layoutBuilt.current ? (
              <MasterLayoutView
                masterLayout={layoutBuilt.current}
                saveButtonRef={saveButtonRef}
                handleSave={() => {}}
                ref={formRef}
              />
            ) : undefined}
            <Grid item container xs={12} mb={1}>
              <Button
                sx={{
                  marginTop: '20px',
                  marginRight: '2px',
                }}
                onClick={onSubmit}
                disabled={
                  getBatchData?.getFormBatch.Status === 'close' ||
                  Object.keys(formRef.current?.formState.errors || {}).length >
                    0
                }
                loading={
                  isBatchLoading || isSubmittingToBatch || isFormStatusUpdating
                }
                id="btn-save-fill-form"
              >
                {t('pages.manageFormsProcessing.save')}
              </Button>
              <Button
                sx={{
                  marginTop: '20px',
                }}
                type="secondary"
                variant="outlined"
                onClick={() => {
                  navigate(-1);
                }}
                disabled={
                  isBatchLoading || isSubmittingToBatch || isFormStatusUpdating
                }
                id={'btn-cancel-edit'}
              >
                {t('pages.manageFormsProcessing.cancel')}
              </Button>
              {(getBatchData?.getFormBatch.Id || batchId) && (
                <Button
                  id="btn-close-batch"
                  sx={{ marginTop: '20px', marginLeft: '25px' }}
                  onClick={onCloseBatch}
                  disabled={
                    getBatchData?.getFormBatch.Status === 'close' ||
                    getBatchData?.getFormBatch.NextSequenceNumber === 1 ||
                    !getBatchData?.getFormBatch.Id
                  }
                  loading={
                    (isBatchLoading &&
                      getBatchStatus !== 'idle' &&
                      getBatchStatus !== 'paused') ||
                    (isSubmittingToBatch &&
                      submitToBatchStatus !== 'loading') ||
                    (isFormStatusUpdating &&
                      isFormStatusUpdatingStatus !== 'loading')
                  }
                >
                  {t('pages.manageFormsProcessing.closeBatch')}
                </Button>
              )}
            </Grid>
          </Grid>
          <Dialog
            id="errors-dialog"
            open={showDialog}
            title=""
            type="passive"
            maxWidth="sm"
            children={errorDialog}
            handleCancelClick={handleClose}
            handleClose={handleClose}
          />

          <Dialog
            id="batch-closed-dialog"
            open={showBatchClosedDialog}
            title={t('pages.manageFormsProcessing.thisBatchIsClosed')}
            type="danger"
            maxWidth="sm"
            dangerModalDangerButtonText={t(
              'pages.manageFormsProcessing.understand'
            )}
            children={batchClosedDialog}
            handleCancelClick={() => history.back()}
            handleDangerClick={() => setShowBatchClosedDialog(false)}
          />

          <Dialog
            id="close-batch-confirmation"
            open={showBatchConfirmationDialog}
            title={t('pages.manageFormsProcessing.closeBatch')}
            type="danger"
            maxWidth="sm"
            dangerModalDangerButtonText={
              Object.keys(formRef?.current?.formState.errors || {}).length === 0
                ? t('pages.manageFormsProcessing.submitAndCloseBatch')
                : t('pages.manageFormsProcessing.closeBatch')
            }
            children={closeBatchDialog}
            handleCancelClick={() => setShowBatchConfirmationDialog(false)}
            handleDangerClick={closeBatch}
            handleClose={() => setShowBatchConfirmationDialog(false)}
          />
        </Grid>
      )}
    </>
  );
}

export default FillForm;
