import { useState, useMemo, createContext, ReactNode, useContext } from 'react';

import { ConfigurationResponse } from 'generated/graphql';

interface Props {
  children: ReactNode;
}

interface ManageConfigToolsCtx {
  selectedRecord: ConfigurationResponse | undefined;
  setSelectedRecord: (
    selectedRecord: ConfigurationResponse | undefined
  ) => void;
}

const initialContext: ManageConfigToolsCtx = {
  selectedRecord: undefined,
  setSelectedRecord: () => {},
};

export const ManageConfigToolsContext = createContext(initialContext);

export const useManageConfigToolsContext = () =>
  useContext(ManageConfigToolsContext);

export default function ManageConfigToolsProvider({ children }: Props) {
  const [selectedRecord, setSelectedRecord] = useState<
    undefined | ConfigurationResponse
  >(undefined);

  const ctx = useMemo(() => {
    return {
      selectedRecord,
      setSelectedRecord,
    };
  }, [selectedRecord]);

  return (
    <ManageConfigToolsContext.Provider value={ctx}>
      {children}
    </ManageConfigToolsContext.Provider>
  );
}
