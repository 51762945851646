import { useContext } from 'react';

import { Grid, Typography } from '@mui/material';
import EntityManagementContext from 'components/contexts/EntityManagement';
import { getLabelAndValueByItem } from 'components/entityManagement/common/confirmationUtils';
import CardAvatar from 'components/entityManagement/common/CardAvatar';
import {
  EntityTypes,
  findSection,
  preprocessNames,
  sectionNames,
} from 'components/entityManagement/common/entityUtils';
import ExtendedAttributesCard from 'components/entityManagement/common/ExtendedAttributes/ExtendedAttributesCard';
import DataCard from 'components/DataCard';
import { EntityForm } from 'types/entities';
import { ExtendedAttributeValues, Section } from 'types/forms';
import { v4 as uuid } from 'uuid';

interface Props {
  data: EntityForm;
  extendedData?: ExtendedAttributeValues[];
}

function ConfirmationEntity({ data, extendedData }: Props): JSX.Element {
  const {
    names,
    identifiers,
    addresses,
    emailAddresses,
    phoneNumbers,
    others,
  } = data;
  const ctx = useContext(EntityManagementContext);

  const displaySection = (
    sectionType: Section,
    sectionData: EntityTypes | undefined
  ): JSX.Element[] => {
    const sectionElements: JSX.Element[] = [];
    for (const attr in sectionData) {
      const itemInfo = getLabelAndValueByItem(
        sectionType,
        attr,
        ctx.idFormats,
        ctx.selectedCommenceDate,
        sectionData
      );
      if (itemInfo[0] !== '') {
        sectionElements.push(
          <Grid
            key={sectionType.sectionIdentifier + '-' + attr}
            sx={{ display: 'flex', flexDirection: 'column' }}
            item
            lg={4}
            md={6}
            sm={6}
          >
            <Typography variant="h5" id={uuid() + `-label`} component="label">
              {itemInfo[0]}
            </Typography>
            <Typography
              variant="h3"
              id={uuid() + `-value`}
              sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
              {itemInfo[1]}
            </Typography>
          </Grid>
        );
      }
    }
    return sectionElements;
  };

  const displayCards = (type: string): JSX.Element[] => {
    let groups;
    const fields = findSection(type);
    switch (type) {
      case 'names':
        preprocessNames(names);
        groups = names;
        break;
      case 'identifiers':
        groups = identifiers;
        break;
      case 'addresses':
        groups = addresses;
        break;
      case 'emailAddresses':
        groups = emailAddresses;
        break;
      case 'phoneNumbers':
        groups = phoneNumbers;
        break;
      case 'others':
        groups = others;
        break;
    }
    const cards: JSX.Element[] = [];
    if (!groups || !fields) return cards;
    groups.forEach((group, index) => {
      cards.push(
        <Grid
          key={`${fields.sectionIdentifier}-${index}-grid`}
          sx={{ display: 'flex' }}
          item
          md={4}
          sm={6}
          order={{ xs: 2, sm: 1, md: -1 }}
        >
          <DataCard
            title={fields.sectionTitle}
            avatar={<CardAvatar sectionName={fields.sectionIdentifier} />}
            children={
              <Grid container spacing={2}>
                {displaySection(fields, group)}
              </Grid>
            }
          />
        </Grid>
      );
    });
    return cards;
  };

  return (
    <Grid container spacing={3}>
      {sectionNames.map((sectionName) => {
        return displayCards(sectionName);
      })}
      <ExtendedAttributesCard extendedData={extendedData ?? []} />
    </Grid>
  );
}

export default ConfirmationEntity;
