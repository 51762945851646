import { useCallback, useEffect, useState } from 'react';
import { Box, Grid, SelectChangeEvent, Theme } from '@mui/material';
import DateCell from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/TableCells/DateCell';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import Select, {
  SelectType,
} from '@revenue-solutions-inc/revxcoreui/material/controls/Select/Select';
import { ColumnDef } from '@tanstack/react-table';
import Loading from 'components/Loading';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { setHeader } from 'redux/contentSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  useGetChannelsQuery,
  useGetModulesByUserQuery,
  ModuleResponse,
  CmGetChannels,
} from 'generated/graphql';

function ManageChannel(): JSX.Element {
  const [platform, setPlatform] = useState<string>('');
  const module = useAppSelector((state) => state.user.module);
  const [allModules, setAllModules] = useState<string>('');
  const allModulesLabel = 'All Modules';

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { data: modulesData } = useGetModulesByUserQuery();

  const moduleValues = useCallback((): SelectType[] => {
    const values: SelectType[] = [];
    if (modulesData) {
      if (modulesData.ModulesByUser.results.length > 1) {
        values.push({
          key: t('pages.manageChannel.allModules'),
          desc: t('pages.manageChannel.allModules'),
        });
      }
      modulesData.ModulesByUser.results.forEach((item: ModuleResponse) => {
        values.push({ key: item.name, desc: item.name });
      });
    }
    return values;
  }, [modulesData, t]);

  useEffect(() => {
    if (modulesData) {
      const modules = moduleValues();
      setPlatform(modules[0]?.desc);
      if (modules[0]?.desc === allModulesLabel) {
        const values = modulesData.ModulesByUser.results.map(
          (item: ModuleResponse) => item.name
        );
        setAllModules(values.toString());
      } else {
        setAllModules(modules[0]?.desc);
      }
    }
  }, [modulesData, t, moduleValues, allModulesLabel]);

  const handleChange = (event: SelectChangeEvent<string | number>) => {
    setPlatform((event.target as HTMLSelectElement).value);
    if (event.target.value !== allModulesLabel && modulesData) {
      setAllModules(String(event.target.value));
    } else if (modulesData) {
      const values = modulesData.ModulesByUser.results.map(
        (item: ModuleResponse) => {
          return item.name;
        }
      );
      setAllModules(values.toString());
    }
  };

  const { data, isLoading } = useGetChannelsQuery(
    {
      module:
        allModules !== '' && platform === t('pages.manageChannel.allModules')
          ? allModules
          : platform,
      fetchArchived: false,
    },
    {
      enabled: !!platform,
    }
  );

  const Column: ColumnDef<CmGetChannels>[] = [
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.manageChannel.displayName')} />
      ),
      id: 'display-name',
      cell: ({ row }) => (
        <Box
          sx={(theme: Theme) => {
            return {
              a: {
                color: theme.palette.linkBlue.main,
              },
            };
          }}
        >
          <Link
            to={{
              pathname: `/${module}/channelDetails/${row.original.Id}/${row.original.ChannelName}/${row.original.Module}`,
            }}
          >
            {row.original.DisplayName}
          </Link>
        </Box>
      ),
      accessorKey: 'DisplayName',
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.manageChannel.module')} />
      ),
      id: 'channel-module',
      accessorKey: 'Module',
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.manageChannel.version')} />
      ),
      id: 'channel-version',
      accessorKey: 'Version',
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.manageChannel.status')} />
      ),
      id: 'channel-status',
      accessorKey: 'Status',
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.manageChannel.createdBy')} />
      ),
      id: 'channel-create-by',
      accessorKey: 'CreatedBy',
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.manageChannel.created')} />
      ),
      id: 'channel-create-date',
      accessorKey: 'CreatedDttm',
      cell: ({ row }) => {
        return <DateCell dateString={row.original.CreatedDttm} />;
      },
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.manageChannel.updatedBy')} />
      ),
      id: 'channel-update-by',
      accessorKey: 'UpdatedBy',
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.manageChannel.updatedDttm')} />
      ),
      id: 'channel-update-dttm',
      accessorKey: 'UpdatedDttm',
      cell: ({ row }) => {
        return <DateCell dateString={row.original.UpdatedDttm} />;
      },
    },
  ];

  useEffect(() => {
    dispatch(setHeader({ pageTitle: t('pages.manageChannel.title') }));
  }, [dispatch, t]);

  if (!isLoading && data) {
    return (
      <>
        <Grid container>
          <Grid item xs={12}>
            <DefaultDataTableNext
              columns={Column as ColumnDef<Record<string, unknown>, unknown>[]}
              data={data.getChannels}
              customHeader={
                <Box sx={{ width: '100%' }}>
                  <Select
                    options={moduleValues()}
                    value={platform}
                    id="get-channels-select-3449399"
                    label={t('pages.manageChannel.sortChannels')}
                    onChange={handleChange}
                  />
                </Box>
              }
            />
          </Grid>
        </Grid>
      </>
    );
  }

  if (isLoading) {
    return <Loading />;
  }
  return <></>;
}
export default ManageChannel;
