import { useState } from 'react';

import { Box, Grid, SelectChangeEvent, Typography } from '@mui/material';
import { Card, CardContent } from '@revenue-solutions-inc/revxcoreui';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import { ColumnDef } from '@tanstack/react-table';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import DataDisplay from '@revenue-solutions-inc/revxcoreui/material/controls/DataDisplay';
import Select from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import { SelectType } from '@revenue-solutions-inc/revxcoreui/material/controls/Select/Select';
import { useTranslation } from 'react-i18next';
import {
  exemptionDefault,
  TaxExemption,
  TaxRate,
  taxRateDefault,
} from 'types/taxingAuthority/entities';

import TaxingAuthorityPeriod from '../TaxingAuthorityPeriod';

function TaxingAuthorityView(): JSX.Element {
  const { t } = useTranslation();
  const [showAddYearView, setShowAddYearView] = useState<boolean>(false);
  const [taxYear, setTaxYear] = useState<string | undefined>();
  const [selectedTaxYear, setSelectedTaxYear] = useState<string>();
  const [taxYearsSelect] = useState<SelectType[]>([]);
  const [taxRates, setTaxRates] = useState<TaxRate[]>([taxRateDefault]);
  const [taxExemptions, setTaxExemptions] = useState<TaxExemption[]>([
    exemptionDefault,
  ]);

  const [taxPeriodValid, setTaxPeriodValid] = useState<boolean>(false);

  const taxRateColumns: ColumnDef<TaxRate>[] = [
    {
      id: 'taxingAuthorityRateType',
      accessorKey: 'taxingAuthorityRateType',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.manageTaxingAuthority.taxType')}
        />
      ),
    },
    {
      id: 'rate',
      accessorKey: 'rate',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.manageTaxingAuthority.rate')}
        />
      ),
      cell: ({ getValue }) => {
        return `${getValue()}%`;
      },
    },
  ];

  const exemptionColumns: ColumnDef<TaxExemption>[] = [
    {
      id: 'taxingAuthorityRateType',
      accessorKey: 'taxingAuthorityRateType',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.manageTaxingAuthority.taxType')}
        />
      ),
      cell: ({ getValue }) => {
        return getValue() ?? '-';
      },
    },
    {
      id: 'taxingAuthorityExemptionType',
      accessorKey: 'taxingAuthorityExemptionType',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.manageTaxingAuthority.exemptionType')}
        />
      ),
    },
    {
      id: 'amount',
      accessorKey: 'amount',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.manageTaxingAuthority.amount')}
        />
      ),
      cell: ({ getValue }) => {
        return getValue() ?? '-';
      },
    },
    {
      id: 'maximumAmount',
      accessorKey: 'maximumAmount',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.manageTaxingAuthority.maxAmount')}
        />
      ),
      cell: ({ getValue }) => {
        return getValue() ?? '-';
      },
    },
    {
      id: 'percentage',
      accessorKey: 'percentage',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.manageTaxingAuthority.percentage')}
        />
      ),
      cell: ({ getValue }) => {
        return getValue() ? `${getValue()}%` : '-';
      },
    },
  ];

  const save = () => {
    const newExemptions = [...taxExemptions];
    const newTaxRates = [...taxRates];

    newExemptions.forEach((item) => {
      item.taxYear = taxYear ?? '';
      if (item.amount == '') {
        item.amount = null;
      }
      if (item.percentage === '') {
        item.percentage = null;
      }
      if (item.maximumAmount === '') {
        item.maximumAmount = null;
      }
      if (item.maximumPercentage === '') {
        item.maximumPercentage = null;
      }
    });

    newTaxRates.forEach((item) => {
      item.taxYear = taxYear ?? '';
    });

    //TODO: need to confirm the specifics of calendarTypeId with service team
  };

  {
    return (
      <>
        <Grid container>
          {!showAddYearView && (
            <Box mt={2}>
              <Grid container alignItems="flex-end">
                <Grid item xs={5}>
                  <Select
                    options={taxYearsSelect}
                    value={selectedTaxYear ? selectedTaxYear : ''}
                    id="tax-select-year-type"
                    label={t('pages.manageTaxingAuthority.taxYear')}
                    onChange={(event: SelectChangeEvent<string | number>) => {
                      setSelectedTaxYear(
                        (event.target as HTMLSelectElement).value
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Button
                    id="addYear"
                    onClick={() => {
                      setShowAddYearView(true);
                    }}
                  >
                    {t('pages.manageTaxingAuthority.addYear')}
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12} mt={3}>
                <Card>
                  <CardContent>
                    <Grid item xs={12} mt={1}>
                      <Typography variant="h2">
                        {t('pages.manageTaxingAuthority.taxRates')}
                      </Typography>
                      <Grid container mt={1}>
                        <Grid item xs={12}>
                          <DefaultDataTableNext
                            columns={
                              taxRateColumns as ColumnDef<
                                Record<string, unknown>
                              >[]
                            }
                            data={[]}
                            enableGlobalFilter={false}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} mt={3}>
                <Card>
                  <CardContent>
                    <Grid item xs={12} mt={1}>
                      <Typography variant="h2">
                        {t('pages.manageTaxingAuthority.exemptions')}
                      </Typography>
                      <Grid container mt={1}>
                        <Grid item xs={12}>
                          <DefaultDataTableNext
                            columns={
                              exemptionColumns as ColumnDef<
                                Record<string, unknown>
                              >[]
                            }
                            data={[]}
                            enableGlobalFilter={false}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} mt={3}>
                <Card>
                  <CardContent>
                    <Grid item xs={12} mt={1}>
                      <Typography variant="h2">
                        {t('pages.manageTaxingAuthority.details')}
                      </Typography>
                      <Grid container mt={1}>
                        <Grid item xs={6}>
                          <DataDisplay
                            id="address"
                            label={t('pages.manageTaxingAuthority.address')}
                            data={''}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <DataDisplay
                            id="email"
                            label={t('pages.manageTaxingAuthority.email')}
                            data={''}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <DataDisplay
                            id="phone"
                            label={t('pages.manageTaxingAuthority.phone')}
                            data={''}
                          />
                        </Grid>
                        <Grid item xs={3} mt={2}>
                          <DataDisplay
                            id="fiscalBeginDate"
                            label={t(
                              'pages.manageTaxingAuthority.fiscalBeginDate'
                            )}
                            data={''}
                          />
                        </Grid>
                        <Grid item xs={3} mt={2}>
                          <DataDisplay
                            id="fiscalEndDate"
                            label={t(
                              'pages.manageTaxingAuthority.fiscalEndDate'
                            )}
                            data={'-'}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Box>
          )}
          {showAddYearView && (
            <>
              <Box mt={2}>
                <Card>
                  <CardContent>
                    <TaxingAuthorityPeriod
                      setIsPeriodValid={setTaxPeriodValid}
                      taxRates={taxRates}
                      taxExemptions={taxExemptions}
                      setTaxRates={setTaxRates}
                      setTaxExemptions={setTaxExemptions}
                      taxYear={taxYear}
                      setTaxYear={setTaxYear}
                    />
                    <Grid container mt={1} spacing={2}>
                      <Grid item>
                        <Button
                          id="save"
                          onClick={save}
                          disabled={!taxPeriodValid}
                        >
                          {t('components.button.save')}
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          id="cancelBtn"
                          variant="text"
                          type="secondary"
                          onClick={() => {
                            setShowAddYearView(false);
                          }}
                        >
                          {t('components.button.cancel')}
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
            </>
          )}
        </Grid>
      </>
    );
  }
}

export default TaxingAuthorityView;
