import { forwardRef } from 'react';

import { AuthenticatedTemplate } from '@azure/msal-react';

export interface AuthenticatedComponentProps {
  children: JSX.Element;
}

// TODO: This isnt exactly right. This is a situation where a material-ui component
// uses a forward ref to pass something from the menu to the menu-item.
// We have to accomplish this as well but the AuthenticatedTemplate from msal doesnt support it
// we may need to crete our own version of msal authenticated template that does pass along a forward ref to children
const AuthenticatedComponent = forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ children }: AuthenticatedComponentProps, _ref) => {
    return <AuthenticatedTemplate>{children}</AuthenticatedTemplate>;
  }
);

export default AuthenticatedComponent;
