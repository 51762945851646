import { useEffect, useState, useCallback } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import Select, {
  SelectType,
} from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'redux/hooks';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  BankAccount,
  EditBankAccountInput,
  useEditBankAccountMutation,
  useGetBankAccountTypeQuery,
} from 'generated/graphql';
import extractMeaningfulMessage from 'utils/errorMessage';
import { addMessage } from 'redux/messageSlice';
import {
  MessageType,
  MessageActionType,
  Input,
  DatePicker,
} from '@revenue-solutions-inc/revxcoreui';
import { isCeaseDateValid } from 'components/entityManagement/common/entityManagementUtils';
import Loading from 'components/Loading';
import { toDate } from 'utils/date-util';
import RadioGroup from 'components/RadioGroup';
import { radioOptions } from 'components/entityManagement/common/defaults/entity';
import { fields } from './fields';

type EditBankAccountProps = {
  bankAccountData: BankAccount;
  selectedBankAccountId: string;
  onCloseCallback?: () => void;
  refetch: () => void;
  bankaccountDetailRefetch: () => void;
};

function EditBankAccount({
  bankAccountData,
  selectedBankAccountId,
  onCloseCallback = () => {},
  refetch,
  bankaccountDetailRefetch,
}: EditBankAccountProps): JSX.Element {
  const { data } = useGetBankAccountTypeQuery();
  const [types, setTypes] = useState<SelectType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const { mutate } = useEditBankAccountMutation({});
  const dispatch = useAppDispatch();
  const methods = useForm({
    mode: 'all',
    defaultValues: {
      ...bankAccountData,
      accountType: bankAccountData.bankAccountType,
      isRelatedToActiveWorkItem: bankAccountData.isRelatedToActiveWorkItem
        ? 'true'
        : 'false',
      allowForEFT: bankAccountData.allowForEFT ? 'true' : 'false',
      allowForRefund: bankAccountData.allowForRefund ? 'true' : 'false',
    },
  });
  const { control, getValues, trigger } = methods;

  useEffect(() => {
    const options: SelectType[] = [];
    data?.GetBankAccountType.forEach((bankAccountType) => {
      const optionType: SelectType = {
        key: bankAccountType.code,
        desc: bankAccountType.description,
      };
      options.push(optionType);
    });
    setTypes(options);
  }, [data]);

  const checkCeaseDate = useCallback((): boolean => {
    let isValid = true;
    const commenceDate = getValues('commenceDate');
    const ceaseDate = getValues('ceaseDate');
    if (ceaseDate) {
      if (
        commenceDate &&
        !isCeaseDateValid(commenceDate, ceaseDate, commenceDate)
      ) {
        isValid = false;
        dispatch(
          addMessage({
            type: MessageType.Error,
            message: t('pages.bankAccount.message.ceaseDateError'),
          })
        );
      }
    }
    return isValid;
  }, [dispatch, getValues, t]);

  const handleSaveBankAccount = async () => {
    if (!checkCeaseDate()) {
      return;
    }
    setIsLoading(true);
    await trigger()
      .then(() => {
        const payload: EditBankAccountInput = {
          bankAccountId: selectedBankAccountId,
          bankAccountType: getValues('accountType'),
          routingNumber: getValues('routingNumber'),
          financialInstitution: getValues('financialInstitution'),
          bankAccountNumber: getValues('bankAccountNumber'),
          ownership: getValues('ownership'),
          commenceDate: getValues('commenceDate'),
          ceaseDate:
            getValues('ceaseDate') === '' ? null : getValues('ceaseDate'),
          isRelatedToActiveWorkItem:
            Boolean(getValues('isRelatedToActiveWorkItem')) ?? false,
          allowForRefund: Boolean(getValues('allowForRefund')),
          allowForEFT: Boolean(getValues('allowForEFT')),
          reason: getValues('reason'),
        };
        mutate(
          {
            bankAccount: payload,
            bankAccountId: selectedBankAccountId,
          },
          {
            onSuccess: () => {
              dispatch(
                addMessage({
                  message: t(
                    'pages.bankAccount.message.editBankAccountSuccess'
                  ),
                  type: MessageType.Success,
                  actionType: MessageActionType.None,
                })
              );
              setIsLoading(false);
              refetch();
              bankaccountDetailRefetch();
              onCloseCallback();
            },
            onError: (error) => {
              setIsLoading(false);
              const message = extractMeaningfulMessage(
                error,
                t('pages.bankAccount.message.editBankAccountError')
              );
              dispatch(
                addMessage({
                  type: MessageType.Error,
                  message: message,
                })
              );
            },
          }
        );
      })
      .catch(() => {
        dispatch(
          addMessage({
            type: MessageType.Error,
            message: t('pages.bankAccount.message.editBankAccountError'),
          })
        );
      });
  };

  function buildRequiredError(fieldName: string) {
    return fieldName + ' ' + `${t('pages.bankAccount.message.required')}`;
  }

  const getDateValue = (value: string | Date) => {
    if (value && typeof value === 'string') {
      return toDate(`${value}`);
    }
    return value as Date;
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSaveBankAccount)}>
        <Typography variant="h2" style={{ marginBottom: 20 }}>
          {t('pages.bankAccount.addBankAccount.editAccount')}
        </Typography>
        <Grid container spacing={3}>
          {fields.map((field) => (
            <Grid item xs={12} sm={6} md={3} key={field.name}>
              <Controller
                name={field.name}
                control={control}
                rules={
                  field.type === 'select'
                    ? {
                        required: field.required ?? false,
                      }
                    : {
                        required: field.requiredRule
                          ? buildRequiredError(t(field.requiredRule))
                          : false,
                        pattern: field.pattern,
                      }
                }
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  switch (field.type) {
                    case 'select':
                      return (
                        <Select
                          autowidth={false}
                          data-tesId={field.name}
                          id={field.name}
                          label={t(field.label)}
                          onChange={onChange}
                          options={types ?? []}
                          required
                          value={`${value}` ?? ''}
                          error={
                            error ? buildRequiredError(t(field.label)) : ''
                          }
                        />
                      );
                    case 'date':
                      return (
                        <DatePicker
                          id={field.name}
                          value={value ? getDateValue(value) : null}
                          handleChange={onChange}
                          label={t(field.label)}
                          requiredErrorMessage={error?.message}
                          fullWidth={true}
                          required={field.required}
                        />
                      );
                    case 'radiogroup':
                      return (
                        <RadioGroup
                          id={field.name}
                          label={t(field.label)}
                          fieldIdentifier={field.fieldIdentifier ?? ''}
                          value={value}
                          onChange={onChange}
                          radioOptions={radioOptions}
                          isRequired={field.required}
                          error={error?.message}
                        />
                      );
                    default:
                      return (
                        <Input
                          id={field.name}
                          value={value ?? ''}
                          onChange={onChange}
                          required={true}
                          label={t(field.label)}
                          error={Boolean(error)}
                          helperText={error?.message}
                          sx={{
                            width: '100%',
                          }}
                          disabled={
                            field.name === 'routingNumber' ||
                            field.name === 'bankAccountNumber'
                          }
                        />
                      );
                  }
                }}
              />
            </Grid>
          ))}
        </Grid>
        <Box
          sx={{
            justifyContent: 'flex-end',
            display: 'flex',
          }}
        >
          <Button
            id={'edit-bank-account-button'}
            onClick={methods.handleSubmit(handleSaveBankAccount)}
          >
            {t('pages.workflow.update.title')}
            {isLoading && <Loading />}
          </Button>
        </Box>
      </form>
    </FormProvider>
  );
}

export default EditBankAccount;
