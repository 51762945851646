/* eslint-disable @typescript-eslint/no-explicit-any */
import { SelectType } from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import {
  useAdvancedSearchAccountMutation,
  useAdvancedSearchEntityMutation,
  useFindBatchByAllQuery,
  useFindDepositByAllQuery,
  useAdvancedSearchAssetMutation,
  useGetFindByJsonParameterQuery,
  useAdvancedSearchPaymentsQuery,
} from 'generated/graphql';

interface KeyValue {
  [key: string]: string | number | undefined;
}

export interface Fields {
  id: string;
  label: string;
  type: string;
  options?: SelectType[];
  stateName: string;
  dataType?: string;
  isCurrency?: boolean;
}

export interface SearchFields {
  [key: string]: Fields;
}

export interface ColumnFields {
  clickable: boolean;
  columnName: string;
  accessorKey: string;
  route?: string;
  routeParam?: string;
  secondRoute?: string;
  secondRouteParam?: string;
  columnValue?: string;
  isDate?: boolean;
  displayFunc?: ({ row }: { row: { original: unknown } }) => string;
}

export interface SearchName {
  searchName: string;
  searchFields: SearchFields;
  searchQuery?: any;
  mutationQuery?: any;
  state: KeyValue;
  searchParam: string;
  dataQuery: string;
  columns: ColumnFields[];
}

export interface IAdvancedSearch {
  [key: string]: SearchName;
}

export const advancedSearch: IAdvancedSearch = {
  manageBatch: {
    searchName: 'Batches',
    searchFields: {
      batchStatus: {
        id: 'batchStatusId',
        label: 'Batch Status',
        type: 'select',
        options: [
          { key: 'open', desc: 'Open' },
          { key: 'closed', desc: 'Closed' },
        ],
        stateName: 'batchStatus',
      },
      paidDateBegin: {
        id: 'paidDateBeginId',
        label: 'Paid Date Begin',
        type: 'datepicker',
        stateName: 'paidDateBegin',
      },
      paidDateEnd: {
        id: 'paidDateEndId',
        label: 'Paid Date End',
        type: 'datepicker',
        stateName: 'paidDateEnd',
      },
    },
    state: {
      batchStatus: '',
      batchType: '',
      branchLocation: '',
      label: '',
      maxAdjustmentAmount: 0,
      minAdjustmentAmount: 0,
      paidDateBegin: '',
      paidDateEnd: '',
    },
    searchParam: 'batchInput',
    searchQuery: useFindBatchByAllQuery,
    dataQuery: 'FindBatchByAll',
    columns: [
      {
        clickable: false,
        columnName: 'Batch Status',
        accessorKey: 'batchStatus',
      },
      {
        clickable: false,
        columnName: 'Batch Label',
        accessorKey: 'label',
      },
      {
        clickable: false,
        columnName: 'Batch Type',
        accessorKey: 'batchType',
      },
      {
        clickable: true,
        columnName: 'Transactions',
        accessorKey: 'transactions',
        route: '/batchDetails',
        routeParam: 'id',
        columnValue: 'View',
      },
      {
        clickable: false,
        columnName: 'Paid Date',
        accessorKey: 'paidDate',
        isDate: true,
      },
      {
        clickable: false,
        columnName: 'Branch Location',
        accessorKey: 'branchLocationId',
      },
    ],
  },
  manageDeposite: {
    searchName: 'Deposits',
    searchFields: {
      depositBeginDate: {
        id: 'depositBeginDateId',
        label: 'Deposit Date Begin',
        type: 'datepicker',
        stateName: 'depositBeginDate',
      },
      depositEndDate: {
        id: 'depositEndDateId',
        label: 'Deposit Date End',
        type: 'datepicker',
        stateName: 'depositEndDate',
      },
      minDepositAmount: {
        id: 'minDepositAmountId',
        label: 'Min Deposit Amount',
        type: 'input',
        stateName: 'minDepositAmount',
        dataType: 'number',
      },
      maxDepositAmount: {
        id: 'maxDepositAmountId',
        label: 'Max Deposit Amount',
        type: 'input',
        stateName: 'maxDepositAmount',
        dataType: 'number',
      },
    },
    state: {
      depositBeginDate: '',
      depositEndDate: '',
      maxDepositAmount: 0,
      minDepositAmount: 0,
    },
    searchParam: 'depositeInput',
    searchQuery: useFindDepositByAllQuery,
    dataQuery: 'FindDepositByAll',
    columns: [
      {
        clickable: true,
        columnName: 'Deposit label',
        accessorKey: 'label',
        route: '/depositDetails',
        routeParam: 'id',
      },
      {
        clickable: false,
        columnName: 'Deposit Status',
        accessorKey: 'depositStatus',
      },
      {
        clickable: false,
        columnName: 'Deposit Amount',
        accessorKey: 'depositAmount',
      },
      {
        clickable: false,
        columnName: 'Deposit Account',
        accessorKey: 'depositAccount',
      },
      {
        clickable: false,
        columnName: 'Deposit Date',
        accessorKey: 'depositDate',
        isDate: true,
      },
    ],
  },
  manageLetters: {
    searchName: 'Letters',
    searchFields: {
      LetterId: {
        id: 'LetterId',
        label: 'Letter Id',
        type: 'input',
        stateName: 'LetterId',
      },
      Street: {
        id: 'Street',
        label: 'Street',
        type: 'input',
        stateName: 'Street',
        dataType: 'string',
      },
      City: {
        id: 'City',
        label: 'City',
        type: 'input',
        stateName: 'City',
        dataType: 'string',
      },
      State: {
        id: 'State',
        label: 'State',
        type: 'input',
        stateName: 'State',
        dataType: 'string',
      },
      ZipCode: {
        id: 'ZipCode',
        label: 'Zip Code',
        type: 'input',
        stateName: 'ZipCode',
      },
      Country: {
        id: 'Country',
        label: 'Country',
        type: 'input',
        stateName: 'Country',
        dataType: 'string',
      },
      PrintDateFrom: {
        id: 'PrintDateFrom',
        label: 'Printed Date Begin',
        type: 'datepicker',
        stateName: 'PrintDateFrom',
      },
      PrintDateTo: {
        id: 'PrintDateTo',
        label: 'Printed Date End',
        type: 'datepicker',
        stateName: 'PrintDateTo',
      },
    },
    state: {
      LetterId: '',
      Street: '',
      City: '',
      State: '',
      ZipCode: '',
      Country: '',
      PrintDateFrom: '',
      PrintDateTo: '',
    },
    searchParam: 'searchItem',
    searchQuery: useGetFindByJsonParameterQuery,
    dataQuery: 'GetFindByJsonParameter',
    columns: [
      {
        clickable: true,
        columnName: 'Letter id',
        accessorKey: 'LetterId',
        route: '/letterDetails',
        routeParam: 'LetterId',
        secondRoute: '',
        secondRouteParam: 'TemplateId',
      },
      {
        clickable: true,
        columnName: 'Entity ID',
        accessorKey: 'PrimaryEntityId',
        columnValue: '--',
        route: '/entity',
        routeParam: 'ContextId',
      },
      {
        clickable: false,
        columnName: 'Entity Name',
        accessorKey: 'EntityName',
        columnValue: '--',
      },
      {
        clickable: false,
        columnName: 'Recipient Address',
        accessorKey:
          'AddressLine1,AddressLine2,StateCode,PostalCode,CountryCode',
      },
      {
        clickable: true,
        columnName: 'Account Number',
        accessorKey: 'PrimaryAccountId',
        columnValue: '--',
        route: '/account',
        routeParam: 'ContextId',
      },
      {
        clickable: false,
        columnName: 'Account Name',
        accessorKey: 'AccountName',
        columnValue: '--',
      },
      {
        clickable: false,
        columnName: 'Correspondence Type Name',
        accessorKey: 'CorrespondenceTypeName',
        columnValue: '--',
      },
      {
        clickable: false,
        columnName: 'Correspondence Type Category',
        accessorKey: 'CorrespondenceTypeCategoryName',
        columnValue: '--',
      },
      {
        clickable: false,
        columnName: 'Printed Date',
        accessorKey: 'PrintDate',
        columnValue: '--',
        isDate: true,
      },
      {
        clickable: false,
        columnName: 'Letter Status',
        accessorKey: 'DocumentStatus',
      },
      {
        clickable: false,
        columnName: 'FTI',
        accessorKey: 'IsFTI',
        columnValue: '--',
      },
    ],
  },
};

export const advancedMutationSearch: IAdvancedSearch = {
  manageEntities: {
    searchName: 'Entities',
    searchFields: {
      nameType: {
        id: 'nameTypeId',
        label: 'Name Type',
        type: 'datasource',
        stateName: 'entityNameType',
        dataType: 'NameType',
      },
      fullName: {
        id: 'fullNameId',
        label: 'Full Name',
        type: 'input',
        stateName: 'entityFullName',
      },
      firstName: {
        id: 'firstNameId',
        label: 'First Name',
        type: 'input',
        stateName: 'entityFirstName',
      },
      middleName: {
        id: 'middleNameId',
        label: 'Middle Name',
        type: 'input',
        stateName: 'entityMiddleName',
      },
      lastName: {
        id: 'lastNameId',
        label: 'Last Name',
        type: 'input',
        stateName: 'entityLastName',
      },
      identifierValue: {
        id: 'identifierValueId',
        label: 'ID',
        type: 'input',
        stateName: 'entityIdentifier',
      },
    },
    state: {
      entityNameType: '',
      entityFullName: '',
      entityFirstName: '',
      entityMiddleName: '',
      entityLastName: '',
      entityIdentifier: '',
    },
    searchParam: 'entitySearch',
    mutationQuery: useAdvancedSearchEntityMutation,
    dataQuery: 'AdvancedSearchEntity',
    columns: [
      {
        clickable: true,
        columnName: 'Entity ID',
        accessorKey: 'primaryEntityIdentifier',
        route: '/entity',
        routeParam: 'entityInfoId',
      },
      {
        clickable: false,
        columnName: 'ID Type',
        accessorKey: 'primaryEntityIdentifierType',
      },
      {
        clickable: false,
        columnName: 'Full Name',
        accessorKey: 'primaryFullName',
      },
      {
        clickable: false,
        columnName: 'Entity Type',
        accessorKey: 'entityType',
      },
      {
        clickable: false,
        columnName: 'Address',
        accessorKey: 'primaryAddressLine1',
      },
      {
        clickable: false,
        columnName: 'City',
        accessorKey: 'primaryAddressCity',
      },
      {
        clickable: false,
        columnName: 'Commence Date',
        accessorKey: 'commenceDate',
        isDate: true,
      },
      {
        clickable: false,
        columnName: 'Cease Date',
        accessorKey: 'ceaseDate',
        columnValue: '--',
        isDate: true,
      },
    ],
  },
  manageAccounts: {
    searchName: 'Accounts',
    searchFields: {
      accountIdentifier: {
        id: 'accountIdentifierId',
        label: 'Account ID',
        type: 'input',
        stateName: 'accountIdentifier',
      },
      accountName: {
        id: 'accountNameId',
        label: 'Account Name',
        type: 'input',
        stateName: 'accountName',
      },
      accountType: {
        id: 'accountTypeId',
        label: 'Account Type',
        type: 'datasource',
        stateName: 'accountType',
        dataType: 'AccountType',
      },
      accountStatus: {
        id: 'accountStatusId',
        label: 'Status',
        type: 'select',
        options: [
          { key: 'OK', desc: 'OK' },
          { key: 'Deleted', desc: 'Deleted' },
        ],
        stateName: 'accountStatus',
      },
      accountBalanceFrom: {
        id: 'accountBalanceFromId',
        label: 'Balance From',
        type: 'input',
        stateName: 'accountBalanceFrom',
      },
      accountBalanceTo: {
        id: 'accountBalanceToId',
        label: 'Balance To',
        type: 'input',
        stateName: 'accountBalanceTo',
      },
      filingPeriod: {
        id: 'filingPeriodId',
        label: 'Filing Period',
        type: 'datasource',
        stateName: 'filingPeriod',
        dataType: 'PeriodFrequencyType',
      },
    },
    state: {
      accountIdentifier: '',
      accountName: '',
      accountType: '',
      accountStatus: '',
      accountBalanceFrom: '',
      accountBalanceTo: '',
      filingPeriod: '',
    },
    searchParam: 'accountSearch',
    mutationQuery: useAdvancedSearchAccountMutation,
    dataQuery: 'AdvancedSearchAccount',
    columns: [
      {
        clickable: true,
        columnName: 'Entity ID',
        accessorKey: 'primaryEntityIdentifier',
        route: '/entity',
        routeParam: 'entityInfoId',
      },
      {
        clickable: false,
        columnName: 'Entity Name',
        accessorKey: 'primaryEntityName',
      },
      {
        clickable: true,
        columnName: 'Account ID',
        accessorKey: 'primaryAccountIdentifier',
        route: '/entity',
        routeParam: 'entityInfoId',
        secondRoute: '/account',
        secondRouteParam: 'accountInfoId',
      },
      {
        clickable: false,
        columnName: 'Account Type',
        accessorKey: 'accountType',
      },
      {
        clickable: false,
        columnName: 'Account Name',
        accessorKey: 'primaryAccountName',
      },
      {
        clickable: false,
        columnName: 'Latest Period Date',
        accessorKey: 'latestPeriodDate',
        columnValue: '--',
        isDate: true,
      },
      {
        clickable: false,
        columnName: 'Balance',
        accessorKey: 'balance',
      },
      {
        clickable: false,
        columnName: 'Commence Date',
        accessorKey: 'commenceDate',
        isDate: true,
      },
      {
        clickable: false,
        columnName: 'Cease Date',
        accessorKey: 'ceaseDate',
        columnValue: '--',
        isDate: true,
      },
    ],
  },
  manageAssets: {
    searchName: 'Assets',
    searchFields: {
      assetIdentifier: {
        id: 'assetIdentifierId',
        label: 'Asset ID',
        type: 'input',
        stateName: 'assetIdentifier',
      },
      assetFullName: {
        id: 'assetNameId',
        label: 'Asset Name',
        type: 'input',
        stateName: 'assetFullName',
      },
      assetNameType: {
        id: 'assetNameTypeId',
        label: 'Name Type',
        type: 'datasource',
        stateName: 'assetNameType',
        dataType: 'NameType',
      },
    },
    state: {
      assetIdentifier: '',
      assetFullName: '',
      assetNameType: '',
    },
    searchParam: 'assetSearch',
    mutationQuery: useAdvancedSearchAssetMutation,
    dataQuery: 'AdvancedSearchAsset',
    columns: [
      {
        clickable: true,
        columnName: 'Asset ID',
        accessorKey: 'primaryAssetIdentifier',
        route: '/asset',
        routeParam: 'assetInfoId',
      },
      {
        clickable: false,
        columnName: 'ID Type',
        accessorKey: 'primaryAssetIdentifierType',
      },
      {
        clickable: false,
        columnName: 'Name',
        accessorKey: 'primaryFullName',
      },
      {
        clickable: false,
        columnName: 'Asset Type',
        accessorKey: 'assetType',
      },

      {
        clickable: false,
        columnName: 'Address',
        accessorKey: 'primaryAddressLine1',
      },
      {
        clickable: false,
        columnName: 'Commence Date',
        accessorKey: 'commenceDate',
        isDate: true,
      },
      {
        clickable: false,
        columnName: 'Cease Date',
        accessorKey: 'ceaseDate',
        columnValue: '--',
        isDate: true,
      },
    ],
  },
};

export const paymentSearch: IAdvancedSearch = {
  managePayments: {
    searchName: 'Payments',
    searchFields: {
      receiveDate: {
        id: 'receiveDateId',
        label: 'Received Date',
        type: 'datepicker',
        stateName: 'receiveDate',
      },
      amount: {
        id: 'amountId',
        label: 'Amount',
        type: 'input',
        stateName: 'amount',
        dataType: 'string',
        isCurrency: true,
      },
    },
    state: {
      receiveDate: '',
      amount: undefined,
    },
    searchParam: 'paymentSearch',
    searchQuery: useAdvancedSearchPaymentsQuery,
    dataQuery: 'AdvancedSearchPayments',
    columns: [
      {
        clickable: false,
        columnName: 'Type',
        accessorKey: 'paymentType',
      },
      {
        clickable: true,
        columnName: 'Amount',
        accessorKey: 'amount',
        route: '/paymentdetails',
      },
      {
        clickable: false,
        columnName: 'Effective Date',
        accessorKey: 'effectiveDate',
        isDate: true,
      },
      {
        clickable: false,
        columnName: 'Note',
        accessorKey: 'paymentNote',
      },
      {
        clickable: false,
        columnName: 'Period Covered',
        accessorKey: 'periodCoveredDate',
        isDate: true,
      },
      {
        clickable: false,
        columnName: 'Status',
        accessorKey: 'paymentStatus',
      },
      {
        clickable: false,
        columnName: 'Account Type',
        accessorKey: 'accountType',
      },
      {
        clickable: false,
        columnName: 'Receive Date',
        accessorKey: 'receiveDate',
        isDate: true,
      },
    ],
  },
};
