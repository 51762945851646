import { useEffect, useState, CSSProperties } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { IHeader, setHeader } from 'redux/contentSlice';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import EditIcon from '@mui/icons-material/Edit';
import { Grid, IconButton, SelectChangeEvent, Stack } from '@mui/material';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import { ColumnDef, Table } from '@tanstack/react-table';
import { format } from 'date-fns';
import { Link, useNavigate } from 'react-router-dom';
import Loading from 'components/Loading';
import Radio from '@revenue-solutions-inc/revxcoreui/material/controls/Radio';
import {
  FormCloneInput,
  FormTemplate,
  useCreateCloneFormMutation,
  useGetFormByFormNameQuery,
  useGetLatestVersionFormQuery,
  useUpdateActiveVersionFormMutation,
  useDeactivateVersionFormMutation,
  useGetFormTemplatesQuery,
} from 'generated/graphql';
import Dialog from '@revenue-solutions-inc/revxcoreui/material/controls/Dialog';
import {
  Checkbox,
  Input,
  MessageType,
} from '@revenue-solutions-inc/revxcoreui';
import { addMessage } from 'redux/messageSlice';
import { dateFormat } from 'utils/date-util';
import extractMeaningfulMessage from 'utils/errorMessage';
import { Box } from '@mui/system';
import { ErrorTypeProps } from '../CreateForms/FormDetails/FormDetails';

const widthHeight: CSSProperties = {
  width: '1.5em',
  height: '1.5em',
};

const cancelButton = {
  ...widthHeight,
  backgroundColor: 'error.main',
  '&:hover': {
    backgroundColor: 'error.main',
  },
};
const checkButton = {
  ...widthHeight,
  backgroundColor: 'secondaryGreen.main',
  '&:hover': {
    backgroundColor: 'secondaryGreen.main',
  },
  '&:disabled': {
    backgroundColor: 'grey1.main',
  },
};
const icon = {
  color: 'white.main',
  fontSize: '1em',
};

interface ErrorsProps {
  name: ErrorTypeProps;
}

interface FormRequestProps {
  id?: string;
  form?: FormTemplate[];
}
interface ListFormProps {
  id?: string;
  form?: FormTemplate;
}

function ManageForms(): JSX.Element {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [formCategory, setFormCategory] = useState<string>('Taxpayer');
  const {
    data,
    isLoading,
    refetch: refetchList,
  } = useGetFormTemplatesQuery({
    formCategory: formCategory,
  });
  const [dataGet, setDataGet] = useState<ListFormProps[] | []>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [currentName, setCurrentName] = useState<string>('');
  const [version, setVersion] = useState<string>('');
  const [selectedForms, setSelectedForms] = useState<ListFormProps | null>(
    null
  );
  const [errors, setErrors] = useState<ErrorsProps>({
    name: {
      error: false,
      message: '',
    },
  });
  let tableReset: Table<ListFormProps> | undefined = undefined;
  const [enabledQuery, setEnabledQuery] = useState<boolean>(false);
  const module = useAppSelector((state) => state.user.module);
  const { data: dataFormName, refetch: checkIfFormNameExist } =
    useGetFormByFormNameQuery(
      {
        formName: name,
      },
      { enabled: enabledQuery }
    );
  const updateActiveVersionForm = useUpdateActiveVersionFormMutation();
  const deactiveVersionForm = useDeactivateVersionFormMutation();

  useEffect(() => {
    if (dataFormName !== undefined && dataFormName.getFormByName.length > 0) {
      setErrors((prev) => ({
        ...prev,
        name: {
          error: true,
          message: t('pages.forms.formNameExists'),
        },
      }));
    }
  }, [dataFormName, t]);

  useEffect(() => {
    if (data && data.getFormTemplates) {
      const listOfForms: FormRequestProps[] = [];
      data.getFormTemplates.flatMap((element) => {
        if (element.Forms && element.Id) {
          const formAux = element.Forms?.map((item) => item);

          if (formAux) {
            const auxItem: FormRequestProps = {
              id: element?.Id,
              form: formAux ? (formAux as FormTemplate[]) : [],
            };
            listOfForms.push(auxItem);
          }
        }
      });
      const allData: ListFormProps[] = [];

      listOfForms.forEach((item: FormRequestProps) => {
        item.form?.forEach((value) => {
          const formValue: ListFormProps = {
            id: item?.id,
            form: value,
          };
          allData.push(formValue);
        });
      });
      setDataGet(allData as unknown as ListFormProps[]);
    }
  }, [data]);

  const [enabledQueryLastestVersion, setEnabledQueryLastestVersion] =
    useState<boolean>(false);
  const { data: latestVersion } = useGetLatestVersionFormQuery(
    {
      formName: currentName,
    },
    {
      enabled: enabledQueryLastestVersion,
    }
  );
  const openToClone = (formName: string) => {
    setCurrentName(formName);
    setName(formName);
    setOpen(true);
    setEnabledQueryLastestVersion(true);
  };

  useEffect(() => {
    if (latestVersion !== undefined && latestVersion) {
      const nextVersion =
        parseInt(latestVersion.getLatestVersionForm.Version as string) + 1;
      setVersion(nextVersion.toString());
      setEnabledQueryLastestVersion(false);
    }
  }, [latestVersion]);

  const handleSelectedForm = (form: ListFormProps) => {
    setSelectedForms(form);
  };

  const handleSetActiveVersion = () => {
    if (selectedForms === undefined) return;
    if (selectedForms?.id) {
      if (selectedForms.form?.IsActive) {
        updateActiveVersionForm.mutate(
          {
            formGroupId: selectedForms.id,
          },
          {
            onSuccess: () => {
              setSelectedForms(null);
              refetchList();
              dispatch(
                addMessage({
                  type: MessageType.Success,
                  message: t('components.message.success'),
                })
              );
            },
            onError: (e: Error[] | unknown) => {
              let message: string = t('pages.forms.formError');
              message = extractMeaningfulMessage(e, message);
              dispatch(
                addMessage({
                  type: MessageType.Error,
                  message: String(message),
                })
              );
            },
          }
        );
      } else {
        deactiveVersionForm.mutate(
          {
            formGroupId: selectedForms.id,
          },
          {
            onSuccess: () => {
              setSelectedForms(null);
              refetchList();
              dispatch(
                addMessage({
                  type: MessageType.Success,
                  message: t('components.message.success'),
                })
              );
            },
            onError: (e: Error[] | unknown) => {
              let message: string = t('pages.forms.formError');
              message = extractMeaningfulMessage(e, message);
              dispatch(
                addMessage({
                  type: MessageType.Error,
                  message: String(message),
                })
              );
            },
          }
        );
      }
    }
  };

  const Column: ColumnDef<ListFormProps>[] = [
    {
      header: () => <HeaderColumnNext localization={t('pages.forms.name')} />,
      accessorKey: 'form.FormName',
      cell: ({ row, table }) => {
        //TODO: this will remove, just is until we have a property for handle reset page for the table
        tableReset = table;
        return (
          <Box style={{ width: '30em' }}>
            <Link
              to={{
                pathname: `/${module}/manageforms/edit/${row.original.id}`,
              }}
              style={{ color: 'linkBlue.main' }}
            >
              {row.original?.form?.FormName}
            </Link>
          </Box>
        );
      },
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.forms.creationDate')} />
      ),
      accessorKey: 'form.CreatedDate',
      cell: ({ row }) => {
        return (
          <Box style={{ width: '10em' }}>
            {format(
              new Date(row.original?.form?.CreatedDate ?? ''),

              dateFormat
            )}
          </Box>
        );
      },
    },
    {
      header: () => <HeaderColumnNext localization={t('pages.forms.update')} />,
      accessorKey: 'form.UpdatedDate',
      cell: ({ row }) => {
        return (
          <Box style={{ width: '10em' }}>
            {format(
              new Date(row.original?.form?.UpdatedDate ?? ''),

              dateFormat
            )}
          </Box>
        );
      },
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.forms.version')} />
      ),
      accessorKey: 'form.Version',
      cell: ({ getValue }) => (
        <Box style={{ width: '10em' }}>{getValue() as string}</Box>
      ),
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.forms.isActive')} />
      ),
      accessorKey: 'form.IsActive',
      cell: ({ row }) => {
        if (selectedForms?.id === row.original.id) {
          return (
            <Box style={{ width: '5em' }}>
              <Checkbox
                id={'addNoteChk'}
                checked={selectedForms?.form?.IsActive || false}
                onChange={(event) => {
                  setSelectedForms((prev) => ({
                    ...prev,
                    form: {
                      ...prev?.form,
                      IsActive: event.target.checked,
                    },
                  }));
                }}
              />
            </Box>
          );
        } else {
          return row.original.form?.IsActive ? (
            <Box style={{ width: '5em' }}>{t('pages.forms.yes')}</Box>
          ) : (
            <Box style={{ width: '5em' }}>{t('pages.forms.no')}</Box>
          );
        }
      },
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.forms.editActiveStatus')} />
      ),
      id: 'content-edit',
      cell: ({ row }) => {
        if (selectedForms?.id === row.original.id) {
          return (
            <Box style={{ width: '10em' }}>
              <Stack spacing={1} direction="row">
                <IconButton
                  size="small"
                  onClick={handleSetActiveVersion}
                  sx={checkButton}
                  aria-label={t('pages.forms.checkButton')}
                  disabled={!selectedForms}
                >
                  <CheckIcon sx={icon} />
                </IconButton>
                <IconButton
                  size="small"
                  sx={cancelButton}
                  aria-label={t('pages.forms.closeButton')}
                  onClick={() => setSelectedForms(null)}
                >
                  <CloseIcon fontSize="small" sx={icon} />
                </IconButton>
              </Stack>
            </Box>
          );
        } else {
          return (
            <Box style={{ width: '10em' }}>
              <IconButton
                color={'primary'}
                onClick={() => handleSelectedForm(row.original)}
                aria-label={t('pages.forms.editFormBtn')}
              >
                <EditIcon />
              </IconButton>
            </Box>
          );
        }
      },
    },
    {
      header: () => (
        <HeaderColumnNext
          localization={t('pages.manageFormsProcessing.clone')}
        />
      ),
      id: 'content-clone',
      cell: ({ row }) => {
        return (
          <Box style={{ width: '8em' }}>
            <IconButton
              onClick={() => openToClone(`${row.original.form?.FormName}`)}
              aria-label={t('pages.forms.cloneForm')}
              color="primary"
            >
              <FileCopyIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    const headerData: IHeader = {
      pageTitle: t('pages.forms.formDesign'),
      buttonLinks: [
        {
          id: 'create_form_custom_header',
          title: t('pages.forms.buttons.create'),
          type: 'primary',
          route: `/${module}/manageforms/${formCategory}/create`,
        },
      ],
    };
    dispatch(setHeader(headerData));
  }, [dispatch, t, formCategory, navigate, module]);

  const validate = () => {
    const formNaeAux = name.replace(/  +/g, ' ').trim();
    if (formNaeAux.length === 0) {
      setErrors((prev) => ({
        ...prev,
        name: { error: true, message: t('pages.forms.fieldRequired') },
      }));
    } else if (formNaeAux === currentName) {
      setName(formNaeAux);
    } else {
      setEnabledQuery(true);
      checkIfFormNameExist();
      setName(formNaeAux);
    }
    setEnabledQuery(false);
  };

  const { mutate } = useCreateCloneFormMutation();

  const handleCloneForm = () => {
    setEnabledQuery(false);
    if (!errors.name.error) {
      const formClone: FormCloneInput = {
        formName: currentName,
        newFormName: name,
      };
      mutate(
        {
          formClone: formClone,
        },
        {
          onSuccess: () => {
            dispatch(
              addMessage({
                type: MessageType.Success,
                message: t('pages.manageFormsProcessing.cloneForm'),
              })
            );
            refetchList();
            setOpen(false);
          },
          onError: () => {
            dispatch(
              addMessage({
                type: MessageType.Error,
                message: t('components.message.networkerror'),
              })
            );
          },
        }
      );
    }
  };

  const handleCloseDialog = () => {
    setName('');
    setCurrentName('');
    setEnabledQuery(false);
    setErrors({ name: { error: false, message: '' } });
    setOpen(false);
    setEnabledQueryLastestVersion(false);
  };

  const cloneForm = (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Input
          id="formName-clone"
          label={t('pages.forms.name')}
          required
          error={errors.name.error}
          value={name}
          onChange={(e: SelectChangeEvent<string | number>) => {
            setName(e.target.value as string);
          }}
          onBlur={validate}
          helperText={errors.name.message}
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          id="version-clone"
          label={t('pages.forms.version')}
          required={false}
          error={false}
          value={name === currentName ? version : '1'}
          disabled={true}
        />
      </Grid>
    </Grid>
  );

  const handleRadioChange = (_event: React.ChangeEvent<HTMLInputElement>) => {
    setFormCategory(_event.target.value);
    //TODO: this will remove, just is until we have a property for handle reset page for the table
    if (tableReset !== undefined) {
      tableReset.setPageIndex(0);
    }
  };

  if (dataGet && !isLoading) {
    return (
      <>
        <Grid container>
          <Grid item xs={12}>
            <DefaultDataTableNext
              columns={Column as ColumnDef<Record<string, unknown>, unknown>[]}
              data={dataGet as Record<string, FormRequestProps>[]}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'end',
              }}
              customHeader={
                <div
                  style={{
                    display: 'flex',
                  }}
                >
                  <Radio
                    value="Taxpayer"
                    color="primary"
                    id="correspondenceEnabled"
                    checked={formCategory === 'Taxpayer'}
                    onChange={handleRadioChange}
                    label="Taxpayer"
                  />
                  <Radio
                    value="Process"
                    color="primary"
                    id="correspondenceEnabled2"
                    checked={formCategory === 'Process'}
                    onChange={handleRadioChange}
                    label="Process"
                  />
                </div>
              }
            />
          </Grid>
        </Grid>
        <Dialog
          id="cloneFormDialog"
          open={open}
          children={cloneForm}
          title={t('pages.manageFormsProcessing.clone')}
          type="transactional"
          maxWidth="xs"
          transactionModalTransactionButtonText={t(
            'pages.manageFormsProcessing.clone'
          )}
          handleClose={handleCloseDialog}
          handleCancelClick={handleCloseDialog}
          handleTransactionClick={handleCloneForm}
        />
      </>
    );
  }
  if (isLoading) {
    return <Loading />;
  }
  return <></>;
}
export default ManageForms;
