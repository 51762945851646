import { ReactNode, useEffect, useState, useRef } from 'react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button/Button';
import SingleSearch from '@revenue-solutions-inc/revxcoreui/material/layout/SingleSearch';
import searchItemsImport, {
  containerResultStyle,
  resultStyle,
  textStyle,
  containerErrorMessageStyle,
} from 'common/search';
import {
  GeneralDataResult,
  GeneralSearchResponse,
  useSearchByIndexQuery,
} from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { setGlobalSearchPhrase, setGlobalSearchRepeat } from 'redux/userSlice';
import { v4 as uuid } from 'uuid';
import { useHasAccess } from 'hooks/useHasAccess';

interface SearchItemsIF {
  id: string;
  label: string;
}

function SingleSearchWrapper(): JSX.Element {
  const entityIndex = process.env.REACT_APP_ENTITY_INDEX ?? '';
  const accountIndex = process.env.REACT_APP_ACCOUNT_INDEX ?? '';
  const assetIndex = process.env.REACT_APP_ASSET_INDEX ?? '';
  const defaultIndex = `${entityIndex},${accountIndex},${assetIndex}` ?? '';
  const MAX_RESULTS = 5;
  const module = useAppSelector((state) => state.user.module);
  const searchPhrase = useAppSelector((state) => state.user.globalSearchPhrase);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchIndex, setSearchIndex] = useState<string>(defaultIndex);
  const [searchType, setSearchType] = useState<string>('');
  const [searchTerm, setSearchTerm] = useState<string | undefined>('');
  const [searchItems, setSearchItems] = useState<SearchItemsIF[]>([
    ...searchItemsImport,
  ]);
  const [results, setResults] = useState<GeneralSearchResponse>();
  const inputRef = useRef<HTMLInputElement>(null);
  const canViewEntitySearch = useHasAccess('maintainEntity', 'view');
  const canViewAccountSearch = useHasAccess('maintainAccount', 'view');
  const canViewAssetSearch = useHasAccess('maintainAsset', 'view');

  const { data, refetch, isFetching } = useSearchByIndexQuery(
    {
      maxResults: MAX_RESULTS,
      indexName: searchIndex,
      searchPhrase: searchTerm ? searchTerm : '',
    },
    { enabled: false }
  );

  const handleSearchAndType = (type: string, index: string) => {
    setSearchType(type);
    setSearchIndex(index);
  };

  const setSearchTypeAndIndex = (type: string) => {
    switch (type) {
      case 'Entity':
        handleSearchAndType(type, entityIndex);
        break;
      case 'Account':
        handleSearchAndType(type, accountIndex);
        break;
      case 'Asset':
        handleSearchAndType(type, assetIndex);
        break;
      default:
        handleSearchAndType('', defaultIndex);
        break;
    }
  };

  const clearInput = () => {
    setSearchType('');
    setSearchTerm('');
    setSearchIndex(defaultIndex);
    if (inputRef.current) inputRef.current.value = '';
  };

  //THIS LISTENER IS TO HANDLED  THE FILTERS BUTTONS BASED ON THE SECURITY
  useEffect(() => {
    const filterOptions: SearchItemsIF[] = [];
    searchItemsImport.forEach((item) => {
      if (
        (canViewEntitySearch && item.id === 'Entity_ID') ||
        (canViewAccountSearch && item.id === 'Account_ID') ||
        (canViewAssetSearch && item.id === 'Asset_ID')
      ) {
        filterOptions.push(item);
      }
    });
    setSearchItems(filterOptions);
  }, [canViewEntitySearch, canViewAccountSearch, canViewAssetSearch]);

  const navigateToItem = (result: GeneralDataResult) => {
    const routeTo = result?.resultType?.toLowerCase();
    if (routeTo === 'entity' && result.entityInfoId) {
      navigate(`/${module}/${routeTo}/${result.entityInfoId}`);
    } else if (routeTo === 'asset' && result.assetInfoId) {
      navigate(`/${module}/${routeTo}/${result.assetInfoId}`);
    } else if (result.accountInfoId && result.entityInfoId) {
      navigate(
        `/${module}/entity/${result.entityInfoId}/${routeTo}/${result.accountInfoId}`
      );
    }
  };

  const handleClickOnResult = (result: GeneralDataResult) => {
    if (inputRef.current) inputRef.current.click();
    setResults(undefined);
    clearInput();
    navigateToItem(result);
  };

  const handleEnterOnResult = (ev: React.KeyboardEvent<HTMLDivElement>) => {
    if (ev.key === 'Enter' && searchTerm) {
      if (searchPhrase === searchTerm) {
        dispatch(setGlobalSearchRepeat(true));
      }
      dispatch(setGlobalSearchPhrase(searchTerm));
      setResults(undefined);
      clearInput();
      navigate(`/${module}/search`);
    }
  };

  const checkResultsExists = (
    contextData: GeneralDataResult[] | undefined
  ): boolean => contextData !== undefined && contextData.length > 0;

  const checkHasResults = (): boolean => {
    if (
      searchType === '' &&
      (checkResultsExists(results?.entityData) ||
        checkResultsExists(results?.accountData) ||
        checkResultsExists(results?.assetsData))
    )
      return true;
    else if (searchType === 'Entity')
      return checkResultsExists(results?.entityData);
    else if (searchType === 'Account')
      return checkResultsExists(results?.accountData);
    else if (searchType === 'Asset')
      return checkResultsExists(results?.assetsData);
    return false;
  };

  const generateListItems = (result: GeneralDataResult): ReactNode => {
    let handleClickResult = () => handleClickOnResult(result);
    if (result.isViewable !== 'true') {
      handleClickResult = () => {};
    }
    return (
      <ListItem sx={containerResultStyle} key={uuid()}>
        <Button
          id={'btn_' + uuid()}
          size="small"
          type="secondary"
          variant="text"
          sx={resultStyle}
          onClick={handleClickResult}
        >
          {result.displayString}
        </Button>
      </ListItem>
    );
  };

  const displayErrorMessages = (): ReactNode => {
    return results?.errorMessage?.split('|').map((message) => (
      <ListItem sx={containerErrorMessageStyle} key={uuid()}>
        {message}
      </ListItem>
    ));
  };

  const generateResults = (): ReactNode => {
    if (isFetching) {
      return (
        <List>
          <ListItemText sx={textStyle}>
            {t('pages.search.loading')}
          </ListItemText>
        </List>
      );
    }
    if (results && !checkHasResults()) {
      return <List>{displayErrorMessages()}</List>;
    }
    if (results) {
      return (
        <List>
          <>
            {results?.entityData?.map((result) => {
              return generateListItems(result);
            })}
            {results?.accountData?.map((result) => {
              return generateListItems(result);
            })}
            {results?.assetsData?.map((result) => {
              return generateListItems(result);
            })}
            {displayErrorMessages()}
          </>
        </List>
      );
    }
  };

  useEffect(() => {
    setResults(data?.SearchByIndex);
  }, [data, dispatch]);

  return (
    <SingleSearch
      inputRef={inputRef}
      queryLen={3}
      value={searchTerm}
      displayValue={searchType}
      label={t('pages.appMenuBar.search')}
      placeholder={t('pages.appMenuBar.searchPlaceHolder')}
      searchResults={generateResults()}
      refetch={() => refetch()}
      setSearchTerm={setSearchTerm}
      onClear={() => clearInput()}
      searchOptions={searchItems}
      handleSelect={setSearchTypeAndIndex}
      setResults={() => setResults(undefined)}
      onKeyDown={handleEnterOnResult}
    />
  );
}

export default SingleSearchWrapper;
