import { useEffect, useState } from 'react';

import { Grid, Box, Typography } from '@mui/material';
import {
  MessageType,
  MessageActionType,
} from '@revenue-solutions-inc/revxcoreui';
import extractMeaningfulMessage from 'utils/errorMessage';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import Checkbox from '@revenue-solutions-inc/revxcoreui/material/controls/Checkbox';
import useAssignRoleUsers, { AssignAction } from 'hooks/useAssignRoleUsers';
import useGetAccessToken from 'hooks/useGetAccessToken';
import useRoleUsers from 'hooks/useRoleUsers';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { ColumnDef } from '@tanstack/react-table';
import { setHeader } from 'redux/contentSlice';
import { useAppDispatch } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import { RoleUser } from 'types/entities';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';

function AddUsers(): JSX.Element {
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const { roleId } = useParams() as {
    roleId: string;
  };

  const accessToken = useGetAccessToken();
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.addUsers',
  });
  const dispatch = useAppDispatch();

  const { mutate: addUsers } = useAssignRoleUsers();

  const { data, refetch, error } = useRoleUsers(accessToken, roleId, false);

  useEffect(() => {
    dispatch(setHeader({ pageTitle: t('title') }));
  }, [dispatch, t]);
  useEffect(() => {
    if (error) {
      dispatch(
        addMessage({
          type: MessageType.Error,
          message: t('components.message.networkerror'),
        })
      );
    }
  }, [error, dispatch, t]);

  const COLUMNS: ColumnDef<RoleUser>[] = [
    {
      header: () => {
        return (
          <Checkbox
            id={'selectAllCheck'}
            checked={selectedRows.length === data?.length}
            onChange={(event) => {
              let newSelectedRows = [...selectedRows];
              if (event.target.checked && data)
                newSelectedRows = data?.map((p) => p.userId);
              else newSelectedRows = [];
              setSelectedRows(newSelectedRows);
            }}
            label={''}
          />
        );
      },
      accessorKey: 'userId',
      cell: ({ row }) => {
        return (
          <Checkbox
            checked={
              selectedRows.findIndex((p) => p === row.original.userId) > -1
            }
            onChange={(event) => {
              let newRows = [...selectedRows];
              if (event.target.checked) {
                setSelectedRows([...selectedRows, row.original.userId]);
              } else {
                newRows = selectedRows.filter((p) => p !== row.original.userId);
                setSelectedRows(newRows);
              }
            }}
            id={''}
            label={''}
          />
        );
      },
    },
    {
      header: () => <HeaderColumnNext localization={t('userName')} />,
      accessorKey: 'name',
    },
    {
      header: () => <HeaderColumnNext localization={t('email')} />,
      accessorKey: 'email',
    },
  ];

  const navigate = useNavigate();

  function updateUsers() {
    const addPayload = selectedRows.map((row) => {
      return { roleId: roleId, userId: row };
    });
    //TODO: remove tenant id from contract
    addUsers(
      {
        userCommand: {
          userRoleMapEntries: addPayload,
          mapAction: AssignAction.ASSIGN,
          tenantId: '8E8F97C7-8657-4895-81C5-1142B6D1DE84',
        },
        token: accessToken,
      },
      {
        onSuccess: () => {
          refetch();
          setSelectedRows([]);
        },
        onError: (errors) => {
          const message = extractMeaningfulMessage(
            errors,
            t('pages.manageReusableContent.networkError')
          );
          dispatch(
            addMessage({
              message: message,
              type: MessageType.Error,
              actionType: MessageActionType.None,
            })
          );
        },
      }
    );
  }

  return (
    <Box>
      <Box sx={{ float: 'left', minWidth: '300px' }}>
        <Grid item xs={10} mb={2}>
          <Typography variant="h1">{t('header')}</Typography>
          <Typography variant="h3">{t('subHeader')}</Typography>
        </Grid>
        <Grid item xs={10} mb={2}>
          <Typography variant="h2">{t('select')}</Typography>
          {selectedRows.length > 0 && (
            <Typography>
              {selectedRows.length}
              {t('userSelected')}
            </Typography>
          )}
        </Grid>
        <Grid item xs={10} mb={2}>
          <Button
            id="saveBtn-addUsers"
            sx={{ mt: 1, mb: 1, float: 'left' }}
            disabled={selectedRows.length === 0}
            onClick={() => {
              updateUsers();
            }}
          >
            {selectedRows.length > 1 ? t('addUsersBtn') : t('addUserBtn')}
          </Button>
          <Button
            id="backBtn-addUsers"
            onClick={() => navigate(`/${module}/manageroles/edit/` + roleId)}
            sx={{ mt: 1, mb: 1, float: 'right' }}
          >
            {t('goBackBtn')}
          </Button>
        </Grid>
      </Box>
      <Box sx={{ float: 'right', minWidth: '900px' }}>
        <Typography variant="h1">{t('unassignedUsers')}</Typography>

        {data && data.length > 0 && (
          <DefaultDataTableNext
            columns={COLUMNS as ColumnDef<Record<string, unknown>>[]}
            data={data}
          />
        )}
        {/* {data && data?.length === 0 && <NoResults />} */}
      </Box>
    </Box>
  );
}

export default AddUsers;
