import { useState, useEffect } from 'react';

import { Grid } from '@mui/material';
import { Card, CardContent } from '@revenue-solutions-inc/revxcoreui';
import { deleteContactsIfEmpty } from 'common/helpers';
import HorizontalNonLinearStepper from 'components/stepper/HorizontalNonLinearStepper';
import { useTranslation } from 'react-i18next';
import { setHeader } from 'redux/contentSlice';
import { useAppDispatch } from 'redux/hooks';
import {
  defaultTaxingAuthority,
  TaxingAuthority,
  TaxRate,
  taxRateDefault,
  TaxExemption,
  exemptionDefault,
} from 'types/taxingAuthority/entities';

import TaxingAuthorityAddress from './TaxingAuthorityAddress/TaxingAuthorityAddress';
import TaxingAuthorityDetails from './TaxingAuthorityDetails/TaxingAuthorityDetails';
import TaxingAuthorityPeriod from './TaxingAuthorityPeriod/TaxingAuthorityPeriod';

function ManageTaxingAuthority(): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [taxingAuthority, setTaxingAuthorityId] = useState<TaxingAuthority>(
    defaultTaxingAuthority
  );

  const [taxRates, setTaxRates] = useState<TaxRate[]>([taxRateDefault]);
  const [taxExemptions, setTaxExemptions] = useState<TaxExemption[]>([
    exemptionDefault,
  ]);
  const [taxYear, setTaxYear] = useState<string>('');

  const [currentStep, setCurrentStep] = useState(0);
  const [stepPassedValidation, setStepPassedValidation] =
    useState<boolean>(true);

  const [taxPeriodValid, setTaxPeriodValid] = useState<boolean>(false);
  const [taxAuthorityDetailsValid, setTaxingAuthorityDetailsValid] =
    useState<boolean>(false);
  const [taxAddressValid, setTaxAddressValid] = useState<boolean>(false);

  const processModel = () => {
    if (taxingAuthority.fiscalDates) {
      if (
        taxingAuthority.fiscalDates[0].beginDate === null &&
        taxingAuthority.fiscalDates[0].endDate === null
      ) {
        delete taxingAuthority.fiscalDates;
      } else {
        taxingAuthority.fiscalDates[0].taxYear = taxYear;
      }
    }

    taxExemptions.forEach((item) => {
      item.taxYear = taxYear;
    });

    taxRates.forEach((item) => {
      item.taxYear = taxYear;
    });
  };

  const saveTaxingAuthority = () => {
    deleteContactsIfEmpty(taxingAuthority);
    processModel();
  };

  const validateSteps = () => {
    let isValid = true;

    switch (currentStep) {
      case 0:
        isValid = taxAuthorityDetailsValid;
        break;
      case 1:
        isValid = taxPeriodValid;
        break;
      case 2:
        isValid = taxAddressValid;
        break;
    }
    setStepPassedValidation(isValid);
  };

  useEffect(() => {
    validateSteps();
  });

  useEffect(() => {
    dispatch(setHeader({ pageTitle: t('pages.manageTaxingAuthority.title') }));
  }, [dispatch, t]);

  return (
    <Card sx={{ minWidth: 1500, maxWidth: 1500 }}>
      <CardContent>
        <Grid container spacing={4}>
          <Grid item xs={12} mt={4}>
            <HorizontalNonLinearStepper
              steps={[
                t('pages.manageTaxingAuthority.taxAuthorityDetails'),
                t('pages.manageTaxingAuthority.taxRatesExemptions'),
                t('pages.manageTaxingAuthority.address'),
              ]}
              currentStep={currentStep}
              handleCurrentStep={(activeStep: number) => {
                setCurrentStep(activeStep);
              }}
              handleSave={saveTaxingAuthority}
              isCompleteStepBtnDisabled={!stepPassedValidation}
              resetBtnLabel={t(
                'pages.manageTaxingAuthority.createTaxingAuthority'
              )}
            >
              {currentStep === 0 && (
                <TaxingAuthorityDetails
                  taxingAuthority={taxingAuthority}
                  setTaxingAuthority={setTaxingAuthorityId}
                  setTaxingAuthorityDetailsValid={
                    setTaxingAuthorityDetailsValid
                  }
                />
              )}
              {currentStep === 1 && (
                <TaxingAuthorityPeriod
                  setIsPeriodValid={setTaxPeriodValid}
                  taxRates={taxRates}
                  taxExemptions={taxExemptions}
                  setTaxRates={setTaxRates}
                  setTaxExemptions={setTaxExemptions}
                  taxYear={taxYear}
                  setTaxYear={setTaxYear}
                />
              )}
              {currentStep === 2 && (
                <TaxingAuthorityAddress
                  taxingAuthority={taxingAuthority}
                  setTaxingAuthority={setTaxingAuthorityId}
                  setTaxAddressValid={setTaxAddressValid}
                />
              )}
            </HorizontalNonLinearStepper>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default ManageTaxingAuthority;
