import {
  Box,
  FormLabel,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { Control, Controller, UseFormWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Channel } from 'types/channels';
export const labelStyle = (theme: Theme) => {
  return {
    fontWeight: theme.typography.fontWeightMedium,
    color: '#333',
    borderBottom: 'none',
    '& .MuiFormLabel-asterisk': {
      fontWeight: theme.typography.fontWeightMedium,
    },
  };
};
interface Days {
  value: number;
  label: string;
}
interface Props {
  index: number;
  control: Control<Channel, unknown>;
  watch: UseFormWatch<Channel>;
}
// TODO: replace with lookup once created
const daysOfWeek: Days[] = [
  { value: 0, label: 'Sun' },
  { value: 1, label: 'Mon' },
  { value: 2, label: 'Tue' },
  { value: 3, label: 'Wed' },
  { value: 4, label: 'Thu' },
  { value: 5, label: 'Fri' },
  { value: 6, label: 'Sat' },
];
function WeekDays({ index, control, watch }: Props): JSX.Element {
  const { t } = useTranslation();

  const watchDays = watch(
    `Channel.Pipeline.PipelineSchedule.${index}.RecurrenceSchedule.WeekDays`
  );

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box>
          <FormLabel sx={labelStyle} id="weekDays">
            {t('pages.manageChannel.runDays')}
          </FormLabel>
        </Box>
        <Box>
          <Controller
            control={control}
            name={`Channel.Pipeline.PipelineSchedule.${index}.RecurrenceSchedule.WeekDays`}
            render={({ field: { value, onChange, ...fieldProps } }) => (
              <ToggleButtonGroup
                {...fieldProps}
                value={value}
                onChange={(event, newValue: number) => onChange(newValue)}
                aria-label="week days"
              >
                {daysOfWeek.map((day) => (
                  <ToggleButton
                    key={day.value}
                    value={day.value}
                    sx={[
                      (theme) =>
                        watchDays?.includes(day.value)
                          ? {
                              backgroundColor: theme.palette.primary.main,
                              ':hover': {
                                backgroundColor: 'linkBlue.main',
                                color: 'white.main',
                              },
                            }
                          : {
                              backgroundColor: 'white.main',
                              color: theme.palette.grey1.main,
                              ':hover': {
                                backgroundColor: 'linkBlue.main',
                                color: 'white.main',
                              },
                            },
                      { minWidth: '6.4em' },
                      { marginLeft: '.2em' },
                      { border: '1px solid' },
                      { marginRight: '0.2rem' },
                      { borderLeft: 'solid 1px !important' },
                      { borderRadius: '4px !important' },
                    ]}
                  >
                    {day.label}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            )}
          />
        </Box>
      </Box>
    </>
  );
}
export default WeekDays;
