import { useEffect } from 'react';
import Loading from 'components/Loading';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { setHeader } from 'redux/contentSlice';
import { useAppDispatch } from 'redux/hooks';

import { useGetReusableContentTypeByIdQuery } from 'generated/graphql';
import CorrespondenceDetails from 'components/correspondenceAndHeaderFooter/detailsPage/DetailsPage';
import { EmptyReusableContentRecord } from '../../../../components/correspondenceAndHeaderFooter/common/InitialTableRecords';

function ManageReusableContent(): JSX.Element {
  const { t } = useTranslation();

  const { contentId, action } = useParams() as {
    contentId: string;
    action: string;
  };

  const dispatch = useAppDispatch();

  const {
    data: reUsablecnt,
    isFetching: isFetching,
    refetch,
  } = useGetReusableContentTypeByIdQuery({
    getReusableContentTypeByIdId: parseInt(contentId),
  });

  enum ActionType {
    EDIT = 'edit',
    CLONE = 'clone',
    CREATE = 'create',
  }
  useEffect(() => {
    dispatch(
      setHeader({
        pageTitle:
          action === ActionType.EDIT
            ? t('pages.reusableContent.details')
            : t('pages.manageReusableContent.title'),
      })
    );
  }, [dispatch, t, action, ActionType]);

  return (
    <>
      {isFetching && <Loading />}

      {!isFetching && reUsablecnt?.GetReusableContentTypeById ? (
        <CorrespondenceDetails
          contentData={
            reUsablecnt?.GetReusableContentTypeById ?? [
              EmptyReusableContentRecord,
            ]
          }
          templateData={[]}
          isFromTemplate={false}
          reFetch={refetch}
          correspondenceId={1}
          rcId={parseInt(contentId)}
          rolesData={[]}
        />
      ) : null}
    </>
  );
}

export default ManageReusableContent;
