import { useState } from 'react';

import {
  LogixGroupType,
  LogixContextResponse,
  DestinationObject,
  ConfigLogix,
  CompileExpressionResponse,
  GetViewLogixFunctionByType,
  FieldsFromExpressionResponse,
  TestExpressionResponse,
  RuleOutputs,
  LogixActionRules,
  LogixMessage,
  DocumentTemplates,
} from '@revenue-solutions-inc/revxcoreui/material/logix/types/entities';
import useMutationRequest from 'hooks/useMutationRequest';
import checkCompileExpession from 'pages/admin/LogixTestHarness/checkCompileExpession';
import preProcessCheckCompileExpession from 'pages/admin/LogixTestHarness/preProcessCompileExpression';
import useGetAccessToken from 'hooks/useGetAccessToken';
import { ErrorResponse } from 'types/graphqlErrors';
import {
  ConfigurationResponse,
  useGetActionsQuery,
  useGetConfigurationRecordsQuery,
  useGetFormTemplatesQuery,
  useGetMessagesQuery,
  useGetViewLogixFunctionByTypeQuery,
  useGetViewPreProcessLogixFunctionQuery,
  useGetWorkflowAttributesQuery,
  useGetWorkflowTypeAndSubTypeQuery,
  WorkflowAttributes,
} from 'generated/graphql';
import getFieldFromExpression from 'pages/admin/LogixTestHarness/getFieldFromExpression';
import testExpression from 'pages/admin/LogixTestHarness/testExpression';
import {
  Logix,
  FormErrors,
  RootAttributes,
  WorkflowTypesAndSubTypes,
} from '@revenue-solutions-inc/revxcoreui';
import { JsonPath } from 'utils/filterJsonData';

import {
  GetFieldsFromExpression,
  TestExpression,
} from '../../pages/admin/LogixTestHarness/entities';

interface WorkflowProps {
  code: string;
  id: number;
}
interface ActionProps {
  name: string;
  category: string;
  repeatable: boolean;
  statusToBeChanged: string;
}

export interface LogiXProps {
  data: LogixGroupType;
  handleSelectedField: (response: LogixContextResponse) => void;
  config: ConfigLogix;
  showDestination: boolean;
  dataDestination?: DestinationObject;
  cleanSelectedFields: boolean;
  getJson: boolean;
  handleGetLogixUpdate: (logixUpdated: LogixGroupType) => void;
  formErrors?: FormErrors[];
}

function getCustomAttributes(
  data: WorkflowAttributes[] | undefined
): RootAttributes[] {
  if (!data) return [];
  const workflowAttributes: RootAttributes[] = [];

  data.forEach((elem) => {
    if (elem.customAttributes) {
      workflowAttributes.push(...(elem.customAttributes as RootAttributes[]));
    }
  });

  return workflowAttributes;
}

function getActionTypes(
  data: ConfigurationResponse[] | undefined
): ActionProps[] {
  if (!data) return [];
  const acTypes: ActionProps[] = [];

  data.forEach((acType) => {
    const logixFields = JsonPath({
      keyName: 'groupName',
      value: 'Configuration',
      schema: acType,
      searchWith: '',
    });
    const objAct = {
      actionCategory: '',
      actionRepetable: false,
      actionStatus: '',
    };
    if (logixFields.length > 0) {
      objAct.actionCategory =
        logixFields[0].value.attribute.find(
          (it: { attributeName: string }) => it.attributeName === 'Category'
        )?.attributeValue ?? '';
      objAct.actionRepetable =
        logixFields[0].value.attribute.find(
          (it: { attributeName: string }) => it.attributeName === 'Repeatable'
        )?.attributeValue ?? false;
      objAct.actionStatus =
        logixFields[0].value.attribute.find(
          (it: { attributeName: string }) =>
            it.attributeName === 'StatusToBeChanged'
        )?.attributeValue ?? false;
    }
    const action: ActionProps = {
      name: acType.configurationName,
      category: objAct.actionCategory,
      repeatable: Boolean(objAct.actionRepetable),
      statusToBeChanged: objAct.actionStatus,
    };
    acTypes.push(action);
  });

  return acTypes;
}

function LogiX({
  data,
  handleSelectedField,
  config,
  showDestination,
  dataDestination,
  cleanSelectedFields,
  getJson,
  handleGetLogixUpdate,
  formErrors = [],
}: LogiXProps): JSX.Element {
  const accessToken = useGetAccessToken();

  const mutationRequest = useMutationRequest<CompileExpressionResponse>();
  const mutationRequestField =
    useMutationRequest<FieldsFromExpressionResponse[]>();
  const mutationRequestTestExpression =
    useMutationRequest<TestExpressionResponse>();

  //TODO: i will use this when onError event is working
  //const { mutate } = useCheckCompileExpressionMutation();
  const { data: dataLogixFunctions } = useGetViewLogixFunctionByTypeQuery({
    type: 'Field',
  });
  const preProcessCheckCompileExpresion = async (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataSend: any
  ): Promise<CompileExpressionResponse> => {
    return new Promise((resolve, reject) => {
      mutationRequest.mutate(
        {
          query: preProcessCheckCompileExpession,
          params: dataSend,
          paramsId: 'rootLogix',
          mutationKey: 'preProcessCheckCompileExpession',
          token: accessToken ?? '',
        },
        {
          onSuccess: async (
            response: ErrorResponse | CompileExpressionResponse
          ) => {
            if ((response as ErrorResponse).errors) {
              reject((response as ErrorResponse).errors);
            } else {
              resolve(response as CompileExpressionResponse);
            }
          },
          onError: async (error) => {
            reject(error);
          },
        }
      );
    });
  };

  const { data: actionsProp } = useGetActionsQuery();
  const { data: messagesProp } = useGetMessagesQuery();

  const { data: dataPreProcessLogixFunctions } =
    useGetViewPreProcessLogixFunctionQuery() || {};

  const [typeObj, setTypeobj] = useState<WorkflowProps | null>(null);
  const [subTypeObj, setSubTypeobj] = useState<WorkflowProps | null>(null);

  const { data: workflowTypesAndSubTypesQuery } =
    useGetWorkflowTypeAndSubTypeQuery();

  const { data: dataWorkflowAttributes } = useGetWorkflowAttributesQuery(
    {
      typeCode: typeObj?.code,
      subTypeCode: subTypeObj?.code,
    },
    {
      enabled: typeObj !== null && subTypeObj !== null,
    }
  );

  const getFormTemplatesResults = useGetFormTemplatesQuery({
    formCategory: 'Taxpayer',
  });
  const docTypes = getFormTemplatesResults?.data;

  const getRuleOutputs = () => {
    return new Promise<RuleOutputs>((resolve) => {
      resolve({
        Success: {
          Condition: 'Success',
          Actions: [
            ...(actionsProp?.getActions as unknown as LogixActionRules[]),
          ],
          Messages: [
            ...(messagesProp?.getMessages as unknown as LogixMessage[]),
          ],
        },
        Failure: {
          Condition: 'Failure',
          Actions: [
            ...(actionsProp?.getActions as unknown as LogixActionRules[]),
          ],
          Messages: [
            ...(messagesProp?.getMessages as unknown as LogixMessage[]),
          ],
        },
      });
    });
  };

  const checkCompileExpresion = async (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataSend: any
  ): Promise<CompileExpressionResponse> => {
    return new Promise((resolve, reject) => {
      //TODO: this will replace the oher mutation when it have the onError function working
      // mutate(
      //   {
      //     RootLogix: dataSend,
      //   },
      //   {
      //     onSuccess: async (response) => {
      //       if (response.checkCompileExpression.Result) {
      //         resolve(response as unknown as CompileExpressionResponse);
      //       } else {
      //         reject((response as unknown as ErrorResponse).errors);
      //       }
      //     },
      //   }
      // );

      //TODO: this will the mutate to replace
      mutationRequest.mutate(
        {
          query: checkCompileExpession,
          params: dataSend,
          paramsId: 'RootLogix',
          mutationKey: 'checkCompileExpression',
          token: accessToken ?? '',
        },
        {
          onSuccess: async (
            response: ErrorResponse | CompileExpressionResponse
          ) => {
            if ((response as ErrorResponse).errors) {
              reject((response as ErrorResponse).errors);
            } else {
              resolve(response as CompileExpressionResponse);
            }
          },
          onError: async (error) => {
            reject(error);
          },
        }
      );
    });
  };

  const getFieldFromExpressionAPI = async (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fields: any
  ): Promise<FieldsFromExpressionResponse[]> => {
    return new Promise((resolve, reject) => {
      //TODO: this will replaced by the new way once the errors handling has been added
      mutationRequestField.mutate(
        {
          query: getFieldFromExpression,
          params: fields,
          paramsId: 'fields',
          mutationKey: 'getFieldsFromExpression',
          token: accessToken ?? '',
        },
        {
          onSuccess: async (
            response:
              | ErrorResponse
              | GetFieldsFromExpression
              | FieldsFromExpressionResponse[]
          ) => {
            if ((response as ErrorResponse).errors) {
              reject((response as ErrorResponse).errors);
            } else {
              const resp = response as GetFieldsFromExpression;
              resolve(resp.data.getFieldsFromExpression);
            }
          },
          onError: async (error) => {
            reject(error);
          },
        }
      );
    });
  };

  const testExpressionAPI = async (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    expression: any
  ): Promise<TestExpressionResponse> => {
    return new Promise((resolve, reject) => {
      //TODO: this will replaced by the new way once the errors handling has been added
      mutationRequestTestExpression.mutate(
        {
          query: testExpression,
          params: expression,
          paramsId: 'expression',
          mutationKey: 'getFieldsFromExpression',
          token: accessToken ?? '',
        },
        {
          onSuccess: async (
            response: ErrorResponse | TestExpressionResponse | TestExpression
          ) => {
            if ((response as ErrorResponse).errors) {
              reject((response as ErrorResponse).errors);
            } else {
              const resp = response as TestExpression;
              resolve(resp.data.testExpression);
            }
          },
          onError: async (error) => {
            reject(error);
          },
        }
      );
    });
  };

  const { data: dataActionTypes } = useGetConfigurationRecordsQuery({
    configurationModule: '',
    configurationType: 'Form Action Type',
  });

  const actionsTypes = getActionTypes(dataActionTypes?.getConfigurations);

  const workFlowAttributes = getCustomAttributes(
    dataWorkflowAttributes?.GetWorkflowAttributes
  );

  const preProcessLogixFunctOpt: GetViewLogixFunctionByType[] | [] =
    (dataPreProcessLogixFunctions?.GetViewPreProcessLogixFunction as GetViewLogixFunctionByType[]) ??
    [];
  // check this types problems and remove the cast
  const logixFunctOpt: GetViewLogixFunctionByType[] | [] =
    (dataLogixFunctions?.GetViewLogixFunctionByType as GetViewLogixFunctionByType[]) ??
    [];

  const workFlowTypesAndSubTypes: WorkflowTypesAndSubTypes[] | [] =
    (workflowTypesAndSubTypesQuery?.GetWorkflowTypeAndSubType as WorkflowTypesAndSubTypes[]) ??
    [];

  const documentTypes: DocumentTemplates[] | [] =
    (docTypes?.getFormTemplates as DocumentTemplates[]) ?? [];

  return (
    <Logix
      data={data}
      handleSelectedField={handleSelectedField}
      config={config}
      showDestination={showDestination}
      dataDestination={dataDestination}
      cleanSelectedFields={cleanSelectedFields}
      getJson={getJson}
      handleGetLogixUpdate={handleGetLogixUpdate}
      checkCompileExpresionAPI={checkCompileExpresion}
      logiXFunctionOpt={logixFunctOpt}
      getFieldFromExpressionAPI={getFieldFromExpressionAPI}
      testExpressionAPI={testExpressionAPI}
      getPossibleRuleOutputs={getRuleOutputs}
      formErrors={formErrors}
      actionsTypes={actionsTypes}
      setTypeobj={setTypeobj}
      setSubTypeobj={setSubTypeobj}
      workFlowAttributes={workFlowAttributes}
      workFlowTypesAndSubTypes={workFlowTypesAndSubTypes}
      preProcessCheckCompileExpresionAPI={preProcessCheckCompileExpresion}
      preProcesslogiXFunctionOpt={preProcessLogixFunctOpt}
      docTypes={documentTypes}
    />
  );
}

export default LogiX;
