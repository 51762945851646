import { Dispatch, SetStateAction } from 'react';
import { useTheme } from '@mui/system';
import { Check, Close, Edit } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { Card, CardContent } from '@revenue-solutions-inc/revxcoreui';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import { ColumnDef } from '@tanstack/react-table';
import Loading from 'components/Loading';
import { BankAccount } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getFormatDate } from 'utils/date-util';

type BankAccountsListProps = {
  data: BankAccount[] | undefined;
  isLoading: boolean;
  setEditBankAccountModalOpen: Dispatch<SetStateAction<boolean>>;
  setSelectedBankAccountId: (id: string) => void;
};

function BankAccountsList({
  data,
  isLoading,
  setEditBankAccountModalOpen,
  setSelectedBankAccountId,
}: BankAccountsListProps): JSX.Element {
  const { t } = useTranslation();
  const theme = useTheme();

  const handleEditBankAccount = (id: string) => {
    setSelectedBankAccountId(id);
    setEditBankAccountModalOpen(true);
  };

  const BankAccountListColumns: ColumnDef<BankAccount>[] = [
    {
      id: 'ownership',
      accessorKey: 'ownership',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.bankAccount.bankAccountData.accountName')}
        />
      ),
    },
    {
      id: 'bankAccountType',
      accessorKey: 'bankAccountType',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.bankAccount.bankAccountData.accountType')}
        />
      ),
    },
    {
      id: 'routingNumber',
      accessorKey: 'routingNumber',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.bankAccount.bankAccountData.routingNumber')}
        />
      ),
      cell: ({ getValue, row }) => (
        <Link
          to={{ pathname: `bankaccount/${row.original.id}` }}
          style={{ color: theme.palette.linkBlue.dark }}
        >
          {getValue() as string}
        </Link>
      ),
    },
    {
      id: 'financialInstitution',
      accessorKey: 'financialInstitution',
      header: () => (
        <HeaderColumnNext
          localization={t(
            'pages.bankAccount.bankAccountData.financialInsitution'
          )}
        />
      ),
    },
    {
      id: 'bankAccountNumber',
      accessorKey: 'bankAccountNumber',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.bankAccount.bankAccountData.accountNumber')}
        />
      ),
      cell: ({ getValue, row }) => (
        <Link
          to={{ pathname: `bankaccount/${row.original.id}` }}
          style={{ color: theme.palette.linkBlue.dark }}
        >
          {getValue() as string}
        </Link>
      ),
    },
    {
      id: 'commenceDate',
      accessorKey: 'commenceDate',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.bankAccount.bankAccountData.commenceDate')}
        />
      ),
      cell: ({ getValue }) =>
        getFormatDate(new Date((getValue() as string) || '')),
    },
    {
      id: 'ceaseDate',
      accessorKey: 'ceaseDate',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.bankAccount.bankAccountData.ceaseDate')}
        />
      ),
      cell: ({ getValue }) =>
        getFormatDate(new Date((getValue() as string) || '')),
    },
    {
      id: 'status',
      accessorKey: 'status',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.bankAccount.bankAccountData.status')}
        />
      ),
    },
    {
      id: 'isRelatedToActiveWorkItem',
      accessorKey: 'isRelatedToActiveWorkItem',
      header: () => (
        <HeaderColumnNext
          localization={t(
            'pages.bankAccount.bankAccountData.isRelatedToActiveWorkItem'
          )}
        />
      ),
      cell: ({ row }) => {
        const isRelatedToActiveWorkItem =
          row.original.isRelatedToActiveWorkItem;
        if (!isRelatedToActiveWorkItem) return <Close />;
        return <Check />;
      },
    },
    {
      id: 'id',
      accessorKey: 'id',
      header: () => <HeaderColumnNext localization={t('')} />,
      cell: ({ row }) => {
        const id = row.original.id ?? '';
        return (
          <IconButton onClick={() => handleEditBankAccount(id)}>
            <Edit />
          </IconButton>
        );
      },
    },
  ];

  return (
    <>
      <Card>
        <CardContent>
          {isLoading && <Loading fullScreen={false} />}
          {!isLoading && data && data.length > 0 && (
            <DefaultDataTableNext
              data={data}
              columns={
                BankAccountListColumns as ColumnDef<
                  Record<string, unknown>,
                  unknown
                >[]
              }
            ></DefaultDataTableNext>
          )}
        </CardContent>
      </Card>
    </>
  );
}

export default BankAccountsList;
