import { useCallback, useEffect, useMemo, useState } from 'react';
import { Grid, SelectChangeEvent, Theme, Typography } from '@mui/material';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  DataDisplay,
  Input,
  MessageActionType,
  MessageType,
  Select,
} from '@revenue-solutions-inc/revxcoreui';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import { ColumnDef } from '@tanstack/react-table';

import { useTranslation } from 'react-i18next';
import DataCard from 'components/DataCard';
import { format } from 'date-fns';
import { dateFormat } from 'utils/date-util';
import {
  useGetActiveCorrespondenceTypesPrintGroupQuery,
  useGetAllPrintGroupsQuery,
  useUpdateCorrespondenceTypePrintGroupMutation,
  useGetLookupTypesConfigurationQuery,
} from 'generated/graphql';
import { SelectType } from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import { setHeader } from 'redux/contentSlice';
import { useAppDispatch } from 'redux/hooks';
import Loading from 'components/Loading';
import { addMessage } from 'redux/messageSlice';
import {
  ConfigurationDomains,
  ConfigurationModules,
} from 'common/platformConfigUtils/platformConfigUtils';

function PrintManagement(): JSX.Element {
  type PrintManagerData = {
    correspondenceTypeId: number;
    correspondenceTypeName: string;
    assignment_Standard: number;
    assignment_FTI: number;
    assignment_StandardOverLimit: number;
    assignment_FTIOverLimit: number;
    updatedDate: string;
  };
  const defaultPrintManagerData: PrintManagerData = {
    correspondenceTypeId: 0,
    correspondenceTypeName: '',
    assignment_Standard: 0,
    assignment_FTI: 0,
    assignment_StandardOverLimit: 0,
    assignment_FTIOverLimit: 0,
    updatedDate: '',
  };
  type ChannelData = {
    printGroupName: string;
    printGroupDescription: string;
    printGroup: string;
  };
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [data, setData] = useState<PrintManagerData>(defaultPrintManagerData);
  const [overLimit, setOverLimit] = useState('5');
  const [dropDown, setDropDown] = useState<ChannelData[]>([
    {
      printGroupName: 'Standard',
      printGroupDescription: 'Pages printed under the Over Limit. Non-FTI',
      printGroup: '',
    },
    {
      printGroupName: 'FTI',
      printGroupDescription: 'Pages printed under the Over Limit. FTI',
      printGroup: '',
    },
    {
      printGroupName: 'Over Limit',
      printGroupDescription: 'Pages printed over the Over Limit. Non-FTI',
      printGroup: '',
    },
    {
      printGroupName: 'Over Limit FTI',
      printGroupDescription: 'Pages printed over the Over Limit. FTI',
      printGroup: '',
    },
  ]);
  const MAIN_CONFIG_MODULE = ConfigurationModules.Platform;
  const MAIN_CONFIG_DOMAIN = ConfigurationDomains.Correspondence;
  const { data: configuredIdTypes, refetch: refetchConfig } =
    useGetLookupTypesConfigurationQuery({
      configurationDomain: MAIN_CONFIG_DOMAIN,
      configurationModule: MAIN_CONFIG_MODULE,
      configurationType: 'CorrespondenceConstants',
    });

  useEffect(() => {
    dispatch(
      setHeader({
        pageTitle: t('pages.printManagement.title'),
        previousPage: t('pages.templates.title'),
        route: `correspondenceTypes`,
      })
    );
  }, [dispatch, t]);

  const handlePrintGroup = useCallback(
    (event: SelectChangeEvent<string | unknown>, printGroupName: string) => {
      const printGroups = dropDown.map((item) => {
        if (item.printGroupName === printGroupName)
          item.printGroup = String(event.target.value);
        return item;
      });
      setDropDown([...printGroups]);
    },
    [dropDown]
  );

  const {
    data: ActiveCorrespondenceTypesPrintGroupData,
    refetch: refetchActiveCorrespondenceTypesPrintGroup,
    isLoading,
  } = useGetActiveCorrespondenceTypesPrintGroupQuery();

  const { data: GetAllPrintGroupsData } = useGetAllPrintGroupsQuery();

  const { mutate: UpdateCorrespondenceTypePrintGroup } =
    useUpdateCorrespondenceTypePrintGroupMutation();

  const printGroupFti: SelectType[] = useMemo(() => {
    const list: SelectType[] = [];
    list.push({
      key: '0',
      desc: 'Choose an option',
    });
    GetAllPrintGroupsData?.getAllPrintGroups.map((cat) => {
      if (cat.fti)
        list.push({
          key: String(cat.printGroupId),
          desc: String(cat.name),
        });
    });
    return list;
  }, [GetAllPrintGroupsData]);

  const printGroupStd: SelectType[] = useMemo(() => {
    const list: SelectType[] = [];
    list.push({
      key: '0',
      desc: 'Choose an option',
    });
    GetAllPrintGroupsData?.getAllPrintGroups.map((cat) => {
      if (!cat.fti)
        list.push({
          key: String(cat.printGroupId),
          desc: String(cat.name),
        });
    });
    return list;
  }, [GetAllPrintGroupsData]);

  const handleSave = () => {
    UpdateCorrespondenceTypePrintGroup(
      {
        corrTypePrintGroup: {
          assignment_FTI: parseInt(
            dropDown.filter((item) => item.printGroupName == 'FTI')[0]
              .printGroup
          ),
          assignment_FTIOverLimit: parseInt(
            dropDown.filter(
              (item) => item.printGroupName == 'Over Limit FTI'
            )[0].printGroup
          ),
          assignment_Standard: parseInt(
            dropDown.filter((item) => item.printGroupName == 'Standard')[0]
              .printGroup
          ),
          assignment_StandardOverLimit: parseInt(
            dropDown.filter((item) => item.printGroupName == 'Over Limit')[0]
              .printGroup
          ),
          correspondenceTypeId: data.correspondenceTypeId,
        },
      },
      {
        onSuccess: () => {
          refetchActiveCorrespondenceTypesPrintGroup();
          dispatch(
            addMessage({
              message: t('pages.printManagement.message.updatePrintGroup'),
              type: MessageType.Success,
              actionType: MessageActionType.None,
            })
          );
        },
        onError: () => {
          dispatch(
            addMessage({
              message: t('pages.printManagement.message.error'),
              type: MessageType.Error,
              actionType: MessageActionType.None,
            })
          );
        },
      }
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fetchPrintManagementDetails = (row: any) => {
    setData((prev) => ({
      ...prev,
      correspondenceTypeName: row.original.correspondenceTypeName as string,
      updatedDate:
        row.original.updatedDate == '0001-01-01T00:00:00+00:00'
          ? ''
          : format(new Date(row.original.updatedDate), dateFormat),
      correspondenceTypeId: row.original.correspondenceTypeId,
    }));
    const printGroups = dropDown.map((item) => {
      if (item.printGroupName === 'Standard')
        item.printGroup =
          row.original.assignment_Standard == 0
            ? '0'
            : String(row.original.assignment_Standard);
      if (item.printGroupName === 'FTI')
        item.printGroup =
          row.original.assignment_FTI == 0
            ? '0'
            : String(row.original.assignment_FTI);
      if (item.printGroupName === 'Over Limit')
        item.printGroup =
          row.original.assignment_StandardOverLimit == 0
            ? '0'
            : String(row.original.assignment_StandardOverLimit);
      if (item.printGroupName === 'Over Limit FTI')
        item.printGroup =
          row.original.assignment_FTIOverLimit == 0
            ? '0'
            : String(row.original.assignment_FTIOverLimit);
      return item;
    });
    setDropDown([...printGroups]);
  };
  const printManagementColumns: ColumnDef<PrintManagerData>[] = useMemo(() => {
    return [
      {
        header: () => (
          <HeaderColumnNext
            localization={t('pages.printManagement.correspondenceTypes')}
          />
        ),
        accessorKey: 'correspondenceTypeName',
      },
      {
        header: () => (
          <HeaderColumnNext
            localization={t('pages.printManagement.standard')}
          />
        ),
        accessorKey: 'assignment_Standard',
        cell: ({ row }) => {
          if (GetAllPrintGroupsData?.getAllPrintGroups) {
            return (
              <Typography>
                {GetAllPrintGroupsData?.getAllPrintGroups.filter(
                  (item) =>
                    item.printGroupId == row.original.assignment_Standard
                ).length > 0
                  ? GetAllPrintGroupsData?.getAllPrintGroups.filter(
                      (item) =>
                        item.printGroupId == row.original.assignment_Standard
                    )[0].name
                  : 'NA'}
              </Typography>
            );
          }
        },
      },
      {
        header: () => (
          <HeaderColumnNext localization={t('pages.printManagement.fti')} />
        ),
        accessorKey: 'assignment_FTI',
        cell: ({ row }) => {
          if (GetAllPrintGroupsData?.getAllPrintGroups) {
            return (
              <Typography>
                {GetAllPrintGroupsData?.getAllPrintGroups.filter(
                  (item) => item.printGroupId == row.original.assignment_FTI
                ).length > 0
                  ? GetAllPrintGroupsData?.getAllPrintGroups.filter(
                      (item) => item.printGroupId == row.original.assignment_FTI
                    )[0].name
                  : 'NA'}
              </Typography>
            );
          }
        },
      },
      {
        header: () => (
          <HeaderColumnNext
            localization={t('pages.printManagement.overLimit')}
          />
        ),
        accessorKey: 'assignment_StandardOverLimit',
        cell: ({ row }) => {
          if (GetAllPrintGroupsData?.getAllPrintGroups) {
            return (
              <Typography>
                {GetAllPrintGroupsData?.getAllPrintGroups.filter(
                  (item) =>
                    item.printGroupId ==
                    row.original.assignment_StandardOverLimit
                ).length > 0
                  ? GetAllPrintGroupsData?.getAllPrintGroups.filter(
                      (item) =>
                        item.printGroupId ==
                        row.original.assignment_StandardOverLimit
                    )[0].name
                  : 'NA'}
              </Typography>
            );
          }
        },
      },
      {
        header: () => (
          <HeaderColumnNext
            localization={t('pages.printManagement.overLimitFti')}
          />
        ),
        accessorKey: 'assignment_FTIOverLimit',
        cell: ({ row }) => {
          if (GetAllPrintGroupsData?.getAllPrintGroups) {
            return (
              <Typography>
                {GetAllPrintGroupsData?.getAllPrintGroups.filter(
                  (item) =>
                    item.printGroupId == row.original.assignment_FTIOverLimit
                ).length > 0
                  ? GetAllPrintGroupsData?.getAllPrintGroups.filter(
                      (item) =>
                        item.printGroupId ==
                        row.original.assignment_FTIOverLimit
                    )[0].name
                  : 'NA'}
              </Typography>
            );
          }
        },
      },
    ];
  }, [GetAllPrintGroupsData?.getAllPrintGroups, t]);
  const outputChannelColumns: ColumnDef<ChannelData>[] = useMemo(() => {
    return [
      {
        header: () => (
          <HeaderColumnNext
            localization={t('pages.printManagement.printGroupName')}
          />
        ),
        accessorKey: 'printGroupName',
      },
      {
        header: () => (
          <HeaderColumnNext
            localization={t('pages.printManagement.printGroupDescription')}
          />
        ),
        accessorKey: 'printGroupDescription',
      },
      {
        header: () => (
          <HeaderColumnNext
            localization={t('pages.printManagement.printGroup')}
          />
        ),
        accessorKey: 'printGroup',
        cell: ({ row }) => (
          <Select
            label={''}
            id={row.original.printGroupName}
            value={
              dropDown.filter(
                (item) => item.printGroupName == row.original.printGroupName
              )[0].printGroup
            }
            options={
              row.original.printGroupName.toLowerCase().includes('fti')
                ? printGroupFti
                : printGroupStd
            }
            onChange={(e) => handlePrintGroup(e, row.original.printGroupName)}
          ></Select>
        ),
      },
    ];
  }, [t, dropDown, printGroupFti, printGroupStd, handlePrintGroup]);

  useEffect(() => {
    if (configuredIdTypes?.GetLookupTypesConfiguration)
      setOverLimit(
        configuredIdTypes?.GetLookupTypesConfiguration[0]
          .platformConfigurationInfo?.configurationSection[0].group[0]
          .attribute[0].attributeValue ?? ''
      );
    else refetchConfig();
  }, [configuredIdTypes?.GetLookupTypesConfiguration, refetchConfig]);

  return (
    <>
      <DataDisplay
        id={'overLimit'}
        label={t('pages.printManagement.currentOverLimit')}
        data={`${overLimit} Pages`}
        sx={{ display: 'inline-block', mb: 1 }}
      />
      <Grid container spacing={3}>
        <Grid item xs={6} md={6}>
          <Card
            sx={{
              borderRadius: '5px',
              boxShadow: '0px 0px 5px rgb(0 0 0 / 30%)',
            }}
          >
            <CardContent>
              {isLoading && <Loading />}
              {!isLoading &&
                ActiveCorrespondenceTypesPrintGroupData?.getActiveCorrespondenceTypesPrintGroup &&
                ActiveCorrespondenceTypesPrintGroupData
                  ?.getActiveCorrespondenceTypesPrintGroup?.length > 0 && (
                  <DefaultDataTableNext
                    data={
                      ActiveCorrespondenceTypesPrintGroupData?.getActiveCorrespondenceTypesPrintGroup ??
                      []
                    }
                    columns={
                      printManagementColumns as ColumnDef<
                        Record<string, unknown>,
                        unknown
                      >[]
                    }
                    cursorHover={true}
                    fetchCursorSelectedRow={fetchPrintManagementDetails}
                  ></DefaultDataTableNext>
                )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6} md={6}>
          <>
            <DataCard
              title={t('pages.printManagement.correspondenceDetails')}
              avatar={<></>}
              children={
                <>
                  <DataDisplay
                    id={'correspondenceTypes'}
                    label={t('pages.printManagement.correspondenceTypes')}
                    data={''}
                    sxLabel={{ display: 'inline-block', mt: -1 }}
                  />
                  <Input
                    id="corrType"
                    ariaLabel="correspondence type"
                    value={data?.correspondenceTypeName}
                    placeholder="Correspondence type"
                    sx={{ width: '15em' }}
                    disabled
                  />
                  <DataDisplay
                    id={'lastUpdated'}
                    label={t('pages.printManagement.lastUpdated')}
                    data={''}
                    sxLabel={{ display: 'inline-block', mt: 1 }}
                  />
                  <Input
                    id="lastUpdated"
                    ariaLabel="correspondence last updated"
                    value={data?.updatedDate}
                    placeholder="Last Updated"
                    sx={{ width: '15em' }}
                    disabled
                  />
                  <Button
                    id="saveBtn-updateContent"
                    data-testid="update-button"
                    sx={{ minWidth: '128px', ml: 2 }}
                    onClick={handleSave}
                  >
                    {t('pages.correspondence.save')}
                  </Button>
                  <Card
                    sx={{
                      borderRadius: '5px',
                      boxShadow: '0px 0px 5px rgb(0 0 0 / 30%)',
                      mt: 1,
                    }}
                  >
                    <CardHeader
                      sx={(theme: Theme) => {
                        return {
                          backgroundColor: theme.palette.secondary.light,
                          '& .MuiCardHeader-title': {
                            fontSize: '0.875rem',
                            fontWeight: 700,
                          },
                        };
                      }}
                      title={t('pages.printManagement.outputChannel')}
                    />

                    <CardContent>
                      <>
                        <DefaultDataTableNext
                          data={dropDown}
                          columns={
                            outputChannelColumns as ColumnDef<
                              Record<string, unknown>,
                              unknown
                            >[]
                          }
                        ></DefaultDataTableNext>
                      </>
                    </CardContent>
                  </Card>
                </>
              }
            ></DataCard>
          </>
        </Grid>
      </Grid>
    </>
  );
}

export default PrintManagement;
