import { useMemo, useState, useEffect, useCallback, ChangeEvent } from 'react';
import {
  Grid,
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from '@mui/material';

import Loading from 'components/Loading';
import { setHeader } from 'redux/contentSlice';
import { useAppDispatch } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import { useHasEdit } from 'hooks/useHasAccess';
import {
  PowerBiClient,
  MessageActionType,
  MessageType,
} from '@revenue-solutions-inc/revxcoreui';

import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  useGetDashboardQuery,
  useUpdateDashboardMutation,
  useGetViewDashboardQuery,
} from 'generated/graphql';

import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import Select from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import Input from '@revenue-solutions-inc/revxcoreui/material/controls/Input';

type DashboardFormType = {
  name: string;
  description: string;
  displayName: string;
  displayNameError: boolean;
  descriptionError: boolean;
  isPresent: boolean;
};

function PowerBiDashboards(): JSX.Element {
  const { t } = useTranslation();
  const { dashboardId } = useParams() as { dashboardId: string };
  const [isLoadingDashboard, setIsLoadingDashboard] = useState(false);
  const { mutate: updateDashboard } = useUpdateDashboardMutation();
  /* Query getDashboard for the initial inputs values update dashboard form */
  const { data: getDashboard, refetch: refetchDashboard } =
    useGetDashboardQuery({
      dashboard: { dashboardId: Number(dashboardId) },
    });
  const dispatch = useAppDispatch();
  /* Query getViewDashboard for the dashboard pbi */
  const { data: viewDashboard } = useGetViewDashboardQuery({
    dashboard: { dashboardId: Number(dashboardId) },
  });

  const [moduleUpdateReport, setModuleUpdateReport] = useState<
    number | null | undefined
  >(-1);

  const canAction = useHasEdit(
    'powerBiDashboards',
    'reportManagementAccessAll'
  );

  useMemo(() => {
    if (getDashboard !== undefined) {
      setModuleUpdateReport(Number(getDashboard.getDashboard.moduleId));
    }
  }, [getDashboard]);

  /* State to set object of inputs & errors forms */
  const [dashboardForm, setDashboardForm] = useState({
    /* Update Dashboard */
    name: 'null-Dashboard-Name-default',
    description: '',
    displayName: '',
    displayNameError: false,
    descriptionError: false,
    isPresent: false,
  });

  /* This useMemo its used to prevent the non data inputs update dashboard form
  when the user reloads the page. */
  useMemo(() => {
    if (getDashboard !== undefined) {
      setDashboardForm({
        name: String(getDashboard.getDashboard.dashboardName),
        description: String(getDashboard.getDashboard.dashboardDescription),
        displayName: String(getDashboard.getDashboard.dashboardDisplayName),
        displayNameError: false,
        descriptionError: false,
        isPresent: false,
      });
    }
  }, [getDashboard]);

  useEffect(() => {
    dispatch(
      setHeader({
        pageTitle: t('pages.dashboards.dashboardDetails'),
        previousPage: t('pages.dashboards.title'),
        route: `Dashboards/`,
      })
    );
  }, [dispatch, t]);

  const handleSuccess = useCallback(
    (type: string, customMessage: string) => {
      dispatch(
        addMessage({
          message: customMessage,
          type: MessageType.Success,
          actionType: MessageActionType.None,
        })
      );
      if (type === 'report') {
        refetchDashboard()
          .then((e) => {
            if (e.isSuccess && e.isFetching === false) {
              setIsLoadingDashboard(false);
            }
          })
          .catch(() => {});
      } else {
        refetchDashboard()
          .then((e) => {
            if (e.isSuccess && e.isFetching === false && e.data) {
              setIsLoadingDashboard(false);
            }
          })
          .catch(() => {});
      }
    },
    [dispatch, refetchDashboard]
  );

  const handleError = useCallback(() => {
    dispatch(
      addMessage({
        message: t('pages.manageReusableContent.networkError'),
        type: MessageType.Error,
        actionType: MessageActionType.None,
      })
    );
    setIsLoadingDashboard(false);
  }, [dispatch, t]);

  /* This useCallback function request to update dashboard*/
  const updateDashboards = useCallback(
    (
      id: number,
      dashboardDisplayName: string,
      dashboardDescription: string,
      moduleId: number
    ) => {
      setIsLoadingDashboard(true);
      updateDashboard(
        {
          dashboard: {
            dashboardId: id,
            dashboardDisplayName: dashboardDisplayName,
            dashboardDescription: dashboardDescription,
            moduleId: moduleId ? Number(moduleId) : 0,
          },
        },
        {
          onSuccess: () =>
            handleSuccess(
              'report',
              t('pages.dashboards.message.updateDashboard')
            ),
          onError: handleError,
        }
      );
    },
    [handleError, handleSuccess, t, updateDashboard]
  );

  /* This function his called to handle the change value of inputs in all the component*/
  const handleUpdate = (event: ChangeEvent<HTMLInputElement>) => {
    switch (event.target.id) {
      case 'dashboardDisplayName':
        if (event.target.value === ' ') {
          setDashboardForm((prev: DashboardFormType) => ({
            ...prev,
            displayName: '',
            displayNameError: true,
          }));
        } else if (event.target.value.length > 100) {
          setDashboardForm((prev: DashboardFormType) => ({
            ...prev,
            displayNameError: true,
          }));
        } else {
          setDashboardForm((prev: DashboardFormType) => ({
            ...prev,
            displayName: event.target.value,
            displayNameError: false,
          }));
        }
        break;
      case 'dashboardDescription':
        if (event.target.value === ' ') {
          setDashboardForm((prev: DashboardFormType) => ({
            ...prev,
            description: '',
            descriptionError: false,
          }));
        } else if (event.target.value.length > 2000) {
          setDashboardForm((prev: DashboardFormType) => ({
            ...prev,
            descriptionError: true,
          }));
        } else {
          setDashboardForm((prev: DashboardFormType) => ({
            ...prev,
            description: event.target.value,
            descriptionError: false,
          }));
        }
        break;
      default:
        break;
    }
  };

  return (
    <>
      {!isLoadingDashboard && getDashboard !== undefined ? (
        <Box
          sx={{
            display: 'grid',
            alignItems: 'end',
            gridAutoFlow: 'row',
          }}
        >
          <Box sx={{ display: 'grid', alignItems: 'end' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box sx={{ display: 'grid', gridAutoFlow: 'column' }}>
                  <Grid
                    item
                    sx={{
                      display: 'grid',
                      gridAutoFlow: 'column',
                      width: 'fit-content',
                    }}
                  >
                    <Card>
                      <CardHeader
                        title={<Typography>{'Update Dashboard'}</Typography>}
                        sx={{ backgroundColor: '#CED7E4' }}
                      />
                      <CardContent>
                        <Box
                          sx={{
                            display: 'grid',
                            gridAutoFlow: 'row',
                            width: 'fit-content',
                          }}
                        >
                          <Typography>Dashboard Name</Typography>

                          <Input
                            id="dashboardName"
                            ariaLabel="dashboard name"
                            disabled
                            value={dashboardForm.name}
                          />

                          <Typography>Dashboard Display Name</Typography>

                          <Input
                            id="dashboardDisplayName"
                            ariaLabel="dashboard display name"
                            value={dashboardForm.displayName}
                            placeholder={'required'}
                            onChange={handleUpdate}
                            error={
                              dashboardForm.displayNameError ||
                              dashboardForm.displayName === '' ||
                              dashboardForm.displayName.length >= 100
                            }
                            helperText={
                              dashboardForm.displayNameError ||
                              dashboardForm.displayName === '' ||
                              dashboardForm.displayName.length >= 100
                                ? 'Error Display Name'
                                : ''
                            }
                          />

                          <Typography>Dashboard Description</Typography>

                          <Input
                            id="dashboardDescription"
                            ariaLabel="dashboard description"
                            value={dashboardForm.description}
                            disabled={!canAction}
                            placeholder={'required'}
                            onChange={handleUpdate}
                            error={
                              dashboardForm.descriptionError ||
                              dashboardForm.description === '' ||
                              dashboardForm.description.length >= 2000
                            }
                            helperText={
                              dashboardForm.descriptionError ||
                              dashboardForm.description === '' ||
                              dashboardForm.description.length >= 2000
                                ? 'Error Description'
                                : ''
                            }
                          />
                        </Box>
                      </CardContent>
                    </Card>

                    <Grid
                      sx={{
                        display: 'grid',
                        gridAutoFlow: 'row',
                        marginLeft: '8px',
                      }}
                    >
                      <Box>
                        <Select
                          disabledOptions={[]}
                          id="UpdateReportModuleId"
                          disabled={!canAction}
                          label="Module"
                          onChange={(x) =>
                            setModuleUpdateReport(Number(x.target.value))
                          }
                          value={
                            moduleUpdateReport === -1
                              ? String(getDashboard.getDashboard.moduleId)
                              : String(moduleUpdateReport)
                          }
                          options={[
                            {
                              desc: 'Platform',
                              key: '1',
                            },
                            {
                              desc: 'Property',
                              key: '2',
                            },
                            {
                              desc: 'Revenue',
                              key: '3',
                            },
                          ]}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: 'grid',
                          gridAutoFlow: 'column',
                          alignContent: 'end',
                        }}
                      >
                        <Button
                          id="cancelBtn"
                          data-testid="cancelButton"
                          type="secondary"
                          sx={{ mt: 1, mb: 1, ml: 1, minWidth: '128px' }}
                          onClick={() => {
                            setDashboardForm({
                              ...dashboardForm,
                              displayName:
                                getDashboard?.getDashboard
                                  ?.dashboardDisplayName ?? '',
                              description:
                                getDashboard?.getDashboard
                                  ?.dashboardDescription ?? '',
                            });
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          id="saveBtn-updateContent"
                          data-testid="updateButton"
                          sx={{ mt: 1, mb: 1, ml: 1, minWidth: '128px' }}
                          onClick={() => {
                            if (
                              !dashboardForm.displayNameError &&
                              !dashboardForm.descriptionError &&
                              dashboardForm.displayName !== '' &&
                              dashboardForm.description !== ''
                            ) {
                              updateDashboards(
                                Number(dashboardId),
                                dashboardForm.displayName,
                                dashboardForm.description,
                                Number(moduleUpdateReport)
                              );
                            }
                          }}
                        >
                          Save Changes
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <hr
                  style={{
                    marginTop: '24px',
                    border: '0.8px solid rgb(205 205 205)',
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      ) : null}

      {viewDashboard !== undefined ? (
        <PowerBiClient
          data={{
            embedUrl: viewDashboard.getViewDashboard.embedUrl,
            id: viewDashboard.getViewDashboard.dashboardId,
            token: viewDashboard.getViewDashboard.token,
          }}
          type="dashboard"
          description="new description"
          name="new dashboard"
          refetchTokenCreateMode={function noRefCheck() {}}
          refetchTokenEditMode={function noRefCheck() {}}
          registerSave={function noRefCheck() {}}
          registerSaveAs={function noRefCheck() {}}
          typeMode="view"
        />
      ) : (
        <Loading />
      )}
    </>
  );
}

export default PowerBiDashboards;
