import { Fragment, useContext, useState, useEffect } from 'react';

import { Box, Grid, IconButton } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { Theme } from '@mui/system';
import {
  Card,
  CardContent,
  CardHeader,
} from '@revenue-solutions-inc/revxcoreui';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import DatePicker from '@revenue-solutions-inc/revxcoreui/material/controls/DatePicker';
import Dialog from '@revenue-solutions-inc/revxcoreui/material/controls/Dialog';
import Input from '@revenue-solutions-inc/revxcoreui/material/controls/Input';
import Select from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import { ConfigurationDomains } from 'common/platformConfigUtils/platformConfigUtils';
import EntityManagementContext from 'components/contexts/EntityManagement';
import IndustryCodeAutocomplete from 'components/entityManagement/accounts/IndustryCodeAutocomplete';
import {
  getMaxLength,
  getNameFieldsByType,
} from 'components/entityManagement/common/entityUtils';
import { processAddressForm } from 'components/entityManagement/common/entityManagementUtils';
import { getDefault } from 'components/entityManagement/common/defaults/account';
import { commenceDateRule } from 'components/entityManagement/common/fields/entities';
import DatasourceSelect from 'components/DatasourceSelect';
import Loading from 'components/Loading';
import EntityRadioGroup from 'components/RadioGroup';
import { useGetLookupConfigurationQuery } from 'generated/graphql';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AssetForm } from 'types/assets';
import { EntityForm } from 'types/entities';
import { SectionField, Section } from 'types/forms';
import { toDate } from 'utils/date-util';
import { formatIdentifier } from 'utils/formatIdentifier';
import { v4 as uuid } from 'uuid';

type PropertyToMap = {
  isPrimary: string;
};

interface Props {
  section: Section;
  fieldIdentifierType: string;
  localizedType: string;
  datasourceType: string;
  enableRemove: boolean;
  sectionIndex?: number;
  avatar?: JSX.Element;
  closeForm: () => void;
  handleSave: () => void;
}

interface PrevPrimary {
  section?: string;
  index?: string;
  inputName?: string;
  value?: string;
}

export const deleteModalTheme = (theme: Theme) => ({
  fill: theme.palette.primary.main,
  marginTop: '5px',
  marginRight: '10px',
  cursor: 'pointer',
  '&:hover': { backgroundColor: 'secondary.main' },
});

function MaintainSection({
  section,
  fieldIdentifierType,
  localizedType,
  datasourceType,
  enableRemove,
  sectionIndex = 0,
  avatar,
  closeForm,
  handleSave,
}: Props): JSX.Element {
  const maintainMultipleSections = ['filingFrequencies'];
  const { control, getValues, setValue, watch } = useFormContext();
  const { t } = useTranslation();
  const ctx = useContext(EntityManagementContext);
  const [openRemoval, setOpenRemoval] = useState<boolean>(false);
  const [selectedIdType, setSelectedIdType] = useState<string>('');
  const [activeSection, setActiveSection] = useState<Section>(section);
  const [prevPrimary, setPrevPrimary] = useState<PrevPrimary>({});

  const { fields, remove } = useFieldArray({
    control,
    name: section.sectionIdentifier,
  });

  const nameTypeValue = useWatch({
    control,
    name: `${section.sectionIdentifier}.${sectionIndex}.NameType`,
  });

  const countryValue = useWatch({
    control,
    name: `${section.sectionIdentifier}.${sectionIndex}.countryId`,
  });
  const [prevCountry, setPrevCountry] = useState<string>('');

  const { isFetching, refetch } = useGetLookupConfigurationQuery(
    {
      configurationDomain: ConfigurationDomains.ReferenceSchema,
      configurationType: 'NameType',
    },
    {
      enabled: nameTypeValue !== '' && nameTypeValue !== undefined,
      onSuccess: (configData) => {
        const newNameFields = getNameFieldsByType(
          configData,
          nameTypeValue,
          activeSection
        );
        setActiveSection(newNameFields);
      },
    }
  );

  const handleIdTypeChange = (selIdType: string) => {
    setSelectedIdType(selIdType);
  };

  const addSection = () => {
    setValue(section.sectionIdentifier, [
      ...getValues(section.sectionIdentifier),
      {
        ...getDefault(section.sectionIdentifier),
      },
    ]);
  };

  const removeSection = () => {
    setOpenRemoval(!openRemoval);
    remove(sectionIndex);
    handleSave();
  };

  const removeFilingSection = (index: number) => {
    remove(index);
  };

  const getRemovalTitle = (): string => {
    return t(`pages.${localizedType}.actions.delete`)
      .concat(' ')
      .concat(section.sectionTitle);
  };

  useEffect(() => {
    if (
      nameTypeValue !== '' &&
      nameTypeValue !== undefined &&
      section.sectionIdentifier === 'names'
    ) {
      if (nameTypeValue.includes('IND')) {
        const values = getValues(section.sectionIdentifier)[sectionIndex];
        values.value = '';
      }
      if (nameTypeValue.includes('BUS')) {
        const values = getValues(section.sectionIdentifier)[sectionIndex];
        values.firstName = '';
        values.middleName = '';
        values.lastName = '';
      }
      refetch();
    }
  }, [
    nameTypeValue,
    section.sectionIdentifier,
    refetch,
    getValues,
    sectionIndex,
  ]);

  useEffect(() => {
    if (
      countryValue !== '' &&
      countryValue !== undefined &&
      prevCountry !== countryValue &&
      section.sectionIdentifier === 'addresses'
    ) {
      const newAddressFields = processAddressForm(activeSection, countryValue);
      setActiveSection(newAddressFields);
      setPrevCountry(countryValue);
    }
  }, [activeSection, countryValue, prevCountry, section.sectionIdentifier]);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      const [sectionName, index, inputName] = name?.split('.') as Array<string>;
      if (inputName === 'isPrimary') {
        getValues(sectionName as keyof (AssetForm | EntityForm))?.map(
          (f: PropertyToMap, indexMap: number) => {
            if (
              indexMap.toString() !== index &&
              value[sectionName][index][inputName].toString() === 'true'
            ) {
              if (f.isPrimary === 'true')
                setPrevPrimary({
                  section: sectionName,
                  index: indexMap.toString(),
                  inputName,
                  value: f.isPrimary,
                });

              f.isPrimary = 'false';
            }
            if (
              value[sectionName][index][inputName].toString() === 'false' &&
              Object.keys(prevPrimary).length !== 0 &&
              prevPrimary.index === indexMap.toString()
            )
              f.isPrimary = 'true';

            return f;
          }
        ) as [];
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, getValues, prevPrimary]);

  const getRules = (field: SectionField): object | undefined => {
    if (field.type === 'identifier') {
      if (!ctx.idValidationRules || !selectedIdType) return field.rules;
      const regExp = ctx.idValidationRules[selectedIdType];
      const rules = {
        required: t('pages.common.requiredId'),
        pattern: {
          value: regExp,
          message: t('pages.common.requiredIdMsg') + `${selectedIdType}`,
        },
      };
      return rules;
    } else if (field.fieldIdentifier === 'commenceDate')
      return commenceDateRule;
    return field.rules;
  };

  const displayDate = (value: Date | null | string): Date | null => {
    return value !== '' ? toDate(value) : null;
  };

  const renderFields = (index?: number): JSX.Element => {
    let internalIndex = sectionIndex;
    if (index) internalIndex = index;
    return (
      <>
        {activeSection.fields
          .filter(
            (field) =>
              field.fieldIdentifier !== fieldIdentifierType && !field.hidden
          )
          .map((field) => {
            const keyElement = field.fieldIdentifier + '.' + internalIndex;
            return (
              <Grid
                sx={{ marginBottom: index === undefined ? '0' : '15px' }}
                key={`${section.sectionIdentifier}.${uuid()}-maintain`}
                item
                xs={12}
                sm={6}
                md={3}
              >
                <Controller
                  name={`${section.sectionIdentifier}.${internalIndex}.${field.fieldIdentifier}`}
                  control={control}
                  defaultValue={''}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => {
                    const errorMessage = error && error?.message;
                    switch (field.type) {
                      case 'select':
                      case 'selectOnlyEdit':
                        return (
                          <Select
                            id={keyElement}
                            options={field.options ?? []}
                            value={value}
                            onChange={onChange}
                            label={field.label}
                            required={field.isRequired}
                            error={errorMessage}
                            sx={{
                              width: '100%',
                            }}
                          />
                        );

                      case 'radio':
                        return (
                          <EntityRadioGroup
                            id={keyElement}
                            label={field.label}
                            fieldIdentifier={field.fieldIdentifier}
                            value={value}
                            onChange={onChange}
                            radioOptions={field.radioOptions}
                            isRequired={field.isRequired}
                            error={errorMessage}
                          />
                        );

                      case 'date':
                        return (
                          <DatePicker
                            id={keyElement}
                            value={displayDate(value)}
                            handleChange={onChange}
                            label={field.label}
                            requiredErrorMessage={errorMessage}
                            required={field.isRequired}
                            fullWidth={true}
                          />
                        );

                      case 'datasource':
                        if (value && field.datasource === datasourceType)
                          setSelectedIdType(value);
                        return (
                          <DatasourceSelect
                            id={keyElement}
                            label={field.label}
                            value={value}
                            required={field.isRequired}
                            errorMsg={errorMessage}
                            idTypeChange={handleIdTypeChange}
                            datasource={field.datasource ?? ''}
                            groupName={field.groupName ?? ''}
                            attributeName={field.attributeName ?? ''}
                            context={field.context ?? ''}
                            fetchLayoutInfo={onChange}
                            maxWidth={true}
                          />
                        );

                      case 'industrycodesource':
                        return (
                          <IndustryCodeAutocomplete
                            id={`${section.sectionIdentifier}.${internalIndex}.${field.fieldIdentifier}`}
                            label={field.label}
                            value={value}
                            errorMsg={errorMessage}
                            onChange={onChange}
                            setValue={setValue}
                          />
                        );

                      case 'identifier':
                        return (
                          <Input
                            key={keyElement}
                            id={keyElement}
                            value={formatIdentifier(
                              value,
                              ctx.idFormats,
                              selectedIdType
                            )}
                            onChange={onChange}
                            inputProps={getMaxLength(
                              selectedIdType,
                              ctx.idMaxLengths
                            )}
                            required={field.isRequired}
                            label={field.label}
                            error={Boolean(error)}
                            helperText={errorMessage}
                            sx={{
                              width: '100%',
                            }}
                          />
                        );

                      default:
                        return (
                          <Input
                            id={keyElement}
                            key={keyElement}
                            value={value}
                            onChange={onChange}
                            required={field.isRequired}
                            label={field.label}
                            error={Boolean(error)}
                            helperText={errorMessage}
                            sx={{
                              width: '100%',
                            }}
                          />
                        );
                    }
                  }}
                  rules={getRules(field)}
                />
              </Grid>
            );
          })}
      </>
    );
  };

  return (
    <Card sx={{ marginBottom: 1 }}>
      <CardHeader
        title={section.sectionTitle}
        avatar={avatar}
        action={
          enableRemove ? (
            <Delete
              sx={deleteModalTheme}
              onClick={() => setOpenRemoval(!openRemoval)}
            />
          ) : (
            <></>
          )
        }
      />
      <CardContent>
        <Grid container spacing={3}>
          {!maintainMultipleSections.includes(section.sectionIdentifier) &&
            renderFields()}
        </Grid>
        <Box sx={{ marginTop: '30px' }}>
          {maintainMultipleSections.includes(section.sectionIdentifier) &&
            fields.map((item, index) => {
              return (
                <Fragment key={item.id}>
                  <Grid container spacing={4}>
                    {renderFields(index)}
                    {index !== 0 && (
                      <Grid
                        key={'delete-filingFrequency-' + item.id}
                        sx={{ marginTop: '20px' }}
                        item
                        xs={12}
                        sm={6}
                        md={3}
                      >
                        <IconButton
                          aria-label="delete"
                          onClick={() => removeFilingSection(index)}
                        >
                          <Delete />
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>
                </Fragment>
              );
            })}
          {maintainMultipleSections.includes(section.sectionIdentifier) && (
            <Button
              id={'add-new-' + section.sectionTitle}
              type="secondary"
              sx={{
                marginTop: '10px',
                marginBottom: '10px',
              }}
              onClick={addSection}
            >
              {t('pages.createAccount.buttonTitle')} {section.sectionTitle}
            </Button>
          )}
        </Box>
        {isFetching && <Loading />}
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Button
            id={`save-new-${section.sectionIdentifier}`}
            sx={{ marginTop: '10px' }}
            onClick={handleSave}
          >
            {t(`pages.${localizedType}.actions.save`)}
          </Button>
          <Button
            id={`close-${section.sectionIdentifier}`}
            type="secondary"
            sx={{ marginTop: '10px', marginLeft: '10px' }}
            onClick={closeForm}
          >
            {t(`pages.${localizedType}.actions.close`)}
          </Button>
        </Box>
        <Dialog
          id={`dialog-remove-item-${section.sectionIdentifier}`}
          type="transactional"
          title={getRemovalTitle()}
          transactionModalTransactionButtonText={t(
            `pages.${localizedType}.actions.delete`
          )}
          open={openRemoval}
          handleClose={() => setOpenRemoval(!openRemoval)}
          handleCancelClick={() => setOpenRemoval(!openRemoval)}
          handleTransactionClick={removeSection}
          maxWidth="sm"
        >
          {t(`pages.${localizedType}.message.confirmRemoval`)}
        </Dialog>
      </CardContent>
    </Card>
  );
}

export default MaintainSection;
