import { gql } from 'graphql-request';

export const updateRoleQuery = gql`
  mutation UpdateUserRole($user: UpdateUserRoleInput!) {
    UpdateUserRole(user: $user) {
      mapAction
      userRoleMapEntries {
        roleId
        userId
        startDate
        endDate
      }
    }
  }
`;
