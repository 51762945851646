import { Channel } from 'types/channels';

export const getChannel: Channel = {
  Channel: {
    ChannelName: '',
    DisplayName: '',
    Status: '',
    Version: 0,
    ActivityName: 'inbound',
    Id: '',
    Pipeline: {
      Id: '',
      Name: '',
      InputDataConnector: '',
      PipelineSchedule: [
        {
          Id: '',
          Name: '',
          StartTime: new Date(),
          EndTime: null,
          Interval: 1,
          Frequency: 'Hour',
          PipelineReferenceName: '',
          IsDelete: false,
          RecurrenceSchedule: {
            Minutes: [],
            Hours: [],
            WeekDays: [],
            MonthDays: [],
          },
        },
      ],
      Parameters: {},
    },
    LogixGroup: null,
    Module: '',
    ModuleId: 2,
    FileGroup: {
      FileGroupName: '',
      FileGroupType: '',
      FileType: '',
      FileProcessingType: '',
      DelimiterValue: '',
      Files: [
        {
          FileName: '',
          FileMetadata: '',
          FileProcessingOrder: null,
          HasHeader: false,
          FileId: '',
        },
      ],
    },
    IsCompressed: false,
    IsEncrypted: false,
    IsFti: false,
  },
};
