import { Modal } from '@mui/material';
import { Box } from '@mui/system';
import AddBankAccount from '../AddBankAccount';

type AddBankAccountModalProps = {
  open: boolean;
  onClose?: () => void;
  refetch: () => void;
};

const AddBankAccountModal = ({
  open,
  onClose,
  refetch,
}: AddBankAccountModalProps) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="create-new-bank-account"
      aria-describedby="create-new-bank-account"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          minWidth: 400,
          maxWidth: 1200,
          bgcolor: 'background.paper',
          p: 4,
        }}
      >
        <AddBankAccount onCloseCallback={onClose} refetch={refetch} />
      </Box>
    </Modal>
  );
};

export default AddBankAccountModal;
