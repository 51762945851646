import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

interface SaveData<T> {
  mutationKey: string | string[];
  params: T;
  query: string;
  token: string | undefined;
  paramsId: string;
}

export default function useMutationRequest<T>() {
  return useMutation(async (data: SaveData<T>) => {
    const response = await axios.post(
      `${process.env.REACT_APP_GRAPH}`,
      {
        query: data.query,
        variables: { [data.paramsId]: { ...data.params } },
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${data.token}`,
        },
      }
    );
    return response.data as T;
  });
}
