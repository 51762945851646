import { Grid } from '@mui/material';
import HistoryIcon from '@mui/icons-material/History';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import DataCard from 'components/DataCard';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import { ColumnDef } from '@tanstack/react-table';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import Dialog from '@revenue-solutions-inc/revxcoreui/material/controls/Dialog';
import {
  TaskRunList,
  useArchiveTaskMutation,
  useGetTaskListByNameQuery,
} from 'generated/graphql';
import { format } from 'date-fns';
import Loading from 'components/Loading';
import { addMessage } from 'redux/messageSlice';
import { MessageType } from '@revenue-solutions-inc/revxcoreui';

const optionalStyles = {
  '.MuiDialogTitle-root': {
    color: 'lightBlack.main !important',
    padding: '10px 24px',
  },
  fontSize: '16px',
  fontWeight: 'normal',
};

export const dialogStyle = {
  '& .MuiSvgIcon-root': {
    display: 'none',
  },
  '.MuiDialogTitle-root': {
    fontWeight: 'bold',
  },
  '& .MuiDialogActions-root': {
    textAlign: 'center',
    display: 'inline-block',
  },
  '& .MuiDialogContent-root': {
    fontSize: '16px',
    fontWeight: 'normal',
  },
};

interface RunHistoryProps {
  isOpenDelete: boolean;
  setIsOpenDelete: React.Dispatch<React.SetStateAction<boolean>>;
  currentModule: string;
}

function RunHistory({
  isOpenDelete,
  setIsOpenDelete,
  currentModule,
}: RunHistoryProps): JSX.Element {
  const { taskName, taskId } = useParams() as {
    taskId: string;
    taskName: string;
  };
  const { t } = useTranslation();

  const module = useAppSelector((state) => state.user.module);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { data: runListData, isLoading } = useGetTaskListByNameQuery({
    taskName,
    module: currentModule,
  });

  const { mutate, isLoading: loadingDelete } = useArchiveTaskMutation();

  const children = <>{t('pages.manageChannel.warningTask')}</>;

  const columnsData: {
    id: string;
    localize: string;
    accessorKey: keyof TaskRunList;
  }[] = [
    {
      id: 'Task-Run-Id',
      localize: 'runId',
      accessorKey: 'TaskRunHistoryId',
    },
    {
      id: 'version',
      localize: 'taskVersionValue',
      accessorKey: 'TaskVersion',
    },
    {
      id: 'status',
      localize: 'taskStatus',
      accessorKey: 'TaskStatus',
    },
    {
      id: 'start-time',
      localize: 'startTime',
      accessorKey: 'StartTime',
    },
    {
      id: 'end-time',
      localize: 'endTime',
      accessorKey: 'EndTime',
    },
    {
      id: 'Elapsed-Time',
      localize: 'elapsedTime',
      accessorKey: 'ElapsedTime',
    },
    {
      id: 'initiated-type',
      localize: 'initiatedType',
      accessorKey: 'RunInitiatedType',
    },
    {
      id: 'initial-by',
      localize: 'initiatedBy',
      accessorKey: 'RunInitiatedBy',
    },
    {
      id: 'Run-Status',
      localize: 'runStatus',
      accessorKey: 'RunStatus',
    },
  ];

  const Column: ColumnDef<TaskRunList>[] = columnsData.map((columnData) => {
    const column: ColumnDef<TaskRunList> = {
      header: () => (
        <HeaderColumnNext
          localization={t(`pages.manageChannel.${columnData.localize}`)}
        />
      ),
      id: columnData.id,
      accessorKey: columnData.accessorKey,
    };
    if (columnData.id === 'Task-Run-Id') {
      column.cell = ({ row }) => (
        <Link
          to={{
            pathname: `/${module}/scheduledTaskStagesDetails/${taskId}/${row.original.TaskName}/${row.original.TaskVersion}/${row.original.TaskRunHistoryId}/${currentModule}`,
          }}
        >
          {row.original.TaskRunHistoryId}
        </Link>
      );
    }

    if (columnData.id === 'start-time') {
      column.cell = ({ row }) => {
        return row.original.StartTime
          ? format(
              new Date(row.original.StartTime?.toString()),
              'MM/dd/yyyy hh:mm:ss a'
            )
          : '';
      };
    } else if (columnData.id === 'end-time') {
      column.cell = ({ row }) => {
        return row.original.EndTime
          ? format(
              new Date(row.original.EndTime?.toString()),
              'MM/dd/yyyy hh:mm:ss a'
            )
          : '';
      };
    }

    return column;
  });
  if (!isLoading && !loadingDelete) {
    return (
      <DataCard
        avatar={<HistoryIcon sx={{ fill: 'primary.main' }} />}
        title={t('pages.manageChannel.runHistory')}
        children={
          <>
            <Grid sx={{ marginBottom: '20px' }}>
              <DefaultDataTableNext
                columns={Column}
                data={
                  (runListData?.getTaskRunListByName ||
                    []) as unknown as Record<string, unknown>[]
                }
                enableGlobalFilter={false}
              />
              <Dialog
                id="delete-dialog"
                title={taskName}
                open={isOpenDelete}
                type="danger"
                optionalStyles={optionalStyles}
                optionalLabel={t('pages.manageChannel.confirmationTask')}
                sx={dialogStyle}
                dangerModalDangerButtonText={t('pages.manageChannel.archive')}
                children={children}
                width={450}
                handleCancelClick={() => {
                  setIsOpenDelete(false);
                }}
                handleDangerClick={() => {
                  setIsOpenDelete(false);
                  mutate(
                    {
                      task: {
                        ScheduleTaskName: taskName,
                        Module: currentModule,
                      },
                    },
                    {
                      onSuccess: () => {
                        dispatch(
                          addMessage({
                            type: MessageType.Success,
                            message: t('pages.manageChannel.runDeleteIcon'),
                          })
                        );
                        navigate(`/${module}/managescheduledtasks`);
                      },
                      onError: () => {
                        dispatch(
                          addMessage({
                            type: MessageType.Error,
                            message: t('components.message.networkerror'),
                          })
                        );
                      },
                    }
                  );
                }}
              />
            </Grid>
          </>
        }
      />
    );
  }
  if (isLoading || loadingDelete) {
    return <Loading />;
  }
  return <></>;
}

export default RunHistory;
