import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  Box,
} from '@mui/material';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import { useTranslation } from 'react-i18next';
import { UserLookupAutocomplete } from 'components/UserLookupAutocomplete';

export enum AssignmentActions {
  ASSIGN = 'assign',
  UNASSIGN = 'unassign',
}

interface Props {
  handleAssignment: (action: string) => () => void;
  setUser: (user: string | undefined) => void;
  selectedUser?: string;
}

function WorkManagerActionsCard({
  handleAssignment,
  setUser,
  selectedUser,
}: Props): JSX.Element {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        minWidth: '260px',
      }}
    >
      <Card
        data-testid="workmanager-actions-card"
        sx={{
          maxWidth: '10%',
          minWidth: '260px',
          justifyItems: 'center',
          position: 'fixed',
        }}
      >
        <CardHeader
          title={
            <Typography variant="h4" align="center">
              <strong>{t('components.workManagerActionsCard.title')}</strong>
            </Typography>
          }
        />
        <CardContent>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <UserLookupAutocomplete setUser={setUser} />
            </Grid>
            <Grid item xs={8}>
              <Button
                disabled={!selectedUser}
                onClick={handleAssignment(AssignmentActions.ASSIGN)}
                id="assign-btn"
                data-testid="assign-btn"
                type="secondary"
                sx={{ ml: 2, mt: 2, minWidth: '128px', order: 1 }}
              >
                <>{t('components.button.assign')}</>
              </Button>
            </Grid>
            <Grid item xs={8}>
              <Button
                onClick={handleAssignment(AssignmentActions.UNASSIGN)}
                id="unassign-btn"
                data-testid="unassign-btn"
                type="secondary"
                sx={{ ml: 2, mt: 2, minWidth: '128px', order: 2 }}
              >
                <>{t('components.button.unassign')}</>
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
}

export default WorkManagerActionsCard;
