import { useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import DataCard from 'components/DataCard';
import { useTranslation } from 'react-i18next';
import {
  TransactionPosting,
  useGetPeriodTransactionsSimplifiedQuery,
} from 'generated/graphql';
import CurrencyCell from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/TableCells/CurrencyCell';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import { ColumnDef } from '@tanstack/react-table';
import DataTable from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import DateCell from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/TableCells/DateCell';
import { useParams } from 'react-router-dom';
import ReorderIcon from '@mui/icons-material/Reorder';

const Translation = () => {
  const { t } = useTranslation();
  return t;
};

export const simplifiedTransactionColumns: ColumnDef<TransactionPosting>[] = [
  {
    id: 'type',
    accessorKey: 'financialTransactionType',
    header: () => (
      <HeaderColumnNext
        localization={Translation()?.('pages.periodDetails.transactions.type')}
      />
    ),
  },
  {
    id: 'effectiveDate',
    accessorKey: 'effectiveDate',
    header: () => (
      <HeaderColumnNext
        localization={Translation()?.(
          'pages.periodDetails.transactions.effectiveDate'
        )}
      />
    ),
    cell: ({ getValue }) => <DateCell dateString={getValue() as string} />,
  },
  {
    id: 'amount',
    accessorKey: 'amount',
    header: () => (
      <HeaderColumnNext
        localization={Translation()?.(
          'pages.periodDetails.transactions.amount'
        )}
      />
    ),
    cell: ({ getValue }) => {
      return (
        <Box sx={{ width: '3.3em' }}>
          <CurrencyCell
            invalidValue={Translation()?.('pages.tableCell.invalidValue')}
            amountString={getValue() as string}
          />
        </Box>
      );
    },
  },
];

function PeriodTransactionsSimplified(): JSX.Element {
  const { t } = useTranslation();
  const { periodId } = useParams() as {
    periodId: string;
  };
  const [transactionPostings, setTransactionPostings] = useState<
    TransactionPosting[] | null
  >();

  const { isLoading } = useGetPeriodTransactionsSimplifiedQuery(
    {
      periodId: periodId,
    },
    {
      onError: () => {},
      onSuccess: (data) => {
        setTransactionPostings(
          data.GetPeriodTransactionsSimplified.financialTransactionPostings
        );
      },
    }
  );

  return (
    <>
      {!isLoading && transactionPostings && (
        <Grid sx={{ mb: '40px' }}>
          <DataCard
            title={t(`pages.periodDetails.transactions.titleSimplified`)}
            avatar={<ReorderIcon sx={{ fill: 'primary.main' }} />}
            children={
              <>
                {transactionPostings.length > 0 && (
                  <DataTable
                    data={transactionPostings}
                    columns={
                      simplifiedTransactionColumns as ColumnDef<
                        Record<string, unknown>
                      >[]
                    }
                    enableGlobalFilter={false}
                  />
                )}
                {transactionPostings.length === 0 && (
                  <Typography sx={{ margin: '10px' }}>
                    {t('pages.periodDetails.transactions.noTransactions')}
                  </Typography>
                )}
              </>
            }
          />
        </Grid>
      )}
    </>
  );
}

export default PeriodTransactionsSimplified;
