import { useEffect, useState } from 'react';

import { Box, Collapse, IconButton, Stack, Theme } from '@mui/material';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import { Input } from '@revenue-solutions-inc/revxcoreui';
import { useTranslation } from 'react-i18next';
import { ConfigurationSection } from 'types/graphTypes';
import { EditAccessUtil } from 'pages/admin/ConfigTypeEditor/editAccessUtil';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import { Group } from 'generated/graphql';
import {
  cancelButton,
  checkButton,
  collapseButton,
  deleteButton,
  editButton,
  expandButton,
  fontSize,
  icon,
} from '../Shared/IconButtonStyling/IconButtonStyling';
import GroupDetails from '../GroupDetails';

interface Props {
  configSection?: ConfigurationSection;
  handleChange: (configSection: ConfigurationSection) => boolean;
  handleDelete: () => void;
  setNewSection?: React.Dispatch<React.SetStateAction<boolean>>;
  isNewSection?: boolean;
  editAccess?: EditAccessUtil;
  isExpanded?: boolean;
  hasRecords?: boolean;
}

const configSectionDefault: ConfigurationSection = {
  configurationSectionName: '',
  group: [],
};
function ConfigurationDetails({
  configSection = configSectionDefault,
  handleChange,
  handleDelete,
  setNewSection,
  isNewSection,
  editAccess,
  isExpanded = false,
  hasRecords,
}: Props) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.configTypeEditor',
  });

  const [inEditMode, setInEditMode] = useState<boolean>(false);
  const [tempConfigSection, setTempConfigSection] =
    useState<ConfigurationSection>(configSection);
  const [sectionNameExists, setSectionNameExists] = useState(false);
  const [isNewGroup, setNewGroup] = useState<boolean>(false);
  const [expanded, setExpanded] = useState(false);

  const handleEditClick = () => {
    setInEditMode(true);
  };
  const handleCancelClick = () => {
    setInEditMode(false);
    setNewSection?.(false);
    setTempConfigSection(configSection);
  };

  const handleSaveClick = () => {
    const success = handleChange(tempConfigSection);
    if (success) {
      setInEditMode(false);
      setSectionNameExists(false);
      setNewSection?.(false);
    } else {
      setSectionNameExists(true);
    }
  };

  const deleteGroup = (oldGroupName: string) => {
    configSection.group =
      configSection.group?.filter(
        (group) => group.groupName !== oldGroupName
      ) ?? [];
    handleChange(configSection);
  };

  const updateGroup = (oldGroupName: string, newGroup: Group) => {
    let groupNameExists = undefined;

    if (!isNewGroup && oldGroupName === newGroup.groupName) {
      groupNameExists = undefined;
    } else {
      groupNameExists = configSection.group.find(
        (group) => group.groupName === newGroup.groupName
      );
    }

    if (groupNameExists === undefined) {
      const groups = [...configSection.group];

      if (isNewGroup) {
        groups.push(newGroup);
      } else {
        const oldInd = configSection.group.findIndex(
          (group) => group.groupName === oldGroupName
        );

        groups[oldInd] = newGroup;
      }
      configSection.group = groups;
      handleChange(configSection);
      return true;
    } else return false;
  };

  const addGroup = () => {
    setNewGroup?.(true);
    setExpanded(true);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (isNewSection) {
      setInEditMode(true);
    }
  }, [isNewSection]);

  useEffect(() => {
    setTempConfigSection(configSection);
  }, [configSection]);

  useEffect(() => {
    setExpanded(isExpanded);
  }, [isExpanded]);

  return (
    <Box mt={'20px'}>
      <Box
        id="panel2a-header"
        sx={(theme: Theme) => {
          return {
            border: inEditMode
              ? `solid 2px ${theme.palette.grey[200]}`
              : 'none',
            backgroundColor: inEditMode ? 'white' : theme.palette.grey[100],
            borderRadius: '5px',
            p: '10px',
            pt: '30px',
          };
        }}
      >
        <Stack direction="row" spacing={2.5}>
          <IconButton
            onClick={handleExpandClick}
            sx={editButton}
            aria-label="expand-button"
          >
            <ExpandCircleDownIcon
              sx={expanded ? collapseButton : expandButton}
            />
          </IconButton>
          <Box mt="-20px !important">
            <Input
              required
              id="configSectionName"
              onChange={(e) => {
                setTempConfigSection({
                  ...tempConfigSection,
                  configurationSectionName: e.target.value,
                });
              }}
              label={t('configSectionName')}
              value={tempConfigSection?.configurationSectionName || ''}
              sx={{ minWidth: '200px' }}
              error={sectionNameExists}
              helperText={sectionNameExists ? t('attributeExists') : ''}
              disabled={!inEditMode || (hasRecords && !isNewSection)}
            />
          </Box>
          {!inEditMode && (
            <>
              <IconButton
                onClick={handleEditClick}
                sx={editButton}
                aria-label="edit-button"
                disabled={!editAccess?.controlExtendedGroup()}
              >
                <EditIcon sx={{ icon }} />
              </IconButton>
              <Box sx={{ right: '20px', position: 'absolute' }}>
                <Button
                  id={'addGroupBtn-configTypeEditor'}
                  onClick={addGroup}
                  type={'secondary'}
                  disabled={!editAccess?.controlExtendedGroup() || isNewGroup}
                >
                  {t('addGroup')}
                </Button>
                <IconButton
                  onClick={handleDelete}
                  sx={deleteButton}
                  disabled={!editAccess?.controlExtendedGroup() || hasRecords}
                  aria-label="delete-button"
                >
                  <Delete sx={{ fontSize }} />
                </IconButton>
              </Box>
            </>
          )}
          {inEditMode && (
            <Stack direction="row" spacing={1} sx={{ float: 'right' }}>
              <IconButton
                size="small"
                onClick={handleSaveClick}
                disabled={!tempConfigSection.configurationSectionName}
                sx={checkButton}
                aria-label="check-button"
              >
                <CheckIcon sx={icon} />
              </IconButton>
              <IconButton
                size="small"
                sx={cancelButton}
                onClick={handleCancelClick}
                aria-label="cancel-button"
              >
                <CloseIcon sx={icon} />
              </IconButton>
            </Stack>
          )}
        </Stack>
      </Box>
      <Collapse
        in={expanded}
        timeout="auto"
        unmountOnExit
        sx={(theme: Theme) => {
          return {
            borderWidth: '0 0 0 2px',
            borderColor: theme.palette.linkBlue.main,
            borderStyle: 'solid',
            marginTop: '20px',
          };
        }}
      >
        {isNewGroup && (
          <GroupDetails
            isNewGroup={isNewGroup}
            setNewGroup={setNewGroup}
            handleChange={updateGroup}
            handleDelete={deleteGroup}
            editAccess={editAccess}
            isExpanded={isExpanded}
            key={'NewGroup'}
            hasRecords={hasRecords}
          />
        )}
        {configSection.group.map((group) => {
          return (
            <GroupDetails
              isNewGroup={false}
              setNewGroup={setNewGroup}
              group={group}
              handleChange={updateGroup}
              handleDelete={deleteGroup}
              editAccess={editAccess}
              isExpanded={isExpanded}
              key={group.groupName}
              hasRecords={hasRecords}
            />
          );
        })}
      </Collapse>
    </Box>
  );
}
export default ConfigurationDetails;
