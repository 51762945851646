import { Dispatch, SetStateAction, useState, useEffect, useRef } from 'react';
import { Grid, Box, CircularProgress, Stack } from '@mui/material';
import DataDisplay from '@revenue-solutions-inc/revxcoreui/material/controls/DataDisplay';
import Input from '@revenue-solutions-inc/revxcoreui/material/controls/Input';
import Select from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import useMaskInput from 'hooks/useMaskInput';
import { textNotEmptyExp, emailExp } from 'common/regexp';
import { useTranslation } from 'react-i18next';
import {
  MessageType,
  MessageActionType,
} from '@revenue-solutions-inc/revxcoreui';
import {
  LocaleInfo,
  SecurityInfo,
  Language,
  TimeZone,
  InfoLocalesList,
  TenantInfo,
} from 'types/tenants';
import extractMeaningfulMessage from 'utils/errorMessage';
import { addMessage } from 'redux/messageSlice';
import { useGetLocaleTypeQuery } from 'generated/graphql';
import { getLocaleType, ConfigurationsResponse } from 'utils/getConfigurations';
import { useAppDispatch } from 'redux/hooks';
interface TenancyConfigProps {
  securityInfo?: SecurityInfo;
  localeInfo: LocaleInfo;
  tenantInfo: TenantInfo;
  handleChange: (newLocaleInfo: LocaleInfo) => void;
  handleValidation: Dispatch<SetStateAction<boolean>>;
}

function TenancyConfig({
  localeInfo,
  tenantInfo,
  handleChange,
  handleValidation,
}: TenancyConfigProps): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const componentFocus = useRef<HTMLSelectElement>(null);
  const [locales, setLocales] = useState<InfoLocalesList[]>([]);
  const [languages, setLanguages] = useState<Language[]>([]);
  const [timezones, setTimezones] = useState<TimeZone[]>([]);

  const adminNameMask = useMaskInput(
    '',
    '',
    textNotEmptyExp,
    tenantInfo.managementAdminName ?? ''
  );
  const adminEmailMask = useMaskInput(
    '',
    t('pages.tenantConfig.errors.mgtAdminEmail'),
    emailExp,
    tenantInfo.managementAdminEmail ?? ''
  );

  const { isFetching: isFetchingLocale } =
    useGetLocaleTypeQuery<ConfigurationsResponse>(
      {
        configurationType: 'LocaleType',
        configurationModule: '',
      },
      {
        onSuccess: (response) => {
          if (response?.getConfigurations) {
            const validLocales: InfoLocalesList[] = getLocaleType(
              response.getConfigurations
            );
            setLocales(validLocales);
          }
        },
        onError: (error) => {
          const message = extractMeaningfulMessage(
            error,
            t('components.message.networkerror')
          );
          dispatch(
            addMessage({
              message: message,
              type: MessageType.Error,
              actionType: MessageActionType.None,
            })
          );
        },
      }
    );

  const handleAdminEmailHelperText = (): string => {
    return adminEmailMask.disabledValue &&
      tenantInfo.managementAdminEmail.length > 0
      ? adminEmailMask.maskError
      : '';
  };

  useEffect(() => {
    const isValid =
      !!localeInfo.currency &&
      !!localeInfo.locationName &&
      !!localeInfo.timeZones &&
      !!localeInfo.languages &&
      adminNameMask.disabledValue === false &&
      adminEmailMask.disabledValue === false;
    handleValidation(isValid);
  }, [
    localeInfo,
    handleValidation,
    tenantInfo.managementAdminEmail,
    tenantInfo.managementAdminName,
    adminNameMask.disabledValue,
    adminEmailMask.disabledValue,
  ]);

  useEffect(() => {
    const selectedLocation = locales.find(
      (locale) => locale.locationName === localeInfo.locationName
    );
    if (selectedLocation) {
      setLanguages(selectedLocation.languages);
      setTimezones(selectedLocation.timeZones);
    }
  }, [localeInfo, locales]);

  useEffect(() => {
    if (locales.length > 0) componentFocus.current?.focus();
  }, [locales]);

  return (
    <Grid
      container
      spacing={1}
      sx={{
        backgroundColor: 'white.main',
        padding: 2,
        borderRadius: 1,
        border: '1px solid #e2e2e2',
      }}
    >
      <Grid item xs={10} mb={0.5}>
        <Stack direction="row" spacing={2}>
          <Select
            label={t('pages.tenantConfig.tenancyConfiguration.locale')}
            required
            value={localeInfo?.locationName ?? ''}
            id="localeSelect"
            inputProps={{
              'data-testid': 'locale-select',
              inputRef: componentFocus,
              autoFocus: true,
            }}
            options={
              locales.length > 0
                ? locales.map((locale) => {
                    return {
                      key: locale?.locationName ?? '',
                      desc: locale?.locationName ?? '',
                    };
                  })
                : []
            }
            onChange={(event) => {
              const selectedLocation = locales.find(
                (locale) => locale.locationName === event.target.value
              );

              if (selectedLocation) {
                handleChange({
                  ...localeInfo,
                  locationName: selectedLocation.locationName,
                  currency: selectedLocation.currency,
                  languages: [
                    { ...selectedLocation.languages[0], selected: true },
                  ],
                  timeZones: [
                    { ...selectedLocation.timeZones[0], selected: true },
                  ],
                });
              }
            }}
            sx={{ width: '25%' }}
          />
          {isFetchingLocale && (
            <Box sx={{ display: 'flex', pt: 3 }}>
              <CircularProgress size={24} />
            </Box>
          )}
        </Stack>
      </Grid>
      <Grid item xs={10} mb={0.5}>
        <Select
          label={t('pages.tenantConfig.tenancyConfiguration.language')}
          required
          value={
            languages.length > 0 && localeInfo.languages.length > 0
              ? localeInfo.languages[0].languageName
              : ''
          }
          id="languageSelect"
          options={
            languages?.map((language) => {
              return {
                key: language.languageName ?? '',
                desc: language.languageName ?? '',
              };
            }) ?? [
              {
                key: ' ',
                desc: t(
                  'pages.tenantConfig.tenancyConfiguration.selectLanguage'
                ),
              },
            ]
          }
          onChange={(event) => {
            const selectedLanguage = event.target.value as string;
            handleChange({
              ...localeInfo,
              languages: [{ languageName: selectedLanguage, selected: true }],
            });
          }}
          sx={{ width: '25%' }}
        />
      </Grid>
      <Grid item xs={10} mb={0.5}>
        <Select
          label={t('pages.tenantConfig.tenancyConfiguration.timeZone')}
          required
          value={
            timezones.length > 0 && localeInfo.timeZones.length > 0
              ? localeInfo.timeZones[0].timeZoneName
              : ''
          }
          id="timeZoneSelect"
          options={
            timezones?.map((timezone) => {
              return {
                key: timezone.timeZoneName ?? '',
                desc: timezone.timeZoneName ?? '',
              };
            }) ?? [{ key: ' ', desc: 'Select Time Zone' }]
          }
          onChange={(event) => {
            const selectedTimezone = event.target.value as string;
            handleChange({
              ...localeInfo,
              timeZones: [{ timeZoneName: selectedTimezone, selected: true }],
            });
          }}
          sx={{ width: '25%' }}
        />
      </Grid>
      <Grid item xs={10} mb={0.5}>
        {localeInfo?.currency ? (
          <DataDisplay
            label={t('pages.tenantConfig.tenancyConfiguration.currency')}
            data={localeInfo?.currency ?? ''}
            id="currencySelect"
          />
        ) : (
          <></>
        )}
      </Grid>
      <Grid item xs={10} mb={0.5}>
        <Input
          id="managementAdminName"
          label={t('pages.tenantConfig.tenancyConfiguration.mgtAdminName')}
          required
          disabled={true}
          value={tenantInfo.managementAdminName}
          sx={{ width: '50%' }}
        />
      </Grid>
      <Grid item xs={10} mb={0.5}>
        <Input
          id="managementAdminEmail"
          label={t('pages.tenantConfig.tenancyConfiguration.mgtAdminEmail')}
          required
          disabled={true}
          value={tenantInfo.managementAdminEmail}
          helperText={handleAdminEmailHelperText()}
          sx={{ width: '50%' }}
        />
      </Grid>
    </Grid>
  );
}

export default TenancyConfig;
