import { WorkflowItem } from 'generated/graphql';

enum Status {
  Active = 1,
  Inactive,
}

export const useFormatSuppressionsDataTable = (
  rawDataItems: WorkflowItem[] = []
) => {
  const tableData = rawDataItems.map((suppression) => {
    try {
      const wf = JSON.parse(suppression?.wF_Full_Object ?? '');
      return {
        ...wf,
        DisplayId: wf.DisplayId ?? '',
        Type: wf.VariantGroupCode ?? '',
        SuppressionStartDate: wf.BeginDate ?? '',
        SuppressionEndDate: wf.EndDate ?? '',
        State: Status[wf.StatusId] ?? '',
      };
    } catch (error) {}
  });

  return tableData as Record<string, unknown>[];
};
