import { Fragment, useEffect, useMemo, useState } from 'react';

import {
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  Theme,
  Typography,
} from '@mui/material';
import {
  Card,
  CardContent,
  CardHeader,
  MessageActionType,
  MessageType,
  Button,
  DatePicker,
  Checkbox,
} from '@revenue-solutions-inc/revxcoreui';
import Dialog from '@revenue-solutions-inc/revxcoreui/material/controls/Dialog';
import CommentSharpIcon from '@mui/icons-material/CommentSharp';
import ContactPhoneSharpIcon from '@mui/icons-material/ContactPhoneSharp';
import ContactsSharpIcon from '@mui/icons-material/ContactsSharp';
import SecuritySharpIcon from '@mui/icons-material/SecuritySharp';
import ViewModuleSharpIcon from '@mui/icons-material/ViewModuleSharp';
import SettingsSharpIcon from '@mui/icons-material/SettingsSharp';
import Loading from 'components/Loading';
import ModalFields from 'components/ModalFields';
import { useTranslation } from 'react-i18next';
import { useParams, Link } from 'react-router-dom';
import { IHeader, setHeader } from 'redux/contentSlice';
import { useAppDispatch } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import { Domain, Module, useGetTenantByIdQuery } from 'generated/graphql';
import { useHasEdit } from 'hooks/useHasAccess';
import { useTheme } from '@mui/system';

const orderDomains = (
  domains: Array<Domain>
): { revx: Array<string>; online: Array<string> } => {
  const revxDefaultDomains: Array<string> = [];
  const onlineDefaulDomains: Array<string> = [];

  domains.forEach((domain) => {
    switch (domain.type) {
      case 'RevX':
        revxDefaultDomains.push(domain.fqdn ?? '');
        break;
      case 'OnlineServices':
        onlineDefaulDomains.push(domain.fqdn ?? '');
        break;
      default:
        break;
    }
  });

  return {
    revx: revxDefaultDomains,
    online: onlineDefaulDomains,
  };
};

export default function Tenant(): JSX.Element {
  const { id } = useParams() as { id: string };
  const dispatch = useAppDispatch();
  const { data, isFetching, error } = useGetTenantByIdQuery({
    tenantId: id,
  });
  const appTheme = useTheme();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const accessViewTenantEdit = useHasEdit('viewTenant', 'ViewTenantEdit');
  const [isIdPDisabled, setIsIdPDisabled] = useState(true);
  const [idpDisplay, setIdpDisplay] = useState({
    openIdMetadataUrl: '',
    clientId: '',
  });

  useEffect(() => {
    if (error) {
      dispatch(
        addMessage({
          message: t('components.message.networkerror'),
          type: MessageType.Error,
          actionType: MessageActionType.None,
        })
      );
    }
  }, [error, dispatch, t]);

  const idPDisabled = (isIdPDisabledProp: boolean): boolean => {
    if (!accessViewTenantEdit) return true;
    return isIdPDisabledProp;
  };

  const typeOfConfigurationStatusIcon = (type: string) => {
    const statusObj = {
      configurationStatusIcon: '',
      style: {},
    };

    switch (type) {
      case 'Success':
        statusObj.configurationStatusIcon = 'checkCircleIcon';
        statusObj.style = {
          fill: '#2e7d32',
        };
        break;
      case 'Failure':
        statusObj.configurationStatusIcon = 'errorIcon';
        statusObj.style = (theme: Theme) => ({
          fill: theme.palette.error.main,
        });
        break;
      default:
        statusObj.configurationStatusIcon = 'pendingIcon';
        statusObj.style = {
          fill: '#ff9800',
        };
        break;
    }
    return statusObj;
  };

  useMemo(() => {
    if (data) {
      setIdpDisplay({
        openIdMetadataUrl:
          data?.Tenant?.tenantIdentityProvider?.openIdMetadataUrl ?? '',
        clientId: data?.Tenant?.tenantIdentityProvider?.clientId ?? '',
      });
    }
  }, [data]);

  const renderModules = () => {
    return data?.Tenant?.modules?.map((module: Module) => (
      <>
        <ListItem key={module.id} alignItems="flex-start">
          <Checkbox
            value="module"
            color="primary"
            id={`module-id-moduleId-${module.id}`}
            checked
            label={module.name ?? ''}
            onChange={() => {}}
          />
        </ListItem>
        {module?.taxTypes &&
          module?.taxTypes?.length > 0 &&
          module?.taxTypes?.map((childModule) => (
            <ListItem
              key={childModule.id}
              sx={{ pl: 5 }}
              alignItems="flex-start"
            >
              <Checkbox
                value="test_Checkbox_value"
                color="primary"
                id={`module-id-moduleId-${childModule.id}`}
                checked
                label={childModule.name ?? ''}
                onChange={() => {}}
              />
            </ListItem>
          ))}
      </>
    ));
  };

  useEffect(() => {
    if (data) {
      const status = typeOfConfigurationStatusIcon(
        data.Tenant?.tenantName ?? ''
      );
      const headerData: IHeader = {
        pageTitle: t('pages.tenantView.navTitle'),
        previousPage: t('pages.tenantList.navTitle'),
        route: 'tenantList',
        icon: {
          icon: 'settingsIcon',
          props: { fill: 'black' },
          fontSize: 'large',
        },
        data: [
          {
            id: 'name-header-1',
            label: t('pages.tenantView.tenant'),
            value: data.Tenant?.tenantName || '',
          },
          {
            icon: status.configurationStatusIcon,
            props: status.style,
            fontSize: 'large',
          },
          {
            id: 'name-header-2',
            label: t('pages.tenantView.status'),
            value: data?.Tenant?.configurationStatus || '',
          },
        ],
      };
      if (
        data?.Tenant?.configurationStatus === 'Dns Configuration Error' ||
        data?.Tenant?.configurationStatus === 'Active Tenant' ||
        data?.Tenant?.configurationStatus === 'Tenant Configuring Dns'
      )
        setIsIdPDisabled(false);
      dispatch(setHeader(headerData));
    }
  }, [data, dispatch, t, setIsIdPDisabled]);

  const getFullDomain = (domain: string): string => {
    if (domain.indexOf('http://') == 0 || domain.indexOf('https://') == 0) {
      return domain;
    }
    return 'https://' + domain;
  };

  const showDomains = (
    title: string,
    emptyTitle: string,
    domains: Array<string>
  ) => {
    return (
      <>
        <Typography sx={{ mt: 4 }}>
          {domains.length > 0 ? title : emptyTitle}
        </Typography>
        {data?.Tenant?.tenancyConfiguration?.defaultDomains != null &&
          domains.map((defaultDomain, index) => (
            <Typography variant="h3" key={index}>
              <a href={getFullDomain(defaultDomain) ?? ''} target="_blank">
                {getFullDomain(defaultDomain) ?? ''}
              </a>
            </Typography>
          ))}
      </>
    );
  };

  return (
    <>
      {isFetching && <Loading />}
      <Grid container spacing={3}>
        <Grid sx={{ display: 'flex' }} item xs={12} md={4}>
          <Card>
            <CardHeader
              avatar={
                <ContactPhoneSharpIcon
                  fontSize="large"
                  sx={{ fill: 'primary.main' }}
                />
              }
              title={t('pages.tenantView.tenantContacts')}
            />
            <CardContent>
              <Grid container spacing={3}>
                {data?.Tenant?.tenantContacts?.map(
                  (
                    { contactType, email, fullName, phone, organization },
                    key
                  ) => (
                    <Fragment key={key}>
                      <Grid item xs={12}>
                        {`${contactType} ${t(
                          'pages.tenantView.contactInformation'
                        )}`}
                        <Divider sx={{ pt: 1 }} />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography color="text.secondary">
                          {t('pages.tenantView.name')}
                        </Typography>
                        {fullName}
                        <Typography color="text.secondary" sx={{ mt: 2 }}>
                          {t('pages.tenantView.userOrganization')}
                        </Typography>
                        {organization}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography color="text.secondary">
                          {t('pages.tenantView.phone')}
                        </Typography>
                        {phone}
                        <Typography color="text.secondary" sx={{ mt: 2 }}>
                          {t('pages.tenantView.email')}
                        </Typography>
                        <Link
                          to={{ pathname: '' }}
                          style={{ color: appTheme.palette.linkBlue.dark }}
                        >
                          {email}
                        </Link>
                      </Grid>
                    </Fragment>
                  )
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid sx={{ display: 'flex' }} item xs={12} md={4}>
          <Card
            sx={{ boxShadow: 'rgb(0 0 0 / 35%) 0px 5px 15px', width: '100%' }}
          >
            <CardHeader
              avatar={
                <SettingsSharpIcon
                  fontSize="large"
                  sx={{ fill: 'primary.main' }}
                />
              }
              title={t('pages.tenantView.tenancyConfiguration')}
            />
            <CardContent>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  flexDirection: 'column',
                  p: 1,
                  m: 1,
                }}
              >
                <Typography>{t('pages.tenantView.locale')}</Typography>
                <Typography variant="h3">
                  {data?.Tenant?.tenancyConfiguration?.locale}
                </Typography>
                <Typography sx={{ mt: 4 }}>
                  {t('pages.tenantView.language')}
                </Typography>
                <Typography variant="h3">
                  {data?.Tenant?.tenancyConfiguration?.language}
                </Typography>
                <Typography sx={{ mt: 4 }}>
                  {t('pages.tenantView.timeZone')}
                </Typography>
                <Typography variant="h3">
                  {data?.Tenant?.tenancyConfiguration?.timeZone}
                </Typography>
                <Typography sx={{ mt: 4 }}>
                  {t('pages.tenantView.currency')}
                </Typography>
                <Typography variant="h3">
                  {data?.Tenant?.tenancyConfiguration?.currency}
                </Typography>
                {data?.Tenant?.tenancyConfiguration?.customDomains &&
                data?.Tenant?.tenancyConfiguration?.customDomains?.length ===
                  0 ? (
                  <Typography sx={{ mt: 4 }}>
                    {t('pages.tenantView.noCustomDomainsConfigured')}
                  </Typography>
                ) : (
                  <>
                    <Typography sx={{ mt: 4 }}>
                      {t('pages.tenantView.customDomains')}
                    </Typography>

                    {data?.Tenant?.tenancyConfiguration?.customDomains?.map(
                      (customDomain, index) => (
                        <Typography variant="h3" key={index}>
                          <a
                            href={getFullDomain(customDomain.fqdn ?? '') ?? ''}
                            target="_blank"
                          >
                            {customDomain.fqdn}
                          </a>
                        </Typography>
                      )
                    )}
                  </>
                )}

                {data?.Tenant?.tenancyConfiguration?.defaultDomains &&
                data?.Tenant?.tenancyConfiguration?.defaultDomains?.length ===
                  0 ? (
                  <Typography sx={{ mt: 4 }}>
                    {t('pages.tenantView.noDefaultDomainsConfigured')}
                  </Typography>
                ) : (
                  <>
                    {data?.Tenant?.tenancyConfiguration?.defaultDomains !=
                      null && (
                      <>
                        {showDomains(
                          t('pages.tenantView.revxDefaulDomains'),
                          t('pages.tenantView.noRevxDefaultDomainsConfigured'),
                          orderDomains(
                            data?.Tenant?.tenancyConfiguration?.defaultDomains
                          ).revx
                        )}
                        {showDomains(
                          t('pages.tenantView.onlineDefaulDomains'),
                          t(
                            'pages.tenantView.noOnlineDefaultDomainsConfigured'
                          ),
                          orderDomains(
                            data?.Tenant?.tenancyConfiguration?.defaultDomains
                          ).online
                        )}
                      </>
                    )}
                  </>
                )}
                <Typography sx={{ mt: 4 }}>
                  {t('pages.tenantView.adminName')}
                </Typography>
                <Typography variant="h3">
                  {data?.Tenant?.tenancyConfiguration?.managementAdminName}
                </Typography>
                <Typography sx={{ mt: 4 }}>
                  {t('pages.tenantView.adminEmail')}
                </Typography>
                <Typography variant="h3">
                  <Link
                    to={{ pathname: '' }}
                    style={{ color: appTheme.palette.linkBlue.dark }}
                  >
                    {data?.Tenant?.tenancyConfiguration?.managementAdminEmail}
                  </Link>
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid sx={{ display: 'flex' }} item xs={12} md={4}>
          <Card
            sx={{ boxShadow: 'rgb(0 0 0 / 35%) 0px 5px 15px', width: '100%' }}
          >
            <CardHeader
              title={t('pages.tenantView.modulesTax')}
              avatar={
                <ViewModuleSharpIcon
                  fontSize="large"
                  sx={{ fill: 'primary.main' }}
                />
              }
            />
            <CardContent>
              <List disablePadding>{renderModules()}</List>
            </CardContent>
          </Card>
        </Grid>
        <Grid sx={{ display: 'flex' }} item xs={12} md={4}>
          <Card
            sx={{ boxShadow: 'rgb(0 0 0 / 35%) 0px 5px 15px', width: '100%' }}
          >
            <CardHeader
              avatar={
                <ContactsSharpIcon
                  fontSize="large"
                  sx={{ fill: 'primary.main' }}
                />
              }
              title={t('pages.tenantView.rsiContacts')}
            />
            <CardContent>
              <Grid container spacing={3}>
                {data?.Tenant?.rsiContacts?.map(
                  ({ fullName, email, phone, contactType }, key) => (
                    <Fragment key={key}>
                      <Grid item xs={12}>
                        {`${contactType} ${t(
                          'pages.tenantView.contactInformation'
                        )}`}
                        <Divider sx={{ pt: 1 }} />
                      </Grid>
                      <Grid item xs={6}>
                        <Typography color="text.secondary">
                          {t('pages.tenantView.name')}
                        </Typography>
                        {fullName}
                      </Grid>
                      <Grid item xs={6}>
                        <Typography color="text.secondary">
                          {t('pages.tenantView.phone')}
                        </Typography>
                        {phone}
                        <Typography color="text.secondary" sx={{ mt: 2 }}>
                          {t('pages.tenantView.email')}
                        </Typography>
                        <Link
                          to={{ pathname: '' }}
                          style={{ color: appTheme.palette.linkBlue.dark }}
                        >
                          {email}
                        </Link>
                      </Grid>
                    </Fragment>
                  )
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid sx={{ display: 'flex' }} item xs={12} md={4}>
          <Card
            sx={{ boxShadow: 'rgb(0 0 0 / 35%) 0px 5px 15px', width: '100%' }}
          >
            <CardHeader
              avatar={
                <SecuritySharpIcon
                  fontSize="large"
                  sx={{ fill: 'primary.main' }}
                />
              }
              action={
                <Button
                  id="modalIdpButton"
                  sx={{ minWidth: '115px' }}
                  type="secondary"
                  disabled={idPDisabled(isIdPDisabled)}
                  onClick={() => setIsOpen(true)}
                >
                  <Typography>
                    {t('pages.tenantView.tenantIdentityProvider')}
                  </Typography>
                </Button>
              }
              title={t('pages.tenantView.tenantIdP')}
            />
            <CardContent>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  flexDirection: 'column',
                  p: 1,
                  m: 1,
                }}
              >
                {data &&
                data.Tenant?.tenantIdentityProvider?.openIdMetadataUrl !== '' &&
                data.Tenant?.tenantIdentityProvider?.openIdMetadataUrl !==
                  null &&
                data.Tenant?.tenantIdentityProvider?.clientId !== null &&
                data.Tenant?.tenantIdentityProvider?.clientId !==
                  '00000000-0000-0000-0000-000000000000' ? (
                  <>
                    <Typography>
                      {t('pages.tenantView.modal.tenantOpenIdMetadataUrl')}
                    </Typography>
                    <Typography variant="h3">
                      <a
                        href={idpDisplay.openIdMetadataUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: appTheme.palette.linkBlue.dark }}
                      >
                        {t('pages.tenantView.modal.tenantProvider')}
                      </a>
                    </Typography>
                    <Typography sx={{ mt: 4 }}>
                      {t('pages.tenantView.modal.clientId')}
                    </Typography>
                    <Typography variant="h3">{idpDisplay.clientId}</Typography>
                    <Typography sx={{ mt: 4 }}>
                      {t('pages.tenantView.modal.clientSecret')}
                    </Typography>
                    <Typography variant="h3">
                      *************************
                    </Typography>
                  </>
                ) : (
                  <Typography>
                    {t('pages.tenantView.noIdpConfigured')}
                  </Typography>
                )}
                <Dialog
                  key="modalIdp"
                  id="modalIdp"
                  title={t('pages.tenantView.modal.title')}
                  transactionModalTransactionButtonText="Save"
                  optionalLabel={t('pages.tenantView.tenantIdentityProvider')}
                  width={320}
                  height={380}
                  open={isOpen}
                  handleClose={() => setIsOpen(false)}
                  children={
                    <ModalFields
                      setIsOpen={setIsOpen}
                      setIdpDisplay={setIdpDisplay}
                      idpDisplay={idpDisplay}
                    />
                  }
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid sx={{ display: 'flex' }} item xs={12} md={4}>
          <Card
            sx={{ boxShadow: 'rgb(0 0 0 / 35%) 0px 5px 15px', width: '100%' }}
          >
            <CardHeader
              avatar={
                <CommentSharpIcon
                  fontSize="large"
                  sx={{ fill: 'primary.main' }}
                />
              }
              title={t('pages.tenantView.messageHistory')}
            />
            <CardContent>
              <Box sx={{ display: 'flex' }}>
                <DatePicker
                  id="dataSent"
                  label={t('pages.tenantView.dateSent')}
                  value={null}
                  handleChange={() => {}}
                />
                <Button
                  id="btnResend"
                  sx={{
                    mx: 1,
                    height: 40,
                    alignSelf: 'flex-end',
                  }}
                >
                  {t('pages.tenantView.resend')}
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
