import { useState, useEffect } from 'react';

import PropertyContainer from 'components/properties/PropertyContainer';
import { setHeader } from 'redux/contentSlice';
import { useAppDispatch } from 'redux/hooks';
import { useTranslation } from 'react-i18next';

function PropertyView(): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [activeNoteCard, setActiveNoteCard] = useState<string>('');

  useEffect(() => {
    dispatch(setHeader({ pageTitle: t('pages.propertyView.propertySummary') }));
  }, [dispatch, t]);

  return (
    <>
      <PropertyContainer
        propertyId={''}
        legalDescription={''}
        propertyType={''}
        activeNoteCard={activeNoteCard}
        setActiveNoteCard={setActiveNoteCard}
        propertySubType={''}
        propertyOwnerName={''}
        propertyOwnerId={''}
      />
    </>
  );
}

export default PropertyView;
