import { createContext, RefObject, useRef } from 'react';
import { WorkflowFormReducerState } from './Form/workflowFormReducer';

type WorkflowFormContextTypes = {
  caseStateCopy: RefObject<WorkflowFormReducerState | null>;
  setCaseStateCopy: (state: WorkflowFormReducerState | null) => void;
};

type WorkflowFormContextProviderProps = {
  children: JSX.Element;
};

const WorkflowFormContext = createContext<WorkflowFormContextTypes>({
  caseStateCopy: { current: null },
  setCaseStateCopy: () => {},
});

const WorkflowFormContextProvider = ({
  children,
}: WorkflowFormContextProviderProps) => {
  const caseStateCopy = useRef<WorkflowFormReducerState | null>(null);

  const setCaseStateCopy = (state: WorkflowFormReducerState | null) => {
    caseStateCopy.current = state;
  };

  return (
    <WorkflowFormContext.Provider
      value={{ caseStateCopy: caseStateCopy, setCaseStateCopy }}
    >
      {children}
    </WorkflowFormContext.Provider>
  );
};

export { WorkflowFormContext, WorkflowFormContextProvider };
