import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FileCopy, Edit } from '@mui/icons-material/';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { Box, IconButton } from '@mui/material';
import { useHasEdit } from 'hooks/useHasAccess';
import {
  MessageActionType,
  MessageType,
} from '@revenue-solutions-inc/revxcoreui';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import { ColumnDef, Table } from '@tanstack/react-table';
import Loading from 'components/Loading';
import NoResults from 'components/NoResults';
import SelectRole from 'components/SelectRole';
import { useTranslation } from 'react-i18next';
import { setHeader } from 'redux/contentSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import {
  User,
  PolicyGroup,
  ModuleResponse,
  BusinessPolicyByRoleResponse,
  useRolesByModuleQuery,
  useQueryPlatformOrModuleQuery,
  useBusinessPoliciesByRoleQuery,
  useUsersByRoleQuery,
  RolesByModuleResponse,
} from 'generated/graphql';
import extractMeaningfulMessage from 'utils/errorMessage';
import BusinessPoliciesByRoleInfo from 'components/BusinessPoliciesByRoleInfo';
import PolicyGroupsByRoleInfo from 'components/PolicyGroupsByRoleInfo';
import UsersByRoleInfo from 'components/viewRolesDashboard/UsersByRoleInfo';
import {
  RowExpanded,
  ColumnsExpand,
  showColumn,
  removeRow,
  addRow,
  isOpenColumnType,
} from 'utils/tableColumns';
import RoleDialog from 'components/ManageRoles/RoleDialog';
import TransparentButton from 'components/TransparentButton';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import Dialog from '@revenue-solutions-inc/revxcoreui/material/controls/Dialog';
import {
  recordIcons,
  lockIcon,
  fontSize,
  expandButton,
  collapseButton,
} from 'components/manageConfigTools/Shared/IconButtonStyling/IconButtonStyling';
type RoleAllData = RolesByModuleResponse & {
  users?: Array<User>;
  businessPolicies?: Array<BusinessPolicyByRoleResponse>;
};

type WarningMessage = {
  isOpen: boolean;
  isFetching: boolean;
  url: string;
  businessPolicies: Array<BusinessPolicyByRoleResponse>;
};

const defaultWarningMessage = {
  isOpen: false,
  isFetching: false,
  url: '',
  businessPolicies: [],
};

function Roles(): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const pageModule = useAppSelector((state) => state.user.module);
  const accessCloneRole = useHasEdit('manageRolesCloneRole', 'CloneRoleEdit');
  const accessEditRole = useHasEdit('manageRolesEditRole', 'EditRoleEdit');
  const accessEditRoleOOTB = useHasEdit(
    'manageRolesEditRole',
    'EditRoleEnableOOTBRecordUpdate'
  );
  const [roleId, setRoleId] = useState<string>('');
  const [rolesAllData, setRolesAllData] = useState<RoleAllData[]>([]);
  const [moduleId, setModuleId] = useState<string>('0');
  const [modules, setModules] = useState<ModuleResponse[]>([]);
  const [rows, setRows] = useState<RowExpanded[]>([]);
  const [warningMessage, setWarningMessage] = useState<WarningMessage>(
    defaultWarningMessage
  );
  let tableReset: Table<RoleAllData> | undefined = undefined;

  useEffect(() => {
    dispatch(setHeader({ pageTitle: t('pages.roles.title') }));
  }, [dispatch, t]);

  const handleCloseModal = () => {
    setWarningMessage(defaultWarningMessage);
    setRoleId('');
  };

  const handleRedirectClone = () => {
    if (warningMessage.url !== '') navigate(warningMessage.url);
  };

  const { isFetching: isFetchingModuleList } = useQueryPlatformOrModuleQuery<{
    Modules: [ModuleResponse];
  }>(
    {},
    {
      enabled: true,
      onSuccess: (data) => {
        if (data?.Modules) {
          setModules(data.Modules);
        }
      },
      onError: (error) => {
        const message = extractMeaningfulMessage(
          error,
          t('pages.manageReusableContent.networkError')
        );
        dispatch(
          addMessage({
            message: message,
            type: MessageType.Error,
            actionType: MessageActionType.None,
          })
        );
      },
    }
  );

  const { isFetching: isFetchingRolesByModule } = useRolesByModuleQuery<{
    RolesByModule: RolesByModuleResponse[];
  }>(
    {
      moduleId: moduleId,
    },
    {
      enabled: moduleId !== '',
      onSuccess: (data) => {
        if (data?.RolesByModule) {
          const newRolesAllData: RoleAllData[] = data.RolesByModule.map(
            (role) => {
              return {
                ...role,
                users: [],
                businessPolicies: [],
              };
            }
          );
          setRolesAllData(newRolesAllData);
        }
      },
      onError: (error) => {
        const message = extractMeaningfulMessage(
          error,
          t('pages.manageReusableContent.networkError')
        );
        dispatch(
          addMessage({
            message: message,
            type: MessageType.Error,
            actionType: MessageActionType.None,
          })
        );
      },
    }
  );

  const {
    isFetching: isFetchingBusinessPolicies,
    refetch: refetchBusinessPolicies,
  } = useBusinessPoliciesByRoleQuery<{
    BusinessPoliciesByRole: [BusinessPolicyByRoleResponse];
  }>(
    {
      roleId: roleId,
    },
    {
      enabled: roleId !== '',
      onSuccess: (data) => {
        if (data?.BusinessPoliciesByRole) {
          const newBusinessPolicies = data.BusinessPoliciesByRole;
          if (warningMessage.isFetching) {
            if (newBusinessPolicies.length > 0) {
              setWarningMessage({
                ...warningMessage,
                isOpen: true,
                businessPolicies: newBusinessPolicies,
              });
            } else {
              setWarningMessage(defaultWarningMessage);
              handleRedirectClone();
            }
          } else {
            const newRolesAllDataState = rolesAllData.map((obj) =>
              obj.roleId === roleId
                ? { ...obj, businessPolicies: newBusinessPolicies }
                : obj
            );
            setRolesAllData(newRolesAllDataState);
          }
        }
      },
      onError: (error) => {
        const message = extractMeaningfulMessage(
          error,
          t('pages.manageReusableContent.networkError')
        );
        dispatch(
          addMessage({
            message: message,
            type: MessageType.Error,
            actionType: MessageActionType.None,
          })
        );
      },
    }
  );

  const { isFetching: isFetchingUsers } = useUsersByRoleQuery<{
    UsersByRole: [User];
  }>(
    {
      userId: roleId,
      isAssigned: true,
    },
    {
      enabled:
        roleId !== '' &&
        rows.some((cRow) => cRow.columnType === ColumnsExpand.USERS),
      onSuccess: (data) => {
        if (data?.UsersByRole) {
          const newUsers = data.UsersByRole;
          const newRolesAllDataState = rolesAllData.map((obj) =>
            obj.roleId === roleId ? { ...obj, users: newUsers } : obj
          );
          setRolesAllData(newRolesAllDataState);
        }
      },
      onError: (error) => {
        const message = extractMeaningfulMessage(
          error,
          t('pages.manageReusableContent.networkError')
        );
        dispatch(
          addMessage({
            message: message,
            type: MessageType.Error,
            actionType: MessageActionType.None,
          })
        );
      },
    }
  );

  const handleExpandRow = (
    isExpanded: boolean,
    rowId: string,
    columnType: string,
    rowRoleId: string
  ) => {
    if (isExpanded) {
      setRows(removeRow(rows, rowId));
    } else {
      setRoleId(rowRoleId);
      setRows(addRow(rows, rowId, columnType));
    }
  };

  const handleOpenModal = async (url: string, newRoleId: string) => {
    setRoleId(newRoleId);
    setWarningMessage({ ...warningMessage, isFetching: true, url: url });
    await refetchBusinessPolicies();
  };

  const rolesColumns: ColumnDef<RoleAllData>[] = [
    {
      header: () =>
        accessEditRole ? (
          <HeaderColumnNext localization={t('components.actions.edit')} />
        ) : (
          <></>
        ),
      id: 'role-edit',
      cell: ({ row }) => {
        if (accessEditRole) {
          if (row.original.isOOTB) {
            if (accessEditRoleOOTB)
              return (
                <Link
                  to={{
                    pathname: `/${pageModule}/manageroles/edit/${row.original.roleId}`,
                  }}
                >
                  <Edit sx={recordIcons} />
                </Link>
              );
            else
              return (
                <IconButton disabled={true} sx={recordIcons}>
                  {' '}
                  <Edit />{' '}
                </IconButton>
              );
          } else if (!row.original.isOOTB)
            return (
              <Link
                to={{
                  pathname: `/${pageModule}/manageroles/edit/${row.original.roleId}`,
                }}
              >
                <Edit sx={recordIcons} />
              </Link>
            );
        } else {
          return <></>;
        }
      },
    },
    {
      header: () =>
        accessCloneRole ? (
          <HeaderColumnNext localization={t('components.actions.clone')} />
        ) : (
          <></>
        ),
      id: 'role-clone',
      cell: ({ row }) =>
        accessCloneRole ? (
          <TransparentButton
            onClick={() =>
              handleOpenModal(
                `/${pageModule}/manageroles/clone/${row.original.roleId}`,
                row.original.roleId
              )
            }
          >
            <FileCopy sx={recordIcons} />
          </TransparentButton>
        ) : (
          <></>
        ),
    },
    {
      header: () => <HeaderColumnNext localization={t('pages.roles.isOOTB')} />,
      id: 'statusId',
      cell: ({ row }) => {
        if (row.original.isOOTB)
          return (
            <IconButton sx={lockIcon} disabled aria-label="ootb-button">
              <LockIcon sx={{ fontSize }} />
            </IconButton>
          );
        else if (!row.original.isOOTB)
          return (
            <IconButton sx={lockIcon} disabled aria-label="ootb-button">
              <LockOpenIcon sx={{ fontSize }} />
            </IconButton>
          );
      },
      accessorKey: 'statusId',
    },
    {
      header: () => <HeaderColumnNext localization={t('pages.roles.name')} />,
      accessorKey: 'roleName',
      cell: ({ getValue }) => {
        return (
          <p style={{ width: '15em', wordBreak: 'break-word' }}>
            {getValue() as string}
          </p>
        );
      },
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.roles.description')} />
      ),
      accessorKey: 'roleDescription',
      cell: ({ getValue }) => {
        return (
          <p style={{ width: '30em', wordBreak: 'break-word' }}>
            {getValue() as string}
          </p>
        );
      },
    },
    {
      header: () => (
        <HeaderColumnNext
          localization={`${t('pages.roles.platform')}/${t(
            'pages.roles.module'
          )}`}
        />
      ),
      accessorKey: 'moduleName',
    },
    {
      header: () => (
        <div style={{ width: '7em' }}>
          <HeaderColumnNext localization={t('pages.roles.policyGroups')} />
        </div>
      ),
      id: ColumnsExpand.POLICY_GROUPS,
      cell: ({ row, table }) => {
        tableReset = table;
        return (
          <IconButton
            {...{
              onClick: () => {
                row.toggleExpanded();
                handleExpandRow(
                  row.getIsExpanded(),
                  row.id,
                  ColumnsExpand.POLICY_GROUPS,
                  ''
                );
              },
            }}
            sx={
              row.getIsExpanded() &&
              isOpenColumnType(rows, row.id, ColumnsExpand.POLICY_GROUPS)
                ? collapseButton
                : expandButton
            }
          >
            <ExpandCircleDownIcon />
          </IconButton>
        );
      },
    },
    {
      header: () => (
        <div style={{ width: '7em' }}>
          <HeaderColumnNext localization={t('pages.roles.businessPolicies')} />
        </div>
      ),
      id: ColumnsExpand.BUSINESS_POLICIES,
      cell: ({ row, table }) => {
        const rowRoleId = row.original.roleId;
        tableReset = table;
        return (
          <IconButton
            {...{
              onClick: () => {
                row.toggleExpanded();
                handleExpandRow(
                  row.getIsExpanded(),
                  row.id,
                  ColumnsExpand.BUSINESS_POLICIES,
                  rowRoleId
                );
              },
              name: 'business-policies-btn',
            }}
            sx={
              row.getIsExpanded() &&
              isOpenColumnType(rows, row.id, ColumnsExpand.BUSINESS_POLICIES)
                ? collapseButton
                : expandButton
            }
          >
            <ExpandCircleDownIcon />
          </IconButton>
        );
      },
    },
    {
      header: () => <HeaderColumnNext localization={t('pages.roles.users')} />,
      id: ColumnsExpand.USERS,
      cell: ({ row, table }) => {
        const rowRoleId = row.original.roleId;
        tableReset = table;
        return (
          <IconButton
            {...{
              onClick: () => {
                row.toggleExpanded();
                handleExpandRow(
                  row.getIsExpanded(),
                  row.id,
                  ColumnsExpand.USERS,
                  rowRoleId
                );
              },
            }}
            sx={
              row.getIsExpanded() &&
              isOpenColumnType(rows, row.id, ColumnsExpand.USERS)
                ? collapseButton
                : expandButton
            }
          >
            <ExpandCircleDownIcon />
          </IconButton>
        );
      },
    },
  ];

  const onSelectModule = (newModuleId: string) => {
    if (newModuleId.length !== 0) {
      setModuleId(newModuleId);
      setRows([]);
      if (tableReset) {
        tableReset.resetExpanded();
      }
    }
  };

  return (
    <Box>
      {(isFetchingModuleList || isFetchingRolesByModule) && <Loading />}
      {(!rolesAllData.length || rolesAllData.length === 0) && <NoResults />}
      {rolesAllData.length > 0 && (
        <>
          <DefaultDataTableNext
            columns={rolesColumns as ColumnDef<Record<string, unknown>>[]}
            data={rolesAllData}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'end',
            }}
            customHeader={
              <SelectRole
                showAll
                moduleList={modules}
                handleChange={onSelectModule}
                selectedRole={moduleId}
              />
            }
            renderRowSubComponent={(row) => (
              <Box sx={{ width: '60em', margin: 'auto' }}>
                {showColumn(rows, row.id, ColumnsExpand.POLICY_GROUPS) && (
                  <PolicyGroupsByRoleInfo
                    policyGroups={row?.original?.policyGroups as [PolicyGroup]}
                  />
                )}
                {showColumn(rows, row.id, ColumnsExpand.USERS) && (
                  <UsersByRoleInfo
                    isLoading={isFetchingUsers}
                    users={(row?.original?.users as [User]) ?? []}
                  />
                )}
                {showColumn(rows, row.id, ColumnsExpand.BUSINESS_POLICIES) && (
                  <BusinessPoliciesByRoleInfo
                    isLoading={isFetchingBusinessPolicies}
                    businessPolicies={
                      (row?.original?.businessPolicies as [
                        BusinessPolicyByRoleResponse
                      ]) ?? []
                    }
                  />
                )}
              </Box>
            )}
          />
          <Dialog
            id="warningForCloneRole"
            transactionModalTransactionButtonText={t(
              'pages.roles.buttons.accept'
            )}
            open={warningMessage.isOpen}
            children={
              <RoleDialog
                businessPolicies={warningMessage.businessPolicies}
                isFetchingBusinessPolicies={isFetchingBusinessPolicies}
              />
            }
            title={t('pages.roles.dialog.warning.title')}
            type="transactional"
            width={320}
            height={360}
            handleClose={handleCloseModal}
            handleTransactionClick={handleRedirectClone}
            handleCancelClick={handleCloseModal}
          />
        </>
      )}
    </Box>
  );
}

export default Roles;
