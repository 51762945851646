import { useState, useEffect, ChangeEvent, Fragment } from 'react';

import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { Grid, IconButton, Typography } from '@mui/material';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import Input from '@revenue-solutions-inc/revxcoreui/material/controls/Input';
import Loading from 'components/Loading';
import useMultiMaskInput, { MaskReturn } from 'hooks/useMultiMaskInput';
import { useTranslation } from 'react-i18next';
import { exemptionDefault, TaxExemption } from 'types/taxingAuthority/entities';
interface Props {
  exemptions: TaxExemption[];
  onChange: (exemptions: TaxExemption[]) => void;
  onError: (hasError: boolean) => void;
}

function ExemptionsManager({
  exemptions,
  onChange,
  onError,
}: Props): JSX.Element {
  const [localExemptions, setlocalExemptions] = useState<TaxExemption[]>();
  const [amounts, setAmounts] = useState<(string | null)[]>(['']);
  const [maxAmounts, setMaxAmounts] = useState<(string | null)[]>(['']);
  const [percentages, setPercentages] = useState<(string | null)[]>(['']);

  const { t } = useTranslation();

  const amountNumberMask = useMultiMaskInput(
    '',
    t('pages.manageTaxingAuthority.validation.invalidNumber'),
    /^\d+(\.\d+)?$/,
    amounts
  );

  const maxAmountNumberMask = useMultiMaskInput(
    '',
    t('pages.manageTaxingAuthority.validation.invalidNumber'),
    /^\d+(\.\d+)?$/,
    maxAmounts
  );

  const decimalNumberMask = useMultiMaskInput(
    '',
    t('pages.manageTaxingAuthority.validation.invalidDecimal'),
    /^\d+(\.\d+)?$/,
    percentages
  );

  useEffect(() => {
    onError(
      amountNumberMask.hasErrors ||
        maxAmountNumberMask.hasErrors ||
        decimalNumberMask.hasErrors
    );
  }, [
    amountNumberMask,
    amountNumberMask.hasErrors,
    decimalNumberMask.hasErrors,
    maxAmountNumberMask,
    maxAmountNumberMask.hasErrors,
    onError,
  ]);

  const handleHelperTextChange = (mask: MaskReturn, index: number) => {
    return mask.maskErrors[index];
  };

  const isPercentageDisabled = (exemption: TaxExemption): boolean => {
    if (exemption.amount && exemption.amount !== '') {
      exemption.percentage = null;
      exemption.maximumAmount = null;
      return true;
    }
    return false;
  };

  const isAmountDisabled = (exemption: TaxExemption): boolean => {
    if (exemption.percentage && exemption.percentage !== '') {
      exemption.amount = null;
      return true;
    }
    return false;
  };

  const isMaxAmountDisabled = (exemption: TaxExemption): boolean => {
    if (exemption.amount && exemption.amount !== '') {
      exemption.percentage = '';
      return true;
    }
    return false;
  };

  const isAmountRequired = (exemption: TaxExemption): boolean => {
    if (exemption.percentage !== '') {
      return false;
    }
    return true;
  };

  const isPercentageRequired = (exemption: TaxExemption): boolean => {
    if (exemption.amount !== '') {
      return false;
    }
    return true;
  };

  useEffect(() => {
    setlocalExemptions(exemptions);
    const newExemptions = [...exemptions];
    const amountValues: (string | null)[] = [];
    const maxAmountValues: (string | null)[] = [];
    const percentageValues: (string | null)[] = [];

    newExemptions.forEach((item, index) => {
      amountValues[index] = item.amount;
    });
    newExemptions.forEach((item, index) => {
      maxAmountValues[index] = item.maximumAmount;
    });
    newExemptions.forEach((item, index) => {
      percentageValues[index] = item.percentage;
    });

    setAmounts(amountValues);
    setMaxAmounts(maxAmountValues);
    setPercentages(percentageValues);
  }, [exemptions]);

  return (
    <>
      {<Loading />}

      <Grid item xs={12} pb={2}>
        <Typography variant="h2">
          {t('pages.manageTaxingAuthority.exemptions')}
        </Typography>
      </Grid>
      {exemptions.map((currExemption, index) => {
        return (
          <Fragment key={`${index}_container`}>
            <Grid container spacing={2}>
              <Grid item mb={2}></Grid>
              <Grid item pb={2}></Grid>
              <Grid item pb={2}>
                <Input
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    amountNumberMask.handleMaskChange(event, index);
                    const newExemptions = [...exemptions];
                    newExemptions[index].amount = event.target.value;
                    onChange(newExemptions);
                  }}
                  value={exemptions[index].amount ?? undefined}
                  label={t('pages.manageTaxingAuthority.amount')}
                  id={`exemptionsAmountInput_${index}`}
                  disabled={isAmountDisabled(currExemption)}
                  required={isAmountRequired(currExemption)}
                  helperText={handleHelperTextChange(amountNumberMask, index)}
                />
              </Grid>
              <Grid item pb={2}>
                <Input
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    maxAmountNumberMask.handleMaskChange(event, index);
                    const newExemptions = [...exemptions];
                    newExemptions[index].maximumAmount = event.target.value;
                    onChange(newExemptions);
                  }}
                  value={exemptions[index].maximumAmount ?? undefined}
                  label={t('pages.manageTaxingAuthority.maxAmount')}
                  id={`exemptionsMaxAmountInput_${index}`}
                  disabled={isMaxAmountDisabled(currExemption)}
                  helperText={handleHelperTextChange(
                    maxAmountNumberMask,
                    index
                  )}
                />
              </Grid>
              <Grid item pb={2}>
                <Input
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    decimalNumberMask.handleMaskChange(event, index);
                    const newExemptions = [...exemptions];
                    newExemptions[index].percentage = event.target.value;
                    onChange(newExemptions);
                  }}
                  value={exemptions[index].percentage ?? undefined}
                  label={t('pages.manageTaxingAuthority.percentage')}
                  id={`exemptionsPercentageInput_${index}`}
                  disabled={isPercentageDisabled(currExemption)}
                  required={isPercentageRequired(currExemption)}
                  helperText={handleHelperTextChange(decimalNumberMask, index)}
                />
              </Grid>
              <Grid item mt={2}>
                {index !== 0 && (
                  <IconButton
                    onClick={() => {
                      if (localExemptions) {
                        localExemptions.splice(index, 1);
                        onChange(localExemptions);
                      }
                      amountNumberMask.maskErrors.splice(index, 1);
                      maxAmountNumberMask.maskErrors.splice(index, 1);
                      decimalNumberMask.maskErrors.splice(index, 1);
                    }}
                    aria-label="delete"
                    color="default"
                    size="small"
                  >
                    <CancelRoundedIcon fontSize="small" />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </Fragment>
        );
      })}
      <Grid item xs={2} pt={2}>
        <Button
          id="addExemption"
          variant="outlined"
          type="secondary"
          onClick={() => {
            if (localExemptions) {
              localExemptions.push({
                ...exemptionDefault,
                taxingAuthorityRateType: '',
                taxYear: '',
                amount: '',
                taxingAuthorityExemptionType: '',
                maximumAmount: '',
                maximumPercentage: null,
                percentage: '',
              });
              onChange(localExemptions);
            }
          }}
        >
          {t('pages.manageTaxingAuthority.addExemption')}
        </Button>
      </Grid>
    </>
  );
}

export default ExemptionsManager;
