export type RowExpanded = {
  rowId: string;
  columnType: string;
};

export enum ColumnsExpand {
  POLICY_GROUPS = 'policyGroups',
  USERS = 'users',
  BUSINESS_POLICIES = 'businessPolicies',
  ROLES = 'roles',
}

export const removeRow = (rows: RowExpanded[], rowId: string): RowExpanded[] =>
  rows.filter((cRow) => cRow.rowId !== rowId);

export const addRow = (
  rows: RowExpanded[],
  rowId: string,
  columnType: string
): RowExpanded[] => {
  const newRows = removeRow(rows, rowId);
  newRows.push({
    rowId: rowId,
    columnType: columnType,
  });
  return newRows;
};

export const showColumn = (
  rows: RowExpanded[],
  rowId: string,
  columnType: string
): boolean => {
  return (
    JSON.stringify({
      rowId: rowId,
      columnType: columnType,
    }) === JSON.stringify(rows.find((cRow) => cRow.rowId === rowId))
  );
};

export const isOpenColumnType = (
  rows: RowExpanded[],
  rowId: string,
  columnType: string
): boolean =>
  rows.some((cRow) => cRow.rowId === rowId && cRow.columnType === columnType);
