import { useEffect, useState } from 'react';

import { Grid, Box, Theme } from '@mui/material';
import DateCell from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/TableCells/DateCell';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { Link, useParams, useLocation } from 'react-router-dom';
import { IHeader, setHeader } from 'redux/contentSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import BillList from 'components/BillList';
import { simplifiedTransactionColumns } from 'components/entityManagement/accounts/maintain/PeriodTransactionsSimplified/PeriodTransactionsSimplified';
import Loading from 'components/Loading';
import { addMessage } from 'redux/messageSlice';
import {
  MessageActionType,
  MessageType,
} from '@revenue-solutions-inc/revxcoreui';
import {
  FinancialTransactionGroupResponse,
  useGetTransactionGroupByIdQuery,
  ViewFinancialTransactionResponse,
} from 'generated/graphql';
import extractMeaningfulMessage from 'utils/errorMessage';

export const useGetFinancialTransactionColumns = (currentPageName: string) => {
  const { id } = useParams() as {
    id: string;
  };
  const { t } = useTranslation();
  const module = useAppSelector((state) => state.user.module);

  const prevPath =
    currentPageName === t('pages.paymentDetails.title')
      ? `/paymentdetails/${id}`
      : `/viewfinancialtransgroup/${id}`;
  const Columns: ColumnDef<Record<string, unknown>>[] = [
    {
      id: 'Id',
      accessorKey: 'id',
      header: () => <HeaderColumnNext localization="Transaction" />,
      cell: ({ row }) => (
        <Box
          sx={(theme: Theme) => {
            return {
              a: {
                color: theme.palette.linkBlue.dark,
              },
            };
          }}
        >
          <Link
            to={{
              pathname: `/${module}/financialtransaction/${row.original.id}`,
            }}
            state={{
              prevPath: prevPath,
              prevPageName: currentPageName,
            }}
          >
            {t('pages.viewFinancialTransGroup.viewDetails')}
          </Link>
        </Box>
      ),
    },
    ...simplifiedTransactionColumns,
    {
      id: 'revenuePostDate',
      accessorKey: 'revenuePostDate',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.viewFinancialTransGroup.revenuePostDate')}
        />
      ),
      cell: ({ getValue }) => <DateCell dateString={getValue() as string} />,
    },
  ];
  return Columns;
};

function ViewFinancialTransGroup(): JSX.Element {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const { id } = useParams() as { id: string };
  const [transactionGroupDetails, setTransactionGroupDetails] =
    useState<FinancialTransactionGroupResponse>();
  const [transactions, setTransactions] =
    useState<ViewFinancialTransactionResponse[]>();

  const { data, isLoading, isError } = useGetTransactionGroupByIdQuery(
    {
      transactionGroupId: id,
    },
    {
      onSuccess: (response) => {
        setTransactionGroupDetails(response.GetTransactionGroupById);
        setTransactions(response.GetTransactionGroupTransactions);
      },
      onError: (error) => {
        const message = extractMeaningfulMessage(
          error,
          t('pages.viewFinancialTransGroup.error')
        );
        dispatch(
          addMessage({
            message: message,
            type: MessageType.Error,
            actionType: MessageActionType.None,
          })
        );
      },
    }
  );

  useEffect(() => {
    const headerData: IHeader = {
      pageTitle: t('pages.viewFinancialTransGroup.title'),
      previousPage: location?.state?.prevPageName,
      route: location?.state?.prevPath.substring(1),
      icon: {
        props: { fill: 'black' },
        icon: 'assignmentIcon',
        fontSize: 'large',
      },
      data: [
        {
          id: t('pages.viewFinancialTransGroup.groupType'),
          first: true,
          label: 'Group Type',
          value: transactionGroupDetails?.financialTransactionGroupType ?? '-',
        },
        {
          id: t('pages.viewFinancialTransGroup.stage'),
          label: 'Stage',
          value: transactionGroupDetails?.financialTransactionGroupStage ?? '-',
        },
        {
          id: t('pages.viewFinancialTransGroup.status'),
          label: 'Status',
          value:
            transactionGroupDetails?.financialTransactionGroupStatus ?? '-',
        },
        {
          id: t('pages.viewFinancialTransGroup.balance'),
          label: 'Balance',
          value: transactionGroupDetails?.balance
            ? new Intl.NumberFormat('en-GB', {
                style: 'currency',
                currency: 'USD',
                currencyDisplay: 'narrowSymbol',
              }).format(transactionGroupDetails?.balance)
            : '-',
        },
      ],
    };
    dispatch(setHeader(headerData));
  }, [data, dispatch, location, t, transactionGroupDetails]);

  const columns = useGetFinancialTransactionColumns(
    t('pages.viewFinancialTransGroup.title')
  );

  return (
    <>
      {isLoading && !isError && <Loading />}
      {!isLoading && transactions && (
        <Grid item xs={12}>
          <DefaultDataTableNext
            columns={columns}
            data={transactions}
            globalFilterLabel={t(
              'pages.viewFinancialTransGroup.findTransactions'
            )}
          />
        </Grid>
      )}
      <Grid item xs={12} mt={3}>
        <BillList transactionGroupId={id} />
      </Grid>
    </>
  );
}
export default ViewFinancialTransGroup;
