import { useEffect } from 'react';

import {
  MessageActionType,
  MessageType,
} from '@revenue-solutions-inc/revxcoreui';
import Loading from 'components/Loading';
import NoResults from 'components/NoResults';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import { setHeader } from 'redux/contentSlice';
import { useAppDispatch } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import { useGetRcTypesQuery } from 'generated/graphql';
import ManagerPage from '../../../../components/correspondenceAndHeaderFooter/ManagerPage/ManagerPage';

function ReusableContents(): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const {
    data: reUsablecnt,
    isFetching: isFetching,
    isError: error,
    refetch,
  } = useGetRcTypesQuery();
  useEffect(() => {
    if (error) {
      dispatch(
        addMessage({
          message: t('pages.manageReusableContent.networkError'),
          type: MessageType.Error,
          actionType: MessageActionType.None,
        })
      );
    } else {
      dispatch(setHeader({ pageTitle: t('pages.reusableContent.title') }));
    }
  }, [error, dispatch, t]);

  return (
    <>
      {isFetching && <Loading />}

      {!isFetching && reUsablecnt?.getRCTypes && (
        <ManagerPage
          rc_data={reUsablecnt?.getRCTypes.map((item) => ({
            ...item,
            hasActiveReusableContent:
              item.hasActiveReusableContent == 'true' ? 'Yes' : 'No',
            latestStatus: item.latestStatus == '' ? '-' : item.latestStatus,
          }))}
          isFromTemplate={false}
          reFetch={refetch}
          correspondence_data={[]}
        />
      )}

      {!isFetching && isEmpty(reUsablecnt?.getRCTypes) && <NoResults />}
    </>
  );
}

export default ReusableContents;
