import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { setHeader } from 'redux/contentSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  useGetTasksQuery,
  GetTasks,
  useGetModulesByUserQuery,
  ModuleResponse,
} from 'generated/graphql';
import { ColumnDef } from '@tanstack/react-table';
import Select, {
  NumericSelectType,
  SelectType,
} from '@revenue-solutions-inc/revxcoreui/material/controls/Select/Select';

import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import { Box, Grid, SelectChangeEvent, Theme } from '@mui/material';

import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import Loading from 'components/Loading';

import DateCell from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/TableCells/DateCell';

import { Link } from 'react-router-dom';
import { addMessage } from 'redux/messageSlice';
import {
  MessageActionType,
  MessageType,
} from '@revenue-solutions-inc/revxcoreui';

function ManageScheduledTasksDashboard(): JSX.Element {
  const [moduleValue, setModuleValue] = useState<string>('');
  const [allModules, setAllModules] = useState<string>('');
  const [initialRender, setInitialRender] = useState<boolean>(true);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const module = useAppSelector((state) => state.user.module);
  const {
    data: modulesData,
    isLoading: loadingModules,
    isError,
  } = useGetModulesByUserQuery();
  const {
    data,
    isLoading,
    isError: tasksError,
  } = useGetTasksQuery(
    {
      module:
        allModules !== '' && moduleValue === t('pages.manageChannel.allModules')
          ? allModules
          : moduleValue,
      fetchArchived: false,
    },
    {
      enabled: !!moduleValue,
    }
  );

  useEffect(() => {
    if (isError && tasksError) {
      dispatch(
        addMessage({
          message: t('components.message.error'),
          type: MessageType.Error,
          actionType: MessageActionType.None,
        })
      );
    }
  }, [dispatch, isError, t, tasksError]);

  const moduleValues = () => {
    const values: (SelectType | NumericSelectType)[] = [];
    if (modulesData) {
      if (modulesData.ModulesByUser.results.length > 1) {
        values.push({
          key: t('pages.manageChannel.allModules') as unknown as string,
          desc: t('pages.manageChannel.allModules') as unknown as string,
        });
      }
      modulesData.ModulesByUser.results.forEach((item: ModuleResponse) => {
        values.push({ key: item.name, desc: item.name });
      });
    }
    return values;
  };

  useEffect(() => {
    dispatch(
      setHeader({
        pageTitle: t('pages.manageChannel.titleTask'),
      })
    );
  }, [dispatch, t]);

  const handleChange = (event: SelectChangeEvent<string | number>) => {
    if (
      event.target.value === t('pages.manageChannel.allModules') &&
      modulesData
    ) {
      const values = modulesData.ModulesByUser.results.map(
        (item: ModuleResponse) => {
          return item.name;
        }
      );
      setAllModules(values.toString());
    }
    setModuleValue((event.target as HTMLSelectElement).value);
  };

  useEffect(() => {
    if (
      !initialRender &&
      modulesData &&
      modulesData.ModulesByUser.results.length > 1
    ) {
      setModuleValue(t('pages.manageChannel.allModules'));
      const values = modulesData.ModulesByUser.results.map(
        (item: ModuleResponse) => {
          return item.name;
        }
      );
      setAllModules(values.toString());
    } else if (
      !initialRender &&
      modulesData &&
      modulesData.ModulesByUser.results.length === 1
    ) {
      setModuleValue(
        modulesData.ModulesByUser.results[0].name as unknown as string
      );
    }

    setInitialRender(false);
  }, [dispatch, initialRender, modulesData, t]);

  const Column: ColumnDef<GetTasks>[] = [
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.manageChannel.displayName')} />
      ),
      id: 'schedule-task-name',
      cell: ({ row }) => (
        <Box
          sx={(theme: Theme) => {
            return {
              a: {
                color: theme.palette.linkBlue.main,
              },
            };
          }}
        >
          <Link
            to={{
              pathname: `/${module}/scheduledTaskDetails/${row.original.TaskId}/${row.original.ScheduleTaskName}/${row.original.Module}`,
            }}
          >
            {row.original.DisplayName}
          </Link>
        </Box>
      ),
      accessorKey: 'DisplayName',
    },

    {
      header: () => (
        <HeaderColumnNext localization={t('pages.manageChannel.module')} />
      ),
      id: 'module',
      accessorKey: 'Module',
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.manageChannel.version')} />
      ),
      id: 'version',
      accessorKey: 'Version',
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.manageChannel.status')} />
      ),
      id: 'status',
      accessorKey: 'Status',
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.manageChannel.createdBy')} />
      ),
      id: 'created-by',
      accessorKey: 'CreatedBy',
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.manageChannel.created')} />
      ),
      id: 'created-dttm',
      accessorKey: 'CreatedDttm',
      cell: ({ row }) => {
        return <DateCell dateString={row.original.CreatedDttm || ''} />;
      },
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.manageChannel.updatedBy')} />
      ),
      id: 'updated-by',
      accessorKey: 'UpdatedBy',
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.manageChannel.updatedDttm')} />
      ),
      id: 'updated-dttm ',
      accessorKey: 'UpdatedDttm',
      cell: ({ row }) => {
        return <DateCell dateString={row.original.UpdatedDttm || ''} />;
      },
    },
  ];

  if (
    !isLoading &&
    data &&
    !loadingModules &&
    !initialRender &&
    !tasksError &&
    !isError
  ) {
    return (
      <>
        <Grid container>
          <Grid item xs={12}>
            <DefaultDataTableNext
              columns={Column as ColumnDef<Record<string, unknown>, unknown>[]}
              data={data.getTasks}
              customHeader={
                <Box sx={{ width: '100%' }}>
                  <Select
                    options={moduleValues()}
                    value={moduleValue}
                    id="moduleOptionsTasks"
                    label={t('pages.manageChannel.sortTasks')}
                    onChange={handleChange}
                  />
                </Box>
              }
            />
          </Grid>
        </Grid>
      </>
    );
  }

  if (isLoading || loadingModules || initialRender) {
    return <Loading />;
  }

  return <></>;
}
export default ManageScheduledTasksDashboard;
