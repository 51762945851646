import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';

type UpdateWorkflowButtonProps = {
  workflowKey: string;
  workflowVariantId: string;
};
const UpdateWorkflowButton = ({
  workflowKey,
  workflowVariantId,
}: UpdateWorkflowButtonProps) => {
  const { t } = useTranslation();
  const module = useAppSelector((state) => state.user.module);

  return (
    <Link
      to={`/${module}/workflow/update/${workflowKey}/${workflowVariantId}`}
      style={{ display: 'flex', flex: 1 }}
    >
      <Button variant="contained" size="large" sx={{ flex: 1 }}>
        {t(`components.rowDisplay.updateWorkBtn`)}
      </Button>
    </Link>
  );
};

export default UpdateWorkflowButton;
