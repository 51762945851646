import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { Box, IconButton, SelectChangeEvent, Stack } from '@mui/material';
import { Button, Select, Input } from '@revenue-solutions-inc/revxcoreui';
import { useTranslation } from 'react-i18next';
import { ExtensibleBusinessDriver } from 'types/graphTypes';
import { EditAccessUtil } from 'pages/admin/ConfigTypeEditor/editAccessUtil';
import { deepCopy } from 'utils/deepCopy';
import {
  businessDriverList,
  BusinessDrivers,
  AttributeTypes,
} from 'common/platformConfigUtils/platformConfigUtils';
import { Maybe } from 'generated/graphql';

enum DriverType {
  BOOLEAN = 'Boolean',
  STRING = 'String',
  INT = 'int',
  DATETIME = 'DateTime',
}

// These data types match up by index to the business drivers enum,
// used to look up the data types automatically so the user does not have to choose it manually
const driverDataTypes = [
  DriverType.BOOLEAN, //required
  DriverType.BOOLEAN, //confidential
  DriverType.INT, //matchschore
  DriverType.STRING, //fieldminimum
  DriverType.STRING, //fieldmaximum
  DriverType.STRING, //regularexpression
  DriverType.STRING, //expression
  DriverType.BOOLEAN, //unique
  DriverType.BOOLEAN,
  DriverType.BOOLEAN,
  DriverType.BOOLEAN, //primary key
  DriverType.BOOLEAN,
  DriverType.BOOLEAN,
  DriverType.BOOLEAN,
  DriverType.BOOLEAN,
  DriverType.BOOLEAN, //hidden
];

interface Props {
  businessDrivers: ExtensibleBusinessDriver[];
  updateBusinessDrivers: (newDrivers: ExtensibleBusinessDriver[]) => void;
  editAccess?: EditAccessUtil;
  inEditMode?: boolean;
  attributeType?: Maybe<string>;
  isRepeating?: boolean;
  isExpression?: boolean;
}

function ExtensibleBusinessDrivers({
  businessDrivers = [],
  updateBusinessDrivers,
  editAccess,
  inEditMode = true,
  attributeType,
  isRepeating,
  isExpression,
}: Props) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.configTypeEditor',
  });

  const addDriver = () => {
    let newType = 0;
    for (const driver in BusinessDrivers) {
      if (Number(driver)) {
        if (businessDrivers.some((d) => d.driverNameType == Number(driver))) {
          continue;
        } else {
          newType = Number(driver);
          break;
        }
      }
    }

    const tempDrivers = [...businessDrivers];
    tempDrivers.push({
      driverNameType: newType,
      driverDataType: driverDataTypes[newType],
      driverValue: driverDataTypes[newType] == DriverType.BOOLEAN ? 'True' : '',
    });
    updateBusinessDrivers(tempDrivers);
  };

  const handleTypeChange = (
    event: SelectChangeEvent<string | number>,
    i: number
  ) => {
    const newType = Number(event.target.value);

    if (businessDrivers.some((d) => d.driverNameType == newType)) return;

    const tempDrivers = deepCopy(businessDrivers);
    const tempDriver = tempDrivers[i];
    tempDriver.driverNameType = newType;
    tempDriver.driverDataType = driverDataTypes[tempDriver.driverNameType];
    if (tempDriver.driverDataType === DriverType.BOOLEAN)
      tempDriver.driverValue = 'true';
    else tempDriver.driverValue = '';
    updateBusinessDrivers(tempDrivers);
  };

  const disablePK = () => {
    if (isRepeating || isExpression) return true;

    const allowedAttributeTypes = [
      AttributeTypes.string.toLowerCase(),
      AttributeTypes.int.toLowerCase(),
      AttributeTypes.referenceData.toLowerCase(),
    ];

    return !allowedAttributeTypes.includes(attributeType?.toLowerCase() || '');
  };

  return (
    <Box sx={{ paddingLeft: '50px' }}>
      <hr />
      <Box sx={{ paddingTop: '10px' }}>
        <Button
          sx={{ float: 'right', mr: '20px' }}
          id={'addDriverBtn-configTypeEditor'}
          onClick={addDriver}
          type={'secondary'}
          disabled={
            businessDrivers.length >= businessDriverList.length ||
            !editAccess?.generalEdit ||
            !inEditMode
          }
        >
          {t('addDriver')}
        </Button>
      </Box>
      <Stack direction={'column'} width={'100%'}>
        {businessDrivers.map((driver, driverIndex) => {
          return (
            <Stack
              direction={'row'}
              flexWrap="wrap"
              mb="1.2em"
              spacing={2}
              key={driver.driverNameType}
            >
              <Select
                required
                id="driverNameTypeId"
                label={t('driverNameType')}
                options={businessDriverList}
                value={driver?.driverNameType?.toString() || '0'}
                onChange={(event) => handleTypeChange(event, driverIndex)}
                sx={{ minWidth: '200px', maxWidth: '200px' }}
                disabled={!editAccess?.generalEdit || !inEditMode}
                disabledOptions={
                  disablePK()
                    ? businessDriverList.filter(
                        ({ key }) =>
                          key === BusinessDrivers.PrimaryKey.toString()
                      )
                    : []
                }
              />
              {driver.driverDataType?.toLowerCase() !==
                DriverType.BOOLEAN.toLowerCase() && (
                <Input
                  label={'Value'}
                  id={`driverNameInput_${driverIndex}`}
                  value={driver.driverValue ?? ''}
                  onChange={(event) => {
                    const tempDrivers = deepCopy(businessDrivers);
                    tempDrivers[driverIndex].driverValue = event.target.value;
                    updateBusinessDrivers(tempDrivers);
                  }}
                  disabled={!editAccess?.generalEdit || !inEditMode}
                />
              )}
              {editAccess?.generalEdit && (
                <IconButton
                  onClick={() => {
                    const tempDrivers = [...businessDrivers];
                    tempDrivers.splice(driverIndex, 1);
                    updateBusinessDrivers(tempDrivers);
                  }}
                  aria-label="remove-button"
                  color="default"
                  sx={{ marginTop: '25px', margin: '0px !important' }}
                  disabled={!inEditMode}
                >
                  <CancelRoundedIcon fontSize="small" />
                </IconButton>
              )}
            </Stack>
          );
        })}
      </Stack>
    </Box>
  );
}

export default ExtensibleBusinessDrivers;
