import { gql } from 'graphql-request';

const getFieldFromExpression = gql`
  mutation GetFieldsFromExpression($fields: FieldsFromExpressionInput!) {
    getFieldsFromExpression(fields: $fields) {
      FieldID
      FieldName
      Type
      IsRepeating
    }
  }
`;

export default getFieldFromExpression;
