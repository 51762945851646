import { useEffect, useState } from 'react';

import { LogixGroupType } from '@revenue-solutions-inc/revxcoreui/material/logix/types/entities';

import DataMapperV2UIComponent from 'components/DataMapperV2UIComponent';

import { setHeader } from 'redux/contentSlice';

import { useAppDispatch } from 'redux/hooks';

import { useTranslation } from 'react-i18next';
import { Button } from '@revenue-solutions-inc/revxcoreui';
import Box from '@mui/material/Box';
import logixFile from './logixFile.json';

function DataMapperTestV2(): JSX.Element {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(
      setHeader({
        pageTitle: t('DATA MAPPER V2'),
        route: `dashboard`,
      })
    );
  }, [dispatch, t]);

  const [changeSchema, setChangeSchema] = useState({
    openDialog: false,
    closeDialog: () => {
      setChangeSchema({ ...changeSchema, openDialog: false });
    },
  });

  const handleMappedLogixGroupSchema = (logixUpdated: LogixGroupType) => {
    // eslint-disable-next-line no-console
    console.log(logixUpdated);
    return logixUpdated;
  };

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ fontSize: '20px' }}>
          {t('pages.dataMapperTestHarnessV2.wip')}
        </div>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            gap: '6px',
            alignItems: 'center',
            marginBottom: '5px',
          }}
        >
          <Button
            id="mapbtn"
            size="medium"
            onClick={() => {
              setChangeSchema((p) => {
                return { ...p, openDialog: true };
              });
            }}
            type="secondary"
          >
            {t('pages.dataMapperTestHarnessV2.changeInputSchema')}
          </Button>
          <div style={{ color: 'grey2.main' }}>
            (<i>{t('pages.dataMapperTestHarnessV2.buttonInfo')}</i>)
          </div>
        </Box>
      </Box>
      <DataMapperV2UIComponent
        logixGroupObject={logixFile as unknown as LogixGroupType}
        changeSchema={changeSchema}
        handleMappedLogixGroupSchema={handleMappedLogixGroupSchema}
      />
    </>
  );
}

export default DataMapperTestV2;
