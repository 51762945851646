import { ReactNode, useMemo, useState, useRef } from 'react';

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import { MessageType } from '@revenue-solutions-inc/revxcoreui';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import SingleSearch from '@revenue-solutions-inc/revxcoreui/material/layout/SingleSearch';
import { ConfigurationDomains } from 'common/platformConfigUtils/platformConfigUtils';
import { containerResultStyle, resultStyle, textStyle } from 'common/search';
import ControlledField from 'components/ControlledField';
import EntityManagementContext from 'components/contexts/EntityManagement';
import {
  OOTBIdFormats,
  OOTBIdMaxLength,
  OOTBIdValidationRules,
} from 'components/contexts/EntityManagement/EntityManagementContext';
import { getRelationship } from 'components/entityManagement/common/defaults/relationship';
import { RelationshipFields } from 'components/entityManagement/common/fields/relationships';
import {
  buildRelationshipRequest,
  getLinkType,
  getRelationshipConfigType,
  LinkTypes,
  SourceType,
} from 'components/entityManagement/common/relationshipUtils';
import Loading from 'components/Loading';
import {
  GeneralDataResult,
  GeneralSearchResponse,
  Relationships,
  useCreateRelationshipsMutation,
  useGetEntityManagementConfigurationQuery,
  useSearchByIndexQuery,
} from 'generated/graphql';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import { Section } from 'types/forms';
import { Error } from 'types/graphqlErrors';
import { RelationshipForm } from 'types/relationships';
import { v4 as uuid } from 'uuid';
import { deepCopy } from 'utils/deepCopy';
import extractMeaningfulMessage from 'utils/errorMessage';

interface Props {
  sourceInfoId: string;
  sourceType: string;
  generalSourceType: number;
  refetchRelationships: () => void;
}

const popperStyle = {
  backgroundColor: 'white.main',
  border: '1px solid',
  borderColor: 'secondary.dark',
  borderRadius: 1,
  inset: '9px auto auto 40px !important',
  minWidth: '56.3%',
  zIndex: 1301,
};

const elementBox = {
  border: '1px solid',
  borderColor: 'secondary.dark',
  borderRadius: '5px',
  marginBottom: '20px',
  padding: '5px',
};

function CreateRelationship({
  sourceInfoId,
  sourceType,
  generalSourceType,
  refetchRelationships,
}: Props): JSX.Element {
  const entityIndex = process.env.REACT_APP_ENTITY_INDEX ?? '';
  const accountIndex = process.env.REACT_APP_ACCOUNT_INDEX ?? '';
  const assetIndex = process.env.REACT_APP_ASSET_INDEX ?? '';
  const defaultIndex =
    generalSourceType === SourceType.Entity
      ? `${entityIndex},${accountIndex},${assetIndex}`
      : assetIndex;
  const MAX_RESULTS = 10;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { mutate: createRelationship } = useCreateRelationshipsMutation({});
  const [isRelationshipSaving, setRelationshipSaving] =
    useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string | undefined>('');
  const [results, setResults] = useState<GeneralSearchResponse>();
  const [elementSelected, setElementSelected] = useState<GeneralDataResult>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const platformConfigId = useRef<string>('');
  const inputRef = useRef<HTMLInputElement>(null);
  const open = Boolean(anchorEl);

  const { control, handleSubmit, getValues, setValue, reset } =
    useForm<RelationshipForm>({
      defaultValues: getRelationship,
      mode: 'onChange',
    });

  const { refetch, isFetching } = useSearchByIndexQuery(
    {
      maxResults: MAX_RESULTS,
      indexName: defaultIndex,
      searchPhrase: searchTerm ? searchTerm : '',
    },
    {
      enabled: false,
      onSuccess: async (searchData) => {
        setResults(searchData?.SearchByIndex);
      },
    }
  );

  const { isFetching: isConfigFetching } =
    useGetEntityManagementConfigurationQuery(
      {
        configurationDomain: ConfigurationDomains.ReferenceSchema,
        configurationName: sourceType + '-' + elementSelected?.type ?? '',
      },
      {
        enabled: elementSelected !== undefined,
        onSuccess: (configData) => {
          platformConfigId.current =
            configData.GetEntityManagementConfiguration
              .platformConfigurationId ?? '';
        },
        onError: (e: Error[] | unknown) => {
          let message: string = t('pages.relationships.platformError');
          message = extractMeaningfulMessage(e, message);
          dispatch(
            addMessage({
              type: MessageType.Error,
              message: message,
            })
          );
        },
      }
    );

  const handleKeyDown = (ev: React.KeyboardEvent<HTMLDivElement>) => {
    setAnchorEl(ev.currentTarget);
  };

  const clearInput = () => {
    if (inputRef.current) inputRef.current.value = '';
    setAnchorEl(null);
  };

  const getDestinationLinkType = (): number => {
    if (elementSelected?.resultType === 'Asset') return SourceType.Asset;
    else if (elementSelected?.resultType === 'Account')
      return SourceType.Account;
    return SourceType.Entity;
  };

  const buildRelationshipTypes = (): Section => {
    const newRelationshipFields = deepCopy(RelationshipFields);
    const relTypeField = newRelationshipFields.fields.find(
      (attr) => attr.fieldIdentifier === 'relationshipType'
    );
    if (relTypeField) {
      const linkType = getLinkType(generalSourceType, getDestinationLinkType());
      const relType = getRelationshipConfigType(linkType);
      relTypeField.datasource = relType;
      relTypeField.groupName = 'DestinationRelationshipTypes';
      relTypeField.attributeName = 'DestinationRelationshipType';
      relTypeField.context = 'relationshipType';
    }
    return newRelationshipFields;
  };

  const handleClickOnResult = (result: GeneralDataResult) => {
    if (inputRef.current) inputRef.current.click();
    setElementSelected(result);
    setResults(undefined);
    clearInput();
  };

  const getDestinationId = (): string => {
    if (elementSelected?.resultType === 'Asset')
      return elementSelected.assetInfoId ?? '';
    else if (elementSelected?.resultType === 'Account')
      return elementSelected.accountInfoId ?? '';
    return elementSelected?.entityInfoId ?? '';
  };

  const checkCeaseDate = (): boolean => {
    let isValid = true;
    const relationshipValues = getValues('relationships');
    if (relationshipValues) {
      relationshipValues.forEach((relationship) => {
        if (
          relationship.commenceDate &&
          relationship.ceaseDate &&
          Date.parse(relationship.commenceDate) >=
            Date.parse(relationship.ceaseDate) &&
          isValid
        ) {
          isValid = false;
          dispatch(
            addMessage({
              type: MessageType.Error,
              message: t('pages.relationships.ceaseDateError'),
            })
          );
        }
      });
    }
    return isValid;
  };

  const buildStartSuccessMsg = (linkType: number): string => {
    switch (linkType) {
      case LinkTypes.EntityToEntity:
        return t('pages.relationships.entityToEntity');
      case LinkTypes.EntityToAsset:
        return t('pages.relationships.entityToAsset');
      case LinkTypes.AssetToEntity:
        return t('pages.relationships.assetToEntity');
      case LinkTypes.AssetToAsset:
        return t('pages.relationships.assetToAsset');
      case LinkTypes.EntityToAccount:
        return t('pages.relationships.entityToAccount');
    }
    return '';
  };

  const saveRelationship = async (data: Relationships) => {
    return createRelationship(
      { relationships: data },
      {
        onSuccess: () => {
          const startMsg = buildStartSuccessMsg(
            data.relationships?.[0].linkType ?? LinkTypes.EntityToEntity
          );
          const successMsg = startMsg.concat(t('pages.relationships.success'));
          dispatch(
            addMessage({
              type: MessageType.Success,
              message: successMsg,
            })
          );
          refetchRelationships();
        },
        onError: async (e: Error[] | unknown) => {
          let message: string = t('pages.relationships.error');
          message = extractMeaningfulMessage(e, message);
          dispatch(
            addMessage({
              type: MessageType.Error,
              message: message,
            })
          );
        },
        onSettled: () => {
          setRelationshipSaving(false);
        },
      }
    );
  };

  const onSubmit = async (data: RelationshipForm) => {
    if (checkCeaseDate()) {
      setRelationshipSaving(true);
      const relationshipRequest = buildRelationshipRequest(
        data,
        platformConfigId.current,
        getLinkType(generalSourceType, getDestinationLinkType()),
        sourceInfoId,
        getDestinationId()
      );
      saveRelationship(relationshipRequest);
    }
  };

  /**
   * Provides the needed values to the Entity/Asset Context
   */
  const contextValue = useMemo(() => {
    return {
      selectedType: sourceType + '-' + elementSelected?.type ?? '',
      selectedIdType: '',
      selectedCommenceDate: '',
      onTypeChange: () => {},
      onIdTypeChange: () => {},
      onCommenceDateChange: () => {},
      idValidationRules: OOTBIdValidationRules,
      idFormats: OOTBIdFormats,
      idMaxLengths: OOTBIdMaxLength,
    };
  }, [elementSelected, sourceType]);

  const generateListItems = (result: GeneralDataResult): ReactNode => {
    return (
      <ListItem sx={containerResultStyle} key={uuid()}>
        <Button
          id="btn_"
          size="small"
          type="secondary"
          variant="text"
          sx={resultStyle}
          onClick={() => handleClickOnResult(result)}
        >
          {result.displayString}
        </Button>
      </ListItem>
    );
  };

  const generateResults = (): ReactNode => {
    if (isFetching) {
      return (
        <List>
          <ListItemText sx={textStyle}>
            {t('pages.search.loading')}
          </ListItemText>
        </List>
      );
    }
    if (results) {
      return (
        <List>
          <>
            {results?.entityData?.map((result) => {
              return generateListItems(result);
            })}
            {results?.accountData?.map((result) => {
              return generateListItems(result);
            })}
            {results?.assetsData?.map((result) => {
              return generateListItems(result);
            })}
          </>
        </List>
      );
    }
  };

  return (
    <>
      {!elementSelected && (
        <>
          <SingleSearch
            inputRef={inputRef}
            queryLen={3}
            value={searchTerm}
            displayValue=""
            label={t('pages.appMenuBar.search')}
            placeholder={t('pages.appMenuBar.searchPlaceHolder')}
            refetch={() => refetch()}
            setSearchTerm={setSearchTerm}
            onKeyDown={handleKeyDown}
            onClear={() => clearInput()}
            searchOptions={[
              { id: 'global', label: t('pages.appMenuBar.search') },
            ]}
            handleSelect={() => {}}
            setResults={() => setResults(undefined)}
          />
          <Popper
            id={'relation-entity-search'}
            open={open}
            anchorEl={anchorEl}
            placement="bottom"
            sx={popperStyle}
          >
            {generateResults()}
          </Popper>
        </>
      )}
      {elementSelected && (
        <>
          {(isRelationshipSaving || isConfigFetching) && <Loading />}
          <Box sx={elementBox}>
            <Typography sx={{ fontWeight: 'bold' }}>
              {t(`pages.relationships.selected`)}
            </Typography>
            {elementSelected.displayString}
          </Box>
          <EntityManagementContext.Provider value={contextValue}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <ControlledField
                control={control}
                section={buildRelationshipTypes()}
                setValue={setValue}
                haveIndex={true}
                index={0}
              />
            </form>
          </EntityManagementContext.Provider>
          <Box
            sx={{ display: 'flex', flexDirection: 'row', marginTop: '20px' }}
          >
            <Button
              id="save-new-relationships"
              onClick={handleSubmit(onSubmit)}
            >
              {t(`pages.entitySummary.actions.save`)}
            </Button>
            <Button
              id="reset-relationship-form"
              type="secondary"
              sx={{ marginLeft: '10px' }}
              onClick={() => reset()}
            >
              {t(`pages.entitySummary.actions.reset`)}
            </Button>
            <Button
              id="back-to-search-entities-assets"
              type="secondary"
              sx={{ marginLeft: '10px' }}
              onClick={() => {
                setElementSelected(undefined);
                reset();
              }}
            >
              {t(`pages.entitySummary.actions.back`)}
            </Button>
          </Box>
        </>
      )}
    </>
  );
}

export default CreateRelationship;
