import { useMemo, useState } from 'react';

import { ArrowForwardIos } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/system';
import { MessageType } from '@revenue-solutions-inc/revxcoreui';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import ControlledField from 'components/ControlledField';
import EntityManagementContext from 'components/contexts/EntityManagement';
import {
  OOTBIdFormats,
  OOTBIdMaxLength,
  OOTBIdValidationRules,
} from 'components/contexts/EntityManagement/EntityManagementContext';
import { RelationshipFields } from 'components/entityManagement/common/fields/relationships';
import IdentifierLink from 'components/entityManagement/common/IdentifierLink';
import {
  buildRelationshipRequest,
  getDestinationType,
  getRelationshipConfigType,
  getSourceType,
  LinkTypes,
} from 'components/entityManagement/common/relationshipUtils';
import Loading from 'components/Loading';
import {
  RelationshipContent,
  useGetAccountByIdQuery,
  useGetAssetByIdQuery,
  useGetEntityByIdQuery,
  useUpdateRelationshipMutation,
} from 'generated/graphql';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import { Section } from 'types/forms';
import { RelationshipForm, Relationships } from 'types/relationships';
import { deepCopy } from 'utils/deepCopy';
import extractMeaningfulMessage from 'utils/errorMessage';
import { capitalizeFirstLetter } from 'utils/string-util';

interface Props {
  sourceType: string;
  sourceName: string;
  sourceId: string;
  relationship: Relationships;
  relationshipForm: RelationshipForm;
  refetchData: () => void;
  handleMaintainModal: () => void;
}

const relationshipElement = (theme: Theme) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    border: '2px',
    borderColor: theme.palette.grey3.main,
    borderStyle: 'solid',
    paddingTop: '5px',
    paddingBottom: '5px',
    minWidth: '25%',
  };
};

const borderElement = (theme: Theme) => {
  return {
    fontWeight: 'bold',
    fontSize: '1.1em',
    paddingLeft: '5px',
    borderBottom: '1px',
    borderBottomColor: theme.palette.grey3.main,
    borderBottomStyle: 'solid',
    marginBottom: '5px',
  };
};

const mainSection = {
  display: 'flex',
  flexDirection: 'row',
  marginBottom: '20px',
};

const iconPlacement = {
  paddingTop: '30px',
  paddingBottom: '20px',
  paddingLeft: '15px',
  paddingRight: '15px',
};

const iconColor = (theme: Theme) => {
  return {
    fill: theme.palette.primary.main,
  };
};

function MaintainRelationship({
  sourceType,
  sourceName,
  sourceId,
  relationship,
  relationshipForm,
  refetchData,
  handleMaintainModal,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const generalSourceType = getSourceType(relationship.linkType);
  const generalDestinationType = getDestinationType(relationship.linkType);
  const { mutate: updateRelationship } = useUpdateRelationshipMutation({});
  const [destinationType, setDestinationType] = useState<string>('');
  const [isRelationshipSaving, setRelationshipSaving] =
    useState<boolean>(false);

  const { control, handleSubmit, getValues, setValue, reset } =
    useForm<RelationshipForm>({
      defaultValues: relationshipForm,
      mode: 'onChange',
    });

  useGetEntityByIdQuery(
    { entityId: relationship.destination },
    {
      enabled: generalDestinationType === 'entity',
      onSuccess: (data) => {
        setDestinationType(data.GetEntityById.entityType ?? '');
      },
      onError: () => {
        dispatch(
          addMessage({
            type: MessageType.Error,
            message: t('pages.entitySummary.message.noEntity'),
          })
        );
      },
    }
  );

  useGetAccountByIdQuery(
    { accountId: relationship.destination },
    {
      enabled: generalDestinationType === 'account',
      onSuccess: (data) => {
        setDestinationType(data.GetAccountById.accountType ?? '');
      },
      onError: () => {
        dispatch(
          addMessage({
            type: MessageType.Error,
            message: t('pages.accountSummary.message.noAccount'),
          })
        );
      },
    }
  );

  useGetAssetByIdQuery(
    { assetId: relationship.destination },
    {
      enabled: generalDestinationType === 'asset',
      onSuccess: (data) => {
        setDestinationType(data.GetAssetById.assetType ?? '');
      },
      onError: () => {
        dispatch(
          addMessage({
            type: MessageType.Error,
            message: t('pages.assetSummary.message.noAsset'),
          })
        );
      },
    }
  );

  const checkCeaseDate = (): boolean => {
    let isValid = true;
    const relationshipValues = getValues('relationships');
    if (relationshipValues) {
      relationshipValues.forEach((relationshipVal) => {
        if (
          relationshipVal.commenceDate &&
          relationshipVal.ceaseDate &&
          Date.parse(relationshipVal.commenceDate) >=
            Date.parse(relationshipVal.ceaseDate) &&
          isValid
        ) {
          isValid = false;
          dispatch(
            addMessage({
              type: MessageType.Error,
              message: t('pages.relationships.ceaseDateError'),
            })
          );
        }
      });
    }
    return isValid;
  };

  const buildRelationshipTypes = (): Section => {
    const newRelationshipFields = deepCopy(RelationshipFields);
    const relTypeField = newRelationshipFields.fields.find(
      (attr) => attr.fieldIdentifier === 'relationshipType'
    );
    if (relTypeField) {
      const relType = getRelationshipConfigType(relationship.linkType);
      relTypeField.datasource = relType;
      relTypeField.groupName = 'DestinationRelationshipTypes';
      relTypeField.attributeName = 'DestinationRelationshipType';
      relTypeField.context = 'relationshipType';
    }
    return newRelationshipFields;
  };

  const buildStartSuccessMsg = (linkType: number): string => {
    switch (linkType) {
      case LinkTypes.EntityToEntity:
        return t('pages.relationships.entityToEntity');
      case LinkTypes.EntityToAsset:
        return t('pages.relationships.entityToAsset');
      case LinkTypes.AssetToEntity:
        return t('pages.relationships.assetToEntity');
      case LinkTypes.AssetToAsset:
        return t('pages.relationships.assetToAsset');
      case LinkTypes.EntityToAccount:
        return t('pages.relationships.entityToAccount');
    }
    return '';
  };

  const saveRelationship = async (data: RelationshipContent) => {
    return updateRelationship(
      { relationship: data },
      {
        onSuccess: () => {
          const startMsg = buildStartSuccessMsg(
            data.linkType ?? LinkTypes.EntityToEntity
          );
          const successMsg = startMsg.concat(
            t('pages.relationships.updateSuccess')
          );
          dispatch(
            addMessage({
              type: MessageType.Success,
              message: successMsg,
            })
          );
          refetchData();
          handleMaintainModal();
        },
        onError: (e: Error[] | unknown) => {
          let message: string = t('pages.relationships.error');
          message = extractMeaningfulMessage(e, message);
          dispatch(
            addMessage({
              type: MessageType.Error,
              message: message,
            })
          );
        },
        onSettled: () => {
          setRelationshipSaving(false);
        },
      }
    );
  };

  const onSubmit = async (data: RelationshipForm) => {
    if (checkCeaseDate()) {
      setRelationshipSaving(true);
      const relationshipRequest = buildRelationshipRequest(
        data,
        relationship.platformId,
        relationship.linkType,
        relationship.sourceId,
        relationship.destination,
        relationship.id
      );
      if (relationshipRequest.relationships) {
        saveRelationship(relationshipRequest.relationships[0]);
      } else setRelationshipSaving(false);
    }
  };

  /**
   * Provides the needed values to the Entity/Asset Context
   */
  const contextValue = useMemo(() => {
    return {
      selectedType: sourceType + '-' + destinationType,
      selectedIdType: '',
      selectedCommenceDate: '',
      onTypeChange: () => {},
      onIdTypeChange: () => {},
      onCommenceDateChange: () => {},
      idValidationRules: OOTBIdValidationRules,
      idFormats: OOTBIdFormats,
      idMaxLengths: OOTBIdMaxLength,
    };
  }, [destinationType, sourceType]);

  return (
    <>
      {isRelationshipSaving && <Loading />}
      <Box sx={mainSection}>
        <Box sx={relationshipElement}>
          <Typography sx={borderElement}>
            {t('pages.relationships.list.source')}
          </Typography>
          <Typography noWrap sx={{ paddingLeft: '5px' }}>
            {sourceName}
          </Typography>
          <Typography sx={{ paddingLeft: '5px' }}>{sourceId}</Typography>
          <Typography sx={{ paddingLeft: '5px' }}>
            {sourceType} ({capitalizeFirstLetter(generalSourceType)})
          </Typography>
        </Box>
        <Box sx={iconPlacement}>
          <ArrowForwardIos sx={iconColor} />
        </Box>
        <Box sx={relationshipElement}>
          <Typography sx={borderElement}>
            {t('pages.relationships.list.destination')}
          </Typography>
          <Typography noWrap sx={{ paddingLeft: '5px' }}>
            <IdentifierLink
              id={relationship.destination}
              section={
                relationship.type !== LinkTypes.EntityToAccount
                  ? 'names'
                  : 'account'
              }
              linkType={relationship.linkType}
              onlyText
            />
          </Typography>
          <Typography sx={{ paddingLeft: '5px' }}>
            <IdentifierLink
              id={relationship.destination}
              section="identifiers"
              linkType={relationship.linkType}
              onlyText
            />
          </Typography>
          <Typography sx={{ paddingLeft: '5px' }}>
            {destinationType} ({capitalizeFirstLetter(generalDestinationType)})
          </Typography>
        </Box>
      </Box>
      <EntityManagementContext.Provider value={contextValue}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ControlledField
            control={control}
            section={buildRelationshipTypes()}
            setValue={setValue}
            haveIndex={true}
            index={0}
          />
        </form>
      </EntityManagementContext.Provider>
      <Box sx={{ ...mainSection, marginTop: '15px' }}>
        <Button
          id="save-existing-relationship"
          onClick={handleSubmit(onSubmit)}
        >
          {t(`pages.entitySummary.actions.save`)}
        </Button>
        <Button
          id="reset-existing-relationship-form"
          type="secondary"
          sx={{ marginLeft: '10px' }}
          onClick={() => {
            reset();
          }}
        >
          {t(`pages.entitySummary.actions.reset`)}
        </Button>
      </Box>
    </>
  );
}

export default MaintainRelationship;
