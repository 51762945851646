import { useContext } from 'react';

import { Typography } from '@mui/material';
import { Input } from '@revenue-solutions-inc/revxcoreui';
import { ManageConfigToolsContext } from 'components/contexts/ManageConfigToolsProvider/ManageConfigToolsProvider';
import { CellContext } from '@tanstack/react-table';
import { ConfigurationResponse } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { hasPKAttr } from 'utils/attributeUtils';

export default function ConfigurationName({
  row,
}: CellContext<ConfigurationResponse, unknown>) {
  const { t } = useTranslation();
  const { selectedRecord, setSelectedRecord } = useContext(
    ManageConfigToolsContext
  );

  return (
    <>
      {row.original.platformConfigurationId ===
      selectedRecord?.platformConfigurationId ? (
        <Input
          label={t('pages.configRecordEditor.configName')}
          id={`configurationName_${row.id}`}
          value={selectedRecord?.configurationName}
          onChange={(event) => {
            if (selectedRecord)
              setSelectedRecord({
                ...selectedRecord,
                configurationName: event?.target.value,
              });
          }}
          disabled={selectedRecord && hasPKAttr(selectedRecord)}
          required
        />
      ) : (
        <Typography>{row.original.configurationName}</Typography>
      )}
    </>
  );
}
