import { useEffect, useState } from 'react';

import { Grid, Tabs, Tab } from '@mui/material';
import { Box } from '@mui/system';
import { MessageType } from '@revenue-solutions-inc/revxcoreui';
import TabPanel from '@revenue-solutions-inc/revxcoreui/material/controls/TabPanel';
import AssignedRolesProvider from 'components/contexts/AssignedRoles';
import AssignedRoles from 'components/editUserRoles/AssignedRoles';
import AvailableRoles from 'components/editUserRoles/AvailableRoles';
import Loading from 'components/Loading';
import useGetAccessToken from 'hooks/useGetAccessToken';
import useQueryRequest from 'hooks/useQueryRequest';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation } from 'react-router-dom';
import { IHeader, setHeader } from 'redux/contentSlice';
import { useAppDispatch } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import { UserRole } from 'types/roles';

import { editUserRoleQuery } from './EditUserRolesScript';

interface ResultData {
  tenantId: string;
  user: [UserRole];
}

function EditUserRoles(): JSX.Element {
  const { userId } = useParams() as { userId: string };
  const accessToken = useGetAccessToken();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [currentTab, setCurrentTab] = useState(location.search == '' ? 0 : 1);
  const [tenantId, setTenantId] = useState<string | undefined>(undefined);
  const [userRole, setUserRole] = useState<UserRole | undefined>(undefined);
  const { t } = useTranslation();

  const { data, error, refetch, isFetching } = useQueryRequest<{
    UserByUserId: ResultData;
  }>(
    ['userId', userId],
    accessToken,
    editUserRoleQuery,
    {
      userId: userId,
    },
    'userId'
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
    if (newValue === 0) refetch();
  };

  useEffect(() => {
    if (data) {
      const [user] = data?.UserByUserId.user;
      setTenantId(data?.UserByUserId.tenantId);
      setUserRole(user);
      const headerData: IHeader = {
        pageTitle: t('pages.editUserRole.title'),
        previousPage:
          location.search.length > 0
            ? t('pages.editUserRole.addUser')
            : t('pages.editUserRole.manageUserRoles'),
        route: location.search.length > 0 ? 'addUser' : 'manageuserroles',
        icon: {
          icon: 'personIcon',
          props: { fill: 'black' },
          fontSize: 'large',
        },
        data: [
          {
            id: 'userNameId',
            first: true,
            label: t('pages.editUserRole.userName'),
            value: userRole?.name || '',
          },
          {
            id: 'name',
            label: t('pages.editUserRole.email'),
            value: userRole?.email || '',
          },
        ],
      };
      dispatch(setHeader(headerData));
    }
  }, [data, data?.UserByUserId, userRole, t, dispatch, location]);

  useEffect(() => {
    if (error) {
      dispatch(
        addMessage({
          message: t('components.message.networkerror'),
          type: MessageType.Error,
        })
      );
    }
  }, [error, dispatch, t]);

  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            flexGrow: '1 !important',
            maxWidth: '100% !important',
          }}
        >
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={currentTab}
              onChange={handleTabChange}
              aria-label="role Tabs"
            >
              <Tab label={t('pages.editUserRole.assignedRoles')} />
              <Tab label={t('pages.editUserRole.availableRoles')} />
            </Tabs>
          </Box>
          {userRole && tenantId && (
            <>
              {isFetching && <Loading />}
              <TabPanel selectedIndex={currentTab} index={0}>
                <AssignedRolesProvider
                  userRole={userRole}
                  tenantId={tenantId}
                  refetch={refetch}
                >
                  <AssignedRoles />
                </AssignedRolesProvider>
              </TabPanel>
              <TabPanel selectedIndex={currentTab} index={1}>
                <AvailableRoles
                  userId={userRole.userId}
                  roles={userRole.userRole}
                  refetchUserRoles={refetch}
                />
              </TabPanel>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default EditUserRoles;
