import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  Modal,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/system';
import { useTranslation } from 'react-i18next';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

interface ErrorMessageModalProps {
  openErrorMessage: boolean;
  handleClose: () => void;
  messages: string[];
}

const ErrorMessageModal: React.FC<ErrorMessageModalProps> = ({
  openErrorMessage,
  handleClose,
  messages,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    bgcolor: 'white.main',
    borderRadius: '8px',
  };

  return (
    <Modal
      open={openErrorMessage}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <Box
          sx={{
            height: '50px',
            width: '400px',
            backgroundColor: theme.palette.error.dark,
            display: 'flex',
            alignItems: 'center',
            borderRadius: '8px',
          }}
        >
          <ReportProblemIcon
            sx={{
              color: 'white.main',
              fontSize: '17px',
              marginRight: '5px',
              marginLeft: '10px',
            }}
          />
          <Typography
            id="modal-modal-title"
            sx={{
              color: 'white.main',
              fontSize: '17px',
              fontWeight: 900,
            }}
          >
            {t('pages.manageChannel.stageException')}
          </Typography>
        </Box>
        <Box
          sx={{
            width: '360px',
            marginLeft: '20px',
            marginTop: '10px',
            maxHeight: '300px',
            overflowY: 'scroll',
            overflowX: 'hidden',
          }}
        >
          <List
            sx={{
              listStyleType: 'disc',
              marginLeft: '10px',
              pl: 1,
              '& > * + *': {
                marginTop: '5px',
              },
            }}
          >
            {messages.map((message) => (
              <ListItem
                key={message}
                sx={{
                  display: 'list-item',
                  whiteSpace: 'unset',
                  wordBreak: 'break-all',
                  color: theme.palette.grey[900],
                }}
              >
                {message}
              </ListItem>
            ))}
          </List>
        </Box>
        <Divider
          sx={{
            backgroundColor: theme.palette.grey2.light,
            borderBottomWidth: '0.1px',
            borderTopWidth: '0.1px',
          }}
        />
        <Box
          sx={{
            width: '400px',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50px',
          }}
        >
          <Button
            id="closeModal"
            onClick={() => handleClose()}
            sx={{
              pl: '20px',
              pr: '20px',
              fontSize: '14px',
              borderRadius: '5px',
              fontWeight: 400,
              color: 'White',
              backgroundColor: 'linkBlue.main',
              '&:hover': {
                backgroundColor: 'linkBlue.dark',
              },
            }}
          >
            {t('pages.manageChannel.close')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ErrorMessageModal;
