import { gql } from 'graphql-request';

export const editUserRoleQuery = gql`
  query UserByUserId($userId: String!) {
    UserByUserId(userId: $userId) {
      tenantId
      user {
        email
        name
        userId
        userRole {
          roleId
          moduleId
          moduleName
          roleDescription
          roleName
          startDate
          endDate
          statusId
        }
      }
    }
  }
`;
