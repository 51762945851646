import { Attribute } from 'types/graphTypes';
import { WorkflowContext } from 'types/WorkflowContext';
import { toStringDate } from 'utils/date-util';

export type Groups = {
  [key: string]:
    | string
    | number
    | Groups
    | Attribute[]
    | string[]
    | WorkflowContext[]
    | null
    | undefined;
};

export const groupsToInclude: string[] = [
  'VariantDetails',
  'ActionAttributes',
  'NonDesignGroup',
];

export function processAttributesForBackend(attributes: Attribute[]): Groups {
  const groups: Groups = {};
  attributes.forEach((attribute: Attribute) => {
    const { attributeName, attributeValue, attributeType } = attribute;
    if (attributeName === 'customAttributes' || attributeName === 'Queue') {
      return;
    } else if (attributeName === 'Priority') {
      // in the future there should be a better pattern than hardcoding this property name
      groups.PriorityIndicator = attributeValue;
    } else if (attributeName === 'FormID') {
      groups.DocId = attributeValue;
    } else {
      /*
        There are a few issues here:
          1. Ideally there should be a uniform way across the app to process attributes into their respective types.
              There is currently something similar for the AttributeDetails, but it is tied to the UI.
              It would be nice if there were a similar function purely for processing data.
          2. Currently, if there is a date field, then a valid date must be inputted (i.e., the field cannot be left blank).
              We would need to change the below code in the future if we need to support intentionally leaving a date field blank.
      */
      const value =
        attributeType && attributeType === 'DateTime'
          ? toStringDate(new Date(attributeValue))
          : attributeValue;
      groups[attributeName] = value;
    }
  });
  return groups;
}
