import { getDate } from 'common/helpers';
import { dateAttributes } from 'components/entityManagement/common/entityUtils';
import { processAddressForm } from 'components/entityManagement/common/entityManagementUtils';
import {
  AccountName,
  AccountAddress,
  AccountIdentifier,
  AccountMain,
} from 'types/accounts';
import { AssetName, AssetOther } from 'types/assets';
import {
  EntityAddress,
  EntityEmailAddress,
  EntityIdentifier,
  EntityName,
  EntityPhoneNumber,
  EntityOther,
  IdFormats,
  NullableString,
} from 'types/entities';
import { Section } from 'types/forms';
import { deepCopy } from 'utils/deepCopy';
import { formatIdentifier } from 'utils/formatIdentifier';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
];

const formatDisplayValue = (
  label: NullableString,
  value: NullableString
): string => {
  if (!value || !label) return '-';
  if (label !== '' && value === '') return '-';
  else if (value === 'true') return 'Yes';
  else if (value === 'false') return 'No';
  return value;
};

//TODO: we need to streamline this function to make it more generic and include less logic
export const getLabelAndValueByItem = (
  sectionType: Section,
  attr: string,
  idFormats: IdFormats,
  selectedDate: string,
  sectionData:
    | EntityAddress
    | EntityEmailAddress
    | EntityIdentifier
    | EntityName
    | EntityPhoneNumber
    | EntityOther
    | AccountName
    | AccountAddress
    | AccountIdentifier
    | AccountMain
    | AssetName
    | AssetOther
): string[] => {
  let value = '';
  let isIdValue = false;
  let sectionCopy = deepCopy(sectionType);
  if (sectionCopy.sectionIdentifier === 'addresses') {
    const countryValue =
      sectionData['countryId' as keyof typeof sectionData] ?? 'USA';
    sectionCopy = processAddressForm(sectionType, countryValue);
  }

  const label =
    sectionCopy.fields.find((field) => field.fieldIdentifier === attr)?.label ??
    '';

  if (sectionCopy.sectionIdentifier === 'identifiers' && attr === 'value') {
    const currentIdType = sectionData['IdType' as keyof typeof sectionData];
    isIdValue = true;
    const attrValue = sectionData[attr as keyof typeof sectionData] ?? '';
    value = formatDisplayValue(
      label,
      formatIdentifier(attrValue, idFormats, currentIdType)
    );
  }

  if (dateAttributes.includes(attr)) {
    const dateValue = sectionData[attr as keyof typeof sectionData];
    if (attr === 'commenceDate') {
      if (String(dateValue) === 'Invalid Date') {
        value = getDate(selectedDate);
      } else value = dateValue ? getDate(dateValue) : getDate(selectedDate);
    } else {
      value = dateValue ? getDate(dateValue) : '-';
    }
  } else if (!isIdValue) {
    const attrValue = sectionData[attr as keyof typeof sectionData];
    value = formatDisplayValue(label, attrValue);
  }

  if (
    sectionCopy.sectionIdentifier === 'filingFrequencies' &&
    attr === 'periodFrequencyMonth'
  ) {
    if (value === '-') {
      return ['', value];
    } else {
      //Index is 1 less than value
      const monthName = months[parseInt(value) - 1];
      return [label, monthName];
    }
  }

  return [label, value];
};
