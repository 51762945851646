import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import {
  AdjustmentApplicationOption,
  ApplyToImpactTypeInput,
  CreateAdjustmentInput,
  CreateAdjustmentResponse,
  CreateManualChargeInput,
  GetLookupNamesConfigurationQuery,
  useCreateAdjustmentMutation,
  useCreateManualChargeMutation,
  useGetAdjustmentOptionsQuery,
  useGetLookupNamesConfigurationQuery,
} from 'generated/graphql';
import { Box } from '@mui/system';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Select,
  MessageType,
  MessageActionType,
  Radio,
} from '@revenue-solutions-inc/revxcoreui';
import Dialog from '@revenue-solutions-inc/revxcoreui/material/controls/Dialog';
import { addMessage } from 'redux/messageSlice';
import { SelectType } from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import { Controller, useForm } from 'react-hook-form';
import { useAppDispatch } from 'redux/hooks';
import { format } from 'date-fns';
import { useQueryClient } from '@tanstack/react-query';
import ControlledAmountField from 'components/controls/ControlledAmountField';
import ControlledDateField from 'components/controls/ControlledDateField';
import { ConfigurationModules } from 'common/platformConfigUtils/platformConfigUtils';
import { dateFormat } from 'utils/date-util';
import { getDate } from 'common/helpers';
import extractMeaningfulMessage from 'utils/errorMessage';
import CreateAdjustment from '../CreateAdjustment';
import AdjustmentPreview from '../AdjustmentPreview';

export const buildTypeList = (
  configDescriptions: GetLookupNamesConfigurationQuery | undefined
) => {
  const typeList: SelectType[] = [];
  configDescriptions?.GetLookupTypesConfiguration.map((type) => {
    typeList?.push({
      key: type.configurationName ? type.configurationName : '',
      desc: type.configurationDescription ?? '',
    });
  });
  return typeList;
};

function CreateManualCharge() {
  const [chargeType, setChargeType] = useState<string[]>();
  const [reasonLookups, setReasonLookups] = useState<SelectType[]>([]);
  const [chargeOpen, setChargeOpen] = useState(false);
  const { control, formState, trigger, getValues, reset } = useForm({
    mode: 'all',
  });
  const {
    control: adjustmentControl,
    formState: adjustmentFormState,
    trigger: triggerAdjustment,
    getValues: getAdjustmentValues,
    setValue: setAdjustmentValue,
    reset: resetAdjustment,
  } = useForm({
    mode: 'all',
  });

  const [selectedTransactionType, setSelectedTransactionType] =
    useState<string>('transaction');

  const { isValid } = formState;
  const { isValid: isAdjustmentFormValid } = adjustmentFormState;
  const [adjustmentPreviewData, setAdjustmentPreviewData] =
    useState<CreateAdjustmentResponse>();
  const [impactTypeFields, setImpactTypeFields] = useState<
    AdjustmentApplicationOption[]
  >([]);
  const [showAdjustmentPreview, setShowAdjustmentPreview] =
    useState<boolean>(false);
  const { t } = useTranslation();

  const { periodId } = useParams() as { periodId: unknown };
  const dispatch = useAppDispatch();

  const queryClient = useQueryClient();
  const { data: manualChargeTypesLookup, error: typesLookupError } =
    useGetLookupNamesConfigurationQuery({
      configurationType: 'ManualChargeType',
      configurationModule: ConfigurationModules.Platform,
    });

  const { data: transactionGroupTypesLookup, error: transactionGroupError } =
    useGetLookupNamesConfigurationQuery({
      configurationType: 'FinancialTransactionGroupType',
      configurationModule: ConfigurationModules.Platform,
    });

  const { data: reasonTypesLookup, error: reasonError } =
    useGetLookupNamesConfigurationQuery({
      configurationType: 'ManualChargeReason',
      configurationModule: ConfigurationModules.Platform,
    });

  const { data: adjustmentData } = useGetAdjustmentOptionsQuery(
    {
      periodId: periodId as string,
    },
    {
      enabled: !!periodId,
      onError: (error) => {
        setShowAdjustmentPreview(false);
        const message = extractMeaningfulMessage(
          error,
          t('pages.periodDetails.adjustments.errorAdjustmentOptions')
        );
        dispatch(
          addMessage({
            message: message,
            type: MessageType.Error,
            actionType: MessageActionType.None,
          })
        );
      },
    }
  );

  const invalidateQueries = () => {
    queryClient.invalidateQueries({
      queryKey: ['GetPeriodFinancialTransactions'],
    });
    queryClient.invalidateQueries({
      queryKey: ['GetAccountPeriodDetail'],
    });
    queryClient.invalidateQueries({
      queryKey: ['GetAccountPeriodsAndBalance'],
    });
    queryClient.invalidateQueries({
      queryKey: ['GetPeriodTransactionsSimplified'],
    });
    queryClient.invalidateQueries({
      queryKey: ['GetAdjustmentOptions'],
    });
    void queryClient.invalidateQueries({
      queryKey: ['GetPendingAdjustments'],
    });
  };

  //TODO: add loading state to manual charge button
  const { mutate, isLoading } = useCreateManualChargeMutation({
    onSuccess: () => {
      invalidateQueries();
      setChargeOpen(false);
      reset();
      dispatch(
        addMessage({
          message: t('pages.periodDetails.transactions.manualChargeSuccess'),
          type: MessageType.Success,
          actionType: MessageActionType.None,
        })
      );
    },
    onError: (error) => {
      const message = extractMeaningfulMessage(
        error,
        t('pages.periodDetails.transactions.manualChargeError')
      );
      dispatch(
        addMessage({
          message: message,
          type: MessageType.Error,
          actionType: MessageActionType.None,
        })
      );
    },
  });
  const { mutate: mutateAdjustment, isLoading: isLoadingAdjustment } =
    useCreateAdjustmentMutation({
      onSuccess: (response) => {
        setAdjustmentPreviewData(response.CreateAdjustment);
        setShowAdjustmentPreview(!showAdjustmentPreview);
        if (showAdjustmentPreview) {
          setChargeOpen(false);
          invalidateQueries();
          resetAdjustment();
          setImpactTypeFields([]);
          dispatch(
            addMessage({
              message: t('pages.periodDetails.adjustments.successOnSave'),
              type: MessageType.Success,
              actionType: MessageActionType.None,
            })
          );
        }
      },
      onError: (error) => {
        setShowAdjustmentPreview(false);
        const message = extractMeaningfulMessage(
          error,
          t('pages.periodDetails.adjustments.errorOnSave')
        );
        dispatch(
          addMessage({
            message: message,
            type: MessageType.Error,
            actionType: MessageActionType.None,
          })
        );
      },
    });

  //Reset form when changing periods
  useEffect(() => {
    reset();
  }, [periodId, reset]);

  useEffect(() => {
    if (reasonError || transactionGroupError || typesLookupError) {
      dispatch(
        addMessage({
          message: t(
            'pages.periodDetails.transactions.manualChargeLookupError'
          ),
          type: MessageType.Error,
          actionType: MessageActionType.None,
        })
      );
    }
  }, [reasonError, transactionGroupError, typesLookupError, dispatch, t]);

  //Build a list of allowed reason types based on the selected charge type
  useEffect(() => {
    const reasonList = reasonTypesLookup?.GetLookupTypesConfiguration.filter(
      (type) => {
        //Filter out any reasons that are not allowed with the charge type
        return chargeType?.includes(type.configurationName);
      }
    ).map((type) => {
      return {
        key: type.configurationName ? type.configurationName : '',
        desc: type.configurationDescription ?? '',
      };
    });

    if (reasonList) {
      setReasonLookups(reasonList);
    }
  }, [chargeType, reasonTypesLookup?.GetLookupTypesConfiguration]);

  function buildRequiredError(fieldName: string) {
    return (
      fieldName + ' ' + `${t('pages.periodDetails.transactions.required')}`
    );
  }

  const createChargeBtn = (): JSX.Element => {
    return (
      <Box sx={{ textAlign: 'right', mr: '8px', ml: '20px' }}>
        <Button
          id="create-charge-link"
          type="secondary"
          variant="text"
          onClick={() => {
            setChargeOpen(true);
          }}
        >
          {t('pages.periodDetails.transactions.add')}
        </Button>
      </Box>
    );
  };
  function submitManualCharge() {
    //Trigger form validation
    trigger().then(() => {
      if (isValid) {
        const payload: CreateManualChargeInput = {
          financialAccountPeriodId: Number(periodId),
          manualChargeType: getValues('manualChargeType'),
          transactionGroupType: getValues('transactionGroupType'),
          manualChargeReason: getValues('transactionReasonType'),
          amount: Number(getValues('transactionAmount')),
          effectiveDate: format(
            new Date(getValues('effectiveDate')),
            dateFormat
          ),
        };
        mutate({ charge: payload });
      }
    });
  }

  //Build allowed options for the charge type
  function buildReasonOptions(selectedChargeType: string) {
    const manualReasonOptions: string[] = [];
    const lookups = manualChargeTypesLookup?.GetLookupTypesConfiguration.find(
      (attr) => {
        //Get the charge type from the config list
        return (
          attr.configurationName === selectedChargeType &&
          attr.platformConfigurationInfo
        );
      }
    );

    //Build list of allowed reason types for the selected charge type
    if (lookups) {
      lookups?.platformConfigurationInfo?.configurationSection.forEach(
        (section) => {
          section.group.forEach((group) => {
            group.attribute.forEach((att) => {
              if (att.attributeName === 'ManualChargeReason') {
                if (att && att.repeatingValue) {
                  if (att.repeatingValue[0]?.attributeValue) {
                    manualReasonOptions.push(
                      att.repeatingValue[0]?.attributeValue
                    );
                  }
                }
              }
            });
          });
        }
      );
    }
    setChargeType(manualReasonOptions);
  }

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedTransactionType(event.target.value);
  };

  const getPeriodBeginEndDates = () => {
    const adjustmentOptions = adjustmentData?.GetAdjustmentOptions;
    return `(${getDate(adjustmentOptions?.periodBeginDate ?? '')} - ${getDate(
      adjustmentOptions?.periodEndDate ?? ''
    )})`;
  };

  const setImpactTypeAmounts = () => {
    impactTypeFields.forEach(
      (item) => (item.amount = Number(getAdjustmentValues(item.name as string)))
    );
  };

  const createTransactionContent = (
    <>
      <Grid item xs={'auto'}>
        <Controller
          control={control}
          name="manualChargeType"
          rules={{ required: true }}
          render={({
            field: { value, onChange },
            fieldState: { error: transactionError },
          }) => {
            return (
              <Select
                required
                autowidth={false}
                options={buildTypeList(manualChargeTypesLookup)}
                id="manualChargeType"
                data-testid="transactionType"
                label={t('pages.periodDetails.transactions.transactionType')}
                onChange={(e) => {
                  buildReasonOptions(e.target.value as string);
                  onChange(e);
                }}
                value={value}
                error={
                  transactionError
                    ? buildRequiredError(
                        t('pages.periodDetails.transactions.transactionType')
                      )
                    : ''
                }
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={'auto'}>
        <Controller
          control={control}
          name="transactionGroupType"
          rules={{ required: true }}
          render={({
            field: { value, onChange },
            fieldState: { error: groupError },
          }) => {
            return (
              <Select
                required
                options={buildTypeList(transactionGroupTypesLookup)}
                id="transactionGroupType"
                data-testid="transactionGroupType"
                label={t(
                  'pages.periodDetails.transactions.transactionGroupType'
                )}
                error={
                  groupError
                    ? buildRequiredError(
                        t(
                          'pages.periodDetails.transactions.transactionGroupType'
                        )
                      )
                    : ''
                }
                onChange={onChange}
                value={value}
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={'auto'}>
        <Controller
          control={control}
          rules={{ required: true }}
          name="transactionReasonType"
          render={({
            field: { value, onChange },
            fieldState: { error: reasonSelectError },
          }) => {
            return (
              <Select
                required
                options={reasonLookups}
                id="transactionReasonType"
                data-testid="transactionReasonType"
                label={t('pages.periodDetails.transactions.reason')}
                onChange={onChange}
                error={
                  reasonSelectError
                    ? buildRequiredError(
                        t('pages.periodDetails.transactions.reason')
                      )
                    : ''
                }
                value={value}
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <ControlledAmountField
          control={control}
          label={t('pages.periodDetails.transactions.amount')}
          rules={{
            required: buildRequiredError(
              `${t('pages.periodDetails.transactions.amount')}`
            ),
            pattern: /^-?(\d*\.{0,1}\d{0,2}$)/,
          }}
          name="transactionAmount"
          required
          allowNegative
        />
      </Grid>
      <Grid item xs={12}>
        <ControlledDateField
          required={true}
          rules={{ required: true }}
          name="effectiveDate"
          control={control}
          id="datepicker"
          label={t('pages.periodDetails.transactions.effectiveDate')}
          isCalendarRequired={true}
          size="medium"
          sx={[{ height: '40px' }]}
          requiredErrorMessage={buildRequiredError(
            `${t('pages.periodDetails.transactions.effectiveDate')}`
          )}
        />
      </Grid>
    </>
  );

  const createAdjustmentContent = (
    <>
      {!showAdjustmentPreview ? (
        <CreateAdjustment
          adjustmentOptions={adjustmentData?.GetAdjustmentOptions}
          impactTypeFields={impactTypeFields}
          setImpactTypeFields={setImpactTypeFields}
          control={adjustmentControl}
          getValues={getAdjustmentValues}
          setValue={setAdjustmentValue}
          buildRequiredError={buildRequiredError}
        />
      ) : (
        <AdjustmentPreview
          previewData={adjustmentPreviewData}
          handleEditAdjustment={() => setShowAdjustmentPreview(false)}
          isLoading={showAdjustmentPreview && isLoadingAdjustment}
        />
      )}
    </>
  );

  const modalHeader = (
    <Grid item xs={12} mb={2}>
      <Box sx={{ display: 'flex' }}>
        <Radio
          checked={selectedTransactionType === 'transaction'}
          onChange={handleRadioChange}
          value="transaction"
          color="primary"
          id="transactionRadioBtn"
          label={t('pages.periodDetails.adjustments.transaction')}
        />
        <Radio
          checked={selectedTransactionType === 'adjustment'}
          onChange={handleRadioChange}
          value="adjustment"
          color="primary"
          id="adjustmentRadioBtn"
          label={t('pages.periodDetails.adjustments.adjustment')}
        />
      </Box>
    </Grid>
  );

  const modalBody = (
    <Grid container spacing={2} mb={1}>
      {selectedTransactionType === 'transaction'
        ? createTransactionContent
        : createAdjustmentContent}
    </Grid>
  );

  const modalWrapper = (
    <>
      {!showAdjustmentPreview && modalHeader}
      {modalBody}
    </>
  );

  function submitCreateAdjustment() {
    triggerAdjustment()
      .then(() => {
        if (isAdjustmentFormValid) setImpactTypeAmounts();
      })
      .finally(() => {
        if (isAdjustmentFormValid) {
          const payload: CreateAdjustmentInput = {
            isForecast: !showAdjustmentPreview,
            financialAccountPeriodId: Number(periodId),
            adjustmentType: getAdjustmentValues('adjustmentType'),
            adjustmentReason: getAdjustmentValues('adjustmentReason'),
            reductionType: getAdjustmentValues('reductionType'),
            applyToTarget: getAdjustmentValues('applyAdjustmentTo'),
            applyTo: impactTypeFields as ApplyToImpactTypeInput[],
          };
          mutateAdjustment({ adjustment: payload });
        }
      });
  }

  const renderSaveButtonText = () => {
    return (
      t('pages.periodDetails.transactions.add') + ` ${selectedTransactionType}`
    );
  };

  const handleSave = () => {
    return selectedTransactionType === 'transaction'
      ? submitManualCharge()
      : submitCreateAdjustment();
  };

  return (
    <>
      {createChargeBtn()}
      <Dialog
        id="addTransactionDialog"
        open={chargeOpen}
        children={modalWrapper}
        title={t('pages.periodDetails.transactions.add')}
        type="transactional"
        transactionModalTransactionButtonText={renderSaveButtonText()}
        optionalLabel={
          t('pages.periodDetails.adjustments.forPeriod') +
          ` ${getPeriodBeginEndDates()}`
        }
        handleClose={() => {
          setChargeOpen(false);
        }}
        handleCancelClick={() => {
          setChargeOpen(false);
        }}
        handleTransactionClick={handleSave}
        loading={isLoading || (!showAdjustmentPreview && isLoadingAdjustment)}
      />
    </>
  );
}
export default CreateManualCharge;
