export const parseType = (type: string) => {
  const objTypes = {
    string: 'String',
    int: 'Int32',
    boolean: 'Boolean',
    DateTime: 'DateTime',
    ReferenceData: 'String',
    Currency: 'Currency',
  };

  return objTypes[type as keyof typeof objTypes] || 'String';
};
