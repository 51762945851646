import {
  Attribute,
  GeneralDataResult,
  GetWorkflowGroupsQuery,
} from 'generated/graphql';
import { WorkflowContextType } from 'types/WorkflowContext';
import { Groups } from '../workflowCommon';

export function useProcessWorkData() {
  const getAttributesToSet = (
    groupsToInclude: string[],
    data: GetWorkflowGroupsQuery | undefined
  ) => {
    if (!data?.GetWorkflowGroups) {
      return [];
    }
    const attributesToSet = groupsToInclude.reduce(
      (acc: Attribute[], includedName: string) => {
        const tempAttributes = data?.GetWorkflowGroups.find(
          (g) => g.groupName === includedName
        )?.attribute as Attribute[];
        if (tempAttributes) {
          acc.push(...tempAttributes);
        }
        return acc;
      },
      []
    );
    return attributesToSet;
  };

  const getCustomAttributesToSet = (
    data: GetWorkflowGroupsQuery | undefined
  ) => {
    if (!data) {
      return;
    }

    return data?.GetWorkflowGroups.find(
      (g) => g.groupName === 'CustomAttributes'
    )?.attribute;
  };

  const getCustomTablesToSet = (data: GetWorkflowGroupsQuery | undefined) => {
    if (!data) {
      return;
    }

    return data?.GetWorkflowGroups.find((g) => g.groupName === 'Tables')
      ?.attribute;
  };

  const processAttributesToSet = (
    groups: Groups,
    attributesToSet: Attribute[]
  ) => {
    return attributesToSet.map((attribute: Attribute) => {
      let { attributeName } = attribute;
      // "Priority" is saved with the name "PriorityIndicator" so it needs to be handled as a special case.
      if (attributeName === 'Priority') {
        attributeName = 'PriorityIndicator';
      }
      const value = groups[attributeName];
      if (
        typeof value !== 'undefined' &&
        value !== null &&
        attributeName !== 'customAttributes'
      ) {
        attribute.attributeValue = String(value);
      }
      return attribute;
    });
  };

  const findTabOptionsGroup = (data: GetWorkflowGroupsQuery) => {
    return (
      data?.GetWorkflowGroups.find(
        (g) => g.groupName === 'WorkflowTabOptions'
      )?.attribute.reduce((res, current) => {
        res[current.attributeName] = current.attributeValue === 'true';
        return res;
      }, {} as { [key: string]: boolean }) ?? {}
    );
  };

  const getSteps = (groupsToInclude: string[], attributes: Attribute[]) => {
    const watchedStepCloseSub =
      attributes
        ?.find((a) => a.attributeName === 'Steps')
        ?.attributeValue.toLowerCase() === 'close';

    const otherCloseReasonSub =
      attributes
        ?.find((a) => a.attributeName === 'CloseReason')
        ?.attributeValue.toLowerCase() === 'other';

    const watchCloseCommentsSub = attributes?.find(
      (a) => a.attributeName === 'CloseComments'
    )?.attributeValue;

    const attributesToRenderSub = attributes?.filter((a) => {
      const showCloseReason =
        !watchedStepCloseSub && a.attributeName === 'CloseReason';
      const showCloseComments =
        !watchedStepCloseSub && a.attributeName === 'CloseComments';
      return (
        !groupsToInclude.includes(a.attributeName) &&
        !showCloseReason &&
        !showCloseComments
      );
    });

    return {
      watchedStepClose: watchedStepCloseSub,
      otherCloseReason: otherCloseReasonSub,
      watchCloseComments: watchCloseCommentsSub,
      attributesToRender: attributesToRenderSub,
    };
  };

  const getIsNotesSubjectValid = (attributes: Attribute[]): boolean => {
    const subjectAttribute = attributes?.find(
      (attr) => attr.attributeName === 'NotesSubject'
    );
    return !subjectAttribute ? true : subjectAttribute?.attributeValue !== '';
  };

  const getWorkflowContext = (context: GeneralDataResult) => {
    const getIdFields = {
      [WorkflowContextType[WorkflowContextType.Entity]]: 'entityInfoId',
      [WorkflowContextType[WorkflowContextType.Account]]: 'accountInfoId',
      [WorkflowContextType[WorkflowContextType.Asset]]: 'assetInfoId',
    };

    return context.resultType &&
      (context.entityInfoId || context.accountInfoId || context.assetInfoId)
      ? {
          WorkflowContextType:
            Object.keys(WorkflowContextType)[
              Object.values(WorkflowContextType).indexOf(context.resultType)
            ],
          ContextId: (context as { [key: string]: string })[
            getIdFields[context.resultType]
          ],
        }
      : undefined;
  };

  const getWorkflowTabOptions = (data: GetWorkflowGroupsQuery | undefined) => {
    const options = data?.GetWorkflowGroups ? findTabOptionsGroup(data) : {};

    return {
      hasDemographics: options?.IncludeDemographic ?? false,
      hasCRM: options?.IncludeCRM ?? false,
      hasHistory: options?.IncludeHistory ?? false,
    };
  };

  return {
    getAttributesToSet,
    getCustomAttributesToSet,
    processAttributesToSet,
    getSteps,
    getIsNotesSubjectValid,
    getWorkflowContext,
    getWorkflowTabOptions,
    getCustomTablesToSet,
  };
}
