import { cloneDeep } from 'lodash';
import {
  Address,
  Contact,
  Name,
  addressDefault,
  contactDefault,
  nameDefault,
} from 'types/contact';
import { FiscalDate, fiscalDateDefault } from 'types/entities';

export interface TaxingAuthorityRatesAndExemptions {
  taxingAuthorityId: string;
  taxRates: TaxRate[];
  taxExemptions: TaxExemption[];
  calendarTypeId: string;
  id: string;
}

export interface TaxExemption {
  taxingAuthorityRateType: string;
  taxYear: string;
  amount: string | null;
  taxingAuthorityExemptionType: string;
  maximumAmount: string | null;
  maximumPercentage: string | null;
  percentage: string | null;
}

export interface TaxRate {
  taxingAuthorityRateType: string;
  taxingAuthorityTaxSubtype?: string;
  rate: string;
  taxYear: string;
}

export interface TaxRates {
  calendarTypeId: string;
  taxRates: TaxRate[];
}
export interface Exemptions {
  calendarTypeId: string;
  exemptions: TaxExemption[];
}
export interface TaxingArea {
  id: string;
  name: string;
  description: string;
  effectiveBeginDate: Date | null;
  effectiveEndDate: Date | null;
}
export interface TaxingAuthorityTaxType {
  id: string;
  name: string;
  description: string;
  taxingAuthorityTaxSubtypes: [];
  taxRates: TaxRate[];
  exemptions: TaxExemption[];
}
export interface TaxingAuthority {
  id: string;
  taxingAuthorityId: string;
  taxingAuthorityTypeId: string;
  taxYear: string;
  fiscalDates?: FiscalDate[];
  addresses: Address[];
  emailAddresses?: Contact[];
  phoneNumbers?: Contact[];
  names: Name[];
  taxingAreas: TaxingArea[];
  exemptions: TaxExemption[];
  taxRates: TaxRate[];
  calendarTypeId: string;
}

const taxingAreaDefault: TaxingArea = {
  id: '',
  name: '',
  description: '',
  effectiveBeginDate: new Date(),
  effectiveEndDate: new Date(),
};

const taxRateDefault: TaxRate = {
  taxingAuthorityRateType: '',
  taxingAuthorityTaxSubtype: '',
  rate: '',
  taxYear: '',
};
export interface TaxingAuthorityRatesAndExemptions {
  taxingAuthorityId: string;
  taxRates: TaxRate[];
  taxExemptions: TaxExemption[];
  calendarTypeId: string;
}
const exemptionDefault: TaxExemption = {
  taxingAuthorityRateType: '',
  taxYear: '',
  amount: null,
  taxingAuthorityExemptionType: '',
  maximumAmount: null,
  maximumPercentage: null,
  percentage: null,
};

const exemptionsDefault: Exemptions = {
  calendarTypeId: 'TaxYearOffset',
  exemptions: [exemptionDefault],
};

const taxingAuthorityTaxTypeDefault: TaxingAuthorityTaxType = {
  id: '',
  name: '',
  description: '',
  taxingAuthorityTaxSubtypes: [],
  taxRates: [taxRateDefault],
  exemptions: [exemptionDefault],
};

const defaultTaxingAuthority: TaxingAuthority = {
  taxingAuthorityId: '',
  id: '',
  taxingAuthorityTypeId: '',
  taxYear: '',
  fiscalDates: [fiscalDateDefault],
  addresses: [addressDefault],
  emailAddresses: [contactDefault],
  phoneNumbers: [contactDefault],
  names: [nameDefault],
  taxingAreas: [],
  exemptions: [],
  taxRates: [],
  calendarTypeId: 'TaxYearOffset',
};
const taxRatesDefault: TaxRates = {
  calendarTypeId: 'TaxYearOffset',
  taxRates: [taxRateDefault],
};

export const defaultTaxingAuthorityModel = cloneDeep(defaultTaxingAuthority);
export const defaultTaxRatesModel = cloneDeep(taxRatesDefault);
export const defaultExemptionsModel = cloneDeep(exemptionsDefault);

export {
  defaultTaxingAuthority,
  taxRateDefault,
  taxingAreaDefault,
  exemptionDefault,
  taxingAuthorityTaxTypeDefault,
  fiscalDateDefault,
  taxRatesDefault,
  exemptionsDefault,
};
