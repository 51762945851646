import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { Box, Grid } from '@mui/material';
import { Add } from '@mui/icons-material';
import { MessageType } from '@revenue-solutions-inc/revxcoreui';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import { ConfigurationDomains } from 'common/platformConfigUtils/platformConfigUtils';
import EntityManagementContext from 'components/contexts/EntityManagement';
import CardAvatar from 'components/entityManagement/common/CardAvatar';
import {
  buildEntityRequest,
  findSection,
  preprocessNames,
  sectionNames,
} from 'components/entityManagement/common/entityUtils';
import {
  buildExtendedValues,
  buildMaintainExtendedValues,
  canRemoveSection,
  isCommenceDateValid,
  isCeaseDateValid,
  validateMultiplePrimaryRecords,
} from 'components/entityManagement/common/entityManagementUtils';
import ExtendedAttributesCard from 'components/entityManagement/common/ExtendedAttributes/ExtendedAttributesCard';
import ExtendedAttributesMaintain from 'components/entityManagement/common/ExtendedAttributes/ExtendedAttributesMaintain';
import MaintainSection from 'components/entityManagement/common/MaintainSection';
import SectionCard from 'components/entityManagement/common/SectionCard';
import Loading from 'components/Loading';
import {
  Entity,
  useGetConfigurationSchemaByIdQuery,
  useGetSchemaGroupsQuery,
  useUpdateEntityMutation,
} from 'generated/graphql';
import { useHasAccess } from 'hooks/useHasAccess';
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import { EntityForm, EntityName, EntitySectionNames } from 'types/entities';
import { ExtendedAttributeValues, Section } from 'types/forms';
import { Error } from 'types/graphqlErrors';
import extractMeaningfulMessage from 'utils/errorMessage';
import { v4 as uuid } from 'uuid';

interface Props {
  data: Entity;
  refetchData: () => void;
  entityFormData: EntityForm;
}

function EntitySectionView({
  data: entityData,
  refetchData = () => {},
  entityFormData,
}: Props): JSX.Element {
  const CONFIG_DOMAIN = ConfigurationDomains.ReferenceSchema;
  const addSectionDisabled: string[] = ['others'];
  const { mutate: updateEntity } = useUpdateEntityMutation({});
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const ctx = useContext(EntityManagementContext);
  const canEdit = useHasAccess('maintainEntity', 'edit');
  const [isFormVisible, setFormVisible] = useState<boolean>(false);
  const [isEntitySaving, setEntitySaving] = useState<boolean>(false);
  const [isSectionRemovable, setSectionRemovable] = useState<boolean>(false);
  const [isRefExistent, setRefExistent] = useState<boolean>(false);
  const [isExtendedFormVisible, setExtendedFormVisible] =
    useState<boolean>(false);
  const [isExtendedFormEditable, setExtendedFormEditable] =
    useState<boolean>(false);
  const [mode, setMode] = useState<string>('');
  const [extendedAttribute, setExtendedAttribute] = useState<string>('');
  const [rowIndex, setRowIndex] = useState<number>(0);
  const [sectionActive, setSectionActive] = useState<Section | null>(null);
  const fixedExtendedAttributes = useRef<ExtendedAttributeValues[]>([]);
  const editOnlyExtendedAttributes = useRef<ExtendedAttributeValues[]>([]);

  const methods = useForm<EntityForm>({
    defaultValues: entityFormData,
    mode: 'onChange',
  });

  const { remove } = useFieldArray({
    control: methods.control,
    name: sectionActive?.sectionIdentifier as EntitySectionNames,
  });

  /**
   * Returns the extended attributes existing inside a registered entity
   */
  const getEntityExtendedAttributes =
    useCallback((): ExtendedAttributeValues[] => {
      const entityExtendedAttrs = entityData?.group?.find(
        (attribute) =>
          attribute.groupName?.toLowerCase() === 'extendedattributes'
      )?.attribute;
      if (entityExtendedAttrs) {
        return buildExtendedValues(entityExtendedAttrs);
      } else return [];
    }, [entityData?.group]);

  /**
   * Clean status and refs when extended attributes form needs it
   */
  const cleanEditExtendedAttributes = () => {
    editOnlyExtendedAttributes.current = [];
    setExtendedFormEditable(false);
  };

  /**
   * Determines if this entity type has extended attributes configured
   */
  const { isFetching: isGroupsFetching } = useGetSchemaGroupsQuery(
    {
      configurationDomain: CONFIG_DOMAIN,
      configurationType: 'EntityType',
      configurationName: entityData.entityType ?? '',
    },
    {
      onSuccess: (schemaGroups) => {
        const foundExtended = schemaGroups.GetSchemaGroups.find(
          (sectionItem) => sectionItem.attributeName === 'ExtendedAttribute'
        );
        if (foundExtended) setExtendedAttribute(foundExtended.attributeValue);
        else {
          setExtendedAttribute('');
          cleanEditExtendedAttributes();
        }
      },
      onError: () => {
        setExtendedAttribute('');
        cleanEditExtendedAttributes();
      },
    }
  );

  /**
   * If the entity type has configured extended attributes, retrieve those here
   */
  const { isFetching: isConfigFetching } = useGetConfigurationSchemaByIdQuery(
    {
      platformConfigurationId: extendedAttribute,
    },
    {
      enabled: extendedAttribute !== '',
      onSuccess: (extendedAttributes) => {
        const extAttributes =
          extendedAttributes?.GetConfigurationSchemaById
            .platformConfigurationInfo?.configurationSection[0].group[0]
            .attribute;
        if (extAttributes) {
          const configVals = buildExtendedValues(extAttributes);
          const entityVals = getEntityExtendedAttributes();
          const mergedValues = buildMaintainExtendedValues(
            configVals,
            entityVals
          );
          if (mergedValues.length > 0) {
            editOnlyExtendedAttributes.current = mergedValues;
            setExtendedFormEditable(true);
          } else cleanEditExtendedAttributes();
        } else cleanEditExtendedAttributes();
      },
      onError: () => {
        cleanEditExtendedAttributes();
      },
    }
  );

  /**
   * Saves/updates the entity
   * @param updateData The entity with updated data
   * @param actionMessage A message to display depending the scenario (update, delete...)
   */
  const saveEntity = async (updateData: Entity, actionMessage: string) => {
    updateEntity(
      {
        entity: updateData,
      },
      {
        onSuccess: () => {
          let sectionTitle = sectionActive?.sectionTitle;
          if (!sectionTitle)
            sectionTitle = t('pages.entitySummary.extendedAttributes');
          setFormVisible(false);
          setExtendedFormVisible(false);
          dispatch(
            addMessage({
              type: MessageType.Success,
              message: `${sectionTitle} ${actionMessage} successfully`,
            })
          );
          setMode('');
          refetchData();
        },
        onError: (error: Error[] | unknown) => {
          let message: string = t('pages.entitySummary.message.error');
          message = extractMeaningfulMessage(error, message);
          dispatch(
            addMessage({
              type: MessageType.Error,
              message: message,
            })
          );
        },
        onSettled: () => {
          setEntitySaving(false);
        },
      }
    );
  };

  /**
   * Enables the removal icon when opening the form
   */
  const enableSectionRemoval = (index: number, section: Section) => {
    const canRemove = canRemoveSection(index, section, entityFormData);
    setSectionRemovable(canRemove);
  };

  /**
   * Opens the modal that contains the edition form
   */
  const openForm = (index: number, section: Section, type: string) => {
    if (type === 'edit') enableSectionRemoval(index, section);
    setFormVisible(true);
    setSectionActive(section);
    setRowIndex(index);
    setMode(type);
  };

  /**
   * Opens the modal that contains the extended attributes form
   */
  const openExtendedForm = (type: string) => {
    setExtendedFormVisible(true);
    setSectionActive(null);
    setMode(type);
  };

  /**
   * Closes the modal that contains the edition form
   */
  const closeForm = () => {
    if (mode === 'add') remove(rowIndex);
    methods.clearErrors();
    methods.resetField(sectionActive?.sectionIdentifier as EntitySectionNames);
    setSectionRemovable(false);
    setFormVisible(false);
    setMode('');
  };

  /**
   * Closes the extended attributes form
   */
  const closeExtendedForm = () => {
    setExtendedFormVisible(false);
    setMode('');
  };

  /**
   * Its used to determine the index when a new item is added
   * @param sectionName Name of the section
   * @returns Index of a new item to be added
   */
  const getSectionLength = (sectionName: string): number => {
    return entityFormData[sectionName as keyof EntityForm]?.length ?? 0;
  };

  /**
   * Compares default and new data to determine the action message
   * @param data Data to be sent to the endpoint
   */
  const getActionMessage = (data: EntityForm): string => {
    const defaultLengths = sectionNames
      .filter((sectionName) => !addSectionDisabled.includes(sectionName))
      .map((section) => getSectionLength(section))
      .reduce((total, current) => total + current);

    const newDataLengths = sectionNames
      .filter((sectionName) => !addSectionDisabled.includes(sectionName))
      .map((section) => {
        const sectionValues = data[section as EntitySectionNames];
        if (sectionValues) {
          return sectionValues.length ?? 0;
        }
        return 0;
      })
      .reduce((total, current) => total + current);
    if (defaultLengths === newDataLengths) return 'updated';
    else if (defaultLengths > newDataLengths) return 'deleted';
    else return 'added';
  };

  /**
   * Deletes the optional sections if no data was entered
   * @param data Data cleaned of empty sections
   */
  const processOptionalSections = (data: EntityForm) => {
    if (data.phoneNumbers != undefined && data.phoneNumbers?.length === 0) {
      delete data.phoneNumbers;
    }
    if (data.emailAddresses != undefined && data.emailAddresses.length === 0) {
      delete data.emailAddresses;
    }
  };

  /**
   * Validates commence date on the section to ensure it is not earlier than Entity commence date
   * @param sectionId Name of the section
   * @returns If the commence date of the section is valid or not
   */
  const checkCommenceDate = (sectionId: EntitySectionNames): boolean => {
    if (sectionId === 'others') return true;
    let isValid = true;
    const sectionValues = methods.getValues(sectionId);
    if (sectionValues) {
      sectionValues.forEach((value) => {
        if (
          value.commenceDate &&
          isValid &&
          !isCommenceDateValid(value.commenceDate, ctx.selectedCommenceDate)
        ) {
          isValid = false;
          dispatch(
            addMessage({
              type: MessageType.Error,
              message: t('pages.createEntity.message.commenceDateError'),
            })
          );
        }
      });
    }
    return isValid;
  };

  /**
   * Checks the cease date is set before the commence date
   * @param sectionId Name of the section
   * @returns If the cease date is valid or not
   */
  const checkCeaseDate = (sectionId: EntitySectionNames): boolean => {
    let isValid = true;
    const sectionValues = methods.getValues(sectionId);
    if (sectionValues) {
      sectionValues.forEach((value) => {
        if (
          value.ceaseDate &&
          isValid &&
          !isCeaseDateValid(
            value.commenceDate,
            value.ceaseDate,
            ctx.selectedCommenceDate
          )
        ) {
          isValid = false;
          dispatch(
            addMessage({
              type: MessageType.Error,
              message: t('pages.createEntity.message.ceaseDateError'),
            })
          );
        }
      });
    }
    return isValid;
  };

  /**
   * Validates the edition of a primary in a section
   * @param data All data contained in hook form
   * @returns If its valid the change in primary or not
   */
  const validatePrimaryRecords = (data: EntityForm): boolean => {
    if (sectionActive?.sectionIdentifier === 'others') return true;
    const message = t('pages.entitySummary.message.onlyOnePrimary');
    const isValid = validateMultiplePrimaryRecords(
      data,
      sectionActive?.sectionIdentifier
    );
    if (!isValid) {
      dispatch(
        addMessage({
          type: MessageType.Error,
          message: `${sectionActive?.sectionTitle} ${message}`,
        })
      );
    }
    return isValid;
  };

  /**
   * Validates if only one primary is selected for each section
   * @param sectionId Section name
   * @returns If the primary selected is valid or not
   */
  const checkPrimaries = (
    sectionId: EntitySectionNames
  ): boolean | undefined => {
    if (addSectionDisabled.includes(sectionId)) return true;
    const sectionGetValues = methods.getValues(sectionId);
    const VALID_PRIMARIES = 1;
    let isPrimaryCount = 0;
    sectionGetValues?.forEach((sectionValues) => {
      for (const index in sectionValues) {
        if (
          (index.includes('isPrimary') || index.includes('active')) &&
          sectionValues[index as keyof typeof sectionValues] === 'true'
        )
          isPrimaryCount++;
      }
    });
    if (isPrimaryCount === VALID_PRIMARIES) return true;
    else {
      dispatch(
        addMessage({
          type: MessageType.Error,
          message: t('pages.createEntity.message.primariesError'),
        })
      );
      return false;
    }
  };

  /**
   * Validates all needed conditions are passing in order to update an entity
   * @param data Entity data
   * @returns Boolean that says its valid or not
   */
  const checkAllConditions = (data: EntityForm): boolean => {
    const sectionActiveName =
      sectionActive?.sectionIdentifier as EntitySectionNames;
    if (
      validatePrimaryRecords(data) &&
      checkPrimaries(sectionActiveName) &&
      checkCommenceDate(sectionActiveName) &&
      checkCeaseDate(sectionActiveName)
    ) {
      return true;
    }
    return false;
  };

  /**
   * Pre-process optional and primaries data for each section
   * @param data Data cleaned and preprocessed
   */
  const onSubmit = (data: EntityForm) => {
    processOptionalSections(data);
    if (isExtendedFormVisible || checkAllConditions(data)) {
      const actionMessage = getActionMessage(data);
      setEntitySaving(true);
      const entityRequest: Entity = buildEntityRequest(
        data,
        entityData.entityType ?? '',
        entityData.platformConfigurationId ?? '',
        fixedExtendedAttributes.current,
        entityData.id || undefined
      );
      saveEntity(entityRequest, actionMessage);
    }
  };

  /**
   * Pre-process Extended Attributes and then saves normally Entity data
   * @param updateExtendedAttrs Extended attributes updated
   */
  const updateExtendedAttributes = (
    updateExtendedAttrs: ExtendedAttributeValues[]
  ) => {
    fixedExtendedAttributes.current = updateExtendedAttrs;
  };

  /**
   * Renders the form that enables adding and editing
   * @returns The form of the selected section
   */
  const getForm = (): JSX.Element => {
    if (sectionActive) {
      return (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <MaintainSection
              section={sectionActive}
              fieldIdentifierType="entityType"
              localizedType="entitySummary"
              datasourceType="EntityToIdTypes"
              enableRemove={isSectionRemovable}
              sectionIndex={rowIndex}
              avatar={
                <CardAvatar sectionName={sectionActive.sectionIdentifier} />
              }
              closeForm={closeForm}
              handleSave={methods.handleSubmit(onSubmit)}
            />
          </form>
        </FormProvider>
      );
    }
    return <></>;
  };

  /**
   * Renders the extended attributes maintain form
   * @returns The maintain form of extended attributes
   */
  const getExtendedForm = (): JSX.Element => {
    return (
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <ExtendedAttributesMaintain
            extendedValues={editOnlyExtendedAttributes.current}
            avatar={<CardAvatar sectionName="extended" />}
            closeForm={closeExtendedForm}
            handleSave={methods.handleSubmit(onSubmit)}
            handleExtendedAttributes={updateExtendedAttributes}
          />
        </form>
      </FormProvider>
    );
  };

  /**
   * Displays the buttons at the top that enables adding new items
   * @returns An array of nodes that contains all buttons
   */
  const displayAddButtons = (): JSX.Element[] => {
    if (mode !== '') return [];
    const addButtons: JSX.Element[] = [];
    sectionNames
      .filter((sectionName) => !addSectionDisabled.includes(sectionName))
      .forEach((sectionName) => {
        const fields = findSection(sectionName);
        if (fields && canEdit) {
          addButtons.push(
            <Button
              key={`add-section-${fields.sectionIdentifier}`}
              id={`add-section-${fields.sectionIdentifier}`}
              type="secondary"
              endIcon={<Add />}
              sx={{ width: '100%', marginRight: '20px', marginTop: '5px' }}
              onClick={() =>
                openForm(
                  getSectionLength(fields.sectionIdentifier),
                  fields,
                  'add'
                )
              }
            >
              {fields.sectionTitle}
            </Button>
          );
        }
      });
    if (getEntityExtendedAttributes().length === 0 && isExtendedFormEditable) {
      addButtons.push(
        <Button
          key="add-section-extended-attributes"
          id="add-section-extended-attributes"
          type="secondary"
          endIcon={<Add />}
          sx={{ width: '100%', marginRight: '20px', marginTop: '5px' }}
          onClick={() => openExtendedForm('add')}
        >
          {t('pages.entitySummary.extendedAttributes')}
        </Button>
      );
    }
    return addButtons;
  };

  /**
   * Displays each one of the cards in Entity Details
   * @param section Name of the section to be rendered
   * @returns An array of nodes with all the cards
   */
  const displaySectionCards = (section: string): JSX.Element[] => {
    if (mode !== '') return [];
    const fields = findSection(section);
    if (section === 'names')
      preprocessNames(
        entityFormData[section as keyof EntityForm] as EntityName[]
      );
    const groups = entityFormData[section as keyof EntityForm];

    const cards: JSX.Element[] = [];
    if (!groups || !fields) return cards;
    groups.forEach((group, index) => {
      cards.push(
        <SectionCard
          group={group}
          fields={fields}
          index={index}
          remove={remove}
          openForm={openForm}
          canEdit={canEdit}
          handleSubmit={methods.handleSubmit(onSubmit)}
          key={`${fields.sectionIdentifier}-${uuid()}-detail-card`}
          keyName={`${fields.sectionIdentifier}-${index}-detail-card`}
        />
      );
    });
    return cards;
  };

  useEffect(() => {
    const extAttributes = getEntityExtendedAttributes();
    if (extAttributes.length > 0) {
      fixedExtendedAttributes.current = extAttributes;
      setRefExistent(true);
    }
  }, [entityData?.group, getEntityExtendedAttributes]);

  return (
    <Box sx={{ marginBottom: '10px' }}>
      {(isEntitySaving || isGroupsFetching || isConfigFetching) && <Loading />}
      <Box sx={{ marginBottom: '20px', width: { xs: '75%', md: '100%' } }}>
        {displayAddButtons()}
      </Box>
      {isFormVisible && canEdit && getForm()}
      {isExtendedFormVisible && canEdit && getExtendedForm()}
      <Grid container spacing={3}>
        {sectionNames.map((sectionName) => {
          return displaySectionCards(sectionName);
        })}
        {mode === '' && isRefExistent && (
          <ExtendedAttributesCard
            extendedData={fixedExtendedAttributes.current}
            canEdit={canEdit}
            isEditableByConfig={isExtendedFormEditable}
            openForm={openExtendedForm}
          />
        )}
      </Grid>
    </Box>
  );
}

export default EntitySectionView;
