import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import Loading from 'components/Loading';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { IHeader, setHeader } from 'redux/contentSlice';
import { useAppDispatch } from 'redux/hooks';
import {
  useArchiveTaskMutation,
  useGetTaskHistoryQuery,
} from 'generated/graphql';
import DownloadIcon from '@mui/icons-material/Download';
import { format } from 'date-fns';
import RunHistory from './RunHistoryTable';
import VersionHistory from './VersionHistoryTable';

function TaskDetailsPage(): JSX.Element {
  const dispatch = useAppDispatch();
  const { taskId, taskName, currentModule } = useParams() as {
    currentModule: string;
    taskName: string;
    taskId: string;
  };
  const [initialRender, setInitialRender] = useState<boolean>(true);
  const [versionValue, setVersionValue] = useState<number>(0);
  const [nextTime, setNextTime] = useState<string>('');
  const { t } = useTranslation();
  const { isLoading: loadingDelete } = useArchiveTaskMutation();

  const { data, isLoading } = useGetTaskHistoryQuery({
    module: currentModule,
    taskName: taskName,
  });

  const [openDelete, setIsOpenDelete] = useState<boolean>(false);

  useEffect(() => {
    if (initialRender === false && !isLoading && data) {
      const versionArray = data.getScheduledTaskHistory.map((item) => {
        if (item.Status === 'Active') {
          return item.Version as unknown as number;
        } else {
          return 0;
        }
      });
      const nextRunArray = data.getScheduledTaskHistory.map((item) => {
        if (item.Status === 'Active' && item.NextRunOccurrence) {
          return item.NextRunOccurrence;
        } else {
          return '';
        }
      });
      const runValue = nextRunArray.find((element) => element !== '');

      const versionValueData = versionArray.find((element) => element > 0);
      if (versionValueData) {
        setVersionValue(versionValueData);
      }
      if (runValue) {
        setNextTime(runValue);
      }
    }
    setInitialRender(false);
  }, [data, data?.getScheduledTaskHistory, initialRender, isLoading]);

  useEffect(() => {
    if (!isLoading && data) {
      const headerData: IHeader = {
        pageTitle: t('pages.manageChannel.taskDetailsPage'),
        previousPage: t('pages.manageChannel.manageTask'),
        route: `managescheduledtasks/`,
        icon: {
          props: { fill: 'black' },
          icon: 'assignmentIcon',
          fontSize: 'large',
        },
        data: [
          {
            id: 'task-schedule-name',
            first: true,
            label: t('pages.manageChannel.ScheduleTaskName'),
            value: taskName,
          },
          {
            id: 'task-schedule-id',
            label: t('pages.manageChannel.ScheduleTaskId'),
            value: taskId,
          },
          {
            id: 'active-version',
            label: t('pages.manageChannel.activeVersion'),
            value: versionValue > 0 ? versionValue.toString() : 'N/A',
          },
          {
            id: 'next-RunTime',
            label: t('pages.manageChannel.nextRunTime'),
            value:
              nextTime !== '' && new Date(nextTime) > new Date()
                ? format(new Date(nextTime), 'MM/dd/yyyy hh:mm:ss a')
                : 'N/A',
          },
        ],
        buttonLinks: [
          {
            action() {
              setIsOpenDelete(true);
            },
            id: 'archive-channel',
            title: t('pages.manageChannel.archiveTask'),
            type: 'secondary',
            startIcon: <DownloadIcon />,
          },
        ],
      };
      dispatch(setHeader(headerData));
    }
  }, [
    isLoading,
    data,
    dispatch,
    t,
    loadingDelete,
    versionValue,
    nextTime,
    taskName,
    taskId,
  ]);

  if (!isLoading && !loadingDelete && !initialRender) {
    return (
      <>
        <Grid container>
          <Grid item xs={12} mb={3}>
            <RunHistory
              isOpenDelete={openDelete}
              setIsOpenDelete={setIsOpenDelete}
              currentModule={currentModule}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <VersionHistory
              scheduleTaskName={taskName}
              currentModule={currentModule}
            />
          </Grid>
        </Grid>
      </>
    );
  }

  if (isLoading || loadingDelete || initialRender) {
    return <Loading />;
  }
  return <></>;
}

export default TaskDetailsPage;
