import { Dispatch, SetStateAction, useState } from 'react';
import { Box, FormLabel, Grid, IconButton, Typography } from '@mui/material';
import DataCard from 'components/DataCard';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ForecastDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/ForecastDataTableNext';
import { useTranslation } from 'react-i18next';
import {
  AccountPeriodDetailResponse,
  GetAccountPeriodForecastBalanceQuery,
  PeriodFinancialImpacts,
  useRecalculateAccountPeriodMutation,
} from 'generated/graphql';
import CurrencyCell from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/TableCells/CurrencyCell';
import ForecastColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/ForecastColumnNext';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import { ColumnDef } from '@tanstack/react-table';
import {
  Button,
  DatePicker,
  MessageActionType,
  MessageType,
} from '@revenue-solutions-inc/revxcoreui';
import CloseIcon from '@mui/icons-material/Close';
import { getDate } from 'common/helpers';
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useQueryClient,
} from '@tanstack/react-query';
import { addMessage } from 'redux/messageSlice';
import { useAppDispatch } from 'redux/hooks';
import { useParams } from 'react-router-dom';
import { ForecastImpactAmount } from '../AccountPeriod/AccountPeriodTransactions';

interface Props {
  impactAmounts?: PeriodFinancialImpacts[] | null;
  forecastDate: Date | null;
  joinedImpactAmounts: ForecastImpactAmount[];
  refetchForecast: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<
    QueryObserverResult<GetAccountPeriodForecastBalanceQuery, unknown>
  >;
  resetForecastData: () => void;
  setForecastDate: Dispatch<SetStateAction<Date | null>>;
  canForecast?: boolean | null;
  isAccountForecastLoading: boolean;
  isPnIUpToDate: boolean;
  isLoading: boolean;
}

function ForecastPeriodTotals({
  impactAmounts,
  forecastDate,
  joinedImpactAmounts,
  refetchForecast,
  resetForecastData,
  setForecastDate,
  canForecast,
  isAccountForecastLoading,
  isPnIUpToDate,
  isLoading,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const { periodId } = useParams() as {
    periodId: string;
  };
  const showForecast = joinedImpactAmounts.length > 0;
  const [forecastDateHasError, setForecastDateHasError] =
    useState<boolean>(false);

  const { mutate, isLoading: isUpdating } = useRecalculateAccountPeriodMutation(
    {
      onSuccess: () => {
        resetForecastData();
        queryClient.invalidateQueries({
          queryKey: ['GetPeriodFinancialTransactions'],
        });
        queryClient.invalidateQueries({
          queryKey: ['GetAccountPeriodDetail'],
        });
        queryClient.invalidateQueries({
          queryKey: ['GetAccountPeriodsAndBalance'],
        });
        dispatch(
          addMessage({
            message: t('pages.periodDetails.updateSuccess'),
            type: MessageType.Success,
            actionType: MessageActionType.None,
          })
        );
      },
      onError: () => {
        dispatch(
          addMessage({
            message: t('pages.periodDetails.updateError'),
            type: MessageType.Error,
            actionType: MessageActionType.None,
          })
        );
      },
    }
  );

  const equalsTodaysDate = () => {
    return new Date().toDateString() !== forecastDate?.toDateString();
  };

  const forecastDateIsValid = () => {
    return forecastDate?.toDateString() === 'Invalid Date' ||
      forecastDate === null ||
      forecastDateHasError
      ? false
      : true;
  };

  const columns: ColumnDef<AccountPeriodDetailResponse>[] = [
    {
      id: 'type',
      accessorKey: 'impactType',
      header: () => <HeaderColumnNext localization={t('')} />,
      cell: ({ getValue }) => {
        return (
          <Box sx={{ mr: '80%' }}>
            <Typography sx={{ fontWeight: 'bold' }}>
              {getValue() as string}
            </Typography>
          </Box>
        );
      },
    },
    {
      id: 'liability',
      accessorKey: 'liability',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.periodDetails.totals.liability')}
        />
      ),
      cell: ({ getValue }) => {
        return (
          <Box sx={{ width: '3.1em' }}>
            <CurrencyCell
              invalidValue={t('pages.tableCell.invalidValue')}
              amountString={getValue() as string}
            />
          </Box>
        );
      },
    },
    {
      id: 'credits',
      accessorKey: 'collection',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.periodDetails.totals.credits')}
        />
      ),
      cell: ({ getValue }) => {
        return (
          <Box sx={{ width: '2.9em' }}>
            <CurrencyCell
              invalidValue={t('pages.tableCell.invalidValue')}
              amountString={getValue() as string}
            />
          </Box>
        );
      },
    },
    {
      id: 'balance',
      accessorKey: 'balance',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.periodDetails.totals.balance')}
        />
      ),
      cell: ({ getValue }) => {
        return (
          <Box sx={{ width: '3.3em' }}>
            <CurrencyCell
              invalidValue={t('pages.tableCell.invalidValue')}
              amountString={getValue() as string}
            />
          </Box>
        );
      },
    },
    {
      id: 'forecastedliability',
      accessorKey: 'forecastLiability',
      meta: {
        forecastColumn: true,
      },
      header: () => <ForecastColumnNext localization="Liability" />,
      cell: ({ getValue }) => {
        return (
          <Box sx={{ width: '7.8em' }}>
            <CurrencyCell
              invalidValue={t('pages.tableCell.invalidValue')}
              amountString={getValue() as string}
            />
          </Box>
        );
      },
    },
    {
      id: 'forecastedcredits',
      accessorKey: 'forecastCollection',
      meta: {
        forecastColumn: true,
      },
      header: () => <ForecastColumnNext localization="Credits" />,
      cell: ({ getValue }) => {
        return (
          <Box sx={{ width: '7.6em' }}>
            <CurrencyCell
              invalidValue={t('pages.tableCell.invalidValue')}
              amountString={getValue() as string}
            />
          </Box>
        );
      },
    },
    {
      id: 'forecastedbalance',
      accessorKey: 'forecastBalance',
      meta: {
        forecastColumn: true,
      },
      header: () => <ForecastColumnNext localization="Balance" />,
      cell: ({ getValue }) => {
        return (
          <Box sx={{ width: '7.9em' }}>
            <CurrencyCell
              invalidValue={t('pages.tableCell.invalidValue')}
              amountString={getValue() as string}
            />
          </Box>
        );
      },
    },
  ];

  const forecastBtn = (): JSX.Element => {
    return (
      <Grid container direction="row" justifyContent="space-between">
        <Box
          sx={{
            width: 'auto',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <FormLabel sx={{ mr: 2 }}>
            {t('pages.periodDetails.forecast.forecastDate')}
          </FormLabel>

          <DatePicker
            ariaLabel={t('pages.periodDetails.forecast.forecastDate')}
            sx={{ mr: 1 }}
            disabled={isAccountForecastLoading}
            value={forecastDate}
            handleChange={(date: Date | null) => {
              setForecastDate(date);
            }}
            onError={(reason: string | null) => {
              setForecastDateHasError(reason ? true : false);
            }}
            disablePast
          />

          {joinedImpactAmounts.length > 0 && !isAccountForecastLoading && (
            <>
              <Typography sx={{ mx: 2, color: 'linkBlue.dark' }}>
                {t('pages.periodDetails.forecast.forecastDate')}:{' '}
                {getDate(forecastDate ? forecastDate.toString() : '')}
              </Typography>
              <IconButton
                onClick={resetForecastData}
                aria-label="close"
                color="default"
                size="small"
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </>
          )}
          <Button
            id="forecast-period-totals"
            type="secondary"
            variant="text"
            loading={isAccountForecastLoading}
            sx={{ mx: 1 }}
            disabled={!forecastDateIsValid()}
            onClick={() => {
              refetchForecast();
            }}
          >
            {t('pages.periodDetails.totals.forecast')}
          </Button>
          {!isPnIUpToDate && (
            <Button
              id="update-period-totals"
              type="secondary"
              variant="text"
              loading={isUpdating}
              sx={{ mx: 1 }}
              onClick={() => {
                mutate({ periodId: periodId });
              }}
            >
              {isUpdating
                ? t('pages.periodDetails.updating')
                : t('pages.periodDetails.update')}
            </Button>
          )}
        </Box>
      </Grid>
    );
  };

  return (
    <>
      {!isLoading && impactAmounts && (
        <Grid sx={{ display: 'flex', flexDirection: 'column' }} item xs={12}>
          <Grid sx={{ marginBottom: '40px' }}>
            <DataCard
              avatar={<MonetizationOnIcon sx={{ fill: 'primary.main' }} />}
              title={t('pages.periodDetails.totals.title')}
              action={canForecast ? forecastBtn() : <></>}
              children={
                <>
                  {impactAmounts.length > 0 && (
                    <Grid>
                      <ForecastDataTableNext
                        data={
                          showForecast
                            ? (joinedImpactAmounts as Record<string, unknown>[])
                            : impactAmounts
                        }
                        columns={
                          columns as ColumnDef<Record<string, unknown>>[]
                        }
                        enableGlobalFilter={false}
                        columnVisibility={{
                          ['forecastedliability']: showForecast,
                          ['forecastedcredits']: showForecast,
                          ['forecastedbalance']: showForecast,
                        }}
                      />
                      {forecastDateIsValid() && !!equalsTodaysDate() && (
                        <Typography sx={{ mt: 1 }}>
                          {t('pages.periodDetails.forecast.balances')}:{' '}
                          {getDate(new Date().toString())}
                        </Typography>
                      )}
                    </Grid>
                  )}
                  {impactAmounts.length === 0 && (
                    <Typography sx={{ margin: '10px' }}>
                      {t('pages.periodDetails.totals.noTotals')}
                    </Typography>
                  )}
                </>
              }
            />
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default ForecastPeriodTotals;
