import { WorkflowAttachment } from 'types/WorkflowAttachment';
import type { ActionMap } from 'utils/createLocalReducer';
import { createReducer } from 'utils/createLocalReducer';

export type AttachmentsPanelState = {
  attachments: WorkflowAttachment[];
  filterValue: string;
  selectedFile: File | null;
  isConfidential: boolean;
  attachmentType: string;
};

export const initialState: AttachmentsPanelState = {
  attachments: [],
  filterValue: '',
  selectedFile: null,
  isConfidential: false,
  attachmentType: '',
};

export const actions = {
  CLEAR_SELECTED_FILE: 'attachments/CLEAR_SELECTED_FILE',
  ON_ADD_SUCCESS: 'attachments/ON_ADD_SUCCESS',
  SET_EXSITING_ATTACHMENTS: 'attachments/SET_EXISTING_ATTACHMENTS',
  SET_IS_CONFIDENTIAL: 'attachments/SET_IS_CONFIDENTIAL',
  SET_SELECTED_FILE: 'attachments/SET_SELECTED_FILE',
  SET_ATTACHMENT_FILTER: 'attachments/SET_FILTER',
  SET_ATTACHMENT_TYPE: 'attachments/SET_ATTACHMENT_TYPE',
};

export const actionMap: ActionMap<AttachmentsPanelState> = {
  [actions.CLEAR_SELECTED_FILE]: (state) => ({
    ...state,
    isConfidential: false,
    selectedFile: null,
    attachmentType: '',
  }),
  [actions.ON_ADD_SUCCESS]: (state, { payload: { attachment } }) => ({
    ...state,
    attachments: [attachment, ...state.attachments],
    isConfidential: false,
    selectedFile: null,
    filterValue: '',
    attachmentType: '',
  }),
  [actions.SET_EXSITING_ATTACHMENTS]: (
    state,
    { payload: { attachments } }
  ) => ({ ...state, attachments }),
  [actions.SET_IS_CONFIDENTIAL]: (state, { payload: { isConfidential } }) => ({
    ...state,
    isConfidential,
  }),
  [actions.SET_SELECTED_FILE]: (state, { payload: { selectedFile } }) => ({
    ...state,
    selectedFile,
  }),
  [actions.SET_ATTACHMENT_FILTER]: (state, { payload: { filterValue } }) => ({
    ...state,
    filterValue,
  }),
  [actions.SET_ATTACHMENT_TYPE]: (state, { payload: { attachmentType } }) => ({
    ...state,
    attachmentType,
  }),
};

export const attachmentsPanelReducer = createReducer<AttachmentsPanelState>(
  initialState,
  actionMap
);
