import { Box } from '@mui/material';

import { Field } from '@revenue-solutions-inc/revxcoreui';

interface CatTypes {
  cat: string;
  id: number;
}
interface CDestinationFieldsProps {
  fields: Field[];
  sinkObjectId: number;
  operationId: number;
  domainName: string;
  setCatInfo: (catino: CatTypes) => void;
  catInfo: CatTypes;
}

function DestinationFields({
  fields,
  setCatInfo,
  catInfo,
}: CDestinationFieldsProps): JSX.Element {
  return (
    <Box>
      {fields && fields.length ? (
        fields.map((field: Field, index: number) => (
          <Box key={index}>
            <Box
              onClick={() => {
                setCatInfo({
                  cat: 'SinkFields',
                  id: field.BaseField.FieldID,
                });
              }}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '98%',
                margin: 'auto',
              }}
            >
              <div
                style={{
                  color: '#439173',
                  cursor: 'pointer',
                  fontWeight:
                    catInfo.cat === 'SinkFields' &&
                    catInfo.id == field.BaseField.FieldID
                      ? 600
                      : 400,
                }}
              >
                {field.BaseField.FieldName}
              </div>
            </Box>
          </Box>
        ))
      ) : (
        <></>
      )}
    </Box>
  );
}

export default DestinationFields;
