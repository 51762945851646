import { useEffect, useState, useCallback, useMemo } from 'react';
import { setHeader } from 'redux/contentSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { Tabs, Tab, Grid } from '@mui/material';
import { GetWorkButton } from 'components/GetWorkButton/GetWorkButton';
import { useQueryClient } from '@tanstack/react-query';
import {
  useAssignWorkflowMutation,
  useGetViewDashboardQuery,
  useGetActiveDashboardsQuery,
  GetActiveDashboardsResponse,
} from 'generated/graphql';
import { useHasAccess } from 'hooks/useHasAccess';
import Loading from 'components/Loading';
import { PowerBiClient, MessageType } from '@revenue-solutions-inc/revxcoreui';
import { addMessage } from 'redux/messageSlice';
import { useTranslation } from 'react-i18next';
import Box from '@mui/system/Box';
import { WorkManagerTable } from 'components/workManagerTable/WorkManagerTable';
import Stack from '@mui/system/Stack';
import { AssignedWorkflowsTable } from '../AssignedUnassignedWork/AssignedWorkflows';
import { UnassignedWorkflowsTable } from '../AssignedUnassignedWork/UnassignedWorkflows';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  customStyle?: { width: string };
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ width: '100%' }}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function Dashboard(): JSX.Element {
  const hasAccess = useHasAccess('dashboardWorkflowSupervisor', 'view');
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { mutate } = useAssignWorkflowMutation();
  const [value, setValue] = useState(0);
  const [valueDashboardsTab, setValueDashboardsTab] = useState(0);
  const module = useAppSelector((state) => state.user.module);
  const modules = useAppSelector((state) => state.content.modules);

  const moduleId: number = useMemo(
    () =>
      modules?.find((item) => item.name?.toLowerCase() === module.toLowerCase())
        ?.id ?? 0,

    [module, modules]
  );

  const {
    data: activeDashboards,
    isError: activeError,
    isLoading: loadingActive,
  } = useGetActiveDashboardsQuery({
    module: {
      moduleId,
    },
  });

  const [activeTab, setActiveTab] = useState(-1);

  const {
    data: viewDashboard,
    isError: viewDashboardError,
    isLoading: loadingView,
  } = useGetViewDashboardQuery({
    dashboard: {
      dashboardId: activeTab,
    },
  });

  useMemo(() => {
    if (
      !activeError &&
      !loadingActive &&
      activeDashboards?.getActiveDashboards.length > 0
    ) {
      setActiveTab(Number(activeDashboards.getActiveDashboards[0].dashboardId));
    }
  }, [activeDashboards?.getActiveDashboards, activeError, loadingActive]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleDashboardsTabChange = useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      setValueDashboardsTab(newValue);
      setActiveTab(
        Number(activeDashboards?.getActiveDashboards[newValue]?.dashboardId)
      );
    },
    [activeDashboards]
  );

  // GetWorkButton onClick function invokes endpoint workflow/setnextavailablework
  const handleGetWorkBtnClick = () => {
    mutate(
      {},
      {
        onSuccess: () => {
          dispatch(
            addMessage({
              type: MessageType.Success,
              message: t(
                'pages.workflow.assignNextWorkflowAPI.message.success'
              ),
            })
          );
          // react-query invalidation causing table data refetch re-rending AWF_Table
          queryClient.invalidateQueries({
            queryKey: ['GetAssignedWorkflows'],
          });
          // react-query invalidation causing table data refetch re-rending UaWF_Table
          queryClient.invalidateQueries({
            queryKey: ['GetUnassignedOwner'],
          });
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onError: (error: any) => {
          if (error[0].extensions.response.statusText === 'Not Found') {
            dispatch(
              addMessage({
                type: MessageType.Error,
                message: t(
                  'pages.workflow.assignNextWorkflowAPI.message.error.notFound'
                ),
              })
            );
          } else {
            dispatch(
              addMessage({
                type: MessageType.Error,
                message: t(
                  'pages.workflow.assignNextWorkflowAPI.message.error.general'
                ),
              })
            );
          }
        },
      }
    );
  };

  useEffect(() => {
    dispatch(setHeader({ pageTitle: t('pages.dashboard.title') }));
  }, [dispatch, t]);

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label={t('pages.dashboard.workTab')} {...a11yProps(0)} />
          {hasAccess ? (
            <Tab label={t('pages.dashboard.managerTab')} {...a11yProps(1)} />
          ) : null}
          <Tab label={t('pages.dashboards.titleManager')} {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Stack spacing={2}>
          <AssignedWorkflowsTable />
          <GetWorkButton onClick={handleGetWorkBtnClick} />
          <UnassignedWorkflowsTable />
        </Stack>
      </TabPanel>
      {hasAccess ? (
        <TabPanel value={value} index={1}>
          <WorkManagerTable />
        </TabPanel>
      ) : null}
      <TabPanel value={value} index={hasAccess ? 2 : 1}>
        <Box
          sx={{
            flexGrow: 1,
            bgcolor: 'background.paper',
            display: 'flex',
            height: '100%',
          }}
        >
          {!loadingView &&
          !loadingActive &&
          !activeError &&
          !viewDashboardError ? (
            <>
              <Tabs
                orientation="vertical"
                value={valueDashboardsTab}
                onChange={handleDashboardsTabChange}
                variant="fullWidth"
                aria-label="Vertical tabs example"
                sx={{ borderRight: 1, borderColor: 'divider' }}
              >
                {activeDashboards.getActiveDashboards.length > 0
                  ? activeDashboards.getActiveDashboards.map(
                      (x: GetActiveDashboardsResponse, index: number) => (
                        <Tab
                          label={
                            x.dashboardDisplayName
                              ? x.dashboardDisplayName
                              : 'unknown'
                          }
                          {...a11yProps(index)}
                        />
                      )
                    )
                  : null}
              </Tabs>

              {activeDashboards.getActiveDashboards.length > 0
                ? activeDashboards.getActiveDashboards.map(
                    (x: object, index: number) => (
                      <TabPanel value={valueDashboardsTab} index={index}>
                        <PowerBiClient
                          data={{
                            embedUrl: viewDashboard.getViewDashboard.embedUrl,
                            id: viewDashboard.getViewDashboard.dashboardId,
                            token: viewDashboard.getViewDashboard.token,
                          }}
                          type="dashboard"
                          description="new description"
                          name="new dashboard"
                          refetchTokenCreateMode={function noRefCheck() {}}
                          refetchTokenEditMode={function noRefCheck() {}}
                          registerSave={function noRefCheck() {}}
                          registerSaveAs={function noRefCheck() {}}
                          typeMode="view"
                        />
                      </TabPanel>
                    )
                  )
                : null}
            </>
          ) : null}
          {loadingView || loadingActive ? <Loading /> : null}
          {viewDashboardError || activeError ? (
            <Grid
              sx={{
                display: 'Grid',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              {t('pages.dashboards.noDashboards')}
            </Grid>
          ) : null}
        </Box>
      </TabPanel>
    </Box>
  );
}

export default Dashboard;
