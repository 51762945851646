import { useState } from 'react';

import { Grid } from '@mui/material';
import {
  Card,
  CardContent,
  Button,
  DestinationObject,
  Input,
  LogixContextResponse,
  LogixGroupType,
} from '@revenue-solutions-inc/revxcoreui';

import LogiX from 'components/LogiX';
import { useTranslation } from 'react-i18next';
import dataDestination from './destination.json';
import logixFile from './logixFile.json';

function LogixTest(): JSX.Element {
  const { t } = useTranslation();
  const [getJson, setGetJson] = useState<boolean>(false);
  const [cleanSelectedFields, setCleanSelectedFields] =
    useState<boolean>(false);
  const [showDestination, setShowDestination] = useState<boolean>(false);
  const [label, setLabel] = useState<string>('Form');
  const [canCreate, setCanCreate] = useState<boolean>(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [logixJsonSchema, setLogixJsonSchema] = useState<LogixGroupType>(
    logixFile as unknown as LogixGroupType
  );

  const handleSelectedField = (response: LogixContextResponse) => {
    //TODO: show the respond from Logix in coreUI
    // eslint-disable-next-line no-console
    console.log(response);
  };

  const handleGetLogixUpdate = (logixUpdated: LogixGroupType) => {
    //TODO: just for QA test to show the full json updated
    // eslint-disable-next-line no-console
    console.log(logixUpdated);
    setGetJson(false);
  };

  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <Card>
          <CardContent>
            <Grid container justifyContent="center">
              <Grid item xs={12} md={showDestination ? 12 : 6}>
                <div style={{ display: 'flex' }}>
                  <div style={{ display: 'flex' }}>
                    <div style={{ paddingRight: '2px' }}>
                      <Button
                        id="btn-save"
                        sx={{ marginBottom: '5px' }}
                        onClick={() =>
                          setCleanSelectedFields(!cleanSelectedFields)
                        }
                      >
                        {t('pages.logixTestHarness.buttons.clean')}
                      </Button>
                    </div>
                    <div style={{ paddingRight: '2px' }}>
                      <Button
                        id="btn-save"
                        sx={{ marginBottom: '5px' }}
                        onClick={() => setShowDestination(!showDestination)}
                      >
                        {t('pages.logixTestHarness.buttons.target')}
                      </Button>
                    </div>
                    <div style={{ paddingRight: '2px' }}>
                      <Button
                        id="btn-save"
                        sx={{ marginBottom: '5px' }}
                        onClick={() => setCanCreate(!canCreate)}
                      >
                        {t('pages.logixTestHarness.buttons.create')}
                      </Button>
                    </div>
                    <div>
                      <Input
                        id="fileGroupName"
                        ariaLabel="file group name"
                        value={label}
                        sx={{ width: '100%', maxWidth: '20em' }}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          setLabel(event.target.value as string);
                        }}
                      />
                    </div>
                  </div>
                  <div style={{ marginLeft: 'auto' }}>
                    <Button
                      id="btn-save"
                      sx={{ marginBottom: '5px' }}
                      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                        event.preventDefault();
                        setGetJson(true);
                      }}
                    >
                      {t('pages.logixTestHarness.buttons.save')}
                    </Button>
                  </div>
                </div>

                <LogiX
                  data={logixJsonSchema}
                  handleSelectedField={handleSelectedField}
                  config={{
                    domain: {
                      label: label,
                      canCreate: canCreate,
                    },
                  }}
                  showDestination={showDestination}
                  dataDestination={
                    dataDestination as unknown as DestinationObject
                  }
                  cleanSelectedFields={cleanSelectedFields}
                  getJson={getJson}
                  handleGetLogixUpdate={handleGetLogixUpdate}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default LogixTest;
