import { useState } from 'react';

import { Grid } from '@mui/material';
import CommitResponse from 'components/bulkUserUpload/CommitResponse';
import Preview from 'components/bulkUserUpload/Preview';
import UploadFile from 'components/bulkUserUpload/UploadFile';
import { CommitBulkFileResponse, ValidateBulkFileResponse } from 'types/roles';

function BulkUserUpload(): JSX.Element {
  const [isPreview, setIsPreview] = useState(false);
  const [isSummary, setIsSummary] = useState(false);
  const [data, setData] = useState<undefined | ValidateBulkFileResponse[]>(
    undefined
  );
  const [comittedDataResponse, setComittedDataResponse] = useState<
    undefined | CommitBulkFileResponse[]
  >(undefined);

  const startOver = () => {
    setIsPreview(false);
    setIsSummary(false);
    setData(undefined);
    setComittedDataResponse(undefined);
  };

  const handleDone = (validateBulkFileResponse: ValidateBulkFileResponse[]) => {
    setIsPreview(true);
    setData(validateBulkFileResponse);
  };

  const handleFileCommited = (
    commitBulkFileResponse: CommitBulkFileResponse[]
  ) => {
    setIsSummary(true);
    setIsPreview(false);
    setComittedDataResponse(commitBulkFileResponse);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {!isPreview && !isSummary ? (
          <UploadFile handleDone={handleDone} />
        ) : isPreview ? (
          <Preview
            dataResponse={data}
            startOver={startOver}
            handleFileCommited={handleFileCommited}
          />
        ) : (
          <CommitResponse
            comittedDataResponse={comittedDataResponse}
            startOver={startOver}
          />
        )}
      </Grid>
    </Grid>
  );
}

export default BulkUserUpload;
