import { CardContent, Grid } from '@mui/material';
import DataDisplay from '@revenue-solutions-inc/revxcoreui/material/controls/DataDisplay';
import { useTranslation } from 'react-i18next';

import PropertyCardLayout from '../PropertyCardLayout';

interface Props {
  setActive: React.Dispatch<React.SetStateAction<string>>;
  propertyOwnerName: string;
  propertyOwnerId: string;
}

function OwnerInformationCard({
  setActive,
  propertyOwnerName,
  propertyOwnerId,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <PropertyCardLayout
      title={t('pages.propertyView.ownerInformation')}
      setActive={setActive}
      activeCard="ActiveOwnerCard"
    >
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <DataDisplay
              id="name"
              label={t('pages.propertyView.name')}
              data={propertyOwnerName}
            />
          </Grid>
          <Grid item xs={6}>
            <DataDisplay
              id="ownerId"
              label={t('pages.propertyView.ownerId')}
              data={propertyOwnerId}
            />
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            <DataDisplay
              id="ownerpercentage"
              label={t('pages.propertyView.ownerPercentage')}
              data={'100%'}
            />
          </Grid>
          <Grid item xs={12}>
            <DataDisplay
              id="ownerpercentage"
              label={t('pages.propertyView.ownerDate')}
              data={'10/18/2021'}
            />
          </Grid>
        </Grid>
      </CardContent>
    </PropertyCardLayout>
  );
}

export default OwnerInformationCard;
