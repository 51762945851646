import { useEffect, useState, useRef } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { Operation } from '@revenue-solutions-inc/revxcoreui';
import DestinationFields from './DestinationFields';

interface CatTypes {
  cat: string;
  id: number;
}
interface DestinationOperationsProps {
  operations: Operation[];
  sinkObjectId: number;
  domainName: string;
  isExpandAll: number;
  setCatInfo: (catino: CatTypes) => void;
  catInfo: CatTypes;
  setIsExpandAll: () => void;
}

function DestinationOperations({
  operations,
  sinkObjectId,
  domainName,
  isExpandAll,
  setCatInfo,
  catInfo,
  setIsExpandAll,
}: DestinationOperationsProps): JSX.Element {
  const theme = useTheme();
  const openOperationsRef = useRef<boolean[]>([]);
  const [reload, setReload] = useState<boolean>(false);

  useEffect(() => {
    const auxOpen = [...openOperationsRef.current];

    operations?.forEach(() => {
      auxOpen.push(false);
    });

    openOperationsRef.current = auxOpen;
  }, [operations]);

  useEffect(() => {
    if (isExpandAll !== 2) {
      const auxOpen = [...openOperationsRef.current];
      auxOpen.forEach((t, i) => {
        auxOpen[i] = isExpandAll === 0 ? false : true;
      });
      openOperationsRef.current = auxOpen;
      setReload(!reload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpandAll]);

  const handleClickShowHide = (index: number) => {
    const auxOpen = [...openOperationsRef.current];
    auxOpen.forEach((t, i) => {
      if (i === index) {
        auxOpen[i] = !t;
      }
    });
    openOperationsRef.current = auxOpen;
    setReload(!reload);
  };

  return (
    <Box
      sx={{
        display: 'block',
        cursor: 'default',
        textAlign: 'left',
      }}
    >
      <Box
        sx={{
          paddingBottom: '5px',
        }}
      >
        {operations && operations.length ? (
          operations.map((operation: Operation, index: number) => (
            <Box key={index}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexGrow: 1,
                  }}
                  onClick={() => {
                    setCatInfo({
                      cat: 'Operations',
                      id: operation.OperationID,
                    });
                    handleClickShowHide(index);
                    setIsExpandAll();
                  }}
                >
                  {openOperationsRef.current[index] ? (
                    <KeyboardArrowDownIcon />
                  ) : (
                    <KeyboardArrowRightIcon />
                  )}
                  <Typography
                    variant="h6"
                    display="block"
                    gutterBottom
                    m={0}
                    sx={{
                      fontSize: '0.875rem',
                      cursor: 'pointer',
                      color: '#2e7ca3',
                      fontWeight:
                        catInfo.cat === 'Operations' &&
                        catInfo.id == operation.OperationID
                          ? 600
                          : 400,
                    }}
                  >
                    {operation.OperationName}
                    <span style={{ color: '#777' }}>
                      {' '}
                      &nbsp; ({operation.SinkFields.length})
                    </span>
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: openOperationsRef.current[index] ? 'block' : 'none',
                  borderLeft: `1px dashed ${theme.palette.grey2.main}`,
                  marginLeft: '11px',
                  paddingLeft: '5px',
                }}
              >
                <DestinationFields
                  fields={operation.SinkFields}
                  sinkObjectId={sinkObjectId}
                  operationId={operation.OperationID}
                  domainName={domainName}
                  setCatInfo={setCatInfo}
                  catInfo={catInfo}
                />
              </Box>
            </Box>
          ))
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
}

export default DestinationOperations;
