import {
  Box,
  FormHelperText,
  RadioGroup as MuiRadioGroup,
  Typography,
} from '@mui/material';
import { labelStyle } from '@revenue-solutions-inc/revxcoreui/material/controls/Input';
import Radio from '@revenue-solutions-inc/revxcoreui/material/controls/Radio';
import { RadioOptions } from 'types/entities';

interface Props {
  id?: string;
  label: string;
  fieldIdentifier: string;
  value: unknown;
  error?: string;
  isRequired?: boolean;
  onChange: (ev?: React.ChangeEvent<HTMLInputElement>) => void;
  radioOptions?: RadioOptions[];
}

function RadioGroup({
  id = '',
  label,
  fieldIdentifier,
  value,
  error,
  isRequired = false,
  onChange,
  radioOptions,
}: Props): JSX.Element {
  return (
    <>
      <Typography sx={labelStyle}>
        {label} {isRequired ? '*' : ''}
      </Typography>
      <MuiRadioGroup name={fieldIdentifier} value={value} onChange={onChange}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          {radioOptions &&
            radioOptions.map((option) => {
              return (
                <Radio
                  key={id + '.' + option.label}
                  id={id + '.' + option.label}
                  value={option.value ?? false}
                  onChange={onChange}
                  label={option.label}
                  sx={{ marginLeft: '4px' }}
                  color="default"
                />
              );
            })}
        </Box>
      </MuiRadioGroup>
      {error && (
        <FormHelperText
          sx={{
            color: 'primaryRed.main',
            fontWeight: 'normal',
            fontSize: '12px',
          }}
        >
          {error}
        </FormHelperText>
      )}
    </>
  );
}

export default RadioGroup;
