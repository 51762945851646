import { useEffect, useState } from 'react';
import { useTheme } from '@mui/system';
import { Box, Grid } from '@mui/material';
import { addMessage } from 'redux/messageSlice';
import {
  MessageType,
  MessageActionType,
} from '@revenue-solutions-inc/revxcoreui';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import Loading from 'components/Loading';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { IHeader, setHeader } from 'redux/contentSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  PaymentBatchIdResponse,
  Submissions,
  usePaymentBatchIdQuery,
} from 'generated/graphql';
import { formatCurrency, getDate } from 'common/helpers';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import CurrencyCell from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/TableCells/CurrencyCell';
import { ColumnDef, Row } from '@tanstack/react-table';

function BatchDetails() {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const module: string = useAppSelector((state) => state.user.module);
  const { id } = useParams() as { id: string };
  const { t } = useTranslation();
  const [paymentBatch, setPaymentBatch] = useState<PaymentBatchIdResponse>();

  const { data, isLoading } = usePaymentBatchIdQuery(
    {
      batchId: id,
    },
    {
      onSuccess: (response) => {
        setPaymentBatch(response.PaymentBatchId);
      },
      onError: () => {
        dispatch(
          addMessage({
            message: t('pages.manageReusableContent.networkError'),
            type: MessageType.Error,
            actionType: MessageActionType.None,
          })
        );
      },
    }
  );

  useEffect(() => {
    if (!isLoading && data) {
      const headerData: IHeader = {
        pageTitle: t('pages.batchDetails.title'),
        previousPage: t('pages.manageBatches.title'),
        route: `managebatches`,
        icon: {
          props: { fill: 'black' },
          icon: 'assignmentIcon',
          fontSize: 'large',
        },
        data: [
          {
            id: 'batchId',
            first: true,
            label: t('pages.batchDetails.batchIdLabel'),
            value: paymentBatch?.identifier ?? '-',
          },
          {
            id: 'creationDate',
            label: t('pages.batchDetails.creationDate'),
            value: paymentBatch?.createdDate
              ? getDate(paymentBatch?.createdDate)
              : '-',
          },
          {
            id: 'status',
            label: t('pages.batchDetails.status'),
            value: paymentBatch?.status ?? '-',
          },
          {
            id: 'type',
            label: t('pages.batchDetails.type'),
            value: paymentBatch?.paymentBatchType ?? '-',
          },
          {
            id: 'paymentAmount',
            label: t('pages.batchDetails.amount'),
            value: paymentBatch?.paymentAmount
              ? formatCurrency(paymentBatch?.paymentAmount as unknown as string)
              : '-',
          },
        ],
      };
      dispatch(setHeader(headerData));
    }
  }, [isLoading, data, dispatch, t, paymentBatch]);

  const currencyCell = (value: string) => {
    return (
      <Box sx={{ width: '7.1em' }}>
        <CurrencyCell
          invalidValue={t('pages.tableCell.invalidValue')}
          amountString={value}
        />
      </Box>
    );
  };

  const linkCell = (row: Row<Submissions>, value: string) => {
    return (
      <Box
        sx={() => {
          return {
            a: {
              color: theme.palette.linkBlue.dark,
            },
          };
        }}
      >
        <Link
          to={{
            pathname: `/${module}/submissiondetails/${row.original.identifier}`,
          }}
          state={{
            prevPath: location.pathname,
            prevPageName: t('pages.batchDetails.title'),
          }}
        >
          {value}
        </Link>
      </Box>
    );
  };

  const submissionColumns: ColumnDef<Submissions>[] = [
    {
      id: 'identifier',
      accessorKey: 'identifier',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.submissionDetails.submissionId')}
        />
      ),
      cell: ({ getValue, row }) => {
        return linkCell(row, getValue() as string);
      },
    },
    {
      id: 'paymentAmount',
      accessorKey: 'paymentAmount',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.batchDetails.paymentAmount')}
        />
      ),
      cell: ({ getValue }) => {
        return currencyCell(getValue() as string);
      },
    },
    {
      id: 'remittanceAmount',
      accessorKey: 'remittanceAmount',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.batchDetails.remittanceAmount')}
        />
      ),
      cell: ({ getValue }) => {
        return currencyCell(getValue() as string);
      },
    },
    {
      id: 'isBalanced',
      accessorFn: (row) => (row.isBalanced === true ? 'Yes' : 'No'),
      header: () => (
        <HeaderColumnNext localization={t('pages.batchDetails.isBalanced')} />
      ),
    },
    {
      id: 'isOpen',
      accessorFn: (row) => (row.isOpen === true ? 'Yes' : 'No'),
      header: () => (
        <HeaderColumnNext localization={t('pages.batchDetails.isOpen')} />
      ),
    },
  ];

  return (
    <>
      {isLoading && <Loading />}
      {data && (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <DefaultDataTableNext
              columns={submissionColumns}
              data={paymentBatch?.submissions ?? []}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default BatchDetails;
