import { useState } from 'react';
import { Box } from '@mui/system';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import Dialog from '@revenue-solutions-inc/revxcoreui/material/controls/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import DialogTitle from '@mui/material/DialogTitle';
import { addMessage } from 'redux/messageSlice';
import extractMeaningfulMessage from 'utils/errorMessage';
import {
  MessageType,
  MessageActionType,
} from '@revenue-solutions-inc/revxcoreui';
import { useDeletePaymentBatchIdMutation } from 'generated/graphql';
import { useHasAccess } from 'hooks/useHasAccess';
import AddWorkflowButton from 'components/AddWorkflowButton/AddWorkflowButton';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { HeaderButtonLink } from 'redux/contentSlice';
import { useAppSelector, useAppDispatch } from 'redux/hooks';
export interface HeaderButtonProps {
  pageTitle?: string;
  buttonLinks?: HeaderButtonLink[];
}

function HeaderButton({
  pageTitle,
  buttonLinks,
}: HeaderButtonProps): JSX.Element | null {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [showWarning, setShowWarning] = useState<boolean>(false);
  const deletePaymentBatchId = useDeletePaymentBatchIdMutation<{
    batchId: string;
    canDelete: boolean;
  }>();
  const canDelete =
    deletePaymentBatchId.data?.DeletePaymentBatchId.canDelete ?? false;
  const module = useAppSelector((state) => state.user.module);
  const accessTenantConfig = useHasAccess('tenantConfig', 'view');
  const accessCreatePolicyGroup = useHasAccess('createPolicyGroup', 'view');
  const accessCreateManageRoles = useHasAccess('createManageRoles', 'view');
  const accessCreateBusinessPolicy = useHasAccess(
    'createBusinessPolicy',
    'edit'
  );
  const accessCreateBatchesProperty = useHasAccess(
    'manageBatchesProperty',
    'edit'
  );
  const accessCreateBatchesRevenue = useHasAccess(
    'manageBatchesRevenue',
    'edit'
  );

  const handleOpenModal = () => {
    setShowWarning(true);
  };

  const handleCloseModal = () => {
    setShowWarning(false);
  };

  if (buttonLinks) {
    return (
      <Box sx={{ minWidth: '150px', display: 'flex', justifyContent: 'end' }}>
        {buttonLinks.map((link) => {
          return (
            <Button
              sx={{ marginRight: '5px' }}
              key={link.id}
              id={link.id}
              onClick={() => {
                if (link.action) {
                  link.action();
                }
                if (link?.route) {
                  navigate(link?.route);
                }
              }}
              type={link.type}
              disabled={link?.disabled ?? false}
              startIcon={link?.startIcon}
              endIcon={link?.endIcon}
            >
              {link.title}
            </Button>
          );
        })}
      </Box>
    );
  }
  //TODO: localize these case statements, otherwise functionality will break when app is localized
  switch (pageTitle) {
    case 'Manage Batches':
      return (
        <Button
          id="createBatch_header"
          onClick={() => navigate(`/${module}/batch`)}
          type="primary"
          disabled={
            (module === 'property' && !accessCreateBatchesProperty) ||
            (module === 'revenue' && !accessCreateBatchesRevenue)
          }
        >
          {t('pages.createBatch.buttons.create')}
        </Button>
      );

    case 'Batch Details':
      return (
        <div>
          <Button
            id="warningBatch_header"
            onClick={handleOpenModal}
            disabled={canDelete}
            type="primary"
          >
            {t('pages.createBatch.buttons.voidBatch')}
          </Button>
          <Dialog
            id="warningVoidBatch"
            title={''}
            open={showWarning}
            type="transactional"
            width={280}
            height={290}
            handleClose={handleCloseModal}
            handleCancelClick={handleCloseModal}
            transactionModalTransactionButtonText={t(
              'pages.batchDetails.warningDetails.acceptButton'
            )}
            handleTransactionClick={() => {
              setShowWarning(false);
              deletePaymentBatchId.mutate(
                {
                  batchId: `${window.location.pathname.split('/').pop()}`,
                },
                {
                  onSuccess: () => {
                    dispatch(
                      addMessage({
                        type: MessageType.Success,
                        message: t(
                          'pages.batchDetails.warningDetails.deleteBatchId'
                        ),
                      })
                    );
                    navigate(`/${module}/managebatches`);
                  },
                  onError: (error) => {
                    const message = extractMeaningfulMessage(
                      error,
                      t('components.message.networkerror')
                    );
                    dispatch(
                      addMessage({
                        message: message,
                        type: MessageType.Error,
                        actionType: MessageActionType.None,
                      })
                    );
                  },
                }
              );
            }}
          >
            <DialogTitle>
              <Typography
                sx={(theme) => ({
                  color: theme.palette.error.main,
                  fontSize: '30px',
                })}
              >
                <WarningIcon />
                {t('pages.batchDetails.warningDetails.warningTitle')}
              </Typography>
            </DialogTitle>
            <DialogContent>
              <Typography sx={{ color: 'black', fontSize: '18px' }}>
                {t('pages.batchDetails.warningDetails.warningDescription')}
              </Typography>
            </DialogContent>
          </Dialog>
        </div>
      );

    case 'Manage Deposits':
      return (
        <Button
          id="createDeposit_header"
          onClick={() => navigate(`/${module}/deposit`)}
          type="primary"
        >
          {t('pages.createDeposit.buttons.create')}
        </Button>
      );
    case 'Manage Channels':
      return (
        <Button
          id="createChannel_header"
          onClick={() => navigate(`/${module}/managechannels/create`)}
          type="primary"
        >
          {t('pages.manageChannel.buttons.create')}
        </Button>
      );
    case 'Manage Scheduled Tasks':
      return (
        <Button
          id="createTask_header"
          onClick={() => navigate(`/${module}/managescheduledtasks/create`)}
          type="primary"
        >
          {t('pages.manageChannel.createTask')}
        </Button>
      );
    case 'Forms Design':
      return (
        <Button
          id="createForm_header"
          onClick={() => navigate(`/${module}/manageforms/create`)}
          type="primary"
        >
          {t('pages.forms.buttons.create')}
        </Button>
      );

    case 'Manage Roles':
      return accessCreateManageRoles ? (
        <Button
          id="createRole_header"
          onClick={() => navigate(`/${module}/manageRoles/create`)}
          type="primary"
        >
          {t('pages.roles.buttons.create')}
        </Button>
      ) : (
        <></>
      );
    case 'Manage Policy Groups':
      return accessCreatePolicyGroup ? (
        <Button
          id="createPoliciesGroup_header"
          onClick={() => navigate(`/${module}/managePolicyGroups/create`)}
          type="primary"
        >
          {t('pages.policyGroupCreate.buttons.create')}
        </Button>
      ) : (
        <></>
      );
    case 'Manage Business Policies':
      return accessCreateBusinessPolicy ? (
        <Button
          id="createBusinessPolies_header"
          onClick={() => navigate(`/${module}/manageBusinessPolicies/create`)}
          type="primary"
        >
          {t('pages.businessPolicy.create.buttons.create')}
        </Button>
      ) : (
        <></>
      );
    case 'Tenant Onboarding':
      return (
        <Button
          id="createTenant_header"
          onClick={() => {
            navigate(`/${module}/tenantconfig`);
            localStorage.removeItem('tenantId');
          }}
          type="primary"
          disabled={!accessTenantConfig}
        >
          {t('pages.tenantConfig.buttons.create')}
        </Button>
      );

    case 'Manage Roles':
      return (
        <Button
          id="createRole_header"
          onClick={() => navigate(`/${module}/manageRoles/create`)}
          type="primary"
        >
          {t('pages.roles.buttons.create')}
        </Button>
      );
    case 'Workflows':
      return <AddWorkflowButton />;
    case 'Dashboard':
      return (
        <>
          <AddWorkflowButton />
        </>
      );
    default:
      return null;
  }
}

export default HeaderButton;
