import { MutableRefObject, useRef, useState } from 'react';

import {
  Box,
  Chip,
  FormLabel,
  Grid,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import useMaskInput from 'hooks/useMaskInput';
import { useTranslation } from 'react-i18next';
import { Channel } from 'types/channels';
import {
  Control,
  Controller,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';

interface Props {
  index: number;
  watch: UseFormWatch<Channel>;
  setValue: UseFormSetValue<Channel>;
  control: Control<Channel, unknown>;
}

export const labelStyle = (theme: Theme) => {
  return {
    fontWeight: theme.typography.fontWeightMedium,
    color: '#333',
    textDecoration: 'underline',
    borderBottom: 'none',
    '& .MuiFormLabel-asterisk': {
      fontWeight: theme.typography.fontWeightMedium,
    },
  };
};

const chipStyle = {
  '& .MuiChip-deleteIcon': {
    display: 'none',
  },
  '&:hover': {
    '& .MuiChip-deleteIcon': {
      display: 'block',
      color: 'white.main',
    },
    backgroundColor: 'linkBlue.main',
    color: 'white.main',
  },
};

function ExecuteTimes({ index, setValue, watch, control }: Props): JSX.Element {
  const { t } = useTranslation();
  const [hourInputValue, setHourInputValue] = useState<string>('');
  const [minuteInputValue, setMinuteInputValue] = useState<string>('');

  const hourRef = useRef(null) as unknown as MutableRefObject<HTMLInputElement>;
  const minuteRef = useRef(
    null
  ) as unknown as MutableRefObject<HTMLInputElement>;

  const minutesValue = Array.isArray(
    watch(
      `Channel.Pipeline.PipelineSchedule.${index}.RecurrenceSchedule.Minutes`
    )
  )
    ? watch(
        `Channel.Pipeline.PipelineSchedule.${index}.RecurrenceSchedule.Minutes`
      )
    : [];

  const hoursValue = Array.isArray(
    watch(`Channel.Pipeline.PipelineSchedule.${index}.RecurrenceSchedule.Hours`)
  )
    ? watch(
        `Channel.Pipeline.PipelineSchedule.${index}.RecurrenceSchedule.Hours`
      )
    : [];

  const handleHour = (event: React.FocusEvent<HTMLInputElement>) => {
    const inputValue = parseInt(event.target.value);
    if (
      !hoursValue.includes(inputValue) &&
      event.target.value !== '' &&
      hoursValue?.length < 5 &&
      inputValue > 0 &&
      inputValue < 24
    ) {
      const inputData = [...hoursValue];
      inputData.push(inputValue);
      setValue(
        `Channel.Pipeline.PipelineSchedule.${index}.RecurrenceSchedule.Hours`,
        inputData
      );
    }
    setHourInputValue(''); // Reset the TextField value
  };

  const handleMinute = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (
      !minutesValue.includes(parseInt(minuteInputValue)) &&
      minuteInputValue !== '' &&
      minutesValue?.length < 5 &&
      parseInt(minuteInputValue) > 0 &&
      parseInt(minuteInputValue) < 60
    ) {
      const inputDataOnAdd = [...minutesValue];
      inputDataOnAdd.push(parseInt(minuteInputValue));
      setValue(
        `Channel.Pipeline.PipelineSchedule.${index}.RecurrenceSchedule.Minutes`,
        inputDataOnAdd
      );
      setMinuteInputValue('');
    } else {
      setMinuteInputValue('');
    }
  };

  const handleDelete = (value: number, type: string) => {
    if (type === 'Hour') {
      const indexValue = hoursValue.indexOf(value);
      if (indexValue > -1) {
        hoursValue.splice(indexValue, 1);
        setValue(
          `Channel.Pipeline.PipelineSchedule.${index}.RecurrenceSchedule.Hours`,
          hoursValue
        );
      }
    } else if (type === 'Minute') {
      const indexValue = minutesValue.indexOf(value);

      if (indexValue > -1) {
        minutesValue.splice(indexValue, 1);
        setValue(
          `Channel.Pipeline.PipelineSchedule.${index}.RecurrenceSchedule.Minutes`,
          minutesValue
        );
      }
    }
  };

  const hourMask = useMaskInput(
    '99',
    t('pages.manageChannel.monthRange'),
    /^(?:[1-9]|1[0-9]|2[0-3])$/
  );

  const minMask = useMaskInput(
    '99',
    t('pages.manageChannel.monthRange'),
    /^(?:[0-9]|[1-5][0-9])$/
  );

  const formatTime = () => {
    let value = '';
    if (hoursValue?.length > 0 && minutesValue?.length > 0) {
      hoursValue.forEach((hour) => {
        minutesValue.forEach((minute) => {
          if (minute?.toString()?.length === 1) {
            value += ` ${hour}:0${minute}, `;
          } else {
            value += ` ${hour}:${minute}, `;
          }
        });
      });
    }

    return value;
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <FormLabel sx={labelStyle} id="execute-times">
          {t('pages.manageChannel.executeTimes')}
        </FormLabel>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={6} md={6}>
          <Controller
            control={control}
            name={`Channel.Pipeline.PipelineSchedule.${index}.RecurrenceSchedule.Hours`}
            render={() => {
              return (
                <TextField
                  fullWidth
                  inputRef={hourRef}
                  value={hourInputValue}
                  label={t('pages.manageChannel.hour')}
                  variant="outlined"
                  size="small"
                  sx={{
                    width: '100%',
                    maxWidth: '20em',
                    margin: '1rem 0',
                    marginRight: '1rem',
                    backgroundColor: 'white.main',
                  }}
                  placeholder={t('pages.manageChannel.hourMask')}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setHourInputValue(event.target.value); // Update local state value
                    hourMask.handleMaskChange(event);
                  }}
                  onBlur={handleHour}
                  InputProps={{
                    startAdornment: (
                      <Box sx={{ margin: '0 0.2rem 0 0', display: 'flex' }}>
                        {hoursValue?.map((data, indexData) => {
                          return (
                            <Chip
                              key={indexData}
                              tabIndex={-1}
                              label={data}
                              onDelete={() => handleDelete(data, 'Hour')}
                              sx={chipStyle}
                            />
                          );
                        })}
                      </Box>
                    ),
                  }}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} mb={3}>
          <Controller
            control={control}
            name={`Channel.Pipeline.PipelineSchedule.${index}.RecurrenceSchedule.Minutes`}
            render={() => {
              return (
                <TextField
                  fullWidth
                  inputRef={minuteRef}
                  value={minuteInputValue}
                  label={t('pages.manageChannel.minutes')}
                  variant="outlined"
                  size="small"
                  sx={{
                    width: '100%',
                    maxWidth: '20em',
                    margin: '1rem 0',
                    backgroundColor: 'white.main',
                  }}
                  placeholder={t('pages.manageChannel.minuteMask')}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setMinuteInputValue(event.target.value);
                    minMask.handleMaskChange(event);
                  }}
                  onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                    handleMinute(event as React.ChangeEvent<HTMLInputElement>);
                  }}
                  InputProps={{
                    startAdornment: (
                      <Box sx={{ margin: '0 0.2rem 0 0', display: 'flex' }}>
                        {minutesValue?.map((data, indexData) => {
                          return (
                            <Chip
                              key={indexData}
                              tabIndex={-1}
                              label={data}
                              onDelete={() => handleDelete(data, 'Minute')}
                              sx={chipStyle}
                            />
                          );
                        })}
                      </Box>
                    ),
                  }}
                />
              );
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} mt={-4}>
        <Typography variant="h2">
          {t('pages.manageChannel.scheduleExecutionTimes')}
        </Typography>
        <Typography variant="h2">{formatTime()}</Typography>
      </Grid>
    </Grid>
  );
}
export default ExecuteTimes;
