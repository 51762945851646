import { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Loading from 'components/Loading';
import { useGetWorkflowGroupsQuery } from 'generated/graphql';
import { useAppSelector } from 'redux/hooks';
import CreateCase from './CreateCase';
import CreateSpecificWork from './CreateSpecificWork';
import { WorkflowFormContextProvider } from '../../../components/Workflow/WorkflowFormContext';

function CreateContainer() {
  const { variantId } = useParams() as { variantId: string };
  const { state: locationState } = useLocation();
  const [workType, setWorkType] = useState<string>('');
  const module = useAppSelector((appState) => appState.user.module);

  useEffect(() => {
    setWorkType(locationState?.__workType);
  }, [locationState]);

  const { data, isLoading } = useGetWorkflowGroupsQuery({
    variantId,
  });

  if (isLoading || !data) {
    return <Loading />;
  }

  return (
    <WorkflowFormContextProvider>
      <>
        {['Notes', 'Suppression'].includes(workType) && (
          <CreateSpecificWork
            variantId={variantId}
            data={data}
            module={module}
            workType={workType}
          />
        )}
        {(!workType || !['Notes', 'Suppression'].includes(workType)) && (
          <CreateCase
            variantId={variantId}
            data={data}
            module={module}
            workType={workType}
          />
        )}
      </>
    </WorkflowFormContextProvider>
  );
}

export default CreateContainer;
