import { useEffect, useMemo, useRef } from 'react';

import Select from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import { useTranslation } from 'react-i18next';
import { QueryModule } from 'types/tenants';

interface SelectRoleProps {
  moduleList: QueryModule[] | undefined;
  handleChange: (moduleId: string) => void;
  selectedRole?: string;
  showAll?: boolean;
}

function SelectRole({
  moduleList,
  handleChange,
  showAll = true,
  selectedRole = '0',
}: SelectRoleProps): JSX.Element {
  const { t } = useTranslation();
  const selectFocus = useRef<HTMLSelectElement>(null);
  const options = useMemo(() => {
    const list = [];

    if (showAll) {
      list.push({
        key: '0',
        desc: `${t('pages.roles.allModules')}`,
      });
    }

    if (moduleList && Array.isArray(moduleList)) {
      moduleList?.forEach(({ moduleId, name }) => {
        list.push({ key: `${moduleId}`, desc: name });
      });
    }

    return list;
  }, [showAll, moduleList, t]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      selectFocus?.current?.focus();
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <Select
      id="select-role"
      data-testid="select-role"
      label={t('pages.roles.showRolesFor')}
      value={selectedRole}
      options={options}
      onChange={(event) => handleChange(`${event.target.value}`)}
      inputProps={{
        ref: selectFocus,
        autoFocus: true,
      }}
      sx={{ width: '18%' }}
    />
  );
}

export default SelectRole;
