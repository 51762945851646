import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { SectionProps } from '../WorkflowDetailsTab';
import { WorkflowFormObject } from '../../WorkflowEditor.types';
import { OtherAttributes } from '../../OtherAttributes';

type OtherAttributesSectionProps = SectionProps;

const OtherAttributesSection = ({
  editDisabled,
  group,
  groupIndex,
}: OtherAttributesSectionProps) => {
  const { t } = useTranslation();
  const { control } = useFormContext<WorkflowFormObject>();

  const getLabel = () => {
    if (group.repeatingActionLabel) {
      return group.repeatingActionLabel;
    }

    if (group.groupName === 'BankruptcyClaimType') {
      return t('pages.workflowEditor.addBankruptcyClaimType');
    }

    if (group.groupName === 'BankruptcyMilestoneType') {
      return t('pages.workflowEditor.addBankruptcyMilestoneType');
    }

    return t('pages.workflowEditor.addCloseReason');
  };

  return (
    <Grid spacing={3} container>
      <Grid item xs={12}>
        <Typography variant="h1">{getLabel()}</Typography>
      </Grid>
      <OtherAttributes
        groupName={group.groupDescription ?? (group.groupName as string)}
        groupLabel={getLabel()}
        control={control}
        grpIndex={groupIndex}
        editDisabled={editDisabled}
      />
    </Grid>
  );
};

export default OtherAttributesSection;
