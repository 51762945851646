import { ReactNode, useEffect, useState } from 'react';
import { Box, Drawer, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

const DETAILS_BASE = {
  workflowVariantDescription: '',
  createdBy: '',
  createdDate: '',
  queueName: '',
  status: '',
  variantOwnerName: '',
  variantStartDate: '',
  variantEndDate: '',
  workflowVariantName: '',
  state: '',
};

export type WorkflowDataDetailsType = Partial<typeof DETAILS_BASE> & {
  displayId?: string;
  workflow_Variant_Id?: string;
  workflow_Key?: string;
};

type RowDataDrawerProps = {
  open: boolean;
  onClose: () => void;
  data?: WorkflowDataDetailsType;
  title?: string;
  footer?: ReactNode;
};

export const RowDataDrawer = ({
  data,
  open,
  onClose,
  title,
  footer,
}: RowDataDrawerProps) => {
  const { t } = useTranslation();
  const [currentData, setCurrentData] = useState<
    WorkflowDataDetailsType | undefined
  >();

  useEffect(() => {
    if (data) setCurrentData(data);
  }, [data]);

  return (
    <Drawer
      anchor={'right'}
      open={open}
      onClose={onClose}
      sx={{ display: 'flex', flexDirection: 'column' }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: 3,
          paddingTop: 2,
          minWidth: '300px',
        }}
      >
        <Typography
          variant="h1"
          gutterBottom
          sx={{ flex: '1', paddingRight: 3 }}
        >
          {title}
        </Typography>
        <Button variant="text" onClick={onClose}>
          Close
        </Button>
      </Box>
      <Box
        sx={{
          flex: 1,
          padding: 3,
          paddingTop: 0,
          paddingBottom: 0,
          overflow: 'auto',
        }}
      >
        {Object.keys(DETAILS_BASE).map((key) => {
          return (
            <Box key={key} sx={{ paddingBottom: 1 }}>
              <Typography variant="h2" display="block">
                {t(`components.rowDisplay.${key}`)}
              </Typography>
              <Typography
                variant="body1"
                display="block"
                color={'GrayText'}
                gutterBottom
              >
                {currentData &&
                  currentData[key as keyof WorkflowDataDetailsType]}
              </Typography>
            </Box>
          );
        })}
      </Box>
      {footer && (
        <Box
          sx={{ display: 'flex', flexDirection: 'column', padding: 3, gap: 1 }}
        >
          {footer}
        </Box>
      )}
    </Drawer>
  );
};
