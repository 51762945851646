import { Fragment, useState } from 'react';

import { Box, Grid, SelectChangeEvent } from '@mui/material';
import {
  Card,
  CardContent,
  CardHeader,
} from '@revenue-solutions-inc/revxcoreui';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import DatePicker from '@revenue-solutions-inc/revxcoreui/material/controls/DatePicker';
import Input from '@revenue-solutions-inc/revxcoreui/material/controls/Input';
import { AttributeTypes } from 'common/platformConfigUtils/platformConfigUtils';
import { radioOptions } from 'components/entityManagement/common/defaults/entity';
import {
  getExtendedValue,
  getExtendedRequired,
  isExtendedFieldValid,
} from 'components/entityManagement/common/entityManagementUtils';
import DatasourceSelect from 'components/DatasourceSelect';
import RadioGroup from 'components/RadioGroup';
import { useTranslation } from 'react-i18next';
import { ExtendedAttributeValues } from 'types/forms';
import { toDate, toStringDate } from 'utils/date-util';

interface Props {
  extendedValues: ExtendedAttributeValues[];
  avatar?: JSX.Element;
  closeForm: () => void;
  handleSave: () => void;
  handleExtendedAttributes: (extendedVals: ExtendedAttributeValues[]) => void;
}

function ExtendedAttributesMaintain({
  extendedValues,
  avatar,
  closeForm,
  handleSave,
  handleExtendedAttributes,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [extendedVals, setExtendedVals] =
    useState<ExtendedAttributeValues[]>(extendedValues);

  const handleChange = (
    ev:
      | React.ChangeEvent<HTMLInputElement>
      | SelectChangeEvent<string | number>
      | null,
    attrName: string,
    date?: Date
  ) => {
    const updateExtended = extendedVals.map((attribute) => {
      const newValue = ev !== null ? String(ev.target.value) : '';
      if (attribute.name === attrName) {
        return {
          ...attribute,
          isDirty: true,
          value: date ? toStringDate(date) : newValue,
        };
      }
      return attribute;
    });
    setExtendedVals(updateExtended);
  };

  const handleExtendedAndSave = () => {
    handleExtendedAttributes(extendedVals);
    handleSave();
  };

  return (
    <Card sx={{ marginBottom: 1 }}>
      <CardHeader
        title={t('pages.createAccount.extendedAttributes')}
        avatar={avatar}
      />
      <CardContent>
        <Grid container spacing={3}>
          {extendedValues.map((attribute, index) => {
            const attrName = attribute.name;
            const errorMsg = attribute.displayName.concat(
              t('pages.createAccount.extendedAttrRequired')
            );
            switch (attribute.type.toLowerCase()) {
              case AttributeTypes.referenceData.toLowerCase():
                return (
                  <Grid
                    key={attrName + '_container'}
                    item
                    xs={12}
                    sm={6}
                    md={3}
                  >
                    <DatasourceSelect
                      id={attrName}
                      key={attrName}
                      label={attribute.displayName}
                      value={getExtendedValue(extendedVals, attrName)}
                      datasource={attribute.datasource ?? ''}
                      fetchLayoutInfo={(ev) => handleChange(ev, attrName)}
                      required={getExtendedRequired(extendedVals, attrName)}
                      errorMsg={
                        !isExtendedFieldValid(extendedVals, attrName)
                          ? errorMsg
                          : undefined
                      }
                      maxWidth={true}
                    />
                  </Grid>
                );
              case AttributeTypes.date.toLowerCase():
                return (
                  <Grid
                    key={attrName + '_container'}
                    item
                    xs={12}
                    sm={6}
                    md={3}
                  >
                    <DatePicker
                      value={toDate(getExtendedValue(extendedVals, attrName))}
                      handleChange={(date: Date | null) => {
                        if (
                          date !== null &&
                          date?.toString() !== 'Invalid Date'
                        ) {
                          handleChange(null, attrName, date);
                        }
                      }}
                      label={attribute.displayName}
                      id={`attr-date-att-maintain${index}`}
                      requiredErrorMessage={
                        !isExtendedFieldValid(extendedVals, attrName)
                          ? errorMsg
                          : undefined
                      }
                      required={getExtendedRequired(extendedVals, attrName)}
                      fullWidth={true}
                    />
                  </Grid>
                );
              case AttributeTypes.boolean.toLowerCase():
                return (
                  <Grid
                    key={attrName + '_container'}
                    item
                    xs={12}
                    sm={6}
                    md={3}
                  >
                    <RadioGroup
                      id={attrName}
                      label={attribute.displayName}
                      fieldIdentifier={attrName}
                      value={getExtendedValue(extendedVals, attrName)}
                      onChange={(ev) => {
                        if (ev) handleChange(ev, attrName);
                      }}
                      radioOptions={radioOptions}
                      isRequired={getExtendedRequired(extendedVals, attrName)}
                      error={
                        !isExtendedFieldValid(extendedVals, attrName)
                          ? errorMsg
                          : undefined
                      }
                    />
                  </Grid>
                );
              case AttributeTypes.int.toLowerCase():
                return (
                  <Grid
                    key={attrName + '_container'}
                    item
                    xs={12}
                    sm={6}
                    md={3}
                  >
                    <Input
                      key={attrName}
                      id={`maintainAttrNameInput_${index}`}
                      value={getExtendedValue(extendedVals, attrName)}
                      onChange={(ev) => {
                        ev.target.value = Math.floor(
                          Number(ev.target.value)
                        ).toString();
                        handleChange(ev, attrName);
                      }}
                      inputProps={{ type: 'number', step: 1 }}
                      label={attribute.displayName}
                      required={getExtendedRequired(extendedVals, attrName)}
                      endAdornment={<Fragment />}
                      helperText={
                        !isExtendedFieldValid(extendedVals, attrName)
                          ? errorMsg
                          : undefined
                      }
                      sx={{
                        width: '100%',
                      }}
                    />
                  </Grid>
                );
              default:
                return (
                  <Grid
                    key={attrName + '_container'}
                    item
                    xs={12}
                    sm={6}
                    md={3}
                  >
                    <Input
                      id={attrName}
                      key={attrName}
                      value={getExtendedValue(extendedVals, attrName)}
                      onChange={(ev) => handleChange(ev, attrName)}
                      label={attribute.displayName}
                      required={getExtendedRequired(extendedVals, attrName)}
                      helperText={
                        !isExtendedFieldValid(extendedVals, attrName)
                          ? errorMsg
                          : undefined
                      }
                      sx={{
                        width: '100%',
                      }}
                    />
                  </Grid>
                );
            }
          })}
        </Grid>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Button
            id="update-extended-attr-btn"
            sx={{ marginTop: '10px' }}
            onClick={handleExtendedAndSave}
          >
            {t('pages.accountSummary.actions.save')}
          </Button>
          <Button
            id="close-extended-attr-btn"
            type="secondary"
            sx={{ marginTop: '10px', marginLeft: '10px' }}
            onClick={closeForm}
          >
            {t('pages.accountSummary.actions.close')}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
}

export default ExtendedAttributesMaintain;
