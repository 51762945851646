import { useEffect, useState } from 'react';

import { Divider, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import { useTranslation } from 'react-i18next';

const stepperStyle = {
  '& .MuiStepLabel-root .Mui-completed': {
    color: 'success.main',
  },
  '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel': {
    color: 'success.main',
  },
  '& .MuiStepLabel-root .Mui-active': {
    color: 'secondaryOrange.main',
  },
  '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel': {
    color: 'secondaryOrange.main',
  },
  '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
    fill: 'white.main',
  },
  '& .MuiStepLabel-label': {
    textAlign: 'left',
  },
  '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
    textAlign: 'left',
  },
  '& .MuiStepLabel-root': {
    alignItems: 'start',
  },
  '& .MuiButtonBase-root': {
    justifyContent: 'start',
  },
  '& .MuiStepConnector-root': {
    left: 'calc(-100% + 40px)',
    right: 'calc(100% + 0px)',
  },
};

interface Props {
  steps: string[];
  saveSuccessful?: boolean;
  currentStep: number;
  handleCurrentStep: (step: number) => void;
  handleSave: () => void;
  handleFormReset?: () => void;
  handleStepValidation?: () => Promise<void>;
  children: React.ReactNode;
  validatedSteps?: { [k: number]: boolean };
  isCompleteStepBtnDisabled?: boolean;
  saveBtnLabel?: string;
  resetBtnLabel?: string;
  nonLinear?: boolean;
  alternativeLabel?: boolean;
  centerAlign?: boolean;
  handleCompleted?: (boolean: boolean) => void;
  loading?: boolean;
}

/**
 * Creates Header component
 * @param {steps} - array of all the steps and their associated labels.
 * @param {currentStep} - the current step.
 * @param {handleCurrentStep} - handles the current step.
 * @param {handleSave} - save changes handler.
 * @param {handleFormRest} - handles the reset action of the form.
 * @param {handleStepValidation} - performs a validation of the form only for a specific step.
 * @param {children} - children that are passed down for each step.
 * @param {validatedSteps} - similar to 'completed' state, but is tied to 'handleStepValidation' execution.
 * @param {isCompleteStepBtnDisabled} - controls whether button to complete a step is disabled.
 * @param {saveBtnLabel} - label for the save button after all steps are complete.
 * @param {resetBtnLabel} - label for the button after all steps are complete.
 * @param {nonLinear} - controls whether you can access steps in a nonlinear fashion, set to true by default..
 * @param {alternativeLabel} - will display the labels below the steps, set to true by default.
 * @param {centerAlign} - will center align the stepper.
 */

function HorizontalNonLinearStepper({
  steps,
  saveSuccessful = true,
  handleCurrentStep,
  handleSave,
  handleFormReset,
  handleStepValidation,
  handleCompleted,
  children,
  validatedSteps,
  isCompleteStepBtnDisabled,
  saveBtnLabel,
  resetBtnLabel,
  nonLinear = true,
  alternativeLabel = true,
  centerAlign = false,
  loading = false,
}: Props): JSX.Element {
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState<{ [k: number]: boolean }>({});
  const { t } = useTranslation();

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleSaveBtnLabel = (): string => {
    if (saveBtnLabel && isLastStep()) return saveBtnLabel;
    return isLastStep()
      ? t('pages.horizontalNonLinearStepper.save')
      : t('pages.horizontalNonLinearStepper.nextStep');
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((_step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
    if (handleCompleted) {
      handleCompleted(completed[step]);
    }
  };

  const handleComplete = () => {
    if (validatedSteps && !validatedSteps[activeStep]) return;
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);

    if (allStepsCompleted() && isLastStep() && !saveSuccessful) {
      handleSave();
    } else {
      handleNext();
    }
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
    if (handleFormReset) handleFormReset();
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    if (handleCompleted) {
      handleCompleted(completed[activeStep]);
    }
  };

  useEffect(() => {
    if (handleCompleted) {
      handleCompleted(completed[activeStep]);
    }
  }, [handleCompleted, activeStep, completed]);

  useEffect(() => {
    handleCurrentStep(activeStep);
  }, [handleCurrentStep, activeStep]);

  return (
    <Box sx={{ width: '100%' }}>
      <Grid
        container
        my={3}
        justifyContent={centerAlign ? 'center' : 'start'}
        sx={{ overflowY: 'hidden', padding: '10px', overflowX: 'auto' }}
      >
        <Grid item xs={12}>
          <Stepper
            nonLinear={nonLinear}
            activeStep={activeStep}
            alternativeLabel={alternativeLabel}
          >
            {steps.map((label, index) => (
              <Step key={label} completed={completed[index]} sx={stepperStyle}>
                <StepButton color="red" onClick={handleStep(index)}>
                  <Typography variant="h5">Step {index + 1}</Typography>
                  <Box>{label}</Box>
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </Grid>
      </Grid>
      <Divider sx={{ mb: 2 }}></Divider>
      <div>
        {allStepsCompleted() && saveSuccessful && resetBtnLabel ? (
          <>
            <Typography sx={{ mt: 2, mb: 1, color: 'success.main' }}>
              {t('pages.horizontalNonLinearStepper.allStepsCompleted')}
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button id="reset" onClick={handleReset}>
                {resetBtnLabel}
              </Button>
            </Box>
          </>
        ) : (
          <>
            <Grid item xs={12}>
              {children}
            </Grid>
            <Box mt={2} sx={{ display: 'flex', flexDirection: 'row' }}>
              {activeStep !== 0 && (
                <Button
                  id="prevStep"
                  onClick={handleBack}
                  type="secondary"
                  variant="outlined"
                  sx={{ mr: 1 }}
                >
                  {t('pages.horizontalNonLinearStepper.prevStep')}
                </Button>
              )}

              <Button
                id="complete"
                onClick={async () => {
                  if (handleStepValidation) await handleStepValidation();
                  handleComplete();
                }}
                type="primary"
                disabled={isCompleteStepBtnDisabled}
                sx={{ mr: 1 }}
                loading={loading}
              >
                {handleSaveBtnLabel()}
              </Button>
              {isLastStep() && resetBtnLabel && (
                <Button id="reset" onClick={handleReset}>
                  {resetBtnLabel}
                </Button>
              )}
            </Box>
          </>
        )}
      </div>
    </Box>
  );
}

export default HorizontalNonLinearStepper;
