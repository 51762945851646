import { useEffect, useState } from 'react';

import { Checkbox, Grid } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import CurrencyCell from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/TableCells/CurrencyCell';
import DateCell from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/TableCells/DateCell';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import { CellContext, ColumnDef, Row } from '@tanstack/react-table';
import Loading from 'components/Loading';
import format from 'date-fns/format';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { IHeader, setHeader } from 'redux/contentSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { dateFormat, getFormatDate, toDate } from 'utils/date-util';
import {
  BatchResponse,
  UpdateDepositPaymentBatchListInput,
  useGetAvailableBatchesQuery,
  useGetDepositDetailsQuery,
  useUpdateDepositBatchListMutation,
} from 'generated/graphql';
import extractMeaningfulMessage from 'utils/errorMessage';
import { addMessage } from 'redux/messageSlice';
import {
  MessageActionType,
  MessageType,
} from '@revenue-solutions-inc/revxcoreui';
import { formatCurrency } from 'common/helpers';

type Batch = {
  label: string;
  paymentBatchId: string;
  status: string;
  paidDate: string | null | undefined;
  amount: string;
  isExisting: boolean;
  isBalanced: boolean;
};

function DepositDetails(): JSX.Element {
  const dispatch = useAppDispatch();
  const { id } = useParams() as { id: string };
  const [depositId, setDepositId] = useState<string>('');
  const { t } = useTranslation();
  const module: string = useAppSelector((state) => state.user.module);
  const [selectedBatchIds, setSelectedBatchIds] = useState<string[]>([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [hasAvailableBatches, setHasAvailableBatches] =
    useState<boolean>(false);
  const [enableSave, setEnableSave] = useState<boolean>(false);
  const { mutate } = useUpdateDepositBatchListMutation({});
  const navigate = useNavigate();
  const theme = useTheme();

  const { isLoading, data } = useGetDepositDetailsQuery(
    {
      getDepositDetailsId: id,
    },
    {
      onSuccess: (item) => {
        // initialize selected Batches
        setSelectedBatchIds(
          item.GetDepositDetails.paymentBatches.map((pb) => pb.id)
        );
      },
      onError: (e) => {
        const message = extractMeaningfulMessage(
          e,
          t('pages.depositDetails.loadDepositDetailsError')
        );
        dispatch(
          addMessage({
            type: MessageType.Error,
            message: message,
          })
        );
      },
    }
  );

  const { isLoading: isLoadingAvailableBatches, data: availableBatchData } =
    useGetAvailableBatchesQuery(
      {},
      {
        onSuccess: () => {
          setHasAvailableBatches(true);
        },
        onError: () => {
          // backend throws error when there is no available batches
          setHasAvailableBatches(false);
        },
      }
    );

  // This filter overrides the default datagrid search filter
  // in order to allow filtering by a module name, since the module column's underlying value is an array of modules
  // we have to explicitly handle search behaviour for that specific column, and implement regular filtering for the other columns
  const customFilter = (
    rows: Array<Row<BatchResponse>>,
    columnIds: Array<string>,
    filterValue: string
  ) => {
    return rows.filter((row) => {
      let include = false;
      const searchValue = filterValue.toLowerCase();
      columnIds.forEach((columnId: string) => {
        if (
          (row.getValue<string>(columnId) &&
            row
              .getValue<string>(columnId)
              .toString()
              .toLowerCase()
              .includes(searchValue)) ||
          (row.getValue<string>(columnId) &&
            !isNaN(Date.parse(row.getValue<string>(columnId).toString())) &&
            format(
              new Date(row.getValue<string>(columnId).toString()),
              dateFormat
            ).includes(searchValue))
        ) {
          include = true;
          return;
        }

        if (include === false && columnId === 'modules') {
          include = row
            .getValue<[]>(columnId)
            .some((m: { moduleName: string }) =>
              m.moduleName.toLowerCase().includes(searchValue)
            );
        }
      });
      return include;
    });
  };

  const showData = (): Batch[] => {
    const aBatchList: Batch[] = [];
    if (
      data?.GetDepositDetails?.paymentBatches &&
      data.GetDepositDetails.paymentBatches.length > 0
    ) {
      data.GetDepositDetails.paymentBatches.forEach((batch) => {
        const singleBatch: Batch = {
          paymentBatchId: batch.id,
          label: batch.identifier,
          status: batch.status,
          paidDate: batch.settlementDate,
          amount: batch.paymentAmount.toString(),
          isExisting: true,
          isBalanced: batch.isBalanced,
        };
        aBatchList.push(singleBatch);
      });
    }

    if (
      hasAvailableBatches &&
      availableBatchData?.GetAvailableBatches &&
      availableBatchData.GetAvailableBatches.length > 0
    ) {
      availableBatchData.GetAvailableBatches.forEach((batch) => {
        const singleBatch: Batch = {
          paymentBatchId: batch.id,
          label: batch.identifier,
          status: batch.status,
          paidDate: batch.settlementDate,
          amount: batch.paymentAmount.toString(),
          isExisting: false,
          isBalanced: batch.isBalanced,
        };
        aBatchList.push(singleBatch);
      });
    }

    return aBatchList;
  };

  const Columns: ColumnDef<Batch>[] = [
    {
      id: 'paymentBatchId',
      cell: ({ row }: CellContext<Batch, unknown>) => {
        return (
          <Checkbox
            id={'batchChk'}
            checked={
              selectedBatchIds.findIndex(
                (p) => p === row.original.paymentBatchId
              ) > -1
            }
            onChange={(event) => {
              let newBatchIds = [...selectedBatchIds];
              if (event.target.checked) {
                newBatchIds.push(row.original.paymentBatchId);
              } else {
                newBatchIds = newBatchIds.filter(
                  (p) => p !== row.original.paymentBatchId
                );
              }
              setSelectedBatchIds(newBatchIds);
              setEnableSave(true);
            }}
          />
        );
      },
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.depositDetails.label')} />
      ),
      accessorKey: 'label',
      cell: ({ getValue, row }) => (
        <Link
          to={{
            pathname: `../../${module}/batchDetails/${row.original.paymentBatchId}`,
          }}
          style={{ color: theme.palette.linkBlue.dark }}
        >
          {getValue() as string}
        </Link>
      ),
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.depositDetails.status')} />
      ),
      accessorKey: 'status',
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.depositDetails.paidDate')} />
      ),
      accessorKey: 'paidDate',
      cell: ({ getValue }) => {
        return <DateCell dateString={getValue() as string} />;
      },
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.depositDetails.amount')} />
      ),
      accessorKey: 'amount',
      cell: ({ getValue }) => {
        return (
          <Box sx={{ width: '3.3em' }}>
            <CurrencyCell
              invalidValue={t('pages.tableCell.invalidValue')}
              amountString={getValue() as string}
            />
          </Box>
        );
      },
    },
    {
      accessorKey: 'isBalanced',
      header: () => (
        <HeaderColumnNext localization={t('pages.depositDetails.isBalanced')} />
      ),
      cell: ({ row }) => <span>{row.original.isBalanced ? 'Yes' : 'No'}</span>,
    },
  ];

  useEffect(() => {
    dispatch(
      setHeader({
        pageTitle: t('pages.depositDetails.title'),
      })
    );
  }, [dispatch, t]);

  useEffect(() => {
    if (id) {
      setDepositId(id);
    }
  }, [id]);

  if (data && dispatch && t) {
    // header
    const headerData: IHeader = {
      pageTitle: t('pages.depositDetails.title'),
      previousPage: t('pages.depositDetails.prevPage'),
      route: `managedeposits`,
      icon: {
        props: { fill: 'black' },
        icon: 'assignmentIcon',
        fontSize: 'large',
      },
      data: [
        {
          id: 'depositLabel',
          first: true,
          label: t('pages.depositDetails.depositLabel'),
          value: data.GetDepositDetails.label,
        },
        {
          id: 'depositVoucherNumber',
          first: true,
          label: t('pages.depositDetails.depositVoucherNumber'),
          value: data.GetDepositDetails.voucherNumber
            ? data.GetDepositDetails.voucherNumber
            : '-',
        },
        {
          id: 'depositDate',
          label: t('pages.depositDetails.depositDate'),
          value: getFormatDate(toDate(data.GetDepositDetails.depositDate)),
        },
        {
          id: 'depositAmount',
          label: t('pages.depositDetails.depositAmount'),
          value:
            data.GetDepositDetails.amount != null ||
            data.GetDepositDetails.amount != undefined
              ? formatCurrency(data.GetDepositDetails.amount.toString())
              : '-',
        },
        {
          id: 'status',
          label: t('pages.depositDetails.status'),
          value: data.GetDepositDetails.status,
        },
        {
          id: 'numberOfBatches',
          label: t('pages.depositDetails.numberOfBatches'),
          value: data.GetDepositDetails.numberOfBatches
            ? data.GetDepositDetails.numberOfBatches.toString()
            : '-',
        },
        {
          id: 'createdBy',
          label: t('pages.depositDetails.createdBy'),
          value: data.GetDepositDetails.createdBy
            ? data.GetDepositDetails.createdBy
            : '-',
        },
      ],
    };
    dispatch(setHeader(headerData));
  }

  const handleSave = () => {
    setIsSaving(true);
    const payload: UpdateDepositPaymentBatchListInput = {
      depositId: depositId,
      paymentBatchIds: selectedBatchIds,
    };

    mutate(
      {
        updateDepositBatchListId: depositId,
        input: payload,
      },
      {
        onSuccess: () => {
          dispatch(
            addMessage({
              message: t('pages.depositDetails.saveSuccessful'),
              type: MessageType.Success,
              actionType: MessageActionType.None,
            })
          );
          setIsSaving(false);
          navigate(`../../${module}/managedeposits`);
        },
        onError: (error) => {
          setIsSaving(false);
          const message = extractMeaningfulMessage(
            error,
            t('pages.depositDetails.saveError')
          );
          dispatch(
            addMessage({
              type: MessageType.Error,
              message: message,
            })
          );
        },
      }
    );
  };

  return (
    <>
      {(isLoading || isLoadingAvailableBatches || isSaving) && <Loading />}
      {data && (
        <>
          {/* <Grid item xs={12} my={2}> will need to uncomment once we update the api for the deposit slip page
            <Button
              id="viewDepositSlip"
              type="primary"
              onClick={() => {
                setViewDepositSlip(true);
              }}
            >
              {t('pages.depositDetails.viewDeposit')}
            </Button>
          </Grid> */}
          <Grid data-testid="batchdetails-body" container spacing={1}>
            <Grid item xs={12}>
              <DefaultDataTableNext
                columns={
                  Columns as ColumnDef<Record<string, unknown>, unknown>[]
                }
                data={showData()}
                customFilter={customFilter as unknown as string}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              flexGrow: '1 !important',
              maxWidth: '100% !important',
            }}
          >
            <Box>
              <Grid
                item
                xs={12}
                sx={{
                  marginBottom: '-4.2em',
                  marginLeft: '1em',
                  paddingTop: '2.2em',
                  width: '100%',
                }}
                data-testid="approve-button"
              >
                {data && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      marginBottom: '4em',
                      width: '100%',
                    }}
                  >
                    <Button
                      id="button-updateDepositBatchList"
                      onClick={handleSave}
                      disabled={!enableSave}
                      sx={{ mt: '1em', mb: '1em', mr: '1em', width: '100%' }}
                    >
                      {t('pages.depositDetails.save')}
                    </Button>
                  </Box>
                )}
              </Grid>
            </Box>
          </Grid>
        </>
      )}
      {/* {viewDepositSlip && ( will need to uncomment once we update the api for the deposit slip page
        <DepositSlip
          setViewDepositSlip={setViewDepositSlip}
          batches={data.GetDepositDetails.paymentBatches}
          deposit={data?.GetDepositDetails ?? depositDefault}
        />
      )} */}
    </>
  );
}

export default DepositDetails;
