import { ChangeEvent, useState } from 'react';
import {
  Button,
  LayoutFields,
  parseLogix,
  parseConfiguration,
} from '@revenue-solutions-inc/revxcoreui';
import Stack from '@mui/system/Stack';
import TextField from '@mui/material/TextField';
import { ConfigurationResponse, FormTemplate } from 'generated/graphql';
import { IMasterLayout } from '@revenue-solutions-inc/revxcoreui/material/layoutBuilder/types/fields';
import { useTranslation } from 'react-i18next';
import LayoutBuilderWrapper from 'pages/admin/LayoutBuilderWrapper/LayoutBuilderWrapper';

interface JsonObj {
  platformConfigurationId?: string;
  FormGroupName?: string;
}

function hasProperty(obj: unknown, property: string) {
  return typeof obj === 'object' && obj !== null && property in obj;
}

export default function LayoutBuilderSandbox() {
  const { t } = useTranslation();
  const [json, setJson] = useState('');
  const [layoutFields, setLayoutFields] = useState<LayoutFields | undefined>();

  const handleBtnClick = () => {
    let newLayoutFields = undefined;
    if (json != '') {
      const jsonObj: JsonObj = JSON.parse(json);
      if (hasProperty(jsonObj, 'platformConfigurationId')) {
        newLayoutFields = parseConfiguration(jsonObj as ConfigurationResponse);
      } else if (hasProperty(jsonObj, 'FormName')) {
        newLayoutFields = parseLogix(jsonObj as FormTemplate);
      }
      setLayoutFields(newLayoutFields);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
    setJson(event.target.value);
  };

  if (layoutFields !== undefined) {
    return (
      <LayoutBuilderWrapper
        fields={layoutFields}
        saveConfiguration={(masterLayout: IMasterLayout) => {
          // eslint-disable-next-line no-console
          console.log(masterLayout);
        }}
      />
    );
  } else
    return (
      <Stack>
        <Button id="createLayout" onClick={handleBtnClick}>
          {t('pages.layout.create')}
        </Button>
        <TextField
          InputLabelProps={{
            sx: { fontSize: '25px', borderBottom: '1px solid' },
          }}
          InputProps={{ sx: { marginTop: 3 } }}
          onChange={handleChange}
          id="filled-multiline-static"
          label={t('pages.layout.jsonMsg')}
          multiline
          rows={25}
          variant="filled"
          sx={{ width: '800px' }}
        />
      </Stack>
    );
}
