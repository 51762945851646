import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

interface UploadFileCommand {
  file: File;
  token: string;
}

export default function useUploadXSD() {
  return useMutation(async ({ file, token }: UploadFileCommand) => {
    const formData = new FormData();
    formData.append('formFile', file);
    const result = await axios.post(
      `${process.env.REACT_APP_CHANNEL}/Channel/UploadXSD`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    return result.data;
  });
}
