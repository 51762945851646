import { GetPolicyGroupByIdResponse } from 'generated/graphql';

const policyGroupDefault: GetPolicyGroupByIdResponse = {
  policyGroupId: '',
  moduleId: 3,
  moduleName: '',
  policyGroupName: '',
  policyGroupTechnicalName: '',
  policyGroupDescription: '',
  authorizationPolicy: [],
};

export { policyGroupDefault };
