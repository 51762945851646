import { useMsal } from '@azure/msal-react';
import { useAppSelector } from 'redux/hooks';

export const useFetchData = <TData, TVariables>(
  query: string,
  options?: RequestInit['headers']
): ((variables?: TVariables) => Promise<TData>) => {
  const url = process.env.REACT_APP_GRAPH || '';
  const { instance, accounts } = useMsal();
  const scope = useAppSelector((state) => state.content.scope);
  const moduleName = useAppSelector((state) => state.user.module);
  const modulesAvailable = useAppSelector((state) => state.content.modules);
  const module = modulesAvailable?.find(
    (itemModule) =>
      itemModule.name?.toLocaleLowerCase() === moduleName.toLocaleLowerCase()
  );
  return async (variables?: TVariables) => {
    const { accessToken } = await instance.acquireTokenSilent({
      scopes: ['openid', scope],
      account: accounts[0],
    });
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
        'Module-Id': module?.id?.toString() ?? '',
        ...options,
      },
      body: JSON.stringify({
        query,
        variables,
      }),
    });

    const json = await res.json();

    //TODO: Update once backend standardizes error messaging
    if (json.errors) {
      if (json.errors.length > 0) {
        throw json.errors;
      } else {
        throw new Error('Error...');
      }
    }

    return json.data;
  };
};
