import { ChangeEvent, useEffect, useState, useCallback } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import Select, {
  SelectType,
} from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'redux/hooks';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  CreateBankAccountInput,
  useCreateBankAccountMutation,
  useGetBankAccountTypeQuery,
} from 'generated/graphql';
import extractMeaningfulMessage from 'utils/errorMessage';
import { addMessage } from 'redux/messageSlice';
import {
  MessageType,
  MessageActionType,
  Input,
  DatePicker,
} from '@revenue-solutions-inc/revxcoreui';
import { isCeaseDateValid } from 'components/entityManagement/common/entityManagementUtils';
import Loading from 'components/Loading';
import { radioOptions } from 'components/entityManagement/common/defaults/entity';
import RadioGroup from 'components/RadioGroup';

type AddBankAccountProps = {
  onCloseCallback?: () => void;
  refetch: () => void;
};

function AddBankAccount({
  onCloseCallback = () => {},
  refetch,
}: AddBankAccountProps): JSX.Element {
  const { data } = useGetBankAccountTypeQuery();
  const [types, setTypes] = useState<SelectType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const { accountId } = useParams() as { accountId: string };
  const methods = useForm({
    mode: 'all',
  });
  const { control, getValues, trigger, setError, setValue, clearErrors } =
    methods;
  const { mutate } = useCreateBankAccountMutation({});
  const dispatch = useAppDispatch();

  useEffect(() => {
    const options: SelectType[] = [];
    data?.GetBankAccountType.forEach((bankAccountType) => {
      const optionType: SelectType = {
        key: bankAccountType.code,
        desc: bankAccountType.description,
      };
      options.push(optionType);
    });
    setTypes(options);
  }, [data]);

  const handleRoutingNumberConfirmationChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setValue('routingNumberConfirmation', value);
    if (value.length !== 9 || !/^[0-9]{9}$/.test(value)) {
      setError('routingNumberConfirmation', {
        type: 'custom',
        message: t('pages.bankAccount.message.routingNumberError'),
      });
      return;
    }
    if (value !== getValues('routingNumber')) {
      setError('routingNumberConfirmation', {
        type: 'custom',
        message: t('pages.bankAccount.message.routingNumberNotMatch'),
      });
      return;
    }
    clearErrors('routingNumberConfirmation');
  };

  const handleAccountNumberConfirmationChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setValue('bankAccountNumberConfirmation', value);
    if (value.length < 6 || value.length > 17 || !/^[0-9]{6,17}$/.test(value)) {
      setError('bankAccountNumberConfirmation', {
        type: 'custom',
        message: t('pages.bankAccount.message.bankAccountNumberError'),
      });
      return;
    }
    if (value !== getValues('bankAccountNumber')) {
      setError('bankAccountNumberConfirmation', {
        type: 'custom',
        message: t('pages.bankAccount.message.bankAccountNotMatch'),
      });
      return;
    }
    clearErrors('bankAccountNumberConfirmation');
  };

  const checkCeaseDate = useCallback((): boolean => {
    let isValid = true;
    const commenceDate = getValues('commenceDate');
    const ceaseDate = getValues('ceaseDate');
    if (ceaseDate) {
      if (
        commenceDate &&
        !isCeaseDateValid(commenceDate, ceaseDate, commenceDate)
      ) {
        isValid = false;
        dispatch(
          addMessage({
            type: MessageType.Error,
            message: t('pages.bankAccount.message.ceaseDateError'),
          })
        );
      }
    }
    return isValid;
  }, [dispatch, getValues, t]);

  const handleSaveBankAccount = async () => {
    if (!checkCeaseDate()) {
      return;
    }
    setIsLoading(true);
    await trigger()
      .then(() => {
        const payload: CreateBankAccountInput = {
          bankAccountType: getValues('accountType'),
          routingNumber: getValues('routingNumber'),
          financialInstitution: getValues('financialInstitution'),
          bankAccountNumber: getValues('bankAccountNumber'),
          ownership: getValues('ownership'),
          commenceDate: getValues('commenceDate'),
          ceaseDate:
            getValues('ceaseDate') === '' ? null : getValues('ceaseDate'),
          isRelatedToActiveWorkItem:
            getValues('isRelatedToActiveWorkItem') &&
            getValues('isRelatedToActiveWorkItem') === 'true'
              ? true
              : false,
          allowForRefund:
            getValues('allowForRefund') &&
            getValues('allowForRefund') === 'true'
              ? true
              : false,
          allowForEFT:
            getValues('allowForEFT') && getValues('allowForEFT') === 'true'
              ? true
              : false,
        };
        mutate(
          {
            bankAccount: payload,
            accountInfoId: accountId,
          },
          {
            onSuccess: () => {
              dispatch(
                addMessage({
                  message: t(
                    'pages.bankAccount.message.createBankAccountSuccess'
                  ),
                  type: MessageType.Success,
                  actionType: MessageActionType.None,
                })
              );
              setIsLoading(false);
              refetch();
              onCloseCallback();
            },
            onError: (error) => {
              setIsLoading(false);
              const message = extractMeaningfulMessage(
                error,
                t('pages.bankAccount.message.createBankAccountError')
              );
              dispatch(
                addMessage({
                  type: MessageType.Error,
                  message: message,
                })
              );
            },
          }
        );
      })
      .catch(() => {
        dispatch(
          addMessage({
            type: MessageType.Error,
            message: t('pages.bankAccount.message.createBankAccountError'),
          })
        );
      });
  };

  function buildRequiredError(fieldName: string) {
    return fieldName + ' ' + `${t('pages.bankAccount.message.required')}`;
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSaveBankAccount)}>
        <Typography variant="h2" style={{ marginBottom: 20 }}>
          {t('pages.bankAccount.addBankAccount.addAccount')}
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name="ownership"
              control={control}
              rules={{
                required: buildRequiredError(
                  t('pages.bankAccount.addBankAccount.ownership')
                ),
                pattern: {
                  value: /^[a-zA-Z\s]*$/,
                  message: t('pages.bankAccount.addBankAccount.ownershipError'),
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Input
                  id="ownership"
                  value={value}
                  onChange={onChange}
                  required={true}
                  label={t('pages.bankAccount.addBankAccount.ownership')}
                  error={Boolean(error)}
                  helperText={error && error?.message}
                  sx={{
                    width: '100%',
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              control={control}
              name="accountType"
              rules={{ required: true }}
              render={({
                field: { value, onChange },
                fieldState: { error: accountTypeError },
              }) => {
                return (
                  <Select
                    autowidth={false}
                    data-tesId="accountType"
                    id="accountType"
                    label={t('pages.bankAccount.addBankAccount.accountType')}
                    onChange={onChange}
                    options={types ?? []}
                    required
                    value={value}
                    error={
                      accountTypeError
                        ? buildRequiredError(
                            t('pages.bankAccount.addBankAccount.accountType')
                          )
                        : ''
                    }
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name="routingNumber"
              control={control}
              rules={{
                required: buildRequiredError(
                  t('pages.bankAccount.addBankAccount.routingNumber')
                ),
                pattern: {
                  value: /^[0-9]{9}$/,
                  message: 'Routing Number must be 9 numeric digits long',
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Input
                  id="routingNumber"
                  value={value}
                  onChange={onChange}
                  required={true}
                  label={t('pages.bankAccount.addBankAccount.routingNumber')}
                  error={Boolean(error)}
                  helperText={error && error?.message}
                  sx={{
                    width: '100%',
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name="routingNumberConfirmation"
              control={control}
              rules={{
                required: buildRequiredError(
                  t(
                    'pages.bankAccount.addBankAccount.routingNumberConfirmation'
                  )
                ),
                pattern: {
                  value: /^[0-9]{9}$/,
                  message: 'Routing Number must be 9 numeric digits long',
                },
              }}
              render={({ field: { value }, fieldState: { error } }) => (
                <Input
                  id="routingNumberConfirmation"
                  value={value}
                  onChange={handleRoutingNumberConfirmationChange}
                  required={true}
                  label={t(
                    'pages.bankAccount.addBankAccount.routingNumberConfirm'
                  )}
                  error={Boolean(error)}
                  helperText={error && error?.message}
                  sx={{
                    width: '100%',
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name="financialInstitution"
              control={control}
              rules={{
                required: buildRequiredError(
                  t('pages.bankAccount.addBankAccount.financialInstitution')
                ),
                pattern: {
                  value: /^[a-zA-Z\s]*$/,
                  message: 'Financial Institution must only contain letters',
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Input
                  id="financialInstitution"
                  value={value}
                  onChange={onChange}
                  required={true}
                  label={t(
                    'pages.bankAccount.addBankAccount.financialInstitution'
                  )}
                  error={Boolean(error)}
                  helperText={error && error?.message}
                  sx={{
                    width: '100%',
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name="bankAccountNumber"
              control={control}
              rules={{
                required: buildRequiredError(
                  t('pages.bankAccount.addBankAccount.accountNumber')
                ),
                pattern: {
                  value: /^[0-9]{6,17}$/,
                  message:
                    'Bank Account Number must be between 6 and 17 numeric digits long',
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Input
                  id="bankAccountNumber"
                  value={value}
                  onChange={onChange}
                  required={true}
                  label={t('pages.bankAccount.addBankAccount.accountNumber')}
                  error={Boolean(error)}
                  helperText={error && error?.message}
                  sx={{
                    width: '100%',
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name="bankAccountNumberConfirmation"
              control={control}
              rules={{
                required: buildRequiredError(
                  t(
                    'pages.bankAccount.addBankAccount.accountNumberConfirmation'
                  )
                ),
                pattern: {
                  value: /^[0-9]{6,17}$/,
                  message:
                    'Bank Account Number must be between 6 and 17 numeric digits long',
                },
              }}
              render={({ field: { value }, fieldState: { error } }) => (
                <Input
                  id="bankAccountNumberConfirmation"
                  value={value}
                  onChange={handleAccountNumberConfirmationChange}
                  required={true}
                  label={t(
                    'pages.bankAccount.addBankAccount.accountNumberConfirm'
                  )}
                  error={Boolean(error)}
                  helperText={error && error?.message}
                  sx={{
                    width: '100%',
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name="commenceDate"
              control={control}
              defaultValue=""
              rules={{
                required: buildRequiredError(
                  t('pages.bankAccount.addBankAccount.commenceDate')
                ),
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <DatePicker
                  id="commenceDate"
                  value={value}
                  handleChange={onChange}
                  label={t('pages.bankAccount.addBankAccount.commenceDate')}
                  requiredErrorMessage={error?.message}
                  required={false}
                  fullWidth={true}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name="ceaseDate"
              control={control}
              defaultValue=""
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <DatePicker
                  id="ceaseDate"
                  value={value === '' ? null : value}
                  handleChange={onChange}
                  label={t('pages.bankAccount.addBankAccount.ceaseDate')}
                  requiredErrorMessage={error?.message}
                  fullWidth={true}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name="isRelatedToActiveWorkItem"
              control={control}
              rules={{
                required:
                  t(
                    'pages.bankAccount.addBankAccount.isRelatedToActiveWorkItem'
                  ) + ' is required',
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <RadioGroup
                  id="isRelatedToActiveWorkItem"
                  label={t(
                    'pages.bankAccount.addBankAccount.isRelatedToActiveWorkItem'
                  )}
                  fieldIdentifier="isRelatedToActiveWorkItem"
                  value={value}
                  onChange={onChange}
                  radioOptions={radioOptions}
                  isRequired={true}
                  error={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name="allowForRefund"
              control={control}
              rules={{
                required:
                  t('pages.bankAccount.addBankAccount.allowForRefund') +
                  ' is required',
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <RadioGroup
                  id="allowForRefund"
                  label={t('pages.bankAccount.addBankAccount.allowForRefund')}
                  fieldIdentifier="allowForRefund"
                  value={value}
                  onChange={onChange}
                  radioOptions={radioOptions}
                  isRequired={true}
                  error={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              name="allowForEFT"
              control={control}
              rules={{
                required:
                  t('pages.bankAccount.addBankAccount.allowForEFT') +
                  ' is required',
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <RadioGroup
                  id="allowForEFT"
                  label={t('pages.bankAccount.addBankAccount.allowForEFT')}
                  fieldIdentifier="allowForEFT"
                  value={value}
                  onChange={onChange}
                  radioOptions={radioOptions}
                  isRequired={true}
                  error={error?.message}
                />
              )}
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            justifyContent: 'flex-end',
            display: 'flex',
          }}
        >
          <Button
            id={'create-bank-account-button'}
            onClick={methods.handleSubmit(handleSaveBankAccount)}
          >
            {t('pages.workflow.simpleWorkflowManual')}
            {isLoading && <Loading />}
          </Button>
        </Box>
      </form>
    </FormProvider>
  );
}

export default AddBankAccount;
