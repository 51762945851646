import { Modules, Contact, InfoLocalesList } from 'types/tenants';

export type CustomerMetadata = {
  CustomerID: string;
  CustomerName: string;
  ContractID: string;
  Module: string;
};

export interface ModuleMetadata {
  ModuleID: string;
  ModuleName: string;
}

interface ModuleFields {
  name: string;
  value: string | number;
}

export interface Attribute {
  attributeName: string;
  attributeValue: string;
}
export interface Attributes {
  attributeName: string;
  attributeValue: string;
  repeatingValue: Attribute[];
}

interface Group {
  attribute: Attributes[];
}

interface Configuration {
  configurationSectionName?: string;
  group: Group[];
}

interface PlatformConfigurationInfo {
  platformConfigurationId: string;
  configurationSection: Configuration[];
}

export interface Configurations {
  configurationName?: string;
  platformConfigurationInfo: PlatformConfigurationInfo;
}

export interface ConfigurationsResponse {
  getConfigurations: Configurations[];
}

const getConfigurationSection = (configurations: Configurations[] | []) => {
  const configurationSection = configurations.map(
    ({ configurationName, platformConfigurationInfo }) => {
      if (!configurationName || !platformConfigurationInfo) return [];
      return platformConfigurationInfo?.configurationSection.map(
        ({ group }) => {
          return {
            configurationName: `${configurationName}`,
            group: group.map(({ attribute }) =>
              attribute.map(
                ({
                  attributeName,
                  attributeValue,
                  repeatingValue,
                }: Attributes) => {
                  return {
                    attributeName,
                    attributeValue,
                    repeatingValue,
                  };
                }
              )
            ),
          };
        }
      );
    }
  );

  return configurationSection.flatMap((array) => array.concat());
};

const emptyCustomer: CustomerMetadata = {
  CustomerID: '',
  CustomerName: '',
  ContractID: '',
  Module: '',
};

const removeUndefinedCustomers = (
  configurationsL: (CustomerMetadata | undefined)[]
): CustomerMetadata[] => {
  if (configurationsL.length === 0) return [];

  const validCustomers: CustomerMetadata[] = configurationsL.filter(
    (element): element is CustomerMetadata => {
      return (
        (element !== undefined && element.CustomerID !== undefined) ||
        (element !== undefined && element.CustomerID !== '')
      );
    }
  );
  return validCustomers;
};

export const getConfigurations = (configurations: Configurations[] | []) => {
  if (configurations.length < 1) return [];

  const attributes = configurations.map(
    ({ platformConfigurationInfo }) =>
      platformConfigurationInfo.configurationSection[0].group[0].attribute
  );

  if (attributes.length === 0) return [];

  const configurationsLite = attributes.map((attrs: Attributes[]) =>
    attrs.reduce(
      (acc, attr) => ({
        ...acc,
        [attr.attributeName]: attr.attributeValue,
      }),
      {}
    )
  );

  return configurationsLite;
};

const transformModuleFields = (attr: Attributes) => {
  let newField: ModuleFields = { name: '', value: '' };
  const name = attr.attributeName;
  const value = attr.attributeValue;
  switch (name) {
    case 'ModuleID':
      newField = { name: 'moduleId', value: parseInt(value) };
      break;
    case 'ModuleName':
      newField = { name: 'moduleName', value: value };
      break;
    default:
      break;
  }
  return newField;
};

const transformContactFields = (attr: Attributes) => {
  let newField: ModuleFields = { name: '', value: '' };
  const name = attr.attributeName;
  const value = attr.attributeValue;
  switch (name) {
    case 'UserID':
      newField = { name: 'userId', value: value };
      break;
    case 'Mail':
    case 'Email':
      newField = { name: 'userEmail', value: value };
      break;
    case 'FirstName':
      newField = { name: 'userFirstName', value: value };
      break;
    case 'LastName':
      newField = { name: 'userLastName', value: value };
      break;
    case 'PhoneNumber':
    case 'Phone':
      newField = { name: 'userPhone', value: value };
      break;
    default:
      break;
  }
  return newField;
};

export const getCustomers = (
  configurations: Configurations[] | []
): CustomerMetadata[] | [] => {
  if (configurations.length < 1) return [];

  const attributes: Attributes[][] = configurations.map(
    ({ platformConfigurationInfo }) =>
      platformConfigurationInfo.configurationSection[0].group[0].attribute
  );

  if (attributes.length === 0) return [];

  const configurationsLite: (CustomerMetadata | undefined)[] = attributes.map(
    (attrs: Attributes[]) =>
      attrs.reduce(
        (acc, attr) => ({
          ...acc,
          [attr.attributeName]: attr.attributeValue,
        }),
        emptyCustomer
      )
  );

  return removeUndefinedCustomers(configurationsLite);
};

export const getRsiContacts = (
  configurations: Configurations[] | []
): Contact[] => {
  const emptyContacts: Contact = {};
  if (configurations.length < 1) return [];

  const configurationSection = getConfigurationSection(configurations);
  if (configurationSection.length === 0) return [];

  const contacts = configurationSection.flatMap(({ group }) =>
    group.map((attrs: Attributes[]) =>
      attrs.reduce((acc, attr) => {
        const newFields: ModuleFields = transformContactFields(attr);
        const name: string = newFields.name;
        const value: string | number = newFields.value;
        return {
          ...acc,
          [name]: value,
        };
      }, emptyContacts)
    )
  );

  const contactsFullFields = contacts.map((cContact: Contact) => ({
    ...cContact,
    userFullName: '',
    userContactType: '',
  }));
  return contactsFullFields;
};

export const getModules = (
  configurations: Configurations[] | []
): Modules[] => {
  const requiredModule = 'Platform';
  const defaultModuleId = -1;
  const emptyModule: Modules = {
    moduleId: defaultModuleId,
    moduleName: '',
  };
  if (configurations.length < 1) return [];

  const configurationSection = getConfigurationSection(configurations);
  if (configurationSection.length === 0) return [];

  const modules = configurationSection.flatMap(({ group }) =>
    group.map((attrs: Attributes[]) =>
      attrs.reduce((acc, attr) => {
        const newFields: ModuleFields = transformModuleFields(attr);
        const name: string = newFields.name;
        const value: string | number = newFields.value;
        return {
          ...acc,
          [name]: value,
        };
      }, emptyModule)
    )
  );

  return modules
    .map((cModule) => {
      if (cModule === undefined) {
        return undefined;
      }
      return {
        selected: cModule.moduleName === requiredModule,
        moduleId: cModule.moduleId,
        moduleName: cModule.moduleName,
      } as Modules;
    })
    .filter((module) => module !== undefined) as Modules[];
};

export const getLocaleType = (
  configurations: Configurations[] | []
): InfoLocalesList[] => {
  if (configurations.length < 1) return [];

  const configurationSection = getConfigurationSection(configurations);
  if (configurationSection.length === 0) return [];

  const response = configurationSection.flatMap((config) => ({
    locationId: '',
    locationName: `${config.configurationName}`,
    currency: `${config.group[0][0].attributeValue}`,
    languages: config.group[1].flatMap((item: Attributes) =>
      item.repeatingValue.map(
        ({ attributeValue: languageName }: Attribute) => ({
          languageName,
          selected: false,
        })
      )
    ),
    timeZones: config.group[2].flatMap((item: Attributes) =>
      item.repeatingValue.map(
        ({ attributeValue: timeZoneName }: Attribute) => ({
          timeZoneName,
          selected: false,
        })
      )
    ),
  }));

  return response;
};
