import { useEffect, useState } from 'react';

import { Grid, Stack, Typography } from '@mui/material';
import { MessageType } from '@revenue-solutions-inc/revxcoreui/material/messaging/Message/Message';
import ManageConfigToolsProvider from 'components/contexts/ManageConfigToolsProvider';
import TableManager from 'components/manageConfigTools/TableManager';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import { Row } from '@tanstack/react-table';
import {
  ConfigurationResponse,
  useGetConfigurationRecordsQuery,
  useGetConfigurationTypesQuery,
} from 'generated/graphql';
import { customFilter } from 'components/manageConfigTools/Shared/FilterHeader/FilterHeader';
import { configHeaderColumns } from 'components/manageConfigTools/Shared/ConfigHeader/ConfigHeader';
import Collapse from '@mui/material/Collapse';
import ExpandButton from 'components/manageConfigTools/Shared/ExpandButton/ExpandButton';
import {
  expandGrid,
  collapseGrid,
} from 'components/manageConfigTools/Shared/CollapseStyling/CollapseStyling';
import { useHasEdit } from 'hooks/useHasAccess';
import Loading from 'components/Loading';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { Card } from '@revenue-solutions-inc/revxcoreui';
import { ConfigurationDomains } from 'common/platformConfigUtils/platformConfigUtils';
import extractMeaningfulMessage from 'utils/errorMessage';
import { EditAccessUtil } from '../ConfigTypeEditor/editAccessUtil';

function ConfigRecordEditor(): JSX.Element {
  const { t } = useTranslation();
  const defaultMessage = t('components.message.networkerror');
  const dispatch = useAppDispatch();
  const [selectedConfigType, setSelectedConfigType] =
    useState<ConfigurationResponse>();
  const [configData, setConfigData] = useState<ConfigurationResponse[]>([]);
  const { isFetching: isFetchingTypes, data: configListData } =
    useGetConfigurationTypesQuery(
      {
        configurationDomain: ConfigurationDomains.ReferenceSchema.toString(),
      },
      {
        onError(error: Error[]) {
          dispatch(
            addMessage({
              message: extractMeaningfulMessage(error, defaultMessage),
              type: MessageType.Error,
            })
          );
        },
      }
    );
  const [displayPanel, setDisplayPanel] = useState<boolean>(false);
  const [expandWidth, setExpandWidth] = useState<number>(7);
  const {
    data,
    refetch: refetchTypes,
    isFetching,
  } = useGetConfigurationRecordsQuery(
    {
      configurationType: selectedConfigType?.configurationType ?? '',
      configurationModule: '',
    },
    {
      enabled: !!selectedConfigType?.configurationType,
      onError(error: Error[]) {
        dispatch(
          addMessage({
            message: extractMeaningfulMessage(error, defaultMessage),
            type: MessageType.Error,
          })
        );
      },
    }
  );

  // Added hardcoded UserRole,this "if" statement set readOnly flag to true
  const editAccess = new EditAccessUtil({
    isFullAccess: useHasEdit('configRecordEditor', 'platformconfigaccessall'),
    isLimitAccess: useHasEdit(
      'configRecordEditor',
      'platformconfiglimitaccess'
    ),
    isReadOnly: selectedConfigType?.configurationType === 'UserRole',
  });

  //This is a hardcoded known group name that comes out of the box
  const extendedAttributeGroup = 'Extended Attributes';

  useEffect(() => {
    if (data) setConfigData(data.getConfigurations);
    else setConfigData([]);
  }, [data]);

  // We only want to show config types that are not in extended attr group
  const configTypeList: ConfigurationResponse[] =
    configListData?.getConfigurationTypes.filter(
      ({ groupName }) => groupName !== extendedAttributeGroup
    ) ?? [];

  const fetchCursorSelectedRow = (rowdata: Row<ConfigurationResponse>) => {
    setSelectedConfigType(rowdata.original);
  };

  const handleSlidePanel = () => {
    setDisplayPanel(!displayPanel);
    if (expandWidth === 7) {
      setTimeout(() => setExpandWidth(12), 600);
    } else if (expandWidth == 12) {
      setExpandWidth(7);
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid
          item
          sm={displayPanel ? 0 : 5}
          xs={displayPanel ? 0 : 12}
          sx={{
            height: `${displayPanel ? '0 !important' : '100%'}`,
          }}
          className={`${displayPanel ? expandGrid : ''}`}
        >
          <Collapse
            in={!displayPanel}
            orientation="horizontal"
            sx={{
              overflow: 'hidden',
              '& .MuiCollapse-wrapper': {
                display: 'block',
              },
            }}
          >
            <Stack direction="row" sx={{ mb: '1em' }}>
              <AccountTreeIcon />
              <Typography variant="h1" sx={{ ml: '.4em' }}>
                {t('pages.configRecordEditor.configList')}
              </Typography>
            </Stack>
            <DefaultDataTableNext
              data={configTypeList}
              columns={configHeaderColumns}
              customFilter={customFilter as unknown as string}
              pageSize={10}
              cursorHover={true}
              fetchCursorSelectedRow={fetchCursorSelectedRow}
            />
          </Collapse>
        </Grid>

        <Grid
          item
          xs={12}
          sm={expandWidth}
          className={`${displayPanel ? collapseGrid : ''}`}
        >
          {selectedConfigType && configData && (
            <>
              <Stack
                direction="row"
                alignItems="end"
                spacing={2}
                sx={{ marginLeft: '.15em', mb: '1em' }}
              >
                <ExpandButton
                  displayPanel={displayPanel}
                  handleSlidePanel={handleSlidePanel}
                />
                <Typography variant="h1">
                  {t('pages.configRecordEditor.configRecords') +
                    ' ' +
                    selectedConfigType?.configurationType}
                </Typography>
              </Stack>
              {selectedConfigType.configurationDescription && (
                <Typography sx={{ marginLeft: '.15em', mb: '1em' }}>
                  {selectedConfigType.configurationDescription}
                </Typography>
              )}
              <Card
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  position: 'relative',
                  p: '.25em .5em .75em .5em',
                }}
              >
                <ManageConfigToolsProvider>
                  <TableManager
                    configData={configData}
                    setConfigData={setConfigData}
                    refetchTypes={refetchTypes}
                    selectedConfigType={selectedConfigType}
                    editAccess={editAccess}
                  />
                </ManageConfigToolsProvider>
              </Card>
            </>
          )}
          {(isFetchingTypes || isFetching) && <Loading />}
        </Grid>
      </Grid>
    </>
  );
}

export default ConfigRecordEditor;
