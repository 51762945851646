import { useCallback, useEffect, useState } from 'react';

import { Grid } from '@mui/material';
import {
  MessageActionType,
  MessageType,
} from '@revenue-solutions-inc/revxcoreui';
import { getDate } from 'common/helpers';
import {
  PaymentDetailsResponse,
  useGetPaymentDetailsByIdQuery,
} from 'generated/graphql';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { IHeader, setHeader } from 'redux/contentSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import Loading from 'components/Loading';
import EditPaymentDetailsModal from 'components/entityManagement/accounts/maintain/EditPaymentDetailsModal';
import PaymentDetailsDataCard from 'components/entityManagement/accounts/maintain/PaymentDetailsDataCard';
import PaymentTransactionsDataCard from 'components/entityManagement/accounts/maintain/PaymentTransactionsDataCard';

export interface PageHistory {
  prevPageName: string;
  prevPath: string;
}

function PaymentDetails(): JSX.Element {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { id } = useParams() as { id: string };
  const location = useLocation();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const module = useAppSelector((state) => state.user.module);
  const { control, formState, trigger, getValues, setValue } = useForm({
    mode: 'all',
  });
  const [paymentDetails, setPaymentDetails] =
    useState<PaymentDetailsResponse>();

  const { data, isLoading, isError } = useGetPaymentDetailsByIdQuery(
    {
      Id: id,
    },
    {
      onSuccess: (paymentData) => {
        setPaymentDetails(paymentData.GetPaymentDetailsById);
        setValue(
          'primaryId',
          paymentData?.GetPaymentDetailsById?.primaryIdentifier ?? ''
        );
        setValue(
          'primaryIdType',
          paymentData?.GetPaymentDetailsById?.primaryIdentifierType ?? ''
        );
        setValue(
          'accountType',
          paymentData?.GetPaymentDetailsById?.accountType ?? ''
        );
        setValue(
          'periodCovered',
          getDate(paymentData?.GetPaymentDetailsById?.periodCoveredDate ?? '')
        );
      },
    }
  );

  useEffect(() => {
    if (isError) {
      dispatch(
        addMessage({
          message: t('pages.paymentDetails.viewPaymentDetailsError'),
          type: MessageType.Error,
          actionType: MessageActionType.None,
        })
      );
    }
  }, [dispatch, isError, t]);

  //TODO: we need a better solution for this
  const getPrevPagePath = useCallback(
    (path: string) => {
      if (path && path.includes(module)) {
        return path.replace(module + '/', '');
      } else {
        return path;
      }
    },
    [module]
  );

  useEffect(() => {
    const headerData: IHeader = {
      pageTitle: t('pages.paymentDetails.title'),
      previousPage: location?.state?.prevPageName,
      route: getPrevPagePath(location.state?.prevPath.substring(1)),
      icon: {
        props: { fill: 'black' },
        icon: 'paymentIcon',
        fontSize: 'large',
      },
      data: [
        {
          id: 'paymentType',
          first: true,
          label: t('pages.paymentDetails.paymentType'),
          value: data?.GetPaymentDetailsById.paymentType ?? '-',
        },
        {
          id: 'amount',
          first: true,
          label: t('pages.paymentDetails.amount'),
          value: data?.GetPaymentDetailsById.amount
            ? new Intl.NumberFormat('en-GB', {
                style: 'currency',
                currency: 'USD',
                currencyDisplay: 'narrowSymbol',
              }).format(data?.GetPaymentDetailsById.amount)
            : '-',
        },
        {
          id: 'paymentStatus',
          label: t('pages.paymentDetails.paymentStatus'),
          value: data?.GetPaymentDetailsById.paymentStatus ?? '-',
        },
        {
          id: 'effectiveDate',
          label: t('pages.paymentDetails.effectiveDate'),
          value: getDate(data?.GetPaymentDetailsById.effectiveDate ?? ''),
        },
      ],
    };
    dispatch(setHeader(headerData));
  }, [data, dispatch, location, t, getPrevPagePath]);

  return (
    <>
      {isLoading && !isError && <Loading />}
      <Grid container spacing={2}>
        <PaymentDetailsDataCard
          paymentDetails={paymentDetails}
          setOpen={setOpenModal}
        />
        <PaymentTransactionsDataCard
          paymentId={id}
          canReversePayment={paymentDetails?.allowSimplifiedReversal ?? false}
        />
      </Grid>
      <EditPaymentDetailsModal
        open={openModal}
        setOpen={setOpenModal}
        paymentId={id}
        control={control}
        formState={formState}
        trigger={trigger}
        getValues={getValues}
      />
    </>
  );
}
export default PaymentDetails;
