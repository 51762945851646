import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FPO from 'assets/FPO.svg';
import placeholder from 'assets/revx-placeholder.png';

interface Props {
  setFpo?: boolean;
}

function UnderConstruction({ setFpo = false }: Props): JSX.Element {
  return (
    <Grid container spacing={0} direction="column" alignItems="center">
      <Grid item xs={3}>
        <Box
          data-testid="under_construction_asset"
          component="img"
          sx={{
            width: '100%',
          }}
          alt="Nothing to see here. Check back later"
          src={setFpo ? FPO : placeholder}
        />
      </Grid>
    </Grid>
  );
}

export default UnderConstruction;
