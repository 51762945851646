import { useEffect, useState } from 'react';

import { useMsal } from '@azure/msal-react';
import {
  MessageType,
  MessageActionType,
} from '@revenue-solutions-inc/revxcoreui';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';

function useGetAccessToken(): string | undefined {
  const navigate = useNavigate();
  const scope = useAppSelector((state) => state.content.scope);
  const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
  const { instance, accounts, inProgress } = useMsal();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (inProgress === 'none') {
      if (instance && accounts && accounts.length && scope) {
        instance
          .acquireTokenSilent({
            scopes: ['openid', scope],
            account: accounts[0],
          })
          .then((response: { accessToken: string }) => {
            setAccessToken(response.accessToken);
          })
          .catch(() => {
            dispatch(
              addMessage({
                message: 'User Token Expired Please Log In Again',
                type: MessageType.Error,
                actionType: MessageActionType.Continue,
              })
            );
            instance.logoutRedirect({
              account: accounts[0],
            });
          });
      }
    }
  }, [instance, accounts, inProgress, dispatch, scope, navigate]);
  return accessToken;
}

export default useGetAccessToken;
