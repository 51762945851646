import { useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { Attribute } from 'types/graphTypes';
import { TabPanel } from '@revenue-solutions-inc/revxcoreui';
import { CustomTable } from 'components/Workflow/CustomTable/CustomTable';

type TablesTabProps = {
  tables: Attribute[];
  variantId: string;
  handleAttributeChange: (
    value: string | number | Date,
    group: string,
    name: string
  ) => void;
};

export const TablesTab = ({ tables }: TablesTabProps) => {
  const [currentTab, setCurrentTab] = useState(0);

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: 'background.paper',
        display: 'flex',
        '.tables-tab__tabpane': {
          backgroundColor: (theme) => theme.palette.grey[50],
          paddingLeft: 2,
          width: '100%',
        },
      }}
    >
      <Tabs orientation="vertical" value={currentTab}>
        {tables.map(
          (
            { attributeDisplayName, attributeName }: Attribute,
            index: number
          ) => {
            return (
              <Tab
                key={`${index}-${attributeName}`}
                value={index}
                label={attributeDisplayName || attributeName}
                onClick={() => setCurrentTab(index)}
              />
            );
          }
        )}
      </Tabs>
      <div className="tables-tab__tabpane">
        {tables.map(
          ({ attributeName, repeatingValue }: Attribute, index: number) => {
            return (
              <TabPanel
                selectedIndex={currentTab}
                index={index}
                sx={{
                  backgroundColor: (theme) => theme.palette,
                }}
                key={`${attributeName}-${index}`}
              >
                <CustomTable tableData={repeatingValue as Attribute[]} />
              </TabPanel>
            );
          }
        )}
      </div>
    </Box>
  );
};
