import { useMemo } from 'react';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import { ColumnDef, Row } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import WorkflowIDCell from 'components/Workflow/WorkflowIDCell';
import PriorityIcon from 'components/PriorityIcon/PriorityIcon';
import { getFormatDate } from 'utils/date-util';

export type WorkflowTableProps = {
  tableName?: string;
  tableData: unknown[];
  onRowClick?: (
    rowData: Row<{ [key: string]: unknown }>,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null
  ) => unknown;
  cursorHover?: boolean;
  customColumns?: ColumnDef<Record<string, unknown>>[];
  customHeader?: JSX.Element;
};

export const WorkHistoryTable = ({
  tableName,
  tableData = [],
  onRowClick = () => {},
  cursorHover,
  customColumns = [],
  customHeader,
}: WorkflowTableProps): JSX.Element => {
  const { t } = useTranslation();

  const columns: ColumnDef<Record<string, unknown>>[] = useMemo(() => {
    return [
      ...customColumns,
      {
        // Work ID column
        enableSorting: true,
        accessorKey: 'DisplayId',
        id: 'Workflow_Key',
        header: () => (
          <HeaderColumnNext
            localization={t('pages.workflow.assignedTable.workflow_Key')}
          />
        ),
        cell: ({ row }) => (
          <WorkflowIDCell
            workflowKey={row.original.Workflow_Key as string}
            variantId={row.original.Workflow_Variant_Id as string}
            displayId={row.original.DisplayId as string}
          />
        ),
      },
      // Queue Name
      {
        enableSorting: true,
        accessorKey: 'QueueName',
        id: 'QueueName',
        header: () => (
          <HeaderColumnNext
            localization={t('components.rowDisplay.queueName')}
          />
        ),
      },
      {
        // Work Type column
        enableSorting: true,
        accessorKey: 'VariantGroupCode',
        id: 'Work Type',
        header: () => (
          <HeaderColumnNext
            localization={t('pages.workflow.assignedTable.variantGroupCode')}
          />
        ),
      },
      {
        // Work Subtype column
        enableSorting: true,
        accessorKey: 'WorkflowVariantCode',
        id: 'Work Subtype',
        header: () => (
          <HeaderColumnNext
            localization={t('pages.workflow.assignedTable.workflowVariantCode')}
          />
        ),
      },
      {
        enableSorting: true,
        accessorKey: 'PriorityIndicator',
        id: 'PriorityIndicator',
        header: () => (
          <HeaderColumnNext
            localization={t('pages.workflow.assignedTable.priorityIndicator')}
          />
        ),
        cell: ({ getValue }) => {
          const value = getValue() as string;
          return <PriorityIcon value={value} />;
        },
      },
      {
        enableSorting: true,
        accessorKey: 'VariantStartDate',
        id: 'VariantStartDate',
        cell: ({ getValue }) => {
          const value = getFormatDate(new Date(getValue() as Date));
          return value;
        },
        header: () => (
          <HeaderColumnNext
            localization={t('pages.workflow.assignedTable.variantStartDate')}
          />
        ),
      },
      {
        enableSorting: true,
        accessorKey: 'VariantEndDate',
        id: 'VariantEndDate',
        header: () => (
          <HeaderColumnNext
            localization={t('pages.workflow.assignedTable.variantEndDate')}
          />
        ),
        cell: ({ getValue }) => {
          const value = getFormatDate(new Date(getValue() as Date));
          return value;
        },
      },
      {
        enableSorting: true,
        accessorKey: 'State',
        id: 'Steps',
        header: () => (
          <HeaderColumnNext
            localization={t('pages.workflow.assignedTable.state')}
          />
        ),
      },
    ];
  }, [t, customColumns]);

  return (
    <DefaultDataTableNext
      id="wf-history-table"
      tableName={tableName}
      data={tableData as Record<string, unknown>[]}
      columns={columns}
      fetchCursorSelectedRow={onRowClick}
      cursorHover={cursorHover}
      customHeader={customHeader}
    />
  );
};
