import {
  ChangeEvent,
  useEffect,
  useState,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { IHeader, setHeader } from 'redux/contentSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import { useTranslation } from 'react-i18next';
import { Stack, Grid, Box, Tabs, Tab, List } from '@mui/material';
import {
  TextArea,
  Button,
  MessageType,
  MessageActionType,
  TabPanel,
  Checkbox,
} from '@revenue-solutions-inc/revxcoreui';
import Input from '@revenue-solutions-inc/revxcoreui/material/controls/Input';
import {
  IBusinessPolicy,
  businessPolicyDefault,
  IBusinessPolicyErrors,
  businessPolicyDefaultErrors,
} from 'types/businessPolicy';
import {
  useCreateBusinessPolicyMutation,
  useUpdateBusinessPolicyMutation,
  useBusinessPolicyByIdQuery,
  CreateBusinessPolicyInput,
  UpdateBusinessPolicyInput,
  BusinessPolicyByIdResponse,
  useBusinessPolicyNameExistQuery,
  RoleLiteResponse,
  useAllRolesQuery,
} from 'generated/graphql';
import Loading from 'components/Loading';
import extractMeaningfulMessage from 'utils/errorMessage';
import { getShortText } from 'utils/getShortText';
import SideScrollBox from 'components/SideScrollBox';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import { ColumnDef, Column, Row } from '@tanstack/react-table';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import NoResults from 'components/NoResults';
import {
  Keys,
  handleIsChecked,
  handleElementsSelected,
} from 'utils/checkboxes';

enum ActionType {
  EDIT = 'edit',
  CREATE = 'create',
}

function BusinessPolicy(): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState(0);
  const module = useAppSelector((state) => state.user.module);
  const { customerBusinessPolicyId, action } = useParams() as {
    customerBusinessPolicyId: string;
    action: string;
  };
  const businessPolicyNameMaxLength = 100;
  const businessPolicyNameMinLength = 3;
  const businessPolicyRemoveError = 0;
  const businessPolicyDescriptionMinLength = 3;
  const businessPolicyDescriptionMaxLength = 245;
  const inputFocus = useRef<HTMLInputElement>(null);
  const [businessPolicyNameExists, setBusinessPolicyNameExists] =
    useState<boolean>(false);
  const [businessPolicyErrors, setBusinessPolicyErrors] =
    useState<IBusinessPolicyErrors>(businessPolicyDefaultErrors);
  const [businessPolicy, setBusinessPolicy] = useState<IBusinessPolicy>(
    businessPolicyDefault
  );
  const [roles, setRoles] = useState<RoleLiteResponse[]>([]);
  const [rolesSelected, setRolesSelected] = useState<string[]>([]);
  const [businessPolicyEditName, setBusinessPolicyEditName] =
    useState<string>('');
  const [filteredRows, setFilteredRows] = useState<RoleLiteResponse[]>([]);
  const [isSeaching, setIsSeaching] = useState<{
    status: boolean;
    value: string;
  }>({
    status: false,
    value: '',
  });

  const createBusinessPolicy =
    useCreateBusinessPolicyMutation<CreateBusinessPolicyInput>();

  const updateBusinessPolicy =
    useUpdateBusinessPolicyMutation<UpdateBusinessPolicyInput>();

  const { isFetching: isFetchingRoles } = useAllRolesQuery<{
    AllRoles: RoleLiteResponse[];
  }>(
    {},
    {
      onSuccess: (data) => {
        if (data?.AllRoles) {
          const newRoles = data.AllRoles;
          setRoles(newRoles);
        }
      },
      onError: (error) => {
        const message = extractMeaningfulMessage(
          error,
          t('pages.manageReusableContent.networkError')
        );
        dispatch(
          addMessage({
            type: MessageType.Error,
            message: message,
            actionType: MessageActionType.None,
          })
        );
      },
    }
  );

  const { refetch: refetchBusinessPolicyNameExists } =
    useBusinessPolicyNameExistQuery<{
      BusinessPolicyNameExist: boolean;
    }>(
      {
        name: `${businessPolicy.name}`.trim(),
      },
      {
        enabled: false,
        onSuccess: (data) => {
          setBusinessPolicyNameExists(data.BusinessPolicyNameExist);
        },
        onError: (error) => {
          const message = extractMeaningfulMessage(
            error,
            t('pages.manageReusableContent.networkError')
          );

          setBusinessPolicyErrors({
            ...businessPolicyErrors,
            name: {
              isError: message !== '',
              message: message,
            },
          });
        },
      }
    );

  const {
    isLoading: isLoadingBusinessPolicy,
    isError: isErrorBusinessPolicy,
    refetch: refetchBusinessPolicy,
  } = useBusinessPolicyByIdQuery<{
    BusinessPolicyById: BusinessPolicyByIdResponse;
  }>(
    {
      customerBusinessPolicyId: customerBusinessPolicyId,
    },
    {
      enabled: false,
      onSuccess: (data) => {
        if (data?.BusinessPolicyById) {
          const businessPolicyData = data.BusinessPolicyById;
          setBusinessPolicyEditName(businessPolicyData.name);
          setBusinessPolicy(businessPolicyData);
          const newRolesSelected = businessPolicyData.roles.map(
            (policyId) => `${policyId}`
          );
          setRolesSelected(newRolesSelected);
        }
      },
      onError: (error) => {
        if (action === ActionType.EDIT) {
          const message = extractMeaningfulMessage(
            error,
            t('pages.manageReusableContent.networkError')
          );
          dispatch(
            addMessage({
              actionType: MessageActionType.None,
              type: MessageType.Error,
              message: message,
            })
          );
        }
      },
    }
  );

  useEffect(() => {
    if (businessPolicy.name.length >= businessPolicyNameMinLength) {
      if (businessPolicy.name.trim() === businessPolicyEditName.trim()) {
        setBusinessPolicyNameExists(false);
      } else {
        refetchBusinessPolicyNameExists();
      }
    }
  }, [
    businessPolicy.name,
    businessPolicyEditName,
    refetchBusinessPolicyNameExists,
  ]);

  useEffect(() => {
    if (action === ActionType.EDIT) {
      refetchBusinessPolicy();
    }
  }, [action, refetchBusinessPolicy]);

  useEffect(() => {
    const headerData: IHeader = {
      pageTitle: t('pages.businessPolicy.create.title'),
      previousPage: t('pages.businessPolicy.previousPage'),
      route: 'manageBusinessPolicies',
    };

    if (action === ActionType.EDIT) {
      headerData.pageTitle = t('pages.businessPolicy.edit.title');
      headerData.icon = {
        icon: 'assignmentTurnedInIcon',
        props: { fill: 'black' },
        fontSize: 'large',
      };
      headerData.data = [
        {
          id: 'name-header',
          label: t('pages.businessPolicy.edit.header.name'),
          value: getShortText(businessPolicy?.name ?? ''),
        },
        {
          id: 'description-header',
          label: t('pages.businessPolicy.edit.header.description'),
          value: getShortText(businessPolicy?.description ?? ''),
        },
      ];
    }
    dispatch(setHeader(headerData));
  }, [action, businessPolicy?.description, businessPolicy?.name, dispatch, t]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      inputFocus?.current?.focus();
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const validateField = (type: 'name' | 'description', value: string) => {
    const currentLength = value.length;
    let errorMessage = '';
    switch (type) {
      case 'name':
        if (currentLength === businessPolicyRemoveError) {
          errorMessage = '';
        } else if (currentLength < businessPolicyNameMinLength) {
          errorMessage = t('pages.businessPolicy.errors.minChars.name');
        } else if (currentLength > businessPolicyNameMaxLength) {
          errorMessage = t('pages.businessPolicy.errors.maxChars.name');
        }
        break;
      case 'description':
        if (currentLength === businessPolicyRemoveError) {
          errorMessage = '';
        } else if (currentLength > businessPolicyDescriptionMaxLength) {
          errorMessage = t('pages.businessPolicy.errors.maxChars.description');
        } else if (currentLength < businessPolicyDescriptionMinLength) {
          errorMessage = t('pages.businessPolicy.errors.minChars.description');
        }
        break;
      default:
        break;
    }

    setBusinessPolicy({
      ...businessPolicy,
      [type]: value,
    });

    setBusinessPolicyErrors({
      ...businessPolicyErrors,
      [type]: {
        isError: errorMessage !== '',
        message: errorMessage,
      },
    });
  };
  const handleCheckAll = useMemo((): boolean => {
    const areCheckedAll = rolesSelected.length > 0 && roles.length > 0;
    if (isSeaching.status) {
      return areCheckedAll && rolesSelected.length === filteredRows.length;
    } else {
      if (rolesSelected) {
        return areCheckedAll && rolesSelected.length === roles.length;
      }
    }
    return false;
  }, [filteredRows.length, isSeaching.status, roles.length, rolesSelected]);

  const handleDisableButton = useMemo((): boolean => {
    const disableButton = [
      businessPolicyNameExists,
      businessPolicyErrors.description.isError,
      businessPolicyErrors.name.isError || businessPolicy.name === '',
      rolesSelected.length < 1,
    ];

    return disableButton.some((element) => element === true);
  }, [
    businessPolicy.name,
    businessPolicyErrors.description.isError,
    businessPolicyErrors.name.isError,
    businessPolicyNameExists,
    rolesSelected.length,
  ]);

  const handlePreviousPage = () => {
    navigate(`/${module}/manageBusinessPolicies`);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  const handleSaveBusinessPolicy = () => {
    if (action === ActionType.EDIT) {
      updateBusinessPolicy.mutate(
        {
          businessPolicyUpdate: {
            customerBusinessPolicyId: businessPolicy.customerBusinessPolicyId,
            description: businessPolicy.description,
            name: businessPolicy.name,
            roles: rolesSelected.map((cRolesDomainId) => cRolesDomainId),
          },
        },
        {
          onSuccess: () => {
            dispatch(
              addMessage({
                message: t('pages.businessPolicy.edit.successMessage'),
                type: MessageType.Success,
                actionType: MessageActionType.None,
              })
            );
            handlePreviousPage();
          },
          onError: (e: Error[] | unknown) => {
            const onCreateDefaultError = t(
              'pages.businessPolicy.edit.errorMessage'
            );
            dispatch(
              addMessage({
                type: MessageType.Error,
                message: extractMeaningfulMessage(e, onCreateDefaultError),
                actionType: MessageActionType.None,
              })
            );
          },
        }
      );
    } else {
      createBusinessPolicy.mutate(
        {
          businessPolicyCreate: {
            name: businessPolicy.name,
            description: businessPolicy.description,
            roles: rolesSelected.map((cRolesDomain) => cRolesDomain),
          },
        },
        {
          onSuccess: () => {
            dispatch(
              addMessage({
                actionType: MessageActionType.None,
                type: MessageType.Success,
                message: t('pages.businessPolicy.create.successMessage'),
              })
            );
            handlePreviousPage();
          },
          onError: (error) => {
            const message = extractMeaningfulMessage(
              error,
              t('pages.businessPolicy.edit.errorMessage')
            );
            dispatch(
              addMessage({
                actionType: MessageActionType.None,
                type: MessageType.Error,
                message: message,
              })
            );
          },
        }
      );
    }
  };

  const getSeachingValue = useCallback((value: string) => {
    const valueLength = value.length > 0;
    setIsSeaching({ status: valueLength, value: value });
  }, []);

  const getFilteredRows = useCallback(
    (newFilteredRows: Row<RoleLiteResponse>[]) => {
      if (isSeaching.status && isSeaching.value.length > 0) {
        const newOriginalRows: RoleLiteResponse[] = newFilteredRows.map(
          (row) => row.original
        );
        setFilteredRows(newOriginalRows);
      }
    },
    [isSeaching]
  );

  const roleColumns: ColumnDef<RoleLiteResponse>[] = [
    {
      header: () => {
        return (
          <Checkbox
            {...{
              onKeyDown: (event) => {
                if (event.key === Keys.ENTER) {
                  if (roles.length === rolesSelected.length) {
                    setRolesSelected([]);
                  } else {
                    const newRoles = roles.map((p) => `${p.id}`);
                    setRolesSelected(newRoles);
                  }
                }
              },
            }}
            id={'selectAllCheck'}
            label={''}
            checked={handleCheckAll}
            onChange={(event) => {
              let newPolicies: string[] = [];
              if (event.target.checked) {
                if (isSeaching.status) {
                  newPolicies = filteredRows.map((pGroup) => pGroup.id);
                  if (rolesSelected.length > 0) {
                    newPolicies = [...rolesSelected, ...newPolicies];
                    newPolicies = newPolicies.filter(
                      (item, index) => newPolicies.indexOf(item) === index
                    );
                  }
                } else if (roles && roles?.length > 0) {
                  newPolicies = roles?.map((pGroup) => pGroup.id);
                }
              } else {
                if (isSeaching.status && rolesSelected?.length > 0) {
                  newPolicies = rolesSelected.filter(
                    (item) =>
                      !filteredRows.map((pGroup) => pGroup.id).includes(item)
                  );
                }
              }
              setRolesSelected(newPolicies);
            }}
            sx={{ ml: 1, mr: 3 }}
          />
        );
      },
      id: 'rolesByDomainId',
      cell: ({ row }) => {
        return (
          <Checkbox
            {...{
              onKeyDown: (event) => {
                if (event.key === Keys.ENTER) {
                  const isChecked = handleIsChecked(
                    rolesSelected,
                    `${row.original.id}`
                  );
                  const newRolesSelected = handleElementsSelected(
                    [...rolesSelected],
                    isChecked,
                    `${row.original.id}`
                  );
                  setRolesSelected(newRolesSelected);
                }
              },
            }}
            id={'rolesChk'}
            checked={
              rolesSelected.length > 0 &&
              rolesSelected.findIndex(
                (cRolesByDomainId) => cRolesByDomainId === row.original.id
              ) > -1
            }
            label={''}
            onChange={(event) => {
              if (event.target.checked) {
                setRolesSelected([...rolesSelected, `${row.original.id}`]);
              } else {
                const newPolicies = [...rolesSelected].filter(
                  (cRolesByDomain) => cRolesByDomain !== `${row.original.id}`
                );
                setRolesSelected(newPolicies);
              }
            }}
            sx={{ ml: 1, mr: 3 }}
          />
        );
      },
    },
    {
      id: 'name',
      accessorKey: 'name',
      header: () => (
        <HeaderColumnNext localization={t('pages.businessPolicy.roleName')} />
      ),
      cell: ({ getValue }) => {
        return (
          <p style={{ width: '20em', wordBreak: 'break-word' }}>
            {getValue() as string}
          </p>
        );
      },
    },
    {
      id: 'description',
      accessorKey: 'description',
      header: () => (
        <HeaderColumnNext
          localization={t('pages.businessPolicy.roleDescription')}
        />
      ),
      cell: ({ getValue }) => {
        return (
          <p style={{ width: '40em', wordBreak: 'break-word' }}>
            {getValue() as string}
          </p>
        );
      },
    },
  ];

  return (
    <>
      {action === ActionType.EDIT &&
        isLoadingBusinessPolicy &&
        !isErrorBusinessPolicy && <Loading />}

      <Grid container>
        <Grid
          item
          xs={4}
          minWidth="15rem"
          sx={{
            flexGrow: '1 !important',
            maxWidth: '100% !important',
          }}
        >
          {action === ActionType.EDIT && (
            <Grid item mb={2} xs={10}>
              <Stack direction="row" spacing={2}>
                <Input
                  id="businessPolicyUniqueId"
                  data-testid="businessPolicyUniqueId-input"
                  label={t('pages.businessPolicy.customerBusinessPolicyId')}
                  inputProps={{
                    'data-testid': 'businessPolicyUniqueId-input',
                  }}
                  required
                  disabled={action === ActionType.EDIT}
                  value={businessPolicy?.customerBusinessPolicyId ?? ''}
                  sx={{ width: '100%' }}
                />
              </Stack>
            </Grid>
          )}
          <Grid item xs={10} mb={2}>
            <Stack direction="row" spacing={2}>
              <Input
                id="businessPolicyName-createBusinessPolicyName"
                data-testid="businessPolicyName-input"
                label={t('pages.businessPolicy.name')}
                required
                inputProps={{
                  'data-testid': 'businessPolicyName-input',
                  ref: inputFocus,
                  maxLength: businessPolicyNameMaxLength + 1,
                }}
                value={businessPolicy?.name ?? ''}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  validateField('name', event.target.value);
                }}
                error={
                  businessPolicyErrors.name.isError || businessPolicyNameExists
                }
                helperText={
                  businessPolicyErrors.name.message ||
                  (businessPolicyNameExists
                    ? t('pages.businessPolicy.errors.businessPolicyNameExists')
                    : '')
                }
                sx={{ width: '100%' }}
              />
            </Stack>
          </Grid>
          <Grid item mb={2} xs={10}>
            <TextArea
              id="businessPolicyDescription"
              data-testid="businessPolicyDescription"
              label={t('pages.businessPolicy.description')}
              value={businessPolicy?.description ?? ''}
              onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                validateField('description', event.target.value);
              }}
              multiline
              error={businessPolicyErrors.description.isError}
              helperText={businessPolicyErrors.description.message}
              sx={{ width: '100%' }}
              inputProps={{
                maxLength: businessPolicyDescriptionMaxLength + 1,
              }}
            />
          </Grid>
          <Grid item xs={10} mb={2}>
            <List
              sx={{
                height: 400,
              }}
            >
              <SideScrollBox
                title={t('pages.businessPolicy.selectedRoles')}
                options={
                  rolesSelected && rolesSelected.length > 0
                    ? rolesSelected.map((cRoles) => {
                        const name =
                          roles.find((p) => p.id === cRoles)?.name ??
                          `${cRoles}`;
                        return {
                          key: cRoles,
                          name: name,
                        };
                      })
                    : []
                }
                id="policy-box"
                scrollBoxValues={rolesSelected as unknown as string[]}
                onChangeState={setRolesSelected}
                width={'100%'}
              />
            </List>
          </Grid>
          <Grid item xs={12} mb={1}>
            <Button
              onClick={() => handleSaveBusinessPolicy()}
              id="saveBtn-createPolicyGroup"
              data-testid="save-button"
              sx={{ mt: 1, mb: 1 }}
              disabled={handleDisableButton}
            >
              {action === ActionType.EDIT
                ? t('pages.businessPolicy.edit.buttons.update')
                : t('pages.businessPolicy.create.buttons.save')}
            </Button>
          </Grid>
        </Grid>
        <Grid
          item
          xs={8}
          sx={{ flexGrow: '1 !important', maxWidth: '100% !important' }}
        >
          <Box sx={{ borderColor: 'divider' }}>
            <Tabs
              value={currentTab}
              onChange={handleTabChange}
              aria-label="role Tabs"
            >
              <Tab label={t('pages.businessPolicy.assignedRoles')} />
            </Tabs>
          </Box>
          <TabPanel selectedIndex={currentTab} index={0}>
            {isFetchingRoles && <Loading />}
            {!isFetchingRoles && roles.length > 0 ? (
              <div data-testid="rolesDomainId-data">
                <DefaultDataTableNext
                  columns={roleColumns as Column<RoleLiteResponse>[]}
                  data={roles}
                  getSeachingValue={getSeachingValue}
                  getFilteredRows={getFilteredRows}
                />
              </div>
            ) : (
              <NoResults />
            )}
          </TabPanel>
        </Grid>
      </Grid>
    </>
  );
}

export default BusinessPolicy;
