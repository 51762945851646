import {
  AccountBalance,
  AlternateEmail,
  Badge,
  Home,
  Label,
  PermIdentity,
  PlaylistAdd,
  Phone,
} from '@mui/icons-material';
import { Theme } from '@mui/system';

interface Props {
  sectionName: string;
}

const setIconColor = (theme: Theme) => ({ fill: theme.palette.primary.main });

function CardAvatar({ sectionName }: Props): JSX.Element {
  const getAvatar = (): JSX.Element => {
    switch (sectionName) {
      case 'account':
        return <AccountBalance sx={setIconColor} />;
      case 'names':
        return <PermIdentity sx={setIconColor} />;
      case 'identifiers':
        return <Badge sx={setIconColor} />;
      case 'addresses':
        return <Home sx={setIconColor} />;
      case 'emailAddresses':
        return <AlternateEmail sx={setIconColor} />;
      case 'phoneNumbers':
        return <Phone sx={setIconColor} />;
      case 'extended':
        return <PlaylistAdd sx={setIconColor} />;
      default:
        return <Label sx={setIconColor} />;
    }
  };

  return getAvatar();
}

export default CardAvatar;
