// TODO: Remove this once the getTask call is updated to correct casing
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { MessageType } from '@revenue-solutions-inc/revxcoreui';
import ProgressLoader from '@revenue-solutions-inc/revxcoreui/material/controls/ProgressLoader';
import Loading from 'components/Loading';
import { useTranslation } from 'react-i18next';
import useGetAccessToken from 'hooks/useGetAccessToken';
import {
  CmScheduledTask,
  ConfigurationResponse,
  useEditScheduleTaskMutation,
  useGetTaskQuery,
  useGetTaskSinkObjectQuery,
  useCreateAndPublishTaskMutation,
  useScheduleTaskMutation,
  useUpdateAndPublishTaskMutation,
  GetConfigurationDocument,
  GetConfigurationQuery,
} from 'generated/graphql';
import { setHeader } from 'redux/contentSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import {
  PipelineScheduleInput,
  ScheduledTasks,
  taskObject,
  Channel,
  ScheduledTasksInput,
} from 'types/channels';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, useWatch } from 'react-hook-form';
import ChannelSchedule from 'pages/admin/createChannel/ChannelSchedule';
import { getChannel } from 'pages/admin/createChannel/CreateChannel/defaultChannel';
import { useQueryClient } from '@tanstack/react-query';
import ManageConfigToolsProvider from 'components/contexts/ManageConfigToolsProvider';
import { isEqual } from 'lodash';
import { useHasAccess } from 'hooks/useHasAccess';
import { getScheduledTasks } from '../defaultTask';
import ScheduledTasksDetails from '../ScheduledTasksDetails';
import ScheduledTasksStages from '../ScheduledTasksStages';

enum ActionType {
  EDIT = 'edit',
  CREATE = 'create',
}

export interface Parameters {
  data: Data;
}

export interface Data {
  getConfiguration: GetConfigurationQuery;
}

function ScheduledTasksPage(): JSX.Element {
  const { mutate, isLoading } = useScheduleTaskMutation();
  const disablePublishTaskScheduled = useHasAccess('publishTask', 'view');
  const { t } = useTranslation();
  const module = useAppSelector((state) => state.user.module);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { mutate: mutateEdit, isLoading: editLoading } =
    useEditScheduleTaskMutation();
  const { mutate: publishTask, isLoading: publishLoading } =
    useCreateAndPublishTaskMutation();
  const { mutate: updateTask, isLoading: updateLoading } =
    useUpdateAndPublishTaskMutation();
  const [completed, setCompleted] = useState<{
    [k: number]: boolean;
  }>({});
  const [currentStep, setCurrentStep] = useState(0);
  const [isTaskDetailsValid, setTaskDetailsValid] = useState(false);
  const [isTaskStagesValid, setTaskStagesValid] = useState(false);
  const [showSchedule, setShowSchedule] = useState<boolean>(true);
  const [stepPassedValidation, setStepPassedValidation] =
    useState<boolean>(true);
  const [parameterValues, setParameterValues] = useState<
    ConfigurationResponse[][]
  >([]);
  const [checkedValue, setCheckedValue] = useState<string>('no');
  const [task, setTask] = useState<ScheduledTasks>(taskObject);
  const [runEdit, setRunEdit] = useState<boolean>(true);
  const [currentData, setCurrentData] = useState<ConfigurationResponse[]>([]);
  const [editTaskCheck, setEditTaskCheck] = useState<boolean>(false);
  const [isChannelScheduleValid, setChannelScheduleValid] = useState(false);

  const taskForm: ScheduledTasks = getScheduledTasks;
  const channelForm: Channel = getChannel;
  const { control, getValues, watch, setValue } = useForm<ScheduledTasks>({
    defaultValues: taskForm,
    mode: 'onChange',
  });
  const [data, setData] = useState<GetConfigurationQuery | undefined | null>(
    null
  );
  const [arrayTest, setArrayTest] = useState<Parameters[]>([]);
  const [masterList, setMasterList] = useState<ConfigurationResponse[]>([]);
  const queryClient = useQueryClient();

  const {
    watch: watchSchedule,
    setValue: setValueSchedule,
    control: controlSchedule,
    getValues: getValuesSchedule,
  } = useForm<Channel>({
    defaultValues: channelForm,
    mode: 'onChange',
  });

  const stagesTask = useWatch({
    control,
    name: 'ScheduleTask.Stages',
  });

  const stageNames = stagesTask.map((stage) => {
    return stage.StageName;
  });

  const { TaskId, ScheduleTaskName, currentModule, action } = useParams() as {
    currentModule: string;
    TaskId: string;
    ScheduleTaskName: string;
    action: string;
  };

  const { data: taskSinkObject, isLoading: sinkLoading } =
    useGetTaskSinkObjectQuery({});

  const { data: valueTask, isLoading: getLoading } = useGetTaskQuery(
    {
      taskName: ScheduleTaskName,
      taskId: TaskId,
      module: currentModule,
    },
    {
      enabled: !!TaskId && !!ScheduleTaskName,
    }
  );
  const accessToken = useGetAccessToken();

  const validateSteps = () => {
    let isValid = true;

    switch (currentStep) {
      case 0:
        isValid = isTaskDetailsValid;
        break;
      case 1:
        isValid = isTaskStagesValid;
        break;
    }
    setStepPassedValidation(isValid);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getConfiguration = async (operationName) => {
    const url = process.env.REACT_APP_GRAPH || '';
    const resp = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        query: GetConfigurationDocument,
        variables: {
          configurationName: operationName,
          configurationDomain: 7,
          configurationModule: 3,
        },
      }),
    });
    const res = await resp.json();

    return res;
  };

  useEffect(() => {
    validateSteps();
  });

  useEffect(() => {
    // TODO: Remove this function once the services change the object
    const lowerCaseKeys = (obj) => {
      const isObject = (o) =>
        Object.prototype.toString.apply(o) === '[object Object]';
      const isArray = (o) =>
        Object.prototype.toString.apply(o) === '[object Array]';

      const transformedObj = isArray(obj) ? [] : {};

      for (const key in obj) {
        const transformedKey = key.replace(/^\w/, (c) => c.toLowerCase());
        if (isObject(obj[key]) || isArray(obj[key])) {
          transformedObj[transformedKey] = lowerCaseKeys(obj[key]);
        } else {
          transformedObj[transformedKey] = obj[key];
        }
      }
      return transformedObj;
    };
    if (valueTask && !editTaskCheck && accessToken) {
      setValue(
        `ScheduleTask`,
        valueTask.getTask as unknown as ScheduledTasksInput
      );
      if (valueTask.getTask.PipelineSchedules) {
        setCheckedValue('yes');
        setShowSchedule(true);
      }
      setValue(`ScheduleTask.PipelineSchedules`, []);
      const testingOne = valueTask.getTask.Stages.map(function (item) {
        return getConfiguration(item.OperationName).then(function (results) {
          return results;
        });
      });

      Promise.all(testingOne).then(function (results) {
        setArrayTest(results);
      });
      if (valueTask.getTask.PipelineSchedules !== null) {
        const newValues = valueTask.getTask.PipelineSchedules.map(
          (parameters) => parameters.TaskParameters
        );

        const actualArray = [];

        newValues.forEach(function (item) {
          const testingArray = [];
          if (item !== null) {
            Object.values(item).map((ele) => testingArray.push(ele));
            actualArray.push(testingArray);
          } else {
            actualArray.push(null);
          }
        });

        const test = lowerCaseKeys(actualArray);

        setParameterValues(test as unknown as ConfigurationResponse[][]);
      }

      setValueSchedule(
        `Channel.Pipeline.PipelineSchedule`,
        valueTask.getTask
          .PipelineSchedules as unknown as PipelineScheduleInput[]
      );
      setEditTaskCheck(true);
    }
  }, [
    accessToken,
    arrayTest,
    currentData,
    editTaskCheck,
    getConfiguration,
    setValue,
    setValueSchedule,
    valueTask,
  ]);

  useEffect(() => {
    if (action === ActionType.CREATE) {
      const headerData: IHeader = {
        pageTitle: t('pages.manageChannel.createScheduledTask'),
        previousPage: t('pages.manageChannel.manageTask'),
        route: `managescheduledtasks/`,
      };
      dispatch(setHeader(headerData));
    }
    if (action === ActionType.EDIT && !getLoading && valueTask) {
      const headerData: IHeader = {
        pageTitle: t('pages.manageChannel.createTaskEdit'),
        previousPage: t('pages.manageChannel.manageTask'),
        route: `managescheduledtasks/`,
        icon: {
          props: { fill: 'black' },
          icon: 'assignmentIcon',
          fontSize: 'large',
        },
        data: [
          {
            id: 'task-display-name',
            first: true,
            label: t('pages.manageChannel.ScheduleTaskName'),
            value: valueTask?.getTask.ScheduleTaskName,
          },
          {
            id: 'current-version',
            label: t('pages.manageChannel.currentVersion'),
            value: valueTask?.getTask.Version
              ? valueTask?.getTask.Version?.toString()
              : '-',
          },
          {
            id: 'current-status',
            label: t('pages.manageChannel.currentStatus'),
            value: valueTask?.getTask.Status,
          },
        ],
      };
      dispatch(setHeader(headerData));
    }
  }, [isLoading, dispatch, t, action, getLoading, valueTask]);

  useEffect(() => {
    switch (currentStep) {
      case 0:
        setCompleted((prevCompleted) => ({
          ...prevCompleted,
          [currentStep]: isTaskDetailsValid,
        }));
        break;
      case 1:
        setCompleted((prevCompleted) => ({
          ...prevCompleted,
          [currentStep]: isTaskStagesValid,
        }));
        break;
    }
  }, [currentStep, isTaskDetailsValid, isTaskStagesValid]);

  const handleReset = () => {
    setCurrentStep(0);
    setCompleted({});
  };

  const checkParameters = (index) => {
    const values = parameterValues[index].map(function (item, indexValue) {
      if (
        !isEqual(
          item.platformConfigurationInfo,
          masterList[indexValue].platformConfigurationInfo
        )
      ) {
        return item;
      }
    });

    return values;
  };

  function mapTask() {
    const newTask = { ...task };

    newTask.ScheduleTask = getValues(`ScheduleTask`);

    if (checkedValue === 'no') {
      newTask.ScheduleTask.PipelineSchedules = [];
    }

    const schedules = getValuesSchedule(`Channel.Pipeline.PipelineSchedule`);
    if (parameterValues.length > 0 && checkedValue === 'yes') {
      const newValues = schedules.map(function (item, index) {
        const values = checkParameters(index);

        const objectValues = values.reduce((acc, filterValue, indexValue) => {
          if (filterValue) {
            return { ...acc, [stageNames[indexValue]]: filterValue };
          } else {
            return { ...acc, ['=']: '=' };
          }
        }, {});
        const taskParameters = Object.keys(objectValues)

          .filter((key) => !key.includes('='))
          .reduce((obj, key) => {
            return Object.assign(obj, {
              [key]: objectValues[key],
            });
          }, {});

        if (Object.keys(taskParameters).length > 0) {
          return {
            ...item,
            TaskParameters: taskParameters,
          };
        } else {
          return {
            ...item,
            TaskParameters: null,
          };
        }
      });

      newTask.ScheduleTask.PipelineSchedules =
        newValues as unknown as PipelineScheduleInput[];
    }

    return newTask;
  }

  const editTask = () => {
    mutateEdit(
      {
        channel: mapTask() as unknown as CmScheduledTask,
      },
      {
        onSuccess: () => {
          queryClient.refetchQueries(['GetTask']);
          dispatch(
            addMessage({
              type: MessageType.Success,
              message: t('pages.manageChannel.updateTask'),
            })
          );
          setTask(taskObject);

          navigate(`/${module}/managescheduledtasks`);
        },
        onError: () => {
          dispatch(
            addMessage({
              type: MessageType.Error,
              message: t('components.message.networkerror'),
            })
          );
        },
      }
    );
  };

  const saveTask = () => {
    mutate(
      {
        channel: mapTask() as unknown as CmScheduledTask,
      },
      {
        onSuccess: () => {
          dispatch(
            addMessage({
              type: MessageType.Success,
              message: t('pages.manageChannel.successTask'),
            })
          );
          setTask(taskObject);

          navigate(`/${module}/managescheduledtasks`);
        },
        onError: () => {
          dispatch(
            addMessage({
              type: MessageType.Error,
              message: t('components.message.networkerror'),
            })
          );
        },
      }
    );
  };

  const publishAndCreateTask = () => {
    publishTask(
      {
        task: mapTask() as unknown as CmScheduledTask,
      },
      {
        onSuccess: () => {
          dispatch(
            addMessage({
              type: MessageType.Success,
              message: t('pages.manageChannel.successTask'),
            })
          );
          setTask(taskObject);

          navigate(`/${module}/managescheduledtasks`);
        },
        onError: () => {
          dispatch(
            addMessage({
              type: MessageType.Error,
              message: t('components.message.networkerror'),
            })
          );
        },
      }
    );
  };

  const updateAndCreateTask = () => {
    updateTask(
      {
        task: mapTask() as unknown as CmScheduledTask,
      },
      {
        onSuccess: () => {
          dispatch(
            addMessage({
              type: MessageType.Success,
              message: t('pages.manageChannel.updateTask'),
            })
          );
          setTask(taskObject);

          navigate(`/${module}/managescheduledtasks`);
        },
        onError: () => {
          dispatch(
            addMessage({
              type: MessageType.Error,
              message: t('components.message.networkerror'),
            })
          );
        },
      }
    );
  };

  return (!isLoading &&
    action === ActionType.CREATE &&
    !sinkLoading &&
    !publishLoading) ||
    (!editLoading &&
      !updateLoading &&
      action === ActionType.EDIT &&
      ((parameterValues.length > 0 && arrayTest.length > 0) ||
        (valueTask?.getTask.PipelineSchedules === null &&
          arrayTest.length > 0)) &&
      !getLoading) ||
    !runEdit ? (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ProgressLoader
            completed={completed}
            handleReset={handleReset}
            setCurrentStep={setCurrentStep}
            steps={[
              t('pages.manageChannel.taskDetails'),
              t('pages.manageChannel.scheduletaskStages'),
              t('pages.manageChannel.scheduleCreation'),
            ]}
            nonLinear={false}
            currentStep={currentStep}
            handleCurrentStep={(activeStep: number) => {
              setCurrentStep(activeStep);
            }}
            centerAlign
            handleSaveDraft={action === ActionType.EDIT ? editTask : saveTask}
            handleSave={
              action === ActionType.EDIT
                ? updateAndCreateTask
                : publishAndCreateTask
            }
            isCompleteStepBtnDisabled={
              !stepPassedValidation && !disablePublishTaskScheduled
            }
            isSaveAndCreateDisabled={!isChannelScheduleValid}
            draftButton={true}
            draftButtonText={t('pages.manageChannel.saveDraft')}
            saveBtnText={t('pages.manageChannel.publish')}
          >
            <form onSubmit={action === ActionType.EDIT ? editTask : saveTask}>
              {currentStep === 0 && (
                <ScheduledTasksDetails
                  setTaskDetailsValid={setTaskDetailsValid}
                  control={control}
                  watch={watch}
                  getValues={getValues}
                  setValue={setValue}
                  isLoading={sinkLoading}
                  setRunEdit={setRunEdit}
                />
              )}
              {currentStep === 1 && (
                <ScheduledTasksStages
                  setTaskStagesValid={setTaskStagesValid}
                  control={control}
                  watch={watch}
                  getValues={getValues}
                  setCurrentData={setCurrentData}
                  setValue={setValue}
                  taskSinkObject={taskSinkObject}
                  isLoading={sinkLoading}
                  setRunEdit={setRunEdit}
                  setData={setData}
                  parameterValues={parameterValues}
                  currentData={currentData}
                  setMasterList={setMasterList}
                  arrayTest={arrayTest}
                  setParameterValues={setParameterValues}
                />
              )}
              {currentStep === 2 && (
                <ManageConfigToolsProvider>
                  <ChannelSchedule
                    taskSchedule={true}
                    setChannelScheduleValid={setChannelScheduleValid}
                    checkedValue={checkedValue}
                    setCheckedValue={setCheckedValue}
                    currentData={currentData}
                    setCurrentData={setCurrentData}
                    parametersData={data}
                    setMasterList={setMasterList}
                    control={controlSchedule}
                    setValue={setValueSchedule}
                    masterList={masterList}
                    watch={watchSchedule}
                    setShowSchedule={setShowSchedule}
                    showSchedule={showSchedule}
                    stageNames={stageNames}
                    parameterValues={parameterValues}
                    setParameterValues={setParameterValues}
                  />
                </ManageConfigToolsProvider>
              )}
            </form>
          </ProgressLoader>
        </Grid>
      </Grid>
    </>
  ) : (
    <Loading />
  );
}

export default ScheduledTasksPage;
