import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import AttributeField from './AttributeField';
import { SectionProps } from '../WorkflowDetailsTab';
import { WorkflowFormObject } from '../../WorkflowEditor.types';

type VariantDetailsSectionProps = SectionProps;

const VariantDetailsSection = ({
  editDisabled,
  isEditMode,
  selectedSchema,
  group,
  groupIndex,
}: VariantDetailsSectionProps) => {
  const { t } = useTranslation();
  const { watch } = useFormContext<WorkflowFormObject>();

  const variantGroupCodeIndex = group.attribute.findIndex(
    (att) => att.attributeName === 'VariantGroupCode'
  );
  const workflowTypeName = watch(
    `workflowConfig.${groupIndex}.attribute.${variantGroupCodeIndex}.attributeValue`
  );

  const noEditableAttributes: string[] = ['VariantGroupCode'];

  return (
    <Grid spacing={3} container>
      {group.attribute.map((att, index) => {
        let customDisplayName = att.attributeDisplayName;
        // replace the name of the dynamic tags with the name of the work type
        if (att.attributeName === 'WorkflowVariantCode')
          customDisplayName = !workflowTypeName
            ? ` ${t(
                'pages.workflowEditor.variantDetailsLabels.workflowSubtype'
              )}`
            : `${workflowTypeName} ${t(
                'pages.workflowEditor.variantDetailsLabels.type'
              )}`;

        if (att.attributeName === 'WorkflowVariantDescription')
          customDisplayName = !workflowTypeName
            ? `${t(
                'pages.workflowEditor.variantDetailsLabels.workflowSubtypeDescription'
              )}`
            : t('pages.workflowEditor.variantDetailsLabels.description');

        return (
          <AttributeField
            key={`field-${group.groupName}-${att.attributeName}`}
            attribute={att}
            groupIndex={groupIndex}
            attributeIndex={index}
            customLabel={customDisplayName}
            schemaType={selectedSchema.workflowSchemaType}
            isDisabled={
              editDisabled ||
              (isEditMode && noEditableAttributes.includes(att.attributeName))
            }
          />
        );
      })}
    </Grid>
  );
};

export default VariantDetailsSection;
