import { gql } from 'graphql-request';

export const getTenatListQuery = gql`
  query ExampleQuery {
    getTenantsList {
      id
      tenantDomain
      tenantName
      tenantContact
      configurationStatus {
        name
        description
        type
      }
      serviceAdmin
      messages
      activationUrl
      creationDate
      createdBy
      lastModifiedDate
      lastModifiedBy
      modules {
        moduleId
        moduleName
      }
    }
  }
`;
