import { useEffect, useState } from 'react';

import {
  Button,
  Message,
  MessageActionType,
  MessageType,
} from '@revenue-solutions-inc/revxcoreui/material';
import Input from '@revenue-solutions-inc/revxcoreui/material/controls/Input';
import { urlExp, guidExp } from 'common/regexp';
import Loading from 'components/Loading';
import useCreateIdentityProvider from 'hooks/useCreateIdentityProvider';
import useGetAccessToken from 'hooks/useGetAccessToken';
import useMultiMaskInput, { MaskReturn } from 'hooks/useMultiMaskInput';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import Box from '@mui/system/Box';

const modalActions = {
  marginTop: '35px',
  width: '100%',
};

const actionsContainer = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

interface Props {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIdpDisplay: React.Dispatch<
    React.SetStateAction<{
      openIdMetadataUrl: string;
      clientId: string;
    }>
  >;
  idpDisplay?: {
    openIdMetadataUrl: string;
    clientId: string;
  };
}

export default function ModalFields({
  setIsOpen,
  setIdpDisplay,
  idpDisplay,
}: Props) {
  const accessToken = useGetAccessToken();
  const { id } = useParams() as { id: string };
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [tenantOpenIdMetadataUrl, setTenantOpenIdMetadataUrl] = useState<
    string | null
  >(null);
  const [tenantUrl, setTenantUrl] = useState<(string | null)[]>(['']);
  const [clientId, setClientId] = useState<string | null>(null);
  const [tenantClientId, setTenantClientId] = useState<(string | null)[]>(['']);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [identityProviderValues, setIdentityProviderValues] = useState({
    tenantId: id,
    tenantOpenIdMetadataUrl: idpDisplay ? idpDisplay.openIdMetadataUrl : '',
    clientId: idpDisplay
      ? idpDisplay?.clientId === '00000000-0000-0000-0000-000000000000'
        ? ''
        : idpDisplay?.clientId
      : '',
    clientSecret: '',
  });
  const [valid, setValid] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  const urlMask = useMultiMaskInput(
    '',
    t('pages.tenantView.errors.tenantOpenIdMetadataUrlRequired'),
    urlExp,
    tenantUrl
  );
  const guidIdMask = useMultiMaskInput(
    '',
    t('pages.tenantView.errors.clientIdRequired'),
    guidExp,
    tenantClientId
  );

  const handleHelperTextChange = (mask: MaskReturn, index: number) => {
    return mask.maskErrors[index];
  };
  const handleHelperTextURLChange = (prop: string | null) => {
    return prop == ''
      ? t('pages.tenantView.errors.tenantOpenIdMetadataUrlRequired')
      : handleHelperTextChange(urlMask, 0);
  };
  const handleHelperTextClientIdChange = (prop: string | null) => {
    return prop == ''
      ? t('pages.tenantView.errors.clientIdRequired')
      : handleHelperTextChange(guidIdMask, 0);
  };
  const handleHelperTextClientSecretChange = (prop: string | null) => {
    return prop == '' ? t('pages.tenantView.errors.clientSecretRequired') : '';
  };

  const {
    isSuccess,
    isError,
    error,
    isLoading,
    mutate: createIdentityProvider,
  } = useCreateIdentityProvider();

  const createUpdateIdentityProvider = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (event.currentTarget.id === 'message_stop') {
      setShowMessage(false);
    } else {
      setShowMessage(false);
      dispatch(
        addMessage({
          message: t('pages.tenantView.notifications.processing'),
          type: MessageType.Info,
          actionType: MessageActionType.None,
        })
      );
      if (!!accessToken && valid) {
        createIdentityProvider({
          token: accessToken,
          identityProvider: identityProviderValues,
        });
      }
    }
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        addMessage({
          message: t('pages.tenantView.notifications.success'),
          type: MessageType.Success,
          actionType: MessageActionType.None,
        })
      );
      setIdpDisplay({
        openIdMetadataUrl: identityProviderValues.tenantOpenIdMetadataUrl
          ? identityProviderValues.tenantOpenIdMetadataUrl
          : '',
        clientId: identityProviderValues.clientId
          ? identityProviderValues.clientId
          : '',
      });
      setIsOpen(false);
    }
  }, [
    isSuccess,
    dispatch,
    t,
    setIsOpen,
    setIdpDisplay,
    identityProviderValues,
  ]);

  useEffect(() => {
    if (
      urlMask.hasErrors ||
      guidIdMask.hasErrors ||
      identityProviderValues.tenantOpenIdMetadataUrl === '' ||
      identityProviderValues.clientId === '' ||
      identityProviderValues.clientSecret === ''
    )
      setValid(false);
    else {
      setValid(true);
    }
  }, [setValid, urlMask, guidIdMask, identityProviderValues]);

  useEffect(() => {
    if (isError) {
      if (error instanceof Error) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        error.message.includes('422')
          ? dispatch(
              addMessage({
                type: MessageType.Error,
                message: t('pages.tenantView.errors.invalidOpenIdMetadataUrl'),
              })
            )
          : dispatch(
              addMessage({
                type: MessageType.Error,
                message: t('components.message.networkerror'),
              })
            );
      }
    }
  }, [isError, dispatch, error, t]);

  return (
    <>
      {isLoading && <Loading />}
      {showMessage && (
        <Message
          type={MessageType.Info}
          message={t('pages.tenantView.notifications.continue')}
          actionType={MessageActionType.Continue}
          onActionClick={(event) => {
            createUpdateIdentityProvider(event);
          }}
        />
      )}
      <Input
        key="tenantOpenIdMetadataUrlInput"
        id="tenantOpenIdMetadataUrlInput"
        label={t('pages.tenantView.modal.tenantOpenIdMetadataUrl')}
        helperText={handleHelperTextURLChange(tenantOpenIdMetadataUrl)}
        required
        sx={isLoading ? { zIndex: -1 } : { zIndex: 0, width: '100%' }}
        value={identityProviderValues.tenantOpenIdMetadataUrl}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setTenantUrl([event.target.value]);
          setTenantOpenIdMetadataUrl(event.target.value);
          setIdentityProviderValues({
            ...identityProviderValues,
            tenantOpenIdMetadataUrl: event.target.value,
          });
        }}
      />
      <Input
        key="clientIdInput"
        id="clientIdInput"
        label={t('pages.tenantView.modal.clientId')}
        helperText={handleHelperTextClientIdChange(clientId)}
        required
        sx={isLoading ? { zIndex: -1 } : { zIndex: 0, width: '100%' }}
        value={identityProviderValues.clientId}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setTenantClientId([event.target.value]);
          setClientId(event.target.value);
          setIdentityProviderValues({
            ...identityProviderValues,
            clientId: event.target.value,
          });
        }}
      />
      <Input
        key="clientSecretInput"
        id="clientSecretInput"
        label={t('pages.tenantView.modal.clientSecret')}
        helperText={handleHelperTextClientSecretChange(clientSecret)}
        required
        sx={isLoading ? { zIndex: -1 } : { zIndex: 0, width: '100%' }}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setClientSecret(event.target.value);
          setIdentityProviderValues({
            ...identityProviderValues,
            clientSecret: event.target.value,
          });
        }}
      />
      <Box sx={modalActions}>
        <Box sx={actionsContainer}>
          <Button
            id="applyIdpChanges"
            variant="outlined"
            type="secondary"
            sx={
              isLoading
                ? { zIndex: -1, padding: '0 40px' }
                : { zIndex: 0, padding: '0 40px' }
            }
            onClick={() => setIsOpen(false)}
          >
            {t('pages.tenantView.modal.cancel')}
          </Button>
          <Button
            id="applyIdpChanges"
            variant="contained"
            sx={
              isLoading
                ? { zIndex: -1, padding: '0 40px' }
                : { zIndex: 0, padding: '0 40px' }
            }
            disabled={!valid}
            onClick={async () => {
              await setShowMessage(false);
              setShowMessage(true);
            }}
          >
            {t('pages.tenantView.modal.apply')}
          </Button>
        </Box>
      </Box>
    </>
  );
}
