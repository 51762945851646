import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Grid, Link, Typography } from '@mui/material';
import { MessageType, Dropzone } from '@revenue-solutions-inc/revxcoreui';
import useGetAccessToken from 'hooks/useGetAccessToken';
import useBulkTemplate from 'hooks/useGetBulkTemplate';
import useValidatebulkfile from 'hooks/useValidatebulkfile';
import { useTranslation } from 'react-i18next';
import { setHeader } from 'redux/contentSlice';
import { useAppDispatch } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import { ValidateBulkFileResponse } from 'types/roles';

import TableInfo from '../TableInfo';

interface Props {
  handleDone: (validateBulkFileResponse: ValidateBulkFileResponse[]) => void;
}

export default function UploadFile({ handleDone }: Props) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.bulkUserUpload',
  });
  const { t: p } = useTranslation();
  const accessToken = useGetAccessToken();
  const dispatch = useAppDispatch();
  const {
    isLoading,
    isSuccess,
    isError,
    data,
    mutate: upload,
  } = useValidatebulkfile();
  const [downloadBulkTemplate, setDownloadBulkTemplate] = useState(false);
  const [bulkUserFile, setBulkUserFile] = useState(new File([''], ''));
  const { isFetching } = useBulkTemplate(accessToken, downloadBulkTemplate);

  const textToList = useCallback((text: string) => {
    return text.split(',');
  }, []);

  const convertToList = useCallback(
    (value: string) => {
      return Object.values(
        t(value, { returnObjects: true }) as {
          [key: string]: string;
        }
      );
    },
    [t]
  );

  const steps = useMemo(() => {
    return convertToList('instructions.steps');
  }, [convertToList]);

  const correctData = useMemo(() => {
    const corrects = convertToList('instructions.example.correct');

    return corrects.map((correct) => ({ row: textToList(correct) }));
  }, [convertToList, textToList]);

  const incorrecData = useMemo(() => {
    return [
      {
        row: textToList(t('instructions.example.incorrect')),
      },
    ];
  }, [t, textToList]);

  const downloadBulkFile = () => {
    setDownloadBulkTemplate(true);
  };

  useEffect(() => {
    if (isFetching) setDownloadBulkTemplate(false);
  }, [isFetching]);

  useEffect(() => {
    if (isSuccess && data) handleDone(data);
  }, [data, isSuccess, handleDone]);

  useEffect(() => {
    if (isError) {
      if (bulkUserFile.type != 'text/csv') {
        dispatch(
          addMessage({
            type: MessageType.Error,
            message: p('components.message.typeError'),
          })
        );
      } else {
        dispatch(
          addMessage({
            type: MessageType.Error,
            message: p('components.message.networkerror'),
          })
        );
      }
    }
  }, [isError, dispatch, p, bulkUserFile]);

  useEffect(() => {
    dispatch(setHeader({ pageTitle: t('title') }));
  }, [dispatch, t]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} mb={1}>
        <Typography
          color="primary"
          sx={{
            mb: 2,
            fontWeight: 'bold',
            fontSize: 15,
          }}
        >
          {t('instructions.title')}
        </Typography>

        <Typography color="text.primary" sx={{ mb: 1 }}>
          {t('instructions.download')}
          <Link onClick={downloadBulkFile} sx={{ cursor: 'pointer' }}>
            {' '}
            {t('instructions.bulkFile')}
          </Link>
        </Typography>
        <Typography color="text.primary" sx={{ mb: 1 }}>
          {t('instructions.add')}
        </Typography>
        <Box sx={{ ml: '1.5em', mb: '1.5em' }}>
          {steps.map((step, key) => (
            <Typography color="text.primary" key={key} sx={{ mb: 1 }}>
              {step}
            </Typography>
          ))}
          <Typography
            color="text.primary"
            sx={{
              mb: 1,
              mt: '1.5em',
            }}
          >
            {t('instructions.example.title')}
          </Typography>
          <Box sx={{ ml: '1.5em' }}>
            <Typography
              color="text.primary"
              sx={{
                mb: 1,
                mt: '1.5em',
              }}
            >
              {t('instructions.correct')}
            </Typography>
            <TableInfo dataSource={correctData} />
            <Typography
              color="text.primary"
              sx={{
                mb: 1,
                mt: '1.5em',
              }}
            >
              {t('instructions.incorrect')}
            </Typography>
            <TableInfo dataSource={incorrecData} />
          </Box>
          <Box sx={{ mt: '1.5em' }}>
            <Typography color="text.primary" sx={{ mb: 1, mt: '1.5em' }}>
              {t('instructions.assistance')}
            </Typography>
          </Box>
        </Box>
        <Typography color="text.primary" sx={{ mb: 1 }}>
          {t('instructions.upload')}
        </Typography>

        <Dropzone
          id={'dropzone'}
          accessToken={accessToken}
          acceptedFiles={[{ 'text/csv': ['.csv'] }]}
          acceptFormat={'.csv'}
          maxFiles={1}
          noClick={true}
          noKeyboard={true}
          dropzoneWithButton={true}
          labelButton={'Upload'}
          setFile={setBulkUserFile}
          upload={upload}
          isLoading={isLoading}
          addStyles={{ backgroundColor: 'white' }}
        />
      </Grid>
    </Grid>
  );
}
