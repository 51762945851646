import { useState, useEffect } from 'react';
import { Typography, Tabs, Tab, Grid } from '@mui/material';
import { Box } from '@mui/system';
import { TabPanel } from '@revenue-solutions-inc/revxcoreui';
import {
  WorkflowSchemaVariantObject,
  WorkflowSchema,
  WorkflowSchemaVariant,
  Group,
} from 'generated/graphql';
import { UseFormTrigger, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { lowerFirstLetter } from 'utils/string-util';
import AttributeField from './sections/AttributeField';
import CustomAttributesSection from './sections/CustomAttributesSection';
import OtherAttributesSection from './sections/OtherAttributesSection';
import VariantDetailsSection from './sections/VariantDetailsSection';
import { WorkflowSteps } from '../StepConfigSection/WorkflowSteps';
import CustomTableAttributes from '../CustomTableAttributes/CustomTableAttributes';
import { WorkflowFormObject } from '../WorkflowEditor.types';

type WorkflowDetailsTabProps = {
  editDisabled: boolean;
  isEditMode?: boolean;
  selectedRecord?: WorkflowSchemaVariantObject;
  selectedSchema: WorkflowSchema;
  triggerValidation: UseFormTrigger<WorkflowFormObject>;
};

export type SectionProps = {
  group: Group;
  groupIndex: number;
  editDisabled: boolean;
  isEditMode?: boolean;
  selectedRecord?: WorkflowSchemaVariantObject;
  selectedSchema: WorkflowSchema;
  triggerValidation?: UseFormTrigger<WorkflowFormObject>;
};

export const GROUP_NAMES = {
  VariantDetails: 'VariantDetails',
  CustomAttributes: 'CustomAttributes',
  NonDesignGroup: 'NonDesignGroup',
  CloseReasonConfiguration: 'CloseReasonConfiguration',
  Tables: 'Tables',
  BankruptcyClaimType: 'BankruptcyClaimType',
  BankruptcyMilestoneType: 'BankruptcyMilestoneType',
} as const;

const CUSTOM_SECTION: {
  [key: string]: React.JSXElementConstructor<SectionProps>;
} = {
  [GROUP_NAMES.VariantDetails]: VariantDetailsSection,
  [GROUP_NAMES.CustomAttributes]: CustomAttributesSection,
  [GROUP_NAMES.Tables]: CustomAttributesSection,
  [GROUP_NAMES.CloseReasonConfiguration]: OtherAttributesSection,
  [GROUP_NAMES.BankruptcyClaimType]: OtherAttributesSection,
  [GROUP_NAMES.BankruptcyMilestoneType]: OtherAttributesSection,
};

type DetailsTabTitleProps = {
  isEditMode?: boolean;
  workflowVariant?: WorkflowSchemaVariant;
};

const DetailsTabTitle = ({
  isEditMode,
  workflowVariant,
}: DetailsTabTitleProps) => {
  const { watch } = useFormContext();

  const groups =
    workflowVariant?.platformConfigurationInfo?.configurationSection[0].group ??
    [];

  const detailsGroupIndex = groups.findIndex(
    (group) => group.groupName === GROUP_NAMES.VariantDetails
  );

  const workflowGroupCodeIndex = groups[detailsGroupIndex]?.attribute.findIndex(
    (att) => att.attributeName === 'VariantGroupCode'
  );
  const variantGroupCodeIndex = groups[detailsGroupIndex]?.attribute.findIndex(
    (att) => att.attributeName === 'WorkflowVariantCode'
  );

  const workflowTypeName = watch(
    `workflowConfig.${detailsGroupIndex}.attribute.${workflowGroupCodeIndex}.attributeValue`
  );
  const workflowVariantName = watch(
    `workflowConfig.${detailsGroupIndex}.attribute.${variantGroupCodeIndex}.attributeValue`
  );

  return (
    <Typography variant="h1">
      {isEditMode && workflowVariant
        ? `Edit ${workflowTypeName} - ${workflowVariantName}`
        : 'New record'}
    </Typography>
  );
};

const WorkflowDetailsTab = ({
  editDisabled,
  isEditMode = false,
  selectedRecord,
  selectedSchema,
  triggerValidation,
}: WorkflowDetailsTabProps) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const { t } = useTranslation();
  const [selectedWFFullObject, setSelectedWFFullObject] =
    useState<WorkflowSchemaVariant>();

  useEffect(() => {
    if (selectedSchema)
      setSelectedWFFullObject(JSON.parse(selectedSchema.workflowFullSchema));
  }, [selectedSchema]);

  const isStepsSectionEnabled = () =>
    selectedWFFullObject?.configurationName !== 'Notes';

  const isCustomTablesEnabled = () =>
    selectedSchema.workflowSchemaType === 'EntityWorkflowVariant';

  return (
    <Box
      sx={(theme) => {
        return {
          padding: 2,
          borderRadius: 2,
          background: theme.palette.white.main,
        };
      }}
    >
      <DetailsTabTitle
        isEditMode={isEditMode}
        workflowVariant={selectedWFFullObject}
      />
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={currentTabIndex}
          onChange={(_, index) => {
            setCurrentTabIndex(index);
          }}
        >
          {selectedWFFullObject?.platformConfigurationInfo?.configurationSection[0].group.map(
            (group, index) => {
              if (
                group.groupName === 'NonDesignGroup' ||
                group.attribute.length === 0 ||
                (group.groupName === GROUP_NAMES.Tables &&
                  isCustomTablesEnabled())
              )
                return null;
              return (
                <Tab
                  key={group.groupName}
                  label={t(
                    `pages.workflowEditor.groupTitles.${lowerFirstLetter(
                      group.groupName as string
                    )}`
                  )}
                  value={index}
                />
              );
            }
          )}
          {isCustomTablesEnabled() && (
            <Tab
              label={t('pages.workflowEditor.groupTitles.tables')}
              value={100}
            />
          )}
          {isStepsSectionEnabled() && (
            <Tab
              label={t('pages.workflowEditor.groupTitles.steps')}
              value={101}
            />
          )}
        </Tabs>
      </Box>

      {selectedWFFullObject?.platformConfigurationInfo?.configurationSection[0].group.map(
        (group, index) => {
          if (group.attribute.length === 0) {
            return null;
          }
          const CustomSection = CUSTOM_SECTION[`${group.groupName}`];
          return (
            <TabPanel
              key={`tab-${group.groupName}`}
              selectedIndex={currentTabIndex}
              index={index}
            >
              <Box sx={{ p: 2, pnx: 2 }}>
                {CustomSection ? (
                  <CustomSection
                    triggerValidation={triggerValidation}
                    group={group}
                    groupIndex={index}
                    editDisabled={editDisabled}
                    isEditMode={isEditMode}
                    selectedRecord={selectedRecord}
                    selectedSchema={selectedSchema}
                  />
                ) : (
                  <Grid spacing={3} container>
                    {group.attribute.map((att, attributeIndex) => (
                      <AttributeField
                        key={`field-${group.groupName}-${att.attributeName}`}
                        attribute={att}
                        groupIndex={index}
                        attributeIndex={attributeIndex}
                        schemaType={selectedSchema.workflowSchemaType}
                        isDisabled={editDisabled}
                      />
                    ))}
                  </Grid>
                )}
              </Box>
            </TabPanel>
          );
        }
      )}
      {isCustomTablesEnabled() && (
        <TabPanel selectedIndex={currentTabIndex} index={100}>
          <Box sx={{ p: '10px' }}>
            <Grid container>
              <CustomTableAttributes isEditMode={isEditMode} />
            </Grid>
          </Box>
        </TabPanel>
      )}
      {isStepsSectionEnabled() && (
        <TabPanel selectedIndex={currentTabIndex} index={101}>
          <Box sx={{ p: '10px' }}>
            <Grid container>
              <WorkflowSteps editDisabled={editDisabled} />
            </Grid>
          </Box>
        </TabPanel>
      )}
    </Box>
  );
};

export default WorkflowDetailsTab;
