import BallotIcon from '@mui/icons-material/Ballot';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Grid, Typography } from '@mui/material';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
} from '@revenue-solutions-inc/revxcoreui';
import Dropzone from '@revenue-solutions-inc/revxcoreui/material/controls/Dropzone';

import useGetAccessToken from 'hooks/useGetAccessToken';
import { UseMutateFunction } from '@tanstack/react-query';

interface UploadFileCommand {
  file: File;
  token: string;
}

interface Props {
  optionOne: string;
  titleOne: string;
  subTitleOne: string;
  createYourFileSubtitle: string;
  optionTwo: string;
  titleTwo: string;
  uploadedFile: File;
  subTitleTwo: string;
  isLink?: boolean;
  uploadTitle: string;
  uploadByDragFile: string;
  fileGroupBuilderOptions: string;
  setDisplayFields: React.Dispatch<React.SetStateAction<boolean>>;
  setUploadedFile: React.Dispatch<React.SetStateAction<File>>;
  setUploadFileChannel: React.Dispatch<React.SetStateAction<boolean>>;
  upload: UseMutateFunction<unknown, unknown, UploadFileCommand, unknown>;
  isLoading: boolean;
}

export const gridCustomStyle = () => {
  return {
    paddingTop: '0px !important',
    paddingLeft: '0px !important',
  };
};

function FileDefintion({
  fileGroupBuilderOptions,
  optionOne,
  titleOne,
  createYourFileSubtitle,
  optionTwo,
  titleTwo,
  upload,
  isLoading,
  uploadTitle,
  uploadByDragFile,
  setUploadedFile,
  setDisplayFields,
}: Props): JSX.Element {
  const accessToken = useGetAccessToken();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <Card
          sx={{
            borderRadius: '5px',
            boxShadow: '0px 0px 5px rgb(0 0 0 / 30%)',
            backgroundColor: 'transparent',
          }}
        >
          <CardHeader
            title={fileGroupBuilderOptions}
            sx={() => {
              return {
                backgroundColor: 'linkBlue.main',
                '& .MuiCardHeader-title': {
                  color: 'white.main',
                },
              };
            }}
          />
          <CardContent>
            <Grid container spacing={2} mt={0} mr={0.5} ml={0.5}>
              <Grid
                item
                md={3.5}
                sm={12}
                xs={12}
                mb={1}
                mr={0.5}
                pr={'16px'}
                sx={gridCustomStyle}
                textAlign="left"
                alignItems="center"
                display="block"
              >
                <Grid
                  item
                  xs={12}
                  md={12}
                  mb={3}
                  mt={0.5}
                  pr={'16px'}
                  display="flex"
                >
                  <BallotIcon />
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: 600,
                      marginTop: '0.5%',
                    }}
                  >
                    {optionOne}
                    <span style={{ marginLeft: '0.2rem' }}>{titleOne}</span>
                  </Typography>
                </Grid>
                <Grid container textAlign="center">
                  <Grid item xs={12} md={12}>
                    <Button
                      id="addGroupDetails"
                      onClick={() => {
                        setDisplayFields(true);
                      }}
                      sx={() => {
                        return {
                          backgroundColor: 'linkBlue.main',
                          '& .MuiButtonBase-root .MuiButton-root:hover': {
                            backgroundColor: 'primary.main',
                          },
                        };
                      }}
                    >
                      <Typography
                        variant="h4"
                        sx={{
                          fontWeight: 600,
                          color: 'white.main',
                        }}
                      >
                        {createYourFileSubtitle}
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                md={0.2}
                mr={1}
                sx={{
                  display: { xs: 'none', md: 'block', lg: 'block' },
                  borderLeft: '3px dashed',
                  borderColor: 'linkBlue.main',
                }}
              ></Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={7.8}
                sx={gridCustomStyle}
                pr={'16px'}
                textAlign="left"
                alignItems="center"
              >
                <Grid
                  item
                  xs={12}
                  md={12}
                  pr={'16px'}
                  pb={'10px'}
                  display="flex"
                >
                  <CloudUploadIcon />
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: 600,
                      marginLeft: '0.2rem',
                      marginTop: '0.2rem',
                    }}
                  >
                    {optionTwo}
                    <span style={{ marginLeft: '0.2rem' }}>{titleTwo}</span>
                  </Typography>
                </Grid>
                <Grid item xs={11.5} md={12}>
                  <Dropzone
                    id={'dropzone'}
                    accessToken={accessToken}
                    acceptedFiles={[{ 'application/xml': ['.xsd'] }]}
                    acceptFormat={'.xsd'}
                    maxFiles={1}
                    noClick={true}
                    noKeyboard={true}
                    dropzoneWithButton={true}
                    labelButton={uploadTitle}
                    label={uploadByDragFile}
                    isLoading={isLoading}
                    setFile={setUploadedFile}
                    sx={{ backgroundColor: 'linkBlue.main' }}
                    addStyles={{
                      padding: '15px 5px 15px 5px',
                      fontWeight: 900,
                      color: 'white.main',
                    }}
                    upload={upload}
                  />
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default FileDefintion;
