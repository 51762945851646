import { ValidationRule } from 'react-hook-form';

export interface BankAccountFormField {
  name:
    | 'ownership'
    | 'accountType'
    | 'routingNumber'
    | 'financialInstitution'
    | 'bankAccountNumber'
    | 'commenceDate'
    | 'ceaseDate'
    | 'isRelatedToActiveWorkItem'
    | 'allowForRefund'
    | 'allowForEFT'
    | 'reason';
  requiredRule?: string;
  defaultValue?: string;
  pattern?: ValidationRule<RegExp>;
  required: boolean;
  label: string;
  type: string;
  fieldIdentifier?: string;
}

export const fields: BankAccountFormField[] = [
  {
    name: 'ownership',
    requiredRule: 'pages.bankAccount.addBankAccount.ownership',
    pattern: {
      value: /^[a-zA-Z\s]*$/,
      message: 'Ownership must only contain letters',
    },
    required: true,
    label: 'pages.bankAccount.addBankAccount.ownership',
    type: 'input',
  },
  {
    name: 'accountType',
    required: true,
    label: 'pages.bankAccount.addBankAccount.accountType',
    type: 'select',
  },
  {
    name: 'routingNumber',
    requiredRule: 'pages.bankAccount.addBankAccount.routingNumber',
    pattern: {
      value: /^\d{9}$/,
      message: 'Routing Number must be 9 numeric digits long',
    },
    required: true,
    label: 'pages.bankAccount.addBankAccount.routingNumber',
    type: 'input',
  },
  {
    name: 'financialInstitution',
    requiredRule: 'pages.bankAccount.addBankAccount.financialInstitution',
    pattern: {
      value: /^[a-zA-Z\s]*$/,
      message: 'Financial Institution must only contain letters',
    },
    required: true,
    label: 'pages.bankAccount.addBankAccount.financialInstitution',
    type: 'input',
  },
  {
    name: 'bankAccountNumber',
    requiredRule: 'pages.bankAccount.addBankAccount.accountNumber',
    pattern: {
      value: /^\d{6,17}$/,
      message:
        'Bank Account Number must be between 6 and 17 numeric digits long',
    },
    required: true,
    label: 'pages.bankAccount.addBankAccount.accountNumber',
    type: 'input',
  },
  {
    name: 'commenceDate',
    requiredRule: 'pages.bankAccount.addBankAccount.commenceDate',
    defaultValue: '',
    required: true,
    label: 'pages.bankAccount.addBankAccount.commenceDate',
    type: 'date',
  },
  {
    name: 'ceaseDate',
    defaultValue: '',
    required: false,
    label: 'pages.bankAccount.addBankAccount.ceaseDate',
    type: 'date',
  },
  {
    name: 'reason',
    requiredRule: 'pages.bankAccount.addBankAccount.reason',
    pattern: {
      value: /^[a-zA-Z\s]*$/,
      message: 'Reason must only contain letters',
    },
    required: true,
    label: 'pages.bankAccount.addBankAccount.reason',
    type: 'input',
  },
  {
    name: 'isRelatedToActiveWorkItem',
    required: true,
    label: 'pages.bankAccount.addBankAccount.isRelatedToActiveWorkItem',
    type: 'radiogroup',
    fieldIdentifier: 'isRelatedToActiveWorkItem',
    requiredRule: 'pages.bankAccount.addBankAccount.isRelatedToActiveWorkItem',
  },
  {
    name: 'allowForRefund',
    required: true,
    label: 'pages.bankAccount.addBankAccount.allowForRefund',
    type: 'radiogroup',
    fieldIdentifier: 'allowForRefund',
    requiredRule: 'pages.bankAccount.addBankAccount.allowForRefund',
  },
  {
    name: 'allowForEFT',
    required: true,
    label: 'pages.bankAccount.addBankAccount.allowForEFT',
    type: 'radiogroup',
    fieldIdentifier: 'allowForEFT',
    requiredRule: 'pages.bankAccount.addBankAccount.allowForEFT',
  },
];
