import { useContext } from 'react';

import { Grid, Typography } from '@mui/material';
import {
  AssetTypes,
  findSection,
  sectionNames,
} from 'components/entityManagement/common/assetUtils';
import { getLabelAndValueByItem } from 'components/entityManagement/common/confirmationUtils';
import CardAvatar from 'components/entityManagement/common/CardAvatar';
import ExtendedAttributesCard from 'components/entityManagement/common/ExtendedAttributes/ExtendedAttributesCard';
import EntityManagementContext from 'components/contexts/EntityManagement';
import DataCard from 'components/DataCard';
import { AssetForm } from 'types/assets';
import { ExtendedAttributeValues, Section } from 'types/forms';

interface Props {
  data: AssetForm;
  extendedData?: ExtendedAttributeValues[];
}

function ConfirmationAsset({ data, extendedData }: Props): JSX.Element {
  const { identifiers, addresses, other, names } = data;
  const ctx = useContext(EntityManagementContext);

  const isSectionFilled = (sectionData: AssetTypes | undefined): boolean => {
    if (sectionData == undefined) return false;
    const sectionValues = Object.values(sectionData);
    const filledSection = sectionValues.find((value) => {
      return value !== '' && value !== 'true';
    });
    if (filledSection == undefined) return false;
    return true;
  };

  const displaySection = (
    sectionType: Section,
    sectionData: AssetTypes | undefined
  ): JSX.Element[] => {
    const sectionElements: JSX.Element[] = [];
    for (const attr in sectionData) {
      const itemInfo = getLabelAndValueByItem(
        sectionType,
        attr,
        ctx.idFormats,
        ctx.selectedCommenceDate,
        sectionData
      );
      if (itemInfo[0] !== '') {
        sectionElements.push(
          <Grid
            key={sectionType.sectionIdentifier + '-' + attr}
            sx={{ display: 'flex', flexDirection: 'column' }}
            item
            lg={4}
            md={6}
            sm={6}
          >
            <Typography
              variant="h5"
              id={sectionType.sectionIdentifier + '-' + attr + `-label`}
              component="label"
            >
              {itemInfo[0]}
            </Typography>
            <Typography
              variant="h3"
              id={sectionType.sectionIdentifier + '-' + attr + `-value`}
              sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
              {itemInfo[1]}
            </Typography>
          </Grid>
        );
      }
    }
    return sectionElements;
  };

  const displayCards = (type: string): JSX.Element[] => {
    let groups;
    const fields = findSection(type);
    switch (type) {
      case 'names':
        groups = names;
        break;
      case 'identifiers':
        groups = identifiers;
        break;
      case 'addresses':
        groups = addresses;
        break;
      case 'other':
        groups = other;
        break;
    }
    const cards: JSX.Element[] = [];
    if (!fields || !groups) return cards;
    groups.forEach((group, index) => {
      if (isSectionFilled(group)) {
        cards.push(
          <Grid
            key={`${fields.sectionIdentifier}-${index}-grid`}
            sx={{ display: 'flex' }}
            item
            md={4}
            sm={6}
            order={{ xs: 2, sm: 1, md: -1 }}
          >
            <DataCard
              title={fields.sectionTitle}
              avatar={<CardAvatar sectionName={fields.sectionIdentifier} />}
              children={
                <Grid container spacing={2}>
                  {displaySection(fields, group)}
                </Grid>
              }
            />
          </Grid>
        );
      }
    });
    return cards;
  };

  return (
    <Grid container spacing={3}>
      {sectionNames.map((sectionName) => {
        return displayCards(sectionName);
      })}
      <ExtendedAttributesCard extendedData={extendedData ?? []} />
    </Grid>
  );
}

export default ConfirmationAsset;
