import { useState, useEffect, useMemo, useCallback } from 'react';
import { Box, Grid, SelectChangeEvent } from '@mui/material';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import DataDisplay from '@revenue-solutions-inc/revxcoreui/material/controls/DataDisplay';
import Dialog from '@revenue-solutions-inc/revxcoreui/material/controls/Dialog';
import Input from '@revenue-solutions-inc/revxcoreui/material/controls/Input';
import ListBox from '@revenue-solutions-inc/revxcoreui/material/controls/ListBox';
import Select, {
  SelectType,
} from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import DataCard from 'components/DataCard';
import {
  GetRcTypeQuery,
  ModuleResponse,
  RolesByModuleResponse,
  useCorrespondenceTypeCategoryQuery,
  useGetCorrespondenceTypeQuery,
  useGetWorkflowGroupVariantsQuery,
  useQueryPlatformOrModuleQuery,
  useRolesByModuleQuery,
  UserRoleAssignmentsResponse,
  useUpdateCorrespondenceTypeMutation,
  useUpdateRcTypeMutation,
} from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  MessageType,
  MessageActionType,
  Checkbox,
} from '@revenue-solutions-inc/revxcoreui';
import { addMessage } from 'redux/messageSlice';
import { ColumnDef } from '@tanstack/react-table';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import SelectRole from 'components/SelectRole';
import extractMeaningfulMessage from 'utils/errorMessage';
import Loading from 'components/Loading';
import { useNavigate } from 'react-router-dom';

type Props = {
  isFromTemplate: boolean;
  rolesData: RolesByModuleResponse[];
  correspondenceId: number;
  rcId: number;
  rcType: GetRcTypeQuery | undefined;
  reFetch: () => void;
  refetchRcType: () => void;
};
const defaultUserRole: UserRoleAssignmentsResponse = {
  roleId: '',
  canAddFlag: false,
};
const defaultUserRoles: UserRoleAssignmentsResponse[] = [
  {
    roleId: '',
    canAddFlag: false,
  },
];
const defaultUserRoles1: UserRoleAssignmentsResponse[] = [
  {
    roleId: '',
    canAddFlag: false,
  },
];

const defaultRoles: RolesByModuleResponse[] = [
  {
    endDate: '',
    moduleId: 1,
    moduleName: '',
    roleDescription: '',
    roleId: '',
    roleName: '',
    isRsiOnly: false,
    isOOTB: false,
    startDate: '',
    statusId: '',
    policyGroups: [],
  },
];
function TopSection(dataProps: Props) {
  const { data: workflowData } = useGetWorkflowGroupVariantsQuery();
  const [category, setCategory] = useState<string>('');
  const [selectedRole, setSelectedRole] = useState<string>('');
  const [corrTypeName, setCorrTypeName] = useState<string>('');
  const [contextLevel, setContextLevel] = useState<string>('');
  const [workflow, setWorkflow] = useState({
    workflowGrp: 0,
    workflowSubType: 0,
  });
  const [certifiedFlag, setCertifiedFlag] = useState<boolean>(false);
  const [ftiFlag, setFtiFlag] = useState<boolean>(false);
  const [rcTypeName, setRcTypeName] = useState<string>('');
  const [rolesAllData, setRolesAllData] = useState<RolesByModuleResponse[]>([]);
  const [moduleId, setModuleId] = useState<string>('1');
  const [modules, setModules] = useState<ModuleResponse[]>([]);
  const [isManageRole, setIsManageRole] = useState<boolean>(false);
  const [selectedRolePermission, setSelectedRolePermission] =
    useState<UserRoleAssignmentsResponse>(defaultUserRole);
  const [userRolesInitialCopy, setUserRolesInitialCopy] = useState({
    initialData: defaultUserRoles,
  });
  const [userRolesRunning, setUserRolesRunning] = useState({
    runningData: defaultUserRoles1,
  });
  const module = useAppSelector((state) => state.user.module);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { mutate: updateCorrespondenceType } =
    useUpdateCorrespondenceTypeMutation();
  const { data: corrTypeCategories, refetch: refetchCorrTypeCategories } =
    useCorrespondenceTypeCategoryQuery({}, { enabled: false, cacheTime: 0 });
  const { data: CorrespondenceType, refetch: refetchCorrespondenceType } =
    useGetCorrespondenceTypeQuery(
      {
        getCorrespondenceTypeId: dataProps.correspondenceId,
      },
      { enabled: false }
    );
  const [roles, setRoles] = useState({
    rolesAssigned: defaultRoles,
  });
  const { mutate: updateRcType } = useUpdateRcTypeMutation();
  const { isLoading: isLoadingRolesByModule } = useRolesByModuleQuery<{
    RolesByModule: RolesByModuleResponse[];
  }>(
    {
      moduleId: moduleId,
    },
    {
      enabled: moduleId !== '',
      onSuccess: (data) => {
        if (data?.RolesByModule) {
          const newRolesAllData: RolesByModuleResponse[] =
            data.RolesByModule.map((role) => {
              return {
                ...role,
              };
            });
          setRolesAllData(newRolesAllData);
        }
      },
      onError: (error) => {
        const message = extractMeaningfulMessage(
          error,
          t('pages.manageReusableContent.networkError')
        );
        dispatch(
          addMessage({
            message: message,
            type: MessageType.Error,
            actionType: MessageActionType.None,
          })
        );
      },
    }
  );
  const { isLoading: isLoadingModuleList } = useQueryPlatformOrModuleQuery<{
    Modules: [ModuleResponse];
  }>(
    {},
    {
      enabled: true,
      onSuccess: (data) => {
        if (data?.Modules) {
          setModuleId(`${data.Modules[0].moduleId}`);
          setModules(data.Modules);
        }
      },
      onError: (error) => {
        const message = extractMeaningfulMessage(
          error,
          t('pages.manageReusableContent.networkError')
        );
        dispatch(
          addMessage({
            message: message,
            type: MessageType.Error,
            actionType: MessageActionType.None,
          })
        );
      },
    }
  );

  const typeCategory: SelectType[] = useMemo(() => {
    const temp: SelectType[] = [];
    corrTypeCategories?.CorrespondenceTypeCategory.map(
      (cat: { correspondenceTypeCategoryId: string; name: string }) => {
        temp.push({
          key: cat.correspondenceTypeCategoryId,
          desc: cat.name,
        });
      }
    );
    return temp;
  }, [corrTypeCategories]);

  const letterPermissionOptions: SelectType[] = [
    { key: '0', desc: 'Add & View Letter' },
    { key: '1', desc: 'View Letter' },
  ];

  const handleSelectValue = useCallback(
    (roleId: string) => {
      if (
        userRolesRunning.runningData?.filter((item) => item.roleId === roleId)
          .length > 0
      ) {
        if (
          userRolesRunning.runningData?.filter((item) => item.roleId === roleId)
            .length > 0 &&
          selectedRolePermission.roleId === roleId &&
          selectedRole == ''
        ) {
          return '';
        } else if (
          userRolesRunning.runningData?.filter(
            (item) => item.roleId === roleId
          )[0].canAddFlag
        )
          return '0';
        else return '1';
      } else return '';
    },
    [selectedRole, selectedRolePermission.roleId, userRolesRunning.runningData]
  );

  const commonColumns: ColumnDef<RolesByModuleResponse>[] = [
    {
      header: () => <HeaderColumnNext localization={t('pages.roles.name')} />,
      accessorKey: 'roleName',
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.roles.permission')} />
      ),
      id: 'content-edit',
      cell: ({ row }) => (
        <Select
          options={letterPermissionOptions}
          id="letterPermission"
          data-testid="content-select"
          label={''}
          value={handleSelectValue(row.original.roleId)}
          sx={{ width: '15em' }}
          onChange={(event: SelectChangeEvent<string | number>) => {
            setSelectedRolePermission({
              roleId: row.original.roleId,
              canAddFlag: event.target.value === '0' ? true : false,
            });
            if (event.target.value == '') {
              setUserRolesRunning((prev) => ({
                runningData: prev.runningData.filter(
                  (item) => item.roleId !== row.original.roleId
                ),
              }));
            }
            setSelectedRole(event.target.value as string);
          }}
        />
      ),
    },
  ];

  const subTypeHandler = () => {
    if (workflow?.workflowSubType == -1) {
      return 'All';
    } else if (workflow?.workflowSubType) {
      return (
        workflowData?.GetWorkflowGroupVariants.filter(
          (grup) => String(grup.id) === String(workflow.workflowGrp)
        )[0]?.variants.filter(
          (k) => String(k.id) === String(workflow.workflowSubType)
        )[0]?.shortDescription ?? '--'
      );
    }
    return '--';
  };
  const onSelectModule = (newModuleId: string) => {
    if (newModuleId && newModuleId.length > 0) setModuleId(newModuleId);
  };
  const ManageRoleDialogNew = (
    <>
      {(isLoadingModuleList || isLoadingRolesByModule) && <Loading />}
      {rolesAllData.length > 0 && (
        <DefaultDataTableNext
          columns={commonColumns as ColumnDef<Record<string, unknown>>[]}
          data={rolesAllData}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'end',
          }}
          customHeader={
            <SelectRole
              showAll={false}
              moduleList={modules}
              handleChange={onSelectModule}
              selectedRole={moduleId}
            />
          }
        />
      )}
      <Grid sx={{ display: 'flex', justifyContent: 'space-around', mt: 2 }}>
        <Button
          id={'cancel'}
          sx={{ minWidth: '128px' }}
          type="secondary"
          onClick={() => {
            setSelectedRolePermission(defaultUserRole);
            setIsManageRole(false);
            setUserRolesRunning({
              runningData: userRolesInitialCopy.initialData,
            });
          }}
        >
          {t('pages.correspondence.cancel')}
        </Button>
        <Button
          id="btn_import"
          sx={{ ml: 2, minWidth: '128px' }}
          onClick={() => {
            setSelectedRolePermission(defaultUserRole);
            setIsManageRole(false);
            setRoles({
              rolesAssigned:
                dataProps.rolesData.filter((item) =>
                  userRolesRunning.runningData?.some(
                    (assigned) => assigned.roleId === item.roleId
                  )
                ) ?? [],
            });
            setUserRolesInitialCopy({
              initialData: userRolesRunning.runningData,
            });
          }}
        >
          {t('pages.manageReusableContent.buttons.save')}
        </Button>
      </Grid>
    </>
  );

  const handleCorresTypeUpdate = () => {
    if (dataProps.isFromTemplate) {
      updateCorrespondenceType(
        {
          correspondence: {
            correspondenceTypeId: dataProps.correspondenceId,
            correspondenceTypeCategoryId: category ? parseInt(category) : 1,
            userRoleAssignments: userRolesRunning.runningData,
            name: corrTypeName.replace(/\s+/g, ' ').trim(),
            certifiedMailFlag: certifiedFlag,
            ftiFlag: ftiFlag,
          },
        },
        {
          onSuccess: () => {
            dataProps.reFetch();
            dispatch(
              addMessage({
                message: t(
                  'pages.correspondence.message.updateCorrespondenceType'
                ),
                type: MessageType.Success,
                actionType: MessageActionType.None,
              })
            );
          },
          onError: () => {
            if (!corrTypeName || corrTypeName.trim().length < 1) {
              dispatch(
                addMessage({
                  message: t('pages.correspondence.message.typeNameRequired'),
                  type: MessageType.Error,
                  actionType: MessageActionType.None,
                })
              );
            } else if (!category || category.length < 1) {
              dispatch(
                addMessage({
                  message: t('pages.correspondence.message.errorMessage'),
                  type: MessageType.Error,
                  actionType: MessageActionType.None,
                })
              );
            } else if (corrTypeName || corrTypeName.length > 0) {
              dispatch(
                addMessage({
                  message: t('pages.correspondence.message.typeNameUnique'),
                  type: MessageType.Error,
                  actionType: MessageActionType.None,
                })
              );
            } else {
              dispatch(
                addMessage({
                  message: t('pages.manageReusableContent.networkError'),
                  type: MessageType.Error,
                  actionType: MessageActionType.None,
                })
              );
            }
          },
        }
      );
    } else {
      updateRcType(
        {
          rc: {
            reusableContentTypeId: dataProps.rcId,
            name: rcTypeName,
          },
        },
        {
          onSuccess: () => {
            dataProps.reFetch();
            dispatch(
              addMessage({
                message: t('pages.reusableContent.message.updateRCType'),
                type: MessageType.Success,
                actionType: MessageActionType.None,
              })
            );
          },
          onError: () => {
            dispatch(
              addMessage({
                message: t('pages.reusableContent.message.errorMessage'),
                type: MessageType.Error,
                actionType: MessageActionType.None,
              })
            );
          },
        }
      );
    }
  };

  useEffect(() => {
    if (dataProps.isFromTemplate) {
      refetchCorrespondenceType();
      refetchCorrTypeCategories();
    } else {
      dataProps.refetchRcType();
    }
  }, [dataProps, refetchCorrTypeCategories, refetchCorrespondenceType]);
  useEffect(() => {
    if (dataProps.rcType?.GetRcType) {
      setRcTypeName(dataProps.rcType?.GetRcType.name);
    }
  }, [dataProps.rcType, dataProps.rcType?.GetRcType]);

  useEffect(() => {
    if (dataProps.rolesData && dataProps.rolesData.length > 0) {
      setRoles({
        rolesAssigned:
          dataProps.rolesData.filter((item) =>
            CorrespondenceType?.GetCorrespondenceType.userRoleAssignments?.some(
              (assigned) => assigned.roleId === item.roleId
            )
          ) ?? [],
      });
    }
  }, [
    dataProps.rolesData,
    CorrespondenceType?.GetCorrespondenceType.userRoleAssignments,
  ]);

  useEffect(() => {
    setCategory(
      String(
        CorrespondenceType?.GetCorrespondenceType.correspondenceTypeCategoryId
      ) ?? ''
    );
    setCorrTypeName(CorrespondenceType?.GetCorrespondenceType.name ?? '');
    setCertifiedFlag(
      CorrespondenceType?.GetCorrespondenceType.certifiedMailFlag ?? false
    );
    setFtiFlag(CorrespondenceType?.GetCorrespondenceType.ftiFlag ?? false);
    setContextLevel(
      CorrespondenceType?.GetCorrespondenceType.contextLevel ?? ''
    );
    setWorkflow({
      workflowGrp:
        CorrespondenceType?.GetCorrespondenceType.workflowTypeId ?? 0,
      workflowSubType:
        CorrespondenceType?.GetCorrespondenceType.workflowSubTypeId ?? 0,
    });
    setUserRolesInitialCopy({
      initialData: [
        ...(CorrespondenceType?.GetCorrespondenceType.userRoleAssignments ??
          defaultUserRoles),
      ],
    });
    setUserRolesRunning({
      runningData: [
        ...(CorrespondenceType?.GetCorrespondenceType.userRoleAssignments ??
          defaultUserRoles),
      ],
    });
  }, [CorrespondenceType, CorrespondenceType?.GetCorrespondenceType]);

  useEffect(() => {
    if (
      selectedRolePermission.roleId &&
      selectedRolePermission.roleId.length > 0
    ) {
      const isUpdatingExistedRole = userRolesRunning.runningData.find(
        (item) => item.roleId === selectedRolePermission.roleId
      );
      if (isUpdatingExistedRole) {
        setUserRolesRunning((prev) => {
          prev.runningData.filter(
            (item) => item.roleId === selectedRolePermission.roleId
          )[0].canAddFlag = selectedRolePermission.canAddFlag;
          return prev;
        });
      } else if (selectedRole !== '')
        setUserRolesRunning((prev) => ({
          runningData: [...prev.runningData, selectedRolePermission],
        }));
    }
  }, [selectedRole, selectedRolePermission, userRolesRunning.runningData]);

  return (
    <>
      <Dialog
        id="manageRoles"
        open={isManageRole}
        children={ManageRoleDialogNew}
        title={t('pages.correspondence.roleManagement')}
        width={730}
        height={500}
        handleClose={() => {
          setSelectedRolePermission(defaultUserRole);
          setIsManageRole(false);
          setUserRolesRunning({
            runningData: userRolesInitialCopy.initialData,
          });
        }}
      />

      <Grid container spacing={2}>
        {dataProps.isFromTemplate && (
          <>
            <Grid sx={{ display: 'flex' }} item xs={12} md={5}>
              <DataCard
                title={t('pages.correspondence.type')}
                avatar={<></>}
                children={
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <DataDisplay
                        id={'typeId'}
                        label={t('pages.correspondence.typeId')}
                        data={
                          CorrespondenceType?.GetCorrespondenceType
                            .correspondenceTypeDisplayId ?? ''
                        }
                      />

                      <DataDisplay
                        id={'typeName'}
                        label={t('pages.correspondence.typeName')}
                        data={''}
                        sxLabel={{ display: 'inline-block', mt: 1 }}
                      />
                      <Input
                        id="corrTypeName"
                        ariaLabel="correspondence type name"
                        value={corrTypeName}
                        placeholder="Correspondence type name"
                        sx={{ width: '15em' }}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          if (event.target.value.length < 51)
                            setCorrTypeName(event.target.value);
                        }}
                      />

                      <DataDisplay
                        id={'typeCategory'}
                        label={t('pages.correspondence.typeCategory')}
                        sxLabel={{ display: 'inline-block', mt: 1 }}
                        data={''}
                      />

                      <Select
                        options={typeCategory}
                        id="typeCategory"
                        data-testid="content-select"
                        label={''}
                        value={category}
                        sx={{ width: '15em' }}
                        onChange={(
                          event: SelectChangeEvent<string | number>
                        ) => {
                          setCategory(event.target.value as string);
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <DataDisplay
                        id={'typeName'}
                        label={t('pages.correspondence.contextLevel')}
                        data={contextLevel}
                        sxLabel={{ display: 'inline-block' }}
                      />
                      {workflow.workflowGrp ? (
                        <>
                          <DataDisplay
                            id={'WfTypeName'}
                            label={t('pages.correspondence.workflow.type')}
                            data={
                              workflow.workflowGrp
                                ? workflowData?.GetWorkflowGroupVariants.filter(
                                    (grup) =>
                                      String(grup.id) ===
                                      String(workflow.workflowGrp)
                                  )[0]?.shortDescription ?? '--'
                                : '--'
                            }
                            sxLabel={{ display: 'inline-block', mt: 1 }}
                          />
                          <DataDisplay
                            id={'WfSubTypeName'}
                            label={t('pages.correspondence.workflow.subType')}
                            data={subTypeHandler()}
                            sxLabel={{ display: 'inline-block', mt: 1 }}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                      <Checkbox
                        id="ftiFlag"
                        checked={ftiFlag}
                        onChange={(event) => {
                          setFtiFlag(event.target.checked);
                        }}
                        label={t('pages.correspondence.ftiFlag')}
                        sx={{
                          '.MuiFormControlLabel-root > p': { fontWeight: 600 },
                          mt: 0.5,
                        }}
                      />
                      <Checkbox
                        id="certifiedMailFlag"
                        checked={certifiedFlag}
                        onChange={(event) => {
                          setCertifiedFlag(event.target.checked);
                        }}
                        label={t('pages.correspondence.certifiedFlag')}
                        sx={{
                          '.MuiFormControlLabel-root > p': { fontWeight: 600 },
                          mt: 0.5,
                        }}
                      />
                    </Grid>
                  </Grid>
                }
              />
            </Grid>
            <Grid sx={{ display: 'flex' }} item xs={12} md={3}>
              <DataCard
                title={t('pages.correspondence.rolesAssociated')}
                avatar={<></>}
                children={
                  <>
                    <ListBox
                      id="roles-assigned"
                      options={
                        dataProps?.rolesData?.length > 0
                          ? roles.rolesAssigned.map((item) => item.roleName)
                          : []
                      }
                      onChange={() => {}}
                      sx={{
                        minHeight: '9rem',
                        maxHeight: '12rem',
                        overflowX: 'hidden',
                        overflowY: 'auto',
                      }}
                    />
                    <Button
                      id="saveBtn-updateContent"
                      data-testid="update-button"
                      sx={{ mt: 1, mb: 1, minWidth: '128px' }}
                      type="secondary"
                      onClick={() => setIsManageRole(true)}
                    >
                      {t('pages.correspondence.manageRoles')}
                    </Button>
                  </>
                }
              />
            </Grid>
          </>
        )}
        {!dataProps.isFromTemplate && (
          <Grid item xs={3}>
            <DataCard
              title={t('pages.correspondence.type')}
              avatar={<></>}
              children={
                <>
                  <DataDisplay
                    id={'typeId'}
                    label={t('pages.reusableContent.typeId')}
                    data={
                      dataProps.rcType?.GetRcType
                        .reusableContentTypeDisplayId ?? ''
                    }
                  />
                  <DataDisplay
                    id={'rcTypeName'}
                    label={t('pages.reusableContent.typeName')}
                    sxLabel={{ display: 'inline-block', mt: 2 }}
                    data={''}
                  />
                  <Input
                    id="rcTypeName"
                    ariaLabel="reusable content type name"
                    value={rcTypeName}
                    placeholder="Rc name"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      if (event.target.value.length < 51)
                        setRcTypeName(event.target.value);
                    }}
                  />
                  <DataDisplay
                    id={'typeCategory'}
                    label={t('pages.reusableContent.rctypeCategory')}
                    sxLabel={{ display: 'inline-block', mt: 1 }}
                    data={''}
                  />
                  {dataProps.rcType?.GetRcType?.reusableContentTypeCategory}
                </>
              }
            />
          </Grid>
        )}
        <Grid
          item
          xs={12}
          md={2}
          sx={{
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'flex-end',
          }}
        >
          <Box>
            <Button
              id="cancelBtn"
              data-testid="update-button"
              type="secondary"
              sx={{ mt: 1, mb: 1, ml: 1, minWidth: '128px' }}
              onClick={() =>
                dataProps.isFromTemplate
                  ? navigate(`/${module}/correspondenceTypes`)
                  : navigate(`/${module}/reusableContents`)
              }
            >
              {t('pages.correspondence.cancel')}
            </Button>
            <Button
              id="saveBtn-updateContent"
              data-testid="update-button"
              sx={{ mt: 1, mb: 1, ml: 1, minWidth: '128px' }}
              onClick={() => handleCorresTypeUpdate()}
            >
              {t('pages.correspondence.save')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default TopSection;
