import { Address, Contact, Name, contactDefault, nameDefault } from './contact';

export interface ExtendedLegalDescription {
  abstract?: 'string';
  additionalDescription?: 'string';
  block?: 'string';
  lot?: 'string';
  neighborhood?: 'string';
}

export enum AreaValue {
  City = 'city',
  State = 'state',
  County = 'county',
}

export interface PropertyOwner {
  id?: string;
  name: Name;
  addresses: Address[] | null;
  phoneNumbers?: Contact[] | null;
  emailAddresses?: Contact[] | null;
  propertyOwnerId: string;
}

export interface Ownership {
  name: string;
  ownershipGroupId?: string;
  propertyOwnerId: string;
  percentage: number;
}

export interface Property {
  id?: string;
  type: string;
  addresses: Address[];
  extendedLegalDescription?: ExtendedLegalDescription;
  geographicalId?: string;
  propertyId: string;
  legalDescription: string;
  ownership: Ownership[];
  subtype: string;
  situsAddress?: Address;
}

const propertyDefault: Property = {
  addresses: [],
  ownership: [{ name: '', propertyOwnerId: '', percentage: 100 }],
  propertyId: '',
  type: '',
  subtype: '',
  legalDescription: '',
  id: '',
};

const propertyOwnerDefault: PropertyOwner = {
  id: '',
  addresses: [],
  phoneNumbers: [contactDefault],
  emailAddresses: [contactDefault],
  name: nameDefault,
  propertyOwnerId: '',
};

export { propertyOwnerDefault, propertyDefault };
