import { BusinessDrivers } from 'common/platformConfigUtils/platformConfigUtils';
import { Attribute } from 'generated/graphql';

export function useAttributeValidations() {
  const checkIsRequired = (attr: Attribute) =>
    attr?.extensibleBusinessDriver?.some((driver) =>
      [BusinessDrivers.Required, BusinessDrivers.PrimaryKey].includes(
        driver.driverNameType as BusinessDrivers
      )
    );

  const checkIsPK = (attr: Attribute) => {
    // check if there is a business driver of type pk
    return attr.extensibleBusinessDriver?.some(
      (driver) => driver.driverNameType === BusinessDrivers.PrimaryKey
    );
  };

  return {
    checkIsRequired,
    checkIsPK,
  };
}
