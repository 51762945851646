import { useEffect, useState } from 'react';

import { Grid, Typography } from '@mui/material';
import DatePicker from '@revenue-solutions-inc/revxcoreui/material/controls/DatePicker';
import Input from '@revenue-solutions-inc/revxcoreui/material/controls/Input';
import Loading from 'components/Loading';
import { useTranslation } from 'react-i18next';
import { Name } from 'types/contact';
import { TaxingAuthority } from 'types/taxingAuthority/entities';

interface Props {
  taxingAuthority: TaxingAuthority;
  setTaxingAuthority: (arg1: TaxingAuthority) => void;
  setTaxingAuthorityDetailsValid: (valid: boolean) => void;
}

function TaxingAuthorityDetails({
  setTaxingAuthority,
  taxingAuthority,
  setTaxingAuthorityDetailsValid,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const [taxingAuthorityExists] = useState(false);

  const validateTaxingAuthority = () => {
    let isValid = true;
    if (!taxingAuthority.taxingAuthorityId) isValid = false;
    if (!taxingAuthority.names[0].value) isValid = false;

    return isValid;
  };

  useEffect(() => {
    const isValid = validateTaxingAuthority();
    setTaxingAuthorityDetailsValid(isValid);
  });

  return (
    <>
      {<Loading />}
      {
        <>
          <Typography variant="h2">
            {t('pages.manageTaxingAuthority.taxAuthorityDetails')}
          </Typography>
          <Grid item xs={6} mt={2}>
            <Input
              required
              id="taxingAuthority"
              data-testid="taxing-authority"
              label={t('pages.manageTaxingAuthority.taxingAuthorityId')}
              inputProps={{
                maxLength: 30,
              }}
              value={taxingAuthority?.taxingAuthorityId ?? ''}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setTaxingAuthority({
                  ...taxingAuthority,
                  taxingAuthorityId: event.target.value as string,
                });
              }}
              error={taxingAuthorityExists}
              helperText={''}
            />
            <Grid item mt={2}>
              <Input
                required
                id="name"
                label={t('pages.manageTaxingAuthority.name')}
                value={taxingAuthority?.names[0]?.value ?? ''}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setTaxingAuthority({
                    ...taxingAuthority,
                    names: [
                      {
                        value: event.target.value,
                        family: '',
                        given: '',
                      },
                    ] as Name[],
                  });
                }}
              />
            </Grid>
            <Grid item mt={2}></Grid>
          </Grid>
          <Grid container spacing={2} columns={14}>
            <Grid item mt={2} xs={2}>
              <DatePicker
                id="fiscalBeginDate"
                label={t('pages.manageTaxingAuthority.fiscalBeginDate')}
                value={
                  taxingAuthority.fiscalDates?.length
                    ? taxingAuthority.fiscalDates[0].beginDate
                    : null
                }
                handleChange={(date: Date | null) => {
                  if (taxingAuthority.fiscalDates) {
                    const newFiscalDates = [...taxingAuthority.fiscalDates];
                    newFiscalDates[0].beginDate = date;
                    setTaxingAuthority({
                      ...taxingAuthority,
                      fiscalDates: newFiscalDates,
                    });
                  }
                }}
              />
            </Grid>
            <Grid item mt={2} xs={4}>
              <DatePicker
                id="fiscalEndDate"
                label={t('pages.manageTaxingAuthority.fiscalEndDate')}
                value={
                  taxingAuthority.fiscalDates?.length
                    ? taxingAuthority.fiscalDates[0].endDate
                    : null
                }
                handleChange={(date: Date | null) => {
                  if (taxingAuthority.fiscalDates) {
                    const newFiscalDates = [...taxingAuthority.fiscalDates];
                    newFiscalDates[0].endDate = date;
                    setTaxingAuthority({
                      ...taxingAuthority,
                      fiscalDates: newFiscalDates,
                    });
                  }
                }}
              />
            </Grid>
          </Grid>
        </>
      }
    </>
  );
}

export default TaxingAuthorityDetails;
