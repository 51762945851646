import { useContext } from 'react';

import Typography from '@mui/material/Typography';
import EntityManagementContext from 'components/contexts/EntityManagement';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { Section } from 'types/forms';
import { formatIdentifier } from 'utils/formatIdentifier';

interface Props {
  section: Section;
  control: UseFormReturn['control'];
  index: number;
}

function SectionTitle({ section, index, control }: Props): JSX.Element {
  const data = useWatch({ control });

  const ctx = useContext(EntityManagementContext);

  const getTitle = () => {
    let detail = '';
    switch (section.sectionIdentifier) {
      case 'addresses':
        detail =
          data.addresses &&
          data.addresses[index] &&
          data.addresses[index].addressLine1
            ? ': ' + data.addresses[index].addressLine1
            : '';
        break;
      case 'identifiers':
        detail =
          data[section.sectionIdentifier] &&
          data[section.sectionIdentifier][index] &&
          data[section.sectionIdentifier][index].value
            ? ': ' +
              formatIdentifier(
                data[section.sectionIdentifier][index].value,
                ctx.idFormats,
                ctx.selectedIdType
              )
            : '';
        break;
      case 'names':
        if (
          data[section.sectionIdentifier] &&
          data[section.sectionIdentifier][index] &&
          data[section.sectionIdentifier][index].value &&
          data[section.sectionIdentifier][index].value !== ''
        ) {
          detail = ': ' + data[section.sectionIdentifier][index].value;
        } else if (
          data[section.sectionIdentifier] &&
          data[section.sectionIdentifier][index] &&
          (data[section.sectionIdentifier][index].firstName ||
            data[section.sectionIdentifier][index].middleName ||
            data[section.sectionIdentifier][index].lastName)
        ) {
          detail =
            ': ' +
            (data[section.sectionIdentifier][index].firstName ?? '') +
            ' ' +
            (data[section.sectionIdentifier][index].middleName ?? '') +
            ' ' +
            (data[section.sectionIdentifier][index].lastName ?? '');
        }
        break;
      case 'phoneNumbers':
      case 'emailAddresses':
        detail =
          data[section.sectionIdentifier] &&
          data[section.sectionIdentifier][index] &&
          data[section.sectionIdentifier][index].value
            ? ': ' + data[section.sectionIdentifier][index].value
            : '';
        break;

      default:
    }
    return `${section.sectionTitle}${detail} `;
  };

  return <Typography variant="h2">{getTitle()}</Typography>;
}

export default SectionTitle;
