import { useEffect, useState, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectAutoComplete } from '@revenue-solutions-inc/revxcoreui';
import { SelectType } from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import { UserWithoutRole, useUsersListBySearchQuery } from 'generated/graphql';

interface UserLookupAutocompleteProps {
  setUser: (user: string | undefined) => void;
  initialValue?: string | null;
  labelTitle?: string;
}

const emptyOption = [
  {
    key: '0',
    desc: '',
  },
];

const UserLookupAutocomplete = ({
  setUser,
  initialValue = null,
  labelTitle,
}: UserLookupAutocompleteProps) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState<SelectType[]>(emptyOption);
  const [inputValue, setInputValue] = useState(' ');
  const [value, setValue] = useState<string | null>(initialValue);
  const { data } = useUsersListBySearchQuery<{
    UsersListBySearch: [UserWithoutRole];
  }>({
    search: inputValue,
  });

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const onChange = (e: SyntheticEvent, newValue: string) => {
    setOptions(emptyOption);
    setValue(newValue);
    setUser(newValue);
  };

  useEffect(() => {
    if (inputValue === '' || !data?.UsersListBySearch.length) {
      setOptions(emptyOption);
    } else if (data?.UsersListBySearch) {
      setOptions(
        data.UsersListBySearch.map(
          (user) =>
            ({
              key: user.email,
              desc: `${user.name} (${user.email})`,
            } as SelectType)
        )
      );
    }
  }, [inputValue, data]);

  return (
    <SelectAutoComplete
      filterOptions={(opts) => opts}
      options={options}
      onChange={onChange}
      value={value}
      label={labelTitle ?? t('components.userLookupAutocomplete.label')}
      id="user-lookup-autocomplete"
      onInputChange={(e: SyntheticEvent, newValue: string) => {
        setInputValue(newValue);
      }}
    />
  );
};

export default UserLookupAutocomplete;
