import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { IHeader, setHeader } from 'redux/contentSlice';
import { Box, IconButton } from '@mui/material';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import { Edit } from '@mui/icons-material/';
import { ColumnDef } from '@tanstack/react-table';
import { useHasAccess } from 'hooks/useHasAccess';
import { Link } from 'react-router-dom';
import {
  recordIcons,
  expandButton,
  collapseButton,
} from 'components/manageConfigTools/Shared/IconButtonStyling/IconButtonStyling';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import Loading from 'components/Loading';
import NoResults from 'components/NoResults';

import {
  BusinessPolicy,
  useBusinessPoliciesQuery,
  useRoleByCustomerBusinessPolicyIdQuery,
  RoleByCustomerBusinessPolicyIdResponse,
} from 'generated/graphql';
import extractMeaningfulMessage from 'utils/errorMessage';
import { addMessage } from 'redux/messageSlice';
import {
  MessageActionType,
  MessageType,
} from '@revenue-solutions-inc/revxcoreui';
import RolesByCustomerBusinessPolicyIdInfo from 'components/RolesByCustomerBusinessPolicyIdInfo';
import {
  RowExpanded,
  ColumnsExpand,
  showColumn,
  removeRow,
  addRow,
  isOpenColumnType,
} from 'utils/tableColumns';

type NewBusinessPolicy = {
  customerBusinessPolicyId: string;
  description: string;
  name: string;
};

type BusinessPolicyAllData = NewBusinessPolicy & {
  roles?: Array<RoleByCustomerBusinessPolicyIdResponse>;
};

function BusinessPolicies(): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const dropdownFocus = useRef<HTMLSelectElement>(null);
  const module = useAppSelector((state) => state.user.module);
  const accessEditBusinessPolicy = useHasAccess('editBusinessPolicy', 'edit');
  const [customerBusinessPolicyId, setCustomerBusinessPolicyId] =
    useState<string>('');
  const [rows, setRows] = useState<RowExpanded[]>([]);
  const [businessPoliciesAllData, setBusinessPoliciesAllData] = useState<
    BusinessPolicyAllData[]
  >([]);

  useEffect(() => {
    const headerData: IHeader = {
      pageTitle: t('pages.businessPolicies.title'),
      previousPage: t('pages.forms.previousPage'),
      route: 'dashboard',
    };
    dispatch(setHeader(headerData));
  }, [dispatch, t]);

  useEffect(() => {
    dropdownFocus?.current?.focus();
  }, []);

  const { isLoading, isError } = useBusinessPoliciesQuery<{
    BusinessPolicies: [BusinessPolicy];
  }>(
    {},
    {
      onSuccess: (data) => {
        if (data?.BusinessPolicies) {
          const newBusinessPoliciesAllData: BusinessPolicyAllData[] =
            data.BusinessPolicies.map((cBusinessPolicy) => {
              return {
                ...cBusinessPolicy,
                roles: [],
              };
            });
          setBusinessPoliciesAllData(newBusinessPoliciesAllData);
        }
      },
      onError: (error) => {
        const message = extractMeaningfulMessage(
          error,
          t('pages.manageReusableContent.networkError')
        );
        dispatch(
          addMessage({
            message: message,
            type: MessageType.Error,
            actionType: MessageActionType.None,
          })
        );
      },
    }
  );

  const { isLoading: isLoadingRoles } = useRoleByCustomerBusinessPolicyIdQuery<{
    RoleByCustomerBusinessPolicyId: [RoleByCustomerBusinessPolicyIdResponse];
  }>(
    {
      customerBusinessPolicyId: customerBusinessPolicyId,
    },
    {
      enabled: customerBusinessPolicyId !== '',
      onSuccess: (data) => {
        if (data?.RoleByCustomerBusinessPolicyId) {
          const newRoles = data.RoleByCustomerBusinessPolicyId;
          const newBusinessPoliciesAllData = businessPoliciesAllData.map(
            (obj) =>
              obj.customerBusinessPolicyId === customerBusinessPolicyId
                ? { ...obj, roles: newRoles }
                : obj
          );
          setBusinessPoliciesAllData(newBusinessPoliciesAllData);
        }
      },
      onError: (error) => {
        const message = extractMeaningfulMessage(
          error,
          t('pages.manageReusableContent.networkError')
        );
        dispatch(
          addMessage({
            message: message,
            type: MessageType.Error,
            actionType: MessageActionType.None,
          })
        );
      },
    }
  );

  const handleExpandRow = (
    isExpanded: boolean,
    rowId: string,
    columnType: string,
    rowCustomerBusinessPolicyId: string
  ) => {
    if (isExpanded) {
      setRows(removeRow(rows, rowId));
    } else {
      setCustomerBusinessPolicyId(rowCustomerBusinessPolicyId);
      setRows(addRow(rows, rowId, columnType));
    }
  };

  const editColumn: ColumnDef<NewBusinessPolicy>[] = [
    {
      header: () => (
        <HeaderColumnNext localization={t('components.actions.edit')} />
      ),
      id: 'business-policy-edit',
      cell: ({ row }) => {
        return (
          <Link
            to={{
              pathname: `/${module}/manageBusinessPolicies/edit/${row.original.customerBusinessPolicyId}`,
            }}
          >
            <p style={{ width: '5em' }}></p>
            <Edit sx={recordIcons} />
          </Link>
        );
      },
    },
  ];

  const commonColumns: ColumnDef<NewBusinessPolicy>[] = [
    {
      header: () => (
        <HeaderColumnNext
          localization={t('pages.businessPolicies.customerBusinessPolicyId')}
        />
      ),
      accessorKey: 'customerBusinessPolicyId',
      cell: ({ getValue }) => {
        return <p style={{ width: '10em' }}>{getValue() as string}</p>;
      },
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.businessPolicies.name')} />
      ),
      accessorKey: 'name',
      cell: ({ getValue }) => {
        return (
          <p style={{ width: '36em', wordBreak: 'break-word' }}>
            {getValue() as string}
          </p>
        );
      },
    },
    {
      header: () => (
        <HeaderColumnNext
          localization={t('pages.businessPolicies.description')}
        />
      ),
      accessorKey: 'description',
      cell: ({ getValue }) => {
        return (
          <p style={{ width: '36em', wordBreak: 'break-word' }}>
            {getValue() as string}
          </p>
        );
      },
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.businessPolicies.roles')} />
      ),
      id: ColumnsExpand.ROLES,
      cell: ({ row }) => {
        const rowCustomerBusinessPolicyId =
          row.original.customerBusinessPolicyId;
        return (
          <IconButton
            {...{
              onClick: () => {
                row.toggleExpanded();
                handleExpandRow(
                  row.getIsExpanded(),
                  row.id,
                  ColumnsExpand.ROLES,
                  rowCustomerBusinessPolicyId
                );
              },
            }}
            sx={
              row.getIsExpanded() &&
              isOpenColumnType(rows, row.id, ColumnsExpand.ROLES)
                ? collapseButton
                : expandButton
            }
          >
            <ExpandCircleDownIcon />
          </IconButton>
        );
      },
    },
  ];

  return (
    <Box>
      {isLoading && <Loading />}
      {businessPoliciesAllData && (
        <DefaultDataTableNext
          customHeader={<div></div>}
          columns={
            (accessEditBusinessPolicy
              ? [...editColumn, ...commonColumns]
              : commonColumns) as ColumnDef<Record<string, unknown>>[]
          }
          data={businessPoliciesAllData}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'end',
          }}
          renderRowSubComponent={(row) => (
            <Box sx={{ width: '60em', margin: 'auto' }}>
              {showColumn(rows, row.id, ColumnsExpand.ROLES) && (
                <RolesByCustomerBusinessPolicyIdInfo
                  isLoading={isLoadingRoles}
                  roles={
                    (row?.original?.roles as [
                      RoleByCustomerBusinessPolicyIdResponse
                    ]) ?? []
                  }
                />
              )}
            </Box>
          )}
        />
      )}
      <br />
      {businessPoliciesAllData && businessPoliciesAllData.length === 0 && (
        <NoResults />
      )}
      {isError && (
        <p>{t('pages.businessPolicies.isErrorBusinessPoliciesList')}</p>
      )}
    </Box>
  );
}

export default BusinessPolicies;
