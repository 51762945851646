import { SetStateAction, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import HistoryIcon from '@mui/icons-material/History';
import { useTranslation } from 'react-i18next';
import DataCard from 'components/DataCard';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import { ColumnDef } from '@tanstack/react-table';

import {
  CmTaskRunInput,
  ConfigurationResponse,
  GetConfigurationDocument,
  GetTasks,
  useBusinessPoliciesUsersQuery,
  useGetTaskHistoryQuery,
  useRunTaskMutation,
} from 'generated/graphql';
import Loading from 'components/Loading';
import { addMessage } from 'redux/messageSlice';
import DateCell from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/TableCells/DateCell';
import ManageConfigToolsProvider from 'components/contexts/ManageConfigToolsProvider';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { isEqual } from 'lodash';
import { Button, MessageType } from '@revenue-solutions-inc/revxcoreui';
import useGetAccessToken from 'hooks/useGetAccessToken';
import appTheme from '@revenue-solutions-inc/revxcoreui/material/themes/defaultTheme';
import TaskRunParameters from '../manageScheduledTasks/TaskRunParameters';
import { Parameters } from '../createScheduledTasks/ScheduledTasks/ScheduledTasks';

enum VersionStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  DRAFT = 'Draft',
}

interface Props {
  scheduleTaskName: string;
  currentModule: string;
}

function VersionHistory({
  scheduleTaskName,
  currentModule,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [openRunTask, setOpenRunTask] = useState<boolean>(false);
  const [initialRender, setInitialRender] = useState<boolean>(false);
  const [taskId, setTaskId] = useState<string>('');
  const [taskNameValue, setTaskNameValue] = useState<string>('');
  const [status, setStatus] = useState<string>('');
  const [stageNames, setStageNames] = useState<string[]>([]);
  const [versionNumber, setVersionNumber] = useState<number>();
  const [dataParameters, setDataParameters] = useState<ConfigurationResponse>();
  const [initialJSON, setInitialJSON] = useState<ConfigurationResponse[]>([]);

  const [arrayTest, setArrayTest] = useState<Parameters[]>([]);
  const { data, isLoading } = useGetTaskHistoryQuery({
    module: currentModule,
    taskName: scheduleTaskName,
  });

  const accessToken = useGetAccessToken();

  const getConfiguration = async (value: string) => {
    const url = process.env.REACT_APP_GRAPH || '';
    const resp = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        query: GetConfigurationDocument,
        variables: {
          configurationName: value,
          configurationDomain: 7,
          configurationModule: 3,
        },
      }),
    });
    const res = await resp.json();

    return res;
  };

  const { data: businessPolicies, isLoading: policyLoading } =
    useBusinessPoliciesUsersQuery();

  const module = useAppSelector((state) => state.user.module);

  const disabledRunButton = (Status: string, BusinessPolicy: string) => {
    const Policies = businessPolicies?.BusinessPoliciesUsers.map((item) => {
      return item.name;
    });
    if (
      (process.env.REACT_APP_ENVIRONMENT === 'Production Environment' &&
        Status === VersionStatus.DRAFT) ||
      Status === VersionStatus.INACTIVE ||
      !Policies?.includes(BusinessPolicy)
    ) {
      return true;
    }
  };

  const { mutate: mutateRun, isLoading: loadingRun } = useRunTaskMutation();

  const columnsData: ColumnDef<GetTasks>[] = [
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.manageChannel.taskVersion')} />
      ),
      id: 'task-edit',
      cell: ({ row }) => {
        return (
          <Link
            to={{
              pathname: `/${module}/managescheduledtasks/edit/${row.original.TaskId}/${row.original.ScheduleTaskName}/${row.original.Module}`,
            }}
          >
            {row.original.Version}
          </Link>
        );
      },
    },
    {
      id: 'DisplayName',
      header: () => (
        <HeaderColumnNext localization={t('pages.manageChannel.displayName')} />
      ),
      accessorKey: 'DisplayName',
    },
    {
      id: 'CreatedBy',
      header: () => (
        <HeaderColumnNext localization={t('pages.manageChannel.createdBy')} />
      ),
      accessorKey: 'CreatedBy',
    },
    {
      id: 'CreatedDttm',
      header: () => (
        <HeaderColumnNext localization={t('pages.manageChannel.createdDate')} />
      ),
      accessorKey: 'CreatedDttm',
      cell: ({ row }) => {
        return <DateCell dateString={row.original.CreatedDttm || ''} />;
      },
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.manageChannel.updatedBy')} />
      ),
      id: 'updated-by',
      accessorKey: 'UpdatedBy',
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.manageChannel.updatedDttm')} />
      ),
      id: 'updated-dttm ',
      accessorKey: 'UpdatedDttm',
      cell: ({ row }) => {
        return <DateCell dateString={row.original.UpdatedDttm || ''} />;
      },
    },
    {
      id: 'Version-Status',
      header: () => (
        <HeaderColumnNext localization={t('pages.manageChannel.status')} />
      ),
      accessorKey: 'Status',
    },
    {
      id: 'Version-Run',
      header: () => (
        <HeaderColumnNext localization={t('pages.manageChannel.runVersion')} />
      ),
      cell: ({ row }) => {
        return (
          <>
            <IconButton
              onClick={() => {
                setOpenRunTask(true);
                setTaskId(row.original.TaskId);
                setTaskNameValue(row.original.ScheduleTaskName);
                setVersionNumber(
                  row.original.Version as unknown as SetStateAction<
                    number | undefined
                  >
                );
                setStatus(row.original.Status);
                const stageValues = row.original.Stages.map(function (stage) {
                  return stage.StageName;
                });
                setStageNames(stageValues);
                const grabValues = row.original.Stages.map(function (item) {
                  return getConfiguration(item.OperationName).then(function (
                    results
                  ) {
                    return results;
                  });
                });

                Promise.all(grabValues).then(function (results) {
                  setArrayTest(results);
                });
              }}
              color="primary"
              disabled={disabledRunButton(
                row.original.Status,
                row.original.BusinessPolicy
              )}
            >
              <PlayArrowIcon />
            </IconButton>
          </>
        );
      },
    },
  ];
  const isObjectEqual = (index: number) => {
    const value = isEqual(
      initialJSON[index],
      arrayTest[index].data.getConfiguration
    );
    return value;
  };
  function runTask() {
    let task;
    if (arrayTest) {
      const newValues = arrayTest.map(function (item, index) {
        if (!isObjectEqual(index)) {
          return item.data.getConfiguration;
        } else {
          return undefined;
        }
      });

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const objectValues: any = newValues.reduce(
        (acc, filterValue, indexValue) => {
          if (filterValue) {
            return { ...acc, [stageNames[indexValue]]: filterValue };
          } else {
            return { ...acc, ['=']: '=' };
          }
        },
        {}
      );
      const taskParameters = Object.keys(objectValues)

        .filter((key) => !key.includes('='))
        .reduce((obj, key) => {
          return Object.assign(obj, {
            [key]: objectValues[key],
          });
        }, {});

      if (Object.keys(taskParameters).length > 0) {
        task = {
          ManualRunParameters: taskParameters,
          TaskId: taskId,
          ScheduledTaskName: taskNameValue,
          Module: currentModule,
        };
      } else {
        task = {
          TaskId: taskId,
          ScheduledTaskName: taskNameValue,
          Module: currentModule,
        };
      }
    }
    return task;
  }

  if (!isLoading && data && !loadingRun && !policyLoading) {
    return (
      <DataCard
        avatar={<HistoryIcon sx={{ fill: 'primary.main' }} />}
        title={t('pages.manageChannel.versionHistory')}
        children={
          <>
            <Grid sx={{ marginBottom: '20px' }}>
              <DefaultDataTableNext
                columns={columnsData as ColumnDef<Record<string, unknown>>[]}
                data={data?.getScheduledTaskHistory}
                enableGlobalFilter={false}
              />

              {openRunTask && !initialRender && arrayTest.length === 0 ? (
                <Loading />
              ) : (
                <Dialog
                  open={openRunTask}
                  aria-labelledby="run-task-dialog"
                  aria-describedby="run-task-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    <Typography
                      variant="h1"
                      style={{ color: `${appTheme.palette.black.main}` }}
                    >
                      {status === 'Draft'
                        ? `${scheduleTaskName} Version ${versionNumber} DRAFT`
                        : `${scheduleTaskName} Version ${versionNumber}`}
                    </Typography>
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText
                      id="alert-dialog-description"
                      color="black.light"
                      fontSize="16px"
                    >
                      {t('pages.manageChannel.confirmationRunTask')}
                    </DialogContentText>
                    <ManageConfigToolsProvider>
                      <TaskRunParameters
                        arrayTest={arrayTest}
                        setInitialJSON={setInitialJSON}
                        dataParameters={dataParameters}
                        initialRender={initialRender}
                        stageNames={stageNames}
                        setInitialRender={setInitialRender}
                        setDataParameters={setDataParameters}
                      />
                    </ManageConfigToolsProvider>
                  </DialogContent>
                  <DialogActions style={{ justifyContent: 'center' }}>
                    <Button
                      onClick={() => {
                        setArrayTest([]);
                        setOpenRunTask(false);
                        setInitialRender(false);
                      }}
                      type="secondary"
                    >
                      {t('pages.configTypeEditor.cancel')}
                    </Button>
                    <Button
                      onClick={() => {
                        setOpenRunTask(false);
                        setInitialRender(false);
                        setArrayTest([]);
                        mutateRun(
                          {
                            task: runTask() as unknown as CmTaskRunInput,
                          },

                          {
                            onSuccess: () => {
                              dispatch(
                                addMessage({
                                  type: MessageType.Success,
                                  message: t('pages.manageChannel.runTaskIcon'),
                                })
                              );
                              setInitialRender(false);
                              setDataParameters(undefined);
                            },
                          }
                        );
                      }}
                      type="primary"
                    >
                      {t('pages.manageChannel.runTask')}
                    </Button>
                  </DialogActions>
                </Dialog>
              )}
            </Grid>
          </>
        }
      />
    );
  }
  if (isLoading || loadingRun || policyLoading) {
    return <Loading />;
  }
  return <></>;
}
export default VersionHistory;
