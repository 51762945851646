import { phoneExp } from 'common/regexp';
import { Section, Rules } from 'types/forms';

import { radioOptions } from '../defaults/entity';
import states from '../defaults/states';
import { setOptions } from '../formatValidations';

export const NameFields: Section = {
  sectionTitle: 'Name',
  sectionIdentifier: 'names',
  fields: [
    {
      label: 'Type',
      fieldIdentifier: 'NameType',
      type: 'datasource',
      datasource: 'EntityToNameTypes',
      rules: { required: 'Name type is required' },
      isRequired: true,
      groupName: 'DestinationNameTypes',
      attributeName: 'DestinationNameTypes',
      context: 'entityType',
    },
    {
      label: 'First Name',
      fieldIdentifier: 'firstName',
      rules: { required: 'First name is required' },
      isRequired: true,
      type: 'text',
    },
    {
      label: 'Middle Name',
      fieldIdentifier: 'middleName',
      type: 'text',
    },
    {
      label: 'Last Name',
      fieldIdentifier: 'lastName',
      rules: { required: 'Last name is required' },
      isRequired: true,
      type: 'text',
    },
    {
      label: 'Name',
      fieldIdentifier: 'value',
      rules: { required: 'Name is required' },
      isRequired: true,
      type: 'text',
    },
    {
      label: 'Suffix',
      fieldIdentifier: 'nameSuffix',
      type: 'select',
      options: setOptions(['Mr', 'Mrs']),
    },
    {
      label: 'Commence Date',
      fieldIdentifier: 'commenceDate',
      type: 'date',
      isRequired: true,
    },
    {
      label: 'Cease Date',
      fieldIdentifier: 'ceaseDate',
      type: 'date',
    },
    {
      label: 'Is Primary',
      fieldIdentifier: 'isPrimary',
      type: 'radio',
      rules: { required: 'Is Primary is required' },
      isRequired: true,
      radioOptions: radioOptions,
    },
    {
      label: 'Is Confidential',
      fieldIdentifier: 'isConfidential',
      type: 'radio',
      rules: { required: 'Is Confidential is required' },
      isRequired: true,
      radioOptions: radioOptions,
    },
  ],
};

export const IdentifiersFields: Section = {
  sectionTitle: 'Identifier',
  sectionIdentifier: 'identifiers',
  fields: [
    {
      label: 'Type',
      fieldIdentifier: 'IdType',
      type: 'datasource',
      datasource: 'EntityToIdTypes',
      rules: { required: 'ID type is required' },
      isRequired: true,
      groupName: 'DestinationIdTypes',
      attributeName: 'IdType',
      context: 'entityType',
    },
    {
      label: 'ID',
      fieldIdentifier: 'value',
      type: 'identifier',
      rules: {
        required: 'ID is required',
      },
      isRequired: true,
    },
    {
      label: 'Commence Date',
      fieldIdentifier: 'commenceDate',
      type: 'date',
      isRequired: true,
    },
    {
      label: 'Cease Date',
      fieldIdentifier: 'ceaseDate',
      type: 'date',
    },
    {
      label: 'Is Primary',
      fieldIdentifier: 'isPrimary',
      type: 'radio',
      rules: { required: 'Is Primary is required' },
      isRequired: true,
      radioOptions: radioOptions,
    },
  ],
};

export const PhoneNumberFields: Section = {
  sectionTitle: 'Phone Number',
  sectionIdentifier: 'phoneNumbers',
  fields: [
    {
      label: 'Type',
      fieldIdentifier: 'PhoneNumberType',
      type: 'datasource',
      datasource: 'PhoneType',
      rules: { required: 'Phone type is required' },
      isRequired: true,
    },
    {
      label: 'Phone Number',
      fieldIdentifier: 'value',
      type: 'text',
      rules: {
        required: 'Phone number is required',
        pattern: {
          value: phoneExp,
          message: 'Please provide a valid phone number',
        },
      },
      isRequired: true,
    },
    {
      label: 'Commence Date',
      fieldIdentifier: 'commenceDate',
      type: 'date',
      isRequired: true,
    },
    {
      label: 'Cease Date',
      fieldIdentifier: 'ceaseDate',
      type: 'date',
    },
    {
      label: 'Is Primary',
      fieldIdentifier: 'isPrimary',
      type: 'radio',
      rules: { required: 'Is Primary is required' },
      isRequired: true,
      radioOptions: radioOptions,
    },
  ],
};

export const EmailAddressFields: Section = {
  sectionTitle: 'Email Address',
  sectionIdentifier: 'emailAddresses',
  contactType: 'email',
  type: 'contact',
  fields: [
    {
      label: 'Type',
      fieldIdentifier: 'EmailAddressType',
      type: 'datasource',
      isRequired: true,
      datasource: 'EmailAddressType',
      rules: { required: 'Email type is required' },
    },
    {
      label: 'Email',
      fieldIdentifier: 'value',
      type: 'text',
      isRequired: true,
      rules: {
        required: 'Email is required',
        pattern: {
          value:
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
          message: 'Please provide a valid email',
        },
      },
    },
    {
      label: 'Commence Date',
      fieldIdentifier: 'commenceDate',
      type: 'date',
      isRequired: true,
    },
    {
      label: 'Cease Date',
      fieldIdentifier: 'ceaseDate',
      type: 'date',
    },
    {
      label: 'Is Primary',
      fieldIdentifier: 'isPrimary',
      type: 'radio',
      rules: { required: 'Is Primary is required' },
      isRequired: true,
      radioOptions: radioOptions,
    },
  ],
};

export const AddressFields: Section = {
  sectionTitle: 'Address',
  sectionIdentifier: 'addresses',
  fields: [
    {
      label: 'Type',
      fieldIdentifier: 'AddressType',
      type: 'datasource',
      datasource: 'EntityToAddressTypes',
      rules: { required: 'Address type is required' },
      isRequired: true,
      groupName: 'DestinationAddressTypes',
      attributeName: 'AddressType',
      context: 'entityType',
    },
    {
      label: 'Country',
      fieldIdentifier: 'countryId',
      rules: { required: 'Country is required' },
      isRequired: true,
      type: 'select',
      options: setOptions(['USA', 'Canada', 'International']),
    },
    {
      label: 'Attention',
      fieldIdentifier: 'attention',
      type: 'text',
    },
    {
      label: 'Address 1',
      fieldIdentifier: 'addressLine1',
      type: 'text',
      rules: { required: 'Address is required' },
      isRequired: true,
    },
    {
      label: 'Address 2',
      fieldIdentifier: 'addressLine2',
      type: 'text',
    },
    {
      label: 'City',
      fieldIdentifier: 'cityId',
      type: 'text',
      rules: { required: 'City is required' },
      isRequired: true,
    },
    {
      label: 'County',
      fieldIdentifier: 'countyId',
      type: 'text',
    },
    {
      label: 'State',
      fieldIdentifier: 'stateProvinceId',
      type: 'select',
      rules: { required: 'State is required' },
      isRequired: true,
      options: setOptions(states),
    },
    {
      label: 'Zip',
      fieldIdentifier: 'postalCode',
      type: 'text',
      rules: { required: 'Zip is required' },
      isRequired: true,
    },
    {
      label: 'Commence Date',
      fieldIdentifier: 'commenceDate',
      type: 'date',
      isRequired: true,
    },
    {
      label: 'Cease Date',
      fieldIdentifier: 'ceaseDate',
      type: 'date',
    },
    {
      label: 'Is Primary',
      fieldIdentifier: 'isPrimary',
      type: 'radio',
      rules: { required: 'Is Primary is required' },
      isRequired: true,
      radioOptions: radioOptions,
    },
    {
      label: 'Is Confidential',
      fieldIdentifier: 'isConfidential',
      type: 'radio',
      rules: { required: 'Is Confidential is required' },
      isRequired: true,
      radioOptions: radioOptions,
    },
    {
      label: 'Has Mail Returned',
      fieldIdentifier: 'hasMailReturned',
      type: 'radio',
      rules: { required: 'Has Mail Returned is required' },
      isRequired: true,
      radioOptions: radioOptions,
    },
  ],
};

export const OtherFields: Section = {
  sectionTitle: 'Other',
  sectionIdentifier: 'others',
  fields: [
    {
      label: 'Entity Type',
      fieldIdentifier: 'entityType',
      type: 'datasource',
      datasource: 'EntityType',
      rules: { required: 'Entity type is required' },
      isRequired: true,
    },
    {
      label: 'Creation Source',
      fieldIdentifier: 'entityCreationSourceId',
      type: 'select',
      options: setOptions(['Channel', 'Online']),
      rules: { required: 'Creation Source is required' },
      isRequired: true,
    },
    {
      label: 'Commence Date',
      fieldIdentifier: 'commenceDate',
      rules: { required: 'Commence date is required' },
      isRequired: true,
      type: 'date',
    },
    {
      label: 'Cease Date',
      fieldIdentifier: 'ceaseDate',
      type: 'date',
    },
    {
      label: 'Organization Type',
      fieldIdentifier: 'organizationType',
      type: 'datasource',
      hidden: true,
      datasource: 'EntityToOrganizationTypes',
      groupName: 'DestinationOrganizationTypes',
      attributeName: 'OrganizationType',
      context: 'entityType',
      rules: { required: 'Organization Type is required' },
      isRequired: true,
    },
  ],
};

export const commenceDateRule: Rules = {
  required: 'Commence date is required',
};
