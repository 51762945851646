import { useEffect, useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import AttributeDetails from 'components/Workflow/AttributeDetails';
import { TabPanel } from '@revenue-solutions-inc/revxcoreui';
import { Attribute, useGetFormQuery } from 'generated/graphql';

type AttributesTabProps = {
  attributes: Attribute[];
  variantId: string;
  formId?: string;
  handleAttributeChange: (
    value: string | number | Date,
    group: string,
    name: string
  ) => void;
};

export const AttributesTab = ({
  attributes,
  variantId,
  handleAttributeChange,
  formId,
}: AttributesTabProps) => {
  const [currentTab, setCurrentTab] = useState(0);

  const { data: formData } = useGetFormQuery(
    { formGroupId: formId as string },
    { enabled: !!formId }
  );
  const [possibleValues, setPossibleValues] = useState<{
    [name: string]: string[];
  }>({});

  useEffect(() => {
    if (formData && formId) {
      const values: { [name: string]: string[] } = {};
      const sections = formData.getForm.Forms?.at(0)?.BusinessSections ?? [];
      for (const sec of sections) {
        sec.Fields?.map((field) => {
          if (field.BaseField?.PossibleValues) {
            values[`${field.BaseField?.FieldName}`] =
              field.BaseField?.PossibleValues;
          }
        });
      }
      setPossibleValues(values);
    }
  }, [formData, formId]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: 'background.paper',
        display: 'flex',
        '.attributes-tab__tabpane': {
          backgroundColor: (theme) => theme.palette.grey[50],
          paddingLeft: 2,
          width: '100%',
        },
      }}
    >
      <Tabs orientation="vertical" value={currentTab}>
        {attributes.map(
          (
            { attributeDisplayName, attributeName }: Attribute,
            index: number
          ) => {
            return (
              <Tab
                key={`${index}-${attributeName}`}
                value={index}
                label={attributeDisplayName || attributeName}
                onClick={() => setCurrentTab(index)}
              />
            );
          }
        )}
      </Tabs>
      <div className="attributes-tab__tabpane">
        {attributes.map(
          ({ attributeName, repeatingValue }: Attribute, index: number) => {
            return (
              <TabPanel
                selectedIndex={currentTab}
                index={index}
                sx={{
                  backgroundColor: (theme) => theme.palette,
                }}
                key={attributeName}
              >
                {repeatingValue
                  ?.filter(
                    (attribute: Attribute) => !attribute?.attributeHidden
                  )
                  .map((valueAttribute: Attribute) => {
                    return (
                      <AttributeDetails
                        key={valueAttribute.attributeName}
                        attribute={valueAttribute}
                        variantId={variantId}
                        group="CustomAttributes"
                        handleChange={handleAttributeChange}
                        posibleValues={possibleValues}
                      />
                    );
                  })}
              </TabPanel>
            );
          }
        )}
      </div>
    </Box>
  );
};
