import { LogiXgroupInput } from 'generated/graphql';

import {
  ConfigDataType,
  DataType,
  FieldType,
  Group,
  LayoutField,
  LayoutFields,
  RepeatingGroup,
} from '@revenue-solutions-inc/revxcoreui/material/layoutBuilder/types/fields';
import uuid from 'react-uuid';

export function parseLogixGroupInput(input: LogiXgroupInput): LayoutFields {
  const layoutFields: LayoutFields = { id: uuid(), groups: [] };

  const groups: Group[] = [];

  input.Contexts?.forEach((context) => {
    const sections =
      context.BusinessSections?.map((section) => {
        const fields: LayoutField[] =
          section.Fields?.map((field) => {
            return {
              id: field.BaseField?.FieldID?.toString() ?? 'No_Id',
              name: field.BaseField?.FieldDisplayName ?? 'no name',
              //If we have options update the type to reference data so the control is rendered as a Select.
              dataType:
                field.BaseField?.PossibleValues &&
                field.BaseField.PossibleValues.length > 0
                  ? DataType.ReferenceData
                  : (field.BaseField?.FieldValueType as ConfigDataType),
              fieldType: field.BaseField?.FieldType as FieldType,
              possibleValues: field.BaseField?.PossibleValues ?? [],
              value: field.FieldValues?.[0]?.Value ?? '',
              rules: {
                Required: field.BaseField?.RequiredField ?? false,
                Visible: field.BaseField?.Show ?? true,
                Format: field.BaseField?.FieldFormat ?? '',
                Disabled: !field.BaseField?.Enable ?? false,
              },
            };
          }) || [];

        let repeats: LayoutField[] = [];
        const RepeatGroup: RepeatingGroup[] = [];
        section.RepeatingGroups?.forEach((repeatingGroup) => {
          repeats = [];
          repeatingGroup.ColumnDefinitions?.forEach((column) => {
            repeats.push({
              id: column.RepeatingField?.BaseField?.FieldID?.toString() ?? '',
              name: column.RepeatingField?.BaseField?.FieldName ?? '',
              dataType:
                (column.RepeatingField?.BaseField
                  ?.FieldValueType as ConfigDataType) ?? '',
              fieldType: column.RepeatingField?.BaseField
                ?.FieldType as FieldType,
              possibleValues:
                column.RepeatingField?.BaseField?.PossibleValues ?? [],
              value:
                column.RepeatingField?.RepeatingValues?.[0]?.FieldValues?.[0]
                  .Value ?? '',
              rules: {
                Required:
                  column.RepeatingField?.BaseField?.RequiredField ?? false,
                Visible: column.RepeatingField?.BaseField?.Show ?? true,
                Format: column.RepeatingField?.BaseField?.FieldFormat ?? '',
                Disabled: !column.RepeatingField?.BaseField?.Enable ?? false,
              },
            });
          });
          RepeatGroup.push({
            name: repeatingGroup.RepeatingGroupName ?? '',
            repeatingGroupFields: [...repeats],
          });
        });

        return {
          id: uuid(),
          groupName: section.SectionName ?? '',
          repeatingGroup: repeats.length > 0,
          repeatingFieldGroups: [...RepeatGroup],
          fields: [...fields],
        };
      }) || [];

    sections.forEach((group) => {
      if (group !== undefined && group) groups?.push(group);
    });
  });

  layoutFields.groups = groups || [];
  return layoutFields;
}
