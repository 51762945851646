/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactNode, Dispatch, SetStateAction } from 'react';
import { IconButton } from '@mui/material';
import { NavigateFunction } from 'react-router-dom';

export const createPbimoduleColumnTables = (
  name: string,
  set: object,
  elementButton?: ReactNode,
  action?:
    | NavigateFunction
    | Dispatch<
        SetStateAction<{
          isOpen: boolean;
          id: number;
          name: string;
          description: string;
        }>
      >
    | any,
  valuesAction?:
    | SetStateAction<{
        isOpen: boolean;
        id: number;
        name: string;
        description: string;
      }>
    | string
    | SetStateAction<{
        download: boolean;
        id: number;
      }>
    | any,
  disabled?: boolean
) => {
  if (name.substring(0, 4) === 'icon') {
    return (
      <>
        <IconButton
          onClick={() => (action !== undefined ? action(valuesAction) : {})}
          disabled={disabled ? !disabled : true}
        >
          {elementButton ? elementButton : null}
        </IconButton>
      </>
    );
  } else {
    return Object.entries(set)
      .map((x) => (x[0] === name ? x[1] : null))
      .filter((x) => x)[0];
  }
};
