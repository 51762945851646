import { useEffect } from 'react';

import { Grid } from '@mui/material';
import Input from '@revenue-solutions-inc/revxcoreui/material/controls/Input';
import { SelectType } from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import useMaskInput from 'hooks/useMaskInput';
import { useTranslation } from 'react-i18next';
import { Channel } from 'types/channels';
import ControlledSelectField from 'components/controls/ControlledSelectField';
import { Control, Controller, UseFormWatch } from 'react-hook-form';

// TODO : replace with lookup
const FrequencyValues: SelectType[] = [
  { key: 'Hour', desc: 'Hour' },
  { key: 'Minute', desc: 'Minute' },
  { key: 'Day', desc: 'Day' },
  { key: 'Week', desc: 'Week' },
  { key: 'Month', desc: 'Month' },
];

interface Props {
  index: number;
  setIntervalValid: React.Dispatch<React.SetStateAction<boolean>>;
  control: Control<Channel, unknown>;
  watch: UseFormWatch<Channel>;
}

function Frequency({
  index,
  control,
  watch,
  setIntervalValid,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const intervalValue = watch(
    `Channel.Pipeline.PipelineSchedule.${index}.Interval`
  );

  const intervalMinuteMask = useMaskInput(
    '999999',
    t('pages.manageChannel.minuteRange'),
    /^(?:[1-9]|[1-9][0-9]{1,4}|[1-6][0-9]{5}|7[01][0-9]{4}|720000)$/,
    intervalValue?.toString() ?? undefined
  );

  const intervalHourMask = useMaskInput(
    '999999',
    t('pages.manageChannel.hourRange'),
    /^([1-9]|[1-9][0-9]{1,3}|1[01][0-9]{3}|12000)$/,
    intervalValue?.toString() ?? undefined
  );

  const intervalDayMask = useMaskInput(
    '999999',
    t('pages.manageChannel.dayRange'),
    /^([1-9]|[1-9][0-9]|[1-4][0-9]{2}|500)$/,
    intervalValue?.toString() ?? undefined
  );

  const intervalWeekMask = useMaskInput(
    '999999',
    t('pages.manageChannel.weekRange'),
    /^(?:[1-9]|[1-6][0-9]|7[01])$/,
    intervalValue?.toString() ?? undefined
  );

  const intervalMonthMask = useMaskInput(
    '999999',
    t('pages.manageChannel.monthRange'),
    /^(?:[1-9]|1[0-6])$/,
    intervalValue?.toString() ?? undefined
  );
  const handleHelperIntervalChange = () => {
    const frequencyValue = watch(
      `Channel.Pipeline.PipelineSchedule.${index}.Frequency`
    );

    if (frequencyValue === 'Minute') {
      return intervalMinuteMask.maskError;
    } else if (frequencyValue === 'Hour') {
      return intervalHourMask.maskError;
    } else if (frequencyValue === 'Day') {
      return intervalDayMask.maskError;
    } else if (frequencyValue === 'Week') {
      return intervalWeekMask.maskError;
    }
    return intervalMonthMask.maskError;
  };

  const validateRequiredFields = () => {
    let isValid = true;
    const frequencyValue = watch(
      `Channel.Pipeline.PipelineSchedule.${index}.Frequency`
    );

    if (
      (frequencyValue === 'Minute' && intervalMinuteMask.maskError) ||
      (frequencyValue === 'Hour' && intervalHourMask.maskError) ||
      (frequencyValue === 'Day' && intervalDayMask.maskError) ||
      (frequencyValue === 'Week' && intervalWeekMask.maskError) ||
      (frequencyValue === 'Month' && intervalMonthMask.maskError)
    ) {
      isValid = false;
    }

    setIntervalValid(isValid);
  };

  useEffect(() => {
    validateRequiredFields();
  });

  const intervalLabel = () => {
    const interval = watch(
      `Channel.Pipeline.PipelineSchedule.${index}.Interval`
    );
    const frequency = watch(
      `Channel.Pipeline.PipelineSchedule.${index}.Frequency`
    );
    if (interval === null || Number.isNaN(interval)) {
      return `${t('pages.manageChannel.every')} ${frequency}s`;
    } else {
      return `${t('pages.manageChannel.every')} ${interval} ${frequency}s`;
    }
  };

  return (
    <>
      <Grid item xs={12} sm={6} md={6} mb={0}>
        <ControlledSelectField
          required
          control={control}
          rules={{
            required: true,
          }}
          id="channelRecurrence"
          sx={{ width: '100%', maxWidth: '20em' }}
          options={FrequencyValues}
          name={`Channel.Pipeline.PipelineSchedule.${index}.Frequency`}
          label={t('pages.manageChannel.frequency')}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} mb={0}>
        <Controller
          control={control}
          rules={{
            required: true,
          }}
          name={`Channel.Pipeline.PipelineSchedule.${index}.Interval`}
          render={({ field: { value, onChange } }) => {
            return (
              <Input
                required
                id="channelEvery"
                key={index}
                label={intervalLabel()}
                value={value ? value.toString() : undefined}
                helperText={handleHelperIntervalChange()}
                sx={{ width: '100%', maxWidth: '20em' }}
                onChange={(event) => {
                  intervalMinuteMask.handleMaskChange(event);
                  intervalHourMask.handleMaskChange(event);
                  intervalDayMask.handleMaskChange(event);
                  intervalWeekMask.handleMaskChange(event);
                  intervalMonthMask.handleMaskChange(event);
                  onChange(parseInt(event.target.value));
                }}
              />
            );
          }}
        />
      </Grid>
    </>
  );
}
export default Frequency;
