import { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Edit } from '@mui/icons-material/';
import { SelectChangeEvent, IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import Typography from '@mui/material/Typography';
import {
  MessageActionType,
  MessageType,
} from '@revenue-solutions-inc/revxcoreui';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import Select from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import { ColumnDef } from '@tanstack/react-table';
import { setHeader } from 'redux/contentSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import Loading from 'components/Loading';
import NoResults from 'components/NoResults';
import extractMeaningfulMessage from 'utils/errorMessage';
import {
  ModuleResponse,
  PolicyGroupsByModuleResponse,
  useQueryPlatformOrModuleQuery,
  usePolicyGroupsByModuleQuery,
  PoliciesResponse,
} from 'generated/graphql';
import { useHasEdit } from 'hooks/useHasAccess';
import {
  recordIcons,
  expandButton,
  collapseButton,
} from 'components/manageConfigTools/Shared/IconButtonStyling/IconButtonStyling';
function ManagePolicyGroups(): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const module = useAppSelector((state) => state.user.module);
  const accessEditPolicyGroup = useHasEdit(
    'policyGroup',
    'EditPolicyGroupEdit'
  );

  const dropdownFocus = useRef<HTMLSelectElement>(null);
  const [policyGroups, setPolicyGroups] = useState<
    PolicyGroupsByModuleResponse[]
  >([]);
  const [selectedModuleId, setSelectedModuleId] = useState<string>('0');
  const [modules, setModules] = useState<ModuleResponse[]>([
    {
      name: t('pages.managePolicyGroups.allModules'),
      moduleId: 0,
    },
  ]);

  useEffect(() => {
    dispatch(setHeader({ pageTitle: t('pages.managePolicyGroups.title') }));
  }, [dispatch, t]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      dropdownFocus?.current?.focus();
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const { isFetching: isFetchingModules } = useQueryPlatformOrModuleQuery<{
    Modules: ModuleResponse[];
  }>(
    {},
    {
      onSuccess: (data) => {
        if (data?.Modules) {
          const newModules = [...modules, ...data.Modules];
          setModules(newModules);
        }
      },
      onError: (error) => {
        const message = extractMeaningfulMessage(
          error,
          t('pages.manageReusableContent.networkError')
        );
        dispatch(
          addMessage({
            message: message,
            type: MessageType.Error,
            actionType: MessageActionType.None,
          })
        );
      },
    }
  );

  const { isFetching: isFetchingPolicyGroups } = usePolicyGroupsByModuleQuery<{
    PolicyGroupsByModule: PolicyGroupsByModuleResponse[];
  }>(
    {
      moduleId: selectedModuleId,
    },
    {
      onSuccess: (data) => {
        if (data?.PolicyGroupsByModule) {
          const newPolicyGroups = data.PolicyGroupsByModule;
          setPolicyGroups(newPolicyGroups);
        }
      },
      onError: (error) => {
        const message = extractMeaningfulMessage(
          error,
          t('pages.manageReusableContent.networkError')
        );
        dispatch(
          addMessage({
            message: message,
            type: MessageType.Error,
            actionType: MessageActionType.None,
          })
        );
      },
    }
  );

  const onSelectModule = (event: SelectChangeEvent<string | number>) => {
    const newSelectedModule = `${(event.target as HTMLSelectElement).value}`;
    if (newSelectedModule.length !== 0) {
      setSelectedModuleId(newSelectedModule);
    }
  };

  const editColumn: ColumnDef<PolicyGroupsByModuleResponse>[] = [
    {
      header: () => (
        <div style={{ width: '5em' }}>
          <HeaderColumnNext localization={t('components.actions.edit')} />
        </div>
      ),
      id: 'role-edit',
      cell: ({ row }) => {
        return accessEditPolicyGroup ? (
          <Link
            to={{
              pathname: `/${module}/managePolicyGroups/edit/${row.original.id}`,
            }}
          >
            <Edit sx={recordIcons} />
          </Link>
        ) : (
          <IconButton disabled={true}>
            {' '}
            <Edit />{' '}
          </IconButton>
        );
      },
    },
  ];

  const commonColumns: ColumnDef<PolicyGroupsByModuleResponse>[] = [
    {
      accessorKey: 'moduleName',
      header: () => (
        <HeaderColumnNext
          localization={`${t('pages.managePolicyGroups.platform')}/${t(
            'pages.managePolicyGroups.module'
          )}`}
        />
      ),
    },
    {
      header: () => (
        <div style={{ width: '20em' }}>
          <HeaderColumnNext localization={t('pages.managePolicyGroups.name')} />
        </div>
      ),
      accessorKey: 'name',
    },
    {
      header: () => (
        <div style={{ width: '25em' }}>
          <HeaderColumnNext
            localization={t('pages.managePolicyGroups.technicalName')}
          />
        </div>
      ),
      accessorKey: 'technicalName',
    },
    {
      header: () => (
        <div style={{ width: '30em' }}>
          <HeaderColumnNext
            localization={t('pages.managePolicyGroups.description')}
          />
        </div>
      ),
      accessorKey: 'description',
    },
    {
      header: () => (
        <div style={{ width: '5em' }}>
          <HeaderColumnNext
            localization={t('pages.managePolicyGroups.policies')}
          />
        </div>
      ),
      id: 'expander',
      cell: ({ row }) => {
        return (
          <IconButton
            {...{
              onClick: () => row.toggleExpanded(),
            }}
            sx={row.getIsExpanded() ? collapseButton : expandButton}
          >
            <ExpandCircleDownIcon />
          </IconButton>
        );
      },
    },
  ];

  const PoliciesInfo: React.FC<{
    policies: [PoliciesResponse];
  }> = ({ policies }): JSX.Element => {
    return policies.length > 0 ? (
      <>
        <Typography
          variant="h3"
          sx={{
            fontSize: 16,
            fontWeight: 'bold',
            marginTop: 1,
            marginBottom: 1,
          }}
        >
          {t('pages.managePolicyGroups.assignedPolicies')}
        </Typography>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
          }}
        >
          {policies.map(({ id, name }: PoliciesResponse) => (
            <span key={id} style={{ width: '50%', marginBottom: '10px' }}>
              {name}
            </span>
          ))}
        </div>
      </>
    ) : (
      <NoResults />
    );
  };

  return (
    <Box>
      {(isFetchingModules || isFetchingPolicyGroups) && <Loading />}
      {(modules.length === 1 || policyGroups.length === 0) && <NoResults />}
      <DefaultDataTableNext
        columns={
          (accessEditPolicyGroup
            ? [...editColumn, ...commonColumns]
            : [...commonColumns]) as ColumnDef<Record<string, unknown>>[]
        }
        data={policyGroups}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'end',
        }}
        customHeader={
          <Select
            id="datatable-selectfilter"
            data-testid="datatable-selectfilter"
            label={t('pages.managePolicyGroups.showPolicyGroupsFor')}
            value={selectedModuleId}
            options={modules.map(({ moduleId, name }: ModuleResponse) => {
              return { key: `${moduleId}`, desc: name };
            })}
            onChange={onSelectModule}
            inputProps={{
              ref: dropdownFocus,
            }}
            sx={{ width: '18%' }}
          />
        }
        renderRowSubComponent={(row) => (
          <Box sx={{ width: '60em', margin: 'auto' }}>
            <PoliciesInfo
              policies={row?.original?.policies as [PoliciesResponse]}
            />
          </Box>
        )}
      />
    </Box>
  );
}
export default ManagePolicyGroups;
