import { useEffect, useState } from 'react';

import {
  MessageActionType,
  MessageType,
} from '@revenue-solutions-inc/revxcoreui';
import Loading from 'components/Loading';
import NoResults from 'components/NoResults';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import { setHeader } from 'redux/contentSlice';
import { useAppDispatch } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';

import {
  CorrespondenceTypeManagerResponse,
  useCorrespondenceTypesQuery,
} from 'generated/graphql';
import ManagerPage from '../../../../components/correspondenceAndHeaderFooter/ManagerPage/ManagerPage';

function CorrespondenceTypes(): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const {
    data: templateData,
    isFetching: isFetching,
    isError: error,
    refetch,
  } = useCorrespondenceTypesQuery();
  useEffect(() => {
    if (error) {
      dispatch(
        addMessage({
          message: t('pages.manageReusableContent.networkError'),
          type: MessageType.Error,
          actionType: MessageActionType.None,
        })
      );
    }
    dispatch(setHeader({ pageTitle: t('pages.templates.title') }));
  }, [error, dispatch, t]);

  const [sortedCorrespondenceTypes, setSortedCorrespondenceTypes] =
    useState<CorrespondenceTypeManagerResponse[]>();

  useEffect(() => {
    if (templateData?.CorrespondenceTypes) {
      const data: CorrespondenceTypeManagerResponse[] =
        templateData?.CorrespondenceTypes?.sort((a, b) => {
          if (a?.createdDate && b?.createdDate) {
            return Date.parse(b.createdDate) - Date.parse(a.createdDate);
          } else return -1;
        });
      setSortedCorrespondenceTypes(data);
    }
  }, [templateData?.CorrespondenceTypes]);

  return (
    <>
      {isFetching && <Loading />}

      {!isFetching && sortedCorrespondenceTypes && (
        <ManagerPage
          correspondence_data={sortedCorrespondenceTypes.map((item) => ({
            ...item,
            hasActiveTemplate: item.hasActiveTemplate == 'true' ? 'Yes' : 'No',
            latestStatus: item.latestStatus == '' ? '-' : item.latestStatus,
            correspondenceTypeCategoryName:
              item.correspondenceTypeCategoryName == ''
                ? '-'
                : item.correspondenceTypeCategoryName,
          }))}
          isFromTemplate={true}
          reFetch={refetch}
          rc_data={[]}
        />
      )}

      {!isFetching && isEmpty(templateData?.CorrespondenceTypes) && (
        <NoResults />
      )}
    </>
  );
}

export default CorrespondenceTypes;
