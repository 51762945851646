import { useContext, useState, useCallback, useMemo } from 'react';
import { Checkbox, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import {
  MessageType,
  MessageActionType,
} from '@revenue-solutions-inc/revxcoreui';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import { default as RsiCheckbox } from '@revenue-solutions-inc/revxcoreui/material/controls/Checkbox';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import {
  Column,
  Row,
  CellContext,
  ColumnDef,
  ColumnDefTemplate,
} from '@tanstack/react-table';
import { AssignedRolesContext } from 'components/contexts/AssignedRoles/AssignedRolesProvider';
import { AssignAction } from 'hooks/useAssignRoleUsers';
import useGetAccessToken from 'hooks/useGetAccessToken';
import useMutationRequest from 'hooks/useMutationRequest';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import { AssignedUserRole } from 'types/roles';
import useTenantZone from 'hooks/useTenantZone';
import { updateRoleQuery } from '../AvailableRoles/AvailableRolesQuery';
import EditableButtonCell from '../EditableButtonCell/EditableButtonCell';
import EditableDatesCell from '../EditableDatesCell/EditableDatesCell';

export default function AssignedRoles() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isFetching: isFetchingTenantZone, tenantDate } = useTenantZone();
  const { assignedRoles } = useContext(AssignedRolesContext);
  const [selectedUserRolesRows, setSelectedUserRolesRows] = useState<string[]>(
    []
  );
  const [notifyUser, setNotifyUser] = useState<boolean>(true);
  const [filteredRows, setFilteredRows] = useState<AssignedUserRole[]>([]);
  const [isSeaching, setIsSeaching] = useState<{
    status: boolean;
    value: string;
  }>({
    status: false,
    value: '',
  });
  const accessToken = useGetAccessToken();
  const { mutate: removeRole } = useMutationRequest();

  const unAssignRoles = () => {
    if (assignedRoles && assignedRoles.userRole) {
      const {
        refetch,

        userRole: { userId },
      } = assignedRoles;
      const userRoleMapEntries = selectedUserRolesRows.map((roleId) => {
        return {
          roleId,
          userId,
        };
      });

      removeRole(
        {
          params: {
            userRoleMapEntries,
            mapAction: AssignAction.UNASSIGN,
            notifyUser: notifyUser,
          },
          paramsId: 'user',
          mutationKey: 'removeUserRole',
          query: updateRoleQuery,
          token: accessToken,
        },
        {
          onSuccess: () => {
            setSelectedUserRolesRows([]);
            dispatch(
              addMessage({
                message: t('components.message.success'),
                type: MessageType.Success,
                actionType: MessageActionType.None,
              })
            );
            refetch();
          },
          onError: () => {
            dispatch(
              addMessage({
                message: t('components.message.networkerror'),
                type: MessageType.Error,
                actionType: MessageActionType.None,
              })
            );
          },
        }
      );
    }
  };

  const handleCheckAll = useMemo((): boolean => {
    if (isSeaching.status) {
      if (filteredRows.length > 0) {
        return filteredRows.every((cRole) =>
          selectedUserRolesRows.includes(cRole.roleId)
        );
      } else return false;
    } else {
      return (
        selectedUserRolesRows.length ===
        assignedRoles?.userRole?.userRole?.length
      );
    }
  }, [
    assignedRoles?.userRole?.userRole?.length,
    filteredRows,
    isSeaching.status,
    selectedUserRolesRows,
  ]);

  const userRolesColumn: ColumnDef<AssignedUserRole>[] = [
    {
      id: 'roleId',
      header: () => {
        return (
          <Checkbox
            id={'selectAllCheck'}
            checked={handleCheckAll}
            onChange={(event) => {
              let newSelectedRows: Array<string> = [];
              if (event.target.checked) {
                if (isSeaching.status) {
                  newSelectedRows = filteredRows.map((d) => d.roleId);
                  if (selectedUserRolesRows.length > 0)
                    newSelectedRows = [
                      ...selectedUserRolesRows,
                      ...newSelectedRows,
                    ];
                } else if (
                  assignedRoles?.userRole?.userRole &&
                  assignedRoles.userRole.userRole.length > 0
                ) {
                  newSelectedRows = assignedRoles.userRole.userRole.map(
                    (d) => d.roleId
                  );
                }
              } else if (
                isSeaching.status &&
                selectedUserRolesRows.length > 0
              ) {
                newSelectedRows = selectedUserRolesRows.filter(
                  (sRole) =>
                    !filteredRows.map((cRole) => cRole.roleId).includes(sRole)
                );
              }
              setSelectedUserRolesRows(newSelectedRows);
            }}
          />
        );
      },
      cell: ({ row }) => {
        return (
          <Checkbox
            id={'roleChk'}
            checked={
              selectedUserRolesRows.findIndex(
                (p) => p === row.original.roleId
              ) > -1
            }
            onChange={(event) => {
              let newRoles = [...selectedUserRolesRows];
              if (event.target.checked) {
                newRoles.push(row.original.roleId);
              } else {
                newRoles = newRoles.filter((p) => p !== row.original.roleId);
              }
              setSelectedUserRolesRows(newRoles);
            }}
          />
        );
      },
    },
    {
      id: 'roleName',
      accessorKey: 'roleName',
      header: () => (
        <HeaderColumnNext localization={t('pages.editUserRole.roleName')} />
      ),
    },
    {
      id: 'roleDescription',
      accessorKey: 'roleDescription',
      header: () => (
        <HeaderColumnNext localization={t('pages.editUserRole.description')} />
      ),
    },
    {
      id: 'moduleName',
      accessorKey: 'moduleName',
      header: () => (
        <HeaderColumnNext localization={t('pages.editUserRole.module')} />
      ),
    },
    {
      id: 'startEndDate',
      accessorKey: 'startEndDate',
      header: () => (
        <HeaderColumnNext localization={t('pages.editUserRole.startEndDate')} />
      ),
      cell: EditableDatesCell as unknown as ColumnDefTemplate<
        CellContext<AssignedUserRole, unknown>
      >,
    },
    {
      id: 'buttons',
      header: () => <HeaderColumnNext localization={''} />,
      cell: EditableButtonCell as unknown as ColumnDefTemplate<
        CellContext<AssignedUserRole, unknown>
      >,
    },
  ];

  const getSeachingValue = useCallback((value: string) => {
    const valueLength = value.length > 0;
    setIsSeaching({ status: valueLength, value: value });
  }, []);

  const getFilteredRows = useCallback(
    (newFilteredRows: Row<AssignedUserRole>[]) => {
      if (isSeaching.status && isSeaching.value.length > 0) {
        const newOriginalRows: AssignedUserRole[] = newFilteredRows.map(
          (row) => row.original
        );
        setFilteredRows(newOriginalRows);
      }
    },
    [isSeaching]
  );

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sx={{
          flexGrow: '1 !important',
          maxWidth: '100% !important',
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography sx={{ mt: 1, mb: 2 }} variant="h4">
            {t('pages.editUserRole.tenantTimeZone', {
              tenantTimeZone: `${
                (!isFetchingTenantZone && tenantDate?.timezone) ?? ''
              }`,
            })}
          </Typography>

          {assignedRoles && assignedRoles?.userRole && (
            <DefaultDataTableNext
              columns={userRolesColumn as Column<AssignedUserRole>[]}
              data-testid="user-roles-data"
              data={assignedRoles?.userRole?.userRole ?? []}
              getSeachingValue={getSeachingValue}
              getFilteredRows={getFilteredRows}
            />
          )}
          {selectedUserRolesRows.length !== 0 && (
            <Grid
              item
              xs={12}
              sx={{
                marginBottom: '-4.2em',
                marginLeft: '1em',
                paddingTop: '2.2em',
                width: '100%',
              }}
              data-testid="remove-button"
            >
              <Box
                sx={{
                  marginBottom: '4em',
                  width: '100%',
                }}
              >
                <Typography>
                  {t('pages.editUserRole.changesEffectiveMessage')}
                </Typography>
                <Button
                  id="button-assignSelectedRoles"
                  onClick={unAssignRoles}
                  sx={{ mt: '1em', mb: '1em', mr: '2em', width: '100%' }}
                >
                  {selectedUserRolesRows.length > 1
                    ? t('pages.editUserRole.removeRoles')
                    : t('pages.editUserRole.removeRole')}
                </Button>
                <RsiCheckbox
                  checked={notifyUser}
                  onChange={(e) => setNotifyUser(e.target.checked)}
                  id={'notifyUser-assignedRoles'}
                  label={`${t('pages.editUserRole.sendEmailMessage')} ${
                    assignedRoles?.userRole?.name
                  }`}
                />
              </Box>
            </Grid>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}
