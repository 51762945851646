import { useEffect, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogContent,
  Stack,
  Typography,
  Box,
  Theme,
  SelectChangeEvent,
  Button,
} from '@mui/material';
import { Select, TabPanel } from '@revenue-solutions-inc/revxcoreui';
import { useVariantGroupData } from 'hooks/workflow/useGroupVariantData';
import { PanelProps, VariantGroup } from '../types';

type PanelLayoutProps = PanelProps & {
  isEdit?: boolean;
  variantType: string;
  setGroups: (grps: VariantGroup[]) => void;
  onChangeSelectedVariant: (e: SelectChangeEvent<string | number>) => void;
  openAddModal: () => void;
  closeAddModal: () => void;
  addModalOpen: boolean;
  children?: ReactNode | null;
  selectedVariant: string;
  onAddItem: () => void;
  variantOptions: { key: string; desc: string }[];
};

export const PanelLayout = ({
  selectedIndex,
  index,
  variantType,
  isEdit,
  setGroups,
  onChangeSelectedVariant,
  openAddModal,
  closeAddModal,
  addModalOpen,
  children,
  selectedVariant,
  onAddItem,
  variantOptions,
}: PanelLayoutProps) => {
  const { t } = useTranslation();

  const { variantGroup } = useVariantGroupData(variantType);

  useEffect(() => {
    if (variantGroup) {
      setGroups(variantGroup.variants as VariantGroup[]);
    }
  }, [variantGroup, setGroups]);

  return (
    <div className="crm-tab__panel">
      <TabPanel
        selectedIndex={selectedIndex}
        index={index}
        sx={{ width: '100%', position: 'relative' }}
      >
        <Box
          sx={(theme: Theme) => ({
            backgroundColor: 'background.paper',
            p: theme.spacing(2),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            width: '100%',
            mb: theme.spacing(2),
          })}
        >
          <Button
            id="crm-add-notes-btn"
            onClick={openAddModal}
            disabled={!isEdit}
            variant="contained"
          >
            {variantType === 'Notes'
              ? t('pages.workflow.addNotes')
              : t('pages.workflow.addSuppression')}
          </Button>
        </Box>
        <Box
          sx={{
            overflow: 'auto',
            maxHeight: '200px',
          }}
        >
          {children}
        </Box>
      </TabPanel>
      <Dialog
        open={addModalOpen}
        onClose={closeAddModal}
        aria-labelledby="add-crm-items"
        aria-describedby="add-items"
      >
        <DialogContent sx={{ minWidth: 320 }}>
          <Stack spacing={2}>
            <Typography variant="h2" gutterBottom>
              {variantType === 'Notes'
                ? t('pages.workflow.addNote')
                : t('pages.workflow.addSuppression')}
            </Typography>
            <Select
              label={t('pages.workflow.selectType')}
              id="crm-tab-note-type-select"
              onChange={onChangeSelectedVariant}
              value={selectedVariant}
              options={variantOptions}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                id="add-note-modal-btn"
                disabled={!selectedVariant}
                onClick={onAddItem}
                variant="contained"
              >
                {variantType === 'Notes'
                  ? t('pages.workflow.addNote')
                  : t('pages.workflow.addSuppression')}
              </Button>
            </Box>
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
};
