import { useState, useEffect, useCallback, useMemo, useRef } from 'react';

import { Box } from '@mui/material';
import { MessageType } from '@revenue-solutions-inc/revxcoreui';
import { ConfigurationDomains } from 'common/platformConfigUtils/platformConfigUtils';
import EntityManagementContext from 'components/contexts/EntityManagement';
import { updateLayoutConfiguration } from 'components/entityManagement/common/accountUtils';
import {
  buildAssetRequest,
  AssetSectionNames,
} from 'components/entityManagement/common/assetUtils';
import { defaultSectionsConfig } from 'components/entityManagement/common/defaults/asset';
import {
  buildExtendedValues,
  isCommenceDateValid,
  isCeaseDateValid,
  CoreSchema,
} from 'components/entityManagement/common/entityManagementUtils';
import ExtendedAttributes from 'components/entityManagement/common/ExtendedAttributes';
import {
  AddressFields,
  IdentifierFields,
  NameFields,
  OtherFields,
} from 'components/entityManagement/common/fields/assets';
import {
  getIdFormats,
  getMaskRules,
  getValidationRules,
} from 'components/entityManagement/common/formatValidations';
import Loading from 'components/Loading';
import HorizontalNonLinearStepper from 'components/stepper/HorizontalNonLinearStepper';
import {
  Asset,
  CreateAssetMutation,
  useGetConfigurationSchemaQuery,
  useCreateAssetMutation,
  useGetLookupConfigurationQuery,
  useGetSchemaGroupsQuery,
  useGetConfigurationSchemaByIdQuery,
} from 'generated/graphql';
import { Control, FieldValues, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import { AssetForm, StepsConfig } from 'types/assets';
import { ExtendedAttributeValues, Section } from 'types/forms';
import { Error } from 'types/graphqlErrors';
import extractMeaningfulMessage from 'utils/errorMessage';

import ConfirmationAsset from '../ConfirmationAsset';
import SectionContainer from '../SectionContainer';

interface Props {
  formMethods: UseFormReturn<AssetForm, unknown>;
  asset: AssetForm;
}

function AssetLayout({ formMethods, asset }: Props): JSX.Element {
  const sections = ['Asset', 'Name', 'Identifier', 'Address', 'Confirmation'];
  const CONFIG_DOMAIN = ConfigurationDomains.ReferenceSchema;
  const CORE_DOMAIN = ConfigurationDomains.EntityManagement;
  const CONFIRMATION_STEP = 4;
  const { mutate: createAsset } = useCreateAssetMutation({});
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const module = useAppSelector((state) => state.user.module);
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [currentDirty, setCurrentDirty] = useState<boolean>(false);
  const [showOptional, setShowOptional] = useState<boolean>(false);
  const [saveSuccessful, setSaveSuccessful] = useState<boolean>(false);
  const [isAssetSaving, setAssetSaving] = useState<boolean>(false);
  const [commenceDate, setCommenceDate] = useState<string>('');
  const [assetType, setAssetType] = useState<string>('');
  const [idType, setIdType] = useState<string>('');
  const [validated, setValidated] = useState<{ [k: number]: boolean }>({});
  const [extendedAttribute, setExtendedAttribute] = useState<string>('');
  const [extendedVals, setExtendedVals] = useState<ExtendedAttributeValues[]>(
    []
  );
  const sectionsConfig = useRef<StepsConfig[]>([...defaultSectionsConfig]);

  const {
    control,
    formState,
    register,
    trigger,
    handleSubmit,
    getFieldState,
    getValues,
    setValue,
    reset,
  } = formMethods;

  /**
   * Used to get information about the configuration schema
   */
  const {
    data: layoutData,
    error: layoutError,
    isFetching: isLayoutFetching,
  } = useGetConfigurationSchemaQuery(
    {
      configurationDomain: CORE_DOMAIN,
      configurationName: CoreSchema.Asset,
    },
    {
      enabled: assetType !== '',
      onSuccess: (data) =>
        updateLayoutConfiguration(data.GetConfigurationSchema),
    }
  );

  /**
   * Used to get information about the Identifiers configuration, specifically
   * to establish ID formats, masks, and validations data
   */
  const { data: configuredIdTypes } = useGetLookupConfigurationQuery({
    configurationDomain: CONFIG_DOMAIN,
    configurationType: 'IdType',
  });

  /**
   * Used to get the information related with extended attributes and builds
   * internal objects to be used in the forms
   */
  const {
    data: extendedAttrData,
    isFetching: isFetchingExtended,
    refetch: refetchExtended,
  } = useGetConfigurationSchemaByIdQuery(
    {
      platformConfigurationId: extendedAttribute,
    },
    {
      onSuccess: (extendedAttributes) => {
        const extAttributes =
          extendedAttributes?.GetConfigurationSchemaById
            .platformConfigurationInfo?.configurationSection[0].group[0]
            .attribute;
        if (extAttributes) {
          const newExtendedVals = buildExtendedValues(extAttributes);
          setExtendedVals(newExtendedVals);
        } else setExtendedVals([]);
      },
      onError: () => {
        setExtendedVals([]);
      },
    }
  );

  /**
   * Using getSchemaGroups to check if the extended attributes are available
   */
  const { isFetching: isFetchingSchemaGroups } = useGetSchemaGroupsQuery(
    {
      configurationDomain: CONFIG_DOMAIN,
      configurationType: 'AssetType',
      configurationName: assetType,
    },
    {
      enabled: assetType !== '',
      onSuccess: (schemaGroups) => {
        const foundExtended = schemaGroups.GetSchemaGroups.find(
          (sectionItem) => sectionItem.attributeName === 'ExtendedAttribute'
        );
        if (foundExtended) setExtendedAttribute(foundExtended.attributeValue);
        else {
          setExtendedAttribute('');
        }
      },
      onError: () => {
        setExtendedAttribute('');
      },
    }
  );

  /**
   * Sets the extended attributes values in its state
   * @param newExtendedValues The new values of extended attributes
   */
  const handleExtendedAttributeValues = (
    newExtendedValues: ExtendedAttributeValues[]
  ) => {
    setExtendedVals(newExtendedValues);
  };

  /**
   * Validates all the extended attributes when required
   * @returns If extended attributes are valid or not
   */
  const checkExtendedAttributes = (): boolean => {
    if (currentStep === 0) {
      let invalidExists = false;
      const newExtendedVals: ExtendedAttributeValues[] = [];
      extendedVals.forEach((attr) => {
        if (attr.isRequired && attr.isRequired === true && attr.value === '') {
          invalidExists = true;
        }
        newExtendedVals.push({
          ...attr,
          isDirty: true,
        });
      });
      setExtendedVals(newExtendedVals);
      if (invalidExists) return false;
      return true;
    }
    return true;
  };

  /**
   * Saves a new asset
   * @param assetData Data to be saved as an asset
   * @returns If the asset was successfully saved
   */
  const saveAsset = async (assetData: Asset) => {
    return createAsset(
      {
        asset: assetData,
      },
      {
        onSuccess: async (data: CreateAssetMutation) => {
          navigate(`/${module}/asset/${data.CreateAsset?.id}`);
          dispatch(
            addMessage({
              type: MessageType.Success,
              message: t('pages.createAsset.message.success'),
            })
          );
          setSaveSuccessful(true);
        },
        onError: async (e: Error[] | unknown) => {
          let message: string = t('pages.createAsset.message.error');
          message = extractMeaningfulMessage(e, message);
          dispatch(
            addMessage({
              type: MessageType.Error,
              message: message,
            })
          );
          setSaveSuccessful(false);
        },
        onSettled: () => {
          setAssetSaving(false);
        },
      }
    );
  };

  const onSubmit = (data: AssetForm) => {
    const platformConfigId =
      layoutData?.GetConfigurationSchema.platformConfigurationId;
    if (layoutError || !platformConfigId) {
      dispatch(
        addMessage({
          type: MessageType.Error,
          message: t('pages.createAsset.message.error'),
        })
      );
    } else {
      setAssetSaving(true);
      const assetResponse = buildAssetRequest(
        data,
        assetType,
        platformConfigId,
        extendedVals
      );
      saveAsset(assetResponse);
    }
  };

  function handleCurrentStep(activeStep: number) {
    setCurrentStep(activeStep);
  }

  const getSectionFields = (): Section => {
    if (currentStep === 0) return OtherFields;
    else if (currentStep === 1) return NameFields;
    else if (currentStep === 2) return IdentifierFields;
    return AddressFields;
  };

  /**
   * Used to get the current section using the name or the current step
   */
  const findSection = useCallback(
    (name?: string): StepsConfig | undefined => {
      return sectionsConfig.current.find((section) =>
        name ? section.name === name : section.step === currentStep
      );
    },
    [currentStep, sectionsConfig]
  );

  /**
   * Used to determine if the section is required by default
   */
  const isSectionRequired = useCallback((): boolean => {
    if (currentStep === CONFIRMATION_STEP) return false;
    const section = findSection();
    return section?.isRequired ?? true;
  }, [currentStep, findSection]);

  /**
   * Controls whether the user wants to fill the form of an optional section
   * @param display Controls if section is displayed or not
   */
  const showOptionalSection = (display: boolean) => {
    const section = findSection();
    if (section) {
      const idxSection = sectionsConfig.current.indexOf(section);
      const sectionUpdate: StepsConfig = {
        ...section,
        showOptional: display,
      };
      sectionsConfig.current.splice(idxSection, 1, sectionUpdate);
    }
    setShowOptional(display);
  };

  /**
   * Sets the validation result for the current step
   * @param isValid If step is valid or not
   */
  const setStepValidationResult = (isValid: boolean) => {
    const newValidated = validated;
    newValidated[currentStep] = isValid;
    setValidated(newValidated);
  };

  /**
   * Validates if only one primary is selected for each section
   * @param sectionId Section name
   * @returns If the primary selected is valid or not
   */
  const checkPrimaries = (sectionId: AssetSectionNames): boolean => {
    const sectionValues = getValues(sectionId);
    const VALID_PRIMARIES = 1;
    let isPrimaryCount = 0;
    if (sectionValues) {
      sectionValues.forEach((value) => {
        if ('isPrimary' in value && value.isPrimary === 'true')
          isPrimaryCount++;
      });
      if (isPrimaryCount === VALID_PRIMARIES) return true;
      else {
        dispatch(
          addMessage({
            type: MessageType.Error,
            message: t('pages.createAsset.message.primariesError'),
          })
        );
        return false;
      }
    }
    return true;
  };

  /**
   * Validates commence date on the section to ensure it is not earlier than Asset commence date
   * @param sectionId Name of the section
   * @returns If the commence date of the section is valid or not
   */
  const checkCommenceDate = (sectionId: AssetSectionNames): boolean => {
    const assetCommenceDate = getValues('other')?.[0].commenceDate;
    let isValid = true;
    const sectionValues = getValues(sectionId);
    if (sectionValues) {
      sectionValues.forEach((value) => {
        if (
          value.commenceDate &&
          isValid &&
          !isCommenceDateValid(value.commenceDate, assetCommenceDate)
        ) {
          isValid = false;
          dispatch(
            addMessage({
              type: MessageType.Error,
              message: t('pages.createAsset.message.commenceDateError'),
            })
          );
        }
      });
    }
    return isValid;
  };

  /**
   * Checks the cease date is set before the commence date
   * @param sectionId Name of the section
   * @returns If the cease date is valid or not
   */
  const checkCeaseDate = (sectionId: AssetSectionNames): boolean => {
    const assetCommenceDate = getValues('other')?.[0].commenceDate;
    let isValid = true;
    const sectionValues = getValues(sectionId);
    if (sectionValues) {
      sectionValues.forEach((value) => {
        if (
          value.ceaseDate &&
          isValid &&
          !isCeaseDateValid(
            value.commenceDate,
            value.ceaseDate,
            assetCommenceDate
          )
        ) {
          isValid = false;
          dispatch(
            addMessage({
              type: MessageType.Error,
              message: t('pages.createAsset.message.ceaseDateError'),
            })
          );
        }
      });
    }
    return isValid;
  };

  /**
   * Determines the validation scenario for the current step when 'Next Step' is clicked
   */
  async function stepValidationProcess() {
    if (isSectionRequired() || showOptional) {
      let commenceDateCheck = true;
      let checkPrimary = true;
      const section = findSection();
      const sectionName = section?.name as keyof typeof asset;
      const validateRules = await trigger(sectionName);
      if (currentStep !== 0) {
        checkPrimary = checkPrimaries(sectionName);
        commenceDateCheck = checkCommenceDate(sectionName);
      }
      const ceaseDateCheck = checkCeaseDate(sectionName);
      const extendedAttrCheck = checkExtendedAttributes();
      setStepValidationResult(
        currentDirty &&
          validateRules &&
          checkPrimary &&
          commenceDateCheck &&
          ceaseDateCheck &&
          extendedAttrCheck
      );
    } else if (currentStep === CONFIRMATION_STEP || !showOptional) {
      setStepValidationResult(true);
    }
  }

  /**
   * Effectively refetch extended attributes when asset type is changed
   */
  useEffect(() => {
    refetchExtended();
  }, [assetType, refetchExtended]);

  /**
   * If the current section is optional, this sets a default behavior
   */
  useEffect(() => {
    const sectionFound = sectionsConfig.current.find(
      (section) => section.step === currentStep
    );
    setShowOptional(sectionFound?.showOptional ?? false);
  }, [currentStep, sectionsConfig]);

  /**
   * Validates if the current section form was 'touched' or not
   */
  useEffect(() => {
    if (isSectionRequired()) {
      const section = findSection();
      const sectionName = section?.name as keyof typeof asset;
      const { isDirty } = getFieldState(sectionName, formState);
      setCurrentDirty(isDirty);
    } else {
      setCurrentDirty(true);
    }
  }, [formState, showOptional, findSection, getFieldState, isSectionRequired]);

  /**
   * Provides the needed values to the Entity/Asset Context
   */
  const contextValue = useMemo(() => {
    const idTypeChangeHandler = (selectedIdType: string) => {
      setIdType(selectedIdType);
    };
    const commenceDateHandler = (selectedCommenceDate: string) => {
      setCommenceDate(selectedCommenceDate);
    };
    const assetTypeChangeHandler = (selectedAssetType: string) => {
      setAssetType(selectedAssetType);
      formMethods.clearErrors();
    };

    return {
      selectedIdType: idType,
      selectedType: assetType,
      selectedCommenceDate: commenceDate,
      onTypeChange: assetTypeChangeHandler,
      onIdTypeChange: idTypeChangeHandler,
      onCommenceDateChange: commenceDateHandler,
      idValidationRules: getValidationRules(configuredIdTypes),
      idFormats: getIdFormats(configuredIdTypes),
      idMaxLengths: getMaskRules(configuredIdTypes),
    };
  }, [idType, assetType, commenceDate, configuredIdTypes, formMethods]);

  return (
    <EntityManagementContext.Provider value={contextValue}>
      <HorizontalNonLinearStepper
        steps={sections}
        saveSuccessful={saveSuccessful}
        currentStep={currentStep}
        handleCurrentStep={handleCurrentStep}
        handleSave={handleSubmit(onSubmit)}
        handleFormReset={reset}
        handleStepValidation={stepValidationProcess}
        validatedSteps={validated}
        saveBtnLabel={t('pages.createAsset.title')}
        resetBtnLabel={t('pages.assetSummary.actions.reset')}
        nonLinear={false}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          {((isLayoutFetching && assetType !== '') || isAssetSaving) && (
            <Loading />
          )}
          {currentStep !== CONFIRMATION_STEP && (
            <SectionContainer
              section={getSectionFields()}
              isRequired={isSectionRequired()}
              showOptional={showOptional}
              handleShowOptional={showOptionalSection}
              {...{
                control: control as Control<FieldValues, unknown>,
                register,
                asset,
                getValues,
                setValue,
              }}
            />
          )}
          {(isFetchingExtended || isFetchingSchemaGroups) && (
            <Box sx={{ marginTop: '15px' }}>
              <Loading fullScreen={false} />
            </Box>
          )}
          {currentStep === 0 && (
            <ExtendedAttributes
              extendedAttributes={
                extendedAttrData?.GetConfigurationSchemaById
                  .platformConfigurationInfo?.configurationSection[0].group[0]
                  .attribute ?? []
              }
              extendedValues={extendedVals}
              handleExtendedValues={handleExtendedAttributeValues}
            />
          )}
          {currentStep === CONFIRMATION_STEP && (
            <>
              <ConfirmationAsset
                data={getValues()}
                extendedData={extendedVals}
              />
            </>
          )}
        </form>
      </HorizontalNonLinearStepper>
    </EntityManagementContext.Provider>
  );
}

export default AssetLayout;
