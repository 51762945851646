import { Fragment, useEffect, useState } from 'react';

import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { Grid, IconButton, Typography } from '@mui/material';
import { Button, Input } from '@revenue-solutions-inc/revxcoreui';
import ControlledInputField from 'components/controls/ControlledInputField';
import Checkbox from '@revenue-solutions-inc/revxcoreui/material/controls/Checkbox';
import ControlledSelectField from 'components/controls/ControlledSelectField';
import Select, {
  SelectType,
} from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import { useTranslation } from 'react-i18next';
import { FileInput, fileInputDefault, Channel } from 'types/channels';
import {
  Control,
  UseFormSetValue,
  UseFormWatch,
  UseFormGetValues,
  useFieldArray,
  useWatch,
  Controller,
} from 'react-hook-form';
import useMaskInput from 'hooks/useMaskInput';
import { useParams } from 'react-router-dom';

// TODO : replace hard coded select options with lookup
const fileGroupTypes: SelectType[] = [
  { key: 'fixed length', desc: 'FIXED LENGTH' },
  { key: 'delimited', desc: 'DELIMITED' },
  { key: 'xml', desc: 'XML' },
  { key: 'excel', desc: 'EXCEL' },
];

const delimiterTypes: SelectType[] = [
  { key: '  ', desc: 'Tab' },
  { key: ';', desc: 'Semicolon' },
  { key: ',', desc: 'Comma' },
  { key: ' ', desc: 'Space' },
  { key: 'other', desc: 'Other' },
];

enum ActionType {
  EDIT = 'edit',
  CREATE = 'create',
}

// TODO : replace hard coded select options with lookup
const fileProcessingTypes: SelectType[] = [
  { key: 'Full', desc: 'Full' },
  { key: 'Partial', desc: 'Partial' },
];

interface Props {
  displayFields: boolean;
  uploadFileChannel: boolean;
  setFileParametersValid: React.Dispatch<React.SetStateAction<boolean>>;
  control: Control<Channel, unknown>;
  getValues: UseFormGetValues<Channel>;
  watch: UseFormWatch<Channel>;
  setValue: UseFormSetValue<Channel>;
  setDelimiterValue: React.Dispatch<React.SetStateAction<string>>;
  delimiterValue: string;
}

function FileDefinitionFields({
  uploadFileChannel,
  displayFields,
  setFileParametersValid,
  control,
  getValues,
  watch,
  setValue,
  setDelimiterValue,
  delimiterValue,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const { action } = useParams() as {
    action: string;
  };

  const [delimiterValueDisabled, setDelimiterValueDisabled] =
    useState<boolean>(false);

  const [headerDisabled, setHeaderDisabled] = useState<boolean>(false);

  const [addChannelFileDisabled, setAddChannelFileHeaderDisabled] =
    useState<boolean>(false);
  const [fileTypes, setFileTypes] = useState<SelectType[]>([]);
  const [fileGroupType, setFileGroupType] = useState<string>('initial');

  const fileProcessingMask = useMaskInput('99', '', /^[0-9]{2}$/);

  const { fields, append } = useFieldArray({
    control,
    name: 'Channel.FileGroup.Files',
  });

  const FileGroup = useWatch({
    control,
    name: 'Channel.FileGroup',
  });

  const fileGroupValue = getValues(`Channel.FileGroup.FileGroupType`);

  const fileInput = useWatch({
    control,
    name: 'Channel.FileGroup.Files',
  });

  const fileNameArray = watch(`Channel.FileGroup.Files`)?.map(function (item) {
    return item.FileName;
  });
  const isNameDuplicate = fileNameArray.some(function (item, idx) {
    return fileNameArray.indexOf(item) != idx;
  });

  const orderArray = watch(`Channel.FileGroup.Files`)?.map(function (item) {
    return item.FileProcessingOrder;
  });
  const isOrderDuplicate = orderArray.some(function (item, idx) {
    return orderArray.indexOf(item) != idx;
  });

  const validateRequiredFields = () => {
    if (fileGroupType.toLowerCase() != 'initial') {
      if (
        FileGroup.FileGroupType.toLowerCase() != fileGroupType.toLowerCase()
      ) {
        fields.forEach((item: Record<'id', string>, index: number) => {
          setValue(`Channel.FileGroup.Files.${index}.HasHeader`, false);
        });
      }
      setFileGroupType(FileGroup.FileGroupType.toLowerCase());
    } else {
      setFileGroupType(FileGroup.FileGroupType.toLowerCase());
    }
    let isValid = true;
    fileInput?.map((value: FileInput) => {
      if (
        FileGroup.FileGroupType === '' ||
        FileGroup.FileGroupName === '' ||
        FileGroup.FileProcessingType === '' ||
        FileGroup.FileType === '' ||
        isNameDuplicate === true ||
        isOrderDuplicate === true
      ) {
        isValid = false;
      } else if (
        value.FileName === '' ||
        value.FileMetadata === '' ||
        !Number.isFinite(value.FileProcessingOrder)
      ) {
        isValid = false;
      } else if (
        FileGroup.FileGroupType.toLowerCase() == 'fixed length' &&
        FileGroup.DelimiterValue.length !== 0
      ) {
        isValid = false;
      } else if (
        FileGroup.FileGroupType.toLowerCase() == 'delimited' &&
        FileGroup.DelimiterValue.length < 1
      ) {
        isValid = false;
      } else if (
        FileGroup.FileGroupType.toLowerCase() == 'xml' &&
        FileGroup.DelimiterValue.length !== 0
      ) {
        isValid = false;
      } else if (
        FileGroup.FileGroupType.toLowerCase() == 'excel' &&
        FileGroup.DelimiterValue.length !== 0
      ) {
        isValid = false;
      }
    });

    setFileParametersValid(isValid);
  };

  const validateSelectedFileGroup = () => {
    const currentFileGroupType = getValues('Channel.FileGroup.FileGroupType');
    const currentFileType = getValues('Channel.FileGroup.FileType');

    let newFileTypes = [];
    let newDelimiterValueDisabled = false;
    let newHeaderDisabled = false;
    let newAddChannelFileHeaderDisabled = false;
    let newFileType = currentFileType;

    switch (currentFileGroupType) {
      case 'fixed length':
        newFileTypes = [{ key: 'txt', desc: 'txt' }];
        newDelimiterValueDisabled = true;
        newFileType = 'txt';
        setValue('Channel.FileGroup.DelimiterValue', '');
        break;

      case 'delimited':
        newFileTypes = [
          { key: 'txt', desc: 'txt' },
          { key: 'csv', desc: 'csv' },
        ];
        newDelimiterValueDisabled = false;
        if (!['txt', 'csv'].includes(currentFileType)) {
          newFileType = 'txt';
        }
        break;

      case 'excel':
        newFileTypes = [
          { key: 'xlx', desc: 'xlx' },
          { key: 'xlsx', desc: 'xlsx' },
        ];
        newDelimiterValueDisabled = true;
        if (!['xlx', 'xlsx'].includes(currentFileType)) {
          newFileType = 'xlx';
        }
        setValue('Channel.FileGroup.DelimiterValue', '');
        break;

      case 'xml':
        newFileTypes = [{ key: 'xml', desc: 'xml' }];
        newDelimiterValueDisabled = true;
        newHeaderDisabled = true;
        newAddChannelFileHeaderDisabled = true;
        newFileType = 'xml';
        setValue('Channel.FileGroup.DelimiterValue', '');
        break;

      default:
        return;
    }

    setValue('Channel.FileGroup.FileType', newFileType);
    setFileTypes(newFileTypes);
    setDelimiterValueDisabled(newDelimiterValueDisabled);
    setHeaderDisabled(newHeaderDisabled);
    setAddChannelFileHeaderDisabled(newAddChannelFileHeaderDisabled);
  };

  useEffect(() => {
    validateSelectedFileGroup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileGroupValue]);

  useEffect(() => {
    validateRequiredFields();
  });

  return uploadFileChannel || displayFields ? (
    <>
      <Grid container>
        <Grid item xs={12} mb={2}>
          <Typography variant="h3">
            {t('pages.manageChannel.fileGroupDetails')}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <ControlledInputField
            control={control}
            rules={{
              required: true,
            }}
            name={`Channel.FileGroup.FileGroupName`}
            required
            id="fileGroupName"
            sx={{ width: '100%', maxWidth: '20em' }}
            label={t('pages.manageChannel.fileGroupName')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <ControlledSelectField
            required
            control={control}
            rules={{
              required: true,
            }}
            id="fileGroupType"
            sx={{ width: '100%', maxWidth: '20em' }}
            options={fileGroupTypes}
            name={`Channel.FileGroup.FileGroupType`}
            label={t('pages.manageChannel.fileGroupType')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <ControlledSelectField
            required
            control={control}
            rules={{
              required: true,
            }}
            id="fileType"
            sx={{ width: '100%', maxWidth: '20em' }}
            options={fileTypes}
            name={`Channel.FileGroup.FileType`}
            label={t('pages.manageChannel.fileType')}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3} mb={3}>
          <ControlledSelectField
            required
            control={control}
            rules={{
              required: true,
            }}
            id="fileProcessingType"
            sx={{ width: '100%', maxWidth: '20em' }}
            options={fileProcessingTypes}
            name={`Channel.FileGroup.FileProcessingType`}
            label={t('pages.manageChannel.fileProcessingType')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            name={`Channel.FileGroup.DelimiterValue`}
            render={({ field: { value, onChange } }) => {
              return (
                <Select
                  required
                  id="channelDelimiterValue"
                  disabled={delimiterValueDisabled}
                  sx={{ width: '100%', maxWidth: '20em' }}
                  label={t('pages.manageChannel.delimiter')}
                  value={value}
                  options={delimiterTypes}
                  onChange={(e) => {
                    onChange(e.target.value);
                    if (e.target.value !== 'Other' && delimiterValue !== '') {
                      setDelimiterValue('');
                    }
                  }}
                />
              );
            }}
          />
        </Grid>
        {watch(`Channel.FileGroup.DelimiterValue`) === 'other' && (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Input
              required
              id="delimiterValue"
              label={t('pages.manageChannel.delimiterValue')}
              value={delimiterValue}
              sx={{ width: '100%', maxWidth: '20em' }}
              onChange={(e) => {
                setDelimiterValue(e.target.value);
              }}
            />
          </Grid>
        )}
        <Grid item xs={12} mb={2} mt={2}>
          <Typography variant="h3">
            {t('pages.manageChannel.fileDetails')}
          </Typography>
        </Grid>
        {fields.map((item: Record<'id', string>, index: number) => (
          <Fragment key={item.id}>
            <Grid item xs={12} sm={6} md={4} lg={3} mb={3}>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                name={`Channel.FileGroup.Files.${index}.FileName`}
                render={({ field: { value, onChange } }) => {
                  return (
                    <Input
                      required
                      id="fileName"
                      label={t('pages.manageChannel.fileName')}
                      value={value}
                      sx={{ width: '100%', maxWidth: '20em' }}
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <ControlledInputField
                required
                control={control}
                rules={{
                  required: true,
                }}
                name={`Channel.FileGroup.Files.${index}.FileMetadata`}
                id="fileMetaData"
                sx={{ width: '100%', maxWidth: '20em' }}
                label={t('pages.manageChannel.fileDescription')}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                name={`Channel.FileGroup.Files.${index}.FileProcessingOrder`}
                render={({ field: { value, onChange } }) => {
                  return (
                    <Input
                      required
                      id="fileProcessingOrder"
                      label={t('pages.manageChannel.fileProcessingOrder')}
                      value={value === null ? '' : value.toString()}
                      sx={{ width: '100%', maxWidth: '20em' }}
                      onChange={(e) => {
                        fileProcessingMask.handleMaskChange(e);
                        onChange(
                          e.target.value === ''
                            ? null
                            : parseInt(e.target.value)
                        );
                        if (action === ActionType.CREATE) {
                          const uniqueValue = new Date().getTime();
                          setValue(
                            `Channel.FileGroup.Files.${index}.FileId`,
                            uniqueValue.toString()
                          );
                        }
                      }}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} lg={1.5} mt={2} pl={0.5}>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                name={`Channel.FileGroup.Files.${index}.HasHeader`}
                render={({ field: { value, onChange } }) => {
                  return (
                    <Checkbox
                      id="channelHeader"
                      label={t('pages.manageChannel.hasHeader')}
                      checked={value}
                      disabled={headerDisabled}
                      onChange={onChange}
                    />
                  );
                }}
              />
            </Grid>
            {index !== 0 ? (
              <Grid item xs={12} sm={6} md={4} lg={1.5} mt={2.5} ml={-4}>
                <IconButton
                  onClick={() => {
                    const deleteValue = [...fields];
                    deleteValue.splice(index, 1);

                    // Creates a new array with objects without the "id" property
                    const cleanedDeleteValue = deleteValue.map((fileItem) => {
                      const { id, ...rest } = fileItem;
                      return rest;
                    });

                    setValue(`Channel.FileGroup.Files`, cleanedDeleteValue);
                  }}
                  aria-label="delete"
                  color="default"
                  size="medium"
                >
                  <CancelRoundedIcon fontSize="medium" />
                </IconButton>
              </Grid>
            ) : (
              <Grid item md={1.5}></Grid>
            )}
          </Fragment>
        ))}
        <Grid item xs={12} pt={2} mb={2}>
          <Button
            id="addChannelFile"
            disabled={addChannelFileDisabled}
            onClick={() => {
              // Creates a new file object with a unique FileId
              const newFileInput = {
                ...fileInputDefault,
                FileId: Date.now().toString(),
              };

              append(newFileInput);
            }}
          >
            {t('pages.manageChannel.addFile')}
          </Button>
        </Grid>
      </Grid>
    </>
  ) : (
    <></>
  );
}

export default FileDefinitionFields;
