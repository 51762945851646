import { formatWorkflowDataForTable } from 'components/AssignedUnassignedWork/tableUtils';
import { WorkflowItem } from 'generated/graphql';

export const useFormatWorkflowDataForTable = () => {
  const formatDataForTable = (data: WorkflowItem[]) =>
    formatWorkflowDataForTable(data);

  return {
    formatDataForTable,
  };
};
