import {
  AttributeTypes,
  BusinessDrivers,
} from 'common/platformConfigUtils/platformConfigUtils';
import {
  Attribute,
  ConfigurationResponse,
  ExtensibleBusinessDriver,
} from 'generated/graphql';
/**
 * Finds all attributes which have the extensible business driver specified
 * @param config The configuration object to search within
 * @param driverType The extensible business driver to match as a number - see exported constants from ExtensibleBusinessDrivers
 * @returns array of Attributes which have the desired driver/constraint
 */
export function findAttributesByDriver(
  config: ConfigurationResponse,
  driverType: number
): Attribute[] {
  //loop through all sections, groups, attributes, and drivers until there is a match
  let attributes: Attribute[] = [];
  config?.platformConfigurationInfo?.configurationSection.forEach((section) => {
    section.group.forEach((group) => {
      const attrsWithDriver = group.attribute.filter((attr) =>
        attr.extensibleBusinessDriver?.some(
          (driver: ExtensibleBusinessDriver) =>
            driver.driverNameType === driverType
        )
      );
      if (attrsWithDriver.length)
        attributes = attributes.concat(attrsWithDriver);
    });
  });
  return attributes;
}

/**
 * Checks if this config has any PK attribute
 * @returns true if there is at least 1 PK attribute
 */
export const hasPKAttr = (configResponse: ConfigurationResponse) => {
  return (
    findAttributesByDriver(configResponse, BusinessDrivers.PrimaryKey).length >=
    1
  );
};

/**
 * Checks all required and PK attributes within a  config to make sure they have values
 * and if the config name is provided (if needed)
 * @returns true if there is a required/ok attribute without a value (should not allow save), false if all required values are present
 */
export function checkRequiredAttr(
  configResponse: ConfigurationResponse,
  selectedRecord: ConfigurationResponse | undefined
) {
  if (hasPKAttr(configResponse)) return false;
  //this means there were no PK attributes.. we don't care if PK attributes have a value or not as that will be handled by validation logic
  //now we need to check if the name is present
  return selectedRecord?.configurationName.trim() === '';
}

/**
 * if this attribute has the constraint/driver specified
 * @returns its value, otherwise returns undefined if the attribute does not have this driver
 */
export function getDriverValue(attribute: Attribute, driverType: number) {
  const foundDriver = attribute.extensibleBusinessDriver?.find(
    (driver) => driver.driverNameType === driverType
  );
  return foundDriver?.driverValue ?? undefined;
}

/**
 * validates this attribute's value based on its constraints (business drivers)
 * @returns error message localization string if any error, blank string if no errors
 */
export function validateConstraints(attribute: Attribute) {
  //TODO add try catch in case some casting doesn't work, driver value isn't the right type etc
  let err = '';
  //validate value
  attribute.extensibleBusinessDriver?.forEach((driver) => {
    const expressionValue = attribute.expressionValue ?? '';
    switch (driver.driverNameType) {
      case BusinessDrivers.Required:
        if (attribute.isExpression) {
          if (expressionValue.trim() === '')
            err = 'pages.attributeInput.required';
        } else if (attribute.attributeValue.trim() === AttributeTypes.boolean) {
          // booleans are skiped even if they are required because booleans always have
          // value true or false.
          break;
        } else if (attribute.attributeValue.trim() === '')
          err = 'pages.attributeInput.required';
        break;
      case BusinessDrivers.PrimaryKey:
        if (attribute.isExpression) {
          if (expressionValue.trim() === '')
            err = 'pages.attributeInput.required';
        } else if (attribute.attributeValue.trim() === '')
          err = 'pages.attributeInput.required';
        break;
      case BusinessDrivers.FieldMinimum:
        //if there is no driver value, skip this validation
        if (!!driver.driverValue) {
          //depending on the type of attribute, this driver behaves differently
          if (attribute.attributeType == AttributeTypes.int) {
            if (Number(attribute.attributeValue) < Number(driver.driverValue))
              err = 'pages.attributeInput.intLow';
          } else if (attribute.attributeType === AttributeTypes.string) {
            if (attribute.attributeValue.length < Number(driver.driverValue))
              err = 'pages.attributeInput.stringLow';
          } else if (attribute.attributeType === AttributeTypes.date) {
            if (
              Date.parse(attribute.attributeValue) <
              Date.parse(driver.driverValue)
            )
              err = 'pages.attributeInput.dateLow';
          } else if (attribute.attributeType === AttributeTypes.currency) {
            if (
              parseFloat(attribute.attributeValue) <
              parseFloat(driver.driverValue)
            )
              err = 'pages.attributeInput.intLow';
          }
        }
        break;
      case BusinessDrivers.FieldMaximum:
        //if there is no driver value, skip this validation
        if (!!driver.driverValue) {
          //depending on the type of attribute, this driver behaves differently
          if (attribute.attributeType === AttributeTypes.int) {
            if (Number(attribute.attributeValue) > Number(driver.driverValue))
              err = 'pages.attributeInput.intHigh';
          } else if (attribute.attributeType === AttributeTypes.string) {
            if (attribute.attributeValue.length > Number(driver.driverValue))
              err = 'pages.attributeInput.stringHigh';
          } else if (attribute.attributeType === AttributeTypes.date) {
            if (
              Date.parse(attribute.attributeValue) >
              Date.parse(driver.driverValue)
            )
              err = 'pages.attributeInput.dateHigh';
          } else if (attribute.attributeType === AttributeTypes.currency) {
            if (
              parseFloat(attribute.attributeValue) >
              parseFloat(driver.driverValue)
            )
              err = 'pages.attributeInput.intHigh';
          }
        }
        break;
      default:
        err = '';
    }
  });
  return err;
}

/**
 * validates this configuration by validating every attribute inside it
 * @returns error object populated with each attributes unique identifier (group+name combo) along with an error message
 * only contains the attributes which have errors. User can check the length of items in this object to determine
 * if this configuration has any errors or not.
 * This error object can be passed down to dynamic attributes and attribute inputs to show the error messages for each input
 */
export function validateConfiguration(config: ConfigurationResponse) {
  const errors: { [key: string]: string } = {};

  config.platformConfigurationInfo?.configurationSection?.forEach((section) => {
    section.group?.forEach((group) => {
      group.attribute.forEach((attribute, j) => {
        if (group.groupAttributeType === 1) {
          attribute.repeatingValue?.forEach((repAttr) => {
            const error = validateConstraints(repAttr);
            if (!!error)
              // this is how we uniquely identify this repeating attribute (row and column) to save its error message
              errors[group.groupName + repAttr.attributeName + j] = error;
          });
        } else {
          const error = validateConstraints(attribute);
          if (!!error)
            errors[group.groupName + attribute.attributeName] = error;
        }
      });
    });
  });
  return errors;
}
