import { useReducer, useContext } from 'react';
import { useNavigate, NavigateOptions, useParams } from 'react-router';
import { SelectChangeEvent } from '@mui/material';
import { useAppSelector } from 'redux/hooks';
import { WorkflowFormContext } from 'components/Workflow/WorkflowFormContext';
import {
  initialState,
  actions,
  suppressionPanelReducer,
} from './suppressionPanelReducer';
import { PanelLayout } from './PanelLayout';
import type { PanelProps, VariantGroup } from '../types';

type SupressionsPanelProps = PanelProps & {
  isEdit?: boolean;
};

const variantType = 'Suppression';

export const SuppressionsPanel = ({
  selectedIndex,
  index,
  isEdit,
}: SupressionsPanelProps) => {
  const navigate = useNavigate();
  const { variantId, key } = useParams() as { variantId: string; key: string };
  const module = useAppSelector((state) => state.user.module);
  const [state, localDispatch] = useReducer(
    suppressionPanelReducer,
    initialState
  );
  const { caseStateCopy } = useContext(WorkflowFormContext);

  const setGroups = (variants: VariantGroup[]) => {
    localDispatch({
      type: actions.SET_VARIANT_OPTIONS,
      payload: {
        options: variants.map((variant) => ({
          key: String(variant.id),
          desc: variant.shortDescription,
        })),
      },
    });
  };

  const handleChangeSelectedVariant = (
    event: SelectChangeEvent<string | number>
  ) => {
    localDispatch({
      type: actions.SELECT_VARIANT,
      payload: {
        selectedVariant: String(event.target.value),
      },
    });
  };

  const openAddSuppressionModal = () => {
    localDispatch({
      type: actions.OPEN_ADD_SUPPRESSION_MODAL,
    });
  };

  const closeAddSuppressionModal = () => {
    localDispatch({
      type: actions.CLOSE_ADD_SUPPRESSION_MODAL,
    });
  };

  const onAddSuppression = () => {
    navigate(`/${module}/workflow/create/${state.selectedVariant}`, {
      state: {
        __workType: 'Suppression',
        __previousUrl: {
          variantId,
          key,
        },
        __previousState: caseStateCopy.current,
      },
    } as NavigateOptions);
  };

  return (
    <PanelLayout
      isEdit={isEdit}
      index={index}
      selectedIndex={selectedIndex}
      variantType={variantType}
      setGroups={setGroups}
      onChangeSelectedVariant={handleChangeSelectedVariant}
      openAddModal={openAddSuppressionModal}
      closeAddModal={closeAddSuppressionModal}
      addModalOpen={state.addSuppressionModalOpen}
      selectedVariant={state.selectedVariant}
      variantOptions={state.variantOptions}
      onAddItem={onAddSuppression}
    >
      Suppression list to go here
    </PanelLayout>
  );
};
