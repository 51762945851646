import { useCallback, useEffect, useMemo, useState } from 'react';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, useTheme } from '@mui/system';
import DatePicker from '@revenue-solutions-inc/revxcoreui/material/controls/DatePicker';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import Input from '@revenue-solutions-inc/revxcoreui/material/controls/Input';
import Select, {
  SelectType,
} from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import { ColumnDef, Row } from '@tanstack/react-table';
import Loading from 'components/Loading';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { addMessage } from 'redux/messageSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { Typography } from '@mui/material';
import { useGetGeneratedLetterUserRolesQuery, User } from 'generated/graphql';
import { useMsal } from '@azure/msal-react';
import useGetAccessToken from 'hooks/useGetAccessToken';
import useQueryRequest from 'hooks/useQueryRequest';
import { userBySearch } from 'pages/admin/ManageUserRoles/ManageuserRolesQuery';
import {
  MessageActionType,
  MessageType,
} from '@revenue-solutions-inc/revxcoreui';
import { CollapseProp } from '../AdvancedSearch';
import { ColumnFields, SearchName } from '../../SearchConfig';

type KeyValue = {
  [key: string]: string | null;
};

interface SearchOptionsProps {
  search: SearchName;
  setResults: React.Dispatch<React.SetStateAction<JSX.Element | JSX.Element[]>>;
  collapse: CollapseProp;
  setCollapse: React.Dispatch<React.SetStateAction<CollapseProp[]>>;
}

interface CollapseParamsProps {
  show: boolean;
}

interface SelectCompProp {
  label: string;
  options?: SelectType[];
  stateName: string;
  id?: string;
  dataType?: string;
}

function SearchOptionsLetters({
  search,
  setResults,
  collapse,
  setCollapse,
}: SearchOptionsProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [searchOp, setSearchOp] = useState(search.state);
  const [searchOpLetter, setSearchOpLetter] = useState('');
  const { accounts } = useMsal();
  const dispatch = useAppDispatch();
  const accessToken = useGetAccessToken();
  const [click, setClick] = useState(false);
  const module = useAppSelector((state) => state.user.module);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [historyState, setHistoryState] = useState<any>([]);
  const [rolesArray, setRolesArray] = useState<number[]>([]);
  const [isValid, setIsValid] = useState(false);
  const regex = /^[0-9\b]+$/;
  const {
    data: rolesData,
    refetch: refetchRolesDetails,
    isFetched: rolesIsFetched,
    isError: rolesFetchError,
  } = useGetGeneratedLetterUserRolesQuery(
    {
      getGeneratedLetterUserRolesId: {
        ids: rolesArray,
      },
    },
    { enabled: false, cacheTime: 0 }
  );

  const {
    data: lettersData,
    refetch: refetchLetters,
    isFetching: lettersFetching,
    isError,
  } = search.searchQuery(
    { [search.searchParam]: searchOpLetter },
    { enabled: false }
  );

  const SelectComp = ({
    label,
    options,
    stateName,
  }: SelectCompProp): JSX.Element => {
    return (
      <Select
        id={`${label.replaceAll(' ', '')}-id`}
        label={label}
        options={options ?? []}
        value={searchOp[stateName] ?? 'Choose an option'}
        onChange={(e) => {
          setSearchOp({
            ...searchOp,
            [stateName]: e.target.value as string,
          });
        }}
        sx={{ mr: 1 }}
      />
    );
  };
  const { data: userData } = useQueryRequest<{
    UsersBySearch: [User];
  }>(
    ['search', accounts[0]?.username],
    accessToken,
    userBySearch,
    {
      search: accounts[0]?.username,
    },
    'search'
  );

  const DatepickerComp = ({
    label,
    stateName,
    id,
  }: SelectCompProp): JSX.Element => {
    const [keyboardValue, setKeyboardValue] = useState<string | undefined>(
      undefined
    );
    const [value, setValue] = useState<Date | null>(
      searchOp[stateName] ? new Date(`${searchOp[stateName]} 00:00:00`) : null
    );

    const handleDateChange = (
      date: Date | null,
      keyboardInputValue: string | undefined
    ) => {
      setValue(date);
      setKeyboardValue(keyboardInputValue);
      if (!keyboardInputValue && date) {
        setSearchOp({
          ...searchOp,
          [stateName]: `${format(new Date(date), 'yyyy-MM-dd')}`,
        });
      }
    };

    return (
      <DatePicker
        id={`datePicker-${id}`}
        label={label}
        value={value}
        handleChange={handleDateChange}
        onBlur={() => {
          if (
            keyboardValue &&
            keyboardValue.split('/').length - 1 === 2 &&
            keyboardValue.split('//').length - 1 === 0
          ) {
            setSearchOp({
              ...searchOp,
              [stateName]: `${format(new Date(keyboardValue), 'yyyy-MM-dd')}`,
            });
          }
        }}
        sx={{ mr: 1 }}
      />
    );
  };
  const handleSearchParams = () => {
    let localSearch = 'WorkflowVariantCode=CorrespondenceWorkflow';
    if (searchOp.LetterId && String(searchOp.LetterId).length > 2)
      localSearch = localSearch.concat(`%26LetterId=LIKE${searchOp.LetterId}`);
    if (searchOp.PrintDateFrom && searchOp.PrintDateTo)
      localSearch = localSearch.concat(
        `%26PrintDate=${searchOp.PrintDateFrom}|${searchOp.PrintDateTo}`
      );
    if (searchOp.PrintDateFrom && !searchOp.PrintDateTo)
      localSearch = localSearch.concat(
        `%26PrintDate=${searchOp.PrintDateFrom}|9999-12-31`
      );
    if (searchOp.City && String(searchOp.City).length > 2)
      localSearch = localSearch.concat(`%26City=LIKE${searchOp.City}`);
    if (searchOp.State && String(searchOp.State).length > 2)
      localSearch = localSearch.concat(`%26StateCode=LIKE${searchOp.State}`);
    if (searchOp.ZipCode && String(searchOp.ZipCode).length > 2)
      localSearch = localSearch.concat(`%26PostalCode=LIKE${searchOp.ZipCode}`);
    if (searchOp.Country && String(searchOp.Country).length > 2)
      localSearch = localSearch.concat(
        `%26CountryCode=LIKE${searchOp.Country}`
      );
    if (searchOp.Street && String(searchOp.Street).length > 2)
      localSearch = localSearch.concat(
        `%26AddressLine1=LIKE${searchOp.Street}`
      );
    else {
      setSearchOpLetter(localSearch);
    }
    if (localSearch === 'WorkflowVariantCode=CorrespondenceWorkflow') {
      dispatch(
        addMessage({
          message: t('pages.search.error'),
          type: MessageType.Error,
          actionType: MessageActionType.None,
        })
      );
      setClick(false);
      return 'searchError';
    }
    if (
      localSearch === searchOpLetter &&
      (lettersData === undefined ||
        lettersData?.GetFindByJsonParameter.length === 0)
    ) {
      dispatch(
        addMessage({
          message: t('pages.search.noresult'),
          type: MessageType.Error,
          actionType: MessageActionType.None,
        })
      );
      setClick(false);
      return 'noResult';
    }
    setClick(false);
    return '';
  };

  useEffect(() => {
    if (searchOpLetter.length > 0 && searchOpLetter.includes('%26')) {
      refetchLetters();
      setClick(true);
    }
  }, [refetchLetters, searchOpLetter]);

  /**
   * This methods builds the link/route a cell will display, i.e. a link to an
   * entity or an account. For an account, it needs two parameters
   * @param path Where the route and parameters are contained
   * @param row The value to attach the route to be defined
   * @returns The complete route/link to be displayed in the cell
   */
  const buildRoute = useCallback(
    (path: ColumnFields, row: Row<KeyValue>): string => {
      if (path.secondRoute || path.secondRouteParam) {
        const firstRoute = path.route ?? '';
        const firstRouteParam =
          row.original[path.routeParam ? path.routeParam : 'id'] ?? '';
        const secondRouteParam =
          row.original[path.secondRouteParam ? path.secondRouteParam : 'id'] ??
          '';
        return (
          `/${module}` +
          firstRoute
            .concat('/')
            .concat(firstRouteParam)
            .concat(path.secondRoute ?? '')
            .concat('/')
            .concat(secondRouteParam)
        );
      } else {
        const route = path.route ?? '';
        const routeParam =
          row.original[path.routeParam ? path.routeParam : 'id'] ?? '';
        return `/${module}` + route.concat('/').concat(routeParam);
      }
    },
    [module]
  );

  const columns: ColumnDef<KeyValue>[] = useMemo(() => {
    if (rolesData && userData && userData?.UsersBySearch) {
      return search.columns.map((col) => {
        return {
          id: col.accessorKey,
          accessorKey: col.accessorKey,
          header: () => <HeaderColumnNext localization={col.columnName} />,
          cell: ({ row }) => {
            if (col.clickable && col.accessorKey == 'LetterId') {
              const generatedLetterRoles =
                rolesData?.GetGeneratedLetterUserRoles?.filter(
                  (item) =>
                    item.correspondenceTypeId ==
                      row.original.CorrespondenceTypeId &&
                    item.roleAssignments &&
                    item.roleAssignments.length > 0
                );
              return generatedLetterRoles.length > 0 &&
                generatedLetterRoles.map((itm) =>
                  itm?.roleAssignments?.filter((ltr) =>
                    userData?.UsersBySearch[0].userRole?.some(
                      (user) => user.roleId === ltr.roleId
                    )
                  )
                ).length > 0 &&
                generatedLetterRoles.map((itm) =>
                  itm?.roleAssignments?.filter((ltr) =>
                    userData?.UsersBySearch[0].userRole?.some(
                      (user) => user.roleId === ltr.roleId
                    )
                  )
                )[0]?.length !== 0 ? (
                <Link
                  to={{
                    pathname: buildRoute(col, row),
                  }}
                  style={{ color: theme.palette.linkBlue.dark }}
                  state={{
                    prevPath: `/search`,
                    prevPageName: t('pages.search.title'),
                  }}
                >
                  {row.original[col.accessorKey] ?? col.columnValue}
                </Link>
              ) : (
                <Typography color={theme.palette.grey2.main}>
                  {row.original[col.accessorKey] ?? col.columnValue}
                </Typography>
              );
            } else if (col.isDate) {
              return row.original[col.accessorKey]
                ? new Date(
                    row.original[col.accessorKey] as string
                  ).toLocaleDateString()
                : col.columnValue;
            } else if (
              col.clickable &&
              col.accessorKey !== 'LetterId' &&
              row.original[col.accessorKey]
            ) {
              return (
                <Link
                  to={{
                    pathname: buildRoute(col, row),
                  }}
                  style={{ color: theme.palette.linkBlue.dark }}
                  state={{
                    prevPath: `/search`,
                    prevPageName: t('pages.search.title'),
                  }}
                >
                  {row.original[col.accessorKey] ?? col.columnValue}
                </Link>
              );
            } else if (col.accessorKey.includes(',')) {
              let address = '';
              col.accessorKey
                .split(',')
                .forEach(
                  (acKey) => (address = address + ',' + row.original[acKey])
                );
              address = address.replace(address[0], '');
              return address;
            } else if (typeof row.original[col.accessorKey] == 'boolean') {
              return row.original[col.accessorKey] ? 'Yes' : 'No';
            } else return row.original[col.accessorKey] ?? col.columnValue;
          },
        };
      });
    } else {
      return [];
    }
  }, [
    search.columns,
    rolesData,
    userData,
    buildRoute,
    theme.palette.linkBlue.dark,
    theme.palette.grey2.main,
    t,
  ]);

  useEffect(() => {
    if (lettersData && click) {
      setHistoryState(
        lettersData[search.dataQuery].map(
          (item: { wF_Full_Object: string }) => {
            return JSON.parse(item.wF_Full_Object);
          }
        )
      );
    } else if (isError && !lettersFetching) {
      setResults(<></>);
      dispatch(
        addMessage({
          type: MessageType.Error,
          message: t('pages.search.noresult'),
        })
      );
    }
  }, [
    lettersData,
    search.dataQuery,
    lettersFetching,
    setResults,
    t,
    isError,
    click,
    refetchLetters,
    dispatch,
  ]);
  useEffect(() => {
    (async function roles() {
      if (rolesArray.length > 0 && !rolesData) {
        await refetchRolesDetails()
          .then(() => {
            setIsValid(true);
          })
          .catch(() => setIsValid(false));
      }
    })().catch((e) => e);
  }, [refetchRolesDetails, rolesArray, rolesData]);
  useEffect(() => {
    if (rolesFetchError && !isValid) {
      setResults(<></>);
      dispatch(
        addMessage({
          type: MessageType.Error,
          message: t('pages.search.noresult'),
        })
      );
    } else if (
      rolesIsFetched &&
      !rolesFetchError &&
      historyState?.length > 0 &&
      isValid
    ) {
      setResults(
        <DefaultDataTableNext
          columns={columns as ColumnDef<Record<string, unknown>, unknown>[]}
          data={[...historyState]}
          tableName={t('pages.search.searchResults')}
        />
      );
      setCollapse((prev) => {
        return prev.map((item) => {
          if (item.searchName === search.searchName) {
            return {
              collapsed: true,
              searchName: search.searchName,
              selected: true,
              results: (
                <DefaultDataTableNext
                  columns={
                    columns as ColumnDef<Record<string, unknown>, unknown>[]
                  }
                  data={[...historyState]}
                  tableName={t('pages.search.searchResults')}
                />
              ),
            };
          }
          return {
            collapsed: true,
            searchName: item.searchName,
            selected: false,
            results: item.results,
          };
        });
      });
      setIsValid(false);
    }
  }, [
    columns,
    historyState,
    rolesIsFetched,
    rolesData,
    search.searchName,
    setCollapse,
    setResults,
    t,
    userData?.UsersBySearch,
    isError,
    rolesFetchError,
    isValid,
    dispatch,
  ]);

  useEffect(() => {
    if (historyState?.length > 0 && click) {
      const localLetters = [...historyState];
      if (localLetters && localLetters.length > 0) {
        const localItems: number[] = [];
        localLetters.forEach((letter) =>
          localItems.push(parseInt(letter.CorrespondenceTypeId))
        );
        setRolesArray(
          localItems.filter((el, index, self) => self.indexOf(el) == index)
        );
      } else {
        setResults(<></>);
        dispatch(
          addMessage({
            type: MessageType.Error,
            message: t('pages.search.noresult'),
          })
        );
      }
      setClick(false);
    } else if (
      (lettersData === undefined ||
        lettersData?.GetFindByJsonParameter.length === 0) &&
      click &&
      !lettersFetching
    ) {
      setResults(<></>);
      dispatch(
        addMessage({
          type: MessageType.Error,
          message: t('pages.search.noresult'),
        })
      );
    }
  }, [
    click,
    historyState,
    isError,
    setResults,
    t,
    lettersFetching,
    dispatch,
    lettersData,
    refetchLetters,
  ]);

  const CollapseParams = ({ show }: CollapseParamsProps) => {
    const ExecuteSetCollapse = () => {
      setCollapse((prev) => {
        return prev.map((item) => {
          if (item.searchName === search.searchName) {
            return {
              collapsed: !item.collapsed,
              searchName: search.searchName,
              selected: true,
              results: item.results,
            };
          }
          return {
            collapsed: true,
            searchName: item.searchName,
            selected: false,
            results: item.results,
          };
        });
      });
    };

    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Typography sx={{ fontWeight: 'bold' }}>
          {t('pages.search.parameters')}
        </Typography>
        {show ? (
          <ExpandLessIcon
            sx={{ cursor: 'pointer' }}
            onClick={ExecuteSetCollapse}
          />
        ) : (
          <ExpandMoreIcon
            sx={{ cursor: 'pointer' }}
            onClick={ExecuteSetCollapse}
          />
        )}
      </Box>
    );
  };

  return (
    <>
      {collapse.selected && <CollapseParams show={!collapse.collapsed} />}
      {!collapse.collapsed && (
        <>
          <Box
            sx={{
              m: 1,
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-evenly',
            }}
          >
            {Object.values(search.searchFields).map((fields, index) => {
              if (fields.type === 'select') {
                return (
                  <SelectComp
                    label={fields.label}
                    options={fields.options}
                    stateName={fields.stateName}
                    key={fields.id}
                  />
                );
              } else if (fields.type === 'datepicker') {
                return (
                  <DatepickerComp
                    id={`${index}_${fields.id}`}
                    label={fields.label}
                    stateName={fields.stateName}
                    key={fields.id}
                  />
                );
              } else if (fields.type === 'input') {
                return (
                  <Input
                    label={fields.label}
                    value={searchOp[fields.stateName]}
                    id={fields.id}
                    key={fields.id}
                    onChange={(e) => {
                      if (
                        fields.dataType === 'string' ||
                        e.target.value.length === 0
                      ) {
                        setSearchOp({
                          ...searchOp,
                          [fields.stateName]: e.target.value,
                        });
                      } else {
                        setSearchOp({
                          ...searchOp,
                          [fields.stateName]: regex.test(e.target.value)
                            ? e.target.value
                            : searchOp[fields.stateName],
                        });
                      }
                    }}
                    sx={{
                      '&.MuiFormControl-root': { mr: 1 },
                      '.MuiFormControl-root.MuiTextField-root': { m: 0 },
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        setHistoryState([]);
                        handleSearchParams();
                      }
                    }}
                  />
                );
              }
            })}
          </Box>
          <Box sx={{ display: 'flex' }}>
            <Button
              id="search-btn-id"
              onClick={() => {
                setHistoryState([]);
                handleSearchParams();
              }}
              sx={{ mt: 1, mr: 1 }}
            >
              {t('pages.search.title')}
            </Button>
            <Button
              id="clear-mtn-btn-id"
              onClick={() => {
                setSearchOp(search.state);
                setClick(false);
              }}
              type="secondary"
              sx={{ mt: 1 }}
            >
              {t('pages.search.clearBtn')}
            </Button>
          </Box>
          {lettersFetching && <Loading />}
        </>
      )}
    </>
  );
}

export default SearchOptionsLetters;
