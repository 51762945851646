import { useCallback, useEffect, useState } from 'react';
import { Grid } from '@mui/material';

import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import {
  Button,
  MessageActionType,
  MessageType,
  Select,
} from '@revenue-solutions-inc/revxcoreui';
import Dialog from '@revenue-solutions-inc/revxcoreui/material/controls/Dialog';
import { addMessage } from 'redux/messageSlice';
import {
  ReverseTransactionInput,
  useGetLookupNamesConfigurationQuery,
  useReverseFinancialTransactionMutation,
} from 'generated/graphql';
import { useAppDispatch } from 'redux/hooks';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useHasAccess } from 'hooks/useHasAccess';
import { Controller, useForm } from 'react-hook-form';
import extractMeaningfulMessage from 'utils/errorMessage';
import { ConfigurationModules } from 'common/platformConfigUtils/platformConfigUtils';
import { SelectType } from '@revenue-solutions-inc/revxcoreui/material/controls/Select';

interface Props {
  sourceId?: number;
}

function ReverseTransaction({ sourceId }: Props) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const canEdit = useHasAccess('reverseTransaction', 'edit');
  const [reversalReasons, setReversalReasons] = useState<SelectType[]>([]);
  const { control, formState, trigger, getValues } = useForm({
    mode: 'all',
  });
  const { isValid } = formState;

  const closeModal = () => {
    setOpen(false);
  };

  const openModal = () => {
    setOpen(true);
  };

  const { data: reversalReasonsLookup, error: reversalReasonsError } =
    useGetLookupNamesConfigurationQuery({
      configurationType: 'ReversalReason',
      configurationModule: ConfigurationModules.Platform,
    });

  const { mutate, isLoading } = useReverseFinancialTransactionMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['GetViewFinancialTransactionById'],
      });
      closeModal();
      navigate(-1);
      dispatch(
        addMessage({
          message: t('pages.FinancialTransaction.reverseSuccess'),
          type: MessageType.Success,
          actionType: MessageActionType.None,
        })
      );
    },
    onError: (error) => {
      const message = extractMeaningfulMessage(
        error,
        t('pages.FinancialTransaction.reverseError')
      );
      dispatch(
        addMessage({
          message: message,
          type: MessageType.Error,
          actionType: MessageActionType.None,
        })
      );
    },
  });

  useEffect(() => {
    const reversalReasonsList =
      reversalReasonsLookup?.GetLookupTypesConfiguration.map((type) => {
        return {
          key: type.configurationName ? type.configurationName : '',
          desc: type.configurationDescription ?? '',
        };
      });

    if (reversalReasonsList) {
      setReversalReasons(reversalReasonsList);
    }
  }, [reversalReasonsLookup?.GetLookupTypesConfiguration]);

  useEffect(() => {
    if (reversalReasonsError) {
      dispatch(
        addMessage({
          message: t('pages.FinancialTransaction.reversalLookupError'),
          type: MessageType.Error,
          actionType: MessageActionType.None,
        })
      );
    }
  }, [dispatch, reversalReasonsError, t]);

  const reverseTransactionBtn = (): JSX.Element => {
    return (
      <Box
        sx={{ width: 220, textAlign: 'right', mr: '8px' }}
        data-testid="reverse-transaction"
      >
        <Button
          id="reverse-transaction-link"
          type="secondary"
          variant="text"
          onClick={openModal}
        >
          {t('pages.FinancialTransaction.reverse')}
        </Button>
      </Box>
    );
  };

  const submitTransactionReversal = useCallback(() => {
    trigger().then(() => {
      if (sourceId && isValid) {
        const payload: ReverseTransactionInput = {
          financialTransactionSourceId: sourceId,
          reversalReason: getValues('reversalReason'),
        };
        mutate({ reverseTransaction: payload });
      }
    });
  }, [getValues, isValid, mutate, sourceId, trigger]);

  const reverseTransactionModalContent = (
    <Grid container spacing={2} mb={1}>
      <Grid item xs={12}>
        <Controller
          control={control}
          name="reversalReason"
          rules={{ required: true }}
          render={({
            field: { value, onChange },
            fieldState: { error: reversalReasonError },
          }) => {
            return (
              <Select
                required
                autowidth={false}
                options={reversalReasons}
                id="reversalReason"
                data-testid="reversalReason"
                label={t('pages.FinancialTransaction.reversalReason')}
                onChange={onChange}
                value={value}
                error={
                  reversalReasonError
                    ? t('pages.FinancialTransaction.reversalReasonRequired')
                    : ''
                }
              />
            );
          }}
        />
      </Grid>
    </Grid>
  );

  const handleSave = useCallback(() => {
    submitTransactionReversal();
  }, [submitTransactionReversal]);

  return (
    <>
      {canEdit && reverseTransactionBtn()}
      <Dialog
        id="reverseTransactionDialog"
        open={open}
        children={reverseTransactionModalContent}
        title={t('pages.FinancialTransaction.reverse')}
        type="transactional"
        loading={isLoading}
        maxWidth="sm"
        transactionModalTransactionButtonText={t(
          'pages.FinancialTransaction.reverseTransactionConfirmButton'
        )}
        handleClose={closeModal}
        handleCancelClick={closeModal}
        handleTransactionClick={handleSave}
      />
    </>
  );
}
export default ReverseTransaction;
