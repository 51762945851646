import { Modal } from '@mui/material';
import { Box } from '@mui/system';
import { useGetBankAccountDetailQuery } from 'generated/graphql';
import EditBankAccount from '../EditBankAccount';

type EditBankAccountModalProps = {
  open: boolean;
  selectedBankAccountId: string;
  onClose?: () => void;
  refetch: () => void;
};

const EditBankAccountModal = ({
  open,
  selectedBankAccountId,
  onClose,
  refetch,
}: EditBankAccountModalProps) => {
  const { data: bankAccountData, refetch: bankaccountDetailRefetch } =
    useGetBankAccountDetailQuery({
      Id: selectedBankAccountId,
    });

  return (
    <>
      {!bankAccountData?.getBankAccountDetail || !selectedBankAccountId ? (
        <></>
      ) : (
        <Modal
          open={open}
          onClose={onClose}
          aria-labelledby="edit-bank-account"
          aria-describedby="edit-bank-account"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              minWidth: 400,
              maxWidth: 1200,
              bgcolor: 'background.paper',
              p: 4,
            }}
          >
            <EditBankAccount
              bankAccountData={bankAccountData.getBankAccountDetail}
              selectedBankAccountId={selectedBankAccountId}
              onCloseCallback={onClose}
              refetch={refetch}
              bankaccountDetailRefetch={() => bankaccountDetailRefetch}
            />
          </Box>
        </Modal>
      )}
    </>
  );
};

export default EditBankAccountModal;
