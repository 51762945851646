import { createReducer } from 'utils/createLocalReducer';
import type { ActionMap } from 'utils/createLocalReducer';

type CRMTabReducerState = {
  activeTab: number;
};

export enum CRMTabIndex {
  LETTERS,
  NOTES,
  SUPPRESSIONS,
  ATTACHMENTS,
}

export const initialState: CRMTabReducerState = {
  activeTab: CRMTabIndex.NOTES,
};

export const actions = {
  SET_ACTIVE_TAB: 'crm/SET_ACTIVE_TAB',
};

export const actionMap: ActionMap<CRMTabReducerState> = {
  [actions.SET_ACTIVE_TAB]: (state, { payload: { activeTab } }) => ({
    ...state,
    activeTab,
  }),
};

export const crmTabReducer = createReducer<CRMTabReducerState>(
  initialState,
  actionMap
);
