import { useState, useCallback } from 'react';
import {
  Grid,
  styled,
  SwipeableDrawer,
  Box,
  Skeleton,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import DataCard from 'components/DataCard';
import { PowerBiClient } from '@revenue-solutions-inc/revxcoreui';
import Loading from 'components/Loading';
import { Theme } from '@mui/system';
import { grey } from '@mui/material/colors';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { useTranslation } from 'react-i18next';
import {
  useGetAccountReportsMutation,
  useGetContextualIdReportsQuery,
  GetContextualIdReportsReponse,
  useGetEntityReportsMutation,
  GetAccountReportsResponse,
  GetEntityReportsResponse,
} from 'generated/graphql';

const setIconColor = (theme: Theme) => ({ fill: theme.palette.primary.main });

type ReponseListType = GetAccountReportsResponse | GetEntityReportsResponse;

type AccountReportsResponse = {
  embedUrl?: string | null;
  name?: string | null;
  pbiProfileId?: string | null;
  pbiWorkspaceId?: string | null;
  id?: string | null;
  token?: string | null;
  tokenExpiration?: string | null;
  tokenId?: string | null;
};

interface ReportState {
  id?: string | null;
  data: AccountReportsResponse | null;
  handleSaveEdit: () => void;
  handleSaveAs: () => void;
  typeMode: string;
}

const drawerBleeding = 56;

function ReportsLogic({ ...props }): JSX.Element {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [valueReportsTab, setValueReportsTab] = useState(0);
  const [stateMode, setStateMode] = useState<ReportState>({
    id: '',
    data: {
      id: '',
      embedUrl: '',
      token: '',
    },
    typeMode: 'view',
    handleSaveEdit: () => {},
    handleSaveAs: () => {},
  });

  const {
    data: reportsData,
    isLoading: isLoadingReportsData,
    isError: isErrorReportsData,
  } = useGetContextualIdReportsQuery(
    {
      report: { contextualTypeId: props.contextualTypeId },
    },
    { enabled: props.contextualTypeId !== undefined }
  );

  const { mutate: accountReportsEmbed } = useGetAccountReportsMutation();
  const { mutate: entityReportsEmbed } = useGetEntityReportsMutation();

  const Puller = styled(Box)(({ theme }) => ({
    width: 30,
    height: 6,
    backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 15px)',
  }));

  const StyledBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
  }));

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const a11yProps = useCallback((index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }, []);

  const handleSuccess = useCallback((dataResponse: ReponseListType) => {
    setStateMode({
      id: dataResponse.reportId,
      data: {
        id: dataResponse.reportId,
        embedUrl: dataResponse.embedUrl,
        token: dataResponse.token,
        tokenExpiration: dataResponse.tokenExpiration,
        name: dataResponse.name,
      },
      typeMode: 'view',
      handleSaveEdit: () => {},
      handleSaveAs: () => {},
    });
  }, []);

  const handleReportsTabChange = useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      const target = event.target as HTMLInputElement;
      setValueReportsTab(newValue);

      if (props.contextualTypeId === 1) {
        accountReportsEmbed(
          {
            report: {
              reportId: Number(target.id),
              customData: props.id ?? 'default',
            },
          },
          {
            onSuccess: (e) => {
              handleSuccess(e.getAccountReports);
            },
          }
        );
      } else {
        entityReportsEmbed(
          {
            report: {
              reportId: Number(target.id),
              customData: props.id ?? 'default',
            },
          },
          {
            onSuccess: (e) => {
              handleSuccess(e.getEntityReports);
            },
          }
        );
      }
      setOpen(true);
    },
    [
      accountReportsEmbed,
      entityReportsEmbed,
      props.contextualTypeId,
      props.id,
      handleSuccess,
    ]
  );

  return (
    <DataCard
      title={t('pages.entitySummary.sectionTitles.activeReports')}
      avatar={<DashboardIcon sx={setIconColor} />}
      children={
        <Grid sx={{}}>
          {isErrorReportsData ? (
            <Grid
              container
              sx={{ justifyContent: 'center', alignItems: 'center' }}
            >
              <Typography>{t('pages.reports.noReports')}</Typography>
            </Grid>
          ) : null}
          {reportsData !== undefined &&
          !isLoadingReportsData &&
          !isErrorReportsData ? (
            <>
              <Tabs
                orientation="vertical"
                value={valueReportsTab}
                onChange={handleReportsTabChange}
                variant="fullWidth"
                aria-label="Vertical tabs"
                sx={{ borderRight: 1, borderColor: 'divider' }}
              >
                <Tab label={'Choose a Report'} {...a11yProps(0)} disabled />
                {reportsData.getContextualIdReports.map(
                  (x: GetContextualIdReportsReponse, index: number) => (
                    <Tab
                      label={x.reportName ? x.reportName : 'unknown'}
                      {...a11yProps(index)}
                      id={String(x.reportId)}
                      onClick={toggleDrawer(true)}
                    />
                  )
                )}
              </Tabs>
              <SwipeableDrawer
                anchor="bottom"
                open={open}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
                swipeAreaWidth={drawerBleeding}
                disableSwipeToOpen={false}
                ModalProps={{
                  keepMounted: true,
                }}
              >
                <StyledBox
                  sx={{
                    position: 'absolute',
                    top: -drawerBleeding,
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8,
                    visibility: 'visible',
                    right: 0,
                    left: 0,
                  }}
                >
                  <Puller />
                </StyledBox>
                <StyledBox
                  sx={{
                    px: 2,
                    pb: 2,
                    height: '100%',
                    overflow: 'auto',
                  }}
                >
                  <Skeleton variant="rectangular" height="100%" />
                  <>
                    {stateMode?.data?.id ? (
                      <PowerBiClient
                        data={stateMode.data}
                        name={'AEWReport'}
                        description={'AEWReport'}
                        typeMode={stateMode.typeMode}
                        handleSaveAs={() => {}}
                        handleSaveEdit={() => {}}
                        refetchTokenCreateMode={() => {}}
                        refetchTokenEditMode={() => {}}
                        registerSaveAs={() => {}}
                        registerSave={() => {}}
                      />
                    ) : (
                      <Loading fullScreen={false} />
                    )}
                  </>
                </StyledBox>
              </SwipeableDrawer>
            </>
          ) : (
            <Loading fullScreen={false} />
          )}
        </Grid>
      }
    />
  );
}
export default ReportsLogic;
