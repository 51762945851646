export const ContactTypes = {
  Email: 'email',
  Phone: 'phone',
};

export const entityIdentifiers = {
  EMPLOYER_IDENTIFICATION_NUMBER: {
    type: 'EmployerIdentificationNumber',
    value: 'Employer Identification Number',
  },
  PARCEL_ID: {
    type: 'ParcelId ',
    value: 'Parcel Id',
  },
  PROPERTY_OWNER_ID: {
    type: 'EmployerIdentificationNumber',
    value: 'Employer Identification Number',
  },
  SOCIAL_SECURITY_NUMBER: {
    type: 'SocialSecurityNumber',
    value: 'Social Security Number',
  },
};
