import { useMemo } from 'react';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import WorkflowIDCell from 'components/Workflow/WorkflowIDCell';
import { getFormatDate } from 'utils/date-util';
import { WorkflowTableProps } from 'components/Workflow/WorkHistoryTable/WorkHistoryTable';

export const SuppressionsTable = ({
  tableName,
  tableData = [],
  onRowClick = () => {},
  cursorHover,
  customHeader,
}: WorkflowTableProps): JSX.Element => {
  const { t } = useTranslation();

  const columns: ColumnDef<Record<string, unknown>>[] = useMemo(() => {
    return [
      {
        enableSorting: true,
        accessorKey: 'DisplayId',
        id: 'Workflow_Key',
        header: () => (
          <HeaderColumnNext
            localization={t('pages.suppressions.suppressionsTable.id')}
          />
        ),
        cell: ({ row }) => (
          <WorkflowIDCell
            workflowKey={row.original.Workflow_Key as string}
            variantId={row.original.Workflow_Variant_Id as string}
            displayId={row.original.DisplayId as string}
          />
        ),
      },
      {
        enableSorting: true,
        accessorKey: 'WorkflowVariantCode',
        id: 'WorkflowVariantCode',
        header: () => (
          <HeaderColumnNext
            localization={t('pages.suppressions.suppressionsTable.subtype')}
          />
        ),
      },
      {
        enableSorting: true,
        accessorKey: 'SuppressionStartDate',
        id: 'SuppressionStartDate',
        cell: ({ getValue }) => {
          const value = getFormatDate(new Date(getValue() as Date));
          return value;
        },
        header: () => (
          <HeaderColumnNext
            localization={t('pages.suppressions.suppressionsTable.startDate')}
          />
        ),
      },
      {
        enableSorting: true,
        accessorKey: 'SuppressionEndDate',
        id: 'SuppressionEndDate',
        header: () => (
          <HeaderColumnNext
            localization={t('pages.suppressions.suppressionsTable.endDate')}
          />
        ),
        cell: ({ getValue }) => {
          const value = getFormatDate(new Date(getValue() as Date));
          return value;
        },
      },
      {
        enableSorting: true,
        accessorKey: 'State',
        id: 'State',
        header: () => (
          <HeaderColumnNext
            localization={t('pages.suppressions.suppressionsTable.state')}
          />
        ),
      },
    ];
  }, [t]);

  return (
    <DefaultDataTableNext
      id="suppressions-table"
      tableName={tableName}
      data={tableData as Record<string, unknown>[]}
      columns={columns}
      fetchCursorSelectedRow={onRowClick}
      cursorHover={cursorHover}
      customHeader={customHeader}
    />
  );
};
