import { useCallback, useState, useEffect, useMemo, useRef } from 'react';

import { Box } from '@mui/material';
import { MessageType } from '@revenue-solutions-inc/revxcoreui';
import { ConfigurationDomains } from 'common/platformConfigUtils/platformConfigUtils';
import {
  buildAccountRequest,
  sectionNames,
  updateLayoutConfiguration,
} from 'components/entityManagement/common/accountUtils';
import {
  defaultSectionsConfig,
  getAccount,
} from 'components/entityManagement/common/defaults/account';
import {
  buildExtendedValues,
  isCommenceDateValid,
  isCeaseDateValid,
  CoreSchema,
} from 'components/entityManagement/common/entityManagementUtils';
import ExtendedAttributes from 'components/entityManagement/common/ExtendedAttributes';
import {
  AccountFields,
  AddressFields,
  EmailAddressFields,
  IdentifierFields,
  IndustryCodes,
  NameFields,
  FilingFrequencyFields,
  PhoneNumberFields,
} from 'components/entityManagement/common/fields/accounts';
import EntityManagementContext from 'components/contexts/EntityManagement';
import {
  getIdFormats,
  getMaskRules,
  getValidationRules,
} from 'components/entityManagement/common/formatValidations';
import Loading from 'components/Loading';
import HorizontalNonLinearStepper from 'components/stepper/HorizontalNonLinearStepper';
import {
  Account,
  CreateAccountMutation,
  useCreateAccountMutation,
  useGetConfigurationSchemaQuery,
  useGetConfigurationSchemaByIdQuery,
  useGetLookupConfigurationQuery,
  useGetSchemaGroupsQuery,
  useGetEntityByIdQuery,
} from 'generated/graphql';
import { Control, FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { addMessage } from 'redux/messageSlice';
import {
  AccountForm,
  AccountRenderSection,
  AccountSectionNames,
  StepsConfig,
} from 'types/accounts';
import { ExtendedAttributeValues, Section } from 'types/forms';
import { Error } from 'types/graphqlErrors';
import { getFormatDate } from 'utils/date-util';
import extractMeaningfulMessage from 'utils/errorMessage';

import ConfirmationAccount from '../ConfirmationAccount';
import SectionContainer from '../SectionContainer';

interface Props {
  entityId: string;
}

function AccountLayout({ entityId }: Props): JSX.Element {
  const sections = [
    'Account',
    'Name',
    'Identifier',
    'Address',
    'Contact(s)',
    'Industry Code',
    'Filing Frequencies',
    'Confirmation',
  ];
  const singleSections: AccountRenderSection[] = [
    { step: 0, section: AccountFields },
    { step: 1, section: NameFields },
    { step: 2, section: IdentifierFields },
    { step: 3, section: AddressFields },
    { step: 5, section: IndustryCodes },
    { step: 6, section: FilingFrequencyFields },
  ];
  const account: AccountForm = getAccount;
  const SECTION_LEVEL = 2; // Used in getSchemaGroups response
  const MULTIPLE_SECTION_INDEX = 4; // The index of email and phone sub-sections
  const CONFIG_DOMAIN = ConfigurationDomains.ReferenceSchema;
  const CORE_DOMAIN = ConfigurationDomains.EntityManagement;
  const CONFIRMATION_STEP = 7;
  const { mutate: createAccount } = useCreateAccountMutation({});
  const dispatch = useAppDispatch();
  const module = useAppSelector((state) => state.user.module);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [currentDirty, setCurrentDirty] = useState<boolean>(false);
  const [saveSuccessful, setSaveSuccessful] = useState<boolean>(false);
  const [showOptional, setShowOptional] = useState<boolean>(false);
  const [showPhoneOptional, setShowPhoneOptional] = useState<boolean>(false);
  const [showEmailOptional, setShowEmailOptional] = useState<boolean>(false);
  const [isAccountSaving, setAccountSaving] = useState<boolean>(false);
  const [, setPeriodFreq] = useState<boolean>(false);
  const [accountType, setAccountType] = useState<string>('');
  const [idType, setIdType] = useState<string>('');
  const [commenceDate, setCommenceDate] = useState<string>('');
  const [extendedAttribute, setExtendedAttribute] = useState<string>('');
  const [validated, setValidated] = useState<{ [k: number]: boolean }>({});
  const [extendedVals, setExtendedVals] = useState<ExtendedAttributeValues[]>(
    []
  );
  const sectionsConfig = useRef<StepsConfig[]>(defaultSectionsConfig);

  const {
    control,
    formState,
    register,
    trigger,
    handleSubmit,
    getFieldState,
    getValues,
    setValue,
    reset,
    clearErrors,
    watch,
  } = useForm<AccountForm>({ defaultValues: account, mode: 'onChange' });

  const periodFrequencyType = watch('filingFrequencies.0.periodFrequency');

  /**
   * Used to get the current section using the name or the current step
   */
  const findSection = useCallback(
    (name?: string): StepsConfig | undefined => {
      return sectionsConfig.current.find((section) =>
        name ? section.name === name : section.step === currentStep
      );
    },
    [currentStep, sectionsConfig]
  );

  /**
   * Used to get Entity information associated with this Account
   */
  const { data: entityData, isFetching: isEntityDataFetching } =
    useGetEntityByIdQuery({ entityId }, { enabled: entityId !== '' });

  /**
   * Used to get information about the configuration schema
   */
  const {
    data: layoutData,
    error: layoutError,
    isFetching: isLayoutFetching,
  } = useGetConfigurationSchemaQuery(
    {
      configurationDomain: CORE_DOMAIN,
      configurationName: CoreSchema.Account,
    },
    {
      enabled: accountType !== '',
      onSuccess: (data) => {
        updateLayoutConfiguration(data.GetConfigurationSchema);
      },
    }
  );

  /**
   * Used to get the information related with extended attributes and builds
   * internal objects to be used in the forms
   */
  const {
    data: extendedAttrData,
    isFetching: isFetchingExtended,
    refetch: refetchExtended,
  } = useGetConfigurationSchemaByIdQuery(
    {
      platformConfigurationId: extendedAttribute,
    },
    {
      onSuccess: (extendedAttributes) => {
        const extAttributes =
          extendedAttributes?.GetConfigurationSchemaById
            .platformConfigurationInfo?.configurationSection[0].group[0]
            .attribute;
        if (extAttributes) {
          const newExtendedVals = buildExtendedValues(extAttributes);
          setExtendedVals(newExtendedVals);
        } else setExtendedVals([]);
      },
      onError: () => {
        setExtendedVals([]);
      },
    }
  );

  /**
   * Using getSchemaGroups, we can know if each section is required or optional
   * As well as the extended attributes to be displayed
   */
  const { isFetching: isFetchingSchemaGroups } = useGetSchemaGroupsQuery(
    {
      configurationDomain: CONFIG_DOMAIN,
      configurationType: 'AccountType',
      configurationName: accountType,
    },
    {
      enabled: accountType !== '',
      onSuccess: (optionalSectionsData) => {
        // This part is related only with Extended Attributes
        const foundExtended = optionalSectionsData.GetSchemaGroups.find(
          (sectionItem) => sectionItem.attributeName === 'ExtendedAttribute'
        );
        if (foundExtended) setExtendedAttribute(foundExtended.attributeValue);
        else {
          setExtendedAttribute('');
          setExtendedVals([]);
        }
        // This part is related only with required/optional Demographics
        optionalSectionsData.GetSchemaGroups.forEach((sectionItem) => {
          const sectionLevel = sectionItem.attributeName.split('__');
          if (sectionLevel.length === SECTION_LEVEL) {
            const sectionName = sectionLevel[1].toLowerCase();
            const section = findSection(sectionName);
            if (section) {
              const idxSection = sectionsConfig.current.indexOf(section);
              let required = true;
              if (sectionItem.attributeValue === 'false') {
                required = false;
              }
              const sectionToAdd: StepsConfig = {
                ...section,
                isRequired: required,
              };
              sectionsConfig.current.splice(idxSection, 1, sectionToAdd);
            }
          }
        });
      },
      onError: () => {
        setExtendedAttribute('');
        setExtendedVals([]);
      },
    }
  );

  /**
   * Used to get information about the Identifiers configuration, specifically
   * to establish ID formats, masks, and validations data
   */
  const { data: configuredIdTypes } = useGetLookupConfigurationQuery({
    configurationDomain: CONFIG_DOMAIN,
    configurationType: 'IdType',
  });

  /**
   * Sets the extended attributes values in its state
   * @param newExtendedValues The new values of extended attributes
   */
  const handleExtendedAttributeValues = (
    newExtendedValues: ExtendedAttributeValues[]
  ) => {
    setExtendedVals(newExtendedValues);
  };

  /**
   * Shows / hides the period frequency dropdown based on frequency type
   */
  const resetPeriodFrequency = useCallback(
    (frequencyType = 'default') => {
      const periodFrequencyDate = FilingFrequencyFields.fields.find(
        (field) => field.fieldIdentifier === 'periodFrequencyMonth'
      );
      if (frequencyType?.toLowerCase() === 'annual-fiscalfiler') {
        if (periodFrequencyDate) {
          periodFrequencyDate.hidden = false;
          setPeriodFreq(true);
        }
      } else {
        if (periodFrequencyDate) {
          periodFrequencyDate.hidden = true;
          setValue('filingFrequencies.0.periodFrequencyMonth', null);
          setPeriodFreq(false);
        }
      }
    },
    [setValue]
  );

  /**
   * Used to determine if a section has a single form
   */
  const isSingleSection = useCallback((): boolean => {
    return (
      currentStep !== MULTIPLE_SECTION_INDEX && currentStep < CONFIRMATION_STEP
    );
  }, [currentStep]);

  /**
   * Used to determine if a section has multiple sub-sections
   */
  const isMultipleSection = (): boolean => {
    return currentStep === MULTIPLE_SECTION_INDEX;
  };

  /**
   * Used to determine if a sub-section inside a multiple step, is required
   */
  const isMultipleSectionRequired = (sectionName: string): boolean => {
    if (!isMultipleSection()) return false;
    const multSection = sectionsConfig.current.find(
      (section) =>
        section.name === sectionName && section.step === MULTIPLE_SECTION_INDEX
    );
    return multSection?.isRequired ?? true;
  };

  /**
   * Used to determine if a single section is required
   */
  const isSingleSectionRequired = useCallback((): boolean => {
    if (!isSingleSection()) return false;
    const section = findSection();
    return section?.isRequired ?? true;
  }, [findSection, isSingleSection]);

  /**
   * Get the section to be displayed depending the step
   * @param currentFields Section to be processed
   * @returns Section to be displayed
   */
  const getSectionFields = (currentFields: Section): Section => {
    if (currentStep === 0) {
      const accountTypeField = currentFields.fields.find(
        (field) => field.fieldIdentifier === 'AccountType'
      );
      if (accountTypeField) {
        accountTypeField.extraType = entityData?.GetEntityById.entityType ?? '';
      }
    }
    return currentFields;
  };

  /**
   * Deletes an empty section
   * @param data Data contained in the Account form
   */
  const processEmptySections = (data: AccountForm) => {
    sectionNames.forEach((sectionName) => {
      const section = data[sectionName as AccountSectionNames];
      if (section) {
        let emptySection = false;
        section.forEach((sectionElement) => {
          const sectionValues = Object.values(sectionElement);
          const filledSection = sectionValues.find((value) => {
            return value !== '' && value !== null && value !== 'true';
          });
          if (filledSection === undefined) emptySection = true;
        });
        if (emptySection) delete data[sectionName as AccountSectionNames];
      }
    });
  };

  /**
   * Sets correctly the commence date if the section date is null
   * @param data Data contained in the Account form
   */
  const processCommenceDates = (data: AccountForm) => {
    sectionNames.forEach((sectionName) => {
      const section = data[sectionName as AccountSectionNames];
      if (section) {
        section.forEach((sectionElement) => {
          if (sectionElement.commenceDate === null) {
            sectionElement.commenceDate = commenceDate;
          }
        });
      }
    });
  };

  /**
   * Validates if only one primary is selected for each section
   * @param sectionId Section name
   * @returns If the primary selected is valid or not
   */
  const checkPrimaries = (sectionId: AccountSectionNames): boolean => {
    if (sectionId === 'filingFrequencies') return true;
    const sectionValues = getValues(sectionId);
    const VALID_PRIMARIES = 1;
    let isPrimaryCount = 0;
    if (sectionValues) {
      sectionValues.forEach((value) => {
        if (
          ('isPrimary' in value && value.isPrimary === 'true') ||
          ('active' in value && value.active === 'true')
        )
          isPrimaryCount++;
      });
      if (isPrimaryCount === VALID_PRIMARIES) return true;
      else {
        dispatch(
          addMessage({
            type: MessageType.Error,
            message: t('pages.createAccount.message.primariesError'),
          })
        );
        return false;
      }
    }
    return true;
  };

  /**
   * Validates commence date on the section to ensure it is not earlier than Account commence date
   * @param sectionId Name of the section
   * @returns If the commence date of the section is valid or not
   */
  const checkCommenceDate = (sectionId: AccountSectionNames): boolean => {
    const accountCommenceDate = getValues('account')?.[0].commenceDate;
    let isValid = true;
    const sectionValues = getValues(sectionId);
    if (sectionValues) {
      sectionValues.forEach((value) => {
        if (
          value.commenceDate &&
          isValid &&
          !isCommenceDateValid(value.commenceDate, accountCommenceDate)
        ) {
          isValid = false;
          dispatch(
            addMessage({
              type: MessageType.Error,
              message: t('pages.createAccount.message.commenceDateError'),
            })
          );
        }
      });
    }
    return isValid;
  };

  /**
   * Checks the cease date is set before the commence date
   * @param sectionId Name of the section
   * @returns If the cease date is valid or not
   */
  const checkCeaseDate = (sectionId: AccountSectionNames): boolean => {
    const accountCommenceDate = getValues('account')?.[0].commenceDate;
    let isValid = true;
    const sectionValues = getValues(sectionId);
    if (sectionValues) {
      sectionValues.forEach((value) => {
        if (
          value.ceaseDate &&
          isValid &&
          !isCeaseDateValid(
            value.commenceDate,
            value.ceaseDate,
            accountCommenceDate
          )
        ) {
          isValid = false;
          dispatch(
            addMessage({
              type: MessageType.Error,
              message: t('pages.createAccount.message.ceaseDateError'),
            })
          );
        }
      });
    }
    return isValid;
  };

  /**
   * Validates commence date to ensure it is not earlier than Entity commence date
   * @param sectionId Name of the section
   * @returns If the commence date is valid or not
   */
  const checkEntityCommenceDate = (sectionId: AccountSectionNames): boolean => {
    const sectionValues = getValues(sectionId);
    if (sectionValues) {
      let aCommenceDate = null;
      const entityCommenceDate = entityData?.GetEntityById.commenceDate;

      sectionValues.forEach((value) => {
        if (value.commenceDate) {
          aCommenceDate = value.commenceDate;
        }
      });
      if (!aCommenceDate || !entityCommenceDate) return true;
      else if (
        Date.parse(getFormatDate(aCommenceDate as unknown as Date)) <
        Date.parse(getFormatDate(new Date(entityCommenceDate)))
      ) {
        dispatch(
          addMessage({
            type: MessageType.Error,
            message:
              t('pages.createAccount.message.entityCommenceDateError') +
              ' of ' +
              getFormatDate(new Date(entityCommenceDate)),
          })
        );
        return false;
      }
    }
    return true;
  };

  /**
   * Validates if the filing frequencies are set correctly
   * @param sectionId Section name
   * @returns If filing frequencies are correct
   */
  const checkFilingFrequencies = (sectionId: AccountSectionNames): boolean => {
    if (sectionId !== 'filingFrequencies') return true;
    const sectionValues = getValues(sectionId);
    if (sectionValues) {
      let isFilingFrequencyValid = true;
      sectionValues.forEach((value, idx) => {
        if (isFilingFrequencyValid) {
          if (idx !== sectionValues.length - 1) {
            // Any cease date, expect the last one, can not be empty
            if (value.ceaseDate === null || value.ceaseDate === '') {
              isFilingFrequencyValid = false;
            }
            // Current cease date needs to be lower than the next commence date
            const currentCeaseDate = new Date(value.ceaseDate ?? '');
            const nextCommenceDate = new Date(
              sectionValues[idx + 1].commenceDate ?? commenceDate
            );
            if (currentCeaseDate >= nextCommenceDate) {
              isFilingFrequencyValid = false;
            }
          } else {
            // Last cease date needs to be empty
            if (value.ceaseDate !== null && value.ceaseDate !== '') {
              isFilingFrequencyValid = false;
            }
          }
        }
      });
      if (!isFilingFrequencyValid) {
        dispatch(
          addMessage({
            type: MessageType.Error,
            message: t('pages.createAccount.message.filingFrequenciesError'),
          })
        );
      }
      return isFilingFrequencyValid;
    }
    return true;
  };

  /**
   * Validates all the extended attributes when required
   * @returns If extended attributes are valid or not
   */
  const checkExtendedAttributes = (): boolean => {
    if (currentStep === CONFIRMATION_STEP - 1) {
      let invalidExists = false;
      const newExtendedVals: ExtendedAttributeValues[] = [];
      extendedVals.forEach((attr) => {
        if (attr.isRequired && attr.isRequired === true && attr.value === '') {
          invalidExists = true;
        }
        newExtendedVals.push({
          ...attr,
          isDirty: true,
        });
      });
      setExtendedVals(newExtendedVals);
      if (invalidExists) return false;
      return true;
    }
    return true;
  };

  /**
   * Controls whether the user wants to fill the form of an optional section
   * @param display Controls if section is displayed or not
   * @param name Name of the section, used in multiple-type sections
   */
  const showOptionalSection = (display: boolean, name?: string) => {
    if (isMultipleSection() && name) {
      if (name === 'phoneNumbers') setShowPhoneOptional(display);
      else if (name === 'emailAddresses') setShowEmailOptional(display);
    } else {
      const section = findSection();
      if (section) {
        const idxSection = sectionsConfig.current.indexOf(section);
        const sectionUpdate: StepsConfig = {
          ...section,
          showOptional: display,
        };
        sectionsConfig.current.splice(idxSection, 1, sectionUpdate);
      }
      setShowOptional(display);
    }
  };

  /**
   * Sets the validation result for the current step
   * @param isValid If step is valid or not
   */
  const setStepValidationResult = (isValid: boolean) => {
    const newValidated = validated;
    newValidated[currentStep] = isValid;
    setValidated(newValidated);
  };

  /**
   * Determines the validation scenario for the current step when 'Next Step' is clicked
   */
  const stepValidationProcess = async () => {
    if (isMultipleSection()) {
      let multSection: AccountSectionNames = 'phoneNumbers';
      let phoneRules = true;
      let phonePrimary = true;
      let emailRules = true;
      let emailPrimary = true;

      if (isMultipleSectionRequired('phoneNumbers') || showPhoneOptional) {
        phoneRules = await trigger([multSection]);
        phonePrimary = checkPrimaries(multSection);
      }
      if (isMultipleSectionRequired('emailAddresses') || showEmailOptional) {
        multSection = 'emailAddresses';
        emailRules = await trigger([multSection]);
        emailPrimary = checkPrimaries(multSection);
      }
      setStepValidationResult(
        currentDirty && phoneRules && phonePrimary && emailRules && emailPrimary
      );
    } else if (isSingleSectionRequired() || showOptional) {
      const section = findSection();
      const sectionName = section?.name as keyof typeof getAccount;
      const validateRules = await trigger([sectionName]);
      const primariesCheck = checkPrimaries(sectionName);
      const commenceDateCheck = checkCommenceDate(sectionName);
      const ceaseDateCheck = checkCeaseDate(sectionName);
      const entityCommenceDateCheck = checkEntityCommenceDate(sectionName);
      const filingFrequenciesCheck = checkFilingFrequencies(sectionName);
      const extendedAttrCheck = checkExtendedAttributes();
      setStepValidationResult(
        currentDirty &&
          validateRules &&
          primariesCheck &&
          commenceDateCheck &&
          ceaseDateCheck &&
          entityCommenceDateCheck &&
          filingFrequenciesCheck &&
          extendedAttrCheck
      );
    } else if (currentStep === CONFIRMATION_STEP || !showOptional) {
      setStepValidationResult(true);
    }
  };

  /**
   * Saves the account and manages the response
   * @param accountData Object containing all Account information
   * @returns Sucess or Error when creating the account
   */
  const saveAccount = async (accountData: Account) => {
    return createAccount(
      {
        account: accountData,
      },
      {
        onSuccess: async (data: CreateAccountMutation) => {
          navigate(
            `/${module}/entity/${entityId}/account/${data.CreateAccount?.id}`
          );
          dispatch(
            addMessage({
              type: MessageType.Success,
              message: t('pages.createAccount.message.success'),
            })
          );
          setSaveSuccessful(true);
        },
        onError: async (e: Error[] | unknown) => {
          let message: string = t('pages.createAccount.message.error');
          message = extractMeaningfulMessage(e, message);
          dispatch(
            addMessage({
              type: MessageType.Error,
              message: message,
            })
          );
          setSaveSuccessful(false);
        },
        onSettled: () => {
          setAccountSaving(false);
        },
      }
    );
  };

  /**
   * Submits the data to the service once saved by the user
   * @param data Data contained in the Account form
   */
  const onSubmit = (data: AccountForm) => {
    const platformConfigId =
      layoutData?.GetConfigurationSchema.platformConfigurationId;
    if (layoutError || !platformConfigId) {
      dispatch(
        addMessage({
          type: MessageType.Error,
          message: t('pages.createAccount.message.error'),
        })
      );
    } else {
      setAccountSaving(true);
      processEmptySections(data);
      processCommenceDates(data);
      const accountResponse = buildAccountRequest(
        data,
        entityId,
        accountType,
        platformConfigId,
        extendedVals
      );
      saveAccount(accountResponse);
    }
  };

  /**
   * Effectively refetch extended attributes when account type is changed
   */
  useEffect(() => {
    refetchExtended();
  }, [accountType, refetchExtended]);

  /**
   * Watch for period frequency, if value is fiscal year we need to allow the user
   * to select a month where the fiscal year ends
   */
  useEffect(() => {
    resetPeriodFrequency(periodFrequencyType);
  }, [periodFrequencyType, resetPeriodFrequency]);

  /**
   * If the current section is optional, this sets a default behavior
   */
  useEffect(() => {
    const sectionFound = sectionsConfig.current.find(
      (section) => section.step === currentStep
    );
    setShowOptional(sectionFound?.showOptional ?? false);
  }, [currentStep, sectionsConfig]);

  /**
   * Validates if the current section form was 'touched' or not
   */
  useEffect(() => {
    if (isSingleSectionRequired() && findSection()?.name !== 'industryCodes') {
      const section = findSection();
      const sectionName = section?.name as keyof typeof getAccount;
      const { isDirty } = getFieldState(sectionName, formState);
      setCurrentDirty(isDirty);
    } else {
      setCurrentDirty(true);
    }
  }, [
    formState,
    findSection,
    isSingleSectionRequired,
    getFieldState,
    getValues,
  ]);

  /**
   * Provides the needed values to the Entity/Asset Context
   */
  const contextValue = useMemo(() => {
    const idTypeChangeHandler = (selectedIdType: string) => {
      setIdType(selectedIdType);
    };
    const commenceDateHandler = (selectedCommenceDate: string) => {
      setCommenceDate(selectedCommenceDate);
    };
    const accountTypeChangeHandler = (selectedAccountType: string) => {
      setAccountType(selectedAccountType);
      resetPeriodFrequency();
      clearErrors();
    };

    return {
      selectedIdType: idType,
      selectedType: accountType,
      selectedCommenceDate: commenceDate,
      onTypeChange: accountTypeChangeHandler,
      onIdTypeChange: idTypeChangeHandler,
      onCommenceDateChange: commenceDateHandler,
      idValidationRules: getValidationRules(configuredIdTypes),
      idFormats: getIdFormats(configuredIdTypes),
      idMaxLengths: getMaskRules(configuredIdTypes),
    };
  }, [
    idType,
    accountType,
    commenceDate,
    configuredIdTypes,
    clearErrors,
    resetPeriodFrequency,
  ]);

  return (
    <EntityManagementContext.Provider value={contextValue}>
      <HorizontalNonLinearStepper
        steps={sections}
        saveSuccessful={saveSuccessful}
        currentStep={currentStep}
        handleCurrentStep={(activeStep: number) => {
          setCurrentStep(activeStep);
        }}
        handleSave={handleSubmit(onSubmit)}
        handleFormReset={() => {
          reset();
        }}
        handleStepValidation={stepValidationProcess}
        validatedSteps={validated}
        saveBtnLabel={t('pages.createAccount.title')}
        resetBtnLabel={t('pages.accountSummary.actions.reset')}
        nonLinear={false}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          {((isLayoutFetching && accountType !== '' && isEntityDataFetching) ||
            isAccountSaving) && <Loading />}
          {singleSections.map((accountSection) => {
            if (accountSection.step === currentStep) {
              return (
                <SectionContainer
                  key={accountSection.section.sectionIdentifier}
                  section={getSectionFields(accountSection.section)}
                  entityId={entityId}
                  isRequired={isSingleSectionRequired()}
                  showOptional={showOptional}
                  handleShowOptional={showOptionalSection}
                  {...{
                    control: control as Control<FieldValues, unknown>,
                    register,
                    account,
                    getValues,
                    setValue,
                  }}
                />
              );
            }
          })}
          {isMultipleSection() && (
            <>
              <SectionContainer
                section={PhoneNumberFields}
                isRequired={isMultipleSectionRequired('phoneNumbers')}
                showOptional={showPhoneOptional}
                handleShowOptional={showOptionalSection}
                {...{
                  control: control as Control<FieldValues, unknown>,
                  register,
                  getValues,
                  setValue,
                }}
              />
              <SectionContainer
                section={EmailAddressFields}
                isRequired={isMultipleSectionRequired('emailAddresses')}
                showOptional={showEmailOptional}
                handleShowOptional={showOptionalSection}
                {...{
                  control: control as Control<FieldValues, unknown>,
                  register,
                  getValues,
                  setValue,
                }}
              />
            </>
          )}
          {(isFetchingExtended || isFetchingSchemaGroups) && (
            <Box sx={{ marginTop: '15px' }}>
              <Loading fullScreen={false} />
            </Box>
          )}
          {currentStep === 0 && (
            <ExtendedAttributes
              extendedAttributes={
                extendedAttrData?.GetConfigurationSchemaById
                  .platformConfigurationInfo?.configurationSection[0].group[0]
                  .attribute ?? []
              }
              extendedValues={extendedVals}
              handleExtendedValues={handleExtendedAttributeValues}
            />
          )}
          {currentStep === CONFIRMATION_STEP && (
            <ConfirmationAccount
              data={getValues()}
              extendedData={extendedVals}
            />
          )}
        </form>
      </HorizontalNonLinearStepper>
    </EntityManagementContext.Provider>
  );
}

export default AccountLayout;
