import { useEffect, useState } from 'react';

import { Box, Collapse, IconButton, Stack, Theme } from '@mui/material';
import { Checkbox, Input, Button } from '@revenue-solutions-inc/revxcoreui';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import { EditAccessUtil } from 'pages/admin/ConfigTypeEditor/editAccessUtil';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import { Attribute, Group } from 'generated/graphql';
import {
  cancelButton,
  checkButton,
  collapseButton,
  deleteButton,
  editButton,
  expandButton,
  fontSize,
  icon,
} from '../Shared/IconButtonStyling/IconButtonStyling';
import AttributeDetails from '../AttributeDetails';

interface Props {
  group?: Group;
  handleChange: (groupName: string, group: Group) => boolean;
  handleDelete: (groupName: string) => void;
  setNewGroup?: React.Dispatch<React.SetStateAction<boolean>>;
  isNewGroup?: boolean;
  editAccess?: EditAccessUtil;
  isExpanded?: boolean;
  hasRecords?: boolean;
}

const groupDefault: Group = {
  attribute: [],
};

function GroupDetails({
  group = groupDefault,
  handleChange,
  handleDelete,
  setNewGroup,
  isNewGroup,
  editAccess,
  isExpanded = false,
  hasRecords,
}: Props) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.configTypeEditor',
  });

  const [inEditMode, setInEditMode] = useState<boolean>(false);
  const [tempGroup, setTempGroup] = useState<Group>(group);
  const [groupNameExists, setGroupNameExists] = useState(false);
  const [isNewField, setNewField] = useState<boolean>(false);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (isNewGroup) {
      setInEditMode(true);
    }
  }, [isNewGroup]);

  const handleEditClick = () => {
    setInEditMode(true);
  };
  const handleCancelClick = () => {
    setTempGroup(group);
    setInEditMode(false);
    setNewGroup?.(false);
  };

  const handleSaveClick = () => {
    const success = handleChange(group.groupName ?? '', tempGroup);
    if (success) {
      setInEditMode(false);
      setGroupNameExists(false);
      setNewGroup?.(false);
    } else {
      setGroupNameExists(true);
    }
  };

  const deleteAttribute = (oldAttrName: string) => {
    group.attribute =
      group.attribute?.filter((attr) => attr.attributeName !== oldAttrName) ??
      [];
    handleChange(group.groupName ?? '', group);
  };

  const updateAttribute = (oldAttrName: string, newAttribute: Attribute) => {
    let attributeNameExists = undefined;
    //since we no longer have the 'selected attribute', we don't know the original name of the attribute
    //so we have to check if there is already an attribute with that name, then they can't save it?
    //we need to maintain the old attribute name, pass in the name and new attribute
    if (!isNewField && oldAttrName === newAttribute.attributeName) {
      attributeNameExists = undefined;
    } else {
      attributeNameExists = group?.attribute.find(
        (attr) => attr.attributeName === newAttribute.attributeName
      );
    }
    if (attributeNameExists === undefined) {
      const attributes = [...group.attribute];
      // new Field is added, existing field is replaced
      if (isNewField) {
        attributes.push(newAttribute);
      } else {
        const oldInd = group.attribute?.findIndex(
          (attr) => attr.attributeName === oldAttrName
        );
        attributes[oldInd] = newAttribute;
      }
      group.attribute = attributes;
      handleChange(group.groupName ?? '', group);

      return true;
    } else return false;
  };

  const addAttribute = () => {
    setNewField?.(true);
    setExpanded(true);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  useEffect(() => {
    setTempGroup(group);
  }, [group]);

  useEffect(() => {
    setExpanded(isExpanded);
  }, [isExpanded]);

  return (
    <Box
      sx={{
        m: '0px 0px 20px 20px',
      }}
    >
      <Box
        id="panel1a-header"
        sx={(theme: Theme) => {
          return {
            border: inEditMode
              ? `solid 2px ${theme.palette.grey[200]}`
              : 'none',
            backgroundColor: inEditMode ? 'white' : theme.palette.grey[100],
            borderRadius: '5px',
            p: '30px 10px 10px 10px',
          };
        }}
      >
        <Stack direction="row" spacing={2.5}>
          <IconButton
            onClick={handleExpandClick}
            sx={editButton}
            aria-label="expand-button"
          >
            <ExpandCircleDownIcon
              sx={expanded ? collapseButton : expandButton}
            />
          </IconButton>
          <Stack
            direction="column"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={0.5}
            mt="-20px !important"
          >
            <Input
              required
              id="groupNameId"
              onChange={(e) => {
                setTempGroup({
                  ...tempGroup,
                  groupName: e.target.value,
                });
              }}
              label={t('groupName')}
              value={tempGroup?.groupName || ''}
              sx={{ minWidth: '200px' }}
              error={groupNameExists}
              helperText={groupNameExists ? t('attributeExists') : ''}
              disabled={!inEditMode || (hasRecords && !isNewGroup)}
            />
            <Input
              id="groupDescriptionId"
              onChange={(e) => {
                setTempGroup({
                  ...tempGroup,
                  groupDescription: e.target.value,
                });
              }}
              label={t('groupDescription')}
              value={tempGroup?.groupDescription || ''}
              sx={{ minWidth: '200px' }}
              disabled={!inEditMode}
            />
            <Checkbox
              id={'groupTypeId'}
              label={t('groupType')}
              checked={tempGroup?.groupAttributeType === 1}
              onChange={(event) => {
                const newGroup = { ...tempGroup };
                newGroup.groupAttributeType = event.target.checked ? 1 : 0;
                setTempGroup(newGroup);
              }}
              disabled={!inEditMode}
            />
            {tempGroup?.groupAttributeType == 1 && (
              <Input
                id="repeatingActionLabel"
                onChange={(e) => {
                  setTempGroup({
                    ...tempGroup,
                    repeatingActionLabel: e.target.value,
                  });
                }}
                label={t('repeatingActionLabel')}
                value={tempGroup?.repeatingActionLabel || ''}
                sx={{ minWidth: '200px' }}
                placeholder={
                  t('suggested') +
                  '"' +
                  t('add') +
                  ' ' +
                  tempGroup.groupName +
                  '"'
                }
                disabled={!inEditMode}
              />
            )}
          </Stack>
          {!inEditMode && (
            <>
              <IconButton
                onClick={handleEditClick}
                sx={editButton}
                aria-label="edit-button"
                disabled={!editAccess?.controlExtendedGroup()}
              >
                <EditIcon sx={{ icon }} />
              </IconButton>
              <Box sx={{ right: '20px', position: 'absolute' }}>
                <IconButton
                  onClick={() => handleDelete(group.groupName ?? '')}
                  sx={deleteButton}
                  disabled={!editAccess?.controlExtendedGroup() || hasRecords}
                  aria-label="delete-button"
                >
                  <Delete sx={{ fontSize }} />
                </IconButton>
                <Button
                  id={'addAttributeBtn-configTypeEditor'}
                  onClick={addAttribute}
                  type={'secondary'}
                  disabled={!editAccess?.controlExtendedGroup() || isNewField}
                >
                  {t('addAttribute')}
                </Button>
              </Box>
            </>
          )}
          {inEditMode && (
            <Stack direction="row" spacing={1} sx={{ float: 'right' }}>
              <IconButton
                size="small"
                onClick={handleSaveClick}
                disabled={!tempGroup.groupName}
                sx={checkButton}
                aria-label="check-button"
              >
                <CheckIcon sx={icon} />
              </IconButton>
              <IconButton
                size="small"
                sx={cancelButton}
                onClick={handleCancelClick}
                aria-label="cancel-button"
              >
                <CloseIcon fontSize="small" sx={icon} />
              </IconButton>
            </Stack>
          )}
        </Stack>
      </Box>
      <Collapse
        in={expanded}
        timeout="auto"
        unmountOnExit
        sx={(theme: Theme) => {
          return {
            borderWidth: '0 0 0 2px',
            borderColor: theme.palette.linkBlue.main,
            borderStyle: 'solid',
            marginTop: '20px',
          };
        }}
      >
        {isNewField && (
          <AttributeDetails
            isNewField={isNewField}
            setNewField={setNewField}
            handleChange={updateAttribute}
            handleDelete={deleteAttribute}
            editAccess={editAccess}
            isExpanded={isExpanded}
            isRepeating={tempGroup?.groupAttributeType === 1}
            hasRecords={hasRecords}
          />
        )}
        {group.attribute.map((attribute) => {
          return (
            <AttributeDetails
              isNewField={false}
              setNewField={setNewField}
              attribute={attribute}
              handleChange={updateAttribute}
              handleDelete={deleteAttribute}
              editAccess={editAccess}
              isExpanded={isExpanded}
              key={attribute.attributeName}
              isRepeating={tempGroup?.groupAttributeType === 1}
              hasRecords={hasRecords}
            />
          );
        })}
      </Collapse>
    </Box>
  );
}
export default GroupDetails;
