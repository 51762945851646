import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Grid, Link, Typography, CardActions } from '@mui/material';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import { ColumnDef } from '@tanstack/react-table';
import { Button } from '@revenue-solutions-inc/revxcoreui';
import { formatNumber } from 'common/helpers';
import { setHeader } from 'redux/contentSlice';
import { useAppDispatch } from 'redux/hooks';
import { useTranslation } from 'react-i18next';
import {
  CommitBulkFileResponse,
  CustomClassPreview,
  StatusResponseType,
} from 'types/roles';

type TempProps = {
  rowNumber: number;
  status: string;
  details: string;
  data: string;
  startDate?: string;
  endDate?: string;
};

interface Props {
  comittedDataResponse: CommitBulkFileResponse[] | undefined;
  startOver: () => void;
}

export default function CommitResponse({
  comittedDataResponse,
  startOver,
}: Props) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.bulkUserUpload',
  });
  const [statusType, setStatusType] = useState<StatusResponseType>('Error');
  const [isShowAll, setIsShowAll] = useState(false);
  const dispatch = useAppDispatch();

  const resultSuccessColums: ColumnDef<TempProps>[] = [
    {
      id: 'rowNumber',
      accessorKey: 'rowNumber',
      header: () => <HeaderColumnNext localization={t('result.row')} />,
      cell: ({ getValue }) => {
        return <p style={{ width: '3em' }}>{getValue() as string}</p>;
      },
    },
    {
      id: 'status',
      accessorKey: 'status',
      header: () => <HeaderColumnNext localization={t('result.status')} />,
      cell: ({ getValue }) => {
        return <p style={{ width: '5em' }}>{getValue() as string}</p>;
      },
    },
    {
      id: 'details',
      accessorKey: 'details',
      header: () => <HeaderColumnNext localization={t('result.details')} />,
      cell: ({ getValue }) => {
        return (
          <p style={{ width: '30em', wordBreak: 'break-word' }}>
            {getValue() as string}
          </p>
        );
      },
    },
    {
      id: 'data',
      accessorKey: 'data',
      header: () => <HeaderColumnNext localization={t('result.data')} />,
      cell: ({ getValue }) => {
        return (
          <p style={{ width: '40.0em', wordBreak: 'break-word' }}>
            {getValue() as string}
          </p>
        );
      },
    },
    {
      id: 'startDate',
      accessorKey: 'startDate',
      header: () => (
        <HeaderColumnNext localization={t('result.roleStartDate')} />
      ),
      cell: ({ getValue }) => {
        return <p style={{ width: '7em' }}>{getValue() as string}</p>;
      },
    },
    {
      id: 'endDate',
      accessorKey: 'endDate',
      header: () => <HeaderColumnNext localization={t('result.roleEndDate')} />,
      cell: ({ getValue }) => {
        return <p style={{ width: '7em' }}>{getValue() as string}</p>;
      },
    },
  ];

  const resultColums: ColumnDef<TempProps>[] = [
    {
      id: 'rowNumber',
      accessorKey: 'rowNumber',
      header: () => <HeaderColumnNext localization={t('result.row')} />,
      cell: ({ getValue }) => {
        return <p style={{ width: '3em' }}>{getValue() as string}</p>;
      },
    },
    {
      id: 'status',
      accessorKey: 'status',
      header: () => <HeaderColumnNext localization={t('result.status')} />,
      cell: ({ getValue }) => {
        return <p style={{ width: '5em' }}>{getValue() as string}</p>;
      },
    },
    {
      id: 'details',
      accessorKey: 'details',
      header: () => <HeaderColumnNext localization={t('result.details')} />,
      cell: ({ getValue }) => {
        return (
          <p style={{ width: '30em', wordBreak: 'break-word' }}>
            {getValue() as string}
          </p>
        );
      },
    },
    {
      id: 'data',
      accessorKey: 'data',
      header: () => <HeaderColumnNext localization={t('result.data')} />,
      cell: ({ getValue }) => {
        return (
          <p style={{ width: '40em', wordBreak: 'break-word' }}>
            {getValue() as string}
          </p>
        );
      },
    },
    {
      id: 'startDate',
      accessorKey: 'startDate',
      header: () => (
        <HeaderColumnNext localization={t('result.roleStartDate')} />
      ),
      cell: ({ getValue }) => {
        return <p style={{ width: '7em' }}>{getValue() as string}</p>;
      },
    },
    {
      id: 'endDate',
      accessorKey: 'endDate',
      header: () => <HeaderColumnNext localization={t('result.roleEndDate')} />,
      cell: ({ getValue }) => {
        return <p style={{ width: '7em' }}>{getValue() as string}</p>;
      },
    },
  ];

  const getDataByStatus = useCallback(
    (statusParam: StatusResponseType) => {
      const temp: TempProps[] = [];

      comittedDataResponse?.forEach(
        ({
          status,
          descriptions,
          rowNumber,
          firstName,
          lastName,
          email,
          moduleName,
          roleName,
          startDate,
          endDate,
        }) => {
          if (statusParam === status) {
            const dataBuilder: string[] = [`${firstName} ${lastName}`, email];
            if (moduleName) dataBuilder.push(moduleName);
            if (roleName) dataBuilder.push(roleName);
            if (status === 'Success') {
              temp.push({
                rowNumber,
                status: 'Success',
                data: dataBuilder.join(' - '),
                details: descriptions.join(' - '),
                startDate,
                endDate,
              });
            } else {
              temp.push({
                rowNumber,
                status,
                details: descriptions.join(' - '),
                data: dataBuilder.join(' - '),
                startDate,
                endDate,
              });
            }
          }
        }
      );

      return temp;
    },
    [comittedDataResponse]
  );

  useEffect(() => {
    dispatch(setHeader({ pageTitle: t('summaryHeaderTitle') }));
  }, [dispatch, t]);

  const { successTotal, skippedTotal, errorTotal } = useMemo(() => {
    return {
      successTotal: formatNumber(getDataByStatus('Success').length),
      skippedTotal: formatNumber(getDataByStatus('Skipped').length),
      errorTotal: formatNumber(getDataByStatus('Error').length),
    };
  }, [getDataByStatus]);

  const dataSource = useMemo(() => {
    const successes: TempProps[] = [];
    const skipped: TempProps[] = [];
    const errors: TempProps[] = [];

    if (statusType === 'Success' || isShowAll) {
      getDataByStatus('Success').forEach((row) => {
        successes.push(row);
      });
    }

    if (statusType === 'Skipped' || isShowAll) {
      getDataByStatus('Skipped').forEach((row) => {
        skipped.push(row);
      });
    }

    if (statusType === 'Error' || isShowAll) {
      getDataByStatus('Error').forEach((row) => {
        errors.push(row);
      });
    }

    return [...successes, ...skipped, ...errors];
  }, [isShowAll, statusType, getDataByStatus]);

  const handleStatus = (param: StatusResponseType) => () => {
    setStatusType(param);
    setIsShowAll(false);
  };

  const boxClass = (param: StatusResponseType) => {
    let classesName: CustomClassPreview = {
      margin: '0.5em',
      padding: '0 2.5em',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      paddingTop: '0.5em !important',
      cursor: 'pointer',
    };

    if (param === statusType && !isShowAll) {
      classesName = {
        ...classesName,
        backgroundColor: 'linkBlue.dark',
        color: 'white.main',
      };
    }

    return classesName;
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography color="text.primary" sx={{ fontSize: '1.1em' }}>
          {t('uploadedFile')}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ mt: 2, mb: '4em' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'stretch',
          }}
        >
          <Box
            sx={boxClass('Success')}
            onClick={handleStatus('Success')}
            data-testid="successTotal"
          >
            <Box
              sx={{
                fontWeight: 600,
                fontSize: '1.3em',
              }}
            >
              {t('boxes.successes')}
            </Box>
            <Box sx={{ fontSize: '3em' }}>{successTotal}</Box>
          </Box>
          <Box
            sx={boxClass('Skipped')}
            onClick={handleStatus('Skipped')}
            data-testid="skippedTotal"
          >
            <Box
              sx={{
                fontWeight: 600,
                fontSize: '1.3em',
              }}
            >
              {t('boxes.skipped')}
            </Box>
            <Box sx={{ fontSize: '3em' }}>{skippedTotal}</Box>
          </Box>
          <Box
            sx={boxClass('Error')}
            onClick={handleStatus('Error')}
            data-testid="errorTotal"
          >
            <Box
              sx={{
                fontWeight: 600,
                fontSize: '1.3em',
              }}
            >
              {t('boxes.errors')}
            </Box>
            <Box sx={{ fontSize: '3em' }}>{errorTotal}</Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sx={{ mt: 3 }}>
        <Grid sx={{ mb: '-4.2em', ml: '1em' }}>
          {!isShowAll && (
            <Link
              id="showAllResults"
              sx={{
                fontSize: '1.1em',
                textDecoration: 'none',
                cursor: 'pointer',
              }}
              onClick={() => setIsShowAll(true)}
            >
              {t('showAllResults')}
            </Link>
          )}
        </Grid>
        {statusType === 'Success' && (
          <DefaultDataTableNext
            columns={
              resultSuccessColums as ColumnDef<Record<string, unknown>>[]
            }
            data-testid="result-data"
            data={dataSource}
          />
        )}
        {statusType === 'Skipped' && (
          <DefaultDataTableNext
            columns={resultColums as ColumnDef<Record<string, unknown>>[]}
            data-testid="result-data"
            data={dataSource}
          />
        )}
        {statusType === 'Error' && (
          <DefaultDataTableNext
            columns={resultColums as ColumnDef<Record<string, unknown>>[]}
            data-testid="result-data"
            data={dataSource}
          />
        )}
      </Grid>
      <CardActions sx={{ justifyContent: 'flex-end', py: 3, px: 2 }}>
        <Button id="startOver" type="ghost" onClick={startOver}>
          {t('buttons.startOver')}
        </Button>
      </CardActions>
    </>
  );
}
