import { useCallback, useEffect, ReactNode, useMemo } from 'react';
import { Divider, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { delay } from 'utils/delay';

const stepperStyle = {
  '& .MuiStepLabel-root .Mui-completed': {
    color: 'success.main',
  },
  '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel': {
    color: 'success.main',
  },
  '& .MuiStepLabel-root .Mui-active': {
    color: 'secondaryOrange.main',
  },
  '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel': {
    color: 'secondaryOrange.main',
  },
  '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
    fill: 'white.main',
  },
  '& .MuiStepLabel-label': {
    textAlign: 'left',
  },
  '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
    textAlign: 'left',
  },
  '& .MuiStepLabel-root': {
    alignItems: 'start',
  },
  '& .MuiButtonBase-root': {
    justifyContent: 'start',
  },
  '& .MuiStepConnector-root': {
    left: 'calc(-100% + 40px)',
    right: 'calc(100% + 0px)',
  },
  '& .MuiTypography-caption': {
    fontSize: '0.775rem',
  },
};

interface StepsProps {
  title: string;
  description?: string;
}

interface Props {
  steps: StepsProps[];
  currentStep: number;
  completed: { [k: number]: boolean };
  isDone: boolean;
  setIsDone: (isDone: boolean) => void;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  handleCurrentStep: (step: number) => void;
  handleSave: (isDraft: boolean) => void;
  handleCreate: () => void;
  handleReset: () => void;
  children: ReactNode;
  isCompleteStepBtnDisabled?: boolean;
  isSaveAndCreateDisabled: boolean;
  nonLinear?: boolean;
  alternativeLabel?: boolean;
  centerAlign?: boolean;
  allStepsCompletedLabel?: string;
}

/**
 * Creates Header component
 * @param {reset} - status to verify some variable must be reseted
 * @param {setReset} - change reset value
 * @param {steps}  - array of all the steps and their associated labels.
 * @param {currentStep} - the current step.
 * @param {handleCurrentStep} - handles the current step.
 * @param {handleSave} - save changes handler.
 * @param {handleCreate} - create changes handler.
 * @param {children} - children that are passed down for each step.
 * @param {isCompleteStepBtnDisabled} - controls whether button to complete a step is disabled.
 * @param {nonLinear} - controls whether you can access steps in a nonlinear fashion, set to true by default..
 * @param {alternativeLabel} - will display the labels below the steps, set to true by default.
 * @param {centerAlign} - will center align the stepper.
 */

function HorizontalNonLinearStepper({
  steps,
  completed,
  currentStep,
  children,
  isCompleteStepBtnDisabled,
  isSaveAndCreateDisabled,
  isDone,
  setIsDone,
  handleCurrentStep,
  handleSave,
  handleCreate,
  setCurrentStep,
  nonLinear = true,
  alternativeLabel = true,
  centerAlign = false,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const totalSteps = useMemo(() => {
    return steps.length;
  }, [steps]);

  const completedSteps = useMemo(() => {
    const completedKey = Object.keys(completed).filter((key) => {
      return completed[parseInt(key)];
    });

    return completedKey.length;
  }, [completed]);

  const isLastStep = useMemo(() => {
    return currentStep === totalSteps - 1;
  }, [currentStep, totalSteps]);

  const { tenantId } = useParams() as { tenantId: string };

  const isUpdateTenantDraft = useMemo(() => {
    return tenantId !== undefined;
  }, [tenantId]);

  const allStepsCompleted = useMemo(() => {
    return completedSteps === totalSteps && isDone;
  }, [completedSteps, totalSteps, isDone]);

  const handleNext = useCallback(() => {
    const newActiveStep =
      isLastStep && !allStepsCompleted
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : currentStep + 1;
    setCurrentStep(newActiveStep);
  }, [
    completed,
    steps,
    isLastStep,
    allStepsCompleted,
    currentStep,
    setCurrentStep,
  ]);

  const handleStep = (step: number) => () => {
    setCurrentStep(step);
  };

  const handleComplete = useCallback(() => {
    handleNext();
  }, [handleNext]);

  const handleSaveDraft = useCallback(() => {
    const isDraft = true;
    handleSave(isDraft);
    setIsDone(true);
    return;
  }, [handleSave, setIsDone]);

  const handleCreateTenant = useCallback(() => {
    const isDraft = false;
    handleSave(isDraft);
    delay(2000).then(() => handleCreate());
    setIsDone(true);
    return;
  }, [handleCreate, handleSave, setIsDone]);

  const handleBack = () => {
    setCurrentStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    handleCurrentStep(currentStep);
  }, [handleCurrentStep, currentStep]);

  const handleEnableNextStep = () => {
    let isDisabled = false;
    if (isLastStep) {
      isDisabled = isSaveAndCreateDisabled;
    } else if (isCompleteStepBtnDisabled != undefined) {
      isDisabled = isCompleteStepBtnDisabled;
    }
    return isDisabled;
  };

  const handleDisabledSaveAsDraft = () => {
    const rsiContacts = 0;
    const tenantInfo = 1;
    const tenancyConfig = 2;
    const modules = 3;
    let disabled = true;

    if (
      currentStep === rsiContacts &&
      completed[`${rsiContacts}`] &&
      completed[`${tenantInfo}`]
    ) {
      disabled = false;
    } else if (
      currentStep === tenantInfo &&
      completed[`${rsiContacts}`] &&
      completed[`${tenantInfo}`]
    ) {
      disabled = false;
    } else if (
      currentStep === tenancyConfig &&
      completed[`${rsiContacts}`] &&
      completed[`${tenantInfo}`]
    ) {
      disabled = false;
    } else if (
      currentStep === modules &&
      completed[`${rsiContacts}`] &&
      completed[`${tenantInfo}`]
    ) {
      disabled = false;
    }
    return disabled;
  };

  const handleDisableLastStep = () =>
    isLastStep && isSaveAndCreateDisabled ? isCompleteStepBtnDisabled : true;

  return (
    <Box sx={{ width: '100%' }}>
      <Grid container my={3} justifyContent={centerAlign ? 'center' : 'start'}>
        <Grid item xs={12}>
          <Stepper
            nonLinear={nonLinear}
            activeStep={currentStep}
            alternativeLabel={alternativeLabel}
          >
            {steps.map((label, index) => (
              <Step
                key={label.title}
                completed={completed[index]}
                sx={stepperStyle}
              >
                <StepButton color="red" onClick={handleStep(index)}>
                  <Typography variant="h5">Step {index + 1}</Typography>
                  <Box>{label.title}</Box>
                  {currentStep === index && (
                    <Typography variant="caption" display="block">
                      {label?.description}
                    </Typography>
                  )}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </Grid>
      </Grid>
      <Divider sx={{ mb: 2 }}></Divider>
      <div>
        {
          <>
            <Grid item xs={12}>
              {children}
            </Grid>
            <Box mt={2} sx={{ display: 'flex', flexDirection: 'row' }}>
              {currentStep !== 0 && (
                <Button
                  id="prevStep"
                  onClick={handleBack}
                  type="secondary"
                  variant="outlined"
                  sx={{ mr: 1, backgroundColor: 'grey6.main' }}
                >
                  {t('pages.horizontalNonLinearStepper.prevStep')}
                </Button>
              )}

              {!isLastStep && (
                <Button
                  id="complete"
                  onClick={handleComplete}
                  type="primary"
                  disabled={handleEnableNextStep()}
                  sx={{ mr: 1 }}
                >
                  {t('pages.horizontalNonLinearStepper.nextStep')}
                </Button>
              )}
              <Button
                id={isUpdateTenantDraft ? 'updateDraft' : 'saveTenantDraft'}
                onClick={handleSaveDraft}
                type="primary"
                disabled={handleDisabledSaveAsDraft()}
                sx={{ mr: 1 }}
              >
                {isUpdateTenantDraft
                  ? t('pages.tenantConfig.buttons.updateDraft')
                  : t('pages.tenantConfig.buttons.saveDraft')}
              </Button>
              {isLastStep ? (
                <Button
                  id="saveTenant"
                  onClick={handleCreateTenant}
                  type="primary"
                  disabled={handleDisableLastStep()}
                  sx={{ mr: 1 }}
                >
                  {t('pages.tenantConfig.buttons.saveAndCreate')}
                </Button>
              ) : (
                ''
              )}
              {currentStep !== steps.length && completed[currentStep] && (
                <Typography
                  variant="caption"
                  sx={{ display: 'inline-block', pt: '0.2rem' }}
                >
                  {t('pages.horizontalNonLinearStepper.step')} {currentStep + 1}{' '}
                  {t('pages.horizontalNonLinearStepper.alreadyCompleted')}
                </Typography>
              )}
            </Box>
          </>
        }
      </div>
    </Box>
  );
}

export default HorizontalNonLinearStepper;
