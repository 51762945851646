import { useState, useEffect } from 'react';

import { Grid } from '@mui/material';
import DateCell from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/TableCells/DateCell/DateCell';
import DataTableNextDateRangeFilter from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DataTableNextDateRangeFilter';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext';
import { ColumnDef } from '@tanstack/react-table';
import { filterByDateRange } from 'common/helpers';
import Loading from 'components/Loading';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { setHeader } from 'redux/contentSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useHasAccess } from 'hooks/useHasAccess';
import { useTheme } from '@mui/system';
import { BatchResponse, useFindBatchQuery } from 'generated/graphql';

function ManageBatchesRevenue(): JSX.Element {
  const { t } = useTranslation();
  const theme = useTheme();
  const [beginDate, setBeginDate] = useState<Date | null>();
  const [endDate, setEndDate] = useState<Date | null>();
  const dispatch = useAppDispatch();
  const module = useAppSelector((state) => state.user.module);
  const canEdit = useHasAccess('manageBatchesRevenue', 'edit');

  const { isLoading, error, data } = useFindBatchQuery({
    openDateBegin: `2000-01-01`,
  });

  const Columns: ColumnDef<BatchResponse>[] = [
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.manageBatches.id')} />
      ),
      accessorKey: 'label',
      cell: ({ row, getValue }) => {
        if (!canEdit) return <>{getValue() as string}</>;
        return (
          <Link
            to={{
              pathname: `/${module}/batchDetails/${row.original.id}`,
            }}
            style={{ color: theme.palette.linkBlue.dark }}
          >
            {getValue() as string}
          </Link>
        );
      },
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.manageBatches.alternateId')} />
      ),
      accessorKey: 'externalId',
    },
    {
      header: () => (
        <HeaderColumnNext
          localization={t('pages.manageBatches.creationDate')}
        />
      ),
      accessorKey: 'createdDate',
      cell: ({ getValue }) => {
        return <DateCell dateString={getValue() as string} />;
      },
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.manageBatches.batchType')} />
      ),
      accessorKey: 'batchType',
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.manageBatches.numItems')} />
      ),
      accessorKey: 'recordCount',
    },
    {
      header: () => (
        <HeaderColumnNext
          localization={t('pages.manageBatches.totalFromDocs')}
        />
      ),
      // UPDATE THIS WHEN PROPERTY EXISTS ON ENDPOINT
      accessorKey: 'totalFromDocs',
    },
    {
      header: () => (
        <HeaderColumnNext
          localization={t('pages.manageBatches.totalFromPayments')}
        />
      ),
      // UPDATE THIS WHEN PROPERTY EXISTS ON ENDPOINT
      accessorKey: 'totalFromPayments',
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.manageBatches.status')} />
      ),
      accessorKey: 'batchStatus',
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.manageBatches.idRange')} />
      ),
      accessorKey: 'batchDetails',
      cell: ({ row }) => {
        const batchDetails = row.original.batchDetails;
        // If array is empyty, returning null
        if (batchDetails.length === 0) return null;

        // If array has only one item, just return the first documentId
        if (batchDetails.length === 1)
          return <>{`${batchDetails[0].documentId}`}</>;

        //If array has more than one item, sort the data based on documentId and return first and last documentId
        const sortedBatchDetails = batchDetails.sort(
          (a, b) => a.documentId - b.documentId
        );
        return (
          <>{`${sortedBatchDetails[0].documentId} - ${
            sortedBatchDetails[sortedBatchDetails.length - 1].documentId
          }`}</>
        );
      },
    },
    {
      header: () => (
        <HeaderColumnNext localization={t('pages.manageBatches.source')} />
      ),
      accessorKey: 'source',
    },
  ];

  useEffect(() => {
    dispatch(setHeader({ pageTitle: t('pages.manageBatches.title') }));
  }, [dispatch, t]);

  if (!isLoading && data) {
    return (
      <>
        {isLoading && <Loading />}
        {data && (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <DefaultDataTableNext
                columns={
                  Columns as ColumnDef<Record<string, unknown>, unknown>[]
                }
                data={
                  beginDate || endDate
                    ? filterByDateRange(data.FindBatch, beginDate, endDate)
                    : data.FindBatch
                }
                customHeader={
                  <>
                    <DataTableNextDateRangeFilter
                      data-testid="datatable-date-range"
                      beginDate={beginDate ? beginDate : null}
                      endDate={endDate ? endDate : null}
                      setBeginDate={setBeginDate}
                      setEndDate={setEndDate}
                    />
                  </>
                }
                globalFilterLabel={t('pages.manageBatches.findBatches')}
              />
            </Grid>
          </Grid>
        )}
      </>
    );
  }
  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <h1>{t('components.message.error')}</h1>;
  }
  return <></>;
}
export default ManageBatchesRevenue;
