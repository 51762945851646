import { useCallback, useEffect, useRef, useState } from 'react';
import { Stack, Box, CircularProgress, Grid } from '@mui/material';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import Select from '@revenue-solutions-inc/revxcoreui/material/controls/Select';
import { useTranslation } from 'react-i18next';
import { RsiContactInfo, Contact } from 'types/tenants';
import { useAppDispatch } from 'redux/hooks';
import {
  getRsiContacts,
  ConfigurationsResponse,
} from 'utils/getConfigurations';
import { useGetConfigurationsQuery } from 'generated/graphql';
import extractMeaningfulMessage from 'utils/errorMessage';
import { addMessage } from 'redux/messageSlice';
import {
  MessageActionType,
  MessageType,
} from '@revenue-solutions-inc/revxcoreui';
import RsiContactManager from '../RsiContactManager';
interface TenantInfoProps {
  rsiContactInfo: RsiContactInfo;
  handleChange: (newContactInfo: RsiContactInfo) => void;
  handleValidation: (isValid: boolean) => void;
}

function RsiContacts({
  rsiContactInfo,
  handleChange,
  handleValidation,
}: TenantInfoProps): JSX.Element {
  const dropdownFocus = useRef<HTMLSelectElement>(null);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [rsiContacts, setRsiContacts] = useState<Contact[]>([]);
  const [selectedContact, setSelectedContact] = useState<Contact>();
  const [contactError, setContactError] = useState<boolean>(false);

  const { isFetching } = useGetConfigurationsQuery<ConfigurationsResponse>(
    {
      configurationType: 'RSI Contacts',
      configurationModule: '',
    },
    {
      onSuccess: (response) => {
        if (response?.getConfigurations) {
          const validRsiContacts = getRsiContacts(response.getConfigurations);
          if (validRsiContacts) {
            setRsiContacts(validRsiContacts);
          }
        }
      },
      onError: (error) => {
        const message = extractMeaningfulMessage(
          error,
          t('pages.manageReusableContent.networkError')
        );
        dispatch(
          addMessage({
            message: message,
            type: MessageType.Error,
            actionType: MessageActionType.None,
          })
        );
      },
    }
  );

  const validateContacts = useCallback(
    (contacts: Contact[]) => {
      if (contacts && contacts?.length > 0 && !contactError) {
        const requiredLengthPhone = 12;
        const requiredFields = contacts.filter(
          ({ userContactType, userEmail, userFirstName, userPhone }) => {
            return (
              !userContactType &&
              !userEmail &&
              !userFirstName &&
              userPhone?.length === requiredLengthPhone
            );
          }
        );

        const onePrimary = contacts.filter(({ userContactType }) => {
          return userContactType === 'Primary';
        });

        return requiredFields?.length === 0 && onePrimary?.length === 1;
      }
      return false;
    },
    [contactError]
  );

  useEffect(() => {
    dropdownFocus.current?.focus();
  }, [rsiContacts]);

  /*
   * Need to check that values are not blank strings bc currently our mask
   * does not error on blank strings.
   * We are calling this useEffect w/o a 2nd argument bc this will need to
   * run every time a contact gets updated
   */
  useEffect(() => {
    const isValid = validateContacts(rsiContactInfo?.rsiContacts ?? []);
    handleValidation(isValid);
  }, [rsiContactInfo?.rsiContacts, validateContacts, handleValidation]);

  const availableContacts = useCallback(
    (contacts: Contact[] | []) => {
      let finalContacts: Contact[] = [...contacts];
      if (
        rsiContactInfo?.rsiContacts &&
        rsiContactInfo?.rsiContacts?.length > 0 &&
        finalContacts?.length > 0
      ) {
        rsiContactInfo.rsiContacts.forEach((usedContact) => {
          finalContacts = finalContacts.filter(
            (contact) => contact?.userId !== usedContact?.userId
          );
        });
      }
      return finalContacts;
    },
    [rsiContactInfo?.rsiContacts]
  );

  const handleGetContacts = (rsiContactsLocal: Contact[] | []): Contact[] =>
    rsiContactsLocal?.length > 0 ? rsiContactsLocal : [];

  return (
    <Grid
      container
      sx={{
        backgroundColor: 'white.main',
        padding: 2,
        borderRadius: 1,
        border: '1px solid #e2e2e2',
      }}
    >
      <Grid item xs={12} mb={0.5} data-testid="availableContactSelect">
        <Stack direction="row" spacing={2}>
          <Select
            inputProps={{
              inputRef: dropdownFocus,
              autofocus: true,
              'data-testid': 'select-input',
            }}
            label={t('pages.tenantConfig.rsiContacts.availableContacts')}
            required
            value={selectedContact?.userId ?? ''}
            id="availableContactSelect"
            options={
              availableContacts(rsiContacts)?.length > 0
                ? availableContacts(rsiContacts)?.map((contact: Contact) => {
                    return {
                      key: contact.userId ?? '',
                      desc: contact.userFirstName + ' ' + contact.userLastName,
                    };
                  })
                : []
            }
            onChange={(event) => {
              const contactSelect = availableContacts(rsiContacts)?.find(
                (contact) => contact.userId === event.target.value
              );
              if (contactSelect) {
                setSelectedContact(contactSelect);
              }
            }}
          />
          <Box sx={{ display: 'flex' }}>
            <Button
              id="addContacts"
              variant="outlined"
              type="secondary"
              disabled={!selectedContact}
              onClick={() => {
                if (selectedContact) {
                  let tempContacts: Contact[] = [];
                  if (
                    rsiContactInfo?.rsiContacts &&
                    rsiContactInfo?.rsiContacts?.length > 0
                  ) {
                    tempContacts = handleGetContacts(
                      rsiContactInfo?.rsiContacts
                    );
                  }
                  tempContacts = [
                    ...tempContacts,
                    {
                      ...selectedContact,
                      userFullName: `${selectedContact?.userFirstName} ${selectedContact?.userLastName}`,
                      userFirstName: selectedContact?.userFirstName,
                      userContactType:
                        tempContacts?.length === 0 ? 'Primary' : 'Secondary',
                      userPhone: selectedContact?.userPhone,
                    },
                  ];
                  if (tempContacts?.length > 0) {
                    handleChange({
                      ...rsiContactInfo,
                      rsiContacts: tempContacts,
                    });
                    setSelectedContact(undefined);
                  }
                }
              }}
              sx={{ mt: 2.5 }}
            >
              {t('components.button.addContact')}
            </Button>
          </Box>
          {isFetching && (
            <Box sx={{ display: 'flex', pt: 3 }}>
              <CircularProgress size={24} />
            </Box>
          )}
        </Stack>
      </Grid>
      <Grid item mt={2}>
        <RsiContactManager
          contacts={rsiContactInfo?.rsiContacts ?? []}
          onChange={(contacts: Contact[]) => {
            handleChange({
              ...rsiContactInfo,
              rsiContacts: contacts,
            });
          }}
          showOrganization={false}
          showThirdPartyType={false}
          allowFirstRowRemoval={true}
          phoneRequired={false}
          isReadOnly={true}
          onError={(hasError: boolean) => {
            setContactError(hasError);
          }}
        />
      </Grid>
    </Grid>
  );
}

export default RsiContacts;
