import { SystemStyleObject, Theme } from '@mui/system';
import { createSlice, PayloadAction, current } from '@reduxjs/toolkit';
import { HeaderElementProps } from 'components/layout/HeaderElement/HeaderElement';
import { Module } from 'generated/graphql';
import { router } from 'router';

export interface HeaderIcon {
  icon: string;
  props: SystemStyleObject<Theme> | undefined;
  fontSize: 'inherit' | 'large' | 'medium' | 'small';
}
export interface HeaderButtonLink {
  id: string;
  route?: string;
  action?: () => void;
  title: string;
  type: 'primary' | 'secondary' | 'ghost';
  disabled?: boolean;
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
}

export interface PageDetail {
  prevPageTitle?: string;
  prevRoute?: string;
}
export interface IHeader {
  previousPage?: string;
  route?: string;
  pageTitle?: string;
  icon?: HeaderIcon;
  buttonLinks?: HeaderButtonLink[];
  data?: (HeaderElementProps | HeaderIcon)[];
}

export interface Content {
  darkMode: boolean;
  language: string;
  scope: string;
  header: IHeader | undefined;
  modules: Module[] | undefined;
  history: PageDetail[];
  tenantName: string;
  environmentName: string;
}

const initialState: Content = {
  darkMode: false,
  language: 'en',
  scope: '',
  header: undefined,
  modules: undefined,
  history: [],
  tenantName: '',
  environmentName: '',
};

export const contentSlice = createSlice({
  name: 'content',
  initialState,
  reducers: {
    setScope: (state, action: PayloadAction<string>) => {
      state.scope = action.payload;
    },
    setEnvironmentName: (state, action: PayloadAction<string>) => {
      state.environmentName = action.payload;
    },
    setTenantName: (state, action: PayloadAction<string>) => {
      state.tenantName = action.payload;
    },
    setDarkMode: (state, action: PayloadAction<boolean>) => {
      state.darkMode = action.payload;
    },
    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
    },
    setHeader: (state, action: PayloadAction<IHeader>) => {
      const prev = {
        prevPageTitle: action.payload.previousPage,
        prevRoute: action.payload.route,
      };

      if (!!action.payload.route) {
        if (current(state.history).length === 0) {
          //First route on the stack
          state.history.push(prev);
        } else if (current(state.history.at(-1))) {
          const currentPage = current(state.history).at(-1);
          //Check that we are not adding a duplicate route to the stack
          //TODO: seperate this logic from the header in a follow on PR
          if (currentPage?.prevPageTitle != action.payload.previousPage) {
            state.history.push(prev);
          }
        }
      }
      state.header = action.payload;
    },
    removeHistory: (state, action: PayloadAction<string>) => {
      const previousPage = state.history.pop();
      const route = previousPage?.prevRoute
        ? previousPage.prevRoute
        : 'dashboard';
      router.navigate(`/${action.payload}/${route}`);
    },
    clearHistory: (state) => {
      state.history = [];
    },
    setModules: (state, action: PayloadAction<Module[]>) => {
      state.modules = action.payload;
    },
    clearHeader: (state) => {
      state.header = undefined;
    },
    clearContentState: (state) => {
      const newState = {
        ...initialState,
        language: state.language,
        darkMode: state.darkMode,
      };
      return newState;
    },
  },
});

export const {
  clearContentState,
  setDarkMode,
  setEnvironmentName,
  setTenantName,
  setLanguage,
  setScope,
  setHeader,
  setModules,
  clearHeader,
  removeHistory,
  clearHistory,
} = contentSlice.actions;

export default contentSlice.reducer;
