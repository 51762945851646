import { Buffer } from 'buffer';
import { useMemo, useState, useEffect, useCallback } from 'react';
import {
  Checkbox,
  DataDisplay,
  MessageActionType,
  MessageType,
  Radio,
  TextArea,
} from '@revenue-solutions-inc/revxcoreui';
import DateCell from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/TableCells/DateCell';
import Button from '@revenue-solutions-inc/revxcoreui/material/controls/Button';
import {
  ActiveCorrespondenceTypeResponse,
  ContactInfoGroup,
  Maybe,
  useActiveCorrespondenceTypeQuery,
  useGenerateLetterMutation,
  useGetAccountCorrespondenceContactInfoQuery,
  useGetAllRelationshipsQuery,
  useGetEntityCorrespondenceContactInfoQuery,
  useGetIsSuppressionExistsQuery,
  useGetManualFieldInfoQuery,
  User,
  useScheduleLetterMutation,
} from 'generated/graphql';
import { Relationships } from 'types/relationships';
import useGetAccessToken from 'hooks/useGetAccessToken';
import { useTranslation } from 'react-i18next';
import Dialog from '@revenue-solutions-inc/revxcoreui/material/controls/Dialog';
import { ColumnDef } from '@tanstack/react-table';
import DefaultDataTableNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/DefaultDataTableNext';
import HeaderColumnNext from '@revenue-solutions-inc/revxcoreui/material/controls/DataTablesNext/HeaderColumnNext/HeaderColumnNext';
import { Box, Grid, RadioGroup, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { useMsal } from '@azure/msal-react';
import useQueryRequest from 'hooks/useQueryRequest';
import { userBySearch } from 'pages/admin/ManageUserRoles/ManageuserRolesQuery';
import FileSaver from 'file-saver';
import { addMessage } from 'redux/messageSlice';
import { useAppDispatch } from 'redux/hooks';
import { dateFormat } from 'utils/date-util';
import {
  getRelationshipList,
  LinkTypes,
} from 'components/entityManagement/common/relationshipUtils';
import DataCard from 'components/DataCard';
import Loading from 'components/Loading';

interface Props {
  isFromTemplate: boolean;
  isHistoryRefetch: boolean;
  setIsHistoryRefetch: (value: boolean) => void;
  entityId: Maybe<string> | undefined;
  isFromEntity: boolean;
  accountId: Maybe<string> | undefined;
}
type Recipients = {
  recipientId: string;
  recipientType: string;
};

function AddLetterInfo(correspondenceProps: Props): JSX.Element {
  const defaultRecipients: Recipients[] = correspondenceProps.isFromEntity
    ? [
        {
          recipientId: correspondenceProps.entityId ?? '',
          recipientType: 'To',
        },
      ]
    : [
        {
          recipientId: correspondenceProps.accountId ?? '',
          recipientType: 'To',
        },
      ];
  const { t } = useTranslation();
  const { accounts } = useMsal();
  const [activeTemplate, setActiveTemplate] = useState<boolean>(false);
  const [openTemplate, setOpenTemplate] = useState<boolean>(false);
  const [isRecipientCC, setIsRecipientCC] = useState<boolean>(false);
  const { data: activeTemplateData, isLoading: activeLoading } =
    useActiveCorrespondenceTypeQuery({
      contextLevel: correspondenceProps.isFromEntity ? 'Entity' : 'Account',
    });
  const [corrType, setCorrType] = useState<ActiveCorrespondenceTypeResponse>();
  const [templateId, setTemplateId] = useState<Maybe<string> | undefined>();
  const [freeFormText, setFreeFormText] = useState<boolean>(false);
  const [address, setAddress] = useState<Maybe<string> | undefined>();
  const [localFtiFlag, setLocalFtiFlag] = useState<boolean>(
    corrType?.ftiFlag ?? false
  );
  const accessToken = useGetAccessToken();
  const dispatch = useAppDispatch();
  const { entityId } = useParams() as { entityId: string };
  const { accountId } = useParams() as { accountId: string };
  const [relationshipList, setRelationshipList] = useState<Relationships[]>([]);
  const [selectedRecipients, setSelectedRecipients] = useState<string[]>([]);
  const [recipientsInfo, setRecipientsInfo] =
    useState<Recipients[]>(defaultRecipients);
  const [generateLetterProps, setGenerateLetterProps] = useState({
    isPreview: false,
    generated: false,
  });
  const { isFetching: isFetchingRelations } = useGetAllRelationshipsQuery(
    {
      id: entityId ?? '',
    },
    {
      enabled: !!entityId,
      onSuccess: (relationshipsData) => {
        const tempRelationships = getRelationshipList(relationshipsData);
        setRelationshipList(tempRelationships);
      },
    }
  );

  const {
    data: ManualFieldInfo,
    refetch: ManualFieldInfoFetch,
    isLoading: ManualFieldInfoLoading,
  } = useGetManualFieldInfoQuery(
    {
      getManualFieldInfoByTemplateId: parseInt(templateId ?? ''),
    },
    { enabled: false, cacheTime: 0 }
  );

  const { mutate: generateMutation } = useGenerateLetterMutation();
  const { mutate: scheduleMutation } = useScheduleLetterMutation();

  const { data: EntityCorrespondenceContatInfo } =
    useGetEntityCorrespondenceContactInfoQuery({
      getEntityCorrespondenceContactInfoId: entityId ?? '',
    });

  const {
    data: AccountCorrespondenceContatInfo,
    refetch: accountContactInfoRefetch,
  } = useGetAccountCorrespondenceContactInfoQuery(
    {
      getAccountCorrespondenceContactInfoId: accountId ?? '',
    },
    { enabled: false }
  );

  const { data: IsSuppressionExistsEntity } = useGetIsSuppressionExistsQuery({
    contextId: entityId ?? '',
    contextType: 'Entity',
  });

  const { data: IsSuppressionExistsAccount } = useGetIsSuppressionExistsQuery({
    contextId: correspondenceProps.isFromEntity ? entityId : accountId,
    contextType: correspondenceProps.isFromEntity ? 'Entity' : 'Account',
  });

  const { data: userData } = useQueryRequest<{
    UsersBySearch: [User];
  }>(
    ['search', accounts[0]?.username],
    accessToken,
    userBySearch,
    {
      search: accounts[0]?.username,
    },
    'search'
  );

  interface MultiSelect {
    freeformFields: [
      {
        fieldName: string;
        value: string;
      }
    ];
    multiChoiceGroups: [
      {
        groupName: string;
        type: string;
        options: [
          { optionName: string; description: string; value: 'true' | 'false' }
        ];
      }
    ];
  }
  const [finalUpdatedJson, setFinalUpdatedJson] = useState<MultiSelect>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getInfoAttr = (object: any, params: string) => {
    if (object) {
      const resultValue =
        object.attribute.find(
          (attr: { attributeName: string }) => attr.attributeName === params
        )?.attributeValue ?? '';
      return resultValue ? resultValue : '';
    }
  };

  const handleCancleClick = () => {
    setActiveTemplate(false);
    setOpenTemplate(false);
  };

  const handleAddressDescription = useCallback(
    (groupData: ContactInfoGroup) => {
      const entityAddressArray = [];
      if (getInfoAttr(groupData, 'isPrimary') == 'true') {
        const addressLine1 = getInfoAttr(groupData, 'addressLine1');
        if (addressLine1) {
          entityAddressArray.push(addressLine1);
        }
        const addressLine2 = getInfoAttr(groupData, 'addressLine2');
        if (addressLine2) {
          entityAddressArray.push(addressLine2);
        }
        const postalCode = getInfoAttr(groupData, 'postalCode');
        if (postalCode) {
          entityAddressArray.push(postalCode);
        }

        const city = getInfoAttr(groupData, 'cityId');
        if (city) {
          entityAddressArray.push(city);
        }

        const county = getInfoAttr(groupData, 'countyId');
        if (county) {
          entityAddressArray.push(county);
        }

        const state = getInfoAttr(groupData, 'stateProvinceId');
        if (state) {
          entityAddressArray.push(state);
        }

        const country = getInfoAttr(groupData, 'countryId');
        if (country) {
          entityAddressArray.push(country);
        }
      }
      return entityAddressArray.join(', ');
    },
    []
  );

  const handleNames = useCallback((groupData: ContactInfoGroup) => {
    const fullName = getInfoAttr(groupData, 'value');
    if (fullName) {
      return fullName;
    } else {
      return '--';
    }
  }, []);

  const namesCellHandlerEntity = (entityRowData: Relationships) => {
    const filteredEntity =
      EntityCorrespondenceContatInfo?.GetEntityCorrespondenceContactInfo.filter(
        (item) => item.id === entityRowData.destination
      );
    if (
      EntityCorrespondenceContatInfo &&
      EntityCorrespondenceContatInfo?.GetEntityCorrespondenceContactInfo
        ?.length > 0 &&
      filteredEntity &&
      filteredEntity[0]?.group &&
      filteredEntity[0]?.group?.length > 0
    ) {
      for (let i = 0; i < filteredEntity[0]?.group?.length; i++) {
        const groupData = filteredEntity[0]?.group[i];
        if (groupData && groupData.groupName == 'names') {
          return handleNames(groupData);
        }
      }
    }
  };

  const namesCellHandlerAccount = (rowData: Relationships) => {
    const filteredAccount =
      AccountCorrespondenceContatInfo?.GetAccountCorrespondenceContactInfo.filter(
        (item) => item.id === rowData.destination
      );
    if (
      AccountCorrespondenceContatInfo &&
      AccountCorrespondenceContatInfo?.GetAccountCorrespondenceContactInfo
        ?.length > 0 &&
      filteredAccount &&
      filteredAccount[0]?.group &&
      filteredAccount[0]?.group?.length > 0
    ) {
      for (let i = 0; i < filteredAccount[0]?.group?.length; i++) {
        const groupData = filteredAccount[0]?.group[i];
        if (groupData && groupData.groupName == 'names') {
          return handleNames(groupData);
        }
      }
    }
  };

  const addressCellHandlerEntity = (entityRowData: Relationships) => {
    const filteredEntity =
      EntityCorrespondenceContatInfo?.GetEntityCorrespondenceContactInfo.filter(
        (item) => item.id === entityRowData.destination
      );
    if (
      EntityCorrespondenceContatInfo &&
      EntityCorrespondenceContatInfo?.GetEntityCorrespondenceContactInfo
        ?.length > 0 &&
      filteredEntity &&
      filteredEntity[0]?.group &&
      filteredEntity[0]?.group?.length > 0
    ) {
      for (let i = 0; i < filteredEntity[0]?.group?.length; i++) {
        const groupData = filteredEntity[0]?.group[i];
        if (groupData && groupData.groupName == 'addresses') {
          return handleAddressDescription(groupData);
        }
      }
    }
  };

  const addressCellHandlerAccount = (rowData: Relationships) => {
    const filteredAccount =
      AccountCorrespondenceContatInfo?.GetAccountCorrespondenceContactInfo.filter(
        (item) => item.id === rowData.destination
      );
    if (
      AccountCorrespondenceContatInfo &&
      AccountCorrespondenceContatInfo?.GetAccountCorrespondenceContactInfo
        ?.length > 0 &&
      filteredAccount &&
      filteredAccount[0]?.group &&
      filteredAccount[0]?.group?.length > 0
    ) {
      for (let i = 0; i < filteredAccount[0]?.group?.length; i++) {
        const groupData = filteredAccount[0]?.group[i];
        if (groupData && groupData.groupName == 'addresses') {
          return handleAddressDescription(groupData);
        }
      }
    }
  };

  const previewButtonClick = (generatedLetters: string[]) => {
    generatedLetters.forEach((letter) => {
      const blobPart = Buffer.from(letter, 'base64');
      const mediaSource = new Blob([blobPart], {
        type: 'application/pdf',
      });
      window.open(URL.createObjectURL(mediaSource), '_blank');
    });
  };

  const generateButtonClick = (generatedLetters: string[]) => {
    generatedLetters.forEach((letter, index) => {
      const blobPart = Buffer.from(letter, 'base64');
      const mediaSource = new Blob([blobPart], {
        type: 'application/pdf',
      });
      if (index == 0) {
        FileSaver.saveAs(mediaSource ?? '', `GeneratedLetter.pdf`, {
          autoBom: false,
        });
      } else {
        FileSaver.saveAs(mediaSource ?? '', `CC_GeneratedLetter.pdf`, {
          autoBom: false,
        });
      }
    });
  };

  useEffect(() => {
    if (correspondenceProps.isFromEntity) {
      if (
        EntityCorrespondenceContatInfo &&
        EntityCorrespondenceContatInfo?.GetEntityCorrespondenceContactInfo
          ?.length > 0 &&
        EntityCorrespondenceContatInfo?.GetEntityCorrespondenceContactInfo[0]
          ?.group &&
        EntityCorrespondenceContatInfo?.GetEntityCorrespondenceContactInfo[0]
          ?.group?.length > 0
      ) {
        for (
          let i = 0;
          i <
          EntityCorrespondenceContatInfo?.GetEntityCorrespondenceContactInfo[0]
            ?.group.length;
          i++
        ) {
          const groupData =
            EntityCorrespondenceContatInfo
              ?.GetEntityCorrespondenceContactInfo[0]?.group[i];
          if (groupData && groupData.groupName == 'addresses') {
            setAddress(handleAddressDescription(groupData));
            break;
          }
        }
      }
    } else if (
      AccountCorrespondenceContatInfo?.GetAccountCorrespondenceContactInfo
    )
      if (
        AccountCorrespondenceContatInfo &&
        AccountCorrespondenceContatInfo?.GetAccountCorrespondenceContactInfo
          ?.length > 0 &&
        AccountCorrespondenceContatInfo?.GetAccountCorrespondenceContactInfo[0]
          ?.group &&
        AccountCorrespondenceContatInfo?.GetAccountCorrespondenceContactInfo[0]
          ?.group.length > 0
      ) {
        for (
          let i = 0;
          i <
          AccountCorrespondenceContatInfo
            ?.GetAccountCorrespondenceContactInfo[0]?.group.length;
          i++
        ) {
          const groupData =
            AccountCorrespondenceContatInfo
              ?.GetAccountCorrespondenceContactInfo[0]?.group[i];
          if (groupData && groupData.groupName == 'addresses') {
            setAddress(handleAddressDescription(groupData));
            break;
          }
        }
      }
  }, [
    AccountCorrespondenceContatInfo,
    EntityCorrespondenceContatInfo,
    correspondenceProps.isFromEntity,
    handleAddressDescription,
  ]);

  useEffect(() => {
    (async function accountsInfo() {
      if (accountId && accountId.length > 0) {
        await accountContactInfoRefetch().then(() => {});
      }
    })().catch((e) => e);
  }, [accountContactInfoRefetch, accountId]);

  function generateLetterEntity(previewReq: boolean) {
    generateMutation(
      {
        letter: {
          contextParameters: {
            entityid: correspondenceProps?.entityId ?? '',
          },
          templateId: parseInt(templateId ?? ''),
          isPreview: previewReq,
          manualFieldInfoJson: JSON.stringify(finalUpdatedJson),
          recipients: recipientsInfo,
          ftiFlag: localFtiFlag,
        },
      },
      {
        onSuccess: (data) => {
          if (previewReq) {
            previewButtonClick(data?.GenerateLetter);
          } else {
            generateButtonClick(data?.GenerateLetter);

            correspondenceProps.setIsHistoryRefetch(
              !correspondenceProps.isHistoryRefetch
            );
            dispatch(
              addMessage({
                message: t('pages.correspondence.message.letterPrint'),
                type: MessageType.Success,
                actionType: MessageActionType.None,
              })
            );
            handleCancleClick();
          }
        },
        onError: () => {
          dispatch(
            addMessage({
              message: t('pages.manageReusableContent.networkError'),
              type: MessageType.Error,
              actionType: MessageActionType.None,
            })
          );
          handleCancleClick();
        },
      }
    );
  }

  const generateLetterAccount = (previewReq: boolean) => {
    generateMutation(
      {
        letter: {
          contextParameters: {
            accountid: correspondenceProps?.accountId ?? '',
            entityid: entityId ?? '',
          },
          templateId: parseInt(templateId ?? ''),
          isPreview: previewReq,
          manualFieldInfoJson: JSON.stringify(finalUpdatedJson),
          recipients: recipientsInfo,
          ftiFlag: localFtiFlag,
        },
      },
      {
        onSuccess: (data) => {
          if (previewReq) {
            previewButtonClick(data?.GenerateLetter);
          } else {
            generateButtonClick(data?.GenerateLetter);

            correspondenceProps.setIsHistoryRefetch(
              !correspondenceProps.isHistoryRefetch
            );
            dispatch(
              addMessage({
                message: t('pages.correspondence.message.letterPrint'),
                type: MessageType.Success,
                actionType: MessageActionType.None,
              })
            );
            handleCancleClick();
          }
        },
        onError: () => {
          dispatch(
            addMessage({
              message: t('pages.manageReusableContent.networkError'),
              type: MessageType.Error,
              actionType: MessageActionType.None,
            })
          );
          handleCancleClick();
        },
      }
    );
  };

  const templateIdClickHandler = useCallback(
    (tempId: Maybe<string> | undefined) => {
      const data = activeTemplateData?.ActiveCorrespondenceType?.filter(
        (item) => item.templateId === tempId
      );
      if (data) setCorrType(data[0]);
    },
    [activeTemplateData?.ActiveCorrespondenceType]
  );
  const FreeFormClickHandler = () => {
    setOpenTemplate(false);
    setFreeFormText(true);
  };

  useEffect(() => {
    if (generateLetterProps.generated) {
      if (correspondenceProps.isFromEntity)
        generateLetterEntity(generateLetterProps.isPreview);
      else generateLetterAccount(generateLetterProps.isPreview);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generateLetterProps, recipientsInfo]);

  const recipientInfoCapture = () => {
    const receipientsData: Recipients[] = [];
    selectedRecipients.forEach((item) => {
      if (!recipientsInfo.some((rec) => rec.recipientId === item))
        receipientsData.push({ recipientId: item, recipientType: 'Cc' });
    });
    setRecipientsInfo([...recipientsInfo, ...receipientsData]);
  };

  const scheduleLetter = async () => {
    if (correspondenceProps.isFromEntity) {
      scheduleMutation(
        {
          schedule: {
            contextParameters: {
              entityid: correspondenceProps?.entityId ?? '',
            },
            templateId: parseInt(templateId ?? ''),
            manualFieldInfoJson: JSON.stringify(finalUpdatedJson),
            recipients: recipientsInfo,
            ftiFlag: localFtiFlag,
          },
        },
        {
          onSuccess: () => {
            correspondenceProps.setIsHistoryRefetch(
              !correspondenceProps.isHistoryRefetch
            );
            dispatch(
              addMessage({
                message: t('pages.correspondence.message.schedule'),
                type: MessageType.Success,
                actionType: MessageActionType.None,
              })
            );
            handleCancleClick();
          },
          onError: () => {
            dispatch(
              addMessage({
                message: t('pages.manageReusableContent.networkError'),
                type: MessageType.Error,
                actionType: MessageActionType.None,
              })
            );
            handleCancleClick();
          },
        }
      );
    } else {
      scheduleMutation(
        {
          schedule: {
            contextParameters: {
              accountid: correspondenceProps?.accountId ?? '',
              entityid: entityId ?? '',
            },
            templateId: parseInt(templateId ?? ''),
            manualFieldInfoJson: JSON.stringify(finalUpdatedJson),
            recipients: recipientsInfo,
            ftiFlag: localFtiFlag,
          },
        },
        {
          onSuccess: () => {
            correspondenceProps.setIsHistoryRefetch(
              !correspondenceProps.isHistoryRefetch
            );
            dispatch(
              addMessage({
                message: t('pages.correspondence.message.schedule'),
                type: MessageType.Success,
                actionType: MessageActionType.None,
              })
            );
            handleCancleClick();
          },
          onError: () => {
            dispatch(
              addMessage({
                message: t('pages.manageReusableContent.networkError'),
                type: MessageType.Error,
                actionType: MessageActionType.None,
              })
            );
            handleCancleClick();
          },
        }
      );
    }
  };
  const dataForActiveCorrespondence = useMemo(() => {
    if (
      activeTemplateData?.ActiveCorrespondenceType &&
      userData?.UsersBySearch
    ) {
      return activeTemplateData?.ActiveCorrespondenceType.filter((item) => {
        for (
          let i = 0;
          i < (item.roleAssignments ? item.roleAssignments?.length : 0);
          i++
        ) {
          const found = userData?.UsersBySearch[0]?.userRole?.some(
            (role) =>
              role.roleId ===
              (item.roleAssignments ? item.roleAssignments[i].roleId : '')
          );
          if (
            found &&
            item?.roleAssignments &&
            item?.roleAssignments[i].canAddFlag
          )
            return item;
        }
      }).sort((a, b) =>
        a.correspondenceTypeName?.toLowerCase() >
        b.correspondenceTypeName?.toLowerCase()
          ? 1
          : -1
      );
    }
  }, [activeTemplateData?.ActiveCorrespondenceType, userData?.UsersBySearch]);

  const handleTextArea = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (
      ManualFieldInfo?.GetManualFieldInfoByTemplate &&
      JSON.parse(ManualFieldInfo?.GetManualFieldInfoByTemplate).freeformFields
        .length > 0
    ) {
      const localJSON = finalUpdatedJson?.freeformFields;
      const localOption = localJSON?.filter(
        (item) => item.fieldName == event.target.id
      );
      if (localOption) localOption[0].value = event.target.value ?? '';
      setFinalUpdatedJson(JSON.parse(JSON.stringify(finalUpdatedJson) ?? ''));
    }
  };

  useEffect(() => {
    if (
      !ManualFieldInfoLoading &&
      ManualFieldInfo?.GetManualFieldInfoByTemplate
    ) {
      setFinalUpdatedJson(
        JSON.parse(ManualFieldInfo?.GetManualFieldInfoByTemplate ?? '')
      );
      if (
        ManualFieldInfo?.GetManualFieldInfoByTemplate &&
        (JSON.parse(ManualFieldInfo?.GetManualFieldInfoByTemplate)
          .multiChoiceGroups.length > 0 ||
          JSON.parse(ManualFieldInfo?.GetManualFieldInfoByTemplate)
            .freeformFields.length > 0)
      ) {
        FreeFormClickHandler();
      } else setOpenTemplate(true);

      templateIdClickHandler(templateId);
    }
  }, [
    templateId,
    templateIdClickHandler,
    ManualFieldInfo?.GetManualFieldInfoByTemplate,
    ManualFieldInfoLoading,
  ]);

  useEffect(() => {
    if (templateId) {
      ManualFieldInfoFetch();
    }
  }, [templateId, ManualFieldInfoFetch]);

  // eslint-disable-next-line
  const fetchLetterDetails = (row: any) => {
    if (address) {
      setTemplateId(row.original.templateId);
      if (
        row.original.templateId === templateId &&
        ManualFieldInfo?.GetManualFieldInfoByTemplate &&
        (JSON.parse(ManualFieldInfo?.GetManualFieldInfoByTemplate)
          .multiChoiceGroups.length > 0 ||
          JSON.parse(ManualFieldInfo?.GetManualFieldInfoByTemplate)
            .freeformFields.length > 0)
      ) {
        FreeFormClickHandler();
      } else if (row.original.templateId === templateId) setOpenTemplate(true);
    } else {
      setOpenTemplate(false);
      setFreeFormText(false);
    }
    setLocalFtiFlag(row.original.ftiFlag);
  };
  const ActiveTemplatesColumns: ColumnDef<ActiveCorrespondenceTypeResponse>[] =
    [
      {
        header: () => (
          <HeaderColumnNext localization={t('pages.correspondence.typeName')} />
        ),
        accessorKey: 'correspondenceTypeName',
      },
      {
        header: () => (
          <HeaderColumnNext localization={t('pages.correspondence.fti')} />
        ),
        accessorKey: 'ftiFlag',
        cell: ({ row }) => {
          if (row.original.ftiFlag) {
            return 'Yes';
          } else {
            return 'No';
          }
        },
      },
      {
        header: () => (
          <HeaderColumnNext localization={t('pages.correspondence.category')} />
        ),
        accessorKey: 'correspondenceCategoryName',
      },

      {
        header: () => (
          <HeaderColumnNext
            localization={t('pages.correspondence.modifiedDate')}
          />
        ),
        accessorKey: 'modifiedDate',
        cell: ({ row }) => {
          return <DateCell dateString={row.original.modifiedDate as string} />;
        },
      },
    ];
  const AddRecipientColumns: ColumnDef<Relationships>[] = [
    {
      id: 'id',
      cell: ({ row }) => {
        return (
          <Checkbox
            id={'recipientChk'}
            label={''}
            checked={
              selectedRecipients.length > 0 &&
              selectedRecipients.findIndex(
                (entity) => entity === row.original.destination
              ) > -1
            }
            sx={{ ml: 1 }}
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedRecipients([
                  ...selectedRecipients,
                  row.original.destination,
                ]);
              } else {
                const newRecipients = [...selectedRecipients].filter(
                  (entity) => entity !== row.original.destination
                );
                setSelectedRecipients(newRecipients);
              }
            }}
          />
        );
      },
    },

    {
      header: () => <HeaderColumnNext localization="Entity Name" />,
      accessorKey: 'destinationName',
      cell: ({ row }) => {
        return correspondenceProps.isFromEntity
          ? namesCellHandlerEntity(row.original)
          : namesCellHandlerAccount(row.original);
      },
    },
    {
      header: () => <HeaderColumnNext localization="Entity Address" />,
      accessorKey: 'relationshi',
      cell: ({ row }) => {
        return correspondenceProps.isFromEntity
          ? addressCellHandlerEntity(row.original)
          : addressCellHandlerAccount(row.original);
      },
    },
    {
      header: () => <HeaderColumnNext localization="Relationship Type" />,
      accessorKey: 'relationshipType',
    },
  ];

  const ActiveTemplateChildren = (
    <>
      <Box style={{ textAlign: 'right' }}>
        {!address ? (
          <Loading></Loading>
        ) : (
          <DataDisplay
            id={'address'}
            label={t('pages.correspondence.address')}
            data={
              address ? address.trim() : t('pages.correspondence.addressError')
            }
            sx={{ display: 'inline-block', ml: 0.5 }}
          ></DataDisplay>
        )}
      </Box>
      {activeLoading || dataForActiveCorrespondence === undefined ? (
        <Loading></Loading>
      ) : dataForActiveCorrespondence?.length ? (
        <>
          <DefaultDataTableNext
            data={
              dataForActiveCorrespondence as unknown as Record<
                string,
                unknown
              >[]
            }
            columns={
              ActiveTemplatesColumns as ColumnDef<
                Record<string, unknown>,
                unknown
              >[]
            }
            cursorHover={address ? true : false}
            fetchCursorSelectedRow={fetchLetterDetails}
            customHeader={
              <Box sx={{ display: 'flex', alignItems: 'end' }}>
                <Button
                  id="btn_cc"
                  sx={{ minWidth: '128px', mr: 1 }}
                  onClick={() => {
                    setIsRecipientCC(true);
                  }}
                  disabled={
                    (correspondenceProps.isFromEntity &&
                      EntityCorrespondenceContatInfo &&
                      EntityCorrespondenceContatInfo
                        ?.GetEntityCorrespondenceContactInfo?.length <= 1) ||
                    (!correspondenceProps.isFromEntity &&
                      AccountCorrespondenceContatInfo &&
                      AccountCorrespondenceContatInfo
                        ?.GetAccountCorrespondenceContactInfo?.length <= 1)
                  }
                >
                  {t('pages.correspondence.cc.recipient')}
                </Button>
              </Box>
            }
          />
          <Button
            id="cancel_button"
            onClick={() => {
              setActiveTemplate(false);
              setSelectedRecipients([]);
            }}
            sx={{ ml: '270px', mt: '18px', minWidth: '128px' }}
          >
            {t('pages.manageReusableContent.buttons.cancel')}
          </Button>
        </>
      ) : (
        <>
          <DataDisplay
            id={''}
            label={t('pages.manageReusableContent.noRecordsError')}
            data={''}
          ></DataDisplay>
        </>
      )}
    </>
  );

  const manualFieldInfoJsonHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (
      ManualFieldInfo?.GetManualFieldInfoByTemplate &&
      JSON.parse(ManualFieldInfo?.GetManualFieldInfoByTemplate)
        .multiChoiceGroups.length > 0
    ) {
      const localJSON = finalUpdatedJson?.multiChoiceGroups;
      const localGroup = localJSON?.filter(
        (item) => item.groupName == e.target.id.split(',')[0]
      );
      if (localGroup && localGroup?.length > 0 && localGroup[0].type == 'sgl') {
        localGroup[0]?.options.map((opt) => (opt.value = 'false'));
      }
      const localOption =
        localGroup &&
        localGroup[0]?.options.filter(
          (item) => item.description == e.target.id.split(',')[1]
        );
      if (localOption)
        localOption[0].value = e.target.checked ? 'true' : 'false';
      setFinalUpdatedJson(JSON.parse(JSON.stringify(finalUpdatedJson) ?? ''));
    }
  };

  const OpenTemplate = (
    <>
      <Grid
        container
        spacing="1"
        justifyContent="center"
        flexDirection="column"
        flexWrap="wrap"
        alignContent="center"
      >
        <Grid item>
          <DataDisplay
            id={'typeName'}
            label={t('pages.correspondence.typeName')}
            data={corrType ? corrType.correspondenceTypeName : ''}
            sx={{
              display: 'inline-block',
              ml: 1,
            }}
          />
        </Grid>
        <Grid item>
          <DataDisplay
            id={'typeCategory'}
            label={t('pages.correspondence.typeCategory')}
            data={corrType ? corrType.correspondenceCategoryName ?? '' : ''}
            sx={{ display: 'inline-block', ml: 1 }}
          />
        </Grid>
        <Grid item>
          <DataDisplay
            id={'modifiedDate'}
            label={t('pages.correspondence.modifiedDate')}
            data={
              corrType
                ? format(new Date(corrType.modifiedDate ?? ''), dateFormat)
                : ''
            }
            sx={{ display: 'inline-block', ml: 1 }}
          />
        </Grid>
        <Grid item>
          <Checkbox
            disabled={localFtiFlag && corrType?.ftiFlag}
            id="ftiFlag"
            checked={localFtiFlag}
            onChange={(event) => {
              setLocalFtiFlag(event.target.checked);
            }}
            label={t('pages.correspondence.fti')}
            sx={{
              '.MuiFormControlLabel-root > p': { fontWeight: 600 },
              mt: 0.5,
            }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing="2"
        style={{ marginTop: '20px', gap: 15 }}
        justifyContent="center"
      >
        <Button
          sx={{ minWidth: '128px' }}
          id={'cancel'}
          onClick={() => {
            setOpenTemplate(false);
            setActiveTemplate(false);
          }}
        >
          {t('pages.correspondence.cancel')}
        </Button>
        <Button
          id="url"
          sx={{ minWidth: '128px' }}
          onClick={() => {
            recipientInfoCapture();
            setGenerateLetterProps({ generated: true, isPreview: true });
          }}
        >
          {t('pages.correspondence.preview')}
        </Button>
        <Button
          id="generate"
          sx={{ minWidth: '128px' }}
          onClick={() => {
            recipientInfoCapture();
            setGenerateLetterProps({ generated: true, isPreview: false });
          }}
        >
          {t('pages.correspondence.generate')}
        </Button>
        <Button
          id="schedule"
          sx={{ minWidth: '128px' }}
          onClick={() => scheduleLetter()}
        >
          {t('pages.correspondence.schedule')}
        </Button>
      </Grid>
    </>
  );

  const FreeFormTextChildren = (
    <>
      {finalUpdatedJson && finalUpdatedJson?.freeformFields?.length > 0 && (
        <Typography
          variant="h3"
          sx={[{ fontWeight: 'bold', color: 'primary.main' }]}
        >
          {t('pages.correspondence.freeFormTextMessage')}
        </Typography>
      )}
      {finalUpdatedJson &&
        finalUpdatedJson.freeformFields?.length > 0 &&
        finalUpdatedJson?.freeformFields
          .filter(
            (value, index, self) =>
              index ===
              self.findIndex((fld) => fld.fieldName === value.fieldName)
          )
          .map((item) => (
            <Box key={item.fieldName}>
              <Typography
                variant="h3"
                sx={[{ fontWeight: 'bold', color: 'primary.main' }]}
              >
                {item.fieldName.replace(item.fieldName.charAt(0), '') + ':'}
              </Typography>
              <TextArea
                ariaLabel="item field name"
                multiline
                id={item.fieldName}
                onChange={handleTextArea}
                sx={{ width: '100%' }}
                placeholder={t('pages.correspondence.freeFormTextPlaceholder')}
                value={
                  finalUpdatedJson?.freeformFields.filter(
                    (item3) => item3.fieldName == item.fieldName
                  )[0].value ?? ''
                }
              />
            </Box>
          ))}
      {finalUpdatedJson && finalUpdatedJson.multiChoiceGroups?.length > 0 && (
        <>
          <Typography
            variant="h3"
            sx={[{ fontWeight: 'bold', color: 'primary.main' }]}
          >
            {t('pages.correspondence.multiChoiceTextMessage')}
          </Typography>

          <Grid container mt={1} sx={{ gap: '2%' }}>
            {finalUpdatedJson?.multiChoiceGroups.map(
              (item: {
                type: string;
                groupName: string;
                options: {
                  optionName: string;
                  description: string;
                  value: string;
                }[];
              }) => {
                if (item.type == 'sgl') {
                  return (
                    <Box width={'45%'} key={item.groupName}>
                      <DataCard
                        title={item.groupName}
                        avatar={<></>}
                        children={
                          <RadioGroup>
                            {item.options.map(
                              (option: {
                                optionName: string;
                                description: string;
                                value: string;
                              }) => {
                                return (
                                  <Radio
                                    id={
                                      option.optionName.split(':')[1] +
                                      ',' +
                                      option.description
                                    }
                                    key={option.optionName}
                                    onChange={(e) =>
                                      manualFieldInfoJsonHandler(e)
                                    }
                                    value={option.description}
                                    color={'default'}
                                    label={option.description}
                                    checked={option.value == 'true'}
                                  />
                                );
                              }
                            )}
                          </RadioGroup>
                        }
                      />
                    </Box>
                  );
                }
                if (item.type == 'mlt') {
                  return (
                    <Box width={'45%'} key={item.groupName}>
                      <DataCard
                        title={item.groupName}
                        avatar={<></>}
                        children={
                          <>
                            {item.options.map(
                              (option: {
                                optionName: string;
                                description: string;
                                value: string;
                              }) => {
                                return (
                                  <Checkbox
                                    id={
                                      option.optionName.split(':')[1] +
                                      ',' +
                                      option.description
                                    }
                                    key={option.optionName}
                                    onChange={(e) =>
                                      manualFieldInfoJsonHandler(e)
                                    }
                                    label={option.description}
                                    checked={option.value == 'true'}
                                  ></Checkbox>
                                );
                              }
                            )}
                          </>
                        }
                      />
                    </Box>
                  );
                }
              }
            )}
          </Grid>
        </>
      )}
      <Grid
        sx={{ display: 'flex', justifyContent: 'center' }}
        style={{ gap: '15px' }}
      >
        <Button
          id="cancel_button"
          onClick={() => {
            setFreeFormText(false);
          }}
          sx={{ mt: '18px', minWidth: '128px' }}
        >
          {t('pages.manageReusableContent.buttons.cancel')}
        </Button>
        <Button
          id="proceed_button"
          onClick={() => setOpenTemplate(true)}
          sx={{ mt: '18px', minWidth: '128px' }}
        >
          {t('pages.correspondence.proceed')}
        </Button>
      </Grid>
    </>
  );

  const recipientCcDialog = (
    <>
      {isRecipientCC && relationshipList.length > 0 && !isFetchingRelations && (
        <>
          <DefaultDataTableNext
            data={
              relationshipList.filter(
                (entityRelation, index) =>
                  index ===
                  relationshipList.findIndex(
                    (other) =>
                      entityRelation.destinationName ===
                        other.destinationName &&
                      entityRelation.type === LinkTypes.EntityToEntity
                  )
              ) as Record<string, unknown>[]
            }
            columns={
              AddRecipientColumns as ColumnDef<
                Record<string, unknown>,
                unknown
              >[]
            }
            cursorHover={address ? true : false}
          />
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: '2%' }}>
            <Button
              id="cancel_btn"
              onClick={() => setIsRecipientCC(false)}
              sx={{ mt: '18px', minWidth: '128px' }}
            >
              {t('pages.manageReusableContent.buttons.cancel')}
            </Button>
          </Box>
        </>
      )}
    </>
  );
  return (
    <>
      {correspondenceProps.isFromTemplate ? (
        <>
          <Dialog
            id="freeFormText"
            open={freeFormText}
            children={FreeFormTextChildren}
            title={t('pages.correspondence.freeFormAndMultiChoice')}
            width={700}
            height={350}
            handleClose={() => {
              setOpenTemplate(false);
              setFreeFormText(false);
            }}
          />
          <Dialog
            id="dialogId"
            open={activeTemplate && !freeFormText}
            children={ActiveTemplateChildren}
            title={t('pages.correspondence.selectLetter')}
            width={700}
            height={350}
            handleClose={() => {
              setActiveTemplate(false);
              setSelectedRecipients([]);
            }}
          />
          <Dialog
            id="openTemplate"
            open={openTemplate}
            children={OpenTemplate}
            title={t('pages.correspondence.selectOption')}
            width={700}
            height={250}
            handleClose={() => {
              setOpenTemplate(false);
            }}
          />
        </>
      ) : null}
      {
        <Button
          id="active_Templates"
          onClick={() =>
            !IsSuppressionExistsEntity && !IsSuppressionExistsAccount
              ? setActiveTemplate(true)
              : dispatch(
                  addMessage({
                    message: t('pages.correspondence.suppressionError'),
                    type: MessageType.Error,
                    actionType: MessageActionType.None,
                  })
                )
          }
          type="secondary"
          variant="text"
        >
          {t('pages.correspondence.add')}
        </Button>
      }
      <Dialog
        id="cc"
        open={isRecipientCC}
        children={recipientCcDialog}
        title={t('pages.correspondence.cc.select')}
        width={700}
        height={350}
        handleClose={() => {
          setIsRecipientCC(false);
        }}
      />
    </>
  );
}
export default AddLetterInfo;
