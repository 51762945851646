import { MasterLayout } from '@revenue-solutions-inc/revxcoreui/material/layoutBuilder/types/layouts';
import { FormLayoutConfiguration } from 'generated/graphql';

export const getMasterLayout = (
  layoutOptions: FormLayoutConfiguration[] | undefined,
  context: string | undefined,
  adjustmentReasons?: string[]
) => {
  if (!layoutOptions) return undefined;
  if (!layoutOptions.length) return undefined;
  if (!layoutOptions[0].layout) return undefined;
  let str = '';
  if (context) {
    let contextualLayout = layoutOptions.find(
      (l) => l.layoutConfigurationId === context
    );
    if (!contextualLayout) {
      contextualLayout = layoutOptions.find(
        (l) =>
          l.context?.toLowerCase().replace(/\s/g, '') ===
          context.toLowerCase().replace(/\s/g, '')
      );
    }
    if (contextualLayout && contextualLayout.layout) {
      str = contextualLayout.layout;
    }
  } else {
    str = layoutOptions[0].layout;
  }

  try {
    const temp: MasterLayout = JSON.parse(str);
    temp.adjustmentReasons = adjustmentReasons;
    return temp;
  } catch {
    return undefined;
  }
};
