import { useEffect, useState } from 'react';
import propertyMenuConfig, {
  revenueMenuConfig,
  adminMenuConfig,
  adminToolsMenuConfig,
} from 'components/layout/Sidebar/sidebarScript';
import MenuConfig from 'components/MenuConfig';
import { Search, Card, CardContent } from '@revenue-solutions-inc/revxcoreui';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { Typography, Grid } from '@mui/material';
import { setHeader } from 'redux/contentSlice';

function Navigation(): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const module = useAppSelector((state) => state.user.module);
  const [value, setValue] = useState('');

  const moduleConfig =
    module === 'property'
      ? propertyMenuConfig
      : module === 'revenue'
      ? revenueMenuConfig
      : adminMenuConfig;

  const [displayModuleConfig, setDisplayModuleConfig] = useState(moduleConfig);

  useEffect(() => {
    dispatch(
      setHeader({
        pageTitle: t('pages.navigation.title'),
      })
    );
  }, [dispatch, t]);

  useEffect(() => {
    if (value === '') setDisplayModuleConfig(moduleConfig);
  }, [value, moduleConfig]);

  useEffect(() => {
    setValue('');
  }, [module]);

  useEffect(() => {
    const result = moduleConfig.filter((item) =>
      t(item.label).toLowerCase().includes(value.toLowerCase()) ||
      item.items?.find((subitems) =>
        t(subitems.label).toLowerCase().includes(value.toLowerCase())
      )
        ? item
        : null
    );
    setDisplayModuleConfig(result);
  }, [value, moduleConfig, t]);

  return (
    <>
      <Grid>
        <Search
          placeholder={t('pages.search.title')}
          value={value}
          handleChange={setValue}
        />
      </Grid>
      <Card sx={{ mt: 2 }}>
        <CardContent>
          <Grid container spacing={1} sx={{ mt: 2 }}>
            <MenuConfig links={displayModuleConfig} module={module} />
          </Grid>
        </CardContent>
      </Card>

      {(module === 'revenue' || module === 'property') && (
        <>
          <Typography variant="h2" sx={{ mt: 2, ml: '.4em' }}>
            {t('pages.adminTools.title')}
          </Typography>
          <Card sx={{ mt: 2 }}>
            <CardContent>
              <Grid container spacing={1} sx={{ mt: 2 }}>
                <MenuConfig links={adminToolsMenuConfig} module={module} />
              </Grid>
            </CardContent>
          </Card>
        </>
      )}
    </>
  );
}

export default Navigation;
